import { imageCacheService } from '@core/canvas/ImageCacheService';
import { httpService } from '@core/http/HttpService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { editAssetService } from './EditAssetService';
import { AssetInfo } from './EditAssetModal.interface';

export async function getImageData(galleryImageId, setImageUrl) {
  if (!galleryImageId) {
    setImageUrl(null);
  } else {
    try {
      const res = await imageCacheService.getImageURL(+galleryImageId);
      if (res) {
        setImageUrl(res.url);
      }
    } catch {}
  }
}

export function saveSingleField(args, value) {
  httpService
    .api({
      type: 'saveGeneralAsset',
      data: { [args.fieldName]: value },
      urlParams: { assetId: args.assetId, useUserOrganizationId: true },
    })
    .then((res: AssetInfo) => {
      assetsService.refreshAssetTableData();
      editAssetService.updateEditAsset({
        ...res,
        [args.fieldName]: value,
      });
    });
}

export async function getSubscriptions(assetId, setSubscriptionsData) {
  try {
    const res = await httpService.api({ type: 'getAssetSubscriptions', urlParams: { assetId } });
    if (res) {
      setSubscriptionsData(res);
    }
  } catch (e) {}
}
