import React, { useEffect, useState } from 'react';
import { Popover, ClickAwayListener } from '@material-ui/core';
import I18n from '@components/I18n';
import classNames from 'classnames';
import styles from './NotificationCenter.scss';
import SingleNotification from './SingleNotification/SingleNotification';
import { SingleNotificationProps } from './SingleNotification/SingleNotification.interface';
import { getNotifications } from '../MainPage.utils';

import CustomSwitch from '@src/components/CustomSwitch';

function NotificationCenter(props) {
  const {
    anchorEl,
    close,
    notifications,
    isNotificationCenterOpen,
    setNotifications,
    unreadNotifications,
    notificationDetailsRef,
  } = props;

  const { allCount, results } = notifications;

  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [nextPage, setNextPage] = useState(2);

  useEffect(() => {
    getNotifications(showUnreadOnly, setNotifications, 1, notifications);
  }, [showUnreadOnly]);

  function toggleShowUnreadOnly() {
    setShowUnreadOnly(!showUnreadOnly);
    setNextPage(2);
  }

  function handleScroll(event) {
    const { scrollHeight, offsetHeight, scrollTop } = event.target;
    const distanceFromBottom = scrollHeight - (scrollTop + offsetHeight);
    if (distanceFromBottom < 1) {
      getNotifications(showUnreadOnly ? true : false, setNotifications, nextPage, notifications);
      setNextPage(nextPage + 1);
    }
  }

  return (
    <Popover
      open={isNotificationCenterOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // PaperProps={{ onMouseLeave: closeMenu }}
    >
      <ClickAwayListener
        onClickAway={() => {
          if (!notificationDetailsRef?.current) {
            close();
          }
        }}>
        <div className={styles.wrapper}>
          <div className={styles.headerWrapper}>
            <div className={styles.headerTopSection}>
              <div className={classNames(styles.headerText, 'ellipsis-overflow')}>
                <I18n>notification-center.notifications</I18n>
              </div>
              <div className={styles.showUnreadOnlyWrapper} onClick={toggleShowUnreadOnly}>
                <CustomSwitch
                  disabled={false}
                  checked={showUnreadOnly}
                  onChange={() => {}}
                  size="small"
                />
                <I18n className={styles.showUnreadOnlyCaption}>
                  notification-center.show-unread-only
                </I18n>
              </div>
            </div>
            <div className={styles.headerDivider}></div>
          </div>
          <div
            className={classNames(
              styles.notificationListWrapper,
              results.length > 9 ? styles.withScrollbar : undefined
            )}
            onScroll={(e) => {
              if (allCount > results.length) {
                handleScroll(e);
              }
            }}>
            {results.map((notification: SingleNotificationProps, idx) => (
              <div key={idx}>
                <SingleNotification
                  id={notification.id}
                  timestamp={notification.timestamp}
                  type={notification.type}
                  name={notification.name}
                  summary={notification.summary}
                  read={notification.read}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  unreadNotifications={unreadNotifications}
                  notificationDetailsRef={notificationDetailsRef}
                  showUnreadOnly={showUnreadOnly}
                />
              </div>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    </Popover>
  );
}

export default NotificationCenter;
