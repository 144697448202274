export const dateTimeChoice = [
  { value: 'DAILY', label: 'enum.DAILY' },
  { value: 'WEEKLY', label: 'enum.WEEKLY' },
  { value: 'MONTHLY', label: 'enum.MONTHLY' },
  { value: 'YEARLY', label: 'enum.YEARLY' },
];

export const options = [
  { value: 'AVERAGE', label: 'enum.AVERAGE' },
  { value: 'COUNT', label: 'enum.COUNT' },
  { value: 'DELTA', label: 'enum.DELTA' },
  { value: 'FIRST', label: 'enum.FIRST' },
  { value: 'LAST', label: 'enum.LAST' },
  { value: 'MAX', label: 'enum.MAX' },
  { value: 'MIN', label: 'enum.MIN' },
  { value: 'SUM', label: 'enum.SUM' },
];

export const operatorOptionByFormat = (tagType, widgetData, filterID?, filterType?) => {
  switch (tagType) {
    case 'stringType':
    case 'ipv4Type':
      return [
        { value: 'IS', label: 'enum.IS' },
        { value: 'IS_NOT', label: 'enum.IS_NOT' },
        { value: 'INCLUDES', label: 'enum.INCLUDES' },
        { value: 'EXCLUDES', label: 'enum.EXCLUDES' },
      ];
    case 'numberType':
    case 'floatType':
      const options = [
        { value: 'IS', label: 'enum.IS' },
        { value: 'IS_NOT', label: 'enum.IS_NOT' },
        { value: 'SMALLER_THAN', label: 'enum.SMALLER_THAN' },
        { value: 'LARGER_THAN', label: 'enum.LARGER_THAN' },
        { value: 'INCLUDES', label: 'enum.INCLUDES' },
        { value: 'EXCLUDES', label: 'enum.EXCLUDES' },
      ];
      const alarmDurationOptions = ['SMALLER_THAN', 'LARGER_THAN'];
      return filterID === 5 && filterType === 'ALARM_INFO'
        ? options.reduce((acc, option) => {
            return alarmDurationOptions.includes(option.value) ? [...acc, option] : [...acc];
          }, [])
        : options;
    case 'dateTimeType':
      if (widgetData && widgetData.type === 'line' && widgetData.scope === 'RAW_DATA') {
        return [{ value: 'AFTER', label: 'enum.AFTER' }];
      }
      return [
        { value: 'IS', label: 'enum.IS' },
        { value: 'BEFORE', label: 'enum.BEFORE' },
        { value: 'AFTER', label: 'enum.AFTER' },
      ];
    case 'booleanType':
      return [
        { value: 'IS', label: 'enum.IS' },
        { value: 'IS_NOT', label: 'enum.IS_NOT' },
      ];
  }
  return null;
};
