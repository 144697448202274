import React, { useState, useEffect, useMemo } from 'react';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import {
  getTableCustomizationData,
  onColumnChanged,
  updateColumns,
} from '../TableCustomization.utils';
import { TableCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { i18nService } from '@core/i18n/I18nService';
import TableCustomizationRow from '../TableCustomizationRow';
import { dndService } from '@core/DndService';
import { compact, cloneDeep } from 'lodash';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { findDateTimeTag } from '@pages/CreateWidgetPage/widgets.utils';
import { isNameValid } from '../TableCustomization.utils';

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1px',
    // padding: 10,
    fontSize: 12,
    fontWeight: 500,
    borderTop: 'solid 1px #c2cfe0',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 0px 10px 0px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: 15,
  },
  wraning: {
    fontSize: 12,
    color: `var(--formikErrorText)`,
    fontWeight: 'bold',
    marginBottom: 8,
  },
}));

function TableConfigColumns(props) {
  const classes = useStyles(props);
  const { widgetData, setWidgetData, previewData, defaultDecimalDigits } = props;
  const { customization, metrics, groupBy, scope } = widgetData as any;
  const { columns } = (customization as TableCustomization) || { columns: null };
  const [tableColumns, setTableColumns] = useState(columns);
  const [wraning, setWraning] = useState('');
  const [displayNameHasError, setDisplayNameHasError] = useState(false);
  const columnsOriginalData = useMemo(() => [...groupBy, ...metrics], [metrics, groupBy]);
  const hasDateTimeTag = useMemo(
    () => findDateTimeTag(columnsOriginalData),
    [columnsOriginalData, scope]
  );
  const columnCfg = compact([
    {
      style: { minWidth: '5%', width: '5%', marginRight: 10 },
    },
    {
      style: { minWidth: hasDateTimeTag ? '16%' : '20%', width: hasDateTimeTag ? '16%' : '20%' },
      headerLabel: 'table-customization.selected-tag',
    },
    {
      style: { minWidth: hasDateTimeTag ? '16%' : '20%', width: hasDateTimeTag ? '16%' : '20%' },
      headerLabel: 'table-customization.column-header',
    },
    {
      style: { minWidth: '16%', width: '16%' },
      headerLabel: 'table-customization.column-width',
    },
    {
      style: { minWidth: '13%', width: '13%' },
      headerLabel: 'table-customization.symbol',
    },
    {
      style: { minWidth: hasDateTimeTag ? '7%' : '10%', width: hasDateTimeTag ? '7%' : '10%' },
      headerLabel: 'table-customization.show-symbol-side',
    },
    {
      style: { minWidth: '13%', width: '13%' },
      headerLabel: 'table-customization.decimal-digits',
    },
    hasDateTimeTag && {
      style: { minWidth: '11%', width: '11%' },
      headerLabel: 'create-widget-page.create-widget.step-four.date-time',
    },
  ]);

  useEffect(() => {
    let _columns;
    if (!columns || customization.yAxes) {
      getTableCustomizationData(
        widgetData,
        columns,
        previewData,
        setWidgetData,
        defaultDecimalDigits
      );
    } else if (
      columns.length &&
      !columns[0].tagType &&
      columns[0].tagType !== '' &&
      columns[0].valueType !== 'VARIABLE'
    ) {
      const unOrderedColumns = getTableCustomizationData(
        widgetData,
        columns,
        previewData,
        setWidgetData,
        true,
        defaultDecimalDigits
      );
      const tempColumns = updateColumns(unOrderedColumns, columns);
      _columns = tempColumns;
      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          columns: _columns,
        },
      }));
    }
    setTableColumns(columns || _columns);
    const tableTotalWidth = columns?.reduce(
      (total, current) => total + (current.width || 100 / columns.length),
      0
    );
    if (tableTotalWidth && +tableTotalWidth.toFixed(2) !== 100) {
      setWraning(tableTotalWidth > 100 ? 'more' : 'less');
    } else {
      setWraning('');
    }
  }, [columns]);

  useEffect(() => {
    const sub = dndService.dragEndSubscribe((result) => {
      const { source, destination } = result;
      if (!destination) return;
      const newIdx = destination.index;
      const prevIdx = source.index;
      let _tableColumns = cloneDeep(tableColumns);

      if (newIdx === prevIdx) return; // No need to reorder
      // else - reorder
      const headerToMove = _tableColumns[prevIdx];
      _tableColumns.splice(prevIdx, 1);
      _tableColumns.splice(newIdx, 0, headerToMove);
      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          columns: [..._tableColumns],
        },
      }));
      return setTableColumns([..._tableColumns]);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [tableColumns]);

  useEffect(() => {
    setDisplayNameHasError(columns?.some((col) => !isNameValid(col.displayName)));
  });

  return (
    <>
      {wraning !== '' && (
        <I18n
          element="div"
          className={classes.wraning}>{`table-customization.width-${wraning}-wraning`}</I18n>
      )}
      {displayNameHasError && (
        <I18n
          element="div"
          className={classes.wraning}>{`table-customization.name-length-error`}</I18n>
      )}
      <Droppable droppableId="tableColumns">
        {(provided) => (
          <div
            className={classes.tableContainer}
            {...provided.droppableProps}
            ref={provided.innerRef}>
            <div className={classes.tableHeader}>
              {columnCfg.map((header, idx) => (
                <div
                  title={header.headerLabel && i18nService.translate(header.headerLabel)}
                  key={`${idx}_${header.headerLabel}`}
                  className={classes.header}
                  style={header.style}>
                  {header.headerLabel && (
                    <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                  )}
                </div>
              ))}
            </div>
            {tableColumns &&
              tableColumns.map((column, idx) => (
                <Draggable
                  index={idx}
                  key={
                    column.operation
                      ? `${column.id}_${column.valueType}_${column.operation}`
                      : `${column.id}_${column.valueType}`
                  }
                  draggableId={
                    column.operation
                      ? `columns_customization_${column.id}_${column.valueType}_${column.operation}`
                      : `columns_customization_${column.id}_${column.valueType}`
                  }>
                  {(provided) => (
                    <div
                      key={idx}
                      className={classes.columnContainer}
                      ref={provided.innerRef}
                      {...provided.draggableProps}>
                      <TableCustomizationRow
                        widgetData={widgetData}
                        columnCfg={columnCfg}
                        column={column}
                        index={idx}
                        columnsLength={tableColumns.length}
                        isLast={idx + 1 == tableColumns.length}
                        provided={provided}
                        columnChanged={(editedColumn, index) =>
                          onColumnChanged(
                            editedColumn,
                            index,
                            tableColumns,
                            setWidgetData,
                            customization
                          )
                        }
                        defaultDecimalDigits={defaultDecimalDigits}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}

export default TableConfigColumns;
