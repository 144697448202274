import React from 'react';
import { i18nService } from '@core/i18n/I18nService';
import classNames from 'classnames';

function SystemRemark(props) {
  const { remarkText, style } = props;

  return (
    <div
      style={
        style
          ? { ...style, color: 'var(--systemFontSelected)' }
          : { height: '100%', width: '100%', paddingTop: '5px' }
      }
      className={classNames('ellipsis-overflow')}
      title={i18nService.translate(remarkText)}>
      {i18nService.translate(remarkText)}
    </div>
  );
}
export default SystemRemark;
