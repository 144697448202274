import { useEffect } from 'react';
import { dispatch } from '@src/redux/store';
import { removeErrorHandling } from '@src/redux/login';
import { useSelector } from '@src/redux/useSelector';
import { modalService } from '@core/modals/ModalService';
import { usePrevious } from '@core/hooks/usePrevious';
import { isEqual } from 'lodash';
import { authService } from '@core/auth/AuthService';
import { ErrorsModalsMap } from './useErrorHandlingModal.interface';

const errorsModalsMap: ErrorsModalsMap = {
  403: {
    type: 'confirm',
    config: {
      text: 'general.roles-update-error',
      showCloseBtn: true,
      headerText: 'general.roles-update-header',
      confirmText: 'general.confirm',
    },
    props: {
      disableBackdropClick: true,
    },
    onClose: () => {
      modalService.closeModals();
      return authService.logout();
    },
  },
};

export function useErrorHandlingModal() {
  const errorHandling = useSelector((state) => state.login.errorHandling);
  const prevErrorHandling = usePrevious(errorHandling);

  const openModal = async (modal) => {
    const res = await modalService.openModal(modal.type, modal.config, modal.props || {});
    dispatch(removeErrorHandling());
    modal.onClose(res);
  };
  useEffect(() => {
    if (errorHandling && !isEqual(errorHandling, prevErrorHandling)) {
      const modal = errorsModalsMap[errorHandling.status];
      modal && openModal(modal);
    }
  }, [errorHandling]);
  return null;
}
