import React, { useMemo, useState, useEffect } from 'react';

// Components
import Table from '@components/Table';
import TextField from '@components/Table/TableFields/TextField';

// services
import { useSelector } from '@redux/useSelector';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { makeStyles } from '@material-ui/styles';
import { compact } from 'lodash';
import I18n from '@components/I18n';
import Icon from '@components/Icon';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '50vh',
    minHeight: (props) => (props['isMobileView'] ? 'auto' : '400px'),
    minWidth: (props) => (props['isMobileView'] ? 'auto' : '400px'),
    width: (props) => (props['isMobileView'] ? 'calc(100vw - 64px)' : '50vw'),
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: '56px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
  table: {
    height: 'calc(100% - 56px)',
  },
}));

function AssetsLocationDetailsModal(props) {
  const { args, dismiss } = props;
  const { clusterLocations, navigateDashboard, widget } = args;

  const [address, setAddress] = useState('');

  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const classes = useStyles(Object.assign({}, props, { isMobileView: isMobileView }));
  const colunmsCfg = useMemo(
    () =>
      compact([
        {
          Component: TextField,
          label: 'map-widget.assets-datails-modal.table.name',
          field: 'assetName',
          style: { width: '20%' },
        },
        {
          Component: TextField,
          field: 'assetSerial',
          label: 'map-widget.assets-datails-modal.table.serial-number',
          style: { width: '20%' },
        },
        {
          Component: TextField,
          field: 'assetType',
          label: 'map-widget.assets-datails-modal.table.type',
          style: { width: '20%' },
        },
        {
          Component: TextField,
          field: 'owner',
          label: 'map-widget.assets-datails-modal.table.owner',
          style: { width: '20%' },
        },
        widget?.customization?.tagCaption && {
          Component: TextField,
          field: 'tagData',
          label: widget?.customization?.tagCaption,
          style: { width: '20%' },
        },
      ]),
    [widget]
  );
  const onRowClicked = (row, rowIndex) => {
    clusterLocations[rowIndex]['asset_id'] = clusterLocations[rowIndex]['assetId'];
    if (navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        widget,
        rawValues: row,
        navigateDashboard,
      });
      dismiss('navigated');
    }
  };

  useEffect(() => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: parseFloat(clusterLocations[0]?.lat),
          lng: parseFloat(clusterLocations[0]?.lon),
        },
      },
      (results, status) => {
        if (status == 'OK') {
          setAddress(results[0].formatted_address);
        }
      }
    );
  }, [clusterLocations]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n address={address}>map-widget.assets-datails-modal.modal-header</I18n>
        <Icon type="close" onClick={() => dismiss('close')} className={'pointer'}></Icon>
      </div>
      <div className={classes.table}>
        <Table
          hasPagination={false}
          data={clusterLocations}
          onRowClicked={onRowClicked}
          colunmsCfg={colunmsCfg}
        />
      </div>
    </div>
  );
}

export default AssetsLocationDetailsModal;
