import { RadialGaugeOptions, LinearGaugeOptions } from 'canvas-gauges';
import { cssVarsService } from '@core/CssVarsService';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { gaugeOptionsProps } from './Gauge.interface';
import { hasValue } from '@core/canvas/widget.utils';
import {
  LinearGaugeCustomization,
  RadialGaugeCustomization,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { numberFormatter } from '@core/utils';
import { getCustomizationValueFromResults } from '@pages/CreateWidgetPage/widgets.utils';

/**
 * Calculates the major ticks in the gauge.
 */
export function getMajorTicks({ majorStart, majorEnd, majorInterval }) {
  let min = majorStart ? +majorStart : 0,
    max = majorEnd ? +majorEnd : 0,
    interval = majorInterval ? +majorInterval : 0;

  // App crashes when majorInterval equals to 0.
  if (!interval) {
    // Takes the range between min and max major if it's a correct range, else - takes 1.
    const range = +majorEnd > +majorStart ? Math.abs(+majorEnd - +majorStart) : 0;
    interval = range ?? 1;
  }

  if (hasValue(min) && hasValue(max)) {
    const majorTicks = [min];
    let tick = min + interval;
    while (tick <= max) {
      tick = tick && +tick % 1 ? +numberFormatter(+tick, 2) : tick;
      majorTicks.push(tick);
      tick += interval;
    }
    if (majorTicks[majorTicks.length - 1] < max) {
      majorTicks.push(max);
    }
    return majorTicks;
  }
  return [];
}

export const getOptions = (props: gaugeOptionsProps): any => {
  if (props.isRadial) {
    return getRadialOptions(props);
  }
  return getLinearOptions(props);
};

export const getRadialOptions = (props: gaugeOptionsProps): RadialGaugeOptions => {
  const { customizationData, canvasEl, value } = props;

  const {
    zones,
    ticks,
    needleVisible,
    title,
    units,
    unitsColor,
    dataColor,
    valueColor,
    decimalDigits,
    startAngle,
    degreesSize,
    valueVisible,
  } = customizationData as RadialGaugeCustomization;

  return {
    renderTo: canvasEl.current,
    title,
    units,
    valueBox: valueVisible,
    valueBoxStroke: 0,
    colorValueBoxBackground: 'transparent',
    valueTextShadow: false,
    valueInt: 0,
    valueDec: +decimalDigits,
    value,
    valueText: numberFormatter(value, decimalDigits).toString(),
    minValue: +ticks.majorStart,
    maxValue: +ticks.majorEnd,
    needle: needleVisible,
    startAngle: +startAngle,
    ticksAngle: +degreesSize,
    colorTitle: dataColor,
    colorNumbers: ticks.valueColor,
    colorPlate: cssVarsService.vars.widgetsBackground,
    colorValueText: valueColor,
    colorUnits: unitsColor,
    majorTicks: getMajorTicks(ticks),
    colorMajorTicks: ticks.majorColor,
    highlights: getHighlightsFromZones(zones),
    minorTicks: +ticks.minorInterval ?? 1,
    borders: false,
    fontTitleSize: 25,
    fontUnitsSize: 25,
    fontValueSize: 25,
    fontNumbersSize: 16,
    borderShadowWidth: 0,
  };
};

export const getLinearOptions = (props: gaugeOptionsProps): LinearGaugeOptions => {
  const { customizationData, canvasEl, value } = props;

  const {
    zones,
    ticks,
    needleVisible,
    needleSide,
    needleSize,
    needleColor,
    backgroundColor,
    barWidth,
    title,
    units,
    unitsColor,
    dataColor,
    valueColor,
    decimalDigits,
    valueVisible,
  } = customizationData as LinearGaugeCustomization;

  return {
    renderTo: canvasEl.current,
    title,
    units,
    valueBox: valueVisible,
    valueBoxStroke: 0,
    colorValueBoxBackground: 'transparent',
    valueTextShadow: false,
    valueInt: 0,
    valueDec: +decimalDigits,
    value,
    valueText: numberFormatter(value, decimalDigits).toString(),
    minValue: +ticks.majorStart,
    maxValue: +ticks.majorEnd,
    needle: needleVisible,
    colorNeedle: needleColor,
    needleSide: needleSide.toLowerCase(),
    needleWidth: needleSize,
    barWidth,
    colorTitle: dataColor,
    colorNumbers: ticks.valueColor,
    colorPlate: backgroundColor,
    colorValueText: valueColor,
    colorUnits: unitsColor,
    majorTicks: getMajorTicks(ticks),
    numberSide: ticks.valueSide.toLowerCase(),
    tickSide: ticks.side.toLowerCase(),
    colorMajorTicks: ticks.majorColor,
    highlights: getHighlightsFromZones(zones),
    minorTicks: +ticks.minorInterval ?? 1,
    barBeginCircle: 20,
    fontNumbersSize: 12,
    fontTitleSize: 25,
    fontUnitsSize: 25,
    fontValueSize: 25,
    borders: false,
    borderShadowWidth: 0,
  };
};

const getHighlightsFromZones = (zones) =>
  zones
    .filter((zone) => hasValue(zone.minValue) && hasValue(zone.maxValue))
    .map((zone) => ({
      from: +zone.minValue,
      to: +zone.maxValue,
      color: zone.color,
    }));

export const onNavigateDashboard = (widget, value, data, navigateDashboard) => {
  if (navigateDashboard && widget.navigationDashboard) {
    navigationDataBuilder({
      selectedValue: value,
      selectedColumn: data.results && Object.values(data.results[0])[0],
      widget,
      columns: data.columns,
      rawValues: data.results[0],
      navigateDashboard,
    });
  }
};

export function convertGaugeCustomization(data: any, customization: any) {
  const ticks = ['majorStart', 'majorEnd', 'majorInterval', 'minorInterval'];

  return {
    ...customization,
    ticks: {
      ...customization.ticks,
      ...Object.fromEntries(
        ticks.map((tick) => {
          return customization.ticks[tick]?.valueId
            ? [
                tick,
                getCustomizationValueFromResults(
                  data,
                  customization.ticks[tick].valueId,
                  data.results[0]
                ),
              ]
            : [tick, customization.ticks[tick]];
        })
      ),
    },
    zones: customization.zones.map((zone) => {
      return {
        ...zone,
        minValue:
          zone.minValue != null
            ? isFinite(zone.minValue)
              ? zone.minValue
              : getCustomizationValueFromResults(data, zone.minValue.valueId, data.results[0])
            : null,
        maxValue:
          zone.maxValue != null
            ? isFinite(zone.maxValue)
              ? zone.maxValue
              : getCustomizationValueFromResults(data, zone.maxValue.valueId, data.results[0])
            : null,
      };
    }),
  };
}
