import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import MaterialCheckbox from '@components/Checkbox';
import NewLogo from '@components/NewLogo';

// Services | Interfaces
import { httpService } from '@core/http/HttpService';
import styles from './TermsAndConditionsPage.scss';
import { dispatch } from '@src/redux/store';
import { setUserStatus } from '@src/redux/login';
import { authService } from '@core/auth/AuthService';
import { useSelector } from '@redux/useSelector';

// 3rd party
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

function TermsAndConditionsPage(props: any) {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const { isLoggedIn, user_status } = useSelector((state) => state.login);
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [allowMarketingMaterials, setAllowMarketingMaterials] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn || user_status == 'ACTIVE') {
      history.push('/login');
    }
  }, [isLoggedIn, user_status]);

  const activateUser = () => {
    httpService
      .api({
        type: 'activateUserIfAcceptedTerms',
        data: { allowMarketingMaterials },
      })
      .then((res: any) => {
        dispatch(setUserStatus(res.user_status));
        history.push('/login');
      });
  };

  const cancel = () => {
    authService.logout();
  };

  return (
    <div className={classNames(!isMobileView && 'max-height')}>
      <div className={classNames(styles.logo, isMobileView && styles.mobileMode)}>
        <NewLogo />
      </div>
      <div className={classNames(styles.termsBody, isMobileView && styles.mobileMode)}>
        <Typography align="center" className={styles.termsHeader}>
          <I18n>terms-and-conditions.header</I18n>
        </Typography>
        <div className={styles.contentWrapper}>
          <iframe
            className={classNames(
              styles.iframe,
              isMobileView ? styles.iframeMobile : styles.iframeHeight
            )}
            src="https://unicloud-public-prod-eu-west-1.s3.amazonaws.com/terms_and_conditions/UnicloudTermsAndConditions.html"></iframe>
          <div className={styles.bottomWrapper}>
            <div className={styles.item}>
              <MaterialCheckbox
                color="primary"
                checked={acceptTermsAndConditions}
                onClick={() => setAcceptTermsAndConditions(!acceptTermsAndConditions)}
              />
              <I18n className={styles.cbLabel}>terms-and-conditions.accept</I18n>
            </div>
            <div className={styles.item}>
              <MaterialCheckbox
                color="primary"
                checked={allowMarketingMaterials}
                onClick={() => setAllowMarketingMaterials(!allowMarketingMaterials)}
              />
              <I18n className={styles.cbLabel}>terms-and-conditions.receive-marketing</I18n>
            </div>
            <div className={classNames(styles.buttons, isMobileView && styles.mobileMode)}>
              <Button
                styles={
                  isMobileView
                    ? { margin: '10px 14px', width: 120, height: 40, fontSize: '18px' }
                    : { margin: '20px 7px', width: 92, height: 28 }
                }
                mode={['cancel', 'bigFont']}
                onClick={cancel}>
                <I18n>general.cancel</I18n>
              </Button>
              <Button
                styles={
                  isMobileView
                    ? { margin: '10px 14px', width: 120, height: 40, fontSize: '18px' }
                    : { margin: '20px 7px', width: 92, height: 28 }
                }
                mode="bigFont"
                disabled={!acceptTermsAndConditions}
                onClick={activateUser}>
                <I18n>general.submit</I18n>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
