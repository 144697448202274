import React from 'react';
import Spinner from '@components/Spinner';
import styles from './PublishingModal.scss';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';

const hiddenS = { display: 'none' };

function PublishingModal({ publish, headerText, text }) {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  return (
    <div className={styles.wrapper} style={publish ? undefined : hiddenS}>
      <div className={styles.mask}>
        <div className={styles.container}>
          <div className={styles.modalHeader}>
            <I18n>{headerText}</I18n>
          </div>

          <div className={classNames(styles.content, isMobileView && styles.mobileView)}>
            <I18n noEllipsis element="div" className={styles.text}>
              {text}
            </I18n>
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PublishingModal);
