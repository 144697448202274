import { concat, compact, cloneDeep } from 'lodash';
import { tagFormatMap } from '@core/mapsAndDefinitions';
export const tableCfg = [
  {
    style: { paddingRight: '10px', minWidth: '35%', width: '35%' },
    headerLabel: 'create-widget-page.create-widget.step-three.multiply-action',
  },
  {
    style: { paddingRight: '10px', minWidth: '35%', width: '35%' },
    headerLabel: 'create-widget-page.create-widget.step-three.multiply-tag',
  },
  {
    style: { paddingRight: '10px', minWidth: '35%', width: '35%' },
    headerLabel: 'create-widget-page.create-widget.step-three.multiply-factor',
  },
];

export const changeWidgetCalculations = (updatedRow, index, setWidgetData) => {
  setWidgetData((prevState) => {
    //when coulmns includes multi types set dataLabels_show to false and shared_tooltip to true
    const data = cloneDeep(prevState.calculations || []);
    const rowIndex = data?.findIndex(
      (d) =>
        d.valueId === updatedRow.valueId &&
        d.valueType === updatedRow.valueType &&
        d.operation === updatedRow.operation
    );

    if (rowIndex > -1) {
      data[rowIndex] = updatedRow;
    } else {
      data[data.length] = updatedRow;
    }

    return {
      ...prevState,
      customization: null,
      calculations: data,
    };
  });
};

const getData = (calculationsObject, data, dataItem, widgetData) => {
  const dataObj =
    calculationsObject[dataItem.valueType || dataItem.tagType] &&
    calculationsObject[dataItem.valueType || dataItem.tagType][dataItem.valueId || dataItem.id];
  const isNumber = ['numberType', 'floatType'].includes(dataItem?.format || dataItem?.type);
  return {
    calculations: [
      ...data.calculations,
      isNumber && {
        valueType: dataItem.valueType || dataItem.tagType,
        valueId: dataItem.valueId || dataItem.id,
        action: 'NONE',
        actionValue: null,
        operation: null,
        ...(dataObj?.null || {}),
        order: data.counter,
      },
    ],
    counter: data.counter + (isNumber ? 1 : 0),
  };
};

export const buildOptions = (option, type, scope) => {
  const tagFormat = type === 'TAG' ? tagFormatMap[option.format] : tagFormatMap[option.type];
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
  };
};

export const getCalculationItemsInMultiply = (
  widgetData,
  setCalculationsItems,
  calculationsObject
) => {
  const { metrics, groupBy, assetProperties, tags, tagTypes, alarmInfos, scope } =
    widgetData as any;
  const dataArray =
    scope === 'RAW_DATA'
      ? concat(
          assetProperties.map((a) => buildOptions(a, 'ASSET_PROPERTY', scope)),
          tags.map((a) => buildOptions(a, 'TAG', scope)),
          tagTypes.map((a) => buildOptions(a, 'TAG_TYPE', scope)),
          alarmInfos.map((a) => buildOptions(a, 'ALARM_INFO', scope))
        )
      : concat(groupBy, metrics);
  const data = dataArray.reduce(
    (data: any, dataItem: any) => {
      return getData(calculationsObject, data, dataItem, widgetData);
    },
    { calculations: [], counter: 0 }
  );
  setCalculationsItems(compact(data.calculations));
};
