import React from 'react';

// Components
import RouterAssetsTable from './RouterAssetsTable';

// Services | Interfaces
import styles from './RouterAssetsMeta.scss';
import { RouterTabsProps } from '@modals/EditRouterModal/EditRouterModal.interface';

function RouterAssetsMeta(props: RouterTabsProps) {
  return (
    <>
      <div className={styles.content}>
        <RouterAssetsTable {...props} />
      </div>
    </>
  );
}

export default RouterAssetsMeta;
