export const colorMap = {
  CONNECTED_CRITICAL_ALARM: 'var(--statusCriticalAlarm)',
  CONNECTED_MAJOR_ALARM: 'var(--statusMajorAlarm)',
  COMMUNICATION_ERROR: 'var(--statusCommunicationError)',

  CONNECTED_WARNING: 'var(--statusConnectedWarning)',
  CONNECTED_MINOR_ALARM: 'var(--statusMinorAlarm)',

  DISCONNECTED: 'var(--statusDisconnected)',
  AVAILABLE: 'var(--statusAvailable)',
  CONNECTED_OK: 'var(--statusConnectedOk)',
  PENDING_ROUTER: 'var(--statusPendingRouter)',

  ARCHIVED: 'var(--statusArchived)',
  MISSINGPLC: 'var(--statusMissingPLC)',

  SUSPENDED: 'var(--statusCommunicationError)',
  LOCKED: 'var(--statusCommunicationError)',
  ACTIVE: 'var(--statusAvailable)',
  PRE_ACTIVATED: 'var(--statusArchived)',

  ABOUT_TO_EXPIRE: 'var(--statusAboutToExpaired)',
  EXPIRED_GRACE: 'var(--statusExpairedGrace)',
  EXPIRED: 'var(--statusExpaired)',

  RECIPIENTS_MISSING: 'var(--formikErrorBorder)',
  DRAFT: 'var(--statusArchived)',
};
