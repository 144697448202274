import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import MaterialCheckbox from '@components/Checkbox';

import styles from './TableWithCheckbox.scss';

const TableWithCheckbox = props => {
  const {
    selectProps: { tableConfig },
    data
  } = props;
  const keys = Object.keys(tableConfig(data));

  return (
    <components.Option {...props}>
      <div className={styles.tableRow}>
        <div style={{ display: 'inline-block' }}>
          <div style={{ display: 'flex' }}>
            <MaterialCheckbox color="primary" checked={props.isSelected} />
          </div>
        </div>
        <div className={styles.tableContent}>
          <div
            className={classNames(styles.tableColumn, 'ellipsis-overflow')}
            title={tableConfig(data)[keys[0]]}>
            {tableConfig(data)[keys[0]]}
          </div>
          <div
            className={classNames(styles.tableColumnMargin, 'ellipsis-overflow')}
            title={tableConfig(data)[keys[1]]}>
            {tableConfig(data)[keys[1]]}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

export default TableWithCheckbox;
