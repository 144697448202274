import React, { useState } from 'react';
import classNames from 'classnames';

// Style
import styles from './EditTimer.scss';

// Helper
import { save } from './EditTimer.utils';

// 3rd party
import 'moment-duration-format';
import { InputBase, InputAdornment } from '@material-ui/core';
import TimeField from 'react-simple-timefield';
import Icon from '@components/Icon';

function EditTimer(props) {
  const { onCancelEditClicked, duration, assetId, assetSubscription } = props;
  const moment = require('moment');
  const [timer, setTimer] = useState(duration);

  const onChangeTimer = (event, value) => {
    setTimer(value);
  };

  return (
    <div className={styles.editMode}>
      <TimeField
        value={timer}
        onChange={onChangeTimer}
        input={
          <InputBase
            endAdornment={
              <InputAdornment position="end">
                <Icon
                  type="remove"
                  className={classNames('pointer', styles.discardIcon)}
                  onClick={onCancelEditClicked}
                />
              </InputAdornment>
            }
            id={styles.inlineInput}
          />
        }
        showSeconds
      />
      <Icon
        disabled={timer === duration}
        type="save"
        className={classNames('pointer', styles.icon)}
        onClick={() => save(timer, moment, assetId, onCancelEditClicked, assetSubscription)}
      />
    </div>
  );
}

export default EditTimer;
