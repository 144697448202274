import React from 'react';
import styles from './FormikField.scss';
import I18n from '@components/I18n';
import FormikInput from '@components/Formik/FormikInput';
import FormikSelect from '@components/Formik/FormikSelect';
import ColorInput from '@components/ColorInput';
import GooglePlacesInput from '@components/GooglePlacesInput';
import MaterialCheckbox from '@components/Checkbox';
import { colorMap } from '@core/statusColorMap';
import classNames from 'classnames';
import TimezoneSelect from '@components/TimezoneSelect';
import MultiSelect from '@components/MultiSelect';
import Icon from '@components/Icon';
import RemoteAccessButton from '@components/RemoteAccessButton';
import Button from '@components/Button';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { i18nService } from '@core/i18n/I18nService';
import { FormControlLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import BasicDatePicker from '@components/BasicDatePicker';
import { buildDateTime } from '@components/widgets/charts.utils';
import FormikLabel from '@components/Formik/FormikLabel';
import { onChange } from '@modals/AssignTagTypeModal/assignTagTypeModal.utils';

const useStyles = makeStyles({
  radioButtoGroup: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
});

const FieldBuider = ({
  name,
  label,
  values,
  type,
  options,
  value,
  nullSubstitutionValue,
  isRequired,
  setFieldValue,
  onLostFocus,
  placeholder,
  onSelectChange,
  maxMenuHeight,
  smallPadding,
  smallResolution,
  mode,
  ...restProps
}): any | undefined => {
  isRequired = typeof isRequired === 'function' ? isRequired(values) : isRequired;
  const classes = useStyles({});

  switch (type) {
    case 'label':
      return (
        <FormikLabel
          className={classNames(
            styles.inputRow,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}
          name={name}
          mode={mode}
          label={label}
          value={value}
          {...restProps}
        />
      );
    case 'input':
      return (
        <FormikInput
          className={classNames(
            styles.inputRow,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}
          name={name}
          mode={mode}
          label={label}
          value={value}
          isRequired={isRequired}
          onLostFocus={onLostFocus}
          {...restProps}
        />
      );
    case 'select':
      const tempValue =
        value && typeof value !== 'object' && options
          ? options.find((opt) => opt.value === value)
          : value;
      return (
        <FormikSelect
          className={classNames(
            styles.inputRow,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}
          selectStyles={
            mode
              ? {
                  menuPortal: { zIndex: 9999999999999 },
                  control: {
                    height: smallResolution
                      ? 32
                      : mode === 'New'
                      ? 40
                      : mode === 'NewThin'
                      ? 37
                      : 28,
                    width:
                      smallResolution && mode === 'New'
                        ? 217
                        : ['NewThin', 'New'].includes(mode)
                        ? 237
                        : 198,
                    border: `solid 1px var(--systemFontSelected)`,
                  },
                  singleValue: { fontSize: 14 },
                }
              : { menuPortal: { zIndex: 9999999999999 } }
          }
          name={name}
          options={options}
          maxMenuHeight={maxMenuHeight}
          label={label}
          defaultValue={tempValue}
          isRequired={isRequired}
          placeholder={placeholder || ''}
          onSelectChange={onSelectChange}
          {...{ ...restProps, mode }}
        />
      );
    case 'color':
      return <ColorInput name={name} label={label} value={value} />;
    case 'googlePlacesInput':
      return (
        <div className={styles.location}>
          <label
            className={classNames(
              styles.dataLabel,
              isRequired && 'asterisk',
              isRequired && styles.isRequired,
              mode === 'NewMobile' && styles.mobileMode,
              'ellipsis-overflow'
            )}>
            <I18n>{label}</I18n>:
          </label>
          <div className={styles.googleInput}>
            <GooglePlacesInput
              id="companyAddress"
              mode="thin"
              defaultValue={value}
              onSelectedCountryChange={(location) => setFieldValue && setFieldValue(name, location)}
              isMulti={false}
              placeholder={placeholder || ''}
              coordinates={true}
              styles={
                mode
                  ? {
                      control: {
                        height: smallResolution
                          ? 32
                          : mode === 'New'
                          ? 40
                          : mode === 'NewThin'
                          ? 37
                          : 28,
                        width:
                          smallResolution && mode === 'New'
                            ? 217
                            : ['NewThin', 'New'].includes(mode)
                            ? 237
                            : 198,
                        border: `solid 1px var(--systemFontSelected)`,
                      },
                      placeholder: { fontSize: 14 },
                    }
                  : {}
              }
            />
          </div>
        </div>
      );
    case 'multiSelect':
      return (
        <div className={styles.multi}>
          <label
            className={classNames(
              styles.dataLabel,
              isRequired && 'asterisk',
              isRequired && styles.isRequired,
              mode === 'NewMobile' && styles.mobileMode,
              'ellipsis-overflow'
            )}>
            <I18n>{label}</I18n>:
          </label>
          <div className={styles.multiSelect}>
            <MultiSelect
              id={name}
              options={options}
              values={value}
              placeholder={placeholder || ''}
              onChange={(values) => setFieldValue && setFieldValue(name, values)}
              maxMenuHeight={maxMenuHeight || 380}
              {...restProps}
            />
          </div>
        </div>
      );
    case 'checkbox':
      return (
        <div
          className={classNames(
            styles.inputRow,
            styles.generic,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}>
          <label
            className={classNames(styles.dataLabel, styles.checkboxLabel, 'ellipsis-overflow')}
            style={restProps.labelStyle}>
            <I18n>{label}</I18n>:
          </label>
          <MaterialCheckbox
            className={styles.checkBoxIcon}
            color="primary"
            onChange={(e) => setFieldValue && setFieldValue(name, e.target.checked)}
            checked={value}
          />
        </div>
      );
    case 'timezone':
      return <TimezoneSelect defaultValue={value}></TimezoneSelect>;
    case 'phoneInput':
      return (
        <div
          className={classNames(
            styles.generic,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}>
          <label
            className={classNames(
              styles.dataLabel,
              mode === 'NewMobile' && styles.mobileMode,
              'ellipsis-overflow'
            )}>
            <I18n>{label}</I18n>:
          </label>
          <div style={{ height: '28px' }}>
            <PhoneNumberInput
              values={values}
              fieldName={name}
              width={174}
              value={value}
              onChange={(value) =>
                setFieldValue && value !== ''
                  ? setFieldValue(name, `+${value}`)
                  : setFieldValue(name, value)
              }
            />
          </div>
        </div>
      );
    case 'radioGroup':
      return (
        <div
          className={classNames(
            styles.generic,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}>
          <label
            className={classNames(styles.dataLabel, styles.checkboxLabel, 'ellipsis-overflow')}>
            <I18n>{label}</I18n>:
          </label>
          <RadioGroup
            value={
              values.value != null && values.value != ''
                ? values.value
                : restProps.defaultValue != null
                ? restProps.defaultValue
                : ''
            }
            className={classNames(classes.radioButtoGroup)}
            onChange={(e, checked) => {
              setFieldValue(name, Number.parseInt(checked));
            }}>
            {restProps.radioGroupOptions.map((opt) => (
              <FormControlLabel
                key={opt.label}
                classes={{ label: styles.radioLabel }}
                value={opt.value}
                control={<Radio />}
                label={i18nService.translate(opt.label)}
              />
            ))}
          </RadioGroup>
        </div>
      );
    case 'datetime':
      return (
        <div
          key="dateTime"
          className={classNames(
            styles.generic,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}>
          <label
            className={classNames(styles.dataLabel, styles.date, 'ellipsis-overflow')}
            style={restProps.labelStyle}>
            <I18n>{label}</I18n>:
          </label>
          <BasicDatePicker
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            allowEmptyDefaultValue={true}
            selectedChanged={(value) =>
              setFieldValue(
                name,
                buildDateTime(value, restProps.dateTimeFormat, 'momentFormat', restProps.timezone)
              )
            }
            value={values[name]}
            timePicker={restProps.timePicker}
            height={25}
            dateWrapperStyle={restProps.dateWrapperStyle}
            buildFormat={(val) =>
              buildDateTime(val, restProps.dateTimeFormat, 'momentFormat', restProps.timezone)
            }
          />
        </div>
      );
    case 'date':
      return (
        <div
          key="date"
          className={classNames(
            styles.generic,
            smallPadding && styles.smallPadding,
            smallResolution && styles.smallResolution
          )}>
          <label
            className={classNames(styles.dataLabel, styles.date, 'ellipsis-overflow')}
            style={restProps.labelStyle}>
            <I18n>{label}</I18n>:
          </label>
          <BasicDatePicker
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            allowEmptyDefaultValue={true}
            selectedChanged={(value) =>
              setFieldValue(
                name,
                buildDateTime(value, restProps.dateTimeFormat, 'momentFormat', restProps.timezone)
              )
            }
            value={values[name]}
            timePicker={false}
            height={25}
            dateWrapperStyle={restProps.dateWrapperStyle}
            buildFormat={(val) =>
              buildDateTime(val, restProps.dateTimeFormat, 'momentFormat', restProps.timezone)
            }
          />
        </div>
      );
  }
};

const getValueLabel = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value.map((v) => `${v.label} `);
    } else if (typeof value === 'object') {
      return value.label;
    }
  }

  return value;
};

const getValue = (fieldValue, restProps) => {
  return fieldValue && typeof fieldValue !== 'object' && restProps.options
    ? restProps.options.find((opt) => opt.value === fieldValue)
    : fieldValue && restProps.type === 'googlePlacesInput'
    ? fieldValue.formatted
    : fieldValue;
};

const FieldView = ({
  enumValue,
  value,
  showVertificationStatus,
  mobileVerified,
  allowMobileValidation,
  isUnassigned = true,
  restProps,
}) => {
  return (
    <div
      className={styles.dataRow}
      style={
        restProps?.fieldViewStyle
          ? { ...restProps.fieldViewStyle, minWidth: allowMobileValidation ? 400 : 440 }
          : { minWidth: allowMobileValidation ? 400 : 440 }
      }>
      <label
        className={classNames(
          styles.dataLabel,
          'ellipsis-overflow',
          restProps.type === 'checkbox' && styles.checkboxLabel,
          restProps.labelClassName || ''
        )}
        style={restProps.labelStyle}>
        <I18n>{restProps.label}</I18n>:
      </label>
      {enumValue && value ? (
        <I18n
          style={{
            color: colorMap[value],
            paddingLeft: '11px',
          }}>{`${enumValue}.${value}`}</I18n>
      ) : restProps.type === 'checkbox' ? (
        <MaterialCheckbox
          className={styles.checkBoxIcon}
          color="primary"
          disabled
          checked={value || false}
        />
      ) : restProps.type === 'booleanVal' ? (
        <span
          className={classNames(
            styles.dataValue,
            'ellipsis-overflow',
            restProps.inputClassName || ''
          )}
          title={getValueLabel(restProps.name)}>
          <I18n>{restProps.name}</I18n>
        </span>
      ) : showVertificationStatus && !!value ? (
        <div style={{ display: 'flex' }}>
          {mobileVerified ? <Icon type="validation_succeded" /> : <Icon type="validation_failed" />}
          <span
            className={classNames(
              styles.dataValue,
              'ellipsis-overflow',
              restProps.inputClassName || ''
            )}
            title={getValueLabel(value)}>
            {getValueLabel(value)}
          </span>
          {!isUnassigned && allowMobileValidation && !mobileVerified && (
            <Button
              onClick={allowMobileValidation}
              mode="outlined"
              styles={{ minWidth: 82, width: 82, marginLeft: 10, marginRight: 35 }}>
              <I18n>{'mobile-number-validation.validate'}</I18n>
            </Button>
          )}
        </div>
      ) : restProps.type === 'icon' ? (
        <span>
          <Icon
            type={restProps.iconType}
            color={restProps.iconColor ? restProps.iconColor : 'var(--systemFont)'}
            tooltipText={
              restProps.iconTooltip ? i18nService.translate(`${restProps.iconTooltip}`) : null
            }
          />
        </span>
      ) : (
        <span
          className={classNames(
            styles.dataValue,
            'ellipsis-overflow',
            restProps.inputClassName || ''
          )}
          title={getValueLabel(value)}>
          {getValueLabel(value)}
        </span>
      )}
    </div>
  );
};

function FormikField(props) {
  const {
    editMode,
    values,
    setFieldValue,
    nullSubstitutionValue,
    editable = () => true,
    enumValue = false,
    showVertificationStatus = false,
    mobileVerified = false,
    allowMobileValidation,
    isUnassigned = true,
    ...restProps
  } = props;
  const fieldValue = values[restProps.name];
  const value = getValue(fieldValue, restProps);

  const fieldCondition = restProps.condition ? restProps.condition(values) : true;

  return fieldCondition ? (
    editMode && editable && editable(values) ? (
      <FieldBuider
        {...restProps}
        value={
          !fieldValue && nullSubstitutionValue
            ? { value: null, label: nullSubstitutionValue }
            : fieldValue
        }
        nullSubstitutionValue={nullSubstitutionValue}
        values={values}
        setFieldValue={setFieldValue}
      />
    ) : (
      <FieldView
        enumValue={enumValue}
        restProps={restProps}
        value={!value && nullSubstitutionValue ? nullSubstitutionValue : value}
        showVertificationStatus={showVertificationStatus}
        mobileVerified={mobileVerified}
        allowMobileValidation={allowMobileValidation}
        isUnassigned={isUnassigned}
      />
    )
  ) : null;
}

export default FormikField;
