import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { template } from 'lodash';
import { useSelector } from '@redux/useSelector';
import { modalService } from '@core/modals/ModalService';
import ToolBar from '@components/ToolBar';
import VariablesTable from '../VariablesTable/VariablesTable';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { getVariables } from '../VariablesTable/VariablesTable.utils';
import { httpService } from '@core/http/HttpService';
import { apiMap } from '@core/http/apiMap';
import { PAGE_SIZE } from '../Variables.utils';

const apiType = 'getAssetTypeVariablesByOrg';

function AssetTypeVariablesTab({ id, hasPermission }) {
  const machineBuilderId = useSelector((state) => state.config.organizationDetails.id);
  const [variablesList, setVariablesList] = useState([]);
  const [editedVariableId, setEditedVariableId] = useState<number>(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);
  const [searchText, setSearchText] = useState('');
  const search = (searchText) => {
    setSearchText(searchText);
  };

  const [_order, setOrder] = useState(null);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);

  useEffect(() => {
    if (machineBuilderId) {
      getVariables(
        machineBuilderId,
        nextPage,
        setNextPage,
        setResultsCount,
        setTotalCount,
        apiType,
        searchText,
        PAGE_SIZE,
        setDisableLoad,
        true,
        setVariablesList,
        _order
      );
    }
  }, [searchText, machineBuilderId]);

  const getLastSearches = () => {
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap[apiType].url)({ machineBuilderId }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches);
      });
  };

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'system properties',
    },
  };

  function openEditAssetTypeModal(rowData, rowIdx) {
    modalService
      .openModal('variableValuesPerAssetModal', {
        rowData,
        rowIdx,
        id,
        editedVariableId,
        setEditedVariableId,
        variablesList,
      })
      .then((res) => {});
  }

  return (
    <div className={styles.content}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <VariablesTable
        variablesList={variablesList}
        origin={'organizationAssetTypes'}
        setVariablesList={setVariablesList}
        selectedOrganizationsId={id}
        editedVariableId={editedVariableId}
        setEditedVariableId={setEditedVariableId}
        onRowClicked={(rowData, rowIdx) => {
          setEditedVariableId(null);
          openEditAssetTypeModal(rowData, rowIdx);
        }}
        apiType={apiType}
        machineBuilderId={machineBuilderId}
        setResultsCount={setResultsCount}
        setTotalCount={setTotalCount}
        searchText={searchText}
        disabledLoad={disabledLoad}
        setDisableLoad={setDisableLoad}
      />
    </div>
  );
}
export default AssetTypeVariablesTab;
