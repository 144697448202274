import React from 'react';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { makeStyles } from '@material-ui/core';
import { i18nService } from '@core/i18n/I18nService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    width: 'fit-content',
  },
  edit: {
    padding: '0px 10px',
    fontSize: '14px',
  },
}));

function LanguageEdit(props) {
  const { onEditClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper} onClick={() => onEditClick(props)}>
      <Icon
        type="edit"
        color="var(--systemFont)"
        tooltipText={i18nService.translate('languages.edit-language-button')}
      />
      <I18n className={classes.edit}>languages.edit-language-button</I18n>
    </div>
  );
}

export default LanguageEdit;
