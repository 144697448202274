import React, { useEffect } from 'react';
import {
  VncCustomization,
  WebCustomization,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import styles from './Web.scss';
import classNames from 'classnames';
import { TextField, makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import MaterialCheckbox from '@components/Checkbox';
import { i18nService } from '@core/i18n/I18nService';
import ColorInput from '@components/ColorInput';
import { cloneDeep } from 'lodash';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 7px',
  },
  textField: {
    width: 'calc(100% - 215px)',
  },
  caption: {
    fontSize: '14px',
    width: '140px',
    marginRight: '10px',
    '&.asterisk': {
      marginLeft: '-7px',
      width: '147px',
    },
  },
  colorItem: {
    margin: '13px 0 13px 7px',
  },
}));

const Vnc = (props) => {
  const { widgetData, setWidgetData } = props;
  const { customization } = widgetData;

  const { enabledButtonColor, buttonText, buttonTextColor } =
    (customization as WebCustomization) || {};

  const classes = useStyles(props);

  useEffect(() => {
    !customization &&
      updateWidgetCustomization(
        {
          enabledButtonColor: '#8CBBE9',
          buttonText: i18nService.translate(
            'create-widget-page.create-widget.step-four.connect-button-text'
          ),
          buttonTextColor: '#3B406E',
        },
        setWidgetData
      );
  }, []);

  function setCustomizationData(data, key) {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  }

  return (
    <div className={styles.widgetValue}>
      <div className={styles.titleWrapper}>
        <I18n>create-widget-page.create-widget.step-four.connect-button</I18n>
      </div>
      <div className={classes.item}>
        <div className={classNames(classes.caption, 'asterisk')}>
          <I18n noEllipsis={true}>create-widget-page.create-widget.step-four.button-text</I18n>
        </div>
        <TextField
          className={classes.textField}
          value={buttonText || ''}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'buttonText');
          }}
          inputProps={{
            maxLength: 20,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <div className={classes.caption}>
          <I18n noEllipsis={true}>
            create-widget-page.create-widget.step-four.button-text-color
          </I18n>
        </div>
        <ColorInput
          value={buttonTextColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'buttonTextColor')}
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <div className={classes.caption}>
          <I18n noEllipsis={true}>create-widget-page.create-widget.step-four.button-color</I18n>
        </div>
        <ColorInput
          value={enabledButtonColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'enabledButtonColor')}
        />
      </div>
    </div>
  );
};

export default Vnc;
