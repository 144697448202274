import React from 'react';
import moment from 'moment';
import { ClusterInfo } from './ClusterInfo.interface';
import { makeStyles } from '@material-ui/styles';
import { onNavigateToDashboard } from '../MapWidget.utils';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { dispatch } from '@redux/store';
import { setModal } from '@redux/widgetModals';
import {
  numberFormatter,
  dateTimeRegex,
  dateRegex,
  convertBooleanToTranslatedString,
} from '@core/utils';
import { buildDateTime } from '@components/widgets/charts.utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '200px',
    top: 'calc(50% - 85px)',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    border: '1px solid black',
    background: 'white',
    borderRadius: '5px',
  },
  assetsList: {
    padding: '15px 8px 8px 10px',
    height: 'calc(100% - 35px)',
  },
  listItem: {
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 600,
    },
  },
  assetsLink: {
    marginLeft: '15px',
    color: 'var(--systemButtonBackground)',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  close: {
    position: 'absolute',
    right: '6px',
    top: '-3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function ClasterInfo(props: ClusterInfo) {
  const {
    setIsInfoWindowOpen,
    clusterLocations,
    widget,
    navigateDashboard,
    navigationDataBuilder,
    customization,
  } = props;
  const classes = useStyles(props);

  const getTagValue = (tagValue) => {
    if (tagValue != null) {
      if (
        tagValue &&
        typeof tagValue === 'string' &&
        (dateTimeRegex.test(tagValue.split('.')[0]) || dateRegex.test(tagValue.split('.')[0]))
      ) {
        return buildDateTime(tagValue, customization?.dateTimeFormat, 'momentFormat');
      }
      if (typeof tagValue === 'boolean') {
        return convertBooleanToTranslatedString(tagValue);
      }
      if (!isNaN(Number(tagValue))) {
        return numberFormatter(tagValue, 2);
      }
      return tagValue;
    }
    return undefined;
  };

  const openAssetsLocationDetails = () => {
    dispatch(
      setModal({
        type: 'assetsLocationDetails',
        args: {
          clusterLocations: clusterLocations.map((location) => {
            const tagValue =
              location?.tagData && location?.tagData[0]?.value != null
                ? location?.tagData[0]?.value
                : null;
            return {
              ...location,
              tagData: getTagValue(tagValue),
            };
          }),
          widget,
          navigateDashboard: navigateDashboard,
        },
      })
    );
  };

  return (
    <div className={classes.wrapper}>
      <div onClick={() => setIsInfoWindowOpen(false)} className={classes.close}>
        x
      </div>
      <div className={classes.assetsList}>
        {clusterLocations.map(
          (location, idx) =>
            idx <= 5 && (
              <div
                key={idx}
                className={classNames(classes.listItem, 'ellipsis-overflow')}
                onClick={() =>
                  onNavigateToDashboard(
                    location.assetId,
                    widget,
                    navigateDashboard,
                    navigationDataBuilder
                  )
                }
                title={location.assetName}>
                {location.assetName}
              </div>
            )
        )}
      </div>
      <div className={classes.assetsLink} onClick={() => openAssetsLocationDetails()}>
        <I18n>{`map-widget.cluster-window-info.${
          clusterLocations.length <= 6 ? 'view-assets-details' : 'view-all-assets'
        }`}</I18n>
      </div>
    </div>
  );
}

export default ClasterInfo;
