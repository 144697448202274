import React from 'react';
import { CardMedia } from '@material-ui/core';
import LogoIcon from '@components/LogoIcon';
import classNames from 'classnames';
import styles from '@pages/MainPage/MainPage.scss';

const DashboardReportHeader = (props: any) => {
  const { logoUrl, buildImageUrl, orgName } = props;

  return (
    <div style={{ display: 'flex' }}>
      <CardMedia className={classNames(styles.logo)}>
        <LogoIcon
          logo={logoUrl ? buildImageUrl(logoUrl) : '/assets/images/uni_logo.jpg'}
          width={150}
          height={25}
        />
      </CardMedia>
      <div className={classNames(styles.saparate)} />
      <div
        className={classNames(styles.orgName, 'ellipsis-overflow')}
        style={{
          textAlign: 'center',
          position: 'absolute',
          width: '100%',
          fontSize: '1.5em',
        }}>
        {orgName}
      </div>
    </div>
  );
};

export default DashboardReportHeader;
