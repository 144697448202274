import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from '@src/redux/useSelector';

const DateTime = (props) => {
  const { timeZone } = props;

  const dateTimeDetails = useSelector((state) => state.config.whiteLabelDetails.dateTime.content);

  const dateTime = timeZone
    ? moment()
        .tz(timeZone)
        .format(`${dateTimeDetails.longDate.format}, ${dateTimeDetails.timeFormat1.format}`)
    : moment().format(`${dateTimeDetails.longDate.format}, ${dateTimeDetails.timeFormat1.format}`);

  return <div>{dateTime}</div>;
};

export default DateTime;
