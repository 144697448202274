import React from 'react';
import { VariableMeta } from '@http/server.interface.d';

import { i18nService } from '@core/i18n/I18nService';
import { valueTypeOptions } from '../../Variables.utils';

function VariableValue(props: VariableMeta) {
  const { valueType } = props;
  const valueTypeToDisplay = i18nService.translate(
    valueTypeOptions.find((option) => option.value === valueType)?.label
  );
  return <div title={valueTypeToDisplay}>{valueTypeToDisplay}</div>;
}

export default VariableValue;
