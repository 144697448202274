import React from 'react';
import ReactApexChart from 'react-apexcharts';
import styles from './PieWidget.scss';
import { PieWidgetProps, PieDisplayType } from './PieWidget.interface';
import { getXaxisCategories } from '../charts.utils';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import * as isEqual from 'lodash/isEqual';
import { getDefaultWidgetColorPalette } from '@core/canvas/widget.utils';
import { connect } from 'react-redux';
import { numberFormatter } from '@core/utils';

class PieWidget extends React.Component<PieWidgetProps, any> {
  state = {
    options: {},
    series: [],
  };

  getValuesFormatter(val, decimalDigits = 0) {
    return numberFormatter(val, decimalDigits);
  }

  getTotalFormatter(w, decimalDigits) {
    const formatted = w.globals.seriesTotals.reduce((a, b) => {
      return a + b;
    }, 0);
    return formatted ? numberFormatter(formatted, decimalDigits) : formatted;
  }

  updateOptions(legendVisible = true) {
    const {
      data = {},
      customization,
      navigateDashboard,
      widget,
      defaultDecimalDigits,
      timezone,
    } = this.props;

    const { groupByColumns = [], metricsColumns = [], results = [] } = data;
    const {
      showAs = PieDisplayType.pie,
      textColor = 'var(--widgetsFont)',
      subtitle_text,
      subtitle_textColor = 'var(--widgetsFont)',
      subtitle_alignment = 'CENTER',
      legend_visible = true,
      legend_position = 'BOTTOM',
      legend_alignment = 'CENTER',
      tooltip_visible = true,
      tooltip_showLegend = true,
      dataLabels_visible = true,
      dataLabels_textColor = 'var(--widgetsFont)',
      showValues_visible = true,
      showValues_decimalDigits = defaultDecimalDigits,
      totalAndValuesColor = 'var(--widgetsFont)',
      colorPalette = getDefaultWidgetColorPalette(),
      dateTimeFormat,
    } = customization;

    const labels = getXaxisCategories(results, groupByColumns, dateTimeFormat, timezone);

    const options = {
      labels,
      colors: colorPalette,
      subtitle: {
        text: subtitle_text || '',
        align: subtitle_alignment.toLowerCase(),
        margin: subtitle_text && subtitle_alignment === 'CENTER' ? 30 : 10,
        style: {
          color: subtitle_textColor,
        },
      },
      legend: {
        show: legendVisible,
        position: legend_position.toLowerCase(),
        horizontalAlign: legend_alignment.toLowerCase(),
        labels: {
          colors: textColor,
          useSeriesColors: false,
        },
        markers: {
          shape: 'circle',
          size: 5,
          radius: 2,
          strokeWidth: 0,
          offsetX: -2,
          offsetY: 0,
        },
      },
      tooltip: {
        enabled: tooltip_visible,
        theme: 'apexchartsTooltip',
        y: {
          formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
            return this.getValuesFormatter(value, showValues_decimalDigits);
          },
        },
      },
      dataLabels: {
        enabled: dataLabels_visible,
        formatter: (val) => this.getValuesFormatter(val) + '%',
        style: {
          colors: [dataLabels_textColor],
          fontSize: '12px',
          fontWeight: 500,
        },
        dropShadow: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: showAs.toLocaleLowerCase() === PieDisplayType.donut,
              name: {
                show: showValues_visible,
                color: totalAndValuesColor,
              },
              value: {
                show: showValues_visible,
                color: totalAndValuesColor,
                formatter: (val) => this.getValuesFormatter(val, showValues_decimalDigits),
              },
              total: {
                show: showValues_visible,
                color: totalAndValuesColor,
                formatter: (w) => this.getTotalFormatter(w, showValues_decimalDigits),
              },
            },
          },
        },
      },
      chart: {
        foreColor: textColor,
        events: {
          dataPointSelection: (event, chartContext, config) =>
            this.onDataPointSelect(event, chartContext, config),
          dataPointMouseEnter: (e) => {
            e.target.style.cursor =
              navigateDashboard && widget?.navigationDashboard?.length && 'pointer';
          },
          dataPointMouseLeave: (e) => {
            e.target.style.cursor = 'auto';
          },
        },
      },
    };
    this.setState({ options });
  }

  onDataPointSelect(event, chartContext, config) {
    const { data = {}, widget, navigateDashboard } = this.props;
    const { groupByColumns = [], metricsColumns = [], results = [] } = data;
    if (event && navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: results[config.dataPointIndex][metricsColumns[0]],
        selectedColumn: metricsColumns[0],
        widget,
        columns: data.columns,
        rawValues: results[config.dataPointIndex],
        navigateDashboard,
      });
      event.stopPropagation();
    }
  }

  updateSeries() {
    const { data = {} } = this.props;
    const { metricsColumns = [], results = [] } = data;

    const series = metricsColumns
      .filter((x) => !x.hidden)
      .reduce((data, metric) => {
        return [...data, ...results.map((data) => data[metric.name] || 0)];
      }, []);

    this.setState({ series });
  }

  componentDidMount() {
    const { customization, timezone } = this.props;

    this.updateOptions(customization.legend_visible);
    this.updateSeries();
  }

  componentDidUpdate(prevProps, prevState) {
    const { data = {}, customization } = this.props;

    if (
      customization &&
      prevProps.customization &&
      !isEqual(customization, prevProps.customization)
    ) {
      // Hack for updating the component,
      // when dataLabels is changed the component has a render bug, and it's not been rendered
      this.updateOptions(!customization.legend_visible);
      setTimeout(() => this.updateOptions(customization.legend_visible));
    }

    if (data && prevProps.data && !isEqual(data, prevProps.data)) {
      this.updateSeries();
      this.updateOptions();
    }
  }

  render() {
    const { data = {}, customization } = this.props;
    const { showAs = PieDisplayType.pie } = customization;
    return (
      <div id={styles.apexChart}>
        {!this.props.apexLangChange && (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type={showAs.toLocaleLowerCase() as PieDisplayType}
            height="98%"
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({ apexLangChange: state.config.apexLangChange }),
  null
)(PieWidget);
