import React from 'react';
import Select from '@components/Select';
import { options } from './TimezoneOptions';

function TimezoneSelect(props) {
  const { defaultValue } = props;

  const timezoneChanged = e => {
    console.log('timezoneChanged', e);
  };

  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      getOptionLabel={option => option.display}
      getOptionValue={option => option}
      onChange={timezoneChanged}
    />
  );
}

export default TimezoneSelect;
