import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { dispatch } from '@src/redux/store';
import { setSelectedOrganization } from '@src/redux/organizations/organizations.actions';

export const getOrganizationDetailsById = (organizationId, selectedMBId) => {
  httpService
    .api({
      type: 'getOrganizationDetails',
      urlParams: { organizationId },
    })
    .then((res: any) => {
      let newRes = res;
      if (res?.address?.formatted === 'Unknown') {
        newRes = {
          ...res,
          address: {
            ...res.address,
            formatted: i18nService.translate('details.company-address.unknown'),
          },
        };
      }
      dispatch(
        setSelectedOrganization({
          ...newRes,
          selectedMBId,
          selectedOrganizationsId: organizationId,
        })
      );
    })
    .catch(() => {
      dispatch(setSelectedOrganization({ selectedMBId, selectedOrganizationsId: organizationId }));
    });
};
