import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { usePrevious } from '@core/hooks/usePrevious';
import { creationStageMap } from '@pages/CreateWidgetPage/widgets.utils';
import { widgetMap } from '@core/canvas/widgetMap';
import { widgetStepValidationMap } from '@pages/CreateWidgetPage/widgetStepValidation/widgetStepValidationMap';
import { makeStyles } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import { onChangeWidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.utils';
import { httpService } from '@core/http/HttpService';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import WidgetSideBar from '@pages/CreateWidgetPage/WidgetSideBar';
import DataStepComponent from '@pages/CreateWidgetPage/DataStepComponent';
import WidgetFooter from '@pages/CreateWidgetPage/WidgetFooter';
import PreviewSection from '@pages/CreateWidgetPage/PreviewSection';
import { createDataSource, cancel, onNavigateFromPage } from './CreateDataSource.utils';

const useStyles = makeStyles((theme) => ({
  createDataSourcePage: {
    display: 'flex',
    height: '100%',
  },
  dataSourceBody: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: 'var(--systemScreenBackground)',
    height: '100%',
    flexDirection: 'column',
  },
  dataSourceDataPreviewContainer: {
    padding: '30px 30px 0 30px',
    flexGrow: 1,
    height: 'calc(100% - 68px)',
    overflowY: 'auto',
  },
  dataSourceDataPreviewBody: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
  },

  widgetPreviewContainer: {
    paddingLeft: 26,
    width: '50%',
  },
}));

function CreateDataSource(props) {
  const { templateId, setIsAddingDataSource, initialDataSourceData, setShowFooter } = props;
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [widgetData, setWidgetData] = useState(initialDataSourceData);
  const [initialData, setInitialData] = useState(initialDataSourceData);
  const [steps, setSteps] = useState([]);
  const [enableSaveAsDraft, setEnableSaveAsDraft] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [isNameValid, setIsNameValid] = useState(true);
  const { name } = widgetData;

  const defaultDecimalDigits = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
  );

  let widget, useSteps;

  const classes = useStyles(props);

  const prevWidgetData: WidgetData = usePrevious(widgetData);

  useEffect(() => {
    onChangeWidgetData(widgetData, prevWidgetData || {}, setPreviewData);
    if (widgetData) {
      widget = widgetMap[widgetData.type];
      useSteps = widget?.settings?.useSteps;
      setEnableSaveAsDraft(getEnableSaveAsDraft(widget));
      setSteps(useSteps || [1, 2, 3, 4]);
      if (!currentStep && widgetData.type) {
        setCurrentStep(useSteps ? useSteps[0] : 1);
      }
    }
  }, [widgetData]);

  useEffect(() => {
    setWidgetData((prevState) => ({
      ...prevState,
      creationStage: creationStageMap[currentStep],
    }));
  }, [currentStep]);

  const getEnableSaveAsDraft = (widget) => {
    if (
      widget?.settings?.enableSaveAsDraftOnStepValidation &&
      widget.settings.enableSaveAsDraftOnStepValidation[currentStep]
    ) {
      return widgetStepValidationMap[currentStep](widgetData) && widgetData.assetTypes.length > 0;
    }

    return (
      widget?.settings?.enableSaveAsDraft ||
      widgetData.assetTypes.length > 0 ||
      widgetData.allAssetTypes
    );
  };

  useEffect(() => {
    setIsNameValid(true);
  }, [name]);

  const validateName = async (newName) => {
    if (newName && newName !== initialDataSourceData.name)
      try {
        const res: any = await httpService.api({
          type: 'validateEventTemplateDataSourceName',
          data: { name: newName },
          urlParams: { templateId: widgetData?.eventTemplateId },
        });
        if (res) {
          setIsNameValid(res.valid);
        }
      } catch {
        setIsNameValid(false);
      }
  };

  const onSaveWidget = useCallback(
    async (publish: boolean = false) => {
      await validateName(name);
      if (isNameValid) {
        createDataSource(widgetData, setWidgetData, publish).then(() => {
          setIsAddingDataSource(false);
          setShowFooter(true);
        });
      }
    },
    [widgetData, templateId, history]
  );

  const getPage = (currentStep, context) => {
    const action = context == 'NEXT' ? 1 : -1;

    const stepIdx = steps.findIndex((step) => step == currentStep);
    const stepToReturn = steps[stepIdx + action];

    return stepToReturn;
  };

  const next = () => {
    setCurrentStep(getPage(currentStep, 'NEXT'));
  };
  const prev = () => {
    setCurrentStep(getPage(currentStep, 'PREV'));
  };

  const onChangeStep = (from, to) => {
    setCurrentStep(to);
  };

  /**
   * This guard is activated whenever the user tries to navigate away from the page.
   */
  const leavePageGuard = useCallback(
    (location) => {
      return onNavigateFromPage(
        location,
        history,
        widgetData,
        setWidgetData,
        initialData,
        getEnableSaveAsDraft(widgetData),
        setIsAddingDataSource,
        setShowFooter
      );
    },
    [widgetData, getEnableSaveAsDraft]
  );

  return (
    <div className={classes.createDataSourcePage}>
      <Prompt message={leavePageGuard} />
      {currentStep ? (
        <>
          <WidgetSideBar
            currentStep={currentStep}
            onUserChangeStep={onChangeStep}
            widgetData={widgetData}
          />
          <div className={classes.dataSourceBody}>
            <div className={classes.dataSourceDataPreviewContainer}>
              <div className={classes.dataSourceDataPreviewBody}>
                <DataStepComponent
                  widgetData={widgetData}
                  setWidgetData={setWidgetData}
                  currentStep={currentStep}
                  previewData={previewData}
                  onChangeStep={onChangeStep}
                  isNameValid={isNameValid}
                  defaultDecimalDigits={defaultDecimalDigits}
                />
                {currentStep > 2 && (
                  <div className={classes.widgetPreviewContainer}>
                    <PreviewSection
                      widgetData={widgetData}
                      data={previewData}
                      defaultDecimalDigits={defaultDecimalDigits}
                    />
                  </div>
                )}
              </div>
            </div>
            <WidgetFooter
              cancel={() =>
                cancel(
                  widgetData,
                  onSaveWidget,
                  getEnableSaveAsDraft(widgetMap[widgetData?.type]),
                  initialData,
                  setIsAddingDataSource,
                  setShowFooter
                )
              }
              next={next}
              prev={prev}
              currentStep={currentStep}
              steps={steps}
              enableSaveAsDraft={enableSaveAsDraft}
              saveWidget={onSaveWidget}
              widgetData={widgetData}
              validation={widgetStepValidationMap[currentStep]}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CreateDataSource;
