import { CanvasWidget, CoordinatesWithId, DashboardState } from '@src/redux/redux.interface';
import { editorConfig } from '@core/canvas/editorConfig';
import { layoutService } from '@core/canvas/LayoutService';
import { omit } from 'lodash';

export const defaultWidthMap = new Map([
  ['DESKTOP', 1280],
  ['TABLET', 768],
  ['MOBILE', 360],
]);

export const unifiedLayout = (state?: DashboardState) => {
  return [
    {
      canvasHeight: state?.canvasHeight ? state?.canvasHeight : 544,
      canvasWidthLabel: state?.canvasWidthLabel
        ? state?.canvasWidthLabel
        : `${defaultWidthMap.get('DESKTOP')}px`,
      canvasWidthValue: state?.canvasWidthValue
        ? state?.canvasWidthValue
        : defaultWidthMap.get('DESKTOP'),
      layoutStateType: 'DESKTOP',
      numSelected: state?.numSelected ? state?.numSelected : 0,
      refreshRate: state?.refreshRate ? state?.refreshRate : null,
      showGrid: state?.showGrid !== null && state?.showGrid !== undefined ? state?.showGrid : false,
    },
  ];
};

export const multiLayout = (
  desktopState: DashboardState,
  canvasHeightTablet: number,
  canvasHeightMobile: number
) => {
  return [
    {
      canvasHeight: desktopState.canvasHeight,
      canvasWidthLabel: desktopState.canvasWidthLabel,
      canvasWidthValue: desktopState.canvasWidthValue,
      layoutStateType: 'DESKTOP',
      numSelected: desktopState.numSelected,
      refreshRate: desktopState.refreshRate,
      showGrid: desktopState.showGrid,
    },
    {
      canvasHeight: canvasHeightTablet,
      canvasWidthLabel: `${defaultWidthMap.get('TABLET')}px`,
      canvasWidthValue: defaultWidthMap.get('TABLET'),
      layoutStateType: 'TABLET',
      numSelected: 0,
      refreshRate: desktopState.refreshRate,
      showGrid: desktopState.showGrid,
    },
    {
      canvasHeight: canvasHeightMobile,
      canvasWidthLabel: `${defaultWidthMap.get('MOBILE')}px`,
      canvasWidthValue: defaultWidthMap.get('MOBILE'),
      layoutStateType: 'MOBILE',
      numSelected: 0,
      refreshRate: desktopState.refreshRate,
      showGrid: desktopState.showGrid,
    },
  ];
};

export const calculateCanvasHeightForNewStates = (widgetStates: Array<any>) => {
  let height = 0;

  for (const widget of widgetStates) {
    if (widget && widget.y + widget.h >= height) {
      height = widget.y + widget.h + 2;
    }
  }

  return height * editorConfig.gridCellSizePx + 50;
};

export const calculateTabletAndMobileWidgetStates = (widgets: Array<any>) => {
  const tabletWidgetStates = layoutService.calculateCanvasWidgetStates('TABLET', widgets);
  const mobileWidgetStates = layoutService.calculateCanvasWidgetStates('MOBILE', widgets);

  return { tablet: tabletWidgetStates, mobile: mobileWidgetStates };
};

export const getWidgetStates = (
  widgets: Array<CanvasWidget>,
  tabletAndMobileWidgetStates: {
    tablet: Array<CoordinatesWithId>;
    mobile: Array<CoordinatesWithId>;
  }
) => {
  return widgets.map((widget) => {
    return {
      ...widget,
      states: [
        widget.states.find((state) => state.layoutStateType === 'DESKTOP'),
        {
          ...tabletAndMobileWidgetStates['tablet'].find((state) => state.id === widget.id),
          layoutStateType: 'TABLET',
        },
        {
          ...tabletAndMobileWidgetStates['mobile'].find((state) => state.id === widget.id),
          layoutStateType: 'MOBILE',
        },
      ],
    };
  });
};

export const statesLocalToServer = (
  splitLayout: boolean,
  initialSettings: any,
  widgets: Array<CanvasWidget>,
  newWidgets: Array<CanvasWidget>,
  newDashboardStates: Array<any>,
  canvasHeightTablet: number,
  canvasHeightMobile: number
) => {
  if (splitLayout === initialSettings.splitLayout) {
    return {
      states: newDashboardStates,
      widgets: widgets.map((widget) => {
        return omit(widget, 'ref');
      }),
    };
  } else {
    if (splitLayout)
      return {
        states: multiLayout(
          newDashboardStates.find((state) => state.layoutStateType === 'DESKTOP'),
          canvasHeightTablet,
          canvasHeightMobile
        ),
        widgets: newWidgets.map((widget) => {
          return omit(widget, 'ref');
        }),
      };
    else
      return {
        states: [newDashboardStates.find((state) => state.layoutStateType === 'DESKTOP')],
        widgets: widgets.map((w) => {
          return {
            ...omit(w, 'ref'),
            states: [w.states.find((s) => s.layoutStateType === 'DESKTOP')],
          };
        }),
      };
  }
};

export function updateSelectedWidgetCoordinates(
  canvasWidgets: Array<CanvasWidget>,
  selectedWidget: CanvasWidget
) {
  return canvasWidgets.find((cw) => {
    if (cw.id === selectedWidget.id) {
      return { ...selectedWidget, x: cw.y, y: cw.y, w: cw.w, h: cw.h };
    }
  });
}
