import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

import { RemoteAccessWidgetProps } from './RemoteAccessWidget.interface';
import Image from '@components/Image';
import { getFlagStatus } from '@core/ffAndPermissions';
import { updateWidgetFilters } from '../widgets.utils';
import { useSelector } from '@src/redux/useSelector';
import VpnWidget from './VpnWidget';
import RemoteOperatorWidget from './RemoteOperatorWidget';
import { getUrlForWebAndVnc } from './RemoteAccessWidget.utils';
import { useAssetBaseDetails } from '@core/hooks/useAssetBaseDetails';
import IFrameRemoteAccessWidget from './IFrameRemoteAccessWidget';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  text: {
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function RemoteAccessWidget(props: RemoteAccessWidgetProps) {
  const classes = useStyles(props);
  const {
    widget,
    type,
    dashboardFilters,
    applyButtonClicked,
    setApplyButtonClicked,
    customization,
    isPreview = false,
  } = props;
  const autoScale = widget?.customization?.autoScale;
  const [widgetFilters, setWidgetFilters] = useState(props.widgetFilters);
  const emptyStateImageUrls = useSelector((state) => state.config.emptyStateImageUrls);
  const widgetType = type || widget.type;

  const clientType = useSelector((state) => state.clientState.clientType);
  const isMobileApp = ['uniCloudApp', 'iOSUniCloudApp'].includes(clientType);

  const [remoteAccessUrl, setRemoteAccessUrl] = useState(null);
  const [assetId, setAssetId] = useState<number>(null);
  const canRemoteAccess = useMemo(() => getFlagStatus('live-dashboard.remote-access-widgets'), []);

  const { assetBaseDetails } = useAssetBaseDetails(assetId);
  const plcType = assetId ? assetBaseDetails?.plcType : null;
  const macchinaConnected = assetId ? assetBaseDetails?.macchinaConnected : false;

  useEffect(() => {
    if (applyButtonClicked || dashboardFilters?.assetFilter?.length) {
      dashboardFilters?.assetFilter?.length > 0
        ? setWidgetFilters(() => updateWidgetFilters(dashboardFilters?.assetFilter))
        : setWidgetFilters([]);

      setApplyButtonClicked(false);
    }
  }, [applyButtonClicked]);

  useEffect(() => {
    if (!widgetFilters?.length) {
      setAssetId(null);
    } else {
      const data =
        Array.isArray(widgetFilters) &&
        widgetFilters?.find(
          (filter) =>
            filter.valueId === 1 &&
            filter.valueType === 'ASSET_PROPERTY' &&
            (!Array.isArray(filter.values) || filter.values.length === 1)
        );
      setAssetId(Array.isArray(data.values) ? data.values[0] : data.values);
    }
  }, [widgetFilters]);

  useEffect(() => {
    if (macchinaConnected) {
      switch (widgetType) {
        case 'web':
          if (assetId && plcType === 'UNISTREAM') {
            getUrlForWebAndVnc(widgetFilters, widgetType, autoScale, setRemoteAccessUrl);
          } else {
            setRemoteAccessUrl(null);
          }
          break;
        case 'vnc':
          if (assetId && plcType === 'UNISTREAM' && !isMobileApp) {
            getUrlForWebAndVnc(widgetFilters, widgetType, autoScale, setRemoteAccessUrl);
          } else {
            setRemoteAccessUrl(null);
          }
          break;
        default:
          setRemoteAccessUrl(null);
          break;
      }
    }
    setRemoteAccessUrl(null);
  }, [assetId, plcType, macchinaConnected]);

  const emptyStateComponent = (
    <div style={{ height: '100%' }}>
      <Image
        mode="smaller"
        src={`${emptyStateImageUrls[widgetType]}`}
        text={
          widget?.id && (!canRemoteAccess || widgetType === 'vpn')
            ? 'widgets.remote-access-no-flag'
            : `widgets.${!widget || !widget.id ? '' : 'remote-access-'}empty-state-text`
        }
      />
    </div>
  );

  function getWidgetComponent() {
    switch (widgetType) {
      case 'vpn':
        if (isPreview) {
          return (
            <VpnWidget
              customization={customization}
              assetId={assetId}
              isPreview={isPreview}
              macchinaConnected={macchinaConnected}
            />
          );
        }
        if (assetId && canRemoteAccess) {
          return (
            <VpnWidget
              customization={customization}
              assetId={assetId}
              isPreview={isPreview}
              macchinaConnected={macchinaConnected}
            />
          );
        }
        return emptyStateComponent;
      case 'remote_operator': // Relevant only for Previe in Widget wizard
        if ((assetId && canRemoteAccess) || isPreview) {
          return (
            <RemoteOperatorWidget
              customization={customization}
              isPreview={isPreview}
              assetId={assetId}
              plcType={plcType}
              widgetType={widgetType}
              macchinaConnected={macchinaConnected}
            />
          );
        }
        return emptyStateComponent;
      case 'vnc':
        switch (plcType) {
          case 'UNISTREAM':
            if (!isMobileApp) {
              if (remoteAccessUrl && canRemoteAccess) {
                return <IFrameRemoteAccessWidget remoteAccessUrl={remoteAccessUrl} />;
              }
              return emptyStateComponent;
            } else if (assetId && canRemoteAccess) {
              return (
                <RemoteOperatorWidget
                  customization={customization}
                  isPreview={isPreview}
                  assetId={assetId}
                  plcType={plcType}
                  widgetType={widgetType}
                  macchinaConnected={macchinaConnected}
                />
              );
            } else {
              return emptyStateComponent;
            }
          case 'LEGACY':
            return assetId && canRemoteAccess ? (
              <RemoteOperatorWidget
                customization={customization}
                isPreview={isPreview}
                assetId={assetId}
                plcType={plcType}
                widgetType={widgetType}
                macchinaConnected={macchinaConnected}
              />
            ) : (
              emptyStateComponent
            );
          case 'MODBUS':
            return emptyStateComponent;
          default:
            return emptyStateComponent;
        }
      case 'web':
        switch (plcType) {
          case 'UNISTREAM':
            if (!isMobileApp) {
              return remoteAccessUrl && canRemoteAccess ? (
                <IFrameRemoteAccessWidget remoteAccessUrl={remoteAccessUrl} />
              ) : (
                emptyStateComponent
              );
            }
            return remoteAccessUrl && canRemoteAccess ? (
              <RemoteOperatorWidget
                customization={customization}
                isPreview={isPreview}
                assetId={assetId}
                plcType={plcType}
                widgetType={widgetType}
                remoteAccessUrl={remoteAccessUrl}
                macchinaConnected={macchinaConnected}
              />
            ) : (
              emptyStateComponent
            );
          default:
            return emptyStateComponent;
        }
      default:
        break;
    }
  }

  return <div className={classes.wrapper}>{getWidgetComponent()}</div>;
}

export default RemoteAccessWidget;
