import { SelectOption } from '@components/Select/Select.interface';
import { httpService } from '@core/http/HttpService';
import { VariableMeta } from '@core/http/server.interface';
import { modalService } from '@core/modals/ModalService';
import { getState } from '@src/redux/store';

export type origin =
  | 'organizationAssetTypes'
  | 'organizationAssets'
  | 'assetType'
  | 'asset'
  | 'variableValuesPerAssetModal';

export const PAGE_SIZE = 1000;

export const valueTypeOptions: SelectOption[] = [
  { value: 'NUMERIC', label: 'general.numeric' },
  { value: 'TEXT', label: 'general.text' },
  { value: 'BOOLEAN', label: 'general.boolean' },
  { value: 'DATE', label: 'general.date' },
  { value: 'DATETIME', label: 'general.datetime' },
];

export const managementLevelOptions: SelectOption[] = [
  { value: 'GLOBAL', label: 'variables.management-level.global' },
  { value: 'LOCAL_MB', label: 'variables.management-level.local-mb' },
  { value: 'LOCAL_ORGANIZATION', label: 'variables.management-level.local-organization' },
];

export const variableConstants = {
  NAME_MAX_LENGTH: 50,
  MIN_VALUE: -10_000_000_000,
  MAX_VALUE: 10_000_000_000,
  TEXT_MAX_LENGTH: 250,
};

export async function validateName(assetTypeId, initialValues, setNameValidation, formik, newName) {
  if (newName && newName !== initialValues.name) {
    const res: any = await httpService.api({
      type: 'validateVariableName',
      data: { name: newName },
      urlParams: { assetTypeId },
    });
    if (res) {
      setNameValidation(
        res.valid
          ? { name: newName, message: '', isValid: true }
          : { name: newName, message: 'variables.variable-name-exists', isValid: false }
      );
    }
  }
  formik.setFieldValue('name', newName);
}

export function getVariablesList(
  origin: origin,
  id,
  setVariablesList,
  onEditDone = null,
  order = null
) {
  const machineBuilderId = getState().config.organizationDetails.id;
  switch (origin) {
    case 'organizationAssetTypes':
      httpService
        .api({
          type: 'getAssetTypeVariablesByOrg',
          urlParams: { organizationId: machineBuilderId },
          query: {
            p: 1,
            ps: PAGE_SIZE,
            search: null,
            ordering: order ? `${order.orderSign}${order.label}` : null,
          },
        })
        .then((res: any) => {
          setVariablesList(res.results);
          onEditDone && onEditDone();
        });
      return;
    case 'organizationAssets':
      httpService
        .api({
          type: 'getAssetVariablesByOrg',
          urlParams: { id },
          query: {
            p: 1,
            ps: PAGE_SIZE,
            search: null,
            ordering: order ? `${order.orderSign}${order.label}` : null,
          },
        })
        .then(({ results }: any) => {
          setVariablesList(results);
          onEditDone && onEditDone();
        });
      return;
    case 'assetType':
      httpService
        .api({
          type: 'getAssetTypeVariablesList',
          urlParams: { id },
        })
        .then((res: VariableMeta[]) => {
          setVariablesList(res);
          onEditDone && onEditDone();
        });
      return;
    case 'asset':
      httpService
        .api({ type: 'getAssetVariablesList', urlParams: { id } })
        .then((res: VariableMeta[]) => {
          setVariablesList(res);
          onEditDone && onEditDone();
        });
      return;
    case 'variableValuesPerAssetModal':
      httpService
        .api({
          type: 'getVariableValuesPerAsset',
          urlParams: { id },
        })
        .then((res) => {
          setVariablesList(res);
          onEditDone && onEditDone();
        });
  }
}

export function openResetValueModal(origin, assetId, variableId, setVariablesList, order) {
  modalService
    .openModal('confirm', {
      text: 'variables.reset-variable-value-pop-up.text',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: 'general.reset-value',
      showCloseBtn: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        const selectedOrganizationsId = getState().organizations.selectedOrganizationsId;
        await resetVariableValue(assetId, variableId);
        getVariablesList(
          origin,
          origin === 'organizationAssets' ? selectedOrganizationsId : variableId,
          setVariablesList,
          null,
          order
        );
      }
    });
}

async function resetVariableValue(assetId, variableId) {
  await httpService.api({
    type: 'resetAssetVariableValue',
    urlParams: { assetId, variableId },
  });
}
