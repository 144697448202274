import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from '@redux/useSelector';

// Components
import AssetInfoSubscriptionView from './AssetInfoSubscriptionView';

// Services | Interfaces
import { httpService } from '@core/http/HttpService';
import styles from './AssetTypeInfoSubscriptionMeta.scss';
import { editAssetTypeService } from '@modals/EditAssetTypeModal/EditAssetTypeService';
import { AssetSubscription } from '@src/redux/redux.interface';

function AssetTypeInfoSubscriptionMeta(props) {
  const { assetTypeRowData, remoteAccessMode, setRemoteAccessMode } = props;

  const cancel = useCallback(() => editAssetTypeService.dismiss(false), []);
  const staticData = useSelector((state) => state.editAssetType.staticData);
  const { id: assetId, name: assetTypeName } = staticData;
  const [assetSubscription, setAssetSubscription] = useState<AssetSubscription>();
  const moment = require('moment');
  const [duration, setDuration] = useState();

  useEffect(() => {
    getAssetTypeSubscription();
  }, [staticData?.gpsSupport]);

  const getAssetTypeSubscription = () => {
    httpService
      .api({ type: 'getAssetTypeSubscription', urlParams: { assetTypeId: assetId } })
      .then((res: AssetSubscription) => {
        setAssetSubscription(res);
        calculateDuration(res);
      });
  };

  const calculateDuration = (subscription) => {
    const durationTagsTimer =
      subscription && subscription.tagsTimer > 3600
        ? moment.duration(subscription.tagsTimer, 'seconds').format('HH:mm:ss')
        : moment.utc(subscription.tagsTimer * 1000).format('HH:mm:ss');
    setDuration(durationTagsTimer);
  };

  const onCancelEdit = (changed: boolean, changes?: any) => {
    if (changed) {
      if (changes) {
        const updatedSubscription = { ...assetSubscription, ...changes };
        calculateDuration(updatedSubscription);
        setAssetSubscription(updatedSubscription);
      } else {
        getAssetTypeSubscription();
      }
    }
  };

  return (
    <div className="max-height">
      <div className={styles.formWrapper}>
        <AssetInfoSubscriptionView
          assetId={assetId}
          assetTypeName={assetTypeName}
          cancel={cancel}
          assetTypePlcModel={assetTypeRowData.plcType}
          assetSubscription={assetSubscription}
          duration={duration}
          setDuration={setDuration}
          onCancelEdit={onCancelEdit}
          remoteAccessMode={remoteAccessMode}
          setRemoteAccessMode={setRemoteAccessMode}
          getAssetTypeSubscription={getAssetTypeSubscription}
        />
      </div>
    </div>
  );
}

export default AssetTypeInfoSubscriptionMeta;
