import React, { useEffect, useState } from 'react';
import styles from './AssetTypeSection.scss';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import AssetTypesInput from './AssetTypesInput';
import { httpService } from '@core/http/HttpService';
import { AssetTypeResponse } from '@pages/CreateWidgetPage/DataStepComponent/StepOne/StepOne.interface';
import SystemRemark from '@components/SystemRemark';

function AssetTypeSection(props) {
  const { widgetData, dashboardId, replacements, replaceAssetTypes } = props;
  const { assetTypes } = widgetData;
  const [assetTypesOptions, setAssetTypesOptions] = useState([]);

  useEffect(() => {
    const request = httpService.api({
      type: 'getAssetTypeOptionsInfo',
      query: { p: 1, ps: 1000, dashboardId, eventTemplateId: null },
    });
    request.then((types: AssetTypeResponse) => {
      setAssetTypesOptions(
        types.results.map((t) => ({
          id: t.id,
          name: t.name,
          labels: t.labels,
        }))
      );
    });

    return () => {
      request.cancel && request.cancel();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.paneWrapper}
        style={{ height: widgetData.variables?.length ? '66px' : '36px' }}>
        {widgetData.allAssetTypes ? (
          <div
            className={classNames(styles.caption, 'ellipsis-overflow')}
            title={i18nService.translate('general.all')}>{`${i18nService.translate(
            'general.source'
          )} (${i18nService.translate('general.all')}):`}</div>
        ) : (
          <div
            className={classNames(styles.caption, 'ellipsis-overflow')}
            title={assetTypes
              .map((assetType) => assetType.name)
              .join(', ')}>{`${i18nService.translate('general.source')} (${
            assetTypes.length
          }):`}</div>
        )}
        <div id={'b1ffbca9-213a-412b-a147-3ec13acb3749'} className={styles.dropdown}>
          <AssetTypesInput
            assetTypes={assetTypes}
            assetTypesOptions={assetTypesOptions.filter((option) =>
              assetTypes.some((assetType) => assetType.id === option.id)
            )}
            replacements={replacements}
            replaceAssetTypes={replaceAssetTypes}
            widgetData={widgetData}
            eventTemplateId={widgetData.eventTemplateId}
            dashboardId={dashboardId}
            hideCaption={true}
            isOptionDisabled={() => true}
            isTarget={false}
            isDisabled={widgetData.allAssetTypes || assetTypes.length <= 2}
            isSelectAllAllowed={false}
          />
        </div>
      </div>
      <div
        className={styles.paneWrapper}
        style={{ height: widgetData.variables?.length ? '66px' : '36px' }}>
        {replacements.assetTypes.replaceWith === 'all' ? (
          <div
            className={classNames(styles.caption, 'ellipsis-overflow')}
            title={i18nService.translate('general.all')}>{`${i18nService.translate(
            'create-widget-page.create-widget.step-one.replace-tags.replace-with'
          )} (${i18nService.translate('general.all')}):`}</div>
        ) : (
          <div
            className={classNames(styles.caption, 'ellipsis-overflow')}
            title={replacements.assetTypes.replaceWith
              .map((assetType) => assetType.name)
              .join(', ')}>{`${i18nService.translate(
            'create-widget-page.create-widget.step-one.replace-tags.replace-with'
          )} (${replacements.assetTypes.replaceWith.length}):`}</div>
        )}
        <div id={'099e8527-fae8-42f8-bc88-c5ed1c9c80fe'} className={styles.dropdown}>
          <AssetTypesInput
            assetTypes={assetTypes}
            assetTypesOptions={assetTypesOptions}
            replacements={replacements}
            replaceAssetTypes={replaceAssetTypes}
            widgetData={widgetData}
            eventTemplateId={widgetData.eventTemplateId}
            isSelectAllAllowed={
              ['value', 'alarms', 'table', 'map'].includes(widgetData.type) &&
              !widgetData.tags?.length &&
              !widgetData.tagTypes?.length
            }
            dashboardId={dashboardId}
            hideCaption={true}
            isTarget={true}
            isDisabled={widgetData.variables?.length}
          />
          {widgetData.variables?.length ? (
            <SystemRemark
              remarkText={
                'create-widget-page.create-widget.step-one.replace-tags.replace-asset-type-disabled.variables'
              }
              style={{ height: '100%', width: '100%', paddingTop: '5px' }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AssetTypeSection;
