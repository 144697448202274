import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  desc: {
    color: ({ customization }: any) => customization.remarkColor || 'var(--widgetsFont)',
    fontSize: ({ customization }: any) => `${customization.descriptionFontSize || 12}px`,
    opacity: 0.8,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

function Description(props) {
  const classes = useStyles(props);
  const { description } = props.customization;

  return (
    <div>
      <div className={classes.desc} title={description}>
        {description}
      </div>
    </div>
  );
}

export default Description;
