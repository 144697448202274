import { MenuItem } from './SideMenu.interface';

export const menuItems: Array<MenuItem> = [
  {
    label: 'menu.live-dashboard',
    route: 'dashboard',
    iconType: 'liveDashboard',
    featureFlag: 'live-dashboard-icon',
    isMobileAvailable: true,
    isUniAdminLiveDashboardAvailable: true,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    label: 'menu.device-management',
    route: 'device-management',
    iconType: 'deviceManagement',
    featureFlag: 'device-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    label: 'menu.events-management',
    route: 'events-management',
    iconType: 'eventsManagement',
    featureFlag: 'events-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    label: 'menu.organizations',
    route: 'organizations',
    iconType: 'organizations',
    featureFlag: 'organizations-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    label: 'dashboard-editor-gallery.dashboard-gallery',
    route: 'edit-dashboard',
    iconType: 'dashboardEditor',
    featureFlag: 'dashboard-editor-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    label: 'menu.augmented-reality-gallery',
    route: 'augmented-reality',
    iconType: 'augmentedReality',
    featureFlag: 'ar-editor-icon',
    isMobileAvailable: true,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    label: 'menu.my-account',
    route: 'my-account',
    iconType: 'myAccount',
    featureFlag: 'my-account-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
];
