import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ImageByValueCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { i18nService } from '@core/i18n/I18nService';
import IBVCustomizationRow from '../IBVCustomizationRow';
import * as cloneDeep from 'lodash/cloneDeep';
import Icon from '@components/Icon';
import { conditionsService } from '@core/ConditionsService';
import { modalService } from '@core/modals/ModalService';

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1px',
    padding: '0px 12px 0px 12px',
    fontSize: 12,
    fontWeight: 500,
    borderTop: 'solid 1px #c2cfe0',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 12px 10px 28px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: 15,
  },
  add: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    height: 43,
  },
}));

function IBVCfgConditions(props) {
  const classes = useStyles(props);
  const {
    widgetData,
    setWidgetData,
    onChange,
    isInstantServerUpdate = false,
    isValidationOnChangeNeeded = true,
  } = props;
  const { customization, metrics, scope } = widgetData as any;
  const { conditions, errors = {} } = (customization as ImageByValueCustomization) || {
    conditions: null,
  };
  const [tableColumns, setTableColumns] = useState(conditions);
  const isBoolean = useMemo(
    () => metrics[0] && metrics[0].type === 'booleanType' && scope === 'LAST_VALUE',
    [metrics, scope]
  );

  const columnCfg = isBoolean
    ? [
        {
          style: { minWidth: '25%', width: '25%' },
          headerLabel: 'create-widget-page.create-widget.step-four.image-by-value.value',
        },
        {
          style: { minWidth: '31px', width: '31px' },
        },
        {
          style: { minWidth: '50%', width: '50%' },
          headerLabel: 'create-widget-page.create-widget.step-four.image-by-value.image',
        },
      ]
    : [
        {
          style: { minWidth: '24%', width: '24%' },
          headerLabel: 'create-widget-page.create-widget.step-four.image-by-value.from',
        },
        {
          style: { minWidth: '24%', width: '24%' },
          headerLabel: 'create-widget-page.create-widget.step-four.image-by-value.to',
        },
        {
          style: { minWidth: '31px', width: '31px' },
        },
        {
          style: { minWidth: '30%', width: '30%' },
          headerLabel: 'create-widget-page.create-widget.step-four.image-by-value.image',
        },
      ];

  useEffect(() => {
    if ((metrics?.length && !conditions) || !conditions.length) {
      let tempColumns = [];
      if (isBoolean) {
        tempColumns = [
          { boolValue: true, id: 1 },
          { boolValue: false, id: 2 },
        ];
      } else {
        tempColumns = [{ fromValue: null, toValue: null, id: moment().unix() }];
      }
      updateConditions(tempColumns);
    }
  }, [conditions, metrics]);

  useEffect(() => {
    if (metrics?.length && conditions?.length) {
      const updatedConditions = conditions.map((condition, idx) => {
        return getValidatedWidgetData(condition, idx, true).customization.conditions[idx];
      });

      const validatedWidgetData = {
        ...widgetData,
        customization: {
          ...widgetData.customization,
          conditions: updatedConditions,
        },
      };
      setWidgetData(validatedWidgetData);
    }
  }, [metrics]);

  const updateConditions = (newUpdate) => {
    setTableColumns(newUpdate);
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        conditions: newUpdate,
      },
    }));

    onChange?.({
      ...widgetData,
      customization: { ...widgetData.customization, conditions: newUpdate },
    });
  };

  const getValidatedWidgetData = (condition, idx, isValidationNeeded = true) => {
    const _conditions = cloneDeep(widgetData.customization.conditions);
    _conditions[idx] = condition;

    const updatedTableColumns = [..._conditions];
    setTableColumns(updatedTableColumns);

    const isValid = isValidationNeeded ? conditionsService.validate(_conditions, condition) : true;
    let errors = widgetData.customization.errors ? { ...widgetData.customization.errors } : {};

    if (!isBoolean) {
      if (!isValid && !Object.keys(errors).some((key) => key === condition.id)) {
        errors[condition.id] = true;
      } else {
        errors[condition.id] = false;
      }
    }

    return {
      ...widgetData,
      customization: Object.assign({}, customization, { conditions: _conditions, errors }),
    };
  };

  const validateOnBlur = (condition, idx) => {
    const newWidgetData = getValidatedWidgetData(condition, idx, true);
    setWidgetData(newWidgetData);
  };

  const onColumnChanged = (condition, idx) => {
    const newWidgetData = getValidatedWidgetData(condition, idx, isValidationOnChangeNeeded);
    setWidgetData(newWidgetData);
    onChange?.(newWidgetData);
  };

  const deleteCondition = (idx) => {
    function remove() {
      const tempConditions = [...conditions];
      tempConditions.splice(idx, 1);
      updateConditions(tempConditions);

      if (errors[conditions[idx].id]) {
        delete errors[conditions[idx].id];
      }
    }

    if (!conditions[idx].fromValue && !conditions[idx].toValue && !conditions[idx].imageId) {
      remove();
    } else {
      modalService
        .openModal('confirm', {
          text: 'create-widget-page.create-widget.step-four.image-by-value.remove-condition-message',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText:
            'create-widget-page.create-widget.step-four.image-by-value.remove-condition-header',
          showCloseBtn: true,
        })
        .then((confirm) => {
          if (confirm) {
            remove();
          }
        });
    }
  };

  const addCondition = () => {
    updateConditions([...conditions, { fromValue: null, toValue: null, id: moment().unix() }]);
  };

  return (
    <div className={classes.tableContainer}>
      {
        <div className={classes.tableHeader}>
          {columnCfg.map((header, idx) => (
            <div
              title={header.headerLabel && i18nService.translate(header.headerLabel)}
              key={idx}
              className={classes.header}
              style={header.style}>
              {header.headerLabel && (
                <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
              )}
            </div>
          ))}
        </div>
      }

      {conditions &&
        conditions.map((condition, idx) => (
          <div key={condition.id} className={classes.columnContainer}>
            <IBVCustomizationRow
              columnCfg={columnCfg}
              column={condition}
              index={idx}
              isLast={idx + 1 == tableColumns.length}
              columnChanged={(editedColumn, index) => onColumnChanged(editedColumn, index)}
              validateOnBlur={validateOnBlur}
              isBoolean={isBoolean}
              conditions={conditions}
              error={errors[condition.id]}
              deleteCondition={() => deleteCondition(idx)}
              disabled={metrics.length == 0}
              isInstantServerUpdate={isInstantServerUpdate}
            />
          </div>
        ))}

      <div className={classNames(classes.columnContainer, classes.add)}>
        {!isBoolean &&
          conditions?.length > 0 &&
          conditions?.length < 100 &&
          !Object.values(errors).find((err) => err) &&
          conditions[conditions.length - 1].imageId &&
          (typeof conditions[conditions.length - 1].fromValue === 'number' ||
            typeof conditions[conditions.length - 1].toValue === 'number') && (
            <Icon
              className="pointer"
              type="addImageRow"
              onClick={addCondition}
              tooltipText={'create-widget-page.create-widget.step-four.add-condition'}></Icon>
          )}
      </div>
    </div>
  );
}

export default IBVCfgConditions;
