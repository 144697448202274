import React, { useCallback, useState, useEffect, useMemo } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import EditRouterDetails from './EditRouterDetails';
import EditRouterTabs from './EditRouterTabs';
import RemoveRouterBTN from '@pages/DeviceManagment/Routers/RoutersManagementTab/RoutersTable/RemoveRouterBTN';
import GetRouterCertificateBTN from './GetRouterCertificateBTN/GetRouterCertificateBTN';

// Services | Interfaces
import { ModalComponentProps } from '@core/modals/modals.interface';
import { RouterData } from '@src/redux/redux.interface';
import { httpService } from '@core/http/HttpService';
import styles from './EditRouterModal.scss';
import RemoteAccessButton from '@components/RemoteAccessButton';
import { getFlagStatus } from '@core/ffAndPermissions';

function EditRouterModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const routerId = args.rowData.id;
  const [router, setRouter] = useState<RouterData>(null);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const buttonStyles = useMemo(() => ({ width: 62, height: 24, marginLeft: 10, minWidth: 62 }), []);

  const flags = useMemo(
    () => ({
      remoteAccess: getFlagStatus('assets.remote-access-buttons'),
    }),
    []
  );

  useEffect(() => getRouter(), []);
  const getRouter = (routerOwnerId?) => {
    httpService
      .api({
        type: 'getRouter',
        urlParams: { routerId, organizationId: routerOwnerId || router?.ownerId || undefined },
      })
      .then((res: RouterData) => {
        setRouter(res);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>edit-router-modal.title</I18n>
      </div>
      <div className={styles.modalToolbar}>
        <div className={styles.toolbarIcons}>
          <GetRouterCertificateBTN {...router} btnText="edit-router-modal.certificate" />
          <RemoveRouterBTN {...router} btnText="edit-router-modal.diconnect" onRemoved={cancel} />
        </div>
      </div>
      {router && (
        <div className={styles.modalContent}>
          <div className={styles.routerDataSection}>
            <EditRouterDetails router={router} onDataChanged={getRouter} />
            {flags.remoteAccess && (
              <div className={styles.remoteAccess}>
                <RemoteAccessButton
                  {...router}
                  parentComponent="EditRouterModal"
                  remoteAccessType="routerWeb"
                  styles={buttonStyles}
                />
              </div>
            )}
          </div>
          <div className={styles.tabsSection}>
            <EditRouterTabs onDataChanged={getRouter} {...router} />
          </div>
          <div className={styles.modalButtons}>
            <Button mode="bigFont" disabled={false} onClick={cancel}>
              <I18n>general.close</I18n>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditRouterModal;
