import React, { useState, useEffect } from 'react';
import { useSelector } from '@src/redux/useSelector';

function LogoIcon(props) {
  const { logo, onChange, height, style = {} } = props;
  const [dimensions, setDimensions] = useState<any>({});
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  const image = new Image();
  image.src = logo;

  const onImageLoad = async ({ target: img }) => {
    const aspectRatio = image.naturalWidth / image.naturalHeight;
    let newHeight = height;
    let newWidth = height * aspectRatio;

    setDimensions({ height: newHeight, width: newWidth });
    onChange && onChange({ height: newHeight, width: newWidth });
  };

  useEffect(() => {
    isMobileView &&
      setDimensions({
        height: 17,
        width: 100,
      });
  }, [isMobileView]);

  return (
    <img
      src={logo}
      // crossOrigin="anonymous"
      onLoad={onImageLoad}
      style={{
        height: dimensions.height,
        width: dimensions.width,
        visibility: dimensions.width ? 'visible' : 'hidden',
        ...style,
      }}
    />
  );
}

export default LogoIcon;
