import React, { useEffect, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper, makeStyles } from '@material-ui/core';
import { compact } from 'lodash';
import CheckboxOptionRow from './CheckboxOptionRow';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '4px 0',
  },
  filtersWrapper: {
    minWidth: 150,
    paddingTop: 11,
    paddingLeft: 12,
    paddingBottom: 15,
  },
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

const TableFilter = (props) => {
  const {
    filterOptions,
    anchorRef,
    onFilter,
    isOpen,
    field,
    filterAll,
    selectedOptionsByField,
  } = props;
  const [open, setOpen] = useState(isOpen);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [options, setOptions] = useState([]);

  const classes = useStyles(props);

  useEffect(() => {
    if (selectedOptionsByField && field) {
      setSelectedOptions(
        selectedOptionsByField[field]
          ? selectedOptionsByField[field].reduce(
              (res, opt) => ({ ...res, [opt]: true }),
              selectedOptionsByField[field].length === filterOptions.length ? { '': true } : {}
            )
          : {}
      );
    }
  }, [field, selectedOptionsByField]);

  useEffect(() => {
    if (filterAll && filterOptions) {
      setOptions([{ value: '', label: 'general.all' }, ...filterOptions]);
    } else if (filterOptions) {
      setOptions(filterOptions);
    }
  }, [filterOptions, filterAll]);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleClose = (e) => {
    onFilter(
      field,
      compact(Object.keys(selectedOptions).map((opt) => selectedOptions[opt] && opt))
    );
    setOpen(false);
  };

  const setOption = (e, value) => {
    if (value === '' && !selectedOptions[value]) {
      setSelectedOptions(options.reduce((res, opt) => ({ ...res, [opt.value]: true }), {}));
      return;
    } else if (value === '') {
      setSelectedOptions({});
      return;
    }
    const tempOptions = { ...selectedOptions, [value]: !selectedOptions[value] };
    const data = Object.keys(tempOptions).filter((opt) => opt !== '');
    if (data.length === filterOptions.length && !data.find((opt) => !tempOptions[opt])) {
      setSelectedOptions({ ...tempOptions, '': true });
    } else {
      setSelectedOptions({ ...tempOptions, '': false });
    }
  };

  return (
    <>
      {open && <div className={classes.backDrop} onClick={handleClose}></div>}
      <Popper
        open={open || false}
        anchorEl={anchorRef && anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.filtersWrapper}>
                  {options?.map((option) => (
                    <CheckboxOptionRow
                      key={option.value}
                      setOption={setOption}
                      option={option}
                      isChecked={!!selectedOptions[option.value]}
                    />
                  ))}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default TableFilter;
