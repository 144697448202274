import RemoteAccessWidget from '@components/widgets/RemoteAccessWidget';
import { WidgetMapItem } from '../widgetMap.interface';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';

const stepFourVpnValidation = (widgetData: WidgetData) => {
  if (widgetData.customization) {
    if (!widgetData.customization['buttonText']) {
      return false;
    }
  }
  return true;
};

export const vpn: WidgetMapItem = {
  id: 'vpn',
  name: 'vpn',
  component: RemoteAccessWidget,
  minWidth: 136,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas:
      'https://unicloud-public-prod-eu-west-1.s3.amazonaws.com/widgets_empty_states/empty_vpn.svg',
    thumbnail: 'vpn',
  },
  editModalSize: {},
  settings: {
    enableSaveAsDraft: true,
    hidePreviewTable: true,
    useSteps: [4],
    stepValidation: {
      4: (widgetData) => stepFourVpnValidation(widgetData),
    },
    languageKeys: (isArWidget) => ['buttonText'],
    allowAssetTypeNavigation: () => false,
    hideFilterNavigation: () => true,
  },
};
