import React from 'react';
import LogoIcon from '@components/LogoIcon';
import styles from '@pages/MainPage/MainPage.scss';
import { useSelector } from '@redux/useSelector';

const DashboardReportFooter = (props: any) => {
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const { freeLogo, emailFooter } = whiteLabelDetails;
  const displayLogo = isPremium ? freeLogo : whiteLabelDetails.premiumLogo;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.preview}>
        {displayLogo?.content?.logoUrl && emailFooter?.content?.displayLogo && (
          <LogoIcon logo={displayLogo?.content?.logoUrl} width={125} height={25} />
        )}
        <div dangerouslySetInnerHTML={{ __html: emailFooter?.content?.email }} />
      </div>
    </div>
  );
};

export default DashboardReportFooter;
