import { useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';

function CanvasRaycaster(props) {
  const { onWidgetClick, onEmptyAreaClick } = props;
  const { camera, scene, gl } = useThree();
  const raycaster = useRef(new THREE.Raycaster());
  const mouse = useRef(new THREE.Vector2());
  const initialMousePosition = useRef({ x: 0, y: 0 });

  const getMousePosition = (event, rect) => {
    return {
      x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
      y: -((event.clientY - rect.top) / rect.height) * 2 + 1,
    };
  };

  const logIntersectedObject = (obj) => {
    if (obj.userData) {
      if (obj.userData.widgetData) {
        if (onWidgetClick) onWidgetClick(obj.userData.widgetData);
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleMouseDown = (event) => {
    if (event.button !== 0) return;
    const rect = gl.domElement.getBoundingClientRect();
    const mousePos = getMousePosition(event, rect);
    initialMousePosition.current = mousePos;
  };

  const handleMouseUp = (event) => {
    if (event.button !== 0) return;
    const rect = gl.domElement.getBoundingClientRect();
    const mousePos = getMousePosition(event, rect);

    // Prevent moved clicks
    if (
      initialMousePosition.current.x !== mousePos.x ||
      initialMousePosition.current.y !== mousePos.y
    ) {
      return;
    }

    mouse.current.x = mousePos.x;
    mouse.current.y = mousePos.y;

    raycaster.current.setFromCamera(mouse.current, camera);

    const intersects = raycaster.current.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      const foundWidget = intersects.some((intersect) => logIntersectedObject(intersect.object));
      if (!foundWidget) {
        if (onEmptyAreaClick) onEmptyAreaClick();
      }
    } else {
      if (onEmptyAreaClick) onEmptyAreaClick();
    }
  };

  useEffect(() => {
    gl.domElement.addEventListener('mousedown', handleMouseDown);
    gl.domElement.addEventListener('mouseup', handleMouseUp);

    return () => {
      gl.domElement.removeEventListener('mousedown', handleMouseDown);
      gl.domElement.removeEventListener('mouseup', handleMouseUp);
    };
  }, [camera, scene, gl]);

  return null;
}

export default CanvasRaycaster;
