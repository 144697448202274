import I18n from '@components/I18n';
import React from 'react';
import styles from './StartEnd.scss';
import moment from 'moment';
import DatePicker from '@components/DatePicker';
import Icon from '@components/Icon';
import classNames from 'classnames';

function StartEnd(props) {
  const {
    startDate,
    endDate,
    startDateChanged,
    endDateChanged,
    xIconClicked,
    showEditOption = true,
  } = props;

  return (
    <>
      {/* START */}
      <div className={styles.row}>
        <div className={styles.caption}>
          <I18n>events-template.edit-template.trigger.start-date</I18n>
        </div>
        <div
          className={styles.datePicker}
          style={{ pointerEvents: showEditOption ? 'auto' : 'none' }}>
          <DatePicker
            data={{
              dateFilter: 'CUSTOM_OPTIONAL',
              dateFilterFrom: startDate,
              dateFilterTo: null,
            }}
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            selectedChanged={startDateChanged}
            maxDate={moment(endDate || '9999-12-31', 'YYYY-MM-DD')}
            height={28}></DatePicker>
        </div>
      </div>
      {/* END */}
      <div className={styles.row}>
        <div className={styles.caption}>
          <I18n>events-template.edit-template.trigger.end-date</I18n>
        </div>
        <div
          className={styles.datePicker}
          style={{ pointerEvents: showEditOption ? 'auto' : 'none' }}>
          <DatePicker
            data={{
              dateFilter: 'CUSTOM_OPTIONAL',
              dateFilterFrom: endDate,
              dateFilterTo: null,
            }}
            disabled={false}
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            selectedChanged={endDateChanged}
            minDate={moment(startDate, 'YYYY-MM-DD')}
            height={28}></DatePicker>
        </div>
        {endDate && showEditOption && (
          <Icon
            type="remove"
            className={classNames(styles.clearIcon, 'pointer')}
            onClick={xIconClicked}
          />
        )}
      </div>
    </>
  );
}

export default StartEnd;
