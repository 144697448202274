import React, { useEffect, useCallback } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import styles from './CreateAssetModalSelect.scss';
import { FormikSelectProps } from './createAssetModalSelect.interface';

function CreateAssetModalSelect(props: FormikSelectProps) {
  const {
    formik,
    name,
    label,
    validate,
    disabled,
    options,
    defaultValue,
    className,
    setState,
    isMandatory = false,
  } = props;

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const handleChange = useCallback(
    (selectedOpt) => {
      setState && setState(selectedOpt);
      formik.setFieldValue(name, selectedOpt);
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(name, true);
  }, [formik]);

  return (
    <>
      <div className={classNames(styles.fieldWrapper, className)}>
        <label
          className={classNames(
            styles.fieldLabel,
            isMandatory && 'asterisk',
            !isMandatory && styles.padding,
            'ellipsis-overflow'
          )}>
          <I18n>{label}</I18n>:
        </label>
        <div className={styles.fieldInput}>
          <Select
            defaultValue={defaultValue}
            isDisabled={disabled}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            maxMenuHeight={180}
          />
          <ErrorMessage name={name}>
            {(err) => (
              <I18n className={styles.error} element="div">
                {err}
              </I18n>
            )}
          </ErrorMessage>
        </div>
      </div>
    </>
  );
}

export default connect(CreateAssetModalSelect);
