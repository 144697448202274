import React from 'react';
import I18n from '@components/I18n';
import styles from './UsageData.scss';

const UsageData = (props) => {
  const { data } = props;
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <I18n>{'my-account-page.licensing-plan.usage.beginning-of-month'}</I18n>
        </div>
        <div className={styles.text}>
          <I18n>{'my-account-page.licensing-plan.usage.during-month'}</I18n>
        </div>
      </div>

      <div className={styles.col}>
        <I18n className={styles.lable}>{'my-account-page.licensing-plan.usage.monthly'}</I18n>
        <div className={styles.number}>{data?.monthly}</div>
        <div className={styles.number}>
          {data?.used <= data?.monthly ? data?.used : data?.monthly}
        </div>
      </div>

      <div className={styles.col}>
        <I18n className={styles.lable}>{'my-account-page.licensing-plan.usage.sms-pool'}</I18n>
        <div className={styles.number}>{data?.pool + data?.monthly}</div>
        <div className={styles.number}>{data?.used}</div>
      </div>
    </div>
  );
};

export default UsageData;
