import React from 'react';
import { makeStyles } from '@material-ui/core';
import Icon from '@components/Icon';

const useStyles = makeStyles((theme: any) => ({
  download: {
    cursor: 'pointer',
    width: 24,
    display: 'flex',
    justifyContent: 'center',
  },
}));

function DownloadNotStart(props) {
  const { callback, color = 'white' } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.download} onClick={callback}>
      <Icon type={'downloadWidgetData'} color={color} tooltipText="context.export.download-csv" />
    </div>
  );
}

export default DownloadNotStart;
