import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Divider, Popover, ClickAwayListener } from '@material-ui/core';
import I18n from '@components/I18n';
import styles from './ArrangementPopover.scss';
import { makeStyles } from '@material-ui/core';
import { ArrangementPopoverProps } from './ArrangementPopover.interface';
import Icon from '@components/Icon';
import { cssVarsService } from '@core/CssVarsService';
import {
  calculateWidgets,
  openErrorModal,
  options,
  updateWidgetLocation,
} from './ArrangementPopover.utils';
import { getState } from '@src/redux/store';

const useStyles = makeStyles((theme: any) => ({
  divider: {
    '&.MuiDivider-middle': {
      margin: 0,
      opacity: 0.1,
      backgroundColor: 'var(--black)',
    },
  },
}));

function ArrangementPopover(props: ArrangementPopoverProps) {
  const {
    open,
    close,
    anchorEl,
    selectedWidgets,
    setSelectedWidgets,
    setSelectedWidgetIdsWithError,
  } = props;
  const classes = useStyles(props);
  const currentLayout = getState().dashboardEditor.currentLayout;

  const closeMenu = useCallback(() => close('cancel'), []);

  function onClick(option: string) {
    const calculatedWidgets = calculateWidgets(selectedWidgets, option);

    if (calculatedWidgets.notAllowedWidgets?.length === 0) {
      calculatedWidgets.newWidgets.map((w) => updateWidgetLocation(w, currentLayout));
      setSelectedWidgets(
        calculatedWidgets.newWidgets.concat(
          selectedWidgets.filter(
            (sw) => !calculatedWidgets.newWidgets.some((nw) => nw.id === sw.id)
          )
        )
      );
      setSelectedWidgetIdsWithError([]);
    } else {
      setSelectedWidgetIdsWithError(calculatedWidgets.notAllowedWidgets.map((w) => w.id));
      openErrorModal(calculatedWidgets, option);
    }

    closeMenu();
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{ onMouseLeave: closeMenu }}>
      <ClickAwayListener onClickAway={close}>
        <div>
          <div className={styles.wrapper} style={{ padding: '10px 0 5px 0' }}>
            <I18n className={styles.optionGroup}>dashboard-editor.align-widgets</I18n>
            {options.align.map((option, idx) => (
              <div key={`${idx}-${option}`}>
                <div
                  id={option}
                  className={classNames(styles.option, 'ellipsis-overflow')}
                  key={idx}
                  onClick={() => onClick(option)}>
                  <Icon
                    className={styles.icon}
                    type={option}
                    width="17px"
                    height="17px"
                    color={cssVarsService.vars.systemEnableEditorIcons}
                    style={{ margin: '0 10px 0 40px' }}
                  />
                  <I18n
                    style={{
                      paddingRight: '20px',
                    }}>{`dashboard-editor.align-widgets.${option}`}</I18n>
                </div>
                <div className={classNames(styles.innerDividerDiv)}>
                  {idx === 2 && (
                    <Divider variant="middle" className={classNames(classes.divider)} />
                  )}
                </div>
              </div>
            ))}
          </div>
          <Divider variant="middle" className={classNames(classes.divider)} />
          <div className={styles.wrapper} style={{ padding: '10px 0 10px 0' }}>
            <I18n className={styles.optionGroup}>dashboard-editor.adjust-widget-size</I18n>
            {options.adjustSize.map((option, idx) => (
              <div key={`${idx}-${option}`}>
                <div
                  id={option}
                  className={classNames(styles.option, 'ellipsis-overflow')}
                  key={idx}
                  onClick={() => onClick(option)}>
                  <Icon
                    className={styles.icon}
                    type={option}
                    width="20px"
                    height="20px"
                    color={cssVarsService.vars.systemEnableEditorIcons}
                    style={{ margin: '0 10px 0 40px' }}
                  />
                  <I18n
                    style={{
                      paddingRight: '20px',
                    }}>{`dashboard-editor.adjust-widget-size.${option}`}</I18n>
                </div>
                <div className={classNames(styles.innerDividerDiv)}>
                  {idx === 1 && (
                    <Divider variant="middle" className={classNames(classes.divider)} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    </Popover>
  );
}

export default ArrangementPopover;
