import { makeStyles } from '@material-ui/core';
import React from 'react';
import EventStatusColors from './EventStatusColors';
import Panel from '../../Panel/Panel';
import styles from './StatusColors.scss';

const useStyles = makeStyles((theme: any) => ({}));

const panelsMap = [
  {
    label: 'white-labeling.event-status-colors.title',
    Component: EventStatusColors,
    isPremium: false,
  },
];

function StatusColors(props) {
  const classes = useStyles(props);
  const { displaySettingsData, setDisplaySettingsData, onChangeData, refreshColorPickers } = props;
  const statusColors = displaySettingsData['statusColors']?.content;

  const setStatusColorsData = (value) => {
    setDisplaySettingsData(value);
  };

  const onStatusColorsChanged = (key, value) => {
    onChangeData('statusColors', key, value);
  };

  return (
    <div>
      {Object.keys(displaySettingsData).length
        ? panelsMap.map((panel) => {
            return (
              <div className={styles.innerAccordionWrapper}>
                <Panel
                  {...panel}
                  key={panel.label}
                  classes={classes}
                  displaySettingsData={statusColors}
                  setDisplaySettingsData={setStatusColorsData}
                  onChangeData={onStatusColorsChanged}
                  refreshColorPickers={refreshColorPickers}
                />
              </div>
            );
          })
        : null}
    </div>
  );
}

export default StatusColors;
