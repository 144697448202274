import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@components/Table';
import Icon from '@components/Icon';
import TextField from '@components/Table/TableFields/TextField';
import { alphanumericSort, dynamicSort, statusToNumberConverter } from '@core/utils';
import { i18nService } from '@core/i18n/I18nService';

const useStyles = makeStyles({
  tableWrapper: {
    overflowY: 'auto',
    paddingBottom: '4px',
    height: '100%',
  },
  statusIcon: { width: '10px', height: '10px', marginLeft: '20px', paddingBottom: '5px' },
});

function StatusIcon({ status }) {
  const classes = useStyles();
  return (
    <div className={classes.statusIcon}>
      {status === 'PUBLISHED' ? <Icon type="validation_succeded" /> : null}
    </div>
  );
}

function ArWidgetsPanel(props) {
  const { onClick, widgetList } = props;
  const classes = useStyles();

  const colunmsCfg = useMemo(
    () => [
      {
        Component: TextField,
        field: 'name',
        label: 'general.name',
        style: { width: '38%' },
        isSortable: true,
      },
      {
        Component: TextField,
        field: 'typeToDisplay',
        label: 'general.type',
        style: { width: '38%' },
        isSortable: true,
      },
      {
        Component: StatusIcon,
        field: 'status',
        label: 'general.status',
        style: { width: '24%' },
        isSortable: true,
      },
    ],
    []
  );

  const orderData = (order: any) => {
    let converter = null;

    if (order.label === 'status') converter = statusToNumberConverter;

    return order.label === 'name'
      ? alphanumericSort(widgetList, order.label, order.orderSign)
      : widgetList.sort(dynamicSort(order.label, order.orderSign, converter));
  };

  return (
    <div className={classes.tableWrapper}>
      <Table
        data={widgetList}
        getData={(firstInit, order) => {
          orderData(order);
        }}
        colunmsCfg={colunmsCfg}
        onRowClicked={onClick}
      />
    </div>
  );
}

export default ArWidgetsPanel;
