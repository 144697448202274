const width = 'calc(20% - 5px)';

export const columnCfg = [
  {
    field: 'remark',
    style: { minWidth: width, width: width },
    headerLabel: 'create-widget-page.create-widget.step-four.caption',
  },
  {
    field: 'fromValue',
    style: { minWidth: width, width: width },
    headerLabel: 'create-widget-page.create-widget.step-four.ranges.from',
  },
  {
    field: 'toValue',
    style: { minWidth: width, width: width },
    headerLabel: 'create-widget-page.create-widget.step-four.ranges.to',
  },
  {
    field: 'backgroundColor',
    style: { minWidth: width, width: width },
    headerLabel: 'create-widget-page.create-widget.step-four.background',
  },
  {
    field: 'textColor',
    style: { minWidth: width, width: width },
    headerLabel: 'create-widget-page.create-widget.step-four.value-color',
  },
  {
    field: 'deleteButton',
    style: { minWidth: '20px', width: '20px' },
  },
];
