import React from 'react';

function IFrameRemoteAccessWidget({ remoteAccessUrl }) {
  return (
    <iframe
      allowFullScreen={true}
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        border: 'none',
        borderRadius: 'none',
        background: 'white',
      }}
      src={remoteAccessUrl}></iframe>
  );
}

export default IFrameRemoteAccessWidget;
