import React from 'react';
import classNames from 'classnames';
import styles from './TableBody.scss';
import { TableBodyProps } from '../table.interface';
import BodyRow from './BodyRow';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  rowHover: {
    '&:hover': {
      backgroundColor: (props: any) =>
        props.widgetType === 'table' || props.widgetType === 'alarms'
          ? props.customization
            ? props.customization?.highlightedTableWidgetRow
              ? props.customization?.highlightedTableWidgetRowColor
              : null
            : null
          : 'var(--highlightedTableRow)',
    },
  },
  row: {
    '&.clickable': {
      backgroundColor: 'transparent',
    },
    display: 'flex',
    alignItems: 'center',
    lineHeight: '18px',
    minHeight: '33px',
    padding: '4px 16px',
    color: (props: any) =>
      props.customization
        ? props.customization.lineFontColor || 'var(--widgetsFont)'
        : 'var(--systemFont)',
    backgroundColor: ({ customization, isAlt }: any) =>
      (isAlt && customization.altLineBackgroundColor) ||
      (customization && customization.lineBackgroundColor) ||
      'inherit',
    borderBottom: (props: any) =>
      props.customization && props.customization.dividerLineColor
        ? `1px solid ${props.customization.dividerLineColor}`
        : '1px solid var(--tableRowBorderBottom)',
  },
}));

const BodyRowWrapper = ({
  row,
  rowIndex,
  customization,
  onRowClicked,
  columnLayout,
  handleCellClick,
  eventTemplateId = null,
  widgetType = null,
  getIsRowClickable = null,
}) => {
  const isClickable = getIsRowClickable ? getIsRowClickable(row, rowIndex) : true;
  const isAlt = customization && customization.altLineBackground && rowIndex % 2;
  const classes = useStyles({ customization, isAlt, widgetType });
  const allowHighlightOnMouseover =
    !eventTemplateId &&
    ((widgetType && customization?.highlightedTableWidgetRow) || (!widgetType && !customization));

  return (
    <div
      key={`row${rowIndex * Math.random() * 9999}`}
      className={classNames(
        classes.row,
        allowHighlightOnMouseover && classes.rowHover,
        onRowClicked && isClickable && styles.clickable
      )}
      onClick={() => onRowClicked && onRowClicked(row, rowIndex)}>
      <BodyRow
        columnLayout={columnLayout}
        row={row}
        rowIndex={rowIndex}
        handleCellClick={handleCellClick}
      />
    </div>
  );
};

function TableBody(props: TableBodyProps) {
  const {
    data,
    columnLayout,
    onTableScroll,
    onRowClicked,
    tableBodyRef,
    hasPagination,
    onCellClicked,
    customization,
    eventTemplateId,
    widgetType,
    getIsRowClickable = null,
  } = props;

  const handleCellClick = (e, column, rowIndex) => {
    e.stopPropagation();
    onCellClicked({ column, rowIndex });
  };

  return (
    <div
      className={styles.tableBody}
      onScroll={(e) => hasPagination && onTableScroll(e)}
      ref={tableBodyRef}>
      {data &&
        data.map((row, rowIndex) => (
          <BodyRowWrapper
            key={`row${rowIndex * Math.random() * 9999}`}
            {...{
              row,
              rowIndex,
              customization,
              onRowClicked,
              columnLayout,
              handleCellClick,
              eventTemplateId,
              widgetType,
              getIsRowClickable,
            }}
          />
        ))}
    </div>
  );
}

export default TableBody;
