import React, { useMemo } from 'react';

import { ButtonProps } from './Button.interface';
import { buttonModes } from './ButtonModes';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const Button = (props: ButtonProps, ref) => {
  const {
    disabled = false,
    mode = 'default',
    onClick,
    styles = {},
    type = 'button',
    htmlElementId = 'button',
    title = undefined,
  } = props;

  const buttonStyles = useMemo(() => {
    const aggregatedStyles = { ...buttonModes[0].styles }; // Defualt button base styles

    if (Array.isArray(mode)) {
      buttonModes
        .filter((m) => mode.includes(m.mode)) // getting the requested modes styles
        .forEach((m) => Object.assign(aggregatedStyles, m.styles)); // building the combined style
    } else {
      const requestedMode = buttonModes.find((m) => m.mode === mode);
      Object.assign(aggregatedStyles, requestedMode ? requestedMode.styles : {}); // assigning style when mode is string
    }
    Object.assign(aggregatedStyles, styles);

    if (disabled) {
      Object.assign(aggregatedStyles, buttonModes.find((m) => m.mode === 'disabled').styles);
    }

    return aggregatedStyles;
  }, [mode, disabled, styles]);

  const useStyles = makeStyles({
    style: buttonStyles,
  });
  const classes = useStyles(buttonStyles);

  return (
    <button
      id={htmlElementId}
      ref={ref}
      type={type}
      className={classNames(classes.style, 'ellipsis-overflow')}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
      title={title}>
      {props.children}
    </button>
  );
};

export default React.forwardRef(Button);
