import React, { useState, useRef } from 'react';
import { makeStyles, Checkbox as MuiCheckbox } from '@material-ui/core';
import classNames from 'classnames';
import { cssVarsService } from '@core/CssVarsService';
import Icon from '@components/Icon';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
});
function Checkbox(props) {
  const classes = useStyles(props);

  return (
    <MuiCheckbox
      className={classes.root}
      disableRipple
      color="default"
      icon={<Icon disabled={props.disabled} type="checkbox" className={classes.icon}></Icon>}
      checkedIcon={
        <Icon
          disabled={props.disabled}
          type="checkedCheckbox"
          className={classes.icon}
          color={cssVarsService.vars.systemFontSelected}></Icon>
      }
      {...props}
    />
  );
}

export default Checkbox;
