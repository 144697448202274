import React, { useState, useEffect, useMemo } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { cloneDeep } from 'lodash';

import { optionsMap } from '../SelectSharedComponents/optionsMap';
import Icon from '@components/Icon';
import styles from './MultiSelect.scss';
import { MultiSelectProps } from './MultiSelect.interface';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@redux/useSelector';
import {
  setPlaceholder,
  setValue,
  onSelectionChange,
  onCreatableSelectChange,
  onFilterOption,
  numOfHiddenChips,
} from './MultiSelect.utils';

const modes = {
  thin: {
    multiValue: {
      height: '17px',
      alignItems: 'center',
      fontSize: '14px',
    },
    deleteIconHeight: '15px',
    deleteIconWidth: '24px',
    valuesChip: {
      height: '17px',
      alignItems: 'center',
      fontSize: '14px',
      boxSizing: 'inherit',
    },
  },
};

const customStyles = (props) => {
  const { menuPortal } = props.styles || {};

  return {
    option: (base, state) => ({
      ...base,
      height: '100%',
      padding: '4px 12px',
      backgroundColor: state.isSelected
        ? 'var(--multiSelectOptionSelected)'
        : state.isFocused
        ? 'var(--multiSelectOptionFocused)'
        : 'var(--white1)',
      color: 'var(--systemFont)',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    container: (base) => ({
      ...base,
      position: 'relative',
      height: 'inherit',
      width: '100%',
      maxHeight: '95px',
      display: 'flex',
    }),
    control: (base, state) => ({
      ...base,
      minHeight: '20px',
      width: '100%',
      height: state.isFocused ? 'none' : 'inherit',
      maxHeight: 96,
      position: 'absolute',
      overflow: 'hidden',
      border: props.errorStyle
        ? 'solid 1px var(--formikErrorBorder)'
        : state.isFocused
        ? 'solid 1px var(--darkerGrey)'
        : 'solid 1px var(--lightGrey)',
      boxShadow: 'none',
      zIndex: 1,
      '&:hover': {
        border: 'solid 1px var(--darkerGrey) !important',
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 12,
      color: 'var(--systemFont)',
      opacity: 0.5,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999999999999,
      ...menuPortal,
    }),
    menu: (base) => ({
      ...base,
      position: 'relative',
      top: 0,
      marginTop: 2,
      zIndex: 999999999999999,
    }),
    menuList: (base) => ({
      ...base,
    }),
    multiValue: (base, state) => ({
      ...base,
      width: state.selectProps.isFocused ? 'auto' : 120,
      borderRadius: `16px`,
      color: 'var(--systemFont)',
      backgroundColor: 'var(--multiSelectChip)',
      ...(props.mode && modes[props.mode].multiValue),
    }),
    valueContainer: (base, state) => ({
      ...base,
      overflowY: state.selectProps.isFocused ? 'auto' : 'hidden',
      maxHeight: 96,
      height: '100%',
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: 'var(--systemFont)',
      width: state.selectProps.isFocused ? 'auto' : 88,
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: 'var(--multiSelectRemoveIcon)',
      '& > div': {
        display: 'flex',
      },
      '&: hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: 'var(--multiSelectRemoveHover)',
      },
    }),
    input: (base) => ({
      ...base,
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      margin: 0,
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      height: 'inherit',
      display:
        state.selectProps.selectType === 'location' &&
        (!state.selectProps.value || !state.selectProps.value.length) &&
        'none',
    }),
  };
};

const valueContainerFocus = (props) => {
  return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
};

const onValueContainerBlur = (
  props,
  value,
  id,
  selectActions,
  inputWidth,
  hiddenChipsCount,
  valueWidth
) => {
  const hiddenChips = Array.isArray(value)
    ? numOfHiddenChips(value.length, id, selectActions, inputWidth, hiddenChipsCount, valueWidth)
    : 0;
  if (Array.isArray(value) && value.length && hiddenChips > 0) {
    return (
      // Display number of chips that fit the input, and add a chip with the number of hidden chips
      <components.ValueContainer {...props}>
        {props.children[0].slice(0, value.length - hiddenChips)}
        <div
          className={styles.hiddenValuesChip}
          style={
            props.selectProps.mode && modes[props.selectProps.mode].valuesChip
          }>{`+${hiddenChips}`}</div>
        {React.cloneElement(props.children[1])}
      </components.ValueContainer>
    );
  }
  // No hidden chips, render regularly
  return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
};

const MultiValueRemove = (props) => {
  const isOptionDisabled = props.selectProps.isOptionDisabled(props.data);
  // Remove button on a single chip
  return (
    !props.selectProps.isDisabled &&
    !isOptionDisabled && (
      <components.MultiValueRemove {...props}>
        <Icon
          height={props.selectProps.mode && modes[props.selectProps.mode].deleteIconHeight}
          width={props.selectProps.mode && modes[props.selectProps.mode].deleteIconWidth}
          type="materialDelete"
        />
      </components.MultiValueRemove>
    )
  );
};

const MultiValueLabel = (props, prefix) => {
  return (
    <components.MultiValueLabel {...props}>
      {prefix && <span>{`${prefix} `}</span>}
      {props.children}
    </components.MultiValueLabel>
  );
};

function MultiSelect(props: MultiSelectProps) {
  const {
    id,
    values = [],
    options,
    onChange,
    onInputChanged,
    filterOption,
    tableConfig,
    creatableValidation,
    getOptionLabel,
    getOptionValue,
    isOptionDisabled,
    isDisabled,
    placeholder,
    optionType = 'default',
    isCreatable = false,
    prefix,
    maxMenuHeight,
    mode,
    selectType,
    closeMenuOnSelect = false,
    closeMenuOnSelectAll = true,
    showPlaceholderOnFocus = false,
    isSelectAllAllowed = false,
    onSelectAll,
  } = props;

  const optionsWithAll = cloneDeep(props.options);
  isSelectAllAllowed &&
    !options.some((opt) => opt.value === 'SELECT_ALL') &&
    optionsWithAll.unshift({
      name: i18nService.translate('general.all'),
      value: 'SELECT_ALL',
    });

  const [valueContainer, setValueContainer] = useState(() => valueContainerFocus);
  const languageId = useSelector((state) => state.config.languageId);
  const [isFocused, setIsFocused] = useState(false);
  const OptionTemplate = optionsMap[optionType].option;
  const HeaderTemplate = optionsMap[optionType].header;
  const selectActions = 55;
  const valueWidth = 124;
  const hiddenChipsCount = 45;
  const inputWidth = 2;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const renderedPlaceholder = useMemo(() => {
    return setPlaceholder(placeholder, languageId);
  }, [placeholder, languageId]);

  const selectValue = useMemo(() => {
    return setValue(values, getOptionValue);
  }, [values, languageId]);

  const onChangedInput = (value) => {
    onInputChanged(value);
  };

  const valueContainerBlur = (props) => {
    const {
      selectProps: { value },
    } = props;
    return onValueContainerBlur(
      props,
      value,
      id,
      selectActions,
      inputWidth,
      hiddenChipsCount,
      valueWidth
    );
  };

  // Closed menu display
  const onMenuClose = () => {
    setMenuIsOpen(false);
    setValueContainer(() => valueContainerBlur);
    setIsFocused(false);
  };

  // Opened menu display
  const onMenuOpen = () => {
    setMenuIsOpen(true);
    setValueContainer(() => valueContainerFocus);
    setIsFocused(true);
  };

  useEffect(() => {
    setValueContainer(() => valueContainerBlur);
  }, []);

  const defaultGetOptionLabel = (option) => {
    return i18nService.translate(option.label);
  };

  const handleSelectionChange = (e, onChange, onSelectAll?) => {
    if (e?.length && e.some((item) => item.value === 'SELECT_ALL') && onSelectAll) {
      onSelectionChange(e, onSelectAll);
      onMenuClose();
    } else {
      onSelectionChange(e, onChange);
    }

    if (closeMenuOnSelectAll && options.length == e?.length) onMenuClose();
  };

  return !isCreatable ? (
    <Select
      id={id}
      isMulti
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        MultiValueRemove,
        ValueContainer: valueContainer,
        MultiValueLabel: (props) => MultiValueLabel(props, prefix),
        Option: OptionTemplate,
        MenuList: HeaderTemplate,
      }}
      styles={customStyles(props)}
      value={selectValue}
      isClearable={false}
      menuIsOpen={menuIsOpen}
      onInputChange={onInputChanged ? onChangedInput : null}
      onChange={(e) => handleSelectionChange(e, onChange, onSelectAll)}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      getOptionValue={getOptionValue}
      filterOption={(option, rawInput) => onFilterOption(filterOption, option, rawInput)}
      isFocused={isFocused}
      isDisabled={isDisabled}
      isOptionDisabled={
        isSelectAllAllowed
          ? (option) => {
              return !!(selectValue?.some((sv) => sv.value === 'SELECT_ALL') &&
              option.value !== 'SELECT_ALL'
                ? true
                : isOptionDisabled);
            }
          : isOptionDisabled
      }
      hideSelectedOptions={false}
      options={isSelectAllAllowed ? optionsWithAll : options}
      mode={mode}
      selectType={selectType}
      placeholder={(!isFocused || showPlaceholderOnFocus) && renderedPlaceholder}
      tableConfig={tableConfig}
      noOptionsMessage={() => null}
      menuPortalTarget={document.getElementById('mainContent') || document.body}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={'auto'}
    />
  ) : (
    <CreatableSelect
      id={id}
      isMulti
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      filterOption={filterOption}
      components={{
        MultiValueRemove,
        ValueContainer: valueContainer,
      }}
      styles={customStyles(props)}
      value={values.map((val) => {
        return { label: val, value: val };
      })}
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onInputChange={onInputChanged ? onChangedInput : null}
      onChange={(e) => onCreatableSelectChange(e, onChange)}
      onCreateOption={(e) => {
        creatableValidation
          ? creatableValidation(e) && onChange([...values, e])
          : onChange([...values, e]);
      }}
      placeholder={renderedPlaceholder}
      mode={mode}
      menuPortalTarget={document.getElementById('mainContent') || document.body}
      noOptionsMessage={() => i18nService.translate('multi-select.create-option', languageId)}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={'auto'}
    />
  );
}
export default MultiSelect;
