import { cloneDeep } from 'lodash';

export const setMetrics = (setWidgetData, widgetData) => {
  if (
    !widgetData.customization &&
    widgetData.scope === 'LAST_VALUE' &&
    widgetData.assetProperties &&
    widgetData.assetProperties.find((a) => a.id === 1)
  ) {
    setWidgetData((prevState) => {
      let newMetrics = cloneDeep(prevState.metrics);
      if (
        newMetrics &&
        Array.isArray(newMetrics) &&
        !newMetrics.some(
          (metric) =>
            metric.valueType === 'ASSET_PROPERTY' && metric.valueId === 1 && !metric.operation
        )
      ) {
        newMetrics.push({
          hide: true,
          operation: null,
          order: prevState.metrics.length,
          type: 'stringType',
          valueId: 1,
          valueType: 'ASSET_PROPERTY',
          name: 'create-widget-page.create-widget.asset-properties.ASSET_ID',
        });
      }
      return {
        ...prevState,
        metrics: newMetrics,
      };
    });
  }
};
