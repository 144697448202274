import { cssVarsService } from '@core/CssVarsService';
import { i18nService } from '@core/i18n/I18nService';
import { compact, concat, cloneDeep } from 'lodash';

import {
  DateTimeType,
  UpdateAssetButtonCustomization,
  UpdateAssetCustomization,
  UpdateAssetRequestSentTextCustomization,
  UpdateAssetSwitchCustomization,
  UpdateAssetTextualRemarkCustomization,
  WidgetUpdateAssetTag,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { updateWidgetCustomization } from '../widget.utils';

const dataTypeIconMap = {
  INT32: 'numberType',
  UINT32: 'numberType',
  FLOAT32: 'floatType',
  BOOLEAN: 'booleanType',
  STRING: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
  INT8: 'numberType',
  UINT8: 'numberType',
  INT16: 'numberType',
  UINT16: 'numberType',
  GEO: 'geoType',
};

const buildOptions = (option, type) => {
  const tagFormat = type === 'TAG' ? option.format : option.type;
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
  };
};

const setScopeAndMetrics = (tags, setWidgetData) => {
  setWidgetData((prevState) => {
    let newMetrics = tags.map((t, index) => {
      const matchingMatrics = prevState.metrics?.find((m) => m.valueId === t.id);

      return {
        order: matchingMatrics ? matchingMatrics.order : index,
        valueType: t.tagType,
        type: dataTypeIconMap[t.type],
        valueId: t.id,
        name: t.name,
        operation: null,
      };
    });

    newMetrics.sort((m1, m2) => m1.order - m2.order);

    return {
      ...prevState,
      scope: 'LAST_VALUE',
      metrics: newMetrics,
    };
  });
};

export const getMergedTagsConfigurationChanges = (
  currentConfiguration: WidgetUpdateAssetTag[],
  tags
): WidgetUpdateAssetTag[] => {
  let newConfiguration = cloneDeep(currentConfiguration);
  // Remove tags that were de-selected
  newConfiguration = newConfiguration.filter((el) => {
    return tags.map((t) => t.id).indexOf(el.tagId) >= 0;
  });
  // Add tags that are newly selected
  const needToBeAddedTags = tags
    .filter((t) => {
      return newConfiguration.map((el) => el.tagId).indexOf(t.id) < 0;
    })
    .map((t) => ({
      tagId: t.id,
      tagType: t.tagType,
      format: t.type,
      name: t.name,
      displayName: t.name,
      order: 0,
      validate: false,
      min: null,
      max: null,
      decimalDigits: null,
      dateTimeFormat: getTagDateTimeFormat(t.type),
    }));

  newConfiguration = newConfiguration.concat(needToBeAddedTags);

  // update order
  let index = 0;
  for (const element of newConfiguration) {
    element.order = index++;
  }

  return newConfiguration;
};

const setCustomizationData = (customization, data, key, setWidgetData, section = null) => {
  const values = cloneDeep(customization);
  if (section) values[section][key] = data;
  else values[key] = data;

  updateWidgetCustomization(values, setWidgetData);
};

export const setMetricsAndCustomization = (setWidgetData, widgetData) => {
  const { tags, tagTypes, metrics } = widgetData || {};
  const data = compact(
    concat(
      tags.map((a) => buildOptions(a, 'TAG')),
      tagTypes.map((a) => buildOptions(a, 'TAG_TYPE'))
    )
  );

  if (!widgetData.customization)
    updateWidgetCustomization(getDefaultUpdateAssetCustomization(data, metrics), setWidgetData);
  else
    setCustomizationData(
      widgetData.customization,
      getMergedTagsConfigurationChanges(widgetData.customization.tagsConfiguration, data),
      'tagsConfiguration',
      setWidgetData
    );

  setScopeAndMetrics(data, setWidgetData);
};

export const getDefaultUpdateAssetCustomization = (tags, metrics): UpdateAssetCustomization => ({
  doNotRefreshWhileEditing: false,
  tagsConfiguration: getTagsConfiguration(tags, metrics),
  textualRemark: getDefaultTextualRemarkCustomization(),
  requestSentText: getDefaultRequestSentTextCustomization(),
  switchCustomization: getDefaultSwitchCustomization(),
  updateButtonCustomization: getDefaultButtonCustomization(),
});

const getDefaultTextualRemarkCustomization = (): UpdateAssetTextualRemarkCustomization => ({
  text: null,
  fontSize: 12,
  color: cssVarsService.vars.widgetsFont,
  horizontalAlignment: 'LEFT',
  verticalAlignment: 'BOTTOM',
});

const getDefaultRequestSentTextCustomization = (): UpdateAssetRequestSentTextCustomization => ({
  text: i18nService.translate(
    'create-widget-page.create-widget.step-four.switch.request-sent-text.default'
  ),
  fontSize: 12,
  color: cssVarsService.vars.widgetsFont,
});

const getDefaultSwitchCustomization = (): UpdateAssetSwitchCustomization => ({
  onText: i18nService.translate(
    'create-widget-page.create-widget.step-four.switch.on-text.default'
  ),
  offText: null,
  color: cssVarsService.vars.systemButtonBackground,
});

const getDefaultButtonCustomization = (): UpdateAssetButtonCustomization => ({
  text: i18nService.translate('create-widget-page.create-widget.step-four.update-asset.update'),
  color: cssVarsService.vars.widgetsFont,
  backgroundColor: cssVarsService.vars.systemButtonBackground,
  disabledColor: '#A4A4A4',
  horizontalAlignment: 'CENTER',
});

const getTagsConfiguration = (tags, metrics): WidgetUpdateAssetTag[] => {
  const tagsConfiguration = tags
    .map((t, index) => {
      const matchingMatrics = metrics?.find((m) => m.valueId === t.id);

      return {
        tagId: t.id,
        tagType: t.tagType,
        format: t.type,
        name: t.name,
        displayName: t.name,
        order: matchingMatrics ? matchingMatrics.order : index,
        validate: false,
        min: null,
        max: null,
        decimalDigits: getTagDecimalDigits(t.type),
        dateTimeFormat: getTagDateTimeFormat(t.type),
      };
    })
    .sort((m1, m2) => m1.order - m2.order);

  return tagsConfiguration;
};

const getTagDecimalDigits = (tagType): number => {
  switch (tagType) {
    case 'FLOAT32':
      return 5;
    default:
      return null;
  }
};

const getTagDateTimeFormat = (tagType): DateTimeType => {
  switch (tagType) {
    case 'DATE':
      return 'DATE';
    case 'DATETIME':
      return 'TIME_FORMAT_ONE';
    default:
      return null;
  }
};
