import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Table from '@components/Table';
import { useSelector } from '@redux/useSelector';
import styles from './AssetsTable.scss';
import { getFlagStatus } from '@core/ffAndPermissions';
import { compact } from 'lodash';
import { getAssets } from './AssetsTable.utils';
import TextField from '@components/Table/TableFields/TextField';
import { AssetTableProps } from './AssetsTable.interface';
import AssignButton from './AssignButton/AssignButton';
import SubscriptionData from './SubscriptionData/SubscriptionData';

const getColunmsCfg = (subscriptionId, subscriptionDescription, dismiss) =>
  compact([
    {
      Component: TextField,
      label: 'assets-tab.asset-id',
      field: 'assetId',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-name',
      field: 'assetName',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'add-subscription-to-asset-modal.asset-Type',
      field: 'assetType',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: SubscriptionData,
      label: 'assets-tab.subscription-status',
      field: 'subscriptionStatus',
      style: { width: '25%' },
      isSortable: true,
      isFilterable: true,
      filterType: 'checkbox',
      filterOptions: [
        { label: `enum.subscription.plc.NOT_AVALIABLE`, value: 'NOT_AVAILABLE' },
        { label: `enum.subscription.plc.ACTIVE`, value: 'ACTIVE' },
        { label: `enum.subscription.plc.ABOUT_TO_EXPIRE`, value: 'ABOUT_TO_EXPIRE' },
        { label: `enum.subscription.plc.EXPIRED_GRACE`, value: 'EXPIRED_GRACE' },
        { label: `enum.subscription.plc.EXPIRED`, value: 'EXPIRED' },
        { label: `enum.subscription.plc.EVALUATION`, value: 'EVALUATION' },
      ],
      filterAll: true,
    },
    {
      Component: AssignButton,
      type: 'staticEnd',
      label: '',
      field: 'assign',
      style: { width: 100 },
      subscriptionId: subscriptionId,
      description: subscriptionDescription,
      dismiss: dismiss,
    },
  ]);

function AssetsTable(props: AssetTableProps) {
  const [data, setData] = useState([]);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [_order, setOrder] = useState(null);
  const {
    searchText,
    licenseId,
    subscriptionId,
    subscriptionDescription,
    dismiss,
    setSearchResults,
  } = props;

  const colunmsCfg = useMemo(() => {
    return getColunmsCfg(subscriptionId, subscriptionDescription, dismiss);
  }, [subscriptionId]);

  const pageSize = useMemo(() => 50, []);

  useEffect(
    () =>
      getAssets(
        setNextPage,
        searchText,
        pageSize,
        nextPage,
        setDisableLoad,
        true,
        licenseId,
        data,
        setData,
        setSearchResults,
        _order
      ),
    [searchText]
  );

  useEffect(() => {
    getAssets(
      setNextPage,
      searchText,
      pageSize,
      nextPage,
      setDisableLoad,
      true,
      licenseId,
      data,
      setData,
      setSearchResults,
      _order
    );
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={data}
        getData={(firstInit, order, filters?) => {
          order && setOrder(order);
          getAssets(
            setNextPage,
            searchText,
            pageSize,
            nextPage,
            setDisableLoad,
            firstInit,
            licenseId,
            data,
            setData,
            setSearchResults,
            order,
            filters
          );
        }}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default AssetsTable;
