import React, { useState, useEffect } from 'react';
import MultiSelect from '@components/MultiSelect';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import { AssetPropertiesResponse } from '../StepOne.interface';
import { assetsPropsMap } from '../../../widgets.utils';
import styles from '../StepOne.scss';
import { useSelector } from '@src/redux/useSelector';
import { remove } from 'lodash';
import classNames from 'classnames';
import SingleSelectWithChip from '@components/SingleSelectWithChip';

const prepareAssetOptions = (options) => {
  return options.map((option) => ({
    id: option.id,
    type: option.type,
    name: `create-widget-page.create-widget.asset-properties.${assetsPropsMap[option.id]}`,
  }));
};

const AssetPropertiesInput = ({
  assetProperties,
  onSelectedAssetProperties,
  widgetType,
  widgetData,
  isMaxDataTags,
  showCaption = true,
  additionalStyles = null,
  isDisabled = false,
  isMulti = true,
}) => {
  const [assetPropertiesOptions, setAssetPropertiesOptions] = useState([]);
  const languageId = useSelector((state) => state.config.languageId);

  useEffect(() => {
    const request = httpService.api<AssetPropertiesResponse>({
      type: 'getAssetProperties',
    });
    request.then((res) => {
      let options = res.assetProperties?.filter(
        (prop) => (widgetType == 'alarms' && prop.type != 'DATE') || widgetType !== 'alarms'
      );

      // Moving PLC Serial Number right after PLC Catalog Number
      const indexOfPlcCatalogNumber = options.indexOf(
        options.find((option) => option.id === 13 || option.id === '13')
      );

      const plcSerialNumber = remove(
        options,
        options.find((option) => option.id === 19 || option.id === '19')
      );

      options.splice(indexOfPlcCatalogNumber + 1, 0, plcSerialNumber[0]);

      setAssetPropertiesOptions(prepareAssetOptions(options));
    });

    return () => {
      request.cancel();
    };
  }, []);

  useEffect(() => {
    if (
      getConfigValueFromWidgetSettings(widgetType, 'defaultAssetProperties') &&
      assetPropertiesOptions.length > 0 &&
      (!assetProperties || assetProperties?.length === 0)
    ) {
      //set default selected options
      const assetProperties = assetPropertiesOptions.filter((option) => {
        return getIsIncludeInSettings(widgetType, 'defaultAssetProperties', option.id);
      });
      assetProperties.length > 0 && onSelectedAssetProperties(assetProperties);
    }
  }, [assetPropertiesOptions, widgetType]);

  const onChange = (e) => {
    // Checking if one of 'defaultAssetProperties' values has been removed
    var func = (acc, assetId) => (!e.map((asset) => asset.id).includes(assetId) ? ++acc : acc);
    let hasForbidden = getConfigValueFromWidgetSettings(
      widgetType,
      'defaultAssetProperties'
    )?.reduce(func, 0);

    return !hasForbidden && onSelectedAssetProperties(e);
  };

  return (
    <div className={classNames(styles.dataField, additionalStyles?.dataField)}>
      {showCaption && (
        <div className={styles.fieldLabel}>
          <I18n>create-widget-page.create-widget.step-one.asset-properties</I18n>
          {assetProperties?.length > 0 && ` (${assetProperties?.length})`}
          <div className={classNames(styles.fieldSubLabel, additionalStyles?.fieldSubLabel)}>
            <I18n>
              {!widgetData.eventTemplateId
                ? 'create-widget-page.create-widget.step-one.asset-properties-sub'
                : 'create-event-data-source-page.create-event-data-source.step-one.asset-properties-sub'}
            </I18n>
          </div>
        </div>
      )}
      <div className={classNames(styles.inputField, additionalStyles?.inputField)}>
        {!isMulti ? (
          <SingleSelectWithChip
            id={'assetProps'}
            isDisabled={
              getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'AssetProperties') || isDisabled
            }
            values={assetProperties?.length > 0 ? prepareAssetOptions(assetProperties) : []}
            options={assetPropertiesOptions}
            getOptionLabel={(option) => i18nService.translate(option.name, languageId)}
            getOptionValue={(option) => option.id}
            onChange={onChange}
            tableConfig={(option) => ({ type: option.type })}
            optionType="assetProps"
          />
        ) : (
          <MultiSelect
            id="assetProps"
            isOptionDisabled={(option) =>
              getIsIncludeInSettings(widgetType, 'defaultAssetProperties', option.id) ||
              (isMaxDataTags && !assetProperties.find((property) => property.id === option.id))
            }
            closeMenuOnSelect={widgetType === 'map'}
            isDisabled={
              getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'AssetProperties') || isDisabled
            }
            values={assetProperties?.length > 0 ? prepareAssetOptions(assetProperties) : []}
            options={assetPropertiesOptions}
            getOptionLabel={(option) => i18nService.translate(option.name, languageId)}
            getOptionValue={(option) => option.id}
            tableConfig={(option) => ({
              type: option.type,
            })}
            onChange={onChange}
            optionType="assetProps"
          />
        )}
      </div>
    </div>
  );
};

export default AssetPropertiesInput;
