import Table from '@components/Table';
import { httpService } from '@core/http/HttpService';
import React, { useEffect, useMemo, useState } from 'react';
import ActivateButton from './ActivateButton/ActivateButton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flex: '0 0 auto',
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    width: '100%',
    height: 'calc(100% - 294px)',
    overflowY: 'scroll',
  },
}));

const SubscriptionsTableTab = (props) => {
  const classes = useStyles(props);
  const [data, setData] = useState() as any;
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const PAGE_SIZE = useMemo(() => 35, []);

  const getData = async (firstInit = false, order?) => {
    try {
      setDisableLoad(true);

      const res: any = await httpService.api({
        type: 'getOrganizationSubscriptionList',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: PAGE_SIZE,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      });
      if (res) {
        res.next && setDisableLoad(false);

        if (firstInit) {
          setData(res.results);
          setNextPage(2);
        } else {
          const newData = data.concat(res.results);
          setData(newData);
          setNextPage(nextPage + 1);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    getData(true);
  }, []);

  const LabelComponent = (props) => {
    return (
      <div
        className="ellipsis-overflow"
        style={{ minWidth: '62px' }}
        title={`${props[props.field]}`}>
        {props[props.field]}
      </div>
    );
  };

  const colunmsCfg = [
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.licensePlan',
      field: 'licenseName',
      style: { width: 'calc(100% / 6)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.catalogNumber',
      field: 'catalogNumber',
      style: { width: 'calc(100% / 6)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.description',
      field: 'description',
      style: { width: 'calc(100% / 6)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.subscriptionKey',
      field: 'subscriptionKey',
      style: { width: 'calc(100% / 6)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.endDate',
      field: 'endDate',
      style: { width: 'calc(100% / 6)' },
      isSortable: true,
    },
    {
      Component: ActivateButton,
      label: 'my-account-page.licensing-plan.table.subscription-status',
      field: 'status',
      style: { width: 'calc(100% / 6)' },
      isSortable: true,
      subscriptionId: 'subscriptionId',
      refresh: () => getData(true),
    },
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.box}>
          <Table
            data={data || []}
            getData={getData}
            colunmsCfg={colunmsCfg}
            disabledLoad={disabledLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsTableTab;
