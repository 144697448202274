import { RadialGaugeCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface.d';
import { GaugeCustomizationSectionProps } from '../GaugeCustomizationSection/GaugeCustomizationSection.interface';
import { hasValue } from '@core/canvas/widget.utils';
import { changeWidgetData, getDefaultGaugeCustomization } from '../GaugeCustomizationUtils';

export const getDefaultRadialGaugeCustomization = (defaultDecimalDigits: number): RadialGaugeCustomization => ({
  ...getDefaultGaugeCustomization(defaultDecimalDigits),
  startAngle: 45,
  degreesSize: 270,
});

export const getGaugeRadiusConfig = (
  customization: RadialGaugeCustomization,
  setWidgetData
): GaugeCustomizationSectionProps[] => {
  const { startAngle, degreesSize } = customization;

  return [
    {
      sectionLabel: 'gauge-radius',
      fields: [
        {
          label: 'data-start-angle',
          value: startAngle,
          onChange: (val) => {
            if (hasValue(val) && (val < 0 || val > 359)) return;
            changeWidgetData('startAngle', val, setWidgetData);
          },
          type: 'number',
          required: true,
        },
        {
          label: 'size-in-degrees',
          value: degreesSize,
          onChange: (val) => {
            if (hasValue(val) && (val < 0 || val > 359)) return;
            changeWidgetData('degreesSize', val, setWidgetData);
          },
          type: 'number',
          required: true,
        },
      ],
    },
  ];
};

export const getRadialNeedleConfig = (
  customization: RadialGaugeCustomization,
  setWidgetData
): GaugeCustomizationSectionProps[] => {
  const { needleVisible } = customization;

  return [
    {
      sectionLabel: 'needle',
      fields: [
        {
          label: 'visible',
          value: needleVisible,
          onChange: (val) => changeWidgetData('needleVisible', val, setWidgetData),
          type: 'checkbox',
        },
      ],
    },
  ];
};
