import { cssVarsService } from '@core/CssVarsService';
import { i18nService } from '@core/i18n/I18nService';
import { numberFormatter } from '@core/utils';
import { HeatmapCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { getMetricName } from '@pages/CreateWidgetPage/widgets.utils';
import { getState } from '@src/redux/store';
import { dateTimeOptionsMap } from '../charts.utils';
import moment from 'moment';
import { debounce } from 'lodash';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';

export const getValueWithDecimalDigits = (val, decimalDigits) =>
  isNaN(Number(val)) ? val : numberFormatter(+val, decimalDigits);

export function getHeatmapOptions(
  customization,
  data,
  defaultDecimalDigits,
  navigateDashboard,
  widget
) {
  const {
    chartType = 'REGULAR',
    dataLabels = {
      visible: false,
      textColor: 'var(--white1)',
      decimalDigits: defaultDecimalDigits,
    },
    dateFormat = 'DATE',
    tooltip = {
      visible: true,
      showAxisLegend: true,
      decimalDigits: 2,
    },
    reverseNegativeShade = false,
    rangesChart = {
      ranges: [],
      legend: {
        visible: true,
        position: 'TOP',
        alignment: 'CENTER',
      },
      shaded: false,
    },
    subtitle = {
      alignment: 'CENTER',
      text: '',
      textColor: cssVarsService.vars.widgetsFont,
    },
  } = customization as HeatmapCustomization;

  const dateTime = getState().config?.whiteLabelDetails?.dateTime?.content;
  const dateTimeGroupByType = data?.groupByColumns?.find(
    (c) => c.valueId === 7 && c.valueType === 'ASSET_PROPERTY'
  )?.operation;

  const graphsColorsPalette =
    getState().config?.whiteLabelDetails?.widgetsColors?.content?.graphsColorsPalette || [];

  const chartSpecificOptions =
    chartType === 'RANGES'
      ? {
          plotOptions: {
            heatmap: {
              radius: 0,
              enableShades: rangesChart.shaded,
              colorScale: {
                ranges: rangesChart.ranges.map((range) => ({
                  from: range.fromValue,
                  to: range.toValue,
                  color: range.backgroundColor,
                  foreColor: range.textColor,
                  name: range.remark,
                })),
                inverse: false,
                min: undefined,
                max: undefined,
              },
              shadeIntensity: 0.5,
              reverseNegativeShade: reverseNegativeShade,
              distributed: false,
              useFillColorAsStroke: false,
            },
          },
          legend: {
            show:
              chartType === 'RANGES' &&
              rangesChart.legend.visible &&
              !rangesChart.ranges.some((range) => range.fromValue == null || range.toValue == null),
            position: rangesChart.legend.position.toLowerCase(),
            horizontalAlign: rangesChart.legend.alignment.toLowerCase(),
            showForSingleSeries: true,
            offsetY: rangesChart.legend.position === 'TOP' ? 17 : 0,
            labels: {
              colors: 'var(--widgetsFont)',
              useSeriesColors: false,
            },
            markers: {
              size: 5,
              shape: 'circle', // circle, square, line, plus, cross
              strokeWidth: 0,
              fillColors: rangesChart.ranges.map((r) => r.backgroundColor),
              radius: 2,
              customHTML: undefined,
              onClick: undefined,
              offsetX: -2,
              offsetY: 0,
            },
          },
        }
      : {
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.5,
              reverseNegativeShade: reverseNegativeShade,
              enableShades: true,
              colorScale: { ranges: [] },
            },
          },
        };

  return {
    chart: {
      animations: { enabled: false },
      toolbar: {
        export: {
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
      id: 'heatmap_chart',
      zoom: {
        enabled: false,
      },
      events: {
        dataPointSelection: debounce(
          (event, chartContext, config) =>
            onDataPointClick(event, chartContext, config, navigateDashboard, widget),
          500
        ),
        dataPointMouseEnter: (e) => {
          e.target.style.cursor =
            navigateDashboard && widget?.navigationDashboard?.length && 'pointer';
        },
        dataPointMouseLeave: (e) => {
          e.target.style.cursor = 'auto';
        },
      },
    },
    dataLabels: {
      enabled: dataLabels.visible,
      formatter(val, opts) {
        if (val === null) {
          return `${i18nService.translate('general.na')}`;
        } else return getValueWithDecimalDigits(val, dataLabels.decimalDigits);
      },
      style: {
        colors: [dataLabels.textColor],
        fontSize: '12px',
        fontWeight: 500,
      },
      dropShadow: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'category',
      categories:
        dateTimeGroupByType === 'DAILY'
          ? data?.xaxis?.categories.map((cat) => {
              return moment(cat).format(dateTime[dateTimeOptionsMap[dateFormat]].momentFormat);
            })
          : data?.xaxis.categories,
      labels: {
        style: {
          colors: 'var(--widgetsFont)',
        },
      },
    },
    yaxis: {
      labels: {
        style: { colors: 'var(--widgetsFont)' },
      },
    },
    colors: graphsColorsPalette,
    theme: {
      palette: 'palette1',
    },
    tooltip: {
      shared: false,
      intersect: false,
      enabled: tooltip.visible,
      y: {
        formatter(val) {
          if (val === null) {
            return `${i18nService.translate('general.no-data')}`;
          } else return getValueWithDecimalDigits(val, tooltip.decimalDigits);
        },
      },
      x: {
        show: tooltip.showAxisLegend,
      },
      theme: 'apexcharts-tooltip',
    },
    subtitle: {
      text: subtitle.text,
      align: subtitle.alignment.toLowerCase(),
      margin: 5,
      style: {
        color: subtitle.textColor,
      },
    },
    ...chartSpecificOptions,
  };
}

export function getSeriesWithNames(widgetData, data, customization, series) {
  const newSeries = series.map((res) => {
    const metric = data.metricsColumns?.find((mCol) => mCol.name === res.name);
    return {
      ...res,
      name: customization?.columns
        ? customization.columns.find(
            (col) =>
              col.valueType === metric.valueType &&
              col.id === metric.valueId &&
              col.operation === metric.operation
          )?.displayName
        : metric?.operation
        ? `${getMetricName(widgetData, metric)} ${metric.operation.toLowerCase()}`
        : getMetricName(widgetData, metric),
    };
  });
  return newSeries;
}

function onDataPointClick(event, chartContext, config, navigateDashboard, widget) {
  const seriesIndex = config.seriesIndex;
  const series = config.w.config.series;
  const selectedSeries = seriesIndex >= 0 ? series[seriesIndex] : null;
  const assetId = selectedSeries?.name;
  const dataPointIndex = config.dataPointIndex;
  const selectedValue =
    seriesIndex >= 0 && dataPointIndex >= 0 ? series[seriesIndex].data[dataPointIndex] : null;

  navigateDashboard &&
    widget.navigationDashboard &&
    navigationDataBuilder({
      selectedValue,
      selectedColumn: selectedSeries,
      widget,
      columns: null,
      rawValues: { asset_id: +assetId },
      navigateDashboard,
    });
}
