import React from 'react';
import styles from './LayoutMode.scss';
import Icon from '@components/Icon';
function LayoutMode(props) {
  const { txtColor = 'var(--systemEnableEditorIcons)' } = props;

  const isMulti =
    props.status === 'published' || props.status === 'combined'
      ? props.published.splitLayout
      : props.draft.splitLayout;

  const config = [
    {
      type: 'desktop',
      tooltipText: 'dashboard-editor.desktop-layout',
      isEnabled: true,
    },
    {
      type: 'tablet',
      tooltipText: 'dashboard-editor.tablet-layout',
      isEnabled: isMulti,
    },
    {
      type: 'mobile',
      tooltipText: 'dashboard-editor.mobile-layout',
      isEnabled: isMulti,
    },
  ];

  return (
    <div className={styles.controlSwitch}>
      {config.map(
        (button, idx) =>
          button.isEnabled && (
            <Icon
              key={idx}
              color={txtColor}
              type={button.type}
              width={'25px'}
              height={'19px'}
              tooltipText={button.tooltipText}
              style={{
                cursor: 'default',
              }}
            />
          )
      )}
    </div>
  );
}

export default LayoutMode;
