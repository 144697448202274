import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import ColorInput from '@components/ColorInput';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme: any) => ({
  colorSettings: {
    marginTop: 40,
    width: '100%',
  },
  colorContainer: {
    marginTop: 20,
    paddingLeft: 10,
  },
  title: {
    width: '100%',
    fontWeight: 800,
  },
  color: {
    display: 'flex',
  },
  checkBox: {
    marginRight: 10,
    marginBottom: 20,
  },
}));

function TableColorSettings(props) {
  const classes = useStyles(props);
  const { setWidgetData, customization, eventTemplateId } = props;

  const colorSettings = [
    {
      label: 'table-customization.header-background-color',
      colorKey: 'headerBackgroundColor',
      labelWidth: 311,
    },
    {
      label: 'table-customization.header-font-color',
      colorKey: 'headerFontColor',
      labelWidth: 311,
    },
    {
      label: 'table-customization.divider-line-color',
      colorKey: 'dividerLineColor',
      labelWidth: 311,
    },
    {
      label: 'table-customization.line-font-color',
      colorKey: 'lineFontColor',
      labelWidth: 311,
    },
    {
      label: 'table-customization.line-background-color',
      colorKey: 'lineBackgroundColor',
      labelWidth: 311,
    },
    {
      label: 'table-customization.alternating-line-background-color',
      colorKey: 'altLineBackgroundColor',
      checkBoxKey: 'altLineBackground',
      labelWidth: 280,
    },
  ];

  !eventTemplateId &&
    colorSettings.push({
      label: 'table-customization.highlight-row-on-mouseover',
      colorKey: 'highlightedTableWidgetRowColor',
      checkBoxKey: 'highlightedTableWidgetRow',
      labelWidth: 280,
    });

  const onChangeColor = (key, value, setWidgetData) => {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: { ...prevState.customization, [key]: value },
    }));
  };

  return (
    <div className={classes.colorSettings}>
      <I18n element="div" className={classes.title}>
        table-customization.table-color-settings
      </I18n>
      <div className={classes.colorContainer}>
        {customization &&
          colorSettings.map((field, idx) => (
            <div className={classes.color} key={idx}>
              {field.checkBoxKey && (
                <MaterialCheckbox
                  className={classes.checkBox}
                  color="primary"
                  onChange={(e) =>
                    onChangeColor(field.checkBoxKey, e.target.checked, setWidgetData)
                  }
                  checked={customization[field.checkBoxKey]}
                />
              )}
              <ColorInput
                {...field}
                value={customization[field.colorKey]}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                disabled={(field.checkBoxKey && !customization[field.checkBoxKey]) || false}
                colorChange={(value) => onChangeColor(field.colorKey, value, setWidgetData)}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default TableColorSettings;
