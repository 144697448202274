import { ViewportState, StoreAction } from './redux.interface';

export const resetLiveDashboardWidgetsData = () => ({
  type: 'liveDashboardWidgetsData/RESET',
});

export const setLiveDashboardWidgetsData = (payload): StoreAction => ({
  type: 'liveDashboardWidgetsData/SET',
  payload,
});

export const updateLiveDashboardWidgetsData = (payload): StoreAction => ({
  type: 'liveDashboardWidgetsData/UPDATE',
  payload,
});

const initialState = {};

export default function liveDashboardWidgetsDataReducer(state = initialState, action: StoreAction) {
  switch (action.type) {
    case 'liveDashboardWidgetsData/SET':
      return { ...state, ...action.payload };
    case 'liveDashboardWidgetsData/UPDATE':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.data,
        },
      };
    case 'liveDashboardWidgetsData/RESET':
      return {};
    default:
      return state;
  }
}
