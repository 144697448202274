import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { isEqual } from 'lodash';

import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { ProgressBarWidgetProps } from './ProgressBarWidget.interface';
import { usePrevious } from '@core/hooks/usePrevious';
import Image from '@components/Image';
import { widgetMap } from '@core/canvas/widgetMap';
import { numberFormatter } from '@core/utils';
import { getCustomizationValueFromResults } from '@pages/CreateWidgetPage/widgets.utils';

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 50px',
    '& :hover': {
      cursor: ({ navigateDashboard, widget }: any) =>
        navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
    },
  },
  textualRemark: {
    paddingTop: 15,
    fontSize: ({ textualRemarkFontSize }) => textualRemarkFontSize || 12,
    fontWeight: 500,
    color: ({ textualRemarkColor }: any) => textualRemarkColor,
  },
  wrapper: {
    height: ({ textualRemark, textualRemarkFontSize = 12 }) =>
      textualRemark && textualRemark !== ''
        ? `calc(100% - ${textualRemarkFontSize * 1.5 + 10}px)`
        : '100%',
    minHeight: 66,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flexDirection: ({ alignment }) =>
      alignment === 'TOP'
        ? 'column'
        : alignment === 'BOTTOM'
        ? 'column-reverse'
        : alignment === 'LEFT'
        ? 'row'
        : 'row-reverse',
  },
  valueCenter: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 20,
  },
  pieWrapper: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    position: 'relative',
  },
  bar: {
    height: ({ barWidth }) => barWidth,
    width: '100%',
    position: 'relative',
    backgroundImage: ({ barBackgroundColor, barColor, value }) =>
      `linear-gradient(to right,  ${barColor} ${value === 100 ? value : value - 6}%, ${barColor} ${
        value === 100 ? value : value - 3
      }%, ${barBackgroundColor} ${value}%)`,
    borderRadius: ({ barWidth }) => barWidth / 2,
  },
  value: {
    padding: ({ alignment }) =>
      `${alignment === 'BOTTOM' ? 20 : 0}px ${alignment === 'LEFT' ? 20 : 0}px ${
        alignment === 'TOP' ? 20 : 0
      }px ${alignment === 'RIGHT' ? 20 : 0}px `,
    fontSize: ({ valueFontSize }) => valueFontSize || 30,
    color: ({ color }) => color,
  },
  pie: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    clip: ({ size, value }) =>
      value <= 50 ? `rect(0, ${size}px, ${size}px, ${size / 2}px)` : 'rect(auto, auto, auto, auto)',
    left: 0,
    position: 'absolute',
    top: 0,
  },
  halfCircle: {
    height: '100%',
    width: '100%',
    border: ({ barWidth, barColor, size }) =>
      `${barWidth > size / 2 ? size / 2 : barWidth}px solid ${barColor}`,
    borderRadius: '50%',
    clip: ({ size }) => `rect(0, ${size / 2}px, ${size}px, 0)`,
    left: 0,
    position: 'absolute',
    top: 0,
  },
  shadow: {
    height: '100%',
    width: '100%',
    border: ({ size, barWidth, barBackgroundColor }) =>
      `${barWidth > size / 2 ? size / 2 : barWidth}px solid ${barBackgroundColor}`,
    borderRadius: '50%',
  },
  leftSide: {
    transform: ({ value }) => `rotate(${value * 3.6}deg)`,
  },
  rightSide: {
    display: ({ value }) => (value <= 50 ? 'none' : 'unset'),
    transform: ({ value }) => (value <= 50 ? 'none' : 'rotate(180deg)'),
  },
}));

function ProgressBarWidget(props: ProgressBarWidgetProps) {
  const renderNum = useMemo(() => ({ num: 0 }), []);
  const { widget, navigateDashboard, customization, defaultDecimalDigits = {} } = props;
  const {
    maxValue,
    alignment,
    type,
    textualRemark,
    valueType,
    textualRemarkFontSize,
    valueFontSize,
    decimalDigits = defaultDecimalDigits,
  } = customization;
  const [size, setSize] = useState(0);
  const [show, setShow] = useState(true);
  const [labelDimensions, setLabelDimensions] = useState({ height: 0, width: 0 }) as any;
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 }) as any;
  const data =
    props.data &&
    props.data.results &&
    props.data.results[0] &&
    props.data.results[0][props.data.columns[0].name];
  const newMaxValue =
    maxValue && isNaN(maxValue)
      ? getCustomizationValueFromResults(props.data, maxValue.valueId, props.data.results[0])
      : maxValue;
  const tempValue = newMaxValue && newMaxValue !== '' ? (data * 100) / newMaxValue : -1;
  const value = Math.round(tempValue < 0 ? undefined : data > newMaxValue ? 100 : tempValue);
  const label =
    valueType === 'PERCENTAGE'
      ? value
      : isNaN(Number(data))
      ? data
      : numberFormatter(data, decimalDigits);
  const classes = useStyles({ ...props, ...customization, size, value });

  useEffect(() => {
    if (alignment === 'CENTER') {
      setLabelDimensions({ height: 0, width: 0 });
    }
    resetProgress();
  }, [alignment, textualRemarkFontSize, valueFontSize, type]);

  const resetProgress = () => {
    renderNum.num = 0;
    setShow(false);
    setTimeout(() => setShow(true));
  };

  const onNavigate = () => {
    if (navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: value,
        selectedColumn: props.data && props.data.columns && props.data.columns[0],
        widget,
        columns: props.data.columns,
        rawValues: props.data.results[0],
        navigateDashboard,
      });
    }
  };

  const updateLabelSize = (e) => {
    if (type === 'CIRCLE') {
      const values = e?.getBoundingClientRect();
      const newLabelDimensions =
        alignment === 'CENTER'
          ? { height: 0, width: 0 }
          : alignment === 'TOP' || alignment === 'BOTTOM'
          ? { height: values?.height, width: 0 }
          : { width: values?.width, height: 0 };
      if (values && !isEqual(labelDimensions, newLabelDimensions)) {
        setLabelDimensions(newLabelDimensions);
      }
    }
  };

  const updateSize = (e) => {
    if (
      type === 'CIRCLE' &&
      (labelDimensions.height !== undefined || labelDimensions.width !== undefined)
    ) {
      const values = e?.getBoundingClientRect();
      const value =
        values?.height - labelDimensions.height >= values?.width - labelDimensions.width
          ? values?.width
          : values?.height;
      const smallestDimenssion =
        values?.height - labelDimensions.height >= values?.width - labelDimensions.width
          ? 'width'
          : 'height';
      const val =
        value !== undefined && value - labelDimensions[smallestDimenssion] >= 0
          ? value - labelDimensions[smallestDimenssion]
          : 0;

      if (value !== undefined && val !== dimensions[smallestDimenssion]) {
        setSize(val);
        setDimensions({ [smallestDimenssion]: val });
      }
    }
  };

  return (
    <>
      <ReactResizeDetector
        handleHeight
        handleWidth
        onResize={type === 'CIRCLE' ? resetProgress : null}
      />
      {show && newMaxValue && data >= 0 && newMaxValue >= 0 ? (
        <div className={classes.container} onClick={onNavigate}>
          <div className={classes.wrapper} ref={updateSize}>
            {alignment !== 'CENTER' && (
              <div className={classes.value} ref={updateLabelSize}>
                {`${label}${valueType === 'PERCENTAGE' ? '%' : ''}`}
              </div>
            )}
            {alignment === 'CENTER' && (
              <div className={classes.valueCenter}>
                <div className={classes.value}>{`${label}${
                  valueType === 'PERCENTAGE' ? '%' : ''
                }`}</div>
              </div>
            )}
            {type === 'BAR' ? (
              <div className={classes.bar} />
            ) : (
              <div className={classes.pieWrapper}>
                <div className={classes.pie}>
                  <div className={classNames(classes.leftSide, classes.halfCircle)}></div>
                  <div className={classNames(classes.rightSide, classes.halfCircle)}></div>
                </div>
                <div className={classes.shadow}></div>
              </div>
            )}
          </div>
          {textualRemark && <div className={classes.textualRemark}>{textualRemark}</div>}
        </div>
      ) : show ? (
        <Image
          mode="smaller"
          src={widgetMap.progress_bar.images.canvas}
          text={`widgets.${widget?.status === 'DRAFT' ? 'draft-' : ''}empty-state-text`}
        />
      ) : null}
    </>
  );
}

export default ProgressBarWidget;
