export function getHeader(notificationType: string) {
  switch (notificationType) {
    case 'EMAIL_NOTIFICATION':
      return 'events-template.edit-template.action-type.email-notification';
    case 'SMS_NOTIFICATION':
      return 'events-template.edit-template.action-type.sms-notification';
    case 'PUSH_NOTIFICATION':
      return 'events-template.edit-template.action-type.push-notification';
    default:
      return 'events-template.edit-template.action-type.email-notification';
  }
}
