import {
  convertCalculationToObject,
  getIsFloat,
  getMetricName,
} from '@pages/CreateWidgetPage/widgets.utils';

export const heatmapStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 180,
    marginRight: 55,
    fontWeight: 800,
  },
  columnTitle: {
    marginBottom: 13,
  },
  columnWidthTitle: {
    fontWeight: 800,
    width: '152px',
  },
  columnsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    paddingRight: 10,
  },
  section: {
    margin: '10px 0',
  },
  chartType: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% - 150px)',
    justifyContent: 'center',
  },
  rowSection: {
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'left',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  columnWidthItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    margin: '0 !important',
  },
  numberField: {
    width: '100px',
    margin: '0 !important',
  },
  cbLabel: {
    marginLeft: 6,
    fontSize: 14,
  },
  checkboxText: {
    margin: '13px 0 0 6px',
    height: 24,
    maxWidth: 500,
  },
  radioLabel: {
    fontSize: 14,
    marginLeft: 0,
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '183px',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
    padding: 10,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 0px 10px 0px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
  selectFullWidthCustomStyle: { container: { width: '100%' } },
  container: { width: '100%' },
};

export const chartTypeOptions = [
  {
    label: 'create-widget-page.create-widget.step-four.heatmap.regular',
    value: 'REGULAR',
  },
  {
    label: 'create-widget-page.create-widget.step-four.heatmap.ranges',
    value: 'RANGES',
  },
];

export const changeWidgetData = (value, property: string, setWidgetData) => {
  switch (property) {
    case 'tooltip':
    case 'dataLabels':
      const reg = /^\d+$/;
      if (
        (!reg.test(value?.decimalDigits) && value?.decimalDigits !== '') ||
        +value?.decimalDigits > 9
      )
        return;
      break;
  }
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [property]: value,
    },
  }));
};

const getDataOnAggregateScope = (
  metric,
  data,
  calculationsObject,
  widgetData,
  defaultDecimalDigits
) => {
  const metricName = getMetricName(widgetData, metric);

  const name = metric.operation ? `${metricName} ${metric.operation.toLowerCase()}` : metricName;

  const calculation =
    calculationsObject[metric.valueType] &&
    calculationsObject[metric.valueType][metric.valueId] &&
    calculationsObject[metric.valueType][metric.valueId][metric.operation];

  const isFloat = getIsFloat(
    metric.operation,
    metric.valueType,
    metric.type,
    calculation,
    widgetData
  );
  return {
    showDecimalPoint: data.showDecimalPoint || isFloat,
    columns: [
      ...data.columns,
      {
        id: metric.valueId,
        valueType: metric.valueType,
        operation: metric.operation,
        name,
        displayName: name.length > 20 ? name.slice(0, 20) : name,
        decimalDigits: isFloat ? defaultDecimalDigits : null,
      },
    ],
    counter: data.counter + 1,
  };
};

export const setColumnsDefaultData = (
  customization,
  widgetData,
  setWidgetData,
  defaultDecimalDigits
) => {
  const calculationsObject = convertCalculationToObject(widgetData.calculations);
  let { columns, showDecimalPoint } = widgetData.metrics.reduce(
    (data: any, metric: any, idx) => {
      if (metric.hide) {
        return data;
      }
      return getDataOnAggregateScope(
        metric,
        data,
        calculationsObject,
        widgetData,
        defaultDecimalDigits
      );
    },
    { columns: [], counter: 0, showDecimalPoint: false }
  );

  let newColumns = [];
  if (customization?.columns?.length) {
    columns.forEach((originalItem) => {
      const existingItems = customization.columns.filter(
        (item) => originalItem.id === item.id && originalItem.operation === item.operation
      );
      if (existingItems.length === 0) {
        newColumns.push(originalItem);
      } else {
        existingItems.forEach((c) => newColumns.push({ ...c, name: originalItem?.name }));
      }
    });
  } else newColumns = columns;

  setWidgetData((prevState) => ({
    ...prevState,
    customization: { ...prevState.customization, columns: newColumns },
  }));
};

export function changeWidgetTable(updatedColumn, index, tableKey, setWidgetData) {
  setWidgetData((prevState) => {
    const data = prevState.customization[tableKey].reduce(
      (d, col, idx) => {
        const item = idx === index ? updatedColumn : col;
        return {
          [tableKey]: [...d[tableKey], item],
        };
      },
      { [tableKey]: [] }
    );
    return {
      ...prevState,
      customization: {
        ...prevState.customization,
        ...data,
      },
    };
  });
}

export const columnsCfg = [
  {
    style: { paddingRight: '10px', minWidth: '50%', width: '50%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.tag-name',
  },
  {
    style: { paddingRight: '10px', minWidth: '50%', width: '50%' },
    headerLabel: 'create-widget-page.create-widget.step-four.display-name',
  },
];
