import React from 'react';
import classNames from 'classnames';
import styles from './GalleryThumbnailInstance.scss';
import ThumbnailToolbar from '../ThumbnailToolbar';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import { GalleryThumbnailInstanceProps } from '../GalleryThumbnailContainer.interface';

const useStyles = makeStyles(() => ({
  previewImage: {
    backgroundImage: (props: any) => {
      return `url(${props.imageUrl})`;
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  badge: {
    fontSize: 12,
    height: 20,
    width: 'fit-content',
    maxWidth: '150px',
    margin: '10px 0 0 10px',
    backgroundColor: (props: any) => {
      return props.badge?.backgroundColor;
    },
    color: (props: any) => {
      return props.badge?.textColor;
    },
    borderRadius: 4,
    padding: '1px 6px 0 6px',
  },
  infoBar: {
    fontSize: 12,
    height: '30px',
    width: '100%',
    backgroundColor: (props: any) => {
      return props.infoBar?.backgroundColor;
    },
    color: (props: any) => {
      return props.infoBar?.textColor;
    },
    padding: '1px 6px 0px 6px',
    marginBottom: 2,
  },
}));

function GalleryThumbnailInstance(props: GalleryThumbnailInstanceProps) {
  const {
    galleryItemInstance,
    status,
    getButtonsCfg,
    setGalleryData,
    toolbarSize,
    badge,
    infoBar,
  } = props;
  const classes = useStyles({
    imageUrl: galleryItemInstance?.previewFile?.url,
    badge: badge,
    infoBar: infoBar,
  });

  return (
    <div className={styles.wrapper}>
      {status === 'draft' && (
        <div className={styles.ribbonBox}>
          <div className={classNames(styles.draftRibbon, 'ellipsis-overflow')}>
            <I18n>general.draft</I18n>
          </div>
        </div>
      )}
      <div className={styles.previewContainer}>
        <div className={classNames(styles.preview, classes.previewImage)}>
          {badge && (
            <div className={classNames(classes.badge, 'ellipsis-overflow')}>
              <I18n>{badge.text}</I18n>
            </div>
          )}
        </div>
      </div>
      {infoBar && (
        <div className={classNames(classes.infoBar, 'ellipsis-overflow')} style={infoBar.style}>
          <I18n element={'div'} {...infoBar.restProps}>
            {infoBar.text}
          </I18n>
        </div>
      )}
      <ThumbnailToolbar
        galleryItemInstance={galleryItemInstance}
        setGalleryData={setGalleryData}
        getButtonsCfg={getButtonsCfg}
        status={status}
        size={toolbarSize}
      />
    </div>
  );
}

export default GalleryThumbnailInstance;
