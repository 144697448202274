import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import MaterialCheckbox from '@components/Checkbox';

import Icon from '@components/Icon';
import styles from './TagDataOptions.scss';

const dataTypeIconMap = {
  INT32: 'numberType',
  UINT32: 'numberType',
  FLOAT32: 'floatType',
  BOOLEAN: 'booleanType',
  STRING: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
  INT8: 'numberType',
  UINT8: 'numberType',
  INT16: 'numberType',
  UINT16: 'numberType',
  GEO: 'geoType',
};

const TagDataOptions = (props) => {
  const {
    selectProps: { tableConfig, showCheckbox = true },
    data,
  } = props;
  const keys = Object.keys(tableConfig(data));
  const columnWidth = (100 / (keys.length - 1)).toString().concat('%');

  return (
    <components.Option {...props}>
      <div className={styles.dataOptionRow}>
        {showCheckbox && <MaterialCheckbox color="primary" checked={props.isSelected} />}
        <div className={styles.dataIcon}>
          <Icon className={styles.dataIcon} type={dataTypeIconMap[tableConfig(data)[keys[0]]]} />
        </div>
        <div className={styles.dataTableContent}>
          <div
            title={tableConfig(data)[keys[1]]}
            style={{ width: columnWidth }}
            className={'ellipsis-overflow'}>
            {tableConfig(data)[keys[1]]}
          </div>
          {keys.length > 2 &&
            keys.map((key, idx) => {
              if (idx > 1) {
                return (
                  <div
                    key={idx}
                    title={tableConfig(data)[key]}
                    style={{ width: columnWidth }}
                    className={classNames(styles.dataColumn, 'ellipsis-overflow')}>
                    {tableConfig(data)[key]}
                  </div>
                );
              }
              return;
            })}
        </div>
      </div>
    </components.Option>
  );
};

export default TagDataOptions;
