import React, { useEffect } from 'react';

// Components
import MaterialCheckbox from '@components/Checkbox';

// services & Interfaces
import { TextCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import styles from './Text.scss';
import I18n from '@components/I18n';
import { cssVarsService } from '@core/CssVarsService';

// 3rd party
import * as cloneDeep from 'lodash/cloneDeep';
import { TextField } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import classNames from 'classnames';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const Text = (props) => {
  const { widgetData, setWidgetData } = props;
  const { customization } = widgetData;
  const { text, bold, italic, underline, size, color } = (customization as TextCustomization) || {};

  useEffect(() => {
    !customization &&
      updateWidgetCustomization(
        {
          text: null,
          bold: false,
          italic: false,
          underline: false,
          size: 20,
          color: cssVarsService.vars.widgetsFont,
        },
        setWidgetData
      );
  }, []);

  const setCustomizationData = (data, key) => {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  return (
    <div className={styles.widgetValue}>
      <div className={styles.rowWithHeader}>
        <I18n element="div" className={styles.subHeader}>
          create-widget-page.create-widget.step-four.text
        </I18n>
        <TextField
          value={text || ''}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => setCustomizationData(e.target.value, 'text')}
          inputProps={{
            maxLength: 100,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={styles.costumizeRow}>
        <MaterialCheckbox
          color="primary"
          onChange={() => setCustomizationData(!bold, 'bold')}
          checked={bold || false}
        />
        <I18n className={styles.text}>create-widget-page.create-widget.step-four.bold</I18n>
      </div>
      <div className={styles.costumizeRow}>
        <MaterialCheckbox
          color="primary"
          onChange={() => setCustomizationData(!italic, 'italic')}
          checked={italic || false}
        />
        <I18n className={styles.text}>create-widget-page.create-widget.step-four.italic</I18n>
      </div>
      <div className={styles.costumizeRow}>
        <MaterialCheckbox
          color="primary"
          onChange={() => setCustomizationData(!underline, 'underline')}
          checked={underline || false}
        />
        <I18n className={styles.text}>create-widget-page.create-widget.step-four.underline</I18n>
      </div>
      <div className={styles.costumizeRow}>
        <I18n className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.text-color
        </I18n>
        <ColorInput
          value={color}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'color')}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.size
        </I18n>
        <div className={styles.fontSizeInput}>
          <TextField
            value={size || ''}
            margin="dense"
            type="number"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setCustomizationData(+e.target.value, 'size');
            }}
            onBlur={(e) => {
              setCustomizationData(100 < +size ? 100 : 10 > +size ? 10 : +size, 'size');
            }}
            inputProps={{
              min: 10,
              max: 100,
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Text;
