import React, { useCallback, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './MobileNumberValidationModal.scss';
import { TextField } from '@material-ui/core';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { useSelector } from '@src/redux/useSelector';
import classNames from 'classnames';
import { authService } from '@core/auth/AuthService';

function MobileNumberValidationModal(props) {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const { args, dismiss } = props;
  const { vertificationNumber } = args;
  const [input, setInput] = useState('');

  const cancel = useCallback(() => dismiss(), [dismiss]);

  const onValidate = async () => {
    try {
      await httpService.api({
        type: 'validateSMSvertification',
        data: {
          mobile: vertificationNumber,
          code: input,
        },
      });

      const confirm = await modalService.openModal('confirm', {
        text: 'mobile-number-validation.mobile-number-validated',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'mobile-number-validation.popup-header',
        showCloseBtn: true,
      });

      cancel();
    } catch (e) {
      onValidationFailed();
    }
  };

  const onResend = async () => {
    try {
      const e = await httpService.api({
        type: 'sendSMSvertification',
        data: {
          mobile: vertificationNumber,
        },
      });
    } catch (e) {
      await modalService.openAlert({
        text: `errors.${e?.data?.message?.code}`,
        headerText: `errors.${e?.data?.message?.code}-header`,
        showCloseBtn: true,
      });
      dismiss();
      authService.logout();
    }
  };

  const onValidationFailed = async () => {
    const confirm = await modalService.openModal('confirm', {
      text: 'mobile-number-validation.number-validation-failed',
      confirmText: 'mobile-number-validation.retry',
      cancelText: 'general.cancel',
      headerText: 'mobile-number-validation.popup-header',
      showCloseBtn: true,
    });

    !confirm && cancel();
  };

  return (
    <div className={classNames(styles.wrapper, isMobileView && styles.mobileMode)}>
      <div style={{ display: 'flex' }}>
        <I18n className={styles.modalHeader} element="div">
          mobile-number-validation.popup-header
        </I18n>

        <div style={{ display: 'flex' }} onClick={cancel}>
          <Icon className={styles.exitButton} type="close" />
        </div>
      </div>

      <div className={classNames(styles.detailsContainer, isMobileView && styles.mobileMode)}>
        <I18n
          noEllipsis
          className={classNames(styles.headerText, isMobileView && styles.mobileMode)}
          element="div">
          mobile-number-validation.enter-code
        </I18n>
        <div className={styles.mobileNumber}>{vertificationNumber}</div>
        <TextField
          value={input}
          variant="outlined"
          placeholder={i18nService.translate('mobile-number-validation.enter-digits')}
          className={classNames(styles.textField, isMobileView && styles.mobileMode)}
          inputProps={{
            style: { fontSize: 12, padding: '10px', color: 'var(--systemFont)' },
            maxLength: 100,
            autoFocus: true,
          }}
          onChange={(e) => setInput(e.target.value)}
          onBlur={(e) => setInput(e.target.value)}
        />
      </div>

      <div className={classNames(styles.modalButtons, isMobileView && styles.mobileMode)}>
        <Button
          disabled={false}
          onClick={cancel}
          mode={['cancel', isMobileView ? 'default' : 'bigFont']}
          styles={{ minWidth: isMobileView ? 76 : 96 }}>
          <I18n>general.cancel</I18n>
        </Button>

        <Button
          mode={isMobileView ? 'default' : 'bigFont'}
          onClick={onResend}
          styles={{ marginLeft: 13, minWidth: isMobileView ? 76 : 96 }}>
          <I18n>mobile-number-validation.resend</I18n>
        </Button>

        <Button
          mode={isMobileView ? 'default' : 'bigFont'}
          onClick={onValidate}
          styles={{ marginLeft: 13, minWidth: isMobileView ? 76 : 96 }}>
          <I18n>mobile-number-validation.validate</I18n>
        </Button>
      </div>
    </div>
  );
}

export default MobileNumberValidationModal;
