import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { withRouter, Redirect, useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, Button } from '@material-ui/core';
import I18n from '@components/I18n';
import NewLogo from '@components/NewLogo';
import styles from '@pages/LoginPage/LoginPage.scss';
import LoginInput from '@pages/LoginPage/LoginInput';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import OnboardingActionMessage from '@components/OnboardingActionMessage';
import { authService } from '@core/auth/AuthService';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('validations.incorrect-pass'),
});

function ResetPasswordPage() {
  const [actionMessage, setActionMessage] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const key = urlSearchParams.get('key');
  const initialValues = useMemo(
    () => ({
      password: '',
      confirmPass: '',
    }),
    []
  );

  const errorOnClick = useCallback(() => {
    history.push('/login');
  }, []);

  const status = useMemo(
    () => ({
      COMPLETED: {
        text: 'first-login.token-expired',
        headerText: 'first-login.expired-header',
        isError: true,
        actionBtnText: 'login-page.submit',
        onClickAction: errorOnClick,
      },
      INVALID: {
        text: 'first-login.token-expired',
        headerText: 'first-login.expired-header',
        isError: true,
        actionBtnText: 'login-page.submit',
        onClickAction: errorOnClick,
      },
      EXPIRED: {
        text: 'first-login.token-expired',
        headerText: 'first-login.expired-header',
        isError: true,
        actionBtnText: 'login-page.submit',
        onClickAction: errorOnClick,
      },
    }),
    []
  );

  useEffect(() => {
    key &&
      httpService
        .api({
          type: 'EmailVerification',
          data: { key },
        })
        .then((res) => {
          setShowForm(true);
        })
        .catch((err) => {
          setActionMessage(err.data.status);
          setShowForm(true);
        });
  }, []);

  const onSubmit = useCallback((values) => {
    event?.preventDefault();
    httpService
      .api({
        type: 'setPasswordReset',
        data: {
          password: values.password,
          key,
        },
      })
      .then((res) => {
        authService.logout();
      });
  }, []);

  const pwCriteriaTest = useCallback((password: string) => {
    let criteriaCount = 0;
    const chars = [...password];
    chars.find((c) => /^[A-Z]$/.test(c)) && ++criteriaCount; // Check for Uppercase letter
    chars.find((c) => /^[a-z]$/.test(c)) && ++criteriaCount; // Check for Lowercase letter
    chars.find((c) => /^\d$/.test(c)) && ++criteriaCount; // Check for Digits
    chars.find((c) => /[^a-zA-Z0-9]$/.test(c)) && ++criteriaCount; // Check for Special characters

    return criteriaCount >= 3 && password.length >= 8;
  }, []);

  const validate = useCallback((values) => {
    if (!pwCriteriaTest(values.password)) {
      return { password: i18nService.translate('reset-password.pass-criteria') };
    }
    if (values.password !== values.confirmPass) {
      return { confirmPass: i18nService.translate('reset-password.no-match') };
    }
  }, []);

  return (
    <>
      {!key ? (
        <Redirect to="/login" />
      ) : (
        showForm &&
        (actionMessage ? (
          <OnboardingActionMessage {...status[actionMessage]} />
        ) : (
          <div className={classNames('max-height', styles.loginPage)}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validate={validate}
              onSubmit={onSubmit}>
              {({ isValid }) => (
                <Form className={styles.loginForm}>
                  <NewLogo />
                  <I18n className={styles.loginHeader}>reset-password.header</I18n>
                  <div className={styles.loginContent}>
                    <LoginInput
                      type="password"
                      name="password"
                      label="login-page.password"
                      className={styles.label}
                    />
                    <LoginInput
                      type="password"
                      name="confirmPass"
                      label="login-page.confirm-pass"
                      disabledPaste={true}
                      className={styles.label}
                    />
                    <Button
                      className={styles.submit}
                      type="submit"
                      variant="contained"
                      disabled={!isValid}>
                      <I18n>login-page.set-password</I18n>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ))
      )}
    </>
  );
}

export default withRouter(ResetPasswordPage);
