import React, { useState, useMemo } from 'react';

// Components
import I18n from '@components/I18n';
import RouterAssetsMeta from './RouterAssetsMeta';
import RouterPLCsMeta from './RouterPLCsMeta/RouterPLCsMeta';

// Services | Interfaces
import { RouterTabsProps } from '../EditRouterModal.interface';
import styles from './EditRouterTabs.scss';

// 3rd party
import classNames from 'classnames';

const tabs = {
  assets: {
    component: RouterAssetsMeta,
    label: 'edit-router-modal.assets',
    featureFlag: 'routers.edit-buttons',
    totalKey: 'assetCount',
  },
  plcs: {
    component: RouterPLCsMeta,
    label: 'edit-router-modal.plcs',
    featureFlag: 'routers.edit-buttons',
    totalKey: 'plcCount',
  },
};

function EditRouterTabs(props: RouterTabsProps) {
  const { onDataChanged } = props;
  const [selectedTab, setselectedTab] = useState('assets');
  const selectedTabCfg = useMemo(() => tabs[selectedTab], [selectedTab]);

  const handleTabChange = (tabKey) => {
    if (selectedTab === tabKey) return;
    setselectedTab(tabKey);
    onDataChanged();
  };

  return selectedTab ? (
    <>
      <div className={styles.tabs}>
        {Object.keys(tabs).map((key) => {
          const tabCfg = tabs[key];
          return (
            <div
              key={key}
              className={classNames('pointer', styles.tab, key === selectedTab && styles.activeTab)}
              onClick={() => handleTabChange(key)}>
              <I18n>{tabCfg.label}</I18n>
              {props[tabCfg.totalKey] > 0 ? (
                <span className={styles.total}>({props[tabCfg.totalKey]})</span>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.tabsContent}>
        <selectedTabCfg.component {...props} />
      </div>
    </>
  ) : null;
}

export default EditRouterTabs;
