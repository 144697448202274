import { RoutersState } from '@redux/redux.interface.d';
import {
  setRoutersData,
  addRoutersData,
  refreshRoutersTableData
} from '@redux/deviceManagment/routers/routers.actions';
import { dispatch } from '@redux/store';

class RoutersService {
  setRoutersTableData(routersData: RoutersState) {
    dispatch(setRoutersData(routersData));
  }

  addRoutersTableData(routersData: RoutersState) {
    dispatch(addRoutersData(routersData));
  }

  refreshRoutersTableData() {
    // Switches refresh to true.
    dispatch(refreshRoutersTableData());
    setTimeout(() => {
      // Switches refresh back to false.
      dispatch(refreshRoutersTableData());
    });
  }
}

export const routersService = new RoutersService();
