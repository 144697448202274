import React, { useEffect } from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { useSelector } from '@redux/useSelector';
import { editAssetTypeService } from '../EditAssetTypeService';
import styles from './EditAssetTypeTabs.scss';
import TabContent from './TabContent/TabContent';
import AssetTypeInfoAlarms from './AssetTypeInfoAlarms';
import AssetTypeInfoTagsMeta from './AsseTypeInfoTagsMeta';
import AssetTypeInfoSubscriptionMeta from './AssetTypeInfoSubscriptionMeta';
import { getFlagStatus } from '@core/ffAndPermissions';
import { makeStyles, Switch, withStyles } from '@material-ui/core';
import AssetTypeInfoVariablesMeta from './AssetTypeInfoVariablesMeta';
import CustomSwitch from '@components/CustomSwitch';

function getTabs(organizationType) {
  return {
    tagsMeta: {
      component: AssetTypeInfoTagsMeta,
      label: 'edit-asset-type-modal.tags',
      featureFlag: 'asset-types.edit-buttons',
      id: '5a0f5bd0-a0be-4f75-bf90-775c4ea234b3',
    },
    alarmsMeta: {
      component: AssetTypeInfoAlarms,
      label: 'edit-asset-type-modal.alarms',
      featureFlag: 'asset-types.edit-buttons',
      id: 'fdeb3858-855f-4e93-b4ea-31cc6c2e1004',
    },
    variablesMeta: {
      component: AssetTypeInfoVariablesMeta,
      label: 'variables.caption',
      id: '11afe9e2-7380-401a-80db-729ecf59708d',
      isHidden: organizationType !== 'MACHINE_BUILDER',
    },
    subscriptionMeta: {
      component: AssetTypeInfoSubscriptionMeta,
      label: 'edit-asset-type-modal.subscription',
      featureFlag: 'asset-types.subscription-tab',
      id: '1d49ea6e-d9c8-4610-a21d-95216435de13',
    },
  };
}

const useStyles = makeStyles((theme: any) => ({
  gpsLocations: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    textAlign: 'right',
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

function EditAssetTypeTabs(props) {
  const assetTypeInfo = useSelector((state) => state.editAssetType);
  const { selectedTab, dataTablesMeta } = assetTypeInfo;
  const classes = useStyles(props);
  const { handleSupportGpsChanged, remoteAccessMode, setRemoteAccessMode } = props;
  const organizationType = useSelector((state) => state.config.organizationDetails.type);
  const tabs = getTabs(organizationType);

  const handleInputChange = (tabName) => {
    if (selectedTab === tabName) return;
    editAssetTypeService.setSelectedTab(tabName);
  };

  useEffect(() => {
    //TODO change to correct feature flags.
    !getFlagStatus('asset-types.edit-buttons') && handleInputChange('subscriptionMeta');
  }, []);

  return selectedTab ? (
    <>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          {Object.keys(tabs).map((key) => {
            if (key === 'dataTablesMeta' && !dataTablesMeta.length) return null;
            if (tabs[key].featureFlag && !getFlagStatus(tabs[key].featureFlag)) return null;
            if (tabs[key].isHidden) return null;
            return remoteAccessMode && key === 'tagsMeta' ? (
              <div
                key={key}
                className={classNames('auto', styles.tab, key === selectedTab && styles.activeTab)}
                style={{ opacity: 0.4 }}
                onClick={() => {}}>
                <I18n id={tabs[key].id}>{tabs[key].label}</I18n>
              </div>
            ) : (
              <div
                key={key}
                className={
                  key === selectedTab
                    ? classNames('auto', styles.tab, styles.activeTab)
                    : classNames('pointer', styles.tab)
                }
                onClick={() => handleInputChange(key)}>
                <I18n id={tabs[key].id}>{tabs[key].label}</I18n>
              </div>
            );
          })}
        </div>
        {!remoteAccessMode && (
          <div className={classes.gpsLocations}>
            <CustomSwitch
              disabled={assetTypeInfo.staticData?.isDemo || remoteAccessMode}
              checked={props.supportGpsLocations && !remoteAccessMode}
              onChange={handleSupportGpsChanged}
              size="medium"
            />
            <I18n className={classes.text}>edit-asset-type-modal.support-gps-locations</I18n>
          </div>
        )}
      </div>
      <div className={styles.tabsContent}>
        <TabContent
          Component={tabs[selectedTab].component}
          {...{ ...props, assetTypeId: assetTypeInfo.staticData.id }}
        />
      </div>
    </>
  ) : null;
}

export default EditAssetTypeTabs;
