import React from 'react';

// Servers | Interfaces
import {
  getOptions,
  getSeries,
  getXaxisPosition,
  getYaxisCustomization,
  getColumnsColors,
  getStackedMinAndMax,
  getGeneralMinAndMax,
} from './ColumnsWidget.utils';
import { getUniqueXaxisCategories } from '../charts.utils';
import { ColumnsWidgetProps } from './ColumnsWidget.interface';
import { editorConfig } from '@core/canvas/editorConfig';
import styles from './ColumnsWidget.scss';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Image from '@components/Image';
import { widgetMap } from '@core/canvas/widgetMap';
// 3rd party
import ReactApexChart from 'react-apexcharts';
import { isEqual, omit } from 'lodash';

const style = {
  columnsWrapper: {
    '& .apexcharts-menu-item.exportCSV': {
      display: 'none',
    },
  },
};
class ColumnsWidget extends React.Component<ColumnsWidgetProps, any> {
  state = {
    options: {},
    series: [],
  };
  cellSize = editorConfig.gridCellSizePx;
  /**
   * This is a class component as a bug-fix for UC-1094. ReactApexChart sets the chart
   * event handlers on mount and doesn't update them if its options object changes.
   * As a consequence of this, its better for onChartClick to be a constant class-member-function,
   * instead of an ever-changing function (as is the case in a functional component).
   */

  componentDidMount() {
    this.setChartData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(omit(prevProps, 'apexLangChange'), omit(this.props, 'apexLangChange'))) {
      this.setChartData();
    }
  }

  setChartData() {
    const {
      data = {},
      customization = {},
      widget,
      widgetData,
      navigateDashboard,
      defaultDecimalDigits,
      timezone,
    } = this.props;
    const { groupByColumns = [], metricsColumns = [], results = [] } = data;
    const { columns = [], yAxes = [], dateTimeFormat, stacked } = customization;
    const isExtraGroupBy = (col) =>
      (widget?.scope === 'LAST_VALUE' || widgetData?.scope === 'LAST_VALUE') &&
      ['STRING', 'DATE', 'DATETIME', 'GEO'].includes(col.format);
    const { _metricColumns, _extraGroupBy } = metricsColumns.reduce(
      (result, col) => ({
        _metricColumns:
          col.hidden || isExtraGroupBy(col)
            ? result._metricColumns
            : [...result._metricColumns, col],
        _extraGroupBy:
          !col.hidden && isExtraGroupBy(col)
            ? [...result._extraGroupBy, col]
            : result._extraGroupBy,
      }),
      {
        _metricColumns: [],
        _extraGroupBy: [],
      }
    );

    const xaxisCategories = getUniqueXaxisCategories(
      results,
      _extraGroupBy.length ? _extraGroupBy : groupByColumns,
      dateTimeFormat,
      timezone
    );

    const series = getSeries(
      _metricColumns,
      columns,
      results,
      _extraGroupBy.length ? _extraGroupBy : groupByColumns
    );

    const resultData = [];
    series.forEach((s) => resultData.push(...s.data));

    const { minVal, maxVal } = stacked
      ? getStackedMinAndMax(series)
      : getGeneralMinAndMax(series, resultData);

    const xaxisPosition = getXaxisPosition(yAxes, _metricColumns, maxVal);
    const yaxisCustomization = getYaxisCustomization(
      yAxes,
      columns,
      series,
      maxVal,
      minVal,
      defaultDecimalDigits
    );
    const columnsColors = getColumnsColors(columns);

    const options = getOptions(
      xaxisCategories,
      xaxisPosition,
      yaxisCustomization,
      columnsColors,
      this.cellSize,
      widget,
      customization,
      data,
      navigateDashboard,
      series,
      defaultDecimalDigits
    );
    this.setState({ options, series });
  }

  render() {
    const { classes } = this.props;
    return (
      <div id={styles.apexChart} className={classes.columnsWrapper}>
        {!this.props.apexLangChange && this.state.series && this.state.series.length > 0 ? (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height="100%"
          />
        ) : (
          <div style={{ height: '100%' }}>
            <Image mode="smaller" src={widgetMap.columns.images.canvas} />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(style as any)(
  connect(
    (state: any) => ({
      apexLangChange: state.config.apexLangChange,
    }),
    null
  )(ColumnsWidget)
);
