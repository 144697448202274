import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';
import { dashboardService } from '@core/canvas/DashboardService';
import { useSelector } from '@redux/useSelector';
import styles from './DashboardEditorPage.scss';
import WidgetGallery from './WidgetGallery';
import Canvas from './Canvas';
import DeTopSection from './DeTopSection';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { setSelectionBox } from '@src/redux/dashboardEditor';
import { dispatch } from '@src/redux/store';
import { updateSelectedWidgetCoordinates } from './DashboardEditorLayout.utils';
import RectangleSelection from './RectangleSelection/RectangleSelection';

function DashboardEditorPage() {
  const dashboardId = useSelector((state) => state.dashboardEditor.id);
  const canvasWidgets = useSelector((state) => state.dashboardEditor.canvasWidgets);
  const isRefreshRateVisible = useSelector(
    (state) => !state.config.whiteLabelDetails.MBConf.dashboardRefreshRate.managedCentrally
  );
  const { id } = useParams() as any;
  const [loadingWidgets, setLoadingWidgets] = useState(false);
  const hasPermission = useMemo(() => getPermissionStatus('DASHBOARD', 'VIEW'), []);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [selectedWidgetIdsWithError, setSelectedWidgetIdsWithError] = useState([]);
  const [isRectangleSelection, setIsRectangleSelection] = useState(false);
  const [anchorElArrangement, setAnchorElArrangement] = useState(null);
  const [anchorElFilters, setAnchorElFilters] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [applyButtonClicked, setApplyButtonClicked] = useState(false);

  useEffect(() => {
    dashboardService.dashboardIdType = 'id';
    setLoadingWidgets(true);
    dashboardService.initDashboard(id).then((res) => {
      setLoadingWidgets(false);
    });
  }, []);

  useEffect(() => {
    if (selectedWidgets?.length) {
      setSelectedWidgets(
        selectedWidgets.map((sw) => {
          return updateSelectedWidgetCoordinates(canvasWidgets, sw);
        })
      );
    }
  }, [canvasWidgets]);

  function onMouseUp() {
    dispatch(setSelectionBox(false));
  }

  return (
    <RectangleSelection
      setIsRectangleSelection={setIsRectangleSelection}
      canvasWidgets={canvasWidgets}
      selectedWidgets={selectedWidgets}
      setSelectedWidgets={setSelectedWidgets}
      isArrangementPopoverOpen={!!anchorElArrangement}
      isFiltersPopoverOpen={!!anchorElFilters}
      isSettingsPopoverOpen={!!anchorElSettings}>
      <div className={classNames('max-height', styles.wrapper)}>
        {dashboardId && (
          <>
            <div className={styles.topSection} onMouseUp={onMouseUp}>
              <DeTopSection
                isRefreshRateVisible={isRefreshRateVisible}
                selectedWidgets={selectedWidgets}
                setSelectedWidgets={setSelectedWidgets}
                setSelectedWidgetIdsWithError={setSelectedWidgetIdsWithError}
                anchorElArrangement={anchorElArrangement}
                setAnchorElArrangement={setAnchorElArrangement}
                anchorElFilters={anchorElFilters}
                setAnchorElFilters={setAnchorElFilters}
                anchorElSettings={anchorElSettings}
                setAnchorElSettings={setAnchorElSettings}
                setApplyButtonClicked={setApplyButtonClicked}
              />
              <Divider variant="middle" id={styles.divider} />

              {hasPermission && <WidgetGallery />}
            </div>
            {!loadingWidgets && (
              <Canvas
                widgets={canvasWidgets}
                selectedWidgets={selectedWidgets}
                setSelectedWidgets={setSelectedWidgets}
                selectedWidgetIdsWithError={selectedWidgetIdsWithError}
                setSelectedWidgetIdsWithError={setSelectedWidgetIdsWithError}
                isRectangleSelection={isRectangleSelection}
                setIsRectangleSelection={setIsRectangleSelection}
                applyButtonClicked={applyButtonClicked}
                setApplyButtonClicked={setApplyButtonClicked}
              />
            )}
          </>
        )}
      </div>
    </RectangleSelection>
  );
}

export default DashboardEditorPage;
