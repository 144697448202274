import I18n from '@components/I18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialCheckbox from '@components/Checkbox';
import Select from '@components/Select';
import styles from './TriggerTimePicker.scss';
import moment from 'moment';
import WeeklyTrigger from './WeeklyTrigger/WeeklyTrigger';
import MonthlyTrigger from './MonthlyTrigger/MonthlyTrigger';
import YearlyTrigger from './YearlyTrigger/YearlyTrigger';
import StartEnd from './StartEnd/StartEnd';

const hourOptions = Array.from({ length: 12 }, (_, i) => ({
  label: (i + 1).toString(),
  value: (i + 1).toString(),
}));
const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
  label: i.toString().padStart(2, '0'),
  value: i.toString().padStart(2, '0'),
}));
const amPmOptions = [
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'PM',
    value: 'PM',
  },
];

function TriggerTimePicker(props) {
  const { templateData, setTemplateData, validation, showEditOption = true } = props;
  const { isSchedulerEditable, time, startDate, endDate, timeFrame } = templateData;
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [amPm, setAmPm] = useState('');

  const extractTime = useCallback(() => {
    const m = moment(time, ['h:m a', 'H:m']);
    setHours(m.format('h'));
    setMinutes(m.format('mm'));
    setAmPm(m.format('a').toUpperCase());
  }, [time]);

  if (hours === '' && minutes === '' && amPm === '') {
    extractTime();
  }

  if (startDate === null) {
    setTemplateData(moment().format('YYYY-MM-DD'), 'startDate');
  }

  useEffect(() => {
    extractTime();
  }, [time]);

  useEffect(() => {
    setTemplateData(moment(`${hours}:${minutes} ${amPm}`, ['h:mm A']).format('HH:mm'), 'time');
  }, [hours, minutes, amPm]);

  return (
    <div className={styles.section}>
      {/* ALLOW EDIT SCHEDULER */}
      <div className={styles.row}>
        <div className={styles.caption}></div>
        {showEditOption && (
          <>
            <MaterialCheckbox
              color="primary"
              onChange={(e) => setTemplateData(!isSchedulerEditable, 'isSchedulerEditable')}
              checked={isSchedulerEditable || false}
            />
            <I18n style={{ marginLeft: '3px', fontWeight: '600', fontSize: '12px' }}>
              events-template.edit-template.trigger.allow-edit-scheduler
            </I18n>
          </>
        )}
      </div>
      {/* TIME PICKER */}
      <div className={styles.row}>
        <div className={styles.caption}>
          <I18n>events-template.edit-template.trigger.time</I18n>
        </div>
        <Select
          styles={{ container: { width: '110px', marginRight: '3px' } }}
          className={styles.field}
          options={hourOptions}
          value={hourOptions.find((opt) => opt.value === hours)}
          getOptionLabel={(i) => i.label}
          getOptionValue={(i) => i.value}
          onChange={(option) => setHours(option.value)}
        />
        <div style={{ marginRight: '3px' }}>:</div>
        <Select
          styles={{ container: { width: '110px', marginRight: '3px' } }}
          className={styles.field}
          options={minuteOptions}
          value={minuteOptions.find((opt) => opt.value === minutes)}
          getOptionLabel={(i) => i.label}
          getOptionValue={(i) => i.value}
          onChange={(option) => setMinutes(option.value)}
        />
        <Select
          styles={{ container: { width: '110px' } }}
          className={styles.field}
          options={amPmOptions}
          value={amPmOptions.find((opt) => opt.value === amPm)}
          getOptionLabel={(i) => i.label}
          getOptionValue={(i) => i.value}
          onChange={(option) => setAmPm(option.value)}
        />
      </div>
      <StartEnd
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(e) => setTemplateData(e.dateFilterFrom, 'startDate')}
        endDateChanged={(e) => setTemplateData(e.dateFilterFrom, 'endDate')}
        xIconClicked={(e) => setTemplateData(null, 'endDate')}
        showEditOption={showEditOption}
      />

      {timeFrame === 'WEEKLY' && <WeeklyTrigger {...props} />}
      {timeFrame === 'MONTHLY' && <MonthlyTrigger {...props} />}
      {timeFrame === 'YEARLY' && <YearlyTrigger {...props} />}
    </div>
  );
}

export default TriggerTimePicker;
