import I18n from '@components/I18n';
import { TextField, makeStyles } from '@material-ui/core';
import Select from '@components/Select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditPdfReportSettingsModal.scss';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import Icon from '@components/Icon';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import classNames from 'classnames';

const useStyle = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
}));

const filtersOptions = [
  {
    label: 'events-template.create-data.source.filters.organization',
    value: 'ORGANIZATION',
  },
  {
    label: 'events-template.create-data.source.filters.asset-type',
    value: 'ASSET_TYPE',
  },
  {
    label: 'events-template.create-data.source.filters.date-range',
    value: 'DATE',
  },
  {
    label: 'events-template.create-data.source.filters.geography',
    value: 'GEO',
  },
  {
    label: 'events-template.create-data.source.filters.asset',
    value: 'ASSET',
  },
];

function EditPdfReportSettingsModal(props) {
  const { args, dismiss } = props;
  const { templateData, pdfSettings, setTemplateData, onSaveTemplate } = args;
  const [name, setName] = useState(templateData.dashboardReport?.name || pdfSettings?.name);
  const [dashboardOptions, setDashboardOptions] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(
    templateData.dashboardReport?.refId || pdfSettings?.selectedDashboard
  );
  const classes = useStyle(props);
  const [checkedFilters, setCheckedFilters] = useState(
    [...templateData.dashboardReport?.visibleFilter] || []
  );

  const allCheckedState = useMemo(
    () => checkedFilters.length === filtersOptions.length,
    [checkedFilters]
  );
  const checAllIndeterminate = useMemo(
    () => checkedFilters.length !== filtersOptions.length && checkedFilters.length,
    [checkedFilters]
  );

  useEffect(() => {
    const getPublishedDashboards = async () => {
      const publishDashboards: any = await httpService.api({
        type: 'getAllPublishDashboard',
        urlParams: { p: 1, ps: 99999 },
      });

      const dashbaords = publishDashboards.results.map((t) => ({
        value: t.refId,
        label: t.name,
      }));

      dashbaords.sort((a, b) => a.label.localeCompare(b.label));

      setDashboardOptions(dashbaords);
    };

    getPublishedDashboards();
  }, []);

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const confirm = useCallback(async () => {
    // Set data to templateData
    const newTemplateData = {
      ...templateData,
      dashboardReport: {
        refId: selectedDashboard,
        name,
        visibleFilter: checkedFilters,
      },
    };

    await onSaveTemplate(newTemplateData, false, false);
    dismiss(true);
  }, [dismiss, name, templateData?.id, selectedDashboard, checkedFilters]);

  const onCheckAllChanged = (value) => {
    if (value) {
      setCheckedFilters(filtersOptions.map((f) => f.value));
    } else setCheckedFilters([]);
  };

  const onCheckOption = (filter, value) => {
    if (value && !checkedFilters.find((f) => f === filter)) {
      setCheckedFilters([...checkedFilters, filter]);
    } else if (!value && checkedFilters.find((f) => f === filter)) {
      setCheckedFilters(checkedFilters.filter((f) => f !== filter));
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n>events-template.edit-pdf-report-settings.modal.header</I18n>
        <Icon type="close" onClick={props.dismiss} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={styles.row}>
            <div className={styles.header}>
              <I18n>events-template.create-data.source.name</I18n>
            </div>
            <TextField
              value={name}
              margin="dense"
              variant="outlined"
              className={styles.textField}
              id={styles.textFieldColor}
              inputProps={{
                style: { fontSize: 12 },
                maxLength: 100,
                autoFocus: true,
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.header}>
              <I18n>events-template.create-data.source.dashboard</I18n>
            </div>
            <Select
              styles={{ container: { width: '400px' } }}
              className={styles.field}
              options={dashboardOptions}
              value={dashboardOptions?.find((opt) => opt.value === selectedDashboard)}
              getOptionLabel={(i) => i.label}
              getOptionValue={(i) => i.value}
              onChange={(option) => setSelectedDashboard(option.value)}
            />
          </div>
          <div className={styles.rowNoHeight}>
            <div className={styles.header}>
              <I18n className={styles.underline}>
                events-template.create-data.source.show-filters
              </I18n>
            </div>
            <div>
              <div
                className={styles.checkboxWrapper}
                onClick={(e) => {
                  onCheckAllChanged(!allCheckedState);
                }}>
                <Checkbox
                  onChange={(e) => onCheckAllChanged(e.target.checked)}
                  color="primary"
                  checked={allCheckedState}
                  indeterminate={checAllIndeterminate}
                  style={{ width: '24px' }}
                />
                <I18n
                  className={classNames(styles.text, 'ellipsis-overflow', styles.checkboxLabel)}>
                  events-template.create-data.source.show-all-filter-details
                </I18n>
              </div>
              <div className={styles.subCheckboxes}>
                {filtersOptions.map((op) => (
                  <div
                    key={op.value}
                    className={styles.checkboxWrapper}
                    onClick={(e) => {
                      onCheckOption(op.value, !checkedFilters.some((f) => op.value === f));
                    }}>
                    <Checkbox
                      onChange={(e) => onCheckOption(op.value, e.target.checked)}
                      color="primary"
                      checked={checkedFilters.some((f) => op.value === f)}
                      style={{ width: '24px' }}
                    />
                    <I18n
                      className={classNames(
                        styles.text,
                        'ellipsis-overflow',
                        styles.checkboxLabel
                      )}>
                      {op.label}
                    </I18n>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.modalButtons}>
          <Button mode={['cancel', 'bigFont']} disabled={false} onClick={cancel}>
            <I18n>general.cancel</I18n>
          </Button>
          <Button
            styles={{ marginLeft: 13 }}
            mode="bigFont"
            onClick={confirm}
            disabled={!name || !selectedDashboard}>
            <I18n>general.confirm</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditPdfReportSettingsModal;
