export const vertexTShader = `
  varying vec4 vPos;
  varying vec3 vNormal;
  varying vec2 vUv;

	void main() {
  	vUv = uv;
		vNormal = normalMatrix * normal;
		vPos = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		gl_Position = vPos;
	}
`;

export const fragmentTShader = `
  varying vec4 vPos;
  varying vec3 vNormal;
  varying vec2 vUv;

  uniform vec3 mainColor;
  uniform float mainAlpha;

  void main() {
    gl_FragColor = vec4(mainColor, mainAlpha) ;
  }
`;
