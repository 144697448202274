import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { dndService } from '@core/DndService';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import I18n from '@components/I18n';
import AvailableData from './AvailableData';
import Filters from './Filters';
import styles from './Filtering.scss';
import MuiExpandMoreIcon from '@components/MuiExpandMoreIcon';

import { concat, compact } from 'lodash';

import { AccordionSummary, AccordionDetails, Accordion, withStyles } from '@material-ui/core';
import { buildOptions, getAvailableData, onDragAndDropFilters } from './Filtering.utils';
import { allowedOperationsMap } from '@core/mapsAndDefinitions';

const useStyles = {
  root: {
    '& .MuiAccordionSummary-root': {
      minHeight: '44px !important',
      padding: '0 19px',
      maxWidth: '100%',
    },
    '& .MuiAccordionSummary-content': {
      margin: '10px 0 !important',
      maxWidth: '92%',
    },
    '& .MuiIconButton-root': {
      padding: 10,
    },
    '& .MuiCollapse-container': {
      borderTop: '1px solid var(--tableRowBorderBottom)',
    },
    '& .MuiAccordionDetails-root': {
      padding: '15px 19px',
    },
  },
};

function Filtering(props) {
  const { widgetData, setWidgetData, classes } = props;
  const {
    groupBy,
    metrics,
    filters,
    assetProperties,
    tags,
    type,
    tagTypes,
    scope,
    assetTypes,
    alarmInfos,
    variables,
  } = widgetData;

  const data = compact(
    concat(
      assetProperties.map((ap) => buildOptions(ap, 'ASSET_PROPERTY', scope)),
      tags.map((t) => buildOptions(t, 'TAG', scope)),
      tagTypes.map((tt) => buildOptions(tt, 'TAG_TYPE', scope)),
      alarmInfos.map((ai) => buildOptions(ai, 'ALARM_INFO', scope)),
      variables.map((v) => buildOptions(v, 'VARIABLE', scope))
    )
  );

  const isDefaultExpanded = useMemo(
    () => !groupBy.length && !metrics.length && !!filters.length,
    [groupBy, metrics, filters]
  );

  const [availableData, setAvailableData] = useState(getAvailableData(scope, data, type));
  const disabled =
    getConfigValueFromWidgetSettings(widgetData.type, 'accordionDisable') &&
    getIsIncludeInSettings(widgetData.type, 'accordionDisable', 'filtering');
  const [expanded, setExpanded] = useState(isDefaultExpanded);
  const dndDisabled = disabled || !expanded;

  useEffect(() => {
    setAvailableData(getAvailableData(scope, data, type));
  }, [metrics, assetProperties, tags, tagTypes, groupBy]);

  useEffect(() => {
    const sub = dndService.dragEndSubscribe((result) => {
      onDragAndDropFilters(
        result,
        availableData,
        filters,
        setWidgetData,
        setAvailableData,
        widgetData
      );
    });

    return () => {
      sub.unsubscribe();
    };
  }, [availableData, filters]);

  return (
    <Accordion
      defaultExpanded={expanded}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disabled={disabled}
      id={styles.collapsePanel}
      className={classNames(styles.ep, classes.root)}>
      <AccordionSummary expandIcon={<MuiExpandMoreIcon />}>
        <div>
          <I18n className={styles.filtering} element="div">
            create-widget-page.create-widget.step-three.filtering
          </I18n>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.epDetailsWrapper}>
          <AvailableData
            disabled={dndDisabled}
            widgetData={widgetData}
            availableData={
              availableData
                .filter((ad) => ad.id != 11)
                ?.filter((ad) => !(ad.tagType === 'ALARM_INFO' && ad.id == 2))
                ?.filter((data) => {
                  if (
                    data.tagType === 'VARIABLE' &&
                    !['numberType', 'floatType'].includes(data.type) &&
                    data.operation
                  ) {
                    return;
                  } else if (
                    data.tagType === 'VARIABLE' &&
                    data.operation &&
                    !allowedOperationsMap.get(data.tagType)?.includes(data.operation)
                  ) {
                    return;
                  } else {
                    return data;
                  }
                }) || []
            }></AvailableData>
          <Filters
            disabled={dndDisabled}
            assetTypes={assetTypes}
            filters={filters}
            widgetData={widgetData}
            setWidgetData={setWidgetData}
            availableData={availableData}></Filters>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(useStyles)(Filtering);
