import { i18nService } from '@core/i18n/I18nService';

export const setPlaceholder = (placeholder, languageId) => {
  return placeholder ? i18nService.translate(placeholder, languageId) : '';
};

export const setValue = (values, getOptionValue) => {
  if (Array.isArray(values)) {
    const tempValues = values.length > 0 ? values : null;
    return tempValues
      ? !getOptionValue
        ? tempValues.map((value) => ({ ...value, label: i18nService.translate(value.label) }))
        : tempValues
      : null;
  } else {
    return values;
  }
};

export const onSelectionChange = (e, onChange) => {
  if (e) {
    onChange([e]);
  } else {
    onChange([]);
  }
};

export const onCreatableSelectChange = (e, onChange) => {
  if (e) {
    onChange(e.map((val) => val.value));
  } else {
    onChange([]);
  }
};

const customFilterOption = (option, rawInput) => {
  const words = rawInput.split(' ');
  return words.reduce(
    (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
    true
  );
};

export const onFilterOption = (filterOption, option, rawInput) => {
  return filterOption ? filterOption : customFilterOption(option, rawInput);
};

export const numOfHiddenChips = (
  valueCount: number,
  id,
  selectActions,
  inputWidth,
  hiddenChipsCount,
  valueWidth
) => {
  // Number of hidden chips to hide from the input and add chip in their stead
  const element = document.getElementById(id);

  if (element) {
    const selectWidth = element.clientWidth;
    const hidden = Math.ceil(valueCount - (selectWidth - selectActions - inputWidth) / valueWidth);
    return hidden > 0
      ? Math.ceil(
          valueCount - (selectWidth - selectActions - inputWidth - hiddenChipsCount) / valueWidth
        )
      : 0;
  } else return 0;
};
