import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { AlertProps } from './Alert.interface';
import styles from './Alert.scss';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import Table from '@components/Table';

function Alert(props: AlertProps) {
  const { args, dismiss } = props;
  const {
    text,
    iconType,
    btnText = 'general.close',
    showCloseBtn,
    headerText,
    listText,
    multiLineText,
    tableCfg,
    tableData,
    ...rest
  } = args;
  const closeBtn = useRef(null);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const close = useCallback(() => dismiss(true), [dismiss]);
  const multiLineRows = multiLineText && i18nService.translate(multiLineText).split('\r\n');
  const customStyles = rest['customStyles'] ? rest['customStyles'] : {};

  useEffect(() => {
    closeBtn.current && closeBtn.current.focus();
  }, []);

  return (
    <div
      id={'3233cc96-4bc7-4784-9d71-57b2ad02fb76'}
      className={classNames(styles.wrapper, isMobileView && styles.mobileView)}
      style={customStyles['customWrapperStyle'] ? customStyles['customWrapperStyle'] : undefined}>
      {showCloseBtn && (
        <div className={styles.modalHeader}>
          <I18n>{headerText}</I18n>
          <Icon type="close" onClick={close} className={'pointer'}></Icon>
        </div>
      )}
      <div
        className={classNames(styles.content, isMobileView && styles.mobileView)}
        style={customStyles['customContentStyle'] ? customStyles['customContentStyle'] : undefined}>
        {iconType && (
          <Icon
            className={styles.icon}
            type={iconType}
            style={
              customStyles['customIconStyle'] ? customStyles['customIconStyle'] : undefined
            }></Icon>
        )}
        {text && (
          <I18n noEllipsis element="div" className={styles.text} {...rest}>
            {text}
          </I18n>
        )}
        {multiLineRows &&
          multiLineRows.length > 0 &&
          multiLineRows.map((row, idx) => (
            <div className={styles.text} key={idx}>
              {row}
            </div>
          ))}
        {listText &&
          listText.length > 0 &&
          (rest['noBullets'] ? (
            <div
              className={styles.listText}
              style={customStyles['customListStyle'] ? customStyles['customListStyle'] : null}>
              {listText.map((item, idx) => (
                <>
                  <I18n element="div" noEllipsis className={styles.text} key={idx}>
                    {item}
                  </I18n>
                  <div style={{ height: '10px' }}></div>
                </>
              ))}
            </div>
          ) : (
            <ul className={styles.listText}>
              {listText.map((item, idx) => (
                <li key={idx}>
                  <I18n noEllipsis className={styles.text}>
                    {item}
                  </I18n>
                </li>
              ))}
            </ul>
          ))}
        {tableCfg && <Table colunmsCfg={tableCfg} data={tableData} />}
      </div>
      <div className={styles.modalButtons}>
        <Button ref={closeBtn} mode="bigFont" onClick={close}>
          <I18n>{btnText}</I18n>
        </Button>
      </div>
    </div>
  );
}

export default Alert;
