export const handleChangeInput = (e, maxLength, setInputValue, formik, name) => {
  const fieldVal = e.target.value;
  if (maxLength && fieldVal.length > maxLength) return;
  setInputValue(fieldVal);
  formik.setFieldValue(name, fieldVal);
};

export const handleBlurInput = (formik, onLostFocus, error, setInputValue, name) => {
  formik.setFieldTouched(name, true);
  if (onLostFocus && !error) {
    onLostFocus(formik.values, formik.setFieldValue, setInputValue, formik.setErrors);
  }
};

export const getStyles = (error, touched, labelInline, mode, styles) => {
  if (error && touched) {
    return labelInline ? styles[`formikInlineErrorInput${mode}`] : styles.formikErrorInput;
  }
  return labelInline ? styles[`formikInlineInput${mode}`] : styles.formikInput;
};
