import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';

import styles from './HeaderTagData.scss';

const HeaderTagData = (props) => {
  const {
    selectProps: { tableConfig },
    options,
  } = props;
  const keys = options[0] && Object.keys(tableConfig(options[0]));
  const columnWidth = keys && keys.length > 1 ? (100 / (keys.length - 1)).toString().concat('%') : '100%';

  return (
    <components.MenuList {...props}>
      {options[0] && (
        <div className={styles.tagDataHeader}>
          <div className={styles.dataHeaderRow}>
            <div style={{ width: columnWidth }} className={'ellipsis-overflow'}>{`${keys[1]}`}</div>
            {keys.length > 2 &&
              keys.map((key, idx) => {
                if (idx > 1) {
                  return (
                    <div
                      key={idx}
                      style={{ width: columnWidth }}
                      className={classNames(
                        styles.tagDataHeaderColumn,
                        'ellipsis-overflow'
                      )}>{`${key}`}</div>
                  );
                }
                return;
              })}
          </div>
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

export default HeaderTagData;
