import { UserStatus, OrganizationStatus } from './SelectOrganizationPage.interface';
import { dispatch, getState } from '@src/redux/store';
import { setSelectedOrganization } from '@src/redux/login';
import { authService } from '@core/auth/AuthService';
import { httpService } from '@core/http/HttpService';

export const getAccounts = async (setIsLoading, history, setAccounts) => {
  setIsLoading(true);
  try {
    const acc = getState().accountState.accounts;
    const accounts = { accounts: history.location.state && history.location.state.accounts };
    const userAccounts: any =
      accounts.accounts && accounts.accounts.length
        ? accounts.accounts
        : acc?.length
        ? acc
        : await httpService
            .api({
              type: 'getAccounts',
            })
            .then((res: any) => res.accounts);

    if (userAccounts.length === 1) {
      updataOrg(userAccounts[0].id, history);
    }

    setAccounts(
      userAccounts
        .filter((account) => account.organizationStatus !== OrganizationStatus.preActivate)
        .sort((a, b) =>
          a.organizationName.toLowerCase() > b.organizationName.toLowerCase()
            ? 1
            : a.organizationName.toLowerCase() === b.organizationName.toLowerCase()
            ? a.country.toLowerCase() > b.country.toLowerCase()
              ? 1
              : -1
            : -1
        )
    );
    setIsLoading(false);
  } catch (e) {
    setIsLoading(false);
  }
};

export const handleChange = (event, selectedOrg, setSelectedOrg, setErrorsMessage) => {
  if (event.target.value !== selectedOrg) {
    setSelectedOrg(event.target.value);
    setErrorsMessage({ error: undefined, orgData: undefined });
  }
};

export const constructAccountLabel = (account) => {
  let label = account.organizationName;

  if (account.country) label = label.concat(` - ${account.country}`);
  return label;
};

const updataOrg = async (id, history) => {
  if (id) {
    dispatch(setSelectedOrganization(id));
    const login = getState().login;
    if (!login?.twoFaStatus) {
      await authService.handle2FaStatus();
      await authService.getWhiteLabelAndLanguageFor2FaPage(id);
      return true;
    }

    login?.twoFaStatus?.isCompleted && history.push('/main');
  }
};

export const onSubmit = async (e, accounts, setErrorsMessage, selectedOrg, history) => {
  e.preventDefault();
  const orgData = accounts.find((account) => account.id.toString() === selectedOrg);
  if (
    orgData &&
    (orgData.userStatus === UserStatus.archived || orgData.userStatus === UserStatus.suspended)
  ) {
    setErrorsMessage({
      error: `select-org-page.${orgData.userStatus.toLowerCase()}-error`,
      orgData,
    });
  } else if (
    orgData &&
    (orgData.organizationStatus === OrganizationStatus.Archived ||
      orgData.organizationStatus === OrganizationStatus.Suspended)
  ) {
    setErrorsMessage({
      error: `select-org-page.${orgData.organizationStatus.toLowerCase()}-error`,
      orgData,
    });
  } else {
    if (orgData) {
      const mFAStatusCalled = await updataOrg(selectedOrg, history);
      if (!mFAStatusCalled) authService.handle2FaStatus();
    }
  }
};
