import React, { useEffect, useState } from 'react';
import styles from '@pages/MainPage/DashboardReport/Filters/Filters.scss';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { FiltersProps } from './FiltersProps.interface';
import { httpService } from '@core/http/HttpService';
import { userFiltersService } from '@pages/DashboardEditorPage/UserFiltersService';
import { useSubject } from '@core/effects';
import { AssetInfo } from '@modals/EditAssetModal/EditAssetModal.interface';
import { i18nService } from '@core/i18n/I18nService';

const Filters = (props: FiltersProps) => {
  const {
    organizationsFilter,
    assetTypesFilter,
    assetFilter,
    dateFilter,
    dateFilterFrom,
    dateFilterTo,
    geoFilter,
  } = props.dashboardFilters;
  const { dashboardVisibleFilters } = props;
  const organizationOptions = useSubject(userFiltersService.organizationOptions$);
  const assetTypeOptions = useSubject(userFiltersService.assetTypeOptions$);
  const [organizations, setOrganizations] = useState('');
  const [assetTypes, setAssetTypes] = useState('');
  const [assetName, setAssetName] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [geo, setGeo] = useState('');
  const showOrganizationFilter = dashboardVisibleFilters.some((item) => item === 'ORGANIZATION');
  const showAssetTypeFilter = dashboardVisibleFilters.some((item) => item === 'ASSET_TYPE');
  const showDateRangeFilter = dashboardVisibleFilters.some((item) => item === 'DATE');
  const showGeoFilter = dashboardVisibleFilters.some((item) => item === 'GEO');
  const showAssetFilter = dashboardVisibleFilters.some((item) => item === 'ASSET');

  useEffect(() => {
    userFiltersService.getOrganizationOptions();
    userFiltersService.getAssetTypeOptions();
  }, []);

  useEffect(() => {
    if (organizationOptions.length) {
      setOrganizations(
        organizationOptions
          ?.filter((org) => organizationsFilter?.some((orgId) => orgId === org.id))
          .map((org) => {
            return { id: org.id, name: org.name };
          })
          .map((item) => item.name)
          .reduce((prev, current) => (prev ? prev + ', ' + current : current), '')
      );
    }
  }, [organizationsFilter, organizationOptions]);

  useEffect(() => {
    if (assetTypeOptions.length) {
      setAssetTypes(
        assetTypeOptions
          ?.filter((assetType) =>
            assetTypesFilter?.some((assetTypeId) => assetTypeId === assetType.id)
          )
          .map((assetType) => {
            return { id: assetType.id, name: assetType.name };
          })
          .map((item) => item.name)
          .reduce((prev, current) => (prev ? prev + ', ' + current : current), '')
      );
    }
  }, [assetTypesFilter, assetTypeOptions]);

  useEffect(() => {
    setGeo(
      geoFilter
        ?.map((item) => item.formatted)
        .reduce((prev, current) => (prev ? prev + ', ' + current : current), '')
    );
  }, [geoFilter]);

  useEffect(() => {
    if (dateFilter === 'CUSTOM') {
      setDateRange(`${dateFilterFrom} - ${dateFilterTo}`);
    } else {
      switch (dateFilter) {
        case 'DAY':
          setDateRange(i18nService.translate('date-picker.last-day'));
          break;
        case 'WEEK':
          setDateRange(i18nService.translate('date-picker.last-week'));
          break;
        case 'MONTH':
          setDateRange(i18nService.translate('date-picker.last-month'));
          break;
        case 'YEAR':
          setDateRange(i18nService.translate('date-picker.last-year'));
          break;
        default:
          setDateRange('');
      }
    }
  }, []);

  useEffect(() => {
    if (assetFilter && (assetFilter as any).length) {
      httpService
        .api({
          type: 'getAssetInfo',
          urlParams: { assetId: assetFilter[0] },
        })
        .then((assetInfo: AssetInfo) => {
          setAssetName(assetInfo.assetName);
        });
    }
  }, []);

  /* PLACEHOLDERS */
  const filters = {
    organizations: ['Organizations'],
    assetTypes: ['Asset types'],
    dateRange: ['Date range'],
    geography: ['Geography'],
    asset: ['Asset'],
  };

  const showFilters =
    filters.organizations.length > 0 ||
    filters.assetTypes.length > 0 ||
    filters.dateRange.length > 0 ||
    filters.geography.length > 0 ||
    filters.asset.length > 0;

  return (
    <>
      {showFilters && (
        <div>
          <div className={styles.subTitle}>
            <I18n>events-template.edit-template.dashboard-report-filters</I18n>
          </div>
          <div>
            {/* ORGANIZATION FILTER */}
            {showOrganizationFilter && (
              <div className={styles.row}>
                <div className={styles.caption}>
                  <I18n>events-template.edit-template.organization</I18n>:
                </div>
                <div
                  className={classNames(styles.content, 'ellipsis-overflow')}
                  title={organizations}>
                  {organizations && organizations?.length
                    ? `${organizations}`
                    : `${i18nService.translate('general.all')}`}
                </div>
              </div>
            )}
            {/* DATE FILTER */}
            {showDateRangeFilter && (
              <div className={styles.row}>
                <div className={styles.caption}>
                  <I18n>events-template.edit-template.data-range</I18n>:
                </div>
                <div className={classNames(styles.content, 'ellipsis-overflow')} title={dateRange}>
                  {dateRange
                    ? `${dateRange}`
                    : `${i18nService.translate('date-picker.last-month')}`}
                </div>
              </div>
            )}
            {/* ASSET TYPE FILTER */}
            {showAssetTypeFilter && (
              <div className={styles.row}>
                <div className={styles.caption}>
                  <I18n>events-template.edit-template.asset-type</I18n>:
                </div>
                <div className={classNames(styles.content, 'ellipsis-overflow')} title={assetTypes}>
                  {assetTypes && assetTypes?.length
                    ? `${assetTypes}`
                    : `${i18nService.translate('general.all')}`}
                </div>
              </div>
            )}
            {/* GEO FILTER */}
            {showGeoFilter && (
              <div className={styles.row}>
                <div className={styles.caption}>
                  <I18n>events-template.edit-template.geography</I18n>:
                </div>
                <div className={classNames(styles.content, 'ellipsis-overflow')} title={geo}>
                  {geo && geo?.length ? `${geo}` : `${i18nService.translate('general.all')}`}
                </div>
              </div>
            )}
            {/* ASSET FILTER */}
            {showAssetFilter && (
              <div className={styles.row}>
                <div className={styles.caption}>
                  <I18n>events-template.edit-template.asset</I18n>:
                </div>
                <div className={classNames(styles.content, 'ellipsis-overflow')} title={assetName}>
                  {assetName ? `${assetName}` : `${i18nService.translate('general.all')}`}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Filters;
