import React, { useMemo } from 'react';
import moment from 'moment';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { colorMap } from '@core/statusColorMap';
import I18n from '@components/I18n';
import { MarkerDataProps } from './MarkerData.interface';
import { staticDictionary } from '@core/i18n/dictionary';
import {
  numberFormatter,
  dateTimeRegex,
  dateRegex,
  convertBooleanToTranslatedString,
} from '@core/utils';
import { buildDateTime } from '@components/widgets/charts.utils';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0px',
    fontSize: 12,
    color: 'var(--widgetsFont)',
  },
  rowWarpper: {
    display: 'flex',
    flex: 1,
    padding: '3px 0px',
  },
  bold: {
    fontWeight: 'bold',
    padding: '0px 10px',
    width: 70,
  },
  status: {
    color: ({ markerData }: any) =>
      (markerData && colorMap[markerData.status]) || theme.app.mapLoctionStatusUndefined,
  },
  empty: {
    padding: 20,
  },
}));

function MarkerData(props: MarkerDataProps) {
  const { markerData, onClick } = props;
  const { assetName, assetSerial, assetType, owner, status, tagData } = markerData || {};
  const { customization, defaultDecimalDigits = 0 } = props;
  const tagValue = useMemo(() => {
    if (tagData && typeof tagData[0]?.value === 'boolean') {
      return convertBooleanToTranslatedString(tagData[0].value);
    } else if (tagData && (tagData[0]?.value || tagData[0]?.value === 0)) {
      if (
        tagData[0].value &&
        typeof tagData[0].value === 'string' &&
        (dateTimeRegex.test(tagData[0].value.split('.')[0]) ||
          dateRegex.test(tagData[0].value.split('.')[0]))
      ) {
        return buildDateTime(tagData[0].value, customization?.dateTimeFormat, 'momentFormat');
      }
      if (!isNaN(Number(tagData[0].value)) && tagData[0].format !== 'STRING') {
        return numberFormatter(tagData[0].value, defaultDecimalDigits);
      }
      return tagData[0].value;
    }
    return undefined;
  }, [customization, tagData]);

  const classes = useStyles(props);

  return props.markerData ? (
    <div className={classes.wrapper}>
      <div className={classes.rowWarpper} onClick={onClick}>
        <I18n className={classes.bold}>{`map-widget.name`}</I18n>{' '}
        <span className="ellipsis-overflow">{assetName}</span>
      </div>

      <div className={classes.rowWarpper}>
        <I18n className={classes.bold}>{`map-widget.serial-number`}</I18n>{' '}
        <span className="ellipsis-overflow">{assetSerial}</span>
      </div>
      <div className={classes.rowWarpper}>
        <I18n className={classes.bold}>{`map-widget.type`}</I18n>
        <span className="ellipsis-overflow">{assetType}</span>
      </div>
      <div className={classes.rowWarpper}>
        <I18n className={classes.bold}>{`map-widget.owner`}</I18n>
        <span className="ellipsis-overflow">{owner}</span>
      </div>
      <div className={classes.rowWarpper}>
        <I18n className={classes.bold}>{`map-widget.status`}</I18n>
        <I18n className={classes.status}>{staticDictionary[`enum.${status}`]}</I18n>
      </div>
      {customization?.tagCaption && (
        <div className={classes.rowWarpper}>
          <span className={classNames(classes.bold, 'ellipsis-overflow')}>
            {customization.tagCaption}
          </span>
          <span className="ellipsis-overflow">{tagValue}</span>
        </div>
      )}
    </div>
  ) : (
    <div className={classes.empty}>
      <CircularProgress />
    </div>
  );
}

export default MarkerData;
