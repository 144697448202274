import React from 'react';
import classNames from 'classnames';

import styles from './TableBody.scss';

const BodyColumn = ({ row, rowIndex, col, colIndex, handleCellClick, ellipsis }) => {
  return (
    <div
      style={{ ...col.style, cursor: col.isClickable && 'pointer' }}
      onClick={(e) => col.isClickable && handleCellClick(e, col, rowIndex)}
      className={classNames(styles.col, ellipsis && 'ellipsis-overflow')}>
      <col.Component {...col} {...col.props} {...row} />
    </div>
  );
};

export default BodyColumn;
