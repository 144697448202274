import React, { useEffect } from 'react';
import styles from './ControlSection.scss';
import SelectComponent from './SelectComponent';
import ButtonComponent from './ButtonComponent';
import {
  defaultCategoryList,
  downloadDataBackupFile,
  exploreDataBackup,
  getFilePath,
  onCategorySelected,
  onDateSelected,
  openConfirmationModal,
} from './ControlSection.utils';
import { useSelector } from '@redux/useSelector';
import { getSubscriptions } from '@modals/EditAssetModal/EditAssetModal.utils';

function ControlSection(props) {
  const {
    organizationId,
    assetId,
    categoryList,
    setCategoryList,
    dateList,
    selectedCategory,
    setSelectedCategory,
    selectedDate,
    setSelectedDate,
    selectedFile,
    setSubscriptionsData,
  } = props;
  const assetInfo = useSelector((state) => state.editAsset);
  const assetStatus = assetInfo?.staticData?.status;

  useEffect(() => {
    function setList(res) {
      setCategoryList(res.map((item) => item.replace('/', '')));
      !res?.length && getSubscriptions(+assetId, setSubscriptionsData);
    }

    !selectedCategory && exploreDataBackup(organizationId, assetId, setList, null);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectors}>
        <SelectComponent
          id={'c77cccae-3ac7-4848-a589-d07074de207d'}
          caption={'general.category'}
          options={defaultCategoryList.map((category) => {
            return {
              ...category,
              isDisabled: categoryList?.includes(category.label) ? false : true,
            };
          })}
          value={selectedCategory}
          placeholder={'edit-asset-modal.data-backup.select-category'}
          isDisabled={!categoryList?.length}
          onChange={onCategorySelected}
          restProps={{ ...props, setValue: setSelectedCategory }}
        />
        <SelectComponent
          id={'b509c0c3-2fa0-4d65-8e80-ff963c521b76'}
          caption={'general.date'}
          options={dateList}
          value={selectedDate}
          placeholder={'edit-asset-modal.data-backup.select-date'}
          isDisabled={!selectedCategory}
          onChange={onDateSelected}
          restProps={{ ...props, setValue: setSelectedDate }}
        />
      </div>
      <div className={styles.actionButtons}>
        <ButtonComponent
          id={'9473f264-02e5-4948-81e4-0e942a5a11f0'}
          caption={'general.download'}
          type={'download'}
          onClick={() =>
            downloadDataBackupFile(
              organizationId,
              assetId,
              selectedFile,
              getFilePath(selectedCategory, selectedDate, selectedFile)
            )
          }
          disabled={!selectedFile}
        />
        <ButtonComponent
          id={'9473f264-02e5-4948-81e4-0e942a5a11f0'}
          caption={'general.restore'}
          type={'uploadRestore'}
          onClick={() =>
            openConfirmationModal(
              organizationId,
              assetId,
              assetStatus,
              assetInfo?.staticData?.plcSerial,
              selectedCategory,
              selectedDate,
              selectedFile
            )
          }
          disabled={!selectedFile}
        />
      </div>
    </div>
  );
}

export default ControlSection;
