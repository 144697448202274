import { StoreAction } from './redux.interface.d';

export const setImageURL = (imageId: number, url: string, expires: number): StoreAction => ({
  type: 'imageCache/SET_IMAGE_URL',
  payload: { imageId, url, expires },
});

export const clearImageURLCache = (): StoreAction => ({
  type: 'imageCache/CLEAR',
  payload: null,
});

export default function imageCacheReducer(state = {}, action: StoreAction) {
  if (action.type == 'imageCache/SET_IMAGE_URL') {
    return {
      ...state,
      [`${action.payload.imageId}`]: {
        url: action.payload.url,
        expires: action.payload.expires,
      },
    };
  } else if (action.type == 'imageCache/CLEAR') {
    return {};
  } else {
    return state;
  }
}
