import * as Yup from 'yup';
import generalRegex from '@core/regex';
import {
  managementLevelOptions,
  valueTypeOptions,
  variableConstants,
} from '@pages/OrganizationsPage/Variables/Variables.utils';

function getType(varType: string) {
  switch (varType) {
    case 'NUMERIC':
    case 'TEXT':
      return 'input';
    case 'BOOLEAN':
      return 'radioGroup';
    case 'DATE':
      return 'date';
    case 'DATETIME':
      return 'datetime';
  }
}

export function getDefaultValueByType(valueType: any) {
  switch (valueType.value) {
    case 'NUMERIC':
      return '';
    case 'TEXT':
      return '';
    case 'BOOLEAN':
      return 0;
    case 'DATE':
      return '';
    case 'DATETIME':
      return '';
  }
}

function getRestProps(varType: string) {
  switch (varType) {
    case 'NUMERIC':
      return { inputType: 'number', regex: /^[0-9-]+$/ };
    case 'TEXT':
      return {
        validation: Yup.string()
          .required('validations.mandatory-field')
          .max(variableConstants.TEXT_MAX_LENGTH, 'my-details.name-message')
          .min(0, 'my-details.name-message'),
      };
    case 'BOOLEAN':
      return {
        radioGroupOptions: [
          { value: 0, label: 'general.on' },
          { value: 1, label: 'general.off' },
        ],
        defaultValue: 0,
      };
    case 'DATE':
    case 'DATETIME':
      const pickerFormats =
        varType === 'DATETIME'
          ? { timePicker: true, dateTimeFormat: 'FIXED_DATETIME' }
          : { timePicker: false, dateTimeFormat: 'FIXED_DATE' };
      return {
        ...pickerFormats,
        dateWrapperStyle: { width: '236px' },
      };
  }
}

export function getFieldsCfg(
  nameValidation,
  setNameValidation,
  varType = 'NUMERIC',
  setValueType,
  getDefaultValueByType
) {
  const { NAME_MAX_LENGTH, MIN_VALUE, MAX_VALUE, TEXT_MAX_LENGTH } = variableConstants;

  return [
    {
      label: 'general.name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validate: (values) => {
        if (nameValidation.name === values.name && nameValidation.message) {
          return nameValidation.message;
        }
        if (nameValidation.name != values.name && nameValidation.message) {
          setNameValidation({ name: values.name, message: '', isValid: true });
        }
      },
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(50, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
      onLostFocus: (values, setFieldValue) => {
        setFieldValue('name', values.name);
      },
      maxLength: NAME_MAX_LENGTH,
      errors: {
        name: nameValidation.message ? nameValidation.message : 'validations.mandatory-field',
      },
      removeLeadingSpaces: true,
      trimOnLostFocus: true,
    },
    {
      label: 'general.type',
      name: 'valueType',
      type: 'select',
      options: valueTypeOptions,
      onSelectChange: (values, setFieldValue) => {
        setFieldValue('valueType', values);
        setValueType(values);
        setFieldValue('value', getDefaultValueByType(values));
        values.value === 'BOOLEAN' && setFieldValue('value', 0);
      },
    },
    {
      label: 'general.value',
      name: 'value',
      type: getType(varType),
      ...getRestProps(varType),
      isRequired: varType !== 'TEXT',
      validation:
        varType === 'BOOLEAN'
          ? null
          : varType === 'TEXT'
          ? Yup.string().max(TEXT_MAX_LENGTH, 'my-details.name-message')
          : Yup.string()
              .required('validations.mandatory-field')
              .max(MAX_VALUE, 'my-details.name-message')
              .min(MIN_VALUE, 'my-details.name-message'),
      minValue: MIN_VALUE,
      maxValue: MAX_VALUE,
      maxLength: TEXT_MAX_LENGTH,
      maxDecimalDigitRegex: varType === 'NUMERIC' ? generalRegex.tenDecimalDigitsRegex : null,
    },
    {
      label: 'variables.management-level',
      name: 'managementLevel',
      type: 'select',
      options: managementLevelOptions,
      onSelectChange: (values, setFieldValue) => {
        setFieldValue('managementLevel', values);
      },
      menuPortalTarget: document.body,
    },
  ];
}
