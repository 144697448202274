import React from 'react';
import I18n from '@components/I18n';
import styles from './LicensePlans.scss';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import SubscrptionsTab from './SubscriptionsTab/SubscriptionsTab';
import AvailableTab from './AvailableTab/AvailableTab';
import OrganizationalSubscriptionsTab from './OrganizationalSubscriptionsTab/OrganizationalSubscriptionsTab';

function LicensePlans(props) {
  return (
    <div className={styles.content}>
      <div className={styles.tabs}>
        <NavLink
          className={styles.tab}
          activeClassName={styles.activeTab}
          to={`${props.match.url}/subscriptions`}>
          <I18n>my-account-page.licensing-plan.subscriptions</I18n>
        </NavLink>

        <NavLink
          className={styles.tab}
          activeClassName={styles.activeTab}
          to={`${props.match.url}/available`}>
          <I18n>my-account-page.licensing-plan.available</I18n>
        </NavLink>

        <NavLink
          className={styles.tab}
          activeClassName={styles.activeTab}
          to={`${props.match.url}/organizational`}>
          <I18n>my-account-page.licensing-plan.organizational-subscriptions</I18n>
        </NavLink>
      </div>

      <div className={styles.routsSection}>
        <Switch>
          <Route exact path={`${props.match.url}/subscriptions`} component={SubscrptionsTab} />
          <Route exact path={`${props.match.url}/available`} component={AvailableTab} />
          <Route
            path={`${props.match.url}/organizational`}
            component={OrganizationalSubscriptionsTab}
          />
          <Redirect to={`${props.match.url}/subscriptions`} />
        </Switch>
      </div>
    </div>
  );
}

export default LicensePlans;
