import { AvailableTagType, GetAvailableTagTypesRes } from '@http/server.interface.d';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { buildErrorObj } from '@core/utils';
import { editAssetTypeService } from '@modals/EditAssetTypeModal/EditAssetTypeService';

/**
 * Calculates the autocomplete dropdown options based on the current input value
 * and the given previous options. The input value should become the 1st option.
 * If the input value matches a real option, that option should be the 1st option
 * instead of the input value.
 */
export function getOptions(prevOptions: AvailableTagType[], inputValue: string) {
  const realOptions = prevOptions.filter((opt) => opt.id);

  if (!inputValue) {
    return realOptions;
  }

  const isInputInOptions = realOptions.some((opt) => opt.name === inputValue);

  if (!isInputInOptions) {
    const inputOption = { id: undefined, name: inputValue };
    return [inputOption].concat(realOptions);
  } else {
    const hitIdx = realOptions.findIndex((opt) => opt.name === inputValue);

    // We put the hit in the beginning of the options.
    return [realOptions[hitIdx]].concat(realOptions.filter((opt) => opt.name !== inputValue));
  }
}

export const focusOnInput = (autocompleteEl) =>
  autocompleteEl.current.querySelector('input').focus();

export const deleteByBackspace = (event, autocompleteEl, selected, setSelected) => {
  const wasBackspacePressed = event.keyCode === 8;
  if (wasBackspacePressed && selected.length > 0) {
    setSelected([]);
    focusOnInput(autocompleteEl);
  }
};

export const getFetchOptions = async (setOptions, inputValue, withBusy, setIsFetching, tagId) => {
  try {
    withBusy && setIsFetching(true);
    const res = await httpService.api<GetAvailableTagTypesRes>({
      type: 'getAvailableTagTypes',
      disableBI: true,
      disableErrorHandling: true,
      urlParams: {
        tagId,
        p: 1,
        ps: 10,
        search: inputValue.current,
      },
    });
    setOptions(getOptions(res.results, inputValue.current));
    withBusy && setIsFetching(false);
  } catch (err) {
    withBusy && setIsFetching(false);
  }
};

export const addEditRemoneTagType = async (type, selected, tagId, name, dismiss) => {
  if (type && selected.length === 0) {
    // Remove tag type.
    const confirm = await modalService.openConfirm({
      text: 'assign-tag-type-modal.remove-msg',
      confirmText: 'assign-tag-type-modal.remove-confirm',
    });
    if (!confirm) {
      dismiss();
      return;
    }
    httpService
      .api({
        type: 'removeTagType',
        urlParams: { tagId },
      })
      .then()
      .catch((err) => {
        err.data?.code === 36 &&
          modalService.openModal('assetTypesTagsInfo', {
            ...err.data,
            tagName: name,
            isTagType: true,
          });
      });
  } else {
    // Add new or update existing tag type.
    const confirm = await modalService.openConfirm({
      text: 'assign-tag-type-modal.assign-msg',
      confirmText: 'general.continue',
    });
    if (!confirm) return;
    httpService
      .api({
        type: 'assignTagType',
        urlParams: { tagId },
        data: {
          id: selected[0].id || null,
          type: selected[0].name,
        },
      })
      .then((err: any) => {
        err.code &&
          modalService.openModal('alert', {
            text: `errors.${err.code}`,
            ...buildErrorObj(err.keys),
          });
      })
      .catch((err: any) => {
        err.data?.code === 36 &&
          modalService.openModal('assetTypesTagsInfo', {
            ...err.data,
            tagName: name,
            isTagType: true,
          });
      });
  }

  // After adding/removing we need to update the UI.
  await editAssetTypeService.getAssetTypeInfo();

  dismiss(true);
};

export const onChange = (event, value, setSelected, inputValue, fetchOptions) => {
  // If selecting the free-text, the item will be a string.
  const normalized = value.map((v) => (v.name ? v : { id: undefined, name: v }));
  setSelected(normalized);

  inputValue.current = '';
  fetchOptions();
};
