import React, { useState, useCallback, useMemo } from 'react';
import { MenuItem, Menu, makeStyles, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@components/Icon';
import { dashboardService } from '@core/canvas/DashboardService';
import { authService } from '@core/auth/AuthService';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';
import classNames from 'classnames';
import { getState } from '@src/redux/store';
import { getFlagStatus } from '@core/ffAndPermissions';

const useStyles = makeStyles((theme: any) => ({
  menuItem: {
    margin: '0 10px',
    padding: '5px 16px 5px 16px',
    borderBottom: `1px solid ${theme.app.multiSelectChip}`,
    display: 'flex',
    cursor: 'pointer',
    color: 'var(--systemFont)',
  },
  name: {
    margin: '0 8px',
    color: 'var(--systemFont)',
    maxWidth: '250px',
  },
  mobileName: {
    margin: '0 5px 0 0',
    maxWidth: '115px',
  },
  icon: {
    display: 'inline-block',
  },
  link: {
    '&:link': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
    '&:visited': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
    '&:hover': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
    '&:active': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
  },
  noPadding: {
    padding: 0,
  },
  ar: {
    margin: '0 10px',
    padding: '7px 16px 5px 27px',
    borderBottom: `1px solid ${theme.app.multiSelectChip}`,
    display: 'flex',
    cursor: 'pointer',
    color: 'var(--systemFont)',
    height: '48px',
    textDecoration: 'none',
  },
}));

const menuLinks = {
  TERMS_AND_CONDITIONS: 'http://www.unitronics.cloud/terms-and-conditions',
  CONTACT_US: 'http://www.unitronics.cloud/unicloud-contact-us',
  HELP: 'http://www.unitronics.cloud/unicloud-help/',
};

function UserMenuSelect(props) {
  const isArAllowed = useSelector((state) => state.config.isArAllowed);
  const classes = useStyles(props);
  const { clientType, isMobileDevice, isMobileApp } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.login);
  const { type } = useSelector((state) => state.config.organizationDetails);
  const arSubscriptionStatus = useSelector((state) => state.config.arSubscriptionStatus);

  const menuItems = [
    ...(isMobileApp && isArAllowed && getFlagStatus('user-menu.ar-viewer')
      ? [
          {
            key: 'ar_menu_item',
            onClick: () => {
              handleClose();
              try {
                if (clientType === 'iOSUniCloudApp') {
                  window['webkit']['messageHandlers']['startARSession'].postMessage(
                    arSubscriptionStatus
                  );
                } else if (clientType === 'uniCloudApp') {
                  uniCloudApp?.startARSession(arSubscriptionStatus);
                }
              } catch (e) {
                console.log(e);
              }
            },
            text: 'user-menu-select.ar',
            isAllow: () => true,
          },
        ]
      : []),
    {
      key: 'help_menu_item',
      onClick: () => onRedirect('HELP'),
      text: 'user-menu-select.help',
      isAllow: () => type === 'MACHINE_BUILDER',
    },
    {
      key: 'contactus_menu_item',
      onClick: () => onRedirect('CONTACT_US'),
      text: 'user-menu-select.contactus',
      isAllow: () => type === 'MACHINE_BUILDER',
    },
    {
      key: 'TAndC_menu_item',
      onClick: () => onRedirect('TERMS_AND_CONDITIONS'),
      text: 'user-menu-select.terms-and-conditions',
      isAllow: () => true,
    },
    {
      key: 'logout_menu_item',
      onClick: () => onLogoutSelected(),
      text: 'user-menu-select.logout',
      isAllow: () => true,
    },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutSelected = useCallback(() => {
    handleClose();
    dashboardService.clearDashboards();
    authService.logout();
  }, []);

  const onRedirect = useCallback((type) => {
    handleClose();
    const clientType = getState().clientState?.clientType;
    switch (clientType) {
      case 'uniCloudApp':
        uniCloudApp?.openUrlInBrowser(menuLinks[type]);
        break;
      case 'iOSUniCloudApp':
        console.log('Before openUrlInBrowser');
        window['webkit']['messageHandlers']['openUrlInBrowser'].postMessage(menuLinks[type]);
        console.log('After openUrlInBrowser');
        break;
      default:
        window.open(menuLinks[type], '', 'top=100,left=100,width=400,height=600');
        break;
    }
  }, []);

  const menusColor = useMemo(() => 'var(--menusForeground)', []);

  return (
    <div>
      <MenuItem
        onClick={handleMenu}
        classes={isMobileDevice || isMobileApp ? { root: classes.noPadding } : {}}>
        {isMobileApp ? (
          <IconButton onClick={handleMenu}>
            <MenuIcon />
          </IconButton>
        ) : (
          <>
            {!isMobileDevice && <Icon type="logout" className={classes.icon} color={menusColor} />}
            <div
              title={user.firstName}
              className={classNames(
                classes.name,
                isMobileDevice || (isMobileApp && classes.mobileName),
                'ellipsis-overflow'
              )}>
              {user.firstName}
            </div>
            <Icon type="dropDown" />
          </>
        )}
      </MenuItem>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}>
        <div style={{ maxHeight: 350, overflow: 'auto' }}>
          {menuItems
            .filter((item) => item.isAllow())
            .map((item) => (
              <MenuItem key={item.key} classes={{ root: classes.menuItem }} onClick={item.onClick}>
                <I18n className={item.key === 'ar_menu_item' && classNames('newFeature')}>
                  {item.text}
                </I18n>
              </MenuItem>
            ))}
        </div>
      </Menu>
    </div>
  );
}

export default UserMenuSelect;
