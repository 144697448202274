import * as RI from '../../redux.interface.d';
import { eAhandlers } from './editAsset.handlers';

const initialState = (): RI.EditAssetState => ({
  selectedTab: 'general',
  staticData: null,
  alarmsMeta: [],
  dataTablesMeta: [],
  general: null,
  remoteAccessMode: false,
});

export default function editAssetReducer(
  state: RI.EditAssetState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'deviceManagment/editAsset/SET_ALL':
      return { ...state, ...payload };
    case 'deviceManagment/editAsset/CLEAR_EDIT_ASSET':
      return initialState();
    case 'deviceManagment/editAsset/UPDATE_EDIT_ASSET':
      return eAhandlers.updateEditAsset(state, payload);
    case 'deviceManagment/editAsset/SET_SELECTED_TAB':
      return { ...state, selectedTab: payload };
    case 'deviceManagment/editAsset/SET_REMOTE_ACCESS_MODE':
      return { ...state, remoteAccessMode: payload };
    default:
      return state;
  }
}
