import React, { useState, useCallback, useMemo } from 'react';
import Table from '@components/Table';
import { makeStyles } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import { alarmInfosMap } from '@pages/CreateWidgetPage/widgets.utils';
import { assetsPropsMap } from '@pages/CreateWidgetPage/widgets.utils';
import { parseCustomizationTerms } from '@core/canvas/widget.utils';

const tableHeadersMap = {
  active_alarm: 'alarm status',
  alarm_index: 'alarm id',
};

const getHeader = (val) => tableHeadersMap[val] || val;

const LabelComponent = (props) => {
  const value = 'Value';
  return (
    <div className="ellipsis-overflow" style={{ minWidth: '62px' }} title={`${value}`}>
      {props.symbol
        ? props.showSymbolLeft
          ? `${value} ${props.symbol}`
          : `${props.symbol} ${value}`
        : value}
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'inherit',
    color: 'var(--widgetsFont)',
  },
  tableWrapper: {
    height: '100%',
    backgroundColor: 'inherit',
    overflowX: 'auto',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--tableSpinnerBackground)',
  },
}));

function TablePreview(props) {
  const { assetProperties, tags, tagTypes, alarmInfo, customization } = props;
  const defaultDecimalDigits = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
  );
  const columns = useMemo(
    () =>
      customization && customization.columns && customization.columns.filter((col) => !col.hidden),
    [customization]
  );

  const tableWidth = useMemo(
    () =>
      customization?.columns?.reduce(
        (total, column) => total + (column.width || 100 / customization.columns?.length),
        0
      ) || 0,
    [customization]
  );

  const data = useMemo(() => {
    const res = [];
    for (let i = 0; i < 3; i++) {
      res.push({
        '@rowNumber': i + 1,
        ...customization?.columns
          ?.map((c) => c.displayName)
          .reduce((accumulate, val) => ({ ...accumulate, [val]: 'Value' }), {}),
      });
    }

    return res;
  }, [customization]);

  const [widgetWidth, setWidgetWidth] = useState(0);
  const classes = useStyles(props);

  const applyColumnCustomization = useCallback(() => {
    if (!customization || !Object.keys(customization).length) {
      return columns.map((c) => ({
        Component: LabelComponent,
        label: getHeader(getColumnName(c)),
        field: getColumnName(c),
        style: {
          width: `${100 / columns.length}%`,
          minWidth: `${100 / columns.length}%`,
          maxWidth: `${100 / columns.length}%`,
        },
        isClickable: false,
        decimalDigits: defaultDecimalDigits,
        ...c,
      }));
    } else {
      const translatedColumn = parseCustomizationTerms(props.terms, 'table', customization);

      return customization.columns.map((c, index) => ({
        ...c,
        Component: LabelComponent,
        label:
          translatedColumn.columns.find((col) => col.id === c.id)?.displayName ||
          getHeader(getColumnName(c)),
        field: getColumnName(c),
        style: {
          width: `${c.width ? 100 / c.width : 100 / columns.length}%`,
          minHeight: 20,
        },
        isClickable: false,
        decimalDigits: c.decimalDigits,
        ...(columns.find((col) => col.id == c.id) || {}),
      }));
    }
  }, [customization, widgetWidth]);

  function getColumnName(column): string {
    switch (column.valueType) {
      case 'ASSET_PROPERTY':
        return i18nService.translate(
          `create-widget-page.create-widget.asset-properties.${assetsPropsMap[column.id]}`
        );
      case 'TAG':
        return tags?.find((tag) => tag.id === column.id)?.name;
      case 'TAG_TYPE':
        return tagTypes?.find((tagType) => tagType.id === column.id)?.name;
      case 'ALARM_INFO':
        return i18nService.translate(
          `create-widget-page.create-widget.alarm-infos.${alarmInfosMap[column.id]}`
        );
      default:
        return '';
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.tableWrapper}>
        <div
          style={{
            width: `calc(${tableWidth > 100 ? tableWidth : 100}%)`,
            minWidth: `calc(${tableWidth > 100 ? tableWidth : 100}%)`,
            height: '100%',
            backgroundColor: 'inherit',
          }}>
          <Table
            data={data}
            customization={customization}
            colunmsCfg={applyColumnCustomization()}
            disabledLoad={true}
          />
        </div>
      </div>
    </div>
  );
}

export default TablePreview;
