import React from 'react';
import I18n from '@components/I18n';
import styles from './StepThree.scss';
import GroupingAndMeasures from './GroupingAndMeasures';
import WidgetSorting from './WidgetSorting';
import Filtering from './Filtering';
import Multiply from './Multiply';

function StepThree(props) {
  const { widgetData, setWidgetData } = props;

  return (
    <div className={styles.stepThreeContainer}>
      <I18n element="div" className={styles.stepHeader}>
        widget-side-bar.organize
      </I18n>
      <div className={styles.stepContent}>
        <GroupingAndMeasures widgetData={widgetData} setWidgetData={setWidgetData} />
        {widgetData.type?.toLocaleLowerCase() === 'update_asset' && (
          <Multiply widgetData={widgetData} setWidgetData={setWidgetData} />
        )}
        <Filtering widgetData={widgetData} setWidgetData={setWidgetData} />
        <WidgetSorting widgetData={widgetData} setWidgetData={setWidgetData} />
      </div>
    </div>
  );
}

export default StepThree;
