import { TableColunm } from '@components/Table/table.interface';
import EditableDataInput from './EditableDataInput';
import TextDataField from './TextDataField';

const tableRowStyle = { width: '50%', height: '40px' };

export function getAssetPropertiesCfg(
  assetPropertiesOptions: Array<any>,
  replaceData: any,
  widgetData: any,
  replacements: any
): Array<TableColunm> {
  return [
    {
      Component: TextDataField,
      label: 'general.source',
      field: 'source',
      style: { width: '50%' },
    },
    {
      Component: EditableDataInput,
      label: 'create-widget-page.create-widget.step-one.replace-tags.replace-with',
      field: 'replaceWith',
      style: tableRowStyle,
      props: {
        options: assetPropertiesOptions,
        replaceData: replaceData,
        dataType: 'assetProperties',
        replacements,
        widgetType: widgetData.type,
      },
    },
  ];
}

export function getTagOrTagTypesCfg(
  assetTypeDataTagOptions: Array<any>,
  replaceData: any,
  widgetData: any,
  replacements: any
): Array<TableColunm> {
  return [
    {
      Component: TextDataField,
      label: 'general.source',
      field: 'source',
      style: { width: '50%' },
    },
    {
      Component: EditableDataInput,
      label: 'create-widget-page.create-widget.step-one.replace-tags.replace-with',
      field: 'replaceWith',
      style: tableRowStyle,
      props: {
        options: assetTypeDataTagOptions,
        replaceData: replaceData,
        dataType: 'tagData',
        replacements,
        widgetType: widgetData.type,
      },
    },
  ];
}
