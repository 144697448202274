import { ContentState, convertToRaw, DraftHandleValue, EditorState, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AddDataSource from './AddDataSource/AddDataSource';
import styles from './RichTextEditor.scss';
import { getHtmlLength } from './RichTextEditor.utils';

function RichTextEditor(props) {
  const {
    value = '',
    setTemplateData,
    isSingleLine,
    allowEnter = true,
    allowRichText = true,
    allowDataSources = true,
    dataSources = [],
    propertyName,
    maxLength,
    customButtons,
    originatesFrom,
    tagsAllowed,
    addDataSourceWidth = null,
    addBottomMargin = true,
  } = props;
  const { contentBlocks, entityMap } = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  const toolbar = useMemo(() => {
    return isSingleLine || !allowRichText
      ? {
          options: [],
        }
      : {
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'remove',
            'history',
          ],
        };
  }, [isSingleLine]);

  useEffect(() => {
    const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (htmlText !== value) {
      const { contentBlocks, entityMap } = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      let newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (newState) => {
    if (
      maxLength &&
      getHtmlLength(draftToHtml(convertToRaw(newState.getCurrentContent())), !allowRichText) >
        maxLength
    ) {
      newState = EditorState.moveFocusToEnd(
        EditorState.push(editorState, editorState.getCurrentContent(), 'undo')
      );
    }

    setEditorState(newState);
    let html = draftToHtml(convertToRaw(newState.getCurrentContent()));

    setTemplateData(html, propertyName);
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (allowRichText) {
      const newEditorState = RichUtils.handleKeyCommand(editorState, command);

      if (newEditorState) {
        setEditorState(newEditorState);
        return 'handled';
      }
    }

    return 'not-handled';
  };

  return (
    <Editor
      toolbar={toolbar}
      toolbarCustomButtons={
        allowDataSources
          ? [
              ...(customButtons && Array.isArray(customButtons) ? customButtons : []),
              <AddDataSource
                dataSources={dataSources}
                originatesFrom={originatesFrom}
                addDataSourceWidth={addDataSourceWidth}
                tagsAllowed={tagsAllowed}
              />,
            ]
          : [...(customButtons && Array.isArray(customButtons) ? customButtons : [])]
      }
      handleReturn={() => isSingleLine || !allowEnter}
      editorState={editorState}
      handlePastedText={() => false}
      handleKeyCommand={handleKeyCommand}
      toolbarClassName={[styles.toolbar, !allowDataSources && styles.invisible]}
      wrapperClassName={[styles.wrapper, addBottomMargin && styles.bottomMargin]}
      editorClassName={isSingleLine ? styles.singleLineEditor : styles.editor}
      onEditorStateChange={onEditorStateChange}
      originatesFrom={originatesFrom}
      mention={{
        separator: ' ',
        trigger: '#@#@SpongeBob#', // This is the trigger in order to prevent the user from entering a text that will might contain the trigger, but still have the mentions working
        suggestions: dataSources.map((d) => {
          return {
            text: d.name,
            value: d.name,
            dataType: d.dataType,
            url: `datasource://d.id`,
          };
        }),
      }}
    />
  );
}

export default RichTextEditor;
