import React, { useEffect } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import I18n from '@components/I18n';
import styles from './FormikLocationInput.scss';
import { FormikLocationProps } from './FormikLocationInput.interface';
import GooglePlacesInput from '@components/GooglePlacesInput';

function FormikLocationInput(props: FormikLocationProps) {
  const { formik, name, validate, location } = props;
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const handleChange = locationSelected => {
    formik.setFieldValue(name, locationSelected);
  };

  return (
    <div id={error && touched ? styles.formikErrorInput : ''}>
      <div className={styles.googleInput}>
        <GooglePlacesInput
          id="location"
          defaultValue={location}
          onSelectedCountryChange={location => handleChange(location)}
          isMulti={false}
          coordinates={true}
        />
      </div>
      <ErrorMessage name={name}>
        {err => (
          <I18n className={styles.error} element="div">
            {err}
          </I18n>
        )}
      </ErrorMessage>
    </div>
  );
}

export default connect(FormikLocationInput);
