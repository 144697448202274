import classNames from 'classnames';
import React, { useEffect, useState, useMemo } from 'react';
import Select from '@components/Select';
import I18n from '@components/I18n';
import { LocalSortingItem, SortDirection } from '../WidgetSorting.interface';
import { i18nService } from '@core/i18n/I18nService';
import { SelectOption } from '@components/Select/Select.interface';
import Icon from '@components/Icon';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import { assetsPropsMap } from '@pages/CreateWidgetPage/widgets.utils';

const useStyles = makeStyles((theme: any) => ({
  widgetSortItem: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    border: `solid 1px ${theme.app.sortItemBorder}`,
    fontSize: 12,
    height: 41,
    backgroundColor: theme.app.white1,
  },
  section: {
    width: '100%',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
  },
  selectSection: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  dragIndicator: {
    justifyContent: 'center',
    width: 20,
    height: '100%',
    backgroundColor: theme.app.lightGrey,
    display: 'flex',
    alignItems: 'center',
  },
  dragIndicatorDisabled: {
    opacity: 0.5,
  },
  sortSelect: {
    flexGrow: 1,
    margin: '0 10px',
  },
  clearIcon: {
    padding: '0 6px',
  },
}));

function WidgetSortItem(props) {
  const { item, sortSelected, sortRemoved, sortColunms, provided } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const { alphabeticSort, ascDescSort, trueFalseSort } = useMemo(
    () => ({
      alphabeticSort: [
        { value: 'ASCENDING', label: i18nService.translate('general.A_Z-sort') },
        { value: 'DESCENDING', label: i18nService.translate('general.Z_A-sort') },
      ],
      ascDescSort: [
        { value: 'ASCENDING', label: i18nService.translate('enum.ASCENDING') },
        { value: 'DESCENDING', label: i18nService.translate('enum.DESCENDING') },
      ],
      trueFalseSort: [
        { value: 'DESCENDING', label: i18nService.translate('general.sort-by-true') },
        { value: 'ASCENDING', label: i18nService.translate('general.sort-by-false') },
      ],
    }),
    [languageId]
  );
  const [sortColunmOptions, setSortColunmOptions] = useState([]);
  const [sortDirectionOptions, setSortDirectionOptions] = useState(ascDescSort);
  const [selectedDirection, setSelectedDirection] = useState<SelectOption>(null);
  const [selectedColunm, setSelectedColunm] = useState(null);
  const classes = useStyles(props);

  const sortColunmsChanged = (sortColunm: LocalSortingItem, prevVal: LocalSortingItem) => {
    setSortOptions(sortColunm);
    setSelectedColunm(sortColunm);
    sortColunm.sortBy = sortDirectionOptions[0].value as SortDirection;
    setSelectedDirection(sortDirectionOptions[0]);
    sortSelected(prevVal, sortColunm);
  };

  const sortDirectionChanged = (sortDirection: SelectOption) => {
    if (sortDirection) {
      setSelectedDirection(sortDirection);
      selectedColunm['sortBy'] = sortDirection.value;
      sortSelected(selectedColunm, selectedColunm);
    }
  };

  const setSortOptions = (item: LocalSortingItem) => {
    let sortOptions;
    const tagType = item.type;

    switch (tagType) {
      case 'stringType':
        sortOptions = alphabeticSort;
        break;
      case 'booleanType':
        sortOptions = trueFalseSort;
        break;
      case 'numberType':
      case 'floatType':
      case 'dateTimeType':
      case 'ipv4Type':
      default:
        sortOptions = ascDescSort;
        break;
    }

    //if select assetId even his type is string should display "Ascending/Descending"
    if (item.valueType === 'ASSET_PROPERTY' && item.valueId === 1) {
      sortOptions = ascDescSort;
    }
    setSortDirectionOptions(sortOptions);
    if (selectedColunm) {
      if (item.sortBy) {
        setSelectedDirection(sortOptions.find((opt) => opt.value === item.sortBy));
      } else {
        setSelectedDirection(sortOptions[0]);
      }
    }
  };

  useEffect(() => {
    if (!!item.valueId) {
      const operation = item.operation
        ? i18nService.translate(`enum.${(item.operation as string).toUpperCase()}`)
        : '';
      setSelectedColunm({
        ...item,
        value: item.valueId,
        label: item.name
          ? `${i18nService.translate(item.name)} ${operation}`
          : i18nService.translate(item.label),
      });
      setSortOptions(item);
    }
    setSortColunmOptions(
      sortColunms
        .map((col) => {
          const name = col.name
            ? col.name
            : col.valueType === 'ASSET_PROPERTY'
            ? `create-widget-page.create-widget.asset-properties.${assetsPropsMap[col.valueId]}`
            : `${col.valueType}_${col.valueId}`;
          const operation = col.operation
            ? i18nService.translate(`enum.${(col.operation as string).toUpperCase()}`)
            : '';
          return {
            ...col,
            value: col.valueId,
            label: `${i18nService.translate(name)} ${operation}`.trim(),
          };
        })
        .filter((option) => option.valueId != 11)
    );

    return () => {
      setSelectedColunm(null);
      setSelectedDirection(null);
    };
  }, [sortColunms, languageId]);

  return (
    <div className={classes.widgetSortItem} ref={provided.innerRef} {...provided.draggableProps}>
      <div
        className={classNames(classes.dragIndicator, !item.name && classes.dragIndicatorDisabled)}
        {...provided.dragHandleProps}>
        <Icon type="drag" />
      </div>
      <div className={classes.section}>
        <div className={classes.selectSection}>
          <I18n element="div" className="ellipsis-overflow">
            create-widget-page.create-widget.step-three.sort-column
          </I18n>
          <div className={classes.sortSelect}>
            <Select
              options={sortColunmOptions}
              onChange={sortColunmsChanged}
              defaultValue={{ value: item.valueId, label: item.name }}
              value={selectedColunm}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className={classes.selectSection}>
          <I18n element="div" className="ellipsis-overflow">
            create-widget-page.create-widget.step-three.by
          </I18n>
          <div className={classes.sortSelect}>
            <Select
              options={sortDirectionOptions}
              onChange={sortDirectionChanged}
              value={selectedDirection}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <Icon
          type="remove"
          className={classNames(classes.clearIcon, 'pointer')}
          onClick={() => sortRemoved(selectedColunm)}
        />
      </div>
    </div>
  );
}

export default WidgetSortItem;
