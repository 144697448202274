import React, { useMemo } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import I18n from '@components/I18n';
import styles from './MyAccountPage.scss';
import MyDetails from './MyDetails';
import CompanyDetails from './CompanyDetails';
import PaymentsDetails from './PaymentsDetails';
import LicensingPlan from './LicensingPlan';
import DisplaySettings from './DisplaySettings';
import Languages from './Languages';
import SystemSettings from './SystemSettings';
import { getFlagStatus } from '@core/ffAndPermissions';
import DeletedUsersMeta from './DeletedUsersMeta';
import RegionalSettings from './RegionalSettings';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { useSelector } from '@src/redux/useSelector';

const redirectToMap = [
  { url: 'myDetails', featureFlag: 'my-details-tab' },
  { url: 'companyDetails', featureFlag: 'my-company-details-tab' },
  { url: 'displaySettings', featureFlag: 'display-settings-tab' },
  { url: 'licensingPlan', featureFlag: 'licensing-plan-tab' },
  { url: 'languages', featureFlag: 'languages-tab' },
  { url: 'deletedUsers', featureFlag: 'deleted-users-tab' },
  { url: 'systemSettings', featureFlag: 'system-settings-tab' },
];

function MyAccountPage(props) {
  const redirectTo = useMemo(
    () => redirectToMap.find((r) => !r.featureFlag || getFlagStatus(`my-account.${r.featureFlag}`)),
    []
  );
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const isUnitronicsAdminUser = organizationTypeMap[organizationDetails?.type] === 1;

  const tabs = {
    myDetails: {
      component: MyDetails,
      label: 'my-account-page.my-details',
      url: 'myDetails',
    },
    companyDetails: {
      component: CompanyDetails,
      label: 'my-account-page.company-details',
      url: 'companyDetails',
      featureFlag: 'my-company-details-tab',
    },
    regionalSettings: {
      component: RegionalSettings,
      label: 'my-account-page.regional-settings',
      url: 'regionalSettings',
    },
    // paymentsDetails: {
    //   component: PaymentsDetails,
    //   label: 'my-account-page.payments-details',
    //   url: 'paymentsDetails'
    // },
    licensingPlan: {
      component: LicensingPlan,
      label: 'my-account-page.license-plans',
      url: 'licensingPlan',
      featureFlag: 'licensing-plan-tab',
      isHidden: isUnitronicsAdminUser,
    },
    displaySettings: {
      component: DisplaySettings,
      label: 'my-account-page.display-settings',
      url: 'displaySettings',
      featureFlag: 'display-settings-tab',
    },
    languages: {
      component: Languages,
      label: 'my-account-page.languages',
      url: 'languages',
      featureFlag: 'languages-tab',
    },
    systemSettings: {
      component: SystemSettings,
      label: 'my-account-page.system-settings',
      url: 'systemSettings',
      featureFlag: 'system-settings-tab',
    },
    deletedUsers: {
      component: DeletedUsersMeta,
      label: 'my-account-page.deleted-users',
      url: 'deletedUsers',
      featureFlag: 'deleted-users-tab',
    },
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.pageTitle}>
          <I18n>my-account-page.my-account</I18n>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.myAccountTabs}>
          {Object.keys(tabs).map((key, index) => {
            if (
              (tabs[key].featureFlag && !getFlagStatus(`my-account.${tabs[key].featureFlag}`)) ||
              tabs[key].isHidden
            ) {
              return null;
            }
            return (
              <NavLink
                key={index}
                className={styles.tab}
                activeClassName={styles.activeTab}
                to={`${props.match.url}/${tabs[key].url}`}>
                <I18n>{tabs[key].label}</I18n>
              </NavLink>
            );
          })}
        </div>
        <div className={styles.routsSection}>
          <Switch>
            {Object.keys(tabs).map((key, index) => {
              if (tabs[key].featureFlag && !getFlagStatus(`my-account.${tabs[key].featureFlag}`)) {
                return null;
              }
              return (
                <Route
                  key={index}
                  path={`${props.match.url}/${tabs[key].url}`}
                  component={tabs[key].component}
                />
              );
            })}
            <Redirect to={`${props.match.url}/${redirectTo.url}`} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default MyAccountPage;
