import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import I18n from '@components/I18n';
import classNames from 'classnames';
import { SwitchCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';

const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  section: {
    margin: '10px 0',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
    '&.asterisk': {
      marginLeft: '-7px',
      width: '112px',
    },
  },
  fontSizeInput: {
    width: '68px',
    height: '40px',
  },
}));

const RequestSentTextSection = (props) => {
  const classes = useStyles(props);
  const { setCustomizationData, customization, horizontalAlignmentOptions } = props;
  const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };
  const { requestSentText, requestSentFontSize, requestSentColor, requestSentHorizontalAlignment } =
    (customization as SwitchCustomization) || {};

  return (
    <div className={classes.section}>
      <I18n className={classes.titleWrapper}>
        create-widget-page.create-widget.step-four.switch.request-sent-text
      </I18n>
      <div className={classes.item}>
        <I18n noEllipsis={true} element="div" className={classNames(classes.text, 'asterisk')}>
          create-widget-page.create-widget.step-four.switch.request-sent-text.text
        </I18n>
        <TextField
          value={requestSentText || ''}
          margin="dense"
          fullWidth
          className={classes.textField}
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'requestSentText');
          }}
          inputProps={{
            maxLength: 200,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.size
        </I18n>
        <TextField
          className={classes.fontSizeInput}
          value={requestSentFontSize || '12'}
          type="number"
          variant="outlined"
          margin="dense"
          onChange={(e) => {
            setCustomizationData(+e.target.value, 'requestSentFontSize');
          }}
          onBlur={(e) => {
            setCustomizationData(
              100 < +requestSentFontSize
                ? 100
                : 1 > +requestSentFontSize
                ? 1
                : +requestSentFontSize,
              'requestSentFontSize'
            );
          }}
          inputProps={{
            min: 1,
            max: 100,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>

      <div className={classNames(classes.item, classes.colorItem)}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.text-color
        </I18n>
        <ColorInput
          value={requestSentColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'requestSentColor')}
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.switch.textual-remark.horizontal-alignment
        </I18n>
        <Select
          styles={selectCustomStyle}
          options={horizontalAlignmentOptions}
          value={
            requestSentHorizontalAlignment &&
            horizontalAlignmentOptions.find((opt) => opt.value === requestSentHorizontalAlignment)
          }
          onChange={(option) =>
            setCustomizationData(option.value, 'requestSentHorizontalAlignment')
          }
        />
      </div>
    </div>
  );
};

export default RequestSentTextSection;
