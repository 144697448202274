import React from 'react';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { makeStyles } from '@material-ui/core';
import styles from './CalculatedNumericInput.scss';
import { i18nService } from '@core/i18n/I18nService';
import { CalculatedNumericInputProps } from './CalculatedNumericInput.interface';
import { TextField } from '@material-ui/core';
import {
  editExpression,
  getDataSources,
  getExpressionToDisplay,
} from '@core/expressionUtilities/expressionUtilities';
import classNames from 'classnames';
import { getDataSourcesWithDataType } from '@pages/CreateWidgetPage/DataStepComponent/StepThree/GroupingAndMeasures/Metrics/Calculations/Calculations.utils';

function CalculatedNumericInput(props: CalculatedNumericInputProps) {
  const {
    expression,
    setExpression,
    widgetData,
    onChange,
    inputWidth,
    tagsAllowed = false,
  } = props;
  const tags = getDataSourcesWithDataType(widgetData.tags, 'assetTags');
  const tagTypes = getDataSourcesWithDataType(widgetData.tagTypes, 'tagTypes');
  const variables = getDataSourcesWithDataType(widgetData.variables, 'systemProperties');

  function handleChange(key: string, value): any {
    onChange(value);
    setExpression(value);
  }

  return (
    <div className={styles.customizeRow} style={{ cursor: 'pointer', pointerEvents: 'auto' }}>
      <div className={styles.icon}>
        <Icon
          type="calculation"
          color="var(--systemFont)"
          style={{ margin: '0 10px 11px 0' }}
          tooltipText={i18nService.translate('expression-builder.calculation.caption')}
          width={'30px'}
          height={'30px'}
          onClick={() => {
            tagsAllowed
              ? editExpression(
                  isNaN(parseFloat(expression)) ? expression : expression.toString(),
                  [
                    ...getDataSources(widgetData, variables, 'VARIABLES'),
                    ...getDataSources(
                      widgetData,
                      widgetData.assetTypes?.length > 1 ? tagTypes : tags
                    ),
                  ],
                  handleChange,
                  tagsAllowed
                )
              : editExpression(
                  isNaN(parseFloat(expression)) ? expression : expression.toString(),
                  getDataSources(widgetData, variables, 'VARIABLES'),
                  handleChange,
                  tagsAllowed
                );
          }}
        />
      </div>
      <div
        className={classNames(styles.input, 'ellipsis-overflow')}
        style={{ width: inputWidth ? inputWidth : '500px' }}>
        {expression && !isFinite(expression as any) ? (
          <div className={'ellipsis-overflow'} title={getExpressionToDisplay(expression)}>
            {getExpressionToDisplay(expression)}
          </div>
        ) : (
          <TextField
            value={expression}
            margin="dense"
            type="number"
            fullWidth
            variant="outlined"
            onChange={(e) => onChange(e.target.value)}
            inputProps={{
              style: { color: 'var(--systemFont)', width: '100%' },
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CalculatedNumericInput;
