import React, { useState, useEffect } from 'react';
import { Button, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { stepsDisplayText, getWidgetSteps, updatingStepsStatus, defaultSteps } from './utils';
import styles from './WidgetSideBar.scss';
import { SideBarProps } from './widgetSideBar.interface';
import { widgetStepValidationMap } from '../widgetStepValidation/widgetStepValidationMap';
import { useSelector } from '@src/redux/useSelector';
import { widgetMap } from '@core/canvas/widgetMap';

const style = {
  root: {
    display: 'flex',
    minWidth: '40px',
    width: '40px',
    height: ' 40px',
    boxShadow: '0 2px 4px 0 var(--widgetGalleryArrowBoxShadow)',
    background: 'var(--lightGrey)',
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold' as 'bold',
  },
  active: {
    boxShadow: 'var(--boxShadow_1)',
    backgroundColor: 'var(--systemFontSelected)',
    color: 'var(--white1)',
  },
};

function WidgetSideBar(props: SideBarProps) {
  const { currentStep, onUserChangeStep, classes, widgetData } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const languageId = useSelector((state) => state.config.languageId);
  const [enableAnimation, setEnableAnimation] = useState(false);
  const [stepsList, setStepsList] = useState([]);

  useEffect(() => {
    if (widgetData && widgetData.type) {
      const widget = widgetMap[widgetData.type];
      const useSteps =
        (widget && widget.settings && widget.settings.useSteps) ||
        (widgetData.eventTemplateId && [1, 2, 3, 4]);

      const data = getWidgetSteps(useSteps, defaultSteps);

      updatingStepsStatus(data, currentStep, widgetStepValidationMap, widgetData);

      setStepsList(data);
    }
  }, [currentStep, widgetData]);

  const toggleHamburger = () => {
    setEnableAnimation(true);
    setOpenMenu(!openMenu);
  };

  return (
    <div
      className={classNames(
        styles.widgetSideBar,
        enableAnimation && styles.enableAnimation,
        openMenu ? styles.expanded : styles.narrow
      )}>
      <div
        className={classNames(
          styles.wrapper,
          enableAnimation && styles.enableAnimation,
          openMenu ? styles.expanded : styles.narrow
        )}>
        {stepsList.map((item, idx) => (
          <React.Fragment key={idx}>
            <div
              className={styles.container}
              style={item.isDisable ? { cursor: 'default', pointerEvents: 'none' } : undefined}
              onClick={() => !item.isDisable && onUserChangeStep(currentStep, item.stepIndex)}>
              <div className={styles.step}>
                <Button
                  disabled={item.isDisable}
                  className={classNames(
                    classes.label,
                    classes.root,
                    (currentStep === item.stepIndex || item.isDone) && classes.active
                  )}>
                  {item.isDone ? <Icon className={styles.innerIcon} type="checkMark" /> : idx + 1}
                </Button>
                {idx + 1 < stepsList.length && <div className={styles.line}></div>}
              </div>
              <div className={styles.data}>
                <div
                  className={classNames(
                    styles.title,
                    currentStep === item.stepIndex && styles.activeTitle
                  )}>
                  <I18n>{item.title}</I18n>
                </div>
                <div
                  className={classNames(
                    'ellipsis-overflow',
                    styles.text,
                    currentStep === item.stepIndex && styles.activeText
                  )}>
                  {item.component && (
                    <item.component
                      displayData={stepsDisplayText[item.stepIndex](widgetData, languageId)}
                    />
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      {openMenu ? (
        <Icon className={styles.arrowIcon} type="arrow_open_menu" onClick={toggleHamburger} />
      ) : (
        <Icon className={styles.arrowIcon} type="arrow_close_menu" onClick={toggleHamburger} />
      )}
    </div>
  );
}

export default withStyles(style)(WidgetSideBar);
