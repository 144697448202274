import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@components/Icon';
import styles from './RouterAssetDisconnectBTN.scss';
import { httpService } from '@core/http/HttpService';
import { AssetData } from '@src/redux/redux.interface';
import { modalService } from '@core/modals/ModalService';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';

function RouterAssetDisconnectBTN(props) {
  const { assetId, assetName, onDataChanged } = props;
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const disconnectRouter = () => {
    modalService
      .openModal('confirm', {
        text: 'router-assets-tab.disconnect-router',
        iconType: 'attention_image',
        assetName,
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'router-assets-tab.disconnect-router-popup-header',
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          httpService
            .api({
              type: 'disconnectRouterFromAsset',
              urlParams: { assetId },
            })
            .then(() => {
              // Refreshing the header and assets tab table
              onDataChanged();

              // Refreshing the parent routers table
              routersService.refreshRoutersTableData();
            });
        }
      });
  };

  return (
    <>
      {hasPermission && (
        <IconButton onClick={disconnectRouter} id={styles.disconnectButton}>
          <Icon
            type="disconnectPlc"
            color="var(--systemFont)"
            tooltipText="remove-router-modal.disconnect-tooltip"
          />
        </IconButton>
      )}
    </>
  );
}
export default RouterAssetDisconnectBTN;
