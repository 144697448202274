import React, { useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

// Components
import Description from './Description/Description';

// Services | Interfaces
import I18n from '@components/I18n';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { ValueWidgetProps } from './ValueWidget.interface';
import { convertStatusName } from '../charts.utils';
import { numberFormatter } from '@core/utils';
import { useSelector } from '@src/redux/useSelector';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: `flex`,
    overflow: 'auto',
    color: 'var(--widgetsFont)',
    flexDirection: 'column',
    padding: '5px 23px 15px 23px',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '& :hover': {
      cursor: ({ navigateDashboard, widget }) =>
        navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
    },
  },
  titleWrapper: {
    display: `flex`,
    flexDirection: ({ customization }: any) =>
      customization.showSymbolLeft ? 'row-reverse' : 'row',
    justifyContent: ({ customization }: any) =>
      customization.showSymbolLeft ? 'flex-end' : 'flex-start',
    flex: 1,
    alignItems: 'center',
  },
  symbol: {
    color: ({ customization }: any) => customization.symbolColor || 'var(--widgetsFont)',
    paddingRight: ({ customization }: any) => (customization.showSymbolLeft ? 10 : 0),
    paddingLeft: ({ customization }: any) => (customization.showSymbolLeft ? 0 : 10),
    fontWeight: 600,
    fontSize: ({ customization }: any) => `${customization.symbolFontSize || 20}px`,
    verticalAlign: 'text-top',
  },
  value: {
    color: ({ customization }: any) => customization.valueColor || 'var(--widgetsFont)',
    fontSize: ({ customization }: any) => `${customization.valueFontSize || 35}px`,
    fontWeight: 800,
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
  },
  emptyBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function ValueWidget(props: ValueWidgetProps) {
  const classes = useStyles(props);
  const { widget, navigateDashboard, defaultDecimalDigits, timezone } = props;
  const {
    symbol,
    showSymbolLeft,
    decimalDigits = defaultDecimalDigits,
    dateTimeFormat,
  } = props.customization;
  const wrapperRef = useRef(null);
  const valueRef = useRef(null);

  const value =
    props.data?.results &&
    props.data.results[0] &&
    convertStatusName(
      props.data.columns[0],
      props.data.results[0][props.data.columns[0].name],
      null,
      dateTimeFormat,
      timezone
    );

  const onNavigate = () => {
    if (navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: value,
        selectedColumn: props.data && props.data.columns && props.data.columns[0],
        widget,
        columns: props.data.columns,
        rawValues: props.data.results[0],
        navigateDashboard,
      });
    }
  };

  return (value && value !== '') || value === 0 || value === false ? (
    <div className={classes.wrapper} ref={wrapperRef} onClick={onNavigate}>
      <>
        <div className={classes.titleWrapper}>
          {showSymbolLeft ? (
            <div
              ref={valueRef}
              className={classes.value}
              title={`${symbol && symbol !== '' ? symbol : ''} ${value} `}>
              {symbol && symbol !== '' && <span className={classes.symbol}>{symbol}</span>}
              {isNaN(Number(props.data.results[0][props.data.columns[0].name])) ||
              props.data.columns[0].format === 'STRING'
                ? value
                : typeof value === 'boolean'
                ? (value + '').toUpperCase()[0] + (value + '').slice(1)
                : numberFormatter(+value, decimalDigits)}
            </div>
          ) : (
            <div
              ref={valueRef}
              className={classes.value}
              title={`${value} ${symbol && symbol !== '' ? symbol : ''}`}>
              {isNaN(Number(props.data.results[0][props.data.columns[0].name])) ||
              props.data.columns[0].format === 'STRING'
                ? value
                : typeof value === 'boolean'
                ? (value + '').toUpperCase()[0] + (value + '').slice(1)
                : numberFormatter(+value, decimalDigits)}
              {symbol && symbol !== '' && <span className={classes.symbol}>{symbol}</span>}
            </div>
          )}
        </div>
        <Description customization={props.customization} />
      </>
    </div>
  ) : (
    <div className={classes.emptyBox}>
      <I18n className={classes.emptyTitle}>widget.widget-empty-label</I18n>
    </div>
  );
}

export default ValueWidget;
