import { StoreAction } from './redux.interface';

export const setClientType = (payload: any): StoreAction => ({
  type: 'client/SET_CLIENT_TYPE',
  payload,
});

export default function clientReducer(state = {}, action: StoreAction) {
  if (action.type == 'client/SET_CLIENT_TYPE') {
    return { clientType: action.payload };
  } else {
    return state;
  }
}
