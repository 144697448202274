import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import styles from './RemoteAccessViewer.scss';
import RemoteAccessButton from '@components/RemoteAccessButton';
import Icon from '@components/Icon';

function RemoteAccessViewer(props) {
  const { assetInfo, macchinaConnected } = props;
  const buttonStyles = { width: 62, height: 24, marginLeft: 10, minWidth: 62 };
  const assetStatus = assetInfo?.staticData?.status;

  return (
    <div className={styles.wrapper}>
      {assetStatus !== 'ARCHIVED' && (
        <div className={styles.statusWrapper}>
          <div
            className={classNames(
              styles.dataLabel,
              styles.bold,
              styles.smallWidth,
              'ellipsis-overflow'
            )}
            style={{ width: 'fit-content', maxWidth: '170px' }}>
            <I18n>{'edit-asset-modal.remote-access-status'}</I18n>:
          </div>
          <div className={styles.statusIcon}>
            {macchinaConnected ? (
              <Icon type="validation_succeded" />
            ) : (
              <Icon type="validation_failed" />
            )}
          </div>
        </div>
      )}
      <div className={styles.remoteAccess}>
        <RemoteAccessButton
          {...assetInfo?.general}
          parentComponent="EditAssetModal"
          remoteAccessType="vpn"
          styles={{ ...buttonStyles, marginLeft: 0 }}
          status={assetInfo.staticData.status}
          macchinaConnected={macchinaConnected}
        />
        <RemoteAccessButton
          {...assetInfo?.general}
          parentComponent="EditAssetModal"
          remoteAccessType="web"
          styles={buttonStyles}
          status={assetInfo.staticData.status}
          macchinaConnected={macchinaConnected}
        />
        <RemoteAccessButton
          {...assetInfo?.general}
          parentComponent="EditAssetModal"
          remoteAccessType="vnc"
          styles={buttonStyles}
          status={assetInfo.staticData.status}
          macchinaConnected={macchinaConnected}
        />
      </div>
    </div>
  );
}

export default RemoteAccessViewer;
