import React, { useState, useEffect } from 'react';
import ModalWrapper from './ModalWrapper';
import { modalService } from './ModalService';

/**
 * A container for the modals. Interacts with the modalService.
 */
export default function ModalsContainer() {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    const sub = modalService.subscribe(m => setModals(m));
    return () => sub.unsubscribe();
  }, []);

  return (
    <>
      {modals.map(({ id, args, props, Component }) => (
        <ModalWrapper key={id} id={id} args={args} props={props} Component={Component} />
      ))}
    </>
  );
}
