import React, { useEffect } from 'react';
import { RadialGaugeCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface.d';
import { GaugeCustomizationProps } from '../GaugeCustomization.interface';
import {
  getDefaultRadialGaugeCustomization,
  getGaugeRadiusConfig,
  getRadialNeedleConfig,
} from './RadialGaugeCustomizationUtils';
import {
  getDataAndValueConfig,
  getDataTicksConfig,
  getZonesConfig,
  getNewZones,
} from '../GaugeCustomizationUtils';
import GaugeCustomizationSection from '../GaugeCustomizationSection';
import { cloneDeep, isEmpty } from 'lodash';

function RadialGaugeCustomization(props: GaugeCustomizationProps) {
  const { widgetData, setWidgetData, defaultDecimalDigits } = props;

  const { customization } = widgetData;
  const _customization = cloneDeep(customization) as RadialGaugeCustomization;

  useEffect(() => {
    if (isEmpty(customization)) {
      setWidgetData((prevState) => ({
        ...prevState,
        customization: getDefaultRadialGaugeCustomization(defaultDecimalDigits),
      }));
      return;
    }

    //TODO used only for old gauges tha dont have 4 & 5 zone;
    if (_customization && _customization.zones.length < 4) {
      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          zones: [...(prevState.customization as any).zones, ...getNewZones()],
        },
      }));
    }
  }, [customization]);

  if (isEmpty(_customization)) return <></>;

  const gaugeRadiusConfig = getGaugeRadiusConfig(_customization, setWidgetData);
  const dataAndValueConfig = getDataAndValueConfig(_customization, setWidgetData);
  const dataTicksConfig = getDataTicksConfig(_customization, setWidgetData, false, widgetData);
  const zonesConfig = getZonesConfig(_customization, setWidgetData, widgetData);
  const needleConfig = getRadialNeedleConfig(_customization, setWidgetData);

  const sectionsConfig = [
    ...gaugeRadiusConfig,
    ...dataAndValueConfig,
    ...dataTicksConfig,
    ...zonesConfig,
    ...needleConfig,
  ];

  return sectionsConfig.map((section, idx) => (
    <GaugeCustomizationSection
      key={`${section.sectionLabel}_${idx}`}
      {...section}
      widgetData={widgetData}
    />
  ));
}

export default RadialGaugeCustomization;
