import I18n from '@components/I18n';
import { httpService } from '@core/http/HttpService';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './SMSMessageEditor.scss';
import RichTextEditor from '../RichTextEditor';
import Icon from '@components/Icon';
import { modalService } from '@core/modals/ModalService';
import { cssVarsService } from '@core/CssVarsService';
import { getHtmlLength } from '../RichTextEditor/RichTextEditor.utils';
import { useSelector } from '@src/redux/useSelector';
import { getState } from '@src/redux/store';
import { i18nService } from '@core/i18n/I18nService';
import { AlarmsDataSources, MAX_LENGTH } from '../../EditTemplate.utils';
import { origin } from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/AddDataSource/AddDataSource';

function SMSMessageEditor(props) {
  const { templateData, setTemplateData } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const [dataSources, setDataSources] = useState([]);

  const getDataSources = async (order) => {
    try {
      const res: any = await httpService.api({
        type: 'getEventTemplateDataSources',
        urlParams: { templateId: templateData.id },
        query: {
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      });

      if (templateData.trigger === 'ALARM') {
        setDataSources([...AlarmsDataSources, ...res]);
      } else {
        setDataSources(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataSources(null);
  }, []);

  const numberOfCharacters = useMemo(() => {
    return getHtmlLength(templateData.body, true, false);
  }, [templateData]);

  const valueOnlyDataSources = useMemo(() => {
    if (dataSources) return dataSources.filter((d) => d.type === 'VALUE' && d.status === 2);

    return [];
  }, [dataSources]);

  const showPreview = useCallback(async () => {
    const whiteLabelDetails = getState().config?.whiteLabelDetails;
    const tableDataSourceMeta = dataSources.find(
      (d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2
    );

    let tableDataSource;
    if (tableDataSourceMeta) {
      tableDataSource = await httpService.api({
        type: 'getEventTemplateDataSource',
        urlParams: { templateId: templateData.id, dataSourceId: tableDataSourceMeta.id },
      });
    }

    await modalService.openModal('previewSmsMessageModal', {
      templateData,
      displaySettingsData: whiteLabelDetails,
      tableDataSource,
    });
  }, [templateData, dataSources]);

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <I18n className={styles.header}>events-template.create-message.sms-message-template</I18n>
        <div className={styles.previewButton} onClick={showPreview}>
          <div className={styles.iconButton}>
            <Icon type={'preview'} color={cssVarsService.vars.systemEnableEditorIcons} />
          </div>
          <I18n>events-template.create-message.preview-message</I18n>
        </div>
      </div>

      <div className={styles.body}>
        <RichTextEditor
          allowRichText={false}
          allowDataSources={true}
          dataSources={valueOnlyDataSources}
          value={templateData.body}
          propertyName="body"
          setTemplateData={setTemplateData}
          maxLength={MAX_LENGTH.MAX_MESSAGE_LENGTH.SMS_NOTIFICATION}
          originatesFrom={origin.eventTemplate}
        />
        <I18n
          className={styles.bodyLengthRemark}
          length={numberOfCharacters}
          maxLength={MAX_LENGTH.MAX_MESSAGE_LENGTH.SMS_NOTIFICATION}>
          events-template.create-message.sms-message-length
        </I18n>
        <I18n className={styles.bodyLengthRemark}>
          events-template.create-message.message-length-disclaimer
        </I18n>
        <I18n className={styles.bodyLengthRemark}>
          events-template.create-message.sms-message-length.notice
        </I18n>
      </div>
    </div>
  );
}

export default SMSMessageEditor;
