import React, { useRef } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import I18n from '@components/I18n';
import PreviewHeader from '@components/widgets/PreviewHeader';
import { remoteAccessWidgets, widgetMap } from '@core/canvas/widgetMap';
import { getConfigValueFromWidgetSettings, excludesWidgets } from '@core/canvas/widget.utils';
import TableData from './TableData';
import { PreviewSectionProps } from './PreviewSection.interface.d';
import Image from '@components/Image';
import styles from './PreviewSection.scss';
import { useSelector } from '@src/redux/useSelector';

function PreviewSection(props: PreviewSectionProps) {
  const { widgetData, data, defaultDecimalDigits } = props;
  const emptyStateImageUrls = useSelector((state) => state.config.emptyStateImageUrls);
  const ref = useRef<HTMLDivElement>(null);
  const shouldShowPreview =
    !getConfigValueFromWidgetSettings(widgetData.type, 'showPreviewData') ||
    getConfigValueFromWidgetSettings(widgetData.type, 'showPreviewData').find((key) => {
      const data = get(widgetData, key);
      return Array.isArray(data) ? data.length : data;
    });
  const WidgetPreview = widgetMap[widgetData.type] && widgetMap[widgetData.type].component;
  const hidePreviewTable =
    (widgetMap[widgetData.type] && widgetMap[widgetData.type].settings.hidePreviewTable) ||
    (widgetData.type === 'line' && !widgetData.metrics.length);
  const widgetItem = widgetMap[widgetData.type];
  return shouldShowPreview ? (
    <>
      {(!widgetData.eventTemplateId ||
        widgetData.type === 'table' ||
        widgetData.type === 'alarms') && (
        <I18n className={styles.stepHeader} element="div">
          create-widget-page.create-widget.step-three.preview-title
        </I18n>
      )}
      <div
        className={
          !widgetData.eventTemplateId || widgetData.type === 'table' || widgetData.type === 'alarms'
            ? styles.stepContent
            : styles.fullHeightStepContent
        }>
        {(!widgetData.eventTemplateId ||
          widgetData.type === 'table' ||
          widgetData.type === 'alarms') && (
          <div className={styles.preview}>
            {!widgetData.hideWidgetName && (
              <PreviewHeader name={widgetData.name} customization={widgetData.customization} />
            )}
            {WidgetPreview && (
              <div
                className={classNames(
                  styles.widgetWrapper,
                  widgetData.hideWidgetName ? styles.widgetWrapperWidthoutActionBar : ''
                )}
                ref={ref}>
                {ref.current &&
                ((data && data.results && data.results.length) ||
                  excludesWidgets.includes(widgetData.type)) ? (
                  //exculding map because the map is the only one that responsive for his own data
                  <WidgetPreview
                    widgetData={widgetData}
                    data={data}
                    type={widgetData.type}
                    emptyImage={
                      remoteAccessWidgets.includes(widgetItem.name)
                        ? `${emptyStateImageUrls[widgetItem.name]}`
                        : widgetItem.images.canvas
                    }
                    customization={widgetData.customization || {}}
                    defaultDecimalDigits={defaultDecimalDigits}
                    style={{ height: ref.current.offsetHeight }}
                    isPreview={true}
                  />
                ) : (
                  <Image
                    mode="smaller"
                    src={
                      remoteAccessWidgets.includes(widgetItem.name)
                        ? `${emptyStateImageUrls[widgetItem.name]}`
                        : widgetItem.images.canvas
                    }
                    text="widget.widget-empty-label"
                  />
                )}
              </div>
            )}
          </div>
        )}
        {!hidePreviewTable && (
          <>
            <I18n className={classNames(styles.stepHeader, styles.tableDataTitle)} element="div">
              create-widget-page.create-widget.step-three.table-data
            </I18n>
            <div
              className={
                !widgetData.eventTemplateId ||
                widgetData.type === 'table' ||
                widgetData.type === 'alarms'
                  ? styles.tableData
                  : styles.fullHeightTableData
              }>
              <TableData data={data} widgetData={widgetData} />
            </div>
          </>
        )}
        {['vnc', 'web'].includes(widgetData.type) && (
          <>
            <I18n className={classNames(styles.stepHeader, styles.tableDataTitle)} element="div">
              create-widget-page.create-widget.step-four.connect-button-preview
            </I18n>
            <div className={styles.preview}>
              {!widgetData.hideWidgetName && (
                <PreviewHeader name={widgetData.name} customization={widgetData.customization} />
              )}
              {WidgetPreview && (
                <div
                  className={classNames(
                    styles.widgetWrapper,
                    widgetData.hideWidgetName ? styles.widgetWrapperWidthoutActionBar : ''
                  )}
                  ref={ref}>
                  {ref.current &&
                  ((data && data.results && data.results.length) ||
                    excludesWidgets.includes(widgetData.type)) ? (
                    //exculding map because the map is the only one that responsive for his own data
                    <WidgetPreview
                      widgetData={widgetData}
                      data={data}
                      type={'remote_operator'}
                      emptyImage={
                        remoteAccessWidgets.includes(widgetItem.name)
                          ? `${emptyStateImageUrls[widgetItem.name]}`
                          : widgetItem.images.canvas
                      }
                      customization={widgetData.customization || {}}
                      defaultDecimalDigits={defaultDecimalDigits}
                      style={{ height: ref.current.offsetHeight }}
                      isPreview={true}
                    />
                  ) : (
                    <Image
                      mode="smaller"
                      src={
                        remoteAccessWidgets.includes(widgetItem.name)
                          ? `${emptyStateImageUrls[widgetItem.name]}`
                          : widgetItem.images.canvas
                      }
                      text="widget.widget-empty-label"
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div className={styles.stepHeader} />
      <div className={classNames(styles.stepContent, styles.empty)}>
        <I18n element="div">create-widget-page.create-widget.step-three.no-preview</I18n>
      </div>
    </>
  );
}

export default React.memo(PreviewSection);
