import I18n from '@components/I18n';
import React from 'react';
import classNames from 'classnames';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';

const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  section: {
    margin: '10px 0',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
    paddingRight: '5px',
    overflowWrap: 'anywhere',
    '&.asterisk': {
      marginLeft: '-7px',
      width: '112px',
    },
  },
  fontSizeInput: {
    width: '68px',
    height: '40px',
  },
}));

const SwitchSection = (props) => {
  const classes = useStyles(props);
  const { onText, offText, color, setCustomizationData, section } = props;

  return (
    <div className={classes.section}>
      <I18n className={classes.titleWrapper}>
        create-widget-page.create-widget.step-four.update-asset.switch-button
      </I18n>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classNames(classes.text, 'asterisk')}>
          create-widget-page.create-widget.step-four.switch.on-text
        </I18n>
        <TextField
          className={classes.textField}
          value={onText || ''}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'onText', section);
          }}
          inputProps={{
            maxLength: 20,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.switch.off-text
        </I18n>
        <TextField
          className={classes.textField}
          value={offText || ''}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'offText', section);
          }}
          inputProps={{
            maxLength: 20,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.switch.color
        </I18n>
        <ColorInput
          value={color}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'color', section)}
        />
      </div>
    </div>
  );
};

export default SwitchSection;
