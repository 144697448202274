import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import I18n from '@components/I18n';

const useStyles = makeStyles((theme: any) => ({
  color: {
    width: 21,
    height: 21,
    borderRadius: 2,
    background: (props: any) =>
      props.disabled
        ? props.isDisplaySettings
          ? props.color.hex
          : 'var(--defaultDisabledColor)'
        : props.color.hex,
    cursor: (props: any) => !props.disabled && 'pointer',
    opacity: (props: any) => props.disabled && 0.7,
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    top: 28,
    right: 28,
  },
  swatch: {
    padding: 2,
    background: (props: any) =>
      props.disabled ? (props.isDisplaySettings ? '#fff' : 'var(--defaultDisabledColor)') : '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: (props: any) => !props.disabled && 'pointer',
  },
  box: {
    width: 1101,
    height: 252,
    borderRadius: 5,
    backgroundColor: '#f9fafe',
  },
  fieldLabel: {
    width: (props) => props.labelWidth || 310,
    color: 'var(--systemFont)',
  },
  field: {
    display: 'flex',
    position: (props) => (props.isParentRelative ? 'unset' : 'relative'),
    marginBottom: (props) =>
      props.customeMarginButton || props.customeMarginButton == 0 ? props.customeMarginButton : 20,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

function SketchColorInput(props) {
  const {
    label,
    name,
    value,
    onChange,
    onChangeComplete,
    disabled,
    popOverPosition,
    disableScrollIntoView = false,
  } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState<any>(hexToRgba(value));
  const classes = useStyles({ ...props, color });
  const inputRef = useRef(null);
  const colorBoxRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current && displayColorPicker && !disableScrollIntoView) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [inputRef, displayColorPicker]);

  useEffect(() => {
    setColor(hexToRgba(value));
  }, [value]);

  const rgbToHex = (rgba) => {
    const { r, g, b, a } = rgba;
    const toHex = (c) => {
      return (c & 0xff).toString(16).padStart(2, '0');
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round(255 * a))}`;
  };

  function hexToRgba(hex) {
    hex = hex.replace(/^#/, '');
    let r, g, b, a;
    if (hex.length === 8) {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
      a = parseInt(hex.slice(6, 8), 16) / 255;
    } else if (hex.length === 6) {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
      a = 1;
    } else {
      r = 1;
      g = 1;
      b = 1;
      a = 1;
    }
    let color = { hex: '#' + hex.slice(0, 6), rgb: { r, g, b, a } };
    return color;
  }

  const handleChange = (color, event) => {
    if (!color) return;
    setColor(color);
    event.target.name = name;
    event.target.value = color;
    const hex = rgbToHex(color.rgb);
    if (onChange) onChange(event, hex);
  };

  const handleChangeComplete = (color, event) => {
    if (!color) return;
    setColor(color);
    const hex = rgbToHex(color.rgb);
    event.target.name = name;
    event.target.value = hex;
    hex && onChangeComplete(event);
    scrollToColorBox();
  };

  const scrollToColorBox = () => {
    if (colorBoxRef && colorBoxRef.current && !disableScrollIntoView) {
      colorBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClose = () => {
    displayColorPicker && scrollToColorBox();
    value !== rgbToHex(color.rgb) && setColor(hexToRgba(value));
    setDisplayColorPicker(false);
  };

  const handleClick = (e) => {
    !disabled && setDisplayColorPicker(!displayColorPicker);
  };

  return (
    <div className={classes.field}>
      {label && <I18n className={classes.fieldLabel}>{label}</I18n>}
      <div className={classes.swatch} ref={colorBoxRef} onClick={handleClick}>
        <div className={classes.color} />
      </div>
      {displayColorPicker && (
        <div className={classes.popover} ref={inputRef} style={popOverPosition}>
          <div className={classes.cover} onClick={handleClose}></div>
          <SketchPicker
            color={color.rgb}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      )}
    </div>
  );
}

export default SketchColorInput;
