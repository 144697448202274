import React, { useCallback, useEffect, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './UpdateSubDomain.scss';
import { InputBase } from '@material-ui/core';
import { httpService } from '@core/http/HttpService';

function UpdateSubDomain(props) {
  const { dismiss, args } = props;
  const { oldSubdomain, onChangeSuccess } = args;
  const [domain, setDomain] = useState('');
  const [status, setStatus] = useState('');
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const confirm = () => {
    switch (status) {
      case 'OK':
        dismiss();
        break;
      case 'ABOUT_TO_CHANGE':
        doValidateDomain().then((res: any) => {
          handleStatus(res.result);
        });
      default:
        setStatus('ABOUT_TO_CHANGE');
        break;
    }
  };

  const doValidateDomain = () => {
    return httpService.api({
      type: 'validateSubDomain',
      data: {
        name: `${domain}.unitronics.io`,
      },
    });
  };

  const handleStatus = (res) => {
    switch (res) {
      case 'OK':
        httpService
          .api({
            type: 'addSubdomain',
            data: {
              name: `${domain}.unitronics.io`,
            },
          })
          .then((res) => {
            setStatus('OK');
            onChangeSuccess((domain + '').toLocaleLowerCase());
          });
        break;
      case 'INVALID':
        setStatus(res);
        break;
      case 'ALREADY_EXISTS':
        setStatus(res);
        break;
    }
  };

  const handleChange = (val) => {
    setDomain(val);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>white-labeling.sub-domains.update-subdomain.header</I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        {status == 'OK' && (
          <I18n
            newSubdomain={`https://${domain}.unitronics.io`}
            oldSubdomain={`https://${oldSubdomain}.unitronics.io`}
            noEllipsis>
            white-labeling.sub-domains.update-subdomain.changed
          </I18n>
        )}
        {status == 'ABOUT_TO_CHANGE' && (
          <I18n noEllipsis>white-labeling.sub-domains.update-subdomain.about-to-change</I18n>
        )}
        {(['INVALID', 'ALREADY_EXISTS'].includes(status) || !status) && (
          <>
            <I18n noEllipsis>white-labeling.sub-domains.update-subdomain.insert-subdomain</I18n>
            <div>
              <InputBase
                className={styles.containerInput}
                value={domain}
                onChange={(e) => handleChange(e.target.value)}
              />
            </div>
          </>
        )}
        {status == 'INVALID' && (
          <I18n noEllipsis className={styles.errorText} element="div">
            white-labeling.sub-domains.registerConfirmationModal.verification.failed
          </I18n>
        )}
        {status == 'ALREADY_EXISTS' && (
          <I18n noEllipsis className={styles.errorText} element="div">
            white-labeling.sub-domains.registerConfirmationModal.verification.domainTaken
          </I18n>
        )}
      </div>
      <div className={styles.modalButtons}>
        {status !== 'OK' && (
          <Button onClick={cancel} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
            <I18n>general.cancel</I18n>
          </Button>
        )}
        <Button onClick={confirm} disabled={!domain} styles={{ marginLeft: 13 }} mode="bigFont">
          <I18n>general.confirm</I18n>
        </Button>
      </div>
    </div>
  );
}

export default UpdateSubDomain;
