import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import moment from 'moment';
import DatePicker from '@components/DatePicker';
import { CustomDateModalProps } from './CustomDateModal.interface';
import styles from './CustomDateModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';

function CustomDateModal(props: CustomDateModalProps) {
  const { args, dismiss } = props;
  const {
    confirmText,
    cancelText,
    showCloseBtn,
    headerText,
    style = {},
    customText,
    ...rest
  } = args;
  const confirmBtn = useRef(null);
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const [chosenDate, setChosenDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    confirmText && confirmBtn.current.focus();
  }, [confirmText]);

  const onConfirm = () => {
    const tempDate = chosenDate;
    tempDate ? dismiss(tempDate) : null;
  };

  return (
    <div
      className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}
      style={style ? style : {}}>
      {showCloseBtn && (
        <div className={styles.modalHeader}>
          <I18n {...rest}>{headerText}</I18n>
          <Icon type="close" onClick={close} className={'pointer'} />
        </div>
      )}
      <div className={styles.customContent}>
        <>
          <div className={styles.customTextHeader}>{customText}</div>
          <div className={styles.customDatePicker}>
            <DatePicker
              data={{
                dateFilter: 'CUSTOM_OPTIONAL',
                dateFilterFrom: chosenDate,
                dateFilterTo: null,
              }}
              singleDatePicker={true}
              displayRanges={false}
              disableCustomLabel={true}
              selectedChanged={({ dateFilterFrom }) => setChosenDate(dateFilterFrom)}
              minDate={moment().format('DD/MM/YYYY')}
              height={28}
            />
          </div>
        </>
      </div>
      <div className={styles.modalButtons}>
        {cancelText && (
          <Button onClick={close} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
            <I18n>{cancelText}</I18n>
          </Button>
        )}
        {confirmText && (
          <Button onClick={onConfirm} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
            <I18n>{confirmText}</I18n>
          </Button>
        )}
      </div>
    </div>
  );
}

export default CustomDateModal;
