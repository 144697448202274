import React, { useMemo } from 'react';
import { colorMap } from '@core/statusColorMap';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import moment from 'moment';

function SubscriptionData(props) {
  const { field, subscriptionStatus } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const value = useMemo(
    () =>
      field === 'subscriptionStatus'
        ? i18nService.translate(
            `enum.subscription.plc.${subscriptionStatus || 'NOT_AVALIABLE'}`,
            languageId
          )
        : '',
    [subscriptionStatus, field, languageId]
  );

  return (
    <span
      style={{
        color:
          field === 'subscriptionStatus' &&
          subscriptionStatus !== 'ACTIVE' &&
          colorMap[subscriptionStatus]
            ? colorMap[subscriptionStatus]
            : 'var(--systemFont)',
      }}
      title={value}>
      {value}
    </span>
  );
}

export default SubscriptionData;
