import { i18nService } from '@core/i18n/I18nService';
import { allowedOperationsMap } from '@core/mapsAndDefinitions';
import { modalService } from '@core/modals/ModalService';
import { expressionBuilderRegex } from '@core/regex';
import { origin } from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/AddDataSource/AddDataSource';
import { compact } from 'lodash';

export const editExpression = async (expression, dataSources, handleChange, tagsAllowed) => {
  const origininatesFrom = origin.widgetData;
  const editExpressionResult = await modalService.openModal('editFormulaExpressionModal', {
    expression: expression || '',
    dataSources,
    origininatesFrom,
    allowEmpty: true,
    tagsAllowed,
  });

  if (editExpressionResult) {
    handleChange('expression', editExpressionResult.expression);
  }
};

export function getExpressionToDisplay(expression, widgetType = null) {
  let newExpression =
    widgetType === 'update_asset' ? expression : convertPowerAndRootFromBE(expression);

  if (newExpression && newExpression.startsWith("'")) newExpression = newExpression.substring(1);

  if (newExpression && newExpression.endsWith("'"))
    newExpression = newExpression.substring(0, newExpression.length - 1);

  if (newExpression) {
    newExpression = newExpression
      .replace(/\[/g, '')
      .replace(/\]/g, '')
      .replace(/assetTags\./g, '')
      .replace(/tagTypes\./g, '')
      .replace(/systemProperties\./g, '')
      .replace(/assetProperties\./g, '');
  }

  return newExpression;
}

export const getDataSources = (widgetData: any, data: any, dataSourceType?: string) => {
  if (widgetData.scope === 'AGGREGATED_DATA' || widgetData.scope === 'AGGREGATED_LAST_VALUE') {
    const dataSources = [];
    let index = 0;
    const operations =
      dataSourceType === 'VARIABLES'
        ? allowedOperationsMap.get(dataSourceType)
        : allowedOperationsMap.get(widgetData.scope);

    widgetData.assetProperties
      ?.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      )
      .forEach((assetProperty) => {
        operations.forEach((operation) => {
          if (
            !isNumericFormat(assetProperty.format || assetProperty.type) &&
            operation.toUpperCase() !== 'COUNT'
          )
            return;

          dataSources.push({
            id: index,
            type: 'ASSET_PROPERTY',
            name: `${i18nService.translate(assetProperty.name)} ${i18nService.translate(
              `enum.${operation.toUpperCase()}`
            )}`,
            operation,
            dataType: 'assetProperties',
          });
          index++;
        });
      });

    data.forEach((item) => {
      operations.forEach((operation) => {
        if (
          !isNumericFormat(item.format || item.type || item.valueType) &&
          operation.toUpperCase() !== 'COUNT'
        )
          return; // Acts as a continue

        dataSources.push({
          name: `${item.name} ${i18nService.translate(`enum.${operation.toUpperCase()}`)}`,
          id: index,
          type: item.type,
          dataType: item.dataType,
        });
        index++;
      });
    });

    return dataSources;
  } else {
    return data.filter((t) => isNumericFormat(t.format || t.type || t.valueType));
  }
};

const isNumericFormat = (format) => {
  switch (format) {
    case 'INT8':
    case 'UINT8':
    case 'INT16':
    case 'UINT16':
    case 'INT32':
    case 'UINT32':
    case 'FLOAT32':
    case 'NUMERIC':
      return true;
  }

  return false;
};

export function convertPowerAndRootFromBE(expression: string): string {
  const subexpressions = new Set([
    ...[...expression.matchAll(expressionBuilderRegex.fromBE.power.numberRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.fromBE.power.tagRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.fromBE.power.expressionRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.fromBE.root.numberRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.fromBE.root.tagRegex)].map((item) => item[0]),
    ...[...expression.matchAll(expressionBuilderRegex.fromBE.root.expressionRegex)].map(
      (item) => item[0]
    ),
  ]);

  const splitSubexpressions = compact(
    Array.from(subexpressions).map((item) => {
      return [item, ...item.split(',')];
    })
  );

  const powerSubexpressions = splitSubexpressions.map((item) => {
    if (item[0].includes('power')) {
      return [
        item[0],
        `${item[1].replace('power(', '').replace('),', '$$$')}^${item[2]
          .replace(' ', '')
          .replace(')', '')
          .replace('$$$', ')')}`,
      ];
    } else if (item[0].includes('nth_root')) {
      return item[2] === ' 2)'
        ? [item[0], `√${item[1].replace('nth_root(', '')}`]
        : [
            item[0],
            `${item[2].replace(' ', '').replace(')', '')}√${item[1].replace('nth_root(', '')}`,
          ];
    }
  });

  const resultExpression = powerSubexpressions.reduce(
    (s, m, i) => s.replace(powerSubexpressions[i][0], powerSubexpressions[i][1]),
    expression
  );

  return resultExpression;
}
