import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { ImageByValueWidgetProps } from './ImageByValueWidget.interface';
import Image from '@components/Image';
import { conditionsService } from '@core/ConditionsService';
import { httpService } from '@core/http/HttpService';
import { imageCacheService } from '@core/canvas/ImageCacheService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      cursor: ({ navigateDashboard, widget }: any) =>
        navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
    },
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function ImageByValueWidget(props: ImageByValueWidgetProps) {
  const [imageUrl, setImageUrl] = useState();
  const classes = useStyles(props);
  const { widget, navigateDashboard, customization, emptyImage } = props;
  const value = useMemo(() => {
    let val = props?.data?.results && props?.data?.results[0][props?.data?.columns[0]?.name];
    return val;
  }, [props]);

  const imageData = useMemo(
    () =>
      !customization?.errors || !Object.values(customization?.errors).find((err) => err)
        ? typeof value === 'boolean'
          ? customization?.conditions?.find((c) => c.boolValue === value)
          : customization?.conditions &&
            conditionsService.findValue(customization?.conditions, value)
        : null,
    [value, customization]
  );

  const getImageData = async () => {
    try {
      const res = await imageCacheService.getImageURL(imageData.imageId);
      if (res) {
        setImageUrl(res.url);
      }
    } catch {}
  };

  useEffect(() => {
    if (imageData?.imageId) {
      getImageData();
    } else {
      setImageUrl(null);
    }
  }, [imageData]);

  const onNavigate = () => {
    if (navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: value,
        selectedColumn: props.data && props.data.columns && props.data.columns[0],
        widget,
        columns: props.data.columns,
        rawValues: props.data.results[0],
        navigateDashboard,
      });
    }
  };

  return (
    <div className={classes.wrapper} onClick={onNavigate}>
      {imageUrl ? (
        <div
          style={{
            height: '100%',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        />
      ) : (
        <Image
          mode="smaller"
          src={emptyImage}
          text={`widgets.${widget?.status === 'DRAFT' ? 'draft-' : ''}empty-state-text`}
        />
      )}
    </div>
  );
}

export default ImageByValueWidget;
