import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import styles from './ImageViewerModal.scss';
import Image from '@components/Image';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { httpService } from '@core/http/HttpService';
import { imageCacheService } from '@core/canvas/ImageCacheService';

function ImageViewerModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { imageId, imageName },
  } = props;
  const [image, setImage] = useState({ name: imageName, url: '' });
  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const getImageData = async () => {
    try {
      const res = await imageCacheService.getImageURL(imageId);
      if (res) {
        setImage(res);
      }
    } catch {}
  };

  useEffect(() => {
    getImageData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>{image?.name}</I18n>
        <div>
          <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
        </div>
      </div>
      <div className={styles.modalContent}>
        <Image src={image?.url} />
      </div>
      <div className={styles.footerButtons}>
        <Button onClick={cancel} styles={{ width: 92 }}>
          <I18n>general.confirm</I18n>
        </Button>
      </div>
    </div>
  );
}

export default ImageViewerModal;
