import React, { useMemo } from 'react';
import CanvasWidget from '@components/canvas/CanvasWidget';
import { editorConfig } from '@core/canvas/editorConfig';
import CanvasWidgetActionBar from '../CanvasWidgetActionBar';
import styles from './CanvasWidgetGroupItem.scss';
import { CanvasWidgetGroupItemProps } from './CanvasWidgetGroupItem.interface.d';
import { cssVarsService } from '@core/CssVarsService';

const cellSize = editorConfig.gridCellSizePx;

/**
 * Renders a widget inside of a widget-group.
 */
function CanvasWidgetGroupItem(props: CanvasWidgetGroupItemProps) {
  const { widget, parent } = props;
  const { hideWidgetName } = widget;

  const wrapperS = useMemo(
    () => ({
      top: (widget.y - parent.y) * cellSize,
      left: (widget.x - parent.x) * cellSize,
      height: widget.h * cellSize,
      width: widget.w * cellSize,
    }),
    [widget, parent]
  );

  return (
    <div className={styles.wrapper} style={wrapperS}>
      <div className={styles.inner}>
        <CanvasWidgetActionBar widget={widget} />
        <CanvasWidget widgetType={widget.type} widget={widget} />
      </div>
    </div>
  );
}

export default React.memo(CanvasWidgetGroupItem);
