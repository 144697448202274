import React from 'react';
import styles from './ToolBar.scss';
import classNames from 'classnames';
import Icon from '@components/Icon';
import SearchInput from '../../pages/DeviceManagment/Assets/SearchInput';
import { ToolbarProps } from './toolBar.interface';
import I18n from '@components/I18n';

function ToolBar(props: ToolbarProps) {
  const { search, buttons, customStyles, style } = props.toolbarCfg;
  const { resultsCount, totalCount } = props;

  return (
    <div className={!style ? styles.container : undefined} style={style && { ...style }}>
      {search ? (
        <SearchInput resultsCount={resultsCount} totalCount={totalCount} {...search} />
      ) : (
        <div></div>
      )}
      {buttons && (
        <div className={styles.btns} style={customStyles && customStyles.btns}>
          {buttons.map((btn, idx) => (
            <div
              key={idx}
              style={{ display: 'flex' }}
              onClick={(e) => btn.onClick(e)}
              className={classNames('pointer', btn.disabled && styles.disabled)}>
              <Icon
                className={classNames('pointer', styles.iconBtn)}
                type={btn.type}
                color="var(--systemFont)"
                disabled={btn.disabled}
              />
              {btn.text && <I18n className={styles.iconText}>{btn.text}</I18n>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ToolBar;
