import React, { useState, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { httpService } from '@core/http/HttpService';

// Components
import DatePicker from '@components/DatePicker';
import I18n from '@components/I18n';
import Button from '@components/Button';

// Services | Interfaces
import { modalService } from '@core/modals/ModalService';
import { ValidateUserResponse } from './DeletedUsersForgetSection.interface';
import styles from './DeletedUsersForgetSection.scss';

// 3rd party
import moment from 'moment';
import classNames from 'classnames';

function DeletedUsersForgetSection(props: any) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [emailNotExistError, setEmailNotExistError] = useState(false);
  const [emailRegexError, setEmailRegexError] = useState(false);
  const [requestDate, setRequestDate] = useState('');
  const [showPicker, setShowPicker] = useState(true);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setEmailRegexError(!emailRegex.test(e.target.value));
    setEmailNotExistError(false);
  };

  const handleRequestDateChange = (e) => {
    setRequestDate(e.dateFilterFrom);
  };

  const validate = () => {
    setEmailNotExistError(false);

    httpService
      .api({
        type: 'validateUserCanBeForgotten',
        data: { email },
      })
      .then((res: ValidateUserResponse) => {
        switch (res.message) {
          case 'OK':
            forgetUser();
            break;
          case 'EMAIL_DOES_NOT_EXISTS':
            setEmailNotExistError(true);
            break;
          case 'USER_IS_MAIN_CONTACT':
            openMainContactModals(res.organizationNames);
            break;
        }
      });
  };

  const openMainContactModals = (orgsNames) => {
    modalService
      .openConfirm({
        headerText: 'my-account-page.deleted-users.forget-user-modal.header',
        text: 'my-account-page.deleted-users.forget-user-modal.main-contact-error',
        confirmText: 'general.continue',
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (!confirm) return;

        const { type, orgNameString } = getOrgNameAndType(orgsNames);
        modalService
          .openConfirm(
            {
              headerText: 'my-account-page.deleted-users.confirm-delete-modal.header',
              text: `my-account-page.deleted-users.confirm-delete-modal.${type}-organization`,
              confirmText: 'general.delete',
              showCloseBtn: true,
            },
            { [`${type}OrgName`]: orgNameString }
          )
          .then((confirm) => confirm && forgetUser());
      });
  };

  const forgetUser = async () => {
    const res: any = await httpService.api({
      type: 'forgetUser',
      data: { name: username, email, requestDate },
    });
    res?.notDeletedOrganizations && (await onCannotDeleteOrg(res.notDeletedOrganizations));
    props.refreshTableData();
    initFields();
  };

  const onCannotDeleteOrg = async (notDeletedOrganizations: string[]) => {
    const { type, orgNameString } = getOrgNameAndType(notDeletedOrganizations);
    await modalService.openModal('alert', {
      text: `my-account-page.deleted-users.cannot-delete-modal.${type}-organization`,
      confirmText: 'general.delete',
      showCloseBtn: true,
      [`${type}OrgName`]: orgNameString,
    });
  };

  const getOrgNameAndType = (orgsNames: string[]) => {
    let type: string, orgNameString: string;
    if (orgsNames.length > 1) {
      type = 'multiple';
      orgNameString = orgsNames.join(', ');
    } else {
      type = 'single';
      orgNameString = orgsNames[0].toString();
    }
    return { type, orgNameString };
  };

  const initFields = () => {
    setUsername('');
    setEmail('');
    setRequestDate('');
    // showPicker is set in order to init datePicker component
    setShowPicker(false);
    setTimeout(() => setShowPicker(true));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.itemWrapper}>
        <div className={styles.item}>
          <div className={styles.itemLabel}>
            <I18n>my-account-page.deleted-users.user-name</I18n>
          </div>
          <TextField
            className={styles.textField}
            inputProps={{
              style: {
                height: 9,
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8,
                color: 'var(--systemFont)',
              },
              maxLength: 100,
            }}
            value={username}
            variant="outlined"
            fullWidth
            onChange={handleUsernameChange}
          />
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <div className={styles.item}>
          <div className={styles.itemLabel}>
            <I18n>my-account-page.deleted-users.email</I18n>
          </div>
          <TextField
            className={styles.textField}
            inputProps={{
              style: {
                height: 9,
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8,
                color: 'var(--systemFont)',
              },
            }}
            value={email}
            variant="outlined"
            fullWidth
            onChange={handleEmailChange}
          />
          {(emailRegexError || emailNotExistError) && (
            <I18n className={styles.error} element="div">
              {`my-account-page.deleted-users.${
                emailNotExistError ? 'email-not-exist-error' : 'email-regex-error'
              }`}
            </I18n>
          )}
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <div className={classNames(styles.item, styles.pickerItem)}>
          <div className={styles.itemLabel}>
            <I18n>my-account-page.deleted-users.request-date</I18n>
          </div>
          {showPicker && (
            <DatePicker
              singleDatePicker={true}
              displayRanges={false}
              disableCustomLabel={true}
              selectedChanged={handleRequestDateChange}
              maxDate={moment().format('DD/MM/YYYY')}
              height={25}></DatePicker>
          )}
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <Button
          onClick={validate}
          mode="outlined"
          disabled={!username?.length || !email?.length || !requestDate?.length || emailRegexError}
          styles={{ width: 88, height: 24, backgroundColor: 'var(--white1)' }}>
          <I18n>my-account-page.deleted-users.forget-me</I18n>
        </Button>
      </div>
    </div>
  );
}

export default DeletedUsersForgetSection;
