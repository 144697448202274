import React, { useState, useMemo } from 'react';
import { dispatch, getState } from '@src/redux/store';
import { isEqual } from 'lodash';
import RefreshRateSelect from './RefreshRateSelect';
import ConfigurationPopover from '@src/components/ConfigurationPopover';
import CanvasWidthSelect from './CanvasWidthSelect';
import ShowGridSelect from './ShowGridSelect';
import SplitLayoutSelect from './SplitLayoutSelect';
import { canvasService } from '@core/canvas/CanvasService';
import { httpService } from '@core/http/HttpService';
import {
  calculateCanvasHeightForNewStates,
  calculateTabletAndMobileWidgetStates,
  getWidgetStates,
  multiLayout,
  statesLocalToServer,
} from '../DashboardEditorLayout.utils';
import { useSelector } from '@src/redux/useSelector';
import {
  setCurrentLayout,
  setDashboardStates,
  setDashboardWidgets,
} from '@src/redux/dashboardEditor';

function SettingsPopover({ openSettingsPopover, anchorEl, closeSettings, isRefreshRateVisible }) {
  const [leaveOpen, setLeaveOpen] = useState(false);
  const dashboardStates = useSelector((state) => state.dashboardEditor.states);
  const currentLayout = useSelector((state) => state.dashboardEditor.currentLayout);
  const widgets = useSelector((state) => state.dashboardEditor.canvasWidgets);

  const initialSettings = {
    splitLayout: useSelector((state) => state.dashboardEditor.isSplitLayout),
    canvasWidth: useSelector((state) => state.dashboardEditor.canvasWidth),
    showGrid: useSelector((state) => state.dashboardEditor.showGrid),
    refreshRate: useSelector((state) => state.dashboardEditor.refreshRate),
  };

  const [splitLayout, setSplitLayout] = useState(initialSettings.splitLayout);
  const [showGrid, setShowGrid] = useState(initialSettings.showGrid);
  const [refreshRate, setRefreshRate] = useState(initialSettings.refreshRate);
  const [canvasWidth, setCanvasWidth] = useState(initialSettings.canvasWidth);

  let canvasHeightTablet;
  let canvasHeightMobile;
  let newWidgets;

  const onSplitLayoutChanged = async (newDashboardStates: Array<any>) => {
    if (splitLayout) {
      const tabletAndMobileWidgetStates = calculateTabletAndMobileWidgetStates(widgets);
      newWidgets = getWidgetStates(widgets, tabletAndMobileWidgetStates);
      canvasHeightTablet = calculateCanvasHeightForNewStates(tabletAndMobileWidgetStates['tablet']);
      canvasHeightMobile = calculateCanvasHeightForNewStates(tabletAndMobileWidgetStates['mobile']);
      dispatch(setDashboardWidgets(newWidgets));
      const resDashboardStates = multiLayout(
        newDashboardStates.find((state) => state.layoutStateType === 'DESKTOP'),
        canvasHeightTablet,
        canvasHeightMobile
      );
      dispatch(setDashboardStates(resDashboardStates));
      return resDashboardStates;
    } else {
      const desktopCanvasWidthValue = dashboardStates.find(
        (state) => state.layoutStateType === 'DESKTOP'
      ).canvasWidthValue;
      const canvasWidth = {
        label: `${desktopCanvasWidthValue}px`,
        value: desktopCanvasWidthValue,
      };
      dispatch(setCurrentLayout('DESKTOP'));
      canvasService.switchCanvasWidth(canvasWidth);
      setCanvasWidth(canvasWidth);
      canvasService.setCanvasWidgetPositions(widgets, 'DESKTOP');
      const resDashboardStates = [
        newDashboardStates.find((state) => state.layoutStateType === 'DESKTOP'),
      ];

      dispatch(setDashboardStates(resDashboardStates));
      return resDashboardStates;
    }
  };

  const submit = async () => {
    const { id } = getState().dashboardEditor;

    const newDashboardStateByCurrentState = [
      {
        ...dashboardStates.find((state) => state.layoutStateType === currentLayout),
        canvasWidthLabel: canvasWidth.label,
        canvasWidthValue: canvasWidth.value,
      },
    ];

    let newDashboardStates = dashboardStates
      .filter((state) => state.layoutStateType !== currentLayout)
      .concat(newDashboardStateByCurrentState)
      .map((state) => {
        return { ...state, refreshRate: refreshRate, showGrid: showGrid };
      });

    if (splitLayout !== initialSettings.splitLayout) {
      newDashboardStates = await onSplitLayoutChanged(newDashboardStates);
    }

    if (
      !isEqual(canvasWidth, initialSettings.canvasWidth) &&
      (splitLayout || currentLayout === 'DESKTOP')
    ) {
      canvasService.changeCanvasWidth(canvasWidth, newDashboardStates);
    }
    refreshRate !== initialSettings.refreshRate && canvasService.changeRefreshRate(refreshRate);
    showGrid !== initialSettings.showGrid && canvasService.changeShowGrid(showGrid);
    splitLayout !== initialSettings.splitLayout && canvasService.changeLayoutMode(splitLayout);

    httpService.api({
      type: 'updatesDashboardStates',
      urlParams: {
        id,
      },
      disableBI: true,
      data: statesLocalToServer(
        splitLayout,
        initialSettings,
        widgets,
        newWidgets,
        canvasService.canvasEndIndicatorX$?.getValue()
          ? newDashboardStates.map((state) => {
              const currentCanvasWidth = dashboardStates.find((state) => {
                if (state.layoutStateType === currentLayout)
                  return {
                    canvasWidthValue: state.canvasWidthValue,
                    canvasWidthLabel: state.canvasWidthLabel,
                  };
              });
              if (state.layoutStateType === currentLayout)
                return { ...state, ...currentCanvasWidth };
            })
          : newDashboardStates,
        canvasHeightTablet,
        canvasHeightMobile
      ),
    });

    closeSettings('apply');
  };

  const config = [
    {
      name: 'split',
      label: 'dashboard-editor.split-dashboard-layout',
      Component: SplitLayoutSelect,
      isHidden: false,
      props: {
        widgets,
        setLeaveOpen,
        splitLayout,
        setSplitLayout,
      },
    },
    {
      name: 'resolution',
      label: 'dashboard-editor.resolution',
      Component: CanvasWidthSelect,
      isHidden: false,
      props: { canvasWidth, setCanvasWidth, currentLayout },
    },
    {
      name: 'showGrid',
      label: 'dashboard-editor.show-hide-grid',
      Component: ShowGridSelect,
      isHidden: false,
      props: { showGrid, setShowGrid },
    },
    {
      name: 'refreshRate',
      label: 'dashboard-editor.refresh-rate',
      Component: RefreshRateSelect,
      isHidden: !isRefreshRateVisible,
      props: { refreshRate, setRefreshRate },
    },
  ];

  const disableApply = useMemo(() => {
    return (
      isEqual(splitLayout, initialSettings.splitLayout) &&
      isEqual(showGrid, initialSettings.showGrid) &&
      isEqual(refreshRate, initialSettings.refreshRate) &&
      isEqual(canvasWidth, initialSettings.canvasWidth)
    );
  }, [splitLayout, showGrid, refreshRate, canvasWidth]);

  return (
    <ConfigurationPopover
      config={config}
      open={openSettingsPopover}
      close={closeSettings}
      onSubmit={submit}
      disableApply={disableApply}
      showClearAll={false}
      anchorEl={anchorEl}
      leaveOpen={leaveOpen}
    />
  );
}

export default SettingsPopover;
