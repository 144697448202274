import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PreviewHeaderProps } from './PeviewHeader.interface';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    borderTopLeftRadius: theme.app.canvasWidgetBorderRadius,
    borderTopRightRadius: theme.app.canvasWidgetBorderRadius,
    height: theme.app.widgetActionBarHeight,
    background: 'var(--widgetsBorderHeader)',
    alignItems: 'center',
    paddingLeft: 23,
    userSelect: 'none',
  },
  title: {
    fontSize: 14,
    color: 'var(--widgetsHeaderFont)',
    fontWeight: 800,
    opacity: 0.8,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

function PreviewHeader(props: PreviewHeaderProps) {
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      {/* {(!props.customization || !props.customization.hideWidgetName) && ( */}
      <div className={classes.title} title={props.name}>
        {props.name}
      </div>
      {/* )} */}
    </div>
  );
}

export default PreviewHeader;
