import React from 'react';
import { UserData } from '../usersTable.interface';
import styles from './UserName.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

function UserName(props: UserData) {
  const { title, firstName, lastName } = props;
  const languageId = useSelector(state => state.config.languageId);
  const titleText= title === 'NONE' ? '' : i18nService.translate(`enum.${title}`, languageId);

  return (
    <div className={styles.name}>{`${titleText} ${firstName} ${lastName}`}</div>
  );
}

export default UserName;
