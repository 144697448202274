import React, { useState, useRef, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import buttonStyles from './buttonStyles.scss';
import disabledButtonStyles from './disabledButtonStyles.scss';
import toolbarStyles from './toolbarStyles.scss';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  editor: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.app.grey}`,
    cursor: 'text',
    padding: 16,
    borderRadius: 5,
    marginBottom: 2,
    background: theme.app.white1,
    width: '100%',
    height: 213,
    '& .DraftEditor-root .DraftEditor-editorContainer': {
      height: 150,
      overflow: 'auto',
    },
  },
  disabled: {
    opacity: 0.5,
  },
}));

function RichTextBox(props) {
  const { value = '', onChange, maxLength, disabled } = props;
  const { contentBlocks, entityMap } = convertFromHTML(value);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const editor = useRef(null);

  useEffect(() => {
    const htmlText = stateToHTML(editorState.getCurrentContent());
    if (htmlText.replace(/\r?\n|\r/g, '') !== value) {
      const { contentBlocks, entityMap } = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [value]);

  const classes = useStyles(props);

  const staticToolbarPlugin = useMemo(
    () =>
      createToolbarPlugin({
        theme: { buttonStyles: disabled ? disabledButtonStyles : buttonStyles, toolbarStyles },
      }),
    []
  );
  const { Toolbar } = staticToolbarPlugin;

  const handleChange = (newEditorState) => {
    if (maxLength && newEditorState.getCurrentContent().getPlainText().length > maxLength) {
      newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(editorState.getCurrentContent().getPlainText()),
          'delete-character'
        )
      );
    }
    const newHtmlText = stateToHTML(newEditorState.getCurrentContent());
    const htmlText = stateToHTML(editorState.getCurrentContent());

    setEditorState(newEditorState);

    if (newHtmlText !== htmlText) {
      onChange && onChange(newHtmlText.replace(/\r?\n|\r/g, ''));
    }
  };

  const focus = () => {
    editor.current && editor.current.focus();
  };

  return (
    <div className={classNames(classes.editor, disabled && classes.disabled)} onClick={focus}>
      <Editor
        editorState={editorState}
        onChange={handleChange}
        plugins={[staticToolbarPlugin]}
        readOnly={disabled}
        ref={editor}
      />
      <Toolbar>
        {(externalProps) => {
          const buttonsProps = disabled
            ? {
                getEditorState: externalProps.getEditorState,
                theme: externalProps.theme,
                setEditorState: () => {},
              }
            : externalProps;
          return (
            <>
              <BoldButton {...buttonsProps} />
              <ItalicButton {...buttonsProps} />
              <UnderlineButton {...buttonsProps} />
              <UnorderedListButton {...buttonsProps} />
              <OrderedListButton {...buttonsProps} />
            </>
          );
        }}
      </Toolbar>
    </div>
  );
}

export default RichTextBox;
