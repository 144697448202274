import React, { useCallback, useRef, useEffect } from 'react';
import { canvasService } from '@core/canvas/CanvasService';
import { useSelector } from '@redux/useSelector';
import styles from './ThumbnailWidgetGroupWrapper.scss';
import { ThumbnailWidgetGroupWrapperProps } from './ThumbnailWidgetGroupWrapper.interface';
import { dispatch } from '@src/redux/store';
import { resetClipboardWidget } from '@src/redux/dashboardGallery';
import ThumbnailWidgetGroup from '../ThumbnailWidgetGroup';

function ThumbnailWidgetGroupWrapper(props: ThumbnailWidgetGroupWrapperProps) {
  const { widgetType, theme, widgetGroup, isRegularWidget } = props;
  const clipboardWidget = useSelector((store) => store.dashboardGallery.clipboardWidget);
  const ref = useRef<HTMLDivElement>(null);
  const isUserFiltersWidget = widgetType === 'userFilters';

  useEffect(() => {
    //workaround to remove widgets that saved with imageUrl
    if (clipboardWidget?.type === 'image' && clipboardWidget?.customization?.imageUrl) {
      dispatch(resetClipboardWidget());
    }
  }, []);

  const onClick = useCallback(() => {
    if (isUserFiltersWidget) {
      canvasService.addUserFiltersWidget();
    }
  }, [widgetType]);

  return (
    <div className={styles.wrapper} onClick={onClick} ref={ref}>
      <ThumbnailWidgetGroup
        theme={theme}
        widgetGroup={widgetGroup}
        isRegularWidget={isRegularWidget}
      />
    </div>
  );
}

export default ThumbnailWidgetGroupWrapper;
