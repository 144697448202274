import React, { useEffect, useMemo, useState } from 'react';
import Table from '@components/Table';
import styles from './EventsTable.scss';
import TextField from '@components/Table/TableFields/TextField';
import { httpService } from '@core/http/HttpService';
import DateField from '@components/Table/TableFields/DateField';
import TextLowercase from '@components/Table/TableFields/TextLowercase';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { EventData, EventsDataResponse } from '../EventsTab.interface';
import EventStatusText from '@pages/EventsManagementPage/EditTemplate/EventStatusText/EventStatusText';
import { ADMIN_GROUP } from '@core/utils';
import { getFlagStatus } from '@core/ffAndPermissions';
import { useSelector } from '@redux/useSelector';

function EventsTable(props) {
  const { searchText, setResolts, organizationPage } = props;

  const showEventsTab = useMemo(() => getFlagStatus('event-management.events'), []);
  const roles = useSelector((state) => state.config.roles);
  const canEditTemplate =
    roles.includes('Machine Builder Admin') || roles.includes('Machine Builder Events Manager');

  const [events, setEvents] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [order, setOrder] = useState(null);
  const [filters, setFilters] = useState(null);
  const defaultOrdering = { label: 'status', orderSign: '' };

  useEffect(() => {
    getEvents(true, defaultOrdering);
  }, [searchText]);

  const colunmsCfg = useMemo(
    () => [
      {
        Component: TextField,
        field: 'name',
        label: 'event-templates-tab.table.name',
        isSortable: true,
        style: { width: '14.2%' },
      },
      {
        Component: EventStatusText,
        field: 'status',
        label: 'event-templates-tab.table.status',
        isSortable: true,
        style: { width: '14.2%' },
        eventsTable: true,
      },
      {
        Component: DateField,
        field: 'createdDate',
        label: 'event-templates-tab.table.create-date',
        isSortable: true,
        style: { width: '14.2%' },
      },
      {
        Component: DateField,
        field: 'endDate',
        label: 'event-templates-tab.table.create-date.table.end-date',
        isSortable: true,
        style: { width: '14.2%' },
      },
      {
        Component: TextLowercase,
        field: 'trigger',
        label: 'event-templates-tab.table.create-date.table.trigger-type',
        isSortable: true,
        style: { width: '14.2%' },
      },
      {
        Component: TextLowercase,
        field: 'action',
        label: 'event-templates-tab.table.create-date.table.action-type',
        isSortable: true,
        style: { width: '14.2%' },
      },
      {
        Component: TextField,
        field: 'recipients',
        label: 'event-templates-tab.table.create-date.table.recipients',
        style: { width: '14.2%' },
      },
    ],
    []
  );

  const showEventDetails = (rowData, rowIdx) => {
    modalService.openModal('eventDetailsModal', {
      rowData,
      rowIdx,
      organizationPage,
      refresh: () => getEvents(true, order),
    });
  };

  const getEvents = async (firstInit, order) => {
    try {
      setDisableLoad(true);
      const res: EventsDataResponse = await httpService.api({
        type: 'getEventsData',
        query: {
          search: !!searchText ? searchText : null,
          ps: 50,
          p: firstInit ? 1 : nextPage,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      });

      if (res) {
        !!searchText && setResolts(res.allCount, res.count);
        const data = res.results.map((item) => {
          const recipients = `${item?.sendToAdminGroup ? ADMIN_GROUP : ''}
          ${item?.templateInfo?.notificationGroups.length > 0 && item?.sendToAdminGroup ? ', ' : ''}
          ${item?.templateInfo?.notificationGroups.map((group) => group.name).join(', ')}`;
          return { ...item, ...item.templateInfo, recipients, eventId: item.id };
        });

        res.next && setDisableLoad(false);
        if (firstInit) {
          setEvents(data);
          setNextPage(2);
        } else {
          setEvents((prev) => [...prev, ...data]);
          setNextPage(nextPage + 1);
        }
      }
    } catch (e) {}
  };

  const getTableData = (firstInit, order, filters) => {
    order && setOrder(order);
    setFilters(filters);
    getEvents(firstInit, order);
  };

  return (
    <div className={styles.eventsTableWrapper}>
      <Table
        data={events}
        getData={getTableData}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
        onRowClicked={showEventDetails}
        showEmptyState={showEventsTab && events.length == 0}
        emptyStateMessage={
          canEditTemplate
            ? 'event-tab.table.empty-state-no-events-long'
            : 'event-tab.table.empty-state-no-events-short'
        }
      />
    </div>
  );
}

export default EventsTable;
