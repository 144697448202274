import React from 'react';
import { useSelector } from '@redux/useSelector';
import styles from './MultiLayoutButtons.scss';
import MultiIconSwitch from '@src/components/MultiIconSwitch';
import { dispatch, getState } from '@src/redux/store';
import {
  clearWidgetSelection,
  setCurrentLayout,
} from '@src/redux/dashboardEditor/dashboardEditor.actions';
import { canvasService } from '@core/canvas/CanvasService';
import { defaultWidthMap } from '../DashboardEditorLayout.utils';
import { CanvasWidget } from '@src/redux/redux.interface';

function MultiLayoutButtons({ setSelectedWidgets }) {
  const currentLayout = useSelector((state) => state.dashboardEditor.currentLayout);
  const dashboardStates = useSelector((state) => state.dashboardEditor.states);
  const widgets = useSelector((state) => state.dashboardEditor.canvasWidgets);
  const isSplitLayout = useSelector((state) => state.dashboardEditor.isSplitLayout);

  const dashboardWidths = new Map([
    [
      'DESKTOP',
      dashboardStates.find((state) => state.layoutStateType === 'DESKTOP').canvasWidthValue,
    ],
    [
      'TABLET',
      dashboardStates.length === 3
        ? dashboardStates.find((state) => state.layoutStateType === 'TABLET')?.canvasWidthValue
        : defaultWidthMap.get('TABLET'),
    ],
    [
      'MOBILE',
      dashboardStates.length === 3
        ? dashboardStates.find((state) => state.layoutStateType === 'MOBILE')?.canvasWidthValue
        : defaultWidthMap.get('MOBILE'),
    ],
  ]);

  const setIsActive = (type: string) => {
    return type === currentLayout;
  };

  const updateCurrentLayout = (
    layoutType: string,
    canvasWidth: number,
    widgets: CanvasWidget[]
  ) => {
    const dashboardStates = getState().dashboardEditor.states;

    const canvasHeight =
      dashboardStates.find((state) => state.layoutStateType === layoutType).canvasHeight ||
      dashboardStates.find((state) => state.layoutStateType === 'DESKTOP').canvasHeight;

    dispatch(setCurrentLayout(layoutType));

    canvasService.switchCanvasWidth({
      label: `${canvasWidth}px`,
      value: canvasWidth,
    });

    canvasService.setCanvasWidgetPositions(
      widgets.map((w) => {
        return { ...w, isSelected: false };
      }),
      layoutType
    );

    dispatch(clearWidgetSelection());
    setSelectedWidgets([]);

    canvasService.setCanvasHeight(canvasHeight, layoutType);

    if (canvasService.canvasEndIndicatorX$?.getValue()) {
      canvasService.canvasEndIndicatorX$.next(null);
    }
  };

  const multiIconConfig = [
    {
      type: 'desktop',
      tooltipText: 'dashboard-editor.desktop-layout',
      isActive: setIsActive('DESKTOP'),
      onClick: () => {
        updateCurrentLayout('DESKTOP', dashboardWidths.get('DESKTOP'), widgets);
      },
      isDisabled: false,
    },
    {
      type: 'tablet',
      tooltipText: 'dashboard-editor.tablet-layout',
      isActive: setIsActive('TABLET'),
      onClick: isSplitLayout
        ? () => {
            updateCurrentLayout('TABLET', dashboardWidths.get('TABLET'), widgets);
          }
        : null,
      isDisabled: !isSplitLayout,
    },
    {
      type: 'mobile',
      tooltipText: 'dashboard-editor.mobile-layout',
      isActive: setIsActive('MOBILE'),
      onClick: isSplitLayout
        ? () => {
            updateCurrentLayout('MOBILE', dashboardWidths.get('MOBILE'), widgets);
          }
        : null,
      isDisabled: !isSplitLayout,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <MultiIconSwitch
        config={multiIconConfig}
        txtColor={'var(--systemEnableEditorIcons)'}
        bgColor={'var(--systemHeaderBackground)'}
        outlineColor={'var(--systemEnableEditorIcons)'}
      />
    </div>
  );
}

export default MultiLayoutButtons;
