import React, { useCallback, useState } from 'react';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import styles from './SchedulePickerModal.scss';
import Trigger from '@pages/EventsManagementPage/EditTemplate/Trigger/Trigger';
import { eventTemplateTriggerValidation } from '@pages/EventsManagementPage/EditTemplate/EditTemplate.utils';
import { httpService } from '@core/http/HttpService';

function SchedulePickerModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { templateInfo, updateTemplate },
  } = props;
  const [templateDetails, setTemplateDetails] = useState(templateInfo);

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const onConfirm = async () => {
    const isValid = eventTemplateTriggerValidation(templateDetails);

    if (isValid) {
      try {
        const data = {
          time: templateDetails.time,
          daysOfWeek: templateDetails.daysOfWeek,
          dayOfMonth: templateDetails.dayOfMonth,
          monthOfYear: templateDetails.monthOfYear,
          dayOfYear: templateDetails.dayOfYear,
        };

        await httpService.api({
          type: 'updateEventsScheduler',
          urlParams: { templateId: templateInfo.eventId },
          data,
        });
        dismiss(data);
      } catch (e) {}
    }
  };

  const updateData = (val, field) => {
    const temp = { ...templateDetails, [field]: val };
    if (field === 'timeFrame') {
      return;
    }

    setTemplateDetails((prev) => ({ ...prev, ...temp }));
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex' }}>
        <I18n
          className={styles.modalHeader}
          element="div"
          title={'event-templates-tab.detail-popup.edit-scheduler'}>
          event-templates-tab.detail-popup.edit-scheduler
        </I18n>
        <div style={{ display: 'flex' }} onClick={cancel}>
          <Icon className={styles.exitButton} type="close" />
        </div>
      </div>

      <div className={styles.modalContent}>
        <div className={styles.mainContainer}>
          <Trigger
            showEditOption={false}
            templateData={templateDetails}
            setTemplateData={updateData}></Trigger>
        </div>

        <div className={styles.modalButtons}>
          <Button disabled={false} onClick={cancel} mode={['cancel', 'bigFont']}>
            <I18n>general.cancel</I18n>
          </Button>

          <Button onClick={onConfirm} styles={{ marginLeft: 13 }} mode="bigFont">
            <I18n>general.confirm</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SchedulePickerModal;
