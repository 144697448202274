import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import { initialState } from '@src/redux/dashboardEditor/dashboardEditor.reducer';
import { localStateToServer } from '@core/canvas/dashboard.utils';
import { getState } from '@src/redux/store';
import history from '@core/history';
import { dashboardService } from '@core/canvas/DashboardService';
import { isEqual } from 'lodash';

export const saveCreateDashboard = (
  values,
  organizationSelected,
  assetTypeSelected,
  assetSelected,
  geographySelected,
  filtersToDashboard,
  dateFilter,
  dismiss
) => {
  const dashboardEditor = initialState();
  const newDashboard = {
    name: values.name,
    organizationsFilter: organizationSelected.map((x) => x.id),
    assetTypesFilter: assetTypeSelected.map((x) => x.id),
    assetFilter: assetSelected.id ? [assetSelected.id] : [],
    geoFilter: geographySelected ? geographySelected : [],
    filterWidgetVisible: filtersToDashboard,
    states: localStateToServer(dashboardEditor),
  };
  Object.assign(newDashboard, dateFilter);

  httpService
    .api({
      type: 'createDashboard',
      data: newDashboard,
    })
    .then((newDashboard: any) => {
      const dashboardCount = getState().dashboardGallery.dashboards.length;
      dashboardService.addNewDashboard(newDashboard);
      if (!dashboardCount) dashboardService.setDefaultDashboard(newDashboard);
      history.push(`/main/edit-dashboard/${newDashboard.id}`);
      dismiss(false);
    });
};

export const resetFiltersClicked = (
  setOrganizationSelected,
  setAssetTypeSelected,
  setAssetSelected,
  setDateFilter,
  setGeographySelected,
  setScopeChange,
  initialScopeState
) => {
  modalService
    .openConfirm({
      text: i18nService.translate('create-dashboard-modal.reset-confirm-text'),
      iconType: 'attention_image',
    })
    .then((confirm) => {
      if (confirm) {
        setOrganizationSelected(initialScopeState.organization);
        setAssetTypeSelected(initialScopeState.assetType);
        setAssetSelected(initialScopeState.assets);
        setDateFilter(initialScopeState.dateFilter);
        setGeographySelected(initialScopeState.geography);

        setScopeChange(false);
      }
    });
};

export const onFilterChange = (
  source,
  filters,
  setOrganizationSelected,
  setAssetTypeSelected,
  setAssetSelected,
  dateRangeSelectedChange,
  setGeographySelected,
  setScopeChange,
  initialScopeState
) => {
  switch (source) {
    case 'organization':
      setOrganizationSelected(filters);
      break;
    case 'assetType':
      setAssetTypeSelected(filters);
      break;
    case 'dateFilter':
      dateRangeSelectedChange(filters);
      break;
    case 'geography':
      setGeographySelected(filters);
      break;
    case 'asset':
      setAssetSelected(filters);
      break;
  }

  if (isEqual(initialScopeState[source], filters)) {
    setScopeChange(false);
    return;
  }

  setScopeChange(true);
};
