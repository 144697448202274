import React, { useState } from 'react';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import styles from './CustomizationRow.scss';
import { CustomizationRowProps } from './CustomizationRow.interface';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { modalService } from '@core/modals/ModalService';
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';

function CustomizationRow(props: CustomizationRowProps) {
  const {
    column,
    columnCfg,
    columnChanged,
    isBoolean,
    conditions,
    deleteCondition,
    index,
    error,
    icons,
  } = props;
  const [editedColumn, setEditedColumn] = useState(column);

  const handleChange = (key: string, value) => {
    setEditedColumn({ ...editedColumn, [key]: value });
  };

  const updateColumn = (value, key: string) => {
    const tempEdited = { ...editedColumn, [key]: value };
    setEditedColumn(tempEdited);
    columnChanged(tempEdited, index);
  };

  return (
    <div className={styles.wrapper}>
      <div style={columnCfg[0].style}>
        {isBoolean ? (
          <div>{upperFirst(editedColumn.boolValue.toString())}</div>
        ) : (
          <InputBase
            title={`${
              editedColumn.fromValue || editedColumn.fromValue === 0 ? editedColumn.fromValue : ''
            }`}
            type={'number'}
            id={styles.inputStyle}
            className={classNames(styles.containerInput, error && styles.error)}
            value={
              editedColumn.fromValue || editedColumn.fromValue === 0 ? editedColumn.fromValue : ''
            }
            onBlur={() => columnChanged(editedColumn, index, 'fromValue')}
            onChange={(e) =>
              handleChange('fromValue', e.target.value === '' ? null : +e.target.value)
            }
          />
        )}
      </div>
      {!isBoolean && (
        <div style={columnCfg[1].style}>
          <InputBase
            type={'number'}
            title={`${
              editedColumn.toValue || editedColumn.toValue === 0 ? editedColumn.toValue : ''
            }`}
            className={classNames(styles.containerInput, error && styles.error)}
            id={styles.inputStyle}
            value={editedColumn.toValue || editedColumn.toValue === 0 ? editedColumn.toValue : ''}
            onBlur={() => columnChanged(editedColumn, index, 'toValue')}
            onChange={(e) =>
              handleChange('toValue', e.target.value === '' ? null : +e.target.value)
            }
          />
        </div>
      )}
      <div style={columnCfg[isBoolean ? 1 : 2].style}>
        <Select
          styles={{ container: { width: '80%' } }}
          options={icons}
          selectType={'onlyIcons'}
          value={editedColumn.iconId && icons.find((opt) => opt.id === editedColumn.iconId)}
          getOptionLabel={(i) => i.url}
          getOptionValue={(i) => i.id}
          onChange={(option) => updateColumn(option.id, 'iconId')}
        />
      </div>
      <div style={columnCfg[isBoolean ? 2 : 3].style}>
        <ColorInput
          value={editedColumn.backgroundColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => updateColumn(value, 'backgroundColor')}
        />
      </div>
      <div style={columnCfg[isBoolean ? 3 : 4].style}>
        <InputBase
          title={`${editedColumn.remark || ''}`}
          className={classNames(styles.containerInput)}
          id={styles.inputStyle}
          value={editedColumn.remark || ''}
          onBlur={() => columnChanged(editedColumn, index, 'remark')}
          onChange={(e) => handleChange('remark', e.target.value)}
        />
      </div>
      <div style={columnCfg[isBoolean ? 4 : 5].style}>
        {!isBoolean && conditions.length > 1 && (
          <Icon
            type={'removeImageRow'}
            className={'pointer'}
            onClick={deleteCondition}
            tooltipText={'general.delete'}
          />
        )}
      </div>
    </div>
  );
}

export default CustomizationRow;
