import React from 'react';

// Comonents
import I18n from '@components/I18n';
import Button from '@components/Button';

// Interfaces | Services
import { modalService } from '@core/modals/ModalService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { httpService } from '@core/http/HttpService';

const AssignButton = (props) => {
  const { styles = {}, reloadData } = props;

  const onClick = async () => {
    const confirm = await modalService.openModal('assignSubscriptionsToAssets', {
      showCloseBtn: true,
      subscriptionInfo: {
        name: props.licensePlan,
        description: props.description,
        catalogNum: props.catalogNumber,
        licenseId: props.licenseId,
        availableLicenses: props.quantityUnassigned,
      },
      reloadData,
    });
  };

  return (
    <Button onClick={onClick} styles={{ minWidth: 92, width: 92, padding: '0 12px', ...styles }}>
      <I18n>{`assets-tab.assign`}</I18n>
    </Button>
  );
};

export default AssignButton;
