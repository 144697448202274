class CreateARProjectService {
  private _dismiss: Function;

  setModalDismiss(dismiss: (val) => void) {
    this._dismiss = dismiss;
  }

  dismiss(val) {
    this._dismiss && this._dismiss(val);
  }
}

export const createARProjectService = new CreateARProjectService();
