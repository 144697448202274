import React, { useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import MuiExpandMoreIcon from '@components/MuiExpandMoreIcon';

import I18n from '@components/I18n';
import { canvasService } from '@core/canvas/CanvasService';
import { useSelector } from '@redux/useSelector';
import FiltersWidgetFilter from './FiltersWidgetFilter';
import FiltersWidgetAddFilterButton from './FiltersWidgetAddFilterButton';
import styles from './FiltersWidget.scss';
import { getPermissionStatus } from '@core/ffAndPermissions';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  filters: {
    boxShadow: theme.app.boxShadowGrey,
    backgroundColor: 'var(--systemScreenBackground)',
    borderRadius: 'var(--canvasWidgetBorderRadius)',
    border: 'solid 1px var(--widgetsFont)',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
  },
}));

const filtersOrder = {
  DATE: 1,
  GEO: 2,
  ASSET_TYPES: 3,
  ORGANIZATIONS: 4,
  ASSET: 5,
};

function FiltersWidget(props) {
  const { currentLayout } = props;
  const filters = useSelector((state) => state.dashboardEditor.filters);
  const showPlusButton = useMemo(() => filters.some((f) => !f.visible), [filters]);
  const [filtersExpanded, setFiltersExpanded] = useState(true);

  const classes = useStyles(props);

  const filtersToRender = useMemo(() => {
    const visibleFilters = filters.filter((f) => {
      if (f.filterType === 'ASSET_TYPES') {
        return f.visible && getPermissionStatus('DEVICE', 'VIEW');
      } else if (f.filterType === 'ASSET') {
        return f.visible && getPermissionStatus('DEVICE', 'VIEW');
      }
      return f.visible;
    });

    visibleFilters.sort((a, b) => filtersOrder[a.filterType] - filtersOrder[b.filterType]);
    return visibleFilters;
  }, [filters]);

  const removeWidget = useCallback(() => {
    canvasService.removeUserFiltersWidget();
  }, []);

  const handleFiltersChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setFiltersExpanded(isExpanded ? !!panel : false);
    };

  return (
    <div className={styles.wrapper} onMouseDown={(e) => e.stopPropagation()}>
      <Accordion
        id={'filtersWidgetAccordion'}
        square={true}
        className={classes.filters}
        onChange={handleFiltersChanged('filters')}
        expanded={filtersExpanded}>
        <AccordionSummary
          id={'filtersWidgetAccordionSummary'}
          expandIcon={<MuiExpandMoreIcon id={'filtersWidgetExpandIcon'} />}>
          <I18n className={styles.header}>filters-widget.title</I18n>
          <div className={styles.headerRight} id={'filtersWidget'}>
            <IconButton onClick={removeWidget}>
              <Delete color="disabled" />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails id={'filtersWidgetAccordionDetails'}>
          <div
            className={styles.filtersContainer}
            style={{
              height: 'fit-content',
              display: currentLayout === 'DESKTOP' ? 'flex' : 'block',
              paddingLeft: currentLayout === 'DESKTOP' ? '29px' : 0,
            }}>
            {filtersToRender.map((filt) => (
              <div
                className={
                  currentLayout === 'DESKTOP'
                    ? styles.filterSlotHorizontal
                    : styles.filterSlotVertical
                }
                key={filt.filterType}>
                <FiltersWidgetFilter filter={filt} />
              </div>
            ))}
            {showPlusButton && (
              <div className={classNames(styles.filterSlot, styles.plusButton)}>
                <FiltersWidgetAddFilterButton />
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default React.memo(FiltersWidget);
