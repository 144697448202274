import React, { useState, useEffect, useMemo } from 'react';
import MultiSelect from '@components/MultiSelect';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import { AlarmInfosResponse } from '../StepOne.interface';
import { alarmInfosMap } from '@pages/CreateWidgetPage/widgets.utils';
import styles from '../StepOne.scss';
import { useSelector } from '@src/redux/useSelector';

const prepareAssetOptions = (options) => {
  let data = options.map((option) => ({
    id: option.id,
    type: option.type,
    name: `create-widget-page.create-widget.alarm-infos.${alarmInfosMap[option.id]}`,
  }));
  return data;
};

const AlarmsInfoInput = ({
  alarmInfos,
  onSelectedAlarmsInfo,
  widgetType,
  isMaxDataTags = false,
  widgetData,
}) => {
  const [alarmInfoOptions, setAlarmInfoOptions] = useState([]);
  const languageId = useSelector((state) => state.config.languageId);
  const defaultAlarmInfos = useMemo(
    () =>
      [
        { id: 8, type: 'STRING' },
        { id: 7, type: 'STRING' },
        { id: 6, type: 'STRING' },
      ].map((option) => ({
        id: option.id,
        type: option.type,
        name: `create-widget-page.create-widget.alarm-infos.${alarmInfosMap[option.id]}`,
      })),
    []
  );
  useEffect(() => {
    const request = httpService.api<AlarmInfosResponse>({
      type: 'getAlarmsInfos',
    });
    request.then((res) => {
      setAlarmInfoOptions(prepareAssetOptions(res.alarmInfos));
    });

    return () => {
      request.cancel();
    };
  }, []);

  const onChange = (e) => {
    // Checking if one of 'defaultAlarmInfos' values has been removed
    const func = (acc, assetId) => (!e.map((asset) => asset.id).includes(assetId) ? ++acc : acc);
    let hasForbidden = getConfigValueFromWidgetSettings(widgetType, 'defaultAlarmInfos')?.reduce(
      func,
      0
    );
    const value =
      alarmInfos.find((option) => [6, 7, 8].includes(option.id)) &&
      ['RAW_DATA', 'LAST_VALUE'].includes(widgetData.scope)
        ? [...e, ...defaultAlarmInfos]
        : e;
    return onSelectedAlarmsInfo(value);
  };

  return (
    <div className={styles.dataField}>
      <div className={styles.fieldLabel}>
        <I18n>create-widget-page.create-widget.step-one.alarms-info</I18n>
        {alarmInfos.length > 0 &&
          ` (${alarmInfos.filter((option) => ![6, 7, 8].includes(option.id)).length})`}
        <div className={styles.fieldSubLabel}>
          <I18n>create-widget-page.create-widget.step-one.alarms-info-sub</I18n>
        </div>
      </div>
      <div className={styles.inputField}>
        <MultiSelect
          id="assetProps"
          isOptionDisabled={(option) => {
            return (
              getIsIncludeInSettings(widgetType, 'defaultAssetProperties', option.id) ||
              isMaxDataTags
            );
          }}
          isDisabled={getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'alarmInfos')}
          values={
            alarmInfos.length > 0
              ? prepareAssetOptions(alarmInfos.filter((option) => ![6, 7, 8].includes(option.id)))
              : []
          }
          options={alarmInfoOptions.filter((option) => ![6, 7, 8].includes(option.id))}
          getOptionLabel={(option) => i18nService.translate(option.name, languageId)}
          getOptionValue={(option) => option.id}
          tableConfig={(option) => ({
            type: option.type,
          })}
          onChange={onChange}
          optionType="assetProps"
        />
      </div>
    </div>
  );
};

export default AlarmsInfoInput;
