import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import { changeWidgetData } from '../Heatmap.utils';
import { TextField } from '@material-ui/core';
import { alignmentOptions } from '@core/canvas/widget.utils';

function Subtitle({ classes, subtitle, setWidgetData }) {
  return (
    <div className={classes.section}>
      <div className={classes.titleWrapper}>
        <I18n>create-widget-page.create-widget.step-four.subtitle</I18n>
      </div>
      <div className={classes.item}>
        <I18n className={classes.text}>create-widget-page.create-widget.step-four.text</I18n>
        <TextField
          value={subtitle.text}
          margin="dense"
          variant="outlined"
          className={classes.textField}
          inputProps={{ maxLength: 50, style: { color: 'var(--systemFont)' } }}
          onChange={(e) =>
            changeWidgetData({ ...subtitle, text: e.target.value }, 'subtitle', setWidgetData)
          }
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <I18n className={classes.text}>create-widget-page.create-widget.step-four.color</I18n>
        <div className={classes.container}>
          <ColorInput
            disabled={!subtitle?.text || subtitle?.text == ''}
            value={subtitle.textColor}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            customeMarginButton={0}
            colorChange={(value) =>
              changeWidgetData({ ...subtitle, textColor: value }, 'subtitle', setWidgetData)
            }
          />
        </div>
      </div>
      <div className={classes.item}>
        <I18n className={classes.text}>create-widget-page.create-widget.step-four.aligment</I18n>
        <Select
          styles={classes.selectFullWidthCustomStyle}
          disabled={!subtitle?.text || subtitle?.text == ''}
          options={alignmentOptions}
          value={alignmentOptions.find((option) => option.value === subtitle.alignment)}
          onChange={(option) =>
            changeWidgetData({ ...subtitle, alignment: option.value }, 'subtitle', setWidgetData)
          }
          unSelect={true}
        />
      </div>
    </div>
  );
}

export default Subtitle;
