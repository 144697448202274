import React, { useState } from 'react';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import styles from './RangeRow.scss';
import { RangeRowProps } from './RangeRow.interface';
import Icon from '@components/Icon';
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';

function RangeRow(props: RangeRowProps) {
  const {
    column,
    columnCfg,
    columnChanged,
    isBoolean,
    ranges,
    deleteRange,
    index,
    error,
    icons,
    maxRemarkLength,
  } = props;
  const [editedColumn, setEditedColumn] = useState(column);

  const handleChange = (key: string, value) => {
    setEditedColumn({ ...editedColumn, [key]: value });
  };

  const updateColumn = (value, key: string) => {
    const tempEdited = { ...editedColumn, [key]: value };
    setEditedColumn(tempEdited);
    columnChanged(tempEdited, index);
  };

  function getField(field: string, idx) {
    const style = columnCfg.find((col) => col.field === field)?.style || undefined;
    const fromValue =
      editedColumn.fromValue || editedColumn.fromValue === 0 ? editedColumn.fromValue : '';
    const toValue = editedColumn.toValue || editedColumn.toValue === 0 ? editedColumn.toValue : '';

    switch (field) {
      case 'fromValue':
        return (
          <div style={style} key={idx}>
            {isBoolean ? (
              <div>{upperFirst(editedColumn.boolValue.toString())}</div>
            ) : (
              <InputBase
                title={`${fromValue}`}
                type={'number'}
                id={styles.inputStyle}
                className={classNames(styles.containerInput, error && styles.error)}
                value={fromValue}
                onBlur={() => columnChanged(editedColumn, index, 'fromValue')}
                onChange={(e) =>
                  handleChange('fromValue', e.target.value === '' ? null : +e.target.value)
                }
              />
            )}
          </div>
        );
      case 'toValue': {
        if (!isBoolean) {
          return (
            <div style={style} key={idx}>
              <InputBase
                type={'number'}
                title={`${toValue}`}
                className={classNames(styles.containerInput, error && styles.error)}
                id={styles.inputStyle}
                value={toValue}
                onBlur={() => columnChanged(editedColumn, index, 'toValue')}
                onChange={(e) =>
                  handleChange('toValue', e.target.value === '' ? null : +e.target.value)
                }
              />
            </div>
          );
        }
        return <div key={idx}></div>;
      }
      case 'icon':
        return (
          <div style={style} key={idx}>
            <Select
              styles={{ container: { width: '80%' } }}
              options={icons}
              selectType={'onlyIcons'}
              value={editedColumn.iconId && icons.find((opt) => opt.id === editedColumn.iconId)}
              getOptionLabel={(i) => i.url}
              getOptionValue={(i) => i.id}
              onChange={(option) => updateColumn(option.id, 'iconId')}
            />
          </div>
        );
      case 'backgroundColor':
        return (
          <div style={style} key={idx}>
            <ColorInput
              value={editedColumn.backgroundColor}
              popOverPosition={{ left: 1 }}
              isParentRelative={false}
              customeMarginButton={0}
              colorChange={(value) => updateColumn(value, 'backgroundColor')}
            />
          </div>
        );
      case 'textColor':
        return (
          <div style={style} key={idx}>
            <ColorInput
              value={editedColumn.textColor}
              popOverPosition={{ left: 1 }}
              isParentRelative={false}
              customeMarginButton={0}
              colorChange={(value) => updateColumn(value, 'textColor')}
            />
          </div>
        );
      case 'remark':
        return (
          <div style={style} key={idx}>
            <InputBase
              title={`${editedColumn.remark}`}
              className={classNames(styles.containerInput)}
              id={styles.inputStyle}
              value={editedColumn.remark || ''}
              onBlur={() => columnChanged(editedColumn, index, 'remark')}
              onChange={(e) => handleChange('remark', e.target.value)}
              inputProps={{
                maxLength: maxRemarkLength,
              }}
            />
          </div>
        );
      case 'deleteButton':
        return (
          <div style={style} key={idx}>
            {!isBoolean && ranges.length > 1 && (
              <Icon
                type={'removeImageRow'}
                className={'pointer'}
                onClick={deleteRange}
                tooltipText={'general.delete'}
              />
            )}
          </div>
        );
      default:
        return <div key={idx}></div>;
    }
  }

  return (
    <div className={styles.wrapper}>{columnCfg.map((col, idx) => getField(col.field, idx))}</div>
  );
}

export default RangeRow;
