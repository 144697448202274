import React from 'react';
import Button from '@components/Button';
import I18n from '@components/I18n';

function UpdateTypeButton(props) {
  const { children, ...rest } = props;

  return (
    <Button {...rest} styles={{ width: 110 ,minWidth: 110}} mode={['outlined']}>
      <I18n>{children}</I18n>
    </Button>
  );
}

export default React.memo(UpdateTypeButton);
