import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './EventStatusText.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { eventsStatusColorMap } from '@core/eventsStatusColorMap';

function EventStatusText(props: any) {
  const { field, eventsTable } = props;
  const languageId = useSelector((state) => state.config.languageId);

  const isAcvtiveStaus = eventsTable && field === 'status' && props[field] === 'ACTIVE';

  const value = useMemo(
    () =>
      isAcvtiveStaus
        ? i18nService.translate('event-templates-tab.table.status.active', languageId)
        : i18nService.translate(`enum.${props[field] || 'NOT_AVALIABLE'}`, languageId),
    [field, languageId, eventsTable]
  );

  return (
    <div
      className={classNames(style.text, 'ellipsis-overflow')}
      style={{ color: eventsStatusColorMap[props[field]] }}
      title={value}>
      {value}
    </div>
  );
}

export default EventStatusText;
