import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: { fontSize: 13 },
});

function Tooltip(props) {
  const { children, text, placement } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const classes = useStyles(props);

  return (
    <MuiTooltip
      classes={{ tooltip: classes.tooltip }}
      title={i18nService.translate(text || '', languageId)}
      placement={placement}>
      {children}
    </MuiTooltip>
  );
}

export default Tooltip;
