import React from 'react';
import { makeStyles } from '@material-ui/core';
import DateRow from './DateRow';
import TimeRow from './TimeRow';
import I18n from '@components/I18n';

const useStyles = makeStyles(() => ({
  wrapper: { display: 'flex', flexDirection: 'column', padding: '22px 44px' },
  label: {
    fontSize: 14,
    fontWeight: 900,
    marginBottom: '8px',
  },
}));

const DateTime = (props) => {
  const { regionalSettingsData, onChangeData } = props;
  const { shortDate, date, longDate, timeFormat1, timeFormat2 } =
    regionalSettingsData?.dateTime?.content || {};
  const classes = useStyles(props);

  return regionalSettingsData?.dateTime?.content ? (
    <div className={classes.wrapper}>
      <DateRow onChangeData={onChangeData} keyName="shortDate" data={shortDate} />
      <DateRow onChangeData={onChangeData} keyName="date" data={date} />
      <DateRow onChangeData={onChangeData} keyName="longDate" data={longDate} />
      <TimeRow onChangeData={onChangeData} keyName="timeFormat1" data={timeFormat1} />
      <TimeRow onChangeData={onChangeData} keyName="timeFormat2" data={timeFormat2} />
    </div>
  ) : null;
};

export default DateTime;
