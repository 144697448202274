import Button from '@components/Button';
import I18n from '@components/I18n';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './VariableValuesPerAssetModal.scss';
import Icon from '@components/Icon';
import TextWithTitle from '@components/TextWithTitle';
import Table from '@components/Table';
import { dynamicSort } from '@core/utils';
import { getColunmsCfg, getVariablesList } from './VariableValuesPerAssetModal.utils';

function VariableValuesPerAssetModal(props) {
  const { args, dismiss } = props || {};
  const { rowData } = args || {};
  const { assetTypeId, assetTypeName, name, id, valueType, order, managementLevel } = rowData;
  const [variablesList, setVariablesList] = useState([]);
  const [editedVariableId, setEditedVariableId] = useState();
  const [editedAssetId, setEditedAssetId] = useState();

  const [_order, setOrder] = useState(null);
  const orderData = (order?: any) => {
    return variablesList.sort(dynamicSort(order.label, order.orderSign));
  };

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  useEffect(() => {
    getVariablesList(id, setVariablesList);
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.modalHeader}>
          <I18n>variables.system-property-by-asset-type</I18n>
          <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
        </div>
        <div className={styles.content}>
          <TextWithTitle
            data={[
              { title: 'general.variable', text: name },
              { title: 'general.asset-type', text: assetTypeName },
            ]}
          />
          <div className={styles.tableWrapper}>
            <Table
              hasPagination={false}
              data={variablesList}
              getData={(firstInit, order) => {
                order && setOrder(order);
                orderData(order);
              }}
              colunmsCfg={getColunmsCfg(
                'variableValuesPerAssetModal',
                id,
                valueType,
                managementLevel,
                setVariablesList,
                editedVariableId,
                setEditedVariableId,
                editedAssetId,
                setEditedAssetId,
                +assetTypeId,
                order
              )}
              className={styles.tableBody}
            />
          </div>
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button mode={['cancel', 'bigFont']} disabled={false} onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default VariableValuesPerAssetModal;
