import React, { useState } from 'react';
import classNames from 'classnames';

// Style
import styles from './EditLicensePlan.scss';

// 3rd party
import Icon from '@components/Icon';
import Select from '@components/Select';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';

function EditLicensePlan(props) {
  const {
    onCancelEditClicked,
    assetId,
    licences,
    selectedLicensePlan,
    currentLicenceId,
    licensingPlans,
    setRemoteAccessMode,
  } = props;
  const [selectedLicense, setSlectedLicensePlan] = useState(selectedLicensePlan);
  const onChange = (value) => {
    setSlectedLicensePlan(value);
  };

  const onSave = async () => {
    try {
      let modalText = 'asset-subscription.license-plan.modal-text';
      if (currentLicenceId) {
        const selectedDefaultData = licensingPlans?.find((l) => l.licenceId === selectedLicense.id);
        const currentLicenseData = licensingPlans?.find((l) => l.licenceId === currentLicenceId);

        if (
          selectedLicense.id !== 5 &&
          currentLicenseData.totalTagsPerMonth > selectedDefaultData.totalTagsPerMonth
        ) {
          modalText = 'asset-subscription.license-plan.not-supported-error';
        }
      }
      const isConfirmed = await modalService.openModal('confirm', {
        text: modalText,
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'asset-subscription.license-plan.modal-header',
        showCloseBtn: true,
      });
      if (isConfirmed) {
        const res: any = await httpService.api({
          type: 'saveSubscriptionDefaultLicenseAsset',
          urlParams: { assetId: assetId },
          data: { licenceId: selectedLicense.id },
        });
        if (res) {
          selectedLicense.name === 'Remote Access'
            ? setRemoteAccessMode(true)
            : setRemoteAccessMode(false);
          onCancelEditClicked(true, { defaultLicenceId: res.licenceId });
        }
      }
    } catch (e) {}
  };

  return (
    <div className={styles.editMode}>
      <Select
        className={styles.ownerSelect}
        label="edit-router-modal.router-owner"
        name="owner"
        defaultValue={selectedLicense}
        options={licences}
        hideLabel={true}
        getOptionLabel={(opt) => opt.name}
        getOptionValue={(opt) => opt.id}
        onChange={onChange}
      />
      <Icon
        type="save"
        disabled={selectedLicensePlan?.id === selectedLicense?.id}
        className={classNames('pointer', styles.icon)}
        onClick={onSave}
      />
      <Icon
        type="remove"
        className={classNames('pointer', styles.icon)}
        onClick={onCancelEditClicked}
      />
    </div>
  );
}

export default EditLicensePlan;
