import React, { useRef, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { CameraControls, useGLTF, Grid } from '@react-three/drei';
import * as THREE from 'three';
import { cssVarsService } from '@core/CssVarsService';
import { dispatch } from '@src/redux/store';
import { decrementBusyCounter } from '@src/redux/config';

const UpViewModelViewerContent = (props) => {
  const { modelPath, upVector, position, rotation, controlsRef, showBusyIndicator } = props;
  const { scene } = useGLTF(modelPath) as any;
  const [worldObjectPosition, setWorldObjectPosition] = useState([0, 0, 0]);
  const [modelLoaded, setModelLoaded] = useState(false);
  const modelRef = useRef<THREE.Group>(null);
  const obj = scene.clone();
  var groupBboundingSphere = null;

  useEffect(() => {
    !showBusyIndicator && dispatch(decrementBusyCounter());
    if (controlsRef.current) {
      const lookAt = [0, 0, 0];
      controlsRef.current.setPosition(...position);
      controlsRef.current.setLookAt(...position, ...lookAt, true);
      controlsRef.current.camera.up.set(...upVector);
      controlsRef.current.update();
      controlsRef.current.enabled = false;
    }
  }, []);

  useEffect(() => {
    if (obj) {
      const boundingBox = new THREE.Box3().setFromObject(obj);
      const boundingSphere = new THREE.Sphere();
      boundingBox.getBoundingSphere(boundingSphere);

      const size = new THREE.Vector3();
      const center = new THREE.Vector3();
      boundingBox.getSize(size);
      boundingBox.getCenter(center);

      obj.position.sub(center);
      const pivot = new THREE.Group();
      pivot.add(obj);
      pivot.rotation.set(rotation[0], rotation[1], rotation[2]);
      pivot.position.set(0, 0, 0);

      const groupBoundingBox = new THREE.Box3().setFromObject(pivot);
      pivot.position.y = pivot.position.y - groupBoundingBox.min.y;
      groupBboundingSphere = new THREE.Sphere();
      groupBoundingBox.getBoundingSphere(groupBboundingSphere);
      modelRef.current = pivot;

      const worldPosition = new THREE.Vector3();
      obj.getWorldPosition(worldPosition);

      if (JSON.stringify(worldObjectPosition) === JSON.stringify([0, 0, 0])) {
        setWorldObjectPosition([worldPosition.x, worldPosition.y, worldPosition.z]);
      }
      setModelLoaded(true);
    }
  }, [obj]);

  useEffect(() => {
    if (modelLoaded && controlsRef.current) {
      const camera = controlsRef.current.camera;
      const fov = (camera.fov * Math.PI) / 180;
      const distance = (groupBboundingSphere.radius / Math.sin(fov / 2)) * 1.4;
      const direction = new THREE.Vector3()
        .subVectors(camera.position, groupBboundingSphere.center)
        .normalize();
      const cameraPosition = direction.multiplyScalar(distance).add(groupBboundingSphere.center);
      controlsRef.current.setPosition(
        cameraPosition.x,
        modelRef.current.position.y * 1.5,
        cameraPosition.z
      );
      controlsRef.current.setTarget(
        modelRef.current.position.x,
        modelRef.current.position.y,
        modelRef.current.position.z
      );
      controlsRef.current.update();
    }
  }, [modelLoaded]);

  const gridConfig = {
    cellSize: 0.1,
    cellThickness: 0.5,
    cellColor: '#6f6f6f',
    sectionSize: 10,
    sectionThickness: 0.8,
    sectionColor: cssVarsService.vars.systemFontSelected,
    fadeDistance: 10000,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
    side: THREE.DoubleSide,
  };

  return (
    <>
      <CameraControls ref={controlsRef} makeDefault />
      <ambientLight intensity={1} />
      <directionalLight intensity={2} position={[5, 10, 7.5]} />
      {modelLoaded && <primitive object={modelRef.current} />}
      <Grid position={[0, 0, 0]} args={[10, 10]} {...gridConfig} />
    </>
  );
};

const UpViewModelViewer = (props) => {
  const { index, modelPath, upVector, position, rotation, showBusyIndicator } = props;
  const controlsRef = useRef(null);

  return (
    <>
      <Canvas key={index} camera={{ near: 0.1, far: 50000 }}>
        <UpViewModelViewerContent
          modelPath={modelPath}
          upVector={upVector}
          position={position}
          rotation={rotation}
          controlsRef={controlsRef}
          showBusyIndicator={showBusyIndicator}
        />
      </Canvas>
    </>
  );
};

export default UpViewModelViewer;
