import ImageByValueWidget from '@components/widgets/ImageByValueWidget';
import { stepThreeDefaultValidation } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';

export const imageByValue: WidgetMapItem = {
  id: 'image_by_value',
  name: 'image_by_value',
  component: ImageByValueWidget,
  minWidth: 120,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_Image.svg',
    thumbnail: 'imageByValue',
  },
  editModalSize: {},
  settings: {
    disabledScope: ['RAW_DATA'],
    disableCreateWidgetFlow: false,
    enableSaveAsDraft: true,
    languageKeys: (isArWidget) => {
      return [];
    },
    accordionDisable: ['sort'],
    dataChangesToHandle: ['metrics', 'filters', 'calculations'],
    stepOneHidden: [],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) =>
        (widgetData?.customization?.conditions[0].boolValue &&
          !widgetData?.customization?.conditions.find((c) => !c.imageId)) ||
        (!widgetData?.customization?.conditions[0].boolValue &&
          (!widgetData?.customization?.errors ||
            !Object.values(widgetData?.customization?.errors).find((err) => err)) &&
          widgetData?.customization?.conditions &&
          widgetData?.customization?.conditions[widgetData?.customization?.conditions.length - 1]
            .imageId),
    },
    extraMetricsText: {
      value: (scope) =>
        `create-widget-page.create-widget.step-three.image-by-value-empty-metrics-${
          scope === 'LAST_VALUE' ? 'last-value' : 'aggregated'
        }`,
      condition: () => true,
    },
    disableGrouping: {
      text: ({ eventTemplateId }) =>
        'create-widget-page.create-widget.step-three.image-by-value-grouping',
    },
    dragAndDropRules: {
      maxGrouping: (scope) => 0,
      maxMetrics: 1,
      metricsDataValidation: (scope, draggableItem) => {
        if (
          scope === 'LAST_VALUE' &&
          !['numberType', 'floatType', 'booleanType'].includes(draggableItem.type)
        ) {
          return 'nonNumericalAndBoolean';
        }
        return null;
      },
    },
    customizationServerChanges: (customization) => ({
      conditions: customization.conditions,
    }),
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => widgetData.scope === 'LAST_VALUE',
  },
};
