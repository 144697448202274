import { i18nService } from '@core/i18n/I18nService';

export function filterOption(option, rawInput) {
  const trimmed = rawInput.trim();
  const data = option.data;
  if (trimmed.length < 3) {
    // We should only start filtering options after typing at least 3 characters.
    return true;
  }
  return (
    data.name.toLowerCase().includes(trimmed.toLowerCase()) ||
    data.labels.some((l) => l.text.toLowerCase().includes(trimmed.toLowerCase()))
  );
}

export const tableConfig = (option) => ({
  [i18nService.translate('create-widget-page.create-widget.step-one.name')]: option.name,
  [i18nService.translate('create-widget-page.create-widget.step-one.labels')]: option.labels
    ? option.labels.map((label) => label.text).join(', ')
    : '',
});

export const getOptionLabel = (option) => option.name;

export const getOptionValue = (option) => option.id;
