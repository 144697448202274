import React, { useState } from 'react';
import classNames from 'classnames';

// Style
import styles from './EditTimer.scss';

// 3rd party
import 'moment-duration-format';
import { InputBase, InputAdornment } from '@material-ui/core';
import TimeField from 'react-simple-timefield';
import Icon from '@components/Icon';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';

function EditTimer(props) {
  const { onCancelEditClicked, duration, assetId, assetSubscription } = props;
  const {
    tagsCount,
    currentLicenceId,
    licensingPlans = [],
    defaultLicenceId,
  } = assetSubscription || {};
  const moment = require('moment');
  const [timer, setTimer] = useState(duration);

  const onChangeTimer = (event, value) => {
    setTimer(value);
  };

  const save = async () => {
    try {
      const secondsTimer = moment.duration(timer).asSeconds();
      const numOfSecondsinMonth = 31 * 24 * 60 * 60;
      const newTotalTagsPerMonth = Math.ceil((numOfSecondsinMonth / secondsTimer) * tagsCount);
      const requiredPlan = licensingPlans.find(
        (plan) => plan.totalTagsPerMonth >= newTotalTagsPerMonth
      );
      const defaultLicenceData = licensingPlans.find((plan) => plan.licenceId === defaultLicenceId);
      const currentLicenseData = licensingPlans.find((l) => l.licenceId === currentLicenceId);
      let modalText = null;

      if (defaultLicenceId === 5 || currentLicenceId === 5) {
        modalText = 'asset-subscription.tags-timer-interval.modal-text';
      } else {
        if (currentLicenceId && currentLicenseData?.totalTagsPerMonth < newTotalTagsPerMonth) {
          modalText = 'asset-subscription.tags-timer-interval.not-supported-error';
        } else if (
          requiredPlan &&
          defaultLicenceData?.totalTagsPerMonth < requiredPlan.totalTagsPerMonth
        ) {
          modalText = 'asset-subscription.tags-timer-interval.modal-text';
        }
      }

      const isConfirmed =
        !modalText ||
        (await modalService.openModal('confirm', {
          text: modalText,
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText: 'asset-subscription.tags-timer-interval.modal-header',
          showCloseBtn: true,
        }));
      if (isConfirmed) {
        const res: any = await httpService.api({
          type: 'saveSubscriptionAsset',
          data: { tagsTimer: secondsTimer },
          urlParams: { assetId },
        });
        if (
          requiredPlan &&
          defaultLicenceData?.totalTagsPerMonth < requiredPlan.totalTagsPerMonth
        ) {
          await httpService.api({
            type: 'saveSubscriptionDefaultLicenseAsset',
            urlParams: { assetId: assetId },
            data: { licenceId: requiredPlan.licenceId },
          });
        }
        if (res) {
          onCancelEditClicked(true);
        }
      }
    } catch (e) {}
  };

  return (
    <div className={styles.editMode}>
      <TimeField
        value={timer}
        onChange={onChangeTimer}
        input={
          <InputBase
            endAdornment={
              <InputAdornment position="end">
                <Icon
                  type="remove"
                  className={classNames('pointer', styles.discardIcon)}
                  onClick={onCancelEditClicked}
                />
              </InputAdornment>
            }
            id={styles.inlineInput}
          />
        }
        showSeconds
      />
      <Icon
        disabled={timer === duration}
        type="save"
        className={classNames('pointer', styles.icon)}
        onClick={save}
      />
    </div>
  );
}

export default EditTimer;
