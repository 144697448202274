import { tagFormatMap } from '@core/mapsAndDefinitions';

export const buildOptions = (option, type) => {
  const tagFormat = type === 'TAG' ? tagFormatMap[option.format] : tagFormatMap[option.type];
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
  };
};
