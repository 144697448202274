import React, { useContext, useRef } from 'react';
import classNames from 'classnames';

import styles from './TableHeader.scss';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import TableFilter from '../TableFilter';

const HeaderColumn = ({ colIndex, classes, col, setFilterData, onSort, orderedBy, filterData }) => {
  const anchorRef = useRef(null);

  const onFilterIconClicks = (e) => {
    e.stopPropagation();
    setFilterData({
      anchorRef,
      ...col,
      isOpen: true,
      selectedOptionsByField: filterData.selectedOptionsByField,
    });
  };

  const iconType =
    orderedBy.label == '' || orderedBy.label !== col.field
      ? 'sort'
      : orderedBy.label === col.field && orderedBy.orderSign === '-'
      ? 'downSort'
      : 'upSort';

  const filterColor =
    filterData?.selectedOptionsByField &&
    filterData?.selectedOptionsByField[col.field] &&
    filterData?.selectedOptionsByField[col.field].length &&
    filterData?.selectedOptionsByField[col.field].length !== col.filterOptions?.length
      ? '#879BB7'
      : '#D0D8E3';

  return (
    <div
      className={classNames(classes.headerCol, col.isSortable && 'pointer')}
      style={col.style}
      // onClick={col.isSortable && (() => onSort(col.field))}>
      // FIXING: Warning: Expected `onClick` listener to be a function, instead got `false`:
      onClick={col.isSortable ? () => onSort(col.field) : undefined}>
      <I18n className="ellipsis-overflow">{col.label}</I18n>
      {col.isSortable && <Icon type={iconType} className={styles.sort} />}
      {col.isFilterable && (
        <div ref={anchorRef}>
          <Icon type="tableFilter" color={filterColor} onClick={onFilterIconClicks} />
        </div>
      )}
    </div>
  );
};

export default HeaderColumn;
