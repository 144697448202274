import React from 'react';

// Components
import Icon from '@components/Icon';

// Services | Interfaces
import { modalService } from '@core/modals/ModalService';
import { RouterPLCDisconnectBTNProps } from './RouterPLCDisconnectBTN.interface';
import styles from './RouterPLCDisconnectBTN.scss';

// 3rd party
import IconButton from '@material-ui/core/IconButton';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';

function RouterPLCDisconnectBTN(props: RouterPLCDisconnectBTNProps) {
  const disconnectPlc = () => {
    modalService.openModal('disconnectPLCFromRouter', props).then((res) => {
      if (!res) return;
      // Refreshing the header and tabs tables
      props.onDataChanged();

      // Refreshing the parent routers table
      setTimeout(() => routersService.refreshRoutersTableData(), 100);
    });
  };

  return (
    <IconButton onClick={disconnectPlc} id={styles.disconnectButton}>
      <Icon
        type="disconnectPlc"
        tooltipText="remove-router-modal.disconnect-tooltip"
        color="var(--systemFont)"
      />
    </IconButton>
  );
}
export default RouterPLCDisconnectBTN;
