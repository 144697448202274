import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './AssetTypesTagsInfoModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import Table from '@components/Table';
import { TableColunm } from '@components/Table/table.interface';
import TextField from '@components/Table/TableFields/TextField';

const colunmsCfg: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'asset-type-tags.type',
    field: 'type',
    style: { width: '25%' },
  },
  {
    Component: TextField,
    label: 'asset-type-tags.name',
    field: 'name',
    style: { width: '25%' },
  },
  {
    Component: TextField,
    label: 'asset-type-tags.object-type',
    field: 'objectType',
    style: { width: '25%' },
  },
  {
    Component: TextField,
    label: 'asset-type-tags.object-name',
    field: 'objectName',
    style: { width: '25%' },
  },
];

function AssetTypesTagsInfoModal(props) {
  const { args, dismiss } = props;
  const {
    text,
    iconType,
    confirmText,
    cancelText,
    showCloseBtn,
    headerText,
    tagName,
    dashboards,
    events,
    arProjects,
    isTagType = false,
    ...rest
  } = args;
  const confirmBtn = useRef(null);
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  useEffect(() => {
    confirmBtn.current.focus();
  }, []);

  const data = useMemo(() => {
    let res = dashboards.reduce(
      (list, dashboard) => [
        ...list,
        ...dashboard.widgets.map((w) => ({
          type: i18nService.translate('asset-type-tags.dashboard'),
          name: `${dashboard.name}${
            dashboard.status == 'DRAFT'
              ? `-${i18nService.translate(`enum.${dashboard.status}`)}`
              : ''
          }`,
          objectType: i18nService.translate('asset-type-tags.widget'),
          objectName: w,
        })),
      ],
      []
    );

    res = events.reduce((list, event) => {
      return [
        ...list,
        ...(event.condition
          ? [
              {
                type: i18nService.translate('asset-type-tags.event'),
                name: event.name,
                objectType: i18nService.translate('asset-type-tags.trigger'),
                objectName: '',
              },
            ]
          : []),
        ...event.dataSources.map((w) => ({
          type: i18nService.translate('asset-type-tags.event'),
          name: event.name,
          objectType: i18nService.translate('asset-type-tags.data-source'),
          objectName: w,
        })),
      ];
    }, res);

    res = arProjects.reduce(
      (list, arProject) => [
        ...list,
        ...arProject.widgets.map((w) => ({
          type: i18nService.translate('asset-type-tags.arProject'),
          name: `${arProject.name}${
            arProject.status == 'DRAFT'
              ? `-${i18nService.translate(`enum.${arProject.status}`)}`
              : ''
          }`,
          objectType: i18nService.translate('asset-type-tags.widget'),
          objectName: w,
        })),
      ],
      res
    );

    return res;
  }, []);

  return (
    <div className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}>
      <div className={styles.modalHeader}>
        <I18n className={styles.modalHeader} element="div">
          {isTagType ? `asset-type-tags.where-tag-type-in-use` : `asset-type-tags.where-tag-in-use`}
        </I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n className={styles.tagNameTitle} tagName={tagName}>
          {isTagType ? `asset-type-tags.tag-type-name-title` : `asset-type-tags.tag-name-title`}
        </I18n>
        <div className={styles.tableWrapper}>
          <Table colunmsCfg={colunmsCfg} data={data} />
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={close} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </div>
  );
}

export default AssetTypesTagsInfoModal;
