import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { modalService } from '@core/modals/ModalService';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import ExportActions from './ExportActions';
import WidgetCustomizeInput from './WidgetCustomizeInput';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import styles from './StepFour.scss';
import { CustomizationMap } from './CustomizationMap';
import { StepFourProps } from './StepFour.interface';
import { validateWidgetName, setInitializeCustomization, setExportFormat } from './StepFour.utils';
import { useMemo } from 'react';
import { cssVarsService } from '@core/CssVarsService';
import { editorConfig } from '@core/canvas/editorConfig';
import { canvasService } from '@core/canvas/CanvasService';

function StepFour(props: StepFourProps) {
  const { widgetData, setWidgetData, previewData, isNameValid, defaultDecimalDigits } = props;
  const { name, type, customization, exportFormats, hideWidgetName, eventTemplateId } = widgetData;
  const enableExportCsv = getConfigValueFromWidgetSettings(type, 'enableExportCsv');
  const headerHeight = useMemo(() => {
    const cellSize = editorConfig.gridCellSizePx;
    return Math.floor(cssVarsService.vars.widgetActionBarHeight / cellSize);
  }, []);

  const WidgetTypeCustomization = CustomizationMap[type];

  useEffect(() => {
    setInitializeCustomization(customization, setWidgetData);
  }, [type]);

  const setWidgetName = useCallback(
    (e) => {
      validateWidgetName(e, setWidgetData);
    },
    [name]
  );

  const setHideWidgetName = useCallback(
    (e) => {
      const hideName = e.target.checked;
      const newState = {
        ...widgetData.state,
        h: hideName ? widgetData.state.h - headerHeight : widgetData.state.h + headerHeight,
        y: hideName ? widgetData.state.y + headerHeight : widgetData.state.y - headerHeight,
      };

      const widgetIntersectionResults =
        e.target.checked || !widgetData.id
          ? {
              isIntersecting: false,
              coords: newState,
            }
          : canvasService.getWidgetIntersectionResultForHeaderReAdded(widgetData.id, newState);

      if (widgetIntersectionResults.isIntersecting) {
        modalService.openModal('confirm', {
          text: 'widget-side-bar.step-four.display-header-failed.text',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          headerText: 'widget-side-bar.step-four.display-header-failed.title',
          showCloseBtn: true,
        });
      } else {
        setWidgetData((prevState) => ({
          ...prevState,
          hideWidgetName: hideName,
          state: widgetIntersectionResults.isIntersecting
            ? {
                ...widgetIntersectionResults.coords,
              }
            : { ...newState, y: widgetData.state.y },
        }));
      }
    },
    [hideWidgetName]
  );

  const onSelectedExportFormat = useCallback(
    (format) => {
      if (!hideWidgetName) {
        setExportFormat(exportFormats, format, setWidgetData);
        return;
      }

      if (!exportFormats.length) {
        // first export format that is being added. Otherwise, either adding another one, or removing the only one
        // In this case we need to check if the widget header is hidden, and if so, check if the widget height can be increased
        // If not, then show a message and don't check the format checkbox.

        const newState = {
          ...widgetData.state,
          h: widgetData.state.h + headerHeight,
          y: widgetData.id ? widgetData.state.y - headerHeight : widgetData.state.y,
        };

        const widgetIntersectionResults = canvasService.getWidgetIntersectionResultForHeaderReAdded(
          widgetData.id,
          newState
        );

        if (widgetIntersectionResults.isIntersecting) {
          modalService.openModal('confirm', {
            text: 'widget-side-bar.step-four.display-export-csv-failed.text',
            iconType: 'attention_image',
            confirmText: 'general.confirm',
            headerText: 'widget-side-bar.step-four.display-export-csv-failed.title"',
            showCloseBtn: true,
          });
        } else {
          setWidgetData((prevState) => ({
            ...prevState,
            state: widgetIntersectionResults.isIntersecting
              ? {
                  ...widgetIntersectionResults.coords,
                }
              : { ...newState, y: widgetData.state.y },
            exportFormats: [format],
          }));
        }
      } else if (exportFormats.length == 1 && exportFormats[0] === format) {
        // We are removing the only format checked, so clear the export formats and decrease the widget height,
        // since header is hidden and the export icon is no longer needed.

        const newState = {
          ...widgetData.state,
          h: widgetData.state.h - headerHeight,
          y: widgetData.state.y + headerHeight,
        };

        setWidgetData((prevState) => ({
          ...prevState,
          state: {
            ...newState,
          },
          exportFormats: [],
        }));
      }
    },
    [exportFormats, hideWidgetName]
  );

  const onResetExportFormat = useCallback(() => {
    setWidgetData((prevState) => ({
      ...prevState,
      exportFormats: [],
    }));
  }, [exportFormats]);

  const isExportFormatChecked = useCallback(
    (format) => {
      return exportFormats.includes(format);
    },
    [exportFormats]
  );

  return (
    <div className={styles.stepFourContainer}>
      <div className={classNames(styles.stepHeader, 'ellipsis-overflow')}>
        <I18n>widget-side-bar.customize</I18n>
      </div>
      <div className={styles.genericCustomization}>
        <WidgetCustomizeInput
          value={name}
          setWidgetName={setWidgetName}
          eventTemplateId={eventTemplateId}
          isNameValid={isNameValid}
        />
        {!eventTemplateId && (
          <div className={styles.item}>
            <MaterialCheckbox checked={hideWidgetName} onChange={(e) => setHideWidgetName(e)} />
            <I18n element="div" className={styles.checkboxText}>
              create-widget-page.create-widget.step-four.hide-header-and-border
            </I18n>
          </div>
        )}

        {enableExportCsv && !eventTemplateId && enableExportCsv(widgetData) && (
          <ExportActions
            exportFormats={exportFormats}
            onSelectedExportFormat={onSelectedExportFormat}
            isExportFormatChecked={isExportFormatChecked}
            onResetExportFormat={onResetExportFormat}
          />
        )}
        {WidgetTypeCustomization && (
          <div>
            <WidgetTypeCustomization
              widgetData={widgetData}
              setWidgetData={setWidgetData}
              previewData={previewData}
              defaultDecimalDigits={defaultDecimalDigits}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default StepFour;
