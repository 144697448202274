import React, { useState } from 'react';

// Components
import DeletedUsersTable from './DeletedUsersTable';
import DeletedUsersForgetSection from './DeletedUsersForgetSection';

// Services | Interfaces
import styles from './DeletedUsersMeta.scss';

function DeletedUsersMeta(props: any) {
  const [needRefreshData, setNeedRefreshData] = useState(true);

  return (
    <>
      <DeletedUsersForgetSection refreshTableData={() => setNeedRefreshData(true)} />
      <div className={styles.content}>
        <DeletedUsersTable
          needRefreshData={needRefreshData}
          onDataTableRefreshed={() => setNeedRefreshData(false)}
        />
      </div>
    </>
  );
}

export default DeletedUsersMeta;
