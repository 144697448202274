import React from 'react';
import Icon from '@components/Icon';
import styles from './NotificationCenterIcon.scss';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

function NotificationCenterIcon({
  unreadNotifications,
  openNotificationCenter,
  notificationsExist,
}) {
  const languageId = useSelector((state) => state.config.languageId);
  const isEnabled = notificationsExist || +unreadNotifications > 0;

  const defaultStyle = {
    transformOrigin: 'center',
    transform: 'rotate(30deg)',
  };

  const disabledbuttonStyle = {
    ...defaultStyle,
    cursor: 'default',
    pointerEvents: 'none',
    opacity: '0.4',
  };

  return (
    <div
      className={classNames(styles.wrapper, isEnabled ? styles.pointer : undefined)}
      onClick={isEnabled ? openNotificationCenter : null}>
      <Icon
        type={isEnabled ? 'notificationsActive' : 'notifications'}
        color={'var(--systemFont)'}
        width={'26px'}
        height={'26px'}
        style={!isEnabled ? disabledbuttonStyle : defaultStyle}
        tooltipText={'notification-center.notifications'}
      />
      {unreadNotifications ? (
        <div className={styles.numberWrapper}>
          <div
            className={styles.number}
            title={i18nService.translate('notification-center.unread-notifications', languageId, {
              unreadNotifications: unreadNotifications,
            })}>
            {unreadNotifications <= 99 ? unreadNotifications : '99+'}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NotificationCenterIcon;
