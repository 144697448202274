import { EventTemplateData } from '@core/http/server.interface';
import { i18nService } from '@core/i18n/I18nService';
import { string } from 'prop-types';
import {
  DATA_SOURCE_MENTION_LENGTH,
  convertLocalHtmlToServer,
  convertServerHtmlToLocal,
  getHtmlLength,
  getUsedDataSources,
} from './Message/RichTextEditor/RichTextEditor.utils';
import { omit } from 'lodash';
import { modalService } from '@core/modals/ModalService';

export const getDefaultEventTemplateData = (organizationId: number): EventTemplateData => {
  return {
    organizationId: organizationId,
    trigger: 'SCHEDULER',
    action: 'EMAIL_NOTIFICATION',
    isSpecificOrg: false,
    orgScope: ['MACHINE_BUILDER'],
    step: 'DETAILS',
    status: 'DRAFT',
    timeFrame: 'DAILY',
    isSchedulerEditable: true,
    time: '08:00:00',
    startDate: null,
    endDate: null,
    attachmentType: 'ATTACHED',
    body: '',
    subject: '',
    usedDataSources: [],
    isFinish: false,
    allowUnselectAdminGroup: false,
    sendToAdminGroup: false,
    notificationGroupsIds: [],
    fieldsCombined: null,
    isConditionLengthExceedsMax: false,
    isCheckingConditionLength: false,
    dashboardReport: {
      dashboardRefId: null,
      name: null,
      dashboardVisibleFilters: [],
    },
    timeZone: '',
  };
};

export const eventTemplateDetailsValidation = (templateData: EventTemplateData): boolean => {
  return (
    !!templateData?.name &&
    templateData?.orgScope?.length > 0 &&
    (!templateData?.isSpecificOrg || templateData.specificOrgId > 0)
  );
};

export const eventTemplateTriggerValidation = (templateData: EventTemplateData): boolean => {
  const {
    time,
    startDate,
    daysOfWeek,
    dayOfMonth,
    monthOfYear,
    dayOfYear,
    timeFrame,
    trigger,
    conditionAssetTypes,
    conditionTags,
    conditionTagTypes,
    conditionTree,
    fieldsCombined,
    isCheckingConditionLength,
    isConditionLengthExceedsMax,
  } = templateData;

  let res = !!time && !!startDate;

  res = res && !isCheckingConditionLength && !isConditionLengthExceedsMax;

  if (timeFrame === 'WEEKLY') res = res && daysOfWeek?.length > 0;

  if (timeFrame === 'MONTHLY') res = res && !!dayOfMonth;

  if (timeFrame === 'YEARLY') res = res && !!dayOfYear && !!monthOfYear;

  if (trigger === 'ALARM' || trigger === 'TELEMETRY')
    res = res && !!conditionAssetTypes && conditionAssetTypes.length > 0;

  if (
    trigger === 'TELEMETRY' &&
    conditionAssetTypes &&
    conditionAssetTypes.length > 1 &&
    fieldsCombined &&
    fieldsCombined['tagTypes']
  )
    res = res && Object.keys(fieldsCombined['tagTypes'].subfields)?.length > 0;

  if (trigger === 'ALARM' || trigger === 'TELEMETRY')
    res = res && validateConditionTree(conditionTree);

  return res;
};

export const eventTemplateDataSourcesValidation = (templateData: EventTemplateData): boolean => {
  return true;
};

export const eventTemplateMessageValidation = (templateData: EventTemplateData): boolean => {
  switch (templateData.action) {
    case 'EMAIL_NOTIFICATION':
      return (
        templateData.subject &&
        templateData.subject != '<p></p>\n' &&
        templateData.subject != '<p></p>' &&
        getHtmlLength(templateData.subject, true) <=
          MAX_LENGTH.MAX_TITLE_LENGTH[templateData.action] &&
        templateData.body &&
        templateData.body != '<p></p>\n' &&
        templateData.body != '<p></p>'
      );
    case 'SMS_NOTIFICATION':
      return (
        templateData.body &&
        templateData.body.length > 0 &&
        templateData.body != '<p></p>\n' &&
        templateData.body != '<p></p>' &&
        getHtmlLength(templateData.body, true, false) <=
          MAX_LENGTH.MAX_MESSAGE_LENGTH[templateData.action]
      );
    case 'PUSH_NOTIFICATION':
      return (
        templateData.subject &&
        templateData.subject != '<p></p>\n' &&
        templateData.subject != '<p></p>' &&
        getHtmlLength(templateData.subject, true, false) <=
          MAX_LENGTH.MAX_TITLE_LENGTH[templateData.action] &&
        templateData.body &&
        templateData.body != '<p></p>\n' &&
        templateData.body != '<p></p>' &&
        getHtmlLength(templateData.body, true) <= MAX_LENGTH.MAX_MESSAGE_LENGTH[templateData.action]
      );
    default:
      return false;
  }
};

export const eventTemplateAudienceValidation = (templateData: EventTemplateData): boolean => {
  return (
    templateData.sendToAdminGroup ||
    (templateData.notificationGroupsIds && templateData.notificationGroupsIds.length > 0)
  );
};

export const getLastValidStepIndex = (data, templateData) => {
  const validSteps = data
    .map((step) => ({ stepIndex: step.stepIndex, isValid: step.validation(templateData) }))
    .filter((step) => step.isValid);
  let lastValidStep = 0;

  for (let i = 0; i < validSteps.length; i++) {
    if (validSteps[i].stepIndex == lastValidStep + 1) lastValidStep = validSteps[i].stepIndex;
    else break;
  }

  return lastValidStep;
};

export const updateStepsStatus = (data, currentStep, templateData) => {
  let areStepsValid = true;
  let arePreviousStepsDone = true;
  let lastValidStep = getLastValidStepIndex(data, templateData);
  const currentStepIndex = Math.min(currentStep - 1, lastValidStep);

  data.forEach((step, index) => {
    if (index) areStepsValid = areStepsValid && data[index - 1]?.validation(templateData);

    if (index > 1) arePreviousStepsDone = arePreviousStepsDone && data[index - 2]?.isDone;

    if (index >= 1 && templateData.trigger !== 'SCHEDULER' && currentStepIndex === 1)
      step.isDone = false;

    if (index > currentStepIndex) {
      // when previous step is valid
      step.isDisable = !areStepsValid || !arePreviousStepsDone;
    } else if (index < currentStepIndex) {
      // when previous step is done
      step.isDone = true;
      step.isDisable = false;
    } else {
      step.isDisable = false;
    }
  });
};

export const localToServer = (templateData) => {
  let res =
    templateData?.condition === ''
      ? {
          ...omit(templateData, 'condition'),
        }
      : {
          ...templateData,
        };

  if (res.action && ['EMAIL_NOTIFICATION', 'PUSH_NOTIFICATION'].includes(res.action)) {
    let usedDataSources = getUsedDataSources(res.subject || '');
    usedDataSources = getUsedDataSources(res.body || '', usedDataSources);
    res = {
      ...res,
      subject: convertLocalHtmlToServer(res.subject || '', true),
      body: convertLocalHtmlToServer(res.body || '', res.action === 'PUSH_NOTIFICATION', true),
      usedDataSources,
    };
  } else if (res.action && res.action === 'SMS_NOTIFICATION') {
    let usedDataSources = getUsedDataSources(res.body || '');
    res = {
      ...res,
      subject: null,
      body: convertLocalHtmlToServer(res.body || '', true, true),
      usedDataSources,
    };
  }

  delete res.notificationGroups;
  delete res.fieldsCombined;
  delete res.isConditionLengthExceedsMax;
  delete res.isCheckingConditionLength;

  if (res.dashboardReport && !res.dashboardReport.refId) {
    res.dashboardReport = {};
  }

  if (res.endDate === undefined) res.endDate = null;

  return res;
};

export const AlarmsDataSources = [
  {
    name: i18nService.translate('events-template.data.source.alarm-name'),
    type: 'VALUE',
    status: 2,
    id: 'ALARM_NAME',
    isReadOnly: true,
  },
  {
    name: i18nService.translate('events-template.data.source.alarm-description'),
    type: 'VALUE',
    status: 2,
    id: 'ALARM_DESCRIPTION',
    isReadOnly: true,
  },
  {
    name: i18nService.translate('events-template.data.source.alarm-suggested-solution'),
    type: 'VALUE',
    status: 2,
    id: 'ALARM_RESOLUTION',
    isReadOnly: true,
  },
  {
    name: i18nService.translate('events-template.data.source.alarm-status'),
    type: 'VALUE',
    status: 2,
    id: 'ALARM_STATUS',
    isReadOnly: true,
  },
];

export const serverToLocal = (templateData, dataSources) => {
  let res = {
    ...templateData,
    notificationGroupsIds: templateData.notificationGroups?.map((g) => g.id),
    assetFilter: templateData.assetFilter || [],
    assetTypesFilter: templateData.assetTypesFilter || [],
    geoFilter: templateData.geoFilter || [],
    organizationsFilter: templateData.organizationsFilter || [],
  };

  let dataSourcesCopy = dataSources;

  if (templateData.trigger === 'ALARM') dataSourcesCopy = [...AlarmsDataSources, ...dataSources];

  try {
    if (res.action && ['EMAIL_NOTIFICATION', 'PUSH_NOTIFICATION'].includes(res.action)) {
      res = {
        ...res,
        subject: res?.subject ? convertServerHtmlToLocal(res.subject, dataSourcesCopy, true) : '',
        body: res?.body ? convertServerHtmlToLocal(res.body, dataSourcesCopy) : '',
      };
    } else if (res.action && res.action === 'SMS_NOTIFICATION') {
      res = {
        ...res,
        subject: null,
        body: res?.body ? convertServerHtmlToLocal(res.body, dataSourcesCopy, true) : '',
      };
    }

    return res;
  } catch (e) {
    return res;
  }
};

const validateConditionTree = (conditionTree: JSON): boolean => {
  if (!conditionTree) return false;

  return validateConditionTreeChildren(conditionTree['children1']);
};

const validateConditionTreeChildren = (conditionTree: JSON): boolean => {
  const childrenKeys = Object.keys(conditionTree);
  let res = true;

  if (childrenKeys.length === 0) res = false;

  childrenKeys.forEach((key) => {
    const ruleOrGroup = conditionTree[key];

    if (ruleOrGroup['type'] == 'group')
      res = res && validateConditionTreeChildren(ruleOrGroup['children1']);
    else {
      // Should be a rule
      const { properties } = ruleOrGroup;
      const { operator, value, valueSrc, field } = properties;

      if (!field || !operator) res = false;

      switch (operator) {
        case 'equal':
        case 'not_equal':
        case 'less':
        case 'less_or_equal':
        case 'greater':
        case 'greater_or_equal':
        case 'starts_with':
        case 'ends_with':
          if (
            valueSrc &&
            valueSrc.length &&
            valueSrc[0] === 'func' &&
            value &&
            value.length &&
            value[0]
          ) {
            const args = value[0]['args'];
            const { expression } = args;
            const expressionValue = expression['value'];

            if (!expressionValue || !expressionValue.length) res = false;
          } else {
            if (!value || !value.length || value[0] == null) res = false;
          }
          break;
        case 'between':
        case 'not_between':
          if (
            valueSrc &&
            valueSrc.length &&
            valueSrc[0] === 'func' &&
            value &&
            value.length &&
            value[0]
          ) {
            const args = value[0]['args'];
            const { expression } = args;
            const expressionValue = expression['value'];

            if (!expressionValue || !expressionValue.length) res = false;
          } else {
            if (!value || !value.length || value[0] == null) res = false;
          }

          if (
            valueSrc &&
            valueSrc.length > 1 &&
            valueSrc[1] === 'func' &&
            value &&
            value.length > 1 &&
            value[1]
          ) {
            const args = value[1]['args'];
            const { expression } = args;
            const expressionValue = expression['value'];

            if (!expressionValue || !expressionValue.length) res = false;
          } else {
            if (!value || value.length < 2 || value[1] == null) res = false;
          }

          break;
      }
    }
  });

  return res;
};

export const MAX_LENGTH = {
  MAX_MESSAGE_LENGTH: { SMS_NOTIFICATION: 500, PUSH_NOTIFICATION: 100 },
  MAX_TITLE_LENGTH: { EMAIL_NOTIFICATION: 100, PUSH_NOTIFICATION: 40 },
};

export function isLengthExceeded(
  action,
  type: 'TITLE' | 'MESSAGE',
  html,
  asPlainText = false,
  includeMentions = true
) {
  if (html) {
    const usedDataSources = getUsedDataSources(html) || [];
    const calculatedMaxLength = includeMentions
      ? MAX_LENGTH[`MAX_${type}_LENGTH`][action]
      : MAX_LENGTH[`MAX_${type}_LENGTH`][action] -
        usedDataSources?.length * DATA_SOURCE_MENTION_LENGTH;
    const A = getHtmlLength(html, asPlainText, includeMentions);
    return getHtmlLength(html, asPlainText, includeMentions) > calculatedMaxLength;
  }
  return false;
}
