import { AssetGeneralModel } from './assetInfoGeneralEdit.interface';
import { httpService } from '@core/http/HttpService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { modalService } from '@core/modals/ModalService';
import { AssetInfo } from '@modals/EditAssetModal/EditAssetModal.interface';
import { PlcSerial } from '@modals/CreatePLCModal/createPLCModal.interface';

export const getInitialValues = (data) => {
  return {
    plcSerial: { id: Math.random(), text: data.plcSerial, message: null, type: null },
    assetName: data.assetName,
    assetSerial: data.assetSerial || '',
    comments: data.comments || '',
    location: data.location,
    galleryImageId: data.galleryImageId,
    owner: { value: data.ownerId, label: data.owner },
  };
};

export const getChangedFields = (values: AssetGeneralModel, initialValues, data) => {
  const changedFields = {};
  for (const key in values) {
    switch (key) {
      case 'assetName':
      case 'assetSerial':
      case 'galleryImageId':
      case 'comments':
        if (values[key] !== initialValues[key]) changedFields[key] = values[key];
        break;
      case 'owner':
        if (values[key].value !== initialValues[key].value) {
          changedFields['ownerId'] = values[key].value;
        }
        break;
      case 'plcSerial':
        if (values[key].text !== initialValues[key].text || values[key].text !== data.plcSerial)
          changedFields['plcId'] = values[key].id;
        break;
      case 'location':
        if (values[key].googleId !== initialValues[key].googleId) {
          changedFields[key] = values[key];
        }
        break;
    }
  }
  return changedFields;
};

const checkIfPlcSerialRemoved = async (
  changedValues,
  values: AssetGeneralModel,
  disconnectedPlcByBtn,
  initialPlcSerial
) => {
  let continueSave = true;
  if (changedValues.hasOwnProperty('plcId') && initialPlcSerial?.text && !disconnectedPlcByBtn) {
    const text =
      !values['plcSerial'].text && !changedValues['plcId']
        ? 'edit-asset-modal.disconnectMessage'
        : 'edit-asset-modal.replace-serial.message';
    const headerText =
      !values['plcSerial'].text && !changedValues['plcId']
        ? 'edit-asset-modal.disconnectHeader'
        : 'edit-asset-modal.replace-serial.confirm-replace.header';
    continueSave = await modalService.openModal('confirm', {
      text,
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText,
      showCloseBtn: true,
    });
  }
  return continueSave;
};

export const onSubmit = async (
  values: AssetGeneralModel,
  onEditClicked,
  data,
  disconnectedPlcByBtn,
  initialValues,
  plcType: string,
  plcSerialGenerated: boolean
) => {
  let newValues: AssetGeneralModel = {
    ...values,
  };

  const changedValues = getChangedFields(newValues, initialValues, data);
  const continueSave = await checkIfPlcSerialRemoved(
    changedValues,
    newValues,
    disconnectedPlcByBtn,
    initialValues['plcSerial']
  );

  if (continueSave) {
    if (plcType === 'MODBUS') {
      if (plcSerialGenerated) {
        await httpService
          .api({
            type: 'createNonUnitronicsPlc',
            data: {
              plcType: plcType,
              plcSerial: values.plcSerial.text,
            },
          })
          .then((res: any) => {
            changedValues['plcId'] = res.id;
            newValues = {
              ...values,
              plcSerial: {
                id: res.id,
                text: res.serial,
                message: '',
              },
            };
          });
      }
    }

    saveData(changedValues, newValues, onEditClicked, data);
  }
};

const saveData = (changedValues, values, onEditClicked, data) => {
  httpService
    .api({
      type: 'saveGeneralAsset',
      data: changedValues,
      urlParams: { assetId: data.assetId, useUserOrganizationId: true },
    })
    .then((res: AssetInfo) => {
      assetsService.refreshAssetTableData();
      editAssetService.updateEditAsset({
        ...values,
        state: res.state,
        ownerId: res.general.ownerId,
        status: res.status,
        hasCertificate: res.hasCertificate,
        plcModel: res.general.plcModel,
        plcCatalogNumber: res.general.plcCatalogNumber,
        connectedAt: res.connectedAt,
        galleryImageId: res.galleryImageId,
      });
      onEditClicked();
    });
};
