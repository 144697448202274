import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { getDateTimeWLFormat, downloadFile } from '@core/utils';

export const defaultCategoryList = [
  { value: 'ALARMS', label: 'Alarms' },
  { value: 'DATA_SAMPLES', label: 'Data Samples' },
  { value: 'DATA_TABLES', label: 'Data Tables' },
  { value: 'MEDIA', label: 'Media' },
  { value: 'NETWORK_SETTINGS', label: 'Network Settings' },
  { value: 'RETAINED_MEMORY', label: 'Retained Memory' },
  { value: 'SYSTEM_LOGS', label: 'System Logs' },
  { value: 'USER_FILES', label: 'User Files' },
];

export function onCategorySelected(
  selectedOption,
  {
    organizationId,
    assetId,
    timezone,
    whiteLabelDateFormat,
    setValue,
    setDateList,
    setSelectedDate,
    setSelectedFile,
    setFileList,
  }
) {
  const query = { prefix: `${selectedOption.label}/` };

  function setList(res) {
    setDateList(
      res
        .map((date) => {
          return {
            value: date.replace('/', ''),
            label: getDateTimeWLFormat(
              date.replace('/', ''),
              timezone,
              whiteLabelDateFormat?.momentFormat
            ),
          };
        })
        .reverse()
    );
  }

  setValue(selectedOption);
  setSelectedFile(null);
  setSelectedDate(null);
  setFileList([]);
  exploreDataBackup(organizationId, assetId, setList, query);
}

function getSelectedcategoryLabel(selectedCategory: { value: string; label: string }) {
  return defaultCategoryList.find((category) => category.value === selectedCategory.value).label;
}

export function onDateSelected(
  selectedOption,
  { selectedCategory, organizationId, assetId, setValue, setSelectedFile, setFileList }
) {
  const query = {
    prefix: `${getSelectedcategoryLabel(selectedCategory)}/${selectedOption.value}/`,
  };

  setValue(selectedOption);
  setSelectedFile(null);

  exploreDataBackup(organizationId, assetId, setFileList, query);
}

export function exploreDataBackup(
  organizationId: number,
  assetId: number,
  setList: (res: any[]) => void,
  query: {} = null
) {
  httpService
    .api({
      type: 'exploreDataBackup',
      urlParams: { organizationId, assetId },
      query: query,
    })
    .then((res: Array<any>) => {
      if (res) {
        setList(res);
      }
    });
}

export function downloadDataBackupFile(
  organizationId: number,
  assetId: number,
  selectedFile: string,
  filePath: string
) {
  httpService
    .api({
      type: 'downloadDataBackupFile',
      urlParams: { organizationId, assetId },
      query: {
        filepath: filePath,
      },
    })
    .then((res: { url: string }) => {
      if (res?.url) {
        try {
          downloadFile(res.url, selectedFile);
        } catch (e) {
          console.log(e);
        }
      }
    });
}

export function restoreFromDataBackupFile(
  organizationId: number,
  assetId: number,
  filePath: string
) {
  httpService.api({
    type: 'restoreFromDataBackupFile',
    urlParams: { organizationId, assetId },
    query: {
      filepath: filePath,
    },
  });
}

export function getFilePath(
  selectedCategory: { value: string; label: string },
  selectedDate: { value: string; label: string },
  selectedFile: string
) {
  return `${getSelectedcategoryLabel(selectedCategory)}/${selectedDate.value}/${selectedFile}`;
}

export function openConfirmationModal(
  organizationId,
  assetId,
  assetStatus,
  plcSerial,
  selectedCategory,
  selectedDate,
  selectedFile
) {
  const actionNotAllowed = [
    'COMMUNICATION_ERROR',
    'DISCONNECTED',
    'PENDING_ROUTER',
    'AVAILABLE',
    'ARCHIVED',
  ].includes(assetStatus);

  modalService
    .openModal('confirm', {
      text: actionNotAllowed
        ? 'edit-asset-modal.data-backup.restore.restore-from-backup.plc-not-connected.text'
        : selectedCategory.value === 'NETWORK_SETTINGS'
        ? 'edit-asset-modal.data-backup.restore.network-settings-warning'
        : 'edit-asset-modal.data-backup.restore.general-warning',
      iconType: 'attention_image',
      confirmText: actionNotAllowed ? null : 'general.confirm',
      cancelText: actionNotAllowed ? 'general.close' : 'general.cancel',
      headerText: actionNotAllowed
        ? 'edit-asset-modal.data-backup.restore.restore-from-backup.plc-not-connected.header'
        : 'edit-asset-modal.data-backup.restore.restore-from-backup',
      showCloseBtn: true,
      selectedFile,
      plcSerial,
    })
    .then(async (confirm) => {
      if (confirm) {
        restoreFromDataBackupFile(
          organizationId,
          assetId,
          getFilePath(selectedCategory, selectedDate, selectedFile)
        );
      }
    });
}
