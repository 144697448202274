import React, { useMemo, useCallback } from 'react';
import { modalService } from '@core/modals/ModalService';
import { TagMeta } from '@http/server.interface.d';
import UpdateTypeButton from './UpdateTypeButton';
import styles from './TagsButtons.scss';
import { getPermissionStatus, getFlagStatus } from '@core/ffAndPermissions';

function TagsButtons(props: TagMeta) {
  const { tagId, type, assignmentStatus, name } = props;
  const inProgress = assignmentStatus === 'IN_PROGRESS';

  const flags = useMemo(
    () => ({
      edit: getFlagStatus('asset-types.edit-buttons'),
    }),
    []
  );

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const buttonLabel = useMemo(() => {
    if (inProgress) {
      return 'asset-type-tags.in-progress';
    } else {
      return type ? 'asset-type-tags.update-type' : 'asset-type-tags.add-type';
    }
  }, [inProgress, type]);

  const onClick = useCallback(() => {
    modalService.openModal('assignTagType', { tagId, type, name });
  }, [tagId, type, name]);

  return (
    <div className={styles.wrapper}>
      {flags.edit && (
        <UpdateTypeButton disabled={inProgress || !hasPermission} onClick={onClick}>
          {buttonLabel}
        </UpdateTypeButton>
      )}
    </div>
  );
}

export default React.memo(TagsButtons);
