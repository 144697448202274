import React from 'react';
import styles from './Form.scss';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import * as Yup from 'yup';

const validationSchema = fieldsCfg => {
  return Yup.object().shape(
    fieldsCfg.reduce(
      (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
      {}
    )
  );
};

function Form(props) {
  const { data, editMode, fieldsCfg, onSubmit = () => {}, enableReinitialize } = props;

  return (
    <div className={styles.form}>
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={data}
        validationSchema={validationSchema(fieldsCfg)}
        onSubmit={onSubmit}>
        {({ isValid, isSubmitting, values, errors }) => (
          <FormikForm className={styles.form}>
            {fieldsCfg.map((field, idx) => (
              <FormikField key={idx} editMode={editMode} {...field} values={values}></FormikField>
            ))}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}

export default Form;
