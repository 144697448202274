import React, { useMemo } from 'react';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import SubscriptionTable from './SubscriptionTable';
import Button from '@components/Button';
import Icon from '@components/Icon';

const useStyle = makeStyles(() => ({
  wrapper: {
    width: ({ args }: { args: any }) => (args?.subscriptions?.length ? 1038 : 740),
    height: ({ args }) => (args?.subscriptions?.length ? 670 : 310),
    position: 'relative',
    overflow: 'hidden',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
  modalContent: {
    width: '100%',
    backgroundColor: 'var(--systemScreenBackground)',
    height: 'calc(100% - 56px)',
    padding: ({ args }) =>
      args?.subscriptions?.length ? '16px 26px 24px 26px' : '15px 18px 21px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  topRow: {
    display: 'flex',
  },
  topRowField: {
    fontWeight: 'normal',
    marginLeft: 12,
  },
  topRowLabel: {
    marginRight: ({ args }) => (args?.subscriptions?.length ? 60 : 44),
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
  emptyState: {
    height: 127,
    backgroundColor: 'var(--systemContentBackground)',
    boxShadow: 'var(--boxShadowGrey)',
    marginTop: 11,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsRow: {
    marginTop: ({ args }) => (args?.subscriptions?.length ? 23 : 21),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const AddSubscriptionToAssetModal = (props: { args: any; dismiss: () => void }) => {
  const { subscriptions = [], assetName, assetId } = props.args;
  const classes = useStyle(props);
  const dataTopRow = useMemo(
    () => [
      { text: 'asset-id', field: 'assetId' },
      { text: 'asset-name', field: 'assetName' },
      { text: 'required-plan', field: 'requiredPlan' },
    ],
    []
  );
  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n>add-subscription-to-asset-modal.title</I18n>
        <Icon type="close" onClick={props.dismiss} className={'pointer'}></Icon>
      </div>
      <div className={classes.modalContent}>
        <div className={classes.topRow}>
          {dataTopRow.map(({ text, field }) => (
            <div className={classes.topRowLabel} key={field}>
              <I18n>{`add-subscription-to-asset-modal.${text}`}</I18n>:
              <span className={classes.topRowField}>{props.args[field]}</span>
            </div>
          ))}
        </div>
        {subscriptions.length ? (
          <SubscriptionTable
            subscriptions={subscriptions}
            assetName={assetName}
            assetId={assetId}
            dismiss={props.dismiss}
          />
        ) : (
          <div className={classes.emptyState}>
            <I18n>add-subscription-to-asset-modal.no-subscriptions</I18n>
            <I18n>add-subscription-to-asset-modal.no-subscriptions-second</I18n>
          </div>
        )}
        <div className={classes.buttonsRow}>
          <Button mode="cancel" onClick={() => props.dismiss()}>
            <I18n>{`general.${subscriptions.length ? 'cancel' : 'close'}`}</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddSubscriptionToAssetModal;
