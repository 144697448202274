import React, { useState, useMemo } from 'react';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import Table from '@components/Table';
import Spinner from '@components/Spinner';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { staticDictionary } from '@core/i18n/dictionary';
import { numberFormatter, dateTimeRegex, dateRegex } from '@core/utils';
import { buildDateTime } from '../charts.utils';
import { getMetricName } from '@pages/CreateWidgetPage/widgets.utils';

const tableHeadersMap = {
  active_alarm: 'alarm status',
  alarm_index: 'alarm id',
};

const getHeader = (val) => tableHeadersMap[val] || val;

const getFieldValue = (val, decimalDigits, props, timezone?: string) => {
  if (typeof val == 'boolean') {
    if (props.valueType === 'ALARM_INFO' && props.valueId === 2) {
      return staticDictionary[`enum.${(val + '_active_alarm').toUpperCase()}`];
    } else {
      return staticDictionary[`enum.${(val + '').toUpperCase()}`];
    }
  }
  if (
    props.valueId === 5 &&
    props.valueType === 'ASSET_PROPERTY' &&
    (!props.operation || props.operation === '')
  ) {
    return staticDictionary[`enum.${val}`];
  }

  if (val && props.operation === 'WEEKLY') {
    return `${buildDateTime(
      val.toString().split(' : ')[0],
      props.dateTimeFormat,
      'momentFormat',
      timezone
    )} : ${buildDateTime(
      val.toString().split(' : ')[1],
      props.dateTimeFormat,
      'momentFormat',
      timezone
    )}`;
  }
  if (val && props.format === 'DATETIME' && props.operation === 'YEARLY') {
    return val;
  }
  if (
    val &&
    typeof val === 'string' &&
    (dateTimeRegex.test(val.split('.')[0]) || dateRegex.test(val.split('.')[0]))
  ) {
    return buildDateTime(val, props.dateTimeFormat, 'momentFormat', timezone);
  }
  if (
    props.format === 'DURATION' ||
    (props.valueId === 5 &&
      props.valueType === 'ALARM_INFO' &&
      props.operation &&
      props.operation === 'AVERAGE')
  ) {
    return props.severity !== 'OK' ? getValueAsDuration(val) : null;
  } else {
    return getFieldNumberStringValue(val, props.format, decimalDigits);
  }
};

const getValueAsDuration = (val): String => {
  const dur = moment.duration(val / 1000, 'seconds');
  const hours = Math.floor(dur.asHours());
  const mins = Math.floor(dur.asMinutes()) - hours * 60;
  const sec = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;

  const formatTime = (time) => (time > 9 ? time.toLocaleString('en') : '0' + time);

  return formatTime(hours) + ':' + formatTime(mins) + ':' + formatTime(sec);
};

const getFieldNumberStringValue = (val, format, decimalDigits): any =>
  isNaN(Number(val)) || format === 'STRING'
    ? val
    : val || val === 0
    ? numberFormatter(val, decimalDigits)
    : '';

const LabelComponent = (props) => {
  const value = getFieldValue(props[props.field], props.decimalDigits, props, props.timezone);
  return (
    <div className="ellipsis-overflow" style={{ minWidth: '62px' }} title={`${value}`}>
      {props.symbol
        ? props.showSymbolLeft
          ? `${value} ${props.symbol}`
          : `${props.symbol} ${value}`
        : value}
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'inherit',
    color: 'var(--widgetsFont)',
  },
  tableWrapper: {
    height: '100%',
    backgroundColor: 'inherit',
    overflowX: 'auto',
    borderRadius: ({ widget, widgetData }: any) =>
      widget?.hideWidgetName || widgetData?.hideWidgetName
        ? 'var(--canvasWidgetBorderRadius)'
        : '0 0 var(--canvasWidgetBorderRadius) var(--canvasWidgetBorderRadius)',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--tableSpinnerBackground)',
  },
}));

function InfiniteTable(props) {
  const {
    data,
    customization,
    navigateDashboard,
    widget,
    widgetData,
    getMoreWidgetData,
    defaultDecimalDigits,
    timezone,
  } = props;
  const isClickable = navigateDashboard && widget?.navigationDashboard?.length;
  const columns = data && data.columns && data.columns.filter((col) => !col.hidden);
  let loading = useMemo(() => false, []);
  const tableWidth = useMemo(
    () =>
      customization?.columns?.reduce(
        (total, column) => total + (column.width || 100 / customization.columns?.length),
        0
      ) || 0,
    [customization]
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [widgetWidth, setWidgetWidth] = useState(0);
  const classes = useStyles(props);
  // A holder for handleGetNextPage to be populated with getNextPage
  const getNextPageHolder = useMemo(
    () => ({
      handleGetNextPage: (e, getData, orderedBy, disabledLoad) => {},
    }),
    []
  );

  const getData = async () => {
    if (!loading) {
      loading = true;
      setShowSpinner(true);
      await props.getMoreWidgetData();
      loading = false;
      setShowSpinner(false);
    }
  };

  const onCellClicked = ({ column, rowIndex }) => {
    navigateDashboard &&
      widget.navigationDashboard &&
      navigationDataBuilder({
        selectedValue: data.results[rowIndex][column.name],
        selectedColumn: column,
        widget,
        columns: data.columns,
        rawValues: data.results && data.results[rowIndex],
        navigateDashboard,
      });
  };

  function applyColumnCustomization() {
    if (!customization || !Object.keys(customization).length) {
      return columns.map((c) => {
        const metricName = getHeader(getMetricName(widgetData, c));
        return {
          Component: LabelComponent,
          ...c,
          label: c.operation ? `${metricName} ${c.operation.toLowerCase()}` : metricName,
          field: c.name,
          style: {
            width: `${100 / columns.length}%`,
            minWidth: `${100 / columns.length}%`,
            maxWidth: `${100 / columns.length}%`,
          },
          isClickable,
          decimalDigits: defaultDecimalDigits,
          timezone: timezone,
        };
      });
    } else {
      return customization.columns?.map((c, index) => {
        const name = columns.find(
          (dataColumn) =>
            dataColumn.valueId === c.id &&
            dataColumn.valueType === c.valueType &&
            (!c.operation || dataColumn.operation === c.operation)
        )?.name;
        return {
          Component: LabelComponent,
          ...c,
          ...(columns.find(
            (col) =>
              col.valueId == c.id && col.valueType === c.valueType && col.operation === c.operation
          ) || {}),
          label: getHeader(c.displayName),
          field: name,
          style: {
            width: `${(widgetWidth * (c.width || 100 / customization.columns.length)) / 100}px`,
            minHeight: 20,
          },
          isClickable,
          decimalDigits: c.decimalDigits,
          timezone: timezone,
        };
      });
    }
  }

  const updateSize = (e) => {
    const values = e?.getBoundingClientRect();
    values && setWidgetWidth(values?.width);
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.tableWrapper}
        // onScroll={(e) => {
        //   // Launching the 'getNextPage()' from the Table Component with the wrapper scroll event
        //   getNextPageHolder['handleGetNextPage'](
        //     e,
        //     getData,
        //     null,
        //     !(getMoreWidgetData && data.next)
        //   );
        // }}
        ref={updateSize}>
        <div
          style={{
            width: `calc(${tableWidth > 100 ? tableWidth : 100}%)`,
            minWidth: `calc(${tableWidth > 100 ? tableWidth : 100}%)`,
            height: '100%',
            backgroundColor: 'inherit',
          }}>
          <Table
            data={data.results}
            getData={getData}
            customization={customization}
            colunmsCfg={applyColumnCustomization()}
            disabledLoad={!(getMoreWidgetData && data.next)}
            onCellClicked={onCellClicked}
            isWrapperScroll={true} // When we want that the wrapper will have the scroll - getData mechanism
            getNextPageHolder={getNextPageHolder} // the getNextPage holder
            eventTemplateId={widget?.eventTemplateId || widgetData?.eventTemplateId}
            widgetType={widget?.type || widgetData?.type}
          />
        </div>
      </div>
      {showSpinner && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default InfiniteTable;
