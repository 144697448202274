import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { List } from '@material-ui/core';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { menuItems } from './SideMenu.utils';
import { useSelector } from '@redux/useSelector';
import styles from './SideMenu.scss';
import { usePrevious } from '@core/hooks/usePrevious';
import { deleteOrganizatioData } from '@src/redux/organizations/organizations.actions';
import { dispatch } from '@src/redux/store';
import { isAllow } from '@pages/MainPage/MainPage.utils';
import { sessionService } from '@core/session/SessionService';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { setSelectionBox } from '@src/redux/dashboardEditor';
import { isMobile, isTablet } from 'react-device-detect';

function SideMenu(props: any) {
  const { openMenu, enableAnimation } = props;
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const isUnitronicsAdminLiveDashboardSession = sessionService.getValue('otherOrganizationID', null)
    ? true
    : false;
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const isUnitronicsAdminUser = organizationTypeMap[organizationDetails?.type] === 1;
  const location = useLocation();
  const prevLocation: any = usePrevious(location);

  const isLiveDashboard = location.pathname.includes('/dashboard');

  useEffect(() => {
    if (
      prevLocation &&
      prevLocation.pathname.includes('/main/organizations') &&
      !location.pathname.includes('/main/organizations')
    ) {
      dispatch(deleteOrganizatioData());
    }
  }, [location]);

  const getColor = (itemRoute) => {
    return location.pathname.includes(`/${itemRoute}`)
      ? isLiveDashboard
        ? 'var(--liveMenusSelected)'
        : 'var(--menusSelected)'
      : isLiveDashboard
      ? 'var(--liveMenusForeground)'
      : 'var(--menusForeground)';
  };

  function onMouseUp() {
    dispatch(setSelectionBox(false));
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        enableAnimation && styles.enableAnimation,
        openMenu
          ? isMobileView
            ? styles.expandedMobile
            : styles.expanded
          : isMobileView
          ? styles.narrowMobile
          : styles.narrow,
        isLiveDashboard && styles.liveDashboardColors,
        isMobileView && styles.mobileWrapper
      )}
      onMouseUp={onMouseUp}>
      {menuItems
        .filter((menuItem) =>
          isAllow(
            menuItem,
            isMobile,
            isTablet,
            isUnitronicsAdminLiveDashboardSession,
            isUnitronicsAdminUser
          )
        )
        .map((item, idx) => (
          <React.Fragment key={idx}>
            <List
              id={isLiveDashboard ? styles.listItemLiveDashboard : styles.listItem}
              className={classNames(
                location.pathname.includes(`/${item.route}`) && styles.item,
                isLiveDashboard && styles.liveDashboardColors,
                isMobileView && !openMenu && styles.mobileView
              )}>
              <NavLink
                className={classNames(
                  styles.iconLink,
                  isLiveDashboard && styles.liveDashboardColors
                )}
                activeClassName={styles.activeIcon}
                to={`/main/${item.route}`}>
                {(!isMobileView || (isMobileView && openMenu)) && (
                  <Icon type={item.iconType} className={styles.icon} color={getColor(item.route)} />
                )}
                {openMenu && (
                  <I18n
                    className={classNames(
                      styles.linkText,
                      openMenu ? styles.expanded : styles.narrow,
                      isLiveDashboard && styles.liveDashboardColors
                    )}
                    style={{ color: getColor(item.route) }}>
                    {item.label}
                  </I18n>
                )}
              </NavLink>
            </List>
          </React.Fragment>
        ))}
    </div>
  );
}

export default React.memo(SideMenu);
