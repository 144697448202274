import React from 'react';
import I18n from '@components/I18n';
import { changeWidgetData } from '../Heatmap.utils';
import { TextField } from '@material-ui/core';
import MaterialCheckbox from '@components/Checkbox';

function Tooltip({ classes, tooltip, setWidgetData }) {
  return (
    <div className={classes.section}>
      <div className={classes.titleWrapper}>
        <I18n>create-widget-page.create-widget.step-four.tooltip</I18n>
      </div>
      <div className={classes.item}>
        <MaterialCheckbox
          color="primary"
          checked={tooltip.visible}
          onClick={() =>
            changeWidgetData({ ...tooltip, visible: !tooltip.visible }, 'tooltip', setWidgetData)
          }
        />
        <I18n className={classes.cbLabel}>create-widget-page.create-widget.step-four.visible</I18n>
      </div>
      <div className={classes.item}>
        <MaterialCheckbox
          color="primary"
          disabled={!tooltip.visible}
          checked={tooltip.showAxisLegend}
          onClick={() =>
            changeWidgetData(
              { ...tooltip, showAxisLegend: !tooltip.showAxisLegend },
              'tooltip',
              setWidgetData
            )
          }
        />
        <I18n className={classes.cbLabel}>
          create-widget-page.create-widget.step-four.show-axis-legend
        </I18n>
      </div>
      <div className={classes.item}>
        <I18n className={classes.text}>
          create-widget-page.create-widget.step-four.decimal-digits
        </I18n>
        <TextField
          disabled={!tooltip.visible}
          className={classes.numberField}
          value={tooltip.decimalDigits}
          margin="dense"
          variant="outlined"
          inputProps={{ style: { color: 'var(--systemFont)' } }}
          onChange={(e) =>
            changeWidgetData(
              { ...tooltip, decimalDigits: e.target.value },
              'tooltip',
              setWidgetData
            )
          }
          type="number"
        />
      </div>
    </div>
  );
}

export default Tooltip;
