import {
  convertCalculationToObject,
  getIsFloat,
  getMetricName,
} from '@pages/CreateWidgetPage/widgets.utils';
import { getVar } from '@core/canvas/widget.utils';

export const changeWidgetData = (value, property: string, setWidgetData) => {
  switch (property) {
    case 'tooltip_decimalDigits':
      const reg = /^\d+$/;
      if ((!reg.test(value) && value !== '') || +value > 9) return;
      break;
    case 'columnsWidthPercent':
      if (+value < 1 || +value > 100) return;
      break;
  }
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [property]: value,
    },
  }));
};

export const setCustomizationData = (type, position, key, value, customization, setWidgetData) => {
  const TempCustom = { ...customization };
  TempCustom[type][position][key] = value;

  setWidgetData((prevState) => ({
    ...prevState,
    customization: TempCustom,
  }));
};

const getColDefaultColor = (colIndex) => {
  const varIndex = colIndex < 6 ? colIndex : colIndex % 6;
  return getVar(`widgetsGraphsColorsPalette${varIndex + 1}`);
};

const getDataOnAggregateScope = (
  metric,
  data,
  calculationsObject,
  widgetData,
  defaultDecimalDigits
) => {
  const metricName = getMetricName(widgetData, metric);

  const name = metric.operation ? `${metricName} ${metric.operation.toLowerCase()}` : metricName;

  const calculation =
    calculationsObject[metric.valueType] &&
    calculationsObject[metric.valueType][metric.valueId] &&
    calculationsObject[metric.valueType][metric.valueId][metric.operation];

  const isFloat = getIsFloat(
    metric.operation,
    metric.valueType,
    metric.type,
    calculation,
    widgetData
  );
  return {
    showDecimalPoint: data.showDecimalPoint || isFloat,
    columns: [
      ...data.columns,
      {
        id: metric.valueId,
        valueType: metric.valueType,
        operation: metric.operation,
        name,
        displayName: name.length > 20 ? name.slice(0, 20) : name,
        type: 'BAR',
        color: getColDefaultColor(data.counter),
        decimalDigits: isFloat ? defaultDecimalDigits : null,
      },
    ],
    yAxes: [
      ...data.yAxes,
      {
        id: metric.valueId,
        valueType: metric.valueType,
        operation: metric.operation,
        name,
        displayName: name.length > 20 ? name.slice(0, 20) : name,
        show: data.counter === 0 ? 'LEFT' : 'NO',
        scale: 'AUTO',
        maxValue: null,
        minValue: null,
        decimalDigits: isFloat ? defaultDecimalDigits : null,
      },
    ],
    counter: data.counter + 1,
  };
};

const getDataOnLastValueScope = (
  data,
  metric,
  calculationsObject,
  widgetData,
  defaultDecimalDigits
) => {
  const metricName = getMetricName(widgetData, metric);

  const calculation =
    calculationsObject[metric?.valueType || metric?.tagType] &&
    calculationsObject[metric.valueType || metric.tagType][metric.valueId || metric.id];

  const isFloat = getIsFloat(
    null,
    metric.valueType,
    metric.type,
    calculation?.null || calculation,
    widgetData
  );

  const displayName = metricName.length > 20 ? metricName.slice(0, 20) : metricName;
  return {
    showDecimalPoint: data.showDecimalPoint || isFloat,
    columns: [
      ...data.columns,
      {
        id: metric.valueId,
        valueType: metric.valueType,
        operation: null,
        name: metricName,
        displayName,
        type: 'BAR',
        color: getColDefaultColor(data.counter),
        decimalDigits: isFloat ? defaultDecimalDigits : null,
      },
    ],
    yAxes: [
      ...data.yAxes,
      {
        id: metric.valueId,
        valueType: metric.valueType,
        operation: null,
        name: metricName,
        displayName,
        show: data.counter === 0 ? 'LEFT' : 'NO',
        scale: 'AUTO',
        maxValue: null,
        minValue: null,
        decimalDigits: isFloat ? defaultDecimalDigits : null,
      },
    ],
    counter: data.counter + 1,
  };
};

export const setColumnsDefaultData = (
  customization,
  widgetData,
  setWidgetData,
  defaultDecimalDigits
) => {
  const calculationsObject = convertCalculationToObject(widgetData.calculations);
  let { columns, yAxes, showDecimalPoint } = widgetData.metrics.reduce(
    (data: any, metric: any, idx) => {
      if (metric.hide) {
        return data;
      }
      if (metric.operation) {
        return getDataOnAggregateScope(
          metric,
          data,
          calculationsObject,
          widgetData,
          defaultDecimalDigits
        );
      } else {
        return getDataOnLastValueScope(
          data,
          metric,
          calculationsObject,
          widgetData,
          defaultDecimalDigits
        );
      }
    },
    { columns: [], yAxes: [], counter: 0, showDecimalPoint: false }
  );

  let newColumns = [];
  if (customization?.columns?.length) {
    columns.forEach((originalItem) => {
      const existingItems = customization.columns.filter(
        (item) => originalItem.id === item.id && originalItem.operation === item.operation
      );
      if (existingItems.length === 0) {
        newColumns.push(originalItem);
      } else {
        existingItems.forEach((c) => newColumns.push({ ...c, name: originalItem?.name }));
      }
    });
  } else newColumns = columns;

  if (customization?.yAxes?.length) {
    // keep customized yAxes - for that it's properties won't be overriden
    yAxes = yAxes.map((originalItem) => {
      const existingItem = customization.yAxes.find(
        (item) => originalItem.id === item.id && originalItem.operation === item.operation
      );
      return { ...existingItem, name: originalItem?.name } || originalItem;
    });
  }

  setWidgetData((prevState) => ({
    ...prevState,
    customization: { ...prevState.customization, columns: newColumns, yAxes },
  }));
};

export const changeWidgetTable = (columnUpdated, index, tableKey, setWidgetData) => {
  // when columns includes multi types set dataLabels_show to false and shared_tooltip to true
  setWidgetData((prevState) => {
    const data = prevState.customization[tableKey].reduce(
      (d, col, idx) => {
        const item = idx === index ? columnUpdated : col;
        return {
          [tableKey]: [...d[tableKey], item],
          dataLabels_visible:
            d.dataLabels_visible || (tableKey === 'columns' && item.type !== 'BAR'),
          tooltip_shared: d.tooltip_shared || (tableKey === 'columns' && item.type !== 'BAR'),
        };
      },
      { [tableKey]: [], dataLabels_visible: false, tooltip_shared: false }
    );
    data.dataLabels_visible = !data.dataLabels_visible;
    return {
      ...prevState,
      customization: {
        ...prevState.customization,
        ...data,
      },
    };
  });
};

export const columnsCfg = [
  {
    style: { paddingRight: '10px', minWidth: '27%', width: '27%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.tag-name',
  },
  {
    style: { paddingRight: '10px', minWidth: '22%', width: '20%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.column-name',
  },
  {
    style: { paddingRight: '10px', minWidth: '20%', width: '20%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.decimal-digits',
  },
  {
    style: { padding: '0 20px 0 10px', width: '12%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.color',
  },
  {
    style: { paddingRight: '10px', minWidth: '20%', width: '20%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.type',
  },
];

export const yAxesCfg = [
  {
    style: { paddingRight: '10px', minWidth: '20%', width: '20%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.tag-name',
  },
  {
    style: { paddingRight: '10px', minWidth: '15%', width: '15%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.show-y-axis',
  },
  {
    style: { paddingRight: '10px', minWidth: '17%', width: '17%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.y-axes-name',
  },
  {
    style: { paddingRight: '10px', minWidth: '12%', width: '12%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.y-axes-decimal-digits',
  },
  {
    style: { paddingRight: '10px', minWidth: '15%', width: '15%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.y-axes-scale',
  },
  {
    style: { paddingRight: '10px', minWidth: '11%', width: '11%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.min-value',
  },
  {
    style: { paddingRight: '10px', minWidth: '11%', width: '11%' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.max-value',
  },
];
