import React, { useState, useCallback, useMemo } from 'react';
import styles from './ExportLanguageModal.scss';
import Icon from '@components/Icon';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useSelector } from '@redux/useSelector';

function ExportLanguageModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { organizationType },
  } = props;
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [exportOption, setExportOption] = useState(
    organizationType === 'UNITRONICS_MAIN' ? 'SYSTEM_ALL' : 'ALL'
  );
  const isArAllowed = useSelector((state) => state.config.isArAllowed);

  const onSubmit = () => {
    dismiss(exportOption);
  };

  const exportOptionsMap = {
    all: {
      value: 'ALL',
      label: 'languages.all',
    },
    systemAll: {
      value: 'SYSTEM_ALL',
      label: 'languages.system-all',
    },
    systemNew: {
      value: 'SYSTEM_NOT_TRANSLATED',
      label: 'languages.system-new',
    },
    dashboard: {
      value: 'DASHBOARD',
      label: 'languages.dashboards',
    },
    event: {
      value: 'EVENT',
      label: 'languages.event-templates',
    },
    ar: {
      value: 'AR',
      label: 'languages.ar-projects',
    },
  };

  const exportOptions = useMemo(
    () =>
      organizationType === 'UNITRONICS_MAIN'
        ? [exportOptionsMap.systemAll, exportOptionsMap.systemNew]
        : [
            exportOptionsMap.all,
            exportOptionsMap.systemAll,
            exportOptionsMap.systemNew,
            exportOptionsMap.dashboard,
            exportOptionsMap.event,
            exportOptionsMap.ar,
          ].filter((option) => {
            if (isArAllowed) {
              return true;
            } else if (option.value === 'AR') {
              return false;
            }
            return true;
          }),
    []
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>languages.export-language</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.content}>
          <RadioGroup
            value={exportOption}
            classes={{ root: styles.radioGroupRoot }}
            onChange={(e) => setExportOption(e.target.value)}>
            {exportOptions.map((opt) => (
              <FormControlLabel
                key={opt.value}
                classes={{ label: styles.radioLabel }}
                value={opt.value}
                control={<Radio />}
                label={i18nService.translate(opt.label)}
              />
            ))}
          </RadioGroup>
        </div>
        <div className={styles.footerButtons}>
          <Button styles={{ width: 92, marginRight: 13 }} onClick={cancel} mode="cancel">
            {i18nService.translate('general.cancel')}
          </Button>
          <Button styles={{ width: 92 }} onClick={onSubmit}>
            {i18nService.translate('languages.export-terms-for-translation')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ExportLanguageModal;
