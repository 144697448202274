import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { UpdateAssetWidgetProps } from './UpdateAssetWidget.interface';
import store, { dispatch, getState } from '@src/redux/store';
import {
  UpdateAssetCustomization,
  WidgetUpdateAssetTag,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import Button from '@components/Button';
import Image from '@components/Image';
import { Typography } from '@material-ui/core';
import { httpService } from '@core/http/HttpService';
import SwitchEditor from './SwitchEditor/SwitchEditor';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import {
  connectedStatuses,
  getDefaultCustomization,
  getTag,
  validateFunction,
  valueToDisplay,
  valueToServer,
} from './UpdateWidget.utils';
import { setModal } from '@src/redux/widgetModals';
import { widgetMap } from '@core/canvas/widgetMap';
import { setLiveDashboardWidgetsData } from '@src/redux/liveDashboardWidgetsData';
import I18n from '@components/I18n';
import TextInput from './TextInput/TextInput';
import { getFlagStatus } from '@core/ffAndPermissions';
import BasicDatePicker from '@components/BasicDatePicker';
import { buildDateTime } from '../charts.utils';
import { releaseWidgetForEdit, updateWidgetFilters } from '../widgets.utils';
import { updateUpdateAssetWidgetsData } from '@src/redux/updateAssetWidgetsData';
import { numberFormatter } from '@core/utils';
import { number } from 'yup';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: `flex`,
    flexDirection: 'column',
    padding: '5px 25px',
    overflow: 'hidden',
  },
  widgetsWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'left',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    height: ({ customization }: any) => {
      let textHeight = 0;
      if (customization.textualRemark?.text && customization.textualRemark?.text !== '')
        textHeight += customization?.textualRemark?.fontSize;

      if (customization.requestSentText?.text && customization.requestSentText?.text !== '')
        textHeight += customization?.requestSentText?.fontSize;

      return `calc(100% - ${textHeight}px)`;
    },
    minHeight: 40,
  },
  widgetWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px 5px 0px',
    minHeight: 40,
    width: '100%',
  },
  remarkText: {
    fontSize: ({ customization }: any) => customization?.textualRemark?.fontSize,
    color: ({ customization }: any) => customization?.textualRemark?.color,
    textAlign: ({ customization }: any) =>
      customization?.textualRemark?.horizontalAlignment &&
      customization?.textualRemark?.horizontalAlignment.toLowerCase(),
  },
  requestSentText: {
    fontSize: ({ customization }: any) => customization?.requestSentText?.fontSize,
    color: ({ customization }: any) => customization?.requestSentText?.color,
    textAlign: 'left',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: ({ customization }: any) =>
      customization?.updateButtonCustomization?.horizontalAlignment === 'CENTER'
        ? 'center'
        : customization?.updateButtonCustomization?.horizontalAlignment === 'RIGHT'
        ? 'flex-end'
        : 'flex-start',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-around',
    width: '100%',
  },
  inputWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  error: {
    fontSize: 12,
    color: 'var(--formikErrorText)',
    fontWeight: 'bold',
    paddingLLeft: 5,
  },
  text: {
    fontSize: '14px',
    width: '73px',
    minWidth: '73px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const UpdateAssetWidget = (props: UpdateAssetWidgetProps) => {
  const updateAssetFlag = useMemo(() => getFlagStatus('dashboard-updates.update-asset-widget'), []);
  const [isDisabled, setIsDisabled] = useState(!updateAssetFlag) as any;
  const [selectedAsset, setSelectedAsset] = useState() as any;
  const [tagsData, setTagsData] = useState() as any;
  const [selectedAssetId, setSelectedAssetId] = useState() as any;
  const {
    widget,
    isPreview,
    widgetData,
    dashboardFilters,
    applyButtonClicked,
    defaultDecimalDigits,
    setApplyButtonClicked,
    timezone,
  } = props;
  const [initialData, setInitialData] = useState() as any;
  const { calculations } = widget || widgetData;
  const [lastAssetUpdate, setLastAssetUpdate] = useState('');

  const [widgetFilters, setWidgetFilters] = useState(props.widgetFilters);

  const decimalSeparator =
    store.getState().config?.whiteLabelDetails.numberFormatting.content.decimalSeparator;
  const thousandsSeparator =
    store.getState().config?.whiteLabelDetails.numberFormatting.content.thousandsSeparator;

  useEffect(() => {
    if (applyButtonClicked || dashboardFilters?.assetFilter?.length) {
      dashboardFilters?.assetFilter?.length > 0
        ? setWidgetFilters(() => updateWidgetFilters(dashboardFilters?.assetFilter))
        : setWidgetFilters([]);

      setApplyButtonClicked(false);
    }
  }, [applyButtonClicked]);

  const customizationFallback = useMemo(
    () =>
      isPreview ? getDefaultCustomization(props.widgetData) : ({} as UpdateAssetCustomization),
    [props.widgetData?.tags]
  );

  const customization =
    props.customization && Object.keys(props.customization).length
      ? props.customization
      : customizationFallback;
  const form = useRef() as any;

  const classes = useStyles({ isDisabled, customization });

  useEffect(() => {
    if (form.current) {
      form.current?.getFormikActions().validateForm();
    }
  }, [initialData]);

  const {
    tagsConfiguration,
    textualRemark,
    requestSentText,
    updateButtonCustomization,
    doNotRefreshWhileEditing,
  } = customization;

  const setDefaultData = async () => {
    try {
      const data =
        Array.isArray(widgetFilters) &&
        widgetFilters?.find(
          (filter) =>
            filter.valueId === 1 &&
            filter.valueType === 'ASSET_PROPERTY' &&
            (!Array.isArray(filter.values) || filter.values.length === 1)
        );
      const assetId = Array.isArray(data?.values)
        ? data?.values.length === 1
          ? data?.values[0]
          : null
        : data?.values;

      if (assetId && props.data?.results[0]) {
        setInitialData(null);
        const res: any = await httpService.api({
          type: 'getAssetInfo',
          disableBI: true,
          urlParams: {
            assetId: Array.isArray(data.values) ? data.values[0] : data.values,
          },
        });

        if (res) {
          setSelectedAsset(res);
          setSelectedAssetId(assetId);
          setLastAssetUpdate(res.updatedAt);
          const reduxData = getState().liveDashboardWidgetsData[widget?.id];
          let updateData = reduxData?.assetId === assetId ? reduxData?.data : null;

          if (!updateData || !doNotRefreshWhileEditing) {
            const result: any = await httpService.api({
              type: 'getTagsData',
              urlParams: { assetId },
              disableBI: true,
            });
            setTagsData(result?.tags);

            const relatedData =
              props.data?.results?.find((r) => r['alias_ASSET_PROPERTY_1_None'] === assetId) || {};
            updateData = props.data.columns.reduce(
              (result, c) =>
                !c.hidden
                  ? {
                      ...result,
                      [`${c.valueId}-${c.valueType}`]:
                        c.format === 'FLOAT32'
                          ? numberFormatter(relatedData[c.name], defaultDecimalDigits)
                          : relatedData[c.name],
                    }
                  : result,
              {}
            );
            updateAssetFlag &&
              dispatch(setLiveDashboardWidgetsData({ [widget.id]: { assetId, data: updateData } }));
          }

          setInitialData(updateData);
          (res.state !== 'CONNECTED' || !connectedStatuses.includes(res.status)) &&
            setIsDisabled(true);
        }
      } else if (isPreview) {
        setInitialData(null);
        const defaultData = props.data?.results[0] || {};
        const updateData = props.data.columns.reduce(
          (result, c) =>
            !c.hidden
              ? {
                  ...result,
                  [`${c.valueId}-${c.valueType}`]:
                    c.format === 'FLOAT32'
                      ? numberFormatter(defaultData[c.name], defaultDecimalDigits)
                      : defaultData[c.name],
                }
              : result,
          {}
        );
        setInitialData(updateData);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setDefaultData();
  }, [widgetFilters, props.data]);

  const ComponentByTagConfig = ({
    tagConfig,
    isDisabled,
    values,
    setFieldValue,
  }: {
    values: any;
    tagConfig: WidgetUpdateAssetTag;
    isDisabled: boolean;
    setFieldValue;
  }) => {
    const customization =
      props.customization && Object.keys(props.customization).length
        ? props.customization
        : customizationFallback;

    const data = props.data?.columns?.find(
      (c) => c.valueId === tagConfig.tagId && c.valueType === tagConfig.tagType
    );
    const mode = data?.format === 'STRING' ? 'text' : 'number';
    const name = `${data?.valueId}-${data?.valueType}`;
    let component = null;

    if (!data) {
      return null;
    }

    switch (data?.format) {
      case 'BOOLEAN':
        component = (
          <SwitchEditor
            value={values[name] === 'True' || values[name] === true}
            onChange={(e) => setFieldValue(name, e.target.checked)}
            {...customization}
            isDisabled={isDisabled || values[name] == null}
          />
        );
        break;
      case 'INT8':
      case 'UINT8':
      case 'INT16':
      case 'UINT16':
      case 'INT32':
      case 'UINT32':
      case 'FLOAT32':
      case 'STRING':
        component = !updateAssetFlag ? (
          <div>{values[name]}</div>
        ) : (
          <TextInput
            name={name}
            mode={mode}
            disabled={isDisabled || values[name] == null}
            label={tagConfig.displayName}
            value={
              data?.format === 'STRING' || data?.format === 'FLOAT32'
                ? values[name]
                : parseInt(values[name]?.toString().replace(/[^\d.-]+/g, ''))
            }
          />
        );
        break;
      case 'DATE':
      case 'DATETIME':
        component = !updateAssetFlag ? (
          <div>
            {buildDateTime(values[name], tagConfig.dateTimeFormat, 'momentFormat', timezone)}
          </div>
        ) : (
          <BasicDatePicker
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            selectedChanged={(date) => setFieldValue(name, date)}
            value={values[name]}
            timePicker={data.format === 'DATETIME'}
            height={25}
            disabled={isDisabled || values[name] == null}
            buildFormat={(val) =>
              buildDateTime(val, tagConfig.dateTimeFormat, 'momentFormat', timezone)
            }
          />
        );
        break;
      default:
        break;
    }

    return (
      <div className={classes.widgetWrapper}>
        <div className={classes.text} title={tagConfig.displayName}>
          {tagConfig.displayName}
        </div>
        <div className={classes.inputWrapper}>
          {component}
          <ErrorMessage name={name}>
            {(err: any) => (
              <I18n className={classes.error} {...(err.args || {})} element="div" noEllipsis>
                {err?.text || err}
              </I18n>
            )}
          </ErrorMessage>
        </div>
      </div>
    );
  };

  const validate = (values) => {
    const isChanged = Object.keys(values)
      .map((key) => {
        const isChanged =
          typeof values[key] == 'boolean'
            ? values[key] != (String(initialData[key]).toLowerCase() == 'true')
            : values[key] != null &&
              values[key] != undefined &&
              initialData[key] != null &&
              initialData[key] != undefined &&
              values[key].toString() !== initialData[key].toString();
        return isChanged;
      })
      .includes(true);

    if (!isChanged) return 'NotChanged'; // A little hack. I return something, so the formik treats it as not valid, but the error is not being displayed.

    return [...tagsConfiguration].reduce((res, item: any) => {
      const calculation = calculations?.find(
        (c) => c.valueId === item.tagId && c.valueType === item.tagType
      );
      const extraData =
        item?.format === 'STRING' &&
        tagsData?.find((tag) => tag[item?.tagType === 'TAG' ? 'id' : 'tagTypeId'] === item?.tagId);
      const name = `${item?.tagId}-${item?.tagType}`;
      const displayName = item.displayName;
      const format =
        tagsData?.find((tag) => tag[item?.tagType === 'TAG' ? 'id' : 'tagTypeId'] === item?.tagId)
          ?.format || item.format;
      return item &&
        validateFunction(item, format, calculation, values[name], extraData, thousandsSeparator)
        ? {
            ...res,
            [name]: validateFunction(
              item,
              format,
              calculation,
              values[name],
              extraData,
              thousandsSeparator
            ),
          }
        : res;
    }, {});
  };

  const onUpdateAssetConfirmationClosed = async (res, values, allValues) => {
    if (res) {
      setIsDisabled('updating');
      dispatch(
        setLiveDashboardWidgetsData({
          [widget?.id]: undefined,
        })
      );
      dispatch(updateUpdateAssetWidgetsData({ [widget.id]: { isWidgetUpdating: true } }));
      await httpService.api({
        type: 'updateAssetTags',
        urlParams: { assetId: selectedAssetId, widgetId: widget.id },
        data: values,
      });
      setInitialData(allValues);
      setTimeout(() => handleAfterUpdate(selectedAssetId, lastAssetUpdate), 2000);
    }
  };

  const handleAfterUpdate = useCallback((assetId: number, lastUpdated: string) => {
    return releaseWidgetForEdit(
      assetId,
      widget.id,
      lastUpdated,
      setIsDisabled,
      setLastAssetUpdate,
      Date.now()
    );
  }, []);

  const onSubmit = (values) => {
    if (isPreview) {
      return;
    }

    const { list, newVals } = Object.keys(values).reduce(
      (res, key) => {
        const tagData = getTag(key.split('-')[0], key.split('-')[1], props.data);
        const isChanged =
          typeof values[key] == 'boolean'
            ? values[key] != (String(initialData[key]).toLowerCase() == 'true')
            : values[key] != null &&
              values[key] != undefined &&
              initialData[key] != null &&
              initialData[key] != undefined &&
              values[key].toString() !== initialData[key].toString();
        const tagConfig =
          isChanged &&
          tagsConfiguration.find(
            (config) =>
              key.split('-')[0] === config.tagId.toString() && key.split('-')[1] === config.tagType
          );

        const calculation =
          isChanged &&
          calculations?.find(
            (c) => c.valueId.toString() === key.split('-')[0] && c.valueType === key.split('-')[1]
          );

        return {
          list: isChanged
            ? [
                ...res.list,
                `${tagConfig?.name} = ${valueToDisplay(
                  values[key],
                  tagData,
                  tagConfig,
                  thousandsSeparator,
                  decimalSeparator
                )}`,
              ]
            : res.list,
          newVals: isChanged
            ? [
                ...res.newVals,
                {
                  valueId: parseInt(key.split('-')[0]),
                  valueType: key.split('-')[1],
                  data: valueToServer(
                    values[key],
                    tagConfig.format,
                    calculation,
                    thousandsSeparator,
                    decimalSeparator
                  ),
                },
              ]
            : res.newVals,
        };
      },
      { list: [], newVals: [] }
    );

    newVals.length &&
      dispatch(
        setModal({
          type: 'confirm',
          onClose: (res) => onUpdateAssetConfirmationClosed(res, newVals, values),
          args: {
            text: 'widgets.update-asset.modal-text',
            iconType: 'attention_image',
            assetName: selectedAsset?.assetName,
            listText: list,
            extraText: 'widgets.update-asset.modal-extra',
            showCloseBtn: true,
            headerText: 'widgets.update-asset.modal-header',
            confirmText: 'general.confirm',
            cancelText: 'general.cancel',
            displayName: 'displayName',
          },
        })
      );
  };

  return (
    <div className={classes.wrapper}>
      {initialData &&
      (selectedAsset?.subscription?.status !== 'EXPIRED' || isPreview) &&
      widgetFilters?.length &&
      widgetFilters?.some(
        (f) =>
          f['valueType'] === 'ASSET_PROPERTY' &&
          f['values'].some((v) =>
            props?.data?.results?.some((r) => r['alias_ASSET_PROPERTY_1_None'] === v)
          )
      ) ? (
        <>
          {textualRemark?.verticalAlignment && textualRemark?.verticalAlignment == 'TOP' && (
            <div
              className={classNames(classes.remarkText, 'ellipsis-overflow')}
              title={textualRemark?.text}>
              {textualRemark?.text}
            </div>
          )}
          <Typography component="div" className={classes.widgetsWrapper}>
            <Formik
              enableReinitialize
              initialValues={cloneDeep(initialData)}
              validate={validate}
              isInitialValid={(formik: any) => !Object.keys(validate(formik.initialValues)).length}
              ref={form}
              onSubmit={onSubmit}>
              {({ isValid, values, errors, setFieldValue }) => {
                if (
                  getState().liveDashboardWidgetsData[widget?.id] &&
                  updateAssetFlag &&
                  JSON.stringify(values) !==
                    JSON.stringify(getState().liveDashboardWidgetsData[widget?.id])
                ) {
                  dispatch(
                    setLiveDashboardWidgetsData({
                      [widget?.id]: { assetId: selectedAssetId, data: values },
                    })
                  );
                }

                return (
                  <FormikForm className={classes.form}>
                    {tagsConfiguration?.map((config, idx) => (
                      <ComponentByTagConfig
                        tagConfig={config}
                        isDisabled={isDisabled}
                        values={values}
                        setFieldValue={setFieldValue}
                        key={idx}
                      />
                    ))}
                    {(isPreview || (updateAssetFlag && !isDisabled)) && (
                      <div className={classes.buttonWrapper}>
                        <Button
                          styles={{
                            width: 92,
                            color: updateButtonCustomization?.color,
                            backgroundColor: !isValid
                              ? updateButtonCustomization?.disabledColor
                              : updateButtonCustomization?.backgroundColor,
                          }}
                          type="submit"
                          disabled={!isValid}>
                          {updateButtonCustomization?.text}
                        </Button>
                      </div>
                    )}
                  </FormikForm>
                );
              }}
            </Formik>
          </Typography>
          {textualRemark?.verticalAlignment && textualRemark?.verticalAlignment == 'BOTTOM' && (
            <div
              className={classNames(classes.remarkText, 'ellipsis-overflow')}
              title={textualRemark?.text}>
              {textualRemark?.text}
            </div>
          )}
          {(isPreview || isDisabled === 'updating') && (
            <div className={classNames(classes.requestSentText, 'ellipsis-overflow')}>
              {requestSentText?.text}
            </div>
          )}
        </>
      ) : (
        <div style={{ height: '100%' }}>
          <Image
            mode="smaller"
            src={widgetMap[widget?.type]?.images.canvas}
            text={`widget.widget-empty-${
              selectedAsset?.subscription?.status === 'EXPIRED' ? 'label' : 'no-asset'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateAssetWidget;
