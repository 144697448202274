import { editorConfig } from '@core/canvas/editorConfig';

export const cellSize = editorConfig.gridCellSizePx;

export function getWidgetEdges(widgetWrapperRect: {
  left: number;
  right: number;
  top: number;
  bottom: number;
}) {
  return {
    left: widgetWrapperRect.left + cellSize,
    right: widgetWrapperRect.right - cellSize,
    top: widgetWrapperRect.top + cellSize,
    bottom: widgetWrapperRect.bottom - cellSize,
  };
}

export function getClickCoordinates(e: MouseEvent) {
  return { x: e.clientX, y: e.clientY };
}

export function getIsClickedNearWidget(
  clickCoordinates: { x: number; y: number },
  widgetEdges: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  }
) {
  const left =
    clickCoordinates.x < widgetEdges.left &&
    clickCoordinates.x >= widgetEdges.left - cellSize;
  const right =
    clickCoordinates.x > widgetEdges.right &&
    clickCoordinates.x <= widgetEdges.right + cellSize;
  const top =
    clickCoordinates.y < widgetEdges.top &&
    clickCoordinates.y >= widgetEdges.top - cellSize;
  const bottom =
    clickCoordinates.y > widgetEdges.bottom &&
    clickCoordinates.y <= widgetEdges.bottom + cellSize;

  return left || right || top || bottom;
}

export function getIsClickedInsideWidget(
  clickCoordinates: { x: number; y: number },
  widgetEdges: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  }
) {
  return (
    clickCoordinates.x >= widgetEdges.left &&
    clickCoordinates.x <= widgetEdges.right &&
    clickCoordinates.y >= widgetEdges.top &&
    clickCoordinates.y <= widgetEdges.bottom
  );
}
