import React from 'react';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import MuiExpandMoreIcon from '@components/MuiExpandMoreIcon';

import I18n from '@components/I18n';

const useStyles = makeStyles((theme: any) => ({
  panel: {
    marginBottom: 13,
    boxShadow: theme.app.boxShadowGrey,
    backgroundColor: 'var(--systemContentBackground)',
    '&:before': {
      height: 0,
    },
  },
  panelRounded: {
    height: 78,
  },
  panelDetails: {
    backgroundColor: theme.app.myAccountPanelsColor,
    width: '100%',
    borderRadius: 4,
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
  },
}));

const Panel = (props) => {
  const {
    label,
    Component,
    regionalSettingsData,
    onChangeData,
    expanded,
    data,
    defaultExpanded,
    setIsError,
  } = props;
  const classes = useStyles(props);

  return (
    <Accordion className={classes.panel} expanded={expanded} defaultExpanded={defaultExpanded}>
      <AccordionSummary className={classes.panelRounded} expandIcon={<MuiExpandMoreIcon />}>
        <I18n className={classes.label}>{label}</I18n>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.panelDetails}>
          {Component && (
            <Component
              regionalSettingsData={regionalSettingsData}
              onChangeData={onChangeData}
              data={data}
              setIsError={setIsError}
            />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Panel;
