import * as RI from '@redux/redux.interface.d';

export const deleteOrganizatioData = () => ({
  type: 'organzitions/DELETE'
});

export const setSelectedOrganization = (selectedOrganizations: any): RI.StoreAction => ({
  type: 'organzitions/SET_SELECTED_ORGANIZATION',
  payload: selectedOrganizations
});
