import React, { useState } from 'react';
import Icon from '@components/Icon';
import styles from './ViewControl.scss';
import { ViewButton, ViewControlProps } from './ViewControl.interface';

function ViewControl(props: ViewControlProps) {
  const { value, setValue } = props;
  const [buttons, setButtons] = useState<ViewButton[]>(
    [
      {
        type: 'menuOutlined',
        tooltip: 'general.list-view',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        isActive: !value,
      },
      {
        type: 'gridViewOutlined',
        tooltip: 'general.tile-view',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        isActive: value,
      },
    ]
  );

  const handleUpdate = () => {
    setButtons(
      buttons.map((button) => {
        return {
          ...button,
          isActive: !button.isActive,
        };
      })
    );
    setValue(!value);
  };

  return (
    <div className={styles.viewSwitch}>
      {buttons.map((button) => (
        <Icon
          key={button.type}
          style={{
            background: button.isActive
              ? 'var(--systemFontSelected)'
              : 'var(--systemScreenBackground)',
            borderTopLeftRadius: button.borderTopLeftRadius || 'none',
            borderBottomLeftRadius: button.borderBottomLeftRadius || 'none',
            borderTopRightRadius: button.borderTopRightRadius || 'none',
            borderBottomRightRadius: button.borderBottomRightRadius || 'none',
          }}
          color={button.isActive ? 'var(--systemScreenBackground)' : 'var(--systemFont)'}
          width={'30px'}
          height={'30px'}
          type={button.type}
          onClick={!button.isActive ? handleUpdate : null}
          tooltipText={button.tooltip}
        />
      ))}
    </div>
  );
}

export default ViewControl;
