import Table from '@components/Table';
import { TableColunm } from '@components/Table/table.interface';
import { httpService } from '@core/http/HttpService';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './AvailableTab.scss';
import AssignButton from './AssignButton/AssignButton';
import SubFilterButton from '../SubscriptionsTab/SubFilterButton/SubFilterButton';

const AvailableTab = (props) => {
  const [data, setData] = useState() as any;
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const PAGE_SIZE = useMemo(() => 35, []);
  const [subFilterButtons, setSubFilterButtons] = useState([]);
  const [ordering, setOrdering] = useState();

  const getData = async (firstInit = false, order?) => {
    try {
      setDisableLoad(true);

      const res: any = await httpService.api({
        type: 'getAvailableSubscriptions',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: PAGE_SIZE,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      });
      if (res) {
        res.next && setDisableLoad(false);
        if (firstInit) {
          setData(res.results);
          setNextPage(2);
        } else {
          const newData = data.concat(res.results);
          setData(newData);
          setNextPage(nextPage + 1);
        }
      }
    } catch (e) {}
  };

  const reloadData = () => {
    getData(true, ordering);
  };

  const getSubscriptionsPlansSummaryByType = async () => {
    try {
      const res: any = await httpService.api({
        type: 'getSubscriptionsPlansSummarByType',
        query: {
          expired: false,
          status: 'UNASSIGNED',
        },
      });

      if (res) {
        const subButtons = res
          .filter((r) => r.count)
          .map((item) => {
            return {
              label: item.name,
              count: item.count,
              key: item.id,
            };
          });
        setSubFilterButtons(subButtons);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getData(true);
    getSubscriptionsPlansSummaryByType();
  }, []);

  const LabelComponent = (props) => {
    return (
      <div
        className="ellipsis-overflow"
        style={{ minWidth: '62px' }}
        title={`${props[props.field]}`}>
        {props[props.field]}
      </div>
    );
  };

  const colunmsCfg = [
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.licensePlan',
      field: 'licensePlan',
      style: { width: 'calc(100% / 4)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.catalogNumber',
      field: 'catalogNumber',
      style: { width: 'calc(100% / 4)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.description',
      field: 'description',
      style: { width: 'calc(100% / 4)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.quantity',
      field: 'quantityUnassigned',
      style: { width: 'calc(100% / 4)' },
    },
    {
      Component: AssignButton,
      type: 'staticEnd',
      label: 'my-account-page.licensing-plan.table.assignSubscription',
      field: 'assign',
      style: { width: 150 },
      reloadData,
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.table}>
          <Table
            className={styles.table}
            data={data || []}
            getData={(firstInit, order) => {
              setOrdering(order);
              getData(firstInit, order);
            }}
            colunmsCfg={colunmsCfg}
            disabledLoad={disabledLoad}
          />
        </div>

        {subFilterButtons && subFilterButtons.length > 0 && (
          <div className={styles.statisticsContainer}>
            {subFilterButtons.map((b, index) => (
              <SubFilterButton
                key={b.key + index}
                label={b.label}
                count={b.count}
                disabled={true}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AvailableTab;
