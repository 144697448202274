import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Table from '@components/Table';
import styles from './DashboardTable.scss';
import TextField from '@components/Table/TableFields/TextField';
import DateField from '@components/Table/TableFields/DateField';
import StatusIcon from './StatusIcon';
import ActionButtons from './ActionButtons';
import LayoutMode from './LayoutMode';
import { dispatch, getState } from '@src/redux/store';
import { setDashboardSortArray } from '@src/redux/dashboardGallery';
import { dynamicSort } from '@core/utils';
import ReactResizeDetector from 'react-resize-detector';

function DashboardTable(props) {
  const { dashboards, sorted, setSorted, setSortedDashboards } = props;
  const [data, setData] = useState([]);
  const [_order, setOrder] = useState(null);
  const tableRef = useRef(null);
  const [showName, setShowName] = useState(true);
  const [actionButtonsWidth, setActionButtonsWidth] = useState(null);
  const [leftMargin, setLeftMargin] = useState(null);

  useEffect(() => {
    setData(
      dashboards
        .map((d) => {
          if (d.dash_instances.length > 1) {
            return d.dash_instances.reduce(
              (res, dash) => ({
                ...res,
                [dash.status.toLowerCase()]: dash,
                refId: d.ref,
              }),
              { draft: {}, published: {} }
            );
          }
          return d.dash_instances[0].status
            ? { [d.dash_instances[0].status.toLowerCase()]: d.dash_instances[0], refId: d.ref }
            : { draft: d.dash_instances[0], refId: d.ref };
        })
        .map((d) => {
          return d.published && d.draft
            ? {
                ...d,
                name: d.published.name,
                status: 'combined',
                createdAt: d.published.createdAt,
                updatedAt: d.published.updatedAt,
                publishedAt: d.published.updatedAt,
              }
            : d.published
            ? {
                ...d,
                name: d.published.name,
                status: 'published',
                createdAt: d.published.createdAt,
                updatedAt: d.published.updatedAt,
                publishedAt: d.published.updatedAt,
              }
            : {
                ...d,
                name: d.draft.name,
                status: 'draft',
                createdAt: d.draft.createdAt,
                updatedAt: d.draft.updatedAt,
                publishedAt: '',
              };
        })
    );
  }, [dashboards]);

  useEffect(() => {
    const dashboardSortArray = getState().dashboardGallery.dashboardSortArray;
    sorted &&
      dashboards?.length &&
      dashboardSortArray?.length &&
      setSortedDashboards(
        dashboards.sort((a, b) =>
          dashboardSortArray.indexOf(a.ref) < dashboardSortArray.indexOf(b.ref)
            ? -1
            : dashboardSortArray.indexOf(a.ref) > dashboardSortArray.indexOf(b.ref)
            ? 1
            : -1
        )
      );
    setSorted(false);
  }, [sorted]);

  const colunmsCfg = useMemo(
    () => [
      {
        Component: StatusIcon,
        field: 'status',
        label: 'general.status',
        style: { width: '100px' },
        isSortable: true,
      },
      {
        Component: TextField,
        field: 'name',
        label: 'general.name',
        style: { width: '26%' },
        isSortable: true,
      },
      {
        Component: LayoutMode,
        field: 'splitLayout',
        label: 'dashboard-editor-gallery.layout-mode',
        style: { width: '10%' },
        isSortable: false,
      },
      {
        Component: DateField,
        field: 'createdAt',
        label: 'general.date-created',
        style: { width: '7%', minWidth: '100px', marginLeft: leftMargin || '20%' },
        isSortable: true,
      },
      {
        Component: DateField,
        field: 'updatedAt',
        label: 'general.date-updated',
        style: { width: '7%', minWidth: '100px' },
        isSortable: true,
      },
      {
        Component: DateField,
        field: 'publishedAt',
        label: 'general.date-published',
        style: { width: '7%', minWidth: '100px' },
        isSortable: true,
      },
      {
        Component: ActionButtons,
        field: '',
        style: {
          width: actionButtonsWidth || '25%',
          minWidth: '120px',
          maxWidth: '25%',
          position: 'relative',
          marginLeft: '20px',
        },
        showName: showName,
      },
    ],
    [showName, actionButtonsWidth, leftMargin]
  );

  const statusToNumberConverter = (status: string): number => {
    return ['published', 'combined', 'draft'].indexOf(status);
  };

  const orderData = (order?: any) => {
    setSorted(true);
    let converter = null;
    if (order.label === 'status') converter = statusToNumberConverter;

    return data.sort(dynamicSort(order.label, order.orderSign, converter));
  };

  useEffect(() => {
    sorted && dispatch(setDashboardSortArray(data.map((dashboard) => dashboard.refId)));
  }, [sorted]);

  const updateShowNameAndWidth = () => {
    const tableWidth = tableRef.current.offsetWidth;
    if (tableRef.current) {
      switch (true) {
        case tableWidth < 1500 && tableWidth >= 1420:
          setShowName(true);
          setLeftMargin('12%');
          break;
        case tableWidth < 1420 && tableWidth >= 1300:
          setShowName(true);
          setLeftMargin('8%');
          break;
        case tableWidth < 1300 && tableWidth >= 1200:
          setShowName(true);
          setLeftMargin('5%');
          setActionButtonsWidth('40%');
          break;
        case tableWidth < 1200:
          setShowName(false);
          setLeftMargin('30px');
          setActionButtonsWidth('120px');
          break;
        default:
          setShowName(true);
          setLeftMargin(null);
          setActionButtonsWidth('25%');
      }
    }
  };

  return (
    <>
      <div className={styles.tableWrapper} ref={tableRef}>
        <ReactResizeDetector handleHeight handleWidth onResize={updateShowNameAndWidth} />
        <Table
          data={data}
          getData={(firstInit, order) => {
            order && setOrder(order);
            orderData(order);
          }}
          colunmsCfg={colunmsCfg}
        />
      </div>
    </>
  );
}

export default DashboardTable;
