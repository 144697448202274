import * as RI from '../../redux.interface.d';
import { aHandlers } from './asset.handlers';
import * as cloneDeep from 'lodash/cloneDeep';

const initialState = (): RI.AssetState => ({
  assetsData: [],
  totalCount: 0,
  resultsCount: 0,
  refresh: false
});

export default function assetReducer(
  state: RI.AssetState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'asset/SET_ASSETS_DATA':
      return {
        ...state,
        assetsData: payload.assetsData,
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount
      };
    case 'asset/ADD_ASSETS_DATA':
      return {
        ...state,
        assetsData: state.assetsData.concat(payload.assetsData),
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount
      };
    case 'deviceManagment/SET_ASSETS_DATA_TO_FIRST':
      return {
        ...state,
        assetsData: [payload, ...state.assetsData],
        totalCount: state.totalCount + 1,
        resultsCount: state.resultsCount + 1
      };
    case 'asset/UPDATE_ASSETS_DATA':
      return aHandlers.updateAssetsData(state, payload);
    case 'asset/REMOVE_ASSET':
      const assetsData = cloneDeep(state.assetsData);
      assetsData.splice(payload, 1);
      return {
        ...state,
        assetsData,
        totalCount: state.totalCount - 1,
        resultsCount: state.resultsCount - 1
      };
    case 'asset/REFRESH_ASSETS_DATA':
      return { ...state, refresh: !state.refresh };
    case 'asset/REMOVE_ALL_ASSETS_DATA':
      return initialState();
    default:
      return state;
  }
}
