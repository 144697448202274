import {
  stepOneValidation,
  stepTwoValidation,
  stepThreeValidation,
  stepFourValidation,
  stepFiveValidation
} from './widgetStepValidation';

export const widgetStepValidationMap = {
  1: stepOneValidation,
  2: stepTwoValidation,
  3: stepThreeValidation,
  4: stepFourValidation,
  5: stepFiveValidation
};
