import React from 'react';
import { widgetMap } from '@core/canvas/widgetMap';
import Icon from '@components/Icon';
import styles from './ThumbnailWidget.scss';
import classNames from 'classnames';
import I18n from '@components/I18n';

function ThumbnailWidget(props) {
  const { widgetType, isDisabled, isRegularWidget, theme, index } = props;
  const widget = props.widgetType === 'empty' ? props.widgetType : widgetMap[widgetType];
  const borderStyle = '1px solid var(--widgetsBorderHeader)';

  return (
    <>
      {widget === 'empty' ? (
        <div
          className={theme ? styles[`thumbnailWidget${theme}`] : styles.thumbnailWidget}
          style={{ background: 'transparent', border: 'null' }}></div>
      ) : (
        <div
          className={classNames(
            theme ? styles[`thumbnailWidget${theme}`] : styles.thumbnailWidget,
            widgetType === 'userFilters' && styles.pointer,
            isRegularWidget && styles.grab
          )}
          style={
            isRegularWidget
              ? {
                  borderTop: index === 1 && borderStyle,
                  borderBottom: borderStyle,
                  borderLeft: borderStyle,
                  borderRight: borderStyle,
                  borderRadius: 0,
                }
              : isDisabled
              ? { opacity: 0.4 }
              : {}
          }>
          <Icon
            color={'hsl(from var(--systemFont) h s l / 0.5)'}
            type={widget && widget.images.thumbnail}
            className={styles.icon}
            style={{ opacity: isDisabled && isRegularWidget && 0.4 }}
          />
          <I18n
            element="div"
            style={{
              opacity: isDisabled && isRegularWidget && 0.4,
            }}>{`edit-dashboard.widget-name.${widget?.id}`}</I18n>
        </div>
      )}
    </>
  );
}

export default ThumbnailWidget;
