import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import LinkOff from '@material-ui/icons/LinkOff';
import { IconButton } from '@material-ui/core';
import CanvasWidget from '@components/canvas/CanvasWidget';
import { editorConfig } from '@core/canvas/editorConfig';
import { canvasService } from '@core/canvas/CanvasService';
import { isWidgetGroup, getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import CanvasWidgetActionBar from '../CanvasWidgetActionBar';
import CanvasWidgetResizeIndicators from '../CanvasWidgetResizeIndicators';
import CanvasWidgetGroupItem from '../CanvasWidgetGroupItem';
import styles from './CanvasWidgetWrapper.scss';
import { CanvasWidgetWrapperProps } from './CanvasWidgetWrapper.interface';
import { getWidgetData } from '@pages/GetWidgetDataService';
import { useSelector } from 'react-redux';
import { widgetMap } from '@core/canvas/widgetMap';
import { usePrevious } from '@core/hooks/usePrevious';
import { CanvasWidget as CanvasWidgetInterface } from '@src/redux/redux.interface';
import { dispatch } from '@src/redux/store';
import { clearWidgetSelection } from '@src/redux/dashboardEditor';
import {
  cellSize,
  getClickCoordinates,
  getIsClickedInsideWidget,
  getIsClickedNearWidget,
  getWidgetEdges,
} from './CanvasWidgetWrapper.utils';

/**
 * Wrapps a widget on the canvas.
 * Is in charge of drag-and-drop and resize behaviour.
 */
function CanvasWidgetWrapper(props: CanvasWidgetWrapperProps) {
  const {
    widget,
    isHidden,
    previewData,
    setPreviewData,
    selectedWidgets,
    setSelectedWidgets,
    addRefToCanvasWidget,
    isWidgetResized,
    setIsWidgetResized,
    selectedWidgetIdsWithError,
    setSelectedWidgetIdsWithError,
    applyButtonClicked,
    setApplyButtonClicked,
  } = props;
  const { id, x, y, h, w, type, hideWidgetName, isSelected } = widget;
  const ref = useRef<HTMLDivElement>(null);
  const isGroup = isWidgetGroup(widget);
  const prevWidget: CanvasWidgetInterface = usePrevious(widget);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isDraft = useMemo(() => widget.status === 'DRAFT', [widget]);

  const {
    organizationsFilter,
    assetTypesFilter,
    assetFilter,
    geoFilter,
    dateFilter,
    dateFilterFrom,
    dateFilterTo,
  } = useSelector(({ dashboardEditor }: any) => dashboardEditor);

  const getWidgetDataFromServer = () => {
    (!widget.status || widget.status.toUpperCase() === 'PUBLISHED') &&
      !widget.isIdTemp &&
      getWidgetData({
        widgetId: id,
        widgetType: widget.type,
        dashboardFilters: {
          organizationsFilter: organizationsFilter.map((f) => f.id),
          assetTypesFilter: assetTypesFilter.map((f) => f.id),
          assetFilter: assetFilter.map((f) => f.id),
          geoFilter,
          dateFilter,
          dateFilterFrom,
          dateFilterTo,
        },
        widgetFilters: [],
      })
        .then((res) => setPreviewData(id, res))
        .catch(() => setPreviewData(id, {}));
  };

  useEffect(() => {
    getConfigValueFromWidgetSettings(widget.type, 'dataChangesToHandle') &&
      !getConfigValueFromWidgetSettings(widget.type, 'getWidgetDataOnlyInPreview') &&
      getWidgetDataFromServer();
  }, [organizationsFilter, assetTypesFilter, geoFilter, dateFilter, dateFilterFrom, dateFilterTo]);

  useEffect(() => {
    prevWidget?.isIdTemp && !widget.isIdTemp && widget.type != 'image' && getWidgetDataFromServer();
  }, [widget]);

  useEffect(() => {
    if (!widget.isIdTemp) {
      addRefToCanvasWidget({ ...widget, ref: ref });
    }
  }, []);

  useEffect(() => {
    setSelectedWidgetIdsWithError && setSelectedWidgetIdsWithError([]);
  }, [x, y, w, h, isSelected]);

  const wrapperS = useMemo(
    () => ({
      top: y * cellSize,
      left: x * cellSize,
      height: h * cellSize,
      width: w * cellSize,
    }),
    [x, y, w, h, hideWidgetName]
  );

  const startDrag = useCallback(
    (e) => {
      const widgetWrapperRect = ref.current.getBoundingClientRect();
      const widgetEdges = getWidgetEdges(widgetWrapperRect);
      const clickCoords = getClickCoordinates(e);

      const isClickedNearWidget = getIsClickedNearWidget(clickCoords, widgetEdges);

      if (e.button !== 0 || isClickedNearWidget) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      canvasService.startDragging({
        element: ref.current,
        widgetType: type,
        canvasWidget: widget,
        pageX: e.pageX,
        pageY: e.pageY,
        widgetSettings: widgetMap[type].settings,
      });
    },
    [widget]
  );

  function onClick(e) {
    if (!isWidgetResized) {
      const widgetWrapperRect = ref.current.getBoundingClientRect();
      const widgetEdges = getWidgetEdges(widgetWrapperRect);
      const clickCoords = getClickCoordinates(e);

      const isClickedNearWidget = getIsClickedNearWidget(clickCoords, widgetEdges);
      const isClickedInsideWidget = getIsClickedInsideWidget(clickCoords, widgetEdges);

      if (isClickedNearWidget && !e.ctrlKey) {
        dispatch(clearWidgetSelection());
      }

      if (isClickedInsideWidget) {
        canvasService.selectWidget(widget, { isMultiSelect: e.ctrlKey });
        let newSelectedWidgets = e.ctrlKey ? selectedWidgets : [];
        !newSelectedWidgets.some((sw) => sw.id === id) && newSelectedWidgets.push(widget);
        if (newSelectedWidgets.length && isSelected && e.ctrlKey) {
          newSelectedWidgets = newSelectedWidgets.filter((sw) => sw.id !== id);
          canvasService.unselectWidget(widget);
        }
        setSelectedWidgets(newSelectedWidgets);
      }
    } else {
      setIsWidgetResized(false);
    }
  }

  const ungroup = useCallback(() => {
    canvasService.removeGroup(widget);
  }, [widget]);

  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  if (isHidden) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      className={classNames(
        styles.wrapper,
        isGroup ? styles.group : undefined,
        isGroup && isSelected
          ? selectedWidgetIdsWithError.includes(id)
            ? styles.selectedWithError
            : styles.selected
          : undefined
      )}
      style={wrapperS}
      ref={ref}
      onMouseDown={startDrag}
      onClick={onClick}>
      <div style={{ height: '100%' }}>
        {isGroup ? (
          <>
            <IconButton
              id={styles.ungroupBtn}
              onMouseDownCapture={stopPropagation}
              onClick={ungroup}>
              <LinkOff />
            </IconButton>
            {widget.children.map((child) => (
              <CanvasWidgetGroupItem key={child.id} parent={widget} widget={child} />
            ))}
          </>
        ) : (
          <div
            className={classNames(
              styles.inner,
              isSelected
                ? selectedWidgetIdsWithError.includes(id)
                  ? styles.selectedWithError
                  : styles.selected
                : undefined
                ? styles.selected
                : undefined
            )}>
            <CanvasWidgetResizeIndicators
              widgetRef={ref}
              widget={widget}
              setIsWidgetResized={setIsWidgetResized}
            />
            <CanvasWidgetActionBar
              isDraft={isDraft}
              widget={widget}
              widgetRef={ref}
              isMouseOverWidget={isMouseOver}
            />
            <CanvasWidget
              widgetType={type}
              widget={widget}
              data={previewData}
              setData={setPreviewData}
              isDraft={isDraft}
              isPointerEventsDisabled={true}
              dashboardFilters={{
                organizationsFilter: organizationsFilter.map((f) => f.id),
                assetTypesFilter: assetTypesFilter.map((f) => f.id),
                assetFilter: assetFilter.map((f) => f.id),
                geoFilter,
                dateFilter,
                dateFilterFrom,
                dateFilterTo,
              }}
              applyButtonClicked={applyButtonClicked}
              setApplyButtonClicked={setApplyButtonClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(CanvasWidgetWrapper);
