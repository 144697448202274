import I18n from '@components/I18n';
import MaterialCheckbox from '@components/Checkbox';
import { isEqual } from 'lodash';
import MultiSelect from '@components/MultiSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Audience.scss';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import Icon from '@components/Icon';
import { modalService } from '@core/modals/ModalService';
import { ADMIN_GROUP } from '@core/utils';

function Audience(props) {
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const { templateData, setTemplateData } = props;
  const { sendToAdminGroup, allowUnselectAdminGroup, notificationGroupsIds } = templateData;
  const [customUserGroups, setCustomUserGroups] = useState(null);

  const userGroups = useMemo(() => {
    let groups = [
      {
        value: 'ADMINS',
        label: ADMIN_GROUP,
      },
    ];

    if (customUserGroups) {
      groups = groups.concat(
        customUserGroups
          .map((g) => ({
            value: g.id,
            label: g.name,
          }))
          .sort((g) => g.label)
      );
    }

    return groups;
  }, [customUserGroups]);

  useEffect(() => {
    const getNotificationGroups = async () => {
      const res: any = await httpService.api({
        type: 'getNotificationGroups',
        query: {
          p: 1,
          ps: 1000,
        },
      });
      if (res) {
        setCustomUserGroups(res.results);
      }
    };

    getNotificationGroups();
  }, []);

  useEffect(() => {
    if (customUserGroups) {
      let newSelectedUserGroups = [];
      if (sendToAdminGroup)
        newSelectedUserGroups.push({
          value: 'ADMINS',
          label: ADMIN_GROUP,
        });
      newSelectedUserGroups = newSelectedUserGroups.concat(
        customUserGroups
          ?.filter((g) => notificationGroupsIds?.some((n) => n === g.id))
          .map((g) => ({
            value: g.id,
            label: g.name,
          }))
          .sort((g) => g.label)
      );
      setSelectedUserGroups(newSelectedUserGroups);
    }
  }, [notificationGroupsIds, sendToAdminGroup, customUserGroups]);

  const onGroupsSelectionChanged = (values) => {
    const isAdminChecked = values.some((v) => v.value === 'ADMINS');
    if (sendToAdminGroup != isAdminChecked) setTemplateData(isAdminChecked, 'sendToAdminGroup');

    const newNotificationGroupsIds = values.filter((v) => v.value !== 'ADMINS').map((v) => v.value);

    if (!isEqual(newNotificationGroupsIds, notificationGroupsIds)) {
      setTemplateData(newNotificationGroupsIds, 'notificationGroupsIds');
    }

    if (!isAdminChecked && sendToAdminGroup) setTemplateData(false, 'allowUnselectAdminGroup');

    setSelectedUserGroups(values);
  };

  const onAddUserGroupClicked = useCallback(async () => {
    const res = await modalService.openModal('addUserGroupModal', {
      customUserGroups: [...customUserGroups, { name: 'Admins', id: 'ADMINS' }],
    });
    if (res && res.name) {
      const newGroup: any = await httpService.api({
        type: 'addNotificationGroup',
        data: { name: res.name },
      });
      if (newGroup) {
        const newNotificationGroups = [...customUserGroups];
        newNotificationGroups.push(newGroup);
        setCustomUserGroups(newNotificationGroups);
      }
    }
  }, [customUserGroups]);

  return (
    <div className={styles.section}>
      <div className={styles.rowWithCheckbox}>
        <div className={styles.header}>
          <I18n>events-template.audience.user-groups</I18n>
        </div>
        <div className={styles.userGroups}>
          <div className={styles.multiSelect}>
            <MultiSelect
              id="null"
              options={userGroups}
              values={userGroups.filter((opt) =>
                selectedUserGroups.some((op) => op.value === opt.value)
              )}
              getOptionLabel={(i) => i18nService.translate(i.label)}
              getOptionValue={(i) => i.value}
              optionType="checkbox"
              placeholder="events-template.audience.user-groups.select"
              onChange={(values) => onGroupsSelectionChanged(values)}
              maxMenuHeight={380}
              showPlaceholderOnFocus
            />
          </div>
          <Icon type="create_new" className={styles.icon} onClick={() => onAddUserGroupClicked()} />
        </div>
      </div>
      {sendToAdminGroup && (
        <div className={styles.row}>
          <MaterialCheckbox
            color="primary"
            onChange={(e) => setTemplateData(!allowUnselectAdminGroup, 'allowUnselectAdminGroup')}
            checked={allowUnselectAdminGroup || false}
          />
          <I18n element="div" className={styles.checkboxText} admins={ADMIN_GROUP}>
            events-template.audience.user-groups.alow-unselect-admins
          </I18n>
        </div>
      )}
    </div>
  );
}

export default Audience;
