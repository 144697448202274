import { LinearGaugeCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface.d';
import {
  getDefaultGaugeCustomization,
  changeWidgetData,
  gaugeSidesOptions,
} from '../GaugeCustomizationUtils';
import { GaugeCustomizationSectionProps } from '../GaugeCustomizationSection/GaugeCustomizationSection.interface';
import { hasValue, getVar } from '@core/canvas/widget.utils';

export const getDefaultLinearGaugeCustomization = (defaultDecimalDigits: number): LinearGaugeCustomization => {
  const defaultCustomization = getDefaultGaugeCustomization(defaultDecimalDigits);
  return {
    ...defaultCustomization,
    needleColor: getVar('widgetsFont'),
    needleSize: 3,
    needleSide: 'LEFT',
    backgroundColor: getVar('widgetsBackground'),
    barWidth: 10,
    ticks: { ...defaultCustomization.ticks, side: 'LEFT', valueSide: 'LEFT' },
  };
};

export const getBackgroundColorConfig = (
  customization: LinearGaugeCustomization,
  setWidgetData
): GaugeCustomizationSectionProps[] => {
  const { backgroundColor } = customization;

  return [
    {
      sectionLabel: 'background-color',
      sectionValue: backgroundColor,
      onChange: (val) => changeWidgetData('backgroundColor', val, setWidgetData),
      type: 'color',
      fields: [],
    },
  ];
};

export const getLinearNeedleConfig = (
  customization: LinearGaugeCustomization,
  setWidgetData
): GaugeCustomizationSectionProps[] => {
  const { needleVisible, needleColor, needleSide, needleSize } = customization;

  return [
    {
      sectionLabel: 'needle-definitions',
      fields: [
        {
          label: 'visible',
          value: needleVisible,
          onChange: (val) => changeWidgetData('needleVisible', val, setWidgetData),
          type: 'checkbox',
        },
        {
          label: 'side',
          value: gaugeSidesOptions.find((o) => o.value === needleSide),
          optionsForSelect: gaugeSidesOptions,
          onChange: (val) => changeWidgetData('needleSide', val, setWidgetData),
          type: 'select',
          disabled: !needleVisible,
        },
        {
          label: 'size',
          value: needleSize,
          onChange: (val) => {
            if (!hasValue(val) || (hasValue(val) && +val < 1)) return;
            changeWidgetData('needleSize', val, setWidgetData);
          },
          type: 'number',
          disabled: !needleVisible,
        },
        {
          label: 'color',
          value: needleColor,
          onChange: (val) => changeWidgetData('needleColor', val, setWidgetData),
          type: 'color',
          disabled: !needleVisible,
        },
      ],
    },
  ];
};

export const getBarConfig = (
  customization: LinearGaugeCustomization,
  setWidgetData
): GaugeCustomizationSectionProps[] => {
  const { barWidth } = customization;

  return [
    {
      sectionLabel: 'bar-width',
      sectionValue: barWidth,
      onChange: (val) => {
        if (hasValue(val) && (+val < 0 || +val > 20)) return;
        changeWidgetData('barWidth', val, setWidgetData);
      },
      type: 'number',
      fields: [],
    },
  ];
};
