import * as RI from '../../redux.interface';
import * as cloneDeep from 'lodash/cloneDeep';

class ATHandlers {
  editAssetStatusById(state: RI.AssetTypeState, id: number, status: string) {
    return {
      ...state,
      assetsTypeData: state.assetsTypeData.map(at => {
        if (at.id === id) {
          return { ...at, status };
        }
        return at;
      })
    };
  }

  updateAssetsTypeData(state: RI.AssetTypeState, payload: any) {
    const changes = payload.data;
    const assetsTypeData = cloneDeep(state.assetsTypeData);
    const rowData = assetsTypeData[payload.rowIdx];
    for (const key in changes) {
      rowData[key] = changes[key];
    }

    return {
      ...state,
      assetsTypeData
    };
  }
}

export const atHandlers = new ATHandlers();
