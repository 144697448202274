import React, { useRef } from 'react';
import styles from './InfoSection.scss';
import I18n from '@components/I18n';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import { colorMap } from '@core/statusColorMap';

function InfoSection(props) {
  const { dataToDisplay } = props;
  const assetIdRef = useRef(null);

  const assetStatus = dataToDisplay.find(
    (d) => d.valueType === 'ASSET_PROPERTY' && d.valueId === 5
  )?.value;

  const assetName = dataToDisplay.find(
    (d) => d.valueType === 'ASSET_PROPERTY' && d.valueId === 2
  )?.value;

  const assetId = dataToDisplay.find(
    (d) => d.valueType === 'ASSET_PROPERTY' && d.valueId === 1
  )?.value;

  const assetNameMaxWidth = assetIdRef?.current ? 250 - assetIdRef?.current?.offsetWidth : 190;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.status}
        title={i18nService.translate(`enum.${assetStatus.toUpperCase()}`)}
        style={{
          backgroundColor: colorMap[assetStatus],
        }}
      />
      <div className={styles.assetNameWrapper} style={{ maxWidth: assetNameMaxWidth }}>
        <I18n style={{ minWidth: 'fit-content', fontWeight: 'bold' }}>{'general.name'}</I18n>:
        <div className={classNames(styles.assetName, 'ellipsis-overflow')} title={assetName}>
          {assetName}
        </div>
      </div>
      <div ref={assetIdRef} className={styles.assetIdWrapper}>
        <I18n element={'div'} style={{ minWidth: 'fit-content', fontWeight: 'bold' }}>
          {'general.id'}
        </I18n>
        :
        <div className={classNames(styles.assetId, 'ellipsis-overflow')} title={assetId}>
          {assetId}
        </div>
      </div>
    </div>
  );
}

export default InfoSection;
