import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { httpService } from '@core/http/HttpService';
import FormikInput from '@components/Formik/FormikInput';
import FormikPlcInput from '@components/Formik/FormikPlcInput';
import FormikTextarea from '@components/Formik/FormikTextarea';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { AssetTypeOption, AssetTypesModel, AssetModel } from './createAssetModal.interface';
import styles from './CreateAssetModal.scss';
import CreateAssetModalSelect from './CreateAssetModalSelect';
import { useSelector } from '@src/redux/useSelector';
import FormikRouterInput from '@components/Formik/FormikRouterInput';
import { modalService } from '@core/modals/ModalService';
import { dynamicSort } from '@core/utils';
import { staticDictionary } from '@core/i18n/dictionary';
import { i18nService } from '@core/i18n/I18nService';
import FormikLabel from '@components/Formik/FormikLabel';
import Icon from '@components/Icon';

const initialValues: AssetModel = {
  assetType: null,
  plcSerial: { id: null, text: null, message: null, type: null },
  assetName: '',
  assetSerial: '',
  routerSerial: { id: null, text: null, message: null },
  comments: '',
  ar: null,
};

function validate(value: string) {
  if (!value) {
    return 'validations.mandatory';
  } else {
    return undefined;
  }
}

const validationSchema = Yup.object().shape({
  assetType: Yup.object().nullable().required('validations.required'),
  assetName: Yup.string().max(50, 'create-asset-modal.max50-chars'),
  assetSerial: Yup.string().max(15, 'create-asset-modal.max15-chars'),
  comments: Yup.string().max(500, 'create-asset-modal.max500-chars'),
});

function CreateAssetModal(props: ModalComponentProps) {
  const { dismiss } = props;
  const [assetTypes, setAssetTypes] = useState<AssetTypeOption[]>([]);
  const [arProjects, setArProjects] = useState<any[]>([]);
  const [selectedAssetType, setSelectedAssetType] = useState<any>();
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const isArAllowed = useSelector((state) => state.config.isArAllowed);
  const assetTypeOptions = useMemo(() => assetTypes?.sort(dynamicSort('label', '+')), [assetTypes]);
  const arProjectsOptions = useMemo(() => arProjects?.sort(dynamicSort('name', '+')), [arProjects]);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const onSubmit = useCallback(
    async (values: AssetModel) => {
      if (
        (values.assetType.plcType === 'LEGACY' || values.assetType.plcType === 'MODBUS') &&
        (!values.routerSerial || !values.routerSerial.id)
      ) {
        modalService
          .openModal('confirm', {
            iconType: 'attention_image',
            text: 'create-asset-modal.confirm.unconnected-router-message',
            confirmText: 'general.confirm',
            cancelText: 'general.cancel',
            headerText: 'create-asset-modal.confirm.connect-router-header',
            showCloseBtn: true,
          })
          .then(async (confirm) => {
            if (confirm) {
              submitAndCloseModal(values);
            }
          });
      } else {
        submitAndCloseModal(values);
      }
    },
    [dismiss]
  );

  const submitAndCloseModal = async (values) => {
    let newValues = {
      ...values,
    };
    if (values.assetType.plcType === 'MODBUS' && !values.plcSerial.id) {
      await httpService
        .api({
          type: 'createNonUnitronicsPlc',
          data: {
            plcType: values.assetType.plcType,
            plcSerial: values.plcSerial.text,
            arProjectId: values.ar ? values.ar.value : null,
          },
        })
        .then((res: any) => {
          newValues = {
            ...values,
            plcSerial: {
              ...values.plcSerial,
              id: res.id,
            },
          };
        });
    }

    const data = {
      assetName: newValues.assetName,
      assetTypeId: newValues.assetType.value,
      plcId: newValues.plcSerial.id,
      routerId:
        (newValues.routerSerial && !newValues.routerSerial.disabled && newValues.routerSerial.id) ||
        null,
      arProjectId: newValues.ar ? newValues.ar.value : null,
    };
    newValues.comments.trim() !== '' && (data['comments'] = newValues.comments);
    newValues.assetSerial.trim() !== '' && (data['assetSerial'] = newValues.assetSerial);
    dismiss({ data, plcType: newValues.assetType.plcType, routerId: newValues?.routerSerial?.id });
  };

  const plcTypeList = useMemo(() => {
    const selectedOptionsDisplay = [];
    if (selectedAssetType) {
      selectedOptionsDisplay.push({
        label: 'create-asset-modal.plc.type',
        value:
          staticDictionary[`enum.${selectedAssetType.plcType}`] ||
          i18nService.translate(`enum.${selectedAssetType.plcType}`),
      });

      if (selectedAssetType.model) {
        selectedOptionsDisplay.unshift({
          label: 'create-asset-modal.model',
          value: selectedAssetType.model,
        });
      }
    }

    return selectedOptionsDisplay;
  }, [selectedAssetType]);

  useEffect(() => {
    httpService
      .api({
        type: 'getAssetTypeOptions',
        urlParams: { organizationId: selectedMBId || undefined },
      })
      .then((types: AssetTypesModel) => {
        setAssetTypes(
          types.assetTypes.map<AssetTypeOption>((t) => ({
            value: t.id,
            label: t.name,
            model: t.model,
            plcType: t.plcType,
          }))
        );
      });
  }, []);

  useEffect(() => {
    if (isArAllowed && selectedAssetType) {
      httpService
        .api({
          type: 'getARProjectByAssetType',
          urlParams: { assetTypeId: selectedAssetType.value },
        })
        .then((res: any) => {
          res?.length && setArProjects(res.map((r) => ({ value: r.id, label: r.name })));
        });
    }
  }, [selectedAssetType]);

  const isValidFormExceptRouterSerial = (isValidForm, errors) => {
    return isValidForm || (Object.keys(errors).length == 1 && errors.routerSerial);
  };

  const updateRouter = (plc, setFieldValue) => {
    if (plc && (plc.type === 'LEGACY' || plc.type === 'MODBUS') && plc.router?.id) {
      setFieldValue('routerSerial', {
        id: plc.router?.id,
        text: plc.router?.serialNumber,
        catalogNumber: plc.router?.catalogNumber,
        message: null,
        disabled: true,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={'modalHeader'}>
        <I18n>create-asset-modal.title</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isValid, values, errors, setFieldValue }) => (
          <Form className={styles.modalForm}>
            <div className={styles.modalContent}>
              <CreateAssetModalSelect
                name={'assetType'}
                options={assetTypeOptions}
                label={'create-asset-modal.asset-type'}
                setState={setSelectedAssetType}
                className={styles.marginBottom}
                isMandatory={true}
              />
              {selectedAssetType &&
                plcTypeList &&
                plcTypeList.map((plcType, idx) => {
                  return (
                    <FormikLabel
                      key={idx}
                      mode={'thin'}
                      label={plcType.label}
                      value={plcType.value}
                      paddingLeft={{ paddingLeft: '7px' }}
                    />
                  );
                })}
              {values.assetType && (
                <>
                  <div className={styles.plcSerialContainer}>
                    <FormikPlcInput
                      name={'plcSerial'}
                      label={'plc-validation.plc-serial'}
                      type={'associateToAsset'}
                      plcType={values.assetType.plcType === 'MODBUS' ? 'MODBUS' : 'UNITRONICS'}
                      onSucceedValidation={(values, res) => updateRouter(res, setFieldValue)}
                      allowRowsEllipsis
                      setCatalogNumberOnGenerate
                      assetPlcType={values.assetType.plcType}
                      className={styles.marginBottom}
                    />
                  </div>
                  {values.plcSerial && !errors.plcSerial && (
                    <>
                      <FormikInput
                        name={'assetName'}
                        label={'create-asset-modal.asset-name'}
                        className={styles.marginBottom}
                        maxLength={50}
                        validate={validate}
                        isRequired
                      />
                      <FormikInput
                        name={'assetSerial'}
                        label={'create-asset-modal.asset-serial'}
                        className={styles.marginBottom}
                        maxLength={15}
                      />
                      {isArAllowed && (
                        <CreateAssetModalSelect
                          name={'ar'}
                          options={arProjectsOptions}
                          label={'create-asset-modal.AR-project'}
                          className={styles.marginBottom}
                          isMandatory={false}
                        />
                      )}
                      <div className={styles.commentContainer}>
                        <FormikTextarea
                          name={'comments'}
                          label={'create-asset-modal.comments'}
                          className={styles.marginBottom}
                          maxLength={500}
                        />
                      </div>
                      {(values.assetType.plcType === 'LEGACY' ||
                        values.assetType.plcType === 'MODBUS') && (
                        <FormikRouterInput
                          name={'routerSerial'}
                          label={'router-validation.router-serial'}
                          type={'associateToAsset'}
                          isRequired={false}
                          value={values.routerSerial}
                          disabled={values.routerSerial['disabled']}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {errors.plcSerial && errors.plcSerial.message && (
                <div className={styles.serverErrorMsg}>
                  <ErrorRoundedIcon className={styles.errorIcon} />
                  <span>{errors.plcSerial.message}</span>
                </div>
              )}
            </div>
            <div className={styles.modalButtons}>
              <Button
                styles={{ marginLeft: 13 }}
                mode={['cancel', 'bigFont']}
                // disabled={isSubmitting}
                onClick={cancel}>
                <I18n>general.cancel</I18n>
              </Button>
              <Button
                styles={{ marginLeft: 13 }}
                mode={'bigFont'}
                type={'submit'}
                disabled={!isValidFormExceptRouterSerial(isValid, errors)}>
                <I18n>general.save</I18n>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateAssetModal;
