import * as RI from '../redux.interface.d';

const initialState = (): RI.OrganizationsState => ({
  selectedOrganizationsId: null,
  selectedMBId: null,
  name: null,
  type: null,
  website: null,
  address: null,
  parentCompany: null,
  status: null,
  rootRelatedOrg: null,
  erpInstanceId: null,
  erpCustomerId: null,
  timezone: null,
  defaultLanguage: null,
  dashboard: null,
  user: null,
  licensingType: null,
  subscriptionManagerOrg: null,
  mfaNullAllowed: false,
  mfaMode: null,
  mfaMethod: null,
});

export default function organizationsReducer(
  state: RI.OrganizationsState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'organzitions/SET_SELECTED_ORGANIZATION':
      return {
        ...state,
        ...payload,
      };
    case 'organzitions/DELETE':
      return {};
    default:
      return state;
  }
}
