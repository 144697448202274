import React, { useEffect, useState } from 'react';
import en from './en.json';

import { PhoneNumberInputProps } from './PhoneNumberInput.interface';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { i18nService } from '@core/i18n/I18nService';
import { makeStyles } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import styles from './PhoneNumberInput.scss';
import { getCountryByGoogleId } from './PhoneNumberInput.utils';

const useStyles = makeStyles({
  containerClass: {
    border: '1px solid #cacaca',
    borderRadius: '5px',
    backgroundColor: 'var(--white1)',
    '&.react-tel-input .form-control': {
      border: 'none',
      boxShadow: 'none',
    },
  },
  buttonClass: {
    border: '1px',
    backgroundColor: 'transparent',
  },
  inputClass: {
    color: 'var(--systemFont)',
  },
});

const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const classes = useStyles(props);
  const { value, className, width, fontSize, onChange, values, fieldName, ...restProps } = props;
  const { address } = values || {};
  const googleLocationId = useSelector(
    (state: any) => state.config?.organizationDetails?.googleLocationId
  );
  const [country, setCountry] = useState('us');

  useEffect(() => {
    if (!value) {
      values
        ? !values[fieldName] &&
          getCountryByGoogleId(address?.googleId ? address.googleId : googleLocationId, setCountry)
        : getCountryByGoogleId(googleLocationId, setCountry);
    }
  }, [address, googleLocationId]);

  const countriesLocalized = Object.keys(en)
    .map((key) => [key, en[key]])
    .map((country) => [country[0], i18nService.translate(country[1])])
    .reduce((prev, current) => ({ ...prev, [current[0]]: current[1] }), {});

  return (
    <div style={{ height: '20px' }}>
      <PhoneInput
        {...restProps}
        inputClass={classes.inputClass}
        containerClass={classes.containerClass}
        dropdownClass={styles.dropdownPosition}
        buttonClass={classes.buttonClass}
        containerStyle={{ height: '28px', padding: '0' }}
        inputStyle={{
          width: width ? `${width}px` : '250px',
          height: '24px',
          paddingLeft: '0',
          padding: '0',
          marginLeft: '60px',
          fontSize: fontSize ? `${fontSize}` : '14px',
          backgroundColor: 'transparent',
        }}
        searchStyle={{ borderRadius: '25px', fontFamily: 'Lato, sans-serif', fontSize: '12px' }}
        buttonStyle={{ height: '26px', margin: '0' }}
        value={value}
        country={country || 'us'}
        onChange={onChange}
        enableSearch
        enableTerritories
        placeholder={''}
        searchPlaceholder={i18nService.translate('general.search')}
        specialLabel={null}
        localization={countriesLocalized}
      />
    </div>
  );
};

export default PhoneNumberInput;
