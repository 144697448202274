import { useEffect } from 'react';
import { dispatch } from '@src/redux/store';
import { removeModal } from '@src/redux/widgetModals';
import { useSelector } from '@src/redux/useSelector';
import { modalService } from '@core/modals/ModalService';
import { usePrevious } from '@core/hooks/usePrevious';
import { isEqual } from 'lodash';

export function useWidgetModal() {
  const modal = useSelector(state => state.widgetModals);
  const prevErrorHandling = usePrevious(modal);

  const openModal = async modal => {
    const res = await modalService.openModal(modal.type, modal.args, modal.props || {});
    dispatch(removeModal());
    modal.onClose && modal.onClose(res);
  };
  useEffect(() => {
    if (modal && modal.type && !isEqual(modal, prevErrorHandling)) {
      openModal(modal);
    }
  }, [modal]);
  return null;
}
