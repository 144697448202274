import { decrementBusyCounter, incrementBusyCounter } from '@src/redux/config';
import { dispatch, getState } from '@src/redux/store';
import axios from 'axios';
import { httpService } from './http/HttpService';
import { UploadARModelFileRes, ARModelUploadPollingStatusRes } from '@http/server.interface.d';

class AugmentedReailtyUploadService {
  async getUploadARModelFileStatus() {
    const res = await httpService.api({
      type: '',
      urlParams: {},
    });
    return res;
  }

  async upload({ getUploadType, file, fileName, callback, errorCallback, setTaskId }) {
    try {
      dispatch(incrementBusyCounter());
      const uploadData = await httpService.api<UploadARModelFileRes>({
        type: getUploadType,
        data: { fileName, type: 'AR_MODEL' },
      });

      if (uploadData?.uploadUrl) {
        const res = await axios({
          url: uploadData?.uploadUrl?.url,
          data: this.buildFormData({ ...uploadData?.uploadUrl?.fields, file }),
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setTaskId(uploadData?.taskId);

        this.startPolling({
          getUploadType,
          taskId: uploadData?.taskId,
          callback,
          errorCallback,
        });
      }
    } catch (e) {
      console.log({ e });
      errorCallback(e);
      dispatch(decrementBusyCounter());
    }
  }

  async startPolling({
    getUploadType,
    taskId,
    callback = null,
    errorCallback = null,
    isCreate = false,
  }) {
    try {
      const pollingData = await httpService.api<ARModelUploadPollingStatusRes>({
        type: `${getUploadType}Status`,
        urlParams: { taskId },
      });

      if (
        pollingData.taskId === taskId &&
        ['UPLOADING', 'IN_PROGRESS'].includes(pollingData.status) &&
        pollingData.response === null
      ) {
        setTimeout(
          () =>
            this.startPolling({
              getUploadType,
              taskId: taskId,
              callback,
              errorCallback,
            }),
          5000
        );
      } else if (
        pollingData.taskId === taskId &&
        pollingData.status === 'IN_PROGRESS' &&
        pollingData.response !== null &&
        !isCreate
      ) {
        callback && callback(pollingData.response);
      } else if (pollingData.taskId === taskId && pollingData.status === 'COMPLETED') {
        callback && callback(pollingData.response.id);
        dispatch(decrementBusyCounter());
      } else if (
        pollingData.taskId === taskId &&
        (pollingData.status === 'BLOCKED' || pollingData.status === 'ERROR')
      ) {
        errorCallback && errorCallback(pollingData.status);
        dispatch(decrementBusyCounter());
      } else {
        setTimeout(
          () =>
            this.startPolling({
              getUploadType,
              taskId,
              callback,
              errorCallback,
              isCreate,
            }),
          10000
        );

        const busyCounter = getState().config.busyCounter;
        busyCounter <= 1 && dispatch(incrementBusyCounter());
      }
    } catch (e) {
      errorCallback && errorCallback(e.data.response.message);
      dispatch(decrementBusyCounter());
      console.error('Polling error', e);
    }
  }

  buildFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  }
}

export const augmentedRealityUploadService = new AugmentedReailtyUploadService();
