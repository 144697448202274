import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import I18n from '@components/I18n';
import { useSelector } from '@redux/useSelector';
import Icon from '@components/Icon';
import {
  getAccounts,
  handleChange,
  constructAccountLabel,
  onSubmit,
} from './SelectOrganizationPage.utils';
import { buildImageUrl } from '@core/utils';
import NewLogo from '@components/NewLogo';

const useStyles = makeStyles((theme: any) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: ({ isMobileView }: any) => (isMobileView ? 'unset' : '95vh'),
    paddingTop: '2.5vh',
    boxSizing: 'border-box',
  },
  logo: {
    marginBottom: 20, // ({ isMobileView }: any) => (isMobileView ? 0 : 20),
  },
  loginHeader: {
    height: 65, //({ isMobileView }: any) => (isMobileView ? 45 : 65),
    color: theme.app.white1,
    backgroundColor: 'var(--systemButtonBackground)',
    fontSize: 24,
    fontWeight: 800,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  loginContent: {
    width: 300,
    paddingTop: 4,
    paddingBottom: 18,
    padding: '4px 0 18px 0', //({ isMobileView }: any) =>
    // isMobileView ? "2px 0" : "4px 0 18px 0",
  },
  loginForm: {
    maxHeight: '85vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 438, //({ isMobileView }: any) => (isMobileView ? "96vw" : 438),
    borderRadius: 8,
    boxShadow: theme.app.boxShadow_1,
  },
  submit: {
    color: theme.app.white1,
    backgroundImage:
      'linear-gradient(to top, var(--systemButtonBackground) 0%, var(--systemButtonBackground))',
    height: 45,
    borderRadius: 5,
    boxShadow: theme.app.boxShadow_1,
    width: '100%',
    margin: ({ isMobileView }: any) => (isMobileView ? '0 0 2px 0' : '20px 0'),
    textTransform: 'unset',
  },
  errorMessage: {
    marginTop: 23,
    display: 'flex',
  },
  errorText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.app.formikErrorText,
    marginLeft: 8,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  orgsList: {
    maxHeight: '50vh', // ({ isMobileView }: any) => (isMobileView ? "44vh" : "50vh"),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: '20px 15px',
  },
}));

function SelectOrganizationPage(props) {
  const { isLoggedIn, userOrganizationId } = useSelector((state) => state.login);
  const [selectedOrg, setSelectedOrg] = useState(userOrganizationId);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState({
    error: undefined,
    orgData: undefined,
  });
  const history = useHistory();
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  const classes = useStyles({
    props,
    isMobileView,
  });

  useEffect(() => {
    getAccounts(setIsLoading, history, setAccounts);
  }, []);

  return (
    <>
      {!isLoggedIn ? <Redirect to="/login" /> : null}
      {!isLoading && accounts?.length > 1 ? (
        <div className={classNames(!isMobileView && 'max-height', classes.page)}>
          <div className={classes.logo}>
            <NewLogo />
          </div>
          <form
            onSubmit={(e) => onSubmit(e, accounts, setErrorsMessage, selectedOrg, history)}
            className={classes.loginForm}>
            <Typography align="center" className={classes.loginHeader}>
              <I18n>select-org-page.title</I18n>
            </Typography>
            <div className={classes.loginContent}>
              {errorsMessage.error && (
                <div className={classes.errorMessage}>
                  <Icon type="error" />
                  <div className={classes.errorText}>
                    <I18n
                      noEllipsis
                      companyName={errorsMessage.orgData && errorsMessage.orgData.organizationName}>
                      {errorsMessage.error}
                    </I18n>
                  </div>
                </div>
              )}
              <div className={classes.orgsList}>
                <RadioGroup
                  value={selectedOrg || ''}
                  onChange={(event) =>
                    handleChange(event, selectedOrg, setSelectedOrg, setErrorsMessage)
                  }>
                  {accounts.map((account) => (
                    <FormControlLabel
                      value={account.id.toString()}
                      control={<Radio />}
                      label={constructAccountLabel(account)}
                      key={account.id}
                    />
                  ))}
                </RadioGroup>
              </div>
              <Button
                className={classes.submit}
                type="submit"
                variant="contained"
                disabled={!selectedOrg}>
                <I18n>general.submit</I18n>
              </Button>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
}

export default SelectOrganizationPage;
