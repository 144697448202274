import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import Button from '@components/Button';
import { httpService } from '@core/http/HttpService';
import ImageThumbnail from '../ImageThumbnail/ImageThumbnail';
import classNames from 'classnames';
import { imageCacheService } from '@core/canvas/ImageCacheService';

const useStyles = makeStyles((theme: any) => ({
  text: {
    fontSize: '14px',
    width: '105px',
  },
  imagePreview: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  imageName: {
    fontSize: '12px',
    textAlign: 'center',
    maxWidth: '150px',
  },
}));

const SwitchImage = (props) => {
  const classes = useStyles(props);
  const {
    text,
    buttonText,
    imageInfo,
    imageName,
    openImageGallery,
    modalHeader,
    existingImages,
    imageType,
  } = props;
  const [imageUrl, setImageUrl] = useState(null);

  const getImageData = async () => {
    try {
      const res = await imageCacheService.getImageURL(imageInfo.imageId);
      if (res) {
        setImageUrl(res.url);
      }
    } catch {}
  };

  useEffect(() => {
    if (imageInfo?.imageId) getImageData();
  }, [imageInfo.imageId]);

  return (
    <React.Fragment>
      <I18n noEllipsis={true} className={classes.text}>
        {text}
      </I18n>
      <Button
        onClick={() =>
          openImageGallery(imageInfo, imageName, modalHeader, existingImages, imageType)
        }>
        <I18n>{buttonText}</I18n>
      </Button>
      <div className={classes.imagePreview}>
        <ImageThumbnail image={imageUrl}></ImageThumbnail>
        <div
          className={classNames(classes.imageName, 'ellipsis-overflow')}
          title={imageInfo.imageName}>
          {imageInfo.imageName}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SwitchImage;
