import * as cloneDeep from 'lodash/cloneDeep';
import * as isEqual from 'lodash/isEqual';
import { editorConfig } from './editorConfig';
import { widgetMap } from './widgetMap';
import {
  getMoveLeftPotential,
  isOverflowing,
  updateRowCoords,
  getMaxOverflow,
  moveWidget,
  stretchWidgetsToFillWidth,
} from './layout.utils';
import { isWidgetGroup } from './widget.utils';
import { LiveWidget, WidgetRow } from './layoutService.interface.d';

const cellSize = editorConfig.gridCellSizePx;

/**
 * A helper class, used by LayoutService to generate a responsive layout.
 */
export class LayoutGenerator {
  private readonly canvasWidth: number;
  private widgets: LiveWidget[];
  private rows: WidgetRow[];
  private wasRowStructureModified = false;

  constructor(canvasWidth: number, widgets: LiveWidget[]) {
    this.canvasWidth = canvasWidth;
    this.widgets = widgets;
  }

  /**
   * Generates a responsive layout based on container width.
   */
  generateLayout(): LiveWidget[] {
    if (!this.canvasWidth) {
      return this.widgets;
    }
    this.widgets = this.widgets.map((w) => this.setWidgetPosition(w));
    const widgets = cloneDeep(this.widgets);

    if (!isOverflowing(this.widgets)) {
      return this.widgets;
    }

    this.rows = this.getRows(this.widgets);

    for (let i = 0; i < this.rows.length; i++) {
      if (isOverflowing(this.rows[i])) {
        const rebuild = this.handleOverflowingRow(i);
        if (rebuild) {
          widgets.sort((w1, w2) => w1.y - w2.y || w1.x - w2.x);
          widgets.forEach((w) => {
            if (w.children.length > 0) {
              w.children.sort((w1, w2) => w1.y - w2.y || w1.x - w2.x);
            }
          });
          this.widgets = this.buildWidgetsPos(widgets);
          break;
        }
      }
    }

    // if (this.wasRowStructureModified) {
    //   this.rows.forEach((row) => {
    //     stretchWidgetsToFillWidth(row.widgets, this.canvasWidth);
    //   });
    // }

    return cloneDeep(this.widgets);
  }

  getWidgetPosition(widget, row) {
    const top = row?.top;
    const left = row?.width;
    const bottom = top + widget.h * cellSize;
    const right = left + widget.w * cellSize;
    return { top, left, bottom, right };
  }

  private buildWidgetsPos(widgets) {
    let rows = [];
    let currentRowTop = cellSize;

    return widgets.map((w, indx) => {
      let widget;
      let isNewRow;
      let newPosition;
      const extraRowData = { top: currentRowTop, width: cellSize };
      if (rows.length) {
        //find a row that widget can added to that row (widget not overflow and not higher then the prev widget)
        const indexRow = rows.findIndex(
          (row) =>
            row?.width + w.w * cellSize <= this.canvasWidth &&
            row?.widgetData.position.bottom -
              (row?.widgetData.position.bottom !== row.top || row.hasWidgets ? row.top : 0) >=
              w.h * cellSize
        );
        if (indexRow > -1) {
          //widget can added to exists tow
          newPosition = this.getWidgetPosition(w, rows[indexRow]);
          extraRowData.top = newPosition.top;
          rows[indexRow].top = newPosition.bottom;
          rows[indexRow].hasWidgets = true;
          rows.splice(0, indexRow);
        } else {
          //if new row
          isNewRow = true;
          newPosition = this.getWidgetPosition(w, extraRowData);
        }
      } else {
        //if new row
        newPosition = this.getWidgetPosition(w, extraRowData);
        isNewRow = true;
      }

      //if widget width is bigger than canvas width set it to the canvas width
      if (isNewRow && newPosition.right > this.canvasWidth) {
        newPosition.right -= newPosition.right - this.canvasWidth;
      }

      //set new widget position
      widget = {
        ...w,
        position: newPosition,
      };
      extraRowData.width = newPosition.right;

      //if there is no space in the current row, clear rows and create new row.
      if (isNewRow) {
        currentRowTop = newPosition.bottom;
        rows = [{ indx, widgetData: { ...widget }, ...extraRowData }];
      } else {
        //if there is space in the current row, create new row.
        rows.unshift({ indx, widgetData: { ...widget }, ...extraRowData });
      }
      return widget;
    });
  }

  /**
   * Translates the widget's x, y, w, h values (cell-index) to absolute positioning
   * values (px): top, left, bottom, right.
   */
  private setWidgetPosition(widget: LiveWidget): LiveWidget {
    const top = cellSize * widget.y;
    const left = cellSize * widget.x;
    const bottom = cellSize * (widget.y + widget.h);
    const right = cellSize * (widget.x + widget.w);
    const isOverflowing = right > this.canvasWidth;

    const newPosition = { top, left, bottom, right, isOverflowing };

    const isSamePosition = widget.position && isEqual(newPosition, widget.position);

    widget.children = widget.children.map((child) => this.setWidgetPosition(child));

    if (!isSamePosition) {
      return {
        ...widget,
        position: newPosition,
        originalPosition: { ...newPosition },
      };
    }
    return widget;
  }

  /**
   * Divides the widgets to rows.
   * Assumes the widgets are sorted by their top position.
   * Sorts each row's widgets from left to right (by their left position).
   */
  private getRows(widgets: LiveWidget[]): WidgetRow[] {
    const rows: WidgetRow[] = [];
    // let _widgets = widgets.sort((w1, w2) => w1.y - w2.y || w1.x - w2.x);
    let currentRow: WidgetRow = {
      top: widgets.length ? widgets[0].y : undefined,
      bottom: widgets.length ? widgets[0].y + widgets[0].h : undefined,
      widgets: [],
    };

    widgets.forEach((w) => {
      const isInCurrentRow = w.y * cellSize < currentRow.bottom;

      if (isInCurrentRow) {
        currentRow.widgets.push(w);
        currentRow.top = Math.min(currentRow.top, w.y * cellSize);
        currentRow.bottom = Math.max(currentRow.bottom, (w.y + w.h) * cellSize);
      } else {
        // Start a new row.
        rows.push(currentRow);
        currentRow = {
          top: w.y * cellSize,
          bottom: (w.y + w.h) * cellSize,
          widgets: [w],
        };
      }
    });

    rows.push(currentRow);

    // Sort each row's widgets from left to right (sort also group children).
    rows.forEach((row) => {
      row.widgets.sort((w1, w2) => w1.x - w2.x);
      row.widgets.forEach((w) => {
        if (w.children.length > 0) {
          w.children.sort((w1, w2) => w1.x - w2.x);
        }
      });
    });

    return rows;
  }

  /**
   * Try to remove whitespace, then try to make the widgets smaller.
   */
  private handleOverflowingRow(rowIdx: number) {
    const row = this.rows[rowIdx];
    let overflow = getMaxOverflow(row.widgets, this.canvasWidth);

    // Try to reduce the whitespace.
    this.reduceRowWhitespace(row.widgets, overflow);
    overflow = getMaxOverflow(row.widgets, this.canvasWidth);
    if (!overflow) {
      return;
    }

    // reduce the width only if there is one widget.
    if (row.widgets.length === 1) {
      overflow = this.reduceRowWidgetsWidth(row, overflow);
      if (!overflow) return;
    }

    return true;

    if (row.widgets.length > 1) {
      // If all fails, move overflowing widgets to the next row.
      this.moveOverflowingWidgetsToNextRow(rowIdx);

      // Repeat overflow mechanism.
      this.handleOverflowingRow(rowIdx);
    }
  }

  /**
   * Reduces the row whitespace. Assumes the widgets are sorted left to right.
   * First, it tries to reduce the whitespace equally for all widgets.
   * If any reducing is left, it will reduce where it can.
   */
  private reduceRowWhitespace(widgets: LiveWidget[], tempMaxReduce: number) {
    let maxReduce = tempMaxReduce;
    if (maxReduce === 0) {
      return;
    }
    let totalReduced = 0;
    let iterationReduced;

    iterationReduced = this.reduceRowWhitespaceIteration(widgets, maxReduce - totalReduced);
    totalReduced += iterationReduced;
    while (iterationReduced > 0 && totalReduced < maxReduce) {
      iterationReduced = this.reduceRowWhitespaceIteration(widgets, maxReduce - totalReduced);
      totalReduced += iterationReduced;
    }
  }

  private reduceRowWhitespaceIteration(widgets: LiveWidget[], maxReduce: number) {
    let reduced = 0;

    // We try to reduce the whitespace equally.
    const availableWhitespace = widgets.map((w, idx) => getMoveLeftPotential(widgets, idx));
    const numWidgetsThatCanReduce = availableWhitespace.filter((val) => val > 0).length;
    const maxIndividualReduce = maxReduce / numWidgetsThatCanReduce;
    const toReduceArr = availableWhitespace.map((val) => Math.min(maxIndividualReduce, val));

    // Reduce the whitespace of each widget.
    toReduceArr.forEach((toReduce, idx) => {
      const appliedToReduce = Math.min(toReduce, maxReduce - reduced);
      let minMovement = null;
      // When reducing the widget whitespace (moving to the left),
      // we must move all widgets to the right of the widget also to the left.
      for (let i = idx; i < widgets.length; i++) {
        const maxLeft = getMoveLeftPotential(widgets, i);
        const movement = Math.min(maxLeft, appliedToReduce);
        if (widgets[i].position?.isOverflowing && (!minMovement || movement < minMovement)) {
          minMovement = movement;
        }
        moveWidget(widgets[i], -movement, 0, this.canvasWidth);
      }
      reduced += minMovement;
    });

    return reduced;
  }

  /**
   * Reduces the width of widgets in a row. Assumes the widgets are sorted
   * left to right.
   */
  private reduceRowWidgetsWidth(row: WidgetRow, maxReduce: number): number {
    let iterationReduced;
    let overflow: number;

    // Try to reduce the width.
    iterationReduced = this.reduceRowWidgetsWidthIteration(row, maxReduce);
    overflow = getMaxOverflow(row.widgets, this.canvasWidth);

    // Continue the process until no more reducing is possible.
    while (iterationReduced && overflow) {
      iterationReduced = this.reduceRowWidgetsWidthIteration(row, overflow);
      overflow = getMaxOverflow(row.widgets, this.canvasWidth);
    }

    return overflow;
  }

  /**
   * Reduces the width of widgets in a row. Assumes the widgets are sorted
   * left to right.
   */
  private reduceRowWidgetsWidthIteration(row: WidgetRow, maxReduce: number) {
    const widgets = row.widgets;
    let reduced = 0;

    // We activate the layout mechanism for a widget group if it is the only widget in a row.
    if (widgets.length === 1 && isWidgetGroup(widgets[0])) {
      return this.reduceWidgetGroupWidth(widgets[0], maxReduce);
    }

    const reducePotentials = widgets.map((widget, idx) => {
      if (isWidgetGroup(widget)) {
        return 0; // We don't reduce widget size for groups unless we have to.
      }
      const width = widget.position.right - widget.position.left;
      const minWidth = widgetMap[widget.type].minWidth;
      const reducePotential = width - minWidth;
      const isLast = idx === widgets.length - 1;

      const hasAdjacentWidgetToTheRight = widgets
        .slice(idx + 1)
        .some((w) => w.position.left === widget.position.right);

      // We only reduce widgets that will have an effect on the row overflow.
      return isLast || hasAdjacentWidgetToTheRight ? reducePotential : 0;
    });

    const numWidgetsThatCanReduce = reducePotentials.filter((rp) => rp > 0).length;
    const maxIndividualReduce = Math.ceil(maxReduce / numWidgetsThatCanReduce);

    // We try to reduce the width equally.
    const toReduceArr = reducePotentials.map((rp) => Math.min(maxIndividualReduce, rp));

    toReduceArr.forEach((toReduce, idx) => {
      const widget = widgets[idx];
      reduced += toReduce;
      widget.position.right -= toReduce;
      widget.position.isOverflowing = widget.position.right > this.canvasWidth;
    });

    this.reduceRowWhitespace(widgets, reduced);

    return reduced;
  }

  /**
   * Reduces the width of a widget group.
   */
  private reduceWidgetGroupWidth(group: LiveWidget, reduce: number) {
    group.position.right -= reduce;

    const origLeft = group.position.left;
    const groupWidth = group.position.right - group.position.left;
    moveWidget(group, -origLeft, 0, this.canvasWidth);

    // The LayoutGenerator assumes the widgets are sorted by their top position.
    group.children.sort((w1, w2) => w1.y - w2.y);

    const groupLayout = new LayoutGenerator(groupWidth, group.children);
    group.children = groupLayout.generateLayout();

    moveWidget(group, origLeft, 0, this.canvasWidth);

    // Update parent coordinates.
    const oldGroupBottom = group.position.bottom;
    const newGroupBottom = group.children.reduce(
      (acc, curr) => Math.max(acc, curr.position.bottom),
      0
    );
    group.position.bottom = newGroupBottom;

    if (newGroupBottom === oldGroupBottom) {
      return;
    }

    // Re-calculate row heights and move all widgets from below rows to adjust.
    const currentRowIdx = this.rows.findIndex((r) => {
      return r.widgets.some((w) => w.id === group.id);
    });
    this.recalculateRowHeights(currentRowIdx);
  }

  /**
   * Recalculates a the height of a specific row, and updates
   * all following rows (and their widgets) accordingly.
   * Is called when a row is suspected to change its height after
   * changing its layout.
   */
  private recalculateRowHeights(fromRowIdx: number) {
    const fromRow = this.rows[fromRowIdx];

    const prevBottom = fromRow.bottom;
    updateRowCoords(fromRow);
    const newBottom = fromRow.bottom;
    const delta = newBottom - prevBottom;

    if (delta === 0) {
      return;
    }

    // Adjust all following rows (and their widgets) by the delta.
    for (let i = fromRowIdx + 1; i < this.rows.length; i++) {
      this.rows[i].top += delta;
      this.rows[i].bottom += delta;
      this.rows[i].widgets.forEach((w) => {
        moveWidget(w, 0, delta, this.canvasWidth);
      });
    }
  }

  // TODO: dudik: change the function to move only the right most
  // widget down one row and check if it needs to expand and fill the row's width
  private moveOverflowingWidgetsToNextRow(rowIdx: number) {
    const row = this.rows[rowIdx];
    let overflowingWidgets = row.widgets.filter((w) => w.position.isOverflowing);

    // We can't move an overflowing widget if it is the only one.
    if (!overflowingWidgets.length || row.widgets.length === 1) {
      return;
    }

    // If all widgets are overflowing we leave the leftmost widget
    // in the row. Move all other widgets down a row.
    if (row.widgets.length === overflowingWidgets.length) {
      overflowingWidgets = overflowingWidgets.slice(1);
    }

    this.wasRowStructureModified = true;

    // Remove overflowing widgets from current row.
    row.widgets = row.widgets.filter((widget) => {
      return !overflowingWidgets.some((w) => w.id === widget.id);
    });

    updateRowCoords(row); // Update the current row's top and bottom
    const rowHeight = row.bottom - row.top;

    // Add new row if necessary.
    if (!this.rows[rowIdx + 1]) {
      this.rows[rowIdx + 1] = { top: row.bottom, bottom: row.bottom, widgets: [] };
    }
    const nextRow = this.rows[rowIdx + 1];

    const leftmostPoint = overflowingWidgets[0].position.left;

    // Change position of overflowing widgets to nextRow.
    overflowingWidgets.forEach((w) => {
      moveWidget(w, -leftmostPoint, rowHeight, this.canvasWidth);
    });

    // Move the next row's widgets to the right of the new overflowing widgets.
    const rightmostPoint = overflowingWidgets.reduce(
      (acc, curr) => Math.max(acc, curr.position.right),
      0
    );
    nextRow.widgets.forEach((w) => {
      moveWidget(w, rightmostPoint, 0, this.canvasWidth);
    });

    // Add the overflowing widgets to the next row.
    nextRow.widgets.unshift(...overflowingWidgets);

    updateRowCoords(nextRow); // Update the next row's top and bottom.
  }
}
