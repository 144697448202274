import MapWidget from '@components/widgets/MapWidget';
import { WidgetMapItem } from '../widgetMap.interface';
import { i18nService } from '@core/i18n/I18nService';
import { MapCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { omit } from 'lodash';

const getDefaultMetric = (widgetData) => {
  const typesMap = {
    tags: 'TAG',
    tagTypes: 'TAG_TYPE',
    variables: 'VARIABLE',
    assetProperties: 'ASSET_PROPERTY',
  };
  const tagType = ['tags', 'tagTypes', 'assetProperties', 'variables'].find(
    (type) => (type !== 'assetProperties' && widgetData[type].length) || widgetData[type].length > 3
  );
  const tagData = tagType
    ? widgetData[tagType].length > 3
      ? widgetData[tagType].find((val) => ![1, 3, 5].includes(val.id))
      : widgetData[tagType][0]
    : undefined;
  const format = tagData?.format || tagData?.type || tagData?.valueType;

  return {
    metrics: tagData
      ? [
          {
            selectType: null,
            name: 'create-widget-page.create-widget.asset-properties.ASSET_ID',
            operation: null,
            order: 0,
            type: 'STRING',
            valueId: 1,
            valueType: typesMap['assetProperties'],
          },
          {
            selectType: null,
            name: tagData.name,
            operation: null,
            order: 1,
            type: tagData.type || tagData.valueType,
            valueId: tagData.id,
            valueType: typesMap[tagType],
          },
        ]
      : [],
    customization: tagData
      ? {
          tagCaption: i18nService.translate(tagData.name),
          dateTimeFormat:
            format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null,
        }
      : null,
  };
};

export const map: WidgetMapItem = {
  id: 'map',
  name: 'Map',
  component: MapWidget,
  minWidth: 300,
  minHeight: 128,
  fullSize: true,
  images: {
    canvas: '/assets/images/map_empty_state_image.svg',
    thumbnail: 'map',
  },
  editModalSize: {},
  settings: {
    maxDataTags: 4,
    hidePreviewTable: true,
    stepOneHidden: [],
    extraTitles: {
      dataTagsSubtitle: {
        text: 'create-widget-page.create-widget.step-one.dataTagsExtraSubtitle',
        condition: (widgetData) => {
          if (widgetData && widgetData.assetTypes.length == 1)
            return 'create-widget-page.create-widget.step-one.dataTagsTitleMoreTag';
          if (widgetData && widgetData.assetTypes.length > 1)
            return 'create-widget-page.create-widget.step-one.dataTagsTitleMoreTagType';
        },
      },
    },
    customizationServerChanges: (customization: MapCustomization) => ({
      ...omit(customization, 'tagName'),
    }),
    accordionDisable: ['sort', 'groupingAndMeasures', 'calculations'],
    dragAndDropRules: {
      maxGrouping: (scope) => 0,
      maxMetrics: 0,
    },
    stepValidation: {
      3: (widgetData) => {
        return !widgetData.scope;
      },
      4: (widgetData) => {
        return widgetData.customization?.tagCaption !== '';
      },
    },
    setDefaultDataByStep: {
      1: (scope, setWidgetData, widgetData) =>
        setWidgetData((prevState) => ({
          ...prevState,
          ...getDefaultMetric(prevState),
        })),
    },
    disabledScope: ['RAW_DATA', 'AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'],
    defaultAssetProperties: [1, 3, 5],
    languageKeys: (isArWidget) => ['tagCaption'],
    similarWidgets: () => [],
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => true,
  },
};
