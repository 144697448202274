import * as RI from '../../redux.interface.d';

export const setAssetsData = (assetsData: RI.AssetState): RI.StoreAction => ({
  type: 'asset/SET_ASSETS_DATA',
  payload: assetsData
});

export const addAssetsData = (assetsData: RI.AssetState): RI.StoreAction => ({
  type: 'asset/ADD_ASSETS_DATA',
  payload: assetsData
});

export const setAssetsDataToFirst = (assetsData: RI.AssetData): RI.StoreAction => ({
  type: 'deviceManagment/SET_ASSETS_DATA_TO_FIRST',
  payload: assetsData
});

export const updateAssetTableData = (changedValues: any, rowIdx: number): RI.StoreAction => ({
  type: 'asset/UPDATE_ASSETS_DATA',
  payload: { data: changedValues, rowIdx }
});

export const removeAssetFromTableData = (rowIdx: string): RI.StoreAction => ({
  type: 'asset/REMOVE_ASSET',
  payload: rowIdx
});

export const removeAllAssetsData = (): RI.StoreAction => ({
  type: 'asset/REMOVE_ALL_ASSETS_DATA',
  payload: null
});

export const refreshAssetTableData = (): RI.StoreAction => ({
  type: 'asset/REFRESH_ASSETS_DATA',
  payload: null
});
