import { HEATMAP_DATA_API_PAGE_SIZE, HEATMAP_DATA_ROW_LENGTH } from '@core/constants';
import { httpService } from '@core/http/HttpService';

export function getPageSize(availableSeries) {
  if (availableSeries?.groupBy?.length === 1) {
    return availableSeries?.series.length <= 10
      ? 30
      : +(HEATMAP_DATA_API_PAGE_SIZE / availableSeries?.series.length).toFixed();
  }

  return availableSeries?.series.length <= 10
    ? availableSeries?.series.length * 31
    : availableSeries?.series.length *
        +(HEATMAP_DATA_API_PAGE_SIZE / availableSeries?.series.length + 1).toFixed();
}

export function getRowSize(availableSeries) {
  const calculatedRowSize = +(
    HEATMAP_DATA_API_PAGE_SIZE / availableSeries?.series.length
  ).toFixed();
  return calculatedRowSize <= HEATMAP_DATA_ROW_LENGTH ? calculatedRowSize : HEATMAP_DATA_ROW_LENGTH;
}

export async function getHeatmapData(
  apiData,

  pageSize,
  availableSeries: { groupBy: Array<any>; series: Array<any> }
) {
  const res: any = await httpService.api({
    ...apiData,
    urlParams: apiData.type === 'getHeatmapData' && { widgetId: apiData.widgetId },
    query: {
      p: apiData.type === 'getHeatmapData' ? apiData.query.p : 1,
      ps: pageSize,
    },
  });

  // If there are more available series than data series, fill the missing series with nulls
  let updatedResults = [];
  updatedResults = fillMissingSeries(res.results, availableSeries, res.xaxis.categories);

  return {
    ...res,
    results: updatedResults,
    ps: pageSize,
  };
}

export async function getHeatmapDataPreview(apiData) {
  let pageSize = HEATMAP_DATA_API_PAGE_SIZE;

  const res: any = await httpService.api({
    ...apiData,
    query: {
      p: 1,
      ps: pageSize,
    },
  });

  const maxPreviewLength = res.results.length <= 5 ? 20 : +(100 / res.results.length).toFixed();

  return {
    ...res,
    results: res.results.map((r) => ({ ...r, data: r.data.slice(0, maxPreviewLength) })),
    xaxis: { ...res.xaxis, categories: res.xaxis.categories.slice(0, maxPreviewLength) },
    ps: pageSize,
  };
}

function fillMissingSeries(results, availableSeries, categories) {
  let updatedResults = results;

  const missingSeriesNames = availableSeries.series.filter(
    (ser) => !results.some((r) => r.name == ser.name)
  );

  const missingSeries = missingSeriesNames.map((ms) => ({
    name: ms.name.toString(),
    data: categories.map(() => null),
  }));

  missingSeries.forEach((element) => {
    const indexOfElement = availableSeries.series
      .map((tr) => tr.name.toString())
      .indexOf(element.name);

    updatedResults.splice(indexOfElement, 0, element);
  });

  return updatedResults;
}

export function getDashboardFilters(dashboardFilters) {
  return {
    organizationsFilter: dashboardFilters?.organizationsFilter
      ? dashboardFilters?.organizationsFilter.map((f) => {
          if (f.id) {
            return f.id;
          }
          return f;
        })
      : null,
    assetTypesFilter: dashboardFilters?.assetTypesFilter
      ? dashboardFilters?.assetTypesFilter.map((f) => {
          if (f.id) {
            return f.id;
          }
          return f;
        })
      : null,
    assetFilter: dashboardFilters?.assetFilter
      ? dashboardFilters?.assetFilter.map((f) => {
          if (f.id) {
            return f.id;
          }
          return f;
        })
      : null,
    geoFilter: dashboardFilters?.geoFilter || null,
    dateFilter: dashboardFilters?.dateFilter,
    dateFilterFrom: dashboardFilters?.dateFilterFrom,
    dateFilterTo: dashboardFilters?.dateFilterTo,
  };
}

export async function getData(
  widgetId,
  dashboardFilters,
  widgetFilters,
  paging,
  setData,
  availableSeries
) {
  let apiData: any = {};
  if (widgetId) {
    apiData.type = 'getHeatmapData';
    apiData.urlParams = { widgetId };
    apiData.query = paging;
    apiData.data = {
      dashboardFilters: getDashboardFilters(dashboardFilters),
      widgetFilters,
    };
  }

  if (
    !apiData?.data?.dashboardFilters?.organizationsFilter ||
    !apiData?.data?.dashboardFilters?.assetTypesFilter ||
    !apiData?.data?.dashboardFilters?.assetFilter ||
    !apiData?.data?.dashboardFilters?.geoFilter
  ) {
    return;
  }

  let res: any = await getHeatmapData(
    { ...apiData, widgetId: widgetId },
    paging.ps,
    availableSeries
  );

  setData(res);
}
