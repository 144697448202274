const generalRegex = {
  leadingSpacesRegex: /^\s+/g,
  multipleSpacesRegex: /[ ]{2,}/gi,
  tenDecimalDigitsRegex: /^(-?\d*\.{0,1}\d{0,10}$)/g,
  capitalizeFirstLettersOfEveryWordInSentenceRegex: /(^\w{1})|(\s+\w{1})/g,
};

export const expressionBuilderRegex = {
  toBE: {
    power: {
      numberRegex: /(\d{1,}\.{0,1}\d{0,}[\^]\d{1,}\.{0,1}\d*)/g,
      tagRegex: /(\[.+?\][\^]\d{1,}\.{0,1}\d*)/g,
      expressionRegex: /([\(].{1,}[\)][\^]\d{1,}\.{0,1}\d*)/g,
    },
    squareRoot: {
      numberRegex: /((^|\-)[^\d][\√]\d{1,}\.{0,1}\d*)/g,
      tagRegex: /([^\d][\√]\[.+?\])/g,
      expressionRegex: /([\√][\(].{1,}[\)])/g,
    },
    nthRoot: {
      numberRegex: /(\d*\.{0,1}\d*[\√]\d{1,}\.{0,1}\d*)/g,
      tagRegex: /(\d*\.{0,1}\d*[\√]\[.+?\])/g,
      expressionRegex: /(\d*\.{0,1}\d*[\√][\(].{1,}[\)])/g,
    },
  },
  fromBE: {
    power: {
      numberRegex: /(power)(\(.+?\,\s\d{1,}\.{0,1}\d{0,}\))/g,
      tagRegex: /(power)(\(\[.+?\]\,\s\d{1,}\.{0,1}\d{0,}\))/g,
      expressionRegex: /(power)(\(\(.+?\)\,\s\d{1,}\.{0,1}\d{0,}\))/g,
    },
    root: {
      numberRegex: /(nth_root)(\(.+?\,\s\d{1,}\.{0,1}\d{0,}\))/g,
      tagRegex: /(nth_root)(\(\[.+?\]\,\s\d{1,}\.{0,1}\d{0,}\))/g,
      expressionRegex: /(nth_root)(\(\(.+?\)\,\s\d{1,}\.{0,1}\d{0,}\))/g,
    },
  },
  general: { doublePower: /(\s\^\d+\)?\^\d+)/g },
  conditionTree: {
    assetTags: /(assetTags\.\d{0,})/g,
    tagTypes: /(tagTypes\.\d{0,})/g,
    systemProperties: /(systemProperties\.\d{0,})/g,
  },
};

export default generalRegex;
