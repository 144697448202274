import React, { useState } from 'react';
import { TableColunm } from '@components/Table/table.interface';
import Table from '@components/Table';
import { useSelector } from '@redux/useSelector';
import TagsName from './TagsName';
import TagsType from './TagsType';
import TagsDescription from './TagsDescription';
import TagsAlias from './TagsAlias';
import styles from './TagsTable.scss';
import TagsAccess from './TagsAccess';
import TagsFormat from './TagsFormat';
import TagsInfo from './TagsInfo';
import TagsButtons from './TagsButtons';
import { TagMeta } from '@core/http/server.interface';
import { dynamicSort } from '@core/utils';

const colunmsCfg: Array<TableColunm> = [
  {
    Component: TagsName,
    label: 'asset-type-tags.name',
    style: { width: '16%', minWidth: '16%', maxWidth: '16%' },
    field: 'name',
    isSortable: true,
  },
  {
    Component: TagsType,
    label: 'asset-type-tags.type',
    style: { width: '16%', minWidth: '16%', maxWidth: '16%' },
    field: 'type',
    isSortable: true,
  },
  {
    Component: TagsAlias,
    label: 'asset-type-tags.alias',
    style: { width: '16%', minWidth: '16%', maxWidth: '16%' },
    field: 'alias',
    isSortable: true,
  },
  {
    Component: TagsFormat,
    label: 'asset-type-tags.format',
    style: { width: '16%', minWidth: '16%', maxWidth: '16%' },
    field: 'format',
    isSortable: true,
  },
  {
    Component: TagsAccess,
    label: 'asset-type-tags.access',
    style: { width: '16%', minWidth: '16%', maxWidth: '16%' },
    field: 'access',
    isSortable: true,
  },
  {
    Component: TagsDescription,
    label: 'asset-type-tags.description',
    style: { width: '16%', minWidth: '16%', maxWidth: '16%' },
  },
  {
    Component: TagsInfo,
    type: 'staticEnd',
    label: '',
    style: { width: 97 },
  },
  {
    Component: TagsButtons,
    type: 'staticEnd',
    label: '',
    style: { width: 110 },
  },
];

function TagsTable() {
  const [_order, setOrder] = useState(null);
  const tagsMeta = useSelector((state) => state.editAssetType.tagsMeta);

  const orderData = (firstInit: boolean, order?: any): TagMeta[] => {
    return tagsMeta.sort(dynamicSort(order.label, order.orderSign));
  };

  const orderedData = _order ? orderData(false, _order) : tagsMeta;

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={false}
        data={orderedData}
        getData={(firstInit, order) => {
          order && setOrder(order);
          orderData(firstInit, order);
        }}
        colunmsCfg={colunmsCfg}
        className={styles.tableBody}
      />
    </div>
  );
}

export default TagsTable;
