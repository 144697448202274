import { WidgetModal, ModalState, StoreAction } from './redux.interface';

export const setModal = (widget: WidgetModal): StoreAction => ({
  type: 'widgetModals/SET_MODAL',
  payload: widget
});

export const removeModal = (): StoreAction => ({
  type: 'widgetModals/REMOVE_MODAL',
  payload: null
});

const initialState: ModalState = {
  type: null
};

export default function widgetModalReducer(state: ModalState = initialState, action: StoreAction) {
  switch (action.type) {
    case 'widgetModals/SET_MODAL':
      const { type, args, props, onClose } = action.payload;
      return {
        ...state,
        onClose,
        type: type,
        args,
        props
      };
    case 'widgetModals/REMOVE_MODAL':
      return {
        ...state,
        type: null,
        onClose: (res: any) => {},
        args: {},
        props: {}
      };
    default:
      return state;
  }
}
