import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';
import MaterialCheckbox from '@components/Checkbox';
import { httpService } from '@core/http/HttpService';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    padding: '26px 40px',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
  },
  wrapperEditor: {
    display: 'flex',
  },
  useSubDomain: {
    display: 'flex',
    alignItems: 'center',
  },
  subDomainInputRow: {
    marginTop: 20,
  },
  validationRulesWrapper: {
    marginTop: 35,
  },
  validationsHeader: {
    fontWeight: 600,
  },
  validationBullets: {
    margin: '5px 0 0 20px',
  },
  checkboxText: {
    marginLeft: 5,
  },
  containerInput: {
    width: 200,
    height: 28,
    margin: '0 4px',
    padding: '0 13px',
    fontSize: '12px',
    color: 'var(--systemFont)',
    fontWeight: 500,
    borderRadius: 5,
    border: 'solid 1px var(--lightGrey)',
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  errorText: {
    fontSize: '12px',
    color: 'var(--formikErrorText)',
    fontWeight: 'bold',
    paddingLeft: '27px',
  },
}));

const validationBullets = [
  'white-labeling.sub-domains.validationsBullets.1',
  'white-labeling.sub-domains.validationsBullets.2',
  'white-labeling.sub-domains.validationsBullets.3',
  'white-labeling.sub-domains.validationsBullets.4',
  'white-labeling.sub-domains.validationsBullets.5',
];

function SubDomains(props) {
  const { displaySettingsData } = props;
  const [domain, setDomain] = useState('');
  const [confirmedSubDomain, setConfirmedSubDomain] = useState('');
  const [isUseSubDomain, setIsUseSubDomain] = useState(false);
  const [environment, setEnvironment] = useState('');
  const [isFailedUnregister, setIsFailedUnregister] = useState(false);
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const orgName = useSelector((state) => state.config?.organizationDetails?.name);
  const classes = useStyles(props);

  useEffect(() => {
    const subDomain = displaySettingsData?.subDomain?.content?.name;
    setConfirmedSubDomain(subDomain);
    setEnvironment(displaySettingsData?.subDomain?.content?.environment);
    if (subDomain) {
      setDomain(subDomain.replace('.unitronics.io', ''));
      setIsUseSubDomain(true);
    }
  }, []);

  const updateUseDomain = () => {
    // Unchecking the 'Use sub-domain' and we have a confirmed subDomain
    if (isUseSubDomain && !!confirmedSubDomain) {
      openConfirmationModal(
        'white-labeling.sub-domains.un-register-sub-domain-modal.confirm1',
        'white-labeling.sub-domains.un-register-sub-domain-modal.header',
        true
      ).then((confirm1) => {
        if (confirm1) {
          // When the user confirm the first confirm modal
          openConfirmationModal(
            i18nService.translate(
              'white-labeling.sub-domains.un-register-sub-domain-modal.confirm2',
              null,
              { environment }
            ),
            'white-labeling.sub-domains.un-register-sub-domain-modal.header',
            true
          ).then((confirm2) => {
            if (confirm2) {
              // When the user confirm the second confirm modal
              httpService
                .api({ type: 'deleteSubdomain' })
                .then(() => {
                  openConfirmationModal(
                    i18nService.translate(
                      'white-labeling.sub-domains.update-subdomain.unregister.success',
                      null,
                      { subDomain: confirmedSubDomain }
                    ),
                    'white-labeling.sub-domains.sub-domain-modal.header'
                  );
                  setConfirmedSubDomain('');
                  setIsUseSubDomain(false);
                  setDomain('');
                })
                .catch(() => {
                  setIsFailedUnregister(true);
                });
            }
          });
        }
      });
    } else {
      setIsUseSubDomain(!isUseSubDomain);
    }
  };

  const openConfirmRegistrationModal = () => {
    return modalService.openModal('confirm', {
      text: i18nService.translate(
        'white-labeling.sub-domains.registerConfirmationModal.text',
        null,
        { subDomainAddress: `https://${domain}.unitronics.io`, organization: orgName }
      ),
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: 'white-labeling.sub-domains.registerConfirmationModal.header',
      showCloseBtn: true,
    });
  };

  const openConfirmationModal = (text, header?, isCancel?) => {
    return modalService.openModal('confirm', {
      text: text,
      confirmText: 'general.confirm',
      cancelText: isCancel ? 'general.cancel' : false,
      headerText: header || 'white-labeling.sub-domains.registerConfirmationModal.header',
      showCloseBtn: true,
    });
  };

  const doValidateDomain = () => {
    return httpService.api({
      type: 'validateSubDomain',
      data: {
        name: `${domain}.unitronics.io`,
      },
    });
  };

  const onValidateResponse = (res) => {
    switch (res) {
      case 'OK':
        openConfirmationModal(
          i18nService.translate(
            'white-labeling.sub-domains.registerConfirmationModal.verification.success',
            null,
            { subDomainAddress: `https://${domain}.unitronics.io` }
          )
        );
        httpService
          .api({
            type: 'addSubdomain',
            data: {
              name: `${domain}.unitronics.io`,
            },
          })
          .then(() => {
            setConfirmedSubDomain(`${domain}.unitronics.io`);
          });
        break;
      case 'INVALID':
        openConfirmationModal(
          'white-labeling.sub-domains.registerConfirmationModal.verification.failed'
        );
        break;
      case 'ALREADY_EXISTS':
        openConfirmationModal(
          'white-labeling.sub-domains.registerConfirmationModal.verification.domainTaken'
        );
        break;
    }
  };

  const register = () => {
    openConfirmRegistrationModal().then((res) => {
      if (res) {
        doValidateDomain().then((res: any) => {
          onValidateResponse(res.result);
        });
      } else {
        setDomain('');
      }
    });
  };

  const change = () =>
    modalService.openModal('updateSubDomain', {
      oldSubdomain: domain,
      onChangeSuccess: (newDomain) => setDomain(newDomain),
    });

  const handleChange = (val) => {
    setDomain(val);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.useSubDomain}>
        <MaterialCheckbox
          disabled={!isPremium}
          checked={isUseSubDomain}
          color="primary"
          onChange={(e) => updateUseDomain()}
        />
        <I18n className={classes.checkboxText}>white-labeling.sub-domains.use-dub-domains</I18n>
      </div>
      {isFailedUnregister && (
        <I18n className={classes.errorText} element="div">
          white-labeling.sub-domains.registerConfirmationModal.verification.failed
        </I18n>
      )}
      <div className={classNames(classes.subDomainInputRow, !isUseSubDomain && classes.disable)}>
        <I18n>white-labeling.sub-domains.input-url</I18n>
        <span>: https://</span>
        <InputBase
          className={classes.containerInput}
          value={domain}
          disabled={!isUseSubDomain || !isPremium || !!confirmedSubDomain}
          onChange={(e) => handleChange(e.target.value)}
        />
        <span>.unitronics.io</span>
        <Button
          styles={{ width: 102, marginTop: 2, marginLeft: 10 }}
          disabled={!isUseSubDomain || !domain || !isPremium}
          onClick={() => (!!confirmedSubDomain ? change() : register())}>
          <I18n>{!!confirmedSubDomain ? 'general.change' : 'general.register'}</I18n>
        </Button>
      </div>
      <div
        className={classNames(classes.validationRulesWrapper, !isUseSubDomain && classes.disable)}>
        <div className={classes.validationsHeader}>
          <I18n>white-labeling.sub-domains.validationsHeader</I18n>:
        </div>
        <div className={classes.validationBullets}>
          {validationBullets.map((text, idx) => {
            return (
              <div key={text} style={{ marginTop: 5 }}>
                - <I18n>{text}</I18n>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SubDomains;
