import React from 'react';
import Table from '@components/Table';
import { makeStyles } from '@material-ui/core';
import { convertStatusName } from '@components/widgets/charts.utils';
import { staticDictionary } from '@core/i18n/dictionary';
import { getMetricName } from '@pages/CreateWidgetPage/widgets.utils';
import { getHeatmapColumnsForMultipleGroupBy, getHeatmapTableData } from './TableData.utils';

const useStyles = makeStyles((theme: any) => ({
  tableDataContainer: {
    borderRadius: 5,
    border: `solid 1px ${theme.app.grey}`,
    backgroundColor: 'var(--systemContentBackground)',
    height: '100%',
    overflow: 'auto',
  },
  tableWrpper: {
    width: ({ data }: any) => data && data.columns && `${data.columns.length * 100}px`,
    minWidth: '100%',
    backgroundColor: 'var(--systemContentBackground)',
  },
}));

const LabelComponent = (props) => {
  const value = convertStatusName(props, props[props.field], 'table');
  return (
    <div className="ellipsis-overflow" style={{ minWidth: '62px' }} title={`${value}`}>
      {props.field === 'active_alarm'
        ? staticDictionary[`enum.${(value + '_active_alarm').toUpperCase()}`]
        : typeof value == 'boolean'
        ? staticDictionary[`enum.${(value + '').toUpperCase()}`]
        : value}
    </div>
  );
};

function TableData({ data, widgetData }) {
  const { scope } = widgetData;
  const classes = useStyles({ data });

  return (
    data &&
    data.results && (
      <div className={classes.tableDataContainer}>
        <div className={classes.tableWrpper}>
          <Table
            data={
              widgetData.type === 'heatmap'
                ? getHeatmapTableData(
                    data.xaxis.categories,
                    data.columns,
                    data.results,
                    widgetData.groupBy
                  )
                : data.results
            }
            colunmsCfg={
              widgetData.type === 'heatmap' && widgetData.groupBy.length > 1
                ? getHeatmapColumnsForMultipleGroupBy(data.columns, data.results, LabelComponent)
                : data.columns.map((c) => {
                    const metricName = getMetricName(widgetData, c);
                    const modifiedName =
                      (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') &&
                      c.operation
                        ? `${metricName} ${c.operation.toLowerCase()}`
                        : metricName;

                    const label =
                      widgetData.type === 'heatmap' &&
                      c.valueId === 7 &&
                      c.valueType === 'ASSET_PROPERTY'
                        ? 'general.date'
                        : modifiedName || metricName;

                    return {
                      ...c,
                      field: c.name,
                      label: label,
                      Component: LabelComponent,
                      style: {
                        width: `${100 / data.columns.length}%`,
                        minWidth: '100px',
                        maxWidth: `${100 / data.columns.length}%`,
                      },
                    };
                  })
            }
            disabledLoad
          />
        </div>
      </div>
    )
  );
}

export default React.memo(TableData);
