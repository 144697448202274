import React from 'react';
import DataViewer from '@components/DataViewer';
import styles from './DataSection.scss';

function DataSection({ leftContainer, rightContainer }) {
  return (
    <>
      <div className={styles.dataSection}>
        <DataViewer
          className={styles.dataViewer}
          data={leftContainer}
          boldLabel
          marginBottom
          labelStyle={{ width: '180px' }}
        />
        <DataViewer
          className={styles.dataViewer}
          data={rightContainer}
          boldLabel
          marginBottom
          labelStyle={{ width: '180px' }}
        />
      </div>
      <div className={styles.dataSeparator}></div>
    </>
  );
}

export default DataSection;
