import React, { useMemo, useState, useEffect } from 'react';

// Components
import I18n from '@components/I18n';
import FormikInputWithCancel from '@components/Formik/FormikInputWithCancel';
import Icon from '@components/Icon';
import FormikLocationInput from '@components/Formik/FormikLocationInput';
import DateField from '@components/Table/TableFields/DateField';

// Services | Interfaces
import { httpService } from '@core/http/HttpService';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';
import { RouterDetailsProps } from '../EditRouterModal.interface';
import { useSelector } from '@src/redux/useSelector';
import styles from './EditRouterDetails.scss';

// 3rd party
import { Formik, Form } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import {
  OwnerOption,
  OwnerModel,
} from '@modals/EditAssetModal/EditAssetTabs/AssetInfoGeneral/AssetInfoGeneralEdit/assetInfoGeneralEdit.interface';
import FormikSelect from '@components/Formik/FormikSelect';
import { modalService } from '@core/modals/ModalService';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';
import { i18nService } from '@core/i18n/I18nService';
import { dynamicSort } from '@core/utils';

const validationSchema = Yup.object().shape({
  owner: Yup.string().max(100, 'edit-router-modal.max100-chars'),
  comments: Yup.string().max(200, 'edit-router-modal.max200-chars'),
});

function EditRouterDetails(props: RouterDetailsProps) {
  const { router, onDataChanged } = props;
  const [ownerEditMode, setOwnerEditMode] = useState(false);
  const [locationEditMode, setLocationEditMode] = useState(false);
  const [commentsEditMode, setCommentsEditMode] = useState(false);
  const [orgOwners, setOrgOwners] = useState<OwnerOption[]>([]);
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const userOrgType = useSelector(
    (state) => state.config.organizationDetails && state.config.organizationDetails.type
  );
  const flags = useMemo(
    () => ({
      edit: getFlagStatus('routers.edit-buttons'),
    }),
    []
  );
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const initialValues = useMemo(() => {
    if (router) {
      return {
        owner: { value: router.ownerId, label: router.owner },
        location: router.location || {},
        comments: router.comments || '',
      };
    }
  }, [router]);

  useEffect(() => {
    httpService
      .api({ type: 'getOwners', urlParams: { organizationId: selectedMBId || undefined } })
      .then((types: OwnerModel[]) => {
        setOrgOwners(
          types.map<OwnerOption>((t) => ({
            value: t.id,
            label: t.name,
          }))
        );
      });
  }, []);

  const getLocation = (location) => {
    if (location) {
      return location.description
        ? location.description
        : location.formatted === 'Unknown'
        ? i18nService.translate('edit-asset-modal.location.unknown')
        : location.formatted;
    }
    return '';
  };

  const saveChanges = (value: any, setStateFunction) => {
    httpService
      .api({
        type: 'updateRouter',
        data: value,
        urlParams: { routerId: router.id, organizationId: router?.ownerId || undefined },
      })
      .then((res: any) => {
        // refresh router data
        onDataChanged(res?.ownerId);
        setStateFunction();
      });
  };

  const onSaveOwner = (values) => {
    if (initialValues.owner.value === values.owner.value) {
      setOwnerEditMode(false);
      return;
    }
    const save = () => {
      saveChanges({ ownerId: values.owner.value }, () => {
        setOwnerEditMode(false);
        routersService.refreshRoutersTableData();
      });
    };
    if (!router.assetCount && !router.plcCount) {
      save();
    } else if (router.plcCount > 0) {
      modalService
        .openModal('changeRouterOwner', {
          router,
          newOwner: values.owner,
        })
        .then((confirmSave) => confirmSave && save());
    }
  };

  return (
    <>
      <div className={styles.section}>
        <div className={styles.dataRow}>
          <div className={classNames(styles.dataLabel, 'ellipsis-overflow')} style={{ width: 150 }}>
            <I18n>edit-router-modal.serial-number</I18n>:
          </div>
          <span title={router.serialNumber} className={styles.statusValue}>
            {router.serialNumber}
          </span>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={() => {}}>
          {({ values, errors, setFieldValue }) => (
            <Form className={styles.modalForm}>
              <div className={classNames(styles.dataRow, !ownerEditMode && styles.padding)}>
                <div
                  className={classNames(styles.dataLabel, 'ellipsis-overflow')}
                  style={{ width: 150 }}>
                  <I18n>edit-router-modal.router-owner</I18n>:
                </div>
                {ownerEditMode ? (
                  <div className={styles.editMode}>
                    <FormikSelect
                      className={styles.ownerSelect}
                      label="edit-router-modal.router-owner"
                      name="owner"
                      defaultValue={values.owner}
                      options={orgOwners?.sort(dynamicSort('label', '+'))}
                      hideLabel={true}
                    />
                    <Icon
                      type="save"
                      className={classNames('pointer', styles.icon)}
                      onClick={() => onSaveOwner(values)}
                    />
                    <Icon
                      type="remove"
                      className={classNames('pointer', styles.icon)}
                      onClick={() => {
                        setFieldValue('owner', initialValues.owner);
                        setOwnerEditMode(false);
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <span title={router.owner} className={styles.dataValue}>
                      {router.owner}
                    </span>
                    {flags.edit && (
                      <Icon
                        type="edit"
                        color="var(--systemFont)"
                        disabled={!hasPermission}
                        className={classNames('pointer', styles.icon)}
                        onClick={() => setOwnerEditMode(true)}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={classNames(styles.dataRow, !locationEditMode && styles.padding)}>
                <div
                  className={classNames(styles.dataLabel, 'ellipsis-overflow')}
                  style={{ width: 150 }}>
                  <I18n>edit-router-modal.router-location</I18n>:
                </div>
                {locationEditMode ? (
                  <div className={styles.editMode}>
                    <FormikLocationInput name="location" location={values.location} />
                    <Icon
                      type="save"
                      className={classNames('pointer', styles.icon)}
                      onClick={() =>
                        saveChanges({ location: values.location }, () => setLocationEditMode(false))
                      }
                    />
                    <Icon
                      type="remove"
                      className={classNames('pointer', styles.icon)}
                      onClick={() => {
                        setFieldValue('location', initialValues?.location);
                        setLocationEditMode(false);
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <span title={getLocation(values.location)} className={styles.dataValue}>
                      {getLocation(values.location)}
                    </span>
                    {flags.edit && (
                      <Icon
                        type="edit"
                        color="var(--systemFont)"
                        disabled={!hasPermission}
                        className={classNames('pointer', styles.icon)}
                        onClick={() => setLocationEditMode(true)}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={classNames(styles.dataRow, !commentsEditMode && styles.padding)}>
                <div
                  className={classNames(styles.dataLabel, 'ellipsis-overflow')}
                  style={{ width: 150 }}>
                  <I18n>edit-router-modal.router-comments</I18n>:
                </div>
                {commentsEditMode ? (
                  <div className={styles.editMode}>
                    <FormikInputWithCancel
                      name="comments"
                      value={values.comments}
                      multiline={true}
                      rows={3}
                      cancel={() => setCommentsEditMode(false)}
                    />
                    <Icon
                      disabled={initialValues.comments === values.comments || !!errors.comments}
                      type="save"
                      className={classNames('pointer', styles.icon)}
                      onClick={() =>
                        saveChanges({ comments: values.comments }, () => setCommentsEditMode(false))
                      }
                    />
                  </div>
                ) : (
                  <>
                    <span title={router.comments} className={styles.dataValue}>
                      {router.comments}
                    </span>
                    {flags.edit && userOrgType !== 'MACHINE_BUILDER_CHANNEL' && (
                      <Icon
                        type="edit"
                        color="var(--systemFont)"
                        disabled={!hasPermission}
                        className={classNames('pointer', styles.icon)}
                        onClick={() => setCommentsEditMode(true)}
                      />
                    )}
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className={styles.section}>
        <div className={styles.dataRow}>
          <div className={classNames(styles.dataLabel, 'ellipsis-overflow')}>
            <I18n>edit-router-modal.date-connected</I18n>:
          </div>
          <span title={router.connectedDate} className={styles.statusValue}>
            <DateField field="connectedDate" {...router} />
          </span>
        </div>
        <div className={styles.dataRow}>
          <div className={classNames(styles.dataLabel, 'ellipsis-overflow')}>
            <I18n>edit-router-modal.router-model</I18n>:
          </div>
          <span title={router.model} className={styles.statusValue}>
            {router.model}
          </span>
        </div>
      </div>
    </>
  );
}

export default EditRouterDetails;
