import { httpService } from '@core/http/HttpService';

export const getAssetCertificat = (assetId) => {
  httpService
    .api({
      type: 'getAssetCertificate',
      urlParams: { assetId },
    })
    .then((res: any) => {
      const fileName = `device.${res.deviceSerial}.encoded`;
      if (navigator['msSaveBlob']) {
        // IE 10+
        const blob = new Blob([res.certificate], { type: '' });
        navigator['msSaveBlob'](blob, fileName);
      } else {
        const linkSource = `data:,${res.certificate}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
};
