import React from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import Select from '@components/Select';
import Button from '@components/Button';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import { useSelector } from '@src/redux/useSelector';
import { dispatch } from '@src/redux/store';
import { updatetWhiteLabelDetails } from '@src/redux/config';
import { modalService } from '@core/modals/ModalService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    padding: '22px 44px',
    width: '100%',
    height: '100%',
    borderRadius: 5,
    backgroundColor: '#f9fafe',
    flexDirection: 'column',
  },
  fields: {
    paddingLeft: 30,
    marginTop: 10,
  },
  row: {
    display: 'flex',
    position: 'relative',
    marginBottom: 20,
    alignItems: 'center',
    color: 'var(--systemFont)',
  },
  field: {
    display: 'flex',
    position: 'relative',
    marginBottom: 20,
  },
  caption: {
    width: 310,
    color: 'var(--systemFont)',
  },
  item: {
    marginBottom: 25,
    alignItems: 'center',
  },
  colorWrapper: {
    marginRight: 10,
  },
}));

function DashboardSettings(props) {
  const classes = useStyles({ props });

  const { displaySettingsData, setDisplaySettingsData } = props;
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const dashboardRefreshRate = useSelector(
    (state) => state.config.whiteLabelDetails?.MBConf?.dashboardRefreshRate
  );

  const managedCentrallyOptions = [
    { label: 'general.yes', value: true },
    { label: 'general.no', value: false },
  ];

  const refreshRateOptions = Array.from({ length: 5 }, (_, i) => ({
    label: (5 - i).toString().padStart(1, ''),
    value: 5 - i,
  }));

  const onChange = async (key, value) => {
    await httpService.api({
      type: 'updateDashboardRefreshRate',
      data: {
        dashboardRefreshRate: {
          ...dashboardRefreshRate,
          [key]: value,
        },
      },
    });
    setDisplaySettingsData({
      ...displaySettingsData,
      MBConf: {
        dashboardRefreshRate: {
          ...displaySettingsData.MBConf.dashboardRefreshRate,
          [key]: value,
        },
      },
    });
    dispatch(
      updatetWhiteLabelDetails({
        ...whiteLabelDetails,
        MBConf: {
          dashboardRefreshRate: {
            ...dashboardRefreshRate,
            [key]: value,
          },
        },
      })
    );
  };

  const onCreateInitialDashboard = async () => {
    let counter = 0;
    const createDashboard = async () => {
      try {
        const response = (await httpService.api({ type: 'createDefaulDashboard' })) as any;
        if (response && response.refId) {
          await modalService.openModal('alert', {
            text: 'white-labeling.dashboards-settings.initial-dashboard.success-pop-up-text',
            iconType: 'v_image',
            btnText: 'general.close',
            headerText: 'white-labeling.dashboards-settings.initial-dashboard.result-pop-up-header',
            showCloseBtn: true,
            dashboardName: response.name,
          });
        }
      } catch (e) {
        if (counter >= 2) {
          const retry = await modalService.openModal('confirm', {
            text: 'white-labeling.dashboards-settings.initial-dashboard.fail-pop-up-text',
            iconType: 'attention_image',
            confirmText: 'general.retry',
            cancelText: 'general.close',
            headerText: 'white-labeling.dashboards-settings.initial-dashboard.result-pop-up-header',
            showCloseBtn: true,
          });
          if (retry) {
            counter = 0;
            createDashboard();
          }
        } else {
          counter++;
          createDashboard();
        }
      }
    };

    createDashboard();
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.item}>
          <I18n>white-labeling.dashboards-settings.dashboard-refresh-settings</I18n>
          <div className={classes.fields}>
            <div className={classes.row}>
              <I18n className={classes.caption}>
                white-labeling.dashboards-settings.managed-centrally
              </I18n>
              <Select
                styles={{ container: { width: '110px', marginRight: '3px' } }}
                className={classes.field}
                options={managedCentrallyOptions}
                value={managedCentrallyOptions.find(
                  (opt) => opt.value === dashboardRefreshRate?.managedCentrally
                )}
                getOptionValue={(i) => i.value}
                onChange={(option) => {
                  onChange('managedCentrally', option.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <I18n className={classes.caption}>
                white-labeling.dashboards-settings.default-refresh-rate
              </I18n>
              <Select
                styles={{ container: { width: '110px' } }}
                className={classes.field}
                options={refreshRateOptions}
                value={refreshRateOptions.find(
                  (opt) => opt.value === dashboardRefreshRate?.refreshRate
                )}
                getOptionValue={(i) => i.value}
                onChange={(option) => {
                  onChange('refreshRate', option.value);
                }}
              />
              <I18n className="ellipsis-overflow" style={{ marginLeft: '20px' }}>
                white-labeling.dashboards-settings.minutes
              </I18n>
            </div>
          </div>
        </div>
        <div className={classes.item}>
          <I18n>white-labeling.dashboards-settings.initial-dashboard</I18n>
          <div className={classes.fields}>
            <div className={classes.row}>
              <Button mode="small" onClick={onCreateInitialDashboard}>
                <I18n>general.create</I18n>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardSettings;
