import React from 'react';
import classNames from 'classnames';
import styles from './Field.scss';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import ColorInput from '@components/ColorInput';
import MaterialCheckbox from '@components/Checkbox';
import { DraggableTableFieldProps } from './Field.interface';
import { InputBase } from '@material-ui/core';
import { getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import Select from '@components/Select';
import { i18nService } from '@core/i18n/I18nService';

function Field(props: DraggableTableFieldProps) {
  const {
    type,
    value,
    options,
    style,
    isLast,
    provided,
    editedRow,
    name,
    setData,
    onChange,
    maxLength = undefined,
    isDisabled = false,
  } = props;

  const dateTimeOptions = getDateTimeOptions(editedRow.tagType);

  switch (type) {
    case 'dragIndicator':
      return (
        <div
          className={classNames(styles.dragIndicator, isLast && styles.lastRow)}
          {...provided.dragHandleProps}>
          <Icon type="drag" />
        </div>
      );
    case 'label':
      return <I18n element={'div'}>{value}</I18n>;
    case 'textInput':
      return (
        <InputBase
          disabled={isDisabled}
          title={i18nService.translate(value)}
          id={styles.inputStyle}
          className={classNames(
            styles.containerInput
            // displayNameHasError && styles.containerInputError
          )}
          value={i18nService.translate(value)}
          inputProps={{ maxLength: maxLength }}
          onChange={(e) => {
            onChange && onChange(e.target.value, editedRow, name, setData);
          }}
          // onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
        />
      );
    case 'numericInput':
      return (
        <InputBase
          disabled={isDisabled}
          title={value}
          id={styles.inputStyle}
          type={'number'}
          className={classNames(
            styles.containerInput
            // displayNameHasError && styles.containerInputError
          )}
          value={value}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => onChange && onChange(e.target.value, editedRow, name, setData)}
          // onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
        />
      );
    case 'select':
      const tempValue =
        value && typeof value !== 'object' && options
          ? options.find((opt) => opt.value === value)
          : value;
      return null;

    case 'dateTimeOptionsSelect':
      return (
        <Select
          options={dateTimeOptions}
          value={
            editedRow?.dateTimeFormat &&
            dateTimeOptions?.find((opt) => opt.value === editedRow?.dateTimeFormat)
          }
          onChange={(option) => onChange(option.value, editedRow, name, setData)}
          disabled={isDisabled}
        />
      );

    case 'colorInput':
      return <ColorInput name={name} label={value} value={value} />;

    case 'checkbox':
      return (
        <MaterialCheckbox
          color="primary"
          onChange={(e) => {
            onChange && onChange(e.target.checked, editedRow, name, setData);
          }}
          checked={value}
        />
      );
    default:
      return;
  }
}

export default Field;
