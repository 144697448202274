/**
 * Generates numeric IDs from 1 to 2000.
 */
export class IdGenerator {
  private currentId = 1;

  /**
   * Returns an ID. Can accept existingIds array, and
   * will not return an ID that is in that array.
   */
  getId(existingIds: number[] = []) {
    while (existingIds.includes(this.currentId)) {
      this.incrementId();
    }
    const idToReturn = this.currentId;
    this.incrementId();
    return idToReturn;
  }

  private incrementId() {
    this.currentId = (this.currentId + 1) % 2000;
    if (this.currentId === 0) {
      this.currentId++;
    }
  }
}
