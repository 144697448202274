import React from 'react';
import classNames from 'classnames';
import styles from './UpdateValueInput.scss';
import { makeStyles } from '@material-ui/core';
import { compact } from 'lodash';
import Icon from '@components/Icon';
import Select from '@components/Select';
import TextField from '@material-ui/core/TextField';
import BasicDatePicker from '@components/BasicDatePicker';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { buildDateTime } from '@components/widgets/charts.utils';
import { i18nService } from '@core/i18n/I18nService';
import {
  getVariablesList,
  variableConstants,
} from '@pages/OrganizationsPage/Variables/Variables.utils';
import { getState } from '@src/redux/store';
import { UpdateValueInputProps } from './UpdateValueInput.interface';

const useStyles = makeStyles(() => ({
  numberField: {
    '&.MuiFormControl-marginDense': {
      margin: 0,
      height: 20,
      padding: '0 0 0 5px',
      border: '1px solid var(--tableRowBorderBottom)',
      borderRadius: 4,
    },
  },
}));

function UpdateValueInput(props: UpdateValueInputProps) {
  const {
    onCancelEditClicked,
    valueType,
    value,
    newValue,
    setNewValue,
    valueToDisplay,
    setValueToDisplay,
    editedVariableId,
    assetTypeOrAssetId,
    setVariablesList,
    onEditDone,
    origin,
    variableId,
    assetId = null,
    inputStyle = null,
    order = null,
  } = props;
  const classes = useStyles(props);

  function onChange(value) {
    setNewValue(valueType === 'BOOLEAN' ? value.value : value);
    setValueToDisplay(
      valueType === 'BOOLEAN'
        ? i18nService.translate(`general.${value.value ? 'on' : 'off'}`)
        : value
    );
  }

  const booleanOptions = [
    { value: true, label: i18nService.translate('general.on') },
    { value: false, label: i18nService.translate('general.off') },
  ];

  function validate(valueType: 'NUMERIC' | 'TEXT', value: number | string): boolean {
    switch (valueType) {
      case 'NUMERIC':
        return (
          !value ||
          (Number(value) &&
            (value as number) >= variableConstants.MIN_VALUE &&
            (value as number) <= variableConstants.MAX_VALUE)
        );
      case 'TEXT':
        return value.toString().length <= variableConstants.TEXT_MAX_LENGTH;
    }
  }

  function getInputByValueType(valueType: string) {
    switch (valueType) {
      case 'NUMERIC':
        return (
          <TextField
            className={classes.numberField}
            value={valueToDisplay != null ? valueToDisplay : ''}
            margin="dense"
            type="number"
            fullWidth
            variant="standard"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const isFloat =
                Number.parseFloat(e.target['valueAsNumber']) && e.target['valueAsNumber'] % 1 !== 0;

              const valueSplitToArray = e.target.value?.split('.');
              const length = valueSplitToArray[1]?.length;
              const fractionPart =
                length > 10 ? valueSplitToArray[1].slice(0, length - 1) : valueSplitToArray[1];
              const newNumericValue = `${valueSplitToArray[0]}.${fractionPart}`;

              let updatedValue;

              if (!isFloat && isFinite(e.target['valueAsNumber'])) {
                updatedValue = e.target['valueAsNumber'];
              } else if (isFloat) {
                updatedValue = newNumericValue;
              } else if (isNaN(e.target['valueAsNumber'])) {
                updatedValue = null;
              }
              validate(valueType, updatedValue) && onChange(updatedValue);
            }}
            inputProps={{
              style: { color: 'var(--systemFont)', fontSize: '13px' },
            }}
          />
        );
      case 'TEXT':
        return (
          <TextField
            className={classes.numberField}
            value={valueToDisplay || ''}
            margin="dense"
            type="text"
            fullWidth
            variant="standard"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const updatedValue = e.target.value;
              validate(valueType, updatedValue) && onChange(e.target.value);
            }}
            inputProps={{
              style: { color: 'var(--systemFont)', fontSize: '13px' },
            }}
          />
        );
      case 'BOOLEAN':
        return (
          <Select
            styles={{
              control: { height: 20, paddingLeft: 0 },
              menuPortal: { zIndex: 10000000000000 },
            }}
            value={booleanOptions.find((option) => option.value === newValue)}
            options={booleanOptions}
            onChange={(option) => {
              onChange(booleanOptions.find((o) => o.value === option.value));
            }}
            maxMenuHeight={180}
            menuPortalTarget={document.body}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        );
      case 'DATE':
        return (
          <BasicDatePicker
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            allowEmptyDefaultValue={true}
            selectedChanged={(value) => {
              onChange(value.split('T')[0]);
            }}
            value={valueToDisplay}
            timePicker={false}
            height={20}
            buildFormat={(val) => buildDateTime(val, 'FIXED_DATE', 'momentFormat', undefined)}
          />
        );
      case 'DATETIME':
        return (
          <BasicDatePicker
            singleDatePicker={true}
            displayRanges={false}
            disableCustomLabel={true}
            allowEmptyDefaultValue={true}
            selectedChanged={(value) => {
              onChange(value.split('+')[0]);
            }}
            value={valueToDisplay}
            timePicker={true}
            height={20}
            buildFormat={(val) => buildDateTime(val, 'FIXED_DATETIME', 'momentFormat', undefined)}
          />
        );
    }
  }

  const save = async (updateType: 'ALL' | 'SAME' | undefined, value: any) => {
    let res = null;
    let organizationId = null;
    switch (origin) {
      case 'assetType':
        res = await httpService.api({
          type: 'updateAssetTypeVariable',
          urlParams: { assetTypeId: +assetTypeOrAssetId, variableId: editedVariableId },
          data: { updateType: updateType, value: value },
        });
        if (res) {
          getVariablesList(origin, assetTypeOrAssetId, setVariablesList, onEditDone, order);
        }
        return;
      case 'asset':
        res = await httpService.api({
          type: 'updateAssetVariable',
          urlParams: { assetId: +assetTypeOrAssetId, variableId: editedVariableId },
          data: { value: value },
        });
        if (res) {
          getVariablesList(origin, assetTypeOrAssetId, setVariablesList, onEditDone, order);
        }
        return;
      case 'organizationAssetTypes':
        organizationId = getState().config.organizationDetails.id;
        res = await httpService.api({
          type: 'updateAssetTypeVariable',
          urlParams: {
            assetTypeId: +assetTypeOrAssetId,
            variableId: editedVariableId,
          },
          data: { updateType: updateType, value: value },
        });
        if (res) {
          getVariablesList(origin, organizationId, setVariablesList, onEditDone, order);
        }
        return;
      case 'organizationAssets':
        res = await httpService.api({
          type: 'updateAssetVariable',
          urlParams: { assetId: +assetTypeOrAssetId, variableId: editedVariableId },
          data: { value: value },
        });
        if (res) {
          getVariablesList(origin, organizationId, setVariablesList, onEditDone, order);
        }
        return;
      case 'variableValuesPerAssetModal':
        res = await httpService.api({
          type: 'updateAssetVariable',
          urlParams: { assetId: assetId, variableId: variableId },
          data: { value: value },
        });
        if (res) {
          getVariablesList(origin, variableId, setVariablesList, onEditDone, order);
        }
        return;
    }
  };

  const onSave = async (e) => {
    e.stopPropagation();
    if (origin === 'assetType' || origin === 'organizationAssetTypes') {
      const res: { allSameValue: boolean } = await httpService.api({
        type: 'validateVariableAllSameValue',
        urlParams: {
          assetTypeId: assetTypeOrAssetId,
          variableId: editedVariableId,
        },
        disableBI: true,
      });

      if (res.allSameValue) {
        save('ALL', newValue);
      } else {
        modalService.openConfirmWithMultiButtons({
          text: 'variables.update-all-or-exclude-manuals.text',
          headerText: '',
          iconType: 'attention_image',
          showCloseBtn: true,
          multiButtons: compact([
            {
              text: 'general.confirm',
              dismissModal: true,
              onClick: () => {
                save('ALL', newValue);
              },
            },
            {
              text: 'general.exclude-manual-changes',
              dismissModal: true,
              onClick: () => {
                save('SAME', newValue);
              },
            },
          ]),
        });
      }
      return;
    }
    save(undefined, newValue);
  };

  return (
    <div
      className={styles.editMode}
      style={inputStyle ? inputStyle : undefined}
      onClick={(e) => e.stopPropagation()}>
      {getInputByValueType(valueType)}
      <Icon
        type="save"
        disabled={
          value == newValue || (valueType === 'NUMERIC' && (newValue == null || isNaN(newValue)))
        }
        className={classNames('pointer', styles.icon)}
        onClick={(e) => {
          e.stopPropagation();
          onSave(e);
        }}
      />
      <Icon
        type="remove"
        className={classNames('pointer', styles.icon)}
        onClick={(e) => {
          e.stopPropagation();
          onCancelEditClicked();
        }}
      />
    </div>
  );
}

export default UpdateValueInput;
