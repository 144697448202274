import React, { useMemo, useState, useEffect } from 'react';

// Components
import Table from '@components/Table';
import TextField from '@components/Table/TableFields/TextField';
import DateField from '@components/Table/TableFields/DateField';

// Services | Interfaces
import { httpService } from '@core/http/HttpService';
import styles from './DeletedUsersTable.scss';

const colunmsCfg = [
  {
    Component: TextField,
    label: 'my-account-page.deleted-users.user-name',
    field: 'name',
    style: { width: '18%' },
    isSortable: true,
  },
  {
    Component: TextField,
    label: 'my-account-page.deleted-users.email',
    field: 'email',
    style: { width: '18%' },
    isSortable: false,
  },
  {
    Component: DateField,
    label: 'my-account-page.deleted-users.request-date',
    field: 'requestDate',
    style: { width: '18%' },
    isSortable: true,
  },
  {
    Component: DateField,
    label: 'my-account-page.deleted-users.delete-date',
    field: 'deleteDate',
    style: { width: '18%' },
    isSortable: true,
  },
];

function DeletedUsersTable(props: any) {
  const { needRefreshData, onDataTableRefreshed } = props;

  const [data, setData] = useState([]);
  const defaultOrdering = { label: 'deleteDate', orderSign: '-' };
  const [nextPage, setNextPage] = useState(1);
  const pageSize = useMemo(() => 50, []);
  const [disabledLoad, setDisableLoad] = useState(false);

  useEffect(() => {
    if (needRefreshData) {
      getData(true);
      onDataTableRefreshed();
    }
  }, [needRefreshData]);

  const getData = (firstInit = false, order = defaultOrdering) => {
    httpService
      .api({
        type: 'getForgottenUsers',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: pageSize,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      })
      .then((res: any) => {
        setDisableLoad(!res.next);
        if (firstInit) {
          setData(res.results);
          setNextPage(2);
        } else {
          setData([...data, ...res.results]);
          setNextPage(nextPage + 1);
        }
      });
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={true}
        data={data}
        colunmsCfg={colunmsCfg}
        getData={getData}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default DeletedUsersTable;
