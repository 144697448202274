import React, { useEffect, useCallback, useState } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import InputBase from '@material-ui/core/InputBase';
import I18n from '@components/I18n';
import styles from './FormikTextarea.scss';
import { FormikTextareaProps } from './formikTextarea.interface';

function FormikTextarea(props: FormikTextareaProps) {
  const {
    formik,
    name,
    label,
    hideLabel = false,
    validate,
    disabled,
    className,
    maxLength,
    value,
    addLeftMargin = false,
  } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      formik.setFieldValue(name, '');
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const handleChange = useCallback(
    (e) => {
      const fieldVal = e.target.value;
      if (maxLength && fieldVal.length > maxLength) return;
      setInputValue(fieldVal);
      formik.setFieldValue(name, fieldVal);
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(name, true);
  }, [formik]);

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {!hideLabel && (
        <label className={classNames(styles.fieldLabel, 'ellipsis-overflow')}>
          <I18n>{label}</I18n>:
        </label>
      )}
      <div className={styles.fieldInput}>
        <InputBase
          className={styles.formikTextareaWrapper}
          id={addLeftMargin ? styles.formikTextareaWithLeftMargin : styles.formikTextarea}
          value={inputValue}
          disabled={disabled}
          rows={3}
          multiline
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage name={name}>
          {(err) => (
            <I18n className={styles.error} element="div">
              {err}
            </I18n>
          )}
        </ErrorMessage>
      </div>
    </div>
  );
}

export default connect(FormikTextarea);
