export enum OrganizationStatus {
  preActivate = 'PRE_ACTIVATED',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Archived = 'ARCHIVED',
}

export enum UserStatus {
  preActivate = 'PRE_ACTIVATED',
  active = 'ACTIVE',
  suspended = 'SUSPENDED',
  archived = 'ARCHIVED',
  locked = 'LOCKED',
}

export interface Account {
  id: number;
  organizationName: string;
  roles: any;
  rootRelatedOrg: boolean;
  organizationType: string;
  organizationStatus: OrganizationStatus;
  userStatus: UserStatus;
  country: string;
}
