import React, { useCallback, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './TestEventModal.scss';
import { emailRegex, phoneRegex } from '@core/utils';
import { httpService } from '@core/http/HttpService';
import classNames from 'classnames';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { InputBase } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';

function TestEventModal(props) {
  const { args, dismiss } = props;
  const { templateData } = args;
  const [input, setInput] = useState('');
  const [isValidInput, setIsValidInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const [visited, setVisited] = useState(false);
  const hasPushNotifications = useSelector((state) => state.config.hasPushNotifications);

  const cancel = useCallback(() => dismiss(), [dismiss]);

  const onConfirm = async () => {
    const data =
      templateData?.action === 'EMAIL_NOTIFICATION'
        ? { email: input }
        : templateData?.action === 'SMS_NOTIFICATION'
        ? { mobile: input }
        : {};
    try {
      await httpService.api({
        type: 'testEventService',
        urlParams: { templateId: templateData?.id },
        data,
      });

      dismiss();
    } catch (e) {}
  };

  const onInsertInput = (value: string, blur: boolean = false) => {
    const isValid = Validate(value);

    templateData?.action === 'SMS_NOTIFICATION'
      ? setInput(value ? `+${value}` : '')
      : setInput(value);

    setIsValidInput(isValid);
    if (blur) setVisited(true);

    !!input && (visited || blur) && setShowError(!isValid);
  };

  const Validate = (value: string) => {
    return templateData?.action === 'EMAIL_NOTIFICATION'
      ? emailRegex.test(value)
      : phoneRegex.test(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>templates-delails-modal.test-notification</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={styles.icon}>
            {templateData?.action === 'PUSH_NOTIFICATION' && hasPushNotifications && (
              <Icon className={styles.icon} type={'attention_image'}></Icon>
            )}
          </div>
          <div className={styles.rowWithError}>
            {templateData?.action === 'EMAIL_NOTIFICATION' ? (
              <>
                <div className={classNames(styles.header, 'asterisk')}>
                  <I18n element="div">login-page.email</I18n>:
                </div>
                <div style={{ display: 'block', width: '100%', alignSelf: 'flex-start' }}>
                  <InputBase
                    id={showError ? styles.textInputError : styles.textInput}
                    value={input}
                    autoComplete="off"
                    onChange={(e) => onInsertInput(e.target.value)}
                    onBlur={(e) => onInsertInput(e.target.value, true)}
                  />
                </div>
              </>
            ) : templateData?.action === 'SMS_NOTIFICATION' ? (
              <>
                <div className={classNames(styles.header, 'asterisk')}>
                  <I18n element="div">templates-delails-modal.mobile-phone-number</I18n>:
                </div>
                <div style={{ display: 'block', width: '100%', alignSelf: 'flex-start' }}>
                  <PhoneNumberInput value={input} onChange={(value) => onInsertInput(value)} />
                  <div className={styles.error}>
                    {showError && <I18n>details.valid-mobile</I18n>}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.text}>
                  {hasPushNotifications ? (
                    <I18n element="div">
                      templates-delails-modal.test-pop-up.push-notification.text.app-installed
                    </I18n>
                  ) : (
                    <I18n element="div">
                      templates-delails-modal.test-pop-up.push-notification.text.app-not-installed
                    </I18n>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.modalButtons}>
          <Button disabled={false} onClick={cancel} mode={['cancel', 'bigFont']}>
            {templateData?.action === 'PUSH_NOTIFICATION' && !hasPushNotifications ? (
              <I18n>general.close</I18n>
            ) : (
              <I18n>general.cancel</I18n>
            )}
          </Button>

          {(templateData?.action !== 'PUSH_NOTIFICATION' || hasPushNotifications) && (
            <Button
              mode="bigFont"
              disabled={!isValidInput && templateData?.action !== 'PUSH_NOTIFICATION'}
              onClick={onConfirm}
              styles={{ marginLeft: 13 }}>
              <I18n>general.confirm</I18n>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestEventModal;
