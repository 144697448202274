import { LiveWidget, WidgetRow } from './layoutService.interface.d';
import { isWidgetGroup } from './widget.utils';

/**
 * Calculates how much a widget can move to the right without bumping into another widget.
 * Assumes the widgets are in a row and sorted left to right.
 */
export function getMoveRightPotential(
  widgets: LiveWidget[],
  idx: number,
  canvasWidth: number
): number {
  const widget = widgets[idx];

  // The starting constraint is the right-most point on the canvas.
  let constraint = canvasWidth;

  // Check all following widgets to see if they are constraints
  // for moving right. Update the left-most constraint value.
  for (let i = idx + 1; i < widgets.length; i++) {
    const isAConstraint =
      widget.position.bottom > widgets[i].position.top &&
      widget.position.top < widgets[i].position.bottom;

    if (isAConstraint) {
      constraint = Math.min(constraint, widgets[i].position.left);
    }
  }

  return constraint - widget.position.right;
}

/**
 * Calculates how much a widget can move to the left without bumping into another widget.
 * Assumes the widgets are in a row and sorted left to right.
 */
export function getMoveLeftPotential(widgets: LiveWidget[], idx: number): number {
  const widget = widgets[idx];

  // The minimum constraint is the left-most point on the canvas (0).
  let constraint = 0;

  // Check all preceding widgets to see if they are constraints
  // for moving left. Update the right-most constraint value.
  for (let i = 0; i < idx; i++) {
    const isAConstraint =
      widget.position.bottom > widgets[i].position.top &&
      widget.position.top < widgets[i].position.bottom;

    if (isAConstraint) {
      constraint = Math.max(constraint, widgets[i].position.right);
    }
  }

  return parseFloat((widget.position.left - constraint).toFixed(4));
}

/**
 * Grows widget widths in a row. Assumes the widgets are sorted from left to right.
 * toGrow can accept a constant value, or an array of values that corresponds to the widgets array.
 * The optional canGrow array can indicate whether a widget can grow or not.
 */
export function growWidgetWidths(
  widgets: LiveWidget[],
  toGrow: number | number[],
  canGrow?: boolean[]
) {
  widgets.forEach((w, idx) => {
    if (Array.isArray(canGrow) && !canGrow[idx]) {
      return;
    }
    const grow = Array.isArray(toGrow) ? toGrow[idx] : toGrow;
    w.position.right += grow;

    // When growing a widget, we must move all widgets to the right
    // of the widget also to the right.
    for (let i = idx + 1; i < widgets.length; i++) {
      widgets[i].position.left += grow;
      widgets[i].position.right += grow;
    }
  });
}

/**
 * Calculates the rightmost point in a row of widgets.
 */
export function getRightmostPoint(widgets: LiveWidget[]): number {
  return widgets.reduce((acc, curr) => Math.max(acc, curr.position.right), 0);
}

/**
 * Checks if at least one widget is overflowing. Accepts an array of
 * widgets or a widget-row.
 */
export function isOverflowing(rowOrWidgets: WidgetRow | LiveWidget[]): boolean {
  const widgets = Array.isArray(rowOrWidgets) ? rowOrWidgets : rowOrWidgets.widgets;
  return widgets.some((w) => {
    return w.position.isOverflowing || w.children.some((ch) => ch.position.isOverflowing);
  });
}

/**
 * Returns the maximum amount of pixels a widget in a list of widgets
 * is overflowing.
 */
export function getMaxOverflow(widgets: LiveWidget[], canvasWidth: number): number {
  return widgets.reduce((acc, curr) => {
    const childrenMaxOverflow = getMaxOverflow(curr.children, canvasWidth);
    const currentMaxOverflow = Math.max(
      parseFloat((curr.position.right - canvasWidth).toFixed(4)),
      childrenMaxOverflow
    );
    return Math.max(acc, currentMaxOverflow);
  }, 0);
}

/**
 * Re-calculates a row's top and bottom based on its widgets.
 */
export function updateRowCoords(row: WidgetRow) {
  const inf = Number.POSITIVE_INFINITY;
  row.top = row.widgets.reduce((acc, curr) => Math.min(acc, curr.position.top), inf);
  row.bottom = row.widgets.reduce((acc, curr) => Math.max(acc, curr.position.bottom), 0);
}

/**
 * Moves a widget the specified amount down and right and
 * recalculates if the widget is overflowing.
 */
export function moveWidget(widget: LiveWidget, right: number, down: number, canvasWidth: number) {
  widget.position.left += right;
  widget.position.right += right;
  widget.position.top += down;
  widget.position.bottom += down;
  widget.position.isOverflowing = widget.position.right > canvasWidth;

  widget.children.forEach((child) => {
    moveWidget(child, right, down, canvasWidth);
  });
}

/**
 * Stretches widgets to fill their row's entire width.
 * Assumes the row begins at 0 and its width is 'widthToFill'.
 */
export function stretchWidgetsToFillWidth(widgets: LiveWidget[], widthToFill: number) {
  // Fill all spaces to the right.
  widgets.forEach((w, idx) => {
    const right = getMoveRightPotential(widgets, idx, widthToFill);
    increaseWidgetWidth(w, 'right', right, widthToFill);
  });

  // Fill all spaces to the left.
  widgets.forEach((w, idx) => {
    const left = getMoveLeftPotential(widgets, idx);
    increaseWidgetWidth(w, 'left', left, widthToFill);
  });
}

/**
 * Increases the width of a widget to a specified direction.
 */
export function increaseWidgetWidth(
  widget: LiveWidget,
  direction: 'left' | 'right',
  increase: number,
  canvasWidth: number
) {
  if (increase === 0) {
    return;
  }

  if (direction === 'right') {
    widget.position.right += increase;
  } else {
    widget.position.left -= increase;
  }

  if (isWidgetGroup(widget)) {
    const originalLeft = widget.position.left;
    const groupWidth = widget.position.right - widget.position.left;

    // Move all widgets in the group so they start at left = 0.
    moveWidget(widget, -originalLeft, 0, canvasWidth);

    // Stretch child widgets to fill the group width.
    stretchWidgetsToFillWidth(widget.children, groupWidth);

    // Move all widgets in the group back so they start at their original left.
    moveWidget(widget, originalLeft, 0, canvasWidth);
  }
}
