import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Button from '@components/Button';
import I18n from '@components/I18n';
import LogoIcon from '@components/LogoIcon';
import { buildImageUrl } from '@core/utils';
import { useSelector } from '@src/redux/useSelector';
import classNames from 'classnames';
import { uploadService } from '@core/UploadService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    padding: '22px 44px',
  },
  dropzone: {
    width: ({ dimensions }: any) => (dimensions && dimensions.width ? dimensions.width + 30 : 283),
    height: 70,
    backgroundColor: theme.app.createWidgetAvailableData,
    padding: '14px 15px',
    margin: '12px 0',
    borderRadius: 5,
  },
  rightSide: {
    marginLeft: 29,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  labelWrapper: {
    marginBottom: 4,
  },
  moreInfo: {
    fontWeight: 'normal',
  },
}));

const labelsMap = [
  { label: 'white-labeling.logo.support-formats', moreInfo: 'PNG, JPG, JPEG' },
  { label: 'white-labeling.logo.maximum-size', moreInfo: '100Kb' },
  { label: 'white-labeling.logo.recommended-rect', moreInfo: '150px X 25px' },
  { label: 'white-labeling.logo.recommended-square', moreInfo: '25px X 25px' },
];

const LabelRow = ({ label, moreInfo, classes }) => (
  <div className={classNames(classes.labelWrapper, 'ellipsis-overflow')}>
    <I18n>{label}</I18n>: <span className={classes.moreInfo}>{moreInfo}</span>
  </div>
);

function Logo(props) {
  const { displaySettingsData, setDisplaySettingsData } = props;
  const [dimensions, setDimensions] = useState<any>({});
  const logo = useMemo(
    () => displaySettingsData.freeLogo?.content?.logoUrl,
    [displaySettingsData.freeLogo?.content?.logoUrl]
  );
  const userIsFree = useSelector((state) => state.config.licensingType === 'FREE');

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: 'image/png,image/jpg,image/jpeg',
    maxSize: 100000,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles[0]) {
        const setLogo = (res) => {
          setDisplaySettingsData((prevState) => ({
            ...prevState,
            freeLogo: { ...prevState.freeLogo, content: res },
            premiumLogo: !userIsFree
              ? { ...prevState.premiumLogo, content: res }
              : { ...prevState.premiumLogo },
          }));
        };
        uploadService.upload({
          getUploadType: 'uploadLogo',
          fileName: acceptedFiles[0].name,
          file: acceptedFiles[0],
          callback: setLogo,
        });
      }
    },
    onDragEnter: () => {
      console.log('onDragEnter');
    },
  });

  const classes = useStyles({ props, dimensions });

  return (
    <div className={classes.wrapper}>
      <div className="ellipsis-overflow">
        <I18n>white-labeling.logo.title</I18n>
        <div {...getRootProps({ className: classNames(classes.dropzone, 'ellipsis-overflow') })}>
          <input {...getInputProps()} />
          {logo ? (
            <LogoIcon
              height={42}
              maxWidth={252}
              onChange={setDimensions}
              logo={buildImageUrl(logo)}
            />
          ) : (
            <I18n>white-labeling.logo.drag-and-drop</I18n>
          )}
        </div>
        <div className={classNames(classes.moreInfo, 'ellipsis-overflow')}>
          {userIsFree && <I18n>white-labeling.logo.logo-premium</I18n>}
        </div>
      </div>
      <div className={classes.rightSide}>
        {labelsMap.map((label) => (
          <LabelRow {...label} classes={classes} key={label.label} />
        ))}

        <Button styles={{ width: 102, marginTop: 2 }} onClick={open}>
          <I18n>general.browse</I18n>
        </Button>
      </div>
    </div>
  );
}

export default Logo;
