import I18n from '@components/I18n';
import React, { useMemo } from 'react';

import styles from './SchedulerTrigger.scss';
import { i18nService } from '@core/i18n/I18nService';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import TriggerTimePicker from '../TriggerTimePicker/TriggerTimePicker';

function SchedulerTrigger(props) {
  const {
    templateData,
    setTemplateData,
    validation,
    nameValidationError,
    showEditOption = true,
  } = props;
  const { timeFrame, trigger, startDate, endDate } = templateData;

  const timeFrameOptions = useMemo(
    () => [
      {
        value: 'DAILY',
        label: 'events-template.edit-template.time-frame.daily',
      },
      {
        value: 'WEEKLY',
        label: 'events-template.edit-template.time-frame.weekly',
      },
      {
        value: 'MONTHLY',
        label: 'events-template.edit-template.time-frame.monthly',
      },
      {
        value: 'YEARLY',
        label: 'events-template.edit-template.time-frame.yearly',
      },
    ],
    []
  );

  return (
    <div className={styles.section}>
      <div className={styles.row}>
        <div className={styles.header}>
          <I18n>events-template.edit-template.scheduler</I18n>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.timeFrame}>
          <RadioGroup
            style={{ pointerEvents: showEditOption ? 'auto' : 'none' }}
            value={timeFrame || ''}
            classes={{ root: styles.radioGroupRoot }}
            onChange={(e) => setTemplateData(e.target.value, 'timeFrame')}>
            {timeFrameOptions.map((opt) => (
              <FormControlLabel
                key={opt.value}
                classes={{ label: styles.radioLabel }}
                value={opt.value}
                control={<Radio />}
                label={i18nService.translate(opt.label)}
              />
            ))}
          </RadioGroup>
        </div>
        <TriggerTimePicker {...props} />
      </div>
    </div>
  );
}

export default SchedulerTrigger;
