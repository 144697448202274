import * as template from 'lodash/template';
import { getState } from '@redux/store';
import { setLanguage } from '@redux/config';
import { AppLanguage } from './i18n.interface';
import defaultDictionary from '@assets/defaultDictionary.json';
import moment from 'moment';
class I18nService {
  /**
   * Translates a key into a string based on the selected language
   * and the dictionary.
   */
  translate(key: string, lang?: any, args?: Record<string, any>): string {
    // lang = getState().config.dictionary;
    const dictionary = getState().config.dictionary || defaultDictionary;

    const translation = dictionary[key] && dictionary[key].val;
    if (!translation) {
      return key;
    }
    return template(translation)(args || {});
  }

  /**
   * Changes the selected language.
   */
  changeLanguage(language: AppLanguage) {
    setLanguage(language);
  }

  changeMomentLang(options) {
    moment.updateLocale(moment.locale(), {
      months: options.months,
      monthsShort: options.shortMonths,
      weekdays: options.days,
      weekdaysShort: options.shortDays,
      weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    });
  }
  changeApexLang(dictionary) {
    const getTranslate = (key) => dictionary[key] && dictionary[key].val;
    const options = {
      months: [
        getTranslate('graphs-month-january'),
        getTranslate('graphs-month-february'),
        getTranslate('graphs-month-march'),
        getTranslate('graphs-month-april'),
        getTranslate('graphs-month-may'),
        getTranslate('graphs-month-june'),
        getTranslate('graphs-month-july'),
        getTranslate('graphs-month-august'),
        getTranslate('graphs-month-september'),
        getTranslate('graphs-month-october'),
        getTranslate('graphs-month-november'),
        getTranslate('graphs-month-december'),
      ],
      shortMonths: [
        getTranslate('graphs-short-month-jan'),
        getTranslate('graphs-short-month-feb'),
        getTranslate('graphs-short-month-mar'),
        getTranslate('graphs-short-month-apr'),
        getTranslate('graphs-short-month-may'),
        getTranslate('graphs-short-month-jun'),
        getTranslate('graphs-short-month-jul'),
        getTranslate('graphs-short-month-aug'),
        getTranslate('graphs-short-month-sep'),
        getTranslate('graphs-short-month-oct'),
        getTranslate('graphs-short-month-nov'),
        getTranslate('graphs-short-month-dec'),
      ],
      days: [
        getTranslate('graphs-days-sunday'),
        getTranslate('graphs-days-monday'),
        getTranslate('graphs-days-tuesday'),
        getTranslate('graphs-days-wednesday'),
        getTranslate('graphs-days-thursday'),
        getTranslate('graphs-days-friday'),
        getTranslate('graphs-days-saturday'),
      ],
      shortDays: [
        getTranslate('graphs-short-days-sun'),
        getTranslate('graphs-short-days-mon'),
        getTranslate('graphs-short-days-tue'),
        getTranslate('graphs-short-days-wed'),
        getTranslate('graphs-short-days-thu'),
        getTranslate('graphs-short-days-fri'),
        getTranslate('graphs-short-days-sat'),
      ],
      toolbar: {
        exportToSVG: getTranslate('graphs-toolbar-exportToSVG'),
        exportToPNG: getTranslate('graphs-toolbar-exportToPNG'),
        menu: getTranslate('graphs-toolbar-menu'),
        selection: getTranslate('graphs-toolbar-selection'),
        selectionZoom: getTranslate('graphs-toolbar-selectionZoom'),
        zoomIn: getTranslate('graphs-toolbar-zoomIn'),
        zoomOut: getTranslate('graphs-toolbar-zoomOut'),
        pan: getTranslate('graphs-toolbar-pan'),
        reset: getTranslate('graphs-toolbar-reset'),
      },
    };
    if (window['Apex']) {
      window['Apex'].chart = {
        locales: [
          {
            name: 'en',
            options,
          },
        ],
        defaultLocale: 'en',
      };
    }
    this.changeMomentLang(options);
  }
}

export const i18nService = new I18nService();
