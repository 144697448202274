import React, { useMemo, useState, useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import styles from './TableCustomizationRow.scss';
import { TableCustomizationRowProps } from './TableCustomizationRow.interface';
import MaterialCheckbox from '@components/Checkbox';
import {
  handleNameBlur,
  handleNameChange,
  handleSymbolChange,
  handleSymbolBlur,
  onSelectedSymbolSide,
  handleDecimalDigitChange,
  handleDecimalDigitBlur,
  handleWidthChange,
  handleWidthBlur,
  onSelectedDateTimeFormat,
  validateNameError,
} from '../TableCustomization.utils';
import Icon from '@components/Icon';
import Select from '@components/Select';
import { getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import { getColumnName } from '@core/canvas/widget.utils';

function TableCustomizationRow(props: TableCustomizationRowProps) {
  const { widgetData, column, columnCfg, provided, isLast, columnsLength, defaultDecimalDigits } =
    props;
  const [editedColumn, setEditedColumn] = useState(column);
  const [displayNameHasError, setDisplayNameHasError] = useState(false);
  const currentValue = useMemo(
    () =>
      editedColumn.width
        ? editedColumn.width
        : editedColumn.width === 0
        ? ''
        : Number.isInteger(100 / columnsLength)
        ? 100 / columnsLength
        : (100 / columnsLength).toFixed(2),
    [editedColumn, columnsLength]
  );
  const format = useMemo(() => {
    if (column?.tagType) {
      return column?.tagType;
    }
    if (!columnCfg[7]) {
      return null;
    }
  }, [column, columnCfg]);

  const dateTimeOptions = useMemo(() => getDateTimeOptions(format), [format]);

  useEffect(() => {
    if (!column.dateTimeFormat && format) {
      const tempDateTimeFormat =
        format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null;
      onSelectedDateTimeFormat(editedColumn, tempDateTimeFormat, setEditedColumn, props);
    }
    validateNameError(editedColumn.displayName, setDisplayNameHasError);
  }, []);

  useEffect(() => {
    if (column.tagType && !editedColumn.tagType) {
      setEditedColumn(column);
    }
  }, [column, editedColumn]);

  const showDecimalDigits =
    editedColumn.decimalDigits ||
    editedColumn.decimalDigits === 0 ||
    editedColumn.decimalDigits === '';

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.dragIndicator, isLast && styles.lastRow)}
        style={columnCfg[0].style}
        {...provided.dragHandleProps}>
        <Icon type="drag" />
      </div>
      <div style={columnCfg[1].style} className={classNames('ellipsis-overflow')}>
        {editedColumn.operation
          ? `${getColumnName(widgetData, editedColumn)} ${editedColumn.operation.toLowerCase()}`
          : getColumnName(widgetData, editedColumn)}
      </div>
      <div style={columnCfg[2].style}>
        <InputBase
          title={editedColumn.displayName}
          id={styles.inputStyle}
          className={classNames(
            styles.containerInput,
            displayNameHasError && styles.containerInputError
          )}
          value={editedColumn.displayName}
          onBlur={() => handleNameBlur(editedColumn, setEditedColumn, props)}
          onChange={(e) =>
            handleNameChange(e.target.value, editedColumn, setEditedColumn, setDisplayNameHasError)
          }
        />
      </div>
      <div style={columnCfg[3].style}>
        <InputBase
          autoComplete="off"
          title={`${currentValue}`}
          className={styles.containerInput}
          id={styles.inputStyle}
          value={currentValue}
          type="number"
          onChange={(e) => {
            handleWidthChange(+e.target.value, setEditedColumn, editedColumn);
          }}
          onBlur={(e) => {
            handleWidthBlur(editedColumn, props, setEditedColumn);
          }}
          inputProps={{
            min: 1,
            max: 100,
          }}
        />
      </div>
      <div style={columnCfg[4].style}>
        <InputBase
          autoComplete="off"
          title={editedColumn.symbol}
          className={styles.containerInput}
          id={styles.inputStyle}
          value={editedColumn.symbol || ''}
          onBlur={() => handleSymbolBlur(editedColumn, props)}
          onChange={(e) => handleSymbolChange(e.target.value, editedColumn, setEditedColumn)}
        />
      </div>
      <div style={columnCfg[5].style}>
        <MaterialCheckbox
          color="primary"
          disabled={!editedColumn.symbol || editedColumn.symbol == ''}
          onChange={() => onSelectedSymbolSide(editedColumn, setEditedColumn, props)}
          checked={editedColumn.showSymbolLeft || false}
        />
      </div>
      <div style={columnCfg[6].style}>
        <InputBase
          type="number"
          disabled={!showDecimalDigits}
          className={styles.containerInput}
          id={styles.inputStyle}
          inputProps={{ min: 0, max: 9 }}
          value={editedColumn.decimalDigits}
          onChange={(e) => handleDecimalDigitChange(e.target.value, editedColumn, setEditedColumn)}
          onBlur={() => handleDecimalDigitBlur(editedColumn, props, setEditedColumn)}
        />
      </div>
      {columnCfg[7] && dateTimeOptions && (
        <div style={columnCfg[7].style}>
          <Select
            options={dateTimeOptions}
            value={
              editedColumn.dateTimeFormat &&
              dateTimeOptions.find((opt) => opt.value === editedColumn.dateTimeFormat)
            }
            onChange={(option) =>
              onSelectedDateTimeFormat(editedColumn, option.value, setEditedColumn, props)
            }
          />
        </div>
      )}
    </div>
  );
}

export default TableCustomizationRow;
