import * as RI from '../../redux.interface.d';

export const setRoutersData = (routersData: RI.RoutersState): RI.StoreAction => ({
  type: 'routers/SET_ROUTERS_DATA',
  payload: routersData
});

export const addRoutersData = (routersData: RI.RoutersState): RI.StoreAction => ({
  type: 'routers/ADD_ROUTERS_DATA',
  payload: routersData
});

export const refreshRoutersTableData = (): RI.StoreAction => ({
  type: 'routers/REFRESH_ROUTERS_DATA',
  payload: null
});

export const removeRoutersData = (): RI.StoreAction => ({
  type: 'routers/REMOVE_ROUTERS_DATA',
  payload: null
});
