import * as Yup from 'yup';

export function getAddLocalTagFieldCfg(widgetData, nameValidation) {
  const NAME_MAX_LENGTH = 50;

  return [
    {
      label: 'general.name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validate: (values) => {
        if (
          (nameValidation.name === values.name || nameValidation.name === values.name) &&
          nameValidation.message
        ) {
          return nameValidation.message;
        }
      },
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(50, 'my-details.name-message')
        .min(1, 'my-details.name-message')
        .test(
          'isUnique',
          'create-widget-page.create-widget.step-three.local-tag-name-exists',
          function (this, value) {
            const id = this.options['parent']?.valueId;
            return !widgetData.localTags.some((item) => item.id != id && item.name === value);
          }
        ),
      onLostFocus: (values, setFieldValue) => {
        setFieldValue('name', values.name);
      },
      maxLength: NAME_MAX_LENGTH,
      removeLeadingSpaces: true,
    },
  ];
}
