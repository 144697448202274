import React from 'react';
import moment from 'moment-timezone';
import styles from './DateTimeField.scss';
import { useSelector } from '@src/redux/useSelector';

function DateTimeField(props: any) {
  const { field } = props;
  const value = props[field];
  const orgTimezone = useSelector((state) => state.config && state.config.timezone);
  const selectOrgTimezone = useSelector(
    (state) => state.organizations && state.organizations.timezone
  );
  const date = moment(value)
    .tz(selectOrgTimezone || orgTimezone)
    .format('DD/MM/YYYY');
  const time = moment(value)
    .tz(selectOrgTimezone || orgTimezone)
    .format('HH:mm');

  return (
    value && (
      <div title={`${date} | ${time}`}>
        <span className={styles.text}>{date}</span>
        <span className={styles.divider}>|</span>
        <span className={styles.text}>{time}</span>
      </div>
    )
  );
}

export default DateTimeField;
