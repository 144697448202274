import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';

export const stepOneValidation = (widgetData) => {
  const { type, tags, tagTypes, variables, assetProperties, assetTypes, allAssetTypes } =
    widgetData;

  const validation = getConfigValueFromWidgetSettings(type, 'stepValidation');

  return allAssetTypes
    ? assetProperties.length
    : assetTypes.length
    ? validation[1]
      ? validation[1](widgetData)
      : assetProperties.length || tags.length || tagTypes.length || variables.length
    : false;
};

export const stepTwoValidation = (widgetData) => {
  const { scope } = widgetData;
  return stepOneValidation(widgetData) && scope;
};

export const stepThreeValidation = (widgetData) => {
  const { type } = widgetData;
  const validation = getConfigValueFromWidgetSettings(type, 'stepValidation');
  if (
    widgetData.calculations?.some(
      (calc) => calc && calc.action && calc.action !== 'NONE' && !calc.actionValue
    )
  ) {
    return false;
  }

  if (validation && validation[3] && validation[3](widgetData)) return false;
  return true;
};

export const stepFourValidation = (widgetData) => {
  const { type } = widgetData;
  const validation = getConfigValueFromWidgetSettings(type, 'stepValidation');
  return (
    widgetData.customization &&
    stepThreeValidation(widgetData) &&
    (!validation || !validation[4] || validation[4](widgetData))
  );
};

export const stepFiveValidation = (widgetData) => {
  return stepFourValidation(widgetData);
};
