// The canvas grid is based on Xpx x Xpx cells.
const gridCellSizePx = 8;

const getOption = (multiple) => ({
  value: multiple * gridCellSizePx,
  label: `${multiple * gridCellSizePx}px`,
});

const canvasWidthOptions = {
  desktop: [getOption(160), getOption(180), getOption(200), getOption(220), getOption(240)],
  tablet: [getOption(75), getOption(96), getOption(100), getOption(120), getOption(160)],
  mobile: [getOption(45), getOption(48), getOption(51)],
};

export const editorConfig = {
  gridCellSizePx,
  canvasWidthOptions,
  defaultCanvasHeight: 544,
  defaultCanvasWidth: canvasWidthOptions[0],
};
