import React, { useMemo } from 'react';
import I18n from '@components/I18n';

function DataSourceStatus(props) {
  const { status } = props;

  return <>{status === 1 ? <I18n>events-template.create-data.source.draft</I18n> : null}</>;
}

export default DataSourceStatus;
