import React, { useEffect, useMemo, useState } from 'react';
import { Router } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import history from '@core/history';
import LoginPage from '@pages/LoginPage';
import MainPage from '@pages/MainPage';
import { LoadScript } from '@react-google-maps/api';
import Spinner from '@components/Spinner';
import SignUp from '@pages/LoginPage/SignUp';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import FirstLoginPage from '@pages/FirstLoginPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import SelectOrganizationPage from '@pages/SelectOrganizationPage/SelectOrganizationPage';
import TermsAndConditionsPage from '@pages/TermsAndConditionsPage';
import SraErrorPage from '@pages/SraErrorPage';
import { useErrorHandlingModal } from '@core/hooks/useErrorHandlingModal';
import { useWidgetModal } from '@core/hooks/useWidgetModal';
import { checkIsMobileView, isSubDomain } from '@core/utils';
import { useSelector } from '@src/redux/useSelector';
import TwoFactorAuthenticationPage from '@pages/TwoFactorAuthenticationPage';

function App() {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  useErrorHandlingModal();
  useWidgetModal();
  checkIsMobileView(window);
  useEffect(() => {
    window.addEventListener('resize', (e: Event) => {
      checkIsMobileView(e.currentTarget);
    });
  }, []);

  const hideSignUp = useMemo(() => isSubDomain(), []);
  const isPdfRenderReady = useSelector((state) => state.viewport.isPdfRenderReady);

  if (isMobileView) {
    document.body.style.height = '100%';
  }

  return (
    <>
      {isPdfRenderReady && <div id="renderPDF"></div>}
      <LoadScript
        loadingElement={<Spinner />}
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={['places']}>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            {!hideSignUp && <Route exact path="/login/signUp" component={SignUp} />}
            <Route exact path="/login/challenge" component={TwoFactorAuthenticationPage} />
            <Route path="/first-login" component={FirstLoginPage} />
            <Route path="/main" component={MainPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <Route exact path="/select-organization" component={SelectOrganizationPage} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
            <Route exact path="/sra/error" component={SraErrorPage} />
            <Redirect exact from="/" to="/login" />
          </Switch>
        </Router>
        <div id="map"></div>
      </LoadScript>
    </>
  );
}

export default App;
