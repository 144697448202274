type Callback<T> = (value: T) => any;

interface Observer<T> {
  cb: Callback<T>;
}

interface Subscription {
  unsubscribe(): void;
}

/**
 * An observable container containing a current value
 * and can be subscribed to to listen to changes in the
 * value.
 */
export class Subject<T> {
  private observers: Array<Observer<T>> = [];
  private value: T;

  constructor(value: T) {
    this.value = value;
  }

  next(value: T) {
    this.value = value;
    this.observers.forEach(({ cb }) => cb(this.value));
  }

  subscribe(cb: Callback<T>): Subscription {
    const ref = { cb };
    this.observers.push(ref);
    cb(this.value);
    const unsubscribe = () => {
      this.observers = this.observers.filter(s => s !== ref);
    };
    return { unsubscribe };
  }

  getValue(): T {
    return this.value;
  }
}
