import { TableColunm } from '@components/Table/table.interface';
import { canvasService } from '@core/canvas/CanvasService';
import { httpService } from '@core/http/HttpService';
import { editCanvasWidgetById } from '@src/redux/dashboardEditor';
import { CanvasWidget } from '@src/redux/redux.interface';
import { dispatch, getState } from '@src/redux/store';
import { adjustHeightOrWidthForSelectedWidgets, adjustSizeOptionsMap } from './Adjustment.utils';
import { calcAlignedWidgetCoordinates } from './Alignment.utils';
import TextField from '@components/Table/TableFields/TextField';
import { WidgetMapItem } from '@src/core/canvas/widgetMap.interface';
import { value } from '@src/core/canvas/widgets/value';
import { columns } from '@src/core/canvas/widgets/columns';
import { table } from '@src/core/canvas/widgets/table';
import { pie } from '@src/core/canvas/widgets/pie';
import { map } from '@src/core/canvas/widgets/map';
import { radialGauge } from '@src/core/canvas/widgets/radialGauge';
import { line } from '@src/core/canvas/widgets/line';
import { text } from '@src/core/canvas/widgets/text';
import { image } from '@src/core/canvas/widgets/image';
import { linearGauge } from '@src/core/canvas/widgets/linearGauge';
import { alarms } from '@src/core/canvas/widgets/alarms';
import { imageByValue } from '@src/core/canvas/widgets/imageByValue';
import { vnc } from '@src/core/canvas/widgets/vnc';
import { web } from '@src/core/canvas/widgets/web';
import { progressBar } from '@src/core/canvas/widgets/progressBar';
import { conditionalValue } from '@src/core/canvas/widgets/conditionalValue';
import { switchWidget } from '@src/core/canvas/widgets/switch';
import { updateAsset } from '@src/core/canvas/widgets/updateAsset';
import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';
import { vpn } from '@core/canvas/widgets/vpn';
import { combo } from '@core/canvas/widgets/combo';
import { heatmap } from '@core/canvas/widgets/heatmap';

export const allWidgets: Array<WidgetMapItem> = [
  value,
  text,
  image,
  imageByValue,
  conditionalValue,
  progressBar,
  table,
  map,
  alarms,
  columns,
  line,
  pie,
  radialGauge,
  linearGauge,
  switchWidget,
  updateAsset,
  web,
  vnc,
  vpn,
  combo,
  heatmap,
];

export const options = {
  align: ['left', 'center', 'right', 'top', 'middle', 'bottom'],
  adjustSize: ['tallest', 'shortest', 'widest', 'narrowest'],
};

export function getExtremeValue(values: Array<number>, type: string) {
  switch (type) {
    case 'min':
      return Math.min(...values);
    case 'max':
      return Math.max(...values);
  }
}

export function getMiddleValue(start: number, end: number) {
  return Math.ceil((start + end) / 2);
}

export function calculateWidgets(
  selectedWidgets: Array<CanvasWidget>,
  option: string
): {
  newWidgets: Array<CanvasWidget>;
  notAllowedWidgets: Array<CanvasWidget>;
} {
  if (options.align.some((opt) => opt === option)) {
    return calcAlignedWidgetCoordinates(selectedWidgets, option);
  } else {
    return adjustHeightOrWidthForSelectedWidgets(selectedWidgets, option);
  }
}

export function getIntersectingWidgets(
  calculatedWidgets: Array<CanvasWidget>,
  allWidgetsWithCalculatedCoords: Array<any>
) {
  return (
    calculatedWidgets.filter((calcWidget) =>
      canvasService.isIntersectingWidgetArrangement(allWidgetsWithCalculatedCoords, {
        id: calcWidget.id,
        coords: { x: calcWidget.x, y: calcWidget.y, w: calcWidget.w, h: calcWidget.h },
      })
    ) || []
  );
}

export function checkIsWidgetIntersecting(
  allWidgetsWithCalculatedCoords: Array<any>,
  widget: CanvasWidget
): boolean {
  return canvasService.isIntersectingWidgetArrangement(allWidgetsWithCalculatedCoords, {
    id: widget.id,
    coords: { x: widget.x, y: widget.y, w: widget.w, h: widget.h },
  });
}

export function getAllWidgetsWithCalculatedCoords(calculatedWidgets: Array<CanvasWidget>) {
  const canvasWidgets = getState().dashboardEditor.canvasWidgets;
  return canvasWidgets.map((w) => {
    const calculatedWidget = calculatedWidgets.find((calcWidget) => calcWidget.id === w.id);
    if (calculatedWidget) {
      return {
        id: calculatedWidget.id,
        coords: {
          x: calculatedWidget.x,
          y: calculatedWidget.y,
          w: calculatedWidget.w,
          h: calculatedWidget.h,
        },
      };
    } else {
      return {
        id: w.id,
        coords: { x: w.x, y: w.y, w: w.w, h: w.h },
      };
    }
  });
}

export function updateWidgetLocation(widget: CanvasWidget, currentLayout: string) {
  httpService.api({
    type: 'updateWidgetState',
    urlParams: { widgetId: widget.id },
    data: {
      x: widget.x,
      y: widget.y,
      w: widget.w,
      h: widget.h,
      layoutStateType: currentLayout,
    },
    disableBI: true,
  });

  dispatch(
    editCanvasWidgetById(
      widget.id,
      { x: widget.x, y: widget.y, w: widget.w, h: widget.h },
      currentLayout
    )
  );
}

export const rowErrorsCfg: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'general.widget-name',
    field: 'name',
    style: { width: '60%', textAlign: 'left' },
  },
  {
    Component: TextField,
    label: 'general.type',
    field: 'type',
    style: { width: '40%', textAlign: 'left' },
  },
];

export function openErrorModal(
  calculatedWidgets: {
    newWidgets: CanvasWidget[];
    notAllowedWidgets: CanvasWidget[];
  },
  option: string
) {
  modalService.openModal('alert', {
    text:
      option === 'shortest' || option === 'narrowest'
        ? adjustSizeOptionsMap.get(option).dimension === 'w'
          ? 'dashboard-editor.adjust-widget-size.width-not-allowed.text'
          : 'dashboard-editor.adjust-widget-size.height-not-allowed.text'
        : 'dashboard-editor.adjust-widget-size.widget-overlapping-detected.text',
    iconType: 'attention_image',
    btnText: 'general.close',
    headerText:
      option === 'shortest' || option === 'narrowest'
        ? adjustSizeOptionsMap.get(option).dimension === 'w'
          ? 'dashboard-editor.adjust-widget-size.width-not-allowed.header'
          : 'dashboard-editor.adjust-widget-size.height-not-allowed.header'
        : 'dashboard-editor.adjust-widget-size.widget-overlapping-detected.header',
    tableCfg: rowErrorsCfg,
    tableData: calculatedWidgets.notAllowedWidgets
      .map((widget) => ({
        name: widget.name,
        type: i18nService.translate(
          `edit-dashboard.widget-name.${allWidgets.find((w) => w.id === widget.type).id}`
        ),
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
    showCloseBtn: true,
    customStyles: {
      customWrapperStyle: { width: '500px' },
      customIconStyle: { marginTop: '26px' },
      customContentStyle: {
        height: calculatedWidgets.notAllowedWidgets.length === 1 ? '300px' : '400px',
      },
    },
  });
}

export function mergeWidgets(targetArray, sourceArray) {
  return targetArray.map((targetWidget) => {
    const widget = sourceArray.find((sourceWidget) => sourceWidget.id === targetWidget.id);
    if (widget) {
      return widget;
    }
    return targetWidget;
  });
}
