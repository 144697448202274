import React from 'react';
import { connect, getIn } from 'formik';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import styles from './FormikPlcTypeSelect.scss';
import { FormikPlcTypeSelectProps } from './FormikPlcTypeSelect.interface';

const FormikPlcTypeSelect = (props: FormikPlcTypeSelectProps) => {
  const {
    formik,
    name,
    label,
    disabled,
    className,
    options,
    allowRowsEllipsis,
    removeLabelEllipsis,
    value,
    onChange,
  } = props;

  return (
    <>
      <div className={classNames(styles.fieldWrapper, className)}>
        <label
          className={classNames(styles.fieldLabel, !removeLabelEllipsis && 'ellipsis-overflow')}>
          <I18n noEllipsis={removeLabelEllipsis}>{label}</I18n>:
        </label>
        <div className={styles.fieldInput}>
          <div className={styles.formikPlcInputWrapper}>
            <Select
              value={options.find((opt) => opt.value === value)}
              options={options}
              onChange={onChange}
              maxMenuHeight={180}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormikPlcTypeSelect;
