import { localToServer } from '@pages/CreateWidgetPage/widgets.utils';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { pick } from 'lodash';
import moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { getState } from '@src/redux/store';
import { staticDictionary } from '@core/i18n/dictionary';
import { dateTimeRegex, dateRegex } from '@core/utils';

export const getXaxisCategories = (results, groupByColumns, dateTimeFormat?, timezone?) =>
  results.map((x) => {
    let str = '';
    groupByColumns.map((y, idx) => {
      const value =
        y.operation === 'WEEKLY'
          ? `${convertStatusName(
              y,
              x[y.name].split(' : ')[0],
              null,
              dateTimeFormat,
              timezone
            )} : ${convertStatusName(y, x[y.name].split(' : ')[1], null, dateTimeFormat, timezone)}`
          : convertStatusName(y, x[y.name], null, dateTimeFormat, timezone);

      if (idx !== 0) return (str += `- ${value}`);
      return (str += `${value} `);
    });
    return str;
  });

export const getUniqueXaxisCategories = (results, groupByColumns, dateTimeFormat?, timezone?) => [
  ...new Set(getXaxisCategories(results, groupByColumns, dateTimeFormat, timezone)),
];

export const convertStatusName = (column, value, type?, dateTimeFormat?, timezone?: string) => {
  if (
    column.valueId === 5 &&
    column.valueType === 'ASSET_PROPERTY' &&
    (!column.operation || column.operation === '')
  ) {
    return staticDictionary[`enum.${value}`];
  }
  if (
    !type &&
    value &&
    typeof value === 'string' &&
    (dateTimeRegex.test(value.split('.')[0]) || dateRegex.test(value.split('.')[0]))
  ) {
    return buildDateTime(value, dateTimeFormat, 'momentFormat', timezone, column.valueType);
  }
  return value;
};

export const getFilters = (dashboardFilters?: any) => {
  const {
    organizationsFilter,
    assetTypesFilter,
    assetFilter,
    geoFilter,
    dateFilter,
    dateFilterFrom,
    dateFilterTo,
  } = dashboardFilters ? dashboardFilters : getState().dashboardEditor;

  return {
    dashboardFilters: {
      organizationsFilter: organizationsFilter && organizationsFilter.map((f) => f.id),
      assetTypesFilter: assetTypesFilter && assetTypesFilter.map((f) => f.id),
      assetFilter: assetFilter.map((f) => f.id),
      geoFilter,
      dateFilter,
      dateFilterFrom,
      dateFilterTo,
    },
  };
};

export const getDefaultPreviewData = (widgetData: WidgetData) => {
  const widgetServerData = localToServer(widgetData);
  return {
    ...getFilters(),
    widgetDetails: pick(widgetServerData, [
      'assetTypes',
      'assetProperties',
      'allAssetTypes',
      'variables',
      'tags',
      'tagTypes',
      'localTags',
      'type',
      'scope',
      'groupBy',
      'metrics',
      'filters',
      'sorts',
      'customizationMetrics',
      'calculations',
    ]),
  };
};

export const dateTimeOptionsMap = {
  SHORT_DATE: 'shortDate',
  DATE: 'date',
  LONG_DATE: 'longDate',
  TIME_FORMAT_ONE: 'timeFormat1',
  TIME_FORMAT_TWO: 'timeFormat2',
};

export const buildDateTime = (date, tempType, key, timezone?: string, valueType?: string) => {
  const config = getState().config?.whiteLabelDetails?.dateTime?.content;
  const isPdfRender = getState().viewport?.isPdfRender;
  const onlyDate = dateRegex.test(date?.split('.')[0]);
  let momentDate = onlyDate
    ? moment(`${date?.split('.')[0]} 00:00:00`)
    : date?.includes('+')
    ? moment(date).local()
    : valueType === 'VARIABLE'
    ? moment(`${date?.split('.')[0]}`).local()
    : moment(`${date?.split('.')[0]}+00:00`).local();

  if (isPdfRender) {
    momentDate = momentTimezone(momentDate).tz(timezone);
  }

  const type = tempType || (onlyDate ? 'DATE' : 'DATE_TIME_FORMAT_ONE');

  switch (type) {
    case 'DATE_TIME_FORMAT_ONE':
      return momentDate.format(
        `${config.date[key]}${onlyDate ? '' : ` ${config.timeFormat1[key]}`}`
      );
    case 'DATE_TIME_FORMAT_TWO':
      return momentDate.format(
        `${config.date[key]}${onlyDate ? '' : ` ${config.timeFormat2[key]}`}`
      );
    case 'TIME_FORMAT_ONE':
    case 'TIME_FORMAT_TWO':
      return onlyDate ? '' : momentDate.format(config[dateTimeOptionsMap[type]][key]);
    case 'FIXED_DATE':
      return momentDate.format('YYYY-MM-DD');
    case 'FIXED_DATETIME':
      return momentDate.format('YYYY-MM-DDTHH:mm:ss');
    default:
      return momentDate.format(config[dateTimeOptionsMap[type]][key]);
  }
};
