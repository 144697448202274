import React, { useState } from 'react';
import { i18nService } from '@core/i18n/I18nService';
import 'moment-duration-format';
import UpdateValueInput from './UpdateValueInput';

function VariableValueComponent(props) {
  const {
    value,
    valueType,
    editedVariableId,
    setEditedVariableId,
    editedAssetId,
    setEditedAssetId,
    assetTypeOrAssetId,
    setVariablesList,
    assetId = null,
    assetTypeId = null,
    inputStyle,
    origin,
    order,
  } = props;
  const variableId = props.variableId || props.id;
  const [newValue, setNewValue] = useState(value);
  const [valueToDisplay, setValueToDisplay] = useState(
    valueType === 'BOOLEAN' ? i18nService.translate(`general.${value ? 'on' : 'off'}`) : value
  );

  const isEditMode =
    origin === 'variableValuesPerAssetModal' && assetId && editedAssetId
      ? assetId === editedAssetId
      : origin === 'organizationAssets'
      ? assetId && editedAssetId && assetId === editedAssetId && variableId === editedVariableId
      : variableId === editedVariableId;

  function onEditDone() {
    setEditedVariableId && setEditedVariableId(null);
    setEditedAssetId && setEditedAssetId(null);
  }

  return (
    <>
      {!isEditMode ? (
        <div title={valueToDisplay}>{valueToDisplay}</div>
      ) : (
        <UpdateValueInput
          origin={origin}
          onCancelEditClicked={onEditDone}
          {...props}
          valueType={valueType}
          value={value}
          newValue={newValue}
          setNewValue={setNewValue}
          valueToDisplay={valueToDisplay}
          setValueToDisplay={setValueToDisplay}
          variableId={variableId}
          editedVariableId={editedVariableId}
          setEditedVariableId={setEditedVariableId}
          assetTypeOrAssetId={assetTypeOrAssetId}
          setVariablesList={setVariablesList}
          onEditDone={onEditDone}
          inputStyle={inputStyle}
          assetId={assetId}
          assetTypeId={assetTypeId}
          order={order}
        />
      )}
    </>
  );
}

export default VariableValueComponent;
