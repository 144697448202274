import { CanvasWidget } from '@redux/redux.interface.d';
import { CSI } from './CanvasService.interface';
import { LiveWidget } from './layoutService.interface';
import { widgetMap } from './widgetMap';
import { cssVarsService } from '@core/CssVarsService';
import { uniqBy } from 'lodash';
import {
  LinearGaugeCustomization,
  RadialGaugeCustomization,
  WidgetData,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { i18nService } from '@core/i18n/I18nService';
import { alarmInfosMap } from '@pages/CreateWidgetPage/widgets.utils';

export const excludesWidgets = [
  'map',
  'text',
  'image',
  'line',
  'web',
  'vnc',
  'vpn',
  'switch',
  'update_asset',
  'heatmap',
];

export function isWidgetGroup(widget: LiveWidget | CanvasWidget | CSI.DragInfo) {
  if ('draggedType' in widget) {
    return widget.draggedType === 'composite';
  } else {
    return widget.type === 'composite';
  }
}

/**
 *
 * @param setWidgetData
 * @param term Function
 * @param key setting attribute
 * @param data new array data to be set
 * @param defaultData default data to be set
 *
 * Set only Array data
 */
export const setDefaultData = (widgetData, term, key, defaultData) => {
  return term()
    ? { [key]: [...uniqBy([...widgetData[key], ...defaultData], 'name')] }
    : {
        [key]: widgetData[key].filter((item) => !defaultData.includes(item)),
      };
};

export const getConfigValueFromWidgetSettings = (type, key) => {
  return widgetMap[type]?.settings && widgetMap[type]?.settings[key];
};

export const getIsIncludeInSettings = (type, key, value) =>
  widgetMap[type]?.settings[key]?.includes(value);

export const getIsIncludeInSettingsObject = (type, key, value) =>
  widgetMap[type]?.settings[key]?.value;

export const stepThreeDefaultValidation = (widgetData) => (key) => {
  return (
    widgetData[key].length < 1 ||
    (widgetData[key].length &&
      widgetData[key].some((m) => m.valueType !== 'CALCULATION' && !m.operation) &&
      (widgetData.scope === 'AGGREGATED_DATA' || widgetData.scope === 'AGGREGATED_LAST_VALUE'))
  );
};

export const buildMetricByOperation = (metrics) =>
  metrics.reduce((data: any, metric: any) => {
    if (metric?.operation) {
      return [...data, { ...metric, displayName: `${metric.name} ${metric.operation}` }];
    } else {
      return [...data, { ...metric, displayName: `${metric.name} ` }];
    }
  }, []);

export const getVar = (variable) => cssVarsService.vars[variable];

export const getDefaultWidgetColorPalette = () => [
  getVar('widgetsGraphsColorsPalette1'),
  getVar('widgetsGraphsColorsPalette2'),
  getVar('widgetsGraphsColorsPalette3'),
  getVar('widgetsGraphsColorsPalette4'),
  getVar('widgetsGraphsColorsPalette5'),
  getVar('widgetsGraphsColorsPalette6'),
];

// Consider 0 as true
export const hasValue = (val) => val !== '' && val !== null && val !== undefined;

export const parseCustomizationTerms = (
  termsFromServer: any,
  widgetType?: string,
  customization?: any
) => {
  const terms = Object.entries(termsFromServer).map((entry) =>
    Object.assign({}, { [`${entry[0]}`]: entry[1] })
  );

  let newCustomization = customization;
  let columns,
    newColumns,
    conditions,
    newConditions,
    tagsConfiguration,
    newTagsConfiguration,
    yAxes,
    newYAxes,
    threshold,
    newThreshold,
    remoteAccessButtons,
    newRemoteAccessButtons;

  const extractValuesFromTermsForColumns = (
    column: any,
    replaceString: string,
    widgetType: string
  ) => {
    return Object.values(
      terms.find((term) =>
        Object.keys(term).find((key) => {
          const id = key
            .replace(
              replaceString === 'thresholdDict.' && key.includes(replaceString)
                ? `${replaceString}threshold`
                : column.tagType && !column.valueType
                ? `${replaceString}${column.tagType.toLowerCase()}`
                : column.valueType
                ? `${replaceString}${column.valueType.toLowerCase()}`
                : `${replaceString}`,
              ''
            )
            .replace(column.operation ? `${column.operation?.toLowerCase()}` : '', '')
            .replaceAll('.', '');

          const isSameOperation =
            !column.operation || key.endsWith(column.operation?.toLowerCase());

          if (
            (widgetType === 'conditional_value' && id == key) ||
            ((id == column?.id || id == column?.tagId) && isSameOperation)
          ) {
            return key;
          }
        })
      )
    )[0];
  };

  const extractValuesFromTermsCustomizationFields = (nestedFields = [] as Array<string>) => {
    if (terms) {
      terms.forEach((term) => {
        const fieldName = `${Object.keys(term)[0].split('.')[0]}`;
        if (nestedFields.includes(fieldName)) {
          newCustomization = {
            ...newCustomization,
            [fieldName]: {
              ...newCustomization[fieldName],
              [`${Object.keys(term)[0].split('.')[1]}`]: Object.values(term)[0],
            },
          };
        } else {
          newCustomization = {
            ...newCustomization,
            [fieldName]: Object.values(term)[0],
          };
        }
      });
    }
  };

  const extractValuesFromTermsForUpdateAsset = () => {
    if (terms) {
      terms.map((term) => {
        const field = `${Object.keys(term)[0].split('.')[0]}`;
        const innerField = `${Object.keys(term)[0].split('.')[1]}`;
        newCustomization = {
          ...newCustomization,
          [`${field}`]: {
            ...newCustomization[`${field}`],
            [`${innerField}`]: Object.values(term)[0],
          },
        };
      });
    }
  };

  switch (widgetType) {
    case 'table':
    case 'alarms':
      columns = customization.columns;
      newColumns = columns.map((column) => {
        return {
          ...column,
          displayName: extractValuesFromTermsForColumns(column, 'columnsDict.', widgetType),
          symbol: extractValuesFromTermsForColumns(column, 'symbolDict.', widgetType),
        };
      });
      newCustomization = { ...newCustomization, columns: newColumns };
      return newCustomization;

    case 'heatmap':
      extractValuesFromTermsCustomizationFields(['subtitle']);
      columns = customization.columns;
      newColumns = columns.map((column) => {
        return {
          ...column,
          displayName: extractValuesFromTermsForColumns(column, 'columnsDict.', widgetType),
        };
      });
      newCustomization = { ...newCustomization, columns: newColumns };
      return newCustomization;

    case 'asset':
      columns = customization.columns;
      remoteAccessButtons = customization.remoteAccessButtons;
      newColumns = columns.map((column) => {
        return {
          ...column,
          displayName: extractValuesFromTermsForColumns(column, 'columnsDict.', widgetType),
          symbol: extractValuesFromTermsForColumns(column, 'symbolDict.', widgetType),
        };
      });
      newRemoteAccessButtons = {
        vpn: {
          ...remoteAccessButtons.vpn,
          displayName: extractValuesFromTermsForColumns(
            { ...remoteAccessButtons.vpn, id: 'vpn' },
            'remoteAccessButtons.',
            widgetType
          ),
        },
        web: {
          ...remoteAccessButtons.web,
          displayName: extractValuesFromTermsForColumns(
            { ...remoteAccessButtons.web, id: 'web' },
            'remoteAccessButtons.',
            widgetType
          ),
        },
        vnc: {
          ...remoteAccessButtons.vnc,
          displayName: extractValuesFromTermsForColumns(
            { ...remoteAccessButtons.vnc, id: 'vnc' },
            'remoteAccessButtons.',
            widgetType
          ),
        },
      };
      newCustomization = {
        ...newCustomization,
        columns: newColumns,
        remoteAccessButtons: newRemoteAccessButtons,
      };
      return newCustomization;

    case 'conditional_value':
      extractValuesFromTermsCustomizationFields();
      conditions = customization.conditions;
      newConditions = conditions.map((condition) => {
        return {
          ...condition,
          remark: extractValuesFromTermsForColumns(condition, 'columnsDict.', widgetType),
        };
      });
      newCustomization = { ...newCustomization, conditions: newConditions };
      return newCustomization;

    case 'columns':
    case 'line':
      columns = customization.columns;
      yAxes = customization.yAxes;
      threshold = customization.threshold;
      newColumns = columns.map((column) => {
        return {
          ...column,
          displayName: extractValuesFromTermsForColumns(column, 'columnsDict.', widgetType),
        };
      });
      newYAxes = yAxes.map((yAxis) => {
        return {
          ...yAxis,
          displayName: extractValuesFromTermsForColumns(yAxis, 'yAxesDict.', widgetType),
        };
      });
      newThreshold =
        threshold?.map((t) => {
          return {
            ...t,
            displayName: extractValuesFromTermsForColumns(t, 'thresholdDict.', widgetType),
          };
        }) || [];
      newCustomization =
        widgetType === 'line'
          ? { ...newCustomization, columns: newColumns, yAxes: newYAxes, threshold: newThreshold }
          : { ...newCustomization, columns: newColumns, yAxes: newYAxes };
      extractValuesFromTermsCustomizationFields();
      return newCustomization;

    case 'update_asset':
      extractValuesFromTermsForUpdateAsset();
      tagsConfiguration = customization.tagsConfiguration;
      newTagsConfiguration = tagsConfiguration.map((tagConfig) => {
        return {
          ...tagConfig,
          displayName: extractValuesFromTermsForColumns(tagConfig, 'columnsDict.', widgetType),
        };
      });
      newCustomization = { ...newCustomization, tagsConfiguration: newTagsConfiguration };
      return newCustomization;

    case 'map': // Just-in-case protection if for some reason received: terms{tagCaption: null}
      if (terms.some((t) => t.tagCaption)) {
        extractValuesFromTermsCustomizationFields();
      }
      return newCustomization;

    default:
      extractValuesFromTermsCustomizationFields();
      return newCustomization;
  }
};

export function getGaugeWidgetsCustomizationServerChanges(
  customization: RadialGaugeCustomization | LinearGaugeCustomization
) {
  function getValue(value) {
    return hasValue(value) ? (isFinite(value as any) ? +value : value) : null;
  }

  return {
    ...customization,
    decimalDigits: +customization.decimalDigits,
    zones: customization.zones?.map((zone) => ({
      ...zone,
      minValue: getValue(zone.minValue),
      maxValue: getValue(zone.maxValue),
    })),
    ticks: {
      ...customization.ticks,
      majorStart: getValue(customization.ticks.majorStart),
      majorEnd: getValue(customization.ticks.majorEnd),
      majorInterval: getValue(customization.ticks.majorInterval),
      minorInterval: getValue(customization.ticks.minorInterval),
    },
  };
}

export function getColumnName(widgetData, editedColumn): string {
  switch (editedColumn.valueType) {
    case 'ASSET_PROPERTY':
      return i18nService.translate(
        widgetData.assetProperties?.find((assetProperty) => assetProperty.id === editedColumn.id)
          ?.name
      );
    case 'TAG':
      return widgetData.tags?.find((tag) => tag.id === editedColumn.id)?.name;
    case 'TAG_TYPE':
      return widgetData.tagTypes?.find((tagType) => tagType.id === editedColumn.id)?.name;
    case 'VARIABLE':
      return widgetData.variables?.find((variable) => variable.id === editedColumn.id)?.name;
    case 'CALCULATION':
      return widgetData.localTags?.find((localTag) => localTag.id === editedColumn.id)?.name;
    case 'ALARM_INFO':
      return i18nService.translate(
        `create-widget-page.create-widget.alarm-infos.${alarmInfosMap[editedColumn.id]}`
      );
    default:
      return '';
  }
}

export function stepFourLineAndColumnsValidation(widgetData: WidgetData) {
  if (
    widgetData.customization &&
    widgetData.customization['columns']?.length &&
    widgetData.customization['columns'].some((c) => c.displayName === '')
  ) {
    return false;
  }
  if (
    widgetData.customization &&
    widgetData.customization['yAxes']?.length &&
    widgetData.customization['yAxes'].some(
      (yAxis) =>
        yAxis.scale === 'MANUAL' &&
        (!yAxis.minValue ||
          [0, ''].includes(yAxis.minValue) ||
          !yAxis.maxValue ||
          [0, ''].includes(yAxis.maxValue))
    )
  ) {
    return false;
  }
  return true;
}

export const alignmentOptions = [
  { label: 'enum.CENTER', value: 'CENTER' },
  { label: 'enum.LEFT', value: 'LEFT' },
  { label: 'enum.RIGHT', value: 'RIGHT' },
];

export const positionOptions = [
  { label: 'enum.TOP', value: 'TOP' },
  { label: 'enum.RIGHT', value: 'RIGHT' },
  { label: 'enum.BOTTOM', value: 'BOTTOM' },
  { label: 'enum.LEFT', value: 'LEFT' },
];

export const updateWidgetCustomization = (values, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      ...values,
    },
  }));
};
