import * as RI from '../../redux.interface';
import * as cloneDeep from 'lodash/cloneDeep';

class AHandlers {
  updateAssetsData(state: RI.AssetState, payload: any) {
    const changes = payload.data;
    const assetsData = cloneDeep(state.assetsData);
    if (assetsData.length && payload.rowIdx >= 0) {
      const rowData = assetsData[payload.rowIdx];
      for (const key in changes) {
        switch (key) {
          case 'assetName':
          case 'assetSerial':
          case 'status':
          case 'hasCertificate':
            if (changes[key] !== rowData[key]) rowData[key] = changes[key];
            break;
          case 'owner':
            if (changes[key].label !== rowData['owner']) rowData['owner'] = changes[key].label;
            break;
        }
      }
    }

    return {
      ...state,
      assetsData,
    };
  }
}

export const aHandlers = new AHandlers();
