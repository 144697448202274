import * as cloneDeep from 'lodash/cloneDeep';
import { concat, compact } from 'lodash';
import { tagFormatMap, variableFormatMap } from '@core/mapsAndDefinitions';
import { SortingItem, LocalSortingItem } from './WidgetSorting.interface';

const getFilteredColunms = (widgetColunms, sortItems) => {
  return widgetColunms.filter(
    (col) =>
      col.valueType !== 'CALCULATION' &&
      !sortItems.find((s) => {
        if (s.operation && col.operation) {
          return s.valueId === col.valueId && s.operation === col.operation;
        } else return s.valueId === col.valueId;
      })
  );
};

const getFilteredSorts = (widgetColunms, sortItems) => {
  return sortItems.filter((sort) =>
    widgetColunms.find((col) => {
      if (col.operation && sort.operation) {
        return col.valueId === sort.valueId && col.operation === sort.operation;
      } else return col.valueId === sort.valueId;
    })
  );
};

const buildOptions = (option, type, idx) => {
  const tagFormat =
    type === 'VARIABLE'
      ? variableFormatMap[option.valueType]
      : 'TAG'
      ? tagFormatMap[option.format]
      : tagFormatMap[option.type];
  return {
    order: idx,
    valueId: option.id,
    valueType: type,
    type: tagFormat,
    name: option.name,
    operation: null,
  };
};

const getAvailableData = (assetProperties, tags, tagTypes, variables, alarmInfos) => {
  return compact(
    concat(
      assetProperties.map((ap, idx) => buildOptions(ap, 'ASSET_PROPERTY', idx)),
      tags.map((t, idx) => buildOptions(t, 'TAG', assetProperties.length + idx)),
      tagTypes.map((tt, idx) =>
        buildOptions(tt, 'TAG_TYPE', assetProperties.length + tags.length + idx)
      ),
      alarmInfos.map((ai, idx) =>
        buildOptions(ai, 'ALARM_INFO', tagTypes.length + assetProperties.length + tags.length + idx)
      ),
      variables.map((v, idx) =>
        buildOptions(
          v,
          'VARIABLE',
          tagTypes.length + assetProperties.length + tags.length + alarmInfos.length + idx
        )
      )
    )
  );
};

const getMergedWidgetColunms = (widgetData) => {
  const { groupBy, metrics, scope, type } = widgetData;

  const metricsOperations = [];
  const widgetMetrics = cloneDeep(metrics) || [];
  widgetMetrics.forEach((metric) => {
    if (metric.operation && (!['LAST_VALUE', 'RAW_DATA'].includes(scope) || type === 'pie')) {
      metricsOperations.push({
        ...metric,
        type:
          metric.type !== 'numberType' && metric.type !== 'floatType' ? 'numberType' : metric.type,
      });
    } else {
      metricsOperations.push({
        ...metric,
        operation: null,
      });
    }
  });
  return groupBy.concat(metricsOperations);
};

export const setAvailableAndSortByDataChanged = (
  setWidgetData,
  setSortItems,
  setAvailableSortColunms,
  sortItems,
  setWidgetColunms,
  widgetData
) => {
  const widgetColunms = getMergedWidgetColunms(widgetData);
  setWidgetColunms(widgetColunms);
  const filteredWidgetColunms = getFilteredColunms(widgetColunms, widgetData.sorts);
  setAvailableSortColunms(filteredWidgetColunms);
  let sorts = getFilteredSorts(widgetColunms, widgetData.sorts);
  sorts = sorts.map((sort, index) => ({ ...sort, order: index }));
  setSortItems(sorts.length ? sorts : sorts.concat({ order: 0 }));
  setWidgetData((prevState) => ({ ...prevState, sorts }));
};

export const setAvailableAndSortData = (
  widgetColunms,
  sortItems,
  setAvailableSortColunms,
  sorts,
  setSortItems
) => {
  const filteredWidgetColunms = getFilteredColunms(widgetColunms, sorts);
  setAvailableSortColunms(filteredWidgetColunms);
  setSortItems(sorts.length ? sorts : sorts.concat({ order: 0 }));
};

export const onSortRemoved = (item: SortingItem, sortItems, setSortItems, setWidgetData) => {
  const _sortItems = cloneDeep(sortItems);
  let sorts = [];
  if (!!item) {
    sorts = _sortItems.filter((s) => {
      if (s.valueId === item.valueId && s.valueType === item.valueType) {
        return s.operation !== item.operation;
      } else return s.valueId !== item.valueId || s.valueType !== item.valueType;
    });
  } else if (!_sortItems[_sortItems.length - 1].valueId) {
    sorts = _sortItems.slice(0, _sortItems.length - 1);
  }
  sorts = sorts.map((sort, index) => ({ ...sort, order: index }));
  setSortItems(sorts.length ? sorts : sorts.concat({ order: 0 }));
  setWidgetData((prevState) => ({ ...prevState, sorts }));
};

export const onSortSelected = (
  prevVal: LocalSortingItem,
  sort: LocalSortingItem,
  sortItems,
  setSortItems,
  setWidgetData
) => {
  const widgetDataSorts = cloneDeep(sortItems);
  const prevValIdx = widgetDataSorts.findIndex((s) => {
    if (s.operation && prevVal.operation) {
      return s.valueId === prevVal.valueId && s.operation === prevVal.operation;
    } else return s.valueId === prevVal.valueId;
  });
  prevValIdx >= 0
    ? (widgetDataSorts[prevValIdx] = { ...sort, order: prevValIdx })
    : (widgetDataSorts[widgetDataSorts.length - 1] = { ...sort, order: widgetDataSorts.length });
  setSortItems(widgetDataSorts);
  setWidgetData((prevState) => ({ ...prevState, sorts: widgetDataSorts }));
};

export const onDragAndDrop = (result, sortItems, setWidgetData) => {
  const { source, destination } = result;

  if (!destination) {
    return;
  }
  const newIdx = destination.index;
  const prevIdx = source.index;

  const _sortItems = cloneDeep(sortItems);

  if (destination.droppableId === 'sorting' && source.droppableId === 'sorting') {
    if (newIdx === prevIdx || prevIdx >= sortItems.length) {
      return; // No need to reorder.
    }
    // Reorder.

    const tagToMove = _sortItems[prevIdx];
    _sortItems.splice(prevIdx, 1);
    _sortItems.splice(newIdx, 0, tagToMove);
    _sortItems.forEach((item, idx) => (item.order = idx));

    return setWidgetData((prevState) => ({ ...prevState, sorts: [..._sortItems] }));
  }
};
