import { httpService } from '@core/http/HttpService';
import { joinByIdenticalValue } from '@core/utils';
import history from '@core/history';
import { modalService } from '@core/modals/ModalService';

export async function getAllARProjects(setSortedArProjects: any) {
  const results: any = await httpService.api({
    type: 'getAllARProjects',
  });

  if (results && results?.length) {
    const sortedARProjects = results.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : -1
    );

    setSortedArProjects(
      prepareCombinedProjectList(joinByIdenticalValue(sortedARProjects, 'refId', 'status'))
    );
  }
  return;
}

export function getButtonsCfg(projectInstance, status, setSortedArProjects) {
  return [
    {
      type: 'trash',
      tooltip: 'general.delete',
      isDisabled: false,
      isVisible: true,
      onClick: () => removeArProject(projectInstance, setSortedArProjects),
    },
    {
      type: 'editGalleryItem',
      tooltip: 'general.edit',
      isDisabled: status === 'combined' && projectInstance.status === 'PUBLISHED',
      isVisible: true,
      onClick: () => editArProject(projectInstance),
    },
  ];
}

export function prepareCombinedProjectList(projectArray = []) {
  return projectArray.map((project) => ({
    ...project,
    status: getProjectStatus(project),
    training: project.draft?.training || project.published?.training,
  }));
}

export function getProjectStatus(project) {
  return project?.draft && project?.published
    ? 'combined'
    : project?.published
    ? 'published'
    : 'draft';
}

export async function editArProject(projectInstance) {
  switch (projectInstance.status) {
    case 'DRAFT':
      history.push(`/main/augmented-reality/${projectInstance.id}`);
      return;
    case 'PUBLISHED':
      await editPublishedArProject(projectInstance.id);
      return;
    default:
      return;
  }
}

async function editPublishedArProject(publishedId) {
  const res = await httpService.api<any>({
    type: `editPublishedArProject`,
    urlParams: { projectId: publishedId },
  });
  if (res) {
    history.push(`/main/augmented-reality/${res.id}`);
  }
}

function removeArProject(project, setSortedArProjects) {
  modalService
    .openConfirm(
      {
        headerText: project.isInUse
          ? 'augmented-reality.gallery.delete-assigned-project.header'
          : 'augmented-reality.gallery.delete-project.header',
        text: project.isInUse
          ? 'augmented-reality.gallery.delete-assigned-project.text'
          : 'augmented-reality.gallery.delete-project.text',
        iconType: 'attention_image',
        showCloseBtn: true,
      },
      { projectName: project.name }
    )
    .then((confirm) => {
      confirm &&
        httpService
          .api({
            type: 'removeArProject',
            urlParams: { projectId: project.id },
          })
          .then(() => {
            getAllARProjects(setSortedArProjects);
          });
    });
}

export function getTrainingBadge(status: string) {
  switch (status) {
    case 'TRAINED':
      return {
        text: 'augmented-reality.gallery.trained',
        backgroundColor: 'var(--systemPublishedBackground)',
        textColor: 'var(--white1)',
      };
    case 'TRAINED_WARNING':
      return {
        text: 'augmented-reality.gallery.trained-warning',
        backgroundColor: 'var(--statusConnectedWarning)',
        textColor: 'var(--white1)',
      };
    case 'ERROR':
      return {
        text: 'general.error',
        backgroundColor: 'var(--statusMajorAlarm)',
        textColor: 'var(--white1)',
      };
    default:
      return null;
  }
}
