import { PLCState } from '@redux/redux.interface.d';
import {
  setPLCData,
  addPLCData,
  refreshPLCTableData
} from '@redux/deviceManagment/plc/plc.actions';
import { dispatch } from '@redux/store';

class PLCService {
  setPLCTableData(plcData: PLCState) {
    dispatch(setPLCData(plcData));
  }

  addPLCTableData(plcData: PLCState) {
    dispatch(addPLCData(plcData));
  }

  refreshPLCTableData() {
    // Switches refresh to true.
    dispatch(refreshPLCTableData());
    setTimeout(() => {
      // Switches refresh back to false.
      dispatch(refreshPLCTableData());
    });
  }
}

export const plcService = new PLCService();
