import { modalService } from '@core/modals/ModalService';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { assetsService } from '../../AssetsService';
import { httpService } from '@core/http/HttpService';
import { AssetDataResponse } from './assetsTable.interface';

export const onEditAssetModal = (rowData, rowIdx, showArchived) => {
  modalService.openModal('editAsset', { rowData, rowIdx }).then((res) => {
    let archive = null;
    if (res) {
      archive = res.archive;
    } else {
      archive = editAssetService.getArchive();
    }

    if ((!archive && !showArchived) || (archive && showArchived)) {
      assetsService.removeAssetFromTableData(rowIdx);
    }
    editAssetService.clearEditAsset();
  });
};

export const getAssets = (
  setNextPage,
  selectedOrganizationsId,
  searchText,
  showArchived,
  pageSize,
  nextPage,
  setDisableLoad,
  firstInit = false,
  order?,
  filters?
) => {
  setDisableLoad(true);

  const filtersData = filters
    ? Object.keys(filters).reduce(
        (res, current) => ({
          ...res,
          [current]: `${filters[current].map((id) => id)}`,
        }),
        {}
      )
    : {};

  httpService
    .api({
      type: 'getAssets',
      query: {
        p: firstInit ? 1 : nextPage,
        ps: pageSize,
        archived: showArchived,
        search: searchText ? searchText : null,
        ordering: order
          ? `${order.orderSign}${order.label}${
              order.label === 'subscriptionStatus' ? `,${order.orderSign}subscriptionStatusAdd` : ''
            }`
          : null,
        subs: !selectedOrganizationsId,
        ...filtersData,
      },
    })
    .then((res: AssetDataResponse) => {
      res.next && setDisableLoad(false);
      if (firstInit) {
        assetsService.setAssetTableData({
          totalCount: res.allCount,
          resultsCount: res.count,
          assetsData: res.results,
        });
        setNextPage(2);
      } else {
        assetsService.addAssetTableData({
          totalCount: res.allCount,
          resultsCount: res.count,
          assetsData: res.results,
        });
        setNextPage(nextPage + 1);
      }
    });
};
