import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { modalService } from '@core/modals/ModalService';
import FormikInputWithCancel from '@components/Formik/FormikInputWithCancel';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { httpService } from '@http/HttpService';
import { useSelector } from '@redux/useSelector';
import { AssetTypeData } from '@redux/redux.interface.d';
import styles from './EditAssetTypeModal.scss';
import { editAssetTypeService } from './EditAssetTypeService';
import EditAssetTypeTabs from './EditAssetTypeTabs';
import MultilineAutoComplete from '@components/MultilineAutoComplete';
import AssetTypesLabel from '@pages/DeviceManagment/Assets/AssetTypesTab/AssetTypesTable/AssetTypesLabel';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';

const validationSchema = Yup.object().shape({
  assetName: Yup.string().required(),
});

function EditAssetTypeModal(props: ModalComponentProps) {
  const { dismiss, args } = props;

  const assetType = args.rowData as AssetTypeData;
  const rowIdx = args.rowIdx;
  const assetTypeInfo = useSelector((state) => state.editAssetType);
  const [remoteAccessMode, setRemoteAccessMode] = useState(
    assetTypeInfo?.staticData?.remoteAccessMode
  );
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [nameEditMode, setNameEditMode] = useState(false);
  const [modelEditMode, setModelEditMode] = useState(false);
  const [labellEditMode, setLabelEditMode] = useState(false);
  const [supportGpsLocations, setSupportGpsLocations] = useState(false);

  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const flags = useMemo(
    () => ({
      publish: getFlagStatus('asset-types.publish-button'),
      edit: getFlagStatus('asset-types.edit-buttons'),
    }),
    []
  );

  useEffect(() => {
    setRemoteAccessMode(assetTypeInfo?.staticData?.remoteAccessMode);
  }, [assetTypeInfo?.staticData]);

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  useEffect(() => {
    editAssetTypeService.assetTypeId = assetType.id;
    editAssetTypeService.setModalDismiss(dismiss);
    editAssetTypeService.getAssetTypeInfo();
    editAssetTypeService.startPollingForAssignmentCompletion();

    return () => {
      editAssetTypeService.stopPollingForAssignmentCompletion();
    };
  }, []);

  const initialValues = useMemo(() => {
    if (assetTypeInfo && assetTypeInfo.staticData) {
      return {
        assetName: assetTypeInfo.staticData.name || '',
        model: assetTypeInfo.staticData.model || '',
      };
    }
  }, [assetTypeInfo]);

  useEffect(() => {
    if (assetTypeInfo && assetTypeInfo.staticData) {
      setSelectedLabels(assetTypeInfo.staticData.labels);
      setSupportGpsLocations(assetTypeInfo.staticData.gpsSupport);
    }
  }, [assetTypeInfo]);

  const saveChanges = (value: any, updateStateFunc) => {
    httpService
      .api({
        type: 'saveAssetTypeChanges',
        data: value,
        urlParams: { aseetTypeid: assetType.id },
      })
      .then((res: any) => {
        editAssetTypeService.updateEditAssetType(value);
        editAssetTypeService.setSelectedTab('alarmsMeta');
        assetsService.refreshAssetTypeTableData();
        updateStateFunc();
      });
  };

  const onBlur = () => {
    setLabelEditMode(false);
    assetType.labels = [...selectedLabels];
    assetsService.updateAssetTypeTableData({ labels: [...selectedLabels] }, rowIdx);
  };

  const handleSupportGpsChanged = (e) => {
    const value = e.target.checked;
    setSupportGpsLocations(value);

    const text = value
      ? 'edit-asset-type-modal.support-gps-locations.turn-on-message'
      : 'edit-asset-type-modal.support-gps-locations.turn-off-message';

    modalService
      .openModal('confirm', {
        style: { width: 589 },
        text: text,
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText:
          'edit-asset-type-modal.support-gps-locations.support-gps-locations.popup-header',
        assetType: assetType.name,
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          assetsService.updateAssetTypeGpsSupport(value);
          httpService
            .api({
              type: 'updateAssetType',
              urlParams: { assetTypeId: assetType.id },
              data: { gpsSupport: value },
            })
            .then(() => {
              editAssetTypeService.updateEditAssetType({ gpsSupport: value });
              editAssetTypeService.getAssetTypeInfo({ disableBI: true });
            });
        } else {
          setSupportGpsLocations(!value);
          editAssetTypeService.updateEditAssetType({ gpsSupport: !value });
        }
      });
  };

  const getAssetTypesLabels = (searchText) => {
    httpService
      .api({
        type: 'getAssetTypesLabels',
        query: { text: searchText },
      })
      .then((assetTypeLabels: any) => {
        setLabels(assetTypeLabels.labels);
      });
  };

  const onDelete = (option, idx) => {
    httpService
      .api({
        type: 'deleteAssetTypeLabel',
        data: { id: selectedLabels[idx].id },
        urlParams: { assetTypeId: assetType.id },
      })
      .then((res) => {
        const arr = [...selectedLabels];
        arr.splice(idx, 1);
        setSelectedLabels(arr);
      });
  };

  const setAllSelectedLabels = () => {
    setSelectedLabels(selectedLabels);
  };

  const clearAll = () => {
    //TODO:console should be inside clearAll function
    console.log('delete all');
  };

  const addLabelToServer = (e, val, label) => {
    if (!val.length) clearAll();
    httpService
      .api({
        type: 'saveAssetTypeLabel',
        data: { text: label },
        urlParams: { assetTypeId: assetType.id },
      })
      .then((res: { id: string; text: string }) => {
        const arr = [...selectedLabels];
        arr.push(res);
        setSelectedLabels(arr);
      });
  };

  return (
    <div className={styles.wrapper}>
      <I18n
        className={styles.modalHeader}
        element="div"
        assetName={assetTypeInfo && assetTypeInfo.staticData && assetTypeInfo.staticData.name}>
        edit-asset-type-modal.title
      </I18n>
      <div className={styles.modalContent}>
        {assetTypeInfo && assetTypeInfo.staticData && (
          <>
            <div className={styles.assetDataSection}>
              <div className={styles.section}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={() => {}}>
                  {({ values, isValid }) => (
                    <Form className={styles.modalForm}>
                      <div className={classNames(styles.dataRow, !nameEditMode && styles.padding)}>
                        <div className={classNames(styles.dataLabel, 'ellipsis-overflow')}>
                          <I18n>edit-asset-type-modal.asset-type-name</I18n>:
                        </div>
                        {nameEditMode ? (
                          <div className={styles.editMode}>
                            <FormikInputWithCancel
                              name="assetName"
                              value={values.assetName}
                              cancel={() => setNameEditMode(false)}
                            />
                            <Icon
                              disabled={!isValid || initialValues.assetName === values.assetName}
                              type="save"
                              className={classNames('pointer', styles.icon)}
                              onClick={() =>
                                saveChanges({ name: values.assetName }, () =>
                                  setNameEditMode(false)
                                )
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <span className={styles.dataValue}>{values.assetName}</span>
                            {flags.edit && (
                              <Icon
                                type="edit"
                                color="var(--systemFont)"
                                disabled={!hasPermission}
                                className={classNames('pointer', styles.icon)}
                                onClick={() => setNameEditMode(true)}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div className={classNames(styles.dataRow, !modelEditMode && styles.padding)}>
                        <div className={classNames(styles.dataLabel, 'ellipsis-overflow')}>
                          <I18n>edit-asset-type-modal.model</I18n>:
                        </div>
                        {modelEditMode ? (
                          <div className={styles.editMode}>
                            <FormikInputWithCancel
                              name="model"
                              maxLength={15}
                              value={values.model}
                              cancel={() => setModelEditMode(false)}
                            />
                            <Icon
                              disabled={!isValid || initialValues.model === values.model}
                              type="save"
                              className={classNames('pointer', styles.icon)}
                              onClick={() =>
                                saveChanges({ model: values.model }, () => setModelEditMode(false))
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <span className={styles.dataValue}>{values.model}</span>
                            {flags.edit && (
                              <Icon
                                type="edit"
                                color="var(--systemFont)"
                                disabled={!hasPermission}
                                className={classNames('pointer', styles.icon)}
                                onClick={() => setModelEditMode(true)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className={styles.section}>
                {/* <div className={styles.dataRow}>
                  <span className={classNames(styles.dataLabel, styles.label, 'ellipsis-overflow')}>
                    <I18n>edit-asset-type-modal.label</I18n>:
                  </span>
                  {labellEditMode ? (
                    <div style={{ width: '100%' }}>
                      <MultilineAutoComplete
                        getOptions={getAssetTypesLabels}
                        labels={labels}
                        setOptions={setLabels}
                        setSelectedLabels={setSelectedLabels}
                        selectedLabels={selectedLabels.map((label) => label.text)}
                        addLabelToServer={addLabelToServer}
                        setAllSelectedLabels={setAllSelectedLabels}
                        onDelete={onDelete}
                        handleBlur={onBlur}></MultilineAutoComplete>
                    </div>
                  ) : (
                    <>
                      <AssetTypesLabel {...assetType}></AssetTypesLabel>
                      {flags.edit && (
                        <Icon
                          type="edit"
                          color="var(--systemFont)"
                          className={classNames('pointer', styles.icon)}
                          disabled={!hasPermission}
                          onClick={() => setLabelEditMode(true)}
                        />
                      )}
                    </>
                  )}
                </div> */}
                <div className={styles.dataRow}>
                  <div className={classNames(styles.statusValue, 'ellipsis-overflow')}>
                    {assetTypeInfo && assetTypeInfo.staticData?.status === 'DRAFT_UPDATING' ? (
                      <I18n
                        style={{
                          color: 'var(--formikErrorText)',
                        }}>{`enum.${assetTypeInfo.staticData.status}`}</I18n>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tabsSection}>
              <EditAssetTypeTabs
                assetTypeRowData={assetType}
                handleSupportGpsChanged={handleSupportGpsChanged}
                supportGpsLocations={supportGpsLocations}
                remoteAccessMode={remoteAccessMode}
                setRemoteAccessMode={setRemoteAccessMode}
              />
            </div>
          </>
        )}

        {!assetTypeInfo && (
          <div className={styles.modalButtons}>
            <Button mode={['cancel', 'bigFont']} onClick={cancel}>
              <I18n>general.cancel</I18n>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditAssetTypeModal;
