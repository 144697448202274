import { getState, dispatch } from '@redux/store';
import { httpService } from '@core/http/HttpService';

class CreateDashboardService {
  private _dismiss: Function;

  setModalDismiss(dismiss: (val) => void) {
    this._dismiss = dismiss;
  }

  dismiss(val) {
    this._dismiss && this._dismiss(val);
  }
}

export const createDashboardService = new CreateDashboardService();
