import React, { useMemo } from 'react';
import { colorMap } from '@core/statusColorMap';
import moment from 'moment';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

function SubscriptionData(props) {
  const { field: tempField, subscription, asset } = props;

  if (asset.id == 694) {
    console.log(asset.id);
  }

  const field =
    tempField === 'subscriptionStatus'
      ? 'status'
      : tempField === 'subscriptionEndDate'
      ? 'endDate'
      : tempField === 'licenseType'
      ? 'licenceType'
      : tempField;
  const languageId = useSelector((state) => state.config.languageId);
  const value = useMemo(() => {
    if (asset.subscriptionStatus === 'ACTIVE') {
      return '';
    } else {
      return subscription
        ? field === 'status'
          ? i18nService.translate(`enum.subscription.plc.${subscription?.status}`, languageId)
          : field === 'endDate' && subscription[field]
          ? moment(subscription[field], 'YYYY-MM-DD').format('DD/MM/YYYY')
          : subscription[field]
        : '';
    }
  }, [subscription, field, languageId]);
  return (
    <span
      style={{
        color:
          field === 'status' &&
          subscription &&
          subscription[field] !== 'ACTIVE' &&
          colorMap[subscription[field]]
            ? colorMap[subscription[field]]
            : 'var(--systemFont)',
      }}
      title={value}>
      {value}
    </span>
  );
}

export default SubscriptionData;
