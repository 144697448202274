import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { dispatch } from '@src/redux/store';
import {
  addLanguage,
  decrementBusyCounter,
  incrementBusyCounter,
  removeLanguage,
} from '@src/redux/config';
import { omit, pick } from 'lodash';
import { uploadService } from '@core/UploadService';
import { downloadFile, updateOrAddToArray } from '@core/utils';
import { TableColunm } from '@components/Table/table.interface';
import TextField from '@components/Table/TableFields/TextField';
import { i18nService } from '@core/i18n/I18nService';

const POLLING_INTERVAL = 1000;

export const getListLanguages = (setDefaultLanguageId, setData) => {
  httpService.api({ type: 'getListLanguages' }).then((res: any) => {
    setDefaultLanguageId(res.defaultLanguageId);
    setData({
      publishAll: res.publishAll,
      languages: res.languages,
      defaultLanguageId: res.defaultLanguageId,
    });
  });
};

const rowErrorsCfg: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'languages.upload-language-errors-line-number',
    field: 'rowNumber',
    style: { width: '30%', textAlign: 'left' },
  },
  {
    Component: TextField,
    label: 'languages.upload-language-errors-error-description',
    field: 'errorType',
    style: { width: '70%', textAlign: 'left' },
  },
];

const rowErrorTypeMap = [
  {
    value: 'INVALID_PLACEHOLDER',
    label: 'languages.upload-language-errors-invalid-placeholder',
  },
  {
    value: 'INVALID_KEY',
    label: 'languages.upload-language-errors-invalid-key',
  },
];

const generalErrorTypeMap = [
  {
    value: 'UNEXPECTED',
    label: 'languages.upload-language-general-errors-unexpected',
  },
  {
    value: 'MULTIPLE_LANGUAGES',
    label: 'languages.upload-language-general-errors-multiple-languages',
  },
  {
    value: 'READ_FILE_ERROR',
    label: 'languages.upload-language-general-errors-read-file-error',
  },
  {
    value: 'INVALID_LANGUAGE_FOR_ORGANIZATION',
    label: 'languages.upload-language-general-errors-invalid-language-for-org',
  },
];

export const openUploadLanguage = () => {
  modalService.openModal('uploadLanguageModal').then((res) => {
    const translationCallback = (res) => {
      if (res.generalError) {
        modalService.openModal('alert', {
          text: 'languages.upload-language-complete',
          iconType: 'attention_imag',
          btnText: 'general.close',
          headerText: 'languages.upload-language-failed-header',
          showCloseBtn: true,
          listText: res.Errors,
        });
      } else if (res.rowErrors?.length) {
        modalService.openModal('alert', {
          multiLineText: `${res.translatedCount} ${i18nService.translate(
            'languages.upload-language-terms-loaded'
          )}\r\n${res.failedCount} ${i18nService.translate(
            'languages.upload-language-errors-found'
          )}`,
          iconType: 'attention_imag',
          btnText: 'general.close',
          headerText: 'languages.upload-language-results-header',
          tableCfg: rowErrorsCfg,
          tableData: res.rowErrors
            .map((error) => ({
              rowNumber: error.rowNumber,
              errorType: `${i18nService.translate(
                rowErrorTypeMap.find((type) => type.value === error.errorType).label
              )}`,
            }))
            .sort((a, b) => (a.rowNumber > b.rowNumber ? 1 : -1)),
          showCloseBtn: true,
        });
      } else {
        modalService.openModal('alert', {
          multiLineText: `${i18nService.translate('languages.upload-language-successful')}\r\n${
            res.translatedCount
          } ${i18nService.translate('languages.upload-language-terms-loaded')}`,
          iconType: 'v_image',
          btnText: 'general.close',
          headerText: 'languages.upload-language-successful-header',
          showCloseBtn: true,
        });
      }
    };
    if (res) {
      uploadService.upload({
        getUploadType: 'uploadFile',
        fileName: res.selectedFile.name,
        file: res.selectedFile,
        imageType: 'TRANSLATION',
        csvDelimiter: res.delimiter,
        moreError: {
          status: 400,
          text: 'languages.upload-language-unsuccessful',
          generalError: generalErrorTypeMap,
          headerText: 'languages.upload-language-failed-header',
        },
        callback: translationCallback,
      });
    }
  });
};

export const openNewLanguageModal = (setData, data, languageData?) => {
  modalService.openModal('newLanguageModal', { languages: data, languageData }).then((res) => {
    if (res) {
      res.status === 'PUBLISHED' && dispatch(addLanguage(omit(res, 'status')));
      setData((prevState) => {
        const languages = updateOrAddToArray(prevState.languages, res, 'id');
        return {
          ...prevState,
          languages,
        };
      });
    }
  });
};

export const openEditLanguageModal = (
  setData,
  data,
  languageData,
  setSelectedLanguage,
  setDefaultLanguageId
) => {
  modalService
    .openModal('editLanguageModal', {
      languages: data,
      languageData,
      setSelectedLanguage,
      setDefaultLanguageId,
      setData,
    })
    .then((res) => {
      if (res) {
        res.status === 'PUBLISHED' || res.status === 'PREVIEW'
          ? dispatch(addLanguage(pick(res, ['id', 'name', 'flag', 'status'])))
          : dispatch(removeLanguage(omit(res, 'status')));
      }
    });
};

export const openExportLanguageModal = async (languageId, organizationType) => {
  modalService
    .openModal('exportLanguageModal', { languageId, organizationType })
    .then(async (res: any) => {
      const { url, fileName } = await exportFile(res, languageId);

      if (url) {
        try {
          downloadFile(url, fileName);
        } catch {
          dispatch(decrementBusyCounter());
        }
      }
    });
};

const exportFile = async (exportType: string, languageId: string) => {
  const job: { id: number } = await httpService.api({
    type: 'exportLanguage',
    urlParams: { languageId: languageId },
    data: { exportType: exportType },
  });

  if (job?.id) {
    dispatch(incrementBusyCounter());
    return await pollingJobStatus(job.id);
  }
};

const pollingJobStatus = (jobId) => {
  let jobStatus = 'IN_PROGRESS';
  let counter = 0;
  let url, fileName;
  let isRequestInProgress: boolean = false;

  return new Promise<{ url: string; fileName: string }>((resolve, reject) => {
    let interval = setInterval(async () => {
      if (jobStatus === 'IN_PROGRESS' && counter < 60) {
        if (isRequestInProgress) return;

        isRequestInProgress = true;
        try {
          const res: any = await httpService.api({
            type: 'getExportLanguageResults',
            urlParams: { jobId: jobId },
          });

          counter++;
          jobStatus = res.status;
          if (jobStatus === 'COMPLETED_SUCCESSFULLY') {
            url = res.url;
            fileName = res.fileName;

            dispatch(decrementBusyCounter());
            clearInterval(interval);
            resolve({ url, fileName });
          }
        } finally {
          isRequestInProgress = false;
        }
      } else {
        dispatch(decrementBusyCounter());
        clearInterval(interval);
        resolve({ url, fileName });
      }
    }, POLLING_INTERVAL);
  });
};

export const publishAll = (data, setSelectedLanguage, setData) => {
  modalService
    .openModal('confirm', {
      text: data.publishAll ? 'languages.select-publish-message' : 'languages.publish-all-message',
      showCloseBtn: true,
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: data.publishAll
        ? 'languages.select-publish-header'
        : 'languages.publish-all-header',
    })
    .then((confirm) => {
      if (confirm) {
        httpService
          .api({
            type: 'publishAll',
            query: { publish: !data.publishAll },
          })
          .then((res: any) => {
            getListLanguages(setSelectedLanguage, setData);
          });
      }
    });
};
