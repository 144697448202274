import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';

export const DATA_SOURCE_MENTION_LENGTH = 20;

export const alarmOnlyMentionHtmlRegex =
  /<a href=\"datasource:\/\/(ALARM_NAME|ALARM_STATUS|ALARM_DESCRIPTION|ALARM_RESOLUTION)\" class=\"wysiwyg-mention\" data-mention data-value=\"(.*?)\">.*?<\/a>/g;

export const mentionHtmlRegex =
  /<a href=\"datasource:\/\/(\d+|ALARM_NAME|ALARM_STATUS|ALARM_DESCRIPTION|ALARM_RESOLUTION)\" class=\"wysiwyg-mention\" data-mention data-value=\"(.*?)\">.*?<\/a>/g;
export const mentionHtmlRegexWithNoPointerEvents =
  /<a href=\"datasource:\/\/(\d+|ALARM_NAME|ALARM_STATUS|ALARM_DESCRIPTION|ALARM_RESOLUTION)\" class=\"wysiwyg-mention\" data-mention data-value=\"(.*?)\"\W*?(style=\"pointer-events: none;\")?>.*?<\/a>/g;
const beMentionHtmlRegex =
  /{{\s?(\d+|ALARM_NAME|ALARM_STATUS|ALARM_DESCRIPTION|ALARM_RESOLUTION)\s?}}/g;

export const convertLocalHtmlToServer = (html, asPlainText = false, preserverLineFeed = false) => {
  const beHtml = html.replace(mentionHtmlRegex, '{{ $1 }}');

  if (!asPlainText) return beHtml;

  const { contentBlocks, entityMap } = htmlToDraft(beHtml);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  if (preserverLineFeed) return contentState.getPlainText('\u000A');
  else return contentState.getPlainText(' ');
};

export const getUsedDataSources = (html, usedDataSources = null) => {
  const res = usedDataSources || [];
  const matches = html.matchAll(mentionHtmlRegex);
  if (matches) {
    for (const match of matches)
      if (/\d+/.test(match[1]) && !res.some((r) => r === match[1])) res.push(match[1]);
  }

  return res;
};

export const convertLocalHtmlToPlainTextWithMentions = (
  html,
  noPointerEvents = false,
  preserverLineFeed = false
) => {
  let newHtml = html.replace(mentionHtmlRegexWithNoPointerEvents, '{{$1, $2}}');

  const { contentBlocks, entityMap } = htmlToDraft(newHtml);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  if (preserverLineFeed) newHtml = contentState.getPlainText().replace(/\u000A/g, '<BR/>');
  else newHtml = contentState.getPlainText(' ');

  if (noPointerEvents)
    return newHtml.replace(
      /{{(\d+|ALARM_NAME|ALARM_STATUS|ALARM_DESCRIPTION|ALARM_RESOLUTION), (.*?)}}/g,
      '<a href="datasource://$1" class="wysiwyg-mention" data-mention data-value="$2" style="pointer-events: none;">#$2</a>'
    );
  else
    return newHtml.replace(
      /{{(\d+|ALARM_NAME|ALARM_STATUS|ALARM_DESCRIPTION|ALARM_RESOLUTION), (.*?)}}/g,
      '<a href="datasource://$1" class="wysiwyg-mention" data-mention data-value="$2">#$2</a>'
    );
};

export const countMentions = (html) => {
  return (html.match(mentionHtmlRegex) || []).length;
};

export const getHtmlLength = (html, asPlainText = false, includeMentions = true) => {
  let htmlWithoutMentions = html.replace(mentionHtmlRegex, '');
  const mentionsCount = countMentions(html);

  if (asPlainText) {
    const { contentBlocks, entityMap } = htmlToDraft(htmlWithoutMentions);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    htmlWithoutMentions = contentState.getPlainText(' ');
  }

  return includeMentions
    ? htmlWithoutMentions.length + DATA_SOURCE_MENTION_LENGTH * mentionsCount
    : htmlWithoutMentions.length;
};

export const convertServerHtmlToLocal = (html, dataSources, fromPlainText = false) => {
  let localHtml = html || '';
  if (fromPlainText) {
    localHtml = `<p>${localHtml.replace(/\n/g, '<BR/>')}</p>`;
  }

  const dataSourceIds = new Set(localHtml.matchAll(beMentionHtmlRegex));

  if (dataSourceIds) {
    for (const match of dataSourceIds) {
      const regex = new RegExp(`{{\\s?${match[1]}\\s?}}`, 'g');
      localHtml = localHtml.replace(regex, getMentionHtmlByDataSourceId(match[1], dataSources));
    }
  }
  return localHtml;
};

const getMentionHtmlByDataSourceId = (dataSourceId, dataSources) => {
  const dataSource = /\d+/.test(dataSourceId)
    ? dataSources.find((d) => d.id === +dataSourceId)
    : dataSources.find((d) => d.id === dataSourceId);

  if (dataSource) {
    return `<a href="datasource://${dataSourceId}" class="wysiwyg-mention" data-mention data-value="${dataSource.name}">#${dataSource.name}<\/a>`;
  } else {
    return `{{ ${dataSourceId} }}`;
  }
};
