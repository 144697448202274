import React, { useState, useCallback, useEffect } from 'react';

import I18n from '@components/I18n';
import MultiSelect from '@components/MultiSelect';
import { useIsMounted } from '@core/hooks/useIsMounted';
import { httpService } from '@core/http/HttpService';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { AssetTagsResponse } from '../StepOne.interface';
import styles from '../StepOne.scss';
import { tagFormatMap } from '@core/mapsAndDefinitions';
import SingleSelectWithChip from '@components/SingleSelectWithChip';

const AssetTagTypesInput = ({
  assetTypes,
  tagTypes,
  onSelectedTagTypes,
  widgetType,
  isMaxDataTags,
}) => {
  const isMounted = useIsMounted();
  const [assetTypeTagTypeOptions, setAssetTypeTagTypeOptions] = useState([]);
  const getAssetTagTypes = useCallback((assetIds, widgetType) => {
    httpService
      .api({
        type: 'getAssetTagTypes',
        query: { p: 1, ps: 1000, assetTypeIds: assetIds.join(','), widgetType },
        disableBI: true,
      })
      .then((tagTypes: AssetTagsResponse) => {
        if (isMounted.current) {
          setAssetTypeTagTypeOptions(
            tagTypes.results.map((tag) => ({ id: tag.id, name: tag.name, type: tag.type }))
          );
        }
      });
  }, []);

  useEffect(() => {
    getAssetTagTypes(
      assetTypes.map((asset) => asset.id),
      widgetType
    );
  }, [assetTypes]);

  return (
    <div className={styles.dataField}>
      <div className={styles.fieldLabel}>
        <I18n>create-widget-page.create-widget.step-one.tag-types</I18n>
        {tagTypes.length > 0 && ` (${tagTypes.length})`}
        <div className={styles.fieldSubLabel}>
          <I18n>create-widget-page.create-widget.step-one.tag-types-sub</I18n>
        </div>
      </div>
      <div className={styles.inputField}>
        {widgetType === 'switch' ? (
          <SingleSelectWithChip
            id={'tagTypes'}
            isOptionDisabled={(option) =>
              tagFormatMap[option.type] &&
              getConfigValueFromWidgetSettings(widgetType, 'assetIsNotDisabled') &&
              !getIsIncludeInSettings(widgetType, 'assetIsNotDisabled', tagFormatMap[option.type])
            }
            values={tagTypes}
            options={assetTypeTagTypeOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedTagTypes}
            tableConfig={(option) => ({
              type: option.type,
            })}
            optionType="assetProps"
          />
        ) : (
          <MultiSelect
            id="tagTypes"
            values={tagTypes}
            isOptionDisabled={(option) =>
              (tagFormatMap[option.type] &&
                getConfigValueFromWidgetSettings(widgetType, 'assetIsNotDisabled') &&
                !getIsIncludeInSettings(
                  widgetType,
                  'assetIsNotDisabled',
                  tagFormatMap[option.type]
                )) ||
              (isMaxDataTags && !tagTypes.find((tag) => option.id === tag.id))
            }
            closeMenuOnSelect={widgetType === 'map'}
            isDisabled={getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'TagTypes')}
            options={assetTypeTagTypeOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedTagTypes}
            tableConfig={(option) => ({
              type: option.type,
            })}
            optionType="assetProps"
          />
        )}
      </div>
    </div>
  );
};

export default AssetTagTypesInput;
