import ProgressBarWidget from '@components/widgets/ProgressBarWidget';
import { WidgetMapItem } from '../widgetMap.interface';
import { onlyPositiveDigitsRegex } from '@core/utils';
import { stepThreeDefaultValidation } from '../widget.utils';

export const progressBar: WidgetMapItem = {
  id: 'progress_bar',
  name: 'progress_bar',
  component: ProgressBarWidget,
  minWidth: 166,
  minHeight: 100,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_progressbar.svg',
    thumbnail: 'progressBar',
  },
  editModalSize: {},
  settings: {
    disabledScope: ['RAW_DATA'],
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => widgetData.scope === 'LAST_VALUE',
    dataChangesToHandle: ['metrics', 'filters', 'calculations', 'customizationMetrics'],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) =>
        (widgetData.customizationMetrics?.length && widgetData.calculations?.length) ||
        (widgetData.customization?.maxValue &&
          onlyPositiveDigitsRegex.test(widgetData.customization?.maxValue)),
    },
    extraMetricsText: {
      value: (scope) =>
        `create-widget-page.create-widget.step-three.progress-bar-empty-metrics-${
          scope === 'LAST_VALUE' ? 'last-value' : 'aggregated'
        }`,
      condition: () => true,
    },
    disableGrouping: {
      text: ({ eventTemplateId }) =>
        'create-widget-page.create-widget.step-three.progress-bar-grouping',
    },
    dragAndDropRules: {
      maxGrouping: (scope) => 0,
      maxMetrics: 1,
      metricsDataValidation: (scope, draggableItem) => {
        if (
          scope === 'LAST_VALUE' &&
          draggableItem.type !== 'numberType' &&
          draggableItem.type !== 'floatType'
        ) {
          return 'nonNumerical';
        }
        return null;
      },
      excludeNonNumericVariables: () => true,
    },
    showPreviewData: ['customization.maxValue', 'metrics'],
    accordionDisable: ['sort'],
    languageKeys: (isArWidget) => ['textualRemark'],
  },
};
