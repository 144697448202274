import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import styles from './Details.scss';
import Icon from '@components/Icon';
import * as Yup from 'yup';
import Button from '@components/Button';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';
import { httpService } from '@core/http/HttpService';
import { useDispatch } from 'react-redux';
import { setSelectedOrganization } from '@src/redux/organizations/organizations.actions';
import organizationTypeMap from '../OrganizationTypeMap';
import {
  getFieldsCfg,
  getFieldsCfgMainContact,
  getFieldCfgPreferences,
  getFieldCfgDefaultDashboards,
  getFieldCfg2,
  getInitialValues,
  setDefaultDashboardsOptions,
  onSubmit,
  onDeleteOrg,
  get2FAFieldCfg,
  mfaModeOptions,
  mfaMethodOptions,
} from './detais.utils';
import { getPermissionStatus, getFlagStatus } from '@core/ffAndPermissions';

function Details() {
  const selectedOrganization = useSelector((store) => store.organizations);
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const targetOrganizationType = useSelector((state) => state.organizations.type);
  const { userOrganizationId } = useSelector((state) => state.login);
  const selectedOrganizationsType = selectedOrganization.type;
  const userOrganizationType = useSelector((state: any) => state.config.organizationDetails.type);
  const userRoles = useSelector((state) => state.config.roles);
  const isUnassigned = useSelector((state) => state.config.isUnAssigned);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [owners, setOwners] = useState([]);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [mbcDashboardOptions, setMbcDashboardOptions] = useState([]);
  const [customerDashboardOptions, setCustomerDashboardOptions] = useState([]);
  const orgType = selectedOrganization?.type && organizationTypeMap[selectedOrganization.type];
  const isUserAdmin = useMemo(() => userRoles.find((role) => role.includes('Admin')), [userRoles]);
  const isUnitronicsAdminUser = organizationTypeMap[userOrganizationType] === 1;

  const showDefaultDashboardFields = useMemo(() => getPermissionStatus('DASHBOARD', 'VIEW'), []);
  const showLanguageField = useMemo(() => getPermissionStatus('LANGUAGE', 'VIEW'), []);
  const editOrganizationField = useMemo(() => getPermissionStatus('ORGANIZATION', 'EDIT'), []);
  const showOrganizationIdField = useMemo(
    () =>
      getFlagStatus('organization-details.organization-id') ||
      (selectedOrganization.selectedOrganizationsId === selectedMBId &&
        (userRoles.includes('Unitronics Admin') ||
          userRoles.includes('Machine Builder Admin') ||
          userRoles.includes('Machine Builder Organization Viewer') ||
          userRoles.includes('Machine Builder Organization Manager'))) ||
      (userOrganizationType === 'UNITRONICS_CHANNEL' &&
        targetOrganizationType === 'MACHINE_BUILDER'),
    []
  );

  const sectionWidthRef = useRef(null);
  const [element, setElement] = useState<Element>();
  const [shouldRearrange, setShouldRearrange] = useState(false);

  useEffect(() => {
    setElement(sectionWidthRef.current);
  }, [sectionWidthRef]);

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      if (entry.contentBoxSize) {
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize;

        setShouldRearrange(contentBoxSize.inlineSize < 700 ? true : false);
      } else {
        setShouldRearrange(false);
      }
    }
  });

  if (element) {
    resizeObserver.observe(element);
  }

  const show2FA = useMemo(
    () =>
      userRoles.includes('Unitronics Admin') ||
      userRoles.includes('Unitronics Channel Organization Manager') ||
      userRoles.includes('Unitronics Channel Organization Viewer') ||
      userRoles.includes('Unitronics Channel Admin') ||
      userRoles.includes('Machine Builder Organization Manager') ||
      userRoles.includes('Machine Builder Organization Viewer') ||
      userRoles.includes('Machine Builder Admin') ||
      userRoles.includes('Machine Builder Channel Organization Manager') ||
      userRoles.includes('Machine Builder Channel Organization Viewer') ||
      userRoles.includes('MB Channel Admin') ||
      userRoles.includes('Customer Admin'),
    [userRoles]
  );

  const initialValues = useMemo(() => {
    return getInitialValues(
      selectedOrganization,
      setEditMode,
      languagesOptions,
      mfaModeOptions(selectedOrganization.mfaNullAllowed),
      mfaMethodOptions
    );
  }, [selectedOrganization, languagesOptions]);

  useEffect(() => {
    if (editOrganizationField) {
      selectedOrganization?.selectedOrganizationsId &&
        httpService
          .api({
            type: 'getAdminUsers',
            query: { p: 1, ps: 500 },
          })
          .then((res: any) => {
            setAdminUsers(res.results);
          });
    } else {
      setAdminUsers([
        {
          ...selectedOrganization.user,
          accountId: selectedOrganization.user.id,
          mainContact: true,
        },
      ]);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    selectedOrganization?.selectedOrganizationsId &&
      httpService
        .api({
          type: 'getOrganizationDefaultLanguage',
          urlParams: {
            organizationId: selectedOrganization.selectedOrganizationsId,
          },
        })
        .then((res: any) => {
          setLanguagesOptions(
            res.languages
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => ({ value: x.id, label: x.name }))
          );
        });
  }, [selectedOrganization]);

  useEffect(() => {
    selectedOrganization?.selectedOrganizationsId &&
      httpService
        .api({
          type: 'getParentsAvailable',
          urlParams: {
            tenantId: userOrganizationId,
            organizationId: selectedOrganization.selectedOrganizationsId,
          },
        })
        .then((res: any) => {
          setOwners(res);
        });
  }, [selectedOrganization]);

  useEffect(() => {
    selectedOrganization?.selectedOrganizationsId &&
      organizationTypeMap[userOrganizationType] === 3 &&
      setDefaultDashboardsOptions(
        selectedMBId,
        selectedOrganization,
        setDashboardOptions,
        setMbcDashboardOptions,
        setCustomerDashboardOptions,
        orgType
      );
  }, [selectedOrganization]);

  const fieldsCfg = getFieldsCfg(
    Yup,
    owners,
    userOrganizationType,
    selectedOrganizationsType,
    orgType,
    showOrganizationIdField,
    isUnitronicsAdminUser
  );

  const fieldsCfgMainContact = getFieldsCfgMainContact(Yup, adminUsers);

  const fieldCfgPreferences = getFieldCfgPreferences(
    Yup,
    languagesOptions,
    showLanguageField,
    editOrganizationField,
    orgType,
    isUnitronicsAdminUser
  );

  const fieldCfgDefaultDashboards = getFieldCfgDefaultDashboards(
    dashboardOptions,
    mbcDashboardOptions,
    customerDashboardOptions,
    orgType,
    organizationTypeMap[userOrganizationType],
    showDefaultDashboardFields
  );

  const fieldCfg2 = getFieldCfg2(
    Yup,
    orgType,
    organizationTypeMap[userOrganizationType],
    isUserAdmin
  );

  const fieldCfg2FA = get2FAFieldCfg(
    selectedOrganization.mfaNullAllowed,
    isUnitronicsAdminUser,
    isUnassigned
  );

  const save = (values: any) => {
    onSubmit(
      values,
      userOrganizationType,
      dispatch,
      selectedOrganization,
      setEditMode,
      userOrganizationId,
      selectedMBId
    );
  };

  const updateStatus = (status) => {
    const apiType = `${status.toLowerCase()}Org`;
    httpService
      .api({
        type: apiType,
        data: { status },
      })
      .then((res) => {
        dispatch(setSelectedOrganization({ status }));
      });
  };

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  const hasPermission = useMemo(() => getPermissionStatus('ORGANIZATION', 'EDIT'), []);
  const isUserOrgSelected = useMemo(
    () => +userOrganizationId === +selectedOrganization.selectedOrganizationsId,
    [userOrganizationId, selectedOrganization]
  );
  const isActionDisabled = useCallback(
    (values, orgStatus) =>
      values.status === orgStatus ||
      !hasPermission ||
      selectedOrganization.selectedOrganizationsId === parseInt(userOrganizationId),
    [hasPermission]
  );

  const canShowAdminLiveDashboard = (): boolean => {
    return (
      organizationTypeMap[userOrganizationType] === 1 &&
      orgType === 3 &&
      selectedOrganization.status === 'ACTIVE'
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, enable: editMode }}
      validationSchema={validationSchema([
        ...fieldsCfg,
        ...fieldsCfgMainContact,
        ...fieldCfgPreferences,
        ...fieldCfgDefaultDashboards,
        ...fieldCfg2,
      ])}
      onSubmit={save}>
      {({ isValid, isSubmitting, values, errors, setFieldValue }) => {
        return (
          <FormikForm className={styles.form}>
            <div
              ref={sectionWidthRef}
              className={styles.box}
              style={{
                height: shouldRearrange
                  ? `calc(110% - ${editMode ? '40px' : '10px'})`
                  : `calc(100% - ${editMode ? '40px' : '10px'})`,
              }}>
              <div
                className={classNames(
                  styles.iconsWrapper,
                  canShowAdminLiveDashboard() && styles.iconsWrapperViewDashboard
                )}>
                {canShowAdminLiveDashboard() && (
                  <a
                    href={
                      '/main?otherOgranizationId=' + selectedOrganization.selectedOrganizationsId
                    }
                    target="_blank">
                    <div
                      style={{ paddingLeft: '30px', paddingTop: '14px' }}
                      className={classNames(
                        'pointer',
                        styles.iconWrapper
                        // (isUserOrgSelected || !hasPermission) && styles.disabled
                      )}>
                      <Icon
                        color="var(--systemFont)"
                        className={styles.iconBtn}
                        // disabled={isUserOrgSelected || !hasPermission}
                        type="liveDashboard"></Icon>
                      <I18n element="div" className={styles.iconText}>
                        details.icons.view-dashboard
                      </I18n>
                    </div>
                  </a>
                )}
                <div className={styles.icons}>
                  <div
                    className={classNames(
                      'pointer',
                      styles.iconWrapper,
                      (isUserOrgSelected || !hasPermission) && styles.disabled
                    )}
                    onClick={() => onDeleteOrg(selectedOrganization, dispatch)}>
                    <Icon
                      color="var(--systemFont)"
                      className={styles.iconBtn}
                      disabled={isUserOrgSelected || !hasPermission}
                      type="deleteOrgIcon"></Icon>
                    <I18n element="div" className={styles.iconText}>
                      details.icons.delete
                    </I18n>
                  </div>
                  <div
                    className={classNames(
                      'pointer',
                      styles.iconWrapper,
                      isActionDisabled(values, 'SUSPENDED') && styles.disabled
                    )}
                    onClick={(e) => updateStatus('SUSPENDED')}>
                    <Icon
                      color="var(--systemFont)"
                      className={styles.iconBtn}
                      disabled={isActionDisabled(values, 'SUSPENDED')}
                      type="suspend"></Icon>
                    <I18n element="div" className={styles.iconText}>
                      details.icons.suspend
                    </I18n>
                  </div>
                  <div
                    className={classNames(
                      'pointer',
                      styles.iconWrapper,
                      isActionDisabled(values, 'ARCHIVED') && styles.disabled
                    )}
                    onClick={(e) => updateStatus('ARCHIVED')}>
                    <Icon
                      color="var(--systemFont)"
                      className={styles.iconBtn}
                      disabled={isActionDisabled(values, 'ARCHIVED')}
                      type="archiveOrgIcon"></Icon>
                    <I18n element="div" className={styles.iconText}>
                      details.icons.archive
                    </I18n>
                  </div>
                  <div
                    className={classNames(
                      'pointer',
                      styles.iconWrapper,
                      isActionDisabled(values, 'ACTIVE') && styles.disabled
                    )}
                    onClick={(e) => updateStatus('ACTIVE')}>
                    <Icon
                      color="var(--systemFont)"
                      className={styles.iconBtn}
                      disabled={isActionDisabled(values, 'ACTIVE')}
                      type="activate"></Icon>
                    <I18n element="div" className={styles.iconText}>
                      details.icons.activate
                    </I18n>
                  </div>

                  {!editMode && (
                    <div
                      className={classNames(
                        'pointer',
                        styles.iconWrapper,
                        !hasPermission && styles.disabled
                      )}
                      onClick={() => setEditMode(!editMode)}>
                      <Icon
                        color="var(--systemFont)"
                        type="editOrgDetails"
                        className={styles.iconBtn}
                        disabled={!hasPermission}
                      />
                      <I18n element="div" className={styles.iconText}>
                        details.icons.edit
                      </I18n>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.details}>
                <div
                  className={styles.item}
                  style={{
                    display: shouldRearrange ? 'block' : 'flex',
                  }}>
                  <div className={styles.fields}>
                    {fieldsCfg.map((field, idx) => (
                      <FormikField
                        key={idx}
                        editMode={editMode}
                        {...field}
                        values={values}
                        setFieldValue={setFieldValue}></FormikField>
                    ))}
                  </div>
                  <div className={styles.fields}>
                    <I18n element="div" className={styles.subTitle}>
                      details.preferences
                    </I18n>
                    <div className={styles.fieldsContent}>
                      {fieldCfgPreferences.map((field, idx) => {
                        if (field.isVisible) {
                          return (
                            <FormikField
                              key={idx}
                              editMode={editMode}
                              {...field}
                              values={values}
                              setFieldValue={setFieldValue}></FormikField>
                          );
                        }
                      })}
                    </div>
                    {orgType === 3 && organizationTypeMap[userOrganizationType] === 3 && (
                      <>
                        <I18n element="div" className={styles.subTitle}>
                          details.default-dashboards
                        </I18n>
                        <div className={orgType === 3 ? styles.fieldsContent : ''}>
                          {fieldCfgDefaultDashboards.map((field, idx) => (
                            <FormikField
                              key={idx}
                              editMode={editMode}
                              {...field}
                              values={values}
                              setFieldValue={setFieldValue}></FormikField>
                          ))}
                        </div>
                      </>
                    )}
                    {show2FA && (
                      <>
                        <I18n element="div" className={styles.subTitle}>
                          details.two-factor-authentication
                        </I18n>
                        <div className={styles.fieldsContent}>
                          {fieldCfg2FA.map((field, idx) => (
                            <FormikField
                              key={idx}
                              editMode={editMode}
                              {...field}
                              values={values}
                              setFieldValue={setFieldValue}></FormikField>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={styles.item}
                  style={{
                    display: shouldRearrange ? 'block' : 'flex',
                  }}>
                  <div className={styles.fields}>
                    <I18n element="div" className={styles.subTitle}>
                      details.main-contact
                    </I18n>
                    <div className={styles.fieldsContent}>
                      {fieldsCfgMainContact.map((field, idx) => (
                        <FormikField
                          key={idx}
                          editMode={editMode}
                          {...field}
                          values={values}></FormikField>
                      ))}
                    </div>
                  </div>
                  <div className={styles.fields}>
                    {fieldCfg2.map((field, idx) => (
                      <FormikField
                        key={idx}
                        editMode={editMode}
                        {...field}
                        values={values}
                        setFieldValue={setFieldValue}></FormikField>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {editMode && (
              <div className={styles.footerButtons}>
                <Button
                  styles={{ width: 92, marginRight: 13 }}
                  mode="cancel"
                  onClick={() => setEditMode(false)}>
                  <I18n>general.cancel</I18n>
                </Button>
                <Button styles={{ width: 92 }} type="submit" disabled={!isValid}>
                  <I18n>general.save</I18n>
                </Button>
              </div>
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
}

export default Details;
