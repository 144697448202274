import React from 'react';
import classNames from 'classnames';
import style from './TextField.scss';
import I18n from '@components/I18n';

function TextField(props: any) {
  const { field, customStyle, getTranslatedValuesToDisplay } = props;
  const value = getTranslatedValuesToDisplay
    ? getTranslatedValuesToDisplay(props[field])
    : props[field];

  return (
    <>
      {getTranslatedValuesToDisplay ? (
        <I18n element={'div'}>{value}</I18n>
      ) : (
        <div
          className={classNames(style.text, 'ellipsis-overflow')}
          title={value}
          style={customStyle ? customStyle : null}>
          {value}
        </div>
      )}
    </>
  );
}

export default TextField;
