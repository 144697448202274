import React from 'react';
import { AssetTypeData } from '@redux/redux.interface.d';
import I18n from '@components/I18n';

function AssetTypesStatus(props: AssetTypeData) {
  const { status } = props;
  return status === 'DRAFT_UPDATING' ? <I18n title={`enum.${status}`}>{`enum.${status}`}</I18n> : null;
}

export default AssetTypesStatus;
