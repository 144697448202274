export const vertexShader = `
  varying vec4 vPos;
  varying vec3 vNormal;
  varying vec2 vUv;

	void main() {
  	vUv = uv;
		vNormal = normalMatrix * normal;
		vPos = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		gl_Position = vPos;
	}
`;

export const fragmentShader = `
  varying vec4 vPos;
  varying vec3 vNormal;
  varying vec2 vUv;

  uniform float borderRadius;
  uniform float borderWidth;
  uniform vec2 size;
  uniform vec3 mainColor;
  uniform float mainAlpha;
  uniform vec3 borderColor;
  uniform float borderAlpha;

  float getEdgeDist() {
    vec2 ndc = vec2(vUv.x * 2.0 - 1.0, vUv.y * 2.0 - 1.0);
    vec2 planeSpaceCoord = vec2(size.x * 0.5 * ndc.x, size.y * 0.5 * ndc.y);
    vec2 corner = size * 0.5;
    vec2 offsetCorner = corner - abs(planeSpaceCoord);
    float innerRadDist = min(offsetCorner.x, offsetCorner.y) * -1.0;
    float roundedDist = length(max(abs(planeSpaceCoord) - size * 0.5 + borderRadius, 0.0)) - borderRadius;
    float s = step(innerRadDist * -1.0, borderRadius);
    return mix(innerRadDist, roundedDist, s);
  }

  void main() {
    float edgeDist = getEdgeDist();
    float discardFactor = step(0.0, -edgeDist);
    
    vec3 finalColor = mix(mainColor, borderColor, step(-borderWidth, edgeDist));
    float alpha = mix(mainAlpha, borderAlpha, step(-borderWidth, edgeDist));

    gl_FragColor = vec4(finalColor, alpha) * discardFactor;
  }
`;
