import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { RadialGauge, LinearGauge } from 'canvas-gauges';
import { convertGaugeCustomization, getOptions, onNavigateDashboard } from './Gauge.utils';
import { GaugeProps } from './Gauge.interface.d';
import { isEmpty } from 'lodash';
import { getDefaultRadialGaugeCustomization } from '@pages/CreateWidgetPage/DataStepComponent/StepFour/CostumizationComponents/Gauge/RadialGaugeCustomization/RadialGaugeCustomizationUtils';
import { getDefaultLinearGaugeCustomization } from '@pages/CreateWidgetPage/DataStepComponent/StepFour/CostumizationComponents/Gauge/LinearGaugeCustomization/LinearGaugeCustomizationUtils';

const gaugeStyle = { height: '100%', width: '98%', margin: 'auto', display: 'flex' };

/**
 * Renders a customizable gauge. Uses the canvas-gauges 3rd party.
 * See: https://canvas-gauges.com
 */
function Gauge(props: GaugeProps) {
  const {
    style,
    className,
    customization,
    data,
    widget,
    navigateDashboard,
    type,
    isPreview,
    defaultDecimalDigits,
  } = props;
  const gaugeHeight = useMemo(() => (isPreview ? '60%' : '100%'), [isPreview]);
  const isRadial = useMemo(
    () =>
      // On edit widget mode - getting 'type' property but on canvas preview mode - getting 'widget' with the 'type' property.
      type === 'radial_gauge' || widget?.type === 'radial_gauge',
    [widget, type]
  );
  const customizationData = isEmpty(customization)
    ? isRadial
      ? getDefaultRadialGaugeCustomization(defaultDecimalDigits)
      : getDefaultLinearGaugeCustomization(defaultDecimalDigits)
    : convertGaugeCustomization(data, customization);

  const value = data?.results?.length > 0 ? (Object.values(data.results[0])[1] as number) : 0; // Extracting the value result from the data
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const gaugeRef = isRadial ? useRef<RadialGauge>(null) : useRef<LinearGauge>(null);

  // initializes/destructs the gauge on mount/unmount.
  useEffect(() => {
    initGauge();

    return () => {
      gaugeRef.current && gaugeRef.current.destroy();
      gaugeRef.current = null;
    };
  }, []);

  // Updates the gauge when any relevant prop changes.
  useEffect(() => {
    if (gaugeRef.current) {
      if (
        (!customizationData ||
          !customizationData.ticks ||
          +customizationData.ticks.majorEnd !== +customizationData.ticks.majorStart) &&
        (customizationData.ticks.majorEnd - customizationData.ticks.majorStart) /
          customizationData.ticks.majorInterval <=
          10000
      ) {
        const options = getOptions({ customizationData, value, canvasEl, isRadial });
        gaugeRef.current.update(options);
      }
    } else {
      initGauge();
    }
  }, [customizationData, value]);

  const initGauge = useCallback(() => {
    if (gaugeRef.current) {
      gaugeRef.current.destroy();
      gaugeRef.current = null;
    }
    if (
      (!customizationData ||
        !customizationData.ticks ||
        customizationData.ticks.majorEnd !== customizationData.ticks.majorStart) &&
      (customizationData.ticks.majorEnd - customizationData.ticks.majorStart) /
        customizationData.ticks.majorInterval <=
        10000
    ) {
      const options = getOptions({ customizationData, value, canvasEl, isRadial });
      gaugeRef.current = isRadial
        ? new RadialGauge(options).draw()
        : new LinearGauge(options).draw();
    }
  }, [customizationData, value]);

  const onNavigate = () => {
    onNavigateDashboard(widget, value, data, navigateDashboard);
  };

  return (
    <div
      style={{
        ...gaugeStyle,
        cursor: navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
      }}
      onClick={onNavigate}>
      <ReactResizeDetector handleHeight handleWidth onResize={initGauge} />
      <div style={{ ...gaugeStyle, height: gaugeHeight }}>
        <canvas style={style} className={className} ref={canvasEl} />
      </div>
    </div>
  );
}

export default React.memo(Gauge);
