import React from 'react';
import classNames from 'classnames';

import { components } from 'react-select';
import styles from './HeaderTableCheckbox.scss';

const HeaderTableCheckbox = props => {
  const {
    selectProps: { tableConfig },
    options
  } = props;
  const keys = options.length ? Object.keys(tableConfig(options[0])) : [];

  return (
    <components.MenuList {...props}>
      {keys.length > 0 && (
        <div className={styles.headerRow}>
          <div className={classNames(styles.headerColumn, 'ellipsis-overflow')}>{`${keys[0]}`}</div>
          <div
            className={classNames(
              styles.headerColumnMargin,
              'ellipsis-overflow'
            )}>{`${keys[1]}`}</div>
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

export default HeaderTableCheckbox;
