import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TableColunm } from '@components/Table/table.interface';
import Table from '@components/Table';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@http/HttpService';
import { editAssetTypeService } from '@modals/EditAssetTypeModal/EditAssetTypeService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { useSelector } from '@redux/useSelector';
import AssetTypesPlcType from './AssetTypesPlcType';
import AssetTypesStatus from './AssetTypesStatus';
import AssetTypesName from './AssetTypesName';
import AssetTypesLabel from './AssetTypesLabel';
import { AssetTypeDataResponse } from './assetTypeData.interface';
import { AssetTypesTableProps } from './assetTypes.interface';
import { compact } from 'lodash';
import styles from './AssetTypesTable.scss';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';

function AssetTypesTable(props: AssetTypesTableProps) {
  const refresh = useSelector((state) => state.assetType.refresh);
  const assetsTypes = useSelector((state) => state.assetType.assetsTypeData);
  const [nextPage, setNextPage] = useState(1);
  const [disabledLoad, setDisableLoad] = useState(false);
  const { searchText } = props;
  const defaultOrdering = { label: 'status,name', orderSign: '' };
  const [_order, setOrder] = useState(defaultOrdering);

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);
  const pageSize = useMemo(() => 50, []);

  useEffect(() => getAssetTypes(true), [searchText]);

  useEffect(() => {
    if (refresh) {
      getAssetTypes(true, _order);
    }
  }, [refresh]);

  const colunmsCfg: Array<TableColunm> = compact([
    {
      Component: AssetTypesName,
      label: 'asset-types-tab.name',
      field: 'name',
      style: { width: '32%', minWidth: '32%', maxWidth: '32%' },
      isSortable: true,
    },
    {
      Component: AssetTypesLabel,
      label: 'asset-types-tab.labels',
      field: 'label',
      style: { width: '32%', minWidth: '32%', maxWidth: '32%', paddingRight: '20px' },
    },
    {
      Component: AssetTypesPlcType,
      label: 'asset-types-tab.plc-type',
      field: 'plcType',
      style: { width: '23%', minWidth: '23%', maxWidth: '23%' },
      isSortable: true,
    },
    {
      Component: AssetTypesStatus,
      label: '',
      field: 'status',
      style: { width: '13%', minWidth: '13%', maxWidth: '13%', color: 'var(--formikErrorText)' },
    },
  ]);

  const getAssetTypes = (firstInit = false, order = defaultOrdering) => {
    setDisableLoad(true);

    httpService
      .api({
        type: 'getAssetTypes',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: pageSize,
          search: searchText ? searchText : null,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      })
      .then((res: AssetTypeDataResponse) => {
        res.next && setDisableLoad(false);
        if (firstInit) {
          assetsService.setAssetsTypeTableData({
            totalCount: res.allCount,
            resultsCount: res.count,
            assetsTypeData: res.results,
          });
          setNextPage(2);
        } else {
          assetsService.addAssetsTypeTableData({
            totalCount: res.allCount,
            resultsCount: res.count,
            assetsTypeData: res.results,
          });
          setNextPage(nextPage + 1);
        }
      });
  };

  const openEditAssetTypeModal = useCallback((rowData, rowIdx) => {
    modalService
      .openModal('editAssetType', { rowData, rowIdx })
      .then((res) => editAssetTypeService.clearEditAssetType());
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={assetsTypes}
        getData={(firstInit, order) => {
          order && setOrder(order);
          getAssetTypes(firstInit, order);
        }}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
        onRowClicked={openEditAssetTypeModal}
        defaultOrdering={defaultOrdering}
      />
    </div>
  );
}

export default AssetTypesTable;
