import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import I18n from '@components/I18n';
import classNames from 'classnames';
import { SwitchCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';

const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  section: {
    margin: '10px 0',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
  },
  fontSizeInput: {
    width: '68px',
    height: '40px',
  },
}));

const TextualRemarkSection = (props) => {
  const classes = useStyles(props);
  const {
    setCustomizationData,
    customization,
    horizontalAlignmentOptions,
    verticalAlignmentOptions,
  } = props;
  const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };
  const {
    textualRemark,
    textualRemarkFontSize,
    textualRemarkColor,
    textualRemarkHorizontalAlignment,
    textualRemarkVerticalAlignment,
  } = (customization as SwitchCustomization) || {};

  return (
    <div className={classes.section}>
      <I18n element="div" className={classes.titleWrapper}>
        create-widget-page.create-widget.step-four.switch.textual-remark
      </I18n>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.switch.textual-remark.add
        </I18n>
        <TextField
          value={textualRemark || ''}
          className={classes.textField}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value ? e.target.value : null, 'textualRemark');
          }}
          inputProps={{
            maxLength: 200,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.size
        </I18n>
        <TextField
          className={classes.fontSizeInput}
          value={textualRemarkFontSize || ''}
          margin="dense"
          type="number"
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(+e.target.value, 'textualRemarkFontSize');
          }}
          onBlur={(e) => {
            setCustomizationData(
              100 < +textualRemarkFontSize
                ? 100
                : 1 > +textualRemarkFontSize
                ? 1
                : +textualRemarkFontSize,
              'textualRemarkFontSize'
            );
          }}
          inputProps={{
            min: 1,
            max: 100,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.text-color
        </I18n>
        <ColorInput
          disabled={!textualRemark}
          value={textualRemarkColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'textualRemarkColor')}
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.switch.textual-remark.horizontal-alignment
        </I18n>
        <Select
          styles={selectCustomStyle}
          options={horizontalAlignmentOptions}
          value={
            textualRemarkHorizontalAlignment &&
            horizontalAlignmentOptions.find((opt) => opt.value === textualRemarkHorizontalAlignment)
          }
          onChange={(option) =>
            setCustomizationData(option.value, 'textualRemarkHorizontalAlignment')
          }
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.switch.textual-remark.vertical-alignment
        </I18n>
        <Select
          styles={selectCustomStyle}
          options={verticalAlignmentOptions}
          value={
            textualRemarkVerticalAlignment &&
            verticalAlignmentOptions.find((opt) => opt.value === textualRemarkVerticalAlignment)
          }
          onChange={(option) =>
            setCustomizationData(option.value, 'textualRemarkVerticalAlignment')
          }
        />
      </div>
    </div>
  );
};

export default TextualRemarkSection;
