import { httpService } from '@core/http/HttpService';
import {
  setSelectedOrganization,
  deleteOrganizatioData,
} from '@src/redux/organizations/organizations.actions';
import { walk, changeNodeAtPath, removeNodeAtPath } from 'react-sortable-tree';
import { dispatch } from '@src/redux/store';
import { removePLCData } from '@src/redux/deviceManagment/plc/plc.actions';
import { removeAllAssetsData } from '@src/redux/deviceManagment/asset/asset.actions';
import { modalService } from '@core/modals/ModalService';
import organizationTypeMap from '../OrganizationTypeMap';
import { i18nService } from '@core/i18n/I18nService';
import { getVar } from '@core/canvas/widget.utils';

export const getNodeKey = ({ treeIndex, node }) => treeIndex;
const MIN_AMOUNT_OF_CHARS = 3;

/**
 * reqursive func to find MB(type 3) id of a node.
 * @param mbMap - includes key(child id) and value(parent id) of all types 4, 5 that where opened
 * @param nodeId - the node that we want to find his MB(type 3) id.
 */
export const getCurrentMB = (mbMap, nodeId) => {
  const findMBId = (id) => {
    return mbMap[id] ? findMBId(mbMap[id]) : id;
  };

  return findMBId(nodeId);
};

export const getResultDict = async (searchResults) => {
  const resultDict = { root: [] };

  try {
    searchResults.forEach((org) => {
      const parentId = org.parentId;
      const orgId = org.id;

      if (parentId === null) {
        resultDict.root.push(org);
      } else {
        resultDict[parentId] = resultDict[parentId] || [];
        resultDict[parentId].push(org);
      }

      if (!resultDict[orgId]) {
        resultDict[orgId] = [];
      }
    });

    Object.keys(resultDict).forEach((key) => {
      resultDict[key].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        if (a.country < b.country) return -1;
        if (a.country > b.country) return 1;
        return 0;
      });
    });
  } catch (err) {
    console.error('Error processing result dictionary:', err);
  }

  return resultDict;
};

export const getChildren = async ({ done, node }) => {
  try {
    const res: any = await httpService.api({
      type: 'getOrganizations',
      urlParams: { organizationId: node.id },
      disableBI: true,
    });
    if (res) {
      done(res.map((org) => ({ ...org, children: getChildren })));
    }
  } catch (err) {
    console.error(err);
  }
};

export const getSearchResults = async (searchText) => {
  try {
    const request: any = await httpService.api({
      type: 'getParentOrgHierarchyByName',
      query: { 'organization-sub-name': searchText },
      disableBI: true,
    });

    request.cancel && request.cancel();

    return request;
  } catch (err) {
    console.error(`Error fetching search results for "${searchText}":`, err);
  }
};

export const getOrganizationDetailsById = (organizationId, selectedMBId) => {
  httpService
    .api({
      type: 'getOrganizationDetails',
      urlParams: { organizationId },
    })
    .then((res: any) => {
      let newRes = res;
      if (res?.address?.formatted === 'Unknown') {
        newRes = {
          ...res,
          address: {
            ...res.address,
            formatted: i18nService.translate('details.company-address.unknown'),
          },
        };
      }
      dispatch(
        setSelectedOrganization({
          ...newRes,
          selectedMBId,
          selectedOrganizationsId: organizationId,
        })
      );
    })
    .catch(() => {
      dispatch(setSelectedOrganization({ selectedMBId, selectedOrganizationsId: organizationId }));
    });
};

export const findNodeAndParentById = (treeData, selected) => {
  let current: any = {};
  let parent: any = {};
  //going through all the open nodes in the tree
  walk({
    treeData,
    //finding the selected node and its parent
    callback: ({ path, node }) => {
      selected.id === node.id && (current = { path, node });
      selected.parentId === node.id && (parent = { path, node });
    },
    getNodeKey,
  });
  return { current, parent };
};

export const removeNode = (treeData, selected) => {
  const { current, parent } = findNodeAndParentById(treeData, selected);

  //change the parent node (discounting children length and collapse it if empty)
  let data = changeNodeAtPath({
    treeData,
    path: parent.path,
    newNode: {
      ...parent.node,
      childCount: parent.node.childCount - 1,
      expanded: parent.node.childCount - 1 !== 0,
    },
    getNodeKey,
  });
  //rmove the selected node
  data = removeNodeAtPath({
    treeData: data,
    path: current.path,
    getNodeKey,
  });

  return data;
};

//remove all prev selected organization data.
export const cleanOrganizationData = () => {
  dispatch(deleteOrganizatioData());
  dispatch(removePLCData());
  dispatch(removeAllAssetsData());
};

export const deleteOrganization = (
  treeData,
  setTreeData,
  selected,
  setSelected,
  organizationDetails,
  machinBuilderMap,
  userOrganizationId,
  history
) => {
  const newTreeData = removeNode(treeData, selected);
  setTreeData(newTreeData);
  onNodeClicked(
    { node: organizationDetails },
    organizationDetails,
    machinBuilderMap,
    userOrganizationId,
    history,
    setSelected
  );
};

export const updateTreeChanges = (
  treeData,
  setTreeData,
  selected,
  setSelected,
  selectedOrganizationDetails,
  organizationDetails
) => {
  if (
    selected &&
    selectedOrganizationDetails &&
    selectedOrganizationDetails.selectedOrganizationsId
  ) {
    const { parentCompany, name, address, selectedOrganizationsId, status } =
      selectedOrganizationDetails;
    //if parent is change
    if (
      parentCompany &&
      parentCompany.id !== selected.parentId &&
      selectedOrganizationsId !== organizationDetails.id
    ) {
      //remove node from tree
      const newTreeData = removeNode(treeData, selected);
      //find the new parent and update him
      const { current } = findNodeAndParentById(newTreeData, {
        id: parentCompany.id,
      });
      let data = newTreeData;
      if (current.path) {
        data = changeNodeAtPath({
          treeData: newTreeData,
          path: current.path,
          newNode: {
            ...current.node,
            expanded: true,
            childCount: current.node.childCount + 1,
            children:
              typeof current.node.children !== 'function'
                ? [
                    ...current.node.children,
                    {
                      ...selected,
                      parentId: parentCompany.id,
                      name,
                      country: address && address.country,
                    },
                  ]
                : current.node.children,
          },
          getNodeKey,
        });
        setSelected({ ...selected, parentId: parentCompany.id });
      }
      setTreeData(data);
    } else if (
      name !== selected.name ||
      status !== selected.status ||
      (address && address.country !== selected.country)
    ) {
      //if name or country or status changed, find current and update him.
      const { current } = findNodeAndParentById(treeData, selected);
      const newNode = {
        ...current.node,
        name,
        status,
        country: address && address.country,
      };
      const data = changeNodeAtPath({
        treeData,
        path: current.path,
        newNode,
        getNodeKey,
      });
      setTreeData(data);
      setSelected({ ...newNode });
    }
  }
};

const toggleExpandedState = (nodes, parentId) => {
  return nodes.map((currentNode) => {
    if (currentNode.id === parentId) {
      return {
        ...currentNode,
        expanded: !currentNode.expanded,
      };
    }
    if (currentNode.children) {
      return {
        ...currentNode,
        children: toggleExpandedState(currentNode.children, parentId),
      };
    }
    return currentNode;
  });
};

const addChildrenToNode = (nodes, parentId, newChildren) => {
  return nodes.map((currentNode) => {
    if (currentNode.id === parentId) {
      return {
        ...currentNode,
        children: [...(currentNode.children || []), ...newChildren],
      };
    }
    if (currentNode.children) {
      return {
        ...currentNode,
        children: addChildrenToNode(currentNode.children, parentId, newChildren),
      };
    }
    return currentNode;
  });
};

const fetchAndAddChildren = async (node, searchResultTreeData, onUpdatingSearchTree) => {
  const res = await httpService.api({
    type: 'getOrganizations',
    urlParams: { organizationId: node.id },
    disableBI: false,
  });

  if (res) {
    const updatedTreeData = addChildrenToNode(searchResultTreeData, node.id, res);
    onUpdatingSearchTree(updatedTreeData);
  }
};

export const expandLeafNodeIfPossible = async (
  node,
  searchResultTreeData,
  onUpdatingSearchTree
) => {
  if (node.children && node.children.length > 0) {
    const updatedTreeData = toggleExpandedState(searchResultTreeData, node.id);
    onUpdatingSearchTree(updatedTreeData);
    return;
  } else {
    node.expanded = !node.expanded;
    fetchAndAddChildren(node, searchResultTreeData, onUpdatingSearchTree);
  }
};

export const updateMBMap = ({ parentId, id, type }, machinBuilderMap) => {
  if (['MACHINE_BUILDER_CHANNEL', 'END_CUSTOMER'].includes(type)) {
    machinBuilderMap[id] = parentId;
  }
};

/**
 * update the selcted node.
 * fetch org details from server.
 * update redux with org details and selected MB id.
 */
export const onNodeClicked = (
  { node },
  organizationDetails,
  machinBuilderMap,
  userOrganizationId,
  history,
  setSelected
) => {
  let selectedMBId = null;
  node.parentId && updateMBMap(node, machinBuilderMap);

  if (organizationDetails.type === 'UNITRONICS_MAIN') {
    selectedMBId = getCurrentMB(machinBuilderMap, node.id);
  } else if (organizationDetails.type !== 'UNITRONICS_CHANNEL') {
    selectedMBId = +userOrganizationId;
  }

  if (history.location.pathname !== '/main/organizations/details') {
    history.push('/main/organizations/details');
  }
  cleanOrganizationData();
  setSelected({ id: node.id, ...node });
  getOrganizationDetailsById(node.id, selectedMBId);
};

export const deleteSelected = async (selected, treeData, setTreeData, setSelected) => {
  const confirm = await modalService.openConfirm(
    {
      text: 'organizations-page.delete-wraning',
    },
    { companyName: selected.name }
  );
  if (confirm) {
    const data = removeNode(treeData, selected);

    setTreeData(data);
    setSelected(undefined);
    dispatch(deleteOrganizatioData());
  }
};

export const onAddingOrg = async (
  rowData,
  selectedOrganizationDetails,
  setTreeData,
  treeData,
  selected
) => {
  const res = await modalService.openModal('newOrganizationModal', {
    organizationType: rowData.node.type,
    rootRelatedOrg: selectedOrganizationDetails
      ? selectedOrganizationDetails.rootRelatedOrg
      : rowData.node.rootRelatedOrg,
  });
  if (res && res.id) {
    const { current } = findNodeAndParentById(treeData, selected);
    const data = changeNodeAtPath({
      treeData,
      path: current.path,
      newNode: {
        ...current.node,
        childCount: current.node.childCount + 1,
        //adding the org to the tree only if node was opened and already get his children
        children:
          // when "node.children" === 'function' node children need to be fetch from server
          typeof current.node.children !== 'function'
            ? [...current.node.children, { ...res, children: getChildren }]
            : current.node.children,
      },
      getNodeKey,
    });
    setTreeData(data);
  }
};

export const setFirstNode = (
  organizationDetails,
  setTreeData,
  setSelected,
  machinBuilderMap,
  userOrganizationId,
  history
) => {
  if (organizationDetails && organizationDetails.id) {
    setTreeData([{ ...organizationDetails, children: getChildren }]);
    onNodeClicked(
      { node: organizationDetails },
      organizationDetails,
      machinBuilderMap,
      userOrganizationId,
      history,
      setSelected
    );
  }
};

export const getBackgroundColor = (node) => {
  const getGradient = (backgroundVar, archivedVar) =>
    `repeating-linear-gradient(
      123deg,
      ${getVar(backgroundVar)},
      ${getVar(backgroundVar)} 10px,
      ${getVar(archivedVar)} 10px,
      ${getVar(archivedVar)} 21px
    )`;

  switch (organizationTypeMap[node.type]) {
    case 1:
      return node.foundInSearch && node.foundInSearch === true
        ? 'var(--widgetsGraphsColorsPalette3)'
        : '#3b406e';
    case 2:
      return node.status === 'ARCHIVED'
        ? getGradient('#686c8f', '#8689a5')
        : node.foundInSearch && node.foundInSearch === true
        ? 'var(--widgetsGraphsColorsPalette3)'
        : '#686c8f';
    case 3:
      return node.status === 'ARCHIVED'
        ? getGradient(
            'systemMachineBuilderOrgBackgroundColor',
            'systemMachineBuilderOrgArchivedColor'
          )
        : node.foundInSearch && node.foundInSearch === true
        ? 'var(--widgetsGraphsColorsPalette3)'
        : 'var(--systemMachineBuilderOrgBackgroundColor)';

    case 4:
      return node.status === 'ARCHIVED'
        ? getGradient(
            'systemMachineBuilderChannelOrgBackgroundColor',
            'systemMachineBuilderChannelOrgArchivedColor'
          )
        : node.foundInSearch && node.foundInSearch === true
        ? 'var(--widgetsGraphsColorsPalette3)'
        : 'var(--systemMachineBuilderChannelOrgBackgroundColor)';
    case 5:
      return node.status === 'ARCHIVED'
        ? getGradient('systemCustomerOrgBackgroundColor', 'systemCustomerOrgArchivedColor')
        : node.foundInSearch && node.foundInSearch === true
        ? 'var(--widgetsGraphsColorsPalette3)'
        : 'var(--systemCustomerOrgBackgroundColor)';
  }
};

export const getNodeColor = (orgType) => {
  switch (organizationTypeMap[orgType]) {
    case 1:
    case 2:
      return '#ffffff';
    case 3:
      return 'var(--systemMachineBuilderOrgTextColor)';
    case 4:
      return 'var(--systemMachineBuilderChannelOrgTextColor)';
    case 5:
      return 'var(--systemCustomerOrgTextColor)';
  }
};

const buildTreeRecursive = (parentId, resultDict) => {
  if (!resultDict[parentId]) {
    return null;
  }

  return resultDict[parentId].map((org) => {
    const children = buildTreeRecursive(org.id, resultDict);
    return {
      ...org,
      expanded: org.expanded,
      ...(children && children.length > 0 && { children }),
    };
  });
};

const updateExpandedField = (parents, resultDict) => {
  parents.forEach((parent) => {
    if (parent.childCount > 0 && resultDict[parent.id].length === 0) {
      parent.expanded = false;
    }
  });
};

const flagParentsFoundInSearch = (parents, ids) => {
  const idsSet = new Set(ids);

  parents.forEach((parent) => {
    parent.foundInSearch = idsSet.has(parent.id);
  });
};

export const buildTree = async (searchText) => {
  if (searchText.length < MIN_AMOUNT_OF_CHARS) {
    return null;
  }
  const searchResults = await getSearchResults(searchText);
  const ids = searchResults['ids'];
  if (ids.length === 0) {
    return null;
  }

  const parents = searchResults['parents'];
  flagParentsFoundInSearch(parents, ids);
  const resultDict = await getResultDict(parents);
  updateExpandedField(parents, resultDict);

  const amountOfResultsFound = ids.length;
  const treeDataResult = buildTreeRecursive('root', resultDict);
  return {
    treeData: treeDataResult,
    amountOfResultsFound: amountOfResultsFound,
  };
};
