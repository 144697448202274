import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@components/Icon';
import styles from './GetCertificateBTN.scss';
import { httpService } from '@core/http/HttpService';
import { PLCData } from '@src/redux/redux.interface';

function GetCertificateBTN(props: PLCData) {
  const { asset, id, hasCertificate, model } = props;

  const getCertificat = () => {
    httpService
      .api({
        type: 'getPlcCertificate',
        urlParams: { plcId: id },
      })
      .then((res: any) => {
        const fileName = `device.${res.deviceSerial}.encoded`;
        if (navigator['msSaveBlob']) {
          // IE 10+
          const blob = new Blob([res.certificate], { type: '' });
          navigator['msSaveBlob'](blob, fileName);
        } else {
          const linkSource = `data:,${res.certificate}`;
          const downloadLink = document.createElement('a');

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
  };

  return (
    <>
      {hasCertificate && (
        <IconButton onClick={getCertificat} id={styles.certificatButton}>
          <Icon type="download" color={'var(--systemFont)'} />
        </IconButton>
      )}
    </>
  );
}
export default GetCertificateBTN;
