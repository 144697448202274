import { WidgetData } from '../../CreateWidgetPage.interface';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';

const stepOneAssetType = (setWidgetValue) => {
  setWidgetValue((prevState: WidgetData) => {
    const unResetKeys = getConfigValueFromWidgetSettings(prevState.type, 'unResetKeys');
    const defaultAssetProperties = getConfigValueFromWidgetSettings(
      prevState.type,
      'defaultAssetProperties'
    );

    return {
      ...prevState,
      variables: unResetKeys?.includes('variables') ? prevState.variables : [],
      localTags: unResetKeys?.includes('localTags') ? prevState.localTags : [],
      tags: unResetKeys?.includes('tags') ? prevState.tags : [],
      tagTypes: unResetKeys?.includes('tagTypes') ? prevState.tagTypes : [],
      assetProperties: defaultAssetProperties
        ? prevState.assetProperties.filter((prop) => defaultAssetProperties.includes(prop.id))
        : [],
      alarmInfos: unResetKeys?.includes('alarmInfos') ? prevState.alarmInfos : [],
      scope: null,
      groupBy: [],
      metrics: [],
      filters: [],
      calculations: [],
      customization: null,
      sorts: [],
      customizationMetrics: [],
    };
  });
};

const stepOneAssetProps = (setWidgetValue) => {
  setWidgetValue((prevState: WidgetData) => ({
    ...prevState,
    scope: null,
    groupBy: [],
    metrics: [],
    filters: [],
    calculations: [],
    customization: null,
    sorts: [],
    customizationMetrics: [],
  }));
};

const stepOneAssetTags = (setWidgetValue) => {
  setWidgetValue((prevState: WidgetData) => ({
    ...prevState,
    tagTypes: [],
    scope: null,
    groupBy: [],
    metrics: [],
    localTags: [],
    filters: [],
    calculations: [],
    customization: null,
    sorts: [],
    customizationMetrics: [],
  }));
};

const stepOneAssetTagTypes = (setWidgetValue) => {
  setWidgetValue((prevState: WidgetData) => ({
    ...prevState,
    tags: [],
    variables: [],
    localTags: [],
    scope: null,
    groupBy: [],
    metrics: [],
    filters: [],
    calculations: [],
    customization: null,
    sorts: [],
    customizationMetrics: [],
  }));
};

export const stepOneResetValuesMap = {
  stepOneAssetType,
  stepOneAssetProps,
  stepOneAssetTags,
  stepOneAssetTagTypes,
  //stepOneExternalValues,
};
