import React, { useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import { getVar } from '@core/canvas/widget.utils';

const useStyles = makeStyles((theme: any) => ({
  treeLegend: {
    paddingTop: '10px',
  },
  nodeLegend: {
    width: '38px',
    height: '24px',
    borderRadius: '30px',
  },
  nodeText: {
    fontSize: '12px',
    color: 'var(--systemFont)',
  },
  nodeWrapper: {
    display: 'flex',
    columnGap: '14px',
    marginBottom: 13,
    marginLeft: 20,
    alignItems: 'center',
  },
}));

function TreeLegend() {
  const classes = useStyles({});
  const nodeOrgTypes = useMemo(
    () => ['MACHINE_BUILDER', 'MACHINE_BUILDER_CHANNEL', 'END_CUSTOMER', 'ARCHIVED_NODE'],
    []
  );

  const getBackground = useCallback((nodeType) => {
    switch (nodeType) {
      case 'MACHINE_BUILDER':
        return getVar('systemMachineBuilderOrgBackgroundColor');
      case 'MACHINE_BUILDER_CHANNEL':
        return getVar('systemMachineBuilderChannelOrgBackgroundColor');
      case 'END_CUSTOMER':
        return getVar('systemCustomerOrgBackgroundColor');
      case 'ARCHIVED_NODE':
        return `repeating-linear-gradient(
          123deg,
          ${getVar('systemCustomerOrgBackgroundColor')},
          ${getVar('systemCustomerOrgBackgroundColor')} 10px,
          ${getVar('systemCustomerOrgArchivedColor')} 10px,
          ${getVar('systemCustomerOrgArchivedColor')} 21px
        )`;
    }
  }, []);

  return (
    <div className={classes.treeLegend}>
      {nodeOrgTypes.map((type) => (
        <div key={type} className={classes.nodeWrapper}>
          <div className={classes.nodeLegend} style={{ background: getBackground(type) }}></div>
          <I18n className={classes.nodeText}>{`enum.${type}`}</I18n>
        </div>
      ))}
    </div>
  );
}

export default TreeLegend;
