import generalRegex from '@core/regex';

export const handleChangeInput = (
  e,
  maxLength,
  setInputValue,
  formik,
  name,
  removeLeadingSpaces = false
) => {
  const fieldVal = removeLeadingSpaces
    ? e.target.value.replace(generalRegex.leadingSpacesRegex, '').replace(generalRegex.multipleSpacesRegex, ' ')
    : e.target.value;

  if (maxLength && fieldVal.length > maxLength) return;
  setInputValue(fieldVal);
  formik.setFieldValue(name, fieldVal);
};

export const handleBlurInput = (
  formik,
  onLostFocus,
  error,
  setInputValue,
  name,
  trimOnLostFocus = false
) => {
  formik.setFieldTouched(name, true);
  if (onLostFocus && !error) {
    onLostFocus(
      {
        ...formik.values,
        [name]: trimOnLostFocus ? formik.values[name].trim() : formik.values[name],
      },
      formik.setFieldValue,
      setInputValue,
      formik.setErrors
    );
  }
};

export const getStyles = (error, touched, labelInline, mode, styles) => {
  if (error && touched) {
    return labelInline ? styles[`formikInlineErrorInput${mode}`] : styles.formikErrorInput;
  }
  return labelInline ? styles[`formikInlineInput${mode}`] : styles.formikInput;
};
