import React, { useState, createContext, useContext, FC } from 'react';
import { ConditionBuilderContextStateProps } from './conditionalTriggerContext.interface';
import { Config, Fields } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import useFieldConfigurator from './ConditionBuilder/Config/useFieldConfigurator';

const InitialConfig = MaterialConfig;

const defaultState: ConditionBuilderContextStateProps = {
  fieldContext: {},
  updateFieldContext: () => {},
};

const ConditionBuilderContext = createContext<ConditionBuilderContextStateProps>(defaultState);

const ContextProvider = (props) => {
  const [fieldContext, setFieldsContext] = useState<Fields>(defaultState.fieldContext);
  const {
    children,
    setAreFieldsLoaded,
    selectedAssetTypes,
    setFieldCombined,
    areAssetTypesLoaded,
  } = props;
  const updateFieldContext = (newFields: Fields) => {
    setFieldsContext(() => newFields);
  };

  useFieldConfigurator({
    ...props,
    setAreFieldsLoaded,
    selectedAssetTypes,
    updateFieldContext: updateFieldContext,
    setFieldCombined: setFieldCombined,
    areAssetTypesLoaded: areAssetTypesLoaded,
  });

  return (
    <ConditionBuilderContext.Provider
      value={{
        fieldContext,
        updateFieldContext,
      }}>
      {children}
    </ConditionBuilderContext.Provider>
  );
};

export const useLocalContext = () => {
  return useContext(ConditionBuilderContext);
};

export { ConditionBuilderContext, ContextProvider };
