import I18n from '@components/I18n';
import Select from '@components/Select';
import { InputBase, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '22px 44px',
    '&.control': {
      height: '10px',
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
    marginBottom: '8px',
  },
  delimiterMark: {
    display: 'flex',
    '&.control': {
      height: '10px',
    },
  },
  select: {
    width: '200px',
    marginRight: '32px',
    '&.control': {
      height: '10px',
    },
  },
  textFieldContainer: {
    width: '200px',
  },
  textField: {
    padding: '0 10px',
    width: '187px',
    height: '34px',
    margin: '0px',
    borderRadius: '5px',
    border: 'solid 1px var(--lightGrey)',
    backgroundColor: 'var(--white1)',
    color: 'var(--systemFont)',
  },
  error: {
    border: 'solid 1px var(--formikErrorText)',
  },
}));

const selectCustomStyle = { control: { height: 36 }, container: { width: 187, marginRight: 8 } };

function CsvDelimiter(props) {
  const {
    regionalSettingsData,
    onChangeData,
    setDelimiter,
    delimiterStyle,
    selectContainerStyle,
    selectLabelStyle,
    selectStyle,
    textFieldContainerStyle,
    textFieldLabelStyle,
    textFieldStyle,
    delimiterCaption = 'white-labeling.delimiter-settings.delimiter-mark',
    insertMarkCaption = 'white-labeling.delimiter-settings.delimiter-mark.insert-mark',
  } = props;
  const classes = useStyles(props);

  const delimiterOptions = useMemo(
    () => [
      {
        value: ',',
        label: ',',
      },
      {
        value: ';',
        label: ';',
      },
      {
        value: 'Other',
        label: 'white-labeling.delimiter-settings.delimiter-mark.other',
      },
    ],
    []
  );

  const [delimiterMark, setDelimiterMark] = useState();
  const [customDelimiterMark, setCustomDelimiterMark] = useState(
    regionalSettingsData?.regionalSettings?.content?.delimiter
  );
  const [error, setError] = useState(false);
  const [isChangedFromUI, setIsChangedFromUI] = useState(false);

  useEffect(() => {
    if (!isChangedFromUI) {
      const delimiter = delimiterOptions.some(
        (op) => op.value === regionalSettingsData?.regionalSettings?.content?.delimiter
      )
        ? regionalSettingsData?.regionalSettings?.content?.delimiter
        : 'Other';

      setDelimiterMark(delimiter);
    } else setIsChangedFromUI(false);
  }, [regionalSettingsData?.regionalSettings?.content?.delimiter]);

  useEffect(() => {
    setError(delimiterMark === 'Other' && !customDelimiterMark);
  }, [customDelimiterMark, delimiterMark]);

  const onDelimiterOptionChanged = (option) => {
    if (setDelimiter) {
      setDelimiter(option.value);
    }
    if (option.value === 'Other') setCustomDelimiterMark(',');

    setDelimiterMark(option.value);
    setIsChangedFromUI(true);
    if (onChangeData) {
      if (option.value === 'Other') onChangeData('regionalSettings', 'delimiter', ',');
      else onChangeData('regionalSettings', 'delimiter', option.value);
    }
  };

  const onCustomDelimiterMarkChanged = (delimiter) => {
    if (setDelimiter) {
      setDelimiter(delimiter);
    }
    setIsChangedFromUI(true);
    setCustomDelimiterMark(delimiter);
    if (delimiter && delimiter.length) onChangeData('regionalSettings', 'delimiter', delimiter);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.delimiterMark} style={delimiterStyle && delimiterStyle}>
        <div className={classes.select} style={selectContainerStyle && selectContainerStyle}>
          <I18n className={classes.label} style={selectLabelStyle && selectLabelStyle}>
            {delimiterCaption}
          </I18n>
          <Select
            styles={{
              ...selectCustomStyle,
              control: {
                ...selectCustomStyle.control,
                height: selectStyle?.height && selectStyle.height,
                width: selectStyle?.minWidth && selectStyle.minWidth,
              },
            }}
            style={selectStyle && selectStyle}
            value={delimiterOptions.find((opt) => opt.value === delimiterMark)}
            options={delimiterOptions}
            onChange={onDelimiterOptionChanged}
            maxMenuHeight={180}
          />
        </div>
        {delimiterMark === 'Other' && (
          <div
            className={classes.textFieldContainer}
            style={textFieldContainerStyle && textFieldContainerStyle}>
            <I18n className={classes.label} style={textFieldLabelStyle && textFieldLabelStyle}>
              {insertMarkCaption}
            </I18n>
            <InputBase
              className={classNames(classes.textField, error && classes.error)}
              style={textFieldStyle && textFieldStyle}
              value={customDelimiterMark}
              inputProps={{ maxLength: 1, minLength: 1 }}
              onChange={(e) => {
                onCustomDelimiterMarkChanged(e.target.value);
              }}
              // onBlur={(e) => handleChange(+e.target.value, 'decimalDigits')}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CsvDelimiter;
