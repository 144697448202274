import React, { useEffect, useState } from 'react';
import { useSubject } from '@core/effects';
import styles from './DataSourceFilters.scss';
import I18n from '@components/I18n';
import { i18nService } from '@core/i18n/I18nService';
import MultiSelect from '@components/MultiSelect';
import DatePicker from '@components/DatePicker';
import GooglePlacesInput from '@components/GooglePlacesInput';
import { userFiltersService } from '@pages/DashboardEditorPage/UserFiltersService';
import moment from 'moment';
import { httpService } from '@core/http/HttpService';
import { ShowClearButton } from '@components/Select/Select';
import AssetFilterComponent from '@components/AssetFilterComponent/AssetFilterComponent';
import { useSelector } from '@src/redux/useSelector';

function DataSourceFilters(props) {
  const { templateData, setTemplateData, onSaveTemplate } = props;

  const {
    trigger,
    assetTypesFilter,
    assetFilter,
    organizationsFilter,
    dateFilter,
    dateFilterFrom,
    dateFilterTo,
    geoFilter,
  } = templateData;

  const findFullDetails = (templateDetails = [], findFrom = []) => {
    const details = templateDetails.map((id) => {
      return findFrom.find((organization) => organization.id === id);
    });
    return details;
  };
  const organizationOptions = useSubject(userFiltersService.organizationOptions$);
  const assetTypeOptions = useSubject(userFiltersService.assetTypeOptions$);
  const [selectedAsset, setSelectedAsset] = useState(assetFilter);
  const [selectedOrganization, setSelectedOrganization] = useState(
    organizationsFilter?.length > 0 ? findFullDetails(organizationsFilter, organizationOptions) : []
  );
  const [selectedAssetTypes, setSelectedAssetTypes] = useState(
    assetTypesFilter?.length > 0 ? findFullDetails(assetTypesFilter, assetTypeOptions) : []
  );
  const [selectedDateRange, setSelectedDateRange] = useState({
    dateFilter: dateFilter ? dateFilter : 'MONTH',
    dateFilterFrom: dateFilterFrom ? dateFilterFrom : null,
    dateFilterTo: dateFilterTo ? dateFilterTo : null,
  });
  const [needToSyncBEWithTemplateData, setNeedToSyncBEWithTemplateData] = useState(false); // IDs
  const languageId = useSelector((state) => state.config.languageId);

  useEffect(() => {
    if (selectedAsset?.length) {
      if (selectedAsset[0]?.id) {
        setTemplateData([selectedAsset[0].id], 'assetFilter');
      }
    } else if (selectedAsset?.id) {
      setTemplateData([selectedAsset.id], 'assetFilter');
    } else {
      setTemplateData([], 'assetFilter');
    }
  }, [selectedAsset]);

  useEffect(() => {
    userFiltersService.getOrganizationOptions();
    userFiltersService.getAssetTypeOptions();

    if (assetTypesFilter?.length > 0 && assetTypeOptions.length > 0) {
      setSelectedAssetTypes(findFullDetails(assetTypesFilter, assetTypeOptions));
    }
  }, []);

  useEffect(() => {
    setSelectedOrganization(
      organizationsFilter?.length > 0
        ? findFullDetails(organizationsFilter, organizationOptions)
        : []
    );
  }, [organizationOptions]);

  useEffect(() => {
    if (needToSyncBEWithTemplateData) {
      setNeedToSyncBEWithTemplateData(false);
      onSaveTemplate(templateData, false);
    }
  }, [needToSyncBEWithTemplateData]);

  return (
    <>
      <div className={styles.header}>
        <I18n>events-template.edit-template.filters</I18n>
      </div>
      <div className={styles.filterContainerGrid}>
        {/* ORGANIZATION FILTER */}
        <div className={styles.filterCellLeft}>
          <div className={styles.caption}>
            <I18n>events-template.edit-template.organization</I18n>
          </div>
          <div className={styles.multiSelect}>
            <MultiSelect
              id="organizationOptionsPopover"
              options={organizationOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={i18nService.translate('general.all')}
              onChange={(filters) => {
                setTemplateData(
                  filters.map((filter) => filter.id),
                  'organizationsFilter'
                );
                setSelectedOrganization(filters);
              }}
              values={selectedOrganization}
              closeMenuOnSelectAll
              optionType="checkbox"
            />
          </div>
        </div>
        {/* DATE FILTER */}
        <div className={styles.filterCell}>
          <div className={styles.caption}>
            <I18n>events-template.edit-template.data-range</I18n>
          </div>
          <div className={styles.multiSelect} key={languageId}>
            <DatePicker
              selectedChanged={({ dateFilter, dateFilterFrom, dateFilterTo }) => {
                setTemplateData(dateFilter, 'dateFilter');
                setTemplateData(dateFilterFrom, 'dateFilterFrom');
                setTemplateData(dateFilterTo, 'dateFilterTo');
                setSelectedDateRange((prev) => ({
                  ...prev,
                  dateFilter,
                  dateFilterFrom,
                  dateFilterTo,
                }));
              }}
              minDate={moment().subtract(7, 'year').format('DD/MM/YYYY')}
              data={selectedDateRange}
            />
          </div>
        </div>
        {/* ASSET TYPE FILTER */}
        <div className={styles.filterCellLeft}>
          <div className={styles.caption}>
            <I18n>events-template.edit-template.asset-type</I18n>
          </div>
          <div className={styles.multiSelect}>
            <MultiSelect
              id="assetTypeOptionsPopover"
              options={assetTypeOptions}
              getOptionLabel={(options) => options.name}
              getOptionValue={(option) => option.id}
              placeholder={i18nService.translate('general.all')}
              onChange={(filters) => {
                setTemplateData(
                  filters.map((filter) => filter.id),
                  'assetTypesFilter'
                );
                setSelectedAssetTypes(filters);
              }}
              values={selectedAssetTypes}
              closeMenuOnSelectAll
              optionType="checkbox"
            />
          </div>
        </div>
        {/* GEO FILTER */}
        <div className={styles.filterCell}>
          <div className={styles.caption}>
            <I18n>events-template.edit-template.geography</I18n>
          </div>
          <div className={styles.multiSelect}>
            <GooglePlacesInput
              styles={{ height: '28px' }}
              id="geographyOptionsPopover"
              defaultValue={geoFilter}
              placeholder={i18nService.translate('general.all')}
              onSelectedCountryChange={(filters) => setTemplateData(filters, 'geoFilter')}
            />
          </div>
        </div>
        {/* ASSET FILTER */}
        {trigger === 'SCHEDULER' && (
          <div className={styles.filterCellLeft}>
            <div className={styles.caption}>
              <I18n>events-template.edit-template.asset</I18n>
            </div>
            <AssetFilterComponent
              assetTypeSelected={selectedAssetTypes}
              assetSelected={selectedAsset}
              assetFilter={assetFilter}
              onChange={(option) => {
                setTemplateData(option ? [option.id] : [], 'assetFilter');
                setSelectedAsset(option ? [option] : []);
              }}
              setAssetSelected={setSelectedAsset}
              showClearButton={ShowClearButton.true}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default DataSourceFilters;
