import { numberFormatter } from '@core/utils';
// import { tagFormatMap } from '@core/mapsAndDefinitions';
import moment from 'moment';
import { buildDateTime } from '../charts.utils';
import { cssVarsService } from '@core/CssVarsService';
import { i18nService } from '@core/i18n/I18nService';
import { compact, concat, cloneDeep } from 'lodash';
import {
  DateTimeType,
  UpdateAssetButtonCustomization,
  UpdateAssetCustomization,
  UpdateAssetRequestSentTextCustomization,
  UpdateAssetSwitchCustomization,
  UpdateAssetTextualRemarkCustomization,
  WidgetUpdateAssetTag,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';

export const isIntegerType = (format: string) => {
  return !!(
    format === 'INT8' ||
    format === 'UINT8' ||
    format === 'INT16' ||
    format === 'UINT16' ||
    format === 'INT32' ||
    format === 'UINT32'
  );
};

export const getTagMinVal = (format, multiplier: number, calculationAction: string): number => {
  let min: number;
  switch (format) {
    case 'FLOAT32':
      min = -3.402823e38;
      break;
    case 'INT8':
      min = -128;
      break;
    case 'UINT8':
      min = 0;
      break;
    case 'INT16':
      min = -32768;
      break;
    case 'UINT16':
      min = 0;
      break;
    case 'INT32':
      min = -2147483648;
      break;
    case 'UINT32':
      min = 0;
      break;
    case 'STRING':
      return 0;
    default:
      return null;
  }

  if (calculationAction === 'MULTIPLY' && min) return min * multiplier;
  else if (calculationAction === 'DIVIDE' && min) return min / multiplier;

  return min;
};

export const getTagMaxVal = (format, multiplier: number, calculationAction: string): number => {
  let max: number;
  switch (format) {
    case 'FLOAT32':
      max = 3.402823e38;
      break;
    case 'INT8':
      max = 127;
      break;
    case 'UINT8':
      max = 255;
      break;
    case 'INT16':
      max = 32767;
      break;
    case 'UINT16':
      max = 65535;
      break;
    case 'INT32':
      max = 2147483647;
      break;
    case 'UINT32':
      max = 4294967295;
      break;
    case 'STRING':
      return 256;
    default:
      return null;
  }

  if (calculationAction === 'MULTIPLY' && max) return max * multiplier;
  else if (calculationAction === 'DIVIDE' && max) return max / multiplier;

  return max;
};

export const getTag = (valueId, valueType, data) =>
  data?.columns.find(
    (c) =>
      c.valueId.toString() === valueId.toString() && c.valueType.toString() === valueType.toString()
  );

export const validateFunction = (
  item,
  format,
  calculation,
  tempValue,
  extraData,
  thousandsSeparator
) => {
  if (typeof tempValue === 'string' && isNumericFormat(format))
    tempValue = tempValue?.replace(thousandsSeparator, '');

  let multiplier = 1;
  let calculationAction = 'NONE';

  if (
    calculation &&
    calculation.expression?.trim() &&
    calculation.action &&
    calculation.action !== 'NONE' &&
    calculation.actionValue != null &&
    calculation.actionValue &&
    !isNaN(Number(calculation.actionValue))
  ) {
    calculationAction = calculation.action;
    multiplier = calculation.actionValue;
  }

  const value =
    format === 'STRING'
      ? tempValue?.length
      : isIntegerType(format) && typeof tempValue === 'string'
      ? +tempValue?.replace(/[^\d.-]+/g, '')
      : +tempValue;

  const typeMin =
    multiplier > 0
      ? getTagMinVal(format, multiplier, calculationAction)
      : getTagMaxVal(format, multiplier, calculationAction);

  const typeMax =
    multiplier > 0
      ? getTagMaxVal(format, multiplier, calculationAction)
      : getTagMinVal(format, multiplier, calculationAction);

  let min = item.min !== null && !isNaN(Number(item.min)) ? Math.max(+item.min, typeMin) : typeMin;
  let max =
    item.max !== null && !isNaN(Number(item.max))
      ? Math.min(+item.max, typeMax)
      : extraData
      ? extraData.maxStrLength
      : typeMax;

  if (format === 'BOOLEAN') {
    return;
  }

  if ((tempValue != null && !tempValue && tempValue !== 0) || tempValue === '') {
    if (format !== 'STRING') {
      return 'validations.required';
    }
  }

  if (isIntegerType(format) && isNaN(value)) return 'validations.required';

  if (item.validate && (min > value || max < value)) {
    return {
      text: `widgets.update-asset.invalid-${format === 'STRING' ? 'string' : 'numeric-new'}-range`,
      args: { min: numberFormatter(min, 5), max: numberFormatter(max, 5) },
    };
  }

  const defaultMin = typeMin;
  const defaultMax = extraData ? extraData.maxStrLength : typeMax;
  if (defaultMin > value || defaultMax < value) {
    return {
      text: `widgets.update-asset.invalid-${format === 'STRING' ? 'string' : 'numeric-new'}-range`,
      args: { min: numberFormatter(defaultMin, 5), max: numberFormatter(defaultMax, 5) },
    };
  }

  // Leaving it here just in case. Since the decimal delimiter in integer is now removed automatically,
  // we don't need the code below. No error will be shown

  // if (
  //   format !== 'STRING' &&
  //   tagFormatMap[format] !== 'floatType' &&
  //   (calculationAction === 'NONE' ||
  //     (calculationAction === 'MULTIPLY' && Number.isInteger(multiplier))) &&
  //   tempValue.toString().includes('.')
  // ) {
  //   return 'widgets.update-asset.invalid-integer.newMessage';
  // }
};

export const connectedStatuses = [
  'CONNECTED_OK',
  'CONNECTED_IN_MAINTENANCE',
  'CONNECTED_WARNING',
  'CONNECTED_MINOR_ALARM',
  'CONNECTED_MAJOR_ALARM',
  'CONNECTED_CRITICAL_ALARM',
];

export const isNumericFormat = (format) => {
  switch (format) {
    case 'FLOAT32':
    case 'INT8':
    case 'UINT8':
    case 'INT16':
    case 'UINT16':
    case 'INT32':
    case 'UINT32':
      return true;
    default:
      return false;
  }
};

export const valueToDisplay = (val, tag, config, thousandsSeparator, decimalSeparator) => {
  switch (tag.format) {
    case 'FLOAT32':
    case 'INT8':
    case 'UINT8':
    case 'INT16':
    case 'UINT16':
    case 'INT32':
    case 'UINT32':
      if (typeof val === 'string' && tag.format === 'FLOAT32')
        return numberFormatter(
          val?.replace(thousandsSeparator, '').replace(decimalSeparator, '.'),
          10
        );
      else return numberFormatter(val?.replace(/[^\d.-]+/g, ''));
    case 'DATE':
    case 'DATETIME':
      return buildDateTime(val, config?.dateTimeFormat, 'momentFormat');
    case 'BOOLEAN':
      return val ? 'True' : 'False';
    default:
      return (val && val !== '') || val === 0 ? val : '""';
  }
};

export const valueToServer = (val, format, calculation, thousandsSeparator, decimalSeparator) => {
  let retVal: number;
  switch (format) {
    case 'FLOAT32':
    case 'INT8':
    case 'UINT8':
    case 'INT16':
    case 'UINT16':
    case 'INT32':
    case 'UINT32':
      if (typeof val === 'string' && format === 'FLOAT32')
        val = val?.replace(thousandsSeparator, '').replace(decimalSeparator, '.');
      else val = val?.replace(/[^\d.-]+/g, '');

      retVal = !isNaN(Number(val)) ? +val : val;

      if (
        calculation &&
        calculation.action &&
        calculation.action !== 'NONE' &&
        calculation.actionValue != null &&
        calculation.actionValue &&
        !isNaN(Number(calculation.actionValue))
      ) {
        if (calculation.action === 'MULTIPLY') retVal = retVal / calculation.actionValue;
        else if (calculation.action === 'DIVIDE') retVal = retVal * calculation.actionValue;
      }

      if (format !== 'FLOAT32') retVal = Math.round(retVal);

      return retVal;
    case 'DATE':
    case 'DATETIME':
      return moment(val).unix();
    default:
      return val;
  }
};

const dataTypeIconMap = {
  INT32: 'numberType',
  UINT32: 'numberType',
  FLOAT32: 'floatType',
  BOOLEAN: 'booleanType',
  STRING: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
  INT8: 'numberType',
  UINT8: 'numberType',
  INT16: 'numberType',
  UINT16: 'numberType',
  GEO: 'geoType',
};

const buildOptions = (option, type) => {
  const tagFormat = type === 'TAG' ? option.format : option.type;
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
  };
};

const setScopeAndMetrics = (tags, setWidgetData) => {
  let index = 0;
  setWidgetData((prevState) => ({
    ...prevState,
    scope: 'LAST_VALUE',
    metrics: tags.map((t) => ({
      order: index++,
      valueType: t.tagType,
      type: dataTypeIconMap[t.type],
      valueId: t.id,
      name: t.name,
      operation: null,
    })),
  }));
};

const updateWidgetCustomization = (values, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      ...values,
    },
  }));
};

export const getMergedTagsConfigurationChanges = (
  currentConfiguration: WidgetUpdateAssetTag[],
  tags
): WidgetUpdateAssetTag[] => {
  let newConfiguration = cloneDeep(currentConfiguration);
  // Remove tags that were de-selected
  newConfiguration = newConfiguration.filter((el) => {
    return tags.map((t) => t.id).indexOf(el.tagId) >= 0;
  });
  // Add tags that are newly selected
  const needToBeAddedTags = tags
    .filter((t) => {
      return newConfiguration.map((el) => el.tagId).indexOf(t.id) < 0;
    })
    .map((t) => ({
      tagId: t.id,
      tagType: t.tagType,
      format: t.type,
      name: t.name,
      displayName: t.name,
      order: 0,
      validate: false,
      min: null,
      max: null,
      decimalDigits: null,
      dateTimeFormat: getTagDateTimeFormat(t.type),
    }));

  newConfiguration = newConfiguration.concat(needToBeAddedTags);

  // update order
  let index = 0;
  for (const element of newConfiguration) {
    element.order = index++;
  }

  return newConfiguration;
};

const setCustomizationData = (customization, data, key, setWidgetData, section = null) => {
  const values = cloneDeep(customization);
  if (section) values[section][key] = data;
  else values[key] = data;

  updateWidgetCustomization(values, setWidgetData);
};

export const getDefaultCustomization = (widgetData) => {
  const { tags, tagTypes, metrics } = widgetData || {};
  const data = compact(
    concat(
      tags.map((a) => buildOptions(a, 'TAG')),
      tagTypes.map((a) => buildOptions(a, 'TAG_TYPE'))
    )
  );

  return getDefaultUpdateAssetCustomization(data, metrics);
};

export const setMetricsAndCustomization = (setWidgetData, widgetData) => {
  const { tags, tagTypes, metrics } = widgetData || {};
  const data = compact(
    concat(
      tags.map((a) => buildOptions(a, 'TAG')),
      tagTypes.map((a) => buildOptions(a, 'TAG_TYPE'))
    )
  );

  if (!widgetData.customization)
    updateWidgetCustomization(getDefaultUpdateAssetCustomization(data, metrics), setWidgetData);
  else
    setCustomizationData(
      widgetData.customization,
      getMergedTagsConfigurationChanges(widgetData.customization.tagsConfiguration, data),
      'tagsConfiguration',
      setWidgetData
    );

  setScopeAndMetrics(data, setWidgetData);
};

export const getDefaultUpdateAssetCustomization = (tags, metrics): UpdateAssetCustomization => ({
  doNotRefreshWhileEditing: false,
  tagsConfiguration: getTagsConfiguration(tags, metrics),
  textualRemark: getDefaultTextualRemarkCustomization(),
  requestSentText: getDefaultRequestSentTextCustomization(),
  switchCustomization: getDefaultSwitchCustomization(),
  updateButtonCustomization: getDefaultButtonCustomization(),
});

const getDefaultTextualRemarkCustomization = (): UpdateAssetTextualRemarkCustomization => ({
  text: null,
  fontSize: 12,
  color: cssVarsService.vars.widgetsFont,
  horizontalAlignment: 'LEFT',
  verticalAlignment: 'BOTTOM',
});

const getDefaultRequestSentTextCustomization = (): UpdateAssetRequestSentTextCustomization => ({
  text: i18nService.translate(
    'create-widget-page.create-widget.step-four.switch.request-sent-text.default'
  ),
  fontSize: 12,
  color: cssVarsService.vars.widgetsFont,
});

const getDefaultSwitchCustomization = (): UpdateAssetSwitchCustomization => ({
  onText: i18nService.translate(
    'create-widget-page.create-widget.step-four.switch.on-text.default'
  ),
  offText: null,
  color: cssVarsService.vars.systemButtonBackground,
});

const getDefaultButtonCustomization = (): UpdateAssetButtonCustomization => ({
  text: i18nService.translate('create-widget-page.create-widget.step-four.update-asset.update'),
  color: cssVarsService.vars.widgetsFont,
  backgroundColor: cssVarsService.vars.systemButtonBackground,
  disabledColor: '#A4A4A4',
  horizontalAlignment: 'CENTER',
});

const getTagsConfiguration = (tags, metrics): WidgetUpdateAssetTag[] => {
  const tagsConfiguration = tags
    .map((t, index) => {
      const matchingMatrics = metrics?.find((m) => m.valueId === t.id);

      return {
        tagId: t.id,
        tagType: t.tagType,
        format: t.type,
        name: t.name,
        displayName: t.name,
        order: matchingMatrics ? matchingMatrics.order : index,
        validate: false,
        min: null,
        max: null,
        decimalDigits: getTagDecimalDigits(t.type),
        dateTimeFormat: getTagDateTimeFormat(t.type),
      };
    })
    .sort((m1, m2) => m1.order - m2.order);

  return tagsConfiguration;
};

const getTagDecimalDigits = (tagType): number => {
  switch (tagType) {
    case 'FLOAT32':
      return 5;
    default:
      return null;
  }
};

const getTagDateTimeFormat = (tagType): DateTimeType => {
  switch (tagType) {
    case 'DATE':
      return 'DATE';
    case 'DATETIME':
      return 'TIME_FORMAT_ONE';
    default:
      return null;
  }
};
