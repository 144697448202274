import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from '@src/redux/useSelector';
import classNames from 'classnames';
import style from './DateField.scss';

function DateField(props: any) {
  const { field } = props;
  const value = field === 'connectedAt' ? props.asset?.connectedAt : props[field];
  const orgTimezone = useSelector((state) => state.config && state.config.timezone);
  const selectOrgTimezone = useSelector(
    (state) => state.organizations && state.organizations.timezone
  );

  const date = value
    ? moment(value)
        .tz(orgTimezone || selectOrgTimezone)
        .format('DD/MM/YYYY')
    : null;

  return (
    <div className={classNames(style.text, 'ellipsis-overflow')} title={date}>
      {date}
    </div>
  );
}

export default DateField;
