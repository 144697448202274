import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { i18nService } from '@core/i18n/I18nService';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { PlcSerialButtonProps } from './PlcSerialButton.interface';
import styles from './PlcSerialButton.scss';

const PlcSerialButton = (props: PlcSerialButtonProps) => {
  const {
    type,
    disconnectPlc,
    generatePlcSerial,
    validatePlc,
    associateToRouter,
    disabled,
    assetName,
  } = props;

  let button;

  switch (type) {
    case 'Validate':
      button = (
        <Button
          mode="outlined"
          disabled={disabled}
          styles={
            associateToRouter ? { position: 'absolute', bottom: 20, right: 20 } : { marginLeft: 8 }
          }
          onClick={validatePlc}>
          <I18n>plc-validation.validate</I18n>
        </Button>
      );
      break;
    case 'Generate':
      button = (
        <Button
          mode="outlined"
          disabled={disabled}
          styles={
            associateToRouter ? { position: 'absolute', bottom: 20, right: 20 } : { marginLeft: 8 }
          }
          onClick={generatePlcSerial}>
          <I18n>plc-validation.generage</I18n>
        </Button>
      );
      break;
    case 'Disconnect':
      button = (
        <>
          <Icon
            type="disconnectPlc"
            color="var(--systemFont)"
            className={styles.disconnectIcon}
            onClick={disconnectPlc}
            tooltipText={i18nService.translate('edit-asset-modal.disconnectTooltip', undefined, {
              assetName: assetName,
            })}
          />
          <CheckIcon id={styles.formikPlcCheckIcon} />
        </>
      );
      break;
    default:
      button = null;
      break;
  }
  return button;
};

export default PlcSerialButton;
