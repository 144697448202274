import { useState, useEffect } from 'react';
import { compact } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import {
  AlarmInfosResponse,
  AssetPropertiesResponse,
  AssetTagsResponse,
} from '@pages/CreateWidgetPage/DataStepComponent/StepOne/StepOne.interface';
import { useSelector } from '@src/redux/useSelector';
import { Fields } from 'react-awesome-query-builder';
import {
  assetPropertiesToFieldArray,
  arrayToFieldGroup,
  alarmInfosToFieldArray,
  tagsToFieldArray,
} from './useFieldConfigurator.utils';
import { AssetPropertiesData } from './useFieldConfigurator.interface';

function useFieldConfigurator(props) {
  const {
    templateData,
    setTemplateData,
    selectedAssetTypes,
    setAreFieldsLoaded,
    updateFieldContext,
    setFieldCombined,
    areAssetTypesLoaded,
  } = props;
  const { trigger, conditionAssetTypes } = templateData;
  const languageId = useSelector((state) => state.config.languageId);
  const [assetProperties, setAssetProperties] = useState({} as Fields);
  const [alarmInfos, setAlarmInfos] = useState({} as Fields);
  const [tagsData, setTagsData] = useState({} as Fields);
  const [variablesData, setVariablesData] = useState({} as Fields);
  const [assetPropertiesData, setAssetPropertiesData] = useState({
    assetIds: [],
    assetNames: [],
    organizations: [],
    assetTypes: [],
  } as AssetPropertiesData);
  const [areAssetPropertiesLoaded, setAreAssetPropertiesLoaded] = useState(false);
  const [areTagsOrAlarmsLoaded, setAreTagsOrAlarmsLoaded] = useState(false);
  const [areOrganizationLoaded, setAreOrganizationLoaded] = useState(false);
  const [areAssetIdsLoaded, setAreAssetIdsLoaded] = useState(false);
  const [areAssetNamesLoaded, setAreAssetNamesLoaded] = useState(false);
  const [areAssetTypesOptionsSet, setAreAssetTypesOptionsSet] = useState(false);

  const updateAssetPropertiesData = async (value, key) => {
    setAssetPropertiesData((prev) => {
      let newAssetPropertiesData = {
        ...prev,
        [key]: value,
      };

      return newAssetPropertiesData;
    });
  };

  useEffect(() => {
    let request = null;

    if (
      !areOrganizationLoaded ||
      !areAssetIdsLoaded ||
      !areAssetNamesLoaded ||
      !areAssetTypesOptionsSet
    )
      return;

    request = httpService.api<AssetPropertiesResponse>({
      type: 'getAssetProperties',
    });
    request.then((res) => {
      const assetPropertiesArray = assetPropertiesToFieldArray(
        trigger,
        res.assetProperties,
        languageId,
        assetPropertiesData
      );
      setAssetProperties(
        arrayToFieldGroup(
          assetPropertiesArray,
          i18nService.translate(`events-template.edit-template.asset-properties`),
          'assetProperties'
        )
      );
      setAreAssetPropertiesLoaded(true);
    });

    if (trigger === 'ALARM') {
      setTagsData({});
      request = httpService.api<AlarmInfosResponse>({
        type: 'getAlarmsInfos',
      });
      setAreTagsOrAlarmsLoaded(false);
      request.then(async (res) => {
        const alarmInfoArray = alarmInfosToFieldArray(
          res.alarmInfos,
          languageId,
          conditionAssetTypes[0]
        );
        setAlarmInfos(
          arrayToFieldGroup(
            await alarmInfoArray,
            i18nService.translate(`events-template.edit-template.alarm-info`),
            'alarmInfo'
          )
        );
        setAreTagsOrAlarmsLoaded(true);
      });
    } else if (trigger === 'TELEMETRY') {
      setAreTagsOrAlarmsLoaded(false);
      setAlarmInfos({});
      if (conditionAssetTypes?.length) {
        if (conditionAssetTypes.length === 1) {
          let variables = [];
          let tags = [];
          const promiseCollection = [];
          function getAssetTypeVariablesList() {
            return httpService.api({
              type: 'getAssetTypeVariablesList',
              urlParams: { id: conditionAssetTypes[0] },
            });
          }
          function getAssetTags() {
            return httpService.api<AssetTagsResponse>({
              type: 'getAssetTags',
              urlParams: { assetId: conditionAssetTypes[0] },
              query: { p: 1, ps: 1000 },
            });
          }
          [getAssetTypeVariablesList, getAssetTags].forEach((func) => {
            const apiResult = func();
            promiseCollection.push(apiResult);
          });
          Promise.all(promiseCollection).then((res) => {
            if (Array.isArray(res)) {
              variables = res[0];
              tags = res[1]?.results;
            }
            setTagsData(
              arrayToFieldGroup(
                tagsToFieldArray(tags, 'tagOrTagType'),
                i18nService.translate(`events-template.edit-template.asset-tags`),
                'assetTags'
              )
            );
            setVariablesData(
              arrayToFieldGroup(
                compact(tagsToFieldArray(variables, 'variable')),
                i18nService.translate(`events-template.edit-template.variables`),
                'systemProperties'
              )
            );
            setAreTagsOrAlarmsLoaded(true);
          });
        } else if (conditionAssetTypes.length > 1) {
          setAreTagsOrAlarmsLoaded(false);
          httpService
            .api<AssetTagsResponse>({
              type: 'getAssetTagTypes',
              query: {
                p: 1,
                ps: 1000,
                assetTypeIds: conditionAssetTypes.map((item) => item).join(','),
              },
            })
            .then((res) => {
              setTagsData(
                arrayToFieldGroup(
                  tagsToFieldArray(res.results, 'tagOrTagType'),
                  i18nService.translate(`events-template.edit-template.tag-types`),
                  'tagTypes'
                )
              );
              setVariablesData({});
              setAreTagsOrAlarmsLoaded(true);
            });
        }
      }
    }
  }, [
    conditionAssetTypes,
    assetPropertiesData,
    areOrganizationLoaded,
    areAssetIdsLoaded,
    areAssetNamesLoaded,
  ]);

  useEffect(() => {
    if (
      areAssetPropertiesLoaded &&
      areTagsOrAlarmsLoaded &&
      areOrganizationLoaded &&
      areAssetIdsLoaded &&
      areAssetNamesLoaded &&
      areAssetTypesOptionsSet
    )
      setAreFieldsLoaded(true);
  }, [
    areAssetPropertiesLoaded,
    areTagsOrAlarmsLoaded,
    areOrganizationLoaded,
    areAssetIdsLoaded,
    areAssetNamesLoaded,
  ]);

  useEffect(() => {
    let requestIds;
    let requestNames;
    let requestOrganizations;

    if (conditionAssetTypes) {
      requestIds = httpService.api<{ next: any; results: any; allCount: string; count: string }>({
        type: 'getFilterAutocomplete',
        query: {
          p: 1,
          ps: 1000,
          property: 'ASSET_ID',
          assetTypes: conditionAssetTypes.reduce(
            (prev, current) => (prev ? prev + ',' + current : current + ''),
            null
          ),
        },
      });
      requestIds.then((res) => {
        updateAssetPropertiesData(
          res.results.map((id) => +id),
          'assetIds'
        );
        setAreAssetIdsLoaded(true);
      });

      requestNames = httpService.api<{ next: any; results: any; allCount: string; count: string }>({
        type: 'getFilterAutocomplete',
        query: {
          p: 1,
          ps: 1000,
          property: 'ASSET_NAME',
          assetTypes: conditionAssetTypes.reduce(
            (prev, current) => (prev ? prev + ',' + current : current + ''),
            null
          ),
        },
      });
      requestNames.then((res) => {
        updateAssetPropertiesData(res.results, 'assetNames');
        setAreAssetNamesLoaded(true);
      });

      requestOrganizations = httpService.api<{
        next: any;
        results: any;
        allCount: string;
        count: string;
      }>({
        type: 'getFilterAutocomplete',
        query: {
          p: 1,
          ps: 1000,
          property: 'ORGANIZATION',
          assetTypes: conditionAssetTypes.reduce(
            (prev, current) => (prev ? prev + ',' + current : current + ''),
            null
          ),
        },
      });
      requestOrganizations.then((res) => {
        updateAssetPropertiesData(res.results, 'organizations');
        setAreOrganizationLoaded(true);
      });
    }
  }, [conditionAssetTypes, areAssetTypesOptionsSet]);

  useEffect(() => {
    if (areAssetTypesLoaded) {
      updateAssetPropertiesData(selectedAssetTypes, 'assetTypes');
      setAreAssetTypesOptionsSet(true);
    }
  }, [areAssetTypesLoaded, selectedAssetTypes]);

  useEffect(() => {
    {
      let fieldsCombined: Fields;
      if (trigger === 'ALARM') {
        fieldsCombined = {
          ...assetProperties,
          ...alarmInfos,
        };
      } else if (trigger === 'TELEMETRY') {
        fieldsCombined = {
          ...assetProperties,
          ...tagsData,
          ...variablesData,
        };
      }
      updateFieldContext(fieldsCombined);
      setFieldCombined(fieldsCombined);
      setTemplateData(fieldsCombined, 'fieldsCombined');
    }
  }, [assetProperties, alarmInfos, tagsData, variablesData, areAssetTypesOptionsSet]);
}

export default useFieldConfigurator;
