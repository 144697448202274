import { ModalMap } from './modals.interface';
import CreateAssetModal from '@modals/CreateAssetModal';
import EditAssetModal from '@modals/EditAssetModal';
import CreatePLCModal from '@modals/CreatePLCModal';
import EditAssetTypeModal from '@modals/EditAssetTypeModal';
import AssignTagTypeModal from '@modals/AssignTagTypeModal';
import CreateDashboardModal from '@modals/CreateDashboardModal';
import ModalWithSelect from '@modals/ModalWithSelect';
import Confirm from './Confirm';
import Alert from './Alert';
import ConfirmWithMultiButtons from './ConfirmWithMultiButtons';
import NewOrganizationModal from '@modals/NewOrganizationModal';
import NewUserModal from '@modals/NewUserModal';
import EditUserModal from '@modals/EditUserModal';
import NewLanguageModal from '@modals/NewLanguageModal/NewLanguageModal';
import EditLanguageModal from '@modals/EditLanguageModal/EditLanguageModal';
import UploadLanguageModal from '@modals/UploadLanguageModal';
import CreateRouterModal from '@modals/CreateRouterModal';
import EditRouterModal from '@modals/EditRouterModal';
import ConnectPLCToRouterModal from '@modals/ConnectPLCToRouterModal';
import DisconnectPLCFromRouterModal from '@modals/DisconnectPLCFromRouterModal';
import ChangeRouterOwnerModal from '@modals/ChangeRouterOwnerModal';
import ChangeAssetOwnerModal from '@modals/ChangeAssetOwnerModal';
import AssetTypesTagsInfoModal from '@modals/AssetTypesTagsInfoModal';
import AssetsLocationDetailsModal from '@modals/AssetsLocationDetailsModal';
import UpdateSubDomain from '@modals/UpdateSubDomain';
import RemoteVPNModal from '@modals/RemoteVPNModal';
import GalleryModal from '@modals/GalleryModal';
import ImageViewerModal from '@modals/ImageViewerModal';
import AddSubscriptionToAssetModal from '@modals/AddSubscriptionToAssetModal';
import DisclaimerModal from '@modals/DisclaimerModal';
import AssignSubscriptionToAssetModal from '@modals/AssignSubscriptionToAssetModal';
import AssignSubscriptionsToAssetsModal from '@modals/AssignSubscriptionsToAssetsModal';
import CreateEventTemplateDataSourceModal from '@modals/CreateEventTemplateDataSourceModal';
import TemplatesDetailsModal from '@modals/TemplatesDetailsModal';
import ChoseDateModal from './ChoseDateModal/intex';
import CustomDateModal from './CustomDateModal/index';
import PreviewEmailMessageModal from '@modals/PreviewEmailMessageModal';
import PreviewSmsMessageModal from '@modals/PreviewSmsMessageModal';
import AddUserGroupModal from '@modals/AddUserGroupModal';
import TestEventModal from '@modals/TestTemplateModal';
import EventDetailsModal from '@modals/EventDetailsModal';
import SmsVertificationModal from '@modals/SmsVertificationModal';
import MobileNumberValidationModal from '@modals/MobileNumberValidationModal';
import SchedulePickerModal from '@modals/SchedulePickerModal';
import EditGroupMembersModal from '@modals/EditGroupMembersModal';
import EditFormulaExpressionModal from '@modals/EditFormulaExpressionModal';
import EditPdfReportSettingsModal from '@modals/EditPdfReportSettingsModal/EditPdfReportSettingsModal';
import DashboardInUseModal from '@modals/DashboardInUseModal/DashboardInUseModal';
import ExportLanguageModal from '@modals/ExportLanguageModal/ExportLanguageModal';
import ReplaceTagsModal from '@modals/ReplaceTagsModal/ReplaceTagsModal';
import AddVariableModal from '@modals/AddVariableModal';
import EditVariableSettingsModal from '@modals/EditVariableSettingsModal';
import VariableInUseModal from '@modals/VariableInUseModal';
import FormikFormModal from '@modals/FormikFormModal';
import VariableValuesPerAssetModal from '@modals/VariableValuesPerAssetModal';
import RemoteHMIModal from '@modals/RemoteHMIModal';
import PreviewPushMessageModal from '@modals/PreviewPushMessageModal';
import NotificationDetailsModal from '@modals/NotificationDetailsModal';
import CreateARProjectModal from '@modals/CreateARProjectModal';
import TrainingARProjectModal from '@modals/TrainingARProjectModal';

export const modalMap: ModalMap = {
  alert: {
    component: Alert,
    props: { rounded: 'true' },
  },
  confirm: {
    component: Confirm,
    props: { rounded: 'true' },
  },
  ConfirmWithMultiButtons: {
    component: ConfirmWithMultiButtons,
    props: { rounded: 'true' },
  },
  createAsset: {
    component: CreateAssetModal,
  },
  editAsset: {
    component: EditAssetModal,
    props: { maxWidth: false },
  },
  editAssetType: {
    component: EditAssetTypeModal,
    props: { maxWidth: false },
  },
  assignTagType: {
    component: AssignTagTypeModal,
  },
  assetTypesTagsInfo: {
    component: AssetTypesTagsInfoModal,
    props: { maxWidth: false },
  },
  createPLC: {
    component: CreatePLCModal,
  },
  createRouter: {
    component: CreateRouterModal,
  },
  editRouter: {
    component: EditRouterModal,
    props: { maxWidth: false },
  },
  connectPLCToRouter: {
    component: ConnectPLCToRouterModal,
  },
  disconnectPLCFromRouter: {
    component: DisconnectPLCFromRouterModal,
  },
  changeAssetOwner: {
    component: ChangeAssetOwnerModal,
  },
  changeRouterOwner: {
    component: ChangeRouterOwnerModal,
  },
  newDashboard: {
    component: CreateDashboardModal,
    props: { maxWidth: false },
  },
  modalWithSelect: {
    component: ModalWithSelect,
  },
  newOrganizationModal: {
    component: NewOrganizationModal,
    props: { maxWidth: false },
  },
  newUserModal: {
    component: NewUserModal,
    props: { maxWidth: false },
  },
  editUserModal: {
    component: EditUserModal,
    props: { maxWidth: false },
  },
  newLanguageModal: {
    component: NewLanguageModal,
    props: { maxWidth: false },
  },
  editLanguageModal: {
    component: EditLanguageModal,
    props: { maxWidth: false },
  },
  exportLanguageModal: {
    component: ExportLanguageModal,
    props: { maxWidth: false },
  },
  uploadLanguageModal: {
    component: UploadLanguageModal,
    props: { maxWidth: false },
  },
  assetsLocationDetails: {
    component: AssetsLocationDetailsModal,
    props: { maxWidth: false },
  },
  updateSubDomain: {
    component: UpdateSubDomain,
    props: { maxWidth: false },
  },
  remoteVPN: {
    component: RemoteVPNModal,
    props: { rounded: 'true' },
  },
  imageGallery: {
    component: GalleryModal,
    props: { rounded: 'true', maxWidth: false },
  },
  imageViewer: {
    component: ImageViewerModal,
    props: { rounded: 'true' },
  },
  addSubscriptionToAssetModal: {
    component: AddSubscriptionToAssetModal,
    props: { maxWidth: false, rounded: 'true' },
  },
  disclaimer: {
    component: DisclaimerModal,
    props: { rounded: 'true' },
  },
  assignSubscriptionToAsset: {
    component: AssignSubscriptionToAssetModal,
    props: { maxWidth: false },
  },
  assignSubscriptionsToAssets: {
    component: AssignSubscriptionsToAssetsModal,
    props: { maxWidth: false },
  },
  createEventDataSourceModal: {
    component: CreateEventTemplateDataSourceModal,
    props: { maxWidth: false },
  },
  editPdfReportSettingsModal: {
    component: EditPdfReportSettingsModal,
    props: { maxWidth: false },
  },
  templatesDetailsModal: {
    component: TemplatesDetailsModal,
    props: { maxWidth: false },
  },
  choseDateModal: {
    component: ChoseDateModal,
    props: { rounded: 'true' },
  },
  customDateModal: {
    component: CustomDateModal,
    props: { rounded: 'true' },
  },
  previewEmailMessageModal: {
    component: PreviewEmailMessageModal,
    props: { maxWidth: false },
  },
  previewSmsMessageModal: {
    component: PreviewSmsMessageModal,
    props: { maxWidth: false },
  },
  previewPushMessageModal: {
    component: PreviewPushMessageModal,
    props: { maxWidth: false },
  },
  addUserGroupModal: {
    component: AddUserGroupModal,
    props: { maxWidth: false },
  },
  testEventModal: {
    component: TestEventModal,
    props: { maxWidth: false },
  },
  eventDetailsModal: {
    component: EventDetailsModal,
    props: { maxWidth: false },
  },
  smsVertificationModal: {
    component: SmsVertificationModal,
    props: { maxWidth: false },
  },
  mobileNumberValidationModal: {
    component: MobileNumberValidationModal,
    props: { maxWidth: false },
  },
  schedulePickerModal: {
    component: SchedulePickerModal,
    props: { maxWidth: false },
  },
  editGroupMembersModal: {
    component: EditGroupMembersModal,
    props: { maxWidth: false },
  },
  editFormulaExpressionModal: {
    component: EditFormulaExpressionModal,
    props: { maxWidth: false },
  },
  dashboardInUseModal: {
    component: DashboardInUseModal,
    props: { maxWidth: false },
  },
  replaceTagsModal: {
    component: ReplaceTagsModal,
    props: { maxWidth: false },
  },
  addVariableModal: {
    component: AddVariableModal,
    props: { maxWidth: false },
  },
  editVariableSettingsModal: {
    component: EditVariableSettingsModal,
    props: { maxWidth: false },
  },
  variableInUseModal: {
    component: VariableInUseModal,
    props: { maxWidth: false },
  },
  variableValuesPerAssetModal: {
    component: VariableValuesPerAssetModal,
    props: { maxWidth: false },
  },
  formikFormModal: {
    component: FormikFormModal,
    props: { maxWidth: false },
  },
  remoteHMI: {
    component: RemoteHMIModal,
    props: { rounded: 'true' },
  },
  notificationDetailsModal: {
    component: NotificationDetailsModal,
    props: { rounded: 'true' },
  },
  newARProject: {
    component: CreateARProjectModal,
    props: { maxWidth: false },
  },
  trainingARProject: {
    component: TrainingARProjectModal,
    props: { maxWidth: false },
  },
};
