import React, { useEffect } from 'react';
import { useSelector } from '@src/redux/useSelector';
import EventsTab from '@pages/EventsManagementPage/EventsTab/EventsTab';

function Events() {
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );

  useEffect(() => {}, [selectedOrganizationsId]);
  return <EventsTab organizationPage></EventsTab>;
}

export default Events;
