import React from 'react';
import classnames from 'classnames';
import styles from './Image.scss';
import I18n from '@components/I18n';

function Image(props) {
  const { src, onClick, mode, text, maxTextWidth = undefined } = props;
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div
        className={classnames(styles.img, styles[mode])}
        style={{ backgroundImage: `url('${src}')` }}
      />
      {text && (
        <div
          className={classnames(styles.text, 'ellipsis-overflow')}
          style={{ maxWidth: maxTextWidth }}>
          <I18n>{text}</I18n>
        </div>
      )}
    </div>
  );
}

export default Image;
