import React from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import AssetsTab from '@pages/DeviceManagment/Assets/AssetsTab';
import AssetTypesTab from '@pages/DeviceManagment/Assets/AssetTypesTab';
import I18n from '@components/I18n';
import styles from './AssetsManagementTab.scss';
import { useSelector } from '@redux/useSelector';

function AssetsManagementTab(props) {
  const roles = useSelector((state) => state.config.roles);
  const showAssetTypesTab =
    roles.includes('Machine Builder Admin') ||
    roles.includes('Machine Builder Device Manager') ||
    roles.includes('Unitronics Admin');

  return (
    <>
      <div className={styles.assetsManagementTabs}>
        <NavLink
          className={styles.tab}
          activeClassName={styles.activeTab}
          to={`${props.match.url}/assets`}>
          <I18n>assets-management-tab.assets</I18n>
        </NavLink>
        {showAssetTypesTab && (
          <NavLink
            className={styles.tab}
            activeClassName={styles.activeTab}
            to={`${props.match.url}/assettypes`}>
            <I18n>assets-management-tab.assets-types</I18n>
          </NavLink>
        )}
      </div>
      <div className={styles.routsSection}>
        <Switch>
          <Route exact path={`${props.match.url}/assets`} component={AssetsTab} />
          {showAssetTypesTab && (
            <Route exact path={`${props.match.url}/assettypes`} component={AssetTypesTab} />
          )}
          <Redirect to={`${props.match.url}/assets`} />
        </Switch>
      </div>
    </>
  );
}

export default AssetsManagementTab;
