import { IdGenerator } from '@core/IdGenerator';
import { isWidgetGroup } from '@core/canvas/widget.utils';
import * as RI from '../redux.interface.d';

const idGenerator = new IdGenerator();

class DEHandlers {
  setCanvasWidgets(
    state: RI.DashboardEditorState,
    widgets: Array<RI.CanvasWidget>,
    currentLayout: string
  ) {
    return {
      ...state,
      canvasWidgets: widgets.map((w) => {
        const state = w.states.find((state) => state.layoutStateType === currentLayout);
        return { ...w, x: state.x, y: state.y, w: state.w, h: state.h };
      }),
    };
  }

  addCanvasWidget(state: RI.DashboardEditorState, widget: Partial<RI.CanvasWidget>) {
    const { x, y, w, h, type } = widget;
    if (
      typeof x !== 'number' ||
      typeof y !== 'number' ||
      typeof w !== 'number' ||
      typeof h !== 'number' ||
      !type
    ) {
      throw new Error('[addCanvasWidget] Invalid params.');
    }

    const childrenIds = (widget.children || []).map((ch) => ch.id);
    const existingIds = this.getExistingIds(state).concat(childrenIds);

    const defaults = {
      id: idGenerator.getId(existingIds),
      isSelected: false,
      isIdTemp: true,
      data: null,
      children: [],
    };

    const widgetToAdd = Object.assign({}, defaults, widget) as RI.CanvasWidget;
    const canvasWidgets = state.canvasWidgets.concat(widgetToAdd);
    const numSelected = this.getNumSelected(canvasWidgets);

    return { ...state, canvasWidgets, numSelected };
  }

  editCanvasWidgetById(
    state: RI.DashboardEditorState,
    id: number,
    widget: RI.CanvasWidget,
    currentLayout: string
  ) {
    return {
      ...state,
      canvasWidgets: state.canvasWidgets?.map((cw) => {
        if (cw.id === id) {
          const newWidgetStates = cw.states?.map((state) => {
            if (state.layoutStateType === currentLayout) {
              return { ...widget, layoutStateType: state.layoutStateType };
            } else {
              return state;
            }
          });
          return { ...cw, ...widget, states: newWidgetStates, isIdTemp: false };
        }
        return cw;
      }),
    };
  }

  setCanvasWidgetStatesById(
    state: RI.DashboardEditorState,
    id: number,
    states: Array<RI.WidgetState>
  ) {
    return {
      ...state,
      canvasWidgets: state.canvasWidgets?.map((cw) => {
        if (cw.id === id) {
          return { ...cw, states: states };
        }
        return cw;
      }),
    };
  }

  removeCanvasWidget(state: RI.DashboardEditorState, widgetId: number | Array<number>) {
    const widgetIDs = typeof widgetId === 'number' ? [widgetId] : widgetId;
    const canvasWidgets = state.canvasWidgets.filter((cw) => !widgetIDs.includes(cw.id));

    return {
      ...state,
      canvasWidgets,
      numSelected: this.getNumSelected(canvasWidgets),
    };
  }

  selectWidget(state: RI.DashboardEditorState, id: number, isMultiSelect: boolean) {
    let wasChanged = false;
    const canvasWidgets = state.canvasWidgets.map((cw) => {
      if (!isMultiSelect && cw.id !== id && cw.isSelected) {
        wasChanged = true;
        return { ...cw, isSelected: false };
      } else if (cw.id === id && !cw.isSelected) {
        wasChanged = true;
        return { ...cw, isSelected: true };
      } else {
        return cw;
      }
    });
    if (!wasChanged) {
      return state;
    }
    return {
      ...state,
      canvasWidgets,
      numSelected: this.getNumSelected(canvasWidgets),
    };
  }

  unselectWidget(state: RI.DashboardEditorState, id: number) {
    let wasChanged = false;
    const canvasWidgets = state.canvasWidgets.map((cw) => {
      if (cw.id === id && cw.isSelected) {
        wasChanged = true;
        return { ...cw, isSelected: false };
      } else {
        return cw;
      }
    });
    if (!wasChanged) {
      return state;
    }
    return {
      ...state,
      canvasWidgets,
      numSelected: this.getNumSelected(canvasWidgets),
    };
  }

  clearWidgetSelection(state: RI.DashboardEditorState) {
    const canvasWidgets = state.canvasWidgets.map((cw) => {
      if (cw.isSelected) {
        return { ...cw, isSelected: false };
      }
      return cw;
    });
    const numSelected = this.getNumSelected(canvasWidgets);
    return numSelected === state.numSelected ? state : { ...state, canvasWidgets, numSelected };
  }
  setDateFilterValues(state: RI.DashboardEditorState, dateFilterValues: RI.DateFilterValues) {
    const dateObj = {
      dateFilter: dateFilterValues.dateFilter,
      dateFilterFrom: dateFilterValues.dateFilterFrom,
      dateFilterTo: dateFilterValues.dateFilterTo,
    };
    return Object.assign({}, state, dateObj);
  }

  private getNumSelected(widgets: Array<RI.CanvasWidget>) {
    return widgets.reduce((acc, curr) => acc + (curr.isSelected ? 1 : 0), 0);
  }

  private getExistingIds(state: RI.DashboardEditorState) {
    const existingIds = [];

    state.canvasWidgets.forEach((w) => {
      existingIds.push(...this.getWidgetIds(w));
    });

    return existingIds;
  }

  private getWidgetIds(widget: RI.CanvasWidget) {
    const ids = [];

    if (isWidgetGroup(widget)) {
      widget.children.forEach((child) => {
        ids.push(...this.getWidgetIds(child));
      });
    } else {
      ids.push(widget.id);
    }

    return ids;
  }
}

export const deHandlers = new DEHandlers();
