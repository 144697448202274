import React from 'react';
import MaterialCheckbox from '@components/Checkbox';

import WidgetNameInput from '../WidgetNameInput';
import I18n from '@components/I18n';
import styles from '../StepFour.scss';

const WidgetCustomizeInput = (props) => {
  const {
    /*  onSelectedHideFrame,
     onSelectedHideName, */
    setWidgetName,
    value,
    eventTemplateId,
    isNameValid,
    /*  hideWidgetName,
     hideWidgetFrame, 
    hideHideOptions, */
  } = props;

  return (
    <div className={styles.widgetName}>
      <div className={styles.titleWrapper}>
        <I18n>
          {!eventTemplateId
            ? 'create-widget-page.create-widget.step-four.widget-name'
            : 'create-event-data-source-page.create-event-data-source.step-four.widget-name'}
        </I18n>
      </div>
      <WidgetNameInput
        value={value}
        margin="dense"
        fullWidth
        variant="outlined"
        onChange={setWidgetName}
        InputProps={{
          style: {
            color: 'var(--systemFont)',
          },
        }}
      />
      <div className={styles.error}>
        {!isNameValid && eventTemplateId && (
          <I18n>events-template.edit-datasource.name-validation-error</I18n>
        )}
      </div>
      {/*   {!hideHideOptions && (
        <>
             <div className={styles.widgetFrame}>
            <MaterialCheckbox
              color="primary"
              onChange={onSelectedHideName}
              checked={hideWidgetName || false}
            />
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.hide-name
            </I18n>
          </div>
          <div className={styles.widgetFrame}>
            <MaterialCheckbox
              color="primary"
              onChange={onSelectedHideFrame}
              checked={hideWidgetFrame || false}
            />
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.hide-frame
            </I18n>
          </div> 
        </>
      )}*/}
    </div>
  );
};

export default WidgetCustomizeInput;
