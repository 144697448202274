import React, { useEffect, useState } from 'react';
import { connect } from 'formik';
import classNames from 'classnames';
import I18n from '@components/I18n';
import styles from './FormikLabel.scss';
import { FormikLabelProps } from './FormikLabel.interface';

function FormikLabel(props: FormikLabelProps | any) {
  const { name, label, value, labelInline = true, className, mode = '', ...restProps } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div
      className={classNames(
        labelInline ? styles.fieldInlineWrapper : styles.fieldWrapper,
        mode === 'NewMobile' && styles.wrapperMobileMode,
        className
      )}>
      {labelInline ? (
        <label
          className={classNames(
            styles.fieldLabelInline,
            mode === 'NewMobile' && styles.mobileMode,
            'ellipsis-overflow'
          )}
          style={{ ...restProps?.editLabelStyle, ...restProps?.paddingLeft }}>
          <I18n>{label}</I18n>:
        </label>
      ) : (
        <I18n element="div" className={styles.fieldLabel} style={restProps?.paddingLeft}>
          {label}
        </I18n>
      )}
      <div className={labelInline ? styles.fieldInput : undefined}>{value}</div>
    </div>
  );
}

export default connect(FormikLabel);
