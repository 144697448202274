import { WidgetType } from '@src/redux/redux.interface';
import { WidgetMap, WidgetMapItem } from './widgetMap.interface';
import { value } from './widgets/value';
import { columns } from './widgets/columns';
import { heatmap } from './widgets/heatmap';
import { table } from './widgets/table';
import { pie } from './widgets/pie';
import { map } from './widgets/map';
import { radialGauge } from './widgets/radialGauge';
import { line } from './widgets/line';
import { text } from './widgets/text';
import { image } from './widgets/image';
import { linearGauge } from './widgets/linearGauge';
import { alarms } from './widgets/alarms';
import { imageByValue } from './widgets/imageByValue';
import { vnc } from './widgets/vnc';
import { web } from './widgets/web';
import { vpn } from './widgets/vpn';
import { combo } from './widgets/combo';
import { progressBar } from './widgets/progressBar';
import { conditionalValue } from './widgets/conditionalValue';
import { switchWidget } from './widgets/switch';
import { updateAsset } from './widgets/updateAsset';

/*
  new widget configuration is here and in redux.intarface
*/

//list for widget gallery the order is important
export const widgetOptions: Array<WidgetType> = [
  'userFilters',
  'alarms',
  'image',
  'conditional_value',
  'image_by_value',
  'progress_bar',
  'text',
  'vnc',
  'web',
  'vpn',
  'combo',
  'value',
  'table',
  'heatmap',
  'columns',
  'map',
  'radial_gauge',
  'linear_gauge',
  'line',
  'pie',
  'switch',
  'update_asset',
];

export interface WidgetGroup {
  name: string;
  label: string;
  thumbnail: string;
  widgets: Array<WidgetMapItem>;
}

export function getWidgetGroupMap(): Array<WidgetGroup> {
  return [
    {
      name: 'valuesAndImagesGroup',
      label: 'edit-dashboard.widget-group.values-and-images',
      thumbnail: 'widgetGroupValuesAndImages',
      widgets: [value, text, image, imageByValue, conditionalValue, progressBar],
    },
    {
      name: 'tablesAndMapsGroup',
      label: 'edit-dashboard.widget-group.tables-and-maps',
      thumbnail: 'widgetGroupTablesAndMaps',
      widgets: [table, map, alarms],
    },
    {
      name: 'graphsAndGaugesGroup',
      label: 'edit-dashboard.widget-group.graphs-and-gauges',
      thumbnail: 'widgetGroupGraphsAndGauges',
      widgets: [heatmap, columns, line, pie, radialGauge, linearGauge],
    },
    {
      name: 'updateAssetGroup',
      label: 'edit-dashboard.widget-group.update-asset',
      thumbnail: 'widgetGroupUpdateAsset',
      widgets: [switchWidget, updateAsset],
    },
    {
      name: 'remoteAccessGroup',
      label: 'edit-dashboard.widget-group.remote-access',
      thumbnail: 'widgetGroupRemoteAccess',
      widgets: [web, vnc, vpn],
    },
    {
      name: 'managementGroup',
      label: 'edit-dashboard.widget-group.management',
      thumbnail: 'widgetGroupManagement',
      widgets: [combo],
    },
  ];
}
