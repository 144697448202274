import React, { useEffect } from 'react';
import PLCManagementPage from '@pages/DeviceManagment/PLC/PLCManagementTab';
import { useSelector } from '@src/redux/useSelector';

function PLC() {
  const selectedOrganizationsId = useSelector(state => state.organizations.selectedOrganizationsId);

  useEffect(() => {}, [selectedOrganizationsId]);
  return <PLCManagementPage></PLCManagementPage>;
}

export default PLC;
