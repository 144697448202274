import { TableColunm } from '@components/Table/table.interface';
import TextField from '@components/Table/TableFields/TextField';

export const dashboardUsageCfg: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'general.dashboard',
    field: 'dashboardName',
    style: { width: '25%' },
  },
  {
    Component: TextField,
    label: 'general.status',
    field: 'dashboardStatus',
    style: { width: '25%' },
  },
  {
    Component: TextField,
    label: 'general.widget-name',
    field: 'widgetName',
    style: { width: '25%' },
  },
  {
    Component: TextField,
    label: 'general.type',
    field: 'widgetType',
    style: { width: '25%' },
  },
];

export const eventTemplateUsageCfg: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'general.template',
    field: 'templateName',
    style: { width: '50%' },
  },
  {
    Component: TextField,
    label: 'general.details',
    field: 'details',
    style: { width: '50%' },
  },
];
