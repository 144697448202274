import * as Yup from 'yup';
import { i18nService } from '@core/i18n/I18nService';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { buildErrorObj, phoneRegex, emailRegex } from '@core/utils';
import { omit } from 'lodash';

const emailValidation = (validationCfg) => {
  const {
    values: { email, name },
    prevCompanyAndEmail,
    setPrevCompanyAndEmail,
    isCancelled,
  } = validationCfg;
  const { email: prevEmail, company: prevCompany } = prevCompanyAndEmail;

  if (!email?.length || !name?.length || (email === prevEmail && name === prevCompany)) return;

  httpService
    .api({
      type: 'emailCompanyValidation',
      data: { email, companyName: name, isEmailMessage: false },
      disableBI: true,
    })
    .then((res: any) => {
      if (isCancelled && isCancelled.current) return;

      if (res.isValid) {
        if (res.id) {
          onEmailExistsForAnotherCompanyName(res, validationCfg);
        } else {
          onEmailAndCompanyNameNotExist(validationCfg);
        }
      } else {
        onEmailExistsWithCompanyName(res, validationCfg);
      }
    });

  setPrevCompanyAndEmail({ email: email, company: name });
};

const onEmailExistsForAnotherCompanyName = (
  res,
  {
    values: { email, name },
    setFieldValue,
    isEmailMessage,
    setUserId,
    setEmailErrors,
    setIsValidEmail,
  }
) => {
  // The user can choose to be assaigned to company,
  // Otherwise he will need to choose a different email.
  modalService
    .openConfirm({
      text: `errors.${res.message.code}`, // I18n key: 'errors.9'
      ...buildErrorObj(res.message.keys),
    })
    .then((confirm) => {
      if (confirm) {
        setFieldValue('id', res['id']);
        setUserId(res['id']);
        setEmailErrors({ email, name, message: '', isEmailMessage, isValid: true });
        setIsValidEmail(true);
      } else {
        // reset email field and set error
        setFieldValue('email', '');
        setEmailErrors({ email, name, message: '', isEmailMessage, isValid: false });
        setIsValidEmail(true);
      }
    });
};

const onEmailAndCompanyNameNotExist = ({
  values: { name, email },
  setFieldValue,
  isEmailMessage,
  setUserId,
  setEmailErrors,
  setIsValidEmail,
}) => {
  setEmailErrors({ email, name, message: '', isEmailMessage, isValid: true });
  setIsValidEmail(true);
  setFieldValue('id', null);
  setUserId(null);
};

const onEmailExistsWithCompanyName = (
  res,
  {
    values: { email, name },
    setFieldValue,
    isEmailMessage,
    setUserId,
    setEmailErrors,
    setIsValidEmail,
  }
) => {
  setEmailErrors({
    email,
    name,
    message:
      res.message &&
      i18nService.translate(
        `errors.${res.message[0]?.code || res.message.code}`, // I18n key: 'errors.14'
        undefined,
        buildErrorObj(res.message[0]?.keys || res.message.keys)
      ),
    isEmailMessage,
    isValid: false,
  });
  setFieldValue('id', null);
  setUserId(null);
  setIsValidEmail(true);
};

export const getFieldsCfg = (
  rootRelatedOrg,
  orgType,
  organizationType,
  organizationsTypesLists,
  emailErrors,
  setUserId,
  setEmailErrors,
  setIsValidEmail,
  prevCompanyAndEmail,
  setPrevCompanyAndEmail,
  isUserAdmin,
  userOrgTypeNumber,
  isCancelled
) => {
  return [
    {
      label: 'details.company-name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .min(2, 'details.company-name-message')
        .max(100, 'details.company-name-message'),
      validate: (values) => {
        if (
          emailErrors &&
          !emailErrors.isEmailMessage &&
          values.name === emailErrors.name &&
          values.email === emailErrors.email
        ) {
          return emailErrors.message;
        }
        if (values.name === '') {
          if (prevCompanyAndEmail.company !== values.name) {
            setPrevCompanyAndEmail((prevState) => ({ ...prevState, company: values.name }));
          }
          setUserId(null);
          setEmailErrors(null);
          return 'validations.mandatory-field';
        }
      },
      onLostFocus: (values, setFieldValue) => {
        emailValidation({
          values,
          setFieldValue,
          isEmailMessage: false,
          setUserId,
          setEmailErrors,
          setIsValidEmail,
          prevCompanyAndEmail,
          setPrevCompanyAndEmail,
          isCancelled,
        });
      },
    },
    {
      label: 'details.company-type',
      name: 'type',
      type: 'select',
      options: organizationsTypesLists[organizationType].map((opt) => ({
        value: opt,
        label: i18nService.translate(`enum.${opt}`),
      })),
      isRequired: true,
      validation: Yup.string().required('validations.mandatory-field'),
    },
    {
      label: 'details.company-website',
      validation: Yup.string().nullable().max(100, 'details.companWebsiteMessage'),
      name: 'website',
      type: 'input',
    },
    {
      label: 'details.company-address',
      name: 'address',
      type: 'googlePlacesInput',
      isRequired: (values) =>
        ['MACHINE_BUILDER', 'MACHINE_BUILDER_CHANNEL', 'END_CUSTOMER'].includes(values.type.value),
      validate: (values) => {
        if (
          ['MACHINE_BUILDER', 'MACHINE_BUILDER_CHANNEL', 'END_CUSTOMER'].includes(
            values.type.value
          ) &&
          !values.address
        ) {
          return 'validations.mandatory-field';
        }
      },
    },
    {
      label: 'details.unitronic-org',
      name: 'rootRelatedOrg',
      type: 'checkbox',
      condition: (values) => {
        const selectedType = values.type?.value;
        return (
          selectedType &&
          (organizationTypeMap[selectedType] === 2 || organizationTypeMap[selectedType] === 3) &&
          userOrgTypeNumber === 1 &&
          isUserAdmin
        );
      },
    },
    {
      label: 'details.erp-instance-id',
      name: 'erpInstanceId',
      type: 'input',
      condition: (values) => {
        return values['rootRelatedOrg'];
      },
      validation: Yup.string()
        .min(1, 'details.erp-instance-id-message')
        .max(50, 'details.erp-instance-id-message'),
    },
    {
      label: 'details.erp-customer-id',
      name: 'erpCustomerId',
      type: 'input',
      condition: (values) => {
        return values['rootRelatedOrg'];
      },
      validation: Yup.string()
        .min(2, 'details.erp-customer-id-message')
        .max(50, 'details.erp-customer-id-message'),
    },
  ];
};

export const getFieldsCfgMainContact = (
  setIsValidEmail,
  emailErrors,
  setUserId,
  setEmailErrors,
  userId,
  prevCompanyAndEmail,
  setPrevCompanyAndEmail,
  isCancelled
) => {
  return [
    {
      label: 'details.email',
      name: 'email',
      type: 'input',
      isRequired: true,
      validate: (values) => {
        if (
          emailErrors &&
          emailErrors.isEmailMessage &&
          values.email === emailErrors.email &&
          values.name === emailErrors.name
        ) {
          return emailErrors.message;
        }
        if (values.email === '') {
          if (prevCompanyAndEmail.email !== values.email) {
            setPrevCompanyAndEmail((prevState) => ({ ...prevState, email: values.email }));
          }
          setUserId(null);
          setEmailErrors(null);
          return 'validations.mandatory-field';
        }
      },
      validation: Yup.string()
        .trim()
        .required('validations.mandatory-field')
        .matches(emailRegex, 'validations.incorrect-email'),
      onLostFocus: (values, setFieldValue) => {
        values.email = values.email?.trim();
        emailValidation({
          values,
          setFieldValue,
          isEmailMessage: true,
          setUserId,
          setEmailErrors,
          setIsValidEmail,
          prevCompanyAndEmail,
          setPrevCompanyAndEmail,
          isCancelled,
        });
      },
    },
    {
      label: 'details.title',
      name: 'title',
      type: 'select',
      options: ['NONE', 'MR', 'MRS', 'MISS', 'MS', 'DR', 'PROF'].map((opt) => ({
        value: opt,
        label: i18nService.translate(`enum.${opt}`),
      })),
      condition: (values) => !userId,
    },
    {
      label: 'details.first-name',
      name: 'firstName',
      type: 'input',
      isRequired: !userId,
      validation:
        !userId &&
        Yup.string()
          .nullable()
          .required('validations.mandatory-field')
          .max(20, 'details.name-message')
          .min(1, 'details.name-message'),
      condition: (values) => !userId,
    },
    {
      label: 'details.middle-name',
      name: 'middleName',
      type: 'input',
      validation:
        !userId &&
        Yup.string().nullable().max(20, 'details.name-message').min(1, 'details.name-message'),
      condition: (values) => !userId,
    },
    {
      label: 'details.last-name',
      name: 'lastName',
      type: 'input',
      isRequired: !userId,
      validation:
        !userId &&
        Yup.string()
          .nullable()
          .required('validations.mandatory-field')
          .max(100, 'details.name-message')
          .min(1, 'details.name-message'),
      condition: (values) => !userId,
    },
    {
      label: 'my-details.phone',
      name: 'phone',
      type: 'phoneInput',
    },
    {
      label: 'my-details.mobile',
      name: 'mobile',
      type: 'phoneInput',
      condition: (values) => !userId,
    },
  ];
};

export const getFieldCfgPreferences = (dashboardOptions, userOrganizationType) => {
  return [
    //need to check if default is work.
    {
      label: 'details.default-dashboard',
      name: 'dashboard',
      type: 'select',
      options: dashboardOptions || [],
      condition: ({ type }) => {
        return organizationTypeMap[userOrganizationType] === 3;
      },
    },
  ];
};

export const getDashboard = async (
  userOrganizationType,
  selectedMBId,
  setDashboardOptions,
  organizationDetails
) => {
  if (organizationTypeMap[userOrganizationType] === 3) {
    let dashboardOptions = [];
    const publishDashboards: any = await httpService.api({
      type: 'getAllPublishDashboard',
      urlParams: { p: 1, ps: 99999, organizationId: selectedMBId || undefined },
    });
    const defaultDashboard = publishDashboards.results.find(
      (m) => organizationDetails && m.refId === organizationDetails.dashboard
    );
    dashboardOptions = publishDashboards.results
      .filter((m) => organizationDetails && m.refId !== organizationDetails.dashboard)
      .map((t) => ({
        value: t.refId,
        label: t.name,
      }));

    dashboardOptions.sort((a, b) => a.label.localeCompare(b.label));

    defaultDashboard &&
      dashboardOptions.unshift({
        value: defaultDashboard.refId,
        label: defaultDashboard.name,
      });

    setDashboardOptions(dashboardOptions);
  }
};

export const onSubmit = (
  values,
  userIdRef,
  dismiss,
  setSaving,
  isValidEmailRef,
  emailValidationRef,
  setIsValidEmail
) => {
  setSaving(true);
  const myInterval = setInterval(() => {
    if (isValidEmailRef.current && emailValidationRef.current.isValid) {
      save(values, userIdRef, dismiss, setSaving, setIsValidEmail);
      clearInterval(myInterval);
    } else if (isValidEmailRef.current && !emailValidationRef.current.isValid) {
      clearInterval(myInterval);
      setIsValidEmail(false);
      setSaving(false);
    }
  }, 100);
};

const save = (values: any, userIdRef, dismiss, setSaving, setIsValidEmail) => {
  const userProperty = [
    'id',
    'title',
    'firstName',
    'middleName',
    'lastName',
    'email',
    'phone',
    'mobile',
  ];

  //if set default dashboard/type with initial value it will set: values.dashboard = id, when select value will be set as object
  ['dashboard', 'type'].forEach((key) => {
    values[key] = values[key] ? (values[key].value ? values[key].value : values[key]) : '';
  });
  values['rootRelatedOrg'] = values['rootRelatedOrg'] || false;
  values['dashboard'] = null;
  if (userIdRef.current) {
    userProperty.forEach((key) => (values[key] = ''));
    values['id'] = userIdRef.current;
  }
  values['user'] = userProperty.reduce(
    (o, key) => ({
      ...o,
      [key]:
        typeof values[key] === 'object' && values[key] != null ? values[key].value : values[key],
    }),
    {}
  );

  httpService
    .api({
      type: 'newOrganization',
      data: omit(
        {
          ...values,
          user: {
            ...values.user,
            middleName: values.user.middleName === '' ? null : values.user.middleName,
          },
        },
        userProperty
      ),
    })
    .then((res: any) => {
      dismiss(res);
    })
    .catch((err) => {
      err.data.message &&
        modalService.openModal('alert', {
          text: `errors.${err.data.code}`,
          ...buildErrorObj(err.data.keys),
        });
    });
  setSaving(false);
  setIsValidEmail(false);
};

export const validationSchema = (fieldsCfg) => {
  return Yup.object().shape(
    fieldsCfg.reduce(
      (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
      {}
    )
  );
};

export const validate = (values, fieldsCfg, fieldsCfgMainContact, fieldCfgPreferences) =>
  [...fieldsCfg, ...fieldsCfgMainContact, ...fieldCfgPreferences].reduce(
    (res, item: any) =>
      item.validate && item.validate(values) ? { ...res, [item.name]: item.validate(values) } : res,
    {}
  );
