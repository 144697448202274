import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { getState } from '@src/redux/store';
import { apiMap } from '@core/http/apiMap';
import { template } from 'lodash';

export const getUsers = (
  setUsersData,
  setTotalCount,
  setResultsCount,
  setNextPage,
  usersData,
  PAGE_SIZE,
  setDisableLoad,
  nextPage,
  searchText,
  firstInit = false,
  order?
) => {
  setDisableLoad(true);

  httpService
    .api({
      type: 'getOrganizationUsers',
      query: {
        p: firstInit ? 1 : nextPage,
        ps: PAGE_SIZE,
        search: searchText ? searchText : null,
        ordering: order ? `${order.orderSign}${order.label}` : null,
      },
    })
    .then((res: any) => {
      res.next && setDisableLoad(false);
      if (firstInit) {
        setUsersData(res.results);
        setTotalCount(res.allCount);
        setResultsCount(res.count);
        setNextPage(2);
      } else {
        const newData = usersData.concat(res.results);
        setUsersData(newData);
        setTotalCount(res.allCount.length);
        setResultsCount(res.count.length);
        setNextPage(nextPage + 1);
      }
    });
};

export const openNewUserModal = async (
  searchText,
  setClearSearch,
  search,
  setUsersData
): Promise<boolean> => {
  const res = await modalService.openModal('newUserModal');

  if (searchText) {
    setClearSearch(true);
    search('');
  }
  if (res) {
    res['roles'] = res['roles'].map((role) => role.name);
    setUsersData((prevState) => [res, ...prevState]);
    return false;
  } else return true;
};

export const getOrganizationId = () => {
  const organizationDetails = getState().config.organizationDetails;
  return organizationDetails && organizationDetails.id;
};
