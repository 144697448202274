import { orderBy } from 'lodash';
import { WidgetDataImageByValueCondition } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';

class ConditionsService {
  validate(
    conditions: WidgetDataImageByValueCondition[],
    updated: WidgetDataImageByValueCondition
  ) {
    if (
      typeof updated.fromValue === 'number' &&
      typeof updated.toValue === 'number' &&
      updated.fromValue > updated.toValue
    ) {
      return false;
    }
    if (conditions.length === 1) {
      return true;
    }
    const orderedConditions = this.orderBy(conditions);
    const updateIndex = orderedConditions.findIndex((condition) => condition.id === updated.id);
    let hasError = false;
    if (orderedConditions[updateIndex - 1]) {
      hasError = this.checkBefore(orderedConditions[updateIndex - 1], updated);
      if (hasError) {
        return false;
      }
    }
    if (orderedConditions[updateIndex + 1]) {
      hasError = this.checkAfter(updated, orderedConditions[updateIndex + 1]);
      if (hasError) {
        return false;
      }
    }

    return true;
  }

  //sort from lowest "fromValue" (includes null) to highest,
  //and inner sorting by "toValue" from the lowest to highest
  orderBy(conditions: WidgetDataImageByValueCondition[]) {
    return [...conditions].sort((a, b) =>
      a.fromValue === null
        ? -1
        : a.fromValue < b.fromValue
        ? -1
        : a.fromValue === b.fromValue
        ? a.toValue < b.toValue
          ? -1
          : 1
        : 1
    );
  }

  checkBefore(before: WidgetDataImageByValueCondition, updated: WidgetDataImageByValueCondition) {
    if (
      !before ||
      typeof before.toValue === 'undefined' ||
      typeof updated.fromValue === 'undefined'
    ) {
      return true;
    }
    if (before.toValue !== 0 && !before.toValue) {
      return true;
    }
    //before: {7, 10} updated: {8, 12}
    if (before.toValue > updated.fromValue) {
      return true;
    }

    //before: {9, 9} updated: {9, 9}
    if (
      before.toValue === updated.fromValue &&
      before.toValue === updated.toValue &&
      before.toValue === before.fromValue
    ) {
      return true;
    }

    return false;
  }

  checkAfter(updated: WidgetDataImageByValueCondition, after: WidgetDataImageByValueCondition) {
    if (
      !updated ||
      typeof updated.toValue === 'undefined' ||
      !after ||
      typeof after.fromValue === 'undefined'
    ) {
      return true;
    }

    if (updated.toValue > after.fromValue) {
      return true;
    }

    return false;
  }

  findValue(conditions: WidgetDataImageByValueCondition[], value: number) {
    const orderedConditions = orderBy(conditions, ['fromValue', 'toValue']).reverse();
    const conditionIndex = orderedConditions.findIndex(
      (condition) =>
        condition &&
        (typeof condition.toValue === 'number' || typeof condition.fromValue === 'number') &&
        ((typeof condition.toValue === 'number' &&
          typeof condition.fromValue === 'number' &&
          condition.toValue >= value &&
          condition.fromValue <= value) ||
          (typeof condition.toValue !== 'number' && condition.fromValue <= value) ||
          (typeof condition.fromValue !== 'number' && condition.toValue >= value))
    );

    if (conditionIndex === -1) {
      return null;
    }

    const afterCondition = orderedConditions[conditionIndex + 1];
    if (
      afterCondition &&
      typeof afterCondition.fromValue !== 'undefined' &&
      typeof afterCondition.toValue !== 'undefined' &&
      afterCondition.fromValue === afterCondition.toValue &&
      afterCondition.toValue === value
    ) {
      return afterCondition;
    }

    return orderedConditions[conditionIndex];
  }
}

export const conditionsService = new ConditionsService();
