import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  defaultLang: {
    marginRight: 15,
  },
  wrapper: {
    display: 'flex',
  },
  flag: {
    border: '1px solid var(--tableRowBorderBottom)',
    alignItems: 'center',
  },
}));

function LanguageFlag(props) {
  const classes = useStyles(props);
  const { flag } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.flag}>
        <img src={flag} style={{ width: 38, height: 25 }} />
      </div>
    </div>
  );
}

export default LanguageFlag;
