import { clearImageURLCache, setImageURL } from '@src/redux/imageCache';
import { dispatch, getState } from '@redux/store';
import moment from 'moment';
import { httpService } from '@core/http/HttpService';
import mutex from 'await-mutex';

const imageUrlRegex = /^https:\/\/.*&Expires=(\d+)$/;

export default class ImageCacheService {
  private _mutex;
  private _mutexCache;

  constructor() {
    this._mutex = new mutex();
    this._mutexCache = {};
  }

  async getImageURL(imageId: number) {
    // 1. Check if Store contains the image ID
    const release = await this._mutex.lock();
    let cachedMutex = this._mutexCache[`${imageId}`];
    let cachedMutexRelease;

    if (!cachedMutex) {
      cachedMutex = new mutex();

      this._mutexCache = {
        ...this._mutexCache,
        [`${imageId}`]: cachedMutex,
      };
    }

    release();
    cachedMutexRelease = await cachedMutex.lock();

    try {
      const imageCache = getState().imageCache;
      if (imageCache[`${imageId}`]) {
        const imageInfo = imageCache[`${imageId}`];
        // 2. If Yes, check if expired
        const currentTime = moment().unix();
        // 3. If not expired, return URL
        if (imageInfo.expires && currentTime < imageInfo.expires) {
          return imageInfo;
        }
      }

      // 4. Else call getImageData, cache the new URL and return in
      const res: any = await httpService.api({
        type: 'getImageData',
        urlParams: { identifier: imageId },
      });
      if (res) {
        const url = res.url;
        const match = url.match(imageUrlRegex);
        if (match) {
          const expires = +match[1];
          dispatch(setImageURL(imageId, res.url, expires));
        }

        return res;
      }
    } finally {
      cachedMutexRelease();
    }
  }

  clearCache() {
    this._mutexCache = {};
    dispatch(clearImageURLCache());
  }
}

export const imageCacheService = new ImageCacheService();
