import { WidgetData } from '../../CreateWidgetPage.interface';

export const stepTwoResetValues = (setWidgetValue) => {
  setWidgetValue((prevState: WidgetData) => ({
    ...prevState,
    groupBy: [],
    metrics: [],
    filters: [],
    sorts: [],
    calculations: [],
    customization: null,
    navigationDashboard: [],
    navigateFilterBy: [],
    customizationMetrics: [],
  }));
};

export const stepTwoResetGroupingAndMetrics = (setWidgetValue) => {
  setWidgetValue((prevState: WidgetData) => ({
    ...prevState,
    groupBy: [],
    metrics: [],
    calculations: [],
    customizationMetrics: [],
    customization: null,
  }));
};
