import ImageWidget from '@components/widgets/ImageWidget';
import { WidgetMapItem } from '../widgetMap.interface';

export const image: WidgetMapItem = {
  id: 'image',
  name: 'image',
  component: ImageWidget,
  minWidth: 120,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_Image.svg',
    thumbnail: 'image',
  },
  editModalSize: {},
  settings: {
    disableCreateWidgetFlow: true,
    enableSaveAsDraft: true,
    // hideHideOptions: true,
    hidePreviewTable: true,
    useSteps: [4, 5],
    languageKeys: (isArWidget) => {
      return [];
    },
    customizationServerChanges: (customization) => {
      // delete customization.hideWidgetFrame;
      // delete customization.hideWidgetName;
      delete customization.imageUrl;
      return customization;
    },
    allowAssetTypeNavigation: (widgetData) => false,
    hideFilterNavigation: (widgetData) => true,
    allowAssetTypeNavigationWithoutAssetProperties: true,
  },
};
