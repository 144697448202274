const usersRolesMap = {
  'Unitronics Admin': 1,
  'Unitronics Channel Admin': 2,
  'Machine Builder Admin': 3,
  'MB Channel Admin': 4,
  'Customer Admin': 5,
  'Machine Builder Dashboard Editor': 6,
  'Machine Builder Dashboard User': 7,
  'Machine Builder Channel Dashboard User': 8,
  'Customer Dashboard User': 9,
  'Machine Builder Device Manager': 10,
  'Machine Builder Channel Device Manager': 11,
  'Machine Builder Device Viewer': 12,
  'Machine Builder Channel Device Viewer': 13,
  'Machine Builder License Manager': 14,
  'Unitronics Channel Organization Manager': 15,
  'Machine Builder Organization Manager': 16,
  'Machine Builder Channel Organization Manager': 17,
  'Unitronics Channel Organization Viewer': 18,
  'Machine Builder Organization Viewer': 19,
  'Machine Builder Channel Organization Viewer': 20,
  'Machine Builder Remote Access': 21,
  'Customer User Manager': 22,
  'Customer User Viewer': 23,
  'Machine Builder Dashboard remote access': 24,
  'Machine Builder Channel Dashboard remote access': 25,
  'Customer Dashboard remote access': 26,
  'Machine Builder Update Asset': 27,
  'Machine Builder Channel Update Asset': 28,
  'Customer Update Asset': 29,
  'Machine Builder Events Manager': 30,
  'Machine Builder Events Viewer': 31,
  'Machine Builder Channel Events Manager': 32,
  'Machine Builder Channel Events Viewer': 33,
  'Customer Events Manager': 34,
  'Customer Events Viewer': 35,
  'Machine Builder AR Manager': 36,
  'Machine Builder AR User': 37,
  'Machine Builder Channel AR User': 38,
  'Customer AR User': 39,
};

export const addMoreRolesMap = { 24: 7, 25: 8, 26: 9 };

export default usersRolesMap;
