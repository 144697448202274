import React from 'react';
import { components } from 'react-select';

const IconsOptions = (props) => {
  return (
    <components.Option {...props}>
      <img src={props.data.extraName || props.label} style={{ width: 30, height: 20 }} />
      {props.data.extraName && <span style={{ marginLeft: 15 }}>{props.label}</span>}
    </components.Option>
  );
};

export default IconsOptions;
