import { compact, concat, omit, cloneDeep } from 'lodash';
import {
  convertCalculationToObject,
  getIsFloat,
  getMetricName,
  getTag,
} from '@pages/CreateWidgetPage/widgets.utils';
import { i18nService } from '@core/i18n/I18nService';
import { tagFormatMap } from '@core/mapsAndDefinitions';

export const changeWidgetData = (value, property: string, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [property]: value,
    },
  }));
};

export function getTagSettingsCfg(widgetData, dateTimeTag) {
  const calculationsObject = convertCalculationToObject(widgetData.calculations);

  return compact([
    {
      type: 'dragIndicator',
      style: { minWidth: '5%', width: '5%', marginRight: '2%' },
    },
    {
      type: 'label',
      field: 'name',
      style: {
        minWidth: dateTimeTag ? '16%' : '19%',
        width: dateTimeTag ? '16%' : '19%',
        marginRight: '1%',
      },
      headerLabel: 'create-widget-page.create-widget.step-four.tag-name',
    },
    {
      type: 'textInput',
      field: 'displayName',
      style: {
        minWidth: dateTimeTag ? '16%' : '19%',
        width: dateTimeTag ? '16%' : '19%',
        marginRight: '1%',
      },
      headerLabel: 'create-widget-page.create-widget.step-four.display-name',
      maxLength: 20,
    },
    {
      type: 'textInput',
      field: 'symbol',
      style: {
        minWidth: dateTimeTag ? '13%' : '17%',
        width: dateTimeTag ? '14%' : '17%',
        marginRight: '1%',
      },
      headerLabel: 'create-widget-page.create-widget.step-four.symbol',
      maxLength: 10,
    },
    {
      type: 'checkbox',
      field: 'showSymbolLeft',
      style: {
        minWidth: dateTimeTag ? '11%' : '17%',
        width: dateTimeTag ? '11%' : '17%',
        marginRight: '1%',
      },
      headerLabel: 'create-widget-page.create-widget.step-four.show-symbol-to-left',
    },
    {
      type: 'numericInput',
      field: 'decimalDigits',
      style: {
        minWidth: dateTimeTag ? '11%' : '16%',
        width: dateTimeTag ? '11%' : '16%',
        marginRight: '1%',
      },
      headerLabel: 'create-widget-page.create-widget.step-four.decimal-digits',
      getIsDisabled: (data) => {
        const calculation =
          calculationsObject[data?.valueType || data?.tagType] &&
          calculationsObject[data.valueType || data.tagType][data.valueId || data.id];

        return !getIsFloat(
          null,
          data.valueType,
          data.type,
          calculation?.null || calculation,
          widgetData
        );
      },
    },
    dateTimeTag && {
      type: 'dateTimeOptionsSelect',
      field: 'dateTimeFormat',
      style: { minWidth: '19%', width: '19%', marginRight: '1%' },
      headerLabel: 'create-widget-page.create-widget.step-four.date-time',
      getIsVisible: (data) => {
        return data?.dateTimeFormat || ['DATETIME', 'DATE', 'TIME'].includes(data?.tagType);
      },
    },
  ]);
}

export const remoteAccessButtonsCfg = compact([
  {
    type: 'dragIndicator',
    style: { minWidth: '5%', width: '5%', marginRight: '2%' },
  },
  {
    type: 'label',
    field: 'label',
    style: { minWidth: '30%', width: '30%' },
    headerLabel: 'general.button',
  },
  {
    type: 'textInput',
    field: 'displayName',
    style: { minWidth: '30%', width: '30%' },
    headerLabel: 'general.title',
    maxLength: 10,
  },
  {
    type: 'checkbox',
    field: 'show',
    style: { minWidth: '30%', width: '30%', marginLeft: '1%' },
    headerLabel: 'general.show',
  },
]);

function getDefaultMetric(metric, name, idx = 0) {
  return {
    id: metric.valueId,
    valueType: metric.valueType,
    operation: null,
    name,
    displayName: name.length > 20 ? name.slice(0, 20) : name,
    symbol: null,
    showSymbolLeft: false,
  };
}

function getDefaultRemoteAccessButtons() {
  return {
    vpn: { order: 0, show: true, displayName: remoteAccessButtonsLabels.vpn },
    web: { order: 0, show: true, displayName: remoteAccessButtonsLabels.web },
    vnc: { order: 0, show: true, displayName: remoteAccessButtonsLabels.vnc },
  };
}

export function formatRemoteAccessButtons(remoteAccessButtons: any[]) {
  return {
    vpn: omit(
      {
        ...remoteAccessButtons.find((col) => col['button'] === 'vpn'),
      },
      ['button', 'label']
    ),
    web: omit(
      {
        ...remoteAccessButtons.find((col) => col['button'] === 'web'),
      },
      ['button', 'label']
    ),
    vnc: omit(
      {
        ...remoteAccessButtons.find((col) => col['button'] === 'vnc'),
      },
      ['button', 'label']
    ),
  };
}

const getDataOnLastValueDataScope = (
  data,
  metric,
  idx,
  calculationsObject,
  widgetData,
  defaultDecimalDigits
) => {
  const metricName = getMetricName(widgetData, metric);

  const calculation =
    calculationsObject[metric?.valueType || metric?.tagType] &&
    calculationsObject[metric.valueType || metric.tagType][metric.valueId || metric.id];

  const isFloat = getIsFloat(
    null,
    metric.valueType,
    metric.type,
    calculation?.null || calculation,
    widgetData
  );

  return {
    showDecimalPoint: data.showDecimalPoint || isFloat,
    columns: [...data.columns, getDefaultMetric(metric, metricName, idx)],
    remoteAccessButtons: getDefaultRemoteAccessButtons(),
    counter: data.counter + 1,
  };
};

export const setColumnsDefaultData = (
  customization,
  widgetData,
  previewData,
  setWidgetData,
  defaultDecimalDigits
) => {
  let defaultData;
  const calculationsObject = convertCalculationToObject(widgetData.calculations);

  defaultData = widgetData.metrics.reduce(
    (data: any, metric: any, idx) => {
      {
        return getDataOnLastValueDataScope(
          data,
          metric,
          idx,
          calculationsObject,
          widgetData,
          defaultDecimalDigits
        );
      }
    },
    { columns: [], remoteAccessButtons: {}, counter: 0, showDecimalPoint: false }
  );

  let { columns, remoteAccessButtons } = defaultData;

  if (customization?.columns?.length) {
    columns = columns.map((originalItem) => {
      const existingItem = customization.columns.find(
        (item) => originalItem.id === item.id && originalItem.operation === item.operation
      );
      return { ...existingItem, name: originalItem?.name } || originalItem;
    });
  }

  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      columns: columns.sort((item1, item2) => item1.order - item2.order),
      remoteAccessButtons,
    },
  }));
};

export const changeWidgetTable = (columnUpdated, index, tableKey, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [tableKey]: prevState.customization[tableKey].map((col, idx) =>
        idx === index ? columnUpdated : col
      ),
    },
  }));
};

export const remoteAccessButtonsLabels = {
  vpn: 'edit-dashboard.widget-name.vpn',
  web: 'edit-dashboard.widget-name.web',
  vnc: 'edit-dashboard.widget-name.vnc',
  hmi: 'create-widget-page.create-widget.step-four.remote-hmi-button-title',
};

export const onMetricRowChanged = (value, editedRow, name, setData) => {
  setData((prevState) => {
    return prevState.map((row) => {
      if (row.id === editedRow.id && row.valueType === editedRow.valueType) {
        return {
          ...row,
          [name]: name === 'decimalDigits' ? (value < 0 || value > 10 ? row[name] : value) : value,
        };
      }
      return row;
    });
  });
};

export const onRemoteAccessButtonRowChanged = (value, editedRow, name, setData) => {
  setData((prevState) => {
    return prevState.map((row) => {
      if (row.button === editedRow.button) {
        return { ...row, [name]: value };
      }
      return row;
    });
  });
};

export function onTagSettingsRowChanged(setWidgetData, tableColumns) {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      columns: [...tableColumns],
    },
  }));
}

export const getComboWidgetCustomizationData = (
  widgetData,
  previewData,
  setWidgetData,
  defaultDecimalDigits,
  notSet = false
) => {
  const { metrics, groupBy, scope } = widgetData as any;
  let _columns;
  const dataArray = concat(groupBy, metrics);

  const data = dataArray.reduce(
    (data: any, dataItem: any) => {
      return getData(previewData, data, dataItem, widgetData, defaultDecimalDigits);
    },
    { columns: [], counter: 0 }
  );

  _columns = data.columns;
  if (!notSet) {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        columns: _columns,
      },
    }));
  }
  return _columns;
};

const getData = (previewData, data, dataItem, widgetData, defaultDecimalDigits = 0) => {
  const valueId =
    (previewData &&
      Array.isArray(previewData?.columns) &&
      previewData?.columns[data?.counter] &&
      previewData?.columns[data?.counter].id) ||
    previewData?.columns[data?.counter].valueId ||
    '';

  const type =
    (previewData &&
      Array.isArray(previewData?.columns) &&
      previewData?.columns[data?.counter] &&
      previewData?.columns[data?.counter].format) ||
    '';

  const valueType =
    (previewData &&
      Array.isArray(previewData?.columns) &&
      previewData?.columns[data?.counter] &&
      previewData?.columns[data?.counter].valueType) ||
    null;

  const metricName = getMetricName(widgetData, {
    valueId: valueId,
    valueType: valueType,
  });

  const originalTag = dataItem.valueType ? getTag(widgetData, dataItem) : dataItem;
  const format = originalTag.format || originalTag.type;

  return {
    columns: [
      ...data.columns,
      {
        id: dataItem.valueId || dataItem.id,
        valueType,
        operation: null,
        name: dataItem.name,
        displayName: metricName,
        symbol: null,
        showSymbolLeft: false,
        decimalDigits: getIsFloat(
          dataItem.operation,
          dataItem.valueType,
          type
            ? tagFormatMap[type]
            : tagFormatMap[dataItem.format || dataItem.type] || dataItem.type
        )
          ? defaultDecimalDigits
          : null,
        tagType: format,
        dateTimeFormat:
          format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null,
      },
    ],
    counter: data?.counter + 1,
  };
};

export function updateColumns(unOrderedColumns, columns) {
  return columns.map((col) => {
    const extraData = unOrderedColumns.find((u) => u.id === col.id) || {};
    return {
      ...extraData,
      ...col,
    };
  });
}

export function getCustomizationColumns(widgetData, previewData, defaultDecimalDigits) {
  const { metrics, groupBy } = widgetData as any;
  const dataArray = concat(groupBy, metrics);

  const data = dataArray.reduce(
    (data: any, dataItem: any) => {
      return getData(previewData, data, dataItem, widgetData, defaultDecimalDigits);
    },
    { columns: [], counter: 0 }
  );

  return data.columns;
}

export const onSelectedDateTimeFormat = (editedColumn, value, setEditedColumn, props) => {
  const { column, index, columnChanged } = props;
  const _column = cloneDeep({ ...editedColumn, dateTimeFormat: value });
  setEditedColumn(_column);
  columnChanged(_column, index);
};
