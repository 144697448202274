import {
  StoreAction,
  DashboardGalleryState,
  DashboardEditorState,
  DashboardSummary,
} from './redux.interface.d';
import { omit } from 'lodash';

export const initGallery = (
  dashboards: DashboardSummary[] = [],
  total: number,
  isLastPage: boolean
): StoreAction => ({
  type: 'gallery/INIT',
  payload: { dashboards, total, isLastPage },
});

export const addNewDashboard = (dashboard: DashboardSummary): StoreAction => ({
  type: 'gallery/ADD_NEW_DASHBOARD',
  payload: { dashboard },
});

export const setDefaultDashboard = (dashboard: DashboardSummary): StoreAction => ({
  type: 'gallery/SET_DEFAULT_DASHBOARD',
  payload: { dashboard },
});

export const setClipboardWidget = (widget): StoreAction => ({
  type: 'gallery/SET_CLIPBOARD_WIDGET',
  payload: { widget },
});

export const resetClipboardWidget = () => ({
  type: 'gallery/RESET_CLIPBOARD_WIDGET',
});

export const addPage = (
  dashboards: DashboardEditorState[],
  total: number,
  isLastPage: boolean
): StoreAction => ({
  type: 'gallery/ADD_PAGE',
  payload: { dashboards, total, isLastPage },
});

export const clearDashboards = (): StoreAction => ({
  type: 'gallery/CLEAR_DASHBOARDS',
  payload: {},
});

export const setDashboardSortArray = (dashboardSortArray: DashboardEditorState[]): StoreAction => ({
  type: 'gallery/SET_DASHBOARD_SORT_ARRAY',
  payload: { dashboardSortArray },
});

const clipboardWidget = localStorage.getItem('CLIPBOARD_WIDGET');

const initialState: DashboardGalleryState = {
  dashboards: null,
  total: 0,
  currentPage: 1,
  isLastPage: true,
  clipboardWidget: clipboardWidget && JSON.parse(clipboardWidget),
  dashboardSortArray: [],
};

export default function loginReducer(
  state: DashboardGalleryState = initialState,
  action: StoreAction
): DashboardGalleryState {
  const { type, payload } = action;

  switch (type) {
    case 'gallery/INIT':
      const clipboardWidget = localStorage.getItem('CLIPBOARD_WIDGET');
      return {
        currentPage: 1,
        dashboards: payload.dashboards,
        total: payload.total,
        isLastPage: payload.isLastPage,
        clipboardWidget: clipboardWidget && JSON.parse(clipboardWidget),
        dashboardSortArray: state.dashboardSortArray || [],
      };
    case 'gallery/ADD_PAGE':
      return {
        ...state,
        currentPage: 1,
        dashboards: state.dashboards.concat(payload.dashboards),
        total: payload.total,
        isLastPage: payload.isLastPage,
      };
    case 'gallery/ADD_NEW_DASHBOARD':
      return {
        ...state,
        dashboards: [payload.dashboard, ...state.dashboards],
        total: payload.total + 1,
      };
    case 'gallery/SET_DASHBOARD_SORT_ARRAY':
      return {
        ...state,
        dashboardSortArray: payload.dashboardSortArray,
      };
    case 'gallery/CLEAR_DASHBOARDS':
      return { ...state, dashboards: [] };
    case 'gallery/SET_DEFAULT_DASHBOARD':
      return {
        ...state,
        dashboards: state.dashboards.map((d) => ({
          ...d,
          defaultForOrganizations: d.id === payload.dashboard.id,
        })),
      };
    case 'gallery/SET_CLIPBOARD_WIDGET':
      localStorage.setItem('CLIPBOARD_WIDGET', JSON.stringify(omit(payload.widget, 'ref')));
      return { ...state, clipboardWidget: omit(payload.widget, 'ref') };
    case 'gallery/RESET_CLIPBOARD_WIDGET':
      localStorage.removeItem('imageWidget');
      localStorage.removeItem('CLIPBOARD_WIDGET');
      return { ...state, clipboardWidget: undefined };
    default:
      return state;
  }
}
