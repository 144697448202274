import { WidgetType } from '@src/redux/redux.interface';
import { value } from './widgets/value';
import { columns } from './widgets/columns';
import { table } from './widgets/table';
import { pie } from './widgets/pie';
import { map } from './widgets/map';
import { radialGauge } from './widgets/radialGauge';
import { line } from './widgets/line';
import { text } from './widgets/text';
import { image } from './widgets/image';
import { linearGauge } from './widgets/linearGauge';
import { alarms } from './widgets/alarms';
import { imageByValue } from './widgets/imageByValue';
import { vnc } from './widgets/vnc';
import { web } from './widgets/web';
import { vpn } from './widgets/vpn';
import { combo } from './widgets/combo';
import { progressBar } from './widgets/progressBar';
import { conditionalValue } from './widgets/conditionalValue';
import { switchWidget } from './widgets/switch';
import { updateAsset } from './widgets/updateAsset';
import { heatmap } from './widgets/heatmap';

/*
  new widget configuration is here and in redux.intarface
*/

//list for widget gallery the order is important
export const widgetOptions: Array<WidgetType> = [
  'userFilters',
  'alarms',
  'image',
  'conditional_value',
  'image_by_value',
  'progress_bar',
  'text',
  'vnc',
  'web',
  'vpn',
  'combo',
  'value',
  'table',
  'heatmap',
  'columns',
  'map',
  'radial_gauge',
  'linear_gauge',
  'line',
  'pie',
  'switch',
  'update_asset',
];

export const remoteAccessWidgets: Array<WidgetType> = ['web', 'vpn', 'vnc'];

export const widgetMap = {
  alarms,
  image,
  image_by_value: imageByValue,
  text,
  value,
  columns,
  table,
  pie,
  map,
  radial_gauge: radialGauge,
  linear_gauge: linearGauge,
  conditional_value: conditionalValue,
  vnc,
  web,
  vpn,
  asset: combo,
  line,
  progress_bar: progressBar,
  switch: switchWidget,
  update_asset: updateAsset,
  userFilters: {
    id: 'userFilters',
    name: 'User filters',
    minWidth: 300,
    minHeight: 300,
    images: {
      canvas: '/assets/images/excel_piechart_780x480.jpg',
      thumbnail: 'userFilters',
    },
    editModalSize: {},
    settings: {
      dragAndDropRules: {},
    },
  },
  clipboard: {
    id: 'clipboard',
    name: 'Clipboard',
    images: {
      thumbnail: 'clipboard',
    },
    editModalSize: {},
    settings: {
      dragAndDropRules: {},
    },
  },
  heatmap,
};
