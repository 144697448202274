import { omit } from 'lodash';
import { isMobile, isTablet } from 'react-device-detect';

import { GetDashboardRes, ServerDashboardState, ServerWidgetState } from '@http/server.interface';
import { DashboardEditorState, WidgetType } from '@redux/redux.interface.d';
import { serverToCalculations } from '@pages/CreateWidgetPage/widgets.utils';
import { parseCustomizationTerms } from '@src/core/canvas/widget.utils';
import { getState } from '@src/redux/store';

function serverWidgetsToLocal(widgets: Array<ServerWidgetState>, currentLayout: string) {
  return widgets.map((w) => ({
    ...omit(
      w.states.find((state) => state.layoutStateType === currentLayout),
      'layoutStateType'
    ),
    filters: w.filters,
    id: w.id,
    calculations: serverToCalculations(w.calculations, w),
    customization:
      w.terms && Object.entries(w.terms).length
        ? parseCustomizationTerms(w.terms, w.type.toLowerCase(), w.customization)
        : w.customization,
    status: w.status,
    exportFormats: w.exportFormats,
    navigationDashboard: w.navigationDashboard,
    navigationFilters: w.navigationFilters,
    widgetFilters: w.widgetFilters,
    name: w.name,
    scope: w.scope,
    type: w.type.toLocaleLowerCase() as WidgetType,
    isSelected: false,
    children: [], // TODO: Server does not support grouping
    hideWidgetName: w.hideWidgetName,
    states: w.states,
  }));
}

/**
 * Converts the server representation of the dashboard to the local
 * representation.
 */
export function serverToLocal(res: GetDashboardRes, isPreview: string): DashboardEditorState {
  const currentLayout =
    isPreview === 'editor'
      ? getState().dashboardEditor.currentLayout
      : res.states.length > 1
      ? isTablet
        ? 'TABLET'
        : isMobile
        ? 'MOBILE'
        : 'DESKTOP'
      : 'DESKTOP';
  const index = res.states.indexOf(
    res.states.find((state) => state.layoutStateType === currentLayout)
  );

  return {
    id: res.id,
    refId: res.refId,
    name: res.name,
    isSplitLayout: !!(res.states.length > 1),
    canvasHeight: res.states[index].canvasHeight,
    showGrid: res.states[index].showGrid,
    refreshRate: res.states[index].refreshRate,
    numSelected: res.states[index].numSelected,
    canvasWidth: {
      label: res.states[index].canvasWidthLabel,
      value: res.states[index].canvasWidthValue,
    },
    canvasWidgets: serverWidgetsToLocal(res.widgets, currentLayout),
    organizationsFilter: res.organizationsFilter,
    assetTypesFilter: res.assetTypesFilter,
    assetFilter: res.singleAvailableAsset
      ? !res.assetFilter?.length
        ? [res.singleAvailableAsset]
        : res.assetFilter
      : res.assetFilter,
    geoFilter: res.geoFilter,
    dateFilter: res.dateFilter,
    dateFilterFrom: res.dateFilterFrom,
    dateFilterTo: res.dateFilterTo,
    filterWidgetVisible: res.filterWidgetVisible,
    filters: res.filters,
    selectedWidgetDimenssions: {},
    status: res.status,
    states: res.states,
    selectionBox: false,
  };
}

/**
 * Converts the local representation of the dashboard to the server
 * representation.
 */
export function localToServer(state: DashboardEditorState): GetDashboardRes {
  return {
    id: state.id,
    name: state.name,
    filterWidgetVisible: state.filterWidgetVisible,
    filters: state.filters,
    organizationsFilter: state.organizationsFilter && state.organizationsFilter.map((f) => f.id),
    assetTypesFilter: state.assetTypesFilter && state.assetTypesFilter.map((f) => f.id),
    assetFilter: state.assetFilter && state.assetFilter.map((f) => f.id),
    geoFilter: state.geoFilter,
    dateFilter: state.dateFilter,
    dateFilterFrom: state.dateFilterFrom,
    dateFilterTo: state.dateFilterTo,
    states: state.states,
    status: state.status,
  };
}

export function localStateToServer(state: DashboardEditorState): Array<ServerDashboardState> {
  return state.states;
}
