import React, { useEffect, useMemo, useState } from 'react';
import { ProgressBarCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import styles from './ProgressBar.scss';
import I18n from '@components/I18n';
import { cssVarsService } from '@core/CssVarsService';
import { cloneDeep, compact } from 'lodash';
import { TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import classNames from 'classnames';
import Select from '@components/Select';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { onlyPositiveDigitsRegex } from '@core/utils';
import {
  getExpressionFromCalculations,
  getIsFloat,
  getOrder,
  getSharedIdForLocalTagsAndCustMetrics,
  removeCustomizationCalculation,
  updateCustomizationCalculations,
} from '@pages/CreateWidgetPage/widgets.utils';
import CalculatedNumericInput from '@components/CalculatedNumericInput';
import { positionOptions, updateWidgetCustomization } from '@core/canvas/widget.utils';

const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };

const ProgressBar = (props) => {
  const languageId = useSelector((state) => state.config.languageId);
  const { widgetData, setWidgetData, defaultDecimalDigits } = props;
  const { customization, calculations, customizationMetrics } = widgetData;
  const [errors, setErrors] = useState({}) as any;
  const {
    maxValue,
    valueFontSize,
    alignment,
    color,
    type,
    valueType,
    barColor,
    barBackgroundColor,
    barWidth,
    textualRemark,
    textualRemarkFontSize,
    textualRemarkColor,
    decimalDigits,
  } = (customization as ProgressBarCustomization) || {};
  const [expression, setExpression] = useState('');

  const showDecimalDigits = getIsFloat(
    widgetData.metrics[0].operation,
    widgetData.metrics[0].valueType,
    widgetData.metrics[0].type,
    calculations?.find(
      (calc) =>
        calc.valueType === widgetData.metrics[0].valueType &&
        calc.valueId === widgetData.metrics[0].valueId
    ),
    widgetData
  );

  useEffect(() => {
    let calculation;

    if (customizationMetrics.length) {
      const valueId = customizationMetrics.find(
        (custMetric) => custMetric.valueType === 'CALCULATION'
      ).valueId;
      calculation = getExpressionFromCalculations(widgetData.calculations, valueId);
    }

    setExpression(calculation ? calculation : maxValue?.toString());
  }, []);

  const alignmentOptions = useMemo(
    () =>
      compact([type !== 'BAR' && { label: 'enum.CENTER', value: 'CENTER' }, ...positionOptions]),
    [type]
  );

  useEffect(() => {
    !customization &&
      updateWidgetCustomization(
        {
          maxValue: null,
          alignment: 'TOP',
          valueFontSize: 20,
          color: cssVarsService.vars.widgetsFont,
          type: 'BAR',
          valueType: 'PERCENTAGE',
          barColor: cssVarsService.vars.widgetsGraphsColorsPalette1,
          barBackgroundColor: cssVarsService.vars.widgetsGraphsColorsPalette2,
          barWidth: 15,
          textualRemark: null,
          textualRemarkFontSize: 12,
          textualRemarkColor: cssVarsService.vars.widgetsFont,
          decimalDigits: defaultDecimalDigits,
        },
        setWidgetData
      );
  }, []);

  const setCustomizationData = (data, key) => {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  const onChangeType = (type) => {
    const tempAligment = alignment === 'CENTER' && type === 'BAR' ? 'TOP' : alignment;
    updateWidgetCustomization({ ...customization, alignment: tempAligment, type }, setWidgetData);
  };

  const onMaxValueChange = (value) => {
    const valueId = getSharedIdForLocalTagsAndCustMetrics(widgetData);

    if (isNaN(value)) {
      const order = getOrder(widgetData);
      setExpression(value);
      updateCustomizationCalculations(setWidgetData, widgetData, value, 'CALCULATION', order);
      setCustomizationData(
        { valueType: 'CALCULATION', valueId: valueId, operation: null, order: order },
        'maxValue'
      );
    } else {
      if (onlyPositiveDigitsRegex.test(value)) {
        setErrors({ ...errors, maxValue: null });
      } else {
        setErrors({
          ...errors,
          maxValue: 'create-widget-page.create-widget.step-four.progress.max-value-error',
        });
      }
      setExpression(value);
      setCustomizationData(+parseFloat(value), 'maxValue');
      removeCustomizationCalculation(widgetData, setWidgetData, valueId);
    }
  };

  return (
    <div className={styles.widgetValue}>
      <div className={styles.rowWithHeader}>
        <I18n noEllipsis={true} element="div" className={styles.subHeader}>
          create-widget-page.create-widget.step-four.progress.type
        </I18n>
        <RadioGroup
          value={type || ''}
          classes={{ root: styles.radioGroupRoot }}
          onChange={(e) => onChangeType(e.target.value)}>
          <FormControlLabel
            value="BAR"
            control={<Radio />}
            label={i18nService.translate(
              'create-widget-page.create-widget.step-four.progress.bar',
              languageId
            )}
          />
          <FormControlLabel
            value="CIRCLE"
            control={<Radio />}
            label={i18nService.translate(
              'create-widget-page.create-widget.step-four.progress.circle',
              languageId
            )}
          />
        </RadioGroup>
      </div>
      <div className={styles.rowWithHeader}>
        <I18n noEllipsis={true} element="div" className={styles.subHeader}>
          create-widget-page.create-widget.step-four.progress.value-type
        </I18n>
        <RadioGroup
          value={valueType || ''}
          classes={{ root: styles.radioGroupRoot }}
          onChange={(e) => setCustomizationData(e.target.value, 'valueType')}>
          <FormControlLabel
            value="PERCENTAGE"
            control={<Radio />}
            label={i18nService.translate(
              'create-widget-page.create-widget.step-four.progress.percentage',
              languageId
            )}
          />
          <FormControlLabel
            value="VALUE"
            control={<Radio />}
            label={i18nService.translate(
              'create-widget-page.create-widget.step-four.progress.actual-value',
              languageId
            )}
          />
        </RadioGroup>
      </div>
      {valueType === 'VALUE' && showDecimalDigits && (
        <div className={styles.customizeRow}>
          <I18n
            noEllipsis={true}
            element="div"
            className={classNames(styles.textText, styles.subHeaderspace)}>
            create-widget-page.create-widget.step-four.progress.decimal-digits
          </I18n>
          <div className={styles.smallWidth}>
            <TextField
              value={decimalDigits ?? ''}
              margin="dense"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCustomizationData(
                  !e.target.value ? e.target.value : +e.target.value < 0 ? 0 : +e.target.value,
                  'decimalDigits'
                );
              }}
              onBlur={(e) => {
                setCustomizationData(
                  10 < +decimalDigits ? 10 : 0 > +decimalDigits ? 0 : +decimalDigits,
                  'decimalDigits'
                );
              }}
              inputProps={{
                min: 0,
                max: 10,
                style: { color: 'var(--systemFont)' },
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.customizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames('asterisk', styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.max-value
        </I18n>
        <div className={styles.fullWidth}>
          <CalculatedNumericInput
            expression={expression}
            setExpression={setExpression}
            widgetData={widgetData}
            onChange={onMaxValueChange}
            inputWidth={'70%'}
          />
          {errors.maxValue && (
            <I18n className={styles.error} element="div">
              {errors.maxValue}
            </I18n>
          )}
        </div>
      </div>
      <div className={styles.customizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.value-alignment
        </I18n>
        <Select
          styles={selectCustomStyle}
          options={alignmentOptions}
          value={alignment && alignmentOptions.find((opt) => opt.value === alignment)}
          onChange={(option) => setCustomizationData(option.value, 'alignment')}
        />
      </div>
      <div className={styles.customizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.value-size
        </I18n>
        <div className={styles.smallWidth}>
          <TextField
            value={valueFontSize || ''}
            margin="dense"
            type="number"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setCustomizationData(+e.target.value, 'valueFontSize');
            }}
            onBlur={(e) => {
              setCustomizationData(
                200 < +valueFontSize ? 200 : 1 > +valueFontSize ? 1 : +valueFontSize,
                'valueFontSize'
              );
            }}
            inputProps={{
              min: 1,
              max: 200,
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
      <div className={styles.customizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.value-color
        </I18n>
        <ColorInput
          value={color}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'color')}
        />
      </div>
      <div className={styles.customizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.bar-color
        </I18n>
        <ColorInput
          value={barColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'barColor')}
        />
      </div>
      <div className={styles.customizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.bar-bg-color
        </I18n>
        <ColorInput
          value={barBackgroundColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'barBackgroundColor')}
        />
      </div>
      <div className={styles.customizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.bar-width
        </I18n>
        <div className={styles.smallWidth}>
          <TextField
            value={barWidth || ''}
            margin="dense"
            type="number"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setCustomizationData(+e.target.value, 'barWidth');
            }}
            onBlur={(e) => {
              setCustomizationData(60 < +barWidth ? 60 : 5 > +barWidth ? 5 : +barWidth, 'barWidth');
            }}
            inputProps={{
              min: 5,
              max: 60,
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
      <div className={styles.customizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.textual-remark
        </I18n>
        <TextField
          value={textualRemark || ''}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'textualRemark');
          }}
          inputProps={{
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={styles.customizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.textual-remark-size
        </I18n>
        <div className={styles.smallWidth}>
          <TextField
            value={textualRemarkFontSize || ''}
            margin="dense"
            type="number"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setCustomizationData(+e.target.value, 'textualRemarkFontSize');
            }}
            onBlur={(e) => {
              setCustomizationData(
                200 < +textualRemarkFontSize
                  ? 200
                  : 1 > +textualRemarkFontSize
                  ? 1
                  : +textualRemarkFontSize,
                'textualRemarkFontSize'
              );
            }}
            inputProps={{
              min: 1,
              max: 200,
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
      <div className={styles.customizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.progress.textual-remark-color
        </I18n>
        <ColorInput
          value={textualRemarkColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'textualRemarkColor')}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
