import { TableColunm } from '@components/Table/table.interface';
import TextField from '@components/Table/TableFields/TextField';

export const colunmsCfgDefaultDashboard: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.organization-type-name',
    field: 'organizationTypeName',
    style: { width: '50%' },
  },
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.details',
    field: 'details',
    style: { width: '50%' },
  },
];

export const colunmsCfgDefaultForAccounts: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.organization-type-name',
    field: 'organizationTypeName',
    style: { width: '50%' },
  },
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.user',
    field: 'userFullName',
    style: { width: '50%' },
  },
];

export const colunmsCfgNavigation: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.dashboard-name',
    field: 'dashboardName',
    style: { width: '35%' },
  },
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.widget-name',
    field: 'widgetName',
    style: { width: '35%' },
  },
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.dashboard-status',
    field: 'dashboardStatus',
    style: { width: '30%' },
  },
];

export const colunmsCfgEvents: Array<TableColunm> = [
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.template-name',
    field: 'templateName',
    style: { width: '70%' },
  },
  {
    Component: TextField,
    label: 'dashboard-gallery.delete-dashboard.template-status',
    field: 'status',
    style: { width: '30%' },
  },
];
