import React, { useState } from 'react';
import MaterialCheckbox from '@components/Checkbox';

import I18n from '@components/I18n';
import styles from '../StepFour.scss';
import ExportItem from './ExportItem';

const exportItemsMap = [
  {
    name: 'CSV',
    label: 'export-csv',
  },
  // {
  //   name: 'PDF',
  //   label: 'exportPdf'
  // },
  // {
  //   name: 'EXL',
  //   label: 'exportExl'
  // }
];

const ExportActions = (props) => {
  const {
    exportFormats,
    onSelectedExportFormat,
    isExportFormatChecked,
    onResetExportFormat,
  } = props;
  const [enableExports, setEnableExports] = useState(exportFormats.length > 0);

  const onSetEnableExports = () => {
    setEnableExports(!enableExports);
    !!enableExports && onResetExportFormat();
  };

  return (
    <div className={styles.exportActions}>
      <div className={styles.exports}>
        {exportItemsMap.map((item) => (
          <ExportItem
            key={item.name}
            onSelectedExportFormat={onSelectedExportFormat}
            isExportFormatChecked={isExportFormatChecked}
            label={item.label}
            name={item.name}
          />
        ))}
      </div>
    </div>
  );
};

export default ExportActions;
