import { useState, useEffect } from 'react';
import { Subject } from './Subject';

export function useSubject<T>(subject: Subject<T>) {
  const [state, setState] = useState(subject.getValue());

  useEffect(() => {
    const sub = subject.subscribe(value => {
      setState(value);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return state;
}
