import { useCallback } from 'react';
import { canvasService } from '@core/canvas/CanvasService';
import { CSI } from '@core/canvas/CanvasService.interface.d';
import { CanvasWidget } from '@redux/redux.interface.d';

export function useResizeCallback(widget: CanvasWidget, direction: CSI.ResizeDirection, widgetRef) {
  return useCallback(
    (event) => {
      // We stop propagation so we don't initiate selectAndStartDrag from CanvasWidgetWrapper.
      event.stopPropagation();

      // We only catch mousedown events originating from the left key.
      if (event.button !== 0) {
        return;
      }

      canvasService.startResize({
        widget,
        direction,
        pageX: event.pageX,
        pageY: event.pageY,
        element: widgetRef.current,
      });
    },
    [widget]
  );
}
