import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from '@redux/useSelector';
import { getState } from '@redux/store';

// Components
import ToolBar from '@components/ToolBar';
import RoutersTable from './RoutersTable';

// Services | Interfaces
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { routersService } from '../RoutersService';
import { apiMap } from '@core/http/apiMap';
import { getPermissionStatus } from '@core/ffAndPermissions';
import styles from './RoutersManagementTab.scss';

// 3rd party
import { template, compact } from 'lodash';
import classNames from 'classnames';

function RoutersManagementPage() {
  const [searchText, setSearchText] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState(null);

  const totalCount = useSelector(state => state.routers.totalCount);
  const resultsCount = useSelector(state => state.routers.resultsCount);
  const userOrgType = useSelector(
    state => state.config.organizationDetails && state.config.organizationDetails.type
  );

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const search = useCallback(searchText => setSearchText(searchText), []);

  const openCreateRouter = useCallback(() => {
    modalService.openModal('createRouter').then(res => {
      if (!res) return;
      httpService
        .api({
          type: 'createRouter',
          urlParams: { routerId: res.id }
        })
        .then(() => {
          if (searchText) {
            // This will automatically refresh the routers table data.
            setClearSearch(true);
            search('');
          } else {
            routersService.refreshRoutersTableData();
          }

          modalService.openAlert({
            text: 'create-router-modal.add-successful-alert',
            iconType: 'v_image'
          });
        });
    });
  }, [searchText]);

  const getLastSearches = () => {
    const organizationId = getState().login.userOrganizationId;
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getRouters'].url)({ organizationId })
        },
        disableBI: true
      })
      .then((res: { [searches: string]: Array<string> }) => {
        setLastSearches(res.searches);
      });
  };

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      pageName: 'Routers',
      getLastSearches,
      lastSearches
    },
    buttons: compact([
      hasPermission &&
        userOrgType !== 'MACHINE_BUILDER_CHANNEL' && {
          type: 'create',
          onClick: openCreateRouter
        }
    ])
  };

  return (
    <div className={classNames('max-height', styles.routersTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <RoutersTable searchText={searchText} />
    </div>
  );
}

export default RoutersManagementPage;
