import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Image from '@components/Image';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './GalleryModal.scss';
import BottomSection from './BottomSection/BottomSection';
import { useInfiniteScroll } from '@core/hooks/useInfiniteScroll';
import { httpService } from '@core/http/HttpService';

function GalleryModal(props) {
  const {
    dismiss,
    args: { selectedImageData, existingImages, modalHeader, widgetType, imageType = 'IMAGE' },
  } = props;

  const close = useCallback(() => dismiss(false), [dismiss]);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [selectedImage, setSelectedImage] = useState(selectedImageData);
  const [lastUploaded, setLastUploaded] = useState(null);

  const getData = async ({ p, ps, isFirstInit, isRefreshList }) => {
    try {
      const res = httpService.api({
        type: 'getImageGallery',
        query: widgetType ? { p, ps, widgetType } : { p, ps },
      });
      if (res) {
        return res;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const { listProps, list, refreshList, resetList, page } = useInfiniteScroll({
    pageSize: 30,
    getData,
    listItemHeight: 28.8,
    dataKey: 'results',
    hasNextKey: 'next',
    totalKey: 'count',
  });

  const setUploadedImage = (image) => {
    setLastUploaded(image);
    setSelectedImage(image);
    resetList();
  };

  const selectImage = () => {
    dismiss(selectedImage);
  };

  const deleteHandler = () => {
    selectedImage?.id === lastUploaded?.id && setLastUploaded(null);
    setSelectedImage(null);
    refreshList();
  };

  useEffect(() => {
    if (list) {
      const selectedImageFromList = list.find((item) => selectedImage?.id === item.id);
      if (selectedImageFromList) setSelectedImage(selectedImageFromList);
    }
  }, [list]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>{modalHeader ? modalHeader : 'gallery.header'}</I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div
        className={styles.content}
        {...listProps({
          style: { paddingBottom: list.length <= 5 ? 288 : list.length <= 10 ? 144 : 0 },
        })}>
        {(page <= 3 && lastUploaded ? [lastUploaded, ...list] : list).map((item, index) =>
          lastUploaded && index !== 0 && lastUploaded?.id === item.id ? null : (
            <div
              key={item.id}
              className={styles.imageContainer}
              onClick={() => setSelectedImage(item)}>
              <div
                className={classNames(
                  styles.imageWrapper,
                  selectedImage && selectedImage?.id === item.id && styles.imageWrapperSelected
                )}>
                {selectedImage && selectedImage?.id === item.id && (
                  <Icon type="selectedImageGallery" className={styles.selectedImageIcon} />
                )}
                {(!selectedImage || selectedImage?.id !== item.id) && (
                  <Icon type="hoveredImageGallery" className={styles.hoveredImageIcon} />
                )}
                <Image src={item.url} />
              </div>
              <div title={item.name} className={classNames(styles.imageName, 'ellipsis-overflow')}>
                {item.name}
              </div>
            </div>
          )
        )}
      </div>
      <BottomSection
        cancel={cancel}
        selectImage={selectImage}
        selectedImage={selectedImage}
        deleteHandler={deleteHandler}
        setUploadedImage={setUploadedImage}
        existingImages={existingImages}
        imageType={imageType}
      />
    </div>
  );
}

export default GalleryModal;
