import VariableValueComponent from '@components/VariableValueComponent';
import VariableName from './VariableName';
import VariableType from './VariableType';
import ActionButtons from './ActionButtons';
import AssetTypeName from './AssetTypeName';
import VariableManagement from './VariableManagement';
import AssetName from './AssetName';
import AssetId from './AssetId';
import { httpService } from '@core/http/HttpService';
import { origin } from '../Variables.utils';

export function getColunmsCfg(
  origin: origin,
  order,
  selectedOrganizationsId,
  setVariablesList: any,
  editedVariableId,
  setEditedVariableId,
  editedAssetId,
  setEditedAssetId,
  assetTypeOrAssetId
) {
  const isOrganizationPage = origin === 'organizationAssetTypes' || origin === 'organizationAssets';
  const styleOrgPage = { minWidth: '19%', maxWidth: '19%' };
  const styleAssetType30 = { minWidth: '30%', maxWidth: '30%' };
  const styleAssetType40 = { minWidth: '40%', maxWidth: '40%' };

  return [
    {
      Component: AssetTypeName,
      field: 'assetTypeName',
      label: 'general.asset-type',
      style: isOrganizationPage ? styleOrgPage : styleAssetType30,
      isSortable: true,
      isVisible: origin === 'organizationAssetTypes',
    },
    {
      Component: AssetName,
      field: 'assetName',
      label: 'assets-tab.asset-name',
      style: isOrganizationPage ? styleOrgPage : styleAssetType30,
      isSortable: true,
      isVisible: origin === 'organizationAssets',
    },
    {
      Component: AssetId,
      field: 'assetId',
      label: 'assets-tab.asset-id',
      style: isOrganizationPage ? styleOrgPage : styleAssetType30,
      isSortable: true,
      isVisible: origin === 'organizationAssets',
    },
    {
      Component: VariableName,
      field: 'name',
      label: isOrganizationPage ? 'variables.property-name' : 'general.name',
      style: isOrganizationPage ? styleOrgPage : styleAssetType30,
      isSortable: true,
      isVisible: true,
    },
    {
      Component: VariableType,
      field: 'valueType',
      label: 'general.type',
      style: isOrganizationPage ? { minWidth: '13%', maxWidth: '13%' } : styleAssetType30,
      isSortable: true,
      isVisible: true,
    },
    {
      Component: VariableManagement,
      field: 'managementLevel',
      label: 'general.management',
      style:
        origin === 'organizationAssetTypes'
          ? { minWidth: '22%', maxWidth: '22%' }
          : origin === 'organizationAssets'
          ? styleOrgPage
          : styleAssetType30,
      isSortable: true,
      isVisible: origin === 'organizationAssetTypes',
    },
    {
      Component: VariableValueComponent,
      field: 'value',
      label: 'general.value',
      style: { minWidth: '220px', maxWidth: '220px' },
      isSortable: false,
      assetTypeOrAssetId,
      editedVariableId,
      origin,
      selectedOrganizationsId,
      setEditedVariableId,
      editedAssetId,
      setEditedAssetId,
      setVariablesList,
      isVisible: true,
      inputStyle: { width: '200px', minWidth: '200px' },
      isOrganizationPage,
      order,
    },
    {
      Component: ActionButtons,
      type: 'staticEnd',
      label: '',
      style: { width: origin === 'organizationAssets' ? 200 : 300 },
      isSortable: false,
      origin,
      setVariablesList,
      setEditedVariableId,
      setEditedAssetId,
      isVisible: true,
      assetTypeOrAssetId,
      order,
    },
  ];
}

export function getVariables(
  organizationId,
  nextPage,
  setNextPage,
  setResultsCount,
  setTotalCount,
  apiType,
  searchText,
  pageSize,
  setDisableLoad,
  firstInit = false,
  setVariablesList,
  variablesList,
  order?
) {
  setDisableLoad && setDisableLoad(true);

  httpService
    .api({
      type: apiType,
      urlParams: { organizationId: organizationId },
      query: {
        p: firstInit ? 1 : nextPage,
        ps: pageSize,
        search: searchText ? searchText : null,
        ordering: order ? `${order.orderSign}${order.label}` : null,
      },
    })
    .then((res: any) => {
      res.next && setDisableLoad && setDisableLoad(false);
      if (firstInit) {
        setVariablesList(res.results);
        setTotalCount(res.allCount);
        setResultsCount(res.count);
        setNextPage(2);
      } else {
        const newVariablesList = variablesList.concat(res.results);
        setVariablesList(newVariablesList);
        setTotalCount(res.allCount);
        setResultsCount(res.count);
        setNextPage(nextPage + 1);
      }
    });
}
