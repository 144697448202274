import React, { useMemo } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import AssetsManagementTab from '@pages/DeviceManagment/Assets/AssetsManagementTab';
import PLCManagementPage from '@pages/DeviceManagment/PLC/PLCManagementTab';
import RoutersManagementPage from '@pages/DeviceManagment/Routers/RoutersManagementTab';
import I18n from '@components/I18n';
import styles from './DeviceManagementPage.scss';
import { getFlagStatus } from '@core/ffAndPermissions';
import { useSelector } from '@src/redux/useSelector';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';

function DeviceManagementPage(props) {
  const flags = useMemo(
    () => ({
      plcTab: getFlagStatus('device-management.plc-management-tab'),
      assetsTab: getFlagStatus('device-management.assets-management-tab'),
      routersTab: getFlagStatus('device-management.routers-management-tab')
    }),
    []
  );

  const userOrgType = useSelector(
    state => state.config.organizationDetails && state.config.organizationDetails.type
  );

  return (
    <div className="max-height">
      <div className={styles.header}>
        <div className={styles.pageTitle}>
          <I18n>device-management-page.device-management</I18n>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.deviceManagementTabs}>
          {flags.assetsTab && (
            <NavLink
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`${props.match.url}/assetsmanagement`}>
              <I18n>device-management-page.assets-management</I18n>
            </NavLink>
          )}
          {flags.plcTab && organizationTypeMap[userOrgType] !== 4 && (
            <NavLink
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`${props.match.url}/plcmanagement`}>
              <I18n>device-management-page.plc-management</I18n>
            </NavLink>
          )}
          {flags.routersTab && (
            <NavLink
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`${props.match.url}/routersmanagement`}>
              <I18n>device-management-page.routers-management</I18n>
            </NavLink>
          )}
        </div>
        <div className={styles.routsSection}>
          <Switch>
            {flags.assetsTab && (
              <Route path={`${props.match.url}/assetsmanagement`} component={AssetsManagementTab} />
            )}
            {flags.plcTab && organizationTypeMap[userOrgType] !== 4 && (
              <Route
                exact
                path={`${props.match.url}/plcmanagement`}
                component={PLCManagementPage}
              />
            )}
            {flags.routersTab && (
              <Route
                exact
                path={`${props.match.url}/routersmanagement`}
                component={RoutersManagementPage}
              />
            )}
            <Redirect to={`${props.match.url}/assetsmanagement`} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default DeviceManagementPage;
