import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import styles from './CreateDashboardModal.scss';
import Icon from '@components/Icon';
import classNames from 'classnames';
import MaterialCheckbox from '@components/Checkbox';
import { createDashboardService } from './CreateDashboardService';
import { httpService } from '@core/http/HttpService';
import { OwnerModel } from './CreateDashboardModal.interface';
import { i18nService } from '@core/i18n/I18nService';
import GooglePlacesInput from '@components/GooglePlacesInput';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import FormikInput from '@components/Formik/FormikInput';
import MultiSelect from '@components/MultiSelect';
import moment from 'moment';
import { getPermissionStatus } from '@core/ffAndPermissions';
import {
  saveCreateDashboard,
  resetFiltersClicked,
  onFilterChange,
} from './CreateDashboardModal.utils';
import { ShowClearButton } from '@components/Select/Select';
import AssetFilterComponent from '../../components/AssetFilterComponent/AssetFilterComponent';
import { dynamicSort } from '@core/utils';
import { DASHBOARD_NAME_MAX_LENGTH } from '@core/constants';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('validations.mandatory'),
});

const initialScopeState = {
  organization: [],
  assetType: [],
  geography: [],
  dateFilter: {
    dateFilter: 'MONTH',
    dateFilterFrom: null,
    dateFilterTo: null,
  },
  assets: [],
};

function CreateDashboardModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const nextNumber = args.nextNumber;
  const cancel = useCallback(() => {
    dismiss(false);
  }, [dismiss]);

  const [organizationOptions, setOrganizationOptions] = useState<Array<OwnerModel>>([]);
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState(initialScopeState.organization);
  const [assetTypeSelected, setAssetTypeSelected] = useState(initialScopeState.assetType);
  const [assetSelected, setAssetSelected] = useState(initialScopeState.assets);
  const [geographySelected, setGeographySelected] = useState(initialScopeState.geography);
  const [scopeChange, setScopeChange] = useState(false);
  const [dateFilter, setDateFilter] = useState(initialScopeState.dateFilter);
  const [filtersToDashboard, setFiltersToDashboard] = useState(false);

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'VIEW'), []);

  const onSubmit = (values) => {
    saveCreateDashboard(
      values,
      organizationSelected,
      assetTypeSelected,
      assetSelected,
      geographySelected,
      filtersToDashboard,
      dateFilter,
      dismiss
    );
  };

  const initialValues = useMemo(() => {
    return {
      name: `Dashboard ${nextNumber}`,
    };
  }, [nextNumber]);

  const getOrganization = (value?: any) => {
    httpService
      .api({
        type: 'getOwners',
        query: {
          search: value,
        },
      })
      .then((organizations: OwnerModel[]) => {
        setOrganizationOptions(organizations.sort(dynamicSort('name', '+')));
      });
  };

  const getAssetTypes = (value?: any) => {
    httpService
      .api({
        type: 'getAssetTypeOptionsInfo',
        query: {
          search: value,
          p: 1,
          ps: 1000,
        },
      })
      .then((types: any) => {
        setAssetTypeOptions(types.results);
      });
  };

  const dateRangeSelectedChange = (dateFilter) => {
    setDateFilter(dateFilter);
  };

  const handleChange = (source, filters) => {
    onFilterChange(
      source,
      filters,
      setOrganizationSelected,
      setAssetTypeSelected,
      setAssetSelected,
      dateRangeSelectedChange,
      setGeographySelected,
      setScopeChange,
      initialScopeState
    );
  };

  useEffect(() => {
    createDashboardService.setModalDismiss(dismiss);
    getOrganization();
    getAssetTypes();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>create-dashboard-modal.title</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ isValid, values, dirty }) => (
            <Form>
              <div>
                <FormikInput
                  name="name"
                  label="create-dashboard-modal.dashboard-name"
                  value={values.name}
                  maxLength={DASHBOARD_NAME_MAX_LENGTH}
                  labelInline={false}
                />
              </div>
              <Tooltip
                text={i18nService.translate('create-dashboard-modal.dashboard-scope-info')}
                placement="right"
                children={
                  <div className={classNames('pointer', styles.btnGroup)}>
                    <I18n className={styles.textBtn}>create-dashboard-modal.dashboard-scope</I18n>
                    <Icon className={styles.iconBtn} type="info"></Icon>
                  </div>
                }></Tooltip>
              <div className={styles.filterGroup}>
                <div className={classNames(styles.fieldLabel, 'ellipsis-overflow')}>
                  <I18n>create-dashboard-modal.organization</I18n>
                </div>
                <MultiSelect
                  id="organization"
                  options={organizationOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  values={organizationSelected}
                  onChange={(filters) => handleChange('organization', filters)}
                  optionType="checkbox"
                  placeholder={i18nService.translate('general.all')}
                  maxSelections={50}
                />
              </div>
              {hasPermission && (
                <div className={styles.filterGroup}>
                  <Tooltip
                    text={i18nService.translate('create-dashboard-modal.assettype-info')}
                    placement="right"
                    children={
                      <div className={classNames('pointer', styles.btnGroup)}>
                        <I18n className={styles.labelBtn}>create-dashboard-modal.assettype</I18n>
                        <Icon className={styles.iconBtn} type="info"></Icon>
                      </div>
                    }></Tooltip>
                  <MultiSelect
                    id="assetType"
                    options={assetTypeOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    values={assetTypeSelected}
                    onChange={(filters) => handleChange('assetType', filters)}
                    tableConfig={(options) => {
                      return {
                        name: options.name,
                        label: options.labels && options.labels.map((item) => item.text).join(', '),
                      };
                    }}
                    optionType="tableWithCheckbox"
                    placeholder={i18nService.translate('general.all')}
                  />
                </div>
              )}
              <div className={styles.filterGroup}>
                <div className={classNames(styles.fieldLabel, 'ellipsis-overflow')}>
                  <I18n>create-dashboard-modal.date-range</I18n>
                </div>
                <DatePicker
                  data={dateFilter}
                  selectedChanged={(filters) => handleChange('dateFilter', filters)}
                  minDate={moment().subtract(7, 'year').format('DD/MM/YYYY')}
                  openDirection="up"></DatePicker>
              </div>
              <div className={styles.filterGroup}>
                <div className={classNames(styles.fieldLabel, 'ellipsis-overflow')}>
                  <I18n>create-dashboard-modal.geography</I18n>
                </div>
                <GooglePlacesInput
                  id="createNewDashboardModalGeo"
                  defaultValue={geographySelected}
                  placeholder={i18nService.translate('general.all')}
                  onSelectedCountryChange={(filters) => handleChange('geography', filters)}
                  maxSelections={50}
                />
              </div>
              {hasPermission && (
                <div className={styles.filterGroup}>
                  <div className={classNames(styles.fieldLabel, 'ellipsis-overflow')}>
                    <I18n>create-dashboard-modal.asset</I18n>
                  </div>
                  <AssetFilterComponent
                    assetTypeSelected={assetTypeSelected}
                    assetSelected={assetSelected}
                    onChange={(filters) => handleChange('asset', filters)}
                    setAssetSelected={setAssetSelected}
                    // showClearButton={ShowClearButton.true}
                    showClearButton={assetSelected ? ShowClearButton.true : ShowClearButton.false}
                  />
                </div>
              )}
              <div className={styles.addFiltersGroup}>
                <MaterialCheckbox
                  checked={filtersToDashboard}
                  color="primary"
                  onChange={(e) => setFiltersToDashboard(e.target.checked)}
                />
                <I18n element="div" className={styles.checkboxText}>
                  create-dashboard-modal.add-filters
                </I18n>
              </div>
              <div className={styles.actionsRow}>
                <div>
                  <Button
                    styles={{ marginLeft: 13, maxWidth: 250 }}
                    mode={['cancel', 'bigFont']}
                    onClick={cancel}>
                    <I18n>general.cancel</I18n>
                  </Button>
                  <Button
                    type="submit"
                    disabled={(!isValid && dirty) || values.name.length < 2}
                    styles={{ marginLeft: 13, maxWidth: 250 }}
                    mode="bigFont">
                    <I18n>general.create</I18n>
                  </Button>
                </div>
                {scopeChange && (
                  <div
                    className={classNames(styles.clear, 'pointer', 'ellipsis-overflow')}
                    onClick={() =>
                      resetFiltersClicked(
                        setOrganizationSelected,
                        setAssetTypeSelected,
                        setAssetSelected,
                        setDateFilter,
                        setGeographySelected,
                        setScopeChange,
                        initialScopeState
                      )
                    }>
                    <I18n>general.clear-all</I18n>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreateDashboardModal;
