import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { RemoteOperatorWidgetProps } from './RemoteOperatorWidget.interface';
import Button from '@components/Button';
import { httpService } from '@core/http/HttpService';
import { useSelector } from '@src/redux/useSelector';
import {
  openAndroidAppIsAvailableModal,
  openIOSAppIsAvailableModal,
} from './RemoteOperatorWidget.utils';
import { isDesktop } from 'react-device-detect';
import { openRemoteHMIorVPNpopup } from '../RemoteAccessWidget.utils';

/*
REMOTE OPERATOR is not a stand-alone widget.
It is used in the Web and Remote HMI widgets to achieve the Connect button functionality similar to the VPN widget
*/

const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    display: `flex`,
    height: ({ customization }: any) => {
      let textHeight = 0;
      if (customization.textualRemark && customization.textualRemark !== '')
        textHeight += customization.textualRemarkFontSize;

      if (customization.requestSentText && customization.requestSentText !== '')
        textHeight += customization.requestSentFontSize;

      return `calc(100% - ${textHeight}px)`;
    },
    width: ({ customization }: any) => (customization?.buttonType === 'IMAGE_BUTTON' ? '100%' : ''),
    minHeight: 38,
  },
  text: {
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function RemoteOperatorWidget(props: RemoteOperatorWidgetProps) {
  const classes = useStyles(props);
  const {
    customization,
    isPreview,
    assetId,
    plcType,
    widgetType,
    macchinaConnected,
    remoteAccessUrl = null,
  } = props;
  const clientType = useSelector((state) => state.clientState.clientType);
  const isMobileApp = ['uniCloudApp', 'iOSUniCloudApp'].includes(clientType);

  const isEnabled =
    macchinaConnected &&
    ((plcType === 'LEGACY' && widgetType !== 'web') || (isMobileApp && plcType === 'UNISTREAM'));

  async function handleClick() {
    if (!isPreview) {
      let link;

      if (widgetType === 'web') {
        link = remoteAccessUrl;
      } else {
        const result = await httpService.api({
          type: 'getRemoteAccessWidgetData',
          urlParams: {
            assetId: assetId,
            type: 'vpn',
          },
        });

        link = result['link'];
      }

      switch (plcType) {
        case 'UNISTREAM':
          if (widgetType === 'vnc') {
            try {
              if (clientType === 'uniCloudApp') {
                uniCloudApp?.startRemoteOperatorSession(link);
              } else if (clientType === 'iOSUniCloudApp') {
                window['webkit']['messageHandlers']['startRemoteOperatorSession'].postMessage(link);
              }
            } catch (e) {
              console.log(`startRemoteOperatorSession failed: ${e}`);
            }
            return;
          } else if (widgetType === 'web') {
            try {
              if (clientType === 'uniCloudApp') {
                uniCloudApp?.startWebSession(link);
              } else if (clientType === 'iOSUniCloudApp') {
                window['webkit']['messageHandlers']['startWebSession'].postMessage(link);
              }
            } catch (e) {
              console.log(`startWebSession failed: ${e}`);
            }
            return;
          }
        case 'LEGACY':
          switch (clientType) {
            case 'uniCloudApp':
              try {
                uniCloudApp?.startRemoteOperatorSession(link);
              } catch (e) {
                console.log(`startRemoteOperatorSession failed: ${e}`);
              }
              break;
            case 'iOSUniCloudApp':
              try {
                window['webkit']['messageHandlers']['startRemoteOperatorSession'].postMessage(link);
              } catch (e) {
                console.log(`startRemoteOperatorSession failed: ${e}`);
              }
              break;
            default:
              const isAndroid = !!navigator.userAgent?.match(/android/gi);
              const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

              if (isAndroid) {
                let isMobileAppInstalled = false;
                if (window.navigator['getInstalledRelatedApps']) {
                  window.navigator['getInstalledRelatedApps']()
                    .then((res) => {
                      isMobileAppInstalled = res.some(
                        (app) => app.id === 'com.unitronics.unicloud'
                      );
                      openAndroidAppIsAvailableModal(isMobileAppInstalled);
                    })
                    .catch((error) => {
                      console.log(error);
                      openAndroidAppIsAvailableModal(isMobileAppInstalled);
                    });
                } else {
                  openAndroidAppIsAvailableModal(isMobileAppInstalled);
                }
              } else if (isiOS) {
                openIOSAppIsAvailableModal();
              } else if (isDesktop) {
                const desktopLink = `${link.replace('srauc', 'remote-operator')}`;
                openRemoteHMIorVPNpopup(desktopLink, 'hmi');
              }
              break;
          }
        default:
          return;
      }
    }
  }

  return (
    <Typography component="div" className={classes.buttonWrapper}>
      <Button
        disabled={!isEnabled && !isPreview}
        mode={'big'}
        onClick={handleClick}
        styles={{
          backgroundColor: customization.enabledButtonColor,
          fontSize: '16px',
          color: customization.buttonTextColor,
        }}>
        {customization.buttonText}
      </Button>
    </Typography>
  );
}

export default RemoteOperatorWidget;
