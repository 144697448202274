import React, { useCallback } from 'react';
import { IconButton, Checkbox } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { dashboardService } from '@core/canvas/DashboardService';
import { FiltersWidgetFilterProps } from './FiltersWidgetFilter.interface.d';
import FilterInput from './FilterInput';
import styles from './FiltersWidgetFilter.scss';
import classNames from 'classnames';
import MaterialCheckbox from '@components/Checkbox';

const checkboxS = { marginLeft: -3, marginRight: 10 };

function FiltersWidgetFilter(props: FiltersWidgetFilterProps) {
  const { filter } = props;

  const removeFilter = useCallback(() => {
    dashboardService.removeFilterFromFiltersWidget(filter.filterType);
  }, [filter]);

  const toggleEditableState = useCallback(() => {
    dashboardService.toggleEditableFilterFromFiltersWidget(filter.filterType);
  }, [filter]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.dragIndicator}>{/* <Icon type="move" /> */}</div>
      <div className={styles.mainSection}>
        <div className={styles.mainLeft}>
          <I18n
            element="div"
            className={styles.title}>{`filters-widget.${filter.filterType}`}</I18n>
          <div className={styles.allowEdit}>
            <MaterialCheckbox
              style={checkboxS}
              color="default"
              checked={filter.editable}
              onChange={toggleEditableState}
            />

            <I18n element="div" className={styles.allowEditText}>
              filters-widget.allow-edit
            </I18n>
          </div>
        </div>
        <div className={styles.mainRight}>
          <FilterInput {...props} />
        </div>
      </div>
      {/* <IconButton id={styles.removeFilter} onClick={removeFilter}>
        <Cancel />
      </IconButton> */}
      <Icon
        type="closeBlue"
        onClick={removeFilter}
        className={classNames('pointer', styles.removeFilter)}></Icon>
    </div>
  );
}

export default React.memo(FiltersWidgetFilter);
