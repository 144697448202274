import React from 'react';
import classNames from 'classnames';

import { i18nService } from '@core/i18n/I18nService';
import I18n from '@components/I18n';
import styles from '../WidgetSideBar.scss';
import { useSelector } from '@src/redux/useSelector';

const StepTwoText = ({ displayData }) => {
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <>
      {displayData.scope ? (
        <div
          className={classNames('ellipsis-overflow', styles.activeText)}
          title={i18nService.translate(
            `create-widget-page.create-widget.step-two.${displayData.scope}`,
            languageId
          )}>
          {i18nService.translate(
            `create-widget-page.create-widget.step-two.${displayData.scope}`,
            languageId
          )}
        </div>
      ) : (
        <div
          className={styles.textBox}
          title={i18nService.translate('widget-side-bar.step-two.default-text', languageId)}>
          <I18n noEllipsis>widget-side-bar.step-two.default-text</I18n>
        </div>
      )}
    </>
  );
};

export default StepTwoText;
