import React from 'react';
import { useSelector } from '@src/redux/useSelector';
import { makeStyles } from '@material-ui/core';
import { buildImageUrl } from '@core/utils';

const useStyles = makeStyles({
  logo: {
    height: ({ isMobileView }: any) => (isMobileView ? 50 : 64),
    minWidth: ({ isMobileView }: any) => (isMobileView ? 50 : 64),
    maxWidth: ({ isMobileView }: any) => (isMobileView ? 300 : 384),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  logoWrapper: {
    height: ({ isMobileView }: any) => (isMobileView ? 50 : 64),
    minWidth: ({ isMobileView }: any) => (isMobileView ? 50 : 64),
    maxWidth: ({ isMobileView }: any) => (isMobileView ? 300 : 384),
  },
});

function LogoIcon(props) {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const { isFree } = props;
  const logoUrl = whiteLabelDetails[isFree ? 'freeLogo' : 'premiumLogo']?.content?.logoUrl;

  const classes = useStyles({ isMobileView });

  return (
    <div
      style={props.style?.logoWrapper ? props.style?.logoWrapper : undefined}
      className={classes.logoWrapper}>
      {logoUrl && (
        <img
          className={classes.logo}
          style={props.style?.logo ? props.style?.logo : undefined}
          src={`${logoUrl && buildImageUrl(logoUrl)}`}
        />
      )}
    </div>
  );
}

export default LogoIcon;
