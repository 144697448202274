import React from 'react';
import styles from './UserRoles.scss';
import { UserData } from '../usersTable.interface';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import usersRolesMap from '../../UsersRolesMap';

function UserRoles(props: UserData) {
  const { roles } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const rolesText = roles
    .map((i: any) =>
      i18nService.translate(`users-roles.${Number.isInteger(i) ? i : usersRolesMap[i]}`, languageId)
    )
    .join(', ');
  return (
    <div className={styles.roles} title={rolesText}>
      {rolesText}
    </div>
  );
}

export default UserRoles;
