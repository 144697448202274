import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@components/Icon';
import styles from './PLCDisconnectBTN.scss';
import { httpService } from '@core/http/HttpService';
import { PLCData } from '@src/redux/redux.interface';
import { modalService } from '@core/modals/ModalService';
import { plcService } from '@pages/DeviceManagment/PLC/PLCService';
import { getPermissionStatus } from '@core/ffAndPermissions';

function PLCDisconnectBTN(props: PLCData) {
  const { asset, serial, id } = props;
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const disconnectPlc = () => {
    const text = serial.startsWith('MDBS')
      ? 'plc-tab.disconnect-modbus-plc'
      : 'plc-tab.disconnect-plc';
    modalService
      .openModal('confirm', {
        text,
        iconType: 'attention_image',
        serial,
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'plc-tab.disconnect-plc-popup-header',
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          httpService
            .api({
              type: 'disconnectPlc',
              urlParams: { plcId: id },
            })
            .then((res) => {
              plcService.refreshPLCTableData();
            })
            .catch((res) => {
              plcService.refreshPLCTableData();
            });
        }
      });
  };

  return (
    <>
      {!asset.id && (
        <IconButton disabled={!hasPermission} onClick={disconnectPlc} id={styles.disconnectButton}>
          <Icon
            type="disconnectPlc"
            tooltipText="plc-tab.disconnect-plc-tooltip"
            color="var(--systemFont)"
            disabled={!hasPermission}
          />
        </IconButton>
      )}
    </>
  );
}
export default PLCDisconnectBTN;
