import { LoginResponse } from '@core/auth/auth.interface';
import { authService } from '@core/auth/AuthService';
import { httpService } from '@core/http/HttpService';
import { getState } from '@src/redux/store';
import history from '@core/history';

export const onSubmit = async (values, setIsCodeValidationFailed, grecaptcha) => {
  let captchaToken = '';
  grecaptcha &&
    (await grecaptcha.enterprise.execute().then((res) => {
      captchaToken = res;
    }));

  try {
    setIsCodeValidationFailed(false);
    const res: LoginResponse = await httpService.api({
      type: 'verify2FaCode',
      data: { code: values.code, captchaToken: captchaToken },
    });

    const { token, refreshToken } = res;

    const credentials = getState().login;
    authService.setLoggedIn({
      ...credentials,
      twoFaStatus: {
        ...credentials.twoFaStatus,
        isCompleted: true,
      },
      token,
      refreshToken,
    });
    history.push('/main');
  } catch {
    setIsCodeValidationFailed(true);
  }
};

export const resendCode = async (method) => {
  await httpService.api({
    type: 'resend2FaCode',
    data: { method },
  });
};
