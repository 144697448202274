import React, { useMemo, useRef, useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import I18n from '@components/I18n';
import { canvasService } from '@core/canvas/CanvasService';
import { useSubject } from '@core/effects';
import { useSelector } from '@redux/useSelector';
import CanvasWidgetWrapper from '../CanvasWidgetWrapper';
import DragCaster from '../DragCaster';
import GridIndicator from '../GridIndicator';
import CanvasEndIndicator from '../CanvasEndIndicator';
import FiltersWidget from '../FiltersWidget';
import styles from './Canvas.scss';
import { cssVarsService } from '@core/CssVarsService';
import { dispatch } from '@src/redux/store';
import { setCanvasWidgets } from '@src/redux/dashboardEditor';

function Canvas({
  widgets,
  selectedWidgets,
  setSelectedWidgets,
  selectedWidgetIdsWithError,
  setSelectedWidgetIdsWithError,
  isRectangleSelection,
  setIsRectangleSelection,
  applyButtonClicked,
  setApplyButtonClicked,
}) {
  const canvasWidgets = widgets;
  const currentLayout = useSelector((state) => state.dashboardEditor.currentLayout);
  const canvasHeight = useSelector((state) => state.dashboardEditor.canvasHeight);
  const canvasWidth = useSelector(
    (state) =>
      state.dashboardEditor.states.find((state) => state.layoutStateType === currentLayout)
        .canvasWidthValue
  );
  cssVarsService.setVariable('sideBarWidth', currentLayout === 'MOBILE' ? 0 : 52);
  cssVarsService.setVariable('canvasWidth', canvasWidth);

  const filterWidgetVisible = useSelector((state) => state.dashboardEditor.filterWidgetVisible);
  const innerStyle = useMemo(
    () => ({ height: canvasHeight, minHeight: '100%' }),
    [canvasHeight, currentLayout]
  );
  const canvasOuterElement = useRef<HTMLDivElement>(undefined);
  const canvasInnerElement = useRef<HTMLDivElement>(undefined);

  const [isWidgetResized, setIsWidgetResized] = useState(false);

  const [previewWidgetDataMap, setPreviewWidgetDataMap] = useState({});

  const draggedItem = useSubject(canvasService.draggedItem$);
  const { isDragging, hasMoved, canvasWidget } = draggedItem;
  const draggedWidgetId = canvasWidget && canvasWidget.id;

  const [widgetsWithRef, setWidgetsWithRef] = useState([]);

  const setPreviewData = (key, value) => {
    setPreviewWidgetDataMap((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    canvasService.setElements(canvasOuterElement.current, canvasInnerElement.current);

    return () => {
      canvasService.cleanup();
    };
  }, []);

  useEffect(() => {
    if (canvasOuterElement.current) canvasOuterElement.current.scrollTop = 0;
  }, [currentLayout]);

  useEffect(() => {
    if (widgetsWithRef.length === canvasWidgets.length) {
      dispatch(setCanvasWidgets(widgetsWithRef, currentLayout));
    }
  }, []);

  function addRefToCanvasWidget(widget) {
    const newWidgets = widgetsWithRef;
    newWidgets.push(widget);
    setWidgetsWithRef(newWidgets);
  }

  return (
    <div className={styles.wrapper} ref={canvasOuterElement}>
      <ReactResizeDetector handleHeight />
      {filterWidgetVisible && <FiltersWidget currentLayout={currentLayout} />}
      <div
        style={innerStyle}
        className={styles.inner}
        ref={canvasInnerElement}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsWidgetResized(false);
          canvasService.onCanvasClick({
            pageX: e.pageX,
            pageY: e.pageY,
            ctrlKey: e.ctrlKey,
            isRectangleSelection: isRectangleSelection,
            setIsRectangleSelection: setIsRectangleSelection,
          });
        }}>
        <CanvasEndIndicator />
        {!canvasWidgets.length && (
          <I18n className={styles.dragMessage}>dashboard-editor.drag-message</I18n>
        )}
        {isDragging && hasMoved && (
          <DragCaster
            draggedItem={draggedItem}
            previewData={
              draggedItem.canvasWidget && previewWidgetDataMap[draggedItem.canvasWidget.id]
            }
          />
        )}

        <GridIndicator />
        {canvasWidgets.map((widget) => (
          <CanvasWidgetWrapper
            key={widget.id}
            widget={widget}
            isHidden={hasMoved && draggedWidgetId === widget.id}
            previewData={widget && previewWidgetDataMap[widget.id]}
            setPreviewData={setPreviewData}
            selectedWidgets={selectedWidgets}
            setSelectedWidgets={setSelectedWidgets}
            addRefToCanvasWidget={addRefToCanvasWidget}
            isWidgetResized={isWidgetResized}
            setIsWidgetResized={setIsWidgetResized}
            selectedWidgetIdsWithError={selectedWidgetIdsWithError}
            setSelectedWidgetIdsWithError={setSelectedWidgetIdsWithError}
            applyButtonClicked={applyButtonClicked}
            setApplyButtonClicked={setApplyButtonClicked}
          />
        ))}
      </div>
    </div>
  );
}

export default Canvas;
