import React from 'react';
import { useSelector } from '@src/redux/useSelector';
import { makeStyles } from '@material-ui/core';

// Interfaces | Services
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { modalService } from '@core/modals/ModalService';

// Components
import I18n from '@components/I18n';
import ColorInput from '@components/ColorInput';

// 3rd party
import { compact } from 'lodash';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    padding: '22px 44px',
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#f9fafe',
    flexDirection: 'column',
  },
  fields: {
    paddingLeft: 30,
    marginTop: 20,
  },
  item: {
    marginBottom: 25,
  },
}));

function SystemColors(props) {
  const classes = useStyles(props);
  const { displaySettingsData, onChangeData, refreshColorPickers } = props;
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);

  const fieldsCfgGeneric = [
    {
      label: 'white-labeling.system-colors.headers-background',
      name: 'headerBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.screens-background',
      name: 'screenBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.fonts',
      name: 'font',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.error-messages-text-color',
      name: 'errorMessagesTextColor',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.popups-header',
      name: 'popupHeaderBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.popups-sub-header',
      name: 'popupSubHeaderBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.contents-section',
      name: 'contentBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.disabled-font',
      name: 'disabledFont',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.disabled-background',
      name: 'disabledBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.highlighted-table-row-system',
      name: 'highlightedTableRow',
      type: 'color',
      keyName: 'systemColors',
    },
  ];

  const fieldsCfgButtons = [
    {
      label: 'white-labeling.system-colors.buttons',
      name: 'buttonBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.cancel-buttons',
      name: 'cancelButtonBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.selected',
      name: 'fontSelected',
      type: 'color',
      keyName: 'systemColors',
    },
  ];

  const fieldsCfgDashboardGallery = [
    {
      label: 'white-labeling.system-colors.published-background',
      name: 'publishedBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.draft-background',
      name: 'draftBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.enable-editor-icons',
      name: 'enableEditorIcons',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.disable-editor-icons',
      name: 'disableEditorIcons',
      type: 'color',
      keyName: 'systemColors',
    },
  ];

  const fieldsCfgDashboardEditor = [
    {
      label: 'white-labeling.system-colors.create-widget-creation-wizard-navigation-pane',
      name: 'createWidgetSideBarBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.selected-widget-border',
      name: 'selectedWidgetBorder',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.selection-rectangle-border-and-background',
      name: 'selectionRectangleColor',
      type: 'color',
      keyName: 'systemColors',
    },
  ];

  const sectionsCfgOrgsTree = [
    {
      label: 'white-labeling.system-colors.mb-organizations',
      fields: compact([
        {
          label: 'white-labeling.system-colors.background',
          labelWidth: '280px',
          name: 'machineBuilderOrgBackgroundColor',
          type: 'color',
          keyName: 'systemColors',
        },
        {
          label: 'white-labeling.system-colors.text',
          labelWidth: '280px',
          name: 'machineBuilderOrgTextColor',
          type: 'color',
          keyName: 'systemColors',
        },
        organizationTypeMap[userOrgType] === 1 && {
          label: 'white-labeling.system-colors.archived-background',
          labelWidth: '280px',
          name: 'machineBuilderOrgArchivedColor',
          type: 'color',
          keyName: 'systemColors',
          onChanged: (field, value) => {
            if (
              displaySettingsData.systemColors?.content?.machineBuilderOrgBackgroundColor === value
            ) {
              modalService.openAlert({ text: 'white-labeling.system-colors.archived-message' });
            } else {
              onChangeData(field.keyName, field.name, value);
            }
          },
        },
      ]),
    },
    {
      label: 'white-labeling.system-colors.mbc-organizations',
      fields: [
        {
          label: 'white-labeling.system-colors.background',
          labelWidth: '280px',
          name: 'machineBuilderChannelOrgBackgroundColor',
          type: 'color',
          keyName: 'systemColors',
        },
        {
          label: 'white-labeling.system-colors.text',
          labelWidth: '280px',
          name: 'machineBuilderChannelOrgTextColor',
          type: 'color',
          keyName: 'systemColors',
        },
        {
          label: 'white-labeling.system-colors.archived-background',
          labelWidth: '280px',
          name: 'machineBuilderChannelOrgArchivedColor',
          type: 'color',
          keyName: 'systemColors',
          onChanged: (field, value) => {
            if (
              displaySettingsData.systemColors?.content?.machineBuilderChannelOrgBackgroundColor ===
              value
            ) {
              modalService.openAlert({ text: 'white-labeling.system-colors.archived-message' });
            } else {
              onChangeData(field.keyName, field.name, value);
            }
          },
        },
      ],
    },
    {
      label: 'white-labeling.system-colors.customer-organizations',
      fields: [
        {
          label: 'white-labeling.system-colors.background',
          labelWidth: '280px',
          name: 'customerOrgBackgroundColor',
          type: 'color',
          keyName: 'systemColors',
        },
        {
          label: 'white-labeling.system-colors.text',
          labelWidth: '280px',
          name: 'customerOrgTextColor',
          type: 'color',
          keyName: 'systemColors',
        },
        {
          label: 'white-labeling.system-colors.archived-background',
          labelWidth: '280px',
          name: 'customerOrgArchivedColor',
          type: 'color',
          keyName: 'systemColors',
          onChanged: (field, value) => {
            if (displaySettingsData.systemColors?.content?.customerOrgBackgroundColor === value) {
              modalService.openAlert({ text: 'white-labeling.system-colors.archived-message' });
            } else {
              onChangeData(field.keyName, field.name, value);
            }
          },
        },
      ],
    },
  ];

  const fieldsCfgConditionBuilder = [
    {
      label: 'white-labeling.system-colors.condition-builder-background',
      name: 'conditionBuilderBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.background-outline',
      name: 'conditionBuilderBackgroundOutline',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.rule-background',
      name: 'conditionBuilderRuleBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.rule-background-outline',
      name: 'conditionBuilderRuleBackgroundOutline',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.tree',
      name: 'conditionBuilderTree',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.text-font',
      name: 'conditionBuilderText',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.input-background',
      name: 'conditionBuilderInputBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.button-text',
      name: 'conditionBuilderButtonText',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.button-text-hover',
      name: 'conditionBuilderButtonTextHover',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.button-background',
      name: 'conditionBuilderButtonBackground',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.and-or-button-text-selected',
      name: 'conditionBuilderAndOrButtonTextSelected',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.and-or-button-background-selected',
      name: 'conditionBuilderAndOrButtonBackgroundSelected',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.not-button-text-selected',
      name: 'conditionBuilderNotButtonTextSelected',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.not-button-background-selected',
      name: 'conditionBuilderNotButtonBackgroundSelected',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.delete-button-text',
      name: 'conditionBuilderDeleteButtonText',
      type: 'color',
      keyName: 'systemColors',
    },
    {
      label: 'white-labeling.system-colors.delete-button-background',
      name: 'conditionBuilderDeleteButtonBackground',
      type: 'color',
      keyName: 'systemColors',
    },
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.item}>
        <I18n>white-labeling.system-colors.general</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgGeneric.map((field, idx) => (
              <ColorInput
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                disabled={!isPremium}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.system-colors.button</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgButtons.map((field, idx) => (
              <ColorInput
                disabled={!isPremium}
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
      <div className={classes.item}>
        <I18n>dashboard-editor-gallery.dashboard-gallery</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgDashboardGallery.map((field, idx) => (
              <ColorInput
                disabled={!isPremium}
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.system-colors.dashboard-editor</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgDashboardEditor.map((field, idx) => (
              <ColorInput
                disabled={!isPremium}
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.system-colors.organizations-tree</I18n>
        {!refreshColorPickers &&
          sectionsCfgOrgsTree.map((section, idx) => (
            <div key={`${idx}_${section.label}`} className={classes.fields}>
              <I18n>{section.label}</I18n>
              {section.fields.map((field, idx) => (
                <div key={`${idx}_${field.keyName}`} className={classes.fields}>
                  <ColorInput
                    disabled={!isPremium}
                    {...field}
                    disableScrollIntoView={true}
                    value={displaySettingsData[field.keyName]?.content[field.name]}
                    colorChange={(value) =>
                      field.onChanged
                        ? field.onChanged(field, value)
                        : onChangeData(field.keyName, field.name, value)
                    }
                    isDisplaySettings
                  />
                </div>
              ))}
            </div>
          ))}
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.system-colors.condition-builder</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgConditionBuilder.map((field, idx) => (
              <ColorInput
                disabled={!isPremium}
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default SystemColors;
