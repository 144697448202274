import React, { useEffect, useMemo } from 'react';
import * as cloneDeep from 'lodash/cloneDeep';
import styles from './Value.scss';
import I18n from '@components/I18n';
import { TextField } from '@material-ui/core';
import { ValueCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { getIsFloat, getTag, getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import MaterialCheckbox from '@components/Checkbox';
import ColorInput from '@components/ColorInput';
import classNames from 'classnames';
import Select from '@components/Select';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const Value = (props) => {
  const { widgetData, setWidgetData, defaultDecimalDigits } = props;
  const { customization, scope, calculations, eventTemplateId } = widgetData;
  const {
    symbol,
    showSymbolLeft,
    description,
    valueFontSize,
    symbolFontSize,
    descriptionFontSize,
    decimalDigits,
    valueColor = 'var(--widgetsFont)',
    symbolColor = 'var(--widgetsFont)',
    remarkColor = 'var(--widgetsFont)',
    dateTimeFormat,
  } = (customization as ValueCustomization) || {};

  const decimalPattern = /^[1-9][0-9]*(\.[0-9]{0,2})?$/i;
  const intPattern = /^\d+$/;
  const selectCustomStyle = { control: { height: 40 }, container: { width: '195px' } };

  const originalTag = useMemo(() => getTag(widgetData, widgetData.metrics[0]), [widgetData]);
  const format = originalTag.format || originalTag.type;
  const dateTimeOptions = useMemo(
    () =>
      scope !== 'AGGREGATED_DATA' &&
      scope !== 'AGGREGATED_LAST_VALUE' &&
      getDateTimeOptions(format),
    [format]
  );
  const showDecimalDigits = getIsFloat(
    widgetData.metrics[0].operation,
    widgetData.metrics[0].valueType,
    widgetData.metrics[0].type,
    calculations?.find(
      (calc) =>
        calc.valueType === widgetData.metrics[0].valueType &&
        calc.valueId === widgetData.metrics[0].valueId
    ),
    widgetData
  );

  useEffect(() => {
    const tempDateTimeFormat =
      format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null;
    if (!customization) {
      updateWidgetCustomization(
        {
          symbol: null,
          showSymbolLeft: false,
          description: null,
          valueFontSize: 35,
          symbolFontSize: 20,
          descriptionFontSize: 12,
          decimalDigits: defaultDecimalDigits,
          dateTimeFormat: tempDateTimeFormat,
        },
        setWidgetData
      );
    } else if (!customization.dateTimeFormat && format) {
      updateWidgetCustomization(
        {
          dateTimeFormat: tempDateTimeFormat,
        },
        setWidgetData
      );
    }
  }, []);

  const setCustomizationData = (data, key) => {
    let reg;
    switch (key) {
      case 'valueFontSize':
      case 'symbolFontSize':
      case 'descriptionFontSize':
        if (+data > 200) return;
        reg = decimalPattern;
        break;
      case 'decimalDigits':
        if (+data > 9) return;
        reg = intPattern;
        break;
    }
    if (reg && !reg.test(data) && data !== '') return;
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  const onBlurRequiredText = (e: any, defaultValue: number, key: string) => {
    if (e.target.value === '') {
      setCustomizationData(defaultValue, key);
    }
  };

  return (
    <div className={styles.widgetValue}>
      {!eventTemplateId && (
        <div className={styles.rowDetails}>
          <div className={styles.item}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.value-size
            </I18n>
            <TextField
              value={valueFontSize}
              margin="dense"
              variant="outlined"
              onChange={(e) => setCustomizationData(e.target.value, 'valueFontSize')}
              onBlur={(e) => onBlurRequiredText(e, 35, 'valueFontSize')}
              inputProps={{
                required: true,
                style: { color: 'var(--systemFont)' },
              }}
            />
          </div>
        </div>
      )}
      {!eventTemplateId && dateTimeOptions && (
        <div className={styles.rowDetails}>
          <div className={styles.item}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.date-time
            </I18n>
            <Select
              styles={selectCustomStyle}
              options={dateTimeOptions}
              value={dateTimeFormat && dateTimeOptions.find((opt) => opt.value === dateTimeFormat)}
              onChange={(option) => setCustomizationData(option.value, 'dateTimeFormat')}
            />
          </div>
        </div>
      )}
      {showDecimalDigits && (
        <div className={styles.rowDetails}>
          <div className={styles.item}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.decimal-digits
            </I18n>
            <TextField
              value={decimalDigits}
              margin="dense"
              variant="outlined"
              type="number"
              onChange={(e) => setCustomizationData(e.target.value, 'decimalDigits')}
              inputProps={{
                style: { color: 'var(--systemFont)' },
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.rowDetails}>
        <div className={styles.item}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.symbol</I18n>
          <TextField
            value={symbol || ''}
            margin="dense"
            variant="outlined"
            onChange={(e) => setCustomizationData(e.target.value, 'symbol')}
            inputProps={{
              maxLength: 10,
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
      {!eventTemplateId && (
        <div className={styles.rowDetails}>
          <div className={styles.item}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.symbol-size
            </I18n>
            <TextField
              value={symbolFontSize}
              margin="dense"
              variant="outlined"
              onChange={(e) => setCustomizationData(e.target.value, 'symbolFontSize')}
              onBlur={(e) => onBlurRequiredText(e, 20, 'symbolFontSize')}
              inputProps={{
                required: true,
                style: { color: 'var(--systemFont)' },
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.showSymbolLeft}>
        <MaterialCheckbox
          color="primary"
          onChange={(e) => setCustomizationData(!showSymbolLeft, 'showSymbolLeft')}
          checked={showSymbolLeft || false}
        />
        <I18n className={styles.showSymbolLeftText}>
          create-widget-page.create-widget.step-four.show-symbol-left
        </I18n>
      </div>
      {!eventTemplateId && (
        <>
          <div className={styles.description}>
            <I18n className={styles.descriptionText}>
              create-widget-page.create-widget.step-four.description
            </I18n>
            <TextField
              value={description || ''}
              margin="dense"
              fullWidth
              variant="outlined"
              onChange={(e) => setCustomizationData(e.target.value, 'description')}
              inputProps={{
                maxLength: 200,
                style: { color: 'var(--systemFont)' },
              }}
              rows={2}
              multiline
            />
          </div>
          <div className={styles.rowDetails}>
            <div className={styles.item}>
              <I18n className={styles.text}>
                create-widget-page.create-widget.step-four.text-size
              </I18n>
              <TextField
                value={descriptionFontSize}
                margin="dense"
                variant="outlined"
                onChange={(e) => setCustomizationData(e.target.value, 'descriptionFontSize')}
                onBlur={(e) => onBlurRequiredText(e, 12, 'descriptionFontSize')}
                inputProps={{
                  required: true,
                  style: { color: 'var(--systemFont)' },
                }}
              />
            </div>
          </div>
          <div className={styles.titleWrapper}>
            <I18n>create-widget-page.create-widget.step-four.colors</I18n>
          </div>
          <div className={classNames(styles.item, styles.colorItem)}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.value-color
            </I18n>
            <ColorInput
              value={valueColor}
              customeMarginButton={0}
              popOverPosition={{ left: 1 }}
              isParentRelative={false}
              colorChange={(value) => setCustomizationData(value, 'valueColor')}
            />
          </div>
          <div className={classNames(styles.item, styles.colorItem)}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.symbol-color
            </I18n>
            <ColorInput
              value={symbolColor}
              popOverPosition={{ left: 1 }}
              isParentRelative={false}
              customeMarginButton={0}
              colorChange={(value) => setCustomizationData(value, 'symbolColor')}
            />
          </div>
          <div className={classNames(styles.item, styles.colorItem)}>
            <I18n className={styles.text}>
              create-widget-page.create-widget.step-four.remark-color
            </I18n>
            <ColorInput
              value={remarkColor}
              popOverPosition={{ left: 1 }}
              isParentRelative={false}
              customeMarginButton={0}
              colorChange={(value) => setCustomizationData(value, 'remarkColor')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Value;
