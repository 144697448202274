import React, { useState, useEffect, useMemo } from 'react';
import Table from '@components/Table';
import { httpService } from '@http/HttpService';
import { plcService } from '@pages/DeviceManagment/PLC/PLCService';
import { useSelector } from '@redux/useSelector';
import PLCAssetConnected from './PLCAssetConnected';
import GetCertificate from './GetCertificateBTN';
import { PLCDataResponse, PLCTableProps } from './plcTable.interface';
import styles from './PLCTable.scss';
import RemoteAccessButton from '@components/RemoteAccessButton';
import { compact } from 'lodash';
import { getFlagStatus } from '@core/ffAndPermissions';
import PLCDisconnectBTN from './PLCDisconnectBTN';
import TextField from '@components/Table/TableFields/TextField';
import DateField from '@components/Table/TableFields/DateField';
import SubscriptionData from './SubscriptionData';
import { staticDictionary } from '@core/i18n/dictionary';
import { getOrganizationDetailsById } from './PLCTable.utils';

const getColunmsCfg = (flags) =>
  compact([
    {
      Component: PLCDisconnectBTN,
      type: 'staticStart',
      label: '',
      style: { width: 35 },
    },
    {
      Component: TextField,
      label: 'plc-tab.serial-number',
      field: 'serial',
      style: { width: '12%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'plc-tab.catalog-number',
      field: 'catalogNumber',
      style: { width: '10%' },
      isSortable: true,
    },
    {
      Component: DateField,
      label: 'plc-tab.date-created',
      field: 'createdAt',
      style: { width: '10%' },
      isSortable: true,
    },
    {
      Component: PLCAssetConnected,
      label: 'plc-tab.asset-connected-name',
      field: 'assetName',
      style: { width: '12%' },
      isSortable: true,
    },
    {
      Component: DateField,
      label: 'plc-tab.date-connected',
      field: 'connectedAt',
      style: { width: '10%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'plc-tab.router-serial',
      field: 'routerSerial',
      style: { width: '13%' },
      isSortable: true,
    },
    {
      Component: SubscriptionData,
      label: 'plc-tab.license-type',
      field: 'licenseType',
      style: { width: '11%' },
      isSortable: false,
    },
    {
      Component: SubscriptionData,
      label: 'plc-tab.subscription-status',
      field: 'subscriptionStatus',
      style: { width: '13%' },
      isSortable: false,
      isFilterable: false,
      filterType: 'checkbox',
      filterOptions: [
        { label: `enum.subscription.plc.ACTIVE`, value: 'ACTIVE' },
        { label: `enum.subscription.plc.ABOUT_TO_EXPIRE`, value: 'ABOUT_TO_EXPIRE' },
        { label: `enum.subscription.plc.EXPIRED_GRACE`, value: 'EXPIRED_GRACE' },
        { label: `enum.subscription.plc.EXPIRED`, value: 'EXPIRED' },
      ],
      filterAll: true,
    },
    {
      Component: SubscriptionData,
      label: 'plc-tab.end-date',
      field: 'subscriptionEndDate',
      style: { width: '9%' },
      isSortable: false,
    },
    {
      Component: GetCertificate,
      type: 'staticEnd',
      label: 'plc-tab.certificate',
      style: { width: 80 },
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'vpn',
      style: { width: 78 },
      props: {
        remoteAccessType: 'vpn',
        parentComponent: 'PLCTable',
      },
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'web',
      style: { width: 78 },
      props: {
        remoteAccessType: 'web',
        parentComponent: 'PLCTable',
      },
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'vnc',
      style: { width: 78 },
      props: {
        remoteAccessType: 'vnc',
        parentComponent: 'PLCTable',
      },
    },
  ]);

function PLCTable(props: PLCTableProps) {
  const plcData = useSelector((state) => state.plc.plcData);
  const refresh = useSelector((state) => state.plc.refresh);
  const selectedOrganization = useSelector((store) => store.organizations);
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );
  const colunmsCfg = useMemo(() => {
    return getColunmsCfg({ remoteAccess: getFlagStatus('plc.remote-access-buttons') });
  }, []);

  const pageSize = useMemo(() => 50, []);

  const [nextPage, setNextPage] = useState(1);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [order, setOrder] = useState({ label: 'createdAt', orderSign: '-' });
  const [filters, setFilters] = useState(null);
  const { searchText } = props;
  const defaultOrdering = { label: 'createdAt', orderSign: '-' };

  useEffect(() => getPLCs(true, order, filters), [searchText, selectedOrganizationsId]);

  useEffect(() => {
    if (plcData?.length) {
      const isNonDemoPlcExist = plcData.some((plc) => plc.subscription.licenceType !== 'Demo');
      if (
        (isNonDemoPlcExist && selectedOrganization.mfaNullAllowed) ||
        (!isNonDemoPlcExist && !selectedOrganization.mfaNullAllowed)
      ) {
        getOrganizationDetailsById(selectedOrganizationsId, selectedOrganization.selectedMBId);
      }
    }
  }, [plcData]);

  useEffect(() => {
    if (refresh) {
      getPLCs(true, order, filters);
    }
  }, [refresh]);

  const getPLCs = (firstInit = false, order, filters?) => {
    const filtersData = filters
      ? Object.keys(filters).reduce(
          (res, current) => ({
            ...res,
            [current === 'subscriptionStatus' ? 'status' : current]: `${filters[current].map(
              (id) => id
            )}`,
          }),
          {}
        )
      : {};
    setDisableLoad(true);
    httpService
      .api({
        type: 'getPLCs',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: pageSize,
          search: searchText ? searchText : null,
          ordering: order ? `${order.orderSign}${order.label}` : null,
          subs: !selectedOrganizationsId,
          ...filtersData,
        },
      })
      .then((res: PLCDataResponse) => {
        res.next && setDisableLoad(false);
        if (firstInit) {
          plcService.setPLCTableData({
            totalCount: res.allCount,
            resultsCount: res.count,
            plcData: res.results,
          });
          setNextPage(2);
        } else {
          plcService.addPLCTableData({
            totalCount: res.allCount,
            resultsCount: res.count,
            plcData: res.results,
          });
          setNextPage(nextPage + 1);
        }
      });
  };

  const getTableData = (firstInit, order, filters) => {
    order && setOrder(order);
    setFilters(filters);
    getPLCs(firstInit, order, filters);
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={plcData}
        getData={getTableData}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
        defaultOrdering={defaultOrdering}
      />
    </div>
  );
}

export default PLCTable;
