import React, { useMemo } from 'react';
import { colorMap } from '@core/statusColorMap';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import moment from 'moment';

function SubscriptionData(props) {
  const { field, subscription } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const value = useMemo(
    () =>
      subscription || field === 'subscriptionStatus'
        ? field === 'subscriptionStatus'
          ? i18nService.translate(
              `enum.subscription.plc.${subscription?.status || 'NOT_AVALIABLE'}`,
              languageId
            )
          : field === 'endDate' && subscription[field]
          ? moment(subscription[field], 'YYYY-MM-DD').format('DD/MM/YYYY')
          : subscription[field]
        : '',
    [subscription, field, languageId]
  );

  return (
    <span
      style={{
        color:
          field === 'subscriptionStatus' &&
          subscription &&
          subscription?.status !== 'ACTIVE' &&
          colorMap[subscription.status]
            ? colorMap[subscription.status]
            : 'var(--systemFont)',
      }}
      title={value}>
      {value}
    </span>
  );
}

export default SubscriptionData;
