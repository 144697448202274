import React, { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import Image from '@components/Image';
import TwoGroupByHeatmapWidget from './TwoGroupByHeatmapWidget';
import SingleGroupByHeatmapWidget from './SingleGroupByHeatmapWidget';
import { widgetMap } from '@core/canvas/widgetMap';
import {
  getDashboardFilters,
  getData,
  getPageSize,
  getRowSize,
} from './HeatmapWidgetGetData.utils';
import { httpService } from '@core/http/HttpService';
import { HeatmapWidgetProps } from './HeatmapWidget.interface';
import { usePrevious } from '@core/hooks/usePrevious';

function HeatmapWidget(props: HeatmapWidgetProps) {
  const {
    isPreview,
    widget,
    customization,
    dashboardFilters,
    widgetFilters,
    defaultDecimalDigits,
    navigateDashboard,
    applyButtonClicked,
    setApplyButtonClicked,
    userFilters,
  } = props;
  const prevDashboardFilters = usePrevious(dashboardFilters);
  const prevUserFilters = usePrevious(userFilters);
  const previewData = isPreview ? props.data : null;

  const [data, setData] = useState<any>();
  const [fetchedData, setFetchedData] = useState({});

  const [rowSize, setRowSize] = useState<number>(null);
  const [pageSize, setPageSize] = useState<number>(null);
  const [availableSeries, setAvailableSeries] = useState(null);

  const canFetchAvailableSeries =
    !isPreview &&
    !widget.isIdTemp &&
    widget.id &&
    (((!data || isEmpty(data)) && !isEqual(prevDashboardFilters, dashboardFilters)) ||
      (applyButtonClicked &&
        (!isEqual(prevDashboardFilters, dashboardFilters) ||
          !isEqual(prevUserFilters, userFilters))));

  const chartProps = {
    isPreview: isPreview,
    data: data,
    setData: setData,
    customization: customization,
    widget: widget,
    defaultDecimalDigits: defaultDecimalDigits,
    dashboardFilters: dashboardFilters,
    widgetFilters: widgetFilters,
    navigateDashboard: navigateDashboard,
    availableSeries: availableSeries,
    pageSize: pageSize,
    rowSize: rowSize,
    fetchedData: fetchedData,
    setFetchedData: setFetchedData,
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && isPreview) {
      setData(previewData);
    }

    return () => {
      isMounted = false;
    };
  }, [previewData]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setFetchedData({});
    }

    return () => {
      isMounted = false;
    };
  }, [dashboardFilters, widgetFilters]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && canFetchAvailableSeries) {
      httpService
        .api({
          type: 'getHeatmapSeries',
          urlParams: { widgetId: widget.id },
          data: { dashboardFilters: getDashboardFilters(dashboardFilters), widgetFilters },
        })
        .then((res: any) => {
          setAvailableSeries(res);
          setPageSize(getPageSize(res));
          setRowSize(getRowSize(res));
        });
    }
    return () => {
      isMounted = false;
    };
  }, [applyButtonClicked, userFilters, dashboardFilters]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !isPreview && rowSize && availableSeries && pageSize) {
      getData(
        widget.id,
        getDashboardFilters(dashboardFilters),
        widgetFilters,
        { p: 1, ps: pageSize },
        setData,
        availableSeries
      );
    }

    return () => {
      isMounted = false;
    };
  }, [rowSize, availableSeries, pageSize]);

  // PATCH TO FIX A LOOP IN CANVAS WHEN THE SERIES AND THE DATA ARE GETTING FETCHED ON MOUSE MOVE AFTER CHANGING FILTERS
  if (applyButtonClicked) {
    setTimeout(() => {
      setApplyButtonClicked(false);
    }, 3000);
  }
  // PATCH END

  return (
    <>
      {data && !isEmpty(data) ? (
        data?.groupByColumns?.length > 1 ? (
          <TwoGroupByHeatmapWidget {...chartProps} />
        ) : (
          <SingleGroupByHeatmapWidget {...chartProps} />
        )
      ) : (
        <div style={{ height: '100%' }}>
          <Image
            mode="smaller"
            src={widgetMap.heatmap.images.canvas}
            text="widget.widget-empty-label"
          />
        </div>
      )}
    </>
  );
}

export default HeatmapWidget;
