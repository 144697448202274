import React, { useEffect } from 'react';
import { TableCustomizationProps } from './TableCustomization.interface';
import { TableCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface.d';
import TableColorSettings from './TableColorSettings';
import TableConfigColumns from './TableConfigColumns';
import { cssVarsService } from '@core/CssVarsService';

function TableCustomization(props: TableCustomizationProps) {
  const { widgetData, setWidgetData, previewData, defaultDecimalDigits } = props;
  const { customization, eventTemplateId } = widgetData as any;

  const defaultColorsSettings = {
    headerBackgroundColor: cssVarsService.vars.widgetsBackground,
    headerFontColor: cssVarsService.vars.widgetsFont,
    lineFontColor: cssVarsService.vars.widgetsFont,
    dividerLineColor: cssVarsService.vars.dashboardBorder,
    lineBackgroundColor: cssVarsService.vars.widgetsBackground,
    altLineBackground: false,
    altLineBackgroundColor: '#bfbfbf',
    highlightedTableWidgetRow: false,
    highlightedTableWidgetRowColor: cssVarsService.vars.highlightedTableRow,
  };

  useEffect(() => {
    !customization &&
      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          ...defaultColorsSettings,
        },
      }));
  }, []);

  return (
    <>
      <TableConfigColumns
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        previewData={previewData}
        defaultDecimalDigits={defaultDecimalDigits}
      />
      <TableColorSettings
        setWidgetData={setWidgetData}
        customization={customization}
        eventTemplateId={eventTemplateId}
      />
    </>
  );
}

export default TableCustomization;
