import React from 'react';
import { withStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import ReactResizeDetector from 'react-resize-detector';
import { debounce, isEqual, differenceBy, compact, omit } from 'lodash';
import { LineWidgetProps } from './LineWidget.interface';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import Image from '@components/Image';
import { widgetMap } from '@core/canvas/widgetMap';
import { useGetWidgetData } from '@core/hooks/useGetWidgetData';
import { usePrevious } from '@core/hooks/usePrevious';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import {
  getLineOptions,
  getAreaOptions,
  getYaxisCustomization,
  getColumnName,
  getDataFromServer,
  getAssetsAndMetricsListFromServer,
  getSeriesWithThreshold,
} from './BrushLineWidget.utils';
import MultiSelect from '@components/MultiSelect';
import Select from '@components/Select';
import { dispatch, getState } from '@src/redux/store';
import {
  setLiveDashboardWidgetsData,
  updateLiveDashboardWidgetsData,
} from '@src/redux/liveDashboardWidgetsData';
import styles from './BrushLineWidget.scss';
import { connect } from 'react-redux';
import { dateTimeOptionsMap } from '../charts.utils';
import I18n from '@components/I18n';
import { getDefaultWidgetColorPalette } from '@core/canvas/widget.utils';
import moment from 'moment';
import * as momentTimezone from 'moment-timezone';

const style = {
  lineWrapper: {
    '& .apexcharts-marker': {
      cursor: (props) =>
        props.navigateDashboard && props.widget?.navigationDashboard?.length ? 'pointer' : 'auto',
      pointerEvents: (props) =>
        props.navigateDashboard && props.widget?.navigationDashboard?.length
          ? 'auto !important'
          : 'none !important',
    },
    '& .apexcharts-menu-item.exportCSV': {
      display: 'none',
    },
  },
};

const ListenerForUpdate = ({ widgetData, userFilters, getWidgetData }) => {
  // listening to changes on widgetData/navigationFilters
  const prevWidgetData: WidgetData = usePrevious(widgetData);
  useGetWidgetData({
    widgetData,
    prevWidgetData,
    dataChangesToHandle: ['metrics', 'groupBy', 'filters', 'localTags', 'calculations'],
    getWidgetData,
    userFilters,
  });

  return null;
};

class LineWidget extends React.Component<LineWidgetProps, any> {
  constructor(props) {
    super(props);

    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.setSelectedAssets = this.setSelectedAssets.bind(this);
    this.onAreaLegendClicked = this.onAreaLegendClicked.bind(this);
    this.onLineLegendClicked = this.onLineLegendClicked.bind(this);
    this.setChartData = this.setChartData.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.getAssetsList = this.getAssetsList.bind(this);
    this.onResize = this.onResize.bind(this);
    this.setSelectedMetrics = this.setSelectedMetrics.bind(this);
  }

  containerRef: any = React.createRef();
  lineRef: any = React.createRef();
  areaRef: any = React.createRef();
  updateAseets = false;
  state = {
    lineOptions: {},
    areaOptions: {} as any,
    lineSeries: [],
    areaSeries: [],
    firstEnter: true,
    assets: [],
    metrics: [],
    customizationMetrics: [],
    selectedAsset: [],
    selectedMetrics: [],
    lineRange: { minValue: undefined, maxValue: undefined },
    isPreview: false,
    areaCanDisplay: false,
    showAssetsSelection: false,
    reRender: false,
    showTagsSelection: false,
    assetsMultiSelect: false,
    tagsMultiSelect: false,
  };

  getFormattedMetrics = (metrics, customization) => {
    let res = [];
    let idx = 0;
    metrics?.forEach((m) => {
      if (m.operation) {
        res.push({
          value: {
            ...m,
            operation: m.operation,
          },
          label: customization?.columns && customization?.columns[idx]?.displayName,
          name: customization?.columns && customization?.columns[idx]?.name,
        });
        idx++;
      } else {
        res.push({
          value: {
            ...m,
          },
          label: customization?.columns && customization?.columns[idx]?.displayName,
          name: customization?.columns && customization?.columns[idx]?.name,
        });
        idx++;
      }
    });

    return res;
  };

  async getAssetsList() {
    try {
      const { widget, widgetData, widgetFilters, dashboardFilters, data, customization } =
        this.props;
      const widgetId = widget?.id;
      this.updateAseets = true;
      const res: any = await getAssetsAndMetricsListFromServer(
        widgetId,
        widgetData,
        widgetFilters,
        dashboardFilters
      );

      if (res) {
        const { assets, metrics } = res;

        const formattedAssets = assets
          .sort((a, b) =>
            a.asset_name.toLowerCase() < b.asset_name.toLowerCase()
              ? -1
              : a.asset_name.toLowerCase() > b.asset_name.toLowerCase()
              ? 1
              : a.id < b.id
              ? -1
              : 1
          )
          .map((x) => ({
            value: x.id,
            label: `${x.asset_name} - ${x.id}`,
          }));

        let formattedMetrics = this.getFormattedMetrics(metrics, customization).sort((a, b) =>
          !a.label
            ? -1
            : a.label.toLowerCase() < b.label.toLowerCase()
            ? -1
            : a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : a.value.valueId < b.value.valueId
            ? -1
            : 1
        );

        const reduxData = getState().liveDashboardWidgetsData[widgetId]?.selectedAsset;
        const reduxSelectedMetrics = getState().liveDashboardWidgetsData[widgetId]?.selectedMetrics;
        const reduxAssets = !differenceBy(reduxData, formattedAssets, 'value').length && reduxData;
        const reduxMetrics =
          !differenceBy(reduxSelectedMetrics, formattedMetrics, 'valueId').length &&
          reduxSelectedMetrics;

        const showAssetsSelection = !(
          widgetId &&
          (formattedMetrics.length === 1
            ? formattedAssets.length < 5
            : formattedAssets.length === 1)
        );

        const showTagsSelection =
          (customization.allowTagSelection &&
            formattedMetrics.length >= 2 &&
            formattedMetrics.length <= 4 &&
            formattedMetrics.length * formattedAssets.length > 4) ||
          formattedMetrics.length > 4;

        const selectedMetrics =
          widgetId && reduxMetrics ? reduxMetrics : formattedMetrics.slice(0, 4);

        const selectedAsset =
          widgetId && reduxAssets
            ? reduxAssets
            : selectedMetrics.length === 1
            ? formattedAssets.slice(0, 4)
            : [formattedAssets[0]];

        const assetsMultiSelect = selectedMetrics.length <= 2;
        const tagsMultiSelect = selectedAsset.length <= 2;

        !reduxAssets &&
          widgetId &&
          dispatch(setLiveDashboardWidgetsData({ [widgetId]: { selectedAsset } }));
        this.setState({
          assets: formattedAssets,
          metrics: formattedMetrics,
          selectedAsset,
          selectedMetrics,
          showAssetsSelection,
          showTagsSelection,
          assetsMultiSelect,
          tagsMultiSelect,
        });
      }
    } catch (e) {}
  }

  async getData(start = null, end = null, setAreaOptions = undefined) {
    try {
      const { widget, widgetData, widgetFilters, dashboardFilters, customization } = this.props;
      const assetIds = compact(this.state.selectedAsset.map((asset) => asset?.id || asset?.value));
      const metrics = this.state.selectedMetrics.map((m) => {
        return {
          ...m.value,
        };
      });

      const res = await getDataFromServer(
        widget?.id,
        widgetData,
        widgetFilters,
        dashboardFilters,
        start,
        end,
        assetIds,
        customization,
        metrics
      );
      if (res) {
        const { results, minTimestamp, maxTimestamp } = res;

        let series =
          results &&
          getSeriesWithThreshold(
            customization,
            results.map((ser) => {
              if (customization.colorPaletteMode === 'MANUAL') {
                return {
                  ...ser,
                  color: customization.columns.find(
                    (col) =>
                      col.id === ser.fieldId &&
                      col.valueType === ser.fieldType &&
                      col.operation === ser.fieldOperation
                  )?.color,
                };
              } else {
                return omit(ser, 'color');
              }
            }),
            widget || widgetData,
            this.props.data,
            this.state.metrics
          );

        this.setChartData(
          { series, minValue: start || minTimestamp, maxValue: end || maxTimestamp },
          !start && !end,
          setAreaOptions
        );
      }
    } catch (e) {
      console.log('getData error', e);
    }
  }

  componentDidMount() {
    const { widget } = this.props;
    (!widget || !widget.id) && this.setState((prevState) => ({ ...prevState, isPreview: true }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { customization, widget, widgetData } = this.props;
    const {
      selectedAsset,
      lineOptions,
      isPreview,
      areaOptions,
      areaSeries,
      lineRange,
      firstEnter,
      selectedMetrics,
    } = this.state;
    if (
      !isEqual(prevState.selectedAsset, selectedAsset) ||
      !isEqual(prevState.selectedMetrics, selectedMetrics) ||
      this.updateAseets
    ) {
      this.updateAseets = false;
      this.getData();
    }

    const selection = getState().liveDashboardWidgetsData[widget?.id]?.lineRange;
    if (firstEnter && areaSeries.length && widget?.id && selection) {
      this.setState({ firstEnter: false });
      if (selection && lineRange.minValue <= selection.min && lineRange.maxValue >= selection.max) {
        this.getData(selection.min, selection.max, true);
      }
    } else if (firstEnter && (!widget?.id || !selection)) {
      this.setState({ firstEnter: false });
    }
    if (!prevState.selectedAsset.length && selectedAsset.length) {
      setTimeout(() => {
        this.setState(() => ({ areaCanDisplay: true }));
      });
    } else if (prevState.selectedAsset.length && !selectedAsset.length) {
      this.setState({ areaCanDisplay: false });
    }
    if (isPreview && !isEqual(lineOptions, prevState.lineOptions)) {
      this.lineRef?.current?.chart?.updateOptions(lineOptions);
    }
    if (isPreview && !isEqual(areaOptions, prevState.areaOptions)) {
      this.areaRef?.current?.chart?.updateOptions(areaOptions);
    }
    if (!isEqual(prevProps.customization, customization)) {
      const { lineSeries } = this.state;

      let lineSeriesWithThreshold =
        (lineSeries?.length &&
          getSeriesWithThreshold(
            customization,
            lineSeries
              .map((ser) => {
                if (customization.colorPaletteMode === 'MANUAL') {
                  return {
                    ...ser,
                    color: customization.columns.find(
                      (col) =>
                        col.id === ser.fieldId &&
                        col.valueType === ser.fieldType &&
                        col.operation === ser.fieldOperation
                    )?.color,
                  };
                } else {
                  return omit(ser, 'color');
                }
              })
              .filter((ser) => ser.fieldType !== 'THRESHOLD'),
            widget || widgetData,
            this.props.data,
            this.state.metrics
          )) ||
        [];

      this.setChartData(
        {
          series: lineSeriesWithThreshold.map((data) =>
            getColumnName(data, this.props.customization, this.props.data, this.state.metrics)
          ),
          ...lineRange,
        },
        false,
        prevProps.customization,
        true
      );
    }
  }

  setSelectedAssets(value) {
    const selectedAsset = Array.isArray(value) ? value : [value];
    const tagsMultiSelect = selectedAsset.length <= 2;
    const { widget } = this.props;
    if (!Array.isArray(value) || value.length < selectedAsset.length || value.length <= 4) {
      this.setState({ selectedAsset, tagsMultiSelect }, () => {
        widget &&
          widget.id &&
          dispatch(
            setLiveDashboardWidgetsData({
              [widget.id]: { selectedAsset, selectedMetrics: this.state.selectedMetrics },
            })
          );
      });
    }
  }

  setSelectedMetrics(value) {
    const selectedMetrics = Array.isArray(value) ? value : [value];
    const assetsMultiSelect = selectedMetrics.length <= 2;
    const { widget } = this.props;
    if (!Array.isArray(value) || value.length < selectedMetrics.length || value.length <= 4) {
      this.setState({ selectedMetrics, assetsMultiSelect }, () => {
        widget &&
          widget.id &&
          dispatch(
            setLiveDashboardWidgetsData({
              [widget.id]: { selectedMetrics, selectedAsset: this.state.selectedAsset },
            })
          );
      });
    }
  }

  private selectionChangeDebounce;
  onSelectionChange(chartContext, { xaxis, yaxis }) {
    clearTimeout(this.selectionChangeDebounce);

    this.selectionChangeDebounce = setTimeout(() => {
      const { min, max } = xaxis;
      if (min !== this.state.lineRange.minValue || max !== this.state.lineRange.maxValue) {
        this.props.widget?.id &&
          dispatch(
            updateLiveDashboardWidgetsData({
              id: this.props.widget?.id,
              data: { lineRange: { min, max } },
            })
          );
        this.getData(min, max);
      }
    }, 500);
  }

  onMarkerClick = (event, chartContext, config) => {
    const { lineSeries } = this.state;
    const { navigateDashboard, widget } = this.props;
    // Extracting the selected data
    const dataPointIndex = config.dataPointIndex;
    const seriesIndex = config.seriesIndex;
    const selectedValue =
      seriesIndex >= 0 && dataPointIndex >= 0
        ? lineSeries[seriesIndex].data[dataPointIndex][1]
        : null;
    const selectedSeries = seriesIndex >= 0 ? lineSeries[seriesIndex] : null;

    navigateDashboard &&
      widget.navigationDashboard &&
      navigationDataBuilder({
        selectedValue,
        selectedColumn: selectedSeries,
        widget,
        columns: null,
        rawValues: { asset_id: selectedSeries.assetId },
        navigateDashboard,
      });
  };

  getLineColorsBySeries(series, customizationColumns) {
    return customizationColumns
      ?.filter((col) =>
        series?.some(
          (ser) =>
            ser.fieldId === col.id &&
            ser.fieldType === col.valueType &&
            ser.fieldOperation === col.operation
        )
      )
      ?.map((c) => c.color);
  }

  setChartData(data, setAreaData, prevCustomization, setAreaOptions = false) {
    const { navigateDashboard, widget, widgetData, dateTime, defaultDecimalDigits } = this.props;
    let customization = this.props.customization;

    if (!customization.colorPalette) {
      let palette = customization.columns
        ? this.getLineColorsBySeries(
            data?.series?.filter((ser) => ser.fieldType !== 'THRESHOLD'),
            customization?.columns
          )
        : getDefaultWidgetColorPalette();

      if (palette?.length < 4 && customization.colorPaletteMode !== 'MANUAL') {
        palette = palette.concat(getDefaultWidgetColorPalette().slice(palette.length, 4));
      }

      customization = {
        ...customization,
        colorPalette: palette,
      };
    }

    if (customization.colorPaletteMode === 'MANUAL') {
      customization = {
        ...customization,
        colorPalette: this.getLineColorsBySeries(
          data?.series?.filter((ser) => ser.fieldType !== 'THRESHOLD'),
          customization?.columns
        ),
      };
    }

    if (
      this.props.data &&
      this.props.data.columns &&
      this.props.data.columns.length === 4 &&
      customization.columns &&
      customization.columns[0] &&
      customization.columns[0]?.name?.startsWith(
        'create-widget-page.create-widget.step-four.line.default-name'
      )
    ) {
      customization = {
        ...customization,
        columns: [
          {
            ...customization.columns[0],
            name: this.props.data.columns[3].name,
            displayName:
              this.props.data.columns[3].name > 25
                ? this.props.data.columns[3].name.slice(0, 25)
                : this.props.data.columns[3].name,
          },
        ],
      };
    }

    if (
      this.props.data &&
      this.props.data.columns &&
      this.props.data.columns.length === 4 &&
      customization.yAxes &&
      customization.yAxes[0] &&
      customization.yAxes[0]?.name?.startsWith(
        'create-widget-page.create-widget.step-four.line.default-name'
      )
    ) {
      customization = {
        ...customization,
        yAxes: [
          {
            ...customization.yAxes[0],
            name: this.props.data.columns[3].name,
            displayName:
              this.props.data.columns[3].name > 25
                ? this.props.data.columns[3].name.slice(0, 25)
                : this.props.data.columns[3].name,
          },
        ],
      };
    }

    const { series: tempSeries = [], minValue, maxValue } = data;
    const { yAxes, columns, legend, brush_display } = customization;
    const colorPalette =
      customization.colorPaletteMode === 'MANUAL'
        ? this.getLineColorsBySeries(data?.series, customization?.columns)
        : customization.colorPalette;

    const tagsData = Array.isArray(widgetData?.tags)
      ? [
          ...widgetData?.tags,
          ...widgetData?.tagTypes,
          ...widgetData?.variables,
          ...widgetData?.localTags,
        ]
      : [];

    const areaSeries = setAreaData
      ? tempSeries
      : this.state.areaSeries.map((data) =>
          getColumnName(data, customization, this.props.data, this.state.metrics, tagsData)
        );
    let series = !setAreaData
      ? areaSeries.map(
          (data) =>
            tempSeries.find(
              (s) =>
                s.fieldId === data.fieldId &&
                s.fieldOperation === data.fieldOperation &&
                s.assetId === data.assetId
            ) || {
              ...data,
              data: [],
            }
        )
      : tempSeries;

    series = getSeriesWithThreshold(
      customization,
      series.filter((ser) => ser.fieldType !== 'THRESHOLD'),
      widget || widgetData,
      this.props.data,
      this.state.metrics
    );

    let extendedYAxes = yAxes?.map((y) => ({
      ...y,
      fieldId: this.state.metrics.find((m) => m.valueId === y.id)?.value?.valueId,
    }));

    const yaxisCustomization = getYaxisCustomization(
      extendedYAxes,
      columns,
      series,
      defaultDecimalDigits
    );

    const yaxisCustomizationArea =
      (setAreaData || setAreaOptions) &&
      getYaxisCustomization(extendedYAxes, columns, series, true);

    const thresholdColorPalette =
      customization?.threshold?.filter((t) => t.show).map((th) => th.color) || [];

    const linesColors =
      colorPalette && (colorPalette.length >= 4 || customization.colorPaletteMode === 'MANUAL')
        ? [...thresholdColorPalette, ...colorPalette]
        : getDefaultWidgetColorPalette();

    const lineLegendPosition =
      (legend?.position && legend?.position.toLowerCase() !== 'bottom') ||
      !brush_display ||
      (widgetData?.scope && widgetData?.scope !== 'RAW_DATA') ||
      widget?.scope !== 'RAW_DATA';

    const visibleThresholdLines = customization.threshold?.filter((t) => t.show);

    const lineOptions = getLineOptions(
      customization,
      yaxisCustomization,
      linesColors,
      this.onLineLegendClicked,
      lineLegendPosition,
      this.onMarkerClick,
      series.filter(
        (ser) =>
          ser.fieldType !== 'THRESHOLD' ||
          visibleThresholdLines.some((vtl) => ser.fieldId === vtl.id)
      ),
      defaultDecimalDigits
    );

    const areaOptions =
      setAreaData || setAreaOptions
        ? getAreaOptions(
            customization,
            yaxisCustomizationArea,
            linesColors,
            minValue,
            maxValue,
            this.onSelectionChange,
            this.onAreaLegendClicked,
            lineLegendPosition,
            dateTime,
            series.filter(
              (ser) =>
                ser.fieldType !== 'THRESHOLD' ||
                visibleThresholdLines.some((vtl) => ser.fieldId === vtl.id)
            )
          )
        : this.state.areaOptions;

    let lineSeries =
      series?.length &&
      getSeriesWithThreshold(
        customization,
        series.filter((ser) => ser.fieldType !== 'THRESHOLD'),
        widget || widgetData,
        this.props.data,
        this.state.metrics
      );

    this.setState({
      lineOptions,
      lineSeries,
      areaOptions,
      areaSeries,
      lineRange: { minValue, maxValue },
    });

    if (
      (prevCustomization?.colorPaletteMode === 'MANUAL' &&
        customization?.colorPaletteMode === 'AUTO') ||
      (customization?.colorPaletteMode === 'AUTO' &&
        customization?.threshold?.filter((t) => !t.show)?.length >
          prevCustomization?.threshold?.filter((t) => !t.show)?.length)
    ) {
      this.setState({ reRender: true });
      setTimeout(() => this.setState({ reRender: false }));
    }
  }

  onAreaLegendClicked(chartContext, seriesIndex, config) {
    this.lineRef?.current?.chart.toggleSeries(this.state.lineSeries[seriesIndex].name);
  }

  onLineLegendClicked(chartContext, seriesIndex, config) {
    this.areaRef?.current?.chart.toggleSeries(this.state.areaSeries[seriesIndex].name);
  }

  onResize() {
    this.setState({ reRender: true });
    setTimeout(() => this.setState({ reRender: false }));
  }

  isAssetOptionDisabled(option) {
    return (
      ((this.state.selectedAsset.length == 2 && this.state.selectedMetrics.length == 2) ||
        this.state.selectedMetrics.length == 4) &&
      this.state.selectedAsset.length * this.state.selectedMetrics.length == 4 &&
      !this.state.selectedAsset.find((asset) => asset.value === option.value)
    );
  }

  isTagOptionDisabled(option) {
    return (
      ((this.state.selectedAsset.length == 2 && this.state.selectedMetrics.length == 2) ||
        this.state.selectedMetrics.length == 4) &&
      this.state.selectedAsset.length * this.state.selectedMetrics.length == 4 &&
      !this.state.selectedMetrics.find(
        (tag) =>
          tag.value.valueId === option.value.valueId &&
          tag.value.valueType === option.value.valueType
      )
    );
  }

  render() {
    const { customization, userFilters, widgetData, widget, classes, dateTime, timezone } =
      this.props;
    const { dateFormat = 'DATE', timeFormat = 'TIME_FORMAT_ONE' } = customization;
    const {
      lineOptions,
      lineSeries,
      areaOptions,
      areaSeries,
      lineRange,
      assets,
      selectedAsset,
      selectedMetrics,
      isPreview,
      areaCanDisplay,
      showAssetsSelection,
      firstEnter,
      reRender,
      metrics,
      showTagsSelection,
      assetsMultiSelect,
      tagsMultiSelect,
    } = this.state;
    const lineLegendPosition =
      (customization.legend?.position &&
        customization.legend?.position.toLowerCase() !== 'bottom') ||
      !customization.brush_display ||
      (widgetData?.scope && widgetData?.scope !== 'RAW_DATA') ||
      widget?.scope !== 'RAW_DATA';

    const isPdfRender = getState().viewport?.isPdfRender;

    let sizeToReduce = isPreview || !showAssetsSelection || !assets.length ? 0 : 58;

    if (!isPreview && showTagsSelection && metrics.length) sizeToReduce += 68;

    return (
      <div ref={this.containerRef} style={{ height: '100%', padding: isPreview ? 5 : 20 }}>
        <ReactResizeDetector handleHeight onResize={this.onResize} />
        <div
          style={{
            height: isPreview || sizeToReduce === 0 ? '100%' : `calc(100% - ${sizeToReduce}px)`,
            display: 'flex',
            flexDirection: 'column',
          }}>
          {assets && assets.length && !selectedAsset.length ? (
            <div style={{ height: '100%' }}>
              <Image
                mode="smaller"
                src={widgetMap.line.images.canvas}
                text="widgets.line-empty-state-text"
              />
            </div>
          ) : metrics && metrics.length && !selectedMetrics.length ? (
            <div style={{ height: '100%' }}>
              <Image
                mode="smaller"
                src={widgetMap.line.images.canvas}
                text="widgets.line-empty-state-text.no-tag-selected"
              />
            </div>
          ) : areaSeries.length && !this.props.apexLangChange && !reRender && !firstEnter ? (
            <>
              <div style={{ flex: 1 }}>
                <div
                  style={{ height: isPreview ? (lineLegendPosition ? '75%' : '65%') : '100%' }}
                  id={styles.lineChart}
                  className={classes.lineWrapper}>
                  <ReactApexChart
                    ref={this.lineRef}
                    options={{
                      xaxis: {
                        type: 'datetime',
                        min:
                          lineRange.minValue === lineRange.maxValue
                            ? lineRange.minValue - 172800000 // UC-4423, workaround for an Apex bug
                            : lineRange.minValue,
                        max:
                          lineRange.minValue === lineRange.maxValue
                            ? lineRange.maxValue + 172800000 // UC-4423, workaround for an Apex bug
                            : lineRange.maxValue,
                        labels: {
                          datetimeUTC: widget?.scope == 'AGGREGATED_DATA' ? true : false, // UC-4423, workaround for an Apex bug
                          style: {
                            colors: 'var(--widgetsFont)',
                          },
                          datetimeFormatter: {
                            year: dateTime[dateTimeOptionsMap[dateFormat]].format,
                            month: dateTime[dateTimeOptionsMap[dateFormat]].format,
                            day: dateTime[dateTimeOptionsMap[dateFormat]].format,
                            hour: dateTime[dateTimeOptionsMap[timeFormat]].format,
                          },
                          formatter:
                            isPdfRender && widget?.scope === 'RAW_DATA'
                              ? (timestamp) => {
                                  return momentTimezone(moment(new Date(timestamp)))
                                    .tz(timezone)
                                    .format('ll HH:mm');
                                }
                              : undefined,
                        },
                        tooltip: {
                          enabled: false,
                        },
                      },
                      ...lineOptions,
                    }}
                    series={lineSeries}
                    height={'100%'}
                    type="line"
                  />
                </div>
              </div>

              {(customization.brush_display || widgetData?.scope === 'RAW_DATA') &&
                areaCanDisplay &&
                (this.containerRef.current.clientHeight >= 304 || isPreview) && (
                  <div
                    style={{
                      minHeight: isPreview ? 0 : 130,
                      height: lineLegendPosition ? '25%' : '35%',
                    }}>
                    <ReactApexChart
                      ref={this.areaRef}
                      options={
                        isPdfRender
                          ? {
                              ...areaOptions,
                              xaxis: {
                                ...areaOptions.xaxis,
                                labels: {
                                  ...areaOptions.xaxis.labels,
                                  formatter: (timestamp) => {
                                    return momentTimezone(moment(new Date(timestamp)))
                                      .tz(timezone)
                                      .format('ll HH:mm');
                                  },
                                },
                              },
                            }
                          : areaOptions
                      }
                      series={areaSeries}
                      type="area"
                      height={'100%'}
                    />
                  </div>
                )}
            </>
          ) : (
            <div style={{ height: '100%' }}>
              <Image
                mode="smaller"
                src={widgetMap.line.images.canvas}
                text="widget.widget-empty-label"
              />
            </div>
          )}
        </div>
        {isPreview || !showAssetsSelection || !assets.length ? null : (
          <div style={{ height: 58, marginBottom: '20px' }}>
            <I18n className={styles.fieldLabel}>widgets.line.select-assets</I18n>
            {assetsMultiSelect ? (
              <div className={styles.select}>
                <MultiSelect
                  id="assetsMultiSelect"
                  isOptionDisabled={(option) => this.isAssetOptionDisabled(option)}
                  options={assets}
                  values={selectedAsset}
                  onChange={debounce(this.setSelectedAssets, 500)}
                  optionType="checkbox"
                  placeholder={
                    selectedMetrics.length <= 1
                      ? 'widgets.line-empty-state-multi-select-placeholder'
                      : 'widgets.line-empty-state-multi-select-placeholder.up-to-2-assets'
                  }
                />
              </div>
            ) : (
              <Select
                value={selectedAsset[0]}
                options={assets}
                styles={{ control: { height: 38 }, menuPortal: { zIndex: 100 } }}
                menuPortalTarget={document.body}
                onChange={this.setSelectedAssets}
                placeholder="widgets.line-empty-state-select-placeholder"
              />
            )}
          </div>
        )}

        {isPreview || !showTagsSelection || !metrics.length ? null : (
          <div style={{ height: 58 }}>
            <I18n className={styles.fieldLabel}>widgets.line.select-tags</I18n>
            {tagsMultiSelect ? (
              <div className={styles.select}>
                <MultiSelect
                  id="tagsMultiSelect"
                  isOptionDisabled={(option) => this.isTagOptionDisabled(option)}
                  options={metrics}
                  values={selectedMetrics}
                  onChange={debounce(this.setSelectedMetrics, 500)}
                  optionType="checkbox"
                  placeholder={
                    selectedAsset.length <= 1
                      ? 'widgets.line-empty-state-multi-select-placeholder.up-to-4-tags'
                      : 'widgets.line-empty-state-multi-select-placeholder.up-to-2-tags'
                  }
                />
              </div>
            ) : (
              <Select
                value={selectedMetrics[0]}
                options={metrics}
                styles={{ control: { height: 38 }, menuPortal: { zIndex: 100 } }}
                menuPortalTarget={document.body}
                onChange={this.setSelectedMetrics}
                placeholder="widgets.line-empty-state-select-placeholder.tag-to-show"
              />
            )}
          </div>
        )}

        <ListenerForUpdate
          getWidgetData={this.getAssetsList}
          userFilters={userFilters}
          widgetData={widgetData}
        />
      </div>
    );
  }
}

export default withStyles(style as any)(
  connect(
    (state: any) => ({
      apexLangChange: state.config.apexLangChange,
      dateTime: state.config?.whiteLabelDetails?.dateTime?.content,
    }),
    null
  )(LineWidget)
);
