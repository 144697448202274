import React, { useMemo, useEffect, useState, useCallback } from 'react';
import I18n from '@components/I18n';
import { modalService } from '@core/modals/ModalService';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import styles from './AugmentedRealityGalleryPage.scss';
import { compact } from 'lodash';
import {
  getButtonsCfg,
  getAllARProjects,
  getTrainingBadge,
} from './AugmentedRealityGalleryPage.utils';
import GalleryThumbnailContainer from '@components/GalleryThumbnail';
import { GalleryThumbnailInfo } from '@components/GalleryThumbnail/GalleryThumbnailContainer.interface';
import moment from 'moment';
import TableEmptyState from '@components/Table/TableEmptyState';
import { getState } from '@src/redux/store';
import { getPermissionStatus } from '@core/ffAndPermissions';

function AugmentedRealityGalleryPage() {
  const [sortedArProjects, setSortedArProjects] = useState([]);
  const isDimensionsStatic = useMemo(
    () => sortedArProjects && sortedArProjects.length <= 3,
    [sortedArProjects]
  );

  const createNewARProject = () => {
    const hasPermission = getState()?.login?.isLoggedIn && getPermissionStatus('AR', 'EDIT');
    if (hasPermission) {
      modalService.openModal('newARProject', {}, { disableBackdropClick: true }).then((res) => {});
    }
  };

  const toolbarCfg: ToolbarCfg = {
    style: {
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'center',
      margin: '0 15px 0 5px',
      padding: 0,
      border: 'none',
    },
    buttons: compact([
      {
        type: 'create',
        text: 'general.add',
        onClick: createNewARProject,
      },
    ]),
  };

  useEffect(() => {
    getAllARProjects(setSortedArProjects);
  }, []);

  function getInfoBarData(status, trainingData): GalleryThumbnailInfo {
    const end = moment(trainingData.etaTime);
    const utcDateNow = new Date().toUTCString();
    const momentUtcDateNow = moment(utcDateNow);
    const durationHours = moment.utc(end.diff(momentUtcDateNow)).hours();
    const durationMinutes = moment.utc(end.diff(momentUtcDateNow)).minutes();
    const isBeforeDate = end.isBefore(momentUtcDateNow);
    const isSameDate = end.isSame(momentUtcDateNow);

    return {
      text: trainingData.etaTime
        ? isBeforeDate || isSameDate
          ? 'augmented-reality.gallery.training-progress.almost-done'
          : 'augmented-reality.gallery.training-progress'
        : 'augmented-reality.gallery.training-progress.calculating',
      backgroundColor: 'var(--systemDraftBackground)',
      textColor: 'var(--systemFont)',
      style: status === 'combined' ? { padding: '1px 6px 6px 6px' } : undefined,
      restProps: end
        ? {
            remainingTime:
              durationHours && durationMinutes
                ? `${durationHours}h ${durationMinutes}m`
                : durationHours
                ? `${durationHours}h`
                : `${durationMinutes}m`,
          }
        : undefined,
    };
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} style={{ display: 'flex' }}>
        <I18n className={styles.title}>augmented-reality.gallery-projects-header</I18n>
        <div style={{ display: 'flex', padding: 0, border: 'none' }}>
          <ToolBar toolbarCfg={toolbarCfg} />
        </div>
      </div>
      {!sortedArProjects.length && (
        <TableEmptyState emptyStateMessage={'augmented-reality.gallery-projects.empty'} />
      )}
      <div className={styles[`thumbnailListGrid${isDimensionsStatic ? 'Static' : ''}`]}>
        {sortedArProjects.map((project, idx) => (
          <GalleryThumbnailContainer
            key={idx}
            galleryItem={project}
            getButtonsCfg={getButtonsCfg}
            setGalleryData={setSortedArProjects}
            badge={getTrainingBadge(project.training?.status)}
            infoBar={
              project.training?.status === 'IN_PROGRESS'
                ? getInfoBarData(project.status, project.training)
                : null
            }
          />
        ))}
      </div>
    </div>
  );
}

export default AugmentedRealityGalleryPage;
