import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { useSelector } from '@redux/useSelector';
import AssetTypesTable from './AssetTypesTable';
import styles from './AssetTypesTab.scss';

function AssetTypesTab() {
  const [searchText, setSearchText] = useState(null);
  const totalCount = useSelector(state => state.assetType.totalCount);
  const resultsCount = useSelector(state => state.assetType.resultsCount);

  const search = useCallback(searchText => setSearchText(searchText), []);
  const toolbarCfg: ToolbarCfg = useMemo(() => {
    return {
      search: {
        searchFunc: search,
        pageName: 'asset types'
      }
    };
  }, []);

  return (
    <div className={classNames('max-height', styles.assetTypesTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <AssetTypesTable searchText={searchText}></AssetTypesTable>
    </div>
  );
}

export default AssetTypesTab;
