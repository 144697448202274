import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import styles from './SidePanelToolbar.scss';
import classNames from 'classnames';
import Button from '@components/Button';
import AssetPropertiesInput from '../../CreateWidgetPage/DataStepComponent/StepOne/AssetPropertiesInput';
import VariablesInput from '../../CreateWidgetPage/DataStepComponent/StepOne/VariablesInput';
import AssetTagsInput from '../../CreateWidgetPage/DataStepComponent/StepOne/AssetTagsInput';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import {
  createUpdateArWidget,
  updateArWidgetState,
  isDataFormCompleted,
  getWidgetDataStatus,
  getMetricsFromWidgetData,
} from '../AugmentedRealityEditorPage.utils';
import ValueCustomizationPanel from './ValueCustomizationPanel';
import ImageByValueCustomizationPanel from './ImageByValueCustomizationPanel';
import MeasurementsPanel from './MeasurementsPanel';
import ArWidgetsPanel from './ArWidgetsPanel';
import MuiExpandMoreIcon from '@components/MuiExpandMoreIcon';
import { i18nService } from '@core/i18n/I18nService';
import { alphanumericSort, dynamicSort, statusToNumberConverter } from '@core/utils';
import { modalService } from '@core/modals/ModalService';

const useStyles = makeStyles((theme: any) => ({
  panel: {
    marginBottom: 13,
    boxShadow: theme.app.boxShadowGrey,
    borderRadius: 8,
    backgroundColor: 'var(--systemContentBackground)',
    '&:before': {
      height: 0,
    },
  },
  panelRounded: {
    height: 78,
  },
  panelDetails: {
    width: '100%',
  },
  expandIcon: {
    color: 'var(--systemFont)',
  },
}));

const SidePanelToolbar = (props) => {
  const classes = useStyles(props);
  const {
    projectData,
    widgetData,
    setWidgetData,
    selectedWidgetId,
    handleWidgetRowClick,
    widgetList,
    setWidgetList,
    onFinishWidgetEditing,
  } = props;
  const assetTypes = [{ id: projectData.assetTypeId }];
  const { assetProperties, tags, variables, type, eventTemplateId } = widgetData;
  const MAX_DATA_TAGS = getConfigValueFromWidgetSettings(widgetData.type, 'maxDataTags');
  const [totalDataTags] = useState(0);
  const isImageByValueType = widgetData.type === 'image_by_value';
  const [measurementsError, setMeasurementsError] = useState(null);

  const handleFinishClick = async () => {
    const updatedWidgetData = { ...widgetData };
    updatedWidgetData.status = getWidgetDataStatus(updatedWidgetData);
    const updatedMetrics = getMetricsFromWidgetData(updatedWidgetData);
    updatedWidgetData.metrics = updatedMetrics ? updatedMetrics : [];
    createUpdateArWidget(projectData, updatedWidgetData, setWidgetList);
    onFinishWidgetEditing(updatedWidgetData);
  };

  const handleCustomizationFormChanges = async (newWidgetData) => {
    const updatedWidgetData = { ...newWidgetData };
    updatedWidgetData.status = getWidgetDataStatus(updatedWidgetData);
    createUpdateArWidget(projectData, updatedWidgetData);
  };

  const handleMeasurementsFormChanges = async (newWidgetData) => {
    updateArWidgetState(projectData, newWidgetData);
  };

  function onSelectedChange(property, newValue) {
    const updatedWidgetData = { ...widgetData };
    updatedWidgetData[property] = newValue;
    const updatedMetrics = getMetricsFromWidgetData(updatedWidgetData);

    const updateData = () => {
      updatedWidgetData.metrics = updatedMetrics || [];
      setWidgetData(updatedWidgetData);
      handleCustomizationFormChanges(updatedWidgetData);
    };

    if (isImageByValueType) {
      if (widgetData.metrics?.length) {
        openConfirmModal(() => {
          updatedWidgetData.customization.conditions = [];
          updatedWidgetData.customization.errors = {};
          updateData();
        });
      } else {
        updatedWidgetData.customization.conditions = [];
        updatedWidgetData.customization.errors = {};
        updateData();
      }
    } else {
      updateData();
    }
  }

  const openConfirmModal = (confirmCallback) => {
    modalService
      .openModal('confirm', {
        iconType: 'attention_image',
        text: 'create-widget-page.create-widget.step-two.change-initialize',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'create-widget-page.create-widget.confirm-widget-changes.header',
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          confirmCallback();
        }
        return confirm;
      });
  };

  if (selectedWidgetId == null) {
    return (
      <div className={styles.sidePanel}>
        <ArWidgetsPanel
          onClick={handleWidgetRowClick}
          widgetList={alphanumericSort(widgetList, 'name', '+')
            .map((widget) => {
              return {
                ...widget,
                typeToDisplay: i18nService.translate(
                  `edit-dashboard.widget-name.${widget.type.toLowerCase()}`
                ),
              };
            })
            .sort(dynamicSort('status', '+', statusToNumberConverter))}
        />
      </div>
    );
  }

  return (
    <div className={styles.sidePanel}>
      <Accordion className={classNames(classes.panel, styles.panelWrapper)} defaultExpanded={true}>
        <AccordionSummary className={classes.panelRounded} expandIcon={<MuiExpandMoreIcon />}>
          <I18n className={styles.sectionTitle}>
            augmented-reailty.widget.side-panel.data-header
          </I18n>
          <div className={styles.stepIndicator}>
            <div
              className={`${styles.statusIndicator} ${
                isDataFormCompleted(widgetData, 'data') ? styles.finished : styles.unfinished
              }`}></div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.panelDetails}>
            <div className={styles.fieldWrapper}>
              <VariablesInput
                isMulti={false}
                isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
                widgetType={type}
                assetTypes={assetTypes}
                variables={variables}
                tags={tags}
                eventTemplateId={eventTemplateId}
                onSelectedVariables={(newValue) => onSelectedChange('variables', newValue)}
                additionalStyles={styles}
                isDisabled={widgetData.tags.length || widgetData.assetProperties.length}
                filteredOptionFormats={
                  isImageByValueType ? ['numberType', 'floatType', 'booleanType'] : []
                }
              />
            </div>
            <div className={styles.fieldWrapper}>
              <AssetPropertiesInput
                isMulti={false}
                assetProperties={assetProperties}
                widgetType={type}
                widgetData={widgetData}
                isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
                onSelectedAssetProperties={(newValue) =>
                  onSelectedChange('assetProperties', newValue)
                }
                additionalStyles={styles}
                isDisabled={
                  widgetData.tags.length || widgetData.variables.length || isImageByValueType
                }
              />
            </div>
            <div className={styles.fieldWrapper}>
              <AssetTagsInput
                isMulti={false}
                isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
                widgetType={type}
                assetTypes={assetTypes}
                tags={tags}
                variables={variables}
                onSelectedTags={(newValue) => onSelectedChange('tags', newValue)}
                eventTemplateId={eventTemplateId}
                allAssetTypes={widgetData.allAssetTypes}
                additionalStyles={styles}
                minified={true}
                isDisabled={widgetData.variables.length || widgetData.assetProperties.length}
                filteredOptionFormats={
                  isImageByValueType ? ['numberType', 'floatType', 'booleanType'] : []
                }
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classNames(classes.panel, styles.panelWrapper)} defaultExpanded={false}>
        <AccordionSummary className={classes.panelRounded} expandIcon={<MuiExpandMoreIcon />}>
          <I18n className={styles.sectionTitle}>
            augmented-reailty.widget.side-panel.customization-header
          </I18n>
          <div className={styles.stepIndicator}>
            <div
              className={`${styles.statusIndicator} ${
                isDataFormCompleted(widgetData, 'customization')
                  ? styles.finished
                  : styles.unfinished
              }`}></div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.panelDetails}>
            {widgetData.type === 'value' && (
              <ValueCustomizationPanel
                widgetData={widgetData}
                widgetList={widgetList}
                setWidgetData={setWidgetData}
                setWidgetList={setWidgetList}
                onUpdate={handleCustomizationFormChanges}
              />
            )}
            {widgetData.type === 'image_by_value' && (
              <ImageByValueCustomizationPanel
                widgetData={widgetData}
                widgetList={widgetList}
                setWidgetData={setWidgetData}
                setWidgetList={setWidgetList}
                onUpdate={handleCustomizationFormChanges}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classNames(classes.panel, styles.panelWrapper)} defaultExpanded={false}>
        <AccordionSummary className={classes.panelRounded} expandIcon={<MuiExpandMoreIcon />}>
          <I18n className={styles.sectionTitle}>
            augmented-reailty.widget.side-panel.measurements-header
          </I18n>
          <div className={styles.stepIndicator}>
            <div
              className={`${styles.statusIndicator} ${
                measurementsError ? styles.unfinished : styles.finished
              }`}></div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.panelDetails}>
            <MeasurementsPanel
              widgetData={widgetData}
              widgetList={widgetList}
              setWidgetData={setWidgetData}
              setWidgetList={setWidgetList}
              onUpdate={handleMeasurementsFormChanges}
              measurementsError={measurementsError}
              setMeasurementsError={setMeasurementsError}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <div className={styles.finishBlock}>
        <Button type="submit" disabled={false} mode="bigFont" onClick={handleFinishClick}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </div>
  );
};

export default SidePanelToolbar;
