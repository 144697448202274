import { httpService } from '@core/http/HttpService';
import { isEqual, compact, uniq } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';
import { getDefaultPreviewData, getFilters } from '../charts.utils';

export const checkIsDifferentLocation = (cluster) => {
  const markers = cluster.getMarkers();
  const firstMarker = {
    lat: markers[0] && markers[0].position.lat(),
    lng: markers[0] && markers[0].position.lng(),
  };

  return markers.some(
    (marker) => marker.position.lat() != firstMarker.lat && marker.position.lng() != firstMarker.lng
  );
};

export const onClusterHover = (cluster, setDisableZoom) => {
  if (checkIsDifferentLocation(cluster)) return;

  setDisableZoom(true);
};

export const clustererNumberBuilder = (number: any) => {
  const decPlaces = Math.pow(10, 0);

  // Enumerate number abbreviations
  let abbrev = ['k', 'm', 'b', 't'];
  abbrev = abbrev.map((type) => i18nService.translate(type));

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number.toString();
};

const getDefaultGeojson = (bounds: any) => {
  const northEastLat = bounds.getNorthEast().lat();
  const southWestLat = bounds.getSouthWest().lat();
  const northEastLng = bounds.getNorthEast().lng();
  const southWestLng = bounds.getSouthWest().lng();

  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [southWestLng, northEastLat],
          [northEastLng, northEastLat],
          [northEastLng, southWestLat],
          [southWestLng, southWestLat],
          [southWestLng, northEastLat],
        ],
      ],
    },
  };
};

export const getData = async ({
  widgetId,
  widgetData,
  zoom = 1,
  widgetFilters,
  bounds,
  dashboardFilters,
}: any) => {
  const apiData: any = {};
  if (widgetId) {
    apiData.type = 'getMapData';
    apiData.urlParams = { widgetId, zoom };
    apiData.data = {
      ...getFilters(dashboardFilters),
      widgetFilters: widgetFilters || [],
      // geojson: getDefaultGeojson(bounds)
    };
  } else {
    //preview
    apiData.type = 'getMapPreviewData';
    apiData.urlParams = { zoom };
    apiData.data = {
      ...getDefaultPreviewData(widgetData),
      // geojson: getDefaultGeojson(bounds)
    };
  }

  return await httpService.api(apiData);
};

export const getMarkerDataFromServer = async ({
  widgetId,
  widgetData,
  zoom,
  geojson,
  widgetFilters,
}) => {
  const apiData: any = {};
  if (widgetId) {
    apiData.type = 'getMarkerData';
    apiData.urlParams = { widgetId, zoom };
    apiData.data = { ...getFilters(), widgetFilters: widgetFilters || [], geojson };
  } else {
    //preview
    apiData.type = 'getMarkerPreviewData';
    apiData.urlParams = { zoom };
    apiData.data = {
      ...getDefaultPreviewData(widgetData),
      geojson,
    };
  }

  return await httpService.api(apiData);
};

export const getDataFromServer = (
  map,
  locations,
  setIsUnauthorized,
  setLocations,
  setRefreshZoom,
  setIsLoadLocations,
  isUnauthorized,
  widgetId,
  widgetData,
  widgetFilters,
  dashboardFilters,
  zoom = undefined
) => {
  !isUnauthorized &&
    getData({
      widgetId,
      widgetData,
      zoom,
      // bounds: map.current.getBounds(),
      widgetFilters,
      dashboardFilters,
    })
      .then((res: any) => {
        if (!isEqual(res, locations)) {
          setIsLoadLocations(true);
        }
        setLocations(res);
        if (res && res.length > 0) {
          // centerize by location
          const bounds = new google.maps.LatLngBounds();
          res.forEach((location) => bounds.extend({ lat: location.lat, lng: location.lon }));
          map?.current?.fitBounds(bounds);
        }
        setRefreshZoom(true);
      })
      .catch((err) => {
        err && err.status === 405 && setIsUnauthorized(true);
        (!err || err.status !== 405) && setLocations([]);
      });
};

export const onNavigateToDashboard = (
  assetId: any,
  widget,
  navigateDashboard,
  navigationDataBuilder
) => {
  widget &&
    widget.navigationDashboard &&
    navigateDashboard &&
    navigationDataBuilder({
      widget,
      rawValues: { asset_id: assetId },
      navigateDashboard,
    });
};

export const navigateFromCluster = (
  cluster,
  map,
  locationsMap,
  widget,
  navigateDashboard,
  navigationDataBuilder,
  disableZoom,
  setIsInfoWindowOpen,
  setClusterLocations,
  isMobile,
  isTablet
) => {
  // Get asset id and navigate
  let isZoomDisabledInMobile = isMobile || isTablet ? !checkIsDifferentLocation(cluster) : false;

  if (disableZoom || isZoomDisabledInMobile || map.current.getZoom() === 22) {
    const locations = cluster.getMarkers().reduce((res, marker) => {
      return [
        ...res,
        ...locationsMap[`${marker.getPosition().lat()}-${marker.getPosition().lng()}`].map(
          (location) => !res.find((_location) => _location.assetId == location.assetId) && location
        ),
      ];
    }, []);
    map.current.setZoom(map.current.getZoom());
    setClusterLocations(compact(locations));
    setIsInfoWindowOpen(true);
    return;
  }
};

export const onIdle = (
  map,
  getIsLoadLocations,
  setZoom,
  setIsLoadLocations,
  setLastZoom,
  locations
) => {
  const mapZoom = map.current.getZoom();
  if (getIsLoadLocations() && mapZoom > 10) {
    setZoom(10);
    setLastZoom(10);
    setIsLoadLocations(false);
  } else if (mapZoom === undefined) {
    const bounds = new google.maps.LatLngBounds();
    locations.forEach((location) => bounds.extend({ lat: location.lat, lng: location.lon }));
    map?.current?.fitBounds(bounds);
  } else {
    //setZoom(undefined);
    setLastZoom(mapZoom);
    setIsLoadLocations(false);
  }
};

export const StatusColorMap = {
  CONNECTED_CRITICAL_ALARM: 'red',
  CONNECTED_MAJOR_ALARM: 'red',
  COMMUNICATION_ERROR: 'red',

  CONNECTED_WARNING: 'yellow',
  CONNECTED_MINOR_ALARM: 'yellow',

  DISCONNECTED: 'grey',
  AVAILABLE: 'green',
  CONNECTED_OK: 'green',
};
