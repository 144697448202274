import React, { useState } from 'react';
import { useSelector } from '@redux/useSelector';
import { TableColunm } from '@components/Table/table.interface';
import Table from '@components/Table';
import AlarmsName from './AlarmsName';
import AlarmsDescription from './AlarmsDescription';
import AlarmsSuggestedResolution from './AlarmsSuggestedResolution';
import styles from './AlarmsTable.scss';
import { boolean } from 'yup';
import { AlarmsMeta } from '@src/redux/redux.interface';
import { AlarmMeta } from '@core/http/server.interface';
import { dynamicSort } from '@core/utils';

const colunmsCfg: Array<TableColunm> = [
  {
    Component: AlarmsName,
    label: 'asset-type-alarms.name',
    style: { width: '20%', minWidth: '20%', maxWidth: '20%' },
    field: 'name',
    isSortable: true,
  },
  {
    Component: AlarmsDescription,
    label: 'asset-type-alarms.description',
    style: { width: '40%', minWidth: '40%', maxWidth: '40%' },
  },
  {
    Component: AlarmsSuggestedResolution,
    label: 'asset-type-alarms.suggested-resolution',
    style: { width: '40%', minWidth: '40%', maxWidth: '40%' },
  },
];

function AlarmsTable() {
  const [_order, setOrder] = useState(null);
  const data = useSelector((state) => state.editAssetType.alarmsMeta);

  const orderData = (firstInit: boolean, order?: any): AlarmMeta[] => {
    return data.sort(dynamicSort(order.label, order.orderSign));
  };

  const orderedData = _order ? orderData(false, _order) : data;

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={false}
        data={orderedData}
        getData={(firstInit, order) => {
          order && setOrder(order);
          orderData(firstInit, order);
        }}
        colunmsCfg={colunmsCfg}
        className={styles.tableBody}
      />
    </div>
  );
}

export default AlarmsTable;
