import * as RI from '../../redux.interface.d';
import { atHandlers } from './assetType.handlers';

const initialState = (): RI.AssetTypeState => ({
  assetsTypeData: [],
  totalCount: 0,
  resultsCount: 0,
  refresh: false,
});

export default function assetTypeReducer(
  state: RI.AssetTypeState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'assetsType/SET_ASSETS_TYPE_DATA':
      return {
        ...state,
        assetsTypeData: payload.assetsTypeData,
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount,
      };
    case 'assetsType/ADD_ASSETS_TYPE_DATA':
      return {
        ...state,
        assetsTypeData: state.assetsTypeData.concat(payload.assetsTypeData),
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount,
      };
    case 'assetsType/EDIT_ASSETS_TYPE':
      return atHandlers.editAssetStatusById(state, payload.id, payload.status);
    case 'assetsType/UPDATE_ASSETS_TYPE_DATA':
      return atHandlers.updateAssetsTypeData(state, payload);
    case 'assetsType/REFRESH_ASSETS_TYPE_DATA':
      return { ...state, refresh: !state.refresh };
    case 'assetsType/EDIT_ASSETS_TYPE_GPS_SUPPORT':
      return { ...state, supportGps: payload.supportGps };
    default:
      return state;
  }
}
