import React from 'react';
import SingleSelectWithChip from '@components/SingleSelectWithChip';
import { i18nService } from '@core/i18n/I18nService';
import { tagFormatsMap } from '@core/mapsAndDefinitions';

const EditableDataInput = (props) => {
  const { replaceWith, widgetType, replacements, dataType, options, replaceData } = props;
  const value = replaceWith;
  const isDisabled =
    dataType === 'assetProperties' &&
    ((widgetType === 'map' && [1, 3, 5].includes(replaceWith?.id)) ||
      (widgetType === 'line' && [1, 2, 7].includes(replaceWith?.id)) ||
      (widgetType === 'heatmap' && [7].includes(replaceWith?.id)));

  const availableOptions =
    (widgetType === 'switch' || widgetType === 'update_asset') &&
    replacements.assetTypes?.replaceWith.length === 1
      ? filterAvailableOptionsByAccess(filterAvailableOptionsByUse(getAvailableOptionsByFormat()))
      : filterAvailableOptionsByUse(getAvailableOptionsByFormat());

  function getAvailableOptionsByFormat() {
    return options.filter((option) => {
      if (replacements && options?.length) {
        const optionFormat = option.format ? option.format : option.type;
        const sourceFormat = props.source.format ? props.source.format : props.source.type;
        if (
          tagFormatsMap.get('numberType').some((format) => format === sourceFormat) &&
          tagFormatsMap.get('numberType').some((format) => format === optionFormat)
        ) {
          return option;
        }
        if (sourceFormat === optionFormat) {
          return option;
        }
      }
    });
  }

  function filterAvailableOptionsByUse(availableOptions: Array<any>) {
    return availableOptions.filter((opt) => {
      if (dataType === 'assetProperties') {
        const assetPropertiesReplaceWith = replacements?.assetProperties?.map(
          (assetProperty) => assetProperty?.replaceWith?.id
        );
        if (value?.id != opt.id && assetPropertiesReplaceWith?.includes(opt.id)) {
          return;
        }
        return opt;
      } else {
        const tagsReplaceWith = replacements?.tagData?.map((tag) => tag?.replaceWith?.id);
        if (value?.id != opt.id && tagsReplaceWith?.includes(opt.id)) {
          return;
        }
        return opt;
      }
    });
  }

  function filterAvailableOptionsByAccess(availableOptions: Array<any>) {
    return availableOptions.filter((opt) => {
      if (opt.access === 'RW') {
        return opt;
      }
      return;
    });
  }

  return (
    <SingleSelectWithChip
      id={`${dataType}Input`}
      isDisabled={isDisabled}
      isOptionDisabled={(option) => !availableOptions.some((ao) => ao.id === option.id)}
      values={value}
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onChange={(option: any) => {
        replaceData(dataType, props.source.id, option?.length ? option : null);
      }}
      tableConfig={(option) => ({
        [i18nService.translate('create-widget-page.create-widget.step-one.format')]: option.format
          ? option.format
          : option.type,
        [i18nService.translate('create-widget-page.create-widget.step-one.name')]: option.name,
        [i18nService.translate('asset-type-tags.format')]: option.format
          ? option.format
          : option.type,
      })}
      optionType="tagDataOptions"
      errorStyle={!value}
      placeholder={
        dataType === 'assetProperties'
          ? i18nService.translate(
              'create-widget-page.create-widget.step-one.replace-tags.select-asset-property'
            )
          : i18nService.translate(
              'create-widget-page.create-widget.step-one.replace-tags.select-tag'
            )
      }
    />
  );
};

export default EditableDataInput;
