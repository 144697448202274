import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './ThumbnailToolbar.scss';
import { ThumbnailToolbarProps } from './ThumbnailToolbar.interface';
import Icon from '@components/Icon';
import { cssVarsService } from '@core/CssVarsService';
import { dashboardService } from '@core/canvas/DashboardService';

function ThumbnailToolbar(props: ThumbnailToolbarProps) {
  const { dashboard, btnConfig, published, size = 'large' } = props;
  const { copy, remove, edit, preview } = btnConfig;
  const btnColor = cssVarsService.vars.systemEnableEditorIcons;
  const disabledBtnColor = cssVarsService.vars.systemDisableEditorIcons;
  const colorsMap = useMemo(
    () => ({
      copy: copy.disabled ? disabledBtnColor : btnColor,
      remove: remove.disabled ? disabledBtnColor : btnColor,
      edit: edit.disabled ? disabledBtnColor : btnColor,
      preview: preview.disabled ? disabledBtnColor : btnColor,
    }),
    [copy, remove, edit, preview, btnColor, disabledBtnColor]
  );

  const iconSize = size === 'small' ? '14px' : null;

  const onCopyClick = () => {
    dashboardService.copyDashboard(dashboard, copy.onClick);
  };

  return (
    <div className={classNames(styles.toolbar, published ? styles.published : styles.draft)}>
      <div className={classNames(styles.title, 'ellipsis-overflow')}>
        <span
          title={dashboard.name}
          className={classNames(styles.dashboardName, size === 'small' && styles.smallName)}>
          {dashboard.name}
        </span>
      </div>
      <div className={styles.dashboardButtons}>
        <Icon
          className={classNames('pointer', styles.btn)}
          type="duplicate"
          tooltipText="create-dashboard-modal.copy-dashboard"
          onClick={onCopyClick}
          disabled={copy.disabled}
          color={colorsMap.copy}
          width={iconSize}
          height={iconSize}
        />
        <Icon
          className={classNames('pointer', styles.btn)}
          type="trash"
          tooltipText="create-dashboard-modal.delete-dashboard"
          onClick={remove.onClick}
          disabled={remove.disabled}
          color={colorsMap.remove}
          width={iconSize}
          height={iconSize}
        />
        <Icon
          className={classNames('pointer', styles.btn)}
          type="editGalleryItem"
          tooltipText="create-dashboard-modal.edit-dashboard"
          onClick={edit.onClick}
          disabled={edit.disabled}
          color={colorsMap.edit}
          width={iconSize}
          height={iconSize}
        />
        <Icon
          className={classNames('pointer', styles.btn)}
          type="preview"
          tooltipText="create-dashboard-modal.preview-dashboard"
          onClick={preview.onClick}
          disabled={preview.disabled}
          color={colorsMap.preview}
          width={iconSize}
          height={iconSize}
        />
      </div>
    </div>
  );
}

export default ThumbnailToolbar;
