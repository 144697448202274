import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';

// Components
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';
import MaterialCheckbox from '@components/Checkbox';
import ColumnCustomizationRow from './ColumnCustomizationRow';
import I18n from '@components/I18n';

// Services | Interfaces
import { i18nService } from '@core/i18n/I18nService';
import { ColumnsCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { ColumnsProps } from './Columns.interface';
import {
  changeWidgetTable,
  setColumnsDefaultData,
  changeWidgetData,
  columnsCfg,
  yAxesCfg,
} from './Columns.utils';

// 3rd party
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import {
  buildMetricsArray,
  getTag,
  findDateTimeTag,
  getDateTimeOptions,
} from '@pages/CreateWidgetPage/widgets.utils';
import { getVar, updateWidgetCustomization } from '@core/canvas/widget.utils';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  columnTitle: {
    marginBottom: 13,
  },
  columnWidthTitle: {
    fontWeight: 800,
    width: '152px',
  },
  columnsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    paddingRight: 10,
  },
  section: {
    margin: '10px 0',
  },
  twoColumnSection: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stacked: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 150,
  },
  stackedCaption: {
    paddingLeft: '6px',
    height: 24,
    minWidth: 120,
  },
  stackType: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% - 150px)',
    justifyContent: 'center',
  },
  stackTypeCaption: {
    height: 24,
    minWidth: 150,
    maxWidth: 150,
    paddingRight: '6px',
    textAlign: 'right',
  },
  rowSection: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  columnWidthItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  numberField: {
    width: '100px',
    margin: '0 !important',
  },
  cbLabel: {
    marginLeft: 6,
    fontSize: 14,
  },
  checkboxText: {
    margin: '13px 0 0 6px',
    height: 24,
    maxWidth: 500,
  },
  radioLabel: {
    fontSize: 14,
    marginLeft: 0,
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
    padding: 10,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 0px 10px 0px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };
const selectFullWidthCustomStyle = { control: { height: 40 }, container: { width: '100%' } };

const xAxisInfoMap = {
  1: [1],
  2: [],
  3: [2, 1],
  9: [8, 1],
  7: [{ id: 6, type: 'dateTimeType' }, 1],
  8: [{ id: 7, type: 'dateTimeType' }, 1],
  4: [3, 1],
  5: [4, 1],
  6: [5, 1],
};

function Columns(props: ColumnsProps) {
  const classes = useStyles(props);
  const { widgetData, setWidgetData, previewData, defaultDecimalDigits } = props;
  const { scope, assetProperties, metrics, groupBy } = widgetData;
  const customization = cloneDeep(widgetData.customization);
  const dateTimeTags = useMemo(
    () => findDateTimeTag(scope === 'LAST_VALUE' ? metrics : groupBy, true),
    [metrics, groupBy]
  );
  const format = useMemo(() => {
    const originalTags = dateTimeTags.map((tag) => getTag(widgetData, tag));
    return originalTags.length
      ? originalTags.find((t) => (t.format || t.type) === 'DATETIME')
        ? 'DATETIME'
        : 'DATE'
      : null;
  }, [dateTimeTags]);
  const dateTimeOptions = useMemo(() => getDateTimeOptions(format), [format]);

  useEffect(() => {
    updateWidgetCustomization(
      {
        dateTimeFormat:
          format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null,
      },
      setWidgetData
    );
  }, [format]);

  const {
    columns,
    columnsWidthPercent = 50,
    yAxes,
    subtitle_text = '',
    subtitle_textColor = 'var(--widgetsFont)',
    subtitle_alignment = 'CENTER',
    legend_visible = true,
    legend_position = 'BOTTOM',
    legend_alignment = 'CENTER',
    tooltip_visible = true,
    tooltip_shared = true,
    tooltip_showAxisLegend = true,
    tooltip_decimalDigits = defaultDecimalDigits,
    dataLabels_visible = true,
    dataLabels_textColor = 'var(--widgetsFont)',
    xAxisInfo,
    dateTimeFormat,
    compare_to_specific_asset = false,
    stacked = false,
    stackType = 'NORMAL',
  } = (customization as ColumnsCustomization) || {};

  const xAxisInfoOptions = useMemo(() => {
    const none = assetProperties.length === 0 || !assetProperties.find((type) => type.id === 1);
    return none
      ? [
          {
            value: 2,
            label: `create-widget-page.create-widget.step-four.columns.x-axis-info.opt-${2}`,
          },
        ]
      : Object.keys(xAxisInfoMap)
          .filter(
            (val) =>
              !xAxisInfoMap[val].length || //None
              xAxisInfoMap[val].length === 1 || //assetId
              assetProperties.find(
                (type) => type.id === (xAxisInfoMap[val][0].id || xAxisInfoMap[val][0])
              ) // assetId + all other asset properties
          )
          .map((value) => ({
            value: +value,
            label: `create-widget-page.create-widget.step-four.columns.x-axis-info.opt-${value}`,
          }));
  }, [assetProperties]);

  useEffect(() => {
    if (!customization) {
      updateWidgetCustomization(
        {
          columnsWidthPercent: columnsWidthPercent,
          dataLabels_textColor: dataLabels_textColor,
          dataLabels_visible: dataLabels_visible,
          legend_alignment: legend_alignment,
          legend_position: legend_position,
          legend_visible: legend_visible,
          subtitle_alignment: subtitle_alignment,
          subtitle_text: subtitle_text,
          subtitle_textColor: subtitle_textColor,
          tooltip_decimalDigits: tooltip_decimalDigits,
          tooltip_shared: tooltip_shared,
          tooltip_showAxisLegend: tooltip_showAxisLegend,
          tooltip_visible: tooltip_visible,
          xAxisInfo: scope === 'LAST_VALUE' ? xAxisInfoOptions[0].value : null,
          dateTimeFormat:
            format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null,
          compare_to_specific_asset: compare_to_specific_asset,
          stacked: stacked,
          stackType: stackType,
        },
        setWidgetData
      );
    } else if (scope === 'LAST_VALUE' && !xAxisInfo) {
      updateWidgetCustomization({ xAxisInfo: xAxisInfoOptions[0].value }, setWidgetData);
    }
  }, []);

  useEffect(() => {
    setWidgetData((prevState) => {
      const tempMetrics = prevState.metrics.filter((m) => !m.hide);
      const selectedMetrics = xAxisInfo
        ? buildMetricsArray(xAxisInfoMap[xAxisInfo], 'ASSET_PROPERTY', true, tempMetrics.length)
        : [];
      return {
        ...prevState,
        metrics: [...tempMetrics, ...selectedMetrics],
      };
    });
  }, [xAxisInfo]);

  const alignmentOptions = useMemo(
    () => [
      { label: 'enum.CENTER', value: 'CENTER' },
      { label: 'enum.LEFT', value: 'LEFT' },
      { label: 'enum.RIGHT', value: 'RIGHT' },
    ],
    []
  );

  const positionOptions = useMemo(
    () => [
      { label: 'enum.TOP', value: 'TOP' },
      { label: 'enum.RIGHT', value: 'RIGHT' },
      { label: 'enum.BOTTOM', value: 'BOTTOM' },
      { label: 'enum.LEFT', value: 'LEFT' },
    ],
    []
  );

  const stackTypeOptions = useMemo(
    () => [
      {
        label: 'create-widget-page.create-widget.step-four.columns-widget.stack-type.normal',
        value: 'NORMAL',
      },
      {
        label: 'create-widget-page.create-widget.step-four.columns-widget.stack-type.percentage',
        value: 'PERCENTAGE',
      },
    ],
    []
  );

  const xAxisInfoValue = useMemo(
    () => xAxisInfo && xAxisInfoOptions.find((o) => o.value === xAxisInfo),
    [xAxisInfo]
  );
  const subtitleAlignment = useMemo(
    () => subtitle_alignment && alignmentOptions.find((o) => o.value === subtitle_alignment),
    [subtitle_alignment]
  );
  const lengedPosition = useMemo(
    () => legend_position && positionOptions.find((o) => o.value === legend_position),
    [legend_position]
  );
  const legendAlignment = useMemo(
    () => legend_alignment && alignmentOptions.find((o) => o.value === legend_alignment),
    [legend_alignment]
  );

  useEffect(() => {
    setColumnsDefaultData(customization, widgetData, setWidgetData, defaultDecimalDigits);
  }, [previewData]);

  const handleCompareToAssetClicked = () => {
    const newValue = !compare_to_specific_asset;
    let newColumns = [];
    if (newValue) {
      // add new columns for specific asset
      let index = 0;
      columns.forEach((c) => {
        newColumns.push({
          ...c,
          color: getVar(`widgetsGraphsColorsPalette${index + 1}`),
        });
        index++;
        newColumns.push({
          ...c,
          specific_asset: true,
          color: getVar(`widgetsGraphsColorsPalette${index + 1}`),
        });
        index++;
      });
    } else {
      // remove specific asset columns
      const filteredColumns = columns.filter((c) => !c.specific_asset);
      let index = 0;
      filteredColumns.forEach((c) => {
        newColumns.push({
          ...c,
          color: getVar(`widgetsGraphsColorsPalette${index + 1}`),
        });
        index++;
      });
    }

    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        columns: newColumns,
        compare_to_specific_asset: newValue,
      },
    }));
  };

  function handleStackedClicked(isChecked) {
    let newColumns = [];

    if (compare_to_specific_asset && isChecked) {
      const filteredColumns = columns.filter((c) => !c.specific_asset);
      let index = 0;
      filteredColumns.forEach((c) => {
        newColumns.push({
          ...c,
          color: getVar(`widgetsGraphsColorsPalette${index + 1}`),
        });
        index++;
      });
    }

    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        stacked: !stacked,
        compare_to_specific_asset: isChecked ? false : compare_to_specific_asset,
        columns: compare_to_specific_asset ? newColumns : columns,
        yAxes: isChecked
          ? prevState.customization['yAxes'].map((yAxis, idx) => {
              if (idx === 0) {
                if (yAxis.show != 'NO') {
                  return yAxis;
                } else {
                  return { ...yAxis, show: 'LEFT' };
                }
              } else {
                return { ...yAxis, show: 'NO' };
              }
            })
          : prevState.customization['yAxes'],
      },
    }));
  }

  function handleStackTypeChanged(option) {
    if (option.value === 'PERCENTAGE') {
      columns.map((column, idx) =>
        changeWidgetTable(
          {
            ...column,
            type: 'BAR',
          },
          idx,
          'columns',
          setWidgetData
        )
      );
      yAxes.map((yAxis, idx) =>
        changeWidgetTable(
          {
            ...yAxis,
            scale: 'AUTO',
            maxValue: null,
            minValue: null,
          },
          idx,
          'yAxes',
          setWidgetData
        )
      );
    }

    changeWidgetData(option.value, 'stackType', setWidgetData);
  }

  return (
    <div className={classes.wrapper}>
      {/* columns-settings */}
      <div className={classes.twoColumnSection}>
        <div className={classes.stacked}>
          <MaterialCheckbox
            color="primary"
            checked={stacked}
            onClick={() => handleStackedClicked(!stacked)}
          />
          <I18n className={classes.stackedCaption}>
            create-widget-page.create-widget.step-four.columns-widget.stacked
          </I18n>
        </div>
        <div className={classes.stackType}>
          <I18n className={classes.stackTypeCaption}>
            create-widget-page.create-widget.step-four.columns-widget.stack-type
          </I18n>
          <Select
            styles={{ container: { width: 'calc(100% - 150px)' } }}
            disabled={!stacked}
            options={stackTypeOptions}
            value={stackTypeOptions.find((opt) => opt.value === stackType)}
            onChange={(option) => handleStackTypeChanged(option)}
            unSelect={true}
          />
        </div>
      </div>
      {(scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') && (
        <div className={classes.section}>
          <MaterialCheckbox
            color="primary"
            checked={compare_to_specific_asset}
            onClick={() => handleCompareToAssetClicked()}
            disabled={stacked}
          />
          <I18n className={classes.checkboxText}>
            create-widget-page.create-widget.step-four.compare-to-specific-asset
          </I18n>
        </div>
      )}
      <div className={classes.section}>
        <div className={classNames(classes.titleWrapper, classes.columnTitle, 'ellipsis-overflow')}>
          <I18n>create-widget-page.create-widget.step-four.columns-settings</I18n>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            {columnsCfg.map((header, idx) => (
              <div
                title={header.headerLabel && i18nService.translate(header.headerLabel)}
                key={`columns_${idx}`}
                className={classes.header}
                style={header.style}>
                {header.headerLabel && (
                  <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                )}
              </div>
            ))}
          </div>
          {columns?.map((col, idx) => (
            <ColumnCustomizationRow
              columnCfg={columnsCfg}
              column={col}
              colIndex={idx}
              index={idx}
              key={`columns_${idx}`}
              type="columns"
              isFirst={idx === 0}
              isLast={idx + 1 === columns.length}
              columnChanged={(column) => changeWidgetTable(column, idx, 'columns', setWidgetData)}
              stacked={stacked}
              stackType={stackType}
              otherDisplayNames={columns
                .filter(
                  (c) =>
                    col.id !== c.id ||
                    col.valueType !== c.valueType ||
                    col.operation !== c.operation
                )
                .map((c) => c.displayName)}
            />
          ))}
        </div>
      </div>
      {/* column-width */}
      <div className={classes.section}>
        <div className={classes.columnWidthItem}>
          <div className={classNames(classes.columnWidthTitle, 'ellipsis-overflow')}>
            <I18n>create-widget-page.create-widget.step-four.column-width</I18n>
          </div>
          <TextField
            className={classes.numberField}
            value={columnsWidthPercent}
            margin="dense"
            variant="outlined"
            inputProps={{ min: 1, max: 100, style: { color: 'var(--systemFont)' } }}
            onChange={(e) => changeWidgetData(e.target.value, 'columnsWidthPercent', setWidgetData)}
            type="number"
          />
        </div>
      </div>
      {/* y-axes */}
      <div className={classes.section}>
        <div className={classNames(classes.titleWrapper, classes.columnTitle)}>
          <I18n>create-widget-page.create-widget.step-four.y-axis</I18n>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            {yAxesCfg.map((header, idx) => (
              <div
                title={header.headerLabel && i18nService.translate(header.headerLabel)}
                key={`yAxes_${idx}`}
                className={classes.header}
                style={header.style}>
                {header.headerLabel && (
                  <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                )}
              </div>
            ))}
          </div>
          {yAxes?.map((col, idx) => (
            <ColumnCustomizationRow
              columnCfg={yAxesCfg}
              key={`yAxes_${idx}`}
              column={col}
              colIndex={idx}
              index={idx}
              isFirst={idx === 0}
              isLast={idx + 1 === yAxes.length}
              type="yAxes"
              columnChanged={(column) => changeWidgetTable(column, idx, 'yAxes', setWidgetData)}
              stacked={stacked}
              stackType={stackType}
            />
          ))}
        </div>
      </div>
      {/* xAxisInfo */}
      {scope === 'LAST_VALUE' && (
        <div className={classes.section}>
          <div className={classes.columnWidthItem}>
            <I18n className={classNames(classes.columnWidthTitle, 'ellipsis-overflow')}>
              create-widget-page.create-widget.step-four.columns.x-axis-info
            </I18n>
            <Select
              styles={selectCustomStyle}
              options={xAxisInfoOptions}
              value={xAxisInfoValue}
              onChange={(option) => changeWidgetData(option.value, 'xAxisInfo', setWidgetData)}
            />
          </div>
        </div>
      )}
      {/* dateTime */}
      {dateTimeOptions && (
        <div className={classes.rowSection}>
          <I18n className={classes.titleWrapper} noEllipsis>
            create-widget-page.create-widget.step-four.date-time
          </I18n>
          <Select
            styles={selectFullWidthCustomStyle}
            options={dateTimeOptions}
            value={dateTimeFormat && dateTimeOptions.find((opt) => opt.value === dateTimeFormat)}
            onChange={(option) => changeWidgetData(option.value, 'dateTimeFormat', setWidgetData)}
          />
        </div>
      )}
      {/* subtitle */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.subtitle</I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.text</I18n>
          <TextField
            value={subtitle_text}
            margin="dense"
            variant="outlined"
            className={classes.textField}
            inputProps={{ maxLength: 50, style: { color: 'var(--systemFont)' } }}
            onChange={(e) => changeWidgetData(e.target.value, 'subtitle_text', setWidgetData)}
          />
        </div>
        <div className={classNames(classes.item, classes.colorItem)}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.color</I18n>
          <ColorInput
            disabled={!subtitle_text}
            value={subtitle_textColor}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            customeMarginButton={0}
            colorChange={(value) => changeWidgetData(value, 'subtitle_textColor', setWidgetData)}
          />
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.aligment</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!subtitle_text}
            options={alignmentOptions}
            value={subtitleAlignment}
            onChange={(option) =>
              changeWidgetData(option.value, 'subtitle_alignment', setWidgetData)
            }
            unSelect={true}
          />
        </div>
      </div>
      {/* legend */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.legend</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            checked={legend_visible}
            onClick={() => changeWidgetData(!legend_visible, 'legend_visible', setWidgetData)}
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.position</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!legend_visible}
            options={positionOptions}
            value={lengedPosition}
            onChange={(option) => changeWidgetData(option.value, 'legend_position', setWidgetData)}
            unSelect={true}
          />
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.aligment</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!legend_visible}
            options={alignmentOptions}
            value={legendAlignment}
            onChange={(option) => changeWidgetData(option.value, 'legend_alignment', setWidgetData)}
            unSelect={true}
          />
        </div>
      </div>
      {/* tooltip */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.tooltip</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            checked={tooltip_visible}
            onClick={() => changeWidgetData(!tooltip_visible, 'tooltip_visible', setWidgetData)}
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            disabled={!tooltip_visible}
            checked={tooltip_shared}
            onClick={() => changeWidgetData(!tooltip_shared, 'tooltip_shared', setWidgetData)}
          />
          <I18n className={classes.cbLabel}>create-widget-page.create-widget.step-four.shared</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            disabled={!tooltip_visible}
            checked={tooltip_showAxisLegend}
            onClick={() =>
              changeWidgetData(!tooltip_showAxisLegend, 'tooltip_showAxisLegend', setWidgetData)
            }
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.show-axis-legend
          </I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>
            create-widget-page.create-widget.step-four.decimal-digits
          </I18n>
          <TextField
            disabled={!tooltip_visible}
            className={classes.numberField}
            value={tooltip_decimalDigits}
            margin="dense"
            variant="outlined"
            inputProps={{ style: { color: 'var(--systemFont)' } }}
            onChange={(e) =>
              changeWidgetData(e.target.value, 'tooltip_decimalDigits', setWidgetData)
            }
            type="number"
          />
        </div>
      </div>
      {/* data-labels */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.data-labels</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            checked={dataLabels_visible}
            onClick={() =>
              changeWidgetData(!dataLabels_visible, 'dataLabels_visible', setWidgetData)
            }
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classNames(classes.item, classes.colorItem)}>
          <I18n className={classes.text}>
            create-widget-page.create-widget.step-four.text-color
          </I18n>
          <ColorInput
            disabled={!dataLabels_visible}
            value={dataLabels_textColor}
            customeMarginButton={0}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            colorChange={(value) => changeWidgetData(value, 'dataLabels_textColor', setWidgetData)}
          />
        </div>
      </div>
    </div>
  );
}

export default Columns;
