import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import I18n from '@components/I18n';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  section: {
    margin: '10px 0',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
    paddingRight: '5px',
    overflowWrap: 'anywhere',
  },
  fontSizeInput: {
    width: '68px',
    height: '40px',
  },
}));

const UpdateButtonSection = (props) => {
  const classes = useStyles(props);
  const {
    setCustomizationData,
    horizontalAlignmentOptions,
    text,
    color,
    backgroundColor,
    horizontalAlignment,
    section,
  } = props;
  const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };

  return (
    <div className={classes.section}>
      <I18n element="div" className={classes.titleWrapper}>
        create-widget-page.create-widget.step-four.update-asset.update-button
      </I18n>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.update-asset.update-text
        </I18n>
        <TextField
          value={text || ''}
          className={classes.textField}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'text', section);
          }}
          inputProps={{
            maxLength: 20,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.update-asset.button-text-color
        </I18n>
        <ColorInput
          disabled={!text}
          value={color}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'color', section)}
        />
      </div>
      <div className={classNames(classes.item, classes.colorItem)}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.update-asset.button-background-color
        </I18n>
        <ColorInput
          disabled={!text}
          value={backgroundColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'backgroundColor', section)}
        />
      </div>
      <div className={classes.item}>
        <I18n noEllipsis={true} className={classes.text}>
          create-widget-page.create-widget.step-four.update-asset.button-horizontal-alignment
        </I18n>
        <Select
          styles={selectCustomStyle}
          options={horizontalAlignmentOptions}
          value={
            horizontalAlignment &&
            horizontalAlignmentOptions.find((opt) => opt.value === horizontalAlignment)
          }
          onChange={(option) => setCustomizationData(option.value, 'horizontalAlignment', section)}
        />
      </div>
    </div>
  );
};

export default UpdateButtonSection;
