import I18n from '@components/I18n';
import React, { useEffect, useMemo } from 'react';
import styles from './YearlyTrigger.scss';
import Select from '@components/Select';
import { i18nService } from '@core/i18n/I18nService';
import moment from 'moment';

const monthsOptions = [
  {
    label: 'events-template.edit-template.trigger.month.january',
    value: 1,
    days: 31,
  },
  {
    label: 'events-template.edit-template.trigger.month.february',
    value: 2,
    days: 28,
  },
  {
    label: 'events-template.edit-template.trigger.month.march',
    value: 3,
    days: 31,
  },
  {
    label: 'events-template.edit-template.trigger.month.april',
    value: 4,
    days: 30,
  },
  {
    label: 'events-template.edit-template.trigger.month.may',
    value: 5,
    days: 31,
  },
  {
    label: 'events-template.edit-template.trigger.month.june',
    value: 6,
    days: 30,
  },
  {
    label: 'events-template.edit-template.trigger.month.july',
    value: 7,
    days: 31,
  },
  {
    label: 'events-template.edit-template.trigger.month.august',
    value: 8,
    days: 31,
  },
  {
    label: 'events-template.edit-template.trigger.month.september',
    value: 9,
    days: 30,
  },
  {
    label: 'events-template.edit-template.trigger.month.october',
    value: 10,
    days: 31,
  },
  {
    label: 'events-template.edit-template.trigger.month.november',
    value: 11,
    days: 30,
  },
  {
    label: 'events-template.edit-template.trigger.month.december',
    value: 12,
    days: 31,
  },
];

function YearlyTrigger(props) {
  const { templateData, setTemplateData } = props;
  const { monthOfYear, dayOfYear } = templateData;

  const dayInMonthOptions = useMemo(() => {
    if (monthOfYear) {
      return Array.from(
        { length: monthsOptions.find((m) => m.value === monthOfYear).days },
        (_, i) => ({
          label: (i + 1).toString(),
          value: i + 1,
        })
      );
    }

    return [];
  }, [monthOfYear]);

  useEffect(() => {
    if (!monthOfYear) {
      setTemplateData(moment().date(), 'dayOfYear');
      setTemplateData(moment().month() + 1, 'monthOfYear');
    }
  }, []);

  useEffect(() => {
    if (monthOfYear) {
      const daysInMonth = monthsOptions.find((m) => m.value === monthOfYear).days;
      if (!dayOfYear || dayOfYear > daysInMonth) setTemplateData(1, 'dayOfYear');
    }
  }, [monthOfYear, dayOfYear]);

  return (
    <div className={styles.row}>
      <div className={styles.header}>
        <I18n>events-template.edit-template.trigger.day-of-year</I18n>
      </div>
      <Select
        styles={{ container: { width: '110px', marginRight: '3px' } }}
        className={styles.field}
        options={monthsOptions}
        value={monthsOptions.find((opt) => opt.value === monthOfYear)}
        getOptionLabel={(i) => i18nService.translate(i.label)}
        getOptionValue={(i) => i.value}
        onChange={(option) => setTemplateData(option.value, 'monthOfYear')}
      />
      <Select
        styles={{ container: { width: '110px', marginRight: '3px' } }}
        className={styles.field}
        options={dayInMonthOptions}
        value={dayInMonthOptions.find((opt) => opt.value === dayOfYear)}
        getOptionLabel={(i) => i.label}
        getOptionValue={(i) => i.value}
        onChange={(option) => setTemplateData(option.value, 'dayOfYear')}
      />
    </div>
  );
}

export default YearlyTrigger;
