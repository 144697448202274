import { CanvasWidget } from '@src/redux/redux.interface';
import {
  allWidgets,
  getAllWidgetsWithCalculatedCoords,
  getIntersectingWidgets,
  checkIsWidgetIntersecting,
  mergeWidgets,
} from './ArrangementPopover.utils';
import { getState } from '@src/redux/store';
import { editorConfig } from '@core/canvas/editorConfig';
import { cssVarsService } from '@core/CssVarsService';

const cellSize = editorConfig.gridCellSizePx;
const sideBarWidth = cssVarsService.vars.sideBarWidth || 52;

export const adjustSizeOptionsMap = new Map([
  ['tallest', { dimension: 'h', extremum: 'max' }],
  ['shortest', { dimension: 'h', extremum: 'min' }],
  ['widest', { dimension: 'w', extremum: 'max' }],
  ['narrowest', { dimension: 'w', extremum: 'min' }],
]);

export function adjustHeightOrWidthForSelectedWidgets(
  selectedWidgets: Array<CanvasWidget>,
  option: string
): {
  newWidgets: Array<CanvasWidget>;
  notAllowedWidgets: Array<CanvasWidget>;
} {
  const extremum = adjustSizeOptionsMap.get(option).extremum;
  const dimension = adjustSizeOptionsMap.get(option).dimension;
  const minOrMaxValue = Math[extremum](...selectedWidgets.map((widget) => widget[dimension]));

  const calculatedWidgets = selectedWidgets
    .filter((widget) => {
      if (widget[dimension] !== minOrMaxValue) {
        return widget;
      }
    })
    .map((w) => {
      return { ...w, [dimension]: minOrMaxValue };
    });

  return extremum === 'min'
    ? {
        newWidgets: calculatedWidgets,
        notAllowedWidgets: getWidgetsUnderMinSize(calculatedWidgets, dimension, option),
      }
    : checkCalculatedWidgetsForOverlappings(calculatedWidgets, selectedWidgets, dimension);
}

export function checkCalculatedWidgetsForOverlappings(
  calculatedWidgets: Array<CanvasWidget>,
  selectedWidgets: Array<CanvasWidget>,
  dimension: string
): {
  newWidgets: Array<CanvasWidget>;
  notAllowedWidgets: Array<CanvasWidget>;
} {
  const currentCoordinate = dimension === 'w' ? 'x' : 'y';
  const allWidgetsWithCalculatedCoords = getAllWidgetsWithCalculatedCoords(calculatedWidgets);

  const intersectingWidgets = getIntersectingWidgets(
    calculatedWidgets,
    allWidgetsWithCalculatedCoords
  );

  const widgetsExceedCanvasRight = calculatedWidgets.filter((w) => checkWidgetExceedsCanvasEdge(w));

  const intersectingOrExceedingWidgets = intersectingWidgets.concat(widgetsExceedCanvasRight);

  if (intersectingOrExceedingWidgets.length) {
    let movedWidgets = makeMoreSpaceByMovingInSecondDirection(
      intersectingOrExceedingWidgets,
      allWidgetsWithCalculatedCoords,
      selectedWidgets,
      dimension
    );

    const intersectingWidgets = getIntersectingWidgets(
      movedWidgets,
      getAllWidgetsWithCalculatedCoords(movedWidgets)
    );

    if (intersectingWidgets.length) {
      movedWidgets = moveIntersectingWidgetsUpOrLeft(
        selectedWidgets,
        calculatedWidgets,
        movedWidgets,
        intersectingWidgets,
        dimension
      );
    }

    const notAllowedWidgets = getNotAllowedWidgets(movedWidgets);

    const widgetsWithRecoveredCoordinate = notAllowedWidgets.length
      ? getWidgetsWithRecoveredCoordinate(
          allWidgetsWithCalculatedCoords,
          notAllowedWidgets,
          currentCoordinate
        )
      : [];

    if (widgetsWithRecoveredCoordinate.length) {
      movedWidgets = movedWidgets.map((mw) => {
        const recoveredWidget = widgetsWithRecoveredCoordinate.find((rw) => rw.id === mw.id);
        if (recoveredWidget) {
          return { ...mw, [currentCoordinate]: recoveredWidget[currentCoordinate] };
        }
        return mw;
      });
    }

    const newWidgets = mergeWidgets(calculatedWidgets, movedWidgets);

    return {
      newWidgets: newWidgets,
      notAllowedWidgets: getNotAllowedWidgets(newWidgets),
    };
  }

  return { newWidgets: calculatedWidgets, notAllowedWidgets: [] };
}

function makeMoreSpaceByMovingInSecondDirection(
  intersectingWidgets: Array<CanvasWidget>,
  allWidgetsWithCalculatedCoords: Array<any>,
  selectedWidgets: Array<CanvasWidget>,
  dimension: string
): Array<CanvasWidget> {
  const coordinate = dimension === 'w' ? 'x' : 'y';
  const intersectionCoordinate = dimension === 'w' ? 'y' : 'x';
  const intersectionDimension = dimension === 'w' ? 'h' : 'w';

  return intersectingWidgets.map((widget) => {
    const widgetsCloseToCurrentWidget = getWidgetsCloseToCurrentWidget(
      allWidgetsWithCalculatedCoords,
      widget,
      dimension,
      coordinate,
      intersectionCoordinate,
      intersectionDimension
    );
    return getWidgetsWithMovedCoordinates(
      allWidgetsWithCalculatedCoords,
      selectedWidgets,
      widgetsCloseToCurrentWidget,
      widget,
      dimension,
      coordinate
    );
  });
}

function getWidgetsCloseToCurrentWidget(
  allWidgetsWithCalculatedCoords: Array<any>,
  widget: CanvasWidget,
  dimension: string,
  coordinate: string,
  intersectionCoordinate: string,
  intersectionDimension: string
): Array<any> {
  return (
    allWidgetsWithCalculatedCoords.filter((w) => {
      if (
        w.coords[coordinate] + w.coords[dimension] + 1 <= widget[coordinate] &&
        ((w.coords[intersectionCoordinate] >= widget[intersectionCoordinate] &&
          w.coords[intersectionCoordinate] <
            widget[intersectionCoordinate] + widget[intersectionDimension]) ||
          (w.coords[intersectionCoordinate] <= widget[intersectionCoordinate] &&
            w.coords[intersectionCoordinate] + w.coords[intersectionDimension] >
              widget[intersectionCoordinate]))
      ) {
        return w;
      }
    }) || []
  );
}

function getWidgetsWithMovedCoordinates(
  allWidgetsWithCalculatedCoords: Array<any>,
  selectedWidgets: Array<CanvasWidget>,
  widgetsCloseToCurrentWidget: Array<any>,
  widget: CanvasWidget,
  dimension: string,
  coordinate: string
): CanvasWidget {
  if (widgetsCloseToCurrentWidget.length) {
    const refWidget = widgetsCloseToCurrentWidget.find((w) => {
      if (
        w.coords[coordinate] + w.coords[dimension] ===
        Math.max(
          ...widgetsCloseToCurrentWidget.map((ww) => {
            return ww.coords[coordinate] + ww.coords[dimension];
          })
        )
      ) {
        return w;
      }
    });

    const newCoordinate = calcMovingCoordinate(
      widget,
      selectedWidgets,
      refWidget,
      coordinate,
      dimension
    );

    return {
      ...widget,
      [coordinate]: newCoordinate,
    };
  } else {
    const widgetMergedToAllWidgetsWithCalculatedCoords = allWidgetsWithCalculatedCoords.map((w) => {
      if (w.id === widget.id) {
        return { id: widget.id, coords: { x: widget.x, y: widget.y, w: widget.w, h: widget.h } };
      }
      return w;
    });

    const intersectingWidgets = getIntersectingWidgets(
      [widget],
      widgetMergedToAllWidgetsWithCalculatedCoords
    );

    if (intersectingWidgets.length === 1) {
      return widget;
    }

    const originalValue = selectedWidgets.find((sw) => sw.id === widget.id)[dimension];
    const sizeToAdd = widget[dimension] - originalValue;
    const newCoord = widget[coordinate] - sizeToAdd;
    return { ...widget, [coordinate]: newCoord ? newCoord : 0 };
  }
}

function checkWidgetExceedsCanvasEdge(widget: CanvasWidget): boolean {
  const canvasWidth = getState().dashboardEditor.canvasWidth.value;
  const maxRight = canvasWidth - sideBarWidth;
  const widgetRightInPixels = (widget.x + widget.w) * cellSize;

  if (widgetRightInPixels > maxRight || widget.x < 0 || widget.y < 0) {
    return true;
  }
  return false;
}

function getWidgetsUnderMinSize(
  widgets: Array<CanvasWidget>,
  dimension: string,
  option: string
): Array<CanvasWidget> {
  return (
    widgets.filter((widget) => {
      const minDimension =
        adjustSizeOptionsMap.get(option).dimension === 'h' ? 'minHeight' : 'minWidth';
      switch (minDimension) {
        case 'minHeight':
          if (widget.hideWidgetName) {
            if (
              widget[dimension] <
              getWidgetMinSizesMap().get(widget.type)[minDimension] / cellSize
            ) {
              return widget;
            }
          }
          if (
            widget[dimension] <
            Math.ceil(
              (getWidgetMinSizesMap().get(widget.type)[minDimension] +
                cssVarsService.vars.widgetActionBarHeight) /
                cellSize
            )
          ) {
            return widget;
          }
        case 'minWidth':
          if (
            widget[dimension] <
            getWidgetMinSizesMap().get(widget.type)[minDimension] / cellSize
          ) {
            return widget;
          }
        default:
          return;
      }
    }) || []
  );
}

function getWidgetMinSizesMap() {
  return new Map(
    allWidgets.map((widget) => [
      widget.id,
      { minHeight: widget.minHeight, minWidth: widget.minWidth },
    ])
  );
}

function calcMovingCoordinate(
  widget: CanvasWidget,
  selectedWidgets: CanvasWidget[],
  refWidget: any,
  coordinate: string,
  dimension: string
): number {
  const refWidgetRightOrBottom = refWidget.coords[coordinate] + refWidget.coords[dimension];
  const freeSpace = widget[coordinate] - refWidgetRightOrBottom;
  const originalValue = selectedWidgets.find((sw) => sw.id === widget.id)[dimension];
  const sizeToAdd = widget[dimension] - originalValue;

  const newCoordinate = refWidgetRightOrBottom + freeSpace - sizeToAdd;

  return newCoordinate < refWidgetRightOrBottom ? refWidgetRightOrBottom : newCoordinate;
}

function moveIntersectingWidgetsUpOrLeft(
  selectedWidgets: CanvasWidget[],
  calculatedWidgets: CanvasWidget[],
  movedWidgets: CanvasWidget[],
  intersectingWidgets: CanvasWidget[],
  dimension: string
): Array<CanvasWidget> {
  const coordinate = dimension === 'w' ? 'x' : 'y';
  const minCoordinate = Math.min(
    ...intersectingWidgets.map((w) => {
      return w[coordinate];
    })
  );

  const updatedIntersectingWidgets = intersectingWidgets.map((iw) => {
    if (iw[coordinate] === minCoordinate) {
      const originalWidget = selectedWidgets.find((sw) => sw.id === iw.id);
      const originalWidgetRightOrBottom = originalWidget[coordinate] + originalWidget[dimension];
      const calculatedWidgetLeftOrTop = originalWidgetRightOrBottom - iw[dimension];
      return { ...iw, [coordinate]: calculatedWidgetLeftOrTop };
    }
    return iw;
  });

  const mergedWidgets = mergeWidgets(movedWidgets, updatedIntersectingWidgets);

  return mergeWidgets(calculatedWidgets, mergedWidgets);
}

function getNotAllowedWidgets(widgets: Array<CanvasWidget>): Array<CanvasWidget> {
  return getIntersectingWidgets(widgets, getAllWidgetsWithCalculatedCoords(widgets)).concat(
    widgets.filter((w) => {
      if (checkWidgetExceedsCanvasEdge(w)) {
        return w;
      }
    })
  );
}

function getWidgetsWithRecoveredCoordinate(
  allWidgetsWithCalculatedCoords: Array<any>,
  notAllowedWidgets: Array<CanvasWidget>,
  currentCoordinate: string
): Array<CanvasWidget> {
  const mergedWidgets = allWidgetsWithCalculatedCoords.map((aw) => {
    const widget = notAllowedWidgets.find((w) => w.id === aw.id);
    if (widget) {
      return { id: widget.id, coords: { x: widget.x, y: widget.y, w: widget.w, h: widget.h } };
    }
    return aw;
  });
  return notAllowedWidgets
    .map((w) => {
      const oldCoordinate = allWidgetsWithCalculatedCoords.find((ww) => ww.id === w.id).coords[
        currentCoordinate
      ];
      const isIntersectingWidget = checkIsWidgetIntersecting(mergedWidgets, {
        ...w,
        [currentCoordinate]: oldCoordinate,
      });
      if (!isIntersectingWidget) {
        return {
          ...w,
          [currentCoordinate]: oldCoordinate,
        };
      }
    })
    .filter((w) => w?.id);
}
