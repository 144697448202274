import React, { useEffect, useState } from 'react';
import { useSelector } from '@redux/useSelector';

import styles from './DataBackup.scss';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import ControlSection from './ControlSection';
import DataSection from './DataSection';
import Button from '@components/Button';
import I18n from '@components/I18n';

function DataBackup(props) {
  const {
    organizationId,
    categoryList,
    setCategoryList,
    dateList,
    setDateList,
    fileList,
    setFileList,
    selectedCategory,
    setSelectedCategory,
    selectedDate,
    setSelectedDate,
    selectedFile,
    setSelectedFile,
  } = props;
  const editAssetGeneral = useSelector((state) => state.editAsset.general);
  const { assetId } = editAssetGeneral;

  const [subscriptionsData, setSubscriptionsData] = useState(null);
  const [noDataText, setNoDataText] = useState('');

  const timezone = useSelector((state) => state.config.timezone);
  const whiteLabelDateFormat = useSelector(
    (state) => state.config.whiteLabelDetails.dateTime.content.shortDate
  );

  useEffect(() => {
    function getMessage() {
      if (categoryList?.length) {
        if (selectedCategory) {
          return 'edit-asset-modal.data-backup.select-date-only';
        } else {
          return 'edit-asset-modal.data-backup.select-category-and-date';
        }
      }
    }

    setNoDataText(getMessage());
  }, [categoryList?.length, selectedCategory]);

  useEffect(() => {
    const hasActiveSubscription = subscriptionsData?.subscriptions.some(
      (sub) =>
        sub.attributes.some((att) => att.type === 'BACKUP_AND_RESTORE' && att.intValue === 1) &&
        !['EXPIRED', 'PENDING'].includes(sub.status) &&
        subscriptionsData?.currentLicenceId === sub.id
    );

    function getMessage() {
      if (!hasActiveSubscription) {
        return 'edit-asset-modal.data-backup.not-supported';
      } else {
        return 'edit-asset-modal.data-backup.no-files';
      }
    }

    subscriptionsData && setNoDataText(getMessage());
  }, [subscriptionsData]);

  function cancel() {
    editAssetService.dismiss(false);
  }

  return (
    <div className="max-height">
      <div className={styles.content}>
        <ControlSection
          organizationId={organizationId}
          assetId={+assetId}
          categoryList={categoryList}
          setCategoryList={setCategoryList}
          dateList={dateList}
          setDateList={setDateList}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setFileList={setFileList}
          timezone={timezone}
          whiteLabelDateFormat={whiteLabelDateFormat}
          setSubscriptionsData={setSubscriptionsData}
        />
        <DataSection
          fileList={fileList}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          noDataText={noDataText}
        />
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </div>
  );
}

export default DataBackup;
