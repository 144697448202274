import React, { useState, useEffect, useCallback } from 'react';
import { TableColunm } from '@components/Table/table.interface';
import Table from '@components/Table';
import TagsName from './TagsName';
import TagsValue from './TagsValue';
import TagsDescription from './TagsDescription';
import TagsAlias from './TagsAlias';
import styles from './TagsTable.scss';
import { useSelector } from '@redux/useSelector';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { httpService } from '@core/http/HttpService';
import { Tag, TagData } from '@modals/EditAssetModal/EditAssetModal.interface';
import { dynamicSort } from '@core/utils';

const colunmsCfg: Array<TableColunm> = [
  {
    Component: TagsName,
    label: 'asset-tags.name',
    style: { width: '23%', minWidth: '23%', maxWidth: '23%' },
    field: 'name',
    isSortable: true,
  },
  {
    Component: TagsValue,
    label: 'asset-tags.value',
    style: { width: '20%', minWidth: '20%', maxWidth: '20%' },
  },
  {
    Component: TagsDescription,
    label: 'asset-tags.description',
    style: { width: '32%', minWidth: '32%', maxWidth: '32%' },
  },
  {
    Component: TagsAlias,
    label: 'asset-tags.alias',
    style: { width: '25%', minWidth: '25%', maxWidth: '25%' },
  },
];

function TagsTable() {
  const [data, setData] = useState([]);
  const assetId = useSelector((state) => state.editAsset.general.assetId);
  const status = useSelector((state) => state.editAsset.staticData.status);
  const [_order, setOrder] = useState(null);

  const orderData = (firstInit: boolean, order?: any): Tag[] => {
    return data.sort(dynamicSort(order.label, order.orderSign));
  };

  const orderedData = _order ? orderData(false, _order) : data;

  useEffect(() => {
    if (status === 'DISCONNECTED' || status === 'ARCHIVED') {
      editAssetService.clearDataInterval('tags');
      getTagsData(assetId, false);
      return;
    }

    editAssetService.clearDataInterval('tags');
    editAssetService.initDataInterval(
      assetId,
      getTagsData,
      'tags',
      editAssetService.INTERVAL_TIMER
    );

    return () => editAssetService.clearDataInterval('tags');
  }, [status]);

  const getTagsData = useCallback((assetId: string, disableBI = true) => {
    httpService
      .api({
        type: 'getTagsData',
        urlParams: { assetId },
        disableBI,
      })
      .then((res: TagData) => {
        setData(res.tags);
      });
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={false}
        data={orderedData}
        getData={(firstInit, order) => {
          order && setOrder(order);
          orderData(firstInit, order);
        }}
        colunmsCfg={colunmsCfg}
        className={styles.tableBody}
      />
    </div>
  );
}

export default TagsTable;
