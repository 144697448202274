import { authService } from '@core/auth/AuthService';
import { LoginModel } from './LoginPage.interface';
import { i18nService } from '@core/i18n/I18nService';
import { buildErrorObj } from '@core/utils';
import { dispatch, getState } from '@src/redux/store';
import { setAccounts } from '@src/redux/accounts';

export const onSubmit = async (values: LoginModel, setErrorsMessage, grecaptcha) => {
  let captchaToken = '';
  grecaptcha &&
    (await grecaptcha.enterprise.execute().then((res) => {
      captchaToken = res;
    }));

  event?.preventDefault();
  if (!!values.username && !!values.password) {
    authService
      .login({
        username: values.username.toLowerCase(),
        password: values.password,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        captchaToken: captchaToken,
      })
      .then(async (res) => {
        dispatch(setAccounts(res));
        if (getState().login?.userOrganizationId) {
          await authService.handle2FaStatus();
        }
      })
      .catch((res: any) => {
        const error = res.data.non_field_errors[0];
        const message =
          typeof error === 'object'
            ? i18nService.translate(`errors.${error.code}`, undefined, buildErrorObj(error.keys))
            : error; // TODO: delete the option of server errors strings after server fox
        setErrorsMessage(message);
      });
  }
};

export const loadFormByStatus = (key, action, changeEmail, welcomeEmail, setLoadForm) => {
  if (key) {
    authService.logout();
    switch (action) {
      case 'CHANGE_EMAIL':
        changeEmail();
        break;
      case 'WELCOME':
        welcomeEmail();
        break;
      default:
        setLoadForm(true);
    }
  } else setLoadForm(true);
};
