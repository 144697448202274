import { decrementBusyCounter, incrementBusyCounter } from '@src/redux/config';
import { dispatch } from '@src/redux/store';
import axios from 'axios';
import { httpService } from './http/HttpService';
import { modalService } from './modals/ModalService';

class UploadService {
  async upload({
    getUploadType,
    file,
    moreError = undefined,
    fileName,
    urlParams = {},
    callback,
    imageType = 'IMAGE',
    csvDelimiter = ',',
  }) {
    try {
      dispatch(incrementBusyCounter());
      const uploadData: {
        uploadUrl: { fields: any; url: string };
        taskId: number;
      } = await httpService.api({
        type: getUploadType,
        urlParams,
        data:
          imageType === 'TRANSLATION' ? { fileName, csvDelimiter } : { fileName, type: imageType },
      });
      if (uploadData?.uploadUrl) {
        const res = await axios({
          url: uploadData?.uploadUrl?.url,
          data: this.buildFormData({ ...uploadData?.uploadUrl?.fields, file }),
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        this.startPolling({
          getUploadType,
          taskId: uploadData?.taskId,
          moreError,
          urlParams,
          callback,
        });
      }
    } catch (e) {
      console.log({ e });
      dispatch(decrementBusyCounter());
    }
  }

  async startPolling({ getUploadType, taskId, urlParams = {}, callback, moreError = undefined }) {
    try {
      const pollingData: {
        taskId: number;
        status: string;
        response: any;
        object?: any;
      } = await httpService.api({
        type: `${getUploadType}Result`,
        urlParams: { ...urlParams, taskId },
      });
      if (pollingData.taskId === taskId && pollingData.status === 'COMPLETED') {
        callback(pollingData?.object || pollingData?.response);
        dispatch(decrementBusyCounter());
      } else {
        setTimeout(
          () =>
            this.startPolling({
              getUploadType,
              taskId,
              urlParams,
              callback,
              moreError,
            }),
          1000
        );
      }
    } catch (e) {
      dispatch(decrementBusyCounter());
      if (e.status === 400 && e?.data?.response?.code === 32) {
        modalService.openModal('confirm', {
          text: `errors.${32}`,
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          headerText: `errors.${32}-header`,
          showCloseBtn: true,
        });
      } else if (moreError?.status === e.status) {
        modalService.openModal('confirm', {
          text: moreError.generalError?.length
            ? moreError.generalError.find((error) => error.value === e.data.response.generalError)
                .label
            : moreError.text,
          taskId: e.data.taskId,
          iconType: 'attention_image',
          confirmText: moreError.generalError?.length ? 'general.close' : 'general.confirm',
          headerText: moreError.headerText,
          showCloseBtn: true,
        });
      }
    }
  }

  buildFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  }
}

export const uploadService = new UploadService();
