import React from 'react';
import classNames from 'classnames';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { SpinnerProps } from './Spinner.interface.d';
import styles from './Spinner.scss';

const useStyles = makeStyles((theme: any) => ({
  root: {
    color: 'var(--systemFontSelected)'
  }
}));

function Spinner(props: SpinnerProps) {
  const { className, style, progressProps } = props;
  const classes = useStyles(props);

  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      <CircularProgress disableShrink {...progressProps} className={classes.root} />
    </div>
  );
}

export default React.memo(Spinner);
