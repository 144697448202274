import I18n from '@components/I18n';
import React, { useMemo } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import ActivitiesLogTab from '../ActivitiesLogTab/ActivitiesLogTab';
import EventsTab from '../EventsTab/EventsTab';
import TemplatesTab from '../TemplatesTab/TemplatesTab';
import styles from './EventsManagementTabs.scss';
import { getFlagStatus } from '@core/ffAndPermissions';

function EventsManagementTabs(props) {
  const showEventsTab = useMemo(() => getFlagStatus('event-management.events'), []);
  const showTemplateTab = useMemo(() => getFlagStatus('event-management.templates'), []);
  const showActivitiesTab = useMemo(() => getFlagStatus('event-management.activities-logs'), []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.pageTitle}>
          <I18n>events-management-page.events-management</I18n>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.tabs}>
          {showEventsTab && (
            <NavLink
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`${props.match.url}/events`}>
              <I18n>events-management.events</I18n>
            </NavLink>
          )}

          {showTemplateTab && (
            <NavLink
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`${props.match.url}/templates`}>
              <I18n>events-management.templates</I18n>
            </NavLink>
          )}

          {showActivitiesTab && (
            <NavLink
              className={styles.tab}
              activeClassName={styles.activeTab}
              to={`${props.match.url}/activitieslog`}>
              <I18n>events-management.activities-log</I18n>
            </NavLink>
          )}
        </div>

        <div className={styles.routsSection}>
          <Switch>
            {showEventsTab && (
              <Route exact path={`${props.match.url}/events`} component={EventsTab} />
            )}
            {showTemplateTab && (
              <Route exact path={`${props.match.url}/templates`} component={TemplatesTab} />
            )}
            {showActivitiesTab && (
              <Route exact path={`${props.match.url}/activitieslog`} component={ActivitiesLogTab} />
            )}
            <Redirect to={`${props.match.url}/events`} />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default EventsManagementTabs;
