import React from 'react';

import styles from './DashboardNavigation.scss';
import Select from '@components/Select';
import Icon from '@components/Icon';
import { ShowClearButton } from '@components/Select/Select';

const DashboardNavigation = ({
  value,
  onSelectedDashboard,
  onReset,
  dashboardOptions,
}) => {
  return (
    <div className={styles.dashboardNavigation}>
      <div className={styles.dashboardSelect}>
        <Select
          showClearButton={ShowClearButton.falseButAllowClearByBackSpace}
          options={dashboardOptions}
          value={value}
          onChange={onSelectedDashboard}
          unSelect={true}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </div>
      <Icon type="materialDelete" className={styles.removeIcon} onClick={() => onReset()}></Icon>
    </div>
  );
};

export default DashboardNavigation;
