import { WidgetMapItem } from '../widgetMap.interface';
import ComboWidget from '@components/widgets/ComboWidget';
import { stepThreeDefaultValidation } from '../widget.utils';
import { setMetrics } from './combo.utils';

export const combo: WidgetMapItem = {
  id: 'asset',
  name: 'asset',
  component: ComboWidget,
  minWidth: 336,
  minHeight: 64,
  fullSize: true,
  images: {
    canvas:
      'https://unicloud-public-prod-eu-west-1.s3.amazonaws.com/widgets_empty_states/empty_combo.svg',
    thumbnail: 'combo',
  },
  editModalSize: {},
  settings: {
    extraMetricsText: {
      value: () => 'create-widget-page.create-widget.step-three.combo-widget-metrics',
      condition: () => true,
    },
    dataChangesToHandle: ['metrics', 'calculations'],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
    },
    setDefaultDataByStep: {
      3: (scope, setWidgetData, widgetData) => {
        setMetrics(setWidgetData, widgetData);
      },
    },
    accordionDisable: ['sort', 'groupBy', 'filtering'],
    dragAndDropRules: {
      maxMetrics: 8,
    },
    disableGrouping: {
      text: () => 'create-widget-page.create-widget.step-three.combo-widget-grouping',
    },
    showPreviewData: ['metrics'],
    defaultScope: 'LAST_VALUE',
    disabledScope: ['RAW_DATA', 'AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'],
    defaultAssetProperties: [1, 2, 5],
    hiddenAssetProperties: [1, 2, 5],
    languageKeys: (isArWidget) => ['columnsDict.*', 'symbolDict.*', 'remoteAccessButtons.*'],
    customizationServerChanges: (customization) => {
      const { columnsDict, symbolDict } =
        customization.columns &&
        customization.columns.reduce(
          (result, column) => ({
            columnsDict: {
              ...result.columnsDict,
              [`${column.valueType.toLowerCase()}.${column.id}`]: `${column.displayName}`,
            },
            symbolDict: {
              ...result.symbolDict,
              [`${column.valueType.toLowerCase()}.${column.id}`]: column.symbol,
            },
          }),
          { columnsDict: {}, symbolDict: {} }
        );

      return {
        ...customization,
        columnsDict,
        symbolDict,
      };
    },
    hideFilterNavigation: () => false,
    allowAssetTypeNavigation: () => true,
  },
};
