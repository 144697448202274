import React, { useRef, useMemo, useEffect } from 'react';
import Panel from './Panel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 5,
    overflow: 'auto',
  },
}));

function SubscriptionsList(props) {
  const { subscriptionsData } = props;
  const { subscriptions = [], currentLicenceId } = subscriptionsData || {};
  const wrapper = useRef(null);
  const classes = useStyles(props);
  const openPanelIndex = useMemo(
    () => subscriptions.findIndex((s) => s.id === currentLicenceId),
    [subscriptions, currentLicenceId]
  );

  useEffect(() => {
    if (openPanelIndex > 0 && wrapper.current) {
      wrapper.current.scrollTo({
        top: openPanelIndex * 66,
        behavior: 'smooth',
      });
    }
  }, [openPanelIndex, wrapper]);

  return (
    <div className={classes.wrapper} ref={wrapper}>
      {subscriptions?.length
        ? subscriptions.map((subscription, idx) => (
            <Panel
              key={`${subscription.type}-${idx}`}
              isOpen={currentLicenceId === subscription.id}
              isActive={currentLicenceId === subscription.id}
              {...subscription}
            />
          ))
        : null}
    </div>
  );
}

export default SubscriptionsList;
