import React from 'react';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/styles';
import Icon from '@components/Icon';
import { SelectedHelpersProps } from './SelectedHelpers.interface';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px 29px 7px 29px',
    '@media (max-width: 1536px)': {
      padding: '30px 29px 7px 5px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    cursor: 'pointer',
    paddingLeft: 12,
    paddingRight: 16,
    maxWidth: 200,
  },
}));

const Button = ({ text, onClick, icon, classes }) => {
  return (
    <div onClick={onClick} className={classes.buttonWrapper}>
      <Icon style={{ paddingRight: 6 }} type={icon} color={'var(--systemFont)'} />
      <I18n>{text}</I18n>
    </div>
  );
};

function SelectedHelpers(props: SelectedHelpersProps) {
  const { expandSelected, collapseSelected, deleteSelected } = props;
  const classes = useStyles(props);
  const buttonsMap = [
    { text: 'organizations-page.expand-selected', icon: 'expendSelected', onClick: expandSelected },
    {
      text: 'organizations-page.collapse-selected',
      icon: 'collapseSelected',
      onClick: collapseSelected,
    },
    // { text: 'organizations-page.delete-selected', icon: 'deleteSelected', onClick: deleteSelected }
  ];
  return (
    <div className={classes.wrapper}>
      {buttonsMap.map((button) => (
        <Button key={button.text} classes={classes} {...button} />
      ))}
    </div>
  );
}

export default SelectedHelpers;
