import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import styles from './FormikFormModal.scss';
import FormikField from '@components/FormikField';
import * as Yup from 'yup';
import Icon from '@components/Icon';

function FormikFormModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const { header, initialValues, validation, onSubmit, formik, fieldsCfg } = args;

  function cancel() {
    dismiss(false);
  }

  function validate(values) {
    return [...fieldsCfg].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );
  }

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>{header}</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema([...fieldsCfg])}
        validate={validate}
        onSubmit={(values) => {
          onSubmit(values);
          dismiss(false);
        }}>
        {({ isValid, isSubmitting, values, errors, setFieldValue, dirty }) => {
          return (
            <FormikForm className={styles.modalForm}>
              <div className={styles.modalContent}>
                {fieldsCfg.map((field, idx) => (
                  <FormikField
                    key={idx}
                    editMode={true}
                    {...field}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
              <div className={styles.modalButtons}>
                <Button styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']} onClick={cancel}>
                  <I18n>general.cancel</I18n>
                </Button>
                <Button
                  styles={{ marginLeft: 13 }}
                  mode="bigFont"
                  type="submit"
                  disabled={!isValid && dirty}>
                  <I18n>general.save</I18n>
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default FormikFormModal;
