import Table from '@components/Table';
import { httpService } from '@core/http/HttpService';
import React, { useEffect, useMemo, useState } from 'react';
import MainStatisticsButton from './MainStatisticsButton/MainStatisticsButton';
import styles from './SubscriptionsTab.scss';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import SubFilterButton from './SubFilterButton/SubFilterButton';
import AssignButton from './AssignButton/AssignButton';
import SubscriptionData from './SubscriptionData/SubscriptionData';

const SubscrptionsTab = (props) => {
  const [data, setData] = useState() as any;
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const PAGE_SIZE = useMemo(() => 35, []);
  const [selectedMainFilter, setSelectedMainFilter] = useState('TOTAL');
  const [showExpired, setShowExpired] = useState(false);
  const [subFilter, setSubFilter] = useState();
  const [mainFiltersCount, setMainFiltersCount] = useState([]);
  const [subFilterButtons, setSubFilterButtons] = useState([]);
  const [ordering, setOrdering] = useState();

  const getSubscriptionsPlansSummary = async (showExpired: boolean) => {
    try {
      const res: any = await httpService.api({
        type: 'getSubscriptionsPlansSummary',
        query: {
          expired: showExpired,
        },
      });

      if (res) {
        setMainFiltersCount(res);
      }
    } catch (e) {}
  };

  const getSubscriptionsPlansSummaryByType = async (
    showExpired: boolean,
    selectedMainFilter: string
  ) => {
    try {
      const res: any = await httpService.api({
        type: 'getSubscriptionsPlansSummarByType',
        query: {
          expired: showExpired,
          status: selectedMainFilter,
        },
      });

      if (res) {
        const subButtons = res
          .filter((r) => r.count)
          .map((item) => {
            return {
              label: item.name,
              count: item.count,
              key: item.id,
            };
          });
        setSubFilterButtons(subButtons);
      }
    } catch (e) {}
  };

  const getData = async (
    firstInit = false,
    showExpired: boolean,
    selectedMainFilter: string,
    subFilter: number,
    order?
  ) => {
    try {
      setDisableLoad(true);

      const res: any = await httpService.api({
        type: 'getSubscriptionList',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: PAGE_SIZE,
          ordering: order ? `${order.orderSign}${order.label}` : null,
          expired: showExpired,
          status: selectedMainFilter,
          type: subFilter,
        },
      });
      if (res) {
        res.next && setDisableLoad(false);
        if (firstInit) {
          setData(res.results);
          setNextPage(2);
        } else {
          const newData = data.concat(res.results);
          setData(newData);
          setNextPage(nextPage + 1);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    getSubscriptionsPlansSummary(showExpired);
  }, [showExpired]);

  useEffect(() => {
    getSubscriptionsPlansSummaryByType(showExpired, selectedMainFilter);
  }, [selectedMainFilter, showExpired]);

  useEffect(() => {
    getData(true, showExpired, selectedMainFilter, subFilter, ordering);
  }, [selectedMainFilter, subFilter, showExpired]);

  const reloadData = () => {
    getSubscriptionsPlansSummary(showExpired);
    getData(true, showExpired, selectedMainFilter, subFilter, ordering);
  };

  const LabelComponent = (props) => {
    return (
      <div
        className="ellipsis-overflow"
        style={{ minWidth: '62px' }}
        title={`${props[props.field]}`}>
        {props[props.field]}
      </div>
    );
  };

  const colunmsCfg = [
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.licensePlan',
      field: 'licenseName',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.catalogNumber',
      field: 'catalogNumber',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.description',
      field: 'description',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.subscriptionKey',
      field: 'subscriptionKey',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: SubscriptionData,
      label: 'my-account-page.licensing-plan.table.endDate',
      field: 'endDate',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.assetName',
      field: 'assetName',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: LabelComponent,
      label: 'my-account-page.licensing-plan.table.assetId',
      field: 'assetId',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: SubscriptionData,
      label: 'my-account-page.licensing-plan.table.status',
      field: 'status',
      style: { width: 'calc(100% / 8)' },
      isSortable: true,
    },
    {
      Component: AssignButton,
      type: 'staticEnd',
      label: 'my-account-page.licensing-plan.table.assignSubscription',
      field: 'assign',
      style: { width: 150 },
      reloadData: reloadData,
    },
  ];

  const mainStatisticsButtons = useMemo(() => {
    return [
      {
        label: 'my-account-page.licensing-plan.total',
        count:
          mainFiltersCount && mainFiltersCount['TOTAL']
            ? mainFiltersCount && mainFiltersCount['TOTAL']
            : 0,
        key: 'TOTAL',
      },
      {
        label: 'my-account-page.licensing-plan.assigned',
        count:
          mainFiltersCount && mainFiltersCount['ASSIGNED']
            ? mainFiltersCount && mainFiltersCount['ASSIGNED']
            : 0,
        key: 'ASSIGNED',
      },
      {
        label: 'my-account-page.licensing-plan.unassigned',
        count:
          mainFiltersCount && mainFiltersCount['UNASSIGNED']
            ? mainFiltersCount && mainFiltersCount['UNASSIGNED']
            : 0,
        key: 'UNASSIGNED',
      },
      {
        label: 'my-account-page.licensing-plan.about-to-expire',
        count:
          mainFiltersCount && mainFiltersCount['ABOUT_TO_EXPIRE']
            ? mainFiltersCount && mainFiltersCount['ABOUT_TO_EXPIRE']
            : 0,
        key: 'ABOUT_TO_EXPIRE',
      },
    ];
  }, [mainFiltersCount]);

  const handleMainFilterClicked = (selectedFilter) => {
    setSelectedMainFilter(selectedFilter);
    setSubFilter(null);
  };

  const handleSubFilterClicked = (selectedFilter) => {
    if (subFilter === selectedFilter) setSubFilter(null);
    else setSubFilter(selectedFilter);
  };

  return (
    <div className={styles.container}>
      <div className={styles.statisticsButtons}>
        {mainStatisticsButtons.map((b) => (
          <MainStatisticsButton
            disabled={b.count === 0}
            label={b.label}
            count={b.count}
            key={b.key}
            filter={b.key}
            isActive={selectedMainFilter === b.key}
            onClick={handleMainFilterClicked}
          />
        ))}
        {selectedMainFilter !== 'ABOUT_TO_EXPIRE' && (
          <div className={styles.checkbox}>
            <MaterialCheckbox
              color="primary"
              checked={showExpired}
              onClick={() => setShowExpired(!showExpired)}
            />
            <I18n className={styles.checkboxText}>my-account-page.licensing-plan.show-expired</I18n>
          </div>
        )}
      </div>
      <div className={styles.secondatyStatisticsButtons}>
        {subFilterButtons.map((b, index) => (
          <SubFilterButton
            key={b.key + index}
            label={b.label}
            count={b.count}
            filter={b.key}
            isActive={subFilter === b.key}
            onClick={handleSubFilterClicked}
          />
        ))}
      </div>
      <Table
        className={styles.tableContainer}
        data={data || []}
        getData={(firstInit, order) => {
          setOrdering(order);
          getData(firstInit, showExpired, selectedMainFilter, subFilter, order);
        }}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
      />
    </div>
  );
};

export default SubscrptionsTab;
