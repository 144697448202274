import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from '@src/redux/useSelector';
import {
  metricsSelectedChange,
  getFilteredOperations,
  getSelectType,
} from '../../GroupingAndMeasures.utils';
import { cloneDeep } from 'lodash';
import Icon from '@components/Icon';
import Select from '@components/Select';
import styles from './MetricsTagComponent.scss';
import classNames from 'classnames';
import I18n from '@components/I18n';
import {
  editExpression,
  getDataSources,
  getExpressionToDisplay,
} from '@core/expressionUtilities/expressionUtilities';
import { getDataSourcesWithDataType } from '../Calculations/Calculations.utils';
import { i18nService } from '@core/i18n/I18nService';
import { useCalculations } from '../Calculations/useCalculations';
import ActionButtons from './ActionButtons';
import { dynamicSort } from '@core/utils';

function MetricsTagComponent(props) {
  const {
    metricsTag,
    availableData,
    idx,
    scope,
    widgetType,
    operations,
    widgetData,
    metricsSelected,
    setMetricsSelected,
    setWidgetData,
  } = props;
  const { onCalculationChanged } = useCalculations({ widgetData, setWidgetData });
  const languageId = useSelector((state) => state.config.languageId);
  let icon = metricsTag.type;
  if (metricsTag.valueType === 'ASSET_PROPERTY' && metricsTag.valueId === 3) icon = 'geoType';
  const isSelectType =
    metricsTag.valueType === 'CALCULATION'
      ? false
      : getSelectType(metricsTag.type, scope, widgetType) === 'SingleSelect';
  const isAggregated = scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE';
  const [filteredOptions, setFilteredOptions] = useState(operations);
  const tags = getDataSourcesWithDataType(widgetData.tags, 'assetTags');
  const tagTypes = getDataSourcesWithDataType(widgetData.tagTypes, 'tagTypes');
  const variables = getDataSourcesWithDataType(widgetData.variables, 'systemProperties');
  const showCalculationButton =
    widgetType !== 'alarms' &&
    (isAggregated || metricsTag.type === 'numberType' || metricsTag.type === 'floatType');

  const [editedCalculation, setEditedCalculation] = useState(metricsTag.calculation);

  const expressionToDisplay = useMemo(() => {
    return editedCalculation
      ? getExpressionToDisplay(editedCalculation.expression, widgetData.type)
      : null;
  }, [editedCalculation]);

  useEffect(() => {
    setFilteredOptions(
      getFilteredOperations(metricsTag, availableData, widgetType, scope, operations)
    );
    if (JSON.stringify(metricsTag.calculation) !== JSON.stringify(editedCalculation)) {
      setEditedCalculation(metricsTag.calculation);
    }
  }, [metricsTag]);

  const getSingleSelectWithTagName = () => {
    const selectedOption = operations.find((op) => op.value === metricsTag.operation) || null;
    const metricsSelected = cloneDeep(selectedOption);
    if (selectedOption) {
      metricsSelected.label = `${i18nService.translate(metricsTag.name)} ${i18nService.translate(
        metricsSelected.label,
        languageId
      )}`;
    }
    return metricsSelected;
  };

  const handleChange = async (key: string, value) => {
    let newCalculation = { ...editedCalculation, [key]: value };
    setEditedCalculation(newCalculation);

    onCalculationChanged(newCalculation);
  };

  return (
    <div className={styles.metric}>
      {isAggregated && isSelectType ? (
        <div
          className={styles.metricSelect}
          style={
            expressionToDisplay
              ? { minWidth: '50%', width: '50%' }
              : !showCalculationButton
              ? { marginRight: '12px' }
              : undefined
          }>
          <Select
            defaultValue={undefined}
            value={getSingleSelectWithTagName()}
            borderError={!getSingleSelectWithTagName()}
            options={filteredOptions}
            onChange={(e) =>
              metricsSelectedChange(
                e,
                widgetData,
                idx,
                'SingleSelect',
                metricsSelected,
                setWidgetData
              )
            }
            menuPortalTarget={document.body}
            placeholder={metricsTag.name}
          />
        </div>
      ) : (
        <div
          className={styles.metricName}
          style={expressionToDisplay ? { width: '40%' } : undefined}>
          <Icon className={styles.iconType} type={icon} />
          <div className="ellipsis-overflow" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <div className={styles.tagName}>
              <I18n>{metricsTag.name}</I18n>
              {isAggregated && metricsTag.valueType !== 'CALCULATION' && (
                <I18n className={styles.operation}>
                  create-widget-page.create-widget.step-three.count
                </I18n>
              )}
            </div>
          </div>
        </div>
      )}
      {expressionToDisplay && (
        <div
          title={expressionToDisplay}
          className={classNames(styles.expression, 'ellipsis-overflow')}>
          {expressionToDisplay}
        </div>
      )}
      {showCalculationButton && (
        <div
          className={styles.rightSideWrapper}
          style={{ width: metricsTag.valueType === 'CALCULATION' ? '80px' : '40px' }}>
          {metricsTag.valueType === 'CALCULATION' && (
            <ActionButtons
              metricsTag={metricsTag}
              widgetData={widgetData}
              setWidgetData={setWidgetData}
              setMetricsSelected={setMetricsSelected}
            />
          )}
          <div
            className={styles.calculationButton}
            style={{
              width: expressionToDisplay ? '50%' : '100%',
              justifyContent: 'space-between',
              display: 'flex',
            }}>
            <Icon
              type="calculation"
              color="var(--systemFont)"
              tooltipText={i18nService.translate('expression-builder.calculation.caption')}
              width={'24px'}
              height={'24px'}
              style={{ marginRight: '5px', verticalAlign: 'center' }}
              onClick={() => {
                editExpression(
                  editedCalculation?.expression,
                  [
                    ...getDataSources(widgetData, variables, 'VARIABLES').sort(
                      dynamicSort('name', '+')
                    ),
                    ...getDataSources(
                      widgetData,
                      widgetData.assetTypes.length > 1 ? tagTypes : tags
                    ).sort(dynamicSort('name', '+')),
                  ],
                  handleChange,
                  true
                );
              }}
              disabled={
                ['AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'].includes(scope) &&
                metricsTag.valueType !== 'CALCULATION' &&
                !metricsTag.operation
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MetricsTagComponent;
