class SessionService {
  getValue = (key, defaultValue) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  };

  setValue(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}

export const sessionService = new SessionService();
