import React, { useCallback } from 'react';
import Select from '@components/Select';

export const refreshRateOptions = [
  { label: 'dashboard-editor.as-default', value: null },
  ...Array.from({ length: 5 }, (_, i) => ({
    label: (5 - i).toString().padStart(1, ''),
    value: 5 - i,
  })),
  { label: 'dashboard-editor.none', value: 0 },
];

function RefreshRateSelect({ refreshRate, setRefreshRate }) {
  const value =
    refreshRate === null
      ? refreshRateOptions.find((option) => option.value === null)
      : refreshRateOptions.find((option) => option.value === refreshRate);

  const onChange = useCallback((selected) => {
    setRefreshRate(selected.value);
  }, []);

  return (
    <Select
      styles={{ control: { height: 37 }, menuPortal: { zIndex: 9999999999999 } }}
      menuPortalTarget={document.body}
      value={value}
      options={refreshRateOptions}
      onChange={onChange}
    />
  );
}

export default RefreshRateSelect;
