import React from 'react';
import { PLCData } from '@redux/redux.interface.d';
import style from './PLCAssetName.scss';
import classNames from 'classnames';

function PLCAssetName(props: PLCData) {
  const { name } = props.asset;

  return (
    <div className={classNames(style.text, 'ellipsis-overflow')} title={name}>
      {name}
    </div>
  );
}

export default PLCAssetName;
