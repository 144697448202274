import React from 'react';
import MaterialCheckbox from '@components/Checkbox';
import classNames from 'classnames';
import styles from '../StepTwo.scss';
import I18n from '@components/I18n';

const getLabel = (label, isCreatingEventTempldateDataSource) => {
  if (!isCreatingEventTempldateDataSource) return label;

  switch (label) {
    case 'create-widget-page.create-widget.step-two.lv-bottom-text':
      return 'create-event-data-source-page.create-event-data-source.step-two.lv-bottom-text';
    case 'create-widget-page.create-widget.step-two.rd-bottom-text':
      return 'create-event-data-source-page.create-event-data-source.step-two.rd-bottom-text';
  }

  return label;
};

const RadioBox = (props) => {
  const {
    scope,
    onSelectedScope,
    labels,
    name,
    disabled,
    extraLabels = {},
    isCreatingEventTempldateDataSource,
  } = props;

  return (
    <div
      className={
        disabled
          ? classNames(styles.radioBox, styles.optionDisabled)
          : scope === name
          ? classNames(styles.radioBox, styles.scopeSelected)
          : classNames(styles.radioBox, styles.pointer)
      }
      onClick={disabled ? null : (e) => onSelectedScope(name)}>
      <div className={styles.radioBoxButton}>
        <MaterialCheckbox
          disabled={disabled || scope === name}
          color="primary"
          checked={scope === name}
        />
      </div>
      <div className={styles.radioBoxBody}>
        <I18n element="div" noEllipsis className={styles.radioBoxLabel}>
          {getLabel(
            `create-widget-page.create-widget.step-two.${extraLabels[labels[0]] || labels[0]}`,
            isCreatingEventTempldateDataSource
          )}
        </I18n>
        <I18n className={classNames(styles.radioTopText)} noEllipsis element="div">
          {getLabel(
            `create-widget-page.create-widget.step-two.${extraLabels[labels[1]] || labels[1]}`,
            isCreatingEventTempldateDataSource
          )}
        </I18n>
        <I18n element="div" noEllipsis className={classNames(styles.radioBottomText)}>
          {getLabel(
            `create-widget-page.create-widget.step-two.${extraLabels[labels[2]] || labels[2]}`,
            isCreatingEventTempldateDataSource
          )}
        </I18n>
      </div>
    </div>
  );
};

export default RadioBox;
