import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './uploadLanguageModal.scss';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import CsvDelimiter from '@pages/MyAccountPage/RegionalSettings/Panels/CsvDelimiter';
import { useSelector } from '@src/redux/useSelector';
import store from '@src/redux/store';

function UploadLanguageModal(props) {
  const { dismiss } = props;
  // const [delimiter, setDelimiter] = useState();
  const whiteLabelDetails = store.getState().config?.whiteLabelDetails;
  const [delimiter, setDelimiter] = useState(
    () => whiteLabelDetails?.regionalSettings?.content?.delimiter
  );
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [selectedFile, setSelectedFile] = useState(null);

  const delimiterStyle = { display: 'block' };
  const selectContainerStyle = { display: 'flex', marginBottom: '20px' };
  const textFieldContainerStyle = { display: 'flex' };
  const selectLabelStyle = { minWidth: '120px', marginRight: '20px' };
  const selectStyle = { minWidth: '140px', height: '28px' };
  const textFieldLabelStyle = { minWidth: '120px', marginRight: '20px' };
  const textFieldStyle = { minWidth: '140px', height: '28px' };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept:
      'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setSelectedFile(acceptedFiles[0]);
      }
    },
  });

  const upload = () => {
    dismiss({ selectedFile, delimiter });
  };

  return (
    <div className={styles.wrapper}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
      </div>
      <div className={styles.modalHeader}>
        <I18n>languages.upload-language-header</I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n element="div" className={styles.text}>
          languages.upload-language-message
        </I18n>
        <CsvDelimiter
          delimiterStyle={delimiterStyle}
          selectContainerStyle={selectContainerStyle}
          textFieldContainerStyle={textFieldContainerStyle}
          selectStyle={selectStyle}
          selectLabelStyle={selectLabelStyle}
          textFieldLabelStyle={textFieldLabelStyle}
          textFieldStyle={textFieldStyle}
          delimiterCaption={'languages.delimiter-caption'}
          insertMarkCaption={''}
          regionalSettingsData={whiteLabelDetails}
          setDelimiter={setDelimiter}
        />
        {selectedFile && (
          <>
            <div className={styles.fileName}>
              <I18n className={styles.fileNameTitle}>languages.upload-language-file-name</I18n>
            </div>
            <div
              className={classNames(styles.fileNamePath, 'ellipsis-overflow')}
              title={selectedFile.name}>
              {selectedFile.name}
            </div>
          </>
        )}
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={cancel} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
          <I18n>general.cancel</I18n>
        </Button>
        <Button onClick={selectedFile ? upload : open} styles={{ marginLeft: 13 }} mode="bigFont">
          <I18n>
            {selectedFile ? 'languages.upload-language-upload' : 'languages.upload-language-browse'}
          </I18n>
        </Button>
      </div>
    </div>
  );
}

export default UploadLanguageModal;
