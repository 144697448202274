import React, { useCallback, useEffect, useRef } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { RemoteVPNModalProps } from './RemoteVPNModal.interface';
import styles from './RemoteVPNModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';

function RemoteVPNModal(props: RemoteVPNModalProps) {
  const { args, dismiss } = props;
  const { link } = args;
  const confirmBtn = useRef(null);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const confirm = useCallback(() => dismiss(true), [dismiss]);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  useEffect(() => {
    confirmBtn.current.focus();
  }, []);

  return (
    <div className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}>
      <div className={styles.modalHeader}>
        <I18n>assets-tab.vpn</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n noEllipsis element="div" className={styles.text}>
          assets-tab.vpn-modal.message
        </I18n>
        <br />
        <a className={styles.linkText} title={link} href={link}>
          {link}
        </a>
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={cancel} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
          <I18n>general.cancel</I18n>
        </Button>
        <Button onClick={confirm} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
          <I18n>general.start</I18n>
        </Button>
      </div>
    </div>
  );
}

export default RemoteVPNModal;
