import * as cloneDeep from 'lodash/cloneDeep';
import { getIsFloat, getMetricName, getTag } from '@pages/CreateWidgetPage/widgets.utils';
import { tagFormatMap } from '@core/mapsAndDefinitions';
import { concat } from 'lodash';

export const onColumnChanged = (column, idx, tableColumns, setWidgetData, customization) => {
  const _tableColumns = cloneDeep(tableColumns);
  _tableColumns[idx] = column;

  setWidgetData((prevState) => ({
    ...prevState,
    customization: Object.assign({}, customization, { columns: _tableColumns }),
  }));
};

const getData = (previewData, data, dataItem, widgetData, defaultDecimalDigits = 0) => {
  const valueId =
    (previewData &&
      Array.isArray(previewData?.columns) &&
      previewData?.columns[data?.counter] &&
      previewData?.columns[data?.counter].id) ||
    previewData?.columns[data?.counter].valueId ||
    '';

  const type =
    (previewData &&
      Array.isArray(previewData?.columns) &&
      previewData?.columns[data?.counter] &&
      previewData?.columns[data?.counter].format) ||
    '';

  const valueType =
    (previewData &&
      Array.isArray(previewData?.columns) &&
      previewData?.columns[data?.counter] &&
      previewData?.columns[data?.counter].valueType) ||
    null;

  const metricName = getMetricName(widgetData, {
    valueId: valueId,
    valueType: valueType,
  });

  const displayName = dataItem.operation
    ? `${metricName} ${dataItem.operation?.toLowerCase()}`
    : metricName;
  const originalTag = dataItem.valueType ? getTag(widgetData, dataItem) : dataItem;
  const format = originalTag.format || originalTag.type;
  const itemCalculation = Array.isArray(widgetData?.calculations)
    ? widgetData?.calculations.find(
        (calc) => calc.valueType === dataItem.valueType && calc.valueId === dataItem.valueId
      )
    : null;

  return {
    columns: [
      ...data.columns,
      {
        id: dataItem.valueId || dataItem.id,
        valueType,
        operation: dataItem.operation,
        name: dataItem.name,
        displayName,
        symbol: null,
        showSymbolLeft: false,
        decimalDigits: getIsFloat(
          dataItem.operation,
          dataItem.valueType,
          type
            ? tagFormatMap[type]
            : tagFormatMap[dataItem.format || dataItem.type] || dataItem.type,
          itemCalculation,
          widgetData
        )
          ? defaultDecimalDigits
          : null,
        tagType: format,
        dateTimeFormat:
          format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null,
      },
    ],
    counter: data?.counter + 1,
  };
};

const getDataForGroupByColumn = (previewData, data, dataItem, widgetData, defaultDecimalDigits) => {
  const metricName = getMetricName(widgetData, {
    valueId:
      previewData?.columns[data?.counter]?.id || previewData?.columns[data?.counter]?.valueId,
    valueType: previewData?.columns[data?.counter]?.valueType,
  });

  const displayName = dataItem.operation
    ? `${metricName} ${dataItem.operation?.toLowerCase()}`
    : metricName;
  const originalTag = dataItem.valueType ? getTag(widgetData, dataItem) : dataItem;
  const format = originalTag.format || originalTag.type;
  const itemCalculation = Array.isArray(widgetData?.calculations)
    ? widgetData?.calculations.find(
        (calc) => calc.valueType === dataItem.valueType && calc.valueId === dataItem.valueId
      )
    : null;

  return {
    columns: [
      ...data.columns,
      {
        id: dataItem.valueId,
        valueType: dataItem.valueType,
        operation: dataItem.operation,
        name: dataItem.name,
        displayName,
        symbol: null,
        showSymbolLeft: false,
        decimalDigits: getIsFloat(
          dataItem.operation,
          dataItem.valueType,
          format
            ? tagFormatMap[format]
            : tagFormatMap[dataItem.format || dataItem.type] || dataItem.type,
          itemCalculation,
          widgetData
        )
          ? defaultDecimalDigits
          : null,
        tagType: format,
        dateTimeFormat:
          format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null,
      },
    ],
    counter: data?.counter + 1,
  };
};

export const getTableCustomizationData = (
  widgetData,
  columns,
  previewData,
  setWidgetData,
  defaultDecimalDigits,
  notSet = false
) => {
  const { metrics, groupBy, scope } = widgetData as any;
  let _columns;
  const dataArray = concat(groupBy, metrics);

  const data = dataArray.reduce(
    (data: any, dataItem: any) => {
      if (groupBy?.length && (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE')) {
        return getDataForGroupByColumn(
          previewData,
          data,
          dataItem,
          widgetData,
          defaultDecimalDigits
        );
      } else {
        return getData(previewData, data, dataItem, widgetData, defaultDecimalDigits);
      }
    },
    { columns: [], counter: 0 }
  );

  _columns = data.columns;
  if (!notSet) {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        columns: _columns,
      },
    }));
  }
  return _columns;
};

export const updateColumns = (unOrderedColumns, columns) =>
  columns.map((col) => {
    const extraData = unOrderedColumns.find((u) => u.id === col.id) || {};
    return {
      ...extraData,
      ...col,
    };
  });

const SymbolMaxLength = 10;
const ColumnMaxLength = 30;
const ColumnMinLength = 1;
const isNumber = /^[0-9]*$/g;

export const handleNameChange = (
  displayName,
  editedColumn,
  setEditedColumn,
  setDisplayNameHasError
) => {
  if (displayName.length > ColumnMaxLength && displayName.length > editedColumn.displayName.length)
    return;
  validateNameError(displayName, setDisplayNameHasError);
  setEditedColumn({ ...editedColumn, displayName });
};

export const validateNameError = (displayName, setDisplayNameHasError) => {
  setDisplayNameHasError(!isNameValid(displayName));
};

export const isNameValid = (displayName) => {
  return displayName?.length <= ColumnMaxLength;
};

export const handleNameBlur = (editedColumn, setEditedColumn, props) => {
  const { column, index, provided, columnChanged } = props;
  const { displayName } = column;
  if (editedColumn.displayName.length < ColumnMinLength) setEditedColumn(column);
  else if (editedColumn.displayName !== displayName) columnChanged(editedColumn, index);
};

export const handleSymbolChange = (symbol: string, editedColumn, setEditedColumn) => {
  if (symbol.length > SymbolMaxLength) return;
  setEditedColumn({
    ...editedColumn,
    symbol,
    showSymbolLeft: symbol == '' ? false : editedColumn.showSymbolLeft,
  });
};

export const handleWidthChange = (width, setEditedColumn, editedColumn) => {
  setEditedColumn({
    ...editedColumn,
    width,
  });
};

export const handleWidthBlur = (editedColumn, props, setEditedColumn) => {
  const { column, index, columnChanged } = props;
  const { width } = column;
  let tempWidth =
    100 < +editedColumn.width ? 100 : 1 > +editedColumn.width ? 1 : +editedColumn.width;

  if (!Number.isInteger(tempWidth)) {
    tempWidth = +tempWidth.toFixed(2);
  }

  if (+tempWidth !== width) {
    columnChanged({ ...editedColumn, width: +tempWidth }, index);
  }
  setEditedColumn({ ...editedColumn, width: +tempWidth });
};

export const handleDecimalDigitChange = (decimalDigits: string, editedColumn, setEditedColumn) => {
  setEditedColumn({ ...editedColumn, decimalDigits: +decimalDigits });
};

export const handleDecimalDigitBlur = (editedColumn, props, setEditedColumn) => {
  const { column, index, columnChanged } = props;
  const { decimalDigits } = column;
  if (+editedColumn.decimalDigits !== decimalDigits) {
    columnChanged({ ...editedColumn, decimalDigits: +editedColumn.decimalDigits }, index);
    editedColumn.decimalDigits === '' && setEditedColumn({ ...editedColumn, decimalDigits: 0 });
  }
};

export const handleSymbolBlur = (editedColumn, props) => {
  const { column, index, columnChanged } = props;
  const { symbol } = column;
  if (editedColumn.symbol !== symbol) columnChanged(editedColumn, index);
};

export const onSelectedSymbolSide = (editedColumn, setEditedColumn, props) => {
  const { column, index, columnChanged } = props;
  const { showSymbolLeft } = column;
  const _column = cloneDeep({ ...editedColumn, showSymbolLeft: !showSymbolLeft });
  setEditedColumn(_column);
  columnChanged(_column, index);
};

export const onSelectedDateTimeFormat = (editedColumn, value, setEditedColumn, props) => {
  const { column, index, columnChanged } = props;
  const _column = cloneDeep({ ...editedColumn, dateTimeFormat: value });
  setEditedColumn(_column);
  columnChanged(_column, index);
};
