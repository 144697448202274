import { GaugeCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface.d';
import { hasValue, getVar } from '@core/canvas/widget.utils';
import { GaugeCustomizationSectionProps } from './GaugeCustomizationSection/GaugeCustomizationSection.interface';
import { SelectOption } from '@components/Select/Select.interface';
import { compact } from 'lodash';
import {
  getOrder,
  getSharedIdForLocalTagsAndCustMetrics,
  updateCustomizationCalculations,
} from '@pages/CreateWidgetPage/widgets.utils';

export const getDefaultGaugeCustomization = (defaultDecimalDigits: number): GaugeCustomization => ({
  decimalDigits: defaultDecimalDigits,
  title: '',
  units: null,
  zones: [
    {
      name: 'zone 1',
      color: '#6CE66C',
      minValue: 0,
      maxValue: 73,
    },
    {
      name: 'zone 2',
      color: '#008000',
      minValue: null,
      maxValue: null,
    },
    {
      name: 'zone 3',
      color: '#FFFF00',
      minValue: null,
      maxValue: null,
    },
    {
      name: 'zone 4',
      color: '#D86666',
      minValue: null,
      maxValue: null,
    },
    {
      name: 'zone 5',
      color: '#FF0000',
      minValue: null,
      maxValue: null,
    },
  ],
  ticks: {
    majorInterval: 10,
    majorStart: 0,
    majorEnd: 220,
    minorInterval: 5,
    majorColor: getVar('widgetsFont'),
    valueColor: getVar('widgetsFont'),
  },
  valueColor: getVar('widgetsFont'),
  dataColor: getVar('widgetsFont'),
  valueVisible: true,
  unitsColor: getVar('widgetsFont'),
  needleVisible: true,
});

export const getNewZones = () => [
  {
    name: 'zone 4',
    color: '#D86666',
    minValue: null,
    maxValue: null,
  },
  {
    name: 'zone 5',
    color: '#FF0000',
    minValue: null,
    maxValue: null,
  },
];

export const gaugeSidesOptions: SelectOption[] = [
  { label: 'enum.BOTH', value: 'BOTH' },
  { label: 'enum.LEFT', value: 'LEFT' },
  { label: 'enum.RIGHT', value: 'RIGHT' },
];

export const changeWidgetData = (property, value, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [property]: value,
    },
  }));
};

function changeWidgetTicksData(property, value, setWidgetData, widgetData = null) {
  let order = null;
  const valueId = getSharedIdForLocalTagsAndCustMetrics(widgetData);
  const ticks = ['majorStart', 'majorEnd', 'majorInterval', 'minorInterval'];

  if (ticks.includes(property) && !isFinite(value as any)) {
    order = getOrder(widgetData);
    updateCustomizationCalculations(setWidgetData, widgetData, value, 'CALCULATION', order);
  }

  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      ticks: {
        ...prevState.customization['ticks'],
        [property]:
          !isFinite(value as any) && order != null
            ? {
                valueType: 'CALCULATION',
                valueId: valueId || order,
                operation: null,
                order,
              }
            : value,
      },
    },
  }));
}
const changeWidgetZonesData = (zoneNumber, property, value, setWidgetData, widgetData = null) => {
  let order = null;

  const valueId = getSharedIdForLocalTagsAndCustMetrics(widgetData);
  if (property !== 'color' && !isFinite(value as any)) {
    order = getOrder(widgetData);
    updateCustomizationCalculations(setWidgetData, widgetData, value, 'CALCULATION', order);
  }

  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      zones: prevState.customization.zones.map((zone, idx) =>
        idx === zoneNumber - 1
          ? {
              ...zone,
              [property]:
                property === 'color'
                  ? value
                  : isFinite(value as any)
                  ? +value
                  : {
                      valueType: 'CALCULATION',
                      valueId: valueId || order,
                      operation: null,
                      order: order,
                    },
            }
          : zone
      ),
    },
  }));
};

const resetZoneByNumber = (zoneNumber, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      zones: prevState.customization.zones.map((zone, idx) =>
        idx === zoneNumber - 1 ? { ...zone, minValue: '', maxValue: '' } : zone
      ),
    },
  }));
};

export const getDataAndValueConfig = (
  customization: GaugeCustomization,
  setWidgetData
): GaugeCustomizationSectionProps[] => {
  const { title, dataColor, units, unitsColor, decimalDigits, valueVisible, valueColor } =
    customization;

  return [
    {
      sectionLabel: 'data-title',
      sectionValue: title,
      onChange: (val) => {
        if (val?.length > 15) return;
        changeWidgetData('title', val, setWidgetData);
      },
      type: 'text',
      fields: [
        {
          label: 'title-color',
          value: dataColor,
          onChange: (val) => changeWidgetData('dataColor', val, setWidgetData),
          disabled: !title?.length,
          type: 'color',
        },
      ],
    },
    {
      sectionLabel: 'value',
      fields: [
        {
          label: 'visible',
          value: valueVisible,
          onChange: (val) => changeWidgetData('valueVisible', val, setWidgetData),
          type: 'checkbox',
        },
        {
          label: 'decimal-digits',
          value: decimalDigits,
          onChange: (val) => {
            if (hasValue(val) && (val < 0 || val > 9)) return;
            if (!hasValue(val)) val = 0;
            changeWidgetData('decimalDigits', val, setWidgetData);
          },
          type: 'number',
          disabled: !valueVisible,
        },
        {
          label: 'value-color',
          value: valueColor,
          onChange: (val) => changeWidgetData('valueColor', val, setWidgetData),
          type: 'color',
          disabled: !valueVisible,
        },
      ],
    },
    {
      sectionLabel: 'data-units',
      sectionValue: units,
      onChange: (val) => {
        if (val?.length > 15) return;
        changeWidgetData('units', val, setWidgetData);
      },
      type: 'text',
      fields: [
        {
          label: 'unit-color',
          value: unitsColor,
          onChange: (val) => changeWidgetData('unitsColor', val, setWidgetData),
          type: 'color',
          disabled: !units?.length,
        },
      ],
    },
  ];
};

export const getDataTicksConfig = (
  customization: GaugeCustomization,
  setWidgetData,
  showValueFields,
  widgetData
): GaugeCustomizationSectionProps[] => {
  const { ticks, zones } = customization;

  return [
    {
      sectionLabel: 'data-ticks',
      onChange: (val) => changeWidgetTicksData('side', val, setWidgetData, widgetData),
      fields: compact([
        showValueFields && {
          label: 'show-side',
          value: gaugeSidesOptions.find((o) => o.value === ticks.side),
          optionsForSelect: gaugeSidesOptions,
          onChange: (val) => changeWidgetTicksData('side', val, setWidgetData, widgetData),
          type: 'select',
        },
        {
          label: 'data-ticks.major',
          type: 'label',
        },
        {
          label: 'data-ticks.start',
          value: ticks.majorStart,
          onChange: (val) => changeWidgetTicksData('majorStart', val, setWidgetData, widgetData),
          onBlur: (val) => {
            if (hasValue(val)) {
              // clears majorEnd if new value is gretaer
              if (+val > +ticks.majorEnd) {
                changeWidgetTicksData('majorEnd', '', setWidgetData, widgetData);
                onClearTicksMajorEnd(setWidgetData, zones);
              }
              // clears majorInterval if greater than the abs of (majorEnd - majorStart)
              if (+ticks.majorInterval > Math.abs(+ticks.majorEnd - +val)) {
                changeWidgetTicksData('majorInterval', '', setWidgetData, widgetData);
                // on clear magorInterval - minorInterval should be cleared too
                changeWidgetTicksData('minorInterval', '', setWidgetData, widgetData);
              }
              // clears zone[0].minValue if new value is gretaer
              if (hasValue(zones[0]?.minValue) && +zones[0].minValue < +val) {
                changeWidgetZonesData(1, 'minValue', '', setWidgetData, widgetData);
                onClearZoneMinValue(1, setWidgetData);
              }
            }
          },
          type: 'calcNumber',
          required: true,
        },
        {
          label: 'data-ticks.end',
          value: ticks.majorEnd,
          onChange: (val) => changeWidgetTicksData('majorEnd', val, setWidgetData, widgetData),
          onBlur: (val) => {
            !hasValue(val) && onClearTicksMajorEnd(setWidgetData, zones);
            if (hasValue(val)) {
              let needToReturn = false;
              if (+val <= +ticks.majorStart) {
                needToReturn = true;
                changeWidgetTicksData('majorEnd', '', setWidgetData, widgetData);
                onClearTicksMajorEnd(setWidgetData, zones);
              }
              if (+val < +ticks.majorInterval) {
                needToReturn = true;
                onClearTicksMajorEnd(setWidgetData, zones);
              }
              if (needToReturn) return;

              // clears majorInterval if greater than the abs of (majorEnd - majorStart)
              if (+ticks.majorInterval > Math.abs(+val - +ticks.majorStart)) {
                changeWidgetTicksData('majorInterval', '', setWidgetData, widgetData);
                changeWidgetTicksData('minorInterval', '', setWidgetData, widgetData);
              }
              // clears data in zones if greater than majorEnd
              zones.forEach((zone, idx) => {
                if (+zone.minValue >= +val) {
                  resetZoneByNumber(idx + 1, setWidgetData);
                } else if (+zone.maxValue > +val) {
                  changeWidgetZonesData(idx + 1, 'maxValue', '', setWidgetData, widgetData);
                }
              });
            }
          },
          type: 'calcNumber',
          required: true,
        },
        {
          label: 'data-ticks.interval',
          value: ticks.majorInterval,
          onChange: (val) => {
            if (hasValue(val) && (+val < 0 || +val > Math.abs(+ticks.majorEnd - +ticks.majorStart)))
              return;
            changeWidgetTicksData('majorInterval', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            !hasValue(val) && changeWidgetTicksData('minorInterval', '', setWidgetData, widgetData);
            // clears data of minorInterval if new value is smaller
            if (hasValue(val) && +val < +ticks.minorInterval) {
              changeWidgetTicksData('minorInterval', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          required: true,
        },
        {
          label: 'data-ticks.color',
          value: ticks.majorColor,
          onChange: (val) => changeWidgetTicksData('majorColor', val, setWidgetData),
          type: 'color',
        },
        {
          label: 'data-ticks.minor',
          type: 'label',
        },
        {
          label: 'data-ticks.interval',
          value: ticks.minorInterval,
          onChange: (val) => {
            if (hasValue(val) && (+val >= +ticks.majorInterval || +val < 0)) return;
            changeWidgetTicksData('minorInterval', val, setWidgetData, widgetData);
          },
          type: 'calcNumber',
        },
        {
          label: 'data-ticks.value',
          type: 'label',
        },
        showValueFields && {
          label: 'show-side',
          value: gaugeSidesOptions.find((o) => o.value === ticks.valueSide),
          optionsForSelect: gaugeSidesOptions,
          onChange: (val) => changeWidgetTicksData('valueSide', val, setWidgetData),
          type: 'select',
        },
        {
          label: 'data-ticks.color',
          value: ticks.valueColor,
          onChange: (val) => changeWidgetTicksData('valueColor', val, setWidgetData),
          type: 'color',
        },
      ]),
    },
  ];
};

export const getZonesConfig = (
  customization: GaugeCustomization,
  setWidgetData,
  widgetData
): GaugeCustomizationSectionProps[] => {
  const { ticks, zones } = customization;
  const [zone1, zone2, zone3, zone4, zone5] = zones;
  const calculationStyles = {
    expressionTextlWidth: '90px',
    zoneInputWidth: '220px',
    zoneColorWidth: '150px',
    noAsteriskPadding: '7px',
  };

  return [
    {
      sectionLabel: 'zone-1',
      styleType: 'row',
      calculationStyles: calculationStyles,
      fields: [
        {
          label: 'start-value',
          value: zone1?.minValue,
          onChange: (val) => {
            if (
              hasValue(val) &&
              isFinite(val as any) &&
              isFinite(ticks.majorEnd as any) + val >= +ticks.majorEnd
            )
              return;
            changeWidgetZonesData(1, 'minValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            !hasValue(val) && onClearZoneMinValue(1, setWidgetData);
            if (hasValue(val) && +val < +ticks.majorStart) {
              changeWidgetZonesData(1, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(1, setWidgetData);
            } else if (hasValue(val) && +val >= +zone1.maxValue) {
              changeWidgetZonesData(1, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          extraClass: 'smallLabel',
        },
        {
          label: 'end-value',
          value: zone1?.maxValue,
          onChange: (val) => {
            if (hasValue(val) && +val > +ticks.majorEnd) return;
            changeWidgetZonesData(1, 'maxValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            if (hasValue(val) && +val <= +zone1.minValue) {
              changeWidgetZonesData(1, 'maxValue', '', setWidgetData, widgetData);
            } else if (hasValue(val) && +val === +ticks.majorEnd) {
              resetZoneByNumber(2, setWidgetData);
              resetZoneByNumber(3, setWidgetData);
            } else if (hasValue(val) && +val > +zone2.minValue) {
              changeWidgetZonesData(2, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(2, setWidgetData);
            }
          },
          type: 'calcNumber',
          required: hasValue(zone1?.minValue),
          disabled: !hasValue(zone1?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'color',
          value: zone1?.color,
          onChange: (val) => changeWidgetZonesData(1, 'color', val, setWidgetData, widgetData),
          type: 'color',
          disabled: !hasValue(zone1?.minValue),
          extraClass: 'smallLabel',
        },
      ],
    },
    {
      sectionLabel: 'zone-2',
      styleType: 'row',
      calculationStyles: calculationStyles,
      fields: [
        {
          label: 'start-value',
          value: zone2?.minValue,
          onChange: (val) => {
            if (hasValue(val) && +val >= +ticks.majorEnd) return;
            changeWidgetZonesData(2, 'minValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            !hasValue(val) && onClearZoneMinValue(2, setWidgetData);
            if (hasValue(val) && +val < +zone1.maxValue) {
              changeWidgetZonesData(2, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(2, setWidgetData);
            } else if (hasValue(val) && +val >= +zone2.maxValue) {
              changeWidgetZonesData(2, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          disabled: !hasValue(zone1?.minValue) || +zone1?.maxValue === +ticks.majorEnd,
          extraClass: 'smallLabel',
        },
        {
          label: 'end-value',
          value: zone2?.maxValue,
          onChange: (val) => {
            if (hasValue(val) && +val > +ticks.majorEnd) return;
            changeWidgetZonesData(2, 'maxValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            if (hasValue(val) && +val <= +zone2.minValue) {
              changeWidgetZonesData(2, 'maxValue', '', setWidgetData, widgetData);
            } else if (hasValue(val) && +val === +ticks.majorEnd) {
              resetZoneByNumber(3, setWidgetData);
            } else if (hasValue(val) && +val > +zone3.minValue) {
              changeWidgetZonesData(3, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(3, setWidgetData);
            }
          },
          type: 'calcNumber',
          required: hasValue(zone2?.minValue),
          disabled: !hasValue(zone2?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'color',
          value: zone2?.color,
          onChange: (val) => changeWidgetZonesData(2, 'color', val, setWidgetData, widgetData),
          type: 'color',
          disabled: !hasValue(zone2?.minValue),
          extraClass: 'smallLabel',
        },
      ],
    },
    {
      sectionLabel: 'zone-3',
      styleType: 'row',
      calculationStyles: calculationStyles,
      fields: [
        {
          label: 'start-value',
          value: zone3?.minValue,
          onChange: (val) => {
            if (hasValue(val) && +val >= +ticks.majorEnd) return;
            changeWidgetZonesData(3, 'minValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            !hasValue(val) && onClearZoneMinValue(3, setWidgetData);
            if (hasValue(val) && +val < +zone2.maxValue) {
              changeWidgetZonesData(3, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(3, setWidgetData);
            } else if (hasValue(val) && +val >= +zone3.maxValue) {
              changeWidgetZonesData(3, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          disabled: !hasValue(zone2?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'end-value',
          value: zone3?.maxValue,
          onChange: (val) => {
            if (hasValue(val) && +val > +ticks.majorEnd) return;
            changeWidgetZonesData(3, 'maxValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            if (hasValue(val) && +val <= +zone3.minValue) {
              changeWidgetZonesData(3, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          required: hasValue(zone3?.minValue),
          disabled: !hasValue(zone3?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'color',
          value: zone3?.color,
          onChange: (val) => changeWidgetZonesData(3, 'color', val, setWidgetData, widgetData),
          type: 'color',
          disabled: !hasValue(zone3?.minValue),
          extraClass: 'smallLabel',
        },
      ],
    },
    {
      sectionLabel: 'zone-4',
      styleType: 'row',
      calculationStyles: calculationStyles,
      fields: [
        {
          label: 'start-value',
          value: zone4?.minValue,
          onChange: (val) => {
            if (hasValue(val) && +val >= +ticks.majorEnd) return;
            changeWidgetZonesData(4, 'minValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            !hasValue(val) && onClearZoneMinValue(4, setWidgetData);
            if (hasValue(val) && +val < +zone3.maxValue) {
              changeWidgetZonesData(4, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(4, setWidgetData);
            } else if (hasValue(val) && +val >= +zone4.maxValue) {
              changeWidgetZonesData(4, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          disabled: !hasValue(zone3?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'end-value',
          value: zone4?.maxValue,
          onChange: (val) => {
            if (hasValue(val) && +val > +ticks.majorEnd) return;
            changeWidgetZonesData(4, 'maxValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            if (hasValue(val) && +val <= +zone4.minValue) {
              changeWidgetZonesData(4, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          required: hasValue(zone4?.minValue),
          disabled: !hasValue(zone4?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'color',
          value: zone4?.color,
          onChange: (val) => changeWidgetZonesData(4, 'color', val, setWidgetData, widgetData),
          type: 'color',
          disabled: !hasValue(zone4?.minValue),
          extraClass: 'smallLabel',
        },
      ],
    },
    {
      sectionLabel: 'zone-5',
      styleType: 'row',
      calculationStyles: calculationStyles,
      fields: [
        {
          label: 'start-value',
          value: zone5?.minValue,
          onChange: (val) => {
            if (hasValue(val) && +val >= +ticks.majorEnd) return;
            changeWidgetZonesData(5, 'minValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            !hasValue(val) && onClearZoneMinValue(5, setWidgetData);
            if (hasValue(val) && +val < +zone4.maxValue) {
              changeWidgetZonesData(5, 'minValue', '', setWidgetData, widgetData);
              onClearZoneMinValue(5, setWidgetData);
            } else if (hasValue(val) && +val >= +zone5.maxValue) {
              changeWidgetZonesData(5, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          disabled: !hasValue(zone4?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'end-value',
          value: zone5?.maxValue,
          onChange: (val) => {
            if (hasValue(val) && +val > +ticks.majorEnd) return;
            changeWidgetZonesData(5, 'maxValue', val, setWidgetData, widgetData);
          },
          onBlur: (val) => {
            if (hasValue(val) && +val <= +zone5.minValue) {
              changeWidgetZonesData(5, 'maxValue', '', setWidgetData, widgetData);
            }
          },
          type: 'calcNumber',
          required: hasValue(zone5?.minValue),
          disabled: !hasValue(zone5?.minValue),
          extraClass: 'smallLabel',
        },
        {
          label: 'color',
          value: zone5?.color,
          onChange: (val) => changeWidgetZonesData(5, 'color', val, setWidgetData, widgetData),
          type: 'color',
          disabled: !hasValue(zone5?.minValue),
          extraClass: 'smallLabel',
        },
      ],
    },
  ];
};

const onClearZoneMinValue = (zoneNumber, setWidgetData) => {
  // If creator cleared this value:
  //  1. Clears zones[idx].maxValue field
  changeWidgetZonesData(zoneNumber, 'maxValue', '', setWidgetData);
  //  2. Clears next zones fields
  let nextZoneNumber = zoneNumber + 1;
  while (nextZoneNumber <= 5) {
    resetZoneByNumber(nextZoneNumber, setWidgetData);
    nextZoneNumber++;
  }
};

const onClearTicksMajorEnd = (setWidgetData, zones) => {
  changeWidgetTicksData('majorInterval', '', setWidgetData);
  changeWidgetTicksData('minorInterval', '', setWidgetData);
  zones.forEach((zone, idx) => {
    resetZoneByNumber(idx + 1, setWidgetData);
  });
};
