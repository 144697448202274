import * as cloneDeep from 'lodash/cloneDeep';
import { flatten } from 'lodash';
import { Subject } from '@core/Subject';
import { getState } from '@redux/store';
import { DashboardEditorState } from '@redux/redux.interface.d';
import { LiveWidget } from './layoutService.interface.d';
import { LayoutGenerator } from './LayoutGenerator';
import { editorConfig } from './editorConfig';
import { defaultWidthMap } from '@pages/DashboardEditorPage/DashboardEditorLayout.utils';
import { cssVarsService } from '@core/CssVarsService';

/**
 * Generates a responsive layout based on wrapper dimensions
 * and widgets array.
 */
class LayoutService {
  widgets$ = new Subject<LiveWidget[]>([]);

  /**
   * Imports the canvasWidgets data from the dashboardEditor.
   * Uses a deep copy.
   */
  importWidgetsFromEditor() {
    const editorWidgets = getState().dashboardEditor.canvasWidgets;

    // Sorting the widgets by their top position helps with the layout algorithm.
    const sorted: LiveWidget[] = cloneDeep(editorWidgets).sort((w1, w2) => w1.y - w2.y);

    this.widgets$.next(sorted);
  }

  importWidgetsFromDashboard(dashboard: DashboardEditorState) {
    // Sorting the widgets by their top position helps with the layout algorithm.
    const sorted: LiveWidget[] = cloneDeep(dashboard.canvasWidgets).sort((w1, w2) => w1.y - w2.y);

    this.widgets$.next(sorted);
  }

  /**
   * Generates a responsive layout based on container width.
   * Will push the new layout to the widgets$ subject.
   */
  generateLayout(width: number) {
    const layoutGenerator = new LayoutGenerator(width, this.widgets$.getValue());
    const widgets = layoutGenerator.generateLayout();
    this.widgets$.next(widgets);
  }

  adjustWidgetWidthInRow = (
    widgetStates: Array<any>,
    canvasWidth: number,
    sideBarWidth: number
  ) => {
    const cellSize = editorConfig.gridCellSizePx;
    const statesGrouped = widgetStates.reduce((group, state) => {
      const { y } = state;
      group[y] = group[y] ?? [];
      group[y].push(state);
      return group;
    }, {});

    const newStates = Object.values(statesGrouped).map((item) => {
      if (Array.isArray(item) && item.length > 1) {
        let row = [];
        const rowWidth = item.reduce((acc, cur) => acc + cur.w, 0);
        rowWidth * cellSize < canvasWidth - sideBarWidth
          ? (row = item)
          : item.forEach((state, index) => {
              const w = Math.floor((canvasWidth / cellSize - 4) / item.length - 3);
              const x = index === 0 ? state.x : item[index - 1].x + w + 1;
              row.unshift({
                ...state,
                w: w,
                x: x,
              });
            });
        return row;
      } else {
        return item;
      }
    });

    return flatten(newStates);
  };

  calculateCanvasWidgetStates(layoutStateType: string, canvasWidgets: Array<any>) {
    const width = defaultWidthMap.get(layoutStateType);
    const newWidgets = new Subject<LiveWidget[]>(canvasWidgets);
    const layoutGenerator = new LayoutGenerator(width, newWidgets.getValue());
    const widgets = layoutGenerator.generateLayout();
    const sideBarWidth = layoutStateType === 'MOBILE' ? 0 : cssVarsService.vars.sideBarWidth || 52;

    const cellSize = editorConfig.gridCellSizePx;
    const calcNewCoord = (current: number, original: number) =>
      (current - original * cellSize) / cellSize + original;
    
      const newStates = widgets.map((w) => {
      let x = Math.trunc(calcNewCoord(w.position.left, w.originalPosition.left));
      let y = Math.trunc(calcNewCoord(w.position.top, w.originalPosition.top));
      let newState = {
        x: x >= 1 ? Math.trunc(x) : 1,
        y: x >= 1 ? Math.trunc(y) : 1,
      };

      return w.w * cellSize < width - sideBarWidth
        ? {
            id: w.id,
            ...newState,
            h: w.h,
            w: w.w,
          }
        : {
            id: w.id,
            ...newState,
            h: w.h,
            w: Math.floor((width - sideBarWidth) / cellSize - 1),
            x: 0,
          };
    });

    return this.adjustWidgetWidthInRow(newStates, width, sideBarWidth);
  }
}

export const layoutService = new LayoutService();
