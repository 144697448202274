import { CanvasWidget } from '@src/redux/redux.interface';
import {
  getAllWidgetsWithCalculatedCoords,
  getExtremeValue,
  getIntersectingWidgets,
  getMiddleValue,
} from './ArrangementPopover.utils';

export const optionVsCoordinateMap = new Map([
  ['left', { coordinate: 'x' }],
  ['center', { coordinate: 'x' }],
  ['right', { coordinate: 'x' }],
  ['top', { coordinate: 'y' }],
  ['middle', { coordinate: 'y' }],
  ['bottom', { coordinate: 'y' }],
]);

export function calcWrappingRectangle(selectedWidgets: Array<any>) {
  const leftPoints = selectedWidgets.map((widget) => {
    return widget.x;
  });
  const topPoints = selectedWidgets.map((widget) => {
    return widget.y;
  });
  const rightPoints = selectedWidgets.map((widget) => {
    return widget.x + widget.w;
  });
  const bottomPoints = selectedWidgets.map((widget) => {
    return widget.y + widget.h;
  });

  const left = getExtremeValue(leftPoints, 'min');
  const right = getExtremeValue(rightPoints, 'max');
  const top = getExtremeValue(topPoints, 'min');
  const bottom = getExtremeValue(bottomPoints, 'max');

  return {
    left: left,
    right: right,
    top: top,
    bottom: bottom,
    center: getMiddleValue(left, right),
    middle: getMiddleValue(top, bottom),
  };
}

export function calcAlignedWidgetCoordinates(
  selectedWidgets: Array<CanvasWidget>,
  option: string
): {
  newWidgets: Array<CanvasWidget>;
  notAllowedWidgets: Array<CanvasWidget>;
} {
  const coordinate = optionVsCoordinateMap.get(option).coordinate;

  const wrappingRectangle = calcWrappingRectangle(selectedWidgets);

  const calculatedWidgets =
    option === 'left' || option === 'top'
      ? selectedWidgets.map((w) => {
          if (w[coordinate] === wrappingRectangle[coordinate]) {
            return w;
          }
          return { ...w, [coordinate]: wrappingRectangle[option] };
        })
      : selectedWidgets.map((w) => {
          return { ...w, [coordinate]: calcSingleAlignedCoordinate(w, wrappingRectangle, option) };
        });

  const allWidgetsWithCalculatedCoords = getAllWidgetsWithCalculatedCoords(calculatedWidgets);

  const intersectingWidgets = getIntersectingWidgets(
    calculatedWidgets,
    allWidgetsWithCalculatedCoords
  );

  return {
    newWidgets: calculatedWidgets,
    notAllowedWidgets: intersectingWidgets,
  };
}

function calcSingleAlignedCoordinate(widget: CanvasWidget, wrappingRectangle: any, option) {
  switch (option) {
    case 'right':
      return wrappingRectangle.right - widget.w;
    case 'center':
      return wrappingRectangle.center - Math.ceil(widget.w / 2);
    case 'bottom':
      return wrappingRectangle.bottom - widget.h;
    case 'middle':
      return wrappingRectangle.middle - Math.ceil(widget.h / 2);
    default:
      return widget[optionVsCoordinateMap.get(option).coordinate];
  }
}
