import VariableValueComponent from '@components/VariableValueComponent';
import TextField from '@components/Table/TableFields/TextField';
import { httpService } from '@core/http/HttpService';
import ActionButtons from './ActionButtons/ActionButtons';
import { origin } from '@pages/OrganizationsPage/Variables/Variables.utils';

export function getColunmsCfg(
  origin: origin,
  variableId,
  valueType,
  managementLevel,
  setVariablesList,
  editedVariableId,
  setEditedVariableId,
  editedAssetId,
  setEditedAssetId,
  assetTypeId,
  order
) {
  const style = { minWidth: '20%', maxWidth: '20%' };

  return [
    {
      Component: TextField,
      field: 'owner',
      label: 'assets-tab.owner',
      style: style,
      isSortable: true,
    },
    {
      Component: TextField,
      field: 'assetId',
      label: 'assets-tab.asset-id',
      style: style,
      isSortable: true,
    },
    {
      Component: TextField,
      field: 'assetName',
      label: 'assets-tab.asset-name',
      style: { minWidth: '25%', maxWidth: '25%' },
      isSortable: true,
    },
    {
      Component: VariableValueComponent,
      field: 'value',
      label: 'general.value',
      style: { width: '240px', minWidth: '240px' },
      isSortable: false,
      origin,
      assetTypeId,
      variableId,
      valueType,
      editedVariableId,
      setEditedVariableId,
      editedAssetId,
      setEditedAssetId,
      setVariablesList,
      isVisible: true,
      inputStyle: { width: '220px', minWidth: '220px' },
    },
    {
      Component: ActionButtons,
      type: 'staticEnd',
      label: '',
      style: { width: 180 },
      isSortable: false,
      origin,
      variableId,
      assetTypeId,
      setVariablesList,
      setEditedVariableId,
      setEditedAssetId,
      isVisible: true,
      order,
      managementLevel,
    },
  ];
}

export function getVariablesList(id: number, setVariablesList) {
  httpService
    .api({
      type: 'getVariableValuesPerAsset',
      urlParams: { id },
    })
    .then((res) => {
      setVariablesList(res);
    });
}
