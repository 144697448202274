import React, { useState } from 'react';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import I18n from '@components/I18n';
import Tooltip from '@components/Tooltip';
import Icon from '@components/Icon';
import styles from './AvailableData.scss';
import LocalSearch from '@components/LocalSearch';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from '@src/redux/useSelector';

function AvailableData(props) {
  const { availableData, disabled, widgetData } = props;
  const languageId = useSelector((state) => state.config.languageId);

  const [searchValue, setSearchValue] = useState('');

  const onSearch = (e) => {
    setSearchValue(e);
  };

  return (
    <div className={styles.filtersAvailableData}>
      <div className={styles.title}>
        <div className={styles.infoGroup}>
          <I18n className={styles.subTitle}>
            create-widget-page.create-widget.step-three.available-data
          </I18n>
          <Tooltip
            text={
              !widgetData.eventTemplateId
                ? 'create-widget-page.create-widget.step-three.filters-available-data-info'
                : 'create-event-data-source-page.create-event-data-source.step-three.filters-available-data-info'
            }
            placement="right"
            children={
              <div>
                <Icon className={styles.iconInfo} type="info"></Icon>
              </div>
            }></Tooltip>
        </div>
        <LocalSearch disableClick={true} searchFunc={onSearch}></LocalSearch>
      </div>

      <Droppable droppableId="filteringAvailableData" isDropDisabled={disabled}>
        {(provided) => (
          <div className={styles.dataTriangle} {...provided.droppableProps} ref={provided.innerRef}>
            <div className={styles.data}>
              {availableData
                .map((a, idx) => ({ ...a, dragIndex: idx }))
                .filter((item) => {
                  return searchValue
                    ? i18nService
                        .translate(item.name)
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    : item;
                })
                .map((tag) => (
                  <Draggable
                    key={`${tag.tagType}_${tag.id}_${tag.dragIndex}`}
                    draggableId={`${tag.tagType}_${tag.id}_${tag.dragIndex}`}
                    index={tag.dragIndex}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames(
                          styles.tagData,
                          tag.isDisabled && styles.tagDataDisabled
                        )}>
                        <div className={styles.dragIndicator} {...provided.dragHandleProps}>
                          <Icon type="drag" />
                        </div>
                        <div className={styles.tag}>
                          <Icon
                            className={styles.iconType}
                            type={
                              tag.tagType === 'ASSET_PROPERTY' && tag.id === 3
                                ? 'geoType'
                                : tag.type
                            }
                          />
                          <div className={classNames(styles.tagName, 'ellipsis-overflow')}>
                            <I18n>
                              {tag.operation
                                ? `${i18nService.translate(
                                    tag.name,
                                    languageId
                                  )} - ${i18nService.translate(
                                    `enum.${tag.operation.toUpperCase()}`,
                                    languageId
                                  )}`
                                : tag.name}
                            </I18n>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default AvailableData;
