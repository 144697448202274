import I18n from '@components/I18n';
import React, { useEffect } from 'react';
import MaterialCheckbox from '@components/Checkbox';
import styles from './WeeklyTrigger.scss';

const daysOfWeekOptions = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

function WeeklyTrigger(props) {
  const { templateData, setTemplateData } = props;
  const { daysOfWeek } = templateData;

  const handleDaysOfWeekChanged = (d) => {
    let newDaysOfWeek = (daysOfWeek && [...daysOfWeek]) || [];
    if (!newDaysOfWeek.some((dw) => dw === d)) newDaysOfWeek.push(d);
    else newDaysOfWeek = newDaysOfWeek.filter((dw) => dw !== d);

    setTemplateData(newDaysOfWeek, 'daysOfWeek');
  };

  return (
    <div className={styles.rowAlignTop}>
      <div className={styles.header}>
        <I18n noEllipsis>events-template.edit-template.trigger.days-of-week</I18n>
      </div>
      <div className={styles.daysOfWeek}>
        {daysOfWeekOptions.map((d) => (
          <div className={styles.dayCheckbox} key={d}>
            <MaterialCheckbox
              color="primary"
              onChange={() => handleDaysOfWeekChanged(d)}
              checked={daysOfWeek?.some((dw) => dw === d)}
            />
            <I18n style={{ marginLeft: '3px', fontWeight: '600', fontSize: '12px' }}>
              {`events-template.edit-template.trigger.days-of-week.${d.toLocaleLowerCase()}`}
            </I18n>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WeeklyTrigger;
