export const staticDictionary = {
  'enum.LEGACY': 'Vision/Samba/Jazz',
  'enum.UNISTREAM': 'Unistream',
  'enum.MODBUS': 'Modbus',
  'enum.TRUE': 'True',
  'enum.FALSE': 'False',
  'enum.TRUE_ACTIVE_ALARM': 'On',
  'enum.FALSE_ACTIVE_ALARM': 'Off',
  'enum.CONNECTED_CRITICAL_ALARM': 'Critical Alarm',
  'enum.CONNECTED_MAJOR_ALARM': 'Major Alarm',
  'enum.COMMUNICATION_ERROR': 'Communication Error',
  'enum.CONNECTED_WARNING': 'Warning',
  'enum.CONNECTED_MINOR_ALARM': 'Minor Alarm',
  'enum.DISCONNECTED': 'Disconnected',
  'enum.AVAILABLE': 'Available',
  'enum.CONNECTED_OK': 'Connected',
  'enum.ARCHIVED': 'Archived',
  'enum.PENDING_ROUTER': 'Pending Router',
  'enum.ASSET_TYPE_UPDATING': 'Asset Type Updating',
};
