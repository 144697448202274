import React, { useMemo } from 'react';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { makeStyles } from '@material-ui/core';
import IconButtonWithText from '@components/IconButtonWithText';
import { openResetValueModal } from '@pages/OrganizationsPage/Variables/Variables.utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonWithText: {
    display: 'flex',
  },
  text: {
    margin: '2px 10px',
    fontSize: '14px',
  },
}));

function ActionButtons(props) {
  const {
    origin,
    assetId,
    setEditedVariableId,
    setEditedAssetId,
    value,
    defaultValue,
    setVariablesList,
    variableId,
    order,
    managementLevel,
  } = props;
  const classes = useStyles(props);
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);
  const showReset = value !== defaultValue && managementLevel !== 'GLOBAL';

  const buttons = [
    {
      type: 'edit',
      name: 'general.update',
      tooltip: 'general.update',
      onClick: () => {
        if (managementLevel !== 'GLOBAL') {
          setEditedVariableId(variableId);
          setEditedAssetId(assetId);
        }
      },
      isDisabled: !hasPermission,
      isVisible: managementLevel !== 'GLOBAL',
    },
    {
      type: 'reset',
      name: 'general.reset',
      tooltip: 'general.reset',
      onClick: () => {
        setEditedVariableId(null);
        setEditedAssetId(null);
        openResetValueModal(origin, assetId, variableId, setVariablesList, order);
      },
      isDisabled: !hasPermission,
      isVisible: showReset,
    },
  ];

  return (
    <div className={classes.wrapper}>
      {buttons.map((button, idx) =>
        button.isVisible ? (
          <IconButtonWithText
            key={idx}
            iconType={button.type}
            name={button.name}
            showName
            tooltip={button.tooltip}
            onClick={!button.isDisabled ? button.onClick : null}
            isDisabled={button.isDisabled}
          />
        ) : null
      )}
    </div>
  );
}

export default ActionButtons;
