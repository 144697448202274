import React from 'react';
import Icon from '@components/Icon';

function LanguageDefault(props) {
  const { isMBDefaultLanguage } = props;

  return <div>{isMBDefaultLanguage ? <Icon type="validation_succeded" /> : null}</div>;
}

export default LanguageDefault;
