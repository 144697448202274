import React, { useMemo } from 'react';
import CanvasWidget from '@components/canvas/CanvasWidget';
import { LiveWidget } from '@core/canvas/layoutService.interface';
import styles from './LiveWidgetWrapper.scss';

function LiveWidgetGroupItem(props: { widget: LiveWidget; parent: LiveWidget }) {
  const { widget, parent } = props;

  const groupInnerS = useMemo(() => {
    return widget.position && parent.position
      ? {
          top: widget.position.top - parent.position.top,
          left: widget.position.left - parent.position.left,
          height: widget.position.bottom - widget.position.top,
          width: widget.position.right - widget.position.left,
        }
      : undefined;
  }, [widget.position, parent.position]);

  if (!widget.position) {
    return null;
  }

  return (
    <div className={styles.groupInner} style={groupInnerS}>
      <div className={styles.inner}>
        <CanvasWidget widgetType={widget.type} widget={widget} isLive />
      </div>
    </div>
  );
}

export default React.memo(LiveWidgetGroupItem);
