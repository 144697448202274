import React from 'react';
import classNames from 'classnames';

import styles from './DashboardFilterInput.scss';
import FilterInput from './FilterInput';
import { DashboardFilterInputProps } from './DashboardFilterInput.interface';
import { i18nService } from '@core/i18n/I18nService';

function DashboardFilterInput(props: DashboardFilterInputProps) {
  const { filter, isMobileView } =
    props;
  const filterNameLabels = {
    GEO: i18nService.translate('filters-widget.GEO'),
    ASSET_TYPES: i18nService.translate('filters-widget.ASSET_TYPES'),
    DATE: i18nService.translate('filters-widget.DATE'),
    ORGANIZATIONS: i18nService.translate('filters-widget.ORGANIZATIONS'),
    ASSET: i18nService.translate('filters-widget.ASSET'),
  };

  return (
    <div className={classNames(styles.filterWrapper, isMobileView && styles.mobileMode)}>
      <div
        className={classNames(
          'ellipsis-overflow',
          styles.dashboardFilterName,
          !filter.editable && styles.nameDisabled
        )}>
        {filterNameLabels[filter.filterType]}
      </div>
      <div className={styles.dashboardFilterInput}>
        <FilterInput {...props} />
      </div>
    </div>
  );
}

export default DashboardFilterInput;
