import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ActivitiesLogTab.scss';
import ActivitiesLogTable from './ActivitiesLogTable/ActivitiesLogTable';
import ActivitiesToolBar from './ActivitiesToolBar/ActivitiesToolBar';
import { DateFilterData } from './ActivitiesLogTab.interface';
import moment from 'moment';

const dateFilterInitial = {
  dateFilter: 'MONTH',
  dateFilterFrom: null,
  dateFilterTo: null,
};

function ActivitiesLogTab() {
  const [numberOfSMS, setNumberOfSMS] = useState('');
  const [numberOfEmails, setNumberOfEmails] = useState('');
  const [numberOfPushes, setNumberOfPushes] = useState('');

  const [selectedDates, setSelectedDates] = useState(dateFilterInitial);

  const [timestampAfter, setTimestampAfter] = useState('');
  const [timestampBefore, setTimestampBefore] = useState('');

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedOrganizationTypes, setSelectedOrganizationTypes] = useState([]);
  const [selectedOrganizatioionNames, setSelectedOrganizatioionNames] = useState([]);
  const [selectedactionTypes, setSelectedactionTypes] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filterAllSelected, setFilterAllSelected] = useState(false);

  useEffect(() => {
    checkFilterData();
  }, [
    selectedEvents,
    selectedOrganizationTypes,
    selectedOrganizatioionNames,
    selectedactionTypes,
    timestampAfter,
    timestampBefore,
  ]);

  const setDatesForSearch = (date: DateFilterData) => {
    if (!date?.dateFilter) {
      setFilterAllSelected(true);
      setTimestampBefore(null);
      return setTimestampAfter(null);
    }
    setFilterAllSelected(false);

    setSelectedDates(date);
    let dateFrom = date.dateFilterFrom || '';
    date.dateFilterTo && setTimestampBefore(moment(date.dateFilterTo).format());

    switch (date.dateFilter) {
      case 'DAY':
        dateFrom = moment().subtract(1, 'days').format();
        break;
      case 'WEEK':
        dateFrom = moment().subtract(1, 'weeks').format();
        break;
      case 'MONTH':
        dateFrom = moment().subtract(1, 'months').format();
        break;
      case 'YEAR':
        dateFrom = moment().subtract(1, 'years').format();
        break;
    }
    setTimestampAfter(moment(dateFrom).format());
  };

  const checkFilterData = () => {
    const filter = {
      events: selectedEvents.map((event) => event.value).join(','),
      organizations: selectedOrganizatioionNames.map((event) => event.value).join(','),
      timestamp_after: timestampAfter,
      timestamp_before: timestampBefore,
      organizationTypes: selectedOrganizationTypes.map((event) => event.value).join(','),
      actionType: selectedactionTypes.map((event) => event.value).join(','),
    };

    Object.keys(filter).forEach((k) => {
      return !filter[k] && filter[k] !== undefined && delete filter[k];
    });

    setFilterData(filter);
  };

  return (
    <div className={classNames('max-height', styles.ActivitiesLogTab)}>
      <ActivitiesToolBar
        numberOfSMS={numberOfSMS}
        numberOfEmails={numberOfEmails}
        numberOfPushes={numberOfPushes}
        selectedDates={selectedDates}
        setSelectedDates={setDatesForSearch}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
        selectedOrganizationTypes={selectedOrganizationTypes}
        setSelectedOrganizationTypes={setSelectedOrganizationTypes}
        selectedOrganizatioionNames={selectedOrganizatioionNames}
        setSelectedOrganizatioionNames={setSelectedOrganizatioionNames}
        selectedactionTypes={selectedactionTypes}
        setSelectedactionTypes={setSelectedactionTypes}
      />
      <ActivitiesLogTable
        filterData={filterData}
        setNumberOfSMS={setNumberOfSMS}
        setNumberOfEmails={setNumberOfEmails}
        setNumberOfPushes={setNumberOfPushes}
        filterAllSelected={filterAllSelected}
      />
    </div>
  );
}

export default ActivitiesLogTab;
