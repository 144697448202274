import React from 'react';

import styles from './UrlNavigation.scss';
import Icon from '@components/Icon';
import { TextField } from '@material-ui/core';

const UrlNavigation = ({ onSelectedUrl, onReset, value }) => {
  return (
    <div className={styles.urlNavigation}>
      <div className={styles.urlInput}>
        <TextField
          value={value || ''}
          margin="dense"
          variant="outlined"
          style={{ marginTop: '3px', width: '355px', maxWidth: '355px' }}
          id={styles.textField}
          inputProps={{
            style: { fontSize: 12, color: 'var(--systemFont)', padding: '6px' },
            maxLength: 100,
            autoFocus: true,
          }}
          onChange={(e) => {
            e.target.value === ''
              ? onReset()
              : onSelectedUrl({ url: e.target.value, assetTypeId: null, type: 'URL' });
          }}
        />
      </div>
      <Icon type="materialDelete" className={styles.removeIcon} onClick={() => onReset()}></Icon>
    </div>
  );
};

export default UrlNavigation;
