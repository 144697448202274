import { getState } from '@src/redux/store';

export const getPermissionStatus = (context, type) => {
  const permissionStatus = getState().config.permissions[context];
  switch (type) {
    case 'VIEW':
      // When the user has an EDIT permission, he will automatically
      // will get a VIEW permission, therefor:
      // 'getPermissionStatus' will return true if the user has a VIEW/EDIT permission
      return permissionStatus && (permissionStatus === 'EDIT' || permissionStatus === 'VIEW');
    case 'EDIT':
      return permissionStatus && permissionStatus === 'EDIT';
    default:
      return false;
  }
};

export const getFlagStatus = key => getState().config.featureFlags[key];
