import { StoreAction } from './redux.interface';

export const setFcmMessaging = (payload: any): StoreAction => ({
  type: 'fcm/SET_FCM_MESSAGING',
  payload,
});

export default function fcmReducer(state = {}, action: StoreAction) {
  if (action.type == 'fcm/SET_FCM_MESSAGING') {
    return { fcmMessaging: action.payload };
  } else {
    return state;
  }
}
