import React from 'react';

// Comonents
import I18n from '@components/I18n';
import Button from '@components/Button';

// Interfaces | Services
import { modalService } from '@core/modals/ModalService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { httpService } from '@core/http/HttpService';

const AssignButton = (props) => {
  const {
    styles = {},
    licenseId,
    assetId,
    assetName,
    description,
    subscriptionId,
    dismiss,
  } = props;

  const onClick = async () => {
    const confirm = await modalService.openModal('confirm', {
      text: 'assign-subscription-to-asset-modal.add-confirm-text',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      assetName,
      description,
      headerText: 'add-subscription-to-asset-modal.add-confirm-title',
    });
    if (confirm) {
      httpService.api({
        type: 'assignSubscriptionByItToAsset',
        urlParams: { assetId, subscriptionId },
      });
      dismiss(true);
    }
  };

  return (
    <Button onClick={onClick} styles={{ minWidth: 92, width: 92, padding: '0 12px', ...styles }}>
      <I18n>{`assets-tab.assign`}</I18n>
    </Button>
  );
};

export default AssignButton;
