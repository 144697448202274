import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ClickAwayListener, Popover } from '@material-ui/core';
import I18n from '@components/I18n';
import styles from './ConfigurationPopover.scss';
import Button from '@components/Button';
import { ConfigurationPopoverProps } from './ConfigurationPopover.interface';

function ConfigurationPopover(props: ConfigurationPopoverProps) {
  const {
    config,
    open,
    close,
    onSubmit,
    disableApply,
    showClearAll,
    onClearAll,
    anchorEl,
    leaveOpen,
    setApplyButtonClicked,
  } = props;
  const cancel = useCallback(() => close('cancel'), []);

  const submit = () => {
    onSubmit();
    close('apply');
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      {/* <ClickAwayListener onClickAway={!leaveOpen && cancel}> */}
      <div className={styles.wrapper} style={{ display: 'block' }}>
        <div style={{ display: 'flex' }}>
          <div className={styles.leftSideWrapper}>
            {config.map(
              (option, idx) =>
                !option.isHidden && (
                  <div className={classNames(styles.field, 'ellipsis-overflow')} key={idx}>
                    <I18n>{option.label}</I18n>:
                  </div>
                )
            )}
          </div>
          <div className={styles.rightSideWrapper}>
            {config.map(
              (option, idx) =>
                !option.isHidden && (
                  <div className={classNames(styles.field, 'ellipsis-overflow')} key={idx}>
                    <option.Component {...option.props} />
                  </div>
                )
            )}
          </div>
        </div>
        <div className={styles.actionsRow}>
          {!disableApply && showClearAll ? (
            <div
              className={classNames(styles.clear, 'pointer', 'ellipsis-overflow')}
              onClick={onClearAll}>
              <I18n>general.clear-all</I18n>
            </div>
          ) : (
            <div className={styles.clear}></div>
          )}
          <div>
            <Button
              styles={{ marginLeft: 13, maxWidth: 120 }}
              mode={['bigFont', 'cancel']}
              onClick={(e) => {
                e.stopPropagation();
                cancel();
              }}>
              <I18n>general.cancel</I18n>
            </Button>
            <Button
              styles={{ marginLeft: 13, maxWidth: 120 }}
              mode="bigFont"
              onClick={(e) => {
                e.stopPropagation();
                submit();
                setApplyButtonClicked && setApplyButtonClicked(true);
              }}
              disabled={disableApply}>
              <I18n>general.apply</I18n>
            </Button>
          </div>
        </div>
      </div>
      {/* </ClickAwayListener> */}
    </Popover>
  );
}

export default ConfigurationPopover;
