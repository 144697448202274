import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ToolBar from '@components/ToolBar';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';
import { useSelector } from '@src/redux/useSelector';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { modalService } from '@core/modals/ModalService';
import DataSourcesTable from './DataSourcesTable/DataSourcesTable';
import DataSourceFilters from './DataSourceFilters/DataSourceFilters';
import styles from './DataSourcesPage.scss';

function DataSourcesPage(props) {
  const {
    templateData,
    setTemplateData,
    setIsAddingDataSource,
    setEditedDataSource,
    setShowFooter,
    onSaveTemplate,
  } = props;
  const [refresh, setRefresh] = useState(true);
  const userOrgType = useSelector(
    (state) => state.config.organizationDetails && state.config.organizationDetails.type
  );

  const hasPermission = useMemo(() => getPermissionStatus('EVENTS', 'EDIT'), []);

  const editDataSource = useCallback(async (dataSource, templateData) => {
    if (dataSource.type === 'pdf') {
      const res = await modalService.openModal('editPdfReportSettingsModal', {
        templateData,
        pdfSettings: dataSource,
        setTemplateData,
        onSaveTemplate,
      });
      if (res) {
        setRefresh(true);
      }
    } else {
      setEditedDataSource(dataSource);
      setShowFooter(false);
      setIsAddingDataSource(true);
    }
  }, []);

  const openCreateDataSource = useCallback(async () => {
    const res = await modalService.openModal('createEventDataSourceModal', {
      headerText: 'events-template.create-datasource.modal.header',
      templateData,
      setTemplateData,
    });
    if (res && res.dataSource) {
      editDataSource(res.dataSource, templateData);
    }
  }, [templateData]);

  const toolbarCfg: ToolbarCfg = {
    buttons: [
      {
        type: 'create',
        onClick: openCreateDataSource,
        disabled: !hasPermission,
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div>
        <DataSourceFilters {...props} />
      </div>
      <div className={styles.innerContainer}>
        <ToolBar toolbarCfg={toolbarCfg} />
        <DataSourcesTable
          templateData={templateData}
          setTemplateData={setTemplateData}
          editDataSource={editDataSource}
          onSaveTemplate={onSaveTemplate}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
}

export default DataSourcesPage;
