import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './TextLowercase.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { colorMap } from '@core/statusColorMap';

function TextLowercase(props: any) {
  const { field, needStatusColor } = props;
  const languageId = useSelector((state) => state.config.languageId);

  const value = useMemo(
    () => i18nService.translate(`enum.${props[field] || 'NOT_AVALIABLE'}`, languageId),
    [field, languageId]
  );

  return (
    <div
      className={classNames(style.text, 'ellipsis-overflow')}
      style={{ color: needStatusColor ? colorMap[props[field]] : null }}
      title={value}>
      {value}
    </div>
  );
}

export default TextLowercase;
