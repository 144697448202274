import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, Button } from '@material-ui/core';
import I18n from '@components/I18n';
import styles from './FirstLoginPage.scss';
import LoginInput from '@pages/LoginPage/LoginInput';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import OnboardingActionMessage from '@components/OnboardingActionMessage';
import { modalService } from '@core/modals/ModalService';
import { authService } from '@core/auth/AuthService';
import { useSelector } from '@src/redux/useSelector';
import { buildImageUrl } from '@core/utils';
import NewLogo from '@components/NewLogo';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('validations.incorrect-pass'),
});

function FirstLoginPage() {
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPage, setShowPage] = useState(false);
  const [email, setEmail] = useState(null);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const key = urlSearchParams.get('key');

  useEffect(() => {
    httpService
      .api({
        type: 'EmailVerification',
        data: { key },
      })
      .then((res: { email: string }) => {
        setEmail(res.email);
        setShowPage(true);
      })
      .catch((err) => {
        setErrorMessage(err.data.status);
        setShowPage(true);
      });
  }, []);

  const errorOnClick = useCallback(() => {
    history.push('login');
  }, []);

  const createAccount = useCallback(() => {
    history.push('sign-up');
  }, []);

  const status = useMemo(
    () => ({
      COMPLETED: {
        text: 'first-login.verified-text',
        headerText: 'first-login.action-verified',
        isError: false,
        actionBtnText: 'login-page.submit',
        onClickAction: errorOnClick,
      },
      EXPIRED: {
        text: 'first-login.create-new-account',
        headerText: 'first-login.expired-header',
        isError: true,
        actionBtnText: 'first-login.create-account',
        onClickAction: createAccount,
      },
      INVALID: {
        text: 'first-login.token-expired',
        headerText: 'first-login.expired-header',
        isError: true,
        actionBtnText: 'login-page.submit',
        onClickAction: errorOnClick,
      },
    }),
    []
  );

  const onSubmit = useCallback((values) => {
    event?.preventDefault();
    httpService
      .api({
        type: 'firstLoginSetPassword',
        data: {
          password: values.password,
          key,
        },
      })
      .then((res) => {
        authService.logout();
        modalService
          .openModal(
            'confirm',
            {
              text: 'first-login.success-message',
              confirmText: 'first-login.success-button',
              showCloseBtn: true,
            },
            { disableBackdropClick: true }
          )
          .then(() => {
            history.push('login');
          });
      });
  }, []);

  const initialValues = useMemo(
    () => ({
      username: '',
      password: '',
      confirmPass: '',
    }),
    []
  );

  /**
   * The regex is split since we have to check for 3 out of 4 conditions
   */
  const pwCriteriaTest = useCallback((password: string) => {
    let criteriaCount = 0;
    const chars = [...password];

    chars.find((c) => /^[A-Z]$/.test(c)) && ++criteriaCount; // Check for Uppercase letter
    chars.find((c) => /^[a-z]$/.test(c)) && ++criteriaCount; // Check for Lowercase letter
    chars.find((c) => /^\d$/.test(c)) && ++criteriaCount; // Check for Digits
    chars.find((c) => /[^a-zA-Z0-9]$/.test(c)) && ++criteriaCount; // Check for Special characters

    return criteriaCount >= 3 && password.length >= 8;
  }, []);

  const validate = useCallback((values) => {
    if (!pwCriteriaTest(values.password)) {
      return { password: i18nService.translate('first-login.pass-criteria') };
    }
    if (values.password !== values.confirmPass) {
      return { confirmPass: i18nService.translate('first-login.no-match') };
    }
  }, []);

  /**
   * When entering the page without key in the params
   * it means that a hack happened and the user will be redirect to login
   */
  return (
    <>
      {!key ? (
        <Redirect to="/login" />
      ) : (
        showPage &&
        (errorMessage ? (
          <OnboardingActionMessage {...status[errorMessage]} />
        ) : (
          <div className={classNames('max-height', styles.loginPage)}>
            <div style={{ marginTop: 70 }}>
              <NewLogo />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validate={validate}
              onSubmit={onSubmit}>
              {({ isValid }) => (
                <Form className={classNames(styles.loginForm, styles.firstLogin)}>
                  <Typography align="center" className={styles.loginHeader}>
                    <I18n>first-login.set-password</I18n>
                  </Typography>
                  <div className={styles.loginContent}>
                    <div className={styles.emailInput}>
                      <I18n className={styles.emailLabel}>first-login.email-address</I18n>
                      <span className={styles.emailText}>{email}</span>
                    </div>
                    <LoginInput
                      type="password"
                      name="password"
                      label="login-page.password"
                      className={styles.label}
                    />
                    <LoginInput
                      type="password"
                      name="confirmPass"
                      label="login-page.insert-password-again"
                      disabledPaste={true}
                      className={styles.label}
                    />
                    <Button
                      className={styles.submit}
                      type="submit"
                      variant="contained"
                      disabled={!isValid}>
                      <I18n>first-login.set-password</I18n>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ))
      )}
    </>
  );
}

export default withRouter(FirstLoginPage);
