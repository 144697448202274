import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ConditionalValueCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { i18nService } from '@core/i18n/I18nService';
import TableCustomizationRow from '../CustomizationRow';
import * as cloneDeep from 'lodash/cloneDeep';
import Icon from '@components/Icon';
import { conditionsService } from '@core/ConditionsService';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1px',
    padding: '0px 12px 0px 28px',
    fontSize: 12,
    fontWeight: 500,
    borderTop: 'solid 1px #c2cfe0',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 12px 10px 28px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: 15,
  },
  add: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    height: 43,
  },
}));

function CfgConditions(props) {
  const classes = useStyles(props);
  const { widgetData, setWidgetData, isBoolean } = props;
  const { customization, metrics, scope } = widgetData as any;
  const { conditions, errors = {} } = (customization as ConditionalValueCustomization) || {
    conditions: null,
  };
  const [icons, setIcons] = useState([]);

  const getIcons = async () => {
    try {
      const res: any = await httpService.api({
        type: 'getConditionalValueIcons',
        query: { p: 1, ps: 1000 },
      });
      if (res) {
        setIcons(res);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getIcons();
  }, []);

  const columnCfg = isBoolean
    ? [
        {
          style: { minWidth: '25%', width: '25%' },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-value',
        },
        {
          style: {
            minWidth: '20%',
            width: '20%',
          },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-icon',
        },
        {
          style: { minWidth: '30%', width: '30%' },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-background',
        },
        {
          style: { minWidth: '25%', width: '25%' },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-text-to-display',
        },
        {
          style: { minWidth: '20px', width: '20px' },
        },
      ]
    : [
        {
          style: { minWidth: '20%', width: '20%' },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-from',
        },
        {
          style: { minWidth: '20%', width: '20%' },
          headerLabel: 'create-widget-page.create-widget.step-four.conditional-value.conditions-to',
        },
        {
          style: {
            minWidth: '20%',
            width: '20%',
          },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-icon',
        },
        {
          style: { minWidth: '17%', width: '17%' },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-background',
        },
        {
          style: { minWidth: '20%', width: '20%' },
          headerLabel:
            'create-widget-page.create-widget.step-four.conditional-value.conditions-text-to-display',
        },
        {
          style: { minWidth: '20px', width: '20px' },
        },
      ];

  const updateConditions = (newUpdate) => {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        conditions: newUpdate,
      },
    }));
  };

  const onColumnChanged = (condition, idx) => {
    const _conditions = cloneDeep(conditions);
    _conditions[idx] = condition;

    setWidgetData((prevState) => {
      const isValid = conditionsService.validate(_conditions, condition);
      let errors = prevState.errors ? { ...prevState.errors } : {};

      if (!isBoolean) {
        if (!isValid && !errors[condition.id]) {
          errors[condition.id] = true;
        } else {
          errors[condition.id] = false;
        }
      }

      return {
        ...prevState,
        customization: Object.assign({}, customization, { conditions: _conditions, errors }),
      };
    });
  };

  const deleteCondition = (idx) => {
    function remove() {
      const tempConditions = [...conditions];
      tempConditions.splice(idx, 1);
      updateConditions(tempConditions);

      if (errors[conditions[idx].id]) {
        delete errors[conditions[idx].id];
      }
    }

    if (
      !conditions[idx].fromValue &&
      !conditions[idx].toValue &&
      !conditions[idx].iconId &&
      !conditions[idx].remark
    ) {
      remove();
    } else {
      modalService
        .openModal('confirm', {
          text: 'create-widget-page.create-widget.step-four.image-by-value.remove-condition-message',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText:
            'create-widget-page.create-widget.step-four.image-by-value.remove-condition-header',
          showCloseBtn: true,
        })
        .then((confirm) => {
          if (confirm) {
            remove();
          }
        });
    }
  };

  const addCondition = () => {
    updateConditions([
      ...conditions,
      { fromValue: null, toValue: null, id: `condition_${moment().unix()}` },
    ]);
  };

  return (
    <div className={classes.tableContainer}>
      {
        <div className={classes.tableHeader}>
          {columnCfg.map((header, idx) => (
            <div
              title={header.headerLabel && i18nService.translate(header.headerLabel)}
              key={idx}
              className={classes.header}
              style={header.style}>
              {header.headerLabel && (
                <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
              )}
            </div>
          ))}
        </div>
      }

      {conditions &&
        conditions.map((condition, idx) => (
          <div key={condition.id} className={classes.columnContainer}>
            <TableCustomizationRow
              columnCfg={columnCfg}
              column={condition}
              index={idx}
              columnChanged={(editedColumn, index) => onColumnChanged(editedColumn, index)}
              isBoolean={isBoolean}
              conditions={conditions}
              error={errors[condition.id]}
              deleteCondition={() => deleteCondition(idx)}
              icons={icons}
            />
          </div>
        ))}

      <div className={classNames(classes.columnContainer, classes.add)}>
        {!isBoolean &&
          conditions?.length < 100 &&
          !Object.values(errors).find((err) => err) &&
          (typeof conditions[conditions.length - 1].fromValue === 'number' ||
            typeof conditions[conditions.length - 1].toValue === 'number') && (
            <Icon
              className="pointer"
              type="addImageRow"
              onClick={addCondition}
              tooltipText={'create-widget-page.create-widget.step-four.add-condition'}></Icon>
          )}
      </div>
    </div>
  );
}

export default CfgConditions;
