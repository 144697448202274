import { omit } from 'lodash';
import HeatmapWidget from '@components/widgets/HeatmapWidget';
import { WidgetMapItem } from '../widgetMap.interface';
import { assetsPropsMap } from '@pages/CreateWidgetPage/widgets.utils';

const defaultGrouping = [
  {
    isDropDown: true,
    isDropDownValue: { value: 'DAILY', label: 'enum.DAILY' },
    name: 'create-widget-page.create-widget.asset-properties.DATE',
    operation: 'DAILY',
    order: 0,
    type: 'dateTimeType',
    valueId: 7,
    valueType: 'ASSET_PROPERTY',
    isDragDisabled: true,
  },
];

const defaultSorting = [
  {
    isDropDown: false,
    isDropDownValue: null,
    label: 'Timestamp',
    name: `create-widget-page.create-widget.asset-properties.${assetsPropsMap[7]}`,
    operation: 'DAILY',
    order: 0,
    sortBy: 'ASCENDING',
    type: 'string',
    value: 7,
    valueId: 7,
    valueType: 'ASSET_PROPERTY',
  },
];

const defaultAssetsProperties = [
  {
    id: 7,
    name: 'create-widget-page.create-widget.asset-properties.DATE',
    type: 'DATE',
  },
];

export const heatmap: WidgetMapItem = {
  id: 'heatmap',
  name: 'Heatmap',
  component: HeatmapWidget,
  minWidth: 328,
  fullSize: true,
  minHeight: 168,
  images: {
    canvas:
      'https://unicloud-public-prod-eu-west-1.s3.amazonaws.com/widgets_empty_states/empty_heatmap.svg',
    thumbnail: 'heatmap',
  },
  editModalSize: {},
  settings: {
    getWidgetDataOnlyInPreview: true,
    dataChangesToHandle: ['metrics', 'filters', 'sorts', 'calculations'],
    defaultAssetProperties: [7],
    defaultScope: 'AGGREGATED_DATA',
    disabledScope: ['RAW_DATA', 'LAST_VALUE', 'AGGREGATED_LAST_VALUE'],
    accordionDisable: ['sort'],
    stepThreeInfo: {
      groupBy: 'create-widget-page.create-widget.step-three.group-by-info.heatmap',
    },
    showPreviewData: ['metrics', 'groupBy'],
    enableExportCsv: () => true,
    setDefaultDataByStep: {
      2: (scope, setWidgetData, widgetData) =>
        setWidgetData((prevState) => ({
          ...prevState,
          groupBy: defaultGrouping,
          sorts: defaultSorting,
          exportFormats: ['CSV'],
        })),
    },
    setAllDefaultData: (setWidgetData) => {
      setWidgetData((prevState) => ({
        ...prevState,
        assetProperties: defaultAssetsProperties,
        groupBy: defaultGrouping,
        sorts: defaultSorting,
      }));
    },
    stepValidation: {
      1: (widgetData) =>
        !!(widgetData.tags?.length || widgetData.tagTypes?.length || widgetData.variables?.length),
      3: (widgetData) =>
        widgetData.scope === 'AGGREGATED_DATA'
          ? !widgetData.groupBy.length ||
            !widgetData.metrics.length ||
            widgetData.metrics.some((m) => m.valueType !== 'CALCULATION' && !m.operation)
          : !widgetData.metrics.length,
      4: (widgetData) => {
        const lastRange =
          widgetData?.customization?.rangesChart?.ranges &&
          widgetData?.customization?.rangesChart?.ranges[
            widgetData?.customization?.rangesChart?.ranges?.length - 1
          ];
        const noErrors =
          !widgetData?.customization?.errors ||
          !Object.values(widgetData?.customization?.errors).find((err) => err);

        return (
          widgetData?.customization?.chartType === 'REGULAR' ||
          (noErrors &&
            (lastRange?.fromValue ||
              lastRange?.fromValue === 0 ||
              lastRange?.toValue ||
              lastRange?.toValue === 0))
        );
      },
    },
    dragAndDropRules: {
      maxGrouping: () => 2,
      maxMetrics: (scope, groupBy) => {
        return groupBy.length === 2 ? 1 : null;
      },
      maxMetricsErrorMessage: 'heatmapMaxMetrics',
      errorHeader: 'general.invalid-action',
      groupingDataValidation: (scope, draggableItemType, isRemove, groupBy, metrics) => {
        if (groupBy?.length === 1 && metrics?.length > 1) {
          return 'heatmapOneGroupingAllowed';
        }
      },
      excludeNonNumericVariables: () => true,
    },
    unResetKeys: ['sorts'],
    maxWidgetsOnCanvas: 1,
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => {
      return widgetData.groupBy.some((g) => g.valueType === 'ASSET_PROPERTY' && g.valueId === 1);
    },
    languageKeys: (isArWidget) => ['columnsDict.*', 'subtitle.text'],
    customizationServerChanges: (customization) => ({
      ...omit(customization, 'errors'),
      columnsDict:
        customization.columns &&
        customization.columns.reduce(
          (result, column) =>
            column.operation
              ? {
                  ...result,
                  [`${column.valueType?.toLowerCase()}.${
                    column.id
                  }.${column.operation?.toLowerCase()}`]: column.displayName,
                }
              : {
                  ...result,
                  [`${column.valueType?.toLowerCase()}.${column.id}`]: column.displayName,
                },
          {}
        ),
    }),
  },
};
