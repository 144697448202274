import React, { useMemo } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import Icon from '@components/Icon';
import { useSelector } from '@src/redux/useSelector';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { getBackgroundColor, getNodeColor } from '../organizationsTree.utils';

const useStyles = makeStyles((theme: any) => ({
  componentWrapper: {
    cursor: (props) => !props.selected && 'pointer',
    height: '100%',
  },
  wrapper: {
    padding: '7px 16px',
    height: 30,
    borderRadius: 30,
    background: (props: any) => getBackgroundColor(props.node),
    marginTop: 6,
    fontSize: 12,
    color: (props: any) => getNodeColor(props.node.type),
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  selected: {
    background: (props) => theme.palette.primary.main,
    color: theme.app.sideMenuText,
  },
  margin: {
    padding: `7px 5.5px 7px 16px`,
  },
  loading: {
    flex: 1,
    alignItems: 'flex-end',
    display: 'flex',
    marginTop: 10,
    marginLeft: 20,
  },
  buttons: {
    borderRadius: 0,
    background: 'none',
    boxShadow: 'none',
    border: 'none',
    top: '40%',
  },
}));

function NodeContent(props) {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    cannotExpand,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
    onNodeClicked,
    onAddClicked,
    updateMBMap,
    selected,
    ...otherProps
  } = props;
  const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
  const selectedOrganizationStatus = useSelector(
    (state) => state.organizations && state.organizations.status
  );
  const disabledAddButton = useMemo(() => getPermissionStatus('ORGANIZATION', 'EDIT'), []);
  const showAddButton = useMemo(
    () => node.type !== 'END_CUSTOMER' && selectedOrganizationStatus !== 'PRE_ACTIVATED',
    [selectedOrganizationStatus, node.type]
  );
  const nodeColor = useMemo(() => getNodeColor(node.type), [node.type]);

  const classes = useStyles(props);
  const country = node.country ? `, ${node.country}` : '';
  const childCount = node.childCount ? `(${node.childCount})` : '';

  // const handle;
  //not in used - the original file
  // if (canDrag) {
  //   if (typeof node.children === 'function' && node.expanded) {
  //     // Show a loading symbol on the handle when the children are expanded
  //     //  and yet still defined by a function (a callback to fetch the children)
  //     handle = (
  //       <div className="rst__loadingHandle">
  //         <div className="rst__loadingCircle">
  //           {[...new Array(12)].map((_, index) => (
  //             <div
  //               // eslint-disable-next-line react/no-array-index-key
  //               key={index}
  //               className={classnames('rst__loadingCirclePoint', rowDirectionClass)}
  //             />
  //           ))}
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     // Show the handle used to initiate a drag-and-drop
  //     handle = connectDragSource(<div className="rst__moveHandle" />, {
  //       dropEffect: 'copy'
  //     });
  //   }
  // }
  //const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  //const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle: any = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === 'rtl') {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  return (
    <>
      <div className={classes.componentWrapper} onClick={onNodeClicked} {...otherProps}>
        {toggleChildrenVisibility && node.childCount > 0 && !cannotExpand && (
          <div>
            <Icon
              type={node.expanded ? 'collapse' : 'expand'}
              style={buttonStyle}
              color={'var(--systemFont)'}
              className={classnames(
                node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
                rowDirectionClass,
                classes.buttons
              )}
              onClick={(e) => {
                e.stopPropagation();
                updateMBMap();
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                });
              }}
            />
            {/* <button
              type="button"
              aria-label={node.expanded ? 'Collapse' : 'Expand'}
              className={classnames(
                node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
                rowDirectionClass
              )}
            /> */}
            {node.expanded && !isDragging && typeof node.children !== 'function' && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className={classnames('rst__lineChildren', rowDirectionClass)}
              />
            )}
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className={classnames(
              classes.wrapper,
              selected && classes.selected,
              selected && showAddButton && classes.margin
            )}>
            <div>{`${node.name}${country} ${childCount}`}</div>
            {selected && showAddButton && (
              <Icon
                color={nodeColor}
                tooltipText="organizations-page.add-organizations-tooltip"
                type="plus_dark"
                style={{ paddingLeft: 9.5, cursor: 'pointer' }}
                onClick={onAddClicked}
                disabled={!disabledAddButton}
              />
            )}
          </div>
          {node.expanded && !isDragging && typeof node.children === 'function' && (
            <div className={classes.loading}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default NodeContent;
//original node component.
{
  /* <div className={classnames('rst__rowWrapper', rowDirectionClass)}> */
}
{
  /* Set the row preview to be used during drag and drop */
}
{
  /* {connectDragPreview(
          <div
            className={classnames(
              'rst__row',
              isLandingPadActive && 'rst__rowLandingPad',
              isLandingPadActive && !canDrop && 'rst__rowCancelPad',
              isSearchMatch && 'rst__rowSearchMatch',
              isSearchFocus && 'rst__rowSearchFocus',
              rowDirectionClass,
              className
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style
            }}>
            {handle}

            <div
              className={classnames(
                'rst__rowContents',
                !canDrag && 'rst__rowContentsDragDisabled',
                rowDirectionClass
              )}>
              <div className={classnames('rst__rowLabel', rowDirectionClass)}>
                <span
                  className={classnames(
                    'rst__rowTitle',
                    node.subtitle && 'rst__rowTitleWithSubtitle'
                  )}>
                  {typeof nodeTitle === 'function'
                    ? nodeTitle({
                        node,
                        path,
                        treeIndex
                      })
                    : nodeTitle}
                </span>

                {nodeSubtitle && (
                  <span className="rst__rowSubtitle">
                    {typeof nodeSubtitle === 'function'
                      ? nodeSubtitle({
                          node,
                          path,
                          treeIndex
                        })
                      : nodeSubtitle}
                  </span>
                )}
              </div>

              <div className="rst__rowToolbar">
                {buttons &&
                  buttons.map((btn, index) => (
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="rst__toolbarButton">
                      {btn}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div> */
}
