import ToolBar from '@components/ToolBar';
import React, { useState } from 'react';
import classNames from 'classnames';
import { template } from 'lodash';
import styles from './EventsTab.scss';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { getState } from '@src/redux/store';
import { httpService } from '@core/http/HttpService';
import { apiMap } from '@core/http/apiMap';
import EventsTable from './EventsTable/EventsTable';

function EventsTab(props) {
  const { organizationPage } = props;

  const [searchText, setSearchText] = useState('');
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);

  const search = (searchText) => {
    setSearchText(searchText);
  };

  const getLastSearches = () => {
    const organizationId = getState().login.userOrganizationId;
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getEventsData'].url)({ organizationId }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches);
      });
  };

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'events',
    },
  };

  const setResoults = (total, results) => {
    setTotalCount(total);
    setResultsCount(results);
  };

  return (
    <div className={classNames('max-height', styles.eventsTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <EventsTable
        searchText={searchText}
        setResolts={setResoults}
        organizationPage={organizationPage}
      />
    </div>
  );
}
export default EventsTab;
