import React from 'react';
import Select from '@components/Select';
import { useSelector } from '@redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { onDontShowAgainChanged } from '@core/utils';
import { dispatch } from '@src/redux/store';
import { setShowSplitDashboardConfirmation } from '@src/redux/config';

function SplitLayoutSelect({ setLeaveOpen, splitLayout, setSplitLayout }) {
  const showSplitDashboardConfirmation = useSelector(
    (state) => state.config.showSplitDashboardConfirmation
  );
  const splitLayoutOptions = [
    { label: i18nService.translate('general.yes'), value: true },
    { label: i18nService.translate('general.no'), value: false },
  ];

  const onSplitLayout = () => {
    if (!splitLayout) {
      setLeaveOpen(true);
      if (showSplitDashboardConfirmation) {
        modalService
          .openModal('confirm', {
            text: 'dashboard-editor.split-dashboard-layout.split-pop-up-text',
            iconType: 'attention_image',
            confirmText: 'general.confirm',
            cancelText: 'general.cancel',
            headerText: 'dashboard-editor.split-dashboard-layout.split-pop-up-header',
            showCloseBtn: true,
            allowDontShowAgain: true,
          })
          .then((confirm) => {
            if (confirm.res) {
              setSplitLayout(true);
              onDontShowAgainChanged('showSplitDashboardConfirmation', confirm.dontShowAgain, () =>
                dispatch(setShowSplitDashboardConfirmation(!confirm.dontShowAgain))
              );
            }
            setLeaveOpen(false);
          });
      } else {
        setSplitLayout(!splitLayout);
      }
    }
  };

  const onUnifyLayout = () => {
    if (splitLayout) {
      setLeaveOpen(true);
      modalService
        .openModal('confirm', {
          text: 'dashboard-editor.split-dashboard-layout.unify-pop-up-text',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText: 'dashboard-editor.split-dashboard-layout.unify-pop-up-header',
          showCloseBtn: true,
          allowDontShowAgain: false,
        })
        .then((confirm) => {
          if (confirm) {
            setSplitLayout(false);
          }
          setLeaveOpen(false);
        });
    }
  };

  return (
    <Select
      styles={{ control: { height: 37 }, menuPortal: { zIndex: 9999999999999 } }}
      menuPortalTarget={document.body}
      value={splitLayoutOptions.find((opt) => opt.value === splitLayout)}
      options={splitLayoutOptions}
      getOptionLabel={(i) => i.label}
      getOptionValue={(i) => i.value}
      onChange={(value) => (value?.value ? onSplitLayout() : onUnifyLayout())}
    />
  );
}

export default SplitLayoutSelect;
