import * as RI from '../../redux.interface.d';

const initialState = (): RI.RoutersState => ({
  totalCount: 0,
  resultsCount: 0,
  routersData: [],
  refresh: false
});

export default function routerstReducer(
  state: RI.RoutersState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'routers/SET_ROUTERS_DATA':
      return {
        ...state,
        routersData: payload.routersData,
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount
      };
    case 'routers/ADD_ROUTERS_DATA':
      return {
        ...state,
        routersData: state.routersData.concat(payload.routersData),
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount
      };
    case 'routers/REFRESH_ROUTERS_DATA':
      return { ...state, refresh: !state.refresh };
    case 'routers/REMOVE_ROUTERS_DATA':
      return initialState();
    default:
      return state;
  }
}
