import { cssVarsService } from '@core/CssVarsService';
import { Grid, makeStyles, Switch, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 66px auto',
  },
  switchText: {
    fontSize: '12px',
    margin: 'auto 0px',
    color: cssVarsService.vars.widgetsFont,
  },
}));

const SwitchEditor = (props) => {
  const { switchCustomization, isDisabled, onChange, value } = props;
  const classes = useStyles({ isDisabled, ...props });

  const CustomSwitch = withStyles({
    switchBase: {
      color: isDisabled ? '#A4A4A4' : switchCustomization.color, // button unchecked
      '&$checked': {
        color: isDisabled ? '#A4A4A4' : switchCustomization.color, // button checked
      },
      '&$checked + $track': {
        backgroundColor: `#8CBBE9!important`, // track checked,
      },
    },
    checked: {},
    track: {
      backgroundColor: `#9F9F9F!important`, // track unchecked
    },
  })(Switch);

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.switchText, 'ellipsis-overflow')}
        title={switchCustomization.offText}>
        {switchCustomization.offText}
      </div>
      <CustomSwitch checked={value} onChange={onChange} disabled={isDisabled} size="medium" />
      <div
        className={classNames(classes.switchText, 'ellipsis-overflow')}
        title={switchCustomization.onText}>
        {switchCustomization.onText}
      </div>
    </div>
  );
};

export default SwitchEditor;
