import React from 'react';
import styles from './SubscribeStatus.scss';
import Icon from '@components/Icon';

function SubscribeStatus(props) {
  const { field } = props;

  return (
    <div className={styles.statusContainer}>
      <Icon type={props[field] ? 'V_icon' : 'X_icon'} color="var(--systemFont)"></Icon>
    </div>
  );
}

export default SubscribeStatus;
