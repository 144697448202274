import React, { useMemo, useState, useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import styles from './TagConfigurationRow.scss';
import MaterialCheckbox from '@components/Checkbox';
import Icon from '@components/Icon';
import Select from '@components/Select';
import { getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import {
  showValidate,
  showDecimalDigits,
  handleNameBlur,
  handleNameChange,
  handleDecimalDigitChange,
  handleDecimalDigitBlur,
  onSelectedDateTimeFormat,
  handleValidateChange,
  validateNameError,
  handleMinChange,
  handleMinBlur,
  handleMaxChange,
  handleMaxBlur,
  getTagMinVal,
  getTagMaxVal,
  minMaxHasError,
} from '../../UpdateAsset.utils';

function TagConfigurationRow(props) {
  const { widgetData, tagConfig, columnCfg, provided, isLast } = props;
  const [editedTagConfig, setEditedTagConfig] = useState(tagConfig);
  const [displayNameHasError, setDisplayNameHasError] = useState(false);

  const format = useMemo(() => {
    if (tagConfig?.format) {
      return tagConfig?.format;
    }
    if (!columnCfg[6]) {
      return null;
    }
  }, [tagConfig, columnCfg]);

  const dateTimeOptions = useMemo(() => getDateTimeOptions(format), [format]);

  useEffect(() => {
    if (!tagConfig.dateTimeFormat && format) {
      const tempDateTimeFormat =
        format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null;
      onSelectedDateTimeFormat(editedTagConfig, tempDateTimeFormat, setEditedTagConfig, props);
    }
    validateNameError(editedTagConfig.displayName, setDisplayNameHasError);
  }, []);

  useEffect(() => {
    if (tagConfig.tagType && !editedTagConfig.tagType) {
      editedTagConfig(tagConfig);
    }
  }, [tagConfig, editedTagConfig]);

  function getColumnName(): string {
    const valueType = editedTagConfig.valueType || editedTagConfig.tagType;
    const tagConfigId = tagConfig.id || tagConfig.tagId;
    switch (valueType) {
      case 'TAG':
        return widgetData.tags?.find((tag) => tag.id === tagConfigId)?.name;
      case 'TAG_TYPE':
        return widgetData.tagTypes?.find((tagType) => tagType.id === tagConfigId)?.name;
      default:
        return '';
    }
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.dragIndicator, isLast && styles.lastRow)}
        style={columnCfg[0].style}
        {...provided.dragHandleProps}>
        <Icon type="drag" />
      </div>
      <div
        style={columnCfg[1].style}
        className={classNames('ellipsis-overflow')}
        title={editedTagConfig.name}>
        {getColumnName()}
      </div>
      <div style={columnCfg[2].style}>
        <InputBase
          title={editedTagConfig.displayName}
          id={styles.inputStyle}
          className={classNames(
            styles.containerInput,
            displayNameHasError && styles.containerInputError
          )}
          value={editedTagConfig.displayName}
          onBlur={() => handleNameBlur(editedTagConfig, setEditedTagConfig, props)}
          onChange={(e) =>
            handleNameChange(
              e.target.value,
              editedTagConfig,
              setEditedTagConfig,
              setDisplayNameHasError
            )
          }
        />
      </div>
      <div style={columnCfg[3].style}>
        <MaterialCheckbox
          className={styles.checkBoxIcon}
          color="primary"
          disabled={!showValidate(editedTagConfig)}
          checked={editedTagConfig?.validate}
          onChange={(e) =>
            handleValidateChange(e.target.checked, editedTagConfig, setEditedTagConfig, props)
          }
        />
      </div>
      <div style={columnCfg[4].style}>
        <InputBase
          type="number"
          className={classNames(
            styles.containerInput,
            minMaxHasError(editedTagConfig, 'min') && styles.containerInputError
          )}
          id={styles.inputStyle}
          // inputProps={
          //   editedTagConfig.tagType !== 'FLOAT32'
          //     ? {
          //         min: getTagMinVal(editedTagConfig.tagType),
          //         max: getTagMaxVal(editedTagConfig.tagType),
          //       }
          //     : {}
          // }
          value={editedTagConfig?.validate ? editedTagConfig.min : ''}
          title={editedTagConfig?.validate ? editedTagConfig.min : ''}
          onChange={(e) => handleMinChange(e.target.value, editedTagConfig, setEditedTagConfig)}
          onBlur={() => handleMinBlur(editedTagConfig, props, setEditedTagConfig)}
          disabled={!editedTagConfig?.validate}
        />
      </div>
      <div style={columnCfg[5].style}>
        <InputBase
          type="number"
          className={classNames(
            styles.containerInput,
            minMaxHasError(editedTagConfig, 'max') && styles.containerInputError
          )}
          id={styles.inputStyle}
          // inputProps={
          //   editedTagConfig.tagType !== 'FLOAT32'
          //     ? {
          //         min: getTagMinVal(editedTagConfig.tagType),
          //         max: getTagMaxVal(editedTagConfig.tagType),
          //       }
          //     : {}
          // }
          value={editedTagConfig?.validate ? editedTagConfig.max : ''}
          title={editedTagConfig?.validate ? editedTagConfig.max : ''}
          onChange={(e) => handleMaxChange(e.target.value, editedTagConfig, setEditedTagConfig)}
          onBlur={() => handleMaxBlur(editedTagConfig, props, setEditedTagConfig)}
          disabled={!editedTagConfig?.validate}
        />
      </div>
      {columnCfg[6] && dateTimeOptions && (
        <div style={columnCfg[6].style}>
          <Select
            options={dateTimeOptions}
            value={
              editedTagConfig.dateTimeFormat &&
              dateTimeOptions.find((opt) => opt.value === editedTagConfig.dateTimeFormat)
            }
            onChange={(option) =>
              onSelectedDateTimeFormat(editedTagConfig, option.value, setEditedTagConfig, props)
            }
          />
        </div>
      )}
    </div>
  );
}

export default TagConfigurationRow;
