import React, { useEffect, useState } from 'react';
import I18n from '@components/I18n';
import HumanFormatQuery from './HumanFormatQuery/HumanFormatQuery';
import QueryBuilder from './QueryBuilder/QueryBuilder';
import styles from './ConditionBuilderContainer.scss';
import classNames from 'classnames';

const conditionMaxLength = 512;

function ConditionBuilderContainer(props) {
  const { setTemplateData, templateData, areFieldsLoaded } = props;
  const { conditionTree } = templateData;
  const [queryString, setQueryString] = useState('');
  const [conditionLength, setConditionLength] = useState(0);

  useEffect(() => {
    if (!templateData?.isCheckingConditionLength)
      setTemplateData(conditionLength > conditionMaxLength, 'isConditionLengthExceedsMax');
  }, [conditionLength, templateData?.isCheckingConditionLength]);

  return (
    <>
      <div className={styles.row}>
        <div className={classNames(styles.captionTop, 'ellipsis-overflow')}>
          <I18n>events-template.edit-template.condition</I18n>
        </div>
        <div className={styles.container}>
          <div
            className={classNames(
              styles.queryString,
              conditionLength > conditionMaxLength ? styles.error : undefined
            )}>
            <HumanFormatQuery queryString={queryString} />
          </div>
          <div className={styles.expressionLengthWrapper}>
            <I18n>events-template.edit-template.condition.number-of-characters</I18n>:&nbsp;
            <div className={conditionLength > conditionMaxLength ? styles.error : undefined}>
              {conditionLength}
            </div>
            &nbsp;/&nbsp;{conditionMaxLength}
            {conditionLength > conditionMaxLength && (
              <div className={styles.error}>
                &nbsp;-&nbsp;
                <I18n className={styles.error}>
                  events-template.edit-template.condition.expression-too-long
                </I18n>
              </div>
            )}
          </div>
          <div className={styles.queryBuilder}>
            <QueryBuilder
              queryString={queryString}
              onQueryStringChange={setQueryString}
              setTemplateData={setTemplateData}
              templateData={templateData}
              conditionTree={conditionTree}
              areFieldsLoaded={areFieldsLoaded}
              setConditionLength={setConditionLength}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ConditionBuilderContainer;
