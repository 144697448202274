import { cssVarsService } from '@core/CssVarsService';
import { i18nService } from '@core/i18n/I18nService';
import { cloneDeep } from 'lodash';
import { tagFormatMap } from '@core/mapsAndDefinitions';
import {
  DateTimeType,
  UpdateAssetButtonCustomization,
  UpdateAssetCustomization,
  UpdateAssetRequestSentTextCustomization,
  UpdateAssetSwitchCustomization,
  UpdateAssetTextualRemarkCustomization,
  WidgetUpdateAssetTag,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { number } from 'prop-types';

const ColumnMaxLength = 30;
const ColumnMinLength = 1;

export const getDefaultUpdateAssetCustomization = (tags, metrics): UpdateAssetCustomization => ({
  doNotRefreshWhileEditing: false,
  tagsConfiguration: getTagsConfiguration(tags, metrics),
  textualRemark: getDefaultTextualRemarkCustomization(),
  requestSentText: getDefaultRequestSentTextCustomization(),
  switchCustomization: getDefaultSwitchCustomization(),
  updateButtonCustomization: getDefaultButtonCustomization(),
});

export const buildOptions = (option, type) => {
  const tagFormat = type === 'TAG' ? option.format : option.type;
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
  };
};

export const getMergedTagsConfigurationChanges = (
  currentConfiguration: WidgetUpdateAssetTag[],
  tags
): WidgetUpdateAssetTag[] => {
  let newConfiguration = cloneDeep(currentConfiguration);
  // Remove tags that were de-selected
  newConfiguration = newConfiguration.filter((el) => {
    return tags.map((t) => t.id).indexOf(el.tagId) >= 0;
  });
  // Add tags that are newly selected
  const needToBeAddedTags = tags
    .filter((t) => {
      return newConfiguration.map((el) => el.tagId).indexOf(t.id) < 0;
    })
    .map((t) => ({
      tagId: t.id,
      tagType: t.tagType,
      format: t.type,
      name: t.name,
      displayName: t.name,
      order: 0,
      validate: false,
      min: null,
      max: null,
      decimalDigits: null,
      dateTimeFormat: getTagDateTimeFormat(t.type),
    }));

  newConfiguration = newConfiguration.concat(needToBeAddedTags);

  // update order
  let index = 0;
  for (const element of newConfiguration) {
    element.order = index++;
  }

  return newConfiguration;
};

const getDefaultTextualRemarkCustomization = (): UpdateAssetTextualRemarkCustomization => ({
  text: null,
  fontSize: 12,
  color: cssVarsService.vars.widgetsFont,
  horizontalAlignment: 'LEFT',
  verticalAlignment: 'BOTTOM',
});

const getDefaultRequestSentTextCustomization = (): UpdateAssetRequestSentTextCustomization => ({
  text: i18nService.translate(
    'create-widget-page.create-widget.step-four.switch.request-sent-text.default'
  ),
  fontSize: 12,
  color: cssVarsService.vars.widgetsFont,
});

const getDefaultSwitchCustomization = (): UpdateAssetSwitchCustomization => ({
  onText: i18nService.translate(
    'create-widget-page.create-widget.step-four.switch.on-text.default'
  ),
  offText: null,
  color: cssVarsService.vars.systemButtonBackground,
});

const getDefaultButtonCustomization = (): UpdateAssetButtonCustomization => ({
  text: i18nService.translate('create-widget-page.create-widget.step-four.update-asset.update'),
  color: cssVarsService.vars.widgetsFont,
  backgroundColor: cssVarsService.vars.systemButtonBackground,
  disabledColor: '#A4A4A4',
  horizontalAlignment: 'CENTER',
});

const getTagsConfiguration = (tags, metrics): WidgetUpdateAssetTag[] => {
  const tagsConfiguration = tags
    .map((t, index) => {
      const matchingMatrics = metrics?.find((m) => m.valueId === t.id);

      return {
        tagId: t.id,
        tagType: t.tagType,
        format: t.type,
        name: t.name,
        displayName: t.name,
        order: matchingMatrics ? matchingMatrics.order : index,
        validate: false,
        min: null,
        max: null,
        decimalDigits: getTagDecimalDigits(t.type),
        dateTimeFormat: getTagDateTimeFormat(t.type),
      };
    })
    .sort((m1, m2) => m1.order - m2.order);

  return tagsConfiguration;
};

export const getTagMinVal = (tagType): number => {
  switch (tagType) {
    case 'FLOAT32':
      return -3.402823e38;
    case 'INT8':
      return -128;
    case 'UINT8':
      return 0;
    case 'INT16':
      return -32768;
    case 'UINT16':
      return 0;
    case 'INT32':
      return -2147483648;
    case 'UINT32':
      return 0;
    case 'STRING':
      return 0;
    default:
      return null;
  }
};

export const getTagMaxVal = (tagType): number => {
  switch (tagType) {
    case 'FLOAT32':
      return 3.402823e38;
    case 'INT8':
      return 127;
    case 'UINT8':
      return 255;
    case 'INT16':
      return 32767;
    case 'UINT16':
      return 65535;
    case 'INT32':
      return 2147483647;
    case 'UINT32':
      return 4294967295;
    case 'STRING':
      return 256;
    default:
      return null;
  }
};

const getTagDecimalDigits = (tagType): number => {
  switch (tagType) {
    case 'FLOAT32':
      return 5;
    default:
      return null;
  }
};

const getTagDateTimeFormat = (tagType): DateTimeType => {
  switch (tagType) {
    case 'DATE':
      return 'DATE';
    case 'DATETIME':
      return 'TIME_FORMAT_ONE';
    default:
      return null;
  }
};

export const showValidate = (tagConfig: WidgetUpdateAssetTag): boolean => {
  switch (tagConfig.format) {
    case 'FLOAT32':
    case 'INT8':
    case 'UINT8':
    case 'INT16':
    case 'UINT16':
    case 'INT32':
    case 'UINT32':
    case 'STRING':
      return true;
    default:
      return false;
  }
};

export const showDecimalDigits = (tagConfig: WidgetUpdateAssetTag): boolean => {
  switch (tagConfig.format) {
    case 'FLOAT32':
      // case 'INT8':
      // case 'UINT8':
      // case 'INT16':
      // case 'UINT16':
      // case 'INT32':
      // case 'UINT32':
      return true;
    default:
      return false;
  }
};

export const handleNameChange = (
  displayName,
  editedTagConfig,
  setEditedTagConfig,
  setDisplayNameHasError
) => {
  if (
    displayName.length > ColumnMaxLength &&
    displayName.length > editedTagConfig.displayName.length
  )
    return;
  validateNameError(displayName, setDisplayNameHasError);
  setEditedTagConfig({ ...editedTagConfig, displayName });
};

export const validateNameError = (displayName, setDisplayNameHasError) => {
  setDisplayNameHasError(!isNameValid(displayName));
};

export const isNameValid = (displayName) => {
  return displayName?.length <= ColumnMaxLength;
};

export const handleNameBlur = (editedTagConfig, setEditedTagConfig, props) => {
  const { tagConfig, index, provided, tagConfigChanged } = props;
  const { displayName } = tagConfig;
  if (editedTagConfig.displayName.length < ColumnMinLength) setEditedTagConfig(tagConfig);
  else if (editedTagConfig.displayName !== displayName) tagConfigChanged(editedTagConfig, index);
};

export const handleMinChange = (min: string, editedTagConfig, setEditedTagConfig) => {
  setEditedTagConfig({ ...editedTagConfig, min: min });
};

export const handleMinBlur = (editedTagConfig, props, setEditedTagConfig) => {
  const { tagConfig, index, tagConfigChanged } = props;
  const { min } = tagConfig;
  if (editedTagConfig.min === '') {
    tagConfigChanged({ ...editedTagConfig, min: null }, index);
    setEditedTagConfig({ ...editedTagConfig, min: null });
  } else if (+editedTagConfig.min !== min) {
    let tempMin = editedTagConfig.min || editedTagConfig.min === 0 ? +editedTagConfig.min : null;
    const max = editedTagConfig.max || editedTagConfig.max === 0 ? +editedTagConfig.max : null;
    tempMin =
      ((tempMin || tempMin === 0) && (max || max === 0) && tempMin < max) || max === null
        ? tempMin
        : max;

    // if (
    //   editedTagConfig.format != 'FLOAT32' &&
    //   tempMin !== null &&
    //   !/^[-]?\d*$/.test(tempMin.toString())
    // )
    //   tempMin = min;

    if (tempMin !== null) tempMin = +tempMin;

    tagConfigChanged({ ...editedTagConfig, min: tempMin }, index);
    setEditedTagConfig({ ...editedTagConfig, min: tempMin });
  }
};

export const handleMaxChange = (max: string, editedTagConfig, setEditedTagConfig) => {
  setEditedTagConfig({ ...editedTagConfig, max: max });
};

export const handleMaxBlur = (editedTagConfig, props, setEditedTagConfig) => {
  const { tagConfig, index, tagConfigChanged } = props;
  const { max } = tagConfig;

  if (editedTagConfig.max === '') {
    tagConfigChanged({ ...editedTagConfig, max: null }, index);
    setEditedTagConfig({ ...editedTagConfig, max: null });
  } else if (+editedTagConfig.max !== max) {
    let tempMax = editedTagConfig.max || editedTagConfig.max === 0 ? +editedTagConfig.max : null;
    const min = editedTagConfig.min || editedTagConfig.min === 0 ? +editedTagConfig.min : null;
    tempMax =
      ((min || min === 0) && (tempMax || tempMax === 0) && min < tempMax) || min === null
        ? tempMax
        : min;

    // if (
    //   editedTagConfig.format != 'FLOAT32' &&
    //   tempMax !== null &&
    //   !/^[-]?\d*$/.test(tempMax.toString())
    // )
    //   tempMax = max;

    if (tempMax !== null) tempMax = +tempMax;

    tagConfigChanged({ ...editedTagConfig, max: tempMax }, index);
    setEditedTagConfig({ ...editedTagConfig, max: tempMax });
  }
};

export const minMaxHasError = (editedTagConfig, fieldName) => {
  if (editedTagConfig?.validate && editedTagConfig.min === null && editedTagConfig.max === null)
    return true;

  if (
    editedTagConfig?.validate &&
    editedTagConfig?.format === 'STRING' &&
    +editedTagConfig[fieldName] < 0
  )
    return true;

  if (
    editedTagConfig?.validate &&
    editedTagConfig?.format !== 'FLOAT32' &&
    editedTagConfig[fieldName] &&
    !/^[-]?\d*$/.test(editedTagConfig[fieldName].toString())
  )
    return true;

  return false;
};

export const handleDecimalDigitChange = (
  decimalDigits: string,
  editedTagConfig,
  setEditedTagConfig
) => {
  setEditedTagConfig({ ...editedTagConfig, decimalDigits: +decimalDigits });
};

export const handleDecimalDigitBlur = (editedTagConfig, props, setEditedTagConfig) => {
  const { tagConfig, index, tagConfigChanged } = props;
  const { decimalDigits } = tagConfig;
  if (+editedTagConfig.decimalDigits !== decimalDigits) {
    tagConfigChanged({ ...editedTagConfig, decimalDigits: +editedTagConfig.decimalDigits }, index);
    editedTagConfig.decimalDigits === '' &&
      setEditedTagConfig({ ...editedTagConfig, decimalDigits: 0 });
  }
};

export const onSelectedDateTimeFormat = (editedTagConfig, value, setEditedTagConfig, props) => {
  const { index, tagConfigChanged } = props;
  const _tagConfig = cloneDeep({ ...editedTagConfig, dateTimeFormat: value });
  setEditedTagConfig(_tagConfig);
  tagConfigChanged(_tagConfig, index);
};

export const handleValidateChange = (value, editedTagConfig, setEditedTagConfig, props) => {
  const { index, provided, tagConfigChanged } = props;
  const _tagConfig = cloneDeep({ ...editedTagConfig, validate: value });
  setEditedTagConfig(_tagConfig);
  tagConfigChanged(_tagConfig, index);
};

export const onTagConfigChanged = (tagConfig, idx, tagsConfiguration, setCustomizationData) => {
  const _tagsConfiguration = cloneDeep(tagsConfiguration);
  _tagsConfiguration[idx] = tagConfig;
  setCustomizationData(_tagsConfiguration, 'tagsConfiguration');
};
