import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import DataViewer from '@components/DataViewer';
import { useSelector } from '@redux/useSelector';
import { AssetData } from '@redux/redux.interface.d';
import EditAssetTabs from './EditAssetTabs/EditAssetTabs';
import { colorMap } from '@core/statusColorMap';
import { editAssetService } from './EditAssetService';
import styles from './EditAssetModal.scss';
import { LastUpdated } from './EditAssetModal.interface';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { getAssetCertificat } from '@pages/DeviceManagment/Assets/AssetsTab/assetsTab.utils';
import { modalService } from '@core/modals/ModalService';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';
import moment from 'moment-timezone';
import { staticDictionary } from '@core/i18n/dictionary';
import { getState } from '@src/redux/store';
import RemoteAccessViewer from './RemoteAccessViewer';
import { saveSingleField } from './EditAssetModal.utils';

function EditAssetModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const rowIdx = args.rowIdx;
  const { macchinaConnected } = args.rowData;
  const asset = args.rowData as AssetData;
  const assetInfo = useSelector((state) => state.editAsset);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [remoteAccessMode, setRemoteAccessMode] = useState(assetInfo?.staticData?.remoteAccessMode);
  const [disconnect, setDisconnect] = useState<boolean>();
  const [disableTelemetries, setDisableTelemetries] = useState(false);
  const [archive, setArchive] = useState<boolean>();
  const [lastUpdated, setLastUpdated] = useState();
  const orgTimezone = useSelector((state) => state.config && state.config.timezone);
  const [supportGpsLocations, setSupportGpsLocations] = useState(false);
  const [gpsLocationComfirmOpen, setGpsLocationComfirmOpen] = useState(false);
  const selectOrgTimezone = useSelector(
    (state) => state.organizations && state.organizations.timezone
  );
  const flags = useMemo(
    () => ({
      getCertificate: getFlagStatus('assets.get-certificate-button'),
      archive: getFlagStatus('assets.archive-button'),
      connect: getFlagStatus('assets.connect-button'),
      getTelemetry: getFlagStatus('assets.get-telemetry-button'),
      remoteAccess: getFlagStatus('assets.remote-access-buttons'),
    }),
    []
  );

  const [assetNameEditMode, setAssetNameEditMode] = useState(false);
  const [assetSerialEditMode, setAssetSerialEditMode] = useState(false);

  const [assetName, setAssetName] = useState<string>();
  const [assetSerial, setAssetSerial] = useState<string>();

  useEffect(() => {
    setRemoteAccessMode(assetInfo?.remoteAccessMode);
    setAssetName(assetInfo?.staticData?.assetName);
    setAssetSerial(assetInfo?.staticData?.assetSerial);
  }, [assetInfo]);

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const firstDataViewerData = assetInfo &&
    assetInfo.staticData && [
      {
        id: 1,
        label: 'edit-asset-modal.asset-name',
        initialValue: assetInfo?.staticData?.assetName,
        value: assetName,
        setValue: setAssetName,
        allowEdit: true,
        allowEmpty: false,
        editMode: assetNameEditMode,
        setEditMode: setAssetNameEditMode,
        onSave: saveSingleField,
        onSaveArgs: { assetId: asset.assetId, fieldName: 'assetName' },
        valueStyle: { minWidth: '150px', maxWidth: '150px' },
        inputStyle: { minWidth: '150px', maxWidth: '150px' },
      },
      {
        id: 2,
        label: 'edit-asset-modal.asset-serial',
        initialValue: assetInfo?.staticData?.assetSerial,
        value: assetSerial,
        setValue: setAssetSerial,
        allowEdit: true,
        allowEmpty: true,
        editMode: assetSerialEditMode,
        setEditMode: setAssetSerialEditMode,
        onSave: saveSingleField,
        onSaveArgs: { assetId: asset.assetId, fieldName: 'assetSerial' },
        maxLength: 15,
        valueStyle: { minWidth: '150px', maxWidth: '150px' },
        inputStyle: { minWidth: '150px', maxWidth: '150px' },
      },
      { id: 3, label: 'edit-asset-modal.asset-id', value: asset.assetId },
    ];

  const secondDataViewerData = assetInfo &&
    assetInfo.staticData && [
      { label: 'edit-asset-modal.plc-serial', value: assetInfo.staticData.plcSerial },
      {
        label: 'edit-asset-modal.updated-at',
        value: lastUpdated
          ? moment(lastUpdated)
              .tz(selectOrgTimezone || orgTimezone)
              .format('DD/MM/YYYY HH:mm:ss')
          : '',
      },
      {
        label: 'edit-asset-modal.connected-at',
        value:
          assetInfo.staticData.connectedAt &&
          moment(assetInfo.staticData.connectedAt)
            .tz(selectOrgTimezone || orgTimezone)
            .format('DD/MM/YYYY'),
      },
    ];

  useEffect(() => {
    editAssetService.setModalDismiss(dismiss);
    editAssetService.getAssetInfo(asset.assetId, rowIdx);
    editAssetService.initDataInterval(
      asset.assetId,
      getLastUpdated,
      'lastUpdated',
      editAssetService.INTERVAL_TIMER
    );

    return () => editAssetService.clearDataInterval('lastUpdated');
  }, []);

  useEffect(() => {
    editAssetService.setModalArchive(archive);
  }, [archive]);

  useEffect(() => {
    if (assetInfo && assetInfo.staticData) {
      const stateBtnVal = editAssetService.getStateBtnVal(
        assetInfo.staticData.state,
        assetInfo.staticData?.subscription?.status,
        asset.isDemo
      );
      stateBtnVal && setDisconnect(stateBtnVal.disconnect);
      stateBtnVal && setArchive(stateBtnVal.archive);
      !gpsLocationComfirmOpen && setSupportGpsLocations(assetInfo.staticData.gpsSupport);
    }
  }, [assetInfo, asset]);

  const getLastUpdated = useCallback((assetId: string) => {
    const remoteAccessModeState = getState().editAsset?.remoteAccessMode;
    !remoteAccessModeState &&
      httpService
        .api({
          type: 'getLastUpdated',
          urlParams: { assetId },
          disableBI: true,
        })
        .then((res: LastUpdated) => {
          setLastUpdated(res.lastUpdate);
          assetsService.updateAssetTableData(res, rowIdx);
        });
  }, []);

  const handleStateChange = (key: 'archive' | 'disconnect') => {
    let apiType;
    if (key === 'archive') {
      apiType = archive ? 'archive' : 'activate';
      if (archive) {
        setDisconnect(false);
      }
      setArchive(!archive);
    }
    if (key === 'disconnect') {
      apiType = disconnect ? 'disconnect' : 'connect';
      setDisconnect(!disconnect);
    }

    httpService
      .api({
        type: apiType,
        urlParams: { assetId: asset.assetId },
      })
      .then((res) => {
        assetsService.updateAssetTableData(res, rowIdx);
        editAssetService.updateEditAsset(res);
        if (apiType === 'archive') {
          modalService.openModal('confirm', {
            text: 'edit-asset-modal.archive-message',
            iconType: 'attention_image',
            confirmText: 'general.close',
            headerText: assetInfo.staticData.assetName,
            showCloseBtn: true,
          });
        }
      });
  };

  const handleSupportGpsChanged = (e) => {
    const value = e.target.checked;
    setSupportGpsLocations(value);

    const text = value
      ? 'edit-asset-modal.support-gps-locations.turn-on-message'
      : 'edit-asset-modal.support-gps-locations.turn-off-message';

    setGpsLocationComfirmOpen(true);
    modalService
      .openModal('confirm', {
        style: { width: 589 },
        text,
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'edit-asset-modal.support-gps-locations.support-gps-locations.popup-header',
        assetName: asset.assetName,
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          editAssetService.updateEditAsset({
            gpsSupport: value,
          });
          httpService.api({
            type: 'saveGeneralAsset',
            urlParams: { assetId: asset.assetId, useUserOrganizationId: true },
            data: { gpsSupport: value },
          });
        } else {
          setSupportGpsLocations(!value);
        }
        setGpsLocationComfirmOpen(false);
      });
  };

  const getCertificat = () => {
    getAssetCertificat(asset.assetId);
  };

  const onGetAssetData = useCallback(() => {
    setDisableTelemetries(true);
    httpService
      .api({
        type: 'getAssetData',
        urlParams: { assetId: asset.assetId },
        disableBI: true,
      })
      .then((res) => {
        setTimeout(() => {
          setDisableTelemetries(false);
        }, 10000);
      });
  }, [asset]);

  return (
    <div className={styles.wrapper}>
      <I18n
        className={styles.modalHeader}
        element="div"
        assetName={assetInfo && assetInfo.staticData && assetInfo.staticData.assetName}>
        edit-asset-modal.title
      </I18n>
      <div className={styles.modalContent}>
        <div className={styles.modalToolbar}>
          {assetInfo && assetInfo.staticData && (
            <div className={styles.statusWarpper}>
              <div className={styles.status}>
                <I18n>edit-asset-modal.status</I18n>:
              </div>
              <div
                className="ellipsis-overflow"
                title={staticDictionary[`enum.${assetInfo.staticData.status}`]}
                style={{
                  color: colorMap[assetInfo.staticData.status],
                }}>
                {staticDictionary[`enum.${assetInfo.staticData.status}`]}
              </div>
            </div>
          )}
          <div className={styles.toolbarsIcon}>
            {flags.getTelemetry && !remoteAccessMode && (
              <div
                className={classNames(
                  styles.btnGroup,
                  hasPermission
                    ? [
                        'pointer',
                        (!archive ||
                          !disconnect ||
                          disableTelemetries ||
                          assetInfo?.staticData?.status === 'PENDING_ROUTER') &&
                          styles.disabledbutton,
                      ]
                    : styles.disabledbutton
                )}
                onClick={onGetAssetData}>
                <Icon className={styles.iconBtn} type="sync" color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>edit-asset-modal.get-telemetry</I18n>
              </div>
            )}
            {flags.getCertificate && (
              <div
                onClick={getCertificat}
                className={classNames(
                  assetInfo?.staticData?.hasCertificate && hasPermission
                    ? 'pointer'
                    : styles.disabledbutton,
                  styles.btnGroup
                )}>
                <Icon
                  className={styles.iconBtn}
                  type="certificate"
                  color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>edit-asset-modal.certificate</I18n>
              </div>
            )}
            {flags.archive && (
              <div
                onClick={() => handleStateChange('archive')}
                className={classNames(
                  hasPermission ? 'pointer' : styles.disabledbutton,
                  styles.btnGroup
                )}>
                <Icon className={styles.iconBtn} type="archive" color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>{`enum.${archive ? 'ARCHIVE' : 'ACTIVATE'}`}</I18n>
              </div>
            )}
            {flags.connect && (
              <div
                onClick={() =>
                  hasPermission &&
                  assetInfo?.staticData?.status !== 'PENDING_ROUTER' &&
                  assetInfo?.staticData?.plcSerial &&
                  assetInfo.staticData?.subscription &&
                  assetInfo.staticData?.subscription?.status !== 'EXPIRED' &&
                  handleStateChange('disconnect')
                }
                className={classNames(
                  hasPermission &&
                    assetInfo?.staticData?.status !== 'PENDING_ROUTER' &&
                    assetInfo?.staticData?.plcSerial &&
                    assetInfo.staticData?.subscription &&
                    assetInfo.staticData?.subscription?.status !== 'EXPIRED'
                    ? 'pointer'
                    : styles.disabledbutton,
                  styles.btnGroup,
                  !archive && styles.disabledbutton
                )}>
                <Icon className={styles.iconBtn} type="disconnect" color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>{`enum.${
                  disconnect ? 'DISCONNECT' : 'CONNECT'
                }`}</I18n>
              </div>
            )}
          </div>
        </div>
        <div className={styles.toolBarLine}></div>

        {assetInfo && assetInfo.staticData && (
          <>
            <div className={styles.assetDataSection}>
              <DataViewer
                data={firstDataViewerData}
                boldLabel
                marginBottom
                labelStyle={{ width: 'fit-content', maxWidth: '190px' }}
              />
              <DataViewer
                data={
                  remoteAccessMode
                    ? [secondDataViewerData[0], secondDataViewerData[2]]
                    : secondDataViewerData
                }
                boldLabel
                marginBottom
                labelStyle={{ width: 'fit-content', maxWidth: '190px' }}
              />
              {flags.remoteAccess && (
                <RemoteAccessViewer
                  id={'9675a0d2-e57d-4a13-a516-da8cf1a2559f'}
                  assetInfo={assetInfo}
                  macchinaConnected={macchinaConnected}
                />
              )}
            </div>
            <div className={styles.tabsSection}>
              <EditAssetTabs
                handleSupportGpsChanged={handleSupportGpsChanged}
                supportGpsLocations={supportGpsLocations}
                hasPermission={hasPermission}
                remoteAccessMode={remoteAccessMode}
                setRemoteAccessMode={setRemoteAccessMode}
              />
            </div>
          </>
        )}

        {!assetInfo && (
          <div className={styles.modalButtons}>
            <Button mode={['cancel', 'bigFont']} disabled={false} onClick={cancel}>
              <I18n>general.cancel</I18n>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditAssetModal;
