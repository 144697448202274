import React, { useMemo, useEffect } from 'react';
import { TextField, FormControlLabel, Radio } from '@material-ui/core';

// Components
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';

// Services | Interfaces
import { PieCustomizationProps } from './PieCustomization.interface';
import { PieCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { PieDisplayType } from '@components/widgets/PieWidget/PieWidget.interface';
import { findDateTimeTag, getTag, getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import {
  alignmentOptions,
  getDefaultWidgetColorPalette,
  positionOptions,
  updateWidgetCustomization,
} from '@core/canvas/widget.utils';
import styles from './PieCustomization.scss';

// 3rd party
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

const selectCustomStyle = { control: { height: 40 }, container: { width: 357 } };

const PieCustomization = (props: PieCustomizationProps) => {
  const { widgetData, setWidgetData, defaultDecimalDigits } = props;
  const { groupBy } = widgetData;
  const customization = cloneDeep(widgetData.customization);
  const dateTimeTags = useMemo(() => findDateTimeTag(groupBy, true), [groupBy]);
  const format = useMemo(() => {
    const originalTags = dateTimeTags.map((tag) => getTag(widgetData, tag));
    return originalTags.length
      ? originalTags.find((t) => (t.format || t.type) === 'DATETIME')
        ? 'DATETIME'
        : 'DATE'
      : null;
  }, [dateTimeTags]);
  const dateTimeOptions = useMemo(() => getDateTimeOptions(format), [format]);

  const {
    showAs,
    textColor,
    subtitle_text,
    subtitle_textColor,
    subtitle_alignment,
    legend_visible,
    legend_position,
    legend_alignment,
    tooltip_visible,
    tooltip_showLegend,
    dataLabels_visible,
    dataLabels_textColor,
    showTotal_visible,
    showTotal_decimalDigits = defaultDecimalDigits,
    showValues_visible,
    showValues_decimalDigits = defaultDecimalDigits,
    totalAndValuesColor,
    colorPalette,
    dateTimeFormat,
  } = (customization as PieCustomization) || {};

  const subtitleAlignment = useMemo(
    () => subtitle_alignment && alignmentOptions.find((o) => o.value === subtitle_alignment),
    [subtitle_alignment]
  );
  const lengedPosition = useMemo(
    () => legend_position && positionOptions.find((o) => o.value === legend_position),
    [legend_position]
  );
  const legendAlignment = useMemo(
    () => legend_alignment && alignmentOptions.find((o) => o.value === legend_alignment),
    [legend_alignment]
  );

  useEffect(() => {
    const tempDateTimeFormat =
      format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null;
    if (!customization) {
      updateWidgetCustomization(
        {
          showAs: PieDisplayType.pie,
          textColor: 'var(--widgetsFont)',
          subtitle_text: '',
          subtitle_textColor: 'var(--widgetsFont)',
          subtitle_alignment: 'CENTER',
          legend_visible: true,
          legend_position: 'BOTTOM',
          legend_alignment: 'CENTER',
          tooltip_visible: true,
          tooltip_showLegend: true,
          dataLabels_visible: true,
          dataLabels_textColor: 'var(--widgetsFont)',
          showValues_visible: true,
          showValues_decimalDigits: defaultDecimalDigits,
          totalAndValuesColor: 'var(--widgetsFont)',
          colorPalette: getDefaultWidgetColorPalette(),
          dateTimeFormat: tempDateTimeFormat,
        },
        setWidgetData
      );
    } else if (!customization.dateTimeFormat && format) {
      updateWidgetCustomization({ dateTimeFormat: tempDateTimeFormat }, setWidgetData);
    }
  }, []);

  const onChange = (value, property: string) => {
    switch (property) {
      case 'showTotal_decimalDigits':
      case 'showValues_decimalDigits':
        const reg = /^\d+$/;
        if ((!reg.test(value) && value !== '') || +value > 9) return;
        break;
    }
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        [property]: value,
      },
    }));
  };

  return (
    <div className={styles.customizationWrapper}>
      {/* show-as */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <I18n>create-widget-page.create-widget.step-four.show-as</I18n>
        </div>
        <div className={classNames(styles.item, styles.radioMargin)}>
          <Radio
            color="primary"
            checked={showAs?.toLocaleLowerCase() === PieDisplayType.pie}
            value={PieDisplayType.pie}
            onChange={(e) => onChange(e.target.value, 'showAs')}
          />
          <I18n className={styles.radioLabel}>create-widget-page.create-widget.step-four.pie</I18n>
        </div>
        <div className={classNames(styles.item, styles.radioMargin)}>
          <Radio
            color="primary"
            checked={showAs?.toLocaleLowerCase() === PieDisplayType.donut}
            value={PieDisplayType.donut}
            onChange={(e) => onChange(e.target.value, 'showAs')}
          />
          <I18n className={styles.radioLabel}>
            create-widget-page.create-widget.step-four.donut
          </I18n>
        </div>
        <div className={classNames(styles.item, styles.colorItem)}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.text-color</I18n>
          <ColorInput
            value={textColor}
            customeMarginButton={0}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            colorChange={(value) => onChange(value, 'textColor')}
          />
        </div>
      </div>
      {/* dateTimeFormat */}
      {dateTimeOptions && (
        <div className={styles.rowDetails}>
          <div className={styles.item}>
            <I18n className={styles.sectionTitle} noEllipsis>
              create-widget-page.create-widget.step-four.date-time
            </I18n>
            <Select
              styles={selectCustomStyle}
              options={dateTimeOptions}
              value={dateTimeFormat && dateTimeOptions.find((opt) => opt.value === dateTimeFormat)}
              onChange={(option) => onChange(option.value, 'dateTimeFormat')}
            />
          </div>
        </div>
      )}
      {/* subtitle */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <I18n>create-widget-page.create-widget.step-four.subtitle</I18n>
        </div>
        <div className={styles.item}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.text</I18n>
          <TextField
            value={subtitle_text}
            margin="dense"
            variant="outlined"
            className={styles.textField}
            inputProps={{
              maxLength: 50,
              style: { color: 'var(--systemFont)' },
            }}
            onChange={(e) => onChange(e.target.value, 'subtitle_text')}
          />
        </div>
        <div className={classNames(styles.item, styles.colorItem)}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.color</I18n>
          <ColorInput
            disabled={!subtitle_text}
            value={subtitle_textColor}
            customeMarginButton={0}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            colorChange={(value) => onChange(value, 'subtitle_textColor')}
          />
        </div>
        <div className={styles.item}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.aligment</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!subtitle_text}
            options={alignmentOptions}
            value={subtitleAlignment}
            onChange={(option) => onChange(option.value, 'subtitle_alignment')}
            unSelect={true}
          />
        </div>
      </div>
      {/* legend */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <I18n>create-widget-page.create-widget.step-four.legend</I18n>
        </div>
        <div className={styles.item}>
          <MaterialCheckbox
            color="primary"
            checked={!!legend_visible}
            onClick={() => onChange(!legend_visible, 'legend_visible')}
          />
          <I18n className={styles.cbLabel}>create-widget-page.create-widget.step-four.visible</I18n>
        </div>
        <div className={styles.item}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.position</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!legend_visible}
            options={positionOptions}
            value={lengedPosition}
            onChange={(option) => onChange(option.value, 'legend_position')}
            unSelect={true}
          />
        </div>
        <div className={styles.item}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.aligment</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!legend_visible}
            options={alignmentOptions}
            value={legendAlignment}
            onChange={(option) => onChange(option.value, 'legend_alignment')}
            unSelect={true}
          />
        </div>
      </div>
      {/* tooltip */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <I18n>create-widget-page.create-widget.step-four.tooltip</I18n>
        </div>
        <div className={styles.item}>
          <MaterialCheckbox
            color="primary"
            checked={!!tooltip_visible}
            onClick={() => onChange(!tooltip_visible, 'tooltip_visible')}
          />
          <I18n className={styles.cbLabel}>create-widget-page.create-widget.step-four.visible</I18n>
        </div>
      </div>
      {/* data-labels */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <I18n>create-widget-page.create-widget.step-four.data-labels</I18n>
        </div>
        <div className={styles.item}>
          <MaterialCheckbox
            color="primary"
            checked={!!dataLabels_visible}
            onClick={() => onChange(!dataLabels_visible, 'dataLabels_visible')}
          />
          <I18n className={styles.cbLabel}>create-widget-page.create-widget.step-four.visible</I18n>
        </div>
        <div className={classNames(styles.item, styles.colorItem)}>
          <I18n className={styles.text}>create-widget-page.create-widget.step-four.text-color</I18n>
          <ColorInput
            disabled={!dataLabels_visible}
            value={dataLabels_textColor}
            customeMarginButton={0}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            colorChange={(value) => onChange(value, 'dataLabels_textColor')}
          />
        </div>
      </div>
      {showAs?.toLocaleLowerCase() === PieDisplayType.donut && (
        <>
          {/* show-values */}
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <I18n>create-widget-page.create-widget.step-four.show-values</I18n>
            </div>
            <div className={styles.item}>
              <MaterialCheckbox
                color="primary"
                checked={!!showValues_visible}
                onClick={() => onChange(!showValues_visible, 'showValues_visible')}
              />
              <I18n className={styles.cbLabel}>
                create-widget-page.create-widget.step-four.visible
              </I18n>
            </div>
            <div className={styles.item}>
              <I18n className={styles.text}>
                create-widget-page.create-widget.step-four.decimal-digits
              </I18n>
              <TextField
                disabled={!showValues_visible}
                className={styles.textField}
                value={showValues_decimalDigits}
                margin="dense"
                variant="outlined"
                onChange={(e) => onChange(e.target.value, 'showValues_decimalDigits')}
                type="number"
                inputProps={{
                  style: { color: 'var(--systemFont)' },
                }}
              />
            </div>
            <div className={classNames(styles.item, styles.colorItem)}>
              <I18n className={styles.text}>
                create-widget-page.create-widget.step-four.text-color
              </I18n>
              <ColorInput
                disabled={!showValues_visible && !showTotal_visible}
                value={totalAndValuesColor}
                customeMarginButton={0}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                colorChange={(value) => onChange(value, 'totalAndValuesColor')}
              />
            </div>
          </div>
        </>
      )}
      {/* color-palette */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <I18n>create-widget-page.create-widget.step-four.color-palette</I18n>
        </div>
        <div className={styles.colotPalleteWrapper}>
          {colorPalette?.map((color, index) => (
            <div key={`${color}-${index}`} className={classNames(styles.item, styles.colorItem)}>
              <span className={styles.colotPalleteText}>{index + 1}</span>
              <ColorInput
                value={color}
                customeMarginButton={0}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                colorChange={(value) =>
                  onChange(
                    colorPalette.map((originalVal, idx) => (idx === index ? value : originalVal)),
                    'colorPalette'
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieCustomization;
