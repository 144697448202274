import React, { useMemo, useState, useEffect } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';

// Services | Interfaces
import styles from './AssetInfoSubscriptionView.scss';

// 3rd party
import 'moment-duration-format';
import EditTimer from './EditTimer';
import ButtonsRow from './ButtonsRow';
import SubscriptionsList from './SubscriptionsList';
import EditLicensePlan from './EditLicensePlan';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { useSelector } from '@src/redux/useSelector';
import classNames from 'classnames';
import { getSubscriptions } from '@modals/EditAssetModal/EditAssetModal.utils';

function AssetInfoSubscriptionView(props) {
  const [editMode, setEditMode] = useState() as any;
  const [subscriptionsData, setSubscriptionsData] = useState() as any;
  const {
    assetSubscription,
    duration,
    cancel,
    onCancelEdit,
    assetId,
    remoteAccessMode,
    setRemoteAccessMode,
  } = props;
  const { licences, defaultLicenceId, requiredPlan, currentLicenceId, licensingPlans } =
    assetSubscription || {};
  const selectedLicensePlan = useMemo(
    () => licences?.find((l) => defaultLicenceId === l.id),
    [defaultLicenceId, licences]
  );

  const licencesOptions = useMemo(() => {
    if (licences && requiredPlan && licensingPlans) {
      let options = licences;
      const minLicense = licensingPlans?.find((l) => l.planName === requiredPlan);
      const minLicenseIndex = options?.findIndex((l) => l.id === minLicense?.licenceId);
      minLicenseIndex > 0 && options.splice(0, minLicenseIndex);
      const remoteAccessPlan = licensingPlans.find((l) => l.planName === 'Remote Access');
      !options?.some((opt) => opt.name === 'Remote Access') && remoteAccessPlan
        ? (options = [
            remoteAccessPlan && {
              id: remoteAccessPlan.licenceId,
              name: remoteAccessPlan.planName,
            },
            ...options,
          ])
        : (options = [...options]);
      return options;
    }
  }, [licences, requiredPlan, licensingPlans]);

  useEffect(() => {
    if (selectedLicensePlan?.name === 'Remote Access') {
      editAssetService.setRemoteAccessMode(true);
      setRemoteAccessMode(true);
    } else {
      editAssetService.setRemoteAccessMode(false);
      setRemoteAccessMode(false);
    }
  }, [selectedLicensePlan]);

  const assetInfo = useSelector((state) => state.editAsset);

  useEffect(() => {
    getSubscriptions(+assetId, setSubscriptionsData);
  }, []);

  useEffect(() => {
    if (subscriptionsData) editAssetService.getAndUpdateAssetSubscription(assetInfo);
  }, [subscriptionsData]);

  const onTimerEditClicked = ({ value }) => {
    setEditMode(value ?? 'timer');
  };

  const onLicensePlanEditClicked = ({ value }) => {
    setEditMode(value ?? 'licensePlan');
  };

  const onEditDone = (isUpdate, data) => {
    setEditMode('');
    onCancelEdit(isUpdate, data);
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.dataSection}>
          <div className={styles.upperSection}>
            <div className={styles.firstRow}>
              <div className={styles.topSection}>
                <div className={styles.leftColumn}>
                  {!remoteAccessMode && (
                    <div className={styles.timer}>
                      <div className={classNames(styles.header, 'ellipsis-overflow')}>
                        <I18n>asset-subscription.tags-timer-interval</I18n>:
                      </div>
                      {editMode !== 'timer' ? (
                        <>
                          <span className={styles.dataValue}>{duration}</span>
                          <Icon
                            type="edit"
                            className={styles.editIcon}
                            onClick={onTimerEditClicked}
                            color="var(--systemFont)"
                          />
                        </>
                      ) : (
                        <EditTimer
                          setTimerEditMode={setEditMode}
                          onCancelEditClicked={onEditDone}
                          {...props}
                        />
                      )}
                    </div>
                  )}
                  {assetSubscription && (
                    <div>
                      <div className={styles.requiredPlan}>
                        <I18n className={styles.header}>
                          asset-subscription.tags-licensing-plan
                        </I18n>
                        {editMode !== 'licensePlan' ? (
                          <>
                            <span className={styles.dataValue}>{selectedLicensePlan?.name}</span>
                            <Icon
                              type="edit"
                              color="var(--systemFont)"
                              className={styles.editIcon}
                              onClick={onLicensePlanEditClicked}
                            />
                          </>
                        ) : (
                          <EditLicensePlan
                            setTimerEditMode={setEditMode}
                            onCancelEditClicked={onEditDone}
                            {...props}
                            selectedLicensePlan={selectedLicensePlan}
                            licences={licencesOptions}
                            licensingPlans={licensingPlans}
                            currentLicenceId={currentLicenceId}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <ButtonsRow
                {...props}
                requiredPlan={requiredPlan}
                subscriptionsData={subscriptionsData}
                setSubscriptionsData={setSubscriptionsData}
              />
            </div>
          </div>
          <SubscriptionsList requiredPlan={requiredPlan} subscriptionsData={subscriptionsData} />
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetInfoSubscriptionView;
