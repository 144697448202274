import { useSelector } from '@src/redux/useSelector';
import React, { useState } from 'react';
import classNames from 'classnames';
import { TemplateWizardProps } from './TemplateWizard.interface';
import styles from './TemplateWizard.scss';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import Icon from '@components/Icon';
import I18n from '@components/I18n';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    minWidth: '24px',
    width: '24px',
    height: ' 24px',
    boxShadow: '0 2px 4px 0 var(--widgetGalleryArrowBoxShadow)',
    background: 'var(--lightGrey)',
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold' as 'bold',
  },
  active: {
    boxShadow: 'var(--boxShadow_1)',
    backgroundColor: 'var(--systemFontSelected)',
    color: 'var(--white1)',
  },
  disabledButton: {
    opacity: 0.4,
  },
}));

function TemplateWizard(props: TemplateWizardProps) {
  const { currentStep, onUserChangeStep, stepsList, templateData } = props;
  const classes = useStyles(props);
  return (
    <div className={classNames(styles.wizardTopBar)}>
      {stepsList.map((item, idx) => (
        <React.Fragment key={idx}>
          <div>
            <div className={styles.step}>
              <div
                className={classNames(currentStep === item.stepIndex && styles.currentStep)}></div>
              <Button
                onClick={() => !item.isDisable && onUserChangeStep(currentStep, item.stepIndex)}
                disabled={item.isDisable}
                className={classNames(
                  classes.label,
                  classes.root,
                  (currentStep === item.stepIndex || item.isDone) && classes.active,
                  currentStep === item.stepIndex && styles.currentStep,
                  currentStep < item.stepIndex &&
                    item.isDone &&
                    item.isDisable &&
                    classes.disabledButton
                )}>
                {item.isDone ? (
                  <Icon className={styles.innerIcon} type="smallCheckMark" />
                ) : (
                  idx + 1
                )}
              </Button>
              {idx + 1 < stepsList.length && (
                <div
                  className={classNames(
                    styles.line,
                    idx + 1 < currentStep && styles.activeLine
                  )}></div>
              )}
            </div>
            <div className={styles.data}>
              <div
                className={classNames(
                  styles.title,
                  currentStep === item.stepIndex && styles.activeTitle
                )}>
                <I18n>{item.title}</I18n>
              </div>
              <div
                className={classNames(
                  'ellipsis-overflow',
                  styles.text,
                  currentStep === item.stepIndex && styles.activeText
                )}></div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default TemplateWizard;
