import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { AddVariableModal } from './AddVariableModal.interface';
import styles from './AddVariableModal.scss';
import { SelectOption } from '@components/Select/Select.interface';
import { getDefaultValueByType, getFieldsCfg } from './AddVariableModal.utils';
import FormikField from '@components/FormikField';
import * as Yup from 'yup';
import { httpService } from '@core/http/HttpService';
import { useSelector } from '@src/redux/useSelector';
import { VariableMeta } from '@core/http/server.interface';
import Icon from '@components/Icon';
import { validateName, valueTypeOptions } from '@pages/OrganizationsPage/Variables/Variables.utils';

function AddVariableModal(props: ModalComponentProps) {
  const assetTypeId = useSelector((state) => state.editAssetType.staticData.id);
  const { dismiss, args } = props;
  const { setVariablesList } = args;
  const [valueType, setValueType] = useState<SelectOption>(valueTypeOptions[0]);
  const [nameValidation, setNameValidation] = useState({
    name: '',
    message: '',
    isValid: true,
  });
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: null,
    valueType: valueType,
    value: getDefaultValueByType(valueType),
    managementLevel: { value: 'GLOBAL', label: 'variables.management-level.global' },
  });

  const fieldsCfg = getFieldsCfg(
    nameValidation,
    setNameValidation,
    valueType.value,
    setValueType,
    getDefaultValueByType
  );

  function cancel() {
    dismiss(false);
  }

  async function onSubmit(values, formik) {
    const { name, valueType, value, managementLevel } = values;
    await validateName(assetTypeId, initialValues, setNameValidation, formik, name);
    httpService
      .api({
        type: 'addAssetTypeVariable',
        urlParams: { assetTypeId: assetTypeId },
        data: {
          name: name,
          valueType: valueType.value,
          value:
            valueType.value === 'TEXT' && !value
              ? ''
              : valueType.value === 'NUMERIC'
              ? +value
              : valueType.value === 'BOOLEAN'
              ? value == 0
                ? true
                : false
              : value,
          managementLevel: managementLevel.value,
        },
      })
      .then(() => {
        httpService
          .api({ type: 'getAssetTypeVariablesList', urlParams: { id: assetTypeId } })
          .then((res: VariableMeta[]) => {
            setVariablesList(res);
          });
        dismiss(true);
      });
  }

  function validate(values) {
    return [...fieldsCfg].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );
  }

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>variables.add-variable-pop-up.header</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema([...fieldsCfg])}
        validate={validate}
        onSubmit={onSubmit}>
        {({ isValid, isSubmitting, values, errors, setFieldValue }) => {
          return (
            <FormikForm className={styles.modalForm}>
              <div className={styles.modalContent}>
                {fieldsCfg.map((field, idx) => (
                  <FormikField
                    key={idx}
                    editMode={true}
                    {...field}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
              <div className={styles.modalButtons}>
                <Button styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']} onClick={cancel}>
                  <I18n>general.cancel</I18n>
                </Button>
                <Button
                  styles={{ marginLeft: 13 }}
                  mode="bigFont"
                  type="submit"
                  disabled={!isValid}>
                  <I18n>general.save</I18n>
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddVariableModal;
