import Value from './CostumizationComponents/Value';
import Text from './CostumizationComponents/Text';
import Image from './CostumizationComponents/Image';
import Map from './CostumizationComponents/Map';
import Columns from './CostumizationComponents/Columns';
import TableCustomization from './CostumizationComponents/TableCustomization';
import PieCustomization from './CostumizationComponents/PieCustomization';
import Line from './CostumizationComponents/Line';
import ProgressBar from './CostumizationComponents/ProgressBar';
import RadialGaugeCustomization from './CostumizationComponents/Gauge/RadialGaugeCustomization';
import LinearGaugeCustomization from './CostumizationComponents/Gauge/LinearGaugeCustomization';
import ImageByValue from './CostumizationComponents/ImageByValue';
import ConditionalValue from './CostumizationComponents/ConditionalValue';
import Switch from './CostumizationComponents/Switch';
import UpdateAsset from './CostumizationComponents/UpdateAsset';
import Vnc from './CostumizationComponents/Vnc';
import Web from './CostumizationComponents/Web';
import Vpn from './CostumizationComponents/Vpn';
import Combo from './CostumizationComponents/Combo';
import Heatmap from './CostumizationComponents/Heatmap';

export const CustomizationMap = {
  value: Value,
  image: Image,
  text: Text,
  columns: Columns,
  table: TableCustomization,
  radial_gauge: RadialGaugeCustomization,
  linear_gauge: LinearGaugeCustomization,
  alarms: TableCustomization,
  pie: PieCustomization,
  line: Line,
  map: Map,
  image_by_value: ImageByValue,
  progress_bar: ProgressBar,
  conditional_value: ConditionalValue,
  switch: Switch,
  update_asset: UpdateAsset,
  vnc: Vnc,
  web: Web,
  vpn: Vpn,
  asset: Combo,
  heatmap: Heatmap,
};
