import React from 'react';
import classNames from 'classnames';
import styles from './ButtonComponent.scss';
import I18n from '@components/I18n';
import Icon from '@components/Icon';

function ButtonComponent({ id, caption, type, onClick, disabled }) {
  return (
    <div
      id={id}
      className={classNames(styles.wrapper, disabled ? styles.disabledCursor : undefined)}
      onClick={onClick}>
      <div className={styles.icon}>
        <Icon className={styles.icon} color={'var(--systemFont)'} type={type} disabled={disabled} />
      </div>
      <div className={classNames('ellipsis-overflow', disabled ? styles.disabled : undefined)}>
        <I18n>{caption}</I18n>
      </div>
    </div>
  );
}

export default ButtonComponent;
