import React from 'react';
import MultiSelect from '@components/MultiSelect';
import styles from './AssetTypesInput.scss';
import { filterOption, getOptionLabel, getOptionValue } from './AssetTypesInput.utils';
import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';

function AssetTypesInput({
  assetTypes,
  assetTypesOptions,
  replacements,
  replaceAssetTypes,
  widgetData,
  eventTemplateId,
  isSelectAllAllowed,
  isTarget,
  isDisabled = false,
  ...restProps
}) {
  const { isOptionDisabled } = restProps;

  function onSelectAll() {
    replaceAssetTypes(undefined, true);
  }

  async function onChange(option) {
    if (replacements?.tagData?.length && replacements?.tagData.some((tag) => tag.replaceWith)) {
      await modalService
        .openModal('confirm', {
          iconType: 'attention_image',
          text: 'create-widget-page.create-widget.step-one.replace-tags.initialize-tags-message',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText:
            'create-widget-page.create-widget.step-one.replace-tags.initialize-tags-header',
          showCloseBtn: true,
        })
        .then((confirm) => {
          if (confirm) {
            replaceAssetTypes(option);
          }
        });
      return;
    }
    replaceAssetTypes(option);
  }

  return (
    <div className={styles.multiSelect}>
      <MultiSelect
        id="assetTypesReplaceTags"
        values={
          isTarget
            ? replacements.assetTypes.replaceWith === 'all'
              ? [
                  {
                    name: i18nService.translate('general.all'),
                    value: 'SELECT_ALL',
                  },
                ]
              : replacements.assetTypes.replaceWith
            : replacements.assetTypes.source === 'all'
            ? [
                {
                  name: i18nService.translate('general.all'),
                  value: 'SELECT_ALL',
                },
              ]
            : assetTypes
        }
        options={assetTypesOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={(option: any) => onChange(option)}
        filterOption={filterOption}
        tableConfig={(option) => ({
          [i18nService.translate('create-widget-page.create-widget.step-one.name')]: option.name,
        })}
        optionType="checkbox"
        isSelectAllAllowed={isSelectAllAllowed}
        onSelectAll={isSelectAllAllowed && onSelectAll}
        isOptionDisabled={isOptionDisabled}
        isDisabled={isDisabled}
        errorStyle={isTarget && !replacements?.assetTypes?.replaceWith?.length}
        placeholder={i18nService.translate('general.select-asset-types')}
      />
    </div>
  );
}

export default AssetTypesInput;
