import React, { useCallback, useEffect, useState, useMemo } from 'react';

import GooglePlacesInput from '@components/GooglePlacesInput';
import MultiSelect from '@components/MultiSelect';
import DatePicker from '@components/DatePicker';
import styles from './DashboardFilterInput.scss';
import { GetOwnersRes, GetAssetTypeOptionsRes } from '@core/http/server.interface';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { usePrevious } from '@core/hooks/usePrevious';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { useSelector } from '@src/redux/useSelector';
import {
  updatePossibleDateRange,
  getDdateFilterText,
  getDdateFilterRange,
} from './DashboardFilterInput.utils';
import AssetFilterComponent from '@components/AssetFilterComponent/AssetFilterComponent';
import { ShowClearButton } from '@components/Select/Select';
import { displayText } from './FilterInput.utils';
import { dynamicSort } from '@core/utils';

const getOptionLabel = (option) => option.name;
const getOptionValue = (option) => option.id;

function OrganizationFilter({ dashboardFilters, setDashboardFilters }) {
  const organizations = dashboardFilters.organizationsFilter;
  const [organizationOptions, setOrganizationOptions] = useState([]);

  useEffect(() => {
    if (organizations.length) setOrganizationOptions(organizations);
    else {
      httpService
        .api<GetOwnersRes>({
          type: 'getOwners',
          disableBI: true,
        })
        .then((res) => {
          setOrganizationOptions(res);
        });
    }
  }, []);

  const selectedChanged = useCallback((filters) => {
    setDashboardFilters((prevState) => ({
      ...prevState,
      organizationsFilter: filters,
    }));
  }, []);

  return (
    <MultiSelect
      id="userFiltersOrganizationOptions"
      styles={{
        menuPortal: { minWidth: '200px' },
      }}
      options={organizationOptions.sort(dynamicSort('name', '+'))}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder="general.all"
      onChange={selectedChanged}
      values={organizations}
      optionType="checkbox"
    />
  );
}

function AssetTypeFilter({ dashboardFilters, setDashboardFilters }) {
  const assetTypes = dashboardFilters.assetTypesFilter;
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);

  useEffect(() => {
    if (assetTypes.length) setAssetTypeOptions(assetTypes);
    // Get all options
    else {
      httpService
        .api<GetAssetTypeOptionsRes>({
          type: 'getAssetTypeOptions',
          disableBI: true,
        })
        .then((res) => {
          setAssetTypeOptions(res.assetTypes);
        });
    }
  }, []);

  const selectedChanged = useCallback((filters) => {
    setDashboardFilters((prevState) => ({
      ...prevState,
      assetTypesFilter: filters,
    }));
  }, []);

  return (
    <MultiSelect
      id="assetTypeOptions"
      styles={{
        menuPortal: { minWidth: '200px' },
      }}
      options={assetTypeOptions.sort(dynamicSort('name', '+'))}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder="general.all"
      onChange={selectedChanged}
      values={assetTypes}
      optionType="checkbox"
    />
  );
}

function AssetFilter(props) {
  const {
    dashboardFilters,
    setDashboardFilters,
    isAssetIllegalRange,
    navigationAssetFilter,
    setNavigationAssetFilter,
    widgetFilters,
  } = props;
  const [assetSelected, setAssetSelected] = useState(
    dashboardFilters.assetFilter?.length ? dashboardFilters.assetFilter : navigationAssetFilter
  );

  useEffect(() => {
    setAssetSelected(dashboardFilters.assetFilter);
  }, [dashboardFilters.assetFilter]);

  const selectedChanged = useCallback((filters) => {
    setDashboardFilters((prevState) => ({
      ...prevState,
      assetFilter: filters ? [filters] : [],
    }));
    setAssetSelected(filters);
  }, []);

  return (
    <AssetFilterComponent
      styles={{
        height: '58px',
      }}
      assetTypeSelected={dashboardFilters.assetTypesFilter}
      assetSelected={isAssetIllegalRange ? [] : assetSelected}
      isAssetIllegalRange={isAssetIllegalRange}
      navigationAssetFilter={navigationAssetFilter.length ? navigationAssetFilter : []}
      setNavigationAssetFilter={setNavigationAssetFilter}
      onChange={selectedChanged}
      setAssetSelected={setAssetSelected}
      showClearButton={
        assetSelected?.length || navigationAssetFilter?.length
          ? ShowClearButton.true
          : ShowClearButton.false
      }
      assetFilter={widgetFilters.find((filter) => filter.valueType === 'ASSET_PROPERTY')}
    />
  );
}

function GeoFilter({ dashboardFilters, setDashboardFilters }) {
  const geography = dashboardFilters.geoFilter;

  const selectedChanged = useCallback((filters) => {
    setDashboardFilters((prevState) => ({
      ...prevState,
      geoFilter: filters,
    }));
  }, []);

  return (
    <GooglePlacesInput
      id="LiveDashboardGeoFilter"
      styles={{
        menuPortal: { minWidth: '200px' },
      }}
      defaultValue={geography}
      placeholder="general.all"
      onSelectedCountryChange={selectedChanged}
      allowSelectingUnknownAddress
    />
  );
}

function DateFilter({ dashboardFilters, setDashboardFilters, filter, dashboardId }) {
  const dateFilter = dashboardFilters.dateFilter;
  const dateFilterFrom = dashboardFilters.dateFilterFrom;
  const dateFilterTo = dashboardFilters.dateFilterTo;
  const [date, setDate] = useState(null);
  const languageId = useSelector((state) => state.config.languageId);
  const dateLabelMap = useMemo(
    () => ({
      DAY: i18nService.translate('date-picker.last-day'),
      WEEK: i18nService.translate('date-picker.last-week'),
      MONTH: i18nService.translate('date-picker.last-month'),
      YEAR: i18nService.translate('date-picker.last-year'),
    }),
    [languageId]
  );

  const prevDashboardId = usePrevious(dashboardId);
  const prevDateFilter = usePrevious(dashboardFilters.dateFilter);

  const selectedDateRange = useMemo(
    () => ({
      dateFilter,
      dateFilterFrom,
      dateFilterTo,
    }),
    [dateFilter, dateFilterFrom, dateFilterTo]
  );

  useEffect(() => {
    /** Should update the possible date range in case the live dashboard page is
     ** first mounted or when a navigation to another dashboard occured
     **/
    updatePossibleDateRange(
      dateFilter,
      setDate,
      dateFilterFrom,
      dateFilterTo,
      dashboardId,
      prevDashboardId,
      date,
      prevDateFilter
    );
  }, [dateFilter, prevDashboardId, dashboardId]);
  // }, [dateFilter, prevDashboardId, dashboardId, date]);

  const selectedChanged = useCallback((value) => {
    setDashboardFilters((prevState) => ({
      ...prevState,
      dateFilter: value.dateFilter,
      dateFilterFrom: value.dateFilterFrom,
      dateFilterTo: value.dateFilterTo,
    }));
  }, []);

  return getDdateFilterRange(selectedDateRange) ? (
    <EditDisabledInput text={i18nService.translate('general.illegal-range', languageId)} />
  ) : date && filter.editable ? (
    <DatePicker
      data={selectedDateRange}
      selectedChanged={selectedChanged}
      maxDate={date ? date.dateFilterTo : null}
      minDate={date ? date.dateFilterFrom : null}
    />
  ) : (
    <EditDisabledInput
      text={getDdateFilterText(dateFilter, dateLabelMap, dateFilterFrom, dateFilterTo)}
    />
  );
}

function EditDisabledInput({ text }) {
  return (
    <div title={text} className={styles.editDisabled}>
      <span className="ellipsis-overflow" style={{ opacity: 0.4 }}>
        {text}
      </span>
    </div>
  );
}

function FilterInput(props) {
  const { filter, dashboardFilters, widgetFilters = [], navigationAssetFilter } = props;
  const { organizationsFilter, assetTypesFilter, geoFilter, assetFilter } = dashboardFilters;
  const languageId = useSelector((state) => state.config.languageId);
  const isAssetIllegalRange = !!(
    dashboardFilters.assetFilter.length &&
    widgetFilters?.length &&
    dashboardFilters.assetFilter[0].id !=
      widgetFilters.find((filter) => filter.valueType === 'ASSET_PROPERTY').values[0]
  );

  switch (filter.filterType) {
    case 'ORGANIZATIONS':
      return getPermissionStatus('ORGANIZATION', 'VIEW') &&
        filter.editable &&
        organizationsFilter ? (
        <OrganizationFilter {...props} />
      ) : (
        <EditDisabledInput text={displayText(organizationsFilter, filter, languageId, 'name')} />
      );
    case 'ASSET_TYPES':
      return getPermissionStatus('DEVICE', 'VIEW') && filter.editable && assetTypesFilter ? (
        <AssetTypeFilter {...props} />
      ) : (
        <EditDisabledInput text={displayText(assetTypesFilter, filter, languageId, 'name')} />
      );
    case 'GEO':
      return filter.editable && geoFilter ? (
        <GeoFilter {...props} />
      ) : (
        <EditDisabledInput text={displayText(geoFilter, filter, languageId, 'formatted')} />
      );
    case 'DATE':
      return <DateFilter {...props} />;
    case 'ASSET':
      return getPermissionStatus('DEVICE', 'VIEW') && filter.editable && assetFilter ? (
        <AssetFilter {...props} isAssetIllegalRange={isAssetIllegalRange} />
      ) : (
        <EditDisabledInput
          text={displayText(
            assetFilter?.length
              ? assetFilter
              : navigationAssetFilter?.length
              ? navigationAssetFilter
              : [],
            filter,
            languageId,
            'name',
            'id',
            isAssetIllegalRange
          )}
        />
      );
  }
}

export default React.memo(FilterInput);
