import React from 'react';
import classNames from 'classnames';

import styles from '../WidgetSideBar.scss';
import I18n from '@components/I18n';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { capitalizeFirstLetterOfEveryWord } from '@core/utils';

const StepOneText = ({ displayData }) => {
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <>
      {displayData.assetTypes.length < 1 && !displayData.allAssetTypes ? (
        <div
          className={styles.textBox}
          title={i18nService.translate(
            !displayData.eventTemplateId
              ? 'widget-side-bar.step-one.default-text'
              : 'event-data-source-side-bar.step-one.default-text',
            languageId
          )}>
          <I18n noEllipsis>
            {!displayData.eventTemplateId
              ? 'widget-side-bar.step-one.default-text'
              : 'event-data-source-side-bar.step-one.default-text'}
          </I18n>
        </div>
      ) : displayData.allAssetTypes ? (
        <div
          className={classNames('ellipsis-overflow', styles.activeText)}
          title={i18nService.translate('general.all', languageId)}>
          {`${i18nService.translate(
            'widget-side-bar.step-one.asset-types',
            languageId
          )}: ${i18nService.translate('general.all', languageId)}`}
        </div>
      ) : (
        <div
          className={classNames('ellipsis-overflow', styles.activeText)}
          title={displayData.assetTypes.join(', ')}>{`${i18nService.translate(
          'widget-side-bar.step-one.asset-types',
          languageId
        )} (${displayData.assetTypes.length}): ${displayData.assetTypes.join(', ')}`}</div>
      )}
      {displayData.assetProperties?.length ||
      displayData.tags?.length ||
      displayData.tagTypes?.length ||
      displayData.variables?.length ? (
        <div>
          <span className={styles.stepOneTitle}>
            <I18n>
              {!displayData.eventTemplateId
                ? 'widget-side-bar.step-one.step-one-data-header'
                : 'event-data-source-side-bar.step-one.step-one-data-header'}
            </I18n>
          </span>
          <div className={classNames(styles.stepOneDataText, styles.activeText)}>
            {displayData.variables.length > 0 && (
              <div
                className="ellipsis-overflow"
                title={displayData.variables.join(', ')}>{`${capitalizeFirstLetterOfEveryWord(
                i18nService.translate('variables.caption', languageId)
              )} (${displayData.variables.length}): ${displayData.variables.join(', ')}`}</div>
            )}
            {displayData.assetProperties.length > 0 && (
              <div
                className="ellipsis-overflow"
                title={displayData.assetProperties.join(', ')}>{`${i18nService.translate(
                'widget-side-bar.step-one.asset-properties',
                languageId
              )} (${displayData.assetProperties.length}): ${displayData.assetProperties.join(
                ', '
              )}`}</div>
            )}
            {displayData.tags.length > 0 && (
              <div
                className="ellipsis-overflow"
                title={displayData.tags.join(', ')}>{`${i18nService.translate(
                'widget-side-bar.step-one.tag-data',
                languageId
              )} (${displayData.tags.length}): ${displayData.tags.join(', ')}`}</div>
            )}
            {displayData.tagTypes.length > 0 && (
              <div
                className="ellipsis-overflow"
                title={displayData.tagTypes.join(', ')}>{`${i18nService.translate(
                'widget-side-bar.step-one.tag-types',
                languageId
              )} (${displayData.tagTypes.length}): ${displayData.tagTypes.join(', ')}`}</div>
            )}
            {displayData.alarmInfos.length > 0 && (
              <div
                className="ellipsis-overflow"
                title={displayData.alarmInfos.join(', ')}>{`${i18nService.translate(
                'widget-side-bar.step-one.alarm-infos',
                languageId
              )} (${displayData.alarmInfos.length}): ${displayData.alarmInfos.join(', ')}`}</div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StepOneText;
