export const sharedDragAndDropRules = {
  allowedScopesByVariableType: new Map([
    ['numberType', ['LAST_VALUE', 'RAW_DATA', 'AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE']],
    ['stringType', ['LAST_VALUE', 'RAW_DATA']],
    ['booleanType', ['LAST_VALUE', 'RAW_DATA']],
    ['dateTimeType', ['LAST_VALUE', 'RAW_DATA']],
  ]),
};

export function getSharedRule(ruleType: string, key: string) {
  return { [ruleType]: sharedDragAndDropRules[ruleType].get(key) };
}
