import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import { changeWidgetData, chartTypeOptions } from '../Heatmap.utils';

function ChartType({ classes, chartType, setWidgetData }) {
  return (
    <div className={classes.rowSection}>
      <I18n className={classNames(classes.titleWrapper, 'ellipsis-overflow')}>
        create-widget-page.create-widget.step-four.chart-type
      </I18n>
      <Select
        styles={classes.selectFullWidthCustomStyle}
        disabled={false}
        options={chartTypeOptions}
        value={chartTypeOptions.find((opt) => opt.value === chartType)}
        onChange={(option) => changeWidgetData(option.value, 'chartType', setWidgetData)}
        unSelect={true}
      />
    </div>
  );
}

export default ChartType;
