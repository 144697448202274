import React, { useState, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import StarBorder from '@material-ui/icons/StarBorder';
import Link from '@material-ui/icons/Link';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { canvasService } from '@core/canvas/CanvasService';
import { useSelector } from '@redux/useSelector';
import { CanvasWidget } from '@redux/redux.interface.d';
import { CanvasWidgetActionBarProps } from './CanvasWidgetActionBar.interface.d';
import styles from './CanvasWidgetActionBar.scss';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { dispatch } from '@src/redux/store';
import { setClipboardWidget } from '@src/redux/dashboardGallery';
import { setSelectedWidgetDimenssions } from '@redux/dashboardEditor';
import Icon from '@components/Icon';
import { storageService } from '@core/StorageService';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import { serverToLocal } from '@pages/CreateWidgetPage/widgets.utils';
import { convertImageToBase64 } from '@core/utils';

function CanvasWidgetActionBar(props: CanvasWidgetActionBarProps) {
  const {
    widget = {} as CanvasWidget,
    className,
    hideActions,
    widgetRef,
    isDraft,
    isMouseOverWidget,
  } = props;
  const history = useHistory();
  const [showButtons, setShowButtons] = useState(false);
  const [widgetDimenssions, setWidgetDimenssions] = useState({
    clientHeight: undefined,
    clientWidth: undefined,
  });
  const { hideWidgetName } = widget;
  const { id: dashboardId, numSelected } = useSelector((state) => state.dashboardEditor);
  const canGroup = widget.isSelected && numSelected > 1;
  const languageId = useSelector((state) => state.config.languageId);

  const iconsStyle = useMemo(
    () => ({
      all: {
        width: widget?.w > 28 ? 24 : 18,
        height: widget?.w > 28 ? 28 : 22,
        color: 'var(--widgetsHeaderFont)',
      },
      copy: { marginTop: widget?.w > 28 ? 0 : -10, marginRight: -5 }, //widget?.w > 28 ? -5 : -5 },
    }),
    [widget]
  );

  useEffect(() => {
    setWidgetDimenssions({
      clientHeight: widgetRef?.current?.clientHeight,
      clientWidth: widgetRef?.current?.clientWidth,
    });
  }, [widgetRef?.current?.clientHeight, widgetRef?.current?.clientWidth]);

  const group = useCallback(() => {
    canvasService.createGroup();
  }, []);

  // const addToFavorites = useCallback(() => console.log('not implemented'), []);
  const updateImageAndCopy = (widgetData, image?) => {
    let customization = widgetData?.customization;
    image && (customization = { ...customization, image });

    dispatch(
      setClipboardWidget({
        ...widget,
        ...widgetData,
        id: undefined,
        copyId: widget.id,
        dashboardId: undefined,
        customization,
      })
    );
  };

  const copyToClipboard = async () => {
    const data: any = await httpService.api({
      type: 'getWidget',
      urlParams: { widgetId: widget.id },
    });
    if (data) {
      const widgetData = serverToLocal(data) as any;
      updateImageAndCopy(widgetData);
    }
  };

  const editWidget = useCallback(
    () => history.push(`/main/edit-dashboard/${dashboardId}/edit-widget/${widget.id}`),
    [widget]
  );

  const deleteWidget = useCallback(() => {
    canvasService.deleteWidget(widget);
  }, [widget]);

  // We stop propagation so we don't initiate selectAndStartDrag from CanvasWidgetWrapper.
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const onMouseEnter = useCallback(() => setShowButtons(true), []);
  const onMouseLeave = useCallback(() => setShowButtons(false), []);

  const hasPermission = useMemo(() => getPermissionStatus('DASHBOARD', 'EDIT'), []);

  return (
    <div
      className={classNames(
        styles.wrapper,
        widget.w <= 20 ? styles.noPadding : '',
        className,
        isDraft ? styles.draftWrapper : '',
        hideWidgetName ? styles.headerHidden : '',
        isMouseOverWidget && hideWidgetName ? styles.headerVisible : ''
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {/* {(!widget.customization || !widget.customization.hideWidgetName) && (   */}
      {widget.w > 20 && (
        <div style={{ width: '100%' }} className={styles.title}>
          {widget.name}
        </div>
      )}
      {/* )} */}
      {!hideActions && (
        <div
          className={classNames(styles.buttons)}
          style={widget.w <= 15 ? { alignItems: 'left' } : null}>
          {/* <div style={{ width: '100%' }} onClick={(e) => onClick(e)}></div> */}
          {/* {canGroup && (
            <IconButton className={styles.button} onMouseDown={stopPropagation} onClick={group}>
              <Link />
            </IconButton>
          )} */}
          {/* <IconButton
            className={styles.button}
            onMouseDown={stopPropagation}
            onClick={addToFavorites}>
            <StarBorder />
          </IconButton> */}
          <IconButton
            className={styles.button}
            style={widget.w <= 15 ? { paddingRight: '9px' } : null}
            disabled={!hasPermission}
            onMouseDown={stopPropagation}
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard();
            }}>
            <Icon
              tooltipText="dashboard-editor.widget-copy"
              type="copyWidget"
              color="var(--widgetsHeaderFont)"
              style={{ ...iconsStyle.all, ...iconsStyle.copy }}
              width={`${iconsStyle.all.width}`}
              height={`${iconsStyle.all.height}`}
            />
          </IconButton>
          <div title={i18nService.translate('dashboard-editor.widget-edit', languageId)}>
            <IconButton
              className={styles.button}
              style={widget.w <= 15 ? { paddingLeft: '9px', paddingRight: '9px' } : null}
              onMouseDown={stopPropagation}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setSelectedWidgetDimenssions({ selectedWidgetDimenssions: widgetDimenssions })
                );
                storageService.set('selectedWidgetDimenssions', widgetDimenssions);
                editWidget();
              }}
              disabled={!hasPermission}>
              <Edit style={iconsStyle.all} />
            </IconButton>
          </div>
          <div title={i18nService.translate('dashboard-editor.widget-delete', languageId)}>
            <IconButton
              className={styles.button}
              style={widget.w <= 15 ? { paddingLeft: '9px', marginRight: '20px' } : null}
              onMouseDown={stopPropagation}
              onClick={(e) => {
                e.stopPropagation();
                deleteWidget();
              }}>
              <Delete style={iconsStyle.all} />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(CanvasWidgetActionBar);
