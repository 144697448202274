import React, { useState } from 'react';
import MaterialCheckbox from '@components/Checkbox/Checkbox';
import { useEffect } from 'react';

function AssignSubscriptionCheckbox(props) {
  const { handleChecked, selectedAssetsIds, isCheckboxDisabled, assetId } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const isChecked = selectedAssetsIds?.some((id) => id === assetId);
    setIsChecked(isChecked);
    setIsDisabled(isCheckboxDisabled(isChecked));
  }, [selectedAssetsIds, assetId]);

  return (
    <div>
      <MaterialCheckbox
        checked={isChecked}
        onChange={(e) => handleChecked(e.target.checked, assetId)}
        disabled={isDisabled}
      />
    </div>
  );
}

export default AssignSubscriptionCheckbox;
