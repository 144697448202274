import React from 'react';

/* 
  We use useIsMounted when we want to get an update when the component has unMounted
  This is equivalent to the useEffet => unMount functionality   
*/
export function useIsMounted() {
  // isMounted.current will be true on component mount, and false when unmounted
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
