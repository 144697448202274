import React, { useState, useRef, useMemo, useEffect } from 'react';
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api';
import Marker from './Marker';
import { withHandlers } from 'recompose';
import { usePrevious } from '@core/hooks/usePrevious';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import {
  getMarkerDataFromServer,
  getDataFromServer,
  navigateFromCluster,
  onNavigateToDashboard,
  onIdle,
  onClusterHover,
} from './MapWidget.utils';
import { MapWidgetProps } from './MapWidget.interface';
import { makeStyles } from '@material-ui/styles';
import { widgetMap } from '@core/canvas/widgetMap';
import Image from '@components/Image';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import ClasterInfo from './ClasterInfo';
import { useGetWidgetData } from '@core/hooks/useGetWidgetData';
import { debounce } from 'lodash';
import { isMobile, isTablet } from 'react-device-detect';

const clusterStyles = [
  {
    url: '/assets/images/cluster_blue.svg',
    height: 32,
    width: 32,
    textColor: 'var(--white1)',
  },
  {
    url: '/assets/images/cluster_blue.svg',
    height: 32,
    width: 32,
    textColor: 'var(--white1)',
  },
  {
    url: '/assets/images/cluster_blue.svg',
    height: 32,
    width: 32,
    textColor: 'var(--white1)',
  },
];

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    padding: 20,
  },
  clusterInfo: {
    width: '200px',
    top: 'calc(50% - 85px)',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    border: '1px solid black',
    background: 'white',
    borderRadius: '5px',
  },
}));

function MapWidget(props: MapWidgetProps) {
  const {
    setIsLoadLocations, // property from enhance HOC - plane a
    getIsLoadLocations, // property from enhance HOC - plane a
    widgetData, // property from  preview
    widget, // property from liveDashboard/editDashboard
    widgetFilters, // property from liveDashboard
    navigateDashboard, // property from livedashboard (navigate func)
    dashboardFilters, // property from livedashboard
    userFilters, // property from livedashboard (indicator when to update data)
    defaultDecimalDigits,
  } = props;
  const widgetId = widget && widget.id;
  const [locations, setLocations] = useState([]);
  const [zoom, setZoom] = useState(0);
  const [center, setCenter] = useState({ lat: -34.397, lng: 150.644 });
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [disableZoom, setDisableZoom] = useState(false);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [clusterLocations, setClusterLocations] = useState([]);
  const [refreshZoom, setRefreshZoom] = useState(false);
  const [lastZoom, setLastZoom] = useState(0);
  const minZoom = useMemo(
    () =>
      widget
        ? widget.w / widget.h >= 1.5 || widget.h / widget.w >= 1.5
          ? 2
          : widget.w / widget.h >= 1.22 || widget.h / widget.w >= 1.22
          ? 1
          : 0
        : 0,
    [widget]
  );

  /* 
  return map that includes lat-lng key and array of locations that we getting from server
  {
    '2324-343'; [location, location]
  }
  */
  const locationsMap = useMemo(
    () =>
      (locations || []).reduce((currentData, location) => {
        const key = `${location.lat}-${location.lon}`;
        return {
          ...currentData,
          [key]: currentData[key] ? [...currentData[key], location] : [location],
        };
      }, {}),
    [locations]
  );
  const map = useRef(null);
  const classes = useStyles(props);

  // listening to changes on widgetData/navigationFilters
  const prevWidgetData: WidgetData = usePrevious(widgetData);

  useGetWidgetData({
    getWidgetData: () =>
      getDataFromServer(
        map,
        locations,
        setIsUnauthorized,
        setLocations,
        setRefreshZoom,
        setIsLoadLocations,
        isUnauthorized,
        widgetId,
        widgetData,
        widgetFilters,
        dashboardFilters
      ),
    dataChangesToHandle: ['filters'],
    widgetData,
    prevWidgetData,
    userFilters,
  });

  const getMarkerData = (geojson) =>
    getMarkerDataFromServer({ widgetId, widgetData, zoom, geojson, widgetFilters });

  const zoomState = map.current?.getZoom();
  useEffect(() => {
    if (map.current && !map.current?.getZoom() && map.current?.getZoom() !== 0) {
      map.current?.setZoom(11);
    }
    setRefreshZoom(false);
  }, [zoomState]);

  useEffect(() => {
    if (map.current && lastZoom) {
      map.current?.setZoom(lastZoom);
    }
    setRefreshZoom(false);
  }, [refreshZoom]);

  const isZoom = zoom ? { zoom } : {};
  return locations.length ? (
    <div className={classes.wrapper}>
      <GoogleMap
        {...isZoom}
        mapContainerStyle={{ height: '100%' }}
        options={{
          fullscreenControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          panControl: false,
          mapTypeControl: false,
          minZoom,
          zoomControl: !!navigateDashboard,
          gestureHandling: navigateDashboard ? 'auto' : 'none',
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        onLoad={(m) => {
          map.current = m;
        }}
        center={center}
        onIdle={() =>
          onIdle(map, getIsLoadLocations, setZoom, setIsLoadLocations, setLastZoom, locations)
        }
        onZoomChanged={debounce(() => {
          disableZoom && setDisableZoom(false);
        }, 500)}>
        {locations && (
          <MarkerClusterer
            styles={clusterStyles}
            onMouseOver={(cluster) => onClusterHover(cluster, setDisableZoom)}
            onClick={(cluster) => {
              navigateFromCluster(
                cluster,
                map,
                locationsMap,
                widget,
                navigateDashboard,
                navigationDataBuilder,
                disableZoom,
                setIsInfoWindowOpen,
                setClusterLocations,
                isMobile,
                isTablet
              );
            }}>
            {(clusterer) => (
              <>
                {locations.map((location, index) => (
                  <Marker
                    getMarkerData={getMarkerData}
                    onNavigateToDashboard={(assetId) =>
                      navigateDashboard &&
                      onNavigateToDashboard(
                        assetId,
                        widget,
                        navigateDashboard,
                        navigationDataBuilder
                      )
                    }
                    key={index}
                    location={location}
                    clusterer={clusterer}
                    customization={widgetData?.customization || widget?.customization}
                    defaultDecimalDigits={defaultDecimalDigits}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
        )}
      </GoogleMap>
      {isInfoWindowOpen && (
        <ClasterInfo
          setIsInfoWindowOpen={setIsInfoWindowOpen}
          clusterLocations={clusterLocations}
          widget={widget ? widget : widgetData}
          customization={widgetData?.customization || widget?.customization}
          navigateDashboard={navigateDashboard}
          navigationDataBuilder={navigationDataBuilder}
        />
      )}
    </div>
  ) : (
    <Image mode="smaller" src={widgetMap.map.images.canvas} />
  );
}

const enhance = withHandlers(() => {
  let isLoad = false;
  return {
    setIsLoadLocations: () => (load) => {
      isLoad = load;
    },
    getIsLoadLocations: () => () => isLoad,
  };
});

export default enhance(MapWidget);
