import React, { useEffect, useMemo } from 'react';

// services & Interfaces
import { MapCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import styles from './Map.scss';
import I18n from '@components/I18n';

// 3rd party
import * as cloneDeep from 'lodash/cloneDeep';
import { TextField } from '@material-ui/core';
import {
  getConfigValueFromWidgetSettings,
  updateWidgetCustomization,
} from '@core/canvas/widget.utils';
import { compact } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';
import classNames from 'classnames';
import Select from '@components/Select';
import { getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';

const selectCustomStyle = { control: { height: 40 }, container: { width: '100%' } };

const Map = (props) => {
  const { widgetData, setWidgetData } = props;
  const { customization, tagTypes, tags, assetProperties, variables, type } = widgetData;
  const { tagCaption, dateTimeFormat } = (customization as MapCustomization) || {};
  const defaultAssetProperties = getConfigValueFromWidgetSettings(type, 'defaultAssetProperties');

  const getExtraAssetProperty = () =>
    compact(
      assetProperties.map((asset) => {
        if (!defaultAssetProperties.includes(asset.id)) return asset;
      })
    );

  const extraAssetProperty = getExtraAssetProperty()[0];

  const tagName = tags?.length
    ? tags[0].name
    : tagTypes?.length
    ? tagTypes[0].name
    : variables?.length
    ? variables[0].name
    : extraAssetProperty
    ? extraAssetProperty?.name
    : null;

  const extraTag = useMemo(
    () => tagTypes[0] || tags[0] || variables[0] || extraAssetProperty,
    [tagTypes, tags, variables, assetProperties]
  );
  const format = extraTag?.format || extraTag?.type || extraTag?.valueType;
  const dateTimeOptions = useMemo(() => getDateTimeOptions(format), [format]);
  useEffect(() => {
    const tempDateTimeFormat =
      format === 'DATE' ? 'DATE' : format === 'DATETIME' ? 'DATE_TIME_FORMAT_ONE' : null;
    if (!extraTag || !customization) {
      updateWidgetCustomization(
        {
          tagCaption: extraTag && i18nService.translate(extraTag?.name),
          dateTimeFormat: tempDateTimeFormat,
        },
        setWidgetData
      );
    } else if (tagName && !dateTimeFormat && format) {
      updateWidgetCustomization(
        {
          dateTimeFormat: tempDateTimeFormat,
        },
        setWidgetData
      );
    }
  }, []);

  const setCustomizationData = (data, key) => {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  return tagName ? (
    <div className={styles.widgetValue}>
      <div className={styles.row}>
        <div className={classNames(styles.label, 'ellipsis-overflow')}>
          <I18n className={styles.subHeader}>
            create-widget-page.create-widget.step-four.tag-name
          </I18n>
        </div>
        <I18n className={classNames(styles.tagName, 'ellipsis-overflow')}>{tagName}</I18n>
      </div>
      <div className={styles.row}>
        <div className={classNames(styles.label, 'ellipsis-overflow')}>
          <I18n className={styles.subHeader}>
            create-widget-page.create-widget.step-four.tag-caption
          </I18n>
        </div>
        <TextField
          value={tagCaption}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => setCustomizationData(e.target.value, 'tagCaption')}
          inputProps={{
            maxLength: 50,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      {dateTimeOptions && (
        <div className={styles.row}>
          <div className={classNames(styles.label)}>
            <I18n className={styles.subHeader} noEllipsis>
              create-widget-page.create-widget.step-four.date-time
            </I18n>
          </div>
          <Select
            styles={selectCustomStyle}
            options={dateTimeOptions}
            value={dateTimeFormat && dateTimeOptions.find((opt) => opt.value === dateTimeFormat)}
            onChange={(option) => setCustomizationData(option.value, 'dateTimeFormat')}
          />
        </div>
      )}
    </div>
  ) : null;
};

export default Map;
