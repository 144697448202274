import Button from '@components/Button';
import DataViewer from '@components/DataViewer';
import I18n from '@components/I18n';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { apiMap } from '@core/http/apiMap';
import { httpService } from '@core/http/HttpService';
import { getState } from '@src/redux/store';
import { template } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './AssignSubscriptionToAssetModal.scss';
import classNames from 'classnames';
import AssetsTable from './AssetsTable/AssetsTable';
import SearchInput from '@pages/DeviceManagment/Assets/SearchInput';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import moment from 'moment';
import Icon from '@components/Icon';

function AssignSubscriptionToAssetModal(props) {
  const [searchText, setSearchText] = useState('');
  const { args, dismiss } = props || {};
  const { subscriptionInfo } = args || {};
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);
  const languageId = useSelector((state) => state.config.languageId);
  const [totalCount, setTotalCount] = useState(0);
  const [resultsCount, setResultsCount] = useState(0);

  const search = (searchText) => {
    setSearchText(searchText);
  };

  const getLastSearches = () => {
    const organizationId = getState().login.userOrganizationId;
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getAssets'].url)({ organizationId }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches);
      });
  };

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const convertStatusToReadbleString = (value): string => {
    let status = value;

    if (status == 'AVAILABLE') status = 'UNASSIGNED';
    return i18nService.translate(`enum.subscription.plc.${status}`, languageId);
  };

  const firstDataViewerData = useMemo(
    () =>
      subscriptionInfo && [
        {
          label: 'assign-subscription-to-asset-modal.subscription-name',
          value: subscriptionInfo.name,
        },
        {
          label: 'assign-subscription-to-asset-modal.subscription-key',
          value: subscriptionInfo.subscriptionKey,
        },
      ],
    [subscriptionInfo]
  );

  const secondDataViewerData = useMemo(
    () =>
      firstDataViewerData && [
        {
          label: 'assign-subscription-to-asset-modal.description',
          value: subscriptionInfo.description,
        },
        {
          label: 'assign-subscription-to-asset-modal.end-date',
          value:
            subscriptionInfo.endDate &&
            moment(subscriptionInfo.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        },
      ],
    [subscriptionInfo]
  );

  const thirdDataViewerData = useMemo(
    () =>
      firstDataViewerData && [
        {
          label: 'assign-subscription-to-asset-modal.catalog-num',
          value: subscriptionInfo.catalogNum,
        },
        {
          label: 'assign-subscription-to-asset-modal.status',
          value: convertStatusToReadbleString(subscriptionInfo.status),
        },
      ],
    [subscriptionInfo]
  );

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'assets',
    },
  };

  const setSearchResults = (total, results) => {
    setTotalCount(total);
    setResultsCount(results);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>assign-subscription-to-asset-modal.title</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        {subscriptionInfo && (
          <>
            <div className={styles.dataSection}>
              <DataViewer
                className={styles.dataViewer}
                data={firstDataViewerData}
                boldLabel
                marginBottom
                labelStyle={{ width: '140px' }}
              />
              <DataViewer
                data={secondDataViewerData}
                className={styles.dataViewer}
                boldLabel
                marginBottom
                smallLabel
                labelStyle={{ width: '140px' }}
              />
              <DataViewer
                data={thirdDataViewerData}
                boldLabel
                marginBottom
                smallLabel
                labelStyle={{ width: '140px' }}
              />
            </div>

            <div className={styles.search}>
              <SearchInput
                resultsCount={resultsCount}
                totalCount={totalCount}
                {...toolbarCfg.search}
              />
            </div>

            <div className={styles.content}>
              <AssetsTable
                setSearchResults={setSearchResults}
                searchText={searchText}
                licenseId={subscriptionInfo.licenseId}
                subscriptionId={subscriptionInfo.subscriptionId}
                subscriptionDescription={subscriptionInfo.description}
                dismiss={dismiss}
              />
            </div>
          </>
        )}
        <div className={styles.modalButtons}>
          <Button mode={['cancel', 'bigFont']} disabled={false} onClick={cancel}>
            <I18n>general.cancel</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AssignSubscriptionToAssetModal;
