import React from 'react';
import { template } from 'lodash';
import iconsMap from './iconsMap';
import { IconProps } from './Icon.interface.d';
import classNames from 'classnames';
import styles from './Icon.scss';
import { cssVarsService } from '@core/CssVarsService';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';

function Icon(props: IconProps) {
  const {
    className,
    type,
    color,
    onClick,
    disabled,
    width,
    height,
    style = {},
    tooltipText,
  } = props;
  const languageId = useSelector((state) => state.config.languageId);

  const getIconSvg = () => {
    let icon;

    if (color) {
      icon = template(iconsMap[type])({ color });
    } else {
      icon = template(iconsMap[type])({ color: cssVarsService.vars.systemFontSelected });
    }

    if (width && height) {
      icon = `${icon.slice(0, 4)} style="width: ${width}; height: ${height};" ${icon.slice(4)}`;
    }

    return icon;
  };

  return (
    <div
      title={i18nService.translate(tooltipText, languageId)}
      dangerouslySetInnerHTML={{ __html: getIconSvg() }}
      className={classNames(
        className,
        disabled && styles.disabledIcon,
        !disabled && onClick && 'pointer'
      )}
      onClick={onClick}
      style={style}
    />
  );
}

export default Icon;
