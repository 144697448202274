import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { isEqual } from 'lodash';

import DashboardFilterInput from './DashboardFilterInput';
import I18n from '@components/I18n';
import Button from '@components/Button';
import { DashboardFilterProps } from './DashboardFilters.interface';

import classNames from 'classnames';
import * as styles from './DashboardFilters.scss';
import { useSelector } from '@src/redux/useSelector';
import { resetLiveDashboardWidgetsData } from '@src/redux/liveDashboardWidgetsData';
import { dispatch, getState } from '@src/redux/store';
import { AssetInfo } from '@modals/EditAssetModal/EditAssetModal.interface';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    borderRadius: theme.app.canvasWidgetBorderRadius,
    backgroundColor: theme.app.canvasTopSectionColor,
    padding: '5px 0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingRight: 10,
    transition: 'height .5s',
  },
}));

const filtersOrder = {
  DATE: 1,
  GEO: 2,
  ASSET_TYPES: 3,
  ORGANIZATIONS: 4,
  ASSET: 5,
};

function DashboardFilters(props: DashboardFilterProps) {
  const {
    filtersCfg,
    filters,
    widgetFilters,
    setFilters,
    applyFilters,
    dashboardId,
    setApplyButtonClicked,
    isPreview = false,
  } = props;
  const isMobileView = isPreview
    ? useSelector((state) => state.viewport.isMobileView) ||
      useSelector((state) => state.dashboardEditor.currentLayout === 'TABLET') ||
      useSelector((state) => state.dashboardEditor.currentLayout === 'MOBILE')
    : useSelector((state) => state.viewport.isMobileView);
  const [isOpenOnMobileMode, setIsOpenOnMobileMode] = useState(false);
  const [navigationAssetFilter, setNavigationAssetFilter] = useState([]);
  const [initialFiltersValue, setInitialFiltersValue] = useState(filters);

  const showApplyButton = useMemo(() => filtersCfg.some((f) => f.editable), [filtersCfg]);
  const filtersToRender = useMemo(() => {
    const visibleFilters = filtersCfg.filter((f) => f.visible);
    visibleFilters.sort((a, b) => filtersOrder[a.filterType] - filtersOrder[b.filterType]);
    return visibleFilters;
  }, [filtersCfg]);
  const classes = useStyles(props);

  const doMobileCheck = () => (isMobileView && isOpenOnMobileMode) || !isMobileView;

  useEffect(() => {
    let assetId = null;
    if (widgetFilters?.length) {
      assetId = widgetFilters.find((filter) => filter.valueType === 'ASSET_PROPERTY').values[0];
      httpService
        .api({
          type: 'getAssetInfo',
          urlParams: { assetId: assetId },
        })
        .then((assetInfo: AssetInfo) => {
          setNavigationAssetFilter([{ id: assetId, name: assetInfo.assetName }]);
        });
    }
  }, [dashboardId]);

  useEffect(() => {
    if (navigationAssetFilter?.length && !filters?.assetFilter?.length) {
      setInitialFiltersValue({ ...filters, assetFilter: navigationAssetFilter });
    }
  }, [navigationAssetFilter]);

  return (
    <div
      className={classNames(
        classes.wrapper,
        isMobileView && styles.mobileModeWrapper,
        isMobileView && !isOpenOnMobileMode && styles.mobileFiltersCloseWrapper,
        isMobileView && isOpenOnMobileMode && styles.mobileFiltersOpenWrapper
      )}>
      {isMobileView && !isOpenOnMobileMode && (
        <div className={styles.mobileFilterDrawBtn}>
          <Button
            styles={{ width: '100%', maxWidth: '100%' }}
            mode="bigFont"
            onClick={() => setIsOpenOnMobileMode(true)}>
            <I18n>general.filters</I18n>
          </Button>
        </div>
      )}
      {doMobileCheck() &&
        filtersToRender.map((filt) => (
          <DashboardFilterInput
            key={filt.filterType}
            filter={filt}
            dashboardFilters={filters}
            widgetFilters={widgetFilters}
            setDashboardFilters={setFilters}
            dashboardId={dashboardId}
            isMobileView={isMobileView}
            navigationAssetFilter={navigationAssetFilter}
            setNavigationAssetFilter={setNavigationAssetFilter}
          />
        ))}
      {doMobileCheck() && (
        <div>
          {isMobileView && (
            <Button
              styles={{ margin: '20px 0 20px 0' }}
              mode={['bigFont', 'cancel']}
              onClick={() => setIsOpenOnMobileMode(false)}>
              <I18n>general.hide</I18n>
            </Button>
          )}
          {showApplyButton && (
            <Button
              disabled={isEqual(filters, initialFiltersValue)}
              styles={Object.assign(
                { marginTop: 25, marginLeft: 'auto' },
                doMobileCheck() && { marginTop: '20px', marginBottom: '20px', marginLeft: '20px' }
              )}
              mode="bigFont"
              onClick={() => {
                setInitialFiltersValue({ ...filters });
                dispatch(resetLiveDashboardWidgetsData());
                applyFilters(filters);
                setApplyButtonClicked(true);
              }}>
              <I18n>filters-widget.apply-btn</I18n>
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardFilters;
