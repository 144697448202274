import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import styles from '../StepTwo.scss';

const RawDataOption = ({ onChangeTableType }) => {
  return (
    <div className={classNames(styles.rawDataText, 'ellipsis-overflow')}>
      <I18n>create-widget-page.create-widget.step-two.raw-data-text</I18n>
      <div className={styles.selectAnotherWidget}>
        <I18n element="div" style={{ maxWidth: '200px' }}>
          create-widget-page.create-widget.step-two.select-another
        </I18n>
        <div
          className={classNames(styles.popupConfirm, 'ellipsis-overflow')}
          onClick={onChangeTableType}>
          <I18n>create-widget-page.create-widget.step-two.another-chart</I18n>
        </div>
      </div>
    </div>
  );
};

export default RawDataOption;
