import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Icon from '@components/Icon';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { getAddLocalTagFieldCfg } from '../../Metrics.utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '34px',
    paddingTop: '3px',
    justifyContent: 'space-between',
  },
}));

function ActionButtons(props) {
  const { metricsTag, widgetData, setWidgetData, setMetricsSelected } = props;
  const classes = useStyles(props);
  const [nameValidation, setNameValidation] = useState({
    name: '',
    message: '',
    isValid: true,
  });
  const addLocalTagFieldsCfg = getAddLocalTagFieldCfg(widgetData, nameValidation);

  function deleteLocalTag() {
    modalService
      .openModal('confirm', {
        headerText: 'create-widget-page.create-widget.step-three.delete-local-tag',
        text: widgetData.customization
          ? 'create-widget-page.create-widget.step-three.delete-local-tag-customization.text'
          : 'create-widget-page.create-widget.step-three.delete-local-tag.text',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
        localTagName: metricsTag.name,
      })
      .then(async (confirm) => {
        if (confirm) {
          setWidgetData((prevState) => ({
            ...prevState,
            localTags: widgetData.localTags.filter(
              (localTag) => localTag.id !== metricsTag.valueId
            ),
            calculations: widgetData.calculations.filter(
              (calc) => !(calc.valueType === 'CALCULATION' && calc.valueId === metricsTag.valueId)
            ),
            metrics: widgetData.metrics.filter(
              (metric) =>
                !(metric.valueType === 'CALCULATION' && metric.valueId === metricsTag.valueId)
            ),
            customization: null,
            customizationMetrics: [],
          }));
        }
      });
  }

  function editLocalTag() {
    modalService.openModal('formikFormModal', {
      header: 'create-widget-page.create-widget.step-three.edit-local-tag',
      initialValues: metricsTag,
      onSubmit: (updatedLocalTag) => {
        setWidgetData((prevState) => ({
          ...prevState,
          localTags: widgetData.localTags.map((localTag) => {
            if (localTag.id === updatedLocalTag.valueId) {
              return { ...localTag, name: updatedLocalTag.name };
            }
            return localTag;
          }),
          metrics: widgetData.metrics.map((metric) => {
            if (
              metric.valueId === updatedLocalTag.valueId &&
              metric.valueType === updatedLocalTag.valueType
            ) {
              return { ...metric, name: updatedLocalTag.name };
            }
            return metric;
          }),
        }));
        setMetricsSelected((prevState) => {
          return prevState.map((metric) => {
            if (metric.valueType === 'CALCULATION' && metric.valueId === updatedLocalTag.valueId) {
              return updatedLocalTag;
            }
            return metric;
          });
        });
      },
      fieldsCfg: addLocalTagFieldsCfg,
    });
  }

  const buttons = [
    {
      type: 'edit',
      tooltip: 'general.edit',
      onClick: editLocalTag,
    },
    {
      type: 'delete',
      tooltip: 'general.delete',
      onClick: deleteLocalTag,
    },
  ];

  return (
    <div className={classes.wrapper}>
      {buttons.map((button, idx) => (
        <Icon
          key={idx}
          type={button.type}
          color="var(--systemFont)"
          tooltipText={i18nService.translate(button.tooltip)}
          disabled={false}
          onClick={button.onClick}
        />
      ))}
    </div>
  );
}

export default ActionButtons;
