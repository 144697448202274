import I18n from '@components/I18n';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EmailMessageEditor.scss';
import RichTextEditor from '../RichTextEditor';
import { modalService } from '@core/modals/ModalService';
import Icon from '@components/Icon';
import { cssVarsService } from '@core/CssVarsService';
import { getState } from '@src/redux/store';
import classNames from 'classnames';
import { AlarmsDataSources } from '../../EditTemplate.utils';
import { origin } from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/AddDataSource/AddDataSource';

const MAX_SUBJECT_LENGTH = 100;

function EmailMessageEditor(props) {
  const languageId = useSelector((state) => state.config.languageId);
  const { templateData, setTemplateData } = props;
  const { attachmentType } = templateData || { id: 1 };
  const [dataSources, setDataSources] = useState([]);
  const [canEmbed, setCanEmbed] = useState(false);
  const [canEmbedCheckFinished, setCanEmbedCheckFinished] = useState(false);

  const getDataSources = async (order) => {
    try {
      const res: any = await httpService.api({
        type: 'getEventTemplateDataSources',
        urlParams: { templateId: templateData.id },
        query: {
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      });

      if (templateData.trigger === 'ALARM') {
        setDataSources([...AlarmsDataSources, ...res]);
      } else {
        setDataSources(res);
      }

      if (
        res &&
        !attachmentType &&
        res?.some((d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2)
      )
        setTemplateData('ATTACHED', 'attachmentType');
    } catch (error) {
      console.log(error);
    }
  };

  const checkCanEmbed = async (templateId, dataSourceId) => {
    try {
      const res: any = await httpService.api({
        type: 'getEventTemplateDataSource',
        urlParams: { templateId: templateId, dataSourceId: dataSourceId },
      });
      setCanEmbed(res.customization.columns.length <= 10);
      setCanEmbedCheckFinished(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataSources(null);
  }, []);

  useEffect(() => {
    if (
      dataSources &&
      dataSources.some((d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2)
    ) {
      const tabularDataSource = dataSources.find(
        (d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2
      );
      checkCanEmbed(templateData?.id, tabularDataSource.id);
    }
  }, [dataSources]);

  const valueOnlyDataSources = useMemo(() => {
    if (dataSources) return dataSources.filter((d) => d.type === 'VALUE' && d.status === 2);

    return [];
  }, [dataSources]);

  const attachmentTypeOptions = useMemo(() => {
    if (
      dataSources &&
      dataSources.some((d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2)
    ) {
      const tabularDataSource = dataSources.find(
        (d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2
      );

      return canEmbed
        ? [
            {
              value: 'ATTACHED',
              label: `events-template.create-message.attach-data-source`,
              dataSourceName: tabularDataSource.name,
              dataSourceType: i18nService.translate(
                `events-template.create-data.source.type.${tabularDataSource.type?.toLowerCase()}`
              ),
            },
            {
              value: 'EMBEDDED',
              label: `events-template.create-message.embed-data-source`,
              dataSourceName: tabularDataSource.name,
              dataSourceType: i18nService.translate(
                `events-template.create-data.source.type.${tabularDataSource.type?.toLowerCase()}`
              ),
            },
          ]
        : [
            {
              value: 'ATTACHED',
              label: `events-template.create-message.attach-data-source`,
              dataSourceName: tabularDataSource.name,
              dataSourceType: i18nService.translate(
                `events-template.create-data.source.type.${tabularDataSource.type?.toLowerCase()}`
              ),
            },
          ];
    }
    return [];
  }, [dataSources, canEmbed]);

  const showPreview = useCallback(async () => {
    const whiteLabelDetails = getState().config?.whiteLabelDetails;
    const tableDataSourceMeta = dataSources.find(
      (d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2
    );

    let tableDataSource;
    if (tableDataSourceMeta) {
      tableDataSource = await httpService.api({
        type: 'getEventTemplateDataSource',
        urlParams: { templateId: templateData.id, dataSourceId: tableDataSourceMeta.id },
      });
    }

    await modalService.openModal('previewEmailMessageModal', {
      templateData,
      displaySettingsData: whiteLabelDetails,
      tableDataSource,
    });
  }, [templateData, dataSources]);

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <I18n className={styles.header}>events-template.create-message.email-format</I18n>
        <div className={styles.previewButton} onClick={showPreview}>
          <div className={styles.iconButton}>
            <Icon type={'preview'} color={cssVarsService.vars.systemEnableEditorIcons} />
          </div>
          <I18n>events-template.create-message.preview-message</I18n>
        </div>
      </div>

      {attachmentTypeOptions && attachmentTypeOptions.length > 0 && (
        <div className={styles.row}>
          <div className={styles.attachmentType}>
            <RadioGroup
              value={attachmentType || ''}
              classes={{ root: styles.radioGroupRoot }}
              onChange={(e) => setTemplateData(e.target.value, 'attachmentType')}>
              {attachmentTypeOptions.map((opt) => (
                <FormControlLabel
                  key={opt.value}
                  classes={{ label: styles.radioLabel }}
                  value={opt.value}
                  control={<Radio />}
                  label={i18nService.translate(opt.label, languageId, {
                    dataSourceName: opt.dataSourceName,
                    dataSourceType: opt.dataSourceType,
                  })}
                />
              ))}
            </RadioGroup>
            <div className={classNames(styles.error, 'ellipsis-overflow')}>
              {canEmbedCheckFinished && !canEmbed && (
                <I18n>events-template.create-message.ten-columns-restriction</I18n>
              )}
            </div>
          </div>
        </div>
      )}

      <I18n className={styles.subHeader}>events-template.create-message.subject</I18n>
      <div className={styles.subject}>
        <RichTextEditor
          isSingleLine
          maxLength={MAX_SUBJECT_LENGTH}
          allowDataSources={true}
          allowRichText={false}
          dataSources={valueOnlyDataSources}
          value={templateData.subject}
          propertyName="subject"
          setTemplateData={setTemplateData}
          originatesFrom={origin.eventTemplate}
        />
      </div>
      <I18n className={styles.subjectLengthRemark}>
        events-template.create-message.subject.max-characters.message
      </I18n>

      <I18n className={styles.subHeader}>events-template.create-message.body</I18n>
      <div className={styles.body}>
        <RichTextEditor
          allowDataSources={true}
          dataSources={valueOnlyDataSources}
          value={templateData.body}
          propertyName="body"
          setTemplateData={setTemplateData}
          originatesFrom={origin.eventTemplate}
        />
      </div>
    </div>
  );
}

export default EmailMessageEditor;
