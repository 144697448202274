import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { useSelector } from '@redux/useSelector';
import styles from './SraErrorPage.scss';
import { getUserConfig } from '@pages/MainPage/MainPage.utils';
import NewLogo from '@components/NewLogo';

function SraErrorPage() {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const { isLoggedIn } = useSelector((state) => state.login);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const error = urlSearchParams.get('error');

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    const isIframe = inIframe();
    getUserConfig(isLoggedIn, undefined, undefined, isIframe);
  }, []);

  return (
    <div
      className={classNames(
        !isMobileView && 'max-height',
        styles.loginPage,
        isMobileView && styles.loginPageMobile
      )}>
      <div className={classNames(styles.logoWrapper, isMobileView && styles.mobileMode)}>
        <NewLogo />
      </div>
      <I18n noEllipsis className={styles.loginForm}>{`errors.${error}`}</I18n>
    </div>
  );
}

export default withRouter(SraErrorPage);
