const DISPLAY_NAME_MAX_LENGTH = 20;
const DISPLAY_NAME_MIN_LENGTH = 1;

export const handleColumnNameChange = (
  displayName,
  previousDisplayName,
  editedColumn,
  setEditedColumn,
  setDisplayNameHasError,
  stacked,
  otherDisplayNames,
  index,
  columnChanged
) => {
  if (
    !displayName ||
    (displayName.length > DISPLAY_NAME_MAX_LENGTH &&
      displayName.length > editedColumn.displayName.length)
  )
    return;

  validateColumnNameError(displayName, setDisplayNameHasError, stacked, otherDisplayNames);
  setEditedColumn({ ...editedColumn, displayName });

  if (displayName !== previousDisplayName) columnChanged({ ...editedColumn, displayName }, index);
};

export const handleYAxisNameChange = (
  displayName,
  editedColumn,
  setEditedColumn,
  setDisplayNameHasError
) => {
  if (
    !displayName ||
    (displayName.length > DISPLAY_NAME_MAX_LENGTH &&
      displayName.length > editedColumn.displayName.length)
  )
    return;
  validateYAxisColumnNameError(displayName, setDisplayNameHasError);
  setEditedColumn({ ...editedColumn, displayName });
};

export const validateColumnNameError = (
  displayName = '',
  setDisplayNameHasError,
  stacked,
  otherDisplayNames
) => {
  setDisplayNameHasError(
    !(
      displayName?.length <= DISPLAY_NAME_MAX_LENGTH &&
      !(stacked && otherDisplayNames.some((name) => name === displayName))
    )
  );
};

export const validateYAxisColumnNameError = (displayName = '', setDisplayNameHasError) => {
  setDisplayNameHasError(!(displayName?.length <= DISPLAY_NAME_MAX_LENGTH));
};

export const isColumnNameValid = (displayName, stacked = false, otherDisplayNames = []) => {
  return !!(
    displayName?.length <= DISPLAY_NAME_MAX_LENGTH &&
    !(stacked && otherDisplayNames.some((name) => name === displayName))
  );
};

export const handleNameBlur = (editedColumn, setEditedColumn, props) => {
  const { column, index, columnChanged } = props;
  const { displayName } = column;
  if (editedColumn.displayName.length < DISPLAY_NAME_MIN_LENGTH) setEditedColumn(column);
  else if (editedColumn.displayName !== displayName) columnChanged(editedColumn, index);
};
