import React, { useCallback } from 'react';
import Button from '@components/Button';

import { editAssetService } from '../../EditAssetService';
import AlarmsTable from './AlarmsTable/AlarmsTable';
import I18n from '@components/I18n';
import styles from './AssetInfoAlarms.scss';

function AssetInfoAlarms() {
  const cancel = useCallback(() => editAssetService.dismiss(false), []);

  return (
    <>
      <div className={styles.content}>
        <AlarmsTable />
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetInfoAlarms;
