import React, { useEffect, useMemo, useState } from 'react';

// Components
import MaterialCheckbox from '@components/Checkbox';

// services & Interfaces
import { ImageInfo, SwitchCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import I18n from '@components/I18n';
import { i18nService } from '@core/i18n/I18nService';
import { cssVarsService } from '@core/CssVarsService';
import classNames from 'classnames';

// 3rd party
import { cloneDeep, compact, concat } from 'lodash';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import { Receipt } from '@material-ui/icons';
import TextualRemarkSection from './TextualRemarkSection/TextualRemarkSection';
import RequestSentTextSection from './RequestSentTextSection/RequestSentTextSection';
import SwitchButtonSection from './SwitchButtonSection/SwitchButtonSection';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { buildOptions } from './Switch.utils';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 10,
  },
}));

const Switch = (props) => {
  const classes = useStyles(props);
  const { widgetData, setWidgetData } = props;
  const { customization } = widgetData;
  const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };
  const { offText } = (customization as SwitchCustomization) || {};
  const [defaultOnImage, setDefaultOnImage] = useState(null);
  const [defaultOffImage, setDefaultOffImage] = useState(null);

  const horizontalAlignmentOptions = useMemo(
    () =>
      compact([
        { label: 'enum.CENTER', value: 'CENTER' },
        { label: 'enum.RIGHT', value: 'RIGHT' },
        { label: 'enum.LEFT', value: 'LEFT' },
      ]),
    []
  );

  const verticalAlignmentOptions = useMemo(
    () =>
      compact([
        { label: 'enum.TOP', value: 'TOP' },
        { label: 'enum.BOTTOM', value: 'BOTTOM' },
      ]),
    []
  );

  const switchButtonTypeOptions = useMemo(
    () =>
      compact([
        { label: 'enum.TOGGLE_BUTTON', value: 'TOGGLE_BUTTON' },
        { label: 'enum.BUTTON', value: 'BUTTON' },
        { label: 'enum.IMAGE_BUTTON', value: 'IMAGE_BUTTON' },
      ]),
    []
  );

  const switchSizeOptions = useMemo(
    () =>
      compact([
        { label: 'enum.NORMAL', value: 'NORMAL' },
        { label: 'enum.SMALL', value: 'SMALL' },
      ]),
    []
  );

  const getImageButtonDefaultImages = async () => {
    try {
      const res: any = await httpService.api({
        type: 'getImageGallery',
        query: { p: 1, ps: 2, widgetType: 'switch', ordering: '-is_default' },
      });
      if (res && res.results) {
        let image = res.results.find((i) => i.type == 'SWITCH_ON');
        if (image) setDefaultOnImage({ imageId: image.id, imageName: image.name });

        image = res.results.find((i) => i.type == 'SWITCH_OFF');
        if (image) setDefaultOffImage({ imageId: image.id, imageName: image.name });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (!defaultOnImage || !defaultOffImage) {
      getImageButtonDefaultImages();
    }
  }, [defaultOnImage, defaultOffImage]);

  useEffect(() => {
    !customization &&
      updateWidgetCustomization(
        {
          textualRemark: null,
          textualRemarkFontSize: 12,
          textualRemarkColor: cssVarsService.vars.widgetsFont,
          textualRemarkHorizontalAlignment: 'LEFT',
          textualRemarkVerticalAlignment: 'BOTTOM',
          requestSentText: i18nService.translate(
            'create-widget-page.create-widget.step-four.switch.request-sent-text.default'
          ),
          requestSentFontSize: 12,
          requestSentColor: cssVarsService.vars.widgetsFont,
          requestSentHorizontalAlignment: 'LEFT',
          buttonType: 'TOGGLE_BUTTON',
          switchSize: 'NORMAL',
          onText: i18nService.translate(
            'create-widget-page.create-widget.step-four.switch.on-text.default'
          ),
          offText: null,
          switchTextColor: cssVarsService.vars.widgetsFont,
          switchTextDisabledColor: '#A4A4A4',
          switchTextFontSize: 12,
          onColor: '#8CBBE9',
          offColor: '#9F9F9F',
          disabledColor: '#A4A4A4',
          switchColor: cssVarsService.vars.systemButtonBackground,
          onImage: { imageId: null, imageName: null },
          offImage: { imageId: null, imageName: null },
        },
        setWidgetData
      );
    setScopeAndMetrics();
  }, []);

  const setCustomizationData = (data, key) => {
    const values = cloneDeep(customization);
    if (key == 'buttonType' && data == 'BUTTON' && !offText) {
      values['offText'] = i18nService.translate(
        'create-widget-page.create-widget.step-four.switch.off-text.default'
      );
    }

    if (key == 'buttonType') {
      if (data == 'IMAGE_BUTTON') {
        values['onImage'] = {
          imageId: defaultOnImage.imageId,
          imageName: defaultOnImage.imageName,
        };
        values['offImage'] = {
          imageId: defaultOffImage.imageId,
          imageName: defaultOffImage.imageName,
        };
      } else {
        values['onImage'] = { imageId: null, imageName: null };
        values['offImage'] = { imageId: null, imageName: null };
      }
    }

    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  const setScopeAndMetrics = () => {
    let index = 0;
    const { tags, tagTypes } = widgetData;
    const data = compact(
      concat(
        tags.map((a) => buildOptions(a, 'TAG')),
        tagTypes.map((a) => buildOptions(a, 'TAG_TYPE'))
      )
    );

    setWidgetData((prevState) => ({
      ...prevState,
      scope: 'LAST_VALUE',
      metrics: data.map((t) => ({
        order: index++,
        valueType: t.tagType,
        valueId: t.id,
        operation: null,
      })),
    }));
  };

  const openImageGallery = async (
    imageInfo: ImageInfo,
    key,
    modalHeader,
    existingImages: ImageInfo[],
    imageType
  ) => {
    try {
      const res = await modalService.openModal('imageGallery', {
        selectedImageData: { id: imageInfo.imageId, name: imageInfo.imageName },
        modalHeader,
        imageType,
        existingImages: existingImages.map((con) => con.imageId),
        widgetType: 'switch',
      });
      if (res && res.id) {
        setCustomizationData({ imageName: res.name, imageId: res.id }, key);
      }
    } catch (e) {}
  };

  return (
    <div className={classes.wrapper}>
      <TextualRemarkSection
        setCustomizationData={setCustomizationData}
        customization={customization}
        horizontalAlignmentOptions={horizontalAlignmentOptions}
        verticalAlignmentOptions={verticalAlignmentOptions}
      />
      <RequestSentTextSection
        setCustomizationData={setCustomizationData}
        customization={customization}
        horizontalAlignmentOptions={horizontalAlignmentOptions}
      />
      <SwitchButtonSection
        setCustomizationData={setCustomizationData}
        customization={customization}
        switchSizeOptions={switchSizeOptions}
        switchButtonTypeOptions={switchButtonTypeOptions}
        openImageGallery={openImageGallery}
      />
    </div>
  );
};

export default Switch;
