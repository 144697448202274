import { httpService } from '@core/http/HttpService';
import { CreateNewARProjectRes } from '@http/server.interface.d';
import { decrementBusyCounter } from '@src/redux/config';
import { dispatch } from '@src/redux/store';

export const validateARProjectName = async (projectName) => {
  try {
    const response = await httpService.api<{ valid: boolean }>({
      type: 'arProjectNameValidate',
      data: { name: projectName },
      disableBI: true,
    });
    return response.valid;
  } catch (error) {
    console.error('Error validating project name', error);
    return false;
  }
};

export const saveCreateARProject = (
  values,
  assetTypeObject,
  uploadedModelID,
  dismiss,
  callback
) => {
  const newARProject = {
    name: values.name,
    assetTypeId: assetTypeObject.id,
    arModelId: uploadedModelID,
  };

  httpService
    .api<CreateNewARProjectRes>({
      type: 'createNewARProject',
      data: newARProject,
    })
    .then((newARProject: any) => {
      callback(newARProject);
      dismiss(false);
      dispatch(decrementBusyCounter());
    });
};

export const unitsOptions = [
  { label: 'units-of-measurement.full.in', value: 'IN' },
  { label: 'units-of-measurement.full.ft', value: 'FT' },
  { label: 'units-of-measurement.full.mm', value: 'MM' },
  { label: 'units-of-measurement.full.cm', value: 'CM' },
  { label: 'units-of-measurement.full.dm', value: 'DM' },
  { label: 'units-of-measurement.full.m', value: 'M' },
  { label: 'units-of-measurement.full.dam', value: 'DAM' },
  { label: 'units-of-measurement.full.hm', value: 'HM' },
];

export function saveUnitsAndUpVectorForARProject(units: string, upVector: string, taskId: number) {
  httpService.api<CreateNewARProjectRes>({
    type: 'saveUnitsForARProject',
    urlParams: { taskId },
    data: { units, upVector },
  });
}

export const upViewImages = [
  {
    title: 'Up Vector X',
    value: 'X_UP',
    position: [4, 3, 4],
    upVector: [0, 1, 0],
    rotation: [0, 0, Math.PI / 2],
  },
  {
    title: 'Up Vector Y',
    value: 'Y_UP',
    position: [4, 3, 4],
    upVector: [0, 1, 0],
    rotation: [0, 0, 0],
  },
  {
    title: 'Up Vector Z',
    value: 'Z_UP',
    position: [4, 3, 4],
    upVector: [0, 1, 0],
    rotation: [Math.PI / 2, 0, 0],
  },
  {
    title: 'Down Vector X',
    value: 'X_DOWN',
    position: [4, 3, 4],
    upVector: [0, 1, 0],
    rotation: [0, 0, -Math.PI / 2],
  },
  {
    title: 'Down Vector Y',
    value: 'Y_DOWN',
    position: [4, 3, 4],
    upVector: [0, 1, 0],
    rotation: [0, 0, Math.PI],
  },
  {
    title: 'Down Vector Z',
    value: 'Z_DOWN',
    position: [4, 3, 4],
    upVector: [0, 1, 0],
    rotation: [-Math.PI / 2, 0, 0],
  },
];
