import React from 'react';
import I18n from '@components/I18n';
import styles from './SidePanelToolbar.scss';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import { usePrevious } from '@core/hooks/usePrevious';
import { MAX_POSITION, MAX_SIZE, MIN_POSITION, MIN_SIZE } from './SidePanelToolbar.utils';

function MeasurementsPanel(props) {
  const {
    widgetData,
    widgetList,
    setWidgetData,
    setWidgetList,
    onUpdate,
    measurementsError,
    setMeasurementsError,
  } = props;
  const prevStatesForm = widgetData?.statesForm
    ? usePrevious(widgetData?.statesForm)
    : { w: MIN_SIZE, h: MIN_SIZE, x: 0, y: 0, z: 0 };

  const handlePaste = (e, min, max) => {
    const pastedValue = parseFloat(e.clipboardData.getData('text'));
    if (!isNaN(pastedValue) && (pastedValue > max || pastedValue <= min)) {
      e.preventDefault();

      var paste;
      if (pastedValue > max) {
        paste = max;
      } else if (pastedValue <= min) {
        paste = min;
      }

      // Insert the max value instead of the pasted value
      const inputName = e.target.name;
      const newFormData = { ...widgetData };
      const nameParts = inputName.split('.');

      if (nameParts.length === 1) {
        newFormData[inputName] = paste;
      } else {
        let nestedProperty = newFormData;
        for (let i = 0; i < nameParts.length - 1; i++) {
          nestedProperty[nameParts[i]] = { ...nestedProperty[nameParts[i]] };
          nestedProperty = nestedProperty[nameParts[i]];
        }
        nestedProperty[nameParts[nameParts.length - 1]] = paste;
      }

      setWidgetData(newFormData);
      const updatedWidgets = widgetList.map((widget) =>
        widget.id === widgetData.id ? { ...widget, ...newFormData } : widget
      );
      setWidgetList(updatedWidgets);
      onUpdate(newFormData);
    }
  };

  const handleBlur = (e, current) => {
    const { name, value } = e.target;
    const newFormData = { ...widgetData };

    if (!value || value === '') {
      setMeasurementsError(null);
      newFormData.statesForm[name.split('.')[1]] = current;
      setWidgetData(newFormData);
    }
  };

  const handleChange = (e, min, max) => {
    setMeasurementsError(null);

    const { name, value } = e.target;
    const nameParts = name.split('.');
    let formattedValue: number;

    if (!value || value === '') {
      setMeasurementsError(name);
    } else if (value < min) {
      formattedValue = min;
    } else if (value > max) {
      formattedValue = max;
    } else {
      formattedValue = parseFloat(value);
    }

    const newFormData = { ...widgetData };

    if (nameParts.length === 1) {
      // Handle top-level properties
      newFormData[name] = formattedValue;
    } else {
      // Handle nested properties
      let nestedProperty = newFormData;

      for (let i = 0; i < nameParts.length - 1; i++) {
        nestedProperty[nameParts[i]] = { ...nestedProperty[nameParts[i]] };
        nestedProperty = nestedProperty[nameParts[i]];
      }

      nestedProperty[nameParts[nameParts.length - 1]] = formattedValue;
    }

    setWidgetData(newFormData);

    const updateWidgetList = () => {
      const updatedWidgets = widgetList.map((widget) =>
        widget.id === widgetData.id ? { ...widget, ...newFormData } : widget
      );
      setWidgetList(updatedWidgets);
    };

    updateWidgetList();

    if (measurementsError || !value || value === '') {
      return;
    }

    onUpdate(newFormData);
  };

  return (
    <>
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>
          <I18n>augmented-reailty.widget.side-panel.width-field</I18n>
        </label>
        <InputBase
          className={classNames(
            styles.smallContainerInput,
            measurementsError == 'statesForm.w' ? styles.errorInput : null
          )}
          type="number"
          name="statesForm.w"
          value={widgetData.statesForm.w || ''}
          onBlur={(e) => handleBlur(e, prevStatesForm.w)}
          onChange={(e) => handleChange(e, MIN_SIZE, MAX_SIZE)}
          onPaste={(e) => handlePaste(e, MIN_SIZE, MAX_SIZE)}
          inputProps={{
            maxLength: 50,
            step: 0.3,
            min: MIN_SIZE,
            max: MAX_SIZE,
            title: widgetData.statesForm.w,
          }}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>
          <I18n>augmented-reailty.widget.side-panel.height-field</I18n>
        </label>
        <InputBase
          className={classNames(
            styles.smallContainerInput,
            measurementsError == 'statesForm.h' ? styles.errorInput : null
          )}
          type="number"
          name="statesForm.h"
          value={widgetData.statesForm.h || ''}
          onBlur={(e) => handleBlur(e, prevStatesForm.h)}
          onChange={(e) => handleChange(e, MIN_SIZE, MAX_SIZE)}
          onPaste={(e) => handlePaste(e, MIN_SIZE, MAX_SIZE)}
          inputProps={{
            maxLength: 50,
            step: 0.1,
            min: MIN_SIZE,
            max: MAX_SIZE,
            title: widgetData.statesForm.h,
          }}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>x: </label>
        <InputBase
          className={classNames(
            styles.smallContainerInput,
            measurementsError == 'statesForm.x' ? styles.errorInput : null
          )}
          type="number"
          name="statesForm.x"
          value={
            widgetData.statesForm.x || widgetData.statesForm.x == '0' ? widgetData.statesForm.x : ''
          }
          onBlur={(e) => handleBlur(e, prevStatesForm.x)}
          onChange={(e) => handleChange(e, MIN_POSITION, MAX_POSITION)}
          onPaste={(e) => handlePaste(e, MIN_POSITION, MAX_POSITION)}
          inputProps={{
            maxLength: 50,
            step: 0.1,
            title: widgetData.statesForm.x,
          }}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>y: </label>
        <InputBase
          className={classNames(
            styles.smallContainerInput,
            measurementsError == 'statesForm.y' ? styles.errorInput : null
          )}
          type="number"
          name="statesForm.y"
          value={
            widgetData.statesForm.y || widgetData.statesForm.y == '0' ? widgetData.statesForm.y : ''
          }
          onBlur={(e) => handleBlur(e, prevStatesForm.y)}
          onChange={(e) => handleChange(e, MIN_POSITION, MAX_POSITION)}
          onPaste={(e) => handlePaste(e, MIN_POSITION, MAX_POSITION)}
          inputProps={{
            maxLength: 50,
            step: 0.1,
            title: widgetData.statesForm.y,
          }}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>z: </label>
        <InputBase
          className={classNames(
            styles.smallContainerInput,
            measurementsError == 'statesForm.z' ? styles.errorInput : null
          )}
          type="number"
          name="statesForm.z"
          value={
            widgetData.statesForm.z || widgetData.statesForm.z == '0' ? widgetData.statesForm.z : ''
          }
          onBlur={(e) => handleBlur(e, prevStatesForm.z)}
          onChange={(e) => handleChange(e, MIN_POSITION, MAX_POSITION)}
          onPaste={(e) => handlePaste(e, MIN_POSITION, MAX_POSITION)}
          inputProps={{
            maxLength: 50,
            step: 0.1,
            title: widgetData.statesForm.z,
          }}
        />
      </div>
    </>
  );
}

export default MeasurementsPanel;
