import React from 'react';
import { InputBase } from '@material-ui/core';
import { NumberInputProps } from './NumberInput.interface';
import styles from './NumberInput.scss';
import classNames from 'classnames';

function NumberInput(props: NumberInputProps) {
  const { value, defaultValue, className, isError, min, max, onBlur, onChange } = props;

  return (
    <InputBase
      type="number"
      className={classNames(styles.containerInput)}
      value={value}
      defaultValue={defaultValue}
      onBlur={onBlur}
      onChange={onChange}
      inputProps={{
        min: min,
        max: max,
        style: {
          fontSize: 12,
          color: 'var(--systemFont)',
        },
      }}
    />
  );
}

export default NumberInput;
