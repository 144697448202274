import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import MaterialCheckbox from '@components/Checkbox';

import styles from './Checkbox.scss';

const Checkbox = props => {
  return (
    <components.Option {...props}>
      <div className={styles.row} title={props.children}>
        <MaterialCheckbox color="primary" checked={props.isSelected} />
        <span className={classNames(styles.oneColumn, 'ellipsis-overflow')}>{props.children}</span>
      </div>
    </components.Option>
  );
};

export default Checkbox;
