import React, { useCallback, useState, useEffect, useMemo } from 'react';

import I18n from '@components/I18n';
import styles from './StepFive.scss';
import classNames from 'classnames';
import DashboardNavigation from './DashboardNavigation';
import MaterialCheckbox from '@components/Checkbox';
import AssetTypeNavigation from './AssetTypeNavigation';
import { httpService } from '@core/http/HttpService';
import { DashboardNavRes } from './DashboardNavigation/DashboardNavigation.interface';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { filterOption } from '../../widgets.utils';
import { useSelector } from '@src/redux/useSelector';
import { compact } from 'lodash';
import UrlNavigation from './UrlNavigation';
import { AssetTypeResponse } from '@src/pages/CreateWidgetPage/DataStepComponent/StepOne/StepOne.interface';

function StepFive(props) {
  const { widgetData, setWidgetData } = props;
  const { navigationDashboard, navigateFilterBy, allAssetTypes } = widgetData;
  const [assetTypes, setAssetTypes] = useState(widgetData.assetTypes || []);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [assetTypeNavigation, setAssetTypeNavigation] = useState(false);
  const [urlNavigation, setUrlNavigation] = useState(false);
  const dashboardEditorData = useSelector((state) => state.dashboardEditor);

  useEffect(() => {
    if (allAssetTypes) {
      const request = httpService.api({
        type: 'getAssetTypeOptionsInfo',
        query: { p: 1, ps: 1000 },
      });
      request.then((types: AssetTypeResponse) => {
        setAssetTypes(
          types.results.map((t) => ({
            id: t.id,
            name: t.name,
            labels: t.labels,
          }))
        );
      });
      return () => {
        // If the component is unmounted before the request finishes we should cancel the request.
        request.cancel && request.cancel();
      };
    }
  }, []);

  useEffect(() => {
    httpService
      .api({ type: 'getAllPublishDashboard', urlParams: { p: 1, ps: 99999 } })
      .then((dashboards: DashboardNavRes) => {
        const isDraftDashboardInclude = dashboards.results.find(
          (dashboard) => dashboard.refId === dashboardEditorData.refId
        );
        //if in edit mode dashboard cannot navigate to itself only to his publish dashboard
        //else add the draft dashboard to the publish dashboards list
        const dashboardsArr = isDraftDashboardInclude
          ? dashboards.results
          : [dashboardEditorData, ...dashboards.results];
        setDashboardOptions(
          dashboardsArr.map((dashboard) => ({
            id: dashboard.refId,
            name: dashboard.name,
            assetTypeId: null,
          }))
        );
      });
    //if edit widget and is navigate by asset type.
    navigationDashboard &&
      navigationDashboard.length &&
      navigationDashboard[0].assetTypeId &&
      setAssetTypeNavigation(true);

    navigationDashboard &&
      navigationDashboard.length &&
      navigationDashboard[0].type === 'URL' &&
      setUrlNavigation(true);
  }, []);

  const onChange = useCallback((newValue, type) => {
    setWidgetData((prevState) => ({
      ...prevState,
      navigationDashboard: newValue ? [{ ...newValue, type: type }] : [],
      navigateFilterBy: newValue ? prevState.navigateFilterBy : [],
    }));

    ['asset'].includes(widgetData.type) &&
      type === 'DASHBOARD' &&
      setWidgetData((prevState) => ({
        ...prevState,
        navigateFilterBy: [filterOption],
      }));
  }, []);

  const onSelectedAssetTypeDashboard = useCallback((dashboard, assetTypeId) => {
    let navigationDashboard;
    setWidgetData((prevState) => {
      const changedAssetTypeNav = prevState.navigationDashboard.find(
        (n) => n.assetTypeId === assetTypeId
      );
      if (changedAssetTypeNav) {
        changedAssetTypeNav.id = dashboard && dashboard.id;
        changedAssetTypeNav.name = dashboard && dashboard.name;
        changedAssetTypeNav.type = urlNavigation ? 'URL' : 'DASHBOARD';

        navigationDashboard = compact([
          ...prevState.navigationDashboard.filter((n) => n.assetTypeId !== assetTypeId),
          dashboard && changedAssetTypeNav,
        ]);
      } else {
        navigationDashboard = [
          ...prevState.navigationDashboard,
          { ...dashboard, assetTypeId: assetTypeId, type: urlNavigation ? 'URL' : 'DASHBOARD' },
        ];
      }

      return {
        ...prevState,
        navigationDashboard,
        navigateFilterBy: ['asset'].includes(widgetData.type) ? [filterOption] : [],
      };
    });
  }, []);

  const onSelectedAssetTypeUrl = useCallback((url, assetTypeId, type) => {
    let navigationDashboard;
    setWidgetData((prevState) => {
      const changedAssetTypeNav = prevState.navigationDashboard.find(
        (n) => n.assetTypeId === assetTypeId
      );
      if (changedAssetTypeNav) {
        changedAssetTypeNav.url = url;
        changedAssetTypeNav.type = type;

        navigationDashboard = compact([
          ...prevState.navigationDashboard.filter((n) => n.assetTypeId !== assetTypeId),
          url && changedAssetTypeNav,
        ]);
      } else {
        navigationDashboard = [
          ...prevState.navigationDashboard,
          { url, assetTypeId: assetTypeId, type: type },
        ];
      }

      return {
        ...prevState,
        navigationDashboard,
        navigateFilterBy: [],
      };
    });
  }, []);

  const onReset = useCallback(() => {
    setWidgetData((prevState) => ({
      ...prevState,
      navigationDashboard: [],
      navigateFilterBy: [],
    }));
  }, []);

  const onResetSelectedAssetTypeNavigation = useCallback((assetTypeId) => {
    setWidgetData((prevState) => {
      const navigationDashboard = prevState.navigationDashboard.filter(
        (n) => n.assetTypeId !== assetTypeId
      );
      return {
        ...prevState,
        navigationDashboard,
        navigateFilterBy: [],
      };
    });
  }, []);

  const toggleFilterByAssetId = useCallback((event) => {
    event.persist();
    setWidgetData((prevState) => ({
      ...prevState,
      navigateFilterBy: event.target.checked ? [filterOption] : [],
    }));
  }, []);

  const toggleAssetTypeNavigation = useCallback(() => {
    setAssetTypeNavigation((prevState) => !prevState);
    setWidgetData((prevState) => ({
      ...prevState,
      navigationDashboard: [],
      navigateFilterBy: [],
    }));
  }, []);

  const toggleUrlNavigation = useCallback(() => {
    setUrlNavigation((prevState) => !prevState);
    setWidgetData((prevState) => ({
      ...prevState,
      navigationDashboard: [],
      navigateFilterBy: [],
    }));
  }, []);

  const allowAssetTypeNavigation = useMemo(
    () => getConfigValueFromWidgetSettings(widgetData.type, 'allowAssetTypeNavigation')(widgetData),
    [widgetData]
  );

  const showPerAssetTypeNavigation = useMemo(
    () => widgetData.assetTypes?.length > 1 || allAssetTypes,
    [widgetData]
  );

  const hideFilterNavigation = useMemo(
    () => getConfigValueFromWidgetSettings(widgetData.type, 'hideFilterNavigation')(widgetData),
    [widgetData]
  );

  const allowAssetTypeNavigationWithoutAssetProperties = useMemo(
    () =>
      getConfigValueFromWidgetSettings(
        widgetData.type,
        'allowAssetTypeNavigationWithoutAssetProperties'
      ),
    [widgetData]
  );

  return (
    <div className={styles.stepFiveContainer}>
      <div className={styles.stepHeader}>
        <I18n>widget-side-bar.navigate</I18n>
      </div>
      <div className={styles.widgetNavigation}>
        <I18n>create-widget-page.create-widget.step-five.header-text</I18n>
        {allowAssetTypeNavigation && showPerAssetTypeNavigation && (
          <div className={styles.checkInput}>
            <MaterialCheckbox checked={assetTypeNavigation} onClick={toggleAssetTypeNavigation} />
            <I18n style={{ marginLeft: '13px' }}>
              create-widget-page.create-widget.step-five.asset-type-check
            </I18n>
          </div>
        )}
        <div className={styles.checkInput}>
          <MaterialCheckbox checked={urlNavigation} onClick={toggleUrlNavigation} />
          <I18n style={{ marginLeft: '13px' }}>
            create-widget-page.create-widget.step-five.open-url-in-new-tab
          </I18n>
        </div>

        {assetTypeNavigation ? (
          <div style={{ margin: '20px 0 0 10px' }}>
            <AssetTypeNavigation
              navigationDashboard={navigationDashboard}
              onSelectedAssetTypeDashboard={onSelectedAssetTypeDashboard}
              onSelectedAssetTypeUrl={onSelectedAssetTypeUrl}
              onChange={(selectedVal) => onChange(selectedVal, urlNavigation ? 'URL' : 'DASHBOARD')}
              onReset={onResetSelectedAssetTypeNavigation}
              assetTypes={assetTypes}
              dashboardOptions={dashboardOptions}
              urlNavigation={urlNavigation}
            />
          </div>
        ) : urlNavigation ? (
          <div className={styles.navigation} style={{ maxHeight: '28px' }}>
            <span className={classNames(styles.navigatesTo, 'ellipsis-overflow')}>
              <I18n>create-widget-page.create-widget.step-five.url-navigation</I18n>
            </span>
            <div className={styles.input}>
              <UrlNavigation
                value={navigationDashboard[0]?.url}
                onSelectedUrl={(selectedVal) => onChange(selectedVal, 'URL')}
                onReset={onReset}
              />
            </div>
          </div>
        ) : (
          <div className={styles.navigation} style={{ maxHeight: '28px' }}>
            <span className={classNames(styles.navigatesTo, 'ellipsis-overflow')}>
              <I18n>create-widget-page.create-widget.step-five.dashboard-navigation</I18n>
            </span>
            <div className={styles.input}>
              <DashboardNavigation
                value={
                  navigationDashboard.length
                    ? dashboardOptions.find((opt) => opt.id === navigationDashboard[0]?.id)
                    : null
                }
                onSelectedDashboard={(selectedVal) => onChange(selectedVal, 'DASHBOARD')}
                onReset={onReset}
                dashboardOptions={dashboardOptions}
              />
            </div>
          </div>
        )}
        {(allowAssetTypeNavigationWithoutAssetProperties || !hideFilterNavigation) &&
          navigationDashboard &&
          navigationDashboard.length > 0 &&
          !urlNavigation &&
          (widgetData.type !== 'heatmap' ||
            widgetData.groupBy.some(
              (g) => g.valueType === 'ASSET_PROPERTY' && g.valueId === 1
            )) && (
            <div className={styles.checkInput}>
              <MaterialCheckbox
                checked={!!navigateFilterBy && navigateFilterBy.length > 0}
                onChange={toggleFilterByAssetId}
                disabled={['asset'].includes(widgetData.type)}
              />
              <I18n style={{ marginLeft: '13px' }}>{filterOption.label}</I18n>
            </div>
          )}
      </div>
    </div>
  );
}

export default StepFive;
