import React, { useMemo } from 'react';
import classNames from 'classnames';
import CanvasWidget from '@components/canvas/CanvasWidget';
import { editorConfig } from '@core/canvas/editorConfig';
import { isWidgetGroup } from '@core/canvas/widget.utils';
import CanvasWidgetGroupItem from '../CanvasWidgetGroupItem';
import CanvasWidgetActionBar from '../CanvasWidgetActionBar';
import styles from './DragCaster.scss';
import { DragCasterProps } from './DragCaster.interface.d';
import { cssVarsService } from '@core/CssVarsService';

const cellSize = editorConfig.gridCellSizePx;

/**
 * The drag caster casts a preview of the dragged-item
 * on the canvas.
 */
function DragCaster(props: DragCasterProps) {
  const { draggedItem, previewData } = props;
  const { gridIndexes, isValidLocation, canvasWidget, draggedType } = draggedItem;
  const hideWidgetName = canvasWidget?.hideWidgetName;

  const isDraft = useMemo(() => canvasWidget && canvasWidget.status === 'DRAFT', [canvasWidget]);
  const isGroup = isWidgetGroup(draggedItem);

  const wrapperS = useMemo(() => {
    const { x, y, w, h } = gridIndexes;
    return {
      top: y * cellSize,
      left: x * cellSize,
      height: h * cellSize,
      width: w * cellSize,
      opacity: isValidLocation ? 1 : 0.2,
    };
  }, [gridIndexes, isValidLocation]);

  return (
    <div className={classNames(styles.wrapper, isGroup && styles.group)} style={wrapperS}>
      {isGroup ? (
        canvasWidget.children.map((child) => (
          <CanvasWidgetGroupItem key={child.id} parent={canvasWidget} widget={child} />
        ))
      ) : (
        <div className={styles.inner}>
          <CanvasWidgetActionBar isDraft={isDraft} widget={canvasWidget} hideActions />
          <CanvasWidget
            isDraft={isDraft}
            widgetType={draggedType}
            widget={canvasWidget}
            data={previewData}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(DragCaster);
