import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { template, compact } from 'lodash';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@http/HttpService';
import { apiMap } from '@http/apiMap';
import { useSelector } from '@redux/useSelector';
import { getState } from '@redux/store';
import { AssetData } from '@redux/redux.interface.d';
import { assetsService } from '../AssetsService';
import AssetsTable from './AssetsTable';
import styles from './AssetsTab.scss';
import { getAssetCertificat } from './assetsTab.utils';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';

function AssetsTab() {
  const [searchText, setSearchText] = useState('');
  const [clearSearch, setClearSearch] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);
  const totalCount = useSelector((state) => state.asset.totalCount);
  const resultsCount = useSelector((state) => state.asset.resultsCount);
  const userOrgType = useSelector(
    (state) => state.config.organizationDetails && state.config.organizationDetails.type
  );

  const search = (searchText) => {
    setSearchText(searchText);
  };

  const getLastSearches = () => {
    const organizationId = getState().login.userOrganizationId;
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getAssets'].url)({ organizationId }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches);
      });
  };

  const openCreateAsset = useCallback(() => {
    modalService.openModal('createAsset').then((temp) => {
      if (!temp || !temp.data) return;
      const { data, plcType, routerId } = temp;
      httpService
        .api({
          type: 'createAsset',
          data,
        })
        .then((asset: AssetData) => {
          ((plcType !== 'LEGACY' && plcType !== 'MODBUS') || routerId) &&
            modalService
              .openConfirm({
                text: 'assets-tab.download-certificate',
                confirmText: 'general.download',
                headerText: 'assets-tab.download-certificate-header',
                showCloseBtn: true,
              })
              .then((dismiss) => dismiss && getAssetCertificat(asset.assetId));
          if (searchText) {
            setClearSearch(true);
            search('');
          } else if (!showArchived) {
            assetsService.setAssetTableDataToFirst(asset);
          }
        });
    });
  }, [searchText, showArchived]);

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'assets',
    },
    buttons: compact([
      {
        type: 'archive',
        onClick: () => setShowArchived(!showArchived),
        disabled: !hasPermission,
        text: showArchived ? 'assets-tab.show-active' : 'assets-tab.show-archived',
        color: 'rgb(135, 155, 183)',
      },
      getFlagStatus('assets.create-new-button') &&
        userOrgType === 'MACHINE_BUILDER' && {
          type: 'create',
          text: 'assets-tab.add-asset',
          onClick: openCreateAsset,
          disabled: !hasPermission,
        },
    ]),
  };

  return (
    <div className={classNames('max-height', styles.assetsTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <AssetsTable searchText={searchText} showArchived={showArchived} />
    </div>
  );
}

export default AssetsTab;
