import React from 'react';
import styles from './SingleAssetComponent.scss';
import InfoSection from './InfoSection/InfoSection';
import TagDataSection from './TagDataSection';
import ImageSection from './ImageSection';
import RemoteAccessSection from './RemoteAccessSection';
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { ComboWidgetCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { remoteAccessButtonsLabels } from '@pages/CreateWidgetPage/DataStepComponent/StepFour/CostumizationComponents/Combo/Combo.utils';

function SingleAssetComponent(props) {
  const {
    widgetData,
    assets,
    data,
    dataColumns,
    customization,
    isPreview,
    navigateDashboard,
    maxHeight,
  } = props;

  const {
    columns,
    remoteAccessButtons = {
      vpn: { order: 0, show: true, displayName: remoteAccessButtonsLabels.vpn },
      web: { order: 0, show: true, displayName: remoteAccessButtonsLabels.web },
      vnc: { order: 0, show: true, displayName: remoteAccessButtonsLabels.hmi },
    },
    remoteAccessButtonsVisible = true,
    imageVisible = true,
  } = (customization as ComboWidgetCustomization) || {};

  const dataToDisplay = customization?.columns?.length
    ? customization?.columns?.map((col) => {
        const dataColumn = dataColumns?.find(
          (dc) => dc.valueId === col.id && dc.valueType === col.valueType
        );
        return {
          valueId: col.id,
          valueType: col.valueType,
          format: dataColumn.format,
          dateTimeFormat: col?.dateTimeFormat,
          name: dataColumn.name,
          displayName: col?.displayName,
          value: data[dataColumn.name],
          decimalDigits: col?.decimalDigits,
          symbol: col?.symbol,
          showSymbolLeft: col?.showSymbolLeft,
        };
      })
    : dataColumns?.map((dc) => {
        return { ...dc, value: data[dc.name] };
      });

  const assetId = dataToDisplay?.find(
    (d) => d.valueType === 'ASSET_PROPERTY' && d.valueId === 1
  )?.value;

  const onNavigate = () => {
    if (!isPreview && navigateDashboard && widgetData.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: null,
        selectedColumn: props.data && props.dataColumns && props.dataColumns[0],
        widget: widgetData,
        columns: props.dataColumns,
        rawValues: props.data,
        navigateDashboard,
      });
    }
  };

  return (
    <div className={styles.wrapper} style={{ maxHeight: maxHeight }}>
      <div className={styles.sectionWrapper} style={{ borderTop: 'none' }} onClick={onNavigate}>
        <InfoSection dataToDisplay={dataToDisplay} customization={customization} />
      </div>
      {dataToDisplay?.length > 3 && (
        <div className={styles.sectionWrapper} onClick={onNavigate}>
          <TagDataSection
            widgetData={widgetData}
            dataToDisplay={dataToDisplay.filter(
              (d) => !(d.valueType === 'ASSET_PROPERTY' && [1, 2, 5].includes(d.valueId))
            )}
            customization={customization}
          />
        </div>
      )}
      {remoteAccessButtons &&
        ['vpn', 'web', 'vnc']
          .map((type) => remoteAccessButtons[type].show)
          ?.some((showFlag) => showFlag) &&
        remoteAccessButtonsVisible && (
          <div className={styles.sectionWrapper}>
            <RemoteAccessSection
              assetDetails={assets.find((asset) => asset.assetId === assetId)}
              customization={customization}
              isPreview={isPreview}
            />
          </div>
        )}
      {dataToDisplay && imageVisible && (
        <div className={styles.sectionWrapper} onClick={onNavigate}>
          <ImageSection
            imageUrl={assets?.find((asset) => asset.assetId === assetId)?.galleryImageUrl}
            customization={customization}
          />
        </div>
      )}
    </div>
  );
}

export default SingleAssetComponent;
