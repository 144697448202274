import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';

const enterpriseLicenseId = 5;
const update = async (
  secondsTimer: number,
  updateType: string,
  assetTypeId,
  onCancelEditClicked,
  excludeEnterprise: boolean,
  requiredPlan?
) => {
  try {
    if (requiredPlan) {
      await httpService.api({
        type: 'saveSubscriptionDefaultLicenseAssetType',
        urlParams: { assetTypeId },
        data: {
          licenceId: requiredPlan.licenceId,
          excludeManual: updateType === 'SAME',
          excludeEnterprise: excludeEnterprise,
        },
      });
    }
    const res = await httpService.api({
      type: 'saveSubscriptionAssetType',
      data: { updateType, tagsTimer: secondsTimer },
      urlParams: { assetTypeId },
    });
    if (res) {
      onCancelEditClicked(true);
    }
  } catch {}
};

const openModal = async (
  timer,
  assetTypeId,
  onCancelEditClicked,
  text,
  assetsCount,
  excludeEnterprise: boolean,
  requiredPlan?
) => {
  modalService.openConfirmWithMultiButtons(
    {
      text,
      iconType: 'attention_image',
      headerText: 'edit-asset-type-modal.subscription-tag-interval.update-required-plan-header',
      showCloseBtn: true,
      multiButtons: [
        {
          text: 'general.confirm',
          dismissModal: true,
          onClick: () =>
            update(timer, 'ALL', assetTypeId, onCancelEditClicked, excludeEnterprise, requiredPlan),
        },
        {
          text: 'general.exclude-manual-changes',
          dismissModal: true,
          onClick: () =>
            update(
              timer,
              'SAME',
              assetTypeId,
              onCancelEditClicked,
              excludeEnterprise,
              requiredPlan
            ),
        },
      ],
    },
    {
      newRequiredPlan: requiredPlan?.planName,
      assetsCount,
    }
  );
};

export const save = (timer, moment, assetTypeId, onCancelEditClicked, assetSubscription) => {
  const { tagsCount = 0, licensingPlans, defaultLicenceId, assetsCount } = assetSubscription || {};
  const secondsTimer = moment.duration(timer).asSeconds();
  const numOfSecondsinMonth = 31 * 24 * 60 * 60;
  const newTotalTagsPerMonth = Math.ceil((numOfSecondsinMonth / secondsTimer) * tagsCount);
  const requiredPlan = licensingPlans.find(
    (plan) => plan.totalTagsPerMonth >= newTotalTagsPerMonth
  );

  if (defaultLicenceId === enterpriseLicenseId && assetsCount) {
    openModal(
      secondsTimer,
      assetTypeId,
      onCancelEditClicked,
      'edit-asset-type-modal.subscription-tag-interval.update-required-plan-enterprise',
      assetsCount,
      true,
      requiredPlan
    );
  } else {
    const defaultLicenceData = licensingPlans.find((plan) => plan.licenceId === defaultLicenceId);
    if (
      requiredPlan &&
      defaultLicenceData?.totalTagsPerMonth < requiredPlan.totalTagsPerMonth &&
      assetsCount
    ) {
      openModal(
        secondsTimer,
        assetTypeId,
        onCancelEditClicked,
        'edit-asset-type-modal.subscription-tag-interval.update-required-plan',
        assetsCount,
        false,
        requiredPlan
      );
    } else {
      httpService
        .api({
          type: 'getTimersStatus',
          urlParams: { assetTypeId },
        })
        .then((res: any) => {
          if (res.allAssetTimersAsAssetType) {
            modalService
              .openConfirm({
                text: 'edit-asset-type-modal.all-as-asset-type',
                iconType: 'attention_image',
                confirmText: 'general.ok',
                cancelText: 'general.cancel',
              })
              .then((confirm) => {
                if (confirm) {
                  update(
                    secondsTimer,
                    'ALL',
                    assetTypeId,
                    onCancelEditClicked,
                    defaultLicenceData?.totalTagsPerMonth < requiredPlan?.totalTagsPerMonth &&
                      requiredPlan
                  );
                } else {
                  onCancelEditClicked(false);
                }
              });
          } else {
            modalService
              .openConfirmWithMultiButtons({
                text: 'edit-asset-type-modal.same-as-asset-type',
                iconType: 'attention_image',
                multiButtons: [
                  {
                    text: 'general.update-all',
                    dismissModal: true,
                    onClick: () =>
                      update(
                        secondsTimer,
                        'ALL',
                        assetTypeId,
                        onCancelEditClicked,
                        defaultLicenceData?.totalTagsPerMonth < requiredPlan?.totalTagsPerMonth &&
                          requiredPlan
                      ),
                  },
                  {
                    text: 'general.exclude-manual-changes',
                    dismissModal: true,
                    onClick: () =>
                      update(
                        secondsTimer,
                        'SAME',
                        assetTypeId,
                        onCancelEditClicked,
                        defaultLicenceData?.totalTagsPerMonth < requiredPlan?.totalTagsPerMonth &&
                          requiredPlan
                      ),
                  },
                ],
              })
              .then((confirm) => {
                onCancelEditClicked(false);
              });
          }
        });
    }
  }
};

// export const save = (timer, moment, assetTypeId, onCancelEditClicked) => {
//   const secondsTimer = moment.duration(timer).asSeconds();
//   httpService
//     .api({
//       type: 'getTimersStatus',
//       urlParams: { assetTypeId },
//     })
//     .then((res: any) => {
//       if (res.allAssetTimersAsAssetType) {
//         modalService
//           .openConfirm({
//             text: 'edit-asset-type-modal.all-as-asset-type',
//             iconType: 'attention_image',
//             confirmText: 'general.ok',
//             cancelText: 'general.cancel',
//           })
//           .then((confirm) => {
//             if (confirm) {
//               updateTimers(secondsTimer, 'ALL', assetTypeId, onCancelEditClicked);
//             } else {
//               onCancelEditClicked(false);
//             }
//           });
//       } else {
//         modalService
//           .openConfirmWithMultiButtons({
//             text: 'edit-asset-type-modal.same-as-asset-type',
//             iconType: 'attention_image',
//             multiButtons: [
//               {
//                 text: 'general.update-all',
//                 dismissModal: true,
//                 onClick: () => updateAll(timer, moment, assetTypeId, onCancelEditClicked),
//               },
//               {
//                 text: 'general.exclude-manual-changes',
//                 dismissModal: true,
//                 onClick: () =>
//                   excludeManualChanges(timer, moment, assetTypeId, onCancelEditClicked),
//               },
//             ],
//           })
//           .then((confirm) => {
//             onCancelEditClicked(false);
//           });
//       }
//     });
// };
