import React from 'react';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

// Components
import ColorInput from '@components/ColorInput';
import GaugeCustomizationField from '../GaugeCustomizationField';
import I18n from '@components/I18n';

// Services | Interfaces
import styles from './GaugeCustomizationSection.scss';
import { GaugeCustomizationSectionProps } from './GaugeCustomizationSection.interface';
import { hasValue } from '@core/canvas/widget.utils';

function GaugeCustomizationSection(props: GaugeCustomizationSectionProps) {
  const {
    sectionLabel,
    sectionValue,
    type,
    onChange,
    fields,
    styleType,
    calculationStyles,
    widgetData,
  } = props;

  const textField = (
    <TextField
      className={type === 'text' ? styles.textField : styles.numberField}
      value={hasValue(sectionValue) ? sectionValue : ''}
      margin="dense"
      variant="outlined"
      onChange={(e) => onChange(e.target.value)}
      type={type}
      inputProps={{
        style: { color: 'var(--systemFont)' },
      }}
    />
  );

  const colorField = (
    <ColorInput
      value={sectionValue}
      customeMarginButton={0}
      popOverPosition={{ left: 1 }}
      isParentRelative={false}
      colorChange={onChange}
    />
  );

  const fieldsMap = {
    text: textField,
    number: textField,
    color: colorField,
  };

  return (
    <div className={styles.section}>
      <div className={styles.headerWrapper}>
        <I18n className={styles.sectionLabel} element="div">
          {`gauge-customization.${sectionLabel}`}
        </I18n>
        {type && fieldsMap[type]}
      </div>
      <div className={classNames(styles.fields, styleType === 'row' && styles.fieldsRow)}>
        {fields.map((field, idx) => (
          <GaugeCustomizationField
            key={`${sectionLabel}_field_${idx}`}
            {...field}
            widgetData={widgetData}
            calculationStyles={calculationStyles}
          />
        ))}
      </div>
    </div>
  );
}

export default GaugeCustomizationSection;
