import React, { useEffect, useState } from 'react';
import styles from './VariablesTable.scss';
import { dynamicSort } from '@core/utils';
import { TagMeta } from '@core/http/server.interface';
import Table from '@components/Table';
import { getColunmsCfg, getVariables } from './VariablesTable.utils';
import { PAGE_SIZE } from '../Variables.utils';

function VariablesTable({
  variablesList,
  setVariablesList,
  origin,
  selectedOrganizationsId = null,
  isMB = false,
  editedVariableId,
  setEditedVariableId,
  onRowClicked = null,
  searchText = null,
  apiType = '',
  setResultsCount = null,
  setTotalCount = null,
  assetTypeOrAssetId = null,
  disabledLoad = false,
  setDisableLoad = null,
  ...restProps
}) {
  const [filteredData, setFilteredData] = useState([]);
  const { machineBuilderId } = restProps;
  const [editedAssetId, setEditedAssetId] = useState();
  const [nextPage, setNextPage] = useState(1);
  const [_order, setOrder] = useState(null);
  const orderData = (order?: any): TagMeta[] => {
    return variablesList.sort(dynamicSort(order.label, order.orderSign));
  };

  useEffect(() => {
    if (origin === 'organizationAssets' && variablesList) {
      if (isMB) {
        setFilteredData(variablesList.filter((variable) => variable.managementLevel !== 'GLOBAL'));
      } else {
        setFilteredData(
          variablesList.filter((variable) => variable.managementLevel === 'LOCAL_ORGANIZATION')
        );
      }
      return;
    }
    setFilteredData(variablesList);
  }, [variablesList]);

  function getgetIsRowClickable(rowData, rowIndex) {
    return rowData.id !== editedVariableId;
  }

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={true}
        data={filteredData}
        onRowClicked={(rowData, rowIndex) => {
          if (rowData.id === editedVariableId) {
            return null;
          } else {
            return onRowClicked(rowData, rowIndex);
          }
        }}
        getIsRowClickable={getgetIsRowClickable}
        disabledLoad={disabledLoad}
        getData={
          origin === 'organizationAssetTypes' || origin === 'organizationAssets'
            ? (firstInit, order) => {
                const checkedOrder =
                  order?.label === 'assetTypeName'
                    ? { label: 'assetTypeName', orderSign: order?.orderSign }
                    : order;
                order && setOrder(checkedOrder);
                getVariables(
                  machineBuilderId,
                  nextPage,
                  setNextPage,
                  setResultsCount,
                  setTotalCount,
                  apiType,
                  searchText,
                  PAGE_SIZE,
                  setDisableLoad,
                  firstInit,
                  setVariablesList,
                  variablesList,
                  checkedOrder
                );
              }
            : (firstInit, order) => {
                order && setOrder(order);
                orderData(order);
              }
        }
        colunmsCfg={getColunmsCfg(
          origin,
          _order,
          selectedOrganizationsId,
          setVariablesList,
          editedVariableId,
          setEditedVariableId,
          editedAssetId,
          setEditedAssetId,
          assetTypeOrAssetId
            ? assetTypeOrAssetId
            : origin === 'organizationAssets'
            ? editedAssetId
            : variablesList.find((variable) => variable.id === editedVariableId)?.assetTypeId
        )?.filter((column) => column.isVisible)}
      />
    </div>
  );
}

export default React.memo(VariablesTable);
