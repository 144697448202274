import React, { useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import TextField from '@components/Table/TableFields/TextField';
import AssignButton from './AssignButton';
import Table from '@components/Table';
import { makeStyles } from '@material-ui/core';
import SearchInput from '@pages/DeviceManagment/Assets/SearchInput';
import { dynamicSort } from '@core/utils';

const useStyles = makeStyles(() => ({
  searchRow: {
    marginTop: 12,
    marginBottom: 23,
    width: 269,
  },
  tableSection: {
    height: 'calc(100% - 133px)',
    backgroundColor: 'var(--systemContentBackground)',
    boxShadow: 'var(--boxShadowGrey)',
    paddingRight: 18,
    paddingLeft: 25,
    borderRadius: 8,
  },
}));

const SubscriptionTable = (props) => {
  const { subscriptions, assetName, assetId, dismiss } = props;
  const [searchResults, setSearchResults] = useState(cloneDeep(subscriptions));
  const [sortByData, setSortByData] = useState();

  const classes = useStyles(props);

  const columnConfig = useMemo(
    () => [
      {
        Component: TextField,
        label: 'add-subscription-to-asset-modal.table.catalog-number',
        field: 'catalogNumber',
        style: { width: '25%' },
        isSortable: true,
      },
      {
        Component: TextField,
        label: 'add-subscription-to-asset-modal.table.description',
        field: 'description',
        style: { width: '30%' },
        isSortable: true,
      },
      {
        Component: TextField,
        label: 'add-subscription-to-asset-modal.table.quantity',
        field: 'quantity',
        style: { width: '20%' },
        isSortable: true,
      },
      {
        Component: AssignButton,
        type: 'staticEnd',
        label: '',
        field: 'assign',
        style: { width: 108 },
        props: { assetName, assetId, dismiss },
      },
    ],
    []
  );

  const orderData = (order?: any, data?) => {
    let tempLabel = order?.label;
    if (tempLabel === 'description') {
      tempLabel = 'order';
    }
    setSearchResults((data || searchResults).sort(dynamicSort(tempLabel, order?.orderSign)));
  };

  const onSearch = (searchText: string) => {
    const results = searchText
      ? subscriptions.filter(
          (s) =>
            s.catalogNumber.toLowerCase().includes(searchText.toLowerCase()) ||
            s.description.toLowerCase().includes(searchText.toLowerCase())
        )
      : cloneDeep(subscriptions);
    orderData(sortByData, results);
  };

  return (
    <>
      <div className={classes.searchRow}>
        <SearchInput
          totalCount={subscriptions.length}
          pageName="subscriptions"
          resultsCount={searchResults.length}
          searchFunc={onSearch}
          clearSearch={true}
          minCharsToSearch={1}
        />
      </div>
      <div className={classes.tableSection}>
        <Table
          data={searchResults}
          getData={(firstInit, order) => {
            order && setSortByData(order);
            orderData(order);
          }}
          colunmsCfg={columnConfig}
          disabledLoad={true}
        />
      </div>
    </>
  );
};

export default SubscriptionTable;
