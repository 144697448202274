import React from 'react';

import styles from '../WidgetSideBar.scss';
import I18n from '@components/I18n';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import {
  getConfigValueFromWidgetSettings,
  getIsIncludeInSettings,
} from '@core/canvas/widget.utils';

const StepThreeText = ({ displayData }) => {
  const languageId = useSelector((state) => state.config.languageId);
  const { widgetData } = displayData;
  const notDisplaySorting =
    getConfigValueFromWidgetSettings(widgetData.type, 'accordionDisable') &&
    getIsIncludeInSettings(widgetData.type, 'accordionDisable', 'sort');
  return (
    <>
      {!displayData.groupBy.length &&
      !displayData.metrics.length &&
      !displayData.filters.length &&
      !displayData.sorts.length ? (
        <div
          className={styles.textBox}
          title={i18nService.translate('widget-side-bar.step-three.default-text', languageId)}>
          <I18n noEllipsis>widget-side-bar.step-three.default-text</I18n>
        </div>
      ) : (
        <div className={styles.activeText}>
          {displayData.groupBy.length > 0 && (
            <div
              className="ellipsis-overflow"
              title={displayData.groupBy.join(', ')}>{`${i18nService.translate(
              'widget-side-bar.step-three.group-by',
              languageId
            )} (${displayData.groupBy.length}): ${displayData.groupBy.join(', ')}`}</div>
          )}
          {displayData.metrics.length > 0 && (
            <div
              className="ellipsis-overflow"
              title={displayData.metrics.join(', ')}>{`${i18nService.translate(
              'widget-side-bar.step-three.data-and-metrics',
              languageId
            )} (${displayData.metrics.length}): ${displayData.metrics.join(', ')}`}</div>
          )}
          {displayData.filters.length > 0 && (
            <div
              className="ellipsis-overflow"
              title={displayData.filters.join(', ')}>{`${i18nService.translate(
              'widget-side-bar.step-three.filter',
              languageId
            )} (${displayData.filters.length}): ${displayData.filters.join(', ')}`}</div>
          )}
          {displayData.sorts.length > 0 && !notDisplaySorting && (
            <div
              className="ellipsis-overflow"
              title={displayData.sorts.join(', ')}>{`${i18nService.translate(
              'widget-side-bar.step-three.sort',
              languageId
            )} (${displayData.sorts.length}): ${displayData.sorts.join(', ')}`}</div>
          )}
        </div>
      )}
    </>
  );
};

export default StepThreeText;
