import { ViewportState, StoreAction } from './redux.interface';

export const setisMobileView = (payload): StoreAction => ({
  type: 'viewport/SET_IS_MOBILE',
  payload,
});

export const setisPDFRender = (payload): StoreAction => ({
  type: 'viewport/SET_IS_PDF_RENDER',
  payload,
});

export const setisPDFRenderReady = (payload): StoreAction => ({
  type: 'viewport/SET_IS_PDF_RENDER_READY',
  payload,
});

const initialState: ViewportState = {
  isMobileView: false,
  isPdfRender: false,
  isPdfRenderReady: false,
};

export default function viewportReducer(state: ViewportState = initialState, action: StoreAction) {
  if (
    action.type == 'viewport/SET_IS_MOBILE' ||
    action.type == 'viewport/SET_IS_PDF_RENDER' ||
    action.type === 'viewport/SET_IS_PDF_RENDER_READY'
  ) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
}
