import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './DashboardInUseModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import {
  colunmsCfgDefaultDashboard,
  colunmsCfgNavigation,
  colunmsCfgEvents,
  colunmsCfgDefaultForAccounts,
} from './DashboardInUseModal.utils';
import AccordionWithTable from '@components/AccordionWithTable/AccordionWithTable';

function DashboardInUseModal(props) {
  const organizationDetails = useSelector((state) => state.config.organizationDetails);

  const { args, dismiss } = props;
  const { dashboardName, usedAsDefaultDashboard, usedInEvent, navigateFrom, defaultForAccounts } =
    args;
  const [data, setData] = useState<Array<{ title: string; config: any; data: any[] }>>([]);

  const confirmBtn = useRef(null);
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  useEffect(() => {
    confirmBtn.current.focus();
  }, []);

  const defaultDashboard = useMemo(() => {
    if (usedAsDefaultDashboard?.specificOrg.length || usedAsDefaultDashboard?.orgTypes.length) {
      const orgTypes = usedAsDefaultDashboard?.orgTypes.reduce(
        (prev, cur) => [
          ...prev,
          {
            organizationTypeName: `${i18nService.translate(`enum.${cur}`)} - ${
              organizationDetails.name
            }`,
            details: `${i18nService.translate(`enum.${cur}`)}`,
          },
        ],
        []
      );

      const specificOrg = usedAsDefaultDashboard?.specificOrg
        .reduce(
          (prev, cur) => [
            ...prev,
            usedAsDefaultDashboard?.orgTypes.some((orgType) => {
              orgType === cur.orgType;
            })
              ? null
              : {
                  organizationTypeName: `${i18nService.translate(`enum.${cur.orgType}`)} - ${
                    cur.name
                  }`,
                },
          ],
          []
        )
        .sort((a, b) => (a.organizationTypeName > b.organizationTypeName ? 1 : -1));

      return [...orgTypes, ...specificOrg];
    } else return [];
  }, []);

  const navigation = useMemo(() => {
    let navigation = [];

    if (navigateFrom.length) {
      navigation = navigateFrom
        .sort((a, b) =>
          a.dashboardName === b.dashboardName && a.widgetName > b.widgetName ? -1 : 1
        )
        .sort((a, b) => (a.dashboardName > b.dashboardName ? 1 : -1))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              dashboardName: `${cur.dashboardName}`,
              widgetName: `${cur.widgetName}`,
              dashboardStatus: `${i18nService.translate(`enum.${cur.status}`)}`,
            },
          ],
          []
        );
    }
    return navigation;
  }, []);

  const defaultDashboardForAccounts = useMemo(() => {
    let defaultDashboardForAccounts = [];

    if (defaultForAccounts.length) {
      defaultDashboardForAccounts = defaultForAccounts
        .sort((a, b) =>
          a.organizationType === b.organizationType && a.organizationName > b.organizationName
            ? -1
            : 1
        )
        .sort((a, b) => (a.organizationTypeName > b.organizationTypeName ? 1 : -1))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              organizationTypeName: `${i18nService.translate(`enum.${cur.organizationType}`)} - ${
                cur.organizationName
              }`,
              userFullName: cur.userFullName,
            },
          ],
          []
        );
    }
    return defaultDashboardForAccounts;
  }, []);

  const events = useMemo(() => {
    let events = [];

    if (usedInEvent.length > 0) {
      events = usedInEvent
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              templateName: `${cur.name}`,
              status: `${i18nService.translate(`enum.${cur.status}`)}`,
            },
          ],
          []
        );
    }
    return events;
  }, []);

  useEffect(() => {
    setData([
      {
        title: 'dashboard-gallery.delete-dashboard.default-dashboard',
        config: colunmsCfgDefaultDashboard,
        data: defaultDashboard,
      },
      {
        title: 'dashboard-gallery.delete-dashboard.default-for-accounts',
        config: colunmsCfgDefaultForAccounts,
        data: defaultDashboardForAccounts,
      },
      {
        title: 'dashboard-gallery.delete-dashboard.widgets-dashboard-navigation',
        config: colunmsCfgNavigation,
        data: navigation,
      },
      {
        title: 'dashboard-gallery.delete-dashboard.events–pdf-report',
        config: colunmsCfgEvents,
        data: events,
      },
    ]);
  }, [defaultDashboard, defaultDashboardForAccounts, navigation, events]);

  return (
    <div className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}>
      <div className={styles.modalHeader}>
        <I18n className={styles.modalHeader} element="div">
          dashboard-gallery.delete-dashboard.dashboard-in-use-header
        </I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n className={styles.dashboardNameTitle} style={{ marginRight: '10px' }}>
          dashboard-gallery.delete-dashboard.dashboard-name-title
        </I18n>
        {dashboardName}
        <div className={styles.tableWrapper}>
          {data.length
            ? data.map((item) =>
                item?.data?.length ? (
                  <AccordionWithTable
                    title={item.title}
                    columnsCfg={item.config}
                    tableData={item.data}
                  />
                ) : null
              )
            : null}
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={close} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </div>
  );
}

export default DashboardInUseModal;
