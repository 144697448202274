import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './PreviewSmsMessageModal.scss';
import Icon from '@components/Icon';
import Button from '@components/Button';
import {
  convertLocalHtmlToPlainTextWithMentions,
  getHtmlLength,
} from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/RichTextEditor.utils';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import { saveAs } from 'file-saver';
import classNames from 'classnames';

const useStyle = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflowY: 'auto',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
}));

const mentionsRegex =
  /<a href=\"datasource:\/\/(\d+)\" class=\"wysiwyg-mention\" data-mention data-value=\"(.*?)\">.*?<\/a>/g;

const hyperLinkRegex = /<a href=\"(.*?)\" target=\"_self\">(.*?)<\/a>/g;

function PreviewSmsMessageModal(props) {
  const { dismiss, args } = props;
  const { templateData, displaySettingsData, tableDataSource } = args;
  const { body, dashboardReport } = templateData;
  const languageId = useSelector((state) => state.config.languageId);
  const classes = useStyle(props);

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const fixHyperlinksAndMentions = (html) => {
    let res = html.replace(
      mentionsRegex,
      '<a href="datasource://$1" class="wysiwyg-mention" data-mention data-value="$2" style="pointer-events: none;">#$2</a>'
    );

    res = res.replace(hyperLinkRegex, '<a href="$1" target="_blank">$2</a>');

    return res;
  };

  const delimiter = useMemo(
    () => displaySettingsData?.regionalSettings?.content?.delimiter || ',',
    []
  );

  const adjustedBody = useMemo(() => {
    return convertLocalHtmlToPlainTextWithMentions(fixHyperlinksAndMentions(body), true, true);
  }, [body]);

  const downloadDataSource = useCallback((tableDataSource) => {
    let csv = '';
    const { customization } = tableDataSource || {};
    const { columns } = customization || {};
    for (let col = 0; col < columns.length; col++) {
      csv = csv
        .concat(columns[col].displayName)
        .concat(col < columns.length - 1 ? delimiter : '\r\n');
    }

    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < columns.length; col++) {
        csv = csv.concat('Value').concat(col < columns.length - 1 ? delimiter : '\r\n');
      }
    }

    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${tableDataSource.name}.csv`);
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n>events-template.create-message.preview-message.sms.modal-header</I18n>
        <Icon type="close" onClick={props.dismiss} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={classNames(styles.row, styles.smsBody)}>
            <div
              className={styles.bodyContent}
              dangerouslySetInnerHTML={{ __html: adjustedBody }}
            />
          </div>
          {tableDataSource && (
            <div className={styles.row}>
              <a href="#" onClick={() => downloadDataSource(tableDataSource)}>
                {i18nService.translate(
                  'events-template.create-message.preview-message.modal.download-data-source',
                  languageId,
                  { dataSourceName: tableDataSource.name }
                )}
              </a>
            </div>
          )}
          {dashboardReport && dashboardReport.refId && (
            <div className={styles.row}>
              <a href="#">
                {i18nService.translate(
                  'events-template.create-message.preview-message.modal.download-data-source',
                  languageId,
                  { dataSourceName: dashboardReport.name }
                )}
              </a>
            </div>
          )}
        </div>
        <div className={styles.modalButtons}>
          <Button mode="bigFont" onClick={cancel}>
            <I18n>general.close</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PreviewSmsMessageModal;
