import React from 'react';
import { AlarmsMeta } from '@redux/redux.interface.d';
import moment from 'moment';
import { buildDateTime } from '@components/widgets/charts.utils';

function AlarmsTime(props: AlarmsMeta) {
  const { createdAt } = props;
  const createdDate = buildDateTime(createdAt, 'DATE_TIME_FORMAT_ONE', 'momentFormat');
  return <div title={createdDate}>{createdDate}</div>;
}

export default AlarmsTime;
