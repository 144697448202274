import { Types } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import { i18nService } from '@core/i18n/I18nService';

const InitialConfig = MaterialConfig;

export const types: Types = {
  // ...InitialConfig.types,
  number: {
    ...InitialConfig.types.number,
    defaultOperator: 'equal',
    widgets: {
      number: {
        operators: [
          'equal',
          'not_equal',
          'less',
          'less_or_equal',
          'greater',
          'greater_or_equal',
          'between',
          'not_between',
          'is_null',
          'is_not_null',
        ],
      },
      func: {
        operators: [
          'equal',
          'not_equal',
          'less',
          'less_or_equal',
          'greater',
          'greater_or_equal',
          'between',
          'not_between',
        ],
      },
    },
  },
  text: {
    ...InitialConfig.types.text,
    defaultOperator: 'equal',
    widgets: {
      text: {
        operators: ['equal', 'not_equal', 'starts_with', 'ends_with', 'is_null', 'is_not_null'],
      },
    },
  },
  boolean: {
    ...InitialConfig.types.boolean,
    defaultOperator: 'equal',
    widgets: {
      boolean: {
        operators: ['equal', 'not_equal', 'is_null', 'is_not_null'],
      },
    },
  },
  select: {
    ...InitialConfig.types.select,
    defaultOperator: 'equal',
    widgets: {
      select: {
        operators: ['equal', 'not_equal', 'is_null', 'is_not_null'],
      },
    },
  },
  datetime: {
    ...InitialConfig.types.datetime,
    defaultOperator: 'equal',
    widgets: {
      datetime: {
        operators: [
          'equal',
          'not_equal',
          'less',
          'less_or_equal',
          'greater',
          'greater_or_equal',
          'is_null',
          'is_not_null',
        ],
      },
    },
  },
};
