export const options = [
  {
    key: 0,
    locatione: 'Pacific/Niue',
    city: 'Niue',
    gmtValue: 'GMT-11:00',
    display: '(GMT-11:00) Niue'
  },
  {
    key: 1,
    locatione: 'Pacific/Pago_Pago',
    city: 'Pago Pago',
    gmtValue: 'GMT-11:00',
    display: '(GMT-11:00) Pago Pago'
  },
  {
    key: 2,
    locatione: 'Pacific/Honolulu',
    city: 'Hawaii Time',
    gmtValue: 'GMT-10:00',
    display: '(GMT-10:00) Hawaii Time'
  },
  {
    key: 3,
    locatione: 'Pacific/Rarotonga',
    city: 'Rarotonga',
    gmtValue: 'GMT-10:00',
    display: '(GMT-10:00) Rarotonga'
  },
  {
    key: 4,
    locatione: 'Pacific/Tahiti',
    city: 'Tahiti',
    gmtValue: 'GMT-10:00',
    display: '(GMT-10:00) Tahiti'
  },
  {
    key: 5,
    locatione: 'Pacific/Marquesas',
    city: 'Marquesas',
    gmtValue: 'GMT-09:30',
    display: '(GMT-09:30) Marquesas'
  },
  {
    key: 6,
    locatione: 'America/Anchorage',
    city: 'Alaska Time',
    gmtValue: 'GMT-09:00',
    display: '(GMT-09:00) Alaska Time'
  },
  {
    key: 7,
    locatione: 'Pacific/Gambier',
    city: 'Gambier',
    gmtValue: 'GMT-09:00',
    display: '(GMT-09:00) Gambier'
  },
  {
    key: 8,
    locatione: 'America/Los_Angeles',
    city: 'Pacific Time',
    gmtValue: 'GMT-08:00',
    display: '(GMT-08:00) Pacific Time'
  },
  {
    key: 9,
    locatione: 'America/Tijuana',
    city: 'Pacific Time - Tijuana',
    gmtValue: 'GMT-08:00',
    display: '(GMT-08:00) Pacific Time - Tijuana'
  },
  {
    key: 10,
    locatione: 'America/Vancouver',
    city: 'Pacific Time - Vancouver',
    gmtValue: 'GMT-08:00',
    display: '(GMT-08:00) Pacific Time - Vancouver'
  },
  {
    key: 11,
    locatione: 'America/Whitehorse',
    city: 'Pacific Time - Whitehorse',
    gmtValue: 'GMT-08:00',
    display: '(GMT-08:00) Pacific Time - Whitehorse'
  },
  {
    key: 12,
    locatione: 'Pacific/Pitcairn',
    city: 'Pitcairn',
    gmtValue: 'GMT-08:00',
    display: '(GMT-08:00) Pitcairn'
  },
  {
    key: 13,
    locatione: 'America/Dawson_Creek',
    city: 'Mountain Time - Dawson Creek',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time - Dawson Creek'
  },
  {
    key: 14,
    locatione: 'America/Denver',
    city: 'Mountain Time',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time'
  },
  {
    key: 15,
    locatione: 'America/Edmonton',
    city: 'Mountain Time - Edmonton',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time - Edmonton'
  },
  {
    key: 16,
    locatione: 'America/Hermosillo',
    city: 'Mountain Time - Hermosillo',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time - Hermosillo'
  },
  {
    key: 17,
    locatione: 'America/Mazatlan',
    city: 'Mountain Time - Chihuahua, Mazatlan',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan'
  },
  {
    key: 18,
    locatione: 'America/Phoenix',
    city: 'Mountain Time - Arizona',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time - Arizona'
  },
  {
    key: 19,
    locatione: 'America/Yellowknife',
    city: 'Mountain Time - Yellowknife',
    gmtValue: 'GMT-07:00',
    display: '(GMT-07:00) Mountain Time - Yellowknife'
  },
  {
    key: 20,
    locatione: 'America/Belize',
    city: 'Belize',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Belize'
  },
  {
    key: 21,
    locatione: 'America/Chicago',
    city: 'Central Time',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Central Time'
  },
  {
    key: 22,
    locatione: 'America/Costa_Rica',
    city: 'Costa Rica',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Costa Rica'
  },
  {
    key: 23,
    locatione: 'America/El_Salvador',
    city: 'El Salvador',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) El Salvador'
  },
  {
    key: 24,
    locatione: 'America/Guatemala',
    city: 'Guatemala',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Guatemala'
  },
  {
    key: 25,
    locatione: 'America/Managua',
    city: 'Managua',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Managua'
  },
  {
    key: 26,
    locatione: 'America/Mexico_City',
    city: 'Central Time - Mexico City',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Central Time - Mexico City'
  },
  {
    key: 27,
    locatione: 'America/Regina',
    city: 'Central Time - Regina',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Central Time - Regina'
  },
  {
    key: 28,
    locatione: 'America/Tegucigalpa',
    city: 'Central Time - Tegucigalpa',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Central Time - Tegucigalpa'
  },
  {
    key: 29,
    locatione: 'America/Winnipeg',
    city: 'Central Time - Winnipeg',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Central Time - Winnipeg'
  },
  {
    key: 30,
    locatione: 'Pacific/Galapagos',
    city: 'Galapagos',
    gmtValue: 'GMT-06:00',
    display: '(GMT-06:00) Galapagos'
  },
  {
    key: 31,
    locatione: 'America/Bogota',
    city: 'Bogota',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Bogota'
  },
  {
    key: 32,
    locatione: 'America/Cancun',
    city: 'America Cancun',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) America Cancun'
  },
  {
    key: 33,
    locatione: 'America/Cayman',
    city: 'Cayman',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Cayman'
  },
  {
    key: 34,
    locatione: 'America/Guayaquil',
    city: 'Guayaquil',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Guayaquil'
  },
  {
    key: 35,
    locatione: 'America/Havana',
    city: 'Havana',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Havana'
  },
  {
    key: 36,
    locatione: 'America/Iqaluit',
    city: 'Eastern Time - Iqaluit',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Eastern Time - Iqaluit'
  },
  {
    key: 37,
    locatione: 'America/Jamaica',
    city: 'Jamaica',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Jamaica'
  },
  {
    key: 38,
    locatione: 'America/Lima',
    city: 'Lima',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Lima'
  },
  {
    key: 39,
    locatione: 'America/Nassau',
    city: 'Nassau',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Nassau'
  },
  {
    key: 40,
    locatione: 'America/New_York',
    city: 'Eastern Time',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Eastern Time'
  },
  {
    key: 41,
    locatione: 'America/Panama',
    city: 'Panama',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Panama'
  },
  {
    key: 42,
    locatione: 'America/Port-au-Prince',
    city: 'Port-au-Prince',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Port-au-Prince'
  },
  {
    key: 43,
    locatione: 'America/Rio_Branco',
    city: 'Rio Branco',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Rio Branco'
  },
  {
    key: 44,
    locatione: 'America/Toronto',
    city: 'Eastern Time - Toronto',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Eastern Time - Toronto'
  },
  {
    key: 45,
    locatione: 'Pacific/Easter',
    city: 'Easter Island',
    gmtValue: 'GMT-05:00',
    display: '(GMT-05:00) Easter Island'
  },
  {
    key: 46,
    locatione: 'America/Caracas',
    city: 'Caracas',
    gmtValue: 'GMT-04:30',
    display: '(GMT-04:30) Caracas'
  },
  {
    key: 47,
    locatione: 'America/Asuncion',
    city: 'Asuncion',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Asuncion'
  },
  {
    key: 48,
    locatione: 'America/Barbados',
    city: 'Barbados',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Barbados'
  },
  {
    key: 49,
    locatione: 'America/Boa_Vista',
    city: 'Boa Vista',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Boa Vista'
  },
  {
    key: 50,
    locatione: 'America/Campo_Grande',
    city: 'Campo Grande',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Campo Grande'
  },
  {
    key: 51,
    locatione: 'America/Cuiaba',
    city: 'Cuiaba',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Cuiaba'
  },
  {
    key: 52,
    locatione: 'America/Curacao',
    city: 'Curacao',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Curacao'
  },
  {
    key: 53,
    locatione: 'America/Grand_Turk',
    city: 'Grand Turk',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Grand Turk'
  },
  {
    key: 54,
    locatione: 'America/Guyana',
    city: 'Guyana',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Guyana'
  },
  {
    key: 55,
    locatione: 'America/Halifax',
    city: 'Atlantic Time - Halifax',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Atlantic Time - Halifax'
  },
  {
    key: 56,
    locatione: 'America/La_Paz',
    city: 'La Paz',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) La Paz'
  },
  {
    key: 57,
    locatione: 'America/Manaus',
    city: 'Manaus',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Manaus'
  },
  {
    key: 58,
    locatione: 'America/Martinique',
    city: 'Martinique',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Martinique'
  },
  {
    key: 59,
    locatione: 'America/Port_of_Spain',
    city: 'Port of Spain',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Port of Spain'
  },
  {
    key: 60,
    locatione: 'America/Porto_Velho',
    city: 'Porto Velho',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Porto Velho'
  },
  {
    key: 61,
    locatione: 'America/Puerto_Rico',
    city: 'Puerto Rico',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Puerto Rico'
  },
  {
    key: 62,
    locatione: 'America/Santo_Domingo',
    city: 'Santo Domingo',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Santo Domingo'
  },
  {
    key: 63,
    locatione: 'America/Thule',
    city: 'Thule',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Thule'
  },
  {
    key: 64,
    locatione: 'Atlantic/Bermuda',
    city: 'Bermuda',
    gmtValue: 'GMT-04:00',
    display: '(GMT-04:00) Bermuda'
  },
  {
    key: 65,
    locatione: 'America/St_Johns',
    city: 'Newfoundland Time - St. Johns',
    gmtValue: 'GMT-03:30',
    display: '(GMT-03:30) Newfoundland Time - St. Johns'
  },
  {
    key: 66,
    locatione: 'America/Araguaina',
    city: 'Araguaina',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Araguaina'
  },
  {
    key: 67,
    locatione: 'America/Argentina/Buenos_Aires',
    city: 'Buenos Aires',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Buenos Aires'
  },
  {
    key: 68,
    locatione: 'America/Bahia',
    city: 'Salvador',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Salvador'
  },
  {
    key: 69,
    locatione: 'America/Belem',
    city: 'Belem',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Belem'
  },
  {
    key: 70,
    locatione: 'America/Cayenne',
    city: 'Cayenne',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Cayenne'
  },
  {
    key: 71,
    locatione: 'America/Fortaleza',
    city: 'Fortaleza',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Fortaleza'
  },
  {
    key: 72,
    locatione: 'America/Godthab',
    city: 'Godthab',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Godthab'
  },
  {
    key: 73,
    locatione: 'America/Maceio',
    city: 'Maceio',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Maceio'
  },
  {
    key: 74,
    locatione: 'America/Miquelon',
    city: 'Miquelon',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Miquelon'
  },
  {
    key: 75,
    locatione: 'America/Montevideo',
    city: 'Montevideo',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Montevideo'
  },
  {
    key: 76,
    locatione: 'America/Paramaribo',
    city: 'Paramaribo',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Paramaribo'
  },
  {
    key: 77,
    locatione: 'America/Recife',
    city: 'Recife',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Recife'
  },
  {
    key: 78,
    locatione: 'America/Santiago',
    city: 'Santiago',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Santiago'
  },
  {
    key: 79,
    locatione: 'America/Sao_Paulo',
    city: 'Sao Paulo',
    gmtValue: 'GMT-02:00',
    display: '(GMT-02:00) Sao Paulo'
  },
  {
    key: 80,
    locatione: 'Antarctica/Palmer',
    city: 'Palmer',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Palmer'
  },
  {
    key: 81,
    locatione: 'Antarctica/Rothera',
    city: 'Rothera',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Rothera'
  },
  {
    key: 82,
    locatione: 'Atlantic/Stanley',
    city: 'Stanley',
    gmtValue: 'GMT-03:00',
    display: '(GMT-03:00) Stanley'
  },
  {
    key: 83,
    locatione: 'America/Noronha',
    city: 'Noronha',
    gmtValue: 'GMT-02:00',
    display: '(GMT-02:00) Noronha'
  },
  {
    key: 84,
    locatione: 'Atlantic/South_Georgia',
    city: 'South Georgia',
    gmtValue: 'GMT-02:00',
    display: '(GMT-02:00) South Georgia'
  },
  {
    key: 85,
    locatione: 'America/Scoresbysund',
    city: 'Scoresbysund',
    gmtValue: 'GMT-01:00',
    display: '(GMT-01:00) Scoresbysund'
  },
  {
    key: 86,
    locatione: 'Atlantic/Azores',
    city: 'Azores',
    gmtValue: 'GMT-01:00',
    display: '(GMT-01:00) Azores'
  },
  {
    key: 87,
    locatione: 'Atlantic/Cape_Verde',
    city: 'Cape Verde',
    gmtValue: 'GMT-01:00',
    display: '(GMT-01:00) Cape Verde'
  },
  {
    key: 88,
    locatione: 'Africa/Abidjan',
    city: 'Abidjan',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Abidjan'
  },
  {
    key: 89,
    locatione: 'Africa/Accra',
    city: 'Accra',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Accra'
  },
  {
    key: 90,
    locatione: 'Africa/Bissau',
    city: 'Bissau',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Bissau'
  },
  {
    key: 91,
    locatione: 'Africa/Casablanca',
    city: 'Casablanca',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Casablanca'
  },
  {
    key: 92,
    locatione: 'Africa/El_Aaiun',
    city: 'El Aaiun',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) El Aaiun'
  },
  {
    key: 93,
    locatione: 'Africa/Monrovia',
    city: 'Monrovia',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Monrovia'
  },
  {
    key: 94,
    locatione: 'America/Danmarkshavn',
    city: 'Danmarkshavn',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Danmarkshavn'
  },
  {
    key: 95,
    locatione: 'Atlantic/Canary',
    city: 'Canary Islands',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Canary Islands'
  },
  {
    key: 96,
    locatione: 'Atlantic/Faroe',
    city: 'Faeroe',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Faeroe'
  },
  {
    key: 97,
    locatione: 'Atlantic/Reykjavik',
    city: 'Reykjavik',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Reykjavik'
  },
  {
    key: 98,
    locatione: 'Etc/GMT',
    city: 'GMT (no daylight saving)',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) GMT (no daylight saving)'
  },
  {
    key: 99,
    locatione: 'Europe/Dublin',
    city: 'Dublin',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Dublin'
  },
  {
    key: 100,
    locatione: 'Europe/Lisbon',
    city: 'Lisbon',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) Lisbon'
  },
  {
    key: 101,
    locatione: 'Europe/London',
    city: 'London',
    gmtValue: 'GMT+00:00',
    display: '(GMT+00:00) London'
  },
  {
    key: 102,
    locatione: 'Africa/Algiers',
    city: 'Algiers',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Algiers'
  },
  {
    key: 103,
    locatione: 'Africa/Ceuta',
    city: 'Ceuta',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Ceuta'
  },
  {
    key: 104,
    locatione: 'Africa/Lagos',
    city: 'Lagos',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Lagos'
  },
  {
    key: 105,
    locatione: 'Africa/Ndjamena',
    city: 'Ndjamena',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Ndjamena'
  },
  {
    key: 106,
    locatione: 'Africa/Tunis',
    city: 'Tunis',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Tunis'
  },
  {
    key: 107,
    locatione: 'Africa/Windhoek',
    city: 'Windhoek',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Windhoek'
  },
  {
    key: 108,
    locatione: 'Europe/Amsterdam',
    city: 'Amsterdam',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Amsterdam'
  },
  {
    key: 109,
    locatione: 'Europe/Andorra',
    city: 'Andorra',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Andorra'
  },
  {
    key: 110,
    locatione: 'Europe/Belgrade',
    city: 'Central European Time - Belgrade',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Central European Time - Belgrade'
  },
  {
    key: 111,
    locatione: 'Europe/Berlin',
    city: 'Berlin',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Berlin'
  },
  {
    key: 112,
    locatione: 'Europe/Brussels',
    city: 'Brussels',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Brussels'
  },
  {
    key: 113,
    locatione: 'Europe/Budapest',
    city: 'Budapest',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Budapest'
  },
  {
    key: 114,
    locatione: 'Europe/Copenhagen',
    city: 'Copenhagen',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Copenhagen'
  },
  {
    key: 115,
    locatione: 'Europe/Gibraltar',
    city: 'Gibraltar',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Gibraltar'
  },
  {
    key: 116,
    locatione: 'Europe/Luxembourg',
    city: 'Luxembourg',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Luxembourg'
  },
  {
    key: 117,
    locatione: 'Europe/Madrid',
    city: 'Madrid',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Madrid'
  },
  {
    key: 118,
    locatione: 'Europe/Malta',
    city: 'Malta',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Malta'
  },
  {
    key: 119,
    locatione: 'Europe/Monaco',
    city: 'Monaco',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Monaco'
  },
  {
    key: 120,
    locatione: 'Europe/Oslo',
    city: 'Oslo',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Oslo'
  },
  {
    key: 121,
    locatione: 'Europe/Paris',
    city: 'Paris',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Paris'
  },
  {
    key: 122,
    locatione: 'Europe/Prague',
    city: 'Central European Time - Prague',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Central European Time - Prague'
  },
  {
    key: 123,
    locatione: 'Europe/Rome',
    city: 'Rome',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Rome'
  },
  {
    key: 124,
    locatione: 'Europe/Stockholm',
    city: 'Stockholm',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Stockholm'
  },
  {
    key: 125,
    locatione: 'Europe/Tirane',
    city: 'Tirane',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Tirane'
  },
  {
    key: 126,
    locatione: 'Europe/Vienna',
    city: 'Vienna',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Vienna'
  },
  {
    key: 127,
    locatione: 'Europe/Warsaw',
    city: 'Warsaw',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Warsaw'
  },
  {
    key: 128,
    locatione: 'Europe/Zurich',
    city: 'Zurich',
    gmtValue: 'GMT+01:00',
    display: '(GMT+01:00) Zurich'
  },
  {
    key: 129,
    locatione: 'Africa/Cairo',
    city: 'Cairo',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Cairo'
  },
  {
    key: 130,
    locatione: 'Africa/Johannesburg',
    city: 'Johannesburg',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Johannesburg'
  },
  {
    key: 131,
    locatione: 'Africa/Maputo',
    city: 'Maputo',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Maputo'
  },
  {
    key: 132,
    locatione: 'Africa/Tripoli',
    city: 'Tripoli',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Tripoli'
  },
  {
    key: 133,
    locatione: 'Asia/Amman',
    city: 'Amman',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Amman'
  },
  {
    key: 134,
    locatione: 'Asia/Beirut',
    city: 'Beirut',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Beirut'
  },
  {
    key: 135,
    locatione: 'Asia/Damascus',
    city: 'Damascus',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Damascus'
  },
  {
    key: 136,
    locatione: 'Asia/Gaza',
    city: 'Gaza',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Gaza'
  },
  {
    key: 137,
    locatione: 'Asia/Jerusalem',
    city: 'Jerusalem',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Jerusalem'
  },
  {
    key: 138,
    locatione: 'Asia/Nicosia',
    city: 'Nicosia',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Nicosia'
  },
  {
    key: 139,
    locatione: 'Europe/Athens',
    city: 'Athens',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Athens'
  },
  {
    key: 140,
    locatione: 'Europe/Bucharest',
    city: 'Bucharest',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Bucharest'
  },
  {
    key: 141,
    locatione: 'Europe/Chisinau',
    city: 'Chisinau',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Chisinau'
  },
  {
    key: 142,
    locatione: 'Europe/Helsinki',
    city: 'Helsinki',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Helsinki'
  },
  {
    key: 143,
    locatione: 'Europe/Istanbul',
    city: 'Istanbul',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Istanbul'
  },
  {
    key: 144,
    locatione: 'Europe/Kaliningrad',
    city: 'Moscow-01 - Kaliningrad',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Moscow-01 - Kaliningrad'
  },
  {
    key: 145,
    locatione: 'Europe/Kiev',
    city: 'Kiev',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Kiev'
  },
  {
    key: 146,
    locatione: 'Europe/Riga',
    city: 'Riga',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Riga'
  },
  {
    key: 147,
    locatione: 'Europe/Sofia',
    city: 'Sofia',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Sofia'
  },
  {
    key: 148,
    locatione: 'Europe/Tallinn',
    city: 'Tallinn',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Tallinn'
  },
  {
    key: 149,
    locatione: 'Europe/Vilnius',
    city: 'Vilnius',
    gmtValue: 'GMT+02:00',
    display: '(GMT+02:00) Vilnius'
  },
  {
    key: 150,
    locatione: 'Africa/Khartoum',
    city: 'Khartoum',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Khartoum'
  },
  {
    key: 151,
    locatione: 'Africa/Nairobi',
    city: 'Nairobi',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Nairobi'
  },
  {
    key: 152,
    locatione: 'Antarctica/Syowa',
    city: 'Syowa',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Syowa'
  },
  {
    key: 153,
    locatione: 'Asia/Baghdad',
    city: 'Baghdad',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Baghdad'
  },
  {
    key: 154,
    locatione: 'Asia/Qatar',
    city: 'Qatar',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Qatar'
  },
  {
    key: 155,
    locatione: 'Asia/Riyadh',
    city: 'Riyadh',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Riyadh'
  },
  {
    key: 156,
    locatione: 'Europe/Minsk',
    city: 'Minsk',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Minsk'
  },
  {
    key: 157,
    locatione: 'Europe/Moscow',
    city: 'Moscow+00 - Moscow',
    gmtValue: 'GMT+03:00',
    display: '(GMT+03:00) Moscow+00 - Moscow'
  },
  {
    key: 158,
    locatione: 'Asia/Tehran',
    city: 'Tehran',
    gmtValue: 'GMT+03:30',
    display: '(GMT+03:30) Tehran'
  },
  {
    key: 159,
    locatione: 'Asia/Baku',
    city: 'Baku',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Baku'
  },
  {
    key: 160,
    locatione: 'Asia/Dubai',
    city: 'Dubai',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Dubai'
  },
  {
    key: 161,
    locatione: 'Asia/Tbilisi',
    city: 'Tbilisi',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Tbilisi'
  },
  {
    key: 162,
    locatione: 'Asia/Yerevan',
    city: 'Yerevan',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Yerevan'
  },
  {
    key: 163,
    locatione: 'Europe/Samara',
    city: 'Moscow+01 - Samara',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Moscow+01 - Samara'
  },
  {
    key: 164,
    locatione: 'Indian/Mahe',
    city: 'Mahe',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Mahe'
  },
  {
    key: 165,
    locatione: 'Indian/Mauritius',
    city: 'Mauritius',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Mauritius'
  },
  {
    key: 166,
    locatione: 'Indian/Reunion',
    city: 'Reunion',
    gmtValue: 'GMT+04:00',
    display: '(GMT+04:00) Reunion'
  },
  {
    key: 167,
    locatione: 'Asia/Kabul',
    city: 'Kabul',
    gmtValue: 'GMT+04:30',
    display: '(GMT+04:30) Kabul'
  },
  {
    key: 168,
    locatione: 'Antarctica/Mawson',
    city: 'Mawson',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Mawson'
  },
  {
    key: 169,
    locatione: 'Asia/Aqtau',
    city: 'Aqtau',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Aqtau'
  },
  {
    key: 170,
    locatione: 'Asia/Aqtobe',
    city: 'Aqtobe',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Aqtobe'
  },
  {
    key: 171,
    locatione: 'Asia/Ashgabat',
    city: 'Ashgabat',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Ashgabat'
  },
  {
    key: 172,
    locatione: 'Asia/Dushanbe',
    city: 'Dushanbe',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Dushanbe'
  },
  {
    key: 173,
    locatione: 'Asia/Karachi',
    city: 'Karachi',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Karachi'
  },
  {
    key: 174,
    locatione: 'Asia/Tashkent',
    city: 'Tashkent',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Tashkent'
  },
  {
    key: 175,
    locatione: 'Asia/Yekaterinburg',
    city: 'Moscow+02 - Yekaterinburg',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Moscow+02 - Yekaterinburg'
  },
  {
    key: 176,
    locatione: 'Indian/Kerguelen',
    city: 'Kerguelen',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Kerguelen'
  },
  {
    key: 177,
    locatione: 'Indian/Maldives',
    city: 'Maldives',
    gmtValue: 'GMT+05:00',
    display: '(GMT+05:00) Maldives'
  },
  {
    key: 178,
    locatione: 'Asia/Calcutta',
    city: 'India Standard Time',
    gmtValue: 'GMT+05:30',
    display: '(GMT+05:30) India Standard Time'
  },
  {
    key: 179,
    locatione: 'Asia/Colombo',
    city: 'Colombo',
    gmtValue: 'GMT+05:30',
    display: '(GMT+05:30) Colombo'
  },
  {
    key: 180,
    locatione: 'Asia/Katmandu',
    city: 'Katmandu',
    gmtValue: 'GMT+05:45',
    display: '(GMT+05:45) Katmandu'
  },
  {
    key: 181,
    locatione: 'Antarctica/Vostok',
    city: 'Vostok',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Vostok'
  },
  {
    key: 182,
    locatione: 'Asia/Almaty',
    city: 'Almaty',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Almaty'
  },
  {
    key: 183,
    locatione: 'Asia/Bishkek',
    city: 'Bishkek',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Bishkek'
  },
  {
    key: 184,
    locatione: 'Asia/Dhaka',
    city: 'Dhaka',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Dhaka'
  },
  {
    key: 185,
    locatione: 'Asia/Omsk',
    city: 'Moscow+03 - Omsk, Novosibirsk',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk'
  },
  {
    key: 186,
    locatione: 'Asia/Thimphu',
    city: 'Thimphu',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Thimphu'
  },
  {
    key: 187,
    locatione: 'Indian/Chagos',
    city: 'Chagos',
    gmtValue: 'GMT+06:00',
    display: '(GMT+06:00) Chagos'
  },
  {
    key: 188,
    locatione: 'Asia/Rangoon',
    city: 'Rangoon',
    gmtValue: 'GMT+06:30',
    display: '(GMT+06:30) Rangoon'
  },
  {
    key: 189,
    locatione: 'Indian/Cocos',
    city: 'Cocos',
    gmtValue: 'GMT+06:30',
    display: '(GMT+06:30) Cocos'
  },
  {
    key: 190,
    locatione: 'Antarctica/Davis',
    city: 'Davis',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Davis'
  },
  {
    key: 191,
    locatione: 'Asia/Bangkok',
    city: 'Bangkok',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Bangkok'
  },
  {
    key: 192,
    locatione: 'Asia/Hovd',
    city: 'Hovd',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Hovd'
  },
  {
    key: 193,
    locatione: 'Asia/Jakarta',
    city: 'Jakarta',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Jakarta'
  },
  {
    key: 194,
    locatione: 'Asia/Krasnoyarsk',
    city: 'Moscow+04 - Krasnoyarsk',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Moscow+04 - Krasnoyarsk'
  },
  {
    key: 195,
    locatione: 'Asia/Saigon',
    city: 'Hanoi',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Hanoi'
  },
  {
    key: 196,
    locatione: 'Asia/Ho_Chi_Minh',
    city: 'Ho Chi Minh',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Ho Chi Minh'
  },
  {
    key: 197,
    locatione: 'Indian/Christmas',
    city: 'Christmas',
    gmtValue: 'GMT+07:00',
    display: '(GMT+07:00) Christmas'
  },
  {
    key: 198,
    locatione: 'Antarctica/Casey',
    city: 'Casey',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Casey'
  },
  {
    key: 199,
    locatione: 'Asia/Brunei',
    city: 'Brunei',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Brunei'
  },
  {
    key: 200,
    locatione: 'Asia/Choibalsan',
    city: 'Choibalsan',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Choibalsan'
  },
  {
    key: 201,
    locatione: 'Asia/Hong_Kong',
    city: 'Hong Kong',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Hong Kong'
  },
  {
    key: 202,
    locatione: 'Asia/Irkutsk',
    city: 'Moscow+05 - Irkutsk',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Moscow+05 - Irkutsk'
  },
  {
    key: 203,
    locatione: 'Asia/Kuala_Lumpur',
    city: 'Kuala Lumpur',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Kuala Lumpur'
  },
  {
    key: 204,
    locatione: 'Asia/Macau',
    city: 'Macau',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Macau'
  },
  {
    key: 205,
    locatione: 'Asia/Makassar',
    city: 'Makassar',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Makassar'
  },
  {
    key: 206,
    locatione: 'Asia/Manila',
    city: 'Manila',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Manila'
  },
  {
    key: 207,
    locatione: 'Asia/Shanghai',
    city: 'China Time - Beijing',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) China Time - Beijing'
  },
  {
    key: 208,
    locatione: 'Asia/Singapore',
    city: 'Singapore',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Singapore'
  },
  {
    key: 209,
    locatione: 'Asia/Taipei',
    city: 'Taipei',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Taipei'
  },
  {
    key: 210,
    locatione: 'Asia/Ulaanbaatar',
    city: 'Ulaanbaatar',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Ulaanbaatar'
  },
  {
    key: 211,
    locatione: 'Australia/Perth',
    city: 'Western Time - Perth',
    gmtValue: 'GMT+08:00',
    display: '(GMT+08:00) Western Time - Perth'
  },
  {
    key: 212,
    locatione: 'Asia/Pyongyang',
    city: 'Pyongyang',
    gmtValue: 'GMT+08:30',
    display: '(GMT+08:30) Pyongyang'
  },
  {
    key: 213,
    locatione: 'Asia/Dili',
    city: 'Dili',
    gmtValue: 'GMT+09:00',
    display: '(GMT+09:00) Dili'
  },
  {
    key: 214,
    locatione: 'Asia/Jayapura',
    city: 'Jayapura',
    gmtValue: 'GMT+09:00',
    display: '(GMT+09:00) Jayapura'
  },
  {
    key: 215,
    locatione: 'Asia/Seoul',
    city: 'Seoul',
    gmtValue: 'GMT+09:00',
    display: '(GMT+09:00) Seoul'
  },
  {
    key: 216,
    locatione: 'Asia/Tokyo',
    city: 'Tokyo',
    gmtValue: 'GMT+09:00',
    display: '(GMT+09:00) Tokyo'
  },
  {
    key: 217,
    locatione: 'Asia/Yakutsk',
    city: 'Moscow+06 - Yakutsk',
    gmtValue: 'GMT+09:00',
    display: '(GMT+09:00) Moscow+06 - Yakutsk'
  },
  {
    key: 218,
    locatione: 'Pacific/Palau',
    city: 'Palau',
    gmtValue: 'GMT+09:00',
    display: '(GMT+09:00) Palau'
  },
  {
    key: 219,
    locatione: 'Australia/Adelaide',
    city: 'Central Time - Adelaide',
    gmtValue: 'GMT+10:30',
    display: '(GMT+10:30) Central Time - Adelaide'
  },
  {
    key: 220,
    locatione: 'Australia/Darwin',
    city: 'Central Time - Darwin',
    gmtValue: 'GMT+09:30',
    display: '(GMT+09:30) Central Time - Darwin'
  },
  {
    key: 221,
    locatione: 'Antarctica/DumontDUrville',
    city: "Dumont D'Urville",
    gmtValue: 'GMT+10:00',
    display: "(GMT+10:00) Dumont D'Urville"
  },
  {
    key: 222,
    locatione: 'Asia/Magadan',
    city: 'Moscow+07 - Magadan',
    gmtValue: 'GMT+10:00',
    display: '(GMT+10:00) Moscow+07 - Magadan'
  },
  {
    key: 223,
    locatione: 'Asia/Vladivostok',
    city: 'Moscow+07 - Yuzhno-Sakhalinsk',
    gmtValue: 'GMT+10:00',
    display: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk'
  },
  {
    key: 224,
    locatione: 'Australia/Brisbane',
    city: 'Eastern Time - Brisbane',
    gmtValue: 'GMT+10:00',
    display: '(GMT+10:00) Eastern Time - Brisbane'
  },
  {
    key: 225,
    locatione: 'Australia/Hobart',
    city: 'Eastern Time - Hobart',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Eastern Time - Hobart'
  },
  {
    key: 226,
    locatione: 'Australia/Sydney',
    city: 'Eastern Time - Melbourne, Sydney',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Eastern Time - Melbourne, Sydney'
  },
  {
    key: 227,
    locatione: 'Pacific/Chuuk',
    city: 'Truk',
    gmtValue: 'GMT+10:00',
    display: '(GMT+10:00) Truk'
  },
  {
    key: 228,
    locatione: 'Pacific/Guam',
    city: 'Guam',
    gmtValue: 'GMT+10:00',
    display: '(GMT+10:00) Guam'
  },
  {
    key: 229,
    locatione: 'Pacific/Port_Moresby',
    city: 'Port Moresby',
    gmtValue: 'GMT+10:00',
    display: '(GMT+10:00) Port Moresby'
  },
  {
    key: 230,
    locatione: 'Pacific/Efate',
    city: 'Efate',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Efate'
  },
  {
    key: 231,
    locatione: 'Pacific/Guadalcanal',
    city: 'Guadalcanal',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Guadalcanal'
  },
  {
    key: 232,
    locatione: 'Pacific/Kosrae',
    city: 'Kosrae',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Kosrae'
  },
  {
    key: 233,
    locatione: 'Pacific/Norfolk',
    city: 'Norfolk',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Norfolk'
  },
  {
    key: 234,
    locatione: 'Pacific/Noumea',
    city: 'Noumea',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Noumea'
  },
  {
    key: 235,
    locatione: 'Pacific/Pohnpei',
    city: 'Ponape',
    gmtValue: 'GMT+11:00',
    display: '(GMT+11:00) Ponape'
  },
  {
    key: 236,
    locatione: 'Asia/Kamchatka',
    city: 'Moscow+09 - Petropavlovsk-Kamchatskiy',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy'
  },
  {
    key: 237,
    locatione: 'Pacific/Auckland',
    city: 'Auckland',
    gmtValue: 'GMT+13:00',
    display: '(GMT+13:00) Auckland'
  },
  {
    key: 238,
    locatione: 'Pacific/Fiji',
    city: 'Fiji',
    gmtValue: 'GMT+13:00',
    display: '(GMT+13:00) Fiji'
  },
  {
    key: 239,
    locatione: 'Pacific/Funafuti',
    city: 'Funafuti',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Funafuti'
  },
  {
    key: 240,
    locatione: 'Pacific/Kwajalein',
    city: 'Kwajalein',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Kwajalein'
  },
  {
    key: 241,
    locatione: 'Pacific/Majuro',
    city: 'Majuro',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Majuro'
  },
  {
    key: 242,
    locatione: 'Pacific/Nauru',
    city: 'Nauru',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Nauru'
  },
  {
    key: 243,
    locatione: 'Pacific/Tarawa',
    city: 'Tarawa',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Tarawa'
  },
  {
    key: 244,
    locatione: 'Pacific/Wake',
    city: 'Wake',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Wake'
  },
  {
    key: 245,
    locatione: 'Pacific/Wallis',
    city: 'Wallis',
    gmtValue: 'GMT+12:00',
    display: '(GMT+12:00) Wallis'
  },
  {
    key: 246,
    locatione: 'Pacific/Apia',
    city: 'Apia',
    gmtValue: 'GMT+14:00',
    display: '(GMT+14:00) Apia'
  },
  {
    key: 247,
    locatione: 'Pacific/Enderbury',
    city: 'Enderbury',
    gmtValue: 'GMT+13:00',
    display: '(GMT+13:00) Enderbury'
  },
  {
    key: 248,
    locatione: 'Pacific/Fakaofo',
    city: 'Fakaofo',
    gmtValue: 'GMT+13:00',
    display: '(GMT+13:00) Fakaofo'
  },
  {
    key: 249,
    locatione: 'Pacific/Tongatapu',
    city: 'Tongatapu',
    gmtValue: 'GMT+13:00',
    display: '(GMT+13:00) Tongatapu'
  },
  {
    key: 250,
    locatione: 'Pacific/Kiritimati',
    city: 'Kiritimati',
    gmtValue: 'GMT+14:00',
    display: '(GMT+14:00) Kiritimati'
  }
];
