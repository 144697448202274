import React, { useState, useMemo, useRef } from 'react';
import { Marker as MapMarker, InfoWindow } from '@react-google-maps/api';
import MarkerData from './MarkerData';
import { MarkerProps } from './Marker.interface';
import { isMobile, isTablet } from 'react-device-detect';
import { StatusColorMap } from '../MapWidget.utils';

function Marker(props: MarkerProps) {
  const [isOpen, setIsOpen] = useState(false);
  // const [markerData, setMarkerData] = useState();
  const {
    getMarkerData,
    location,
    onNavigateToDashboard,
    clusterer,
    customization,
    defaultDecimalDigits,
  } = props;
  const { geojson, status, lat, lon } = location;
  const position = { lat, lng: lon };
  let isIn = useMemo(() => false, []);
  const marker = useRef(null);

  const toggleOpen = () => {
    isIn = true;
    setTimeout(() => {
      if (!isOpen && isIn) {
        setIsOpen(true);
        //plane a
        // !markerData &&
        //   getMarkerData({
        //     type: 'Feature',
        //     geometry: JSON.parse(geojson),
        //     properties: {
        //       count: 1
        //     }
        //   })
        //     .then(res => setMarkerData(res && res.results && res.results[0]))
        //     .catch(() => {
        //       setMarkerData({
        //         id: 12,
        //         assetName: 'sdfdgfdg',
        //         serialNumber: '12322',
        //         assetTypeName: 'jdhfksjdfs',
        //         ownerName: 'org1',
        //         status: 'CONNECTED_OK'
        //       });
        //     });
      }
    }, 500);
  };
  const toggleClose = () => {
    setIsOpen(false);
    isIn = false;
  };

  const onClick = () => {
    const assetId = location && location.assetId;
    assetId && onNavigateToDashboard(assetId);
    //plan a
    // ? onNavigateToDashboard(assetId)
    // : getMarkerData({
    //     type: 'Feature',
    //     geometry: JSON.parse(geojson),
    //     properties: {
    //       count: 1
    //     }
    //   })
    //     .then(res =>
    //       onNavigateToDashboard(res && res.results && res.results[0] && res.results[0].id)
    //     )
    //     .catch(() => {
    //       onNavigateToDashboard(1);
    //     });
  };

  return (
    <MapMarker
      position={position}
      onLoad={(m) => (marker.current = m)}
      icon={{ url: `/assets/images/location_icon_${StatusColorMap[status] || 'grey'}.svg` }}
      onMouseOut={toggleClose}
      onClick={isTablet || isMobile ? toggleOpen : onNavigateToDashboard ? onClick : null}
      clusterer={clusterer}
      onMouseOver={toggleOpen}>
      {isOpen && (
        <InfoWindow onCloseClick={toggleClose} anchor={marker.current}>
          <MarkerData
            customization={customization}
            //onMouseOut={toggleClose}
            markerData={location}
            defaultDecimalDigits={defaultDecimalDigits}
            onClick={onClick}
          />
        </InfoWindow>
      )}
    </MapMarker>
  );
}

export default Marker;
