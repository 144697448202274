import React, { useCallback, useEffect, useMemo } from 'react';

import { StepTwoProps } from './StepTwo.interface';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { modalService } from '@core/modals/ModalService';
import { stepTwoResetValues, stepTwoResetGroupingAndMetrics } from './resetValues';
import I18n from '@components/I18n';
import styles from './StepTwo.scss';
import RadioBox from './RadioBox';
import RawDataOption from './RawDataOption';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { isEmpty } from 'lodash';
import { editorConfig } from '@core/canvas/editorConfig';
import { cssVarsService } from '@core/CssVarsService';
import { scopeTypesMap } from '@core/mapsAndDefinitions';
import { getDefaultScope } from './StepTwo.utils';

function StepTwo(props: StepTwoProps) {
  const { widgetData, setWidgetData } = props;
  const { scope, type } = widgetData;
  const languageId = useSelector((state) => state.config.languageId);
  const isCreatingEventTempldateDataSource = widgetData.eventTemplateId > 0;
  const typeMap = useMemo(
    () =>
      ['table', 'line'].map((type) => ({
        label: i18nService.translate(`create-widget-page.create-widget.step-four.${type}`),
        value: type,
      })),
    [languageId]
  );

  const headerHeight = useMemo(() => {
    const cellSize = editorConfig.gridCellSizePx;
    return Math.floor(cssVarsService.vars.widgetActionBarHeight / cellSize);
  }, []);

  const radioBoxLabels = getConfigValueFromWidgetSettings(type, 'stepTwoRadioBoxText');

  const changedToAggregate = (widgetData, scope) =>
    (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') &&
    widgetData?.scope !== 'AGGREGATED_DATA' &&
    widgetData?.scope !== 'AGGREGATED_LAST_VALUE';
  const changedFromAggregate = (widgetData, scope) =>
    scope !== 'AGGREGATED_DATA' &&
    scope !== 'AGGREGATED_LAST_VALUE' &&
    (widgetData?.scope === 'AGGREGATED_DATA' || widgetData?.scope === 'AGGREGATED_LAST_VALUE');

  const changeFromAggregateToAggregateByLastValueOrViceVersa = (widgetData, scope) =>
    (scope === 'AGGREGATED_DATA' && widgetData?.scope === 'AGGREGATED_LAST_VALUE') ||
    (scope === 'AGGREGATED_LAST_VALUE' && widgetData?.scope === 'AGGREGATED_DATA');

  const changedFromOrToAggregate = (widgetData, scope) => {
    const { groupBy, metrics, filters, sorts, customization, localTags } = widgetData || {};

    return (
      (!!groupBy.length ||
        !!metrics.length ||
        !!filters.length ||
        !!sorts.length ||
        !!localTags.length ||
        !isEmpty(customization)) &&
      (changedToAggregate(widgetData, scope) ||
        changedFromAggregate(widgetData, scope) ||
        changeFromAggregateToAggregateByLastValueOrViceVersa(widgetData, scope))
    );
  };

  const onSelectedScope = useCallback(
    (scope) => {
      const changedFromRawDataToLastValue =
        scope === 'LAST_VALUE' && widgetData?.scope === 'RAW_DATA';

      if (changedFromRawDataToLastValue && widgetData.exportFormats.length) {
        updateWidgetScopeAndRemoveExports(scope);
        return;
      }
      if (changedFromOrToAggregate(widgetData, scope)) {
        modalService
          .openModal('confirm', {
            iconType: 'attention_image',
            text: !widgetData.eventTemplateId
              ? 'create-widget-page.create-widget.step-two.change-initialize-aggragate'
              : 'create-event-data-source-page.create-event-data-source.step-two.change-initialize-aggragate',
            confirmText: 'general.confirm',
            cancelText: 'general.cancel',
            headerText: !widgetData.eventTemplateId
              ? 'create-widget-page.create-widget.confirm-widget-changes.header'
              : 'create-event-data-source-page.create-event-data-source.confirm-data-source-changes.header',
            showCloseBtn: true,
          })
          .then((confirm) => {
            if (confirm) {
              updateWidgetScope(scope, 'fromOrToAggregated');
              stepTwoResetValues(setWidgetData);
              let setDefaultData = getConfigValueFromWidgetSettings(type, 'setDefaultDataByStep');
              setDefaultData &&
                setDefaultData['2'] &&
                setDefaultData['2'](scope, setWidgetData, widgetData);
            }
          });
      } else if (
        scope === 'RAW_DATA' &&
        ['AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'].includes(widgetData?.scope)
      ) {
        const updateRawData = () => {
          stepTwoResetGroupingAndMetrics(setWidgetData);
          let setDefaultData = getConfigValueFromWidgetSettings(type, 'setDefaultDataByStep');
          setDefaultData &&
            setDefaultData['2'] &&
            setDefaultData['2'](scope, setWidgetData, widgetData);
          updateWidgetScope(scope, 'fromOrToAggregated');
        };

        if (widgetData.customization && !isEmpty(widgetData.customization)) {
          modalService
            .openModal('confirm', {
              iconType: 'attention_image',
              text: !widgetData.eventTemplateId
                ? 'create-widget-page.create-widget.step-two.remove-step-four'
                : 'create-event-data-source-page.create-event-data-source.step-two.remove-step-four',
              confirmText: 'general.confirm',
              cancelText: 'general.cancel',
              headerText: 'create-widget-page.create-widget.confirm-widget-changes.header',
              showCloseBtn: true,
            })
            .then((confirm) => {
              confirm && updateRawData();
            });
        } else {
          updateRawData();
        }
      } else {
        updateWidgetScope(scope);
      }
    },
    [widgetData]
  );

  const updateWidgetScope = (scope, mode = null) => {
    switch (mode) {
      case 'fromOrToAggregated':
        setWidgetData((prevState) => ({
          ...prevState,
          scope,
          alarmInfos: [],
          calculations: [],
          metrics: [],
          localTags: [],
        }));
      default:
        setWidgetData((prevState) => ({
          ...prevState,
          scope,
        }));
    }

    let setDefaultData = getConfigValueFromWidgetSettings(type, 'setDefaultDataByStep');
    setDefaultData && setDefaultData['2'] && setDefaultData['2'](scope, setWidgetData, widgetData);
  };

  const updateWidgetScopeAndRemoveExports = (scope) => {
    let state = widgetData.state;
    if (widgetData.hideWidgetName) {
      state = {
        ...state,
        h: widgetData.state.h - headerHeight,
        y: widgetData.state.y + headerHeight,
      };
    }
    setWidgetData((prevState) => ({
      ...prevState,
      scope,
      exportFormats: [],
      state: {
        ...state,
      },
    }));
    let setDefaultData = getConfigValueFromWidgetSettings(type, 'setDefaultDataByStep');
    setDefaultData && setDefaultData['2'] && setDefaultData['2'](scope, setWidgetData, widgetData);
  };

  const onChangeTableType = useCallback(() => {
    const data = {
      condition: (data) => !data.type || data.type.length > 0,
      changedKey: 'type',
      selectCfg: {
        isMulti: false,
      },
      text: 'create-widget-page.create-widget.step-two.change-widget',
      options: typeMap,
    };
    modalService
      .openModal('modalWithSelect', {
        ...data,
      })
      .then((selectedType) => {
        if (selectedType) {
          setWidgetData((prevState) => ({
            ...prevState,
            type: selectedType.value,
            customization: undefined,
            metrics: [],
            calculations: [],
            groupBy: [],
            filters: [],
            sorts: [],
          }));

          let setAllDefaultData = getConfigValueFromWidgetSettings(
            selectedType.value,
            'setAllDefaultData'
          );
          setAllDefaultData && setAllDefaultData(setWidgetData);
        }
      });
  }, []);

  useEffect(() => {
    if (!scope) {
      const defaultScope = getDefaultScope(type);

      if (defaultScope) {
        onSelectedScope(defaultScope);
      }
    }
  }, []);

  return (
    <div className={styles.stepTwoContainer}>
      <div className={styles.stepHeader}>
        <I18n>widget-side-bar.scope</I18n>
      </div>
      <div className={styles.radioContainer}>
        {scopeTypesMap.map((item) => (
          <div key={item.name} className={styles.radioboxContainer}>
            <RadioBox
              scope={scope}
              onSelectedScope={onSelectedScope}
              labels={item.labels}
              disabled={getIsIncludeInSettings(type, 'disabledScope', item.name)}
              name={item.name}
              extraLabels={radioBoxLabels && radioBoxLabels[item.name]}
              isCreatingEventTempldateDataSource={isCreatingEventTempldateDataSource}
            />
            {getIsIncludeInSettings(type, 'disabledScope', item.name) &&
              item.name === 'RAW_DATA' &&
              !widgetData.eventTemplateId && (
                <RawDataOption onChangeTableType={onChangeTableType} />
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StepTwo;
