import Details from './Details';
import Assets from './Assets';
import Users from './Users';
import PLC from './PLC';
import Routers from './Routers';
import { getFlagStatus } from '@core/ffAndPermissions';
import Events from './Events';
import Variables from './Variables';
import LicensePlans from '@src/pages/OrganizationsPage/LicensePlans';

export const showTabMap = {
  UNITRONICS_MAIN: {
    UNITRONICS_MAIN: ['details', 'users', 'events'],
    UNITRONICS_CHANNEL: ['details', 'users', 'events'],
    MACHINE_BUILDER: [
      'details',
      'users',
      'assets',
      'routers',
      'events',
      'variables',
      'licensePlans',
    ],
    MACHINE_BUILDER_CHANNEL: ['details', 'users', 'assets', 'routers', 'events'],
    END_CUSTOMER: ['details', 'users', 'assets', 'routers', 'events'],
  },
  UNITRONICS_CHANNEL: {
    UNITRONICS_CHANNEL: ['details', 'users', 'events'],
    MACHINE_BUILDER: ['details', 'users', 'events'],
  },
  MACHINE_BUILDER: {
    MACHINE_BUILDER_CHANNEL: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
    END_CUSTOMER: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
  },
  MACHINE_BUILDER_CHANNEL: {
    MACHINE_BUILDER_CHANNEL: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
    END_CUSTOMER: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
  },
  END_CUSTOMER: { END_CUSTOMER: ['details', 'users', 'events', 'variables'] },
};

export const redirectToMap = [
  { url: 'details', featureFlag: 'details-tab' },
  { url: 'users', featureFlag: 'users-tab' },
];

function getIsDisplay(tabName, userOrgType, selectedOrgType) {
  return (
    !showTabMap[userOrgType][selectedOrgType] ||
    showTabMap[userOrgType][selectedOrgType].includes(tabName)
  );
}

export const getTabs = (
  showAssetsTab,
  showUsersTab,
  showPlcTab,
  showRoutersTab,
  userOrgType,
  selectedOrgType,
  showEventsTab,
  isVariablesTabVisible,
  showLicensePlansTab
) => ({
  details: {
    component: Details,
    label: 'organizations-page.details',
    url: 'details',
    condition: () => getIsDisplay('details', userOrgType, selectedOrgType),
  },
  users: {
    component: Users,
    label: 'organizations-page.users',
    url: 'users',
    condition: () => showUsersTab && getIsDisplay('users', userOrgType, selectedOrgType),
  },
  assets: {
    component: Assets,
    label: 'organizations-page.assets',
    url: 'assets',
    condition: () => showAssetsTab && getIsDisplay('assets', userOrgType, selectedOrgType),
  },
  licensePlans: {
    component: LicensePlans,
    label: 'my-account-page.license-plans',
    url: 'licensePlans',
    condition: () =>
      showLicensePlansTab && getIsDisplay('licensePlans', userOrgType, selectedOrgType),
  },
  plc: {
    component: PLC,
    label: 'organizations-page.plc',
    url: 'plc',
    condition: () => showPlcTab && getIsDisplay('plc', userOrgType, selectedOrgType),
  },
  events: {
    component: Events,
    label: 'organizations-page.events',
    url: 'events',
    condition: () => showEventsTab && getIsDisplay('events', userOrgType, selectedOrgType),
  },
  routers: {
    component: Routers,
    label: 'organizations-page.routers',
    url: 'routers',
    condition: () => showRoutersTab && getIsDisplay('routers', userOrgType, selectedOrgType),
  },
  variables: {
    component: Variables,
    label: 'variables.caption',
    url: 'variables',
    condition: () =>
      isVariablesTabVisible && getIsDisplay('variables', userOrgType, selectedOrgType),
  },
});

export const getRedirectTab = (userOrgType) => {
  return userOrgType === 'END_CUSTOMER'
    ? redirectToMap[1]
    : redirectToMap.find((r) => getFlagStatus(`organizations-management.${r.featureFlag}`));
};
