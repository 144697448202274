import { Subject } from '@core/Subject';
import { httpService } from '@http/HttpService';
import { GetOwnersRes, GetAssetTypeOptionsRes, AssetTypeOption } from '@http/server.interface.d';
import { GeoFilter, DateFilterValues, FilterItem } from '@redux/redux.interface.d';
import {
  setGeoFilter,
  setDateFilter,
  setAssetTypesFilter,
  setOrganizationsFilter,
  setAssetFilter,
} from '@redux/dashboardEditor';
import { dispatch } from '@redux/store';
import { AssetTypeModel, AssetModel } from '@modals/CreateAssetModal/createAssetModal.interface';
import sortBy from 'lodash';
import asset from '@src/redux/deviceManagment/asset';

class UserFiltersService {
  organizationOptions$ = new Subject<GetOwnersRes>([]);
  assetTypeOptions$ = new Subject<AssetTypeOption[]>([]);

  async getOrganizationOptions() {
    const res = await httpService.api<GetOwnersRes>({
      type: 'getOwners',
      disableBI: true,
    });
    const orderedOrgs = res.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : 0
    );
    this.organizationOptions$.next(orderedOrgs);
  }

  async getAssetTypeOptions() {
    const res = await httpService.api<GetAssetTypeOptionsRes>({
      type: 'getAssetTypeOptions',
      disableBI: true,
    });
    const orderedAssetTypes = res.assetTypes.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : 0
    );
    this.assetTypeOptions$.next(orderedAssetTypes);
  }

  setOrganizationsFilter(organizationsFilters: FilterItem[]) {
    dispatch(setOrganizationsFilter(organizationsFilters));
  }

  setAssetTypesFilter(assetTypesFilters: AssetTypeModel[] | FilterItem[]) {
    const filters = assetTypesFilters as FilterItem[];
    dispatch(
      setAssetTypesFilter(filters.map((assetType) => ({ id: assetType.id, name: assetType.name })))
    );
  }

  setAssetFilter(assetFilters: any | any[]) {
    const filters = assetFilters as FilterItem | FilterItem[];
    dispatch(
      Array.isArray(filters)
        ? setAssetFilter(
            filters.length > 0 && filters[0].id
              ? [{ id: filters[0].id, name: filters[0].name }]
              : []
          )
        : dispatch(setAssetFilter(filters.id ? [{ id: filters.id, name: filters.name }] : []))
    );
  }

  setGeoFilter(geoFilters: GeoFilter[]) {
    dispatch(setGeoFilter(geoFilters));
  }

  setDateFilter(dateFilter: DateFilterValues) {
    dispatch(setDateFilter(dateFilter));
  }
}

export const userFiltersService = new UserFiltersService();
