import React from 'react';

import { DashboardIconProps, StatusIconProps } from './StatusIcon.interface';
import I18n from '@components/I18n';

const DashboardIcon = (props: React.PropsWithChildren<DashboardIconProps>) => {
  const { isDraft, width = '40px', height = '24px', fontSize = '12px', style, children } = props;
  return (
    <div
      style={{
        ...style,
        width: width,
        height: height,
        border: isDraft
          ? '1px solid var(--systemDraftBackground)'
          : '1px solid var(--systemPublishedBackground)',
        borderBottom: !isDraft && '5px solid var(--systemPublishedBackground)',
        borderRadius: '3px',
        backgroundColor: isDraft
          ? 'var(--systemDraftBackground)'
          : 'var(--systemPublishedBackground)',
      }}>
      {isDraft && (
        <I18n
          element={'div'}
          style={{
            color: 'var(--systemFont)',
            textAlign: 'center',
            fontSize: fontSize,
            marginTop: height === '24px' ? '1px' : '-3px',
          }}>
          general.draft
        </I18n>
      )}
      {children && children}
    </div>
  );
};

function StatusIcon(props: StatusIconProps) {
  const { status } = props;

  return (
    <>
      {status === 'draft' ? (
        <DashboardIcon isDraft />
      ) : status === 'published' ? (
        <DashboardIcon />
      ) : (
        <div style={{ height: '24px' }}>
          <DashboardIcon
            width={'34px'}
            height={'18px'}
            style={{
              marginTop: '6px',
              zIndex: 997,
              position: 'relative',
            }}>
            <DashboardIcon
              isDraft
              width={'34px'}
              height={'18px'}
              fontSize={'10px'}
              style={{
                left: 5,
                bottom: 1,
                zIndex: 999,
                position: 'absolute',
              }}
            />
          </DashboardIcon>
        </div>
      )}
    </>
  );
}

export default StatusIcon;
