import React, { useCallback, useEffect, useState } from 'react';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import styles from './TrainingARProjectModal.scss';
import Icon from '@components/Icon';
import { trainingARProjectService } from './TrainingARProjectService';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import { onCancel, trainingARProject } from './TrainingARProjectModal.utils';
import { modalService } from '@core/modals/ModalService';
import { dispatch } from '@src/redux/store';
import { setArSubscriptionStatus } from '@src/redux/config';
import { useSelector } from '@src/redux/useSelector';
import moment from 'moment';

function TrainingARProjectModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const arSubscriptionStatus = useSelector((state) => state.config.arSubscriptionStatus);
  const dateFormat = useSelector(
    (state) => state.config.whiteLabelDetails.dateTime.content.shortDate
  );
  const { projectData } = args;
  const [selectedTracking, setSelectedTracking] = useState(null);
  const [formError, setFormError] = useState('');
  const cancel = useCallback(() => {
    onCancel(dismiss);
  }, [dismiss]);

  const onTrainSuccessful = (trainingResponse) => {
    dispatch(setArSubscriptionStatus('TRIAL'));
    dismiss(true);
  };

  const onSubmit = async () => {
    if (!isTrainingModelsExist() && arSubscriptionStatus !== 'NONE') {
      await modalService.openModal('confirm', {
        text: 'augmented-reailty.training-modal.training-quota-error-text',
        iconType: 'attention_image',
        confirmText: 'general.close',
        headerText: 'augmented-reailty.training-modal.training-quota-error-header',
        showCloseBtn: true,
      });
      dismiss(false);
    } else if (isFormValid()) {
      const now = new Date();
      const expirationDate = moment(now).add(31, 'days').format(dateFormat.momentFormat);

      const isConfirmed = await modalService.openModal('confirm', {
        text:
          arSubscriptionStatus === 'NONE'
            ? 'augmented-reailty.training-modal.start-training-confirmation-text.trial'
            : 'augmented-reailty.training-modal.start-training-confirmation-text',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'augmented-reailty.training-modal.start-training-confirmation-header',
        showCloseBtn: true,
        maxTrainingModels: projectData.maxNumberOfTrainingModels,
        remainingTrainingModels:
          projectData.maxNumberOfTrainingModels - projectData.currentNumberOfTrainingModels,
        expirationDate: expirationDate,
      });
      if (isConfirmed) {
        trainingARProject(
          projectData.arModel.id,
          selectedTracking,
          onTrainSuccessful,
          setFormError
        );
      }
    }
  };

  const trackingOptions = [
    {
      label: i18nService.translate(
        'augmented-reailty.training-modal.tracking-option-default-label'
      ),
      description: i18nService.translate(
        'augmented-reailty.training-modal.tracking-option-default-description'
      ),
      image: '/assets/images/training/default-image.png',
      value: 'DEFAULT',
    },
    {
      label: i18nService.translate(
        'augmented-reailty.training-modal.tracking-option-low-feature-label'
      ),
      description: i18nService.translate(
        'augmented-reailty.training-modal.tracking-option-low-feature-description'
      ),
      image: '/assets/images/training/low-feature-image.png',
      value: 'LOW_FEATURE_OBJECTS',
    },
  ];

  const isFormValid = () => {
    if (selectedTracking === null) {
      setFormError(
        i18nService.translate('augmented-reailty.training-modal.training-validation-error')
      );
      return false;
    } else {
      setFormError('');
      return true;
    }
  };

  const isTrainingModelsExist = () => {
    const remainModels =
      projectData.maxNumberOfTrainingModels - projectData.currentNumberOfTrainingModels;
    if (remainModels > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    trainingARProjectService.setModalDismiss(dismiss);
  }, [dismiss]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>augmented-reailty.training-modal.header</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.trainContainer}>
          <div className={styles.section}>
            <div className={styles.header}>
              <h6>
                <I18n>augmented-reailty.training-modal.optimize-tracking</I18n>
              </h6>
              <div className={styles.textDescription}>
                <I18n>augmented-reailty.training-modal.optimize-tracking-description</I18n>
              </div>
            </div>
            <div className={styles.trackingOptions}>
              {trackingOptions.map((option, index) => (
                <div
                  key={index}
                  className={
                    styles.trackingOption +
                    ` ${selectedTracking === option.value ? styles.selected : ''}`
                  }
                  onClick={() => setSelectedTracking(option.value)}>
                  <div className={styles.trackingInfo}>
                    <h6>
                      <b>{option.label}</b>
                    </h6>
                    <p>{option.description}</p>
                  </div>
                  <img src={option.image} alt={option.label} />
                </div>
              ))}
            </div>
          </div>

          {formError && <div className={styles.validationError}>{formError}</div>}
          <div className={styles.actionsRowContainer}>
            <div className={styles.actionsRow}>
              <Button
                styles={{ marginLeft: 13, maxWidth: 250 }}
                mode={['cancel', 'bigFont']}
                onClick={cancel}>
                <I18n>general.cancel</I18n>
              </Button>
              <Button
                disabled={selectedTracking === null}
                styles={{ marginLeft: 13, maxWidth: 250 }}
                mode="bigFont"
                onClick={onSubmit}>
                <I18n>general.start</I18n>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingARProjectModal;
