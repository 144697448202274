import { Modal, Subscriber, Callback } from './modals.interface';
import { modalMap } from './modalMap';
import { Button } from './ConfirmWithMultiButtons/ConfirmWithMultiButtons.interface';
import { getState } from '@src/redux/store';

class ModalService {
  private modals: Array<Modal> = [];
  private subscribers: Array<Subscriber> = [];
  private id = 0;

  /**
   * Opens a configurable alert dialog.
   */
  openAlert(cfg: {
    text?: string;
    iconType?: string;
    btnText?: string;
    showCloseBtn?: boolean;
    headerText?: string;
    listText?: Array<string>;
  }) {
    const withDefaults = Object.assign(
      {},
      {
        text: '',
        iconType: '',
        btnText: 'general.close',
        showCloseBtn: false,
        headerText: '',
        listText: [],
      },
      cfg
    );
    return this.openModal('alert', withDefaults);
  }

  /**
   * Opens a configurable confirmation dialog.
   */
  openConfirm(
    cfg: {
      text?: string;
      iconType?: string;
      confirmText?: string;
      cancelText?: string;
      secondText?: string;
      extraText?: string;
      showCloseBtn?: boolean;
      headerText?: string;
      listText?: Array<string>;
    },
    moreInfo?
  ) {
    const withDefaults = Object.assign(
      {
        text: '',
        iconType: '',
        cancelText: 'general.cancel',
        confirmText: 'general.confirm',
        showCloseBtn: false,
        headerText: '',
        listText: [],
      },
      cfg
    );
    return this.openModal('confirm', { ...withDefaults, ...moreInfo });
  }

  /**
   * Opens a configurable confirmation With Multi Buttons dialog.
   */
  openConfirmWithMultiButtons(
    cfg: {
      text?: string;
      iconType?: string;
      showCloseBtn?: boolean;
      cancelText?: string;
      headerText?: string;
      secondText?: string;
      extraText?: string;
      multiButtons: Array<Button>;
    },
    moreInfo?
  ) {
    const withDefaults = Object.assign(
      {
        text: '',
        iconType: '',
        cancelText: 'general.cancel',
        showCloseBtn: false,
      },
      cfg
    );
    return this.openModal('ConfirmWithMultiButtons', { ...withDefaults, ...moreInfo });
  }

  /**
   * Open a modal. Receives a Component to put inside the modal, args
   * to pass into the modal (as args prop), and props for the Material-UI Dialog Component.
   * Returns a promise that resolves when the modal closes with an output value.
   */
  openModal(type: string, args: any = {}, props: any = {}): Promise<any> {
    const isPdfRender = getState().viewport.isPdfRender;
    // don't show any modal when rendering a PDF, since it will be shown over the screenshot
    // The most likely modal to open are the Phone number validation popup.
    if (isPdfRender) return;

    const cfg = modalMap[type];
    if (!cfg) {
      throw new Error('ModalService.openModal: Unrecognized modal type!');
    }
    return new Promise((resolve) => {
      this.modals = this.modals.concat({
        args,
        props: Object.assign({}, cfg.props, props),
        id: this.id++,
        Component: cfg.component,
        resolve,
      });
      this.subscribers.forEach(({ cb }) => {
        return cb(this.modals);
      });
    });
  }

  /**
   * Close a modal. Requires a dialog-id (from modals array).
   * Can supply an output value for the promise resolve of openModal().
   */
  closeModal(id: number, value: any) {
    const modal = this.modals.find((m) => m.id === id);
    this.modals = this.modals.filter((m) => m.id !== id);
    this.subscribers.forEach(({ cb }) => cb(this.modals));
    modal && modal.resolve(value);
  }

  /**
   * Close a modal. Requires a dialog-id (from modals array).
   * Can supply an output value for the promise resolve of openModal().
   */
  closeModals() {
    this.modals.forEach((m) => m.resolve(null));
    this.subscribers.forEach(({ cb }) => cb([]));
  }

  /**
   * Subscribe to changes in modals array. Returns an unsubscribe function.
   */
  subscribe(cb: Callback) {
    const ref = { cb };
    this.subscribers.push(ref);
    cb(this.modals);
    const unsubscribe = () => {
      this.subscribers = this.subscribers.filter((s) => s !== ref);
    };
    return { unsubscribe };
  }
}

/**
 * Manages the app modals. Active modals are kept in an internal array.
 */
export const modalService = new ModalService();
