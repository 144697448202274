import React, { useState, useEffect } from 'react';

// Components
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import Tooltip from '@components/Tooltip';
import Select from '@components/Select';

// Services
import { dateTimeChoice } from '@pages/CreateWidgetPage/SharedMaps';
import { i18nService } from '@core/i18n/I18nService';
import styles from '../GroupingAndMeasures.scss';
import { useSelector } from '@src/redux/useSelector';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';

// 3rd party
import classNames from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import * as cloneDeep from 'lodash/cloneDeep';
import { widgetMap } from '@core/canvas/widgetMap';
import { openConfirmInitializeWidget } from '../GroupingAndMeasures.utils';
import { modalService } from '@core/modals/ModalService';

const ThenBy = (props) => {
  return props.idx ? (
    <I18n className={classNames(styles.boxText, styles.thenBy)}>
      create-widget-page.create-widget.step-three.then-by
    </I18n>
  ) : null;
};

const TagComponent = (props) => {
  let icon = props.grouptag.type;
  if (props.grouptag.valueType === 'ASSET_PROPERTY' && props.grouptag.valueId === 3)
    icon = 'geoType';

  return (
    <div className={styles.mainTag}>
      <Icon className={styles.iconType} type={icon} />
      <I18n className={classNames(styles.tagName, 'ellipsis-overflow')}>{props.grouptag.name}</I18n>
    </div>
  );
};

function GroupBy(props) {
  const { widgetData, setWidgetData, groupBy, disabled, widgetType, eventTemplateId, scope } =
    props;
  const languageId = useSelector((state) => state.config.languageId);
  const disableGrouping = getConfigValueFromWidgetSettings(widgetType, 'disableGrouping');
  const disableGroupingText = disableGrouping?.text({ eventTemplateId, scope });
  const isGroupingDisabled = !!((!!disableGrouping && disableGroupingText) || disabled);
  const [groupBySelected, setGroupBySelected] = useState(groupBy);

  useEffect(() => {
    setGroupBySelected(groupBy);
  }, [groupBy]);

  useEffect(() => {
    ['heatmap'].includes(widgetType) &&
      setWidgetData((prevState) => ({
        ...prevState,
        sorts: groupBy.map((g) => ({ ...g, sortBy: 'ASCENDING' })),
      }));
  }, [groupBySelected]);

  const onUpdateDropdownValue = (val, idx) => {
    function doUpdate() {
      const updatedGroupBySelected = cloneDeep(groupBySelected);
      const updatedItem = updatedGroupBySelected[idx];
      updatedItem.operation = val.value;

      updatedItem.isDropDownValue = {
        value: val.value,
        label: val.label,
      };

      setWidgetData((prevState) => ({
        ...prevState,
        groupBy: updatedGroupBySelected,
        sorts: ['heatmap'].includes(widgetType)
          ? updatedGroupBySelected.map((g) => ({ ...g, sortBy: 'ASCENDING' }))
          : prevState.sorts,
        customization: null,
      }));
    }
    if (['alarms', 'table'].includes(widgetType) && widgetData?.customization) {
      modalService
        .openModal('confirm', {
          iconType: 'attention_image',
          text: 'create-widget-page.create-widget.initialize-step-four',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText: widgetData.eventTemplateId
            ? 'create-event-data-source-page.create-event-data-source.confirm-data-source-changes.header'
            : 'create-widget-page.create-widget.confirm-widget-changes.header',
          showCloseBtn: true,
        })
        .then((results) => {
          const isConfirm = results.res;
          if (isConfirm) {
            doUpdate();
          }
        });
    } else {
      doUpdate();
    }
  };

  return (
    <div className={styles.groupBy}>
      <div className={styles.infoGroup}>
        <I18n className={styles.subTitle}>
          create-widget-page.create-widget.step-three.group-by
        </I18n>
        <Tooltip
          text={
            widgetMap[widgetType]?.settings['stepThreeInfo']?.groupBy ||
            'create-widget-page.create-widget.step-three.group-by-info'
          }
          placement="right"
          children={
            <div>
              <Icon className={styles.iconInfo} type="info" />
            </div>
          }></Tooltip>
      </div>
      <Droppable droppableId="groupBy" isDropDisabled={isGroupingDisabled}>
        {(provided) => (
          <div
            className={classNames(
              'default',
              styles.groupByBox,
              isGroupingDisabled ? styles.disableMode : undefined
            )}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {groupBySelected.filter((g) => !g.hide).length <= 0 ? (
              <I18n noEllipsis className={styles.boxText}>
                {disableGrouping?.text({ eventTemplateId, scope }) ??
                  'create-widget-page.create-widget.step-three.group-box'}
              </I18n>
            ) : (
              groupBySelected.map((grouptag, idx) =>
                grouptag.hide ? null : (
                  <div key={`${idx}_${grouptag.valueType}_${grouptag.type}`}>
                    <div>
                      <ThenBy key={`thenBy${idx}`} idx={idx} />
                    </div>
                    <Draggable
                      key={`${grouptag.order}grouping`}
                      draggableId={`${grouptag.order}grouping`}
                      index={idx}
                      isDragDisabled={grouptag.isDragDisabled}>
                      {(provided) => (
                        <div
                          className={styles.groupItem}
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div className={styles.groupByContent}>
                            {!grouptag.isDragDisabled ? (
                              <div className={styles.dragIndicator} {...provided.dragHandleProps}>
                                <Icon type="drag" />
                              </div>
                            ) : null}
                            {grouptag.isDropDown ? (
                              <div className={styles.selectTag}>
                                <Select
                                  value={
                                    grouptag.isDropDownValue && {
                                      ...grouptag.isDropDownValue,
                                      label: `${i18nService.translate(
                                        grouptag.name,
                                        languageId
                                      )} | ${i18nService.translate(
                                        grouptag.isDropDownValue.label,
                                        languageId
                                      )}`,
                                    }
                                  }
                                  defaultValue={dateTimeChoice[0]}
                                  options={dateTimeChoice}
                                  onChange={(e) => onUpdateDropdownValue(e, idx)}
                                  iconName="clock_icon"
                                  menuPortalTarget={document.body}
                                />
                              </div>
                            ) : (
                              <TagComponent grouptag={grouptag} />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </div>
                )
              )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
export default GroupBy;
