import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import SingleAssetComponent from './SingleAssetComponent/SingleAssetComponent';
import I18n from '@components/I18n';
import { ComboWidgetProps } from './ComboWidget.interface';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    maxHeight: 'fit-content',
    overflow: 'auto',
    color: 'var(--widgetsFont)',
    padding: '5px',
    overflowX: 'auto',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '& :hover': {
      cursor: ({ navigateDashboard, widget }) =>
        navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
    },
  },
  gridRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  titleWrapper: {
    display: `flex`,
    flexDirection: ({ customization }: any) =>
      customization.showSymbolLeft ? 'row-reverse' : 'row',
    justifyContent: ({ customization }: any) =>
      customization.showSymbolLeft ? 'flex-end' : 'flex-start',
    flex: 1,
    alignItems: 'center',
  },
  emptyBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function ComboWidget(props: ComboWidgetProps) {
  const classes = useStyles(props);
  const { widget, widgetData, navigateDashboard, customization, isPreview = false } = props;
  const { results, columns } = props?.data;
  const { imageVisible, remoteAccessButtonsVisible } = customization;
  const wrapperRef = useRef(null);

  const [assets, setAssets] = useState([]);
  const [maxHeight, setMaxHeight] = useState<string>(undefined);

  function getMaxHeight(tagDataLength) {
    const baseHeight = 41;
    let maxHeight = baseHeight + tagDataLength * 21;
    if (remoteAccessButtonsVisible) {
      maxHeight = maxHeight + 65;
    }
    if (imageVisible) {
      maxHeight = maxHeight + 260;
    }
    return maxHeight;
  }

  useEffect(() => {
    widget?.customization?.columns?.length &&
      setMaxHeight(`${getMaxHeight(widget?.customization?.columns?.length - 3)}px`);
  }, [widget?.customization?.columns]);

  useEffect(() => {
    const assetIds = results?.map((res) => res?.alias_ASSET_PROPERTY_1_None) || [];

    httpService
      .api({
        type: 'getAssetDataForComboWidget',
        query: { assetId: assetIds },
      })
      .then((result: any[]) => {
        setAssets(result);
      });
  }, []);

  return assets?.length ? (
    <div className={classes.wrapper} ref={wrapperRef}>
      <div className={classes.gridRow}>
        {results.map((res, idx) => (
          <SingleAssetComponent
            key={idx}
            widgetData={widget || widgetData}
            assets={assets}
            data={res}
            dataColumns={columns}
            customization={customization}
            isPreview={isPreview}
            navigateDashboard={navigateDashboard}
            maxHeight={maxHeight}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className={classes.emptyBox}>
      <I18n className={classes.emptyTitle}>widget.widget-empty-label</I18n>
    </div>
  );
}

export default ComboWidget;
