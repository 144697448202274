import { openRemoteHMIorVPNpopup } from '@components/widgets/RemoteAccessWidget/RemoteAccessWidget.utils';
import {
  openAndroidAppIsAvailableModal,
  openIOSAppIsAvailableModal,
} from '@components/widgets/RemoteAccessWidget/RemoteOperatorWidget/RemoteOperatorWidget.utils';
import { httpService } from '@core/http/HttpService';

export async function getRemoteAccessUrl(assetId, type) {
  const res: any = await httpService.api({
    type: 'getRemoteAccessWidgetData',
    urlParams: {
      assetId: assetId,
      type: type,
    },
  });
  if (res && res.link) {
    const urlLink = res.link;
    return urlLink;
  }
  return null;
}

export function getIsDisabled(
  button,
  plcType,
  macchinaConnected,
  currentLayout,
  platformFlags: { isMobileApp; isDesktop; isMobile; isTablet }
) {
  if (macchinaConnected) {
    switch (button.type) {
      case 'vpn':
        if (platformFlags.isDesktop && currentLayout === 'DESKTOP') {
          return false;
        } else {
          return true;
        }
      case 'vnc':
        if (['UNISTREAM', 'LEGACY'].includes(plcType)) {
          return false;
        } else {
          return true;
        }
      case 'web':
        if (['UNISTREAM'].includes(plcType)) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }
  return true;
}

export function handleVncUnistream(clientType, vpnUrl, vncUrl) {
  if (clientType === 'uniCloudApp') {
    try {
      uniCloudApp?.startRemoteOperatorSession(vpnUrl);
    } catch (e) {
      console.log(`startRemoteOperatorSession failed: ${e}`);
    }
  } else if (clientType === 'iOSUniCloudApp') {
    try {
      window['webkit']['messageHandlers']['startRemoteOperatorSession'].postMessage(vpnUrl);
    } catch (e) {
      console.log(`startRemoteOperatorSession failed: ${e}`);
    }
  } else {
    window.open(`${vncUrl}`, '_blank');
  }
}

export function handleVncLegacy(clientType, isDesktop, url) {
  switch (clientType) {
    case 'uniCloudApp':
      try {
        uniCloudApp?.startRemoteOperatorSession(url);
      } catch (e) {
        console.log(`startRemoteOperatorSession failed: ${e}`);
      }
      break;
    case 'iOSUniCloudApp':
      try {
        window['webkit']['messageHandlers']['startRemoteOperatorSession'].postMessage(url);
      } catch (e) {
        console.log(`startRemoteOperatorSession failed: ${e}`);
      }
      break;
    default:
      const isAndroid = !!navigator.userAgent?.match(/android/gi);
      const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isAndroid) {
        let isMobileAppInstalled = false;
        if (window.navigator['getInstalledRelatedApps']) {
          window.navigator['getInstalledRelatedApps']()
            .then((res) => {
              isMobileAppInstalled = res.some((app) => app.id === 'com.unitronics.unicloud');
              openAndroidAppIsAvailableModal(isMobileAppInstalled);
            })
            .catch((error) => {
              console.log(error);
              openAndroidAppIsAvailableModal(isMobileAppInstalled);
            });
        } else {
          openAndroidAppIsAvailableModal(isMobileAppInstalled);
        }
      } else if (isiOS) {
        openIOSAppIsAvailableModal();
      } else if (isDesktop) {
        const desktopLink = `${url.replace('srauc', 'remote-operator')}`;
        openRemoteHMIorVPNpopup(desktopLink, 'hmi');
      }
      break;
  }
}

export function handleWeb(clientType, url) {
  if (clientType === 'uniCloudApp') {
    try {
      uniCloudApp?.startWebSession(url);
    } catch (e) {
      console.log(`startWebSession failed: ${e}`);
    }
  } else if (clientType === 'iOSUniCloudApp') {
    try {
      window['webkit']['messageHandlers']['startWebSession'].postMessage(url);
    } catch (e) {
      console.log(`startWebSession failed: ${e}`);
    }
  } else {
    window.open(`${url}`, '_blank');
  }
}
