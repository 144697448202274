import React, { useMemo } from 'react';
import styles from './Variables.scss';
import I18n from '@components/I18n';
import { getPermissionStatus } from '@core/ffAndPermissions';
import AssetVariablesTab from './Tabs/AssetVariablesTab';
import AssetTypeVariablesTab from './Tabs/AssetTypeVariablesTab';
import { useSelector } from '@src/redux/useSelector';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';

function Variables(props) {
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);
  const roles = useSelector((state) => state.config.roles);

  const showAssetTypesTab =
    selectedOrganizationsId == selectedMBId &&
    (roles.includes('Machine Builder Admin') ||
      (roles.includes('Machine Builder Device Manager') &&
        roles.includes('Machine Builder Organization Manager')));

  return (
    <>
      <div className={styles.assetsManagementTabs}>
        {showAssetTypesTab && (
          <NavLink
            className={styles.tab}
            activeClassName={styles.activeTab}
            to={`${props.match.url}/assettypes`}>
            <I18n>assets-management-tab.assets-types</I18n>
          </NavLink>
        )}
        <NavLink
          className={styles.tab}
          activeClassName={styles.activeTab}
          to={`${props.match.url}/assets`}>
          <I18n>assets-management-tab.assets</I18n>
        </NavLink>
      </div>
      <div className={styles.routsSection}>
        <Switch>
          {showAssetTypesTab && (
            <Route
              exact
              path={`${props.match.url}/assettypes`}
              component={() =>
                AssetTypeVariablesTab({ id: selectedOrganizationsId, hasPermission })
              }
            />
          )}
          <Route
            exact
            path={`${props.match.url}/assets`}
            component={() => AssetVariablesTab({ id: selectedOrganizationsId })}
          />
          <Redirect
            to={showAssetTypesTab ? `${props.match.url}/assettypes` : `${props.match.url}/assets`}
          />
        </Switch>
      </div>
    </>
  );
}

export default Variables;
