import { debounce, compact } from 'lodash';
import { getVar, getDefaultWidgetColorPalette } from '@core/canvas/widget.utils';
import { getFilters, getDefaultPreviewData, dateTimeOptionsMap } from '../charts.utils';
import { httpService } from '@core/http/HttpService';
import { getArrayFromObjectWithOrder, numberFormatter } from '@core/utils';
import { getState } from '@src/redux/store';

export const getValueWithDecimalDigits = (val, decimalDigits) =>
  isNaN(Number(val)) ? val : numberFormatter(+val, decimalDigits);

export const getColumnsColors = (columns) => {
  return columns?.length
    ? columns.map((col, idx) => {
        if (!!col.color) return col.color;
        else {
          const varIndex = idx < 6 ? idx : idx % 6;
          return getVar(`widgetsGraphsColorsPalette${varIndex + 1}`);
        }
      })
    : getDefaultWidgetColorPalette();
};

export const getColumnName = (data, customization, propsData, metrics, tagsData?) => {
  let newCustomization = {
    ...customization,
    columns: customization?.columns?.map((c) => ({
      ...c,
      fieldId: metrics.find((m) => m.value.value === c.id)?.value?.valueId,
    })),
  };

  if (
    propsData &&
    propsData.columns &&
    propsData.columns.length === 4 &&
    customization.columns &&
    customization.columns[0] &&
    customization.columns[0]?.name?.startsWith(
      'create-widget-page.create-widget.step-four.line.default-name'
    )
  ) {
    newCustomization = {
      ...newCustomization,
      columns: [
        {
          ...newCustomization.columns[0],
          name: propsData.columns[3].name,
          displayName:
            propsData.columns[3].name > 25
              ? propsData.columns[3].name.slice(0, 25)
              : propsData.columns[3].name,
        },
      ],
    };
  }

  const displayName = customization?.columns?.find(
    (col) => col.id === data.fieldId && col.operation === data.fieldOperation
  )?.displayName;

  let matchingMetrics = data.fieldOperation
    ? metrics.find(
        (m) =>
          m.value?.valueId === data.fieldId &&
          m.value?.operation &&
          m.value?.operation === data.fieldOperation
      )
    : metrics.find((m) => m.value?.valueId === data.fieldId);

  if ((!matchingMetrics || !matchingMetrics?.label) && propsData && propsData.metricsColumns) {
    const matchingMetricsColumn = data.fieldOperation
      ? propsData.metricsColumns.find(
          (m) => m.valueId === data.fieldId && m.operation === data.fieldOperation
        )
      : propsData.metricsColumns.find((m) => m.valueId === data.fieldId);

    if (matchingMetricsColumn) {
      matchingMetrics = {
        ...matchingMetricsColumn,
        label: displayName
          ? displayName
          : data.fieldOperation
          ? `${
              tagsData?.find((t) => t.id === matchingMetricsColumn?.valueId)?.name
            } ${data.fieldOperation.toLowerCase()}`
          : tagsData?.find((t) => t.id === matchingMetricsColumn?.valueId)?.name,
      };
    }
    if (!matchingMetrics?.label || matchingMetrics?.label?.includes('alias_TAG_')) {
      const label = tagsData
        ? tagsData.find((t) => t.id === matchingMetrics?.value?.valueId)?.name
        : data.name;
      matchingMetrics = {
        ...matchingMetrics,
        label: label,
      };
    }
  }

  const nameInLegend =
    data.fieldType === 'THRESHOLD'
      ? customization?.legend?.structure
        ? Object.values(customization?.legend?.structure)?.some((val) => val['show'])
          ? data.name
          : ''
        : data.name
      : customization?.legend?.structure
      ? getNameInLegend(
          {
            assetId: data.assetId,
            assetName: data.assetName,
            tagName: displayName || matchingMetrics?.label || data.fieldName,
          },
          customization.legend?.structure
        )
      : `${data.assetId} - ${data.assetName} - ${
          displayName || matchingMetrics?.label || data.fieldName
        }`;

  return {
    ...data,
    name: nameInLegend === '' ? ' ' : nameInLegend,
  };
};

function getNameInLegend(
  data: { assetId: number; assetName: string; tagName: string },
  legendStructure
) {
  const sortedLegenStructure = getArrayFromObjectWithOrder(legendStructure, 'item');
  const displayNames = sortedLegenStructure
    .filter((element) => element['show'])
    .map((element) => {
      return data[element['item']];
    });
  return displayNames.join(' - ');
}

export const getYaxisCustomization = (yAxes, columns, series, defaultDecimalDigits, isArea?) => {
  const isMultiple = false;
  let yAxesToShow =
    yAxes &&
    yAxes.filter(
      (y) =>
        y.show !== 'NO' &&
        series.some((s) => s.fieldId === y.id && (!y.operation || y.operation === s.fieldOperation))
    );
  if (yAxesToShow && yAxesToShow.length === 0)
    yAxesToShow = [yAxes.find((axis) => axis.show !== 'NO')];

  return yAxesToShow && yAxesToShow.length && series.length
    ? compact(
        yAxesToShow.map((x, idx) => {
          if (x.show === 'NO') {
            return null;
          } else {
            const {
              displayName,
              scale = 'AUTO',
              minValue,
              maxValue,
              decimalDigits = defaultDecimalDigits,
            } = x;

            const show = yAxesToShow.includes(x) ? x.show : 'NO';

            const seriesName = columns[idx]?.displayName;
            const yaxisName = !isMultiple
              ? x && displayName && displayName !== ''
                ? displayName
                : columns[idx]?.displayName
              : columns[idx]?.displayName;

            const yAxisItem: any = {
              show: show !== 'NO',
              seriesName,
              yAxisIndex: idx,
              tickAmount: isArea ? 3 : 5,
              axisBorder: {
                show: true,
                color: 'var(--widgetsFont)',
              },
              axisTicks: {
                show: true,
                borderType: 'solid',
                color: 'var(--widgetsFont)',
                width: 6,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                show: true,
                style: {
                  colors: isArea ? 'transparent' : 'var(--widgetsFont)',
                  fontSize: '12px',
                  fontWeight: 500,
                },
                formatter: (value) => getValueWithDecimalDigits(value, decimalDigits),
              },
              opposite: show === 'RIGHT',
              title: {
                text: yaxisName,
                style: {
                  color: isArea ? 'transparent' : 'var(--widgetsFont)',
                  fontSize: '12px',
                  fontWeight: 500,
                },
              },
            };
            if (scale === 'MANUAL' && isFinite(minValue) && isFinite(maxValue)) {
              yAxisItem.forceNiceScale = false;
              yAxisItem.min = +minValue;
              yAxisItem.max = +maxValue;
            }

            return yAxisItem;
          }
        })
      )
    : {};
};

export const getLineOptions = (
  customization,
  yaxisCustomization,
  linesColors,
  onLineLegendClicked,
  lineLegendPosition,
  onMarkerClick,
  series,
  defaultDecimalDigits
) => {
  const {
    subtitle_text,
    subtitle_textColor = 'var(--widgetsFont)',
    subtitle_alignment = 'CENTER',
    legend = {
      visible: true,
      position: 'BOTTOM',
      alignment: 'CENTER',
      structure: {
        assetId: { order: 0, show: true },
        assetName: { order: 1, show: true },
        tagName: { order: 2, show: true },
      },
    },
    tooltip_visible = true,
    tooltip_shared,
    tooltip_showAxisLegend,
    tooltip_decimalDigits = defaultDecimalDigits,
    dataLabels_visible = false,
    dataLabels_textColor = 'var(--widgetsFont)',
    dataLabels_decimalDigits = defaultDecimalDigits,
    colorPaletteMode = 'AUTO',
  } = customization;
  const delimiter = getState().config?.whiteLabelDetails?.regionalSettings?.content?.delimiter;
  const visibleThresholdLines = customization?.threshold?.filter((t) => t.show) || [];
  const dashArray = series
    .filter(
      (ser) =>
        ser.fieldType !== 'THRESHOLD' || visibleThresholdLines.some((vtl) => ser.fieldId === vtl.id)
    )
    .map((ser) => {
      if (ser.style === 'DOTTED') {
        return 2;
      } else if (ser.style === 'DASHED') {
        return 10;
      } else return 0;
    });
  return {
    chart: {
      animations: { enabled: false },
      toolbar: {
        export: {
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
      id: 'chart2',
      zoom: {
        enabled: false,
      },
      events: {
        markerClick: debounce(onMarkerClick, 500),
        legendClick: onLineLegendClicked,
      },
    },
    dataLabels: {
      enabled: dataLabels_visible,
      formatter(val, opts) {
        return getValueWithDecimalDigits(val, dataLabels_decimalDigits);
      },
      style: {
        colors: [dataLabels_textColor],
        fontSize: '12px',
        fontWeight: 500,
      },
      dropShadow: {
        enabled: false,
      },
    },
    legend: {
      show: lineLegendPosition && legend.visible,
      position: legend.position.toLowerCase(),
      horizontalAlign: legend.alignment.toLowerCase(),
      showForSingleSeries: true,
      offsetY: legend.position === 'TOP' ? 17 : 0,
      labels: {
        colors: 'var(--widgetsFont)',
        useSeriesColors: false,
      },
      markers: {
        shape: 'circle', // circle, square, line, plus, cross
        size: 5,
        strokeWidth: 0,
        radius: 2,
        offsetX: -2,
        offsetY: 0,
      },
    },
    markers: {
      shape: 'circle', // circle, square, line, plus, cross
      size: series.map((s) => (s?.data?.length === 1 ? 6 : 0)),
      strokeWidth: 2,
      fillOpacity: 1,
      radius: 2,
      offsetX: 0,
      offsetY: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2,
      dashArray: dashArray,
    },
    subtitle: {
      text: subtitle_text || '',
      align: subtitle_alignment.toLowerCase(),
      margin: 5,
      style: {
        color: subtitle_textColor,
      },
    },
    theme: {
      palette: 'palette1',
    },
    tooltip: {
      shared: tooltip_shared,
      intersect: false,
      enabled: tooltip_visible,
      y: {
        formatter(val) {
          return getValueWithDecimalDigits(val, tooltip_decimalDigits);
        },
      },
      x: {
        show: tooltip_showAxisLegend,
      },
      theme: 'apexcharts-tooltip',
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetX: 0,
        offsetY: 0,
      },
    },
    // onItemHover: {
    //   highlightDataSeries: true,
    // },
    yaxis: yaxisCustomization,
    colors: [
      function ({ value, seriesIndex, w }) {
        const currentSeries = series[seriesIndex];
        if (
          currentSeries &&
          (currentSeries?.fieldType === 'THRESHOLD' || customization.colorPaletteMode === 'MANUAL')
        ) {
          return currentSeries.color;
        } else {
          return seriesIndex > linesColors.length - 1
            ? linesColors[seriesIndex - linesColors.length + visibleThresholdLines?.length]
            : linesColors[seriesIndex];
        }
      },
    ],
    colorPaletteMode: colorPaletteMode,
  };
};

export const getAreaOptions = (
  customization,
  yaxisCustomization,
  linesColors,
  minValue,
  maxValue,
  onSelectionChange,
  onAreaLegendClicked,
  lineLegendPosition,
  dateTime,
  series
) => {
  const {
    legend = {
      visible: true,
      position: 'BOTTOM',
      alignment: 'CENTER',
      structure: {
        assetId: { order: 0, show: true },
        assetName: { order: 1, show: true },
        tagName: { order: 2, show: true },
      },
    },
    dateFormat = 'DATE',
    timeFormat = 'TIME_FORMAT_ONE',
    colorPaletteMode = 'AUTO',
  } = customization;
  const visibleThresholdLines = customization?.threshold?.filter((t) => t.show) || [];
  return {
    chart: {
      animations: { enabled: false },
      toolbar: {
        export: {
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
      id: 'chart1',
      type: 'area',
      events: {
        selection: onSelectionChange,
        legendClick: onAreaLegendClicked,
      },
      brush: {
        target: 'chart2',
        enabled: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: minValue,
          max: maxValue,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    legend: {
      show: !lineLegendPosition && legend.visible,
      position: legend.position.toLowerCase(),
      horizontalAlign: legend.alignment.toLowerCase(),
      showForSingleSeries: true,
      labels: {
        colors: 'var(--widgetsFont)',
        useSeriesColors: false,
      },
      markers: {
        shape: 'circle', // circle, square, line, plus, cross
        size: 5,
        strokeWidth: 0,
        radius: 2,
        offsetX: -2,
        offsetY: 0,
      },
    },
    markers: {
      shape: 'circle', // circle, square, line, plus, cross
      size: series.map((s) => (s?.data?.length === 1 ? 6 : 0)),
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: dateTime[dateTimeOptionsMap[dateFormat]].format,
          month: dateTime[dateTimeOptionsMap[dateFormat]].format,
          day: dateTime[dateTimeOptionsMap[dateFormat]].format,
          hour: `${dateTime[dateTimeOptionsMap[dateFormat]].format} ${
            dateTime[dateTimeOptionsMap[timeFormat]].format
          } `,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: yaxisCustomization,
    colors: [
      function ({ value, seriesIndex, w }) {
        const currentSeries = series[seriesIndex];
        if (
          currentSeries &&
          (currentSeries?.fieldType === 'THRESHOLD' || customization.colorPaletteMode === 'MANUAL')
        ) {
          return currentSeries.color;
        } else {
          return seriesIndex > linesColors.length - 1
            ? linesColors[seriesIndex - linesColors.length + visibleThresholdLines?.length]
            : linesColors[seriesIndex];
        }
      },
    ],
    colorPaletteMode: colorPaletteMode,
  };
};

export const getDataFromServer = async (
  widgetId,
  widgetData,
  widgetFilters,
  dashboardFilters,
  start = null,
  end = null,
  assetIds,
  customization,
  metrics
) => {
  const apiData: any = {};

  if (widgetId && metrics && metrics.length) {
    apiData.type = 'getTimeseriesData';
    apiData.urlParams = { widgetId };
    apiData.data = {
      ...getFilters(dashboardFilters),
      widgetFilters: widgetFilters || [],
      metrics: metrics || [],
    };
  } else {
    if (widgetData) {
      apiData.type = 'getTimeseriesDataPreview';
      apiData.data = {
        ...getDefaultPreviewData(widgetData),
      };
    }
  }

  const isCanvasMode =
    window.location.pathname.includes('edit-dashboard') &&
    !window.location.pathname.includes('preview');

  apiData.data = {
    ...apiData.data,
    start,
    end,
    assetIds,
    sampleSize: customization?.dataLabels_visible || isCanvasMode ? 50 : 500,
  };

  apiData.query = {
    p: 1,
    ps: assetIds.length * (customization?.dataLabels_visible || isCanvasMode ? 50 : 500),
  };

  if (
    !apiData?.data?.dashboardFilters?.organizationsFilter ||
    !apiData?.data?.dashboardFilters?.assetTypesFilter ||
    !apiData?.data?.dashboardFilters?.assetFilter ||
    !apiData?.data?.dashboardFilters?.geoFilter
  ) {
    return {};
  }

  if (
    widgetData?.scope !== 'AGGREGATED_DATA' ||
    (widgetData?.scope === 'AGGREGATED_DATA' &&
      !widgetData?.metrics.some((m) => m.valueType !== 'CALCULATION' && !m.operation))
  ) {
    try {
      const res: any = await httpService.api({ ...apiData, disableBI: true });
      if (res) {
        return res;
      }
    } catch (e) {
      console.log({ e });
    }
  }
};

export const getAssetsAndMetricsListFromServer = async (
  widgetId,
  widgetData,
  widgetFilters,
  dashboardFilters
) => {
  const apiData: any = {};

  if (widgetId) {
    apiData.type = 'getTimeseriesAssetsAndMetricsList';
    apiData.urlParams = { widgetId };
    apiData.data = {
      ...getFilters(dashboardFilters),
      widgetFilters: widgetFilters || [],
    };
  } else {
    //preview
    apiData.type = 'getTimeseriesAssetsAndMetricsListPreview';
    apiData.data = {
      ...getDefaultPreviewData(widgetData),
    };
  }

  if (
    !apiData?.data?.dashboardFilters?.organizationsFilter ||
    !apiData?.data?.dashboardFilters?.assetTypesFilter ||
    !apiData?.data?.dashboardFilters?.assetFilter ||
    !apiData?.data?.dashboardFilters?.geoFilter
  ) {
    return {};
  }

  if (
    widgetData?.scope !== 'AGGREGATED_DATA' ||
    (widgetData?.scope === 'AGGREGATED_DATA' &&
      !widgetData?.metrics.some((m) => m.valueType !== 'CALCULATION' && !m.operation))
  ) {
    try {
      const res: any = await httpService.api({ ...apiData, disableBI: true });

      if (res) {
        return res;
      }
    } catch (e) {}
  } else {
    return {};
  }
};

export function getSeriesWithThreshold(customization, series, widgetData, propsData, metrics) {
  let thresholdSeries = [];
  if (customization.threshold?.length && customization.threshold?.some((t) => t.show)) {
    const lowestTimestamp = Math.min(...series?.map((ser) => ser?.data[0][0]));
    const highestTimestamp = Math.max(...series?.map((ser) => ser?.data[ser?.data?.length - 1][0]));

    if (series?.some((ser) => ser.data?.length)) {
      thresholdSeries = customization.threshold
        .filter((t) => t.show)
        .map((th) => {
          return {
            fieldId: th.id,
            fieldName: th.displayName,
            fieldOperation: null,
            fieldType: 'THRESHOLD',
            color: th.color,
            style: th.style,
            name: th.displayName,
            data: [
              [lowestTimestamp, th.value],
              [highestTimestamp, th.value],
            ],
          };
        });
    }
  }

  const tagsData = Array.isArray(widgetData?.tags)
    ? [
        ...widgetData?.tags,
        ...widgetData?.tagTypes,
        ...widgetData?.variables,
        ...widgetData?.localTags,
      ]
    : [];

  return [...thresholdSeries, ...series].map(
    (data) => widgetData && getColumnName(data, customization, propsData, metrics, tagsData)
  );
}
