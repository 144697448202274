import React, { useState, useMemo } from 'react';
import { default as ReactSelect, components } from 'react-select';
import styles from './Select.scss';
import CreatableSelect from 'react-select/creatable';
import { optionsMap } from './optionsMap';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { handleChange, handleBlur, onChangedInput } from './Select.utils';

const icon = (props) =>
  (props.iconName || props.selectType == 'icons' || props.selectType === 'onlyIcons') && {
    alignItems: 'center',
    display: 'flex',

    ':before': {
      content: `url(${
        props.iconName
          ? `/assets/images/${props.iconName}.png`
          : props.value?.extraName || props.value?.url
      })`,
      width: 30,
      height: 20,
      marginTop: props.selectType === 'onlyIcons' ? -4 : 2,
    },
  };

const clearButtonVisibility = (props) => {
  const { showClearButton = ShowClearButton.default } = props;

  if (
    showClearButton === ShowClearButton.default ||
    showClearButton === ShowClearButton.false ||
    showClearButton === ShowClearButton.falseButAllowClearByBackSpace
  ) {
    return 'hidden';
  }

  return 'visible';
};

const isClearable = (props): boolean => {
  const { showClearButton = ShowClearButton.default, isCreatable = false } = props;

  if (
    (showClearButton === ShowClearButton.default && isCreatable) ||
    showClearButton === ShowClearButton.true ||
    showClearButton === ShowClearButton.falseButAllowClearByBackSpace
  )
    return true;

  return false;
};

const customStyles = (props) => {
  // You can use the 'styles' prop to override the styling of the component.
  const {
    control,
    menu,
    option,
    indicatorsContainer,
    indicatorSeparator,
    clearIndicator,
    container,
    placeholder = {},
    singleValue = {},
    input,
    menuPortal,
  } = props.styles || {};

  const clearIndicatorVisibility = clearButtonVisibility(props);

  return {
    control: () => ({
      height: control?.height ? control.height : 28,
      borderRadius: 5,
      border: `solid 1px ${
        props.errorStyle || props.borderError ? 'var(--formikErrorBorder)' : 'var(--lightGrey)'
      }`,
      marginLeft: control?.marginLeft ? control?.marginLeft : 0,
      paddingLeft: 5,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: props.errorStyle ? 'var(--formikErrorBackground)' : 'var(--white1)',
      ...control,
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      zIndex: 9999999999999,
      ...menu,
    }),
    menuPortal: (base) => ({
      ...base,
      ...menuPortal,
    }),
    valueContainer: (base) => ({
      ...base,
      height: 'inherit',
      whiteSpace: 'nowrap',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 12,
      overflow: 'hidden',
      backgroundColor: state.isSelected
        ? 'var(--multiSelectOptionSelected)'
        : state.isFocused
        ? 'var(--multiSelectOptionFocused)'
        : 'var(--white1)',
      color: 'var(--systemFont)',
      opacity: state.isDisabled ? 0.5 : 1,
      ...option,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 'inherit',
      ...indicatorsContainer,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      ...indicatorSeparator,
    }),
    clearIndicator: (base) => ({
      ...base,
      visibility: clearIndicatorVisibility,
      ...clearIndicator,
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
      ...container,
      opacity: props.disabled ? 0.5 : 1,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 12,
      color: 'var(--systemFont)',
      opacity: 0.5,
      ...placeholder,
    }),
    singleValue: (provided) =>
      props.iconName || props.selectType === 'icons' || props.selectType === 'onlyIcons'
        ? {
            ...provided,
            marginLeft: props.selectType === 'icons' ? 40 : 27,
            fontSize: 12,
            color: 'var(--systemFont)',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: props.selectType === 'onlyIcons' ? 'none' : 'unset',
            overflow: 'hidden',
            ...singleValue,
          }
        : {
            ...provided,
            fontSize: 12,
            color: 'var(--systemFont)',
            visibility: 'visible',
            ...singleValue,
          },
    input: (provided) => ({
      ...provided,
      ...icon(props),
      ...input,
      padding: 0,
      margin: 0,
      color: 'var(--systemFont)',
    }),
  };
};

const SingleValue = (props) => {
  return (
    // Usually, the title (tooltip) will be the same as the displayed value.
    // In order to pass a different tooltip, add a 'tooltip' property to the relevant options in the options array.
    // E.g.: [ {label: 'some label', value: 'some value', tooltip: 'some tooltip} ]
    <div title={i18nService.translate(props.data.tooltip) || props.children || ''}>
      <components.SingleValue {...props}>{props.children}</components.SingleValue>
    </div>
  );
};

export enum ShowClearButton {
  default,
  true,
  false,
  falseButAllowClearByBackSpace,
}

function Select(props) {
  const {
    onChange,
    onInputChanged,
    getOptionLabel,
    getOptionValue,
    options,
    value,
    defaultValue,
    disabled,
    filterOption,
    placeholder,
    maxMenuHeight,
    defaultMenuIsOpen,
    menuPortalTarget,
    isCreatable = false,
    creatableValidation,
    onBlur,
    unSelect,
    inputValue,
    onFocus,
    blurInputOnSelect,
    showClearButton = ShowClearButton.default,
    isOptionDisabled = () => false,
    ...restProps
  } = props;
  const [prevValue, setPrevValue] = useState(defaultValue);
  const languageId = useSelector((state) => state.config.languageId);

  const renderedPlaceholder = useMemo(
    () => (placeholder ? i18nService.translate(placeholder, languageId) : ''),
    [placeholder, languageId]
  );

  const selectValue = useMemo(() => {
    if (value && !getOptionLabel) {
      return { ...value, label: i18nService.translate(value.label) };
    }
    return value;
  }, [value, languageId]);

  const defaultGetOptionLabel = (option) => i18nService.translate(option.label);

  return !isCreatable ? (
    <ReactSelect
      components={
        restProps.selectType
          ? {
              SingleValue,
              Option: optionsMap[restProps.selectType],
            }
          : { SingleValue }
      }
      placeholder={renderedPlaceholder || ''}
      className={styles.Select}
      onChange={(selectedVal) =>
        handleChange(selectedVal, prevValue, onChange, setPrevValue, unSelect)
      }
      value={selectValue}
      filterOption={filterOption}
      menuPortalTarget={menuPortalTarget}
      backspaceRemovesValue
      isClearable={isClearable(props)}
      defaultValue={defaultValue}
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
      options={options}
      styles={customStyles(props)} // To set the width, pass the following in props: styles={{ container: { width: '110px' } }}
      maxMenuHeight={maxMenuHeight}
      defaultMenuIsOpen={defaultMenuIsOpen}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      getOptionValue={getOptionValue}
      onInputChange={(value) => onChangedInput(value, onInputChanged)}
      onBlur={() => handleBlur(onBlur)}
      noOptionsMessage={() => null}
      inputValue={inputValue}
      onFocus={onFocus}
      blurInputOnSelect={blurInputOnSelect}
      menuPlacement={'auto'}
    />
  ) : (
    <CreatableSelect
      inputValue={inputValue}
      onFocus={onFocus}
      blurInputOnSelect={blurInputOnSelect}
      isClearable={isClearable(props)}
      placeholder={renderedPlaceholder || ''}
      className={styles.Select}
      menuPortalTarget={menuPortalTarget}
      backspaceRemovesValue
      defaultValue={defaultValue}
      isDisabled={disabled}
      styles={customStyles(props)} // To set the width, pass the following in props: styles={{ container: { width: '110px' } }}
      maxMenuHeight={maxMenuHeight}
      defaultMenuIsOpen={defaultMenuIsOpen}
      value={selectValue}
      onChange={(selectedVal) =>
        handleChange(selectedVal, prevValue, onChange, setPrevValue, unSelect)
      }
      onCreateOption={(e) => {
        creatableValidation
          ? creatableValidation(e) && onChange({ label: e, value: e })
          : onChange({ label: e, value: e });
      }}
      onInputChange={(value) => onChangedInput(value, onInputChanged)}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      getOptionValue={getOptionValue}
      onBlur={() => handleBlur(onBlur)}
      menuPlacement={'auto'}
    />
  );
}

export default Select;
