import { modalService } from '@core/modals/ModalService';

export const validateWidgetName = (e, setWidgetData) => {
  const widgetName = e.target.value;
  widgetName.length <= 100 &&
    setWidgetData((prevState) => ({
      ...prevState,
      name: widgetName,
    }));
};

export const setInitializeCustomization = (customization, setWidgetData) => {
  !customization &&
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        // hideWidgetFrame: false,
        // hideWidgetName: false,
        ...prevState.customization,
      },
    }));
};

const onSideEffectCfgChangedKey = (
  sideEffectCfg,
  widgetData,
  widgetType,
  setWidgetData,
  onChangeStep
) => {
  modalService
    .openModal('modalWithSelect', {
      options: sideEffectCfg.selectCfg.options
        ? sideEffectCfg.selectCfg.options(widgetData)
        : widgetData[sideEffectCfg.changedKey],
      widgetType,
      ...sideEffectCfg,
    })
    .then((newData) => {
      if (newData) {
        const tempNewData = sideEffectCfg.generateSelectedData
          ? sideEffectCfg.generateSelectedData(newData)
          : newData;
        setWidgetData((prevState) => ({
          ...prevState,
          type: widgetType,
          sorts: [],
          filters: [],
          customization: null,
          [sideEffectCfg.changedKey]: sideEffectCfg.selectCfg.isMulti ? tempNewData : [tempNewData],
        }));
        sideEffectCfg.goToStep && onChangeStep(null, sideEffectCfg.goToStep);
      }
    });
};

const onSideEffectCfgChangedData = (
  sideEffectCfg,
  setWidgetData,
  widgetType,
  onChangeStep,
  widgetData
) => {
  const changeData = sideEffectCfg.getNewData ? sideEffectCfg.getNewData(widgetData) : {};
  setWidgetData((prevState) => ({
    ...prevState,
    type: widgetType,
    sorts: [],
    filters: [],
    groupBy: [],
    metrics: [],
    calculations: [],
    customization: null,
    ...changeData,
  }));
  sideEffectCfg.goToStep && onChangeStep(null, sideEffectCfg.goToStep);
};

export const onSimilarWidgetSelect = (similarWidgets, props, widgetType) => {
  const { widgetData, setWidgetData, onChangeStep } = props;
  const { scope } = widgetData;
  // getting the new type's configuration object
  const newTypeConfig = similarWidgets(scope).find((widget) => widget.widgetType === widgetType);
  // getting widget type change side effect obj
  const sideEffectCfg =
    newTypeConfig && newTypeConfig.getSideEffectCfg && newTypeConfig.getSideEffectCfg();
  // when selected similar widget sometimes there are sideeffect and we need to take care of it.
  if (sideEffectCfg && sideEffectCfg.condition(widgetData)) {
    if (sideEffectCfg.changedKey) {
      onSideEffectCfgChangedKey(sideEffectCfg, widgetData, widgetType, setWidgetData, onChangeStep);
    } else {
      onSideEffectCfgChangedData(
        sideEffectCfg,
        setWidgetData,
        widgetType,
        onChangeStep,
        widgetData
      );
    }
  } else {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: null,
      type: widgetType,
    }));
  }
};

export const setExportFormat = (exportFormats, format, setWidgetData) => {
  let newExportFormats = [...exportFormats];
  if (exportFormats.includes(format)) {
    newExportFormats = newExportFormats.filter((f) => f !== format);
  } else {
    newExportFormats.push(format);
  }

  setWidgetData((prevState) => ({
    ...prevState,
    exportFormats: newExportFormats,
  }));
};
