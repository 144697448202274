import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Popover } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import AddCircle from '@material-ui/icons/AddCircle';
import I18n from '@components/I18n';
import Button from '@components/Button';
import { dashboardService } from '@core/canvas/DashboardService';
import { useSelector } from '@redux/useSelector';
import { UserFilterType } from '@redux/redux.interface.d';
import styles from './FiltersWidgetAddFilterButton.scss';
import MaterialCheckbox from '@components/Checkbox';

const addIconS = { marginRight: 3 };
const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' };

/**
 * The user-filters widget is docked to the top of the canvas.
 */
function FiltersWidgetAddFilterButton() {
  const filters = useSelector((state) => state.dashboardEditor.filters);
  const popoverAnchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const resolveClosePopover = useRef(null);
  const options = useMemo(() => filters.filter((f) => !f.visible), [filters]);

  const initialValues = useMemo(() => {
    const values = {};
    options.forEach((opt) => {
      values[opt.filterType] = false;
    });
    return values;
  }, [options]);

  const openPopover = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closePopover = useCallback(() => {
    return new Promise((resolve) => {
      // The promise is resolved when onExited fires.
      resolveClosePopover.current = resolve;
      setIsOpen(false);
    });
  }, []);

  const onExited = useCallback(() => {
    resolveClosePopover.current();
  }, []);

  const onSubmit = useCallback((values) => {
    closePopover().then(() => {
      const filterTypes = Object.keys(values).filter((key) => values[key]) as UserFilterType[];
      dashboardService.addFiltersToFiltersWidget(filterTypes);
    });
  }, []);

  return (
    <>
      <div className={styles.btnWrapper} onClick={openPopover}>
        <div className={styles.btnInner} ref={popoverAnchorEl}>
          <AddCircle color="primary" style={addIconS} />
          <I18n>filters-widget.add-btn</I18n>
        </div>
      </div>
      <Popover
        open={isOpen}
        anchorEl={popoverAnchorEl.current}
        anchorOrigin={anchorOrigin}
        onClose={closePopover}
        onExited={onExited}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          isInitialValid={false}
          validate={validateForm}>
          {(formik) => (
            <Form>
              <div className={styles.options}>
                {options.map((opt) => (
                  <Field key={opt.filterType} name={opt.filterType}>
                    {({ field }) => (
                      <div className={styles.option}>
                        <MaterialCheckbox
                          color="default"
                          name={field.name}
                          checked={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                        <I18n className={styles.label}>{`filters-widget.${opt.filterType}`}</I18n>
                      </div>
                    )}
                  </Field>
                ))}
              </div>
              <div className={styles.submitBtnRow}>
                <Button
                  type="submit"
                  styles={{ marginLeft: 10 }}
                  mode="bigFont"
                  disabled={!formik.isValid}>
                  <I18n>general.done</I18n>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Popover>
    </>
  );
}

/**
 * Will mark the form as invalid only when all options are unchecked.
 */
function validateForm(values) {
  const someAreChecked = Object.keys(values).some((key) => values[key]);
  const errors = {};
  if (!someAreChecked) {
    Object.keys(values).forEach((key) => {
      errors[key] = 'Required';
    });
  }
  return errors;
}

export default React.memo(FiltersWidgetAddFilterButton);
