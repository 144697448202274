import { useSelector } from '@src/redux/useSelector';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import classNames from 'classnames';
import styles from './TwoFactorAuthenticationPage.scss';
import { Button } from '@material-ui/core';
import I18n from '@components/I18n';
import NewLogo from '@components/NewLogo';
import { onSubmit, resendCode } from './TwoFactorAuthenticationPage.utils';
import * as Yup from 'yup';
import TwoFAInput from './TwoFAInput';
import { i18nService } from '@core/i18n/I18nService';
import { authService } from '@core/auth/AuthService';
import { CAPTCHA_SCRIPT_URL } from '@core/utils';

const codeValidationRegex = /^\d{6}$/;

const initialValues = {
  code: '',
};

const validationSchema = Yup.object().shape({
  code: Yup.string().trim().required().matches(codeValidationRegex),
});

function TwoFactorAuthenticationPage() {
  const { isLoggedIn, twoFaStatus, userOrganizationId, user_status } = useSelector(
    (state) => state.login
  );
  const { mfaPreferredMethod = 'EMAIL' } = twoFaStatus;
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const [isCodeValidationFailed, setIsCodeValidationFailed] = useState(false);

  const recaptchaRef = useRef(null);
  const host = window.location.protocol + '//' + window.location.host;
  const apiKey = authService.getReCaptchaAPIKey(
    true,
    host.includes('localhost') ? process.env.BASE_URL : host
  );

  const [grecaptcha, setGrecaptcha] = useState('');

  const onFormChange = () => {
    setIsCodeValidationFailed(false);
  };

  useEffect(() => {
    const reCAPTCHAscript = document.createElement('script');
    reCAPTCHAscript.type = 'text/javascript';
    reCAPTCHAscript.src = CAPTCHA_SCRIPT_URL;
    (reCAPTCHAscript.defer = true), document.head.appendChild(reCAPTCHAscript);

    setGrecaptcha(window['grecaptcha']);
  }, []);

  return (
    <>
      {!isLoggedIn ? <Redirect to="/login" /> : null}
      {isLoggedIn && user_status === 'PRE_ACTIVATED' ? (
        <Redirect to="/terms-and-conditions" />
      ) : null}
      {isLoggedIn && twoFaStatus.isCompleted ? <Redirect to="/main" /> : null}

      {isLoggedIn && user_status === 'ACTIVE' && !userOrganizationId ? (
        <Redirect to="/select-organization" />
      ) : null}
      <div
        className={classNames(
          !isMobileView && 'max-height',
          styles.mfaPage,
          isMobileView && styles.mfaPageMobile
        )}>
        <div
          className={'g-recaptcha'}
          data-sitekey={apiKey}
          data-size={'invisible'}
          data-action={'MFA_VERIFY'}></div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values, setIsCodeValidationFailed, grecaptcha)}>
          {({ isValid }) => (
            <Form
              onChange={onFormChange}
              className={classNames(styles.mfaForm, isMobileView && styles.mobileMode)}>
              <div className={classNames(styles.logoWrapper, isMobileView && styles.mobileMode)}>
                <NewLogo />
              </div>
              <I18n className={styles.headerWrapper} element="div">
                2fa-page.header
              </I18n>
              <I18n
                className={styles.textWrapper}
                mfaMethod={i18nService.translate(`2fa-page.${mfaPreferredMethod?.toLowerCase()}`)}
                element="div">
                2fa-page.text
              </I18n>
              <div className={styles.mfaContent}>
                <TwoFAInput
                  name="code"
                  label="2fa-page.code"
                  className={styles.label}
                  trimOnBlur
                  hasError={isCodeValidationFailed}
                />
                {isCodeValidationFailed && (
                  <I18n noEllipsis className={styles.error} element="div">
                    2fa-page.code-validation-failed
                  </I18n>
                )}
                <Button
                  className={classNames(styles.link, isMobileView && styles.mobileMode)}
                  onClick={() => resendCode(mfaPreferredMethod)}>
                  <I18n
                    element="div"
                    mfaMethod={i18nService.translate(
                      `2fa-page.${mfaPreferredMethod?.toLowerCase()}`
                    )}>
                    2fa-page.resend-code
                  </I18n>
                </Button>
                <Button
                  className={classNames(styles.submit, isMobileView && styles.submitMobile)}
                  type="submit"
                  variant="contained"
                  disabled={!isValid}>
                  <I18n>2fa-page.continue</I18n>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default TwoFactorAuthenticationPage;
