import * as cloneDeep from 'lodash/cloneDeep';
import * as isEqual from 'lodash/isEqual';

export const handleChange = (selectedVal, prevValue, onChange, setPrevValue, unSelect) => {
  const prevVal = cloneDeep(prevValue);
  onChange(selectedVal, prevVal);
  if (isEqual(selectedVal, prevVal) && unSelect) {
    onChange(null, prevVal);
    setPrevValue(null);
    return;
  }
  setPrevValue(selectedVal);
};

export const onChangedInput = (value, onInputChanged) => {
  onInputChanged ? onInputChanged(value) : null;
};

export const handleBlur = (onBlur) => {
  onBlur && onBlur();
};
