import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { cloneDeep, isEqual, isEmpty } from 'lodash';
import Audience from './Audience/Audience';
import DataSources from './DataSources/DataSources';
import Details from './Details/Details';
import {
  eventTemplateAudienceValidation,
  eventTemplateDataSourcesValidation,
  eventTemplateDetailsValidation,
  eventTemplateMessageValidation,
  eventTemplateTriggerValidation,
  localToServer,
  serverToLocal,
  updateStepsStatus,
} from './EditTemplate.utils';
import Message from './Message/Message';
import TemplateWizard from './TemplateWizard/TemplateWizard';
import { TemplateWizardStep } from './TemplateWizard/TemplateWizard.interface';
import TemplateWizardFooter from './TemplateWizardFooter/TemplateWizardFooter';
import Trigger from './Trigger/Trigger';
import { EventTemplateData } from '@core/http/server.interface';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { useSelector } from '@src/redux/useSelector';
import { alarmOnlyMentionHtmlRegex } from './Message/RichTextEditor/RichTextEditor.utils';

export const defaultSteps: TemplateWizardStep[] = [
  {
    stepIndex: 1,
    title: 'events-template-side-bar.details',
    isDone: false,
    isDisable: false,
    step: 'DETAILS',
    component: Details,
    validation: eventTemplateDetailsValidation,
  },
  {
    stepIndex: 2,
    title: 'events-template-side-bar.trigger',
    isDone: false,
    isDisable: true,
    step: 'TRIGGER_SETTINGS',
    component: Trigger,
    validation: eventTemplateTriggerValidation,
  },
  {
    stepIndex: 3,
    title: 'events-template-side-bar.data-sources',
    isDone: false,
    isDisable: true,
    step: 'DATA_SOURCES',
    component: DataSources,
    validation: eventTemplateDataSourcesValidation,
  },
  {
    stepIndex: 4,
    title: 'events-template-side-bar.message',
    isDone: false,
    isDisable: true,
    step: 'MESSAGE_FORMAT',
    component: Message,
    validation: eventTemplateMessageValidation,
  },
  {
    stepIndex: 5,
    title: 'events-template-side-bar.audience',
    isDone: false,
    isDisable: true,
    step: 'AUDIENCE',
    component: Audience,
    validation: eventTemplateAudienceValidation,
  },
];

const useStyles = makeStyles((theme) => ({
  createTemplatePage: {
    height: '100%',
  },
  templateBody: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: 'var(--systemScreenBackground)',
    height: 'calc(100% - 82px)',
    flexDirection: 'column',
  },
  templateDataPreviewContainer: {
    flexGrow: 1,
    height: 'calc(100% - 68px)',
    overflowY: 'auto',
  },
  templateDataPreviewBody: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
  },

  templatePreviewContainer: {
    paddingLeft: 26,
    width: '50%',
  },
}));

const steps = [1, 2, 3, 4, 5];

function EditTemplate(props) {
  const location = useLocation();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [templateData, setTemplateData] = useState({} as EventTemplateData);
  const classes = useStyles(props);
  const [initialData, setInitialData] = useState({} as EventTemplateData);
  const [stepsList, setStepsList] = useState(defaultSteps);
  const currentStepData = stepsList[currentStep - 1];
  const [nameValidationError, setNameValidationError] = useState(false);
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const [showFooter, setShowFooter] = useState(true); // When there is a sub-wizard, then only its footer should be visible
  const [startOfEdit, setStartOfEdit] = useState(false);
  const [rnd, setRnd] = useState(Math.random());

  useEffect(() => {
    // @ts-ignore
    if (!location.state?.templateData) {
      history.push(`/main/events-management/templates/`);
    } else {
      setInitialData({
        // @ts-ignore
        ...location.state?.templateData,
      });
      setTemplateData({
        // @ts-ignore
        ...location.state?.templateData,
      });
      setStartOfEdit(true);
      // @ts-ignore
      let state = { ...history.location.state };

      delete state.templateData;
      history.replace({ ...history.location, state });
    }
  }, []);

  useEffect(() => {
    if (templateData && !isEmpty(templateData)) {
      const tempStepList = cloneDeep(stepsList);
      const templateStep = tempStepList.find((step) => step.step === templateData.step)?.stepIndex;
      updateStepsStatus(tempStepList, Math.max(currentStep, templateStep), templateData);
      setStepsList(tempStepList);

      if (startOfEdit) {
        const selectedStep =
          tempStepList.find((step) => !step.isDone) || tempStepList[templateStep.lengh - 1];
        setCurrentStep(selectedStep.stepIndex);
        setStartOfEdit(false);
        updateTemplateData(selectedStep.step, 'step');
      }
    }
  }, [currentStep, templateData, startOfEdit]);

  const getPage = (currentStep, context) => {
    const action = context == 'NEXT' ? 1 : -1;
    const stepIdx = steps.findIndex((step) => step == currentStep);
    const stepToReturn = steps[stepIdx + action];

    return stepToReturn;
  };

  const next = async () => {
    let res = true;
    if (!isEqual(initialData, templateData) || !templateData.id)
      res = await onSaveTemplate(templateData);

    if (res) setCurrentStep(getPage(currentStep, 'NEXT'));
  };

  const prev = () => {
    setCurrentStep(getPage(currentStep, 'PREV'));
  };

  const validateTemplateName = async () => {
    const res: any = await httpService.api({
      type: 'validateEventTemplateName',
      data: { name: templateData.name },
    });

    setNameValidationError(!res.valid);
    return res.valid;
  };

  const enableSaveAsDraft = useCallback((templateData: EventTemplateData) => {
    return eventTemplateDetailsValidation(templateData);
  }, []);

  const onSaveTemplate = useCallback(
    async (templateData, goBack: boolean = false, isFinish: boolean = false) => {
      const doneSteps = stepsList.filter((step) => step.isDone);
      let lastStep = stepsList[0].step;
      const newTemplateData = { ...templateData };
      if (doneSteps.length) {
        if (doneSteps[doneSteps.length - 1].stepIndex < 5)
          lastStep = stepsList.find(
            (step) => step.stepIndex == doneSteps[doneSteps.length - 1].stepIndex + 1
          ).step;
        else lastStep = doneSteps[doneSteps.length - 1].step;
      }

      if (!newTemplateData?.id) {
        // need to create a new template
        if (await validateTemplateName()) {
          const res: any = await httpService.api({
            type: 'createEventTemplate',
            data: localToServer({ ...newTemplateData, step: lastStep }),
          });
          const newLocal = serverToLocal(res, []);

          setInitialData(newLocal);
          setTemplateData(newLocal);

          goBack && history.goBack();

          return true;
        }
      } else {
        let canSave = true;
        if (initialData?.name !== newTemplateData.name) {
          canSave = await validateTemplateName();
        }

        if (canSave) {
          const serverTemplateData = {
            ...localToServer(newTemplateData),
            step: lastStep,
            isFinish: isFinish,
          };

          if (serverTemplateData.isFinish) {
            // If template contains table/alarms data sources, then attachment type must be set, else it must be null
            // It could have been easier if the attachmentType was not nullable and did not have to be null when there are no alarms or tables.

            // First get all the related data sources for this event template:
            const dataSource: any = await httpService.api({
              type: 'getEventTemplateDataSources',
              urlParams: { templateId: templateData?.id },
            });
            if (dataSource) {
              const hasAttachment = dataSource.some(
                (d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2
              );
              if (!serverTemplateData.attachmentType && hasAttachment)
                serverTemplateData.attachmentType = 'ATTACHED';
              else if (serverTemplateData.attachmentType && !hasAttachment)
                serverTemplateData.attachmentType = null;
            } else serverTemplateData.attachmentType = null;
          }

          const res: any = await httpService.api({
            type: 'updateEventTemplate',
            urlParams: { templateId: newTemplateData.id },
            data: serverTemplateData,
          });

          const dataSources = res.dataSources || [];
          const newLocal = serverToLocal(res, dataSources);
          setInitialData(newLocal);
          setTemplateData(newLocal);

          goBack && history.goBack();

          return true;
        }
      }

      return false;
    },
    [history, stepsList]
  );

  const onChangeStep = async (from, to) => {
    if (to > from)
      if (!isEqual(initialData, templateData) || !templateData.id) {
        if (!(await onSaveTemplate(templateData))) return;
      }

    if (nameValidationError) setNameValidationError(false);

    setCurrentStep(to);

    if (!showFooter && from !== to) setShowFooter(true);
  };

  const cancel = (history) => {
    history.goBack();
  };

  const updateTemplateData = async (value, key, blur = false) => {
    let newSubject = templateData.subject;
    let newBody = templateData.body;

    if (key === 'trigger' && templateData.trigger === 'ALARM') {
      if (
        alarmOnlyMentionHtmlRegex.test(templateData.subject || '') ||
        alarmOnlyMentionHtmlRegex.test(templateData.body || '')
      ) {
        const confirm = await modalService.openModal('confirm', {
          style: { width: 589 },
          headerText: 'events-template.edit-template.confirm-trigger-type-change',
          text: 'events-template.edit-template.alarm-related-data-sources-will-be-removed',
          cancelText: 'general.cancel',
          confirmText: 'general.confirm',
          showCloseBtn: true,
        });

        if (confirm) {
          newSubject = templateData.subject
            ? templateData.subject.replace(alarmOnlyMentionHtmlRegex, '')
            : templateData.subject;

          newBody = templateData.body
            ? templateData.body.replace(alarmOnlyMentionHtmlRegex, '')
            : templateData.body;
        } else value = 'ALARM';
      }
    }

    setTemplateData((prev) => {
      let newTemplateData = {
        ...prev,
        [key]: value,
      };

      if (key === 'isSpecificOrg') {
        newTemplateData = {
          ...newTemplateData,
          orgScope: value
            ? newTemplateData.orgScope.length
              ? [newTemplateData.orgScope[0]]
              : ['MACHINE_BUILDER']
            : newTemplateData.orgScope,
          specificOrgId: value ? organizationDetails.id : null,
        };
      }

      if (key === 'orgScope') {
        newTemplateData = {
          ...newTemplateData,
          specificOrgId: null,
        };
      }

      if (key === 'timeFrame') {
        newTemplateData.daysOfWeek = null;
        newTemplateData.dayOfMonth = null;
        newTemplateData.monthOfYear = null;
        newTemplateData.dayOfYear = null;

        if (value === 'WEEKLY') {
          newTemplateData = {
            ...newTemplateData,
            daysOfWeek: ['MONDAY'],
          };
        }
      }

      if (key === 'trigger' && prev.trigger !== value) {
        newTemplateData = {
          ...newTemplateData,
          conditionTree: null,
          condition: null,
          conditionTagTypes: null,
          conditionTags: null,
          conditionVariables: null,
          conditionAssetTypes: null,
        };

        if (prev.trigger === 'ALARM') {
          newTemplateData = {
            ...newTemplateData,
            subject: newSubject,
            body: newBody,
          };
        }
      }

      return newTemplateData;
    });
  };

  return (
    <div className={classes.createTemplatePage}>
      {/* <Prompt message={leavePageGuard} /> */}
      <>
        <TemplateWizard
          currentStep={currentStep}
          onUserChangeStep={onChangeStep}
          templateData={templateData}
          stepsList={stepsList}
        />
        <div className={classes.templateBody}>
          <div className={classes.templateDataPreviewContainer}>
            <currentStepData.component
              setTemplateData={updateTemplateData}
              templateData={templateData}
              validation={currentStepData?.validation}
              nameValidationError={nameValidationError}
              setShowFooter={setShowFooter}
              onSaveTemplate={onSaveTemplate}
            />
          </div>
          {showFooter && (
            <TemplateWizardFooter
              cancel={() => cancel(history)}
              next={next}
              prev={prev}
              currentStep={currentStep}
              steps={steps}
              enableSaveAsDraft={enableSaveAsDraft}
              onSaveTemplate={onSaveTemplate}
              templateData={templateData}
              validation={currentStepData.validation}
            />
          )}
        </div>
      </>
    </div>
  );
}

export default EditTemplate;
