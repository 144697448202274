import { StoreAction } from './redux.interface';

export const setUpdateAssetWidgetsData = (payload): StoreAction => ({
  type: 'updateAssetWidgetsData/SET',
  payload,
});

export const updateUpdateAssetWidgetsData = (payload): StoreAction => ({
  type: 'updateAssetWidgetsData/UPDATE',
  payload,
});

export const resetUpdateAssetWidgetsData = () => ({
  type: 'updateAssetWidgetsData/RESET',
});

export default function updateAssetWidgetsDataReducer(state = {}, action: StoreAction) {
  switch (action.type) {
    case 'updateAssetWidgetsData/SET':
      return { ...state, ...action.payload };
    case 'updateAssetWidgetsData/UPDATE':
      const key = Object.keys(action.payload)[0];
      return {
        ...state,
        [key]: { isWidgetUpdating: action.payload[key].isWidgetUpdating },
      };
    case 'updateAssetWidgetsData/RESET':
      return {};
    default:
      return state;
  }
}
