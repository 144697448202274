import React from 'react';
import classNames from 'classnames';

import styles from './AssetTypeNavigation.scss';
import { AssetTypeNavigationProps } from './AssetTypeNavigation.interface';
import I18n from '@components/I18n';
import UrlNavigation from '../UrlNavigation';
import DashboardNavigation from '../DashboardNavigation';

const AssetTypeNavigation = (props: AssetTypeNavigationProps) => {
  const {
    navigationDashboard,
    onSelectedAssetTypeDashboard,
    onSelectedAssetTypeUrl,
    onReset,
    assetTypes,
    dashboardOptions,
    urlNavigation,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.navigateCaptionRow}>
        <div className={styles.navigateCaption}>
          <I18n>create-widget-page.create-widget.step-five.asset-type-name</I18n>
        </div>
        {urlNavigation ? (
          <div className={styles.navigateCaption} style={{ marginLeft: '10px' }}>
            <I18n>create-widget-page.create-widget.step-five.url</I18n>
          </div>
        ) : (
          <div className={styles.navigateCaption} style={{ marginLeft: '10px' }}>
            <I18n>create-widget-page.create-widget.step-five.dashboard</I18n>
          </div>
        )}
      </div>
      {assetTypes.map((assetType) => {
        const selectedNavigation = navigationDashboard.find((n) => n.assetTypeId === assetType.id);
        return (
          <div key={assetType.id} className={styles.navigation} style={{ maxHeight: '28px' }}>
            <div className={styles.assetTypeName}>
              <span title={assetType.name} className={'ellipsis-overflow'}>
                {assetType.name}
              </span>
            </div>
            <div className={styles.inputContainer}>
              {urlNavigation ? (
                <div className={styles.input}>
                  <UrlNavigation
                    value={selectedNavigation?.url || ''}
                    onSelectedUrl={(url) => onSelectedAssetTypeUrl(url.url, assetType.id, 'URL')}
                    onReset={() => onReset(assetType.id)}
                  />
                </div>
              ) : (
                <div className={styles.input}>
                  <DashboardNavigation
                    value={
                      selectedNavigation
                        ? dashboardOptions.find((opt) => opt.id === selectedNavigation.id)
                        : {}
                    }
                    onSelectedDashboard={(dashboard) =>
                      onSelectedAssetTypeDashboard(dashboard, assetType.id)
                    }
                    onReset={() => onReset(assetType.id)}
                    dashboardOptions={dashboardOptions}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AssetTypeNavigation;
