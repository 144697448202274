import React from 'react';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import IBVCfgConditions from './IBVCfgConditions';

const useStyles = makeStyles((theme: any) => ({
  costumizeRow: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 6,
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
  },
  subHeaderspace: {
    width: '120px',
  },
  text: {
    marginLeft: '10px',
    fontSize: '14px',
    minWidth: '65px',
  },
  widgetValue: {
    marginTop: '10px',
  },
  imageDimensions: {
    width: '100%',
    margin: '10px 0',
  },
  labelWrapper: {
    width: '100%',
  },
  error: {
    fontSize: 12,
    color: 'var(--formikErrorText)',
    fontWeight: 'bold',
  },
}));

const ImageByValue = (props) => {
  const {
    widgetData,
    setWidgetData,
    onChange,
    isInstantServerUpdate = false,
    isValidationOnChangeNeeded = true,
  } = props;
  const { customization } = widgetData;

  const classes = useStyles(props);

  return (
    <div className={classes.widgetValue}>
      <div className={classes.header}>
        <I18n>create-widget-page.create-widget.step-four.image-by-value.conditions</I18n>
      </div>
      <IBVCfgConditions
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        onChange={onChange}
        isInstantServerUpdate={isInstantServerUpdate}
        isValidationOnChangeNeeded={isValidationOnChangeNeeded}></IBVCfgConditions>
      {customization?.errors && Object.values(customization?.errors).find((err) => err) && (
        <I18n className={classes.error}>
          create-widget-page.create-widget.step-four.image-by-value.conditions-error
        </I18n>
      )}
    </div>
  );
};

export default ImageByValue;
