import I18n from '@components/I18n';
import React, { useEffect } from 'react';
import styles from './MonthlyTrigger.scss';
import Select from '@components/Select';
import moment from 'moment';

const dayOfMonthOptions = Array.from({ length: 31 }, (_, i) => ({
  label: (i + 1).toString(),
  value: i + 1,
}));

function MonthlyTrigger(props) {
  const { templateData, setTemplateData } = props;
  const { dayOfMonth } = templateData;

  useEffect(() => {
    if (!dayOfMonth) setTemplateData(moment().date(), 'dayOfMonth');
  }, []);

  return (
    <div className={styles.row}>
      <div className={styles.header}>
        <I18n>events-template.edit-template.trigger.day-of-month</I18n>
      </div>
      <Select
        styles={{ container: { width: '110px' } }}
        className={styles.field}
        options={dayOfMonthOptions}
        value={dayOfMonthOptions.find((opt) => opt.value === dayOfMonth)}
        getOptionLabel={(i) => i.label}
        getOptionValue={(i) => i.value}
        onChange={(option) => setTemplateData(option.value, 'dayOfMonth')}
      />
    </div>
  );
}

export default MonthlyTrigger;
