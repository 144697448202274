import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { cloneDeep, debounce } from 'lodash';
import { Config, JsonTree, SpelFieldMeta } from 'react-awesome-query-builder';
import { setArgValue } from 'react-awesome-query-builder/lib/utils/funcUtils';
import { ExtendedField } from '../Config/useFieldConfigurator.utils';
import { expressionBuilderRegex } from '@core/regex';
import {
  getGroupName,
  getNextIndex,
} from '@modals/EditFormulaExpressionModal/EditFormulaExpressionModal.utils';
import { dynamicSort } from '@core/utils';

const functionRegex = /Calculate\(('.*?')\)/;

export const fixNullValueForField = (jsonTree): JsonTree => {
  const fixedTree = cloneDeep(jsonTree);
  fixNullValueForFieldInteral(fixedTree['children1']);
  return fixedTree;
};

const fixNullValueForFieldInteral = (conditionTree) => {
  const childrenKeys = Object.keys(conditionTree);

  childrenKeys.forEach((key) => {
    const ruleOrGroup = conditionTree[key];

    if (ruleOrGroup['type'] == 'group') fixNullValueForFieldInteral(ruleOrGroup['children1']);
    else {
      // Should be a rule
      const { properties } = ruleOrGroup;
      const { operator, value, valueSrc } = properties;
      switch (operator) {
        case 'equal':
        case 'not_equal':
        case 'less':
        case 'less_or_equal':
        case 'greater':
        case 'greater_or_equal':
        case 'starts_with':
        case 'ends_with':
          if (valueSrc && valueSrc.length && valueSrc[0] === 'field') {
            if (value == null) properties.value = undefined;
          }
          break;
        case 'between':
        case 'not_between':
          if (valueSrc && valueSrc.length && valueSrc[0] === 'field') {
            if (value[0] == null) value[0] = undefined;
          }

          if (valueSrc && valueSrc.length && valueSrc[0] === 'field') {
            if (value[1] == null) value[1] = undefined;
          }
          break;
      }
    }
  });
};

export const singleCalculationToServer = (calculation, dataSources) => {
  const { expression } = calculation;
  let newExpression = (expression && expression.trim()) || '';

  if (newExpression && newExpression.startsWith("'")) newExpression = newExpression.substring(1);

  if (newExpression && newExpression.endsWith("'"))
    newExpression = newExpression.substring(0, newExpression.length - 1);

  let startIndex = 0;

  let nextDataSourceIndex = getNextIndex(newExpression, startIndex);

  while (nextDataSourceIndex >= 0) {
    const groupName = getGroupName(newExpression, nextDataSourceIndex);
    let bracketsCount = 0;
    for (let charIndex = nextDataSourceIndex; charIndex < newExpression.length; charIndex++) {
      if (newExpression[charIndex] === '[') bracketsCount++;
      if (newExpression[charIndex] === ']') bracketsCount--;

      if (bracketsCount === 0) {
        const tagName = newExpression.substring(
          nextDataSourceIndex + groupName.length + 2,
          charIndex
        );

        const matchingTag = dataSources.find((ds) => {
          if (ds.name === tagName && ds.dataType === groupName) {
            return ds;
          }
        });

        if (matchingTag) {
          newExpression = newExpression.replace(
            `[${groupName}.${tagName}]`,
            `[${groupName}.${matchingTag.id}]`
          );
        } else {
          newExpression = newExpression.replace(`[${groupName}.${tagName}]`, '');
        }

        break;
      }
    }
    startIndex = nextDataSourceIndex + 1;

    nextDataSourceIndex = getNextIndex(newExpression, startIndex);
  }

  return newExpression;
};

export const editExpression = async (expression, props, groupName) => {
  const fields = props.config.fields;

  const systemProperties = fields.systemProperties
    ? Object.keys(fields.systemProperties.subfields).reduce((acc, key) => {
        const cur = fields.systemProperties.subfields[key];
        if (cur?.type === 'number')
          return [...acc, { name: cur?.label, id: cur?.id, dataType: 'systemProperties' }];
        return acc;
      }, [])
    : [];

  const tagsOrTagTypes = Object.keys(fields[groupName].subfields).reduce((acc, key) => {
    const cur = fields[groupName].subfields[key];
    if (cur?.type === 'number')
      return [
        ...acc,
        {
          name: cur?.label,
          id: cur.id,
          dataType: groupName === 'assetTags' ? 'assetTags' : 'tagTypes',
        },
      ];
    return acc;
  }, []);

  const dataSources = [
    ...systemProperties.sort(dynamicSort('name', '+')),
    ...tagsOrTagTypes.sort(dynamicSort('name', '+')),
  ];

  const editExpressionResult = await modalService.openModal('editFormulaExpressionModal', {
    expression,
    dataSources,
    isTagTypes: groupName == 'tagTypes',
    origininatesFrom: 'conditionBuilder',
  });

  const expressionWithIds = singleCalculationToServer(
    { expression: editExpressionResult.expression },
    dataSources
  );

  if (editExpressionResult) {
    setQueryArgValue(props, 'expression', expressionWithIds);
  }
};

export const changeSigmaToBEFormat = (
  expression: string,
  config,
  usedTagsIds: Array<number>,
  usedVariablesIds: Array<number>
): string => {
  const tagIdRegex = /{{\s?TAG_(\d+)\s?}}/g;
  const variableIdRegex = /{{\s?VARIABLE_(\d+)\s?}}/g;

  let newExpression = replaceTagIdsWithNames(expression, config?.fields) || expression;

  if (newExpression && newExpression.startsWith('"')) newExpression = newExpression.substring(1);

  if (newExpression && newExpression.endsWith('"'))
    newExpression = newExpression.substring(0, newExpression.length - 1);

  if ((!config.fields?.assetTags && !config.fields?.tagTypes) || !newExpression)
    return newExpression;

  while (functionRegex.test(newExpression)) {
    const match = newExpression.match(functionRegex);
    let funcExpression = match[1].substring(1, match[1].length - 1); // remove first and last '

    let startIndex = 0;
    let nextDataSourceIndex = getNextIndex(funcExpression, startIndex);

    while (nextDataSourceIndex >= 0) {
      const groupName = getGroupName(funcExpression, nextDataSourceIndex);
      const tagIds = Object.keys(config.fields[groupName].subfields);
      let bracketsCount = 0;
      for (let charIndex = nextDataSourceIndex; charIndex < funcExpression.length; charIndex++) {
        if (funcExpression[charIndex] === '[') bracketsCount++;
        if (funcExpression[charIndex] === ']') bracketsCount--;

        if (bracketsCount === 0) {
          const tagName = funcExpression.substring(
            nextDataSourceIndex + groupName.length + 2,
            charIndex
          );

          const matchingTagId = tagIds.find(
            (name) => config.fields[groupName].subfields[name].label === tagName
          );

          if (matchingTagId)
            funcExpression = (funcExpression as any).replaceAll(
              `[${groupName}.${tagName}]`,
              groupName === 'systemProperties'
                ? `{{ VARIABLE_${config.fields[groupName].subfields[matchingTagId].id} }}`
                : `{{ TAG_${config.fields[groupName].subfields[matchingTagId].id} }}`
            );
          else funcExpression = (funcExpression as any).replaceAll(`[${groupName}.${tagName}]`, '');
          break;
        }
      }

      startIndex = nextDataSourceIndex + 1;
      nextDataSourceIndex = getNextIndex(funcExpression, startIndex);
    }

    newExpression = newExpression.replace(functionRegex, `(${funcExpression})`);
  }

  const usedTags = new Set((newExpression as any).matchAll(tagIdRegex));
  const usedVariables = new Set((newExpression as any).matchAll(variableIdRegex));

  if (usedTags) {
    for (const match of usedTags) {
      if (!usedTagsIds.some((n) => n === +match[1])) usedTagsIds.push(+match[1]);
    }
  }

  if (usedVariables) {
    for (const match of usedVariables) {
      if (!usedVariablesIds.some((n) => n === +match[1])) usedVariablesIds.push(+match[1]);
    }
  }

  return newExpression;
};

export const setQueryArgValue = (props, argKey, argVal) => {
  const { config } = props;
  const funcDefinition = config.funcs.SIGMA;
  const { args } = funcDefinition;
  const argDefinition = args[argKey];

  props.setValue(setArgValue(props.value, argKey, argVal, argDefinition, config));
};

export const formatField = (
  field: string,
  parentField: string | null,
  parts: Array<string>,
  partsExt: Array<SpelFieldMeta>,
  fieldDefinition: ExtendedField,
  config: Config
): string => {
  switch (parts[0]) {
    case 'assetTags':
    case 'tagTypes':
      return `{{ TAG_${fieldDefinition.id} }}`;
    case 'systemProperties':
      return `{{ VARIABLE_${fieldDefinition.id} }}`;
    case 'assetProperties':
      switch (parts[1]) {
        case 'assetId':
          return `{{ ASSET_ID }}`;
        case 'assetName':
          return `{{ ASSET_NAME }}`;
        case 'organization':
          return `{{ ASSET_ORG_NAME }}`;
        case 'status':
          return `{{ ASSET_STATUS }}`;
        case 'assetType':
          return `{{ ASSET_TYPE }}`;
      }
    case 'alarmInfo':
      switch (parts[1]) {
        case 'name':
          return `{{ ALARM_NAME }}`;
        case 'activeAlarm':
          return `{{ ALARM_STATUS }}`;
      }
  }
  return field;
};

export const convertLocalConditionTreeToServer = (conditionTree: JsonTree, fields): JsonTree => {
  if (!conditionTree) return conditionTree;

  const newTree = cloneDeep(conditionTree);

  convertLocalConditionTreeToServerInternal(newTree['children1'], fields);

  return newTree;
};

const convertLocalConditionTreeToServerInternal = (conditionTree: JsonTree, fields) => {
  const childrenKeys = Object.keys(conditionTree);

  childrenKeys.forEach((key) => {
    const ruleOrGroup = conditionTree[key];

    if (ruleOrGroup['type'] == 'group')
      convertLocalConditionTreeToServerInternal(ruleOrGroup['children1'], fields);
    else if (ruleOrGroup['type'] == 'rule') {
      const { properties } = ruleOrGroup;
      const { field } = properties;

      if (field && field.startsWith('tagTypes.')) {
        const tagId = field.substr('tagTypes.'.length);

        const matchingField = fields['tagTypes']?.subfields[tagId];

        if (matchingField) properties.field = `tagTypes.${matchingField.id}`;
      } else if (
        field &&
        (field.startsWith('assetTags.') || field.startsWith('systemProperties.'))
      ) {
        const prefix = field.startsWith('assetTags.') ? 'assetTags' : 'systemProperties';

        const tagId = +field.substr(`${prefix}.`.length);

        const matchingField = fields[prefix]?.subfields[tagId];

        if (matchingField) properties.field = `${prefix}.${matchingField.id}`;
      }
    }
  });
};

export const convertServerConditionTreeToLocal = (conditionTree: JsonTree, fields): JsonTree => {
  if (!conditionTree) return conditionTree;

  const newTree = cloneDeep(conditionTree);

  convertServerConditionTreeToLocalInternal(newTree['children1'], fields);

  return newTree;
};

const convertServerConditionTreeToLocalInternal = (conditionTree: JsonTree, fields) => {
  const childrenKeys = Object.keys(conditionTree);
  const numberRegex = /^\d+$/;

  childrenKeys.forEach((key) => {
    const ruleOrGroup = conditionTree[key];

    if (ruleOrGroup['type'] == 'group')
      convertServerConditionTreeToLocalInternal(ruleOrGroup['children1'], fields);
    else {
      // Should be a rule
      const { properties } = ruleOrGroup;
      const { field } = properties;

      if (field && field.startsWith('assetTags.')) {
        const tagId = field.substr('assetTags.'.length);

        if (numberRegex.test(tagId)) {
          var tagNames = fields['assetTags'] ? Object.keys(fields['assetTags'].subfields) : [];

          const matchingField = tagNames.find(
            (name) => fields['assetTags'].subfields[name].id === +tagId
          );

          if (matchingField) {
            properties.field = `assetTags.${matchingField}`;
          } else {
            properties.field = null;
          }
        }
      } else if (field && field.startsWith('systemProperties.')) {
        const tagId = field.substr('systemProperties.'.length);

        if (numberRegex.test(tagId)) {
          var tagNames = Object.keys(fields['systemProperties'].subfields);

          const matchingField = tagNames.find(
            (name) => fields['systemProperties'].subfields[name].id === +tagId
          );

          if (matchingField) {
            properties.field = `systemProperties.${matchingField}`;
          }
        }
      } else if (field && field.startsWith('tagTypes.')) {
        const tagId = field.substr('tagTypes.'.length);

        if (numberRegex.test(tagId)) {
          var tagNames = fields['tagTypes'] ? Object.keys(fields['tagTypes'].subfields) : [];

          const matchingField = tagNames.find(
            (name) => fields['tagTypes']?.subfields[name]?.id === +tagId
          );

          if (matchingField) {
            properties.field = `tagTypes.${matchingField}`;
          } else {
            properties.field = null;
          }
        }
      } else if (
        field == 'assetProperties.assetType' &&
        properties.valueType &&
        properties.valueType.length &&
        fields.assetProperties.subfields.assetType.type !== properties.valueType[0]
      ) {
        properties.valueType = [fields.assetProperties.subfields.assetType.type];
      }
    }
  });
};

export const checkConditionLength = async (
  condition,
  templateData,
  setConditionLength,
  setTemplateData
) => {
  const debouncedFunction = debounce(
    async (condition, templateData, setConditionLength, setTemplateData) => {
      const {
        conditionAssetTypes,
        conditionTagTypes,
        conditionTags,
        conditionVariables,
        startDate,
        endDate,
        trigger,
      } = templateData;
      const res: any = await httpService.api({
        type: 'queryEventTemplateConditionLength',
        disableBI: true,
        urlParams: { templateId: templateData.id },
        data: {
          condition,
          conditionAssetTypes,
          conditionTagTypes,
          conditionTags,
          conditionVariables,
          startDate,
          endDate,
          trigger,
        },
      });
      if (res) {
        setConditionLength(res.length);
        setTemplateData(false, 'isCheckingConditionLength'); // reset flag that say that condition length is being checked
      }
    },
    500
  );

  if (condition)
    await debouncedFunction(condition, templateData, setConditionLength, setTemplateData);
  else {
    setConditionLength(0);
    setTemplateData(false, 'isCheckingConditionLength');
  }
};

export function replaceTagIdsWithNames(expression, data) {
  let newExpression = expression || '';

  const tagsOrTagTypes =
    newExpression?.match(
      data?.assetTags
        ? expressionBuilderRegex.conditionTree.assetTags
        : expressionBuilderRegex.conditionTree.tagTypes
    ) || [];

  const systemProperties =
    newExpression?.match(expressionBuilderRegex.conditionTree.systemProperties) || [];

  const tagsWithIds = [...tagsOrTagTypes, ...systemProperties];

  const tagsWithNames = tagsWithIds?.map((tag) => {
    const groupName = tag.includes('tagTypes.')
      ? 'tagTypes'
      : tag.includes('systemProperties')
      ? 'systemProperties'
      : 'assetTags';
    const id = tag.split(`${groupName}.`)[1];

    return tag.replace(id, data[groupName]?.subfields[id]?.label);
  });

  const combined = tagsWithIds?.map((tag, idx) => [tag, tagsWithNames[idx]]);

  combined?.forEach((element) => {
    newExpression = newExpression.replaceAll(`${element[0]}]`, `${element[1]}]`);
  });

  return newExpression;
}
