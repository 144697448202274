import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Table from '@components/Table';
import { useSelector } from '@redux/useSelector';
import { AssetTableProps } from './assetsTable.interface';
import AssetsStatus from './AssetsStatus';
import styles from './AssetsTable.scss';
import RemoteAccessButton from '@components/RemoteAccessButton';
import { getFlagStatus } from '@core/ffAndPermissions';
import { compact } from 'lodash';
import { onEditAssetModal, getAssets } from './AssetsTable.utils';
import TextField from '@components/Table/TableFields/TextField';
import AssignButton from './AssignButton';
import SubscriptionData from './SubscriptionData';

const getColunmsCfg = (flags) =>
  compact([
    {
      Component: TextField,
      label: 'assets-tab.owner',
      field: 'owner',
      style: { width: '13%' },
      isSortable: true,
    },
    {
      Component: AssetsStatus,
      label: 'assets-tab.status',
      field: 'status',
      style: { width: '13%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-id',
      field: 'assetId',
      style: { width: '10%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-serial-number',
      field: 'assetSerial',
      style: { width: '13%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.name',
      field: 'assetName',
      style: { width: '13%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-type',
      field: 'assetType',
      style: { width: '13%' },
      isSortable: true,
    },
    {
      Component: SubscriptionData,
      label: 'assets-tab.asset-subscription',
      field: 'subscriptionStatus',
      style: { width: '13%' },
      isSortable: true,
      isFilterable: true,
      filterType: 'checkbox',
      filterOptions: [
        { label: `enum.subscription.plc.NOT_AVALIABLE`, value: 'NOT_AVAILABLE' },
        { label: `enum.subscription.plc.ACTIVE`, value: 'ACTIVE' },
        { label: `enum.subscription.plc.ABOUT_TO_EXPIRE`, value: 'ABOUT_TO_EXPIRE' },
        { label: `enum.subscription.plc.EXPIRED_GRACE`, value: 'EXPIRED_GRACE' },
        { label: `enum.subscription.plc.EXPIRED`, value: 'EXPIRED' },
        { label: `enum.subscription.plc.EVALUATION`, value: 'EVALUATION' },
      ],
      filterAll: true,
    },
    {
      Component: SubscriptionData,
      label: 'assets-tab.asset-end-date',
      field: 'endDate',
      style: { width: '12%' },
      isSortable: true,
    },
    flags.canAssign && {
      Component: AssignButton,
      type: 'staticEnd',
      label: '',
      field: 'assign',
      style: { width: 78 },
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'vpn',
      style: { width: 78 },
      props: {
        remoteAccessType: 'vpn',
        parentComponent: 'AssetsTable',
      },
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'web',
      style: { width: 78 },
      props: {
        remoteAccessType: 'web',
        parentComponent: 'AssetsTable',
      },
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'vnc',
      style: { width: 78 },
      props: {
        remoteAccessType: 'vnc',
        parentComponent: 'AssetsTable',
      },
    },
  ]);

function AssetsTable(props: AssetTableProps) {
  const refresh = useSelector((state) => state.asset.refresh);
  const assetsData = useSelector((state) => state.asset.assetsData);
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );
  const roles = useSelector((state) => state.config.roles);
  const featureFlag = useSelector((state) => state.config.featureFlags);

  const colunmsCfg = useMemo(() => {
    return getColunmsCfg({
      remoteAccess: getFlagStatus('assets.remote-access-buttons'),
      canAssign: getFlagStatus('assets.subscription-tab'),
    });
  }, []);

  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [_order, setOrder] = useState(null);
  const { showArchived, searchText } = props;

  const pageSize = useMemo(() => 50, []);

  useEffect(
    () =>
      getAssets(
        setNextPage,
        selectedOrganizationsId,
        searchText,
        showArchived,
        pageSize,
        nextPage,
        setDisableLoad,
        true,
        _order
      ),
    [searchText, showArchived, selectedOrganizationsId]
  );

  useEffect(() => {
    if (refresh) {
      getAssets(
        setNextPage,
        selectedOrganizationsId,
        searchText,
        showArchived,
        pageSize,
        nextPage,
        setDisableLoad,
        true,
        _order
      );
    }
  }, [refresh]);

  const openEditAssetModal = useCallback(
    (rowData, rowIdx) => {
      onEditAssetModal(rowData, rowIdx, showArchived);
    },
    [showArchived]
  );

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={assetsData}
        getData={(firstInit, order, filters?) => {
          const checkedOrder =
            order?.label === 'endDate'
              ? { label: 'subscriptionEndDate', orderSign: order?.orderSign }
              : order?.orderSign && order?.orderSign !== ''
              ? order
              : null;
          order && setOrder(checkedOrder);
          getAssets(
            setNextPage,
            selectedOrganizationsId,
            searchText,
            showArchived,
            pageSize,
            nextPage,
            setDisableLoad,
            firstInit,
            checkedOrder,
            filters
          );
        }}
        colunmsCfg={colunmsCfg}
        onRowClicked={openEditAssetModal}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default AssetsTable;
