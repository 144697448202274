import * as RI from '../../redux.interface';

class EAhandlers {
  updateEditAsset(state: RI.EditAssetState, payload: any) {
    if (state.staticData && state.general) {
      for (const key in payload) {
        switch (key) {
          case 'assetName':
          case 'assetSerial':
            if (payload[key] !== state.staticData[key]) {
              state.staticData[key] = payload[key];
              state.general[key] = payload[key];
            }
            break;
          case 'comments':
            if (payload[key] !== state.general[key]) state.general[key] = payload[key];
            break;
          case 'status':
          case 'state':
          case 'connectedAt':
            if (payload[key] !== state.staticData[key]) state.staticData[key] = payload[key];
            break;
          case 'hasCertificate':
            if (payload[key] !== state.staticData[key]) state.staticData[key] = payload[key];
            break;
          case 'gpsSupport':
            if (payload[key] !== state.staticData[key]) {
              state.staticData[key] = payload[key];
              state.general[key] = payload[key];
            }
            break;
          case 'owner':
            if (payload[key].label !== state.general[key]) state.general[key] = payload[key].label;
            break;
          case 'ownerId':
          case 'plcModel':
          case 'plcCatalogNumber':
          case 'hasCertificate':
            if (payload[key] !== state.general[key]) state.general[key] = payload[key];
            break;
          case 'location':
            if (payload[key].googleId !== state.general[key].googleId) {
              state.general[key] = payload[key];
            }
            break;
          case 'plcSerial':
            if (payload[key].text !== state.staticData[key]) {
              state.staticData[key] =
                typeof payload[key] === 'string' ? payload[key] : payload[key].text;
              state.general[key] =
                typeof payload[key] === 'string' ? payload[key] : payload[key].text;
            }
            break;
          case 'subscription':
          case 'galleryImageId':
            state.staticData[key] = payload[key];
            state.general[key] = payload[key];
            break;
          case 'ar':
            state.staticData[key] = payload[key];
            state.general[key] = payload[key];
            break;
        }
      }
    }

    return {
      ...state,
    };
  }
}

export const eAhandlers = new EAhandlers();
