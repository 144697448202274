import React from 'react';
import I18n from '@components/I18n';
import { changeWidgetData } from '../Heatmap.utils';
import MaterialCheckbox from '@components/Checkbox';

function ReverseNegativeShade({ classes, chartType, reverseNegativeShade, shaded, setWidgetData }) {
  return (
    <div className={classes?.section}>
      <MaterialCheckbox
        color="primary"
        checked={reverseNegativeShade}
        onClick={() => {
          changeWidgetData(!reverseNegativeShade, 'reverseNegativeShade', setWidgetData);
        }}
        disabled={chartType === 'RANGES' && !shaded}
      />
      <I18n className={classes?.checkboxText}>
        create-widget-page.create-widget.step-four.heatmap.reverse-negative-shades
      </I18n>
    </div>
  );
}

export default ReverseNegativeShade;
