import React, { useCallback } from 'react';
import Button from '@components/Button';
import { editAssetTypeService } from '../../EditAssetTypeService';
import styles from './AssetTypeInfoTagsMeta.scss';
import I18n from '@components/I18n';
import TagsTable from './TagsTable/TagsTable';

function AssetTypeInfoTagsMeta() {
  const cancel = useCallback(() => editAssetTypeService.dismiss(false), []);

  return (
    <>
      <div className={styles.content}>
        <TagsTable></TagsTable>
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetTypeInfoTagsMeta;
