import { httpService } from '@core/http/HttpService';
import { VariableMeta } from '@core/http/server.interface';
import { modalService } from '@core/modals/ModalService';
import {
  getVariablesList,
  managementLevelOptions,
  variableConstants,
} from '@pages/OrganizationsPage/Variables/Variables.utils';
import * as Yup from 'yup';

export function getFieldsCfg(nameValidation) {
  const { NAME_MAX_LENGTH } = variableConstants;

  return [
    {
      label: 'general.name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validate: (values) => {
        if (nameValidation.name === values.name && nameValidation.message) {
          return nameValidation.message;
        }
      },
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(50, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
      onLostFocus: (values, setFieldValue) => {
        setFieldValue('name', values.name);
      },
      maxLength: NAME_MAX_LENGTH,
      errors: {
        name: nameValidation.message ? nameValidation.message : 'validations.mandatory-field',
      },
      removeLeadingSpaces: true,
    },
    {
      label: 'variables.management-level',
      name: 'managementLevel',
      type: 'select',
      options: managementLevelOptions,
      onSelectChange: (values, setFieldValue) => {
        setFieldValue('managementLevel', values.value);
      },
    },
    {
      label: 'general.type',
      name: 'valueType',
      type: 'label',
    },
  ];
}

export async function openResetValuesModal(args) {
  modalService
    .openModal('confirm', {
      text: 'variables.edit-management-level.reset-values-pop-up.text',
      headerText: 'variables.edit-management-level.reset-values-pop-up.header',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        saveVariableSettings(args);
      }
    });
}

export function saveVariableSettings({
  origin,
  assetTypeId,
  id,
  name,
  managementLevel,
  setVariablesList,
  dismiss,
}) {
  httpService
    .api({
      type: 'editAssetTypeVariableSettings',
      urlParams: { assetTypeId, variableId: id },
      data: {
        name: name,
        managementLevel: managementLevel,
      },
    })
    .then(() => {
      getVariablesList(origin, assetTypeId, setVariablesList);
      dismiss(true);
    });
}
