import TableWidget from '@components/widgets/TableWidget';
import { stepThreeDefaultValidation, setDefaultData } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';
import { alarmInfosMap } from '@pages/CreateWidgetPage/widgets.utils';

const defaultAlarmInfos = [
  { id: 8, type: 'STRING' },
  { id: 7, type: 'STRING' },
  { id: 6, type: 'STRING' },
].map((option) => ({
  id: option.id,
  type: option.type,
  name: `create-widget-page.create-widget.alarm-infos.${alarmInfosMap[option.id]}`,
}));

const defaultFilter = [
  {
    disabled: true,
    autocompleteOptions: [],
    condition: {
      label: 'enum.IS',
      value: 'IS',
    },
    name: 'create-widget-page.create-widget.alarm-infos.ACTIVE_ALARM',
    operation: null,
    order: 0,
    type: 'booleanType',
    valueId: 2,
    valueType: 'ALARM_INFO',
    values: ['true'],
  },
];

export const alarms: WidgetMapItem = {
  id: 'alarms',
  name: 'Alarms',
  component: TableWidget,
  fullSize: true,
  minWidth: 300,
  minHeight: 64,
  images: {
    canvas: '/assets/images/empty_alarms.svg',
    thumbnail: 'alarms',
  },
  editModalSize: {},
  settings: {
    dataChangesToHandle: [
      'scope',
      'groupBy',
      'metrics',
      'filters',
      'sorts',
      'type',
      'calculations',
    ],
    getDefaultFilters: (scope) => {
      if (scope === 'LAST_VALUE') {
        return defaultFilter;
      }
      return [];
    },
    stepOneHidden: ['Variables'],
    disableGrouping: {
      text: ({ scope }) => {
        switch (scope) {
          case 'RAW_DATA':
            return 'create-event-data-source-page.create-event-data-source.step-three.grouping-raw-data';
          case 'LAST_VALUE':
            return 'create-event-data-source-page.create-event-data-source.step-three.grouping-last-value';
          default:
            return null;
        }
      },
    },
    setDefaultDataByStep: {
      2: (scope, setWidgetData, widgetData) => {
        const alarmInfos = setDefaultData(
          widgetData,
          () => ['RAW_DATA', 'LAST_VALUE'].includes(scope),
          'alarmInfos',
          scope === 'LAST_VALUE'
            ? [
                ...defaultAlarmInfos,
                {
                  id: 2,
                  name: 'create-widget-page.create-widget.alarm-infos.ACTIVE_ALARM',
                  type: 'BOOLEAN',
                },
              ]
            : defaultAlarmInfos
        );
        setWidgetData((prev) => ({
          ...prev,
          ...alarmInfos,
          filters: prev.scope === 'LAST_VALUE' ? [...defaultFilter] : [],
        }));
      },
    },
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
    },
    dragAndDropRules: {
      maxGrouping: (scope) => null,
      maxMetrics: null,
      groupingDataValidation: (scope, draggableItem, isRemove) => {
        if (
          !isRemove &&
          (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') &&
          (draggableItem?.id === 4 || draggableItem?.valueId === 4) &&
          (draggableItem?.tagType === 'ALARM_INFO' || draggableItem?.valueType === 'ALARM_INFO')
        ) {
          return 'noTimeStampOnAggregate';
        }
        if (
          !isRemove &&
          scope === 'AGGREGATED_LAST_VALUE' &&
          (draggableItem?.id === 7 || draggableItem?.valueId === 7) &&
          (draggableItem?.tagType === 'ASSET_PROPERTY' ||
            draggableItem?.valueType === 'ASSET_PROPERTY')
        ) {
          return 'noTimeStampOnAggregateByLastValue';
        }
        if (
          !isRemove &&
          (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') &&
          (draggableItem?.id === 5 || draggableItem?.valueId === 5) &&
          (draggableItem?.tagType === 'ALARM_INFO' || draggableItem?.valueType === 'ALARM_INFO')
        ) {
          return 'noDurationOnAggregate';
        }
        if (
          scope === 'AGGREGATED_DATA' &&
          (draggableItem?.id === 2 || draggableItem?.valueId === 2) &&
          (draggableItem?.tagType === 'ALARM_INFO' || draggableItem?.valueType === 'ALARM_INFO')
        ) {
          return 'noGroupByActiveAlarmOnAggregate';
        }
        return null;
      },
      metricsDataValidation: (scope, draggableItem) => {
        if (
          scope === 'AGGREGATED_DATA' &&
          draggableItem.id == 2 &&
          draggableItem.tagType == 'ALARM_INFO'
        ) {
          return 'noActiveAlarmInMetricsOnAggregate';
        }
        return null;
      },
    },
    showPreviewData: ['scope'],
    enableExportCsv: (widgetData) => widgetData.scope === 'RAW_DATA',
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) =>
      widgetData.groupBy.find(
        (gb) => gb.valueType === 'ASSET_PROPERTY' && (gb.valueId === 1 || gb.valueId === 8)
      ) || // id 1 is Asset ID, id 8 is Asset Type
      ['RAW_DATA', 'LAST_VALUE'].includes(widgetData.scope),
    languageKeys: (isArWidget) => ['columnsDict.*', 'symbolDict.*'],
    accordionDisable: ['calculations'],
    customizationServerChanges: (customization) => {
      const { columnsDict, symbolDict } =
        customization.columns &&
        customization.columns.reduce(
          (result, column) =>
            column.operation
              ? {
                  columnsDict: {
                    ...result.columnsDict,
                    [`${column.valueType.toLowerCase()}.${
                      column.id
                    }.${column.operation.toLowerCase()}`]: column.displayName,
                  },
                  symbolDict: {
                    ...result.symbolDict,
                    [`${column.valueType.toLowerCase()}.${
                      column.id
                    }.${column.operation.toLowerCase()}`]: column.symbol,
                  },
                }
              : {
                  columnsDict: {
                    ...result.columnsDict,
                    [`${column.valueType.toLowerCase()}.${column.id}`]: `${column.displayName}`,
                  },
                  symbolDict: {
                    ...result.symbolDict,
                    [`${column.valueType.toLowerCase()}.${column.id}`]: column.symbol,
                  },
                },
          { columnsDict: {}, symbolDict: {} }
        );
      return {
        ...customization,
        columnsDict,
        symbolDict,
      };
    },
  },
};
