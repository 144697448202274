import { httpService } from '@core/http/HttpService';
import { PlcValidModel } from './FormikPlcInput.interface';
import { i18nService } from '@core/i18n/I18nService';
import { buildErrorObj } from '@core/utils';
import { modalService } from '@core/modals/ModalService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { HttpRequestCfg } from '@core/http/http.interface';
import { staticDictionary } from '@core/i18n/dictionary';

export const handleBlurPlcInput = (btnClicked, formik, name) => {
  setTimeout(() => {
    if (btnClicked.current) {
      btnClicked.current = false;
    } else {
      // We only set the field as touched if blur is not
      // from pressing the validate button.
      formik.setFieldTouched(name, true);
    }
  }, 300);
};

export enum PlcSerialStatus {
  none = 'none',
  generated = 'generated',
  validated = 'validated',
}

export const validatePlcSerial = (
  btnClicked,
  type,
  plcSerial,
  formik,
  initialValuesPlc,
  setCatalogNumber,
  name,
  routerId?,
  onSucceedValidation?,
  setPlcSerial?,
  assetPlcType?,
  setPlcSerialStatus?
) => {
  btnClicked.current = true; // Needed for onBlur logic.
  let apiType;
  if (type === 'addToOrganization') apiType = 'validatePlc';
  else if (type === 'associateToAsset') apiType = 'validateNewAssetPlc';
  else if (type === 'associateToRouter') apiType = 'validatePLCforRouter';

  const apiCfg: HttpRequestCfg = {
    type: apiType,
    data: { plcSerial: plcSerial.text },
  };
  type === 'associateToRouter' && (apiCfg.urlParams = { routerId });
  type === 'associateToAsset' &&
    (apiCfg.data = {
      ...apiCfg.data,
      assetPlcType: assetPlcType,
    });

  httpService.api(apiCfg).then((res: PlcValidModel) => {
    if (plcSerial.text === initialValuesPlc.text) {
      setCatalogNumber(res.catalogNumber);
      const plcVal = {
        id: res.id || Math.random(),
        text: plcSerial.text,
        message: null,
        type: res.type,
      };
      formik.setFieldValue(name, plcVal);
      setPlcSerial && setPlcSerial(plcVal);
      setPlcSerialStatus && setPlcSerialStatus(PlcSerialStatus.validated);
    } else {
      if (res.valid || res.succeed) {
        setCatalogNumber(res.catalogNumber);
        const plcVal = {
          id: res.id,
          text: plcSerial.text,
          message: null,
          type: res.type,
        };
        formik.setFieldValue(name, plcVal);
        setPlcSerial && setPlcSerial(plcVal);
        setPlcSerialStatus && setPlcSerialStatus(PlcSerialStatus.validated);
        onSucceedValidation &&
          onSucceedValidation(
            {
              ...formik.values,
              [name]: {
                id: res.id,
                text: plcSerial.text,
                message: null,
                type: res.type,
              },
            },
            res
          );
      } else {
        const plcVal = {
          id: res.assetId || Math.random(),
          text: plcSerial.text,
          message:
            res.message &&
            i18nService.translate(
              `errors.${res.message.code || res.message}`,
              undefined,
              res.message.keys
                ? buildErrorObj(
                    res.message.keys,
                    res.message.code === 33 ? convertPlcTypeToString : null
                  )
                : undefined
            ),
          type: res.type,
        };
        formik.setFieldValue(name, plcVal);
        setPlcSerial && setPlcSerial(plcVal);
        formik.setFieldTouched(name, true);
      }
    }
  });
};

const convertPlcTypeToString = (plcType) => {
  return staticDictionary && staticDictionary[`enum.${plcType}`]
    ? staticDictionary[`enum.${plcType}`]
    : plcType;
};

export const disconnectPlcSerial = (
  assetId,
  setDisconnectPlcByBtn,
  setPlcSerial,
  setCatalogNumber,
  rowIndex,
  formik,
  name
): boolean => {
  modalService
    .openModal('confirm', {
      text: 'edit-asset-modal.disconnectMessage',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: 'edit-asset-modal.disconnectHeader',
      showCloseBtn: true,
    })
    .then((confirm) => {
      if (confirm) {
        httpService
          .api({
            type: 'disconnectPlcFromAsset',
            urlParams: { assetId },
          })
          .then((res: any) => {
            formik.setFieldValue(name, {
              id: null,
              text: '',
              message: null,
              type: null,
            });
            assetsService.updateAssetTableData(
              { status: 'DISCONNECTED', hasCertificate: false },
              rowIndex
            );
            editAssetService.updateEditAsset({
              status: 'DISCONNECTED',
              connectedAt: null,
              state: res.state,
              plcSerial: { text: '' },
              plcModel: null,
              plcCatalogNumber: null,
              hasCertificate: false,
            });
            setDisconnectPlcByBtn(true);
            const plcVal = { id: null, text: '', message: null };
            setPlcSerial(plcVal);
            setCatalogNumber(null);
            assetsService.refreshAssetTableData();
            return true;
          });
      }
    });

  return false;
};
