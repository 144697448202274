import React from 'react';
import { makeStyles } from '@material-ui/core';

import { DataStepComponentProps } from './DataStepComponent.interface';
import { dataStepComponentMap } from './DataStepComponentMap';

const useStyles = makeStyles(() => ({
  widgetDataContainer: {
    flexGrow: 1,
    height: 'inherit',
    width: '100%',
    zIndex: 0,
  },
  widgetDataContainerFixed: {
    height: 'inherit',
    width: '50%',
    zIndex: 0,
  },
}));

function DataStepComponent(props: DataStepComponentProps) {
  const {
    widgetData,
    setWidgetData,
    currentStep,
    previewData,
    onChangeStep,
    isNameValid,
    defaultDecimalDigits,
  } = props;
  const CurrentComponent = dataStepComponentMap[currentStep];
  const classes = useStyles(props);
  return (
    //The design is different according to the design of the steps
    <div
      className={
        currentStep === 3 || currentStep === 4
          ? classes.widgetDataContainerFixed
          : classes.widgetDataContainer
      }>
      <CurrentComponent
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        previewData={previewData}
        onChangeStep={onChangeStep}
        isNameValid={isNameValid}
        defaultDecimalDigits={defaultDecimalDigits}
      />
    </div>
  );
}

export default DataStepComponent;
