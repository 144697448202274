import React, { useState, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { MuiThemeProvider } from '@material-ui/core';
import store, { dispatch } from '@redux/store';
import App from './App';
import { authService } from '@auth/AuthService';
import { cssVarsService } from '@core/CssVarsService';
import { dndService } from '@core/DndService';
import ModalsContainer from '@core/modals/ModalsContainer';
import GlobalBusyIndicator from '@components/GlobalBusyIndicator';
import { httpService } from '@core/http/HttpService';
import { setConfig, setLanguage } from '@src/redux/config';
import { setClientType } from '@src/redux/client';

function checkIsBrowserOrApp(): string {
  try {
    return uniCloudApp?.isUniCloudApp() ? 'uniCloudApp' : 'browser';
  } catch {
    try {
      window['webkit']['messageHandlers']['isiOSUniCloudApp'].postMessage('');
      return 'iOSUniCloudApp';
    } catch (e) {
      return 'browser';
    }
  }
}

function Root() {
  const [showApp, setShowApp] = useState(false);

  const setConfigAndLanguage = async () => {
    const config: any = await httpService.api({
      type: 'getUnauthenticatedConfig',
    });
    dispatch(setConfig({ whiteLabelDetails: config.whiteLabelDetails }));
    config.languageTerms && dispatch(setLanguage({ dictionary: config.languageTerms }));
  };

  useEffect(() => {
    const clientType = checkIsBrowserOrApp();
    dispatch(setClientType(clientType));

    Promise.all([setConfigAndLanguage(), authService.init(clientType)]).then(() => {
      setShowApp(true);
    });
  }, []);

  if (!showApp) {
    return null;
  }

  return (
    <ReduxProvider store={store}>
      <MuiThemeProvider theme={cssVarsService.theme}>
        {/* There should be only one app-wide DragDropContext. Use the DndService to listen to events. */}
        <DragDropContext onDragEnd={dndService.onDragEnd}>
          <App />
          <ModalsContainer />
          <GlobalBusyIndicator />
        </DragDropContext>
      </MuiThemeProvider>
    </ReduxProvider>
  );
}

export default Root;
