import { i18nService } from '@core/i18n/I18nService';

export const displayText = (
  filterType,
  filter,
  languageId,
  key,
  additionalKey?,
  isAssetIllegalRange?
) => {
  if (!filterType) {
    return i18nService.translate('general.illegal-range', languageId);
  } else {
    if (filter.filterType === 'ASSET') {
      return isAssetIllegalRange
        ? i18nService.translate('general.illegal-range', languageId)
        : filterType.length
        ? filterType
            .map((f) => (additionalKey ? `${f[key]} - ${f[additionalKey]}` : f[key]))
            .join(', ')
        : i18nService.translate('general.all', languageId);
    } else {
      return filterType.length
        ? filterType.map((f) => f[key]).join(', ')
        : i18nService.translate('general.all', languageId);
    }
  }
};
