export function getHeatmapTableData(
  categories: string[],
  columns: Array<{ name: string; valueId: number; valueType: string }>,
  results: any[],
  groupBy: any[]
) {
  if (groupBy?.length === 1) {
    const tableData = categories.map((category, catIdx) => {
      let resRow = { ['@rowNumber']: catIdx + 1, [columns[0].name]: category };
      columns
        .filter((column) => column.valueId !== 7 && column.valueType !== 'ASSET_PROPERTY')
        .forEach((col, index) => {
          resRow = { ...resRow, [col.name]: results[index].data[catIdx] };
        });
      return resRow;
    });
    return tableData;
  } else {
    const tableData = categories.map((category, catIdx) => {
      let resRow = { ['@rowNumber']: catIdx + 1, [columns[0].name]: category };
      results.forEach((res, index) => {
        resRow = { ...resRow, [res.name]: results[index].data[catIdx] };
      });
      return resRow;
    });
    return tableData;
  }
}

export function getHeatmapColumnsForMultipleGroupBy(
  columns: any[],
  results: any[],
  LabelComponent: (props) => JSX.Element
) {
  const dateColumn = {
    ...columns[0],
    field: columns[0].name,
    label: 'general.date',
    Component: LabelComponent,
    style: {
      width: `${100 / (results.length + 1)}%`,
      minWidth: '100px',
      maxWidth: `${100 / (results.length + 1)}%`,
    },
  };
  const groupByColumns = results.map((res) => {
    return {
      field: res.name,
      label: res.name,
      Component: LabelComponent,
      style: {
        width: `${100 / (results.length + 1)}%`,
        minWidth: '100px',
        maxWidth: `${100 / (results.length + 1)}%`,
      },
    };
  });
  return [dateColumn, ...groupByColumns];
}
