import React, { useMemo } from 'react';

// Comonents
import I18n from '@components/I18n';
import Button from '@components/Button';

// Interfaces | Services
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { colorMap } from '@core/statusColorMap';
import { useSelector } from '@src/redux/useSelector';

const ActivateButton = (props) => {
  const { subscriptionKey, status, endDate, startDate, styles = {}, id, refresh } = props;
  const languageId = useSelector((state) => state.config.languageId);

  const onClick = async () => {
    const confirm = await modalService.openModal('confirm', {
      text: i18nService.translate(
        'my-account-page.licensing-plan.organizational-subscriptions.activate-madal-body',
        undefined,
        {
          subscription_key: subscriptionKey,
        }
      ),
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      headerText:
        'my-account-page.licensing-plan.organizational-subscriptions.activate-madal-header',
    });
    if (confirm) {
      try {
        await httpService.api({
          type: 'activateOrganizationSubscription',
          urlParams: { subscription_id: id },
        });
        refresh();
      } catch (e) {}
    }
  };

  const value = useMemo(
    () => i18nService.translate(`enum.subscription.plc.${status}`, languageId),
    [status]
  );

  const color = useMemo(
    () => (status !== 'ACTIVE' && colorMap[status] ? colorMap[status] : 'var(--systemFont)'),
    [status]
  );

  return (
    <>
      {!(!!endDate || !!startDate) ? (
        <Button onClick={onClick} styles={{ minWidth: 90, ...styles }}>
          <I18n>{`my-account-page.licensing-plan.organizational-subscriptions.activate`}</I18n>
        </Button>
      ) : (
        <span
          style={{
            color: color,
            ...styles,
          }}
          title={value}>
          {value}
        </span>
      )}
    </>
  );
};

export default ActivateButton;
