import { editorConfig } from '@core/canvas/editorConfig';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { cssVarsService } from '@core/CssVarsService';
import { httpService } from '@core/http/HttpService';
import moment from 'moment-timezone';
import { css } from 'react-select/src/components/SingleValue';

export const setLiveWidgetData = (
  getWidgetData,
  isUnauthorized,
  userFilters,
  widget,
  paging,
  setWidgetData,
  setIsUnauthorized
) => {
  if (
    !isUnauthorized &&
    userFilters &&
    getConfigValueFromWidgetSettings(widget.type, 'dataChangesToHandle') &&
    !getConfigValueFromWidgetSettings(widget.type, 'getWidgetDataOnlyInPreview') &&
    (!widget.status || widget.status.toUpperCase() === 'PUBLISHED')
  ) {
    paging.p = 1;
    getWidgetData(widget.id, paging, null, widget.type, widget.customization)
      .then((res) => setWidgetData(res))
      .catch((err) => err && err.status === 405 && setIsUnauthorized(true));
  }
};

//belong to pagination
export const getMoreWidgetData = async (getWidgetData, widget, paging, setWidgetData) => {
  paging.p += 1;
  const res = await getWidgetData(widget.id, paging, { disableBI: true }, widget.type);
  res &&
    res.results &&
    setWidgetData((prevState) => ({
      ...prevState,
      next: res.next,
      results: prevState.results.concat(res.results),
    }));
  return res;
};

export const exportData = (widget, dashboardFilters, widgetFilters, setWidgetsDownloadStatus) => {
  httpService
    .api({
      type: 'exportWidgetData',
      urlParams: { widgetId: widget.id },
      data: {
        exportFormat: widget.exportFormats[0],
        date: moment().format('DD/MM/YY HH/MM/SS'),
        tz: moment.tz.guess(true),
        dashboardFilters: {
          organizationsFilter:
            dashboardFilters.organizationsFilter &&
            dashboardFilters.organizationsFilter.map((f) => f.id),
          assetTypesFilter:
            dashboardFilters.assetTypesFilter && dashboardFilters.assetTypesFilter.map((f) => f.id),
          assetFilter:
            dashboardFilters.assetFilter.length > 0 ? [dashboardFilters.assetFilter[0].id] : [],
          geoFilter: dashboardFilters.geoFilter,
          dateFilter: dashboardFilters.dateFilter,
          dateFilterFrom: dashboardFilters.dateFilterFrom,
          dateFilterTo: dashboardFilters.dateFilterTo,
        },
        widgetFilters,
      },
      disableBI: true,
    })
    .then((res: any) => {
      setWidgetsDownloadStatus((prevState) => ({
        ...prevState,
        [widget.id]: { ...res, status: 'IN_PROGRESS' },
      }));
    });
};

export const getPositionStyle = (position, hideWidgetName) => {
  const cellSize = editorConfig.gridCellSizePx;
  return position
    ? {
        top: position.top,
        left: position.left,
        height: position.bottom - position.top,
        width: position.right - position.left,
      }
    : undefined;
};
