import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { TextField, InputAdornment, withStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import styles from './FormikInputWithCancel.scss';
import { FormikInputProps } from './FormikInputWithCancel.interface';

const inputProps = { autoComplete: 'off' };

const GlobalCss = withStyles({
  '@global': {
    '.MuiInputBase-multiline': {
      padding: '3px 18px 0 7px!important',
      width: '222px'
    }
  }
})(() => null);

function FormikInputWithCancel(props: FormikInputProps) {
  const { formik, name, validate, maxLength, value, multiline = false, rows, cancel } = props;
  const [inputValue, setInputValue] = useState(value);
  const originalValue = useMemo(() => value, []);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const handleChange = useCallback(
    e => {
      const fieldVal = e.target.value;
      if (maxLength && fieldVal.length > maxLength) return;
      setInputValue(fieldVal);
      formik.setFieldValue(name, fieldVal);
    },
    [formik]
  );

  const cancelChanges = () => {
    formik.setFieldValue(name, originalValue);
    cancel();
  };

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(name, true);
  }, [formik]);

  return (
    <div id={error && touched ? styles.formikErrorInput : ''}>
      <GlobalCss />
      <TextField
        variant="outlined"
        value={inputValue}
        margin="dense"
        multiline={multiline}
        rows={rows}
        onChange={handleChange}
        onBlur={handleBlur}
        className={styles.textfield}
        id={multiline ? styles.textareaInput : styles.textfieldInput}
        error={!!error && !!touched}
        inputProps={inputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={styles.textfieldIcon}>
              <Icon
                type="remove"
                className={classNames('pointer', styles.editIcon, multiline && styles.multiLine)}
                onClick={cancelChanges}
              />
            </InputAdornment>
          )
        }}
      />
      <ErrorMessage name={name}>
        {err => (
          <I18n className={styles.error} element="div">
            {err}
          </I18n>
        )}
      </ErrorMessage>
    </div>
  );
}

export default connect(FormikInputWithCancel);
