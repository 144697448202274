import React from 'react';
import { AssetTypeData } from '@src/redux/redux.interface';

function AssetTypesLabel(props: AssetTypeData) {
  const { labels } = props;
  const labelsText = labels.map((i) => i.text).join(', ');
  return (
    <div className="ellipsis-overflow" style={{ minWidth: '62px' }} title={labelsText}>
            {labelsText}
    </div>
  );
}

export default AssetTypesLabel;
