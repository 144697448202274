import React from 'react';
import Panel from '../../Panel/Panel';
import DateTime from './DateTime';
import DelimiterSettings from './DateTime/DelimiterSettings/DelimiterSettings';
import NumberFormatting from '@src/pages/MyAccountPage/SharedComponents/NumberFormatting';
import styles from './RegionalSettings.scss';

const panelsMap = [
  { label: 'white-labeling.date-time.title', Component: DateTime },
  { label: 'white-labeling.number-formatting.title', Component: NumberFormatting },
  { label: 'white-labeling.csv-delimiter-settings.title', Component: DelimiterSettings },
];

function RegionalSettings(props) {
  return panelsMap.map((panel) => {
    return (
      <div className={styles.innerAccordionWrapper}>
        <Panel {...panel} key={panel.label} {...props} />
      </div>
    );
  });
}

export default RegionalSettings;
