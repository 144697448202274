import React, { useMemo } from 'react';
import { AssetData } from '@redux/redux.interface.d';
import { colorMap } from '@core/statusColorMap';
import { staticDictionary } from '@core/i18n/dictionary';

function AssetsStatus(props: AssetData) {
  const { status } = props;
  const value = useMemo(() => staticDictionary[`enum.${status}`], [status]);
  return (
    <span style={{ color: colorMap[status] }} title={value}>
      {value}
    </span>
  );
}

export default AssetsStatus;
