export const setMetrics = (setWidgetData, widgetData) => {
  if (!widgetData.metrics?.length) {
    setWidgetData((prevState) => {
      return {
        ...prevState,
        metrics: [
          {
            hide: true,
            operation: null,
            order: 0,
            type: 'stringType',
            valueId: 1,
            valueType: 'ASSET_PROPERTY',
            name: 'create-widget-page.create-widget.asset-properties.ASSET_ID',
          },
          {
            hide: true,
            operation: null,
            order: 1,
            type: 'stringType',
            valueId: 2,
            valueType: 'ASSET_PROPERTY',
            name: 'create-widget-page.create-widget.asset-properties.ASSET_NAME',
          },
          {
            hide: true,
            operation: null,
            order: 2,
            type: 'stringType',
            valueId: 5,
            valueType: 'ASSET_PROPERTY',
            name: 'create-widget-page.create-widget.asset-properties.STATUS',
          },
        ],
      };
    });
  }
};
