import React, { useMemo } from 'react';
import Table from '@components/Table';
import styles from './LanguageTable.scss';
import TextField from '@components/Table/TableFields/TextField';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { compact } from 'lodash';
import {
  openEditLanguageModal,
  openExportLanguageModal,
  openNewLanguageModal,
  openUploadLanguage,
} from '../language.utils';
import LanguageFlag from './Components/LanguageFlag';
import LanguageDefault from './Components/LanguageDefault';
import LanguageExport from './Components/LanguageExport';
import LanguageEdit from './Components/LanguageEdit';

function LanguageTable(props) {
  const {
    languages,
    setLanguages,
    organizationType,
    defaultLanguageId,
    setDefaultLanguageId,
    setSelectedLanguage,
  } = props;

  const colunmsCfg = useMemo(
    () => [
      {
        Component: TextField,
        field: 'name',
        label: 'languages.language',
        style: { width: '17%' },
      },
      {
        Component: LanguageFlag,
        field: 'flag',
        style: { width: '17%' },
      },
      {
        Component: TextField,
        field: 'status',
        label: 'general.status',
        style: { width: '17%' },
        getTranslatedValuesToDisplay: (value) => {
          return value && `general.${value.toLowerCase()}`;
        },
      },
      {
        Component: LanguageDefault,
        field: 'isMBDefaultLanguage',
        label: 'general.default',
        style: { width: '29%' },
      },
      {
        Component: LanguageEdit,
        style: { width: '10%' },
        onEditClick: (languageData) =>
          openEditLanguageModal(
            setLanguages,
            languages,
            languageData,
            setSelectedLanguage,
            setDefaultLanguageId
          ),
      },
      {
        Component: LanguageExport,
        style: { width: '10%' },
        onExportClick: (languageData) => openExportLanguageModal(languageData.id, organizationType),
      },
    ],
    []
  );

  const toolbarCfg: ToolbarCfg = {
    buttons: compact([
      {
        type: 'upload',
        onClick: openUploadLanguage,
        text: 'languages.upload-translation',
      },
      {
        type: 'create',
        onClick: () => openNewLanguageModal(setLanguages, languages),
        text: 'languages.add-language',
      },
    ]),
  };

  // Returns an alphabetically sorted list with the default language at the top
  const getSortedLanguages = () => {
    const sorted = languages.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    const filtered = sorted.filter((language) => language.id !== defaultLanguageId);
    filtered.unshift(languages.find((language) => language.id === defaultLanguageId));
    const isMBDefaultLanguageAdded = filtered.map((language) => ({
      ...language,
      isMBDefaultLanguage: language?.id === defaultLanguageId,
    }));
    return isMBDefaultLanguageAdded;
  };

  return (
    <>
      <ToolBar toolbarCfg={toolbarCfg} />
      <div className={styles.tableWrapper}>
        <Table data={getSortedLanguages()} getData={() => {}} colunmsCfg={colunmsCfg} />
      </div>
    </>
  );
}

export default LanguageTable;
