import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import TimeField from 'react-advanced-timefield';
import { InputBase } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '28px',
    borderRadius: '5px',
    border: 'solid 1px var(--lightGrey)',
    padding: '0 13px',
    fontSize: '12px',
    color: 'var(--systemFont)',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
});
function TimeFieldMore24(props) {
  const { onUpdateDuration, idx, filter } = props;
  const [duration, setDuration] = useState(Array.isArray(filter.values) ? '' : filter.values);
  const classes = useStyles(props);

  const onBlur = () => onUpdateDuration(duration, idx);

  const onChange = (e, val) => setDuration(val);

  return (
    <TimeField
      className={classes.root}
      value={duration}
      onChange={onChange}
      onBlur={onBlur}
      input={<InputBase id={idx} />}
      showSeconds
    />
  );
}

export default TimeFieldMore24;
