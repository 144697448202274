import * as Yup from 'yup';

import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';
import { phoneRegex, buildErrorObj, emailRegex } from '@core/utils';

export const getFieldsCfg = (
  emailValidation,
  setEmailValidation,
  userId,
  setUserId,
  dashboardOptions,
  orgType,
  rolesOptions,
  setIsValidEmail
) => [
  {
    label: 'my-details.email',
    name: 'email',
    type: 'input',
    isRequired: true,
    validate: (values) => {
      if (emailValidation.email === values.email && emailValidation.message) {
        return emailValidation.message;
      }
    },
    validation: Yup.string()
      .trim()
      .required('validations.mandatory-field')
      .matches(emailRegex, 'validations.incorrect-email'),
    onLostFocus: (values, setFieldValue, setInputValue, setErrors) => {
      values.email = values.email?.trim();
      emailValidation.email !== values['email'] &&
        httpService
          .api({
            type: 'userEmailValidate',
            data: { email: values['email'] },
            disableBI: true,
          })
          .then((res: any) => {
            if (res.isValid) {
              if (res.id) {
                modalService
                  .openConfirm({
                    text: `errors.${res.message.code}`,
                    ...buildErrorObj(res.message.keys),
                  })
                  .then((confirm) => {
                    if (confirm) {
                      setFieldValue('id', res['id']);
                      setUserId(res['id']);
                      setEmailValidation({ email: values['email'], message: '', isValid: true });
                      setIsValidEmail(true);
                      return;
                    } else {
                      setFieldValue('email', '');
                      setInputValue('');
                      setEmailValidation({ email: '', message: '', isValid: false });
                      setIsValidEmail(true);
                    }
                  });
              } else {
                setEmailValidation({ email: values['email'], message: '', isValid: true });
                setIsValidEmail(true);
                setFieldValue('id', null);
                setUserId(null);
              }
            } else {
              setEmailValidation({
                email: values.email,
                message:
                  res.message &&
                  i18nService.translate(
                    `errors.${res.message.code}`,
                    undefined,
                    buildErrorObj(res.message.keys)
                  ),
                isValid: false,
              });
              setIsValidEmail(true);
              setFieldValue('id', null);
              setUserId(null);
            }
          });
    },
  },
  {
    label: 'my-details.title',
    name: 'title',
    type: 'select',
    options: ['NONE', 'MR', 'MRS', 'MISS', 'MS', 'DR', 'PROF'].map((opt) => ({
      value: opt,
      label: i18nService.translate(`enum.${opt}`),
    })),
    condition: (values) => !userId,
  },
  {
    label: 'my-details.first-name',
    name: 'firstName',
    type: 'input',
    isRequired: !userId,
    validation:
      !userId &&
      Yup.string()
        .required('validations.mandatory-field')
        .max(20, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
    condition: (values) => !userId,
  },
  {
    label: 'my-details.middle-name',
    name: 'middleName',
    type: 'input',
    validation:
      !userId && Yup.string().max(20, 'my-details.name-message').min(1, 'my-details.name-message'),
    condition: (values) => !userId,
  },
  {
    label: 'my-details.last-name',
    name: 'lastName',
    type: 'input',
    isRequired: !userId,
    validation:
      !userId &&
      Yup.string()
        .required('validations.mandatory-field')
        .max(100, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
    condition: (values) => !userId,
  },
  {
    label: 'my-details.phone',
    name: 'phone',
    type: 'phoneInput',
    condition: (values) => !userId,
  },
  {
    label: 'my-details.mobile',
    name: 'mobile',
    type: 'phoneInput',
    condition: (values) => !userId,
  },
  {
    label: 'my-details.roles',
    name: 'roles',
    options: rolesOptions,
    type: 'multiSelect',
    isRequired: true,
    maxMenuHeight: 180,
    validation: Yup.array().required('validations.mandatory-field'),
  },
  // {
  //   label: 'my-details.default-dashboard',
  //   name: 'dashboard',
  //   type: 'select',
  //   options: dashboardOptions,
  //   condition: values => {
  //     return orgType === 3 || orgType === 4 || orgType === 5;
  //   }
  // }
];
