import React, { useEffect, useCallback } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import styles from './FormikSelect.scss';
import { FormikSelectProps } from './formikSelect.interface';

function FormikSelect(props: FormikSelectProps) {
  const {
    formik,
    name,
    label,
    validate,
    disabled,
    isRequired,
    options,
    defaultValue,
    className,
    placeholder,
    onSelectChange,
    maxMenuHeight,
    hideLabel = false,
    mode,
    labelClassName = '',
    inputClassName = '',
    selectStyles,
    ...restProps
  } = props;
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const handleChange = useCallback(
    (selectedOpt) => {
      formik.setFieldValue(name, selectedOpt);
      onSelectChange && onSelectChange(selectedOpt, formik.setFieldValue, formik.values);
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(name, true);
  }, [formik]);

  return (
    <>
      <div className={classNames(styles.fieldWrapper, className)}>
        {!hideLabel && (
          <label
            className={classNames(
              styles.fieldLabel,
              isRequired && 'asterisk',
              isRequired && styles.isRequired,
              mode === 'NewMobile' && styles.mobileMode,
              'ellipsis-overflow',
              labelClassName
            )}>
            <I18n>{label}</I18n>:
          </label>
        )}
        <div className={classNames(styles.fieldInput, inputClassName)}>
          <Select
            placeholder={placeholder}
            value={defaultValue}
            isDisabled={disabled}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            maxMenuHeight={maxMenuHeight || 180}
            menuPortalTarget={restProps['menuPortalTarget']}
            errorStyle={error && touched}
            styles={selectStyles}
            {...restProps}
          />
          <ErrorMessage name={name}>
            {(err) => (
              <I18n className={styles.error} element="div">
                {err}
              </I18n>
            )}
          </ErrorMessage>
        </div>
      </div>
    </>
  );
}

export default connect(FormikSelect);
