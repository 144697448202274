import React, { useEffect, useCallback, useState } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import { i18nService } from '@core/i18n/I18nService';
import I18n from '@components/I18n';
import styles from './TwoFAInput.scss';
import { TwoFAInputProps } from './TwoFAInput.interface';
import { InputBase } from '@material-ui/core';
import Icon from '@components/Icon';
import { useSelector } from '@src/redux/useSelector';

const getStyles = (isPassword, hasError, styles) => {
  if (isPassword) return hasError ? styles.inputPasswordError : styles.inputPassword;
  else return hasError ? styles.inputError : styles.input;
};

function TwoFAInput(props: TwoFAInputProps) {
  const { formik, name, label, type, disabled, disabledPaste, trimOnBlur, hasError } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const [inputValue, setInputValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      formik.setFieldValue(name, '');
    };
  }, [name]);

  const handleChange = useCallback(
    (e) => {
      const fieldVal = e.target.value;
      setInputValue(fieldVal);
      formik.setFieldValue(name, fieldVal);
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(name, true);
    if (trimOnBlur) {
      const nameValue = getIn(formik.values, name)?.trim();
      setInputValue(nameValue);
      formik.setFieldValue(name, nameValue);
    }
  }, [formik]);

  return (
    <div className={styles.fieldInput}>
      <div style={{ position: 'relative' }}>
        <InputBase
          id={getStyles(type === 'password', hasError, styles)}
          value={inputValue}
          placeholder={i18nService.translate(label)}
          disabled={disabled}
          margin="dense"
          fullWidth
          type={!showPassword && type}
          inputProps={{
            onPaste: (e) => {
              disabledPaste && e.preventDefault();
            },
            onChange: handleChange,
          }}
          onBlur={handleBlur}
          className={hasError ? styles.inputError : ''}
          error={hasError}
        />
        {type === 'password' && (
          <div
            title={i18nService.translate('login.show-password', languageId)}
            className={styles.showPassword}
            onMouseDown={() => setShowPassword(true)}
            onTouchStart={() => setShowPassword(true)}
            onTouchEnd={() => setShowPassword(false)}
            onMouseUp={() => setShowPassword(false)}>
            <Icon type="showPassword" />
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(TwoFAInput);
