import I18n from '@components/I18n';
import { TextField } from '@material-ui/core';
import MaterialCheckbox from '@components/Checkbox';
import Select from '@components/Select';
import MultiSelect from '@components/MultiSelect';
import NumberInput from '@components/NumberInput';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './Details.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { httpService } from '@core/http/HttpService';
import { GetOwnersRes } from '@core/http/server.interface';
import { userFiltersService } from '@pages/DashboardEditorPage/UserFiltersService';
import { modalService } from '@core/modals/ModalService';
import { isLengthExceeded } from '../EditTemplate.utils';

function Details(props) {
  const { templateData, setTemplateData, validation, nameValidationError } = props;
  const {
    name,
    trigger,
    action,
    latchPeriod = 30,
    latchPeriodUnit = 'MINUTE',
    isSpecificOrg,
    orgScope,
    specificOrgId,
  } = templateData;
  const { organizationDetails } = useSelector((state) => state.config);
  const [subOrgOptions, setSubOrgOptions] = useState([]);
  const [showSubOrgsSelection, setShowSubOrgsSelection] = useState(false);
  const [showLatchPeriod, setShowLatchPeriod] = useState(false);

  // DROPDOWN OPTIONS
  const actionTypeOptions = useMemo(
    () => [
      {
        value: 'EMAIL_NOTIFICATION',
        label: 'events-template.edit-template.action-type.email-notification',
      },
      {
        value: 'PUSH_NOTIFICATION',
        label: 'events-template.edit-template.action-type.push-notification',
      },
      {
        value: 'SMS_NOTIFICATION',
        label: 'events-template.edit-template.action-type.sms-notification',
      },
    ],
    []
  );

  const triggerOptions = useMemo(
    () => [
      {
        value: 'SCHEDULER',
        label: 'events-template.edit-template.trigger-type.SCHEDULER',
      },
      {
        value: 'ALARM',
        label: 'events-template.edit-template.trigger-type.alarm',
      },
      {
        value: 'TELEMETRY',
        label: 'events-template.edit-template.trigger-type.telemetry',
      },
    ],
    []
  );

  const latchPeriodUnitOptions = useMemo(
    () => [
      {
        value: 'MINUTE',
        label: 'events-template.edit-template.trigger-type.latch-period-minutes',
      },
      {
        value: 'HOUR',
        label: 'events-template.edit-template.trigger-type.latch-period-hours',
      },
    ],
    []
  );

  const organizationTypeOptions = useMemo(
    () => [
      {
        value: 'MACHINE_BUILDER',
        label: 'events-template.edit-template.organization-type.machine-builder',
      },
      {
        value: 'MACHINE_BUILDER_CHANNEL',
        label: 'events-template.edit-template.organization-type.machine-builder-channel',
      },
      {
        value: 'END_CUSTOMER',
        label: 'events-template.edit-template.organization-type.customer',
      },
    ],
    []
  );

  // EVENT HANDLERS

  const handleOnBlur = () => {
    latchPeriodUnit === 'HOURS'
      ? setTemplateData(24 < +latchPeriod ? 24 : 1 > +latchPeriod ? 1 : +latchPeriod, 'latchPeriod')
      : setTemplateData(
          60 < +latchPeriod ? 60 : 1 > +latchPeriod ? 1 : +latchPeriod,
          'latchPeriod'
        );
  };

  const handleOnChange = (e) => {
    setTemplateData(e.target.value, 'latchPeriod');
  };

  // USE EFFECT

  useEffect(() => {
    if (trigger === 'ALARM' || trigger === 'TELEMETRY') {
      setShowLatchPeriod(true);
      setTemplateData([], 'assetFilter');

      // Reset Scheduler relevant templateData to defaults
      [
        { key: 'timeFrame', value: 'DAILY' },
        { key: 'daysOfWeek', value: null },
        { key: 'dayOfMonth', value: null },
        { key: 'dayOfYear', value: null },
        { key: 'monthOfYear', value: null },
      ].forEach((element) => {
        setTemplateData(element.value, element.key);
      });
    } else {
      setShowLatchPeriod(false);
    }

    setTemplateData(latchPeriod, 'latchPeriod');
    setTemplateData(latchPeriodUnit, 'latchPeriodUnit');
  }, [trigger]);

  useEffect(() => {
    if (latchPeriodUnit === 'HOUR' && latchPeriod > 24) {
      setTemplateData(1, 'latchPeriod');
    }

    if (latchPeriod === null) {
      setTemplateData(1, 'latchPeriod');
    }
  }, [latchPeriodUnit]);

  useEffect(() => {
    userFiltersService.getOrganizationOptions();
    userFiltersService.getAssetTypeOptions();
  }, []);

  useEffect(() => {
    if (isSpecificOrg && orgScope[0] !== 'MACHINE_BUILDER') {
      setShowSubOrgsSelection(false);
      httpService
        .api({
          type: 'getOwners',
          query: {
            type: orgScope[0],
          },
        })
        .then((types: GetOwnersRes) => {
          setSubOrgOptions(
            types.map((subOrg) => {
              return {
                label: subOrg.name,
                value: subOrg.id,
              };
            })
          );
          setShowSubOrgsSelection(true);

          if (!types.some((subOrg) => subOrg.id === specificOrgId))
            setTemplateData(null, 'specificOrgId');
        })
        .catch((error) => {
          setSubOrgOptions([]);
          setShowSubOrgsSelection(true);
          setTemplateData(null, 'specificOrgId');
        });
    }
  }, [isSpecificOrg, orgScope]);

  function onActionTypeChange(option) {
    if (['SMS_NOTIFICATION', 'PUSH_NOTIFICATION'].includes(option.value)) {
      let newSubject = templateData.subject;
      let newBody = templateData.body;

      const shouldRemoveDataSourceFromSubject =
        option.value === 'PUSH_NOTIFICATION' && templateData.subject?.includes('href="datasource');

      if (shouldRemoveDataSourceFromSubject) {
        newSubject = removeDataSource();
      }

      const isSubjectLengthExceeded = isLengthExceeded(
        option.value,
        'TITLE',
        newSubject,
        true,
        false
      );
      const isBodyLengthExceeded = isLengthExceeded(option.value, 'MESSAGE', newBody, true);

      if (isSubjectLengthExceeded || isBodyLengthExceeded) {
        function onConfirm(subject, body) {
          setTemplateData(option.value, 'action');
          setTemplateData(subject, 'subject');
          setTemplateData(body, 'body');
        }

        openRemoveTextConfirmation(shouldRemoveDataSourceFromSubject, () =>
          onConfirm(
            isSubjectLengthExceeded ? null : newSubject,
            isBodyLengthExceeded ? null : newBody
          )
        );
      } else if (shouldRemoveDataSourceFromSubject) {
        openRemoveDataSourceConfirmation(option);
      } else {
        setTemplateData(option.value, 'action');
      }
    } else {
      setTemplateData(option.value, 'action');
    }
  }

  function openRemoveDataSourceConfirmation(option) {
    modalService
      .openModal('confirm', {
        headerText: 'events-template.edit-template.remove-datasource-header',
        text: 'events-template.edit-template.remove-datasource-from-subject-text',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          const newSubject = removeDataSource();
          setTemplateData(newSubject, 'subject');
          setTemplateData(option.value, 'action');
        } else {
          return;
        }
      });
  }

  function removeDataSource() {
    const dataSourceRegex = /([\<])(a href)(.{1,})([^\<\/p\>])/g;
    const splitSubject = templateData.subject.split('</a>');
    const withoutDataSources = splitSubject.map((substring) =>
      substring.replaceAll(dataSourceRegex, '')
    );
    return withoutDataSources.join('');
  }

  function openRemoveTextConfirmation(
    shouldRemoveDataSourceFromSubject: boolean,
    onConfirm: () => void
  ) {
    modalService
      .openModal('confirm', {
        headerText: 'events-template.edit-template.remove-text-pop-up.header',
        text: shouldRemoveDataSourceFromSubject
          ? 'events-template.edit-template.remove-text-and-data-source-pop-up.text'
          : 'events-template.edit-template.remove-text-pop-up.text',
        iconType: 'attention_image',
        showCloseBtn: true,
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
      })
      .then((confirm) => {
        if (confirm) {
          onConfirm();
        }
      });
  }

  return (
    <div className={styles.section}>
      <div className={styles.rowWithError}>
        {/* TEMPLATE NAME */}
        <div className={styles.header}>
          <I18n>events-template.edit-template.name</I18n>
        </div>
        <TextField
          value={name || ''}
          margin="dense"
          variant="outlined"
          className={styles.textField}
          id={styles.textFieldColor}
          inputProps={{
            style: { fontSize: 12 },
            maxLength: 100,
            autoFocus: true,
          }}
          onChange={(e) => {
            setTemplateData(e.target.value, 'name');
          }}
          onBlur={(e) => {
            setTemplateData(e.target.value, 'name', true);
          }}
        />
      </div>
      <div className={styles.error}>
        {nameValidationError && <I18n>events-template.edit-template.name-validation-error</I18n>}
      </div>
      {/* TRIGGER TYPE */}
      <div className={styles.row}>
        <div className={styles.header}>
          <I18n>events-template.edit-template.trigger-type</I18n>
        </div>
        <Select
          styles={{ container: { width: '450px' } }}
          className={styles.field}
          options={triggerOptions}
          value={triggerOptions.find((opt) => opt.value === trigger)}
          defaultValue={triggerOptions[0]}
          getOptionLabel={(i) => i18nService.translate(i.label)}
          getOptionValue={(i) => i.value}
          onChange={(option) => setTemplateData(option.value, 'trigger')}
        />
      </div>
      {/* LATCH PERIOD */}
      {(showLatchPeriod || trigger === 'ALARM' || trigger === 'TELEMETRY') && (
        <div className={styles.row}>
          <div className={styles.header}>
            <I18n>events-template.edit-template.trigger-type.latch-period</I18n>
          </div>
          <div className={styles.multiElementRow}>
            <NumberInput
              value={latchPeriod}
              min="1"
              max={`${latchPeriodUnit === 'HOUR' ? 24 : 60}`}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
            />
            <Select
              styles={{ container: { marginLeft: '3px' } }}
              className={styles.field}
              options={latchPeriodUnitOptions}
              value={latchPeriodUnitOptions.find((opt) => opt.value === latchPeriodUnit)}
              defaultValue={latchPeriodUnitOptions[0]}
              getOptionLabel={(i) => i18nService.translate(i.label)}
              getOptionValue={(i) => i.value}
              onChange={(option) => setTemplateData(option.value, 'latchPeriodUnit')}
            />
          </div>
        </div>
      )}
      {/* ACTION TYPE */}
      <div className={styles.row}>
        <div className={styles.header}>
          <I18n>events-template.edit-template.action-type</I18n>
        </div>
        <Select
          styles={{ container: { width: '450px' } }}
          className={styles.field}
          options={actionTypeOptions}
          value={actionTypeOptions.find((opt) => opt.value === action)}
          getOptionLabel={(i) => i18nService.translate(i.label)}
          getOptionValue={(i) => i.value}
          onChange={(option) => {
            if (option.value !== templateData.action) {
              onActionTypeChange(option);
            }
          }}
        />
      </div>
      {/* SCOPE */}
      <div className={styles.subSectionContainer}>
        <div className={styles.header}>
          <I18n>events-template.edit-template.notify-organization</I18n>
        </div>
        {/* NOTIFY ORGANIZATION */}
        <div className={styles.subSection}>
          <div className={styles.row}>
            <div className={styles.subHeader}>
              <I18n>events-template.edit-template.specific-organization</I18n>
            </div>
            <MaterialCheckbox
              color="primary"
              onChange={(e) => setTemplateData(!isSpecificOrg, 'isSpecificOrg')}
              checked={isSpecificOrg || false}
            />
          </div>
          {/* ORGANIZATION TYPE(S) */}
          <div className={styles.row}>
            <div className={styles.subHeader}>
              <I18n>{`events-template.edit-template.organization-${
                isSpecificOrg ? 'type' : 'types'
              }`}</I18n>
            </div>
            {orgScope && isSpecificOrg && (
              <div className={styles.select}>
                <Select
                  styles={{ container: { width: '450px', height: '36px' } }}
                  className={styles.field}
                  options={organizationTypeOptions}
                  value={organizationTypeOptions.find((opt) => opt.value === orgScope[0])}
                  getOptionLabel={(i) => i18nService.translate(i.label)}
                  getOptionValue={(i) => i.value}
                  onChange={(option) => setTemplateData([option.value], 'orgScope')}
                />
              </div>
            )}

            {orgScope && !isSpecificOrg && (
              <div className={styles.multiSelect}>
                <MultiSelect
                  id="organizationTypes"
                  mode="thin"
                  options={organizationTypeOptions}
                  values={organizationTypeOptions.filter((opt) =>
                    orgScope.some((op) => opt.value === op)
                  )}
                  getOptionLabel={(i) => i18nService.translate(i.label)}
                  getOptionValue={(i) => i.value}
                  onChange={(options) =>
                    setTemplateData(
                      options.map((option) => option.value),
                      'orgScope'
                    )
                  }
                  optionType="checkbox"
                />
              </div>
            )}
          </div>
          {orgScope && isSpecificOrg && (
            <div className={styles.row}>
              <div className={styles.subHeader}>
                <I18n>
                  {organizationTypeOptions.find((opt) => opt.value === orgScope[0])?.label}
                </I18n>
              </div>
              {orgScope[0] === 'MACHINE_BUILDER' ? (
                organizationDetails?.name
              ) : { showSubOrgsSelection } ? (
                <Select
                  placeholder="events-template.edit-template.select-organization"
                  styles={{ container: { width: '450px' } }}
                  errorStyle={!specificOrgId}
                  options={subOrgOptions}
                  value={subOrgOptions.find((opt) => opt.value === specificOrgId) || ''}
                  getOptionLabel={(i) => i18nService.translate(i.label)}
                  getOptionValue={(i) => i.value}
                  onChange={(option) => setTemplateData(option.value, 'specificOrgId')}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Details;
