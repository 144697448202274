import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core';
import { uploadService } from '@core/UploadService';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { getState } from '@src/redux/store';

const useStyles = makeStyles(() => ({
  wrapper: {
    borderTop: `1px solid var(--lightGrey)`,
    padding: '5px 24px 21px 26px',
    display: 'flex',
    flexDirection: 'column',
  },
  data: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 2,
    color: 'var(--systemFont)',
  },
  extraBold: {
    fontWeight: 'bold',
  },
  buttonsRow: {
    display: 'flex',
    marginTop: 18,
  },
  firstButton: {
    flex: 1,
  },
}));

function BottomSection(props) {
  const {
    cancel,
    selectImage,
    selectedImage,
    deleteHandler,
    setUploadedImage,
    existingImages,
    imageType,
  } = props;
  const classes = useStyles(props);
  const state = getState();
  const userOrganizationId = state.login && state.login.userOrganizationId;

  const { open, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: 'image/png,image/jpg,image/jpeg,image/gif',
    maxSize: 5000000,
    multiple: false,
    onDropRejected: (rejected) => {
      if (rejected.length) {
        modalService.openModal('confirm', {
          text: `image-customization.image-file-upload-error`,
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          showCloseBtn: true,
        });
      }
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        uploadService.upload({
          getUploadType: 'uploadImageGallery',
          file: acceptedFiles[0],
          fileName: acceptedFiles[0].name,
          callback: setUploadedImage,
          imageType: imageType,
        });
      }
    },
  });

  const deleteImage = async () => {
    try {
      if (
        +selectedImage?.organizationId &&
        +selectedImage?.organizationId !== +userOrganizationId
      ) {
        modalService.openModal('confirm', {
          text: `gallery.cannot-delete-massage`,
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          // headerText: `errors.${32}-header`,
          showCloseBtn: true,
        });
      } else {
        const res: { canDelete: boolean } = await httpService.api({
          type: 'deleteGalleryImageValidation',
          urlParams: { identifier: selectedImage.id },
        });

        if (
          res.canDelete &&
          (!existingImages || !existingImages.find((i) => i === selectedImage.id))
        ) {
          await httpService.api({
            type: 'deleteGalleryImage',
            urlParams: { identifier: selectedImage.id },
          });
          deleteHandler();
        } else {
          modalService.openModal('confirm', {
            text: `gallery.cannot-delete-massage`,
            iconType: 'attention_image',
            confirmText: 'general.confirm',
            // headerText: `errors.${32}-header`,
            showCloseBtn: true,
          });
        }
      }
    } catch (e) {}
  };

  // Will be used later, when System images will be marked with a small icon over it
  // And the delete button will be disabled when selecting a system image.
  //const deleteButtonDisabled = !selectedImage?.id || (+selectedImage?.organizationId && +selectedImage?.organizationId !== +userOrganizationId);

  return (
    <div className={classes.wrapper}>
      <div className={classes.data}>
        <I18n>gallery.supported-formats</I18n>:{' '}
        <span className={classes.extraBold}>PNG, JPG, JPEG, GIF</span>
      </div>
      <div className={classes.data}>
        <I18n>gallery.maximum-size</I18n>: <span className={classes.extraBold}>5 MB</span>
      </div>
      <div className={classes.buttonsRow}>
        <div className={classes.firstButton}>
          <Button onClick={open} mode={['bigFont']}>
            <I18n>gallery.upload</I18n>
          </Button>
        </div>
        <Button onClick={deleteImage} disabled={!selectedImage?.id} mode={['bigFont']}>
          <I18n>general.delete</I18n>
        </Button>
        <Button onClick={cancel} styles={{ marginLeft: 19 }} mode={['cancel', 'bigFont']}>
          <I18n>general.cancel</I18n>
        </Button>
        <Button
          onClick={selectImage}
          disabled={!selectedImage?.id}
          styles={{ marginLeft: 19 }}
          mode={['bigFont']}>
          <I18n>gallery.select</I18n>
        </Button>
      </div>
      <input {...getInputProps()} />
    </div>
  );
}

export default BottomSection;
