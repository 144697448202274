import { Switch, withStyles } from '@material-ui/core';

const CustomSwitch = withStyles({
  switchBase: {
    color: 'var(--systemButtonBackground)',
    '&$checked': {
      color: 'var(--systemButtonBackground)',
    },
    '&$checked + $track': {
      backgroundColor: `#8CBBE9!important`,
    },
  },
  checked: {},
  track: {
    backgroundColor: `#9F9F9F!important`,
  },
})(Switch);

export default CustomSwitch;
