import React from 'react';
import classNames from 'classnames';

import I18n from '@components/I18n';
import styles from '../WidgetSideBar.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

const StepFiveText = ({ displayData }) => {
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <>
      {displayData.navigationDashboard ? (
        <>
          <div
            className={classNames('ellipsis-overflow', styles.activeText)}
            title={
              displayData.navigationDashboard
            }>{`Dashboard: ${displayData.navigationDashboard}`}</div>
          {displayData.navigateFilterBy.length > 0 ? (
            <div
              className={classNames('ellipsis-overflow', styles.activeText)}
              title={displayData.navigateFilterBy.join(
                ', '
              )}>{`Filtered by: ${displayData.navigateFilterBy.join(', ')}`}</div>
          ) : null}
        </>
      ) : (
        <div
          className={styles.textBox}
          title={i18nService.translate('widget-side-bar.step-five.default-text', languageId)}>
          <I18n noEllipsis>widget-side-bar.step-five.default-text</I18n>
        </div>
      )}
    </>
  );
};

export default StepFiveText;
