import React from 'react';
import { AssetTypeData } from '@redux/redux.interface.d';
import I18n from '@components/I18n';
import { staticDictionary } from '@core/i18n/dictionary';

function AssetTypesPlcType(props: AssetTypeData) {
  const { plcType } = props;
  return (
    <I18n title={staticDictionary[`enum.${plcType}`]}>{staticDictionary[`enum.${plcType}`]}</I18n>
  );
}

export default AssetTypesPlcType;
