import React, { useState, useCallback, useMemo } from 'react';
import { template, compact } from 'lodash';
import classNames from 'classnames';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { apiMap } from '@core/http/apiMap';
import { PLCData } from '@redux/redux.interface.d';
import { useSelector } from '@redux/useSelector';
import { getState } from '@redux/store';
import { plcService } from '../PLCService';
import PLCTable from './PLCTable';
import styles from './PLCManagementTab.scss';
import { getPermissionStatus } from '@core/ffAndPermissions';

function PLCManagementPage() {
  const [searchText, setSearchText] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState(null);

  const totalCount = useSelector((state) => state.plc.totalCount);
  const resultsCount = useSelector((state) => state.plc.resultsCount);
  const userOrgType = useSelector(
    (state) => state.config.organizationDetails && state.config.organizationDetails.type
  );

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const search = useCallback((searchText) => setSearchText(searchText), []);

  const openCreatePLC = useCallback(() => {
    modalService.openModal('createPLC').then((res) => {
      if (!res) return;
      httpService
        .api({
          type: res.apiType,
          data: res.payload,
        })
        .then(() => {
          if (searchText) {
            // This will automatically refresh the plc table data.
            setClearSearch(true);
            search('');
          } else {
            plcService.refreshPLCTableData();
          }

          modalService.openAlert({
            text: 'create-plc-modal.add-successful-alert',
            iconType: 'v_image',
          });
        });
    });
  }, [searchText]);

  const getLastSearches = () => {
    const organizationId = getState().login.userOrganizationId;
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getPLCs'].url)({ organizationId }),
        },
        disableBI: true,
      })
      .then((res: { [searches: string]: Array<string> }) => {
        setLastSearches(res.searches);
      });
  };

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      pageName: 'PLC',
      getLastSearches,
      lastSearches,
    },
    buttons: compact([
      ['MACHINE_BUILDER', 'UNITRONICS_MAIN'].includes(userOrgType) && {
        type: 'create',
        onClick: openCreatePLC,
        disabled: !hasPermission,
      },
    ]),
  };

  return (
    <div className={classNames('max-height', styles.assetsTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <PLCTable searchText={searchText} />
    </div>
  );
}

export default PLCManagementPage;
