import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import styles from './PreviewEmailMessageModal.scss';
import Icon from '@components/Icon';
import Button from '@components/Button';
import { convertLocalHtmlToPlainTextWithMentions } from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/RichTextEditor.utils';
import { useSelector } from '@src/redux/useSelector';
import LogoIcon from '@components/LogoIcon';
import TablePreview from './TablePreview/TablePreview';
import { i18nService } from '@core/i18n/I18nService';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import { fixHyperlinksAndMentions } from '@core/utils';

const useStyle = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflowY: 'auto',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
}));

function PreviewEmailMessageModal(props) {
  const { dismiss, args } = props;
  const { templateData, displaySettingsData, tableDataSource } = args;
  const { subject, body, dashboardReport } = templateData;
  const { freeLogo, emailFooter } = displaySettingsData;
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const displayLogo = isPremium ? freeLogo : whiteLabelDetails.premiumLogo;
  const languageId = useSelector((state) => state.config.languageId);
  const classes = useStyle(props);

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const delimiter = useMemo(
    () => displaySettingsData?.regionalSettings?.content?.delimiter || ',',
    []
  );

  const adjustedSubject = useMemo(() => {
    return convertLocalHtmlToPlainTextWithMentions(fixHyperlinksAndMentions(subject), true);
  }, [subject]);

  const adjustedBody = useMemo(() => {
    return fixHyperlinksAndMentions(body);
  }, [body]);

  const downloadDataSource = useCallback((tableDataSource) => {
    let csv = '';
    const { customization } = tableDataSource || {};
    const { columns } = customization || {};
    for (let col = 0; col < columns.length; col++) {
      csv = csv
        .concat(columns[col].displayName)
        .concat(col < columns.length - 1 ? delimiter : '\r\n');
    }

    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < columns.length; col++) {
        csv = csv.concat('Value').concat(col < columns.length - 1 ? delimiter : '\r\n');
      }
    }

    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${tableDataSource.name}.csv`);
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n>events-template.create-message.preview-message.email.modal-header</I18n>
        <Icon type="close" onClick={props.dismiss} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={styles.row}>
            <div className={styles.emailSubject}>
              <div dangerouslySetInnerHTML={{ __html: adjustedSubject }} />
            </div>
          </div>
          <div className={classNames(styles.row, styles.emailBody)}>
            <div
              className={styles.bodyContent}
              dangerouslySetInnerHTML={{ __html: adjustedBody }}
            />
          </div>
          {tableDataSource && templateData.attachmentType == 'EMBEDDED' && (
            <div className={styles.table}>
              <TablePreview {...tableDataSource} />
            </div>
          )}
          {tableDataSource && templateData.attachmentType == 'ATTACHED' && (
            <div className={styles.row}>
              <a href="#" onClick={() => downloadDataSource(tableDataSource)}>
                {i18nService.translate(
                  'events-template.create-message.preview-message.modal.download-data-source',
                  languageId,
                  { dataSourceName: tableDataSource.name }
                )}
              </a>
            </div>
          )}
          {dashboardReport && dashboardReport.refId && (
            <div className={styles.row}>
              <a href="#">
                {i18nService.translate(
                  'events-template.create-message.preview-message.modal.download-data-source',
                  languageId,
                  { dataSourceName: dashboardReport.name }
                )}
              </a>
            </div>
          )}
          <div className={styles.preview}>
            {displayLogo?.content?.logoUrl && emailFooter?.content?.displayLogo && (
              <LogoIcon logo={displayLogo?.content?.logoUrl} width={125} height={25} />
            )}
            <div dangerouslySetInnerHTML={{ __html: emailFooter?.content?.email }} />
          </div>
        </div>
        <div className={styles.modalButtons}>
          <Button mode="bigFont" onClick={cancel}>
            <I18n>general.close</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PreviewEmailMessageModal;
