import React from 'react';
import EmailMessageEditor from './EmailMessageEditor/EmailMessageEditor';
import styles from './Message.scss';
import SMSMessageEditor from './SMSMessageEditor/SMSMessageEditor';
import PushMessageEditor from './PushMessageEditor/PushMessageEditor';

function Message(props) {
  const { templateData } = props;
  const { action } = templateData;

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        {action === 'EMAIL_NOTIFICATION' && <EmailMessageEditor {...props} />}
        {action === 'SMS_NOTIFICATION' && <SMSMessageEditor {...props} />}
        {action === 'PUSH_NOTIFICATION' && <PushMessageEditor {...props} />}
      </div>
    </div>
  );
}

export default Message;
