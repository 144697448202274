import React, { useEffect, useState, useMemo } from 'react';

// Components
import Table from '@components/Table';
import TextField from '@components/Table/TableFields/TextField';
import DateTimeField from '@components/Table/TableFields/DateTimeField';
import RouterAssetDisconnectBTN from './RouterAssetDisconnectBTN';
import AssetsStatus from '@pages/DeviceManagment/Assets/AssetsTab/AssetsTable/AssetsStatus';

// Services | Interfaces
import { RouterTabsProps } from '@modals/EditRouterModal/EditRouterModal.interface';
import { httpService } from '@core/http/HttpService';
import { AssetDataResponse } from '@pages/DeviceManagment/Assets/AssetsTab/AssetsTable/assetsTable.interface';
import styles from './RouterAssetsTable.scss';

// 3rd party
import { compact } from 'lodash';

const getColunmsCfg = (refreshRouter, refreshRouterAssets) =>
  compact([
    {
      Component: RouterAssetDisconnectBTN,
      type: 'staticStart',
      label: '',
      style: { width: 35 },
      props: {
        /**
         * Refreshing the header and assets tab table
         */
        onDataChanged: () => {
          refreshRouter();
          refreshRouterAssets();
        },
      },
    },
    {
      Component: TextField,
      label: 'assets-tab.owner',
      field: 'owner',
      style: { width: '17%' },
      isSortable: true,
    },
    {
      Component: AssetsStatus,
      label: 'assets-tab.status',
      field: 'status',
      style: { width: '17%' },
      isSortable: true,
    },
    {
      Component: DateTimeField,
      label: 'assets-tab.last-update',
      field: 'updatedAt',
      style: { width: '17%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-serial-number',
      field: 'assetSerial',
      style: { width: '17%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.name',
      field: 'assetName',
      style: { width: '17%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-type',
      field: 'assetType',
      style: { width: '16%' },
      isSortable: true,
    },
  ]);

function RouterAssetsTable(props: RouterTabsProps) {
  const { id: routerId, onDataChanged, owner, ownerId } = props;
  const [routerAssets, setRouterAssets] = useState([]);
  const defaultOrdering = { label: 'updatedAt', orderSign: '-' };
  const [nextPage, setNextPage] = useState(1);
  const pageSize = useMemo(() => 50, []);
  const [disabledLoad, setDisableLoad] = useState(false);

  useEffect(() => getRouterAssets(true), [owner]);

  const getRouterAssets = (firstInit = false, order = defaultOrdering) => {
    httpService
      .api({
        type: 'getRouterAssets',
        urlParams: { routerId, organizationId: ownerId || undefined },
        query: {
          p: firstInit ? 1 : nextPage,
          ps: pageSize,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      })
      .then((res: AssetDataResponse) => {
        setDisableLoad(!res.next);
        if (firstInit) {
          setRouterAssets(res.results);
          setNextPage(2);
        } else {
          setRouterAssets([...routerAssets, ...res.results]);
          setNextPage(nextPage + 1);
        }
      });
  };

  const colunmsCfg = useMemo(() => {
    return getColunmsCfg(onDataChanged, getRouterAssets);
  }, [onDataChanged]);

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={true}
        data={routerAssets}
        colunmsCfg={colunmsCfg}
        className={styles.tableBody}
        getData={getRouterAssets}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default RouterAssetsTable;
