import React from 'react';
import styles from './TextWithTitle.scss';
import { i18nService } from '@core/i18n/I18nService';

function TextWithTitle(props: { data: Array<{ title: string; text: string }> }) {
  return (
    <>
      <div className={styles.wrapper}>
        {props.data.map((item, idx) => {
          return (
            <div key={idx} className={styles.row}>
              {`${i18nService.translate(item.title)}:`}
              <div className={styles.text}>{item.text}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default TextWithTitle;
