import React from 'react';
import styles from './RemoteAccessSection.scss';
import { getArrayFromObjectWithOrder } from '@core/utils';
import Button from '@components/Button';
import I18n from '@components/I18n';
import {
  getIsDisabled,
  getRemoteAccessUrl,
  handleVncLegacy,
  handleVncUnistream,
  handleWeb,
} from './RemoteAccessSection.utils';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { useSelector } from '@src/redux/useSelector';
import { remoteAccessButtonsLabels } from '@pages/CreateWidgetPage/DataStepComponent/StepFour/CostumizationComponents/Combo/Combo.utils';
import { i18nService } from '@core/i18n/I18nService';
import { getFlagStatus } from '@core/ffAndPermissions';
import { openRemoteHMIorVPNpopup } from '@components/widgets/RemoteAccessWidget/RemoteAccessWidget.utils';

function RemoteAccessSection(props) {
  const { assetDetails = {}, customization, isPreview } = props;
  const { assetId, plcType, macchinaConnected } = assetDetails;
  const {
    remoteAccessButtons = {
      vpn: { order: 0, show: true, displayName: remoteAccessButtonsLabels.vpn },
      web: { order: 0, show: true, displayName: remoteAccessButtonsLabels.web },
      vnc: { order: 0, show: true, displayName: remoteAccessButtonsLabels.hmi },
    },
  } = customization;

  const remoteAccessButtonsArray = getArrayFromObjectWithOrder(remoteAccessButtons, 'type');

  const currentLayout = useSelector((state) => state.dashboardEditor.currentLayout);
  const clientType = useSelector((state) => state.clientState.clientType);
  const isMobileApp = ['uniCloudApp', 'iOSUniCloudApp'].includes(clientType);

  const canRemoteAccess = getFlagStatus('live-dashboard.remote-access-widgets');

  async function onClick(button) {
    let vpnUrl = null;
    let vncUrl = null;
    let webUrl = null;

    if (!isPreview) {
      switch (button.type) {
        case 'vpn':
          vpnUrl = await getRemoteAccessUrl(assetId, 'vpn');
          vpnUrl && openRemoteHMIorVPNpopup(vpnUrl, 'vpn');
          break;
        case 'vnc':
          switch (plcType) {
            case 'UNISTREAM':
              vpnUrl = await getRemoteAccessUrl(assetId, 'vpn');
              vncUrl = await getRemoteAccessUrl(assetId, 'vnc');
              handleVncUnistream(clientType, vpnUrl, vncUrl);
              break;
            case 'LEGACY':
              vpnUrl = await getRemoteAccessUrl(assetId, 'vpn');
              handleVncLegacy(clientType, isDesktop, vpnUrl);
              break;
          }
          break;
        case 'web':
          webUrl = await getRemoteAccessUrl(assetId, 'web');
          plcType === 'UNISTREAM' && handleWeb(clientType, webUrl);
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      {remoteAccessButtonsArray
        .filter((button: any) => button.show)
        .map((btn, idx) => (
          <Button
            key={idx}
            disabled={
              !canRemoteAccess ||
              getIsDisabled(btn, plcType, macchinaConnected, currentLayout, {
                isMobileApp,
                isDesktop,
                isMobile,
                isTablet,
              })
            }
            onClick={canRemoteAccess && !isPreview ? () => onClick(btn) : null}
            mode="outlined"
            styles={{ minWidth: '28%', width: '28%' }}
            title={i18nService.translate(btn['displayName'])}>
            <I18n style={{ pointerEvents: 'none' }} title={btn['displayName']}>
              {btn['displayName']}
            </I18n>
          </Button>
        ))}
    </div>
  );
}

export default RemoteAccessSection;
