import React from 'react';
import { makeStyles } from '@material-ui/core';
import { classNames } from 'react-select/src/utils';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '64px',
    height: '64px',
  },
}));

function ImageThumbnail(props) {
  const { image } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          height: '100%',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          backgroundImage: `url(${image})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      />
    </div>
  );
}

export default ImageThumbnail;
