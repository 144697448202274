import axios from 'axios';
import { template, merge } from 'lodash';
import { apiMap } from './apiMap';
import { HttpRequestCfg, CancellablePromise, Permission } from './http.interface';
import { LoginResponse } from '@auth/auth.interface';
import { authService } from '@auth/AuthService';
import { getState, dispatch } from '@redux/store';
import { incrementBusyCounter, decrementBusyCounter } from '@redux/config';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { setErrorHandling } from '@src/redux/login';
import { sessionService } from '@core/session/SessionService';

class HttpService {
  /**
   * Creates an HTTP request. Has automatic error-handling and busy-indicator-handling.
   * Returns a modified promise with a cancel function.
   * You can cancel the request by calling the cancel function.
   */
  api<T>(cfg: HttpRequestCfg): CancellablePromise<T> {
    let cancelFunction;

    const promise = new Promise((resolve, reject) => {
      if (apiMap[cfg.type].mock) {
        resolve(apiMap[cfg.type].mock);
        return;
      }

      if (apiMap[cfg.type].hasPermission) {
        const permissions = Array.isArray(apiMap[cfg.type].hasPermission)
          ? apiMap[cfg.type].hasPermission  as Permission[]
          : [apiMap[cfg.type].hasPermission as Permission];
      
        const hasPermission = permissions.some(permission =>
          getPermissionStatus(permission.key, permission.type)
        );
      
        if (!hasPermission) {
          console.error(
            `${cfg.type} - You have no permission to perform ${permissions.map(p => `${p.type} - ${p.key}`).join(' or ')}`
          );
          reject();
          return;
        }
      }

      if (!cfg.disableBI) {
        dispatch(incrementBusyCounter());
      }
      const authHeader =
        cfg.noAuthHeader || apiMap[cfg.type].noAuthHeader ? {} : this.getAuthHeader();

      const formDataHeader = { 'X-Requested-With': 'XMLHttpRequest' };
      if (cfg.isFormData) {
        formDataHeader['Content-Type'] = 'multipart/form-data';
        if (cfg.fileName) {
          formDataHeader['Content-Disposition'] = `attachment; filename=${unescape(
            encodeURIComponent(cfg.fileName)
          )}`;
        }
      }

      const useUserOrganizationId = cfg.urlParams?.useUserOrganizationId;

      axios({
        url: this.getUrl(cfg, useUserOrganizationId),
        responseType: cfg.responseType,
        data: cfg.isFormData ? this.buildFormData(cfg.data) : cfg.data,
        params: this.getQuery(cfg),
        paramsSerializer: cfg.paramsSerializer,
        method: apiMap[cfg.type].method,
        headers: Object.assign(authHeader, apiMap[cfg.type].headers, cfg.headers, formDataHeader),
        cancelToken: new axios.CancelToken((c) => {
          cancelFunction = c;
        }),
      }).then(
        (res) => {
          if (!cfg.disableBI) {
            dispatch(decrementBusyCounter());
          }
          resolve(cfg.returnAllRes ? res : res.data);
        },
        (err) => {
          console.log({ err });
          if (err?.request?.status === 416) {
            window.location.reload();
            authService.logout();
          } else this.errorHandler(cfg, err, resolve, reject);
        }
      );
    }) as CancellablePromise<T>;

    promise.cancel = cancelFunction;

    return promise;
  }

  buildFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  }

  getQuery(cfg: HttpRequestCfg) {
    const state = getState();
    const userOrganizationId = state.login && state.login.userOrganizationId;
    const otherOrganizationId = sessionService.getValue('otherOrganizationID', null);
    const query = cfg.query || {};

    if (otherOrganizationId) return userOrganizationId ? { ...query } : query;
    else
      return userOrganizationId
        ? { ...query, tenant_id: apiMap[cfg.type].isTenantIdRequired ? userOrganizationId : null }
        : query;
  }

  getAuthHeader(token?: string) {
    const headerToken = token || getState().login.token;
    return headerToken ? { Authorization: `JWT ${headerToken}` } : {};
  }

  private getUrl(cfg: HttpRequestCfg, useUserOrganizationId: boolean) {
    const url = apiMap[cfg.type].url;
    const state = getState();
    const userOrganizationId = state.login && state.login.userOrganizationId;
    const selectedOrganizationsId = state.organizations.selectedOrganizationsId;
    const otherOrganizationId = sessionService.getValue('otherOrganizationID', null);
    const organizationId = otherOrganizationId
      ? otherOrganizationId
      : useUserOrganizationId
      ? userOrganizationId
      : selectedOrganizationsId
      ? selectedOrganizationsId
      : userOrganizationId;

    const urlParams = merge({ organizationId }, cfg.urlParams);
    return template(url)(urlParams);
  }

  private errorHandler(cfg, err, resolve, reject) {
    if (!cfg.disableBI) {
      dispatch(decrementBusyCounter());
    }
    if (err.response && err.response.status === 401 && this.isTokenExpired(err)) {
      this.refreshToken().then(
        (newCredentials) => {
          const login = getState().login;
          const loginCredentials = { ...login, ...newCredentials };
          authService.setLoggedIn(loginCredentials);
          this.api(cfg).then(resolve, reject);
        },
        (refreshTokenErr) => {
          authService.logout();
          reject(refreshTokenErr);
        }
      );
    } else if (err.response && err.response.status === 403) {
      if (cfg?.type == 'refreshToken') authService.logout();
      else dispatch(setErrorHandling({ status: 403, type: 'roles-update' }));
    } else {
      if (cfg.disableErrorHandling) {
        return reject(err.response);
      }

      // TODO: open error modal.
      reject(err.response);
    }
  }

  private isTokenExpired(err) {
    return err.response.data === 'Signature has expired.';
  }

  private refreshToken(): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      const login = getState().login;
      const refresh_token = login.refreshToken;
      const token = login.token;
      if (!refresh_token) reject();

      this.api({
        type: 'refreshToken',
        data: {
          refresh_token,
          token,
        },
        headers: {
          Authorization: undefined,
        },
      }).then(resolve, reject);
    });
  }
}

export const httpService = new HttpService();
