import React, { useEffect, useState } from 'react';
import { useSelector } from '@redux/useSelector';
import styles from './AugmentedRealityMeta.scss';
import classNames from 'classnames';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import EditARProject from './EditARProject';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { getSubscriptions } from '@modals/EditAssetModal/EditAssetModal.utils';

function AugmentedRealityMeta(props) {
  const editAssetGeneral = useSelector((state) => state.editAsset.general);
  const { assetTypeId, ar } = editAssetGeneral as any;
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [arProjectOptions, setArProjectOptions] = useState([]);
  const [message, setMessage] = useState(null);
  const [showArProjectSelector, setShowArProjectSelector] = useState(false);
  const arSubscriptionStatus = useSelector((state) => state.config.arSubscriptionStatus);
  const hasPermission = getPermissionStatus('DEVICE', 'EDIT');
  const [subscriptionsData, setSubscriptionsData] = useState(null);

  function getHasValidAssetSubscription() {
    return subscriptionsData?.subscriptions.some(
      (sub) =>
        ['Basic', 'Intermediate', 'Advanced', 'Enterprise'].includes(sub.licence) &&
        !['EXPIRED', 'PENDING'].includes(sub.status) &&
        subscriptionsData?.currentLicenceId === sub.id
    );
  }

  function getMessageText(hasValidAssetSubscription: boolean, isProjectExist: boolean) {
    switch (arSubscriptionStatus) {
      case 'NONE':
        setMessage('edit-asset-modal.augmented-reality.no-projects');
        setShowArProjectSelector(false);
        break;
      case 'TRIAL':
        if (!isProjectExist) {
          setMessage('edit-asset-modal.augmented-reality.no-projects');
          setShowArProjectSelector(false);
        } else {
          setMessage(null);
          setShowArProjectSelector(true);
        }
        break;
      case 'ACTIVE':
        if (hasValidAssetSubscription) {
          if (!isProjectExist) {
            setMessage('edit-asset-modal.augmented-reality.no-projects');
            setShowArProjectSelector(false);
          } else {
            setMessage(null);
            setShowArProjectSelector(true);
          }
        } else {
          setMessage('edit-asset-modal.augmented-reality.not-supported-by-asset-subscription');
          setShowArProjectSelector(false);
        }
        break;
      case 'EXPIRED':
        if (hasValidAssetSubscription) {
          setMessage('edit-asset-modal.augmented-reality.trial-expired');
          setShowArProjectSelector(false);
        } else {
          setMessage(
            'edit-asset-modal.augmented-reality.trial-expired-not-supported-by-asset-subscription'
          );
          setShowArProjectSelector(false);
        }
        break;
      default:
        setMessage(null);
        setShowArProjectSelector(true);
        break;
    }
  }

  useEffect(() => {
    ar && setSelectedProject(ar);
    editAssetGeneral?.assetId && getSubscriptions(+editAssetGeneral?.assetId, setSubscriptionsData);
  }, []);

  useEffect(() => {
    if (subscriptionsData) {
      const hasActiveAssetSubscription = getHasValidAssetSubscription();
      let arProjects = [];

      if (
        arSubscriptionStatus === 'TRIAL' ||
        (arSubscriptionStatus === 'ACTIVE' && hasActiveAssetSubscription)
      ) {
        if (hasPermission) {
          httpService
            .api({
              type: 'getARProjectByAssetType',
              urlParams: { assetTypeId: assetTypeId },
            })
            .then((res: any) => {
              if (res?.length) {
                setArProjectOptions([
                  { name: i18nService.translate('general.none'), id: null },
                  ...res,
                ]);
                arProjects = res;
              }
              getMessageText(hasActiveAssetSubscription, !!arProjects.length);
            });
        } else {
          if (ar) {
            setSelectedProject(ar);
          } else {
            getMessageText(hasActiveAssetSubscription, false);
          }
        }
      } else {
        getMessageText(hasActiveAssetSubscription, !!arProjects.length);
      }
    }
  }, [subscriptionsData]);

  function cancel() {
    editAssetService.dismiss(false);
  }

  function onEdit() {
    setIsEditMode(true);
  }

  function onEditDone() {
    setIsEditMode(false);
  }

  return (
    <div className="max-height">
      <div className={styles.content}>
        <div>
          <div className={styles.container}>
            {message && (
              <I18n element={'div'} className={classNames(styles.empty, 'ellipsis-overflow')}>
                {message}
              </I18n>
            )}
            {showArProjectSelector && (arProjectOptions?.length || ar) ? (
              <>
                <div className={styles.header}>
                  <I18n>edit-asset-modal.augmented-reality.augmented-reality-project</I18n>:
                </div>
                {isEditMode ? (
                  <EditARProject
                    setTimerEditMode={setIsEditMode}
                    onCancelEdit={onEditDone}
                    {...props}
                    currentProject={ar}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    options={arProjectOptions}
                  />
                ) : (
                  <>
                    <span className={styles.dataValue}>
                      {selectedProject?.name || i18nService.translate('general.none')}
                    </span>
                    {hasPermission && (
                      <Icon
                        type="edit"
                        color="var(--systemFont)"
                        className={styles.editIcon}
                        onClick={onEdit}
                      />
                    )}
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </div>
  );
}

export default AugmentedRealityMeta;
