import React from 'react';
import HeaderColumn from './HeaderColumn';

import styles from './TableHeader.scss';

const HeaderRow = ({ orderedBy, columnLayout, onSort, classes, setFilterData, filterData }) => {
  return (
    <>
      {columnLayout.staticStart.map((col, colIndex) => (
        <HeaderColumn
          key={colIndex}
          classes={classes}
          col={col}
          colIndex={colIndex}
          onSort={onSort}
          orderedBy={orderedBy}
          setFilterData={setFilterData}
          filterData={filterData}
        />
      ))}
      <div
        className={styles.responsiveContainer}
        style={{ width: `calc(100% - ${columnLayout.fixedWidth}px)` }}>
        {columnLayout.responsive.map((col, colIndex) => (
          <HeaderColumn
            key={colIndex}
            classes={classes}
            col={col}
            colIndex={colIndex}
            onSort={onSort}
            orderedBy={orderedBy}
            setFilterData={setFilterData}
            filterData={filterData}
          />
        ))}
      </div>
      {columnLayout.staticEnd.map((col, colIndex) => (
        <HeaderColumn
          key={colIndex}
          classes={classes}
          col={col}
          colIndex={colIndex}
          onSort={onSort}
          orderedBy={orderedBy}
          setFilterData={setFilterData}
          filterData={filterData}
        />
      ))}
    </>
  );
};

export default HeaderRow;
