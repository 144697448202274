import I18n from '@components/I18n';
import Icon from '@components/Icon';
import classNames from 'classnames';
import React from 'react';
import styles from './EditCopyDeleteButton.scss';

function EditCopyDeleteButton(props) {
  const { buttonType, onClick, type, templateId, id, templateData, isReadOnly } = props;
  const text = `events-template.data.source.${buttonType}`;
  const isDisabled = type !== 'VALUE' && buttonType === 'copy';

  return isReadOnly ? (
    <></>
  ) : (
    <div
      style={{ display: 'flex' }}
      onClick={(e) => onClick(templateId, id, type, templateData)}
      className={classNames('pointer', isDisabled && styles.disabledButton)}>
      <Icon
        className={styles.iconBtn}
        type={`${buttonType}_dataSource`}
        color="var(--systemFont)"
      />
      {text && <I18n className={styles.iconText}>{text}</I18n>}
    </div>
  );
}

export default EditCopyDeleteButton;
