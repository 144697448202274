import React from 'react';

// Components
import Table from '@components/Table';
import TextField from '@components/Table/TableFields/TextField';
import LicensingPlaneFrequencyField from './LicensingPlaneFrequencyField';
import LicensingPlaneTagsField from './LicensingPlaneTagsField';

// Services | Interfaces
import { LicensingPlan } from '@src/redux/redux.interface';

const colunmsCfg = [
  {
    Component: TextField,
    field: 'planName',
    style: { width: '20%' },
  },
  {
    Component: LicensingPlaneTagsField,
    label: 'licensing-plan-table.tags',
    field: 'numTags',
    style: { width: '20%' },
  },
  {
    Component: LicensingPlaneFrequencyField,
    label: 'licensing-plan-table.update-frequency',
    field: 'updateFrequencySeconds',
    style: { width: '20%' },
  },
  {
    Component: LicensingPlaneTagsField,
    label: 'licensing-plan-table.total-tags-per-month',
    field: 'totalTagsPerMonth',
    style: { width: '20%' },
  },
];

function AssetSubscriptionLicensingPlanesTable(props: { planes: LicensingPlan[] }) {
  const { planes } = props;

  return <Table hasPagination={false} data={planes} colunmsCfg={colunmsCfg} />;
}

export default AssetSubscriptionLicensingPlanesTable;
