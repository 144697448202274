import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import { changeWidgetTable, columnsCfg } from '../Heatmap.utils';
import { i18nService } from '@core/i18n/I18nService';
import HeatmapCustomizationRow from './HeatmapCustomizationRow';

function TagSettings({ widgetData, classes, columns, setWidgetData }) {
  return (
    <div className={classes.section}>
      <div className={classNames(classes.titleWrapper, classes.columnTitle, 'ellipsis-overflow')}>
        <I18n>create-widget-page.create-widget.step-four.tags-settings</I18n>
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.tableHeader}>
          {columnsCfg.map((header, idx) => (
            <div
              title={header.headerLabel && i18nService.translate(header.headerLabel)}
              key={`columns_${idx}`}
              className={classes.header}
              style={header.style}>
              {header.headerLabel && (
                <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
              )}
            </div>
          ))}
        </div>
        {columns
          ?.filter((column) => column.valueType !== 'ASSET_PROPERTY' && column.valueId !== 7)
          ?.map((col, idx) => (
            <HeatmapCustomizationRow
              key={`columns_${idx}`}
              columnCfg={columnsCfg}
              column={col}
              columnChanged={(column) => changeWidgetTable(column, idx, 'columns', setWidgetData)}
              widgetData={widgetData}
              setWidgetData={setWidgetData}
            />
          ))}
      </div>
    </div>
  );
}

export default TagSettings;
