import React, { useState, useCallback, useEffect } from 'react';
import { usePrevious } from '@core/hooks/usePrevious';
import I18n from '@components/I18n';
import MultiSelect from '@components/MultiSelect';
import { useIsMounted } from '@core/hooks/useIsMounted';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { httpService } from '@core/http/HttpService';
import { AssetTagsResponse } from '../StepOne.interface';
import { tagFormatMap } from '@core/mapsAndDefinitions';
import styles from '../StepOne.scss';
import { i18nService } from '@core/i18n/I18nService';
import SingleSelectWithChip from '@components/SingleSelectWithChip';
import classNames from 'classnames';
import { AssetTypeModel } from '../StepOne.interface';

const AssetTagsInput = ({
  assetTypes,
  tags,
  variables,
  onSelectedTags,
  widgetType,
  isMaxDataTags,
  eventTemplateId,
  allAssetTypes,
  additionalStyles = null,
  minified = false,
  isDisabled = false,
  isMulti = true,
  filteredOptionFormats = [],
}) => {
  const isMounted = useIsMounted();
  const previousAssetTypes: AssetTypeModel[] = usePrevious(assetTypes);
  const [assetTypeDataTagOptions, setAssetTypeDataTagOptions] = useState([]);

  const getAssetTags = useCallback((assetId, widgetType) => {
    httpService
      .api({
        type: 'getAssetTags',
        urlParams: { assetId },
        query: { p: 1, ps: 1000, widgetType: widgetType },
        disableBI: true,
      })
      .then((tags: AssetTagsResponse) => {
        if (isMounted.current) setAssetTypeDataTagOptions(tags.results);
      });
  }, []);

  function getTableConfig(option) {
    if (minified) {
      return {
        [i18nService.translate('create-widget-page.create-widget.step-one.format')]: option.format,
        [i18nService.translate('create-widget-page.create-widget.step-one.name')]: option.name,
        [i18nService.translate('create-widget-page.create-widget.step-one.tag-type')]: option.type,
      };
    }
    return {
      [i18nService.translate('create-widget-page.create-widget.step-one.format')]: option.format,
      [i18nService.translate('create-widget-page.create-widget.step-one.name')]: option.name,
      [i18nService.translate('create-widget-page.create-widget.step-one.alias')]: option.alias,
      [i18nService.translate('create-widget-page.create-widget.step-one.tag-type')]: option.type,
      [i18nService.translate('create-widget-page.create-widget.step-one.description')]:
        option.description,
    };
  }

  useEffect(() => {
    const currentAssetTypeId = assetTypes.length === 1 ? assetTypes[0].id : null;
    const previousAssetTypeId =
      previousAssetTypes && previousAssetTypes.length === 1 ? previousAssetTypes[0].id : null;

    if (currentAssetTypeId && currentAssetTypeId !== previousAssetTypeId) {
      getAssetTags(currentAssetTypeId, widgetType);
    } else if (!currentAssetTypeId) {
      setAssetTypeDataTagOptions([]);
    }
  }, [assetTypes, previousAssetTypes, getAssetTags, widgetType]);

  return (
    <div className={classNames(styles.dataField, additionalStyles?.dataField)}>
      <div className={styles.fieldLabel}>
        <I18n>create-widget-page.create-widget.step-one.tag-data</I18n>
        {tags.length > 0 && ` (${tags.length})`}
        <div className={classNames(styles.fieldSubLabel, additionalStyles?.fieldSubLabel)}>
          <I18n>
            {!eventTemplateId
              ? 'create-widget-page.create-widget.step-one.tag-data-sub'
              : 'create-event-data-source-page.create-widget.step-one.tag-data-sub'}
          </I18n>
        </div>
      </div>
      <div className={classNames(styles.inputField, additionalStyles?.inputField)}>
        {!isMulti ? (
          <SingleSelectWithChip
            id={'assetTags'}
            values={tags}
            isOptionDisabled={(option) => {
              return (
                filteredOptionFormats.length > 0 &&
                !filteredOptionFormats.includes(tagFormatMap[option.format])
              );
            }}
            isDisabled={
              (allAssetTypes
                ? widgetType !== 'alarms'
                : assetTypes?.length
                ? getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'TagData')
                : true) || isDisabled
            }
            options={assetTypeDataTagOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedTags}
            tableConfig={(option) => getTableConfig(option)}
            optionType="tagDataOptions"
          />
        ) : widgetType === 'switch' ? (
          <SingleSelectWithChip
            id={'assetTags'}
            isOptionDisabled={(option) =>
              (tagFormatMap[option.format] &&
                getConfigValueFromWidgetSettings(widgetType, 'assetIsNotDisabled') &&
                !getIsIncludeInSettings(
                  widgetType,
                  'assetIsNotDisabled',
                  tagFormatMap[option.format]
                )) ||
              option.access === 'RO'
            }
            isDisabled={
              (allAssetTypes
                ? widgetType !== 'alarms'
                : assetTypes?.length
                ? getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'TagData')
                : true) || isDisabled
            }
            values={tags}
            options={assetTypeDataTagOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedTags}
            tableConfig={(option) => getTableConfig(option)}
            optionType="tagDataOptions"
          />
        ) : (
          <MultiSelect
            id="assetTags"
            isOptionDisabled={(option) =>
              (tagFormatMap[option.format] &&
                getConfigValueFromWidgetSettings(widgetType, 'assetIsNotDisabled') &&
                !getIsIncludeInSettings(
                  widgetType,
                  'assetIsNotDisabled',
                  tagFormatMap[option.format]
                )) ||
              (isMaxDataTags && !tags.find((tag) => option.id === tag.id)) ||
              (getConfigValueFromWidgetSettings(widgetType, 'acceptOnlyWritableTags') &&
                option.access === 'RO')
            }
            closeMenuOnSelect={widgetType === 'map'}
            isDisabled={
              (allAssetTypes
                ? widgetType !== 'alarms'
                : assetTypes?.length
                ? getIsIncludeInSettings(widgetType, 'stepOneDisabled', 'TagData')
                : true) || isDisabled
            }
            values={tags}
            options={assetTypeDataTagOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedTags}
            tableConfig={(option) => getTableConfig(option)}
            optionType="tagDataOptions"
          />
        )}
      </div>
    </div>
  );
};

export default AssetTagsInput;
