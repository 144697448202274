import React from 'react';
import { remoteAccessWidgets, widgetMap } from '@core/canvas/widgetMap';
import { CanvasWidgetProps } from './CanvasWidget.interface';
import Image from '@components/Image';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { getDisplayWidget, getCustomization } from './CanvasWidget.utils';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { useSelector } from '@src/redux/useSelector';

const getLineStyle = (props) => {
  const { isDraft, widget = {} } = props;
  const { hideWidgetName } = widget;

  let lineStyles = 'solid';
  if (hideWidgetName) lineStyles = 'dashed';

  if (props?.isLive && hideWidgetName) return '';

  return `${lineStyles} 1px var(--${isDraft ? 'systemDraftBackground' : 'widgetsBorderHeader'})`;
};

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    height: ({ widget, showExportIcon, isLive }: any) =>
      (widget?.hideWidgetName && !showExportIcon) ||
      (widget?.hideWidgetName && showExportIcon && !isLive)
        ? '100%'
        : `calc(100% - ${theme.app.widgetActionBarHeight}px)`,
    width: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'var(--widgetsBackground)',
  },
  hideFrame: {
    borderRadius: 'inherit',
  },
  smallerWidget: {
    padding: 20,
    height: '100%',
    width: '100%',
  },
  regularWidget: {
    height: '100%',
    width: '100%',
  },
  withFrame: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderRight: (props: any) => getLineStyle(props),
    borderBottom: (props: any) => getLineStyle(props),
    borderLeft: (props: any) => getLineStyle(props),
    borderTopLeftRadius: ({ widget }: any) => (widget?.hideWidgetName ? 5 : 0),
    borderTopRightRadius: ({ widget }: any) => (widget?.hideWidgetName ? 5 : 0),
    borderTop: (props: any) => getLineStyle(props),
  },
}));

function CanvasWidget(props: CanvasWidgetProps) {
  const {
    widgetType,
    widget,
    data,
    setData,
    widgetFilters,
    navigateDashboard,
    getMoreWidgetData,
    dashboardFilters,
    userFilters,
    isDraft,
    applyButtonClicked,
    setApplyButtonClicked,
    timezone,
    isPointerEventsDisabled,
  } = props;

  const defaultDecimalDigits = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
  );
  const emptyStateImageUrls = useSelector((state) => state.config.emptyStateImageUrls);
  const selectionBox = useSelector((state) => state.dashboardEditor.selectionBox);

  const widgetItem = widgetMap[widgetType];
  const enableExportCsv =
    widget?.type && getConfigValueFromWidgetSettings(widget.type, 'enableExportCsv');
  const showExportIcon =
    widget?.exportFormats &&
    widget?.exportFormats.length !== 0 &&
    enableExportCsv &&
    enableExportCsv(widget);

  const classes = useStyles({ ...props, showExportIcon });

  if (!widgetItem) {
    console.error(`[CanvasWidgetWrapper] Unrecognizable widget of type: ${widgetType}`);
    return null;
  }

  const Widget = widgetItem.component;

  return Widget ? (
    <div className={classNames(classes.wrapper, classes.withFrame)}>
      {!isDraft && getDisplayWidget(data, widget) ? (
        <div
          className={!widgetItem.fullSize ? classes.smallerWidget : classes.regularWidget}
          style={
            isPointerEventsDisabled ? { pointerEvents: 'none', userSelect: 'none' } : undefined
          }>
          <Widget
            getMoreWidgetData={getMoreWidgetData}
            userFilters={userFilters}
            widget={widget || {}}
            navigateDashboard={navigateDashboard}
            dashboardFilters={dashboardFilters}
            widgetFilters={widgetFilters}
            emptyImage={
              remoteAccessWidgets.includes(widgetItem.name)
                ? `${emptyStateImageUrls[widgetItem.name]}`
                : widgetItem.images.canvas
            }
            data={data || {}}
            setData={setData}
            customization={getCustomization(widget)}
            applyButtonClicked={applyButtonClicked}
            setApplyButtonClicked={setApplyButtonClicked}
            defaultDecimalDigits={
              widget.type === 'map' || widget.type === 'update_asset'
                ? defaultDecimalDigits
                : undefined
            }
            timezone={timezone}
          />
        </div>
      ) : (
        <Image
          mode="smaller"
          src={
            remoteAccessWidgets.includes(widgetItem.name)
              ? `${emptyStateImageUrls[widgetItem.name]}`
              : widgetItem.images.canvas
          }
          text={`widgets.${isDraft ? 'draft-' : ''}empty-state-text`}
        />
      )}
    </div>
  ) : null;
}

export default CanvasWidget;
