import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

import { UpdateAssetCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import {
  buildOptions,
  getDefaultUpdateAssetCustomization,
  getMergedTagsConfigurationChanges,
} from './UpdateAsset.utils';
import MaterialCheckbox from '@components/Checkbox';
import styles from '@components/FormikField/FormikField.scss';
import { cloneDeep, compact, concat } from 'lodash';
import classNames from 'classnames';
import I18n from '@components/I18n';
import SwitchSection from './SwitchSection/SwitchSection';
import TextualRemarkSection from './TextualRemarkSecion/TextualRemarkSection';
import RequestSentTextSection from './RequestSentTextSecion/RequestSentTextSection';
import UpdateButtonSection from './UpdateButtonSection/UpdateButtonSection';
import TableTagsConfiguration from './TableTagsConfiguration/TableTagsConfiguration';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 10,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 0px',
  },
  text: {
    fontSize: '14px',
    width: '137px',
  },
  cbLabel: {
    marginLeft: 6,
    fontSize: 14,
  },
}));

const dataTypeIconMap = {
  INT32: 'numberType',
  UINT32: 'numberType',
  FLOAT32: 'floatType',
  BOOLEAN: 'booleanType',
  STRING: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
  INT8: 'numberType',
  UINT8: 'numberType',
  INT16: 'numberType',
  UINT16: 'numberType',
  GEO: 'geoType',
};

const UpdateAsset = (props) => {
  const classes = useStyles(props);
  const { widgetData, setWidgetData } = props;
  const { customization } = widgetData;
  const {
    switchCustomization,
    textualRemark,
    requestSentText,
    updateButtonCustomization,
    tagsConfiguration,
  } = customization || {};
  const hasBooleanTags =
    widgetData?.tags?.some((t) => t.format === 'BOOLEAN') ||
    widgetData?.tagTypes?.some((t) => t.type === 'BOOLEAN');

  const horizontalAlignmentOptions = useMemo(
    () =>
      compact([
        { label: 'enum.CENTER', value: 'CENTER' },
        { label: 'enum.RIGHT', value: 'RIGHT' },
        { label: 'enum.LEFT', value: 'LEFT' },
      ]),
    []
  );

  const verticalAlignmentOptions = useMemo(
    () =>
      compact([
        { label: 'enum.TOP', value: 'TOP' },
        { label: 'enum.BOTTOM', value: 'BOTTOM' },
      ]),
    []
  );

  useEffect(() => {
    const { tags, tagTypes, metrics } = widgetData || {};
    const data = compact(
      concat(
        tags.map((a) => buildOptions(a, 'TAG')),
        tagTypes.map((a) => buildOptions(a, 'TAG_TYPE'))
      )
    );

    if (!customization)
      updateWidgetCustomization(getDefaultUpdateAssetCustomization(data, metrics), setWidgetData);
    else
      setCustomizationData(
        getMergedTagsConfigurationChanges(customization.tagsConfiguration, data),
        'tagsConfiguration'
      );

    setScopeAndMetrics(data);
  }, []);

  const setCustomizationData = (data, key, section = null) => {
    const values = cloneDeep(customization);
    if (section) values[section][key] = data;
    else values[key] = data;

    updateWidgetCustomization(values, setWidgetData);
  };

  const setScopeAndMetrics = (tags) => {
    let index = 0;
    setWidgetData((prevState) => ({
      ...prevState,
      scope: 'LAST_VALUE',
      metrics: tags.map((t) => ({
        order: index++,
        valueType: t.tagType,
        type: dataTypeIconMap[t.type],
        valueId: t.id,
        name: t.name,
        operation: null,
      })),
    }));
  };

  return (
    <div className={classes.wrapper}>
      <TableTagsConfiguration
        widgetData={widgetData}
        setCustomizationData={setCustomizationData}
        tagsConfiguration={tagsConfiguration}
        section="tagsConfiguration"
      />
      <div className={classes.item}>
        <MaterialCheckbox
          className={styles.checkBoxIcon}
          color="primary"
          onChange={(e) => setCustomizationData(e.target.checked, 'doNotRefreshWhileEditing')}
          checked={customization?.doNotRefreshWhileEditing}
        />
        <I18n
          noEllipsis={true}
          className={classes.cbLabel}
          title={
            'create-widget-page.create-widget.step-four.update-asset.do-not-refresh-on-edit.tooltip'
          }>
          create-widget-page.create-widget.step-four.update-asset.do-not-refresh-on-edit
        </I18n>
      </div>
      {hasBooleanTags && (
        <SwitchSection
          setCustomizationData={setCustomizationData}
          {...switchCustomization}
          section="switchCustomization"
        />
      )}
      <TextualRemarkSection
        setCustomizationData={setCustomizationData}
        {...textualRemark}
        section="textualRemark"
        horizontalAlignmentOptions={horizontalAlignmentOptions}
        verticalAlignmentOptions={verticalAlignmentOptions}
      />
      <UpdateButtonSection
        {...updateButtonCustomization}
        setCustomizationData={setCustomizationData}
        section="updateButtonCustomization"
        horizontalAlignmentOptions={horizontalAlignmentOptions}
      />
      <RequestSentTextSection
        setCustomizationData={setCustomizationData}
        {...requestSentText}
        section="requestSentText"
      />
    </div>
  );
};

export default UpdateAsset;
