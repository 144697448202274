import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Select from '@components/Select';
import I18n from '@components/I18n';
import moment from 'moment';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  wrapper: { display: 'flex', flexDirection: 'column', paddingBottom: 34 },
  row: { display: 'flex', marginTop: 8 },
  separator: { width: 101, marginRight: 8 },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--systemFont)',
  },
  format: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 12,
    textAlign: 'center',
    color: 'var(--systemFont)',
    maxWidth: 790,
  },
  error: {
    color: 'var(--formikErrorText)',
  },
}));

const selectCustomStyle = { control: { height: 36 }, container: { width: 187, marginRight: 8 } };
const selectSmallCustomStyle = {
  control: { height: 36 },
  container: { width: 101, marginRight: 8 },
};

const formatsMap = { dd: 'DD', d: 'D', yyyy: 'YYYY', yy: 'YY' };

const defaultDates = {
  shortDate: { DAY: 'dd', MONTH: 'MM', YEAR: 'yy' },
  date: { DAY: 'dd', MONTH: 'MM', YEAR: 'yyyy' },
  longDate: { DAY: 'dddd', MONTH: 'MM', YEAR: 'yyyy' },
};

const getSaperator = (sap) => {
  switch (sap) {
    case 'space':
      return ' ';
    case ',':
      return ', ';
    case 'none':
      return '';
    default:
      return sap;
  }
};
const buildFormat = (data) =>
  data.fields.reduce(
    (obj, current, idx) => ({
      format: `${obj.format}${current.type === 'NONE' ? '' : current.value}${
        getSaperator(data.separators[idx]) || ''
      }`,
      momentFormat: `${obj.momentFormat}${
        current.type === 'NONE' ? '' : formatsMap[current.value] || current.value
      }${getSaperator(data.separators[idx]) || ''}`,
    }),
    { format: '', momentFormat: '' }
  );

const DateRow = (props) => {
  const classes = useStyles(props);
  const { onChangeData, keyName } = props;
  const { fields, separators, momentFormat } = props.data;
  const [fieldsData, setFieldsData] = useState({ fields, separators });
  const [showError, setShowError] = useState(false);
  const [isChangedFromUI, setIsChangedFromUI] = useState(false);

  const typeOptions = useMemo(
    () => [
      { value: 'DAY', label: 'white-labeling.date-time.day' },
      { value: 'MONTH', label: 'white-labeling.date-time.month' },
      { value: 'YEAR', label: 'white-labeling.date-time.year' },
      { value: 'NONE', label: 'white-labeling.date-time.none' },
    ],
    []
  );

  const options = useMemo(
    () => ({
      DAY: [
        { value: 'dddd', label: 'dddd' },
        { value: 'ddd', label: 'ddd' },
        { value: 'dd', label: 'dd' },
        { value: 'd', label: 'd' },
      ],
      MONTH: [
        { value: 'MMMM', label: 'MMMM' },
        { value: 'MMM', label: 'MMM' },
        { value: 'MM', label: 'MM' },
        { value: 'M', label: 'M' },
      ],
      YEAR: [
        { value: 'yyyy', label: 'yyyy' },
        { value: 'yy', label: 'yy' },
      ],
      NONE: [],
    }),
    []
  );

  const separatorsOptions = useMemo(
    () => [
      { value: '/', label: '/' },
      { value: '-', label: '-' },
      { value: '.', label: '.' },
      { value: 'space', label: 'white-labeling.date-time.space' },
      { value: ',', label: ',' },
      { value: 'none', label: 'white-labeling.date-time.none' },
    ],
    []
  );

  useEffect(() => {
    if (!isChangedFromUI) {
      setFieldsData({ fields, separators });
    } else setIsChangedFromUI(false);
  }, [fields, separators]);

  const onChangeType = (value, index) => {
    if (fieldsData.fields[index].type !== value) {
      const isExists = value !== 'NONE' && fieldsData.fields.find((field) => field.type === value);
      const tempFields = [...fieldsData.fields];
      tempFields[index] = { type: value, value: defaultDates[keyName][value] };
      const tempData = { ...fieldsData, fields: tempFields };
      const { format, momentFormat } = buildFormat(tempData);
      if (isExists) {
        setShowError(true);
      } else {
        showError && setShowError(false);
        onChangeData('dateTime', keyName, { ...tempData, format, momentFormat });
      }
      setIsChangedFromUI(true);
      setFieldsData(tempData);
    }
  };

  const updateData = (newData) => {
    const { format, momentFormat } = buildFormat(newData);
    onChangeData('dateTime', keyName, { ...newData, format, momentFormat });
    setIsChangedFromUI(true);
    setFieldsData(newData);
  };

  const onChangeformat = (value, index) => {
    const tempFields = [...fieldsData.fields];
    tempFields[index] = { ...tempFields[index], value };
    updateData({ ...fieldsData, fields: tempFields });
  };

  const onChangeSaperator = (value, index) => {
    const tempSeparators = [...fieldsData.separators];
    tempSeparators[index] = value;
    updateData({ ...fieldsData, separators: tempSeparators });
  };

  return (
    <div className={classes.wrapper}>
      <I18n className={classes.title}>{`white-labeling.date-time.${keyName}`}</I18n>
      <div className={classes.row}>
        <Select
          styles={selectCustomStyle}
          options={typeOptions}
          value={typeOptions.find((opt) => opt.value === fieldsData.fields[0].type)}
          onChange={(option) => onChangeType(option.value, 0)}
        />
        <div className={classes.separator} />
        <Select
          styles={selectCustomStyle}
          options={typeOptions}
          value={typeOptions.find((opt) => opt.value === fieldsData.fields[1].type)}
          onChange={(option) => onChangeType(option.value, 1)}
        />
        <div className={classes.separator} />
        <Select
          styles={selectCustomStyle}
          options={typeOptions}
          value={typeOptions.find((opt) => opt.value === fieldsData.fields[2].type)}
          onChange={(option) => onChangeType(option.value, 2)}
        />
      </div>
      <div className={classes.row}>
        <Select
          styles={selectCustomStyle}
          options={options[fieldsData.fields[0].type]}
          value={options[fieldsData.fields[0].type].find(
            (opt) => opt.value === fieldsData.fields[0].value
          )}
          onChange={(option) => onChangeformat(option.value, 0)}
          disabled={fieldsData.fields[0].type === 'NONE'}
        />
        <Select
          styles={selectSmallCustomStyle}
          options={separatorsOptions}
          value={separatorsOptions.find((opt) => opt.value === fieldsData.separators[0])}
          onChange={(option) => onChangeSaperator(option.value, 0)}
        />
        <Select
          styles={selectCustomStyle}
          options={options[fieldsData.fields[1].type]}
          value={options[fieldsData.fields[1].type].find(
            (opt) => opt.value === fieldsData.fields[1].value
          )}
          onChange={(option) => onChangeformat(option.value, 1)}
          disabled={fieldsData.fields[1].type === 'NONE'}
        />
        <Select
          styles={selectSmallCustomStyle}
          options={separatorsOptions}
          value={separatorsOptions.find((opt) => opt.value === fieldsData.separators[1])}
          onChange={(option) => onChangeSaperator(option.value, 1)}
        />
        <Select
          styles={selectCustomStyle}
          options={options[fieldsData.fields[2].type]}
          value={options[fieldsData.fields[2].type].find(
            (opt) => opt.value === fieldsData.fields[2].value
          )}
          onChange={(option) => onChangeformat(option.value, 2)}
          disabled={fieldsData.fields[2].type === 'NONE'}
        />
      </div>
      {!showError && <div className={classes.format}>{moment().format(momentFormat)}</div>}
      {showError && (
        <I18n className={classNames(classes.format, classes.error)}>
          white-labeling.date-time.invalid-date-error
        </I18n>
      )}
    </div>
  );
};

export default DateRow;
