import React from 'react';
import classNames from 'classnames';
import styles from './SingleNotification.scss';
import { SingleNotificationProps } from './SingleNotification.interface';
import { buildDateTime } from '@components/widgets/charts.utils';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { dispatch } from '@src/redux/store';
import { setUnreadNotifications } from '@src/redux/config';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  bg: {
    background: 'hsl(from var(--systemButtonBackground) h s l / 0.1)',
    '&:hover': { background: 'var(--highlightedTableRow)' },
  },
});

function SingleNotification(props: SingleNotificationProps) {
  const {
    id,
    timestamp,
    type,
    name,
    summary,
    read,
    notifications,
    setNotifications,
    unreadNotifications,
    notificationDetailsRef,
    showUnreadOnly,
  } = props;
  const classes = useStyles({ ...props });
  const datetime = buildDateTime(timestamp, 'DATE_TIME_FORMAT_ONE', 'momentFormat');

  async function openNotification() {
    let notificationDetails = {};
    notificationDetails = await httpService.api({
      type: 'getNotificationDetails',
      urlParams: { notificationId: id },
    });

    if (!read) {
      httpService
        .api({
          type: 'markNotificationRead',
          urlParams: { notificationId: id },
        })
        .then(() => {
          setNotifications({
            ...notifications,
            results: showUnreadOnly
              ? notifications.results.filter((n) => n.id !== id)
              : notifications.results.map((n) => {
                  if (n.id === id) {
                    return { ...n, read: true };
                  }
                  return n;
                }),
          });
          dispatch(setUnreadNotifications(unreadNotifications - 1));
        });
    }

    modalService.openModal('notificationDetailsModal', {
      notificationDetails,
      notificationDetailsRef,
    });
  }

  return (
    <div className={classNames(styles.wrapper, classes.bg)} onClick={openNotification}>
      <div className={styles.topRow}>
        <div className={classNames(styles.templateName, 'ellipsis-overflow')} title={name}>
          {name}
        </div>
        <div className={styles.rightPart}>
          <div className={styles.datetime} title={datetime}>
            {datetime}
          </div>
          <div className={styles.readMarkWrapper}>
            {!read && <div className={styles.readMark}></div>}
          </div>
        </div>
      </div>
      <div className={styles.bottomRow} title={summary}>
        <div className={classNames(styles.summary, 'ellipsis-overflow')}>{summary}</div>
      </div>
    </div>
  );
}

export default SingleNotification;
