import React, { useEffect, useCallback, useState } from 'react';
import { connect, getIn } from 'formik';
import InputBase from '@material-ui/core/InputBase';
import styles from './TextInput.scss';
import { TextInputProps } from './textInput.interface';
import { handleChangeInput, handleBlurInput } from './TextInput.utils';
import NumberFormat from 'react-number-format';
import { useSelector } from '@src/redux/useSelector';

function TextInput(props: TextInputProps) {
  const { formik, name, disabled, maxLength, value, mode = '', onLostFocus } = props;
  const numberFormatting = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content
  );
  const [inputValue, setInputValue] = useState(value);
  const error = getIn(formik.errors, name);

  const handleChange = useCallback(
    (e) => {
      handleChangeInput(e, maxLength, setInputValue, formik, name);
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    handleBlurInput(formik, onLostFocus, error, setInputValue, name);
  }, [formik]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return mode === 'number' ? (
    <NumberFormat
      thousandSeparator={numberFormatting.thousandsSeparator}
      decimalSeparator={numberFormatting.decimalSeparator}
      id={styles.formikInlineInput}
      value={inputValue}
      disabled={disabled}
      inputProps={{
        step: 'any',
      }}
      fullWidth
      autoComplete="off"
      onChange={handleChange}
      onBlur={handleBlur}
      customInput={InputBase}></NumberFormat>
  ) : (
    <InputBase
      id={styles.formikInlineInput}
      value={inputValue}
      disabled={disabled}
      type={mode}
      inputProps={{
        step: 'any',
      }}
      fullWidth
      autoComplete="off"
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export default connect(TextInput);
