import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { useSelector } from '@redux/useSelector';
import { colorMap } from '@core/statusColorMap';
import styles from './TemplatesDetailsModal.scss';
import moment from 'moment-timezone';
import DataSection from '@components/DataSection';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { Template } from './TemplatesDetailsModal.interface';
import PublishingModal from './PublishingModal';
import { serverToLocal } from '@pages/EventsManagementPage/EditTemplate/EditTemplate.utils';
import { dateTimezoneFormat } from '@core/utils';
import { getState } from '@src/redux/store';

let isCompleted = false;

function TemplatesDetailsModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { rowData, refresh, onCopyTemplate, copiedTemplate, onEditTemplate },
  } = props;
  const roles = useSelector((state) => state.config.roles);
  const isUnassigned = useSelector((state) => state.config.isUnAssigned);
  const canEdit =
    roles.includes('Machine Builder Admin') || roles.includes('Machine Builder Events Manager');

  const [templateData, setTemplateData] = useState<Template | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const orgTimezone = useSelector((state) => state.config && state.config.timezone);
  const selectOrgTimezone = useSelector(
    (state) => state.organizations && state.organizations.timezone
  );
  const languageId = useSelector((state) => state.config.languageId);
  const isTestAllowed =
    ['EMAIL_NOTIFICATION', 'PUSH_NOTIFICATION'].includes(templateData?.action) || !isUnassigned;

  useEffect(() => {
    if (copiedTemplate) {
      const dataSources = copiedTemplate.dataSources || [];
      setTemplateData(serverToLocal(copiedTemplate, dataSources));
    } else getTemplate();
  }, []);

  const getTemplate = async () => {
    try {
      const res: Template = await httpService.api({
        type: 'getEventTemplateData',
        urlParams: { templateId: rowData?.id },
      });
      if (res) {
        const dataSources = res.dataSources || [];
        setTemplateData(serverToLocal(res, dataSources));
      }
    } catch (e) {}
  };

  const generalSectionLeftContainer = useMemo(
    () => [
      { label: 'templates-delails-modal.name', value: templateData?.name },
      {
        label: 'templates-delails-modal.create-date',
        value: dateTimezoneFormat(templateData?.createdDate, selectOrgTimezone, orgTimezone),
      },
    ],
    [templateData]
  );

  const generalSectionRightContainer = useMemo(
    () => [
      {
        label: 'templates-delails-modal.action',
        value: i18nService.translate(`enum.${templateData?.action || ''}`, languageId),
      },
      {
        label: 'templates-delails-modal.expiry-date',
        value: dateTimezoneFormat(templateData?.endDate, selectOrgTimezone, orgTimezone),
      },
    ],
    [templateData]
  );

  const triggerSectionLeftContainer = useMemo(() => {
    const tempSection = [];

    tempSection.push({
      label: 'templates-delails-modal.execution-start-date',
      value: dateTimezoneFormat(templateData?.startDate, selectOrgTimezone, orgTimezone),
    });

    if (templateData?.trigger === 'SCHEDULER') {
      tempSection.push({
        label: 'templates-delails-modal.recurrence',
        value: templateData?.timeFrame
          ? i18nService.translate(`enum.${templateData?.timeFrame || ''}`, languageId)
          : '',
      });

      if (templateData?.timeFrame === 'WEEKLY') {
        tempSection.push({
          label: 'templates-delails-modal.weeks',
          value: templateData?.daysOfWeek
            .map((item) => {
              return i18nService.translate(`enum.${item || ''}`, languageId);
            })
            .join(', '),
        });
      } else if (templateData?.timeFrame === 'MONTHLY') {
        tempSection.push({
          label: 'templates-delails-modal.months',
          value: templateData?.dayOfMonth,
        });
      } else if (templateData?.timeFrame === 'YEARLY') {
        tempSection.push({
          label: 'templates-delails-modal.years',
          value: `${templateData?.dayOfYear} ${moment(templateData?.monthOfYear, 'M').format(
            'MMMM'
          )}`,
        });
      }
    }

    return tempSection;
  }, [templateData]);

  const triggerSectionRightContainer = useMemo(() => {
    const tempSection = [];

    if (templateData?.trigger === 'SCHEDULER')
      tempSection.push({
        label: 'templates-delails-modal.execution-time',
        value: templateData?.time ? moment(templateData?.time, ['HH.mm']).format('HH:mm') : '',
      });

    tempSection.push({
      label: 'templates-delails-modal.execution-end-date',
      value: dateTimezoneFormat(templateData?.endDate, selectOrgTimezone, orgTimezone),
    });

    return tempSection;
  }, [templateData]);

  const testTemplate = async () => {
    await modalService.openModal('testEventModal', {
      templateData,
      headerText: 'templates-delails-modal.end-template',
    });
  };

  const editTemplate = async () => {
    onEditTemplate(templateData);
    cancel();
  };

  const deleteTemplate = async () => {
    const confirm = await modalService.openModal('confirm', {
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      headerText: 'templates-delails-modal.delete-popup.delete-template',
      text: 'templates-delails-modal.delete-popup.proceed-delete-template',
    });
    if (confirm) {
      httpService
        .api({
          type: 'deleteEventTemplate',
          urlParams: { templateId: templateData?.id },
        })
        .then((res: any) => {
          if (res.status === 'ENDED') {
            modalService.openModal('alert', {
              text: 'templates-delails-modal.delete-popup.template-ended',
              btnText: 'general.confirm',
              showCloseBtn: true,
              headerText: 'templates-delails-modal.delete-popup.delete-operation-failed',
            });
            getTemplate();
          } else {
            cancel();
            refresh();
          }
        });
    }
  };

  const copyTemplate = () => {
    onCopyTemplate(templateData?.id);
    cancel();
  };

  const endTemplate = async () => {
    const confirmedDate = await modalService.openModal('choseDateModal', {
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      headerText: 'templates-delails-modal.end-template',
    });
    httpService
      .api({
        type: 'endEventTemplateData',
        urlParams: { templateId: rowData.id },
        data: {
          endDate: confirmedDate,
        },
      })
      .then((res: any) => {
        dismiss(false);
        refresh();
      });
  };

  const onPreviewMessage = useCallback(async () => {
    if (templateData?.body) {
      const whiteLabelDetails = getState().config?.whiteLabelDetails;

      const tableDataSourceMeta = templateData?.dataSources.find(
        (d) => (d.type === 'TABLE' || d.type === 'ALARMS') && d.status === 2
      );

      let tableDataSource;
      if (tableDataSourceMeta) {
        tableDataSource = await httpService.api({
          type: 'getEventTemplateDataSource',
          urlParams: { templateId: templateData?.id, dataSourceId: tableDataSourceMeta.id },
        });
      }

      let previewMessageModalType = '';

      switch (templateData?.action) {
        case 'EMAIL_NOTIFICATION':
          previewMessageModalType = 'previewEmailMessageModal';
          break;
        case 'SMS_NOTIFICATION':
          previewMessageModalType = 'previewSmsMessageModal';
          break;
        case 'PUSH_NOTIFICATION':
          previewMessageModalType = 'previewPushMessageModal';
          break;
        default:
          previewMessageModalType = 'previewEmailMessageModal';
          break;
      }

      await modalService.openModal(previewMessageModalType, {
        templateData,
        displaySettingsData: whiteLabelDetails,
        tableDataSource,
      });
    }
  }, [templateData]);

  const archiveTemplate = async () => {
    try {
      const confirm = await modalService.openModal('confirm', {
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
        headerText: 'templates-delails-modal.archive-template',
        text: 'templates-delails-modal.archive-template-proceed',
        templateName: templateData?.name,
      });
      if (confirm) {
        await httpService.api({
          type: 'archiveEventTemplateData',
          urlParams: { templateId: rowData.id },
        });
        setTemplateData((prev) => ({ ...prev, status: 'ARCHIVED' }));
        refresh();
      }
    } catch (e) {}
  };

  const activateTemplate = async () => {
    try {
      const confirm = await modalService.openModal('confirm', {
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
        headerText: 'templates-delails-modal.activate-template',
        text: 'templates-delails-modal.activate-template-proceed',
        templateName: templateData?.name,
      });
      if (confirm) {
        await httpService.api({
          type: 'activateEventTemplateData',
          urlParams: { templateId: rowData.id },
        });
        setTemplateData((prev) => ({ ...prev, status: 'DRAFT' }));
        refresh();
      }
    } catch (e) {}
  };

  const publishTemplate = async () => {
    const confirm = await modalService.openModal('confirm', {
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      headerText: 'templates-delails-modal.publish-popup.publish',
      templateName: templateData?.name,
      text: 'templates-delails-modal.publish-popup.event-will-be-publishe',
    });
    if (confirm) {
      startPublish();
    }
  };

  const getPublishStatus = async (jobId) => {
    const job = await getJobStatus(jobId);
    switch (job.status) {
      case 'IN_PROGRESS':
        return checkJobStatus(jobId);

      case 'COMPLETED_SUCCESSFULLY':
        return ifCompleted();

      case 'FAILED':
        return ifFailed(null);
    }
  };

  const checkJobStatus = (jobId) => {
    const interval = setInterval(async () => {
      try {
        const job = await getJobStatus(jobId);
        switch (job.status) {
          case 'IN_PROGRESS':
            return;

          case 'COMPLETED_SUCCESSFULLY':
            isCompleted = true;
            clearInterval(interval);
            return ifCompleted();

          case 'FAILED':
            clearInterval(interval);
            return ifFailed(null);
        }
      } catch (e) {
        // assuming that reading the job status takes more than 2 seconds then the timer will be called again.
        // the request before the last one will return completed status,
        // while the last one will return a 400 error that the job is not active (or something similar)
        // We want to prevent the timer from keep ticking, while preveting the error message showing when not needed

        clearInterval(interval);
        if (!isCompleted) return ifFailed(e);
      }
    }, 2000);
  };

  const ifCompleted = async () => {
    setShowAlert(false);
    modalService.openModal('alert', {
      text: 'templates-delails-modal.publish-popup.publish-operation-succeeded',
      btnText: 'general.confirm',
      showCloseBtn: true,
      headerText: 'templates-delails-modal.publish-popup.publishing-event',
    });
    getTemplate();
    refresh();
  };

  const ifFailed = async (e) => {
    setShowAlert(false);
    if (e && e.status === 400 && e?.data?.message?.code === 35) {
      modalService.openModal('alert', {
        headerText: 'templates-delails-modal.publish-popup.event-template-publish-failed',
        text: 'templates-delails-modal.publish-popup.max-events-count-reached-error',
        btnText: 'general.confirm',
        showCloseBtn: true,
      });
    } else {
      const retry = await modalService.openModal('confirm', {
        confirmText: 'templates-delails-modal.publish-popup.retry',
        cancelText: 'general.cancel',
        showCloseBtn: true,
        headerText: 'templates-delails-modal.publish-popup.publishing-event',
        text: 'templates-delails-modal.publish-popup.publish-operation-failed',
      });
      if (retry) {
        startPublish();
      }
    }
  };

  const startPublish = async () => {
    try {
      setShowAlert(true);
      const res: { jobId: string } = await httpService.api({
        type: 'publishEventTemplate',
        urlParams: { templateId: templateData?.id },
        disableBI: true,
      });
      if (res) {
        getPublishStatus(res.jobId);
      }
    } catch (e) {
      ifFailed(e);
    }
  };

  const getJobStatus = async (jobId) => {
    try {
      const job: { status: string } = await httpService.api({
        type: 'getPublishEventTemplateStatus',
        urlParams: { jobId },
        disableBI: true,
      });
      return job;
    } catch (e) {
      setShowAlert(false);
    }
  };

  const saspendTemplate = async () => {
    const confirm = await modalService.openModal('confirm', {
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      headerText: 'templates-delails-modal.publish-popup.suspend',
      templateName: templateData?.name,
      text: 'templates-delails-modal.publish-popup.will-be-suspended',
    });
    if (confirm) {
      try {
        await httpService.api({
          type: 'saspendEventTemplate',
          urlParams: { templateId: templateData?.id },
        });

        setTemplateData((prev) => ({ ...prev, status: 'SUSPENDED' }));
        refresh();
      } catch (e) {}
    }
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex' }}>
        <I18n className={styles.modalHeader} element="div" templateName={templateData?.name}>
          templates-delails-modal.title
        </I18n>
        <div style={{ display: 'flex' }} onClick={cancel}>
          <Icon className={styles.exitButton} type="close" />
        </div>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalToolbar}>
          <div className={styles.statusWarpper}>
            <div className={styles.status}>
              <I18n>edit-asset-modal.status</I18n>:
            </div>
            <div
              className="ellipsis-overflow"
              title={i18nService.translate(`enum.${templateData?.status || ''}`, languageId)}
              style={{
                color: colorMap[templateData?.status],
              }}>
              {i18nService.translate(`enum.${templateData?.status || ''}`, languageId)}
            </div>
          </div>

          <div className={styles.toolbarsIcon}>
            <div
              onClick={onPreviewMessage}
              className={templateData?.body ? styles.btnGroup : styles.disabledbutton}>
              <Icon className={styles.iconBtn} type="preview" color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.preview-message</I18n>
            </div>

            {templateData?.isFinish && templateData?.status === 'DRAFT' && canEdit && (
              <div onClick={publishTemplate} className={styles.btnGroup}>
                <Icon
                  className={styles.iconBtn}
                  type="template_publish"
                  color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>details.icons.publish</I18n>
              </div>
            )}

            <div
              onClick={editTemplate}
              className={
                (templateData?.status === 'SUSPENDED' || templateData?.status === 'DRAFT') &&
                canEdit
                  ? styles.btnGroup
                  : styles.disabledbutton
              }>
              <Icon
                className={styles.iconBtn}
                type="template_edit"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.edit</I18n>
            </div>

            <div
              onClick={deleteTemplate}
              className={
                templateData?.canBeDeleted && canEdit ? styles.btnGroup : styles.disabledbutton
              }>
              <Icon
                className={styles.iconBtn}
                type="template_delete"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.delete</I18n>
            </div>

            <div
              onClick={archiveTemplate}
              className={
                templateData?.status === 'ARCHIVED'
                  ? styles.disabledbutton
                  : canEdit
                  ? styles.btnGroup
                  : styles.disabledbutton
              }>
              <Icon
                className={styles.iconBtn}
                type="teplate_archive"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.archive</I18n>
            </div>

            <div
              onClick={testTemplate}
              className={
                isTestAllowed &&
                templateData?.isFinish &&
                templateData?.status === 'DRAFT' &&
                canEdit
                  ? styles.btnGroup
                  : styles.disabledbutton
              }>
              <Icon
                className={styles.iconBtn}
                type="template_test"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.test</I18n>
            </div>

            <div
              onClick={copyTemplate}
              className={canEdit ? styles.btnGroup : styles.disabledbutton}>
              <Icon
                className={styles.iconBtn}
                type="template_copy"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.copy</I18n>
            </div>

            <div
              onClick={saspendTemplate}
              className={
                ['SUSPENDED', 'DRAFT', 'EXPIRED', 'ARCHIVED'].includes(templateData?.status)
                  ? styles.disabledbutton
                  : canEdit
                  ? styles.btnGroup
                  : styles.disabledbutton
              }>
              <Icon
                className={styles.iconBtn}
                type="template_suspend"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.suspend</I18n>
            </div>

            <div
              onClick={activateTemplate}
              className={
                ['SUSPENDED', 'ARCHIVED'].includes(templateData?.status) && canEdit
                  ? styles.btnGroup
                  : styles.disabledbutton
              }>
              <Icon
                className={styles.iconBtn}
                type="template_start"
                color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.activate</I18n>
            </div>

            <div
              onClick={endTemplate}
              className={
                templateData?.status === 'PUBLISHED' && canEdit
                  ? styles.btnGroup
                  : styles.disabledbutton
              }>
              <Icon className={styles.iconBtn} type="template_end" color="var(--systemFont)"></Icon>
              <I18n className={styles.textBtn}>details.icons.end</I18n>
            </div>
          </div>
        </div>

        <div className={styles.templateDetailsContainer}>
          <div className={styles.dataContainer}>
            <I18n className={styles.dataSectionTitle}>templates-delails-modal.general</I18n>
            <DataSection
              leftContainer={generalSectionLeftContainer}
              rightContainer={generalSectionRightContainer}></DataSection>
          </div>

          <div className={styles.dataContainer}>
            <I18n className={styles.dataSectionTitle}>templates-delails-modal.scope</I18n>
            <div className={styles.dataSection}>
              {templateData?.isSpecificOrg
                ? `${i18nService.translate(
                    `enum.${templateData?.orgScope[0] || ''}`,
                    languageId
                  )} - ${templateData?.specificOrgName ? templateData?.specificOrgName : ''}`
                : templateData?.orgScope
                    ?.map((item) => {
                      return i18nService.translate(`enum.${item || ''}`, languageId);
                    })
                    .join(', ')}
            </div>
            <div className={styles.dataSeparator}></div>
          </div>

          <div className={styles.dataContainer}>
            <div style={{ display: 'flex' }}>
              <I18n className={styles.dataSectionTitle}>templates-delails-modal.trigger</I18n>
              {`:  ${i18nService.translate(`enum.${templateData?.trigger || ''}`, languageId)}`}
            </div>
            <DataSection
              leftContainer={triggerSectionLeftContainer}
              rightContainer={triggerSectionRightContainer}></DataSection>
          </div>

          <div className={styles.dataContainer}>
            <I18n className={styles.dataSectionTitle}>templates-delails-modal.audience</I18n>
            <div className={styles.audienceSection}>
              {templateData?.sendToAdminGroup && (
                <div>
                  <I18n>templates-delails-modal.Admins</I18n>
                  <I18n className={styles.allowUnselectAdminGroup} style={{ fontSize: 12 }}>
                    {templateData?.allowUnselectAdminGroup
                      ? 'templates-delails-modal.allowed-to-exclude'
                      : 'templates-delails-modal.not-allow-to-exclude'}
                  </I18n>
                </div>
              )}
              {templateData?.notificationGroups?.map((group, index) => (
                <div key={index}>{group.name}</div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.modalButtons}>
          <Button mode="bigFont" disabled={false} onClick={cancel}>
            <I18n>general.close</I18n>
          </Button>
        </div>
      </div>
      <PublishingModal
        publish={showAlert}
        headerText={'templates-delails-modal.publish-popup.publishing-event'}
        text={'templates-delails-modal.publish-popup.publishing-event'}></PublishingModal>
    </div>
  );
}

export default TemplatesDetailsModal;
