import React, { useState, useEffect, useMemo } from 'react';
import { GooglePlacesInputProps } from './GooglePlacesInput.interface';
import MultiSelect from '@components/MultiSelect';
import Select from '@components/Select';
import {
  addressChanged,
  setOptionsGoogleMaps,
  setCountryWithDefault,
  selectedCountryDetailsChange,
  onResetValues,
} from './GooglePlacesInput.utils';

import throttle from 'lodash/throttle';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

const autocompleteService = { current: null };

const getOptionLabel = (option) => option.description || option.formatted;
const getOptionValue = (option) => option.googleId || option.place_id;

function GooglePlacesInput(props: GooglePlacesInputProps) {
  const {
    id,
    onSelectedCountryChange,
    resetValues,
    placeholder,
    isDisabled = false,
    defaultValue,
    maxSelections,
    coordinates = false,
    mode,
    isMulti = true,
    styles = {},
    allowSelectingUnknownAddress = false,
  } = props;
  const defaultLocation = defaultValue ? (isMulti ? defaultValue : [defaultValue]) : [];
  const languageId = useSelector((state) => state.config.languageId);
  const [countryDetails, setCountryDetails] = useState(defaultLocation);
  const [inputValue, setInputValue] = useState(
    defaultValue && !isMulti ? defaultValue.formatted : ''
  );
  const [options, setOptions] = useState([]);
  const [showValue, setShowValue] = useState(true);
  let map;
  const handleChange = (newValue) => {
    setInputValue(newValue);
  };
  const unknownLocationText = useMemo(() => {
    return i18nService.translate('edit-asset-modal.location.unknown');
  }, [languageId]);

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        (autocompleteService.current as any).getPlacePredictions(input, callback);
      }, 200),
    []
  );

  useEffect(() => {
    const center = { lat: -33.866, lng: 151.196 };
    map = new google.maps.Map(document.getElementById('map'), {
      center,
      zoom: 17,
    });
  });

  useEffect(() => {
    onResetValues(resetValues, setCountryDetails);
  }, [resetValues]);

  useEffect(() => {
    selectedCountryDetailsChange(countryDetails, onSelectedCountryChange, isMulti);
  }, [countryDetails]);

  useEffect(() => {
    setCountryWithDefault(defaultValue, isMulti, countryDetails, setCountryDetails);
  }, [defaultValue]);

  useEffect(() => {
    setOptionsGoogleMaps(
      autocompleteService,
      inputValue,
      setOptions,
      fetch,
      unknownLocationText,
      allowSelectingUnknownAddress
    );
  }, [inputValue, fetch]);

  const updateInputValue = () => {
    !isMulti && countryDetails[0] && setInputValue(countryDetails[0].formatted);
    setShowValue(false);
  };

  return isMulti ? (
    <MultiSelect
      id={id}
      styles={{
        ...styles,
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={(newValues) =>
        addressChanged(newValues, countryDetails, coordinates, isMulti, setCountryDetails, map)
      }
      values={countryDetails}
      isDisabled={isDisabled}
      filterOption={(option) => true}
      onInputChanged={handleChange}
      optionType="checkbox"
      selectType="location"
      placeholder={placeholder}
      maxSelections={maxSelections}
      mode={mode}
    />
  ) : (
    <Select
      value={showValue && countryDetails}
      onBlur={() => {
        setShowValue(true);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onFocus={updateInputValue}
      onChange={(newValues) =>
        addressChanged(newValues, countryDetails, coordinates, isMulti, setCountryDetails, map)
      }
      blurInputOnSelect={true}
      filterOption={(option) => true}
      placeholder={placeholder || ''}
      onInputChanged={handleChange}
      inputValue={inputValue}
      styles={{
        indicatorsContainer: { display: 'none' },
        indicatorSeparator: { display: 'none' },
        ...styles,
      }}
    />
  );
}

export default GooglePlacesInput;
