import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TableHeaderProps } from '../table.interface';
import HeaderRow from './HeaderRow';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 24px 0px 16px',
    borderBottom: `1px solid var(--tableRowBorderBottom)`,
    backgroundColor: (props: any) =>
      (props.customization && props.customization.headerBackgroundColor) || 'inherit',
    position: 'sticky',
    top: 0,
  },
  headerCol: {
    padding: '16px 8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: (props: any) =>
      (props.customization && props.customization.headerFontColor) || `var(--systemFont)`,
  },
}));

function TableHeader(props: TableHeaderProps) {
  const { onSort, columnLayout, orderedBy, setFilterData, filterData, style = undefined } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.header} style={{ top: style?.top ? style?.top : 0 }}>
      <HeaderRow
        orderedBy={orderedBy}
        filterData={filterData}
        columnLayout={columnLayout}
        onSort={onSort}
        classes={classes}
        setFilterData={setFilterData}
      />
    </div>
  );
}
export default TableHeader;
