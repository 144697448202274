import React, { useCallback, useState } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';

// Services | Interfaces
import { i18nService } from '@core/i18n/I18nService';
import styles from './ChangeAssetOwnerModal.scss';

function ChangeAssetOwnerModal(props) {
  const {
    dismiss,
    args: { asset },
  } = props;

  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [text, setText] = useState(
    i18nService.translate('change-asset-owner-modal.msg', undefined, {
      assetName: asset.assetName,
    })
  );

  const getInitialButtons = () => {
    return [
      { text: 'general.cancel', onClick: cancel },
      {
        text: `change-asset-owner-modal.btn-move-asset`,
        onClick: onMoveAsset,
      },
      {
        text: 'change-asset-owner-modal.btn-move-router',
        onClick: onMoveRouter,
      },
    ];
  };

  const onMoveAsset = () => {
    setText(
      i18nService.translate('change-asset-owner-modal.move-asset-msg', undefined, {
        assetName: asset.assetName,
      })
    );
    setActionButtons([{ text: 'general.confirm', onClick: cancel }]);
  };

  const onMoveRouter = () => {
    if (asset.routerState === 'CONNECTED_SINGLE') {
      dismiss(true);
    } else if (asset.routerState === 'CONNECTED_MULTIPLE') {
      setText('change-asset-owner-modal.move-router-with-multiple-assets-msg');
      setActionButtons([
        { text: 'change-asset-owner-modal.btn-move-all', onClick: () => dismiss(true) },
        { text: 'general.cancel', onClick: cancel },
      ]);
    }
  };

  const [actionButtons, setActionButtons] = useState(getInitialButtons());

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>change-asset-owner-modal.header</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n element="div" className={styles.text} noEllipsis={true}>
          {text}
        </I18n>
      </div>
      <div className={styles.modalButtons}>
        {actionButtons.map((btn, idx) => (
          <Button
            key={idx}
            onClick={() => btn.onClick()}
            styles={{ marginLeft: 13 }}
            mode={btn.text === 'general.cancel' ? ['cancel', 'bigFont'] : 'bigFont'}>
            <I18n>{btn.text}</I18n>
          </Button>
        ))}
      </div>
    </div>
  );
}

export default ChangeAssetOwnerModal;
