import React from 'react';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import { i18nService } from '@core/i18n/I18nService';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DraggableTableRow from './DraggableTableRow';

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1px',
    fontSize: 12,
    fontWeight: 500,
    borderTop: 'solid 1px #c2cfe0',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 0px 10px 0px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: 15,
  },
  warning: {
    fontSize: 12,
    color: `var(--formikErrorText)`,
    fontWeight: 'bold',
    marginBottom: 8,
  },
}));

function DraggableTable(props) {
  const classes = useStyles(props);
  const { droppableId, columnCfg, data, setData, onRowChanged, mergeData } = props;

  function onDragEnd(result, droppableId) {
    const { source, destination } = result;
    if (!destination) return;
    const newIdx = destination.index;
    const prevIdx = source.index;

    if (newIdx === prevIdx) return;
    if (data[prevIdx]?.draggableId !== droppableId || data[newIdx]?.draggableId !== droppableId)
      return;
    const headerToMove = data[prevIdx];
    data.splice(prevIdx, 1);
    data.splice(newIdx, 0, headerToMove);
    const newData = data;
    return mergeData ? mergeData([...newData]) : setData([...newData]);
  }

  return (
    <>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, droppableId)}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div
              className={classes.tableContainer}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              <div className={classes.tableHeader}>
                {columnCfg.map((header, index) => (
                  <div
                    title={header.headerLabel && i18nService.translate(header.headerLabel)}
                    key={`${index}_${header.headerLabel}`}
                    className={classes.header}
                    style={header.style}>
                    {header.headerLabel && (
                      <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                    )}
                  </div>
                ))}
              </div>
              {data &&
                data.map((column, idx) => (
                  <Draggable
                    index={idx}
                    key={`${column?.id}_${idx}`}
                    draggableId={`${column?.id}_${idx}`}>
                    {(provided) => (
                      <div
                        key={idx}
                        className={classes.columnContainer}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <DraggableTableRow
                          columnCfg={columnCfg}
                          row={column}
                          index={idx}
                          columnsLength={data.length}
                          isLast={idx + 1 == data.length}
                          provided={provided}
                          onRowChanged={onRowChanged}
                          setData={setData}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default DraggableTable;
