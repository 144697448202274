import React, { useEffect, useMemo } from 'react';
import { TagMeta } from '@http/server.interface.d';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { httpService } from '@core/http/HttpService';
import { getPermissionStatus } from '@core/ffAndPermissions';
import Icon from '@components/Icon';
import { makeStyles } from '@material-ui/core';
import { i18nService } from '@core/i18n/I18nService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    width: 'fit-content',
  },
  export: {
    padding: '0px 10px',
    fontSize: '14px',
  },
}));

function LanguageExport(props) {
  const { onExportClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper} onClick={() => onExportClick(props)}>
      <Icon
        type="export"
        color="var(--systemFont)"
        tooltipText={i18nService.translate('languages.export-terms-for-translation')}
      />
      <I18n className={classes.export}>languages.export-terms-for-translation</I18n>
    </div>
  );
}

export default LanguageExport;
