import React, { useMemo } from 'react';

import styles from './EditThumbnail.scss';
import GenericThumbnail from '../GenericThumbnail';
import { EditThumbnailProps } from './EditThumbnail.interface';
import { dashboardService } from '@core/canvas/DashboardService';

function EditThumbnail(props: EditThumbnailProps) {
  const { publishedDashboard, draftDashboard } = props;

  const editModeCfg = useMemo(
    () => ({
      published: {
        edit: {
          disabled: true,
          onClick: () => dashboardService.dashboardCopyEdit(publishedDashboard.id, 'edit'),
        },
        copy: {
          disabled: false,
          onClick: () => dashboardService.dashboardCopyEdit(publishedDashboard.id, 'copy'),
        },
        remove: {
          disabled: false,
          onClick: () => dashboardService.removePublishedDashboard(publishedDashboard, true),
        },
        preview: {
          disabled: false,
          onClick: () => dashboardService.openDashboardPreview(publishedDashboard.id, 'gallery'),
        },
      },
      draft: {
        edit: {
          disabled: false,
          onClick: () => dashboardService.dashboardCopyEdit(draftDashboard.id, 'edit', true),
        },
        copy: {
          disabled: false,
          onClick: () => dashboardService.dashboardCopyEdit(draftDashboard.id, 'copy'),
        },
        remove: {
          disabled: false,
          onClick: () => dashboardService.removeDraftDashboard(draftDashboard, true),
        },
        preview: {
          disabled: true,
          onClick: () => dashboardService.openDashboardPreview(draftDashboard.id, 'gallery'),
        },
      },
    }),
    [publishedDashboard, draftDashboard]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.publishedThumbnail}>
        <GenericThumbnail dashboard={publishedDashboard} buttonsCfg={editModeCfg.published} />
      </div>
      <div className={styles.draftThumbnail}>
        <GenericThumbnail dashboard={draftDashboard} buttonsCfg={editModeCfg.draft} />
      </div>
    </div>
  );
}

export default EditThumbnail;
