import React, { useMemo } from 'react';
import I18n from '@components/I18n';

function DataSourceType(props) {
  const { type } = props;
  const text = useMemo(
    () => `events-template.create-data.source.type.${type?.toLowerCase()}`,
    [type]
  );

  return <I18n>{text}</I18n>;
}

export default DataSourceType;
