import React, { useEffect, useState, useMemo } from 'react';

import { useSelector } from '@redux/useSelector';
import { FiltersWidgetFilterProps } from './FiltersWidgetFilter.interface.d';
import styles from './FiltersWidgetFilter.scss';
import moment from 'moment';
import { i18nService } from '@core/i18n/I18nService';

function OrganizationFilter() {
  const organizations = useSelector((state) => state.dashboardEditor.organizationsFilter);
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <div title={organizations.map((o) => o.name).join(', ')} className={styles.DisabledFilters}>
      <span className="ellipsis-overflow">
        {organizations.length
          ? organizations.map((o) => o.name).join(', ')
          : i18nService.translate('filters-widget.all', languageId)}
      </span>
    </div>
  );
}

function AssetTypeFilter() {
  const assetTypes = useSelector((state) => state.dashboardEditor.assetTypesFilter);
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <div title={assetTypes.map((a) => a.name).join(', ')} className={styles.DisabledFilters}>
      <span className="ellipsis-overflow">
        {assetTypes.length
          ? assetTypes.map((a) => a.name).join(', ')
          : i18nService.translate('filters-widget.all', languageId)}
      </span>
    </div>
  );
}

function AssetFilter() {
  const assets = useSelector((state) => state.dashboardEditor.assetFilter);
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <div
      title={assets.map((a) => `${a.name} - ${a.id}`).join(', ')}
      className={styles.DisabledFilters}>
      <span className="ellipsis-overflow">
        {assets.length
          ? assets.map((a) => `${a.name} - ${a.id}`).join(', ')
          : i18nService.translate('filters-widget.all', languageId)}
      </span>
    </div>
  );
}

function GeoFilter() {
  const geography = useSelector((state) => state.dashboardEditor.geoFilter);
  const languageId = useSelector((state) => state.config.languageId);

  return (
    <div title={geography.map((g) => g.formatted).join(', ')} className={styles.DisabledFilters}>
      <span className="ellipsis-overflow">
        {geography.length
          ? geography.map((g) => g.formatted).join(', ')
          : i18nService.translate('filters-widget.all', languageId)}
      </span>
    </div>
  );
}

function DateFilter() {
  const dateFilter = useSelector((state) => state.dashboardEditor.dateFilter);
  const dateFilterFrom = useSelector((state) => state.dashboardEditor.dateFilterFrom);
  const dateFilterTo = useSelector((state) => state.dashboardEditor.dateFilterTo);
  const [date, setDate] = useState(null);
  const format = 'DD/MM/YYYY';
  const BEformat = 'YYYY-MM-DD';
  const languageId = useSelector((state) => state.config.languageId);
  const dateLabelMap = useMemo(
    () => ({
      DAY: i18nService.translate('date-picker.last-day'),
      WEEK: i18nService.translate('date-picker.last-week'),
      MONTH: i18nService.translate('date-picker.last-month'),
      YEAR: i18nService.translate('date-picker.last-year'),
    }),
    [languageId]
  );

  const timeUpdate = (type, dateDifference) => {
    setDate({
      dateFilter,
      dateFilterFrom: moment().subtract(dateDifference, type),
      dateFilterTo: moment(),
    });
  };

  const setDateRange = () => {
    switch (dateFilter) {
      case 'DAY':
        timeUpdate(1, 'days');
        break;
      case 'WEEK':
        timeUpdate(6, 'days');
        break;
      case 'MONTH':
        timeUpdate(1, 'months');
        break;
      case 'YEAR':
        timeUpdate(1, 'year');
        break;
      case 'CUSTOM':
        setDate({
          dateFilter,
          dateFilterFrom: moment(dateFilterFrom, BEformat),
          dateFilterTo: moment(dateFilterTo, BEformat),
        });
    }
  };

  useEffect(() => {
    setDateRange();
  }, [dateFilter]);

  return (
    <div
      title={
        date ? `${date.dateFilterFrom.format(format)} - ${date.dateFilterTo.format(format)}` : ''
      }
      className={styles.DisabledFilters}>
      <span className="ellipsis-overflow">
        {date
          ? date.dateFilter && date.dateFilter !== 'CUSTOM'
            ? `${dateLabelMap[date.dateFilter]}`
            : `${date.dateFilterFrom.format(format)} - ${date.dateFilterTo.format(format)}`
          : ''}
      </span>
    </div>
  );
}

function FilterInput(props: FiltersWidgetFilterProps) {
  const { filter } = props;

  switch (filter.filterType) {
    case 'ORGANIZATIONS':
      return <OrganizationFilter />;
    case 'ASSET_TYPES':
      return <AssetTypeFilter />;
    case 'GEO':
      return <GeoFilter />;
    case 'DATE':
      return <DateFilter />;
    case 'ASSET':
      return <AssetFilter />;
  }
}

export default React.memo(FilterInput);
