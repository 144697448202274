import React from 'react';
import Checkbox from '@components/Checkbox';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '4px 0',
    cursor: 'pointer',
  },
  filtersWrapper: {
    minWidth: 150,
    paddingTop: 11,
    paddingLeft: 12,
    paddingBottom: 15,
  },
  label: {
    marginLeft: 8,
    fontSize: 12,
  },
}));

const CheckboxOptionRow = (props) => {
  const { option, isChecked, setOption } = props;
  const classes = useStyles(props);
  return (
    <div
      key={option.value}
      className={classes.row}
      onClick={(e) => {
        setOption(e, option.value);
      }}>
      <Checkbox
        onChange={() => setOption(null, option.value)}
        color="primary"
        checked={isChecked}
      />
      <I18n className={classNames(classes.label, 'ellipsis-overflow')}>{option.label}</I18n>
    </div>
  );
};

export default CheckboxOptionRow;
