import React, { useMemo } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import I18n from '@components/I18n';
import classNames from 'classnames';
import { SwitchCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import Button from '@components/Button';
import ImageIcon from '../../Image/ImageIcon';
import SwitchImage from '../SwitchImage/SwitchImage';

const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  section: {
    margin: '10px 0',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
    '&.asterisk': {
      marginLeft: '-7px',
      width: '112px',
    },
  },
  fontSizeInput: {
    width: '68px',
    height: '40px',
  },
  itemNoIndent: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 0',
  },
  titleWrapperInlineContent: {
    width: '132px',
    fontWeight: 800,
    marginRight: '5px',
  },
}));

const SwitchButtonSection = (props) => {
  const classes = useStyles(props);
  const {
    setCustomizationData,
    customization,
    switchButtonTypeOptions,
    switchSizeOptions,
    openImageGallery,
  } = props;
  const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };
  const buttonTypeSselectCustomStyle = {
    control: { height: 40 },
    container: { width: 'calc(100% - 147px)' },
  };
  const {
    buttonType,
    switchSize,
    onText,
    offText,
    switchTextColor,
    switchTextDisabledColor,
    switchTextFontSize,
    onColor,
    offColor,
    disabledColor,
    switchColor,
    onImage,
    offImage,
  } = (customization as SwitchCustomization) || {};

  const existingImages = [onImage, offImage];

  const selectedButtonType = useMemo(
    () => buttonType && switchButtonTypeOptions.find((opt) => opt.value === buttonType),
    [buttonType]
  );

  return (
    <div className={classes.section}>
      <div className={classes.itemNoIndent}>
        <I18n noEllipsis={true} className={classes.titleWrapperInlineContent}>
          create-widget-page.create-widget.step-four.switch.button-type
        </I18n>
        <Select
          styles={buttonTypeSselectCustomStyle}
          options={switchButtonTypeOptions}
          value={buttonType && switchButtonTypeOptions.find((opt) => opt.value === buttonType)}
          onChange={(option) => setCustomizationData(option.value, 'buttonType')}
        />
      </div>

      {selectedButtonType &&
        (selectedButtonType.value === 'TOGGLE_BUTTON' || selectedButtonType.value === 'BUTTON') && (
          <React.Fragment>
            <div className={classes.item}>
              <I18n noEllipsis={true} className={classes.text}>
                {selectedButtonType.value === 'TOGGLE_BUTTON'
                  ? 'create-widget-page.create-widget.step-four.switch.switch-size'
                  : 'create-widget-page.create-widget.step-four.switch.button-size'}
              </I18n>
              <Select
                styles={selectCustomStyle}
                options={switchSizeOptions}
                value={switchSize && switchSizeOptions.find((opt) => opt.value === switchSize)}
                onChange={(option) => setCustomizationData(option.value, 'switchSize')}
              />
            </div>
            <div className={classes.item}>
              <I18n noEllipsis={true} className={classNames(classes.text, 'asterisk')}>
                create-widget-page.create-widget.step-four.switch.on-text
              </I18n>
              <TextField
                className={classes.textField}
                value={onText || ''}
                margin="dense"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  setCustomizationData(e.target.value, 'onText');
                }}
                inputProps={{
                  maxLength: 20,
                  style: { color: 'var(--systemFont)' },
                }}
              />
            </div>
            <div className={classes.item}>
              <I18n
                noEllipsis={true}
                className={classNames(
                  classes.text,
                  selectedButtonType.value === 'BUTTON' ? 'asterisk' : ''
                )}>
                create-widget-page.create-widget.step-four.switch.off-text
              </I18n>
              <TextField
                className={classes.textField}
                value={offText || ''}
                margin="dense"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  setCustomizationData(e.target.value ? e.target.value : null, 'offText');
                }}
                inputProps={{
                  maxLength: 20,
                  style: { color: 'var(--systemFont)' },
                }}
              />
            </div>
            <div className={classNames(classes.item, classes.colorItem)}>
              <I18n noEllipsis={true} className={classes.text}>
                create-widget-page.create-widget.step-four.text-color
              </I18n>
              <ColorInput
                value={switchTextColor}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                customeMarginButton={0}
                colorChange={(value) => setCustomizationData(value, 'switchTextColor')}
              />
            </div>
            <div className={classNames(classes.item, classes.colorItem)}>
              <I18n noEllipsis={true} className={classes.text}>
                create-widget-page.create-widget.step-four.text.disabled-color
              </I18n>
              <ColorInput
                value={switchTextDisabledColor}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                customeMarginButton={0}
                colorChange={(value) => setCustomizationData(value, 'switchTextDisabledColor')}
              />
            </div>
            {selectedButtonType && selectedButtonType.value === 'TOGGLE_BUTTON' && (
              <div className={classes.item}>
                <I18n noEllipsis={true} className={classes.text}>
                  create-widget-page.create-widget.step-four.size
                </I18n>
                <TextField
                  className={classes.textField}
                  value={switchTextFontSize || ''}
                  margin="dense"
                  type="number"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setCustomizationData(+e.target.value, 'switchTextFontSize');
                  }}
                  onBlur={(e) => {
                    setCustomizationData(
                      100 < +switchTextFontSize
                        ? 100
                        : 1 > +switchTextFontSize
                        ? 1
                        : +switchTextFontSize,
                      'switchTextFontSize'
                    );
                  }}
                  inputProps={{
                    min: 1,
                    max: 100,
                    style: { color: 'var(--systemFont)' },
                  }}
                />
              </div>
            )}
            <div className={classNames(classes.item, classes.colorItem)}>
              <I18n noEllipsis={true} className={classes.text}>
                create-widget-page.create-widget.step-four.switch.on-color
              </I18n>
              <ColorInput
                value={onColor}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                customeMarginButton={0}
                colorChange={(value) => setCustomizationData(value, 'onColor')}
              />
            </div>
            <div className={classNames(classes.item, classes.colorItem)}>
              <I18n noEllipsis={true} className={classes.text}>
                create-widget-page.create-widget.step-four.switch.off-color
              </I18n>
              <ColorInput
                value={offColor}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                customeMarginButton={0}
                colorChange={(value) => setCustomizationData(value, 'offColor')}
              />
            </div>
            <div className={classNames(classes.item, classes.colorItem)}>
              <I18n noEllipsis={true} className={classes.text}>
                create-widget-page.create-widget.step-four.switch.disabled-color
              </I18n>
              <ColorInput
                value={disabledColor}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                customeMarginButton={0}
                colorChange={(value) => setCustomizationData(value, 'disabledColor')}
              />
            </div>
            {selectedButtonType && selectedButtonType.value === 'TOGGLE_BUTTON' && (
              <div className={classNames(classes.item, classes.colorItem)}>
                <I18n noEllipsis={true} className={classes.text}>
                  create-widget-page.create-widget.step-four.switch.color
                </I18n>
                <ColorInput
                  value={switchColor}
                  popOverPosition={{ left: 1 }}
                  isParentRelative={false}
                  customeMarginButton={0}
                  colorChange={(value) => setCustomizationData(value, 'switchColor')}
                />
              </div>
            )}
          </React.Fragment>
        )}
      {selectedButtonType && selectedButtonType.value === 'IMAGE_BUTTON' && (
        <React.Fragment>
          <div className={classes.item}>
            <SwitchImage
              text="create-widget-page.create-widget.step-four.switch.on-image"
              buttonText="create-widget-page.create-widget.step-four.switch.upload-on"
              modalHeader="gallery.header.image-for-on"
              imageType="SWITCH_ON"
              imageInfo={onImage}
              imageName={'onImage'}
              openImageGallery={openImageGallery}
              existingImages={existingImages}
            />
          </div>

          <div className={classes.item}>
            <SwitchImage
              text="create-widget-page.create-widget.step-four.switch.off-image"
              buttonText="create-widget-page.create-widget.step-four.switch.upload-off"
              modalHeader="gallery.header.image-for-off"
              imageType="SWITCH_OFF"
              imageInfo={offImage}
              imageName={'offImage'}
              openImageGallery={openImageGallery}
              existingImages={existingImages}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SwitchButtonSection;
