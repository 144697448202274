import React from 'react';
import styles from './TableEmptyState.scss';
import classNames from 'classnames';
import I18n from '@components/I18n';

const TableEmptyState = (props) => {
  return (
    <div className={classNames(styles.emptyState, 'ellipsis-overflow')}>
      <I18n>{props.emptyStateMessage}</I18n>
    </div>
  );
};

export default TableEmptyState;
