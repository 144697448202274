import React, { useEffect, useState } from 'react';
import { useSelector } from '@src/redux/useSelector';

// services & Interfaces
import { ImageCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import I18n from '@components/I18n';
import { httpService } from '@core/http/HttpService';
import { storageService } from '@core/StorageService';

// 3rd party
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import * as cloneDeep from 'lodash/cloneDeep';
import { makeStyles } from '@material-ui/core';
import Button from '@components/Button';
import ImageIcon from './ImageIcon';
import { modalService } from '@core/modals/ModalService';
import { imageCacheService } from '@core/canvas/ImageCacheService';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const WIDGET_ACTION_BAR_HEIGHT = 45;
const WIDGET_SIDES_BORDER_SIZE = 2;

const getImagAreaDimanssions = (dimenssions) => {
  return {
    imageAreaHeight: dimenssions.clientHeight - WIDGET_ACTION_BAR_HEIGHT,
    imageAreaWidth: dimenssions.clientWidth - WIDGET_SIDES_BORDER_SIZE,
  };
};

const useStyles = makeStyles((theme: any) => ({
  dropzone: {
    // width: ({ imageAreaWidth }: any) => {console.log("styles: imageAreaWidth", imageAreaWidth); return (imageAreaWidth ? imageAreaWidth + 30 : undefined)},
    // height: ({ imageAreaHeight }: any) => (imageAreaHeight ? imageAreaHeight + 30 : undefined),
    minHeight: '100px',
    minWidth: '100px',
    backgroundColor: theme.app.createWidgetAvailableData,
    padding: '14px 15px',
    margin: '12px 0',
    borderRadius: 5,
  },
  moreInfo: {
    fontWeight: 'normal',
  },
  filesExclusives: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  costumizeRow: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
  },
  rowWithHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
  },
  subHeaderspace: {
    width: '120px',
  },
  text: {
    marginLeft: '10px',
    fontSize: '14px',
    minWidth: '65px',
  },
  widgetValue: {
    marginTop: '10px',
  },
  imageDimensions: {
    width: '100%',
    margin: '10px 0',
  },
  labelWrapper: {
    width: '100%',
  },
}));

const Image = (props) => {
  const { widgetData, setWidgetData } = props;
  const { customization } = widgetData;
  const { imageId, imageAreaHeight, imageAreaWidth } = (customization as ImageCustomization) || {};
  const [imageUrl, setImageUrl] = useState();
  const selectedWidgetDimenssions = useSelector(
    (state) => state.dashboardEditor.selectedWidgetDimenssions
  );
  const imageArea = getImagAreaDimanssions(selectedWidgetDimenssions);

  const classes = useStyles({
    props,
    imageAreaWidth: imageId ? undefined : imageAreaWidth,
    imageAreaHeight: imageId ? undefined : imageAreaHeight,
  });

  useEffect(() => {
    // Transforming useEffet to async function
    (async () => {
      const widgetDimensionsFromStorage = await storageService.get('selectedWidgetDimenssions');
      (!customization || !customization.imageAreaHeight) &&
        updateWidgetCustomization(
          {
            imageAreaHeight:
              imageArea.imageAreaHeight || widgetDimensionsFromStorage['clientHeight'],
            imageAreaWidth: imageArea.imageAreaWidth || widgetDimensionsFromStorage['clientWidth'],
            imageId: customization.imageId,
          },
          setWidgetData
        );
    })();
  }, []);

  const getImageData = async () => {
    try {
      const res = await imageCacheService.getImageURL(+imageId);
      if (res) {
        setImageUrl(res.url);
      }
    } catch {}
  };

  useEffect(() => {
    getImageData();
  }, [imageId]);

  const setCustomizationData = (data, key) => {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  const openGallery = async () => {
    try {
      const res = await modalService.openModal('imageGallery', {
        selectedImageData: { id: imageId },
      });
      if (res && res.id) {
        setCustomizationData(res.id, 'imageId');
      }
    } catch (e) {}
  };

  return (
    <div className={classes.widgetValue}>
      <div className={classes.rowWithHeader}>
        <div className="ellipsis-overflow" style={{ width: '100%' }}>
          <I18n>create-widget-page.create-widget.step-four.image.upload-imgae</I18n>
          <div className={classNames(classes.dropzone, 'ellipsis-overflow')}>
            {imageUrl ? (
              <ImageIcon height={imageAreaHeight} width={imageAreaWidth} image={imageUrl} />
            ) : null}
          </div>
        </div>
        <Button styles={{ width: 102, marginTop: 2 }} onClick={openGallery}>
          <I18n>image-customization.select</I18n>
        </Button>
      </div>

      <div className={classNames(classes.imageDimensions, 'ellipsis-overflow')}>
        <I18n element="div" width={imageAreaWidth}>
          image-customization.image-width
        </I18n>
        <I18n element="div" height={imageAreaHeight}>
          image-customization.image-height
        </I18n>
      </div>
    </div>
  );
};

export default Image;
