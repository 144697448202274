import { httpService } from '@http/HttpService';
import { AssetState, AssetTypeState, AssetData } from '@redux/redux.interface.d';
import {
  setAssetsData,
  addAssetsData,
  setAssetsDataToFirst,
  updateAssetTableData,
  removeAssetFromTableData,
  refreshAssetTableData,
} from '@redux/deviceManagment/asset/asset.actions';
import {
  setAssetsTypeData,
  editAssetType,
  addAssetsTypeData,
  updateAssetTypeTableData,
  refreshAssetTypeTableData,
  editAssetTypeGpsSupport,
} from '@redux/deviceManagment/assetType/assetType.actions';
import { dispatch, getState } from '@redux/store';

class AssetsService {
  setAssetTableData(assetsData: AssetState) {
    dispatch(setAssetsData(assetsData));
  }
  setAssetTableDataToFirst(assetData: AssetData) {
    dispatch(setAssetsDataToFirst(assetData));
  }

  addAssetTableData(assetsData: AssetState) {
    dispatch(addAssetsData(assetsData));
  }

  setAssetsTypeTableData(assetsTypeData: AssetTypeState) {
    dispatch(setAssetsTypeData(assetsTypeData));
  }

  addAssetsTypeTableData(assetsTypeData: AssetTypeState) {
    dispatch(addAssetsTypeData(assetsTypeData));
  }

  updateAssetType(id: number, status: string) {
    dispatch(editAssetType(id, status));
  }

  updateAssetTypeGpsSupport(supportGps: boolean) {
    dispatch(editAssetTypeGpsSupport(supportGps));
  }

  updateAssetTableData(changedValues: any, rowIdx: number) {
    dispatch(updateAssetTableData(changedValues, rowIdx));
  }

  updateAssetTypeTableData(changedValues: any, rowIdx: number) {
    dispatch(updateAssetTypeTableData(changedValues, rowIdx));
  }

  removeAssetFromTableData(rowIdx: string) {
    dispatch(removeAssetFromTableData(rowIdx));
  }

  refreshAssetTableData() {
    // Switches refresh to true.
    dispatch(refreshAssetTableData());
    setTimeout(() => {
      // Switches refresh back to false.
      dispatch(refreshAssetTableData());
    });
  }

  refreshAssetTypeTableData() {
    // Switches refresh to true.
    dispatch(refreshAssetTypeTableData());
    setTimeout(() => {
      // Switches refresh back to false.
      dispatch(refreshAssetTypeTableData());
    });
  }
}

export const assetsService = new AssetsService();
