import React, { useEffect, useMemo, useState } from 'react';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import styles from './MultiplayRow.scss';
import Select from '@components/Select';
import { getTag } from '@pages/CreateWidgetPage/widgets.utils';
import { modalService } from '@core/modals/ModalService';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import I18n from '@components/I18n';

function MultiplayRow(props) {
  const { item, tableCfg, columnChanged, index, widgetData } = props;
  const [editedRow, setEditedRow] = useState(item);
  const [error, setError] = useState(editedRow.action !== 'NONE' && !editedRow.actionValue);
  const tag = useMemo(() => getTag(widgetData, item), [item.valueType, item.valueId]);

  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(editedRow)) {
      setEditedRow(item);
    }
  }, [item]);

  const handleChange = async (key: string, value, updated?) => {
    const unResetKeys = getConfigValueFromWidgetSettings(widgetData.type, 'unResetKeys');
    let confirm = true;

    if (updated && key == 'actionValue' && !Number.isInteger(value)) {
      value = Math.round((value + Number.EPSILON) * 1000000) / 1000000;
    }

    if (updated && widgetData.customization && !unResetKeys?.includes('customization')) {
      confirm = await modalService.openModal('confirm', {
        iconType: 'attention_image',
        text: !widgetData.eventTemplateId
          ? 'create-widget-page.create-widget.step-two.change-initialize'
          : 'create-event-data-source-page.create-event-data-source.step-two.change-initialize',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: !widgetData.eventTemplateId
          ? 'create-widget-page.create-widget.confirm-widget-changes.header'
          : 'create-event-data-source-page.create-event-data-source.confirm-data-source-changes.header',
        showCloseBtn: true,
      });
    }
    if (confirm) {
      let newRow = { ...editedRow, [key]: value };

      if (key === 'action' && newRow.actionValue == null) newRow = { ...newRow, actionValue: 1 };

      setEditedRow(newRow);

      setError(
        updated &&
          newRow.action !== 'NONE' &&
          (!newRow.actionValue ||
            newRow.actionValue === 0 ||
            newRow.actionValue > 1000000 ||
            newRow.actionValue < -1000000)
      );

      updated && columnChanged(newRow, index);
    } else {
      setEditedRow(item);
    }
  };

  const multiplayOptions = useMemo(
    () => [
      { value: 'NONE', label: 'create-widget-page.create-widget.step-three.multiply-action.none' },
      {
        value: 'MULTIPLY',
        label: 'create-widget-page.create-widget.step-three.multiply-action.multiply',
      },
      {
        value: 'DIVIDE',
        label: 'create-widget-page.create-widget.step-three.multiply-action.divide',
      },
    ],
    []
  );

  const handleChangeValue = (value?: number) => {
    handleChange('actionValue', value);
  };

  return (
    <div className={styles.wrapper}>
      <div style={tableCfg[0].style}>
        <Select
          options={multiplayOptions}
          value={multiplayOptions.find((opt) => opt.value === editedRow.action)}
          onChange={(option) => handleChange('action', option.value, true)}
        />
      </div>
      <div style={tableCfg[1].style}>
        <I18n>{tag.name}</I18n>
        <I18n style={{ marginLeft: '3px' }}>
          {editedRow.operation ? `enum.${editedRow.operation?.toUpperCase()}` : ''}
        </I18n>
      </div>
      <div style={tableCfg[2].style}>
        <InputBase
          type="number"
          className={classNames(styles.containerInput, error && styles.error)}
          id={styles.inputStyle}
          disabled={editedRow.action === 'NONE'}
          value={editedRow.action === 'NONE' ? '' : editedRow.actionValue}
          onBlur={() => handleChange('actionValue', editedRow.actionValue, true)}
          onChange={(e) => handleChangeValue(e.target.value === '' ? null : +e.target.value)}
        />
      </div>
    </div>
  );
}

export default MultiplayRow;
