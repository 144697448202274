import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { LiveWidgetFrameProps } from './LiveWidgetFrame.interface';
import DownloadInProgress from './DownloadInProgress';
import DownloadNotStart from './DownloadNotStart';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  widgetFrame: {
    display: 'flex',
    borderTopLeftRadius: theme.app.canvasWidgetBorderRadius,
    borderTopRightRadius: theme.app.canvasWidgetBorderRadius,
    height: theme.app.widgetActionBarHeight,
    background: 'var(--widgetsBorderHeader)',
    alignItems: 'center',
    padding: '0 23px',
    userSelect: 'none',
  },
  titleWrapper: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    color: 'var(--widgetsHeaderFont)',
    fontWeight: 800,
    opacity: 0.8,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  headerHidden: {
    position: 'absolute',
    width: '100%',
    opacity: 0,
  },
  headerVisible: {
    opacity: 0.8,
    zIndex: 1,
  },
  headerForExport: {
    position: 'unset',
    opacity: 1,
    zIndex: 1,
    background: 'var(--widgetsBackground)',
  },
}));

function LiveWidgetFrame(props: LiveWidgetFrameProps) {
  const { widget, widgetData, exportData, downloadStatus, isMouseOverWidget } = props;
  const { hideWidgetName } = widget;
  const [showExport, setShowExport] = useState(false);
  const statusMap = {
    NOT_IN_PROGRESS: { component: DownloadNotStart, callback: exportData },
    IN_PROGRESS: { component: DownloadInProgress },
  };
  const data =
    downloadStatus && downloadStatus.status
      ? statusMap[downloadStatus.status]
      : statusMap['NOT_IN_PROGRESS'];
  const Status = data && data.component;
  const classes = useStyles({ ...props, statusMap });
  const enableExportCsv = getConfigValueFromWidgetSettings(widget.type, 'enableExportCsv');

  widget.exportFormats &&
    widget.exportFormats.length !== 0 &&
    enableExportCsv &&
    enableExportCsv(widget);

  useEffect(() => {
    if (
      widget.exportFormats?.length &&
      enableExportCsv &&
      enableExportCsv(widget) &&
      (widgetData?.results?.length || ['line', 'heatmap'].includes(widget.type))
    ) {
      setShowExport(true);
    } else {
      setShowExport(false);
    }
  }, [widgetData, enableExportCsv, widget.exportFormats, widget.type]);

  return (
    <div
      className={classNames(
        classes.widgetFrame,
        hideWidgetName ? classes.headerHidden : '',
        isMouseOverWidget && hideWidgetName ? classes.headerVisible : '',
        hideWidgetName &&
          widget.exportFormats &&
          widget.exportFormats.length !== 0 &&
          enableExportCsv
          ? classes.headerForExport
          : ''
      )}>
      <div className={classes.titleWrapper}>
        <div className={classes.title} title={widget.name}>
          {widget.name}
        </div>
      </div>
      {showExport ? (
        <Status
          callback={data.callback}
          downloadStatus={downloadStatus}
          color={hideWidgetName ? 'var(--systemFont)' : undefined}
        />
      ) : null}
    </div>
  );
}

export default LiveWidgetFrame;
