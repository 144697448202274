import React, { useEffect, useState } from 'react';
import { template } from 'lodash';
import styles from './styles.scss';
import ToolBar from '@components/ToolBar';
import VariablesTable from '../VariablesTable/VariablesTable';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { useSelector } from '@redux/useSelector';
import { getVariables } from '../VariablesTable/VariablesTable.utils';
import { httpService } from '@core/http/HttpService';
import { apiMap } from '@core/http/apiMap';
import { PAGE_SIZE } from '../Variables.utils';
import { getIsMachineBuilder } from '@core/utils';

const apiType = 'getAssetVariablesByOrg';

function AssetVariablesTab({ id }) {
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );
  const roles = useSelector((state) => state.config.roles);

  const [variablesList, setVariablesList] = useState([]);
  const [editedVariableId, setEditedVariableId] = useState<number>(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);
  const [searchText, setSearchText] = useState('');
  const search = (searchText) => {
    setSearchText(searchText);
  };

  const [_order, setOrder] = useState(null);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);

  useEffect(() => {
    if (selectedOrganizationsId) {
      getVariables(
        selectedOrganizationsId,
        nextPage,
        setNextPage,
        setResultsCount,
        setTotalCount,
        apiType,
        searchText,
        PAGE_SIZE,
        setDisableLoad,
        true,
        setVariablesList,
        _order
      );
    }
  }, [searchText, selectedOrganizationsId]);

  const getLastSearches = () => {
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap[apiType].url)({
            organizationId: selectedOrganizationsId,
          }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches);
      });
  };

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'assets',
    },
  };

  return (
    <div className={styles.content}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <VariablesTable
        variablesList={variablesList}
        origin={'organizationAssets'}
        setVariablesList={setVariablesList}
        id={id}
        editedVariableId={editedVariableId}
        setEditedVariableId={setEditedVariableId}
        apiType={apiType}
        selectedOrganizationsId={selectedOrganizationsId}
        isMB={getIsMachineBuilder(roles)}
        setResultsCount={setResultsCount}
        setTotalCount={setTotalCount}
        searchText={searchText}
        disabledLoad={disabledLoad}
        setDisableLoad={setDisableLoad}
      />
    </div>
  );
}

export default AssetVariablesTab;
