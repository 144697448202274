import React, { useEffect, useMemo, useState } from 'react';
import Table from '@components/Table';
import styles from './TemplatesTable.scss';
import TextField from '@components/Table/TableFields/TextField';
import { httpService } from '@core/http/HttpService';
import DateField from '@components/Table/TableFields/DateField';
import TextLowercase from '@components/Table/TableFields/TextLowercase';
import { modalService } from '@core/modals/ModalService';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@src/redux/useSelector';
import EventStatusText from '@pages/EventsManagementPage/EditTemplate/EventStatusText/EventStatusText';

function TemplatesTable(props) {
  const { showArchived, searchText, showAll, setResolts } = props;
  const roles = useSelector((state) => state.config.roles);

  const history = useHistory();
  const [tamplates, setTemplates] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [dataStatus, setDataStatus] = useState('PUBLISHED,SUSPENDED,DRAFT');
  const [order, setOrder] = useState({ label: 'createDate', orderSign: '-' });
  const defaultOrdering = { label: 'status', orderSign: '' };

  useEffect(() => {
    const newDataStatus = showAll
      ? 'PUBLISHED,SUSPENDED,DRAFT,EXPIRED,ARCHIVED'
      : showArchived
      ? 'ARCHIVED'
      : 'PUBLISHED,SUSPENDED,DRAFT';
    setDataStatus(newDataStatus);

    getTemplates(true, defaultOrdering, newDataStatus);
  }, [showArchived, showAll, searchText]);

  const colunmsCfg = useMemo(
    () => [
      {
        Component: TextField,
        field: 'name',
        label: 'event-templates-tab.table.name',
        isSortable: true,
        style: { width: '16.5%' },
      },
      {
        Component: EventStatusText,
        field: 'status',
        label: 'event-templates-tab.table.status',
        isSortable: true,
        style: { width: '16.5%' },
      },
      {
        Component: TextLowercase,
        field: 'trigger',
        label: 'event-templates-tab.table.trigger-type',
        isSortable: true,
        style: { width: '16.5%' },
      },
      {
        Component: TextLowercase,
        field: 'action',
        label: 'event-templates-tab.table.action-type',
        isSortable: true,
        style: { width: '16.5%' },
      },
      {
        Component: DateField,
        field: 'createdDate',
        label: 'event-templates-tab.table.create-date',
        isSortable: true,
        style: { width: '16.5%' },
      },
      {
        Component: DateField,
        field: 'endDate',
        label: 'event-templates-tab.table.expiry-date',
        style: { width: '16.5%' },
      },
    ],
    []
  );

  const getTemplates = async (firstInit, order, newDataStatus) => {
    setDisableLoad(true);
    const res: { next: any; results: any; allCount: string; count: string } = await httpService.api(
      {
        type: 'getEventTemplates',
        query: {
          status: !!newDataStatus ? newDataStatus : dataStatus,
          search: searchText ? searchText : null,
          ps: 50,
          p: firstInit ? 1 : nextPage,
          ordering:
            order && order.label
              ? `${order.orderSign}${order.label}`
              : `${defaultOrdering.orderSign}${defaultOrdering.label}`,
          archived: showArchived,
        },
      }
    );

    if (res) {
      searchText && setResolts(res.allCount, res.count);

      res.next && setDisableLoad(false);
      if (firstInit) {
        setTemplates(res.results);
        setNextPage(2);
      } else {
        setTemplates((prev) => [...prev, ...res.results]);
        setNextPage(nextPage + 1);
      }
    }
  };

  const onShowTamplateDitails = (rowData, rowIdx, copiedTemplate) => {
    modalService.openModal('templatesDetailsModal', {
      rowData,
      refresh: () => getTableData(true, defaultOrdering),
      onCopyTemplate: (templateId) => onCopyTemplate(templateId),
      copiedTemplate: copiedTemplate ? copiedTemplate : null,
      onEditTemplate: (templateData) => onEditTemplate(templateData),
    });
  };

  const onEditTemplate = (templateData) => {
    const templ = { ...templateData };
    history.push(`/main/events-management/templates/create`, { templateData: templ });
  };

  const onCopyTemplate = (templateId) => {
    httpService
      .api({
        type: 'copyEventTemplateData',
        urlParams: { templateId: templateId },
      })
      .then((res: any) => {
        getTableData(true, defaultOrdering);
        onShowTamplateDitails(null, null, res);
      });
  };

  const getTableData = (firstInit, order) => {
    order && setOrder(order);
    getTemplates(firstInit, order, dataStatus);
  };

  const onRoleClicked = (rowData, rowIdx) => {
    onShowTamplateDitails(rowData, rowIdx, null);
  };

  return (
    <div className={styles.tamplatesTableWrapper}>
      <Table
        data={tamplates}
        getData={getTableData}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
        onRowClicked={onRoleClicked}
      />
    </div>
  );
}

export default TemplatesTable;
