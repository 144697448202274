import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import { changeWidgetData } from '../Heatmap.utils';

function DateFormat({ classes, dateFormat, dateOptions, setWidgetData }) {
  return (
    <div className={classes.rowSection}>
      <I18n className={classNames(classes.titleWrapper, 'ellipsis-overflow')}>
        create-widget-page.create-widget.step-four.date
      </I18n>
      <Select
        styles={classes.selectFullWidthCustomStyle}
        options={dateOptions}
        value={dateFormat && dateOptions.find((opt) => opt.value === dateFormat)}
        onChange={(option) => changeWidgetData(option.value, 'dateFormat', setWidgetData)}
      />
    </div>
  );
}

export default DateFormat;
