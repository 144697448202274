import React, { useState, useEffect } from 'react';
import { getListLanguages } from './language.utils';
import { useSelector } from '@src/redux/useSelector';
import { makeStyles } from '@material-ui/core';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import LanguageTable from './LanguageTable/LanguageTable';
import { publishAll } from './language.utils';

const useStyles = makeStyles((theme: any) => ({
  box: {
    width: '100%',
    height: 'calc(100% - 60px)',
    boxSizing: 'border-box',
    backgroundColor: 'var(--systemContentBackground)',
    boxShadow: '0 0 6px 0 rgba(194, 207, 224, 0.4)',
    borderRadius: 8,
    padding: 25,
    marginTop: 22,
  },
  publishAll: {
    padding: '0px 10px',
    fontSize: '14px',
  },
}));

function Languages(props) {
  const organizationType = useSelector((state) => state.config.organizationDetails.type);
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const [defaultLanguageId, setDefaultLanguageId] = useState();
  const classes = useStyles(props);

  const [data, setData] = useState({ publishAll: true, languages: [], defaultLanguageId: null });

  useEffect(() => {
    getListLanguages(setDefaultLanguageId, setData);
  }, []);

  return (
    <>
      {organizationType !== 'UNITRONICS_MAIN' && (
        <div>
          <MaterialCheckbox
            color="primary"
            checked={data.publishAll}
            onChange={() => publishAll(data, setDefaultLanguageId, setData)}
          />
          <I18n className={classes.publishAll}>languages.publish-all</I18n>
        </div>
      )}
      <div className={classes.box}>
        <LanguageTable
          languages={data.languages.filter((x) => x.id !== 1)}
          setLanguages={setData}
          defaultLanguageId={defaultLanguageId}
          organizationType={organizationType}
          setDefaultLanguageId={setDefaultLanguageId}
          setData={setData}
        />
      </div>
    </>
  );
}

export default Languages;
