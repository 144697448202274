import React, { useCallback } from 'react';
import styles from './DashboardThumbnail.scss';
import { DashboardThumbnailProps } from './DashboardThumbnail.interface';
import { ButtonConfig } from './ThumbnailToolbar/ThumbnailToolbar.interface';
import EditThumbnail from './EditThumbnail';
import GenericThumbnail from './GenericThumbnail';

const Thumbnail = ({ dashboard }) => {
  if (dashboard.dash_instances.length > 1) {
    const { draft, published } = dashboard.dash_instances.reduce(
      (res, dash) => ({
        ...res,
        [dash.status.toLowerCase()]: dash
      }),
      { draft: {}, published: {} }
    );
    return <EditThumbnail publishedDashboard={published} draftDashboard={draft} />;
  }
  return <GenericThumbnail dashboard={dashboard.dash_instances[0]} />;
};

function DashboardThumbnail(props: DashboardThumbnailProps) {
  const { dashboard } = props;

  return (
    <div className={styles.container}>
      <Thumbnail dashboard={dashboard} />
    </div>
  );
}

export default DashboardThumbnail;
