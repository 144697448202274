import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import Button from '@components/Button';
import { modalService } from '@core/modals/ModalService';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 19,
    justifyContent: 'flex-end',
  },
}));

function Header(props) {
  const { isPublishButtonDisabled, onPublish, onClear } = props;
  const classes = useStyles(props);

  const buttonsMap = useMemo(
    () => [
      {
        label: 'white-labeling.regional-settings.publish',
        title: 'white-labeling.regional-settings.publish.message',
        header: 'white-labeling.regional-settings.publish.header',
        action: 'PUBLISH',
        disabled: isPublishButtonDisabled,
      },
      {
        label: 'white-labeling.regional-settings.clear-settings',
        title: 'white-labeling.regional-settings.clear-settings.message',
        header: 'white-labeling.regional-settings.clear-settings.header',
        action: 'CLEAR',
      },
    ],
    [isPublishButtonDisabled]
  );

  const updateDataPerAction = (action) => {
    switch (action) {
      case 'PUBLISH':
        onPublish();
        return;
      case 'CLEAR':
        onClear();
        return;
    }
  };

  const onClickButton = async (buttonData) => {
    const { title, action, header } = buttonData;
    const confirm = await modalService.openModal('confirm', {
      text: title,
      headerText: header,
      showCloseBtn: true,
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
    });
    if (confirm) {
      try {
        updateDataPerAction(action);
      } catch (e) {}
    }
  };
  return (
    <div className={classes.header}>
      {buttonsMap.map((button) => (
        <Button
          disabled={button.disabled}
          styles={{ marginLeft: 18 }}
          onClick={() => onClickButton(button)}
          mode="big"
          key={button.label}>
          <I18n>{button.label}</I18n>
        </Button>
      ))}
    </div>
  );
}

export default Header;
