import { useMemo, useRef, useState, useEffect, useCallback } from 'react';

/**
 * make infinite scroll functionality so at every moment there will be only 3 pages in the
 * screen, get:
 * getData - function that return more data from BE, get: page, pageSize, isFirstInit, isRefresh;
 * pageSize - number
 * listItemHeight - from scrolling from top, (react not scroll from top when changing the upper item in the list)
 * dataKey - which key is the "data" in the obj that getData return;
 * metaKey - which key is the "meta" in the obj that getData return;
 * hasNextKey - which key is the "hasNextKey" in the meta obj that getData return;
 * totalKey - which key is the "totalKey" in the meta obj that getData return;
 * @param param0
 * return:
 *  listProps: add it to div to get the scroll control 
    resetList: function that reset the list and return to page 1
    refreshList: refresh the list data
    list,
    meta,
    page - current page
 */
export function useInfiniteScroll({
  getData,
  pageSize,
  listItemHeight,
  dataKey = 'data',
  metaKey = undefined,
  hasNextKey = 'hasNext',
  totalKey = 'total',
}: {
  getData: Function;
  pageSize: number;
  listItemHeight: number;
  dataKey?: string;
  metaKey?: string;
  hasNextKey?: string;
  totalKey?: string;
}) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({}) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstInit, setIsFirstInit] = useState(false);
  const listRef = useRef(null);
  const page = useMemo(() => ({ p: 1 }), []);

  useEffect(() => {
    //when adding more items to the top of the list, scroll to the position the user was before
    if (isLoading && listRef?.current) {
      listRef.current.scrollTop = listItemHeight * pageSize;
      setIsLoading(false);
    } else if (listRef?.current && isFirstInit) {
      listRef.current.scrollTop = 0;
      setIsFirstInit(false);
    }
  }, [data]);

  useEffect(() => {
    getMoreData({ firstInit: true });
  }, []);

  const onScroll = useCallback(
    (event) => {
      const { scrollHeight, offsetHeight, scrollTop } = event.target;
      const distanceFromBottom = scrollHeight - (scrollTop + offsetHeight);

      if (!isLoading) {
        if (distanceFromBottom <= 150 && meta[hasNextKey]) {
          page.p += 1;
          setIsLoading(true);
          getMoreData({});
        } else if (scrollTop < 50 && page.p > 3) {
          page.p -= 1;
          setIsLoading(true);
          getMoreData({ isScrollDown: false });
        }
      }
    },
    [isLoading, meta, hasNextKey, data]
  );

  const getMoreData = async ({ firstInit = false, isScrollDown = true }) => {
    try {
      let p = isScrollDown ? page.p : page.p - 2;

      const res = await getData({ p, ps: pageSize, isFirstInit: firstInit });
      let list = [...data];
      if (firstInit) {
        list = res[dataKey];
      } else {
        //the list should hold up to only 3 pages at one time.
        if (data.length > pageSize * 2) {
          const lastPageSize =
            !isScrollDown && !meta[hasNextKey] ? meta[totalKey] % pageSize : pageSize;
          list.splice(isScrollDown ? 0 : pageSize * 2 - 1, lastPageSize);
        }

        if (isScrollDown) {
          list = list.concat(res[dataKey]);
        } else {
          list = res[dataKey].concat(list);
        }
      }

      if (isScrollDown) {
        setIsLoading(false);
      }
      if (firstInit) {
        setIsFirstInit(true);
      }
      setMeta(metaKey ? res[metaKey] : res);
      setData(list);
    } catch (e) {
      console.log({ e });
    }
  };

  const resetList = async () => {
    try {
      page.p = 1;
      const res = await getData({ p: 1, ps: pageSize, isFirstInit: true });
      if (res) {
        setIsFirstInit(true);
        setData(res[dataKey]);
        setMeta(metaKey ? res[metaKey] : res);
      }
    } catch {}
  };

  const refreshList = async () => {
    const p = page.p < 3 ? 1 : page.p - 2;
    const pageLoader = page.p === 1 ? [1] : page.p === 2 ? [1, 2] : [1, 2, 3];
    const res = await Promise.all(
      pageLoader.map((loader, index) =>
        getData({ p: p + index, ps: pageSize, isRefreshList: true })
      )
    );
    if (res) {
      const listData = res.reduce((list: [], d) => [...list, ...d[dataKey]], []);
      setData(listData);
    }
  };

  const listProps = useMemo(
    () => ({ style = {} }) => ({
      ref: listRef,
      onScroll,
      style: { overflow: 'auto', flex: 1, ...style },
    }),
    [listRef, onScroll]
  );

  return {
    listProps,
    resetList,
    refreshList,
    list: data,
    meta,
    page: page.p,
  };
}
