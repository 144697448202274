import React, { useMemo } from 'react';

// Components
import Icon from '@components/Icon';
import I18n from '@components/I18n';

// Services | Interfaces
import { GetRouterCertificateBTNProps } from './GetRouterCertificateBTN.interface';
import styles from './GetRouterCertificateBTN.scss';
import { httpService } from '@core/http/HttpService';

// 3rd party
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

function GetRouterCertificateBTN(router: GetRouterCertificateBTNProps) {
  const { id, btnText, hasCertificate } = router;

  const getCertificate = () => {
    httpService
      .api({
        type: 'getRouterCertificate',
        urlParams: { routerId: id },
      })
      .then((res: any) => {
        const fileName = `device.${res.deviceSerial}.encoded`;
        if (navigator['msSaveBlob']) {
          // IE 10+
          const blob = new Blob([res.certificate], { type: '' });
          navigator['msSaveBlob'](blob, fileName);
        } else {
          const linkSource = `data:,${res.certificate}`;
          const downloadLink = document.createElement('a');

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
  };

  return (
    <IconButton onClick={getCertificate} disabled={!hasCertificate} id={styles.button}>
      <Icon
        color="var(--systemFont)"
        className={styles.icon}
        type="getCertificate"
        disabled={!hasCertificate}
      />
      {btnText && (
        <I18n className={classNames(styles.textBtn, !hasCertificate && styles.disabled)}>
          {btnText}
        </I18n>
      )}
    </IconButton>
  );
}
export default GetRouterCertificateBTN;
