import { httpService } from '@core/http/HttpService';
import { LastUpdated } from '@modals/EditAssetModal/EditAssetModal.interface';
import { dispatch, getState } from '@src/redux/store';
import { updateUpdateAssetWidgetsData } from '@src/redux/updateAssetWidgetsData';

export const updateWidgetFilters = (assetFilter: any[]): any[] => {
  if (assetFilter?.length > 0) {
    return [
      {
        valueType: 'ASSET_PROPERTY',
        valueId: 1,
        condition: 'IS',
        values: [assetFilter[0].id],
        order: 0,
      },
    ];
  } else {
    return [];
  }
};

export const releaseWidgetForEdit = (
  assetId: number,
  widgetId: number,
  initialLastAssetUpdate: string,
  setIsDisabled: any,
  setLastAssetUpdate: any,
  startTime: number
) => {
  const isUpdating = getState().updateAssetWidgetsData[widgetId].isWidgetUpdating;
  const queueCount = Object.keys(getState().updateAssetWidgetsData).length;
  let timeoutHandler: NodeJS.Timeout;
  if (isUpdating) {
    const elapsedTime = Date.now() - startTime;
    const currentInterval = () => {
      if (elapsedTime < 60_000) {
        return 1000;
      } else if (elapsedTime > 60_000 && elapsedTime < 120_000) {
        return 5000;
      } else if (elapsedTime > 120_000 && elapsedTime < 180_000) {
        return 10_000;
      } else if (elapsedTime > 180_000 && elapsedTime < 240_000) {
        return 20_000;
      } else if (elapsedTime > 240_000 && elapsedTime < 300_000) {
        return 30_000;
      } else {
        return 60_000;
      }
    };

    httpService
      .api({
        type: 'getLastUpdated',
        urlParams: { assetId },
        disableBI: true,
      })
      .then((res: LastUpdated) => {
        if (res.lastUpdate !== initialLastAssetUpdate) {
          setIsDisabled(false);
          setLastAssetUpdate(res.lastUpdate);
          dispatch(updateUpdateAssetWidgetsData({ [widgetId]: { isWidgetUpdating: false } }));
          clearTimeout(timeoutHandler);
        } else {
          timeoutHandler = setTimeout(() => {
            releaseWidgetForEdit(
              assetId,
              widgetId,
              res.lastUpdate,
              setIsDisabled,
              setLastAssetUpdate,
              startTime
            );
          }, currentInterval() * queueCount);
        }
      });
  }
  return;
};
