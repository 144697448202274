import React, { useState, useEffect } from 'react';
import styles from './DraggableTableRow.scss';
import { DraggableTableCustomizationRowProps } from './DraggableTableRow.interface';
import Field from './Field';

function DraggableTableRow(props: DraggableTableCustomizationRowProps) {
  const { row, columnCfg, provided, isLast, onRowChanged, setData } = props;
  const [editedRow, setEditedRow] = useState(row);

  useEffect(() => {
    if (row.tagType && !row.tagType) {
      setEditedRow(row);
    }
  }, [row, editedRow]);

  return (
    <div className={styles.wrapper}>
      {columnCfg.map((col, idx) => {
        const isVisible = col?.getIsVisible ? col.getIsVisible(row) : true;
        const isDisabled = col?.getIsDisabled ? col.getIsDisabled(row) : false;

        return isVisible ? (
          <div style={col.style} key={idx}>
            <Field
              name={col.field}
              editedRow={row}
              provided={provided}
              isLast={isLast}
              type={col.type}
              value={row[col.field]}
              style={col.style}
              onChange={onRowChanged}
              setData={setData}
              isDisabled={isDisabled}
              maxLength={col.maxLength}
            />
          </div>
        ) : (
          <div style={col.style} key={idx}></div>
        );
      })}
    </div>
  );
}

export default DraggableTableRow;
