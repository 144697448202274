import React, { useMemo } from 'react';

// Components
import Icon from '@components/Icon';

// Services | Interfaces
import { modalService } from '@core/modals/ModalService';
import styles from './TagsInfo.scss';

// 3rd party
import IconButton from '@material-ui/core/IconButton';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { httpService } from '@core/http/HttpService';
import { getPermissionStatus } from '@core/ffAndPermissions';

function TagsInfo(props) {
  const { used, tagId, name } = props;

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'VIEW'), []);

  const onClick = () => {
    httpService
      .api({
        type: 'getTagsInfo',
        urlParams: { tagId: tagId },
      })
      .then((res: any) => {
        modalService.openModal('assetTypesTagsInfo', { ...res, tagName: name });
      });
  };

  return (
    <div>
      {hasPermission && (
        <>
          {used ? (
            <Button styles={{ minWidth: 87, width: 87 }} onClick={onClick} mode={['outlined']}>
              <I18n>asset-type-tags.in-use</I18n>
            </Button>
          ) : (
            <I18n>asset-type-tags.not-in-use</I18n>
          )}
        </>
      )}
    </div>
  );
}
export default TagsInfo;
