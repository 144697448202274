import TableWidget from '@components/widgets/TableWidget';
import { stepThreeDefaultValidation } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { isNameValid } from '@pages/CreateWidgetPage/DataStepComponent/StepFour/CostumizationComponents/TableCustomization/TableCustomization.utils';

const stepFourTableValidation = (widgetData: WidgetData) => {
  if (
    widgetData.customization &&
    widgetData.customization['columns'] &&
    widgetData.customization['columns'].length &&
    widgetData.customization['columns'].some(
      (c) => !isNameValid(c.displayName) || c.displayName === ''
    )
  ) {
    return false;
  }
  return true;
};

const similarWidgets = (scope) => {
  let tableSimilarWidgets = [
    {
      widgetType: 'line',
      getSideEffectCfg: () => ({
        goToStep: 3,
        condition: (widgetData) => true,
      }),
    },
  ];

  if (scope !== 'RAW_DATA') {
    tableSimilarWidgets = [
      ...tableSimilarWidgets,
      {
        widgetType: 'columns',
        getSideEffectCfg: () => ({
          goToStep: 3,
          condition: (widgetData) => widgetData.groupBy.length > 2,
          changedKey: 'groupBy',
          selectCfg: {
            isMulti: true,
            getOptionLabel: (option) => option.name,
            getOptionValue: (option) => option.valueId,
          },
          title: 'change-widget-data-modal.table-similar-widget-message',
        }),
      },
      {
        widgetType: 'pie',
        getSideEffectCfg: () => ({
          goToStep: 3,
          condition: (widgetData) => widgetData.groupBy.length > 2 || widgetData.metrics.length > 1,
        }),
      },
    ];
  }

  return tableSimilarWidgets;
};

export const table: WidgetMapItem = {
  id: 'table',
  name: 'Table',
  component: TableWidget,
  fullSize: true,
  minWidth: 300,
  minHeight: 64,
  images: {
    canvas: '/assets/images/empty_table.svg',
    thumbnail: 'table',
  },
  editModalSize: {},
  settings: {
    dataChangesToHandle: [
      'scope',
      'groupBy',
      'metrics',
      'filters',
      'sorts',
      'type',
      'calculations',
    ],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) => stepFourTableValidation(widgetData),
    },
    stepOneHidden: [],
    disableGrouping: {
      text: ({ scope }) => {
        switch (scope) {
          case 'RAW_DATA':
            return 'create-event-data-source-page.create-event-data-source.step-three.grouping-raw-data';
          case 'LAST_VALUE':
            return 'create-event-data-source-page.create-event-data-source.step-three.grouping-last-value';
          default:
            return null;
        }
      },
    },
    dragAndDropRules: {
      maxGrouping: (scope) => null,
      maxMetrics: null,
      groupingDataValidation: (scope, draggableItem, isRemove) => {
        if (
          !isRemove &&
          scope === 'AGGREGATED_LAST_VALUE' &&
          (draggableItem.id === 7 || draggableItem.valueId === 7) &&
          (draggableItem.tagType === 'ASSET_PROPERTY' ||
            draggableItem.valueType === 'ASSET_PROPERTY')
        ) {
          return 'noTimeStampOnAggregateByLastValue';
        }
        return null;
      },
    },
    showPreviewData: ['scope'],
    similarWidgets,
    enableExportCsv: (widgetData) => widgetData.scope === 'RAW_DATA',
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) =>
      widgetData.groupBy.find(
        (gb) => gb.valueType === 'ASSET_PROPERTY' && (gb.valueId === 1 || gb.valueId === 8)
      ) || // id 1 is Asset ID, id 8 is Asset Type
      ['RAW_DATA', 'LAST_VALUE'].includes(widgetData.scope),
    languageKeys: (isArWidget) => ['columnsDict.*', 'symbolDict.*'],
    customizationServerChanges: (customization) => {
      const { columnsDict, symbolDict } =
        customization.columns &&
        customization.columns.reduce(
          (result, column) =>
            column.operation
              ? {
                  columnsDict: {
                    ...result.columnsDict,
                    [`${column.valueType.toLowerCase()}.${
                      column.id
                    }.${column.operation.toLowerCase()}`]: column.displayName,
                  },
                  symbolDict: {
                    ...result.symbolDict,
                    [`${column.valueType.toLowerCase()}.${
                      column.id
                    }.${column.operation.toLowerCase()}`]: column.symbol,
                  },
                }
              : {
                  columnsDict: {
                    ...result.columnsDict,
                    [`${column.valueType.toLowerCase()}.${column.id}`]: `${column.displayName}`,
                  },
                  symbolDict: {
                    ...result.symbolDict,
                    [`${column.valueType.toLowerCase()}.${column.id}`]: column.symbol,
                  },
                },
          { columnsDict: {}, symbolDict: {} }
        );
      return {
        ...customization,
        columnsDict,
        symbolDict,
      };
    },
  },
};
