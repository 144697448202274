const ColumnMaxLength = 20;
const ColumnMinLength = 1;

export const handleNameChange = (
  displayName,
  editedColumn,
  setEditedColumn,
  setDisplayNameHasError
) => {
  if (
    !displayName ||
    (displayName.length > ColumnMaxLength && displayName.length > editedColumn.displayName.length)
  )
    return;
  validateNameError(displayName, setDisplayNameHasError);
  setEditedColumn({ ...editedColumn, displayName });
};

export const validateNameError = (displayName = '', setDisplayNameHasError) => {
  setDisplayNameHasError(!isNameValid(displayName));
};

export const isNameValid = (displayName) => {
  return displayName?.length <= ColumnMaxLength;
};

export const handleNameBlur = (editedColumn, setEditedColumn, props) => {
  const { column, index, provided, columnChanged } = props;
  const { displayName } = column;
  if (editedColumn.displayName?.length < ColumnMinLength) setEditedColumn(column);
  else if (editedColumn.displayName !== displayName) columnChanged(editedColumn, index);
};
