import * as RI from '../../redux.interface.d';

export const setPLCData = (plcData: RI.PLCState): RI.StoreAction => ({
  type: 'plc/SET_PLC_DATA',
  payload: plcData
});

export const addPLCData = (plcData: RI.PLCState): RI.StoreAction => ({
  type: 'plc/ADD_PLC_DATA',
  payload: plcData
});

export const refreshPLCTableData = (): RI.StoreAction => ({
  type: 'plc/REFRESH_PLC_DATA',
  payload: null
});

export const removePLCData = (): RI.StoreAction => ({
  type: 'plc/REMOVE_PLC_DATA',
  payload: null
});
