import React, { useCallback, useEffect, useState } from 'react';
import I18n from '@components/I18n';
import styles from './StepOne.scss';
import AssetTypesInput from './AssetTypesInput';
import VariablesInput from './VariablesInput';
import AssetPropertiesInput from './AssetPropertiesInput';
import AssetTagsInput from './AssetTagsInput';
import AlarmsInfoInput from './AlarmsInfoInput';
import AssetTagTypesInput from './AssetTagTypesInput/AssetTagTypesInput';
import {
  getConfigValueFromWidgetSettings,
  getIsIncludeInSettings,
} from '@core/canvas/widget.utils';
import { modalService } from '@core/modals/ModalService';
import IconButtonWithText from '@components/IconButtonWithText';
import { useParams } from 'react-router-dom';
import { useSelector } from '@redux/useSelector';
import {
  openReplaceTagsModal,
  promptOpenReplaceTagsModal,
} from '@pages/CreateWidgetPage/CreateWidgetPage.utils';
import {
  addAssetTypeMoreTwo,
  addAssetTypeOnOneExists,
  checkAssetPropertieOrTagOrTagTypeOrVariable,
  checkByTypeUsedInStep3,
  cleanAndUpdateData,
  cleanNavigationDashboard,
  onSetWidgetData,
  removeAllAssetTypesChangeFunctionality,
  removeAssetTypeChangeFunctionality,
  removeAssetTypeNotChangeFunctionality,
  updateWidgetData,
  updateWidgetDataOnSelectAllChanged,
} from './StepOne.utils';
import AssetInput from './AssetInput';
import { scopeTypesMap } from '@core/mapsAndDefinitions';
import { widgetMap } from '@core/canvas/widgetMap';
import { getDefaultScope } from '../StepTwo/StepTwo.utils';

function StepOne(props) {
  const { widgetData, setWidgetData } = props;
  const {
    assetTypes,
    assetProperties,
    alarmInfos,
    tags,
    tagTypes,
    variables,
    localTags,
    calculations,
    type,
    scope,
    eventTemplateId,
  } = widgetData;
  const dontShowOpenReplaceTagsPopup = useSelector((state) =>
    state.config.dontShowAgain.includes('SWITCH_TAGS_UTILITY')
  );
  const showDisclaimer = useSelector((state) => state.config.showDisclaimerAgain);

  const [totalDataTags, setTotalDataTags] = useState(0);
  const dataTagsSubtitle = getConfigValueFromWidgetSettings(widgetData.type, 'extraTitles');
  const MAX_DATA_TAGS = getConfigValueFromWidgetSettings(widgetData.type, 'maxDataTags');

  const showReplaceButton = !!(
    !eventTemplateId &&
    !['asset'].includes(widgetData.type) &&
    (widgetData.metrics?.length ||
      widgetData.filters?.length ||
      widgetData.sorts?.length ||
      widgetData.groupBy?.length ||
      widgetData.calculations?.length ||
      widgetData.customization)
  );

  const { dashboardId }: any = useParams();

  useEffect(() => {
    if (
      !widgetData.id &&
      !alarmInfos.length &&
      !assetTypes.length &&
      !assetProperties.length &&
      !tags.length &&
      !tagTypes.length &&
      !variables.length &&
      !localTags.length &&
      !calculations.length
    ) {
      setWidgetData((prevState) => ({ ...prevState, metrics: [] }));
    }

    if (
      widgetData.copyId &&
      !widgetData.isOpenReplaceTagsModalPrompted &&
      !(showDisclaimer && (type === 'switch' || type === 'update_asset')) &&
      !dontShowOpenReplaceTagsPopup
    ) {
      promptOpenReplaceTagsModal(widgetData, dashboardId, setWidgetData);
      if (widgetData['copyId']) {
        setWidgetData({ ...widgetData, isOpenReplaceTagsModalPrompted: true });
      }
    }
  }, []);

  useEffect(() => {
    setTotalDataTags(tags.length + tagTypes.length + assetProperties.length + variables.length);
  }, [tags, tagTypes, assetProperties, variables]);

  useEffect(() => {
    const setDefaultData = getConfigValueFromWidgetSettings(type, 'setDefaultDataByStep');

    if (setDefaultData && setDefaultData['1']) {
      setDefaultData['1'](scope, setWidgetData, widgetData);
    }

    if (!scope) {
      const defaultScope = getDefaultScope(type);

      if (defaultScope) {
        setWidgetData({ ...widgetData, scope: defaultScope });

        if (setDefaultData && setDefaultData['2']) {
          setDefaultData['2'](scope, setWidgetData, widgetData);
        }
      }
    }
  }, [widgetData]);

  const openConfirmModal = (text) => {
    return modalService.openModal('confirm', {
      iconType: 'attention_image',
      text: text,
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: !eventTemplateId
        ? 'create-widget-page.create-widget.confirm-widget-changes.header'
        : 'create-event-data-source-page.create-event-data-source.confirm-data-source-changes.header',
      showCloseBtn: true,
    });
  };

  useEffect(() => {
    if (scope === 'RAW_DATA' && calculations?.length) {
      if (type === 'table' || type === 'line') {
        let tempTags = tags?.length ? tags : tagTypes?.length ? tagTypes : null;

        if (
          tempTags?.length &&
          calculations.some(
            (calc) =>
              !tags?.some((t) => t.id === calc.valueId) &&
              !tagTypes?.some((tt) => tt.id === calc.valueId) &&
              !localTags?.some((lt) => lt.id === calc.valueId)
          )
        ) {
          setWidgetData((prevState) => {
            return {
              ...prevState,
              calculations: calculations.filter(
                (calc) =>
                  tempTags.find((tag) => tag.id === calc.valueId) ||
                  localTags.find((localTag) => localTag.id === calc.valueId)
              ),
            };
          });
        }
      } else if (type === 'alarms') {
        setWidgetData((prevState) => {
          return {
            ...prevState,
            calculations: [],
          };
        });
      }
    }
  }, [tags, tagTypes, localTags]);

  function onSelectedAssetTypes(newValue) {
    if (removeAssetTypeNotChangeFunctionality(widgetData, newValue)) {
      openConfirmModal('create-widget-page.create-widget.step-one.initialize-settings').then(
        (confirm) => confirm && cleanAndUpdateData(newValue, setWidgetData)
      );
    } else if (
      widgetData.assetTypes?.length &&
      removeAssetTypeChangeFunctionality(widgetData, newValue)
    ) {
      openConfirmModal('create-widget-page.create-widget.step-one.initialize-navigation').then(
        (confirm) => {
          confirm && cleanNavigationDashboard(newValue, widgetData, setWidgetData);
        }
      );
    } else if (widgetData.allAssetTypes && removeAllAssetTypesChangeFunctionality(widgetData)) {
      openConfirmModal('create-widget-page.create-widget.step-one.initialize-settings').then(
        (confirm) => confirm && updateWidgetDataOnSelectAllChanged(widgetData, setWidgetData, false)
      );
    } else if (addAssetTypeOnOneExists(widgetData, newValue)) {
      if (checkByTypeUsedInStep3(widgetData, 'TAG')) {
        openConfirmModal('create-widget-page.create-widget.step-one.initialize-settings').then(
          (confirm) => {
            confirm && cleanAndUpdateData(newValue, setWidgetData);
          }
        );
      } else if (checkByTypeUsedInStep3(widgetData, 'TAG_TYPE')) {
        openConfirmModal('create-widget-page.create-widget.step-one.initialize-tag-data').then(
          (confirm) => {
            confirm && cleanAndUpdateData(newValue, setWidgetData);
          }
        );
      } else if (checkByTypeUsedInStep3(widgetData, 'VARIABLE')) {
        openConfirmModal('create-widget-page.create-widget.step-one.initialize-tag-data').then(
          (confirm) => {
            confirm && cleanAndUpdateData(newValue, setWidgetData);
          }
        );
      } else {
        setWidgetData((prevData) => ({
          ...prevData,
          assetTypes: newValue,
          tagTypes: [],
          tags: [],
          variables: [],
          localTags: [],
          metrics: [],
        }));
      }
    } else if (addAssetTypeMoreTwo(widgetData, newValue)) {
      if (checkByTypeUsedInStep3(widgetData, 'TAG_TYPE')) {
        openConfirmModal('create-widget-page.create-widget.step-one.initialize-settings').then(
          (confirm) => {
            confirm && cleanAndUpdateData(newValue, setWidgetData);
          }
        );
      } else {
        openConfirmModal('create-widget-page.create-widget.step-one.initialize-tag-types').then(
          (confirm) => {
            confirm &&
              setWidgetData((prevData) => ({
                ...prevData,
                assetTypes: newValue,
                tagTypes: [],
                localTags: [],
              }));
          }
        );
      }
    } else {
      widgetData.allAssetTypes
        ? updateWidgetDataOnSelectAllChanged(widgetData, setWidgetData, false, newValue)
        : updateWidgetData(newValue, setWidgetData);
    }
  }

  function onSelectedAlarmsInfo(newValue) {
    checkAssetPropertieOrTagOrTagTypeOrVariable(
      widgetData,
      setWidgetData,
      newValue,
      'ALARM_INFO',
      'alarmInfos',
      openConfirmModal
    );
  }

  function onSelectedVariables(newValue) {
    checkAssetPropertieOrTagOrTagTypeOrVariable(
      widgetData,
      setWidgetData,
      newValue,
      'VARIABLE',
      'variables',
      openConfirmModal
    );
  }

  function onSelectedAssetProperties(newValue) {
    checkAssetPropertieOrTagOrTagTypeOrVariable(
      widgetData,
      setWidgetData,
      newValue,
      'ASSET_PROPERTY',
      'assetProperties',
      openConfirmModal
    );
  }

  function onSelectedTags(newValue) {
    checkAssetPropertieOrTagOrTagTypeOrVariable(
      widgetData,
      setWidgetData,
      newValue,
      'TAG',
      'tags',
      openConfirmModal
    );
  }

  function onSelectedTagTypes(newValue) {
    checkAssetPropertieOrTagOrTagTypeOrVariable(
      widgetData,
      setWidgetData,
      newValue,
      'TAG_TYPE',
      'tagTypes',
      openConfirmModal
    );
  }

  return (
    <div className={styles.stepOneContainer}>
      <div className={styles.stepHeader}>
        <I18n>widget-side-bar.data</I18n>
        {showReplaceButton && (
          <div
            id={'e2cc320f-8a56-48b3-82d7-895041fbb831'}
            style={{ fontSize: '14px', fontWeight: 500 }}>
            <IconButtonWithText
              iconType={'autorenewMui'}
              name={'create-widget-page.create-widget.step-one.replace-tags'}
              showName={true}
              tooltip={'create-widget-page.create-widget.step-one.replace-tags'}
              onClick={() => openReplaceTagsModal(widgetData, dashboardId, setWidgetData)}
            />
          </div>
        )}
      </div>
      <AssetTypesInput
        assetTypes={assetTypes}
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        eventTemplateId={eventTemplateId}
        onSelectedAssetTypes={onSelectedAssetTypes}
        updateWidgetDataOnSelectAllChanged={updateWidgetDataOnSelectAllChanged}
        isSelectAllAllowed={
          !!['value', 'alarms', 'table', 'map', 'asset'].includes(widgetData.type)
        }
      />
      {/* {['asset'].includes(type) && (
        <AssetInput
          selectedAsset={assetId || ''}
          assetTypes={assetTypes}
          widgetData={widgetData}
          setWidgetData={setWidgetData}
          eventTemplateId={eventTemplateId}
          onChange={(newValue) =>
            onSetWidgetData(widgetData, setWidgetData, 'assetId', newValue.id)
          }
        />
      )} */}
      <div className={styles.epSummary}>
        <div className={styles.collapseTitle}>
          <I18n>
            {!eventTemplateId
              ? 'create-widget-page.create-widget.step-one.data-tags'
              : 'create-event-data-source-page.create-widget.step-one.data-tags'}
          </I18n>
        </div>
        <div className={styles.collapseText}>
          <I18n>
            {!eventTemplateId
              ? 'create-widget-page.create-widget.step-one.data-tags-sub'
              : 'create-event-data-source-page.create-widget.step-one.data-tags-sub'}
          </I18n>
        </div>
        {dataTagsSubtitle && totalDataTags < MAX_DATA_TAGS && (
          <div className={styles.collapseText}>
            <I18n>{dataTagsSubtitle.dataTagsSubtitle.condition(widgetData)}</I18n>
          </div>
        )}
      </div>
      <div className={styles.epDetailsWrapper}>
        {!getIsIncludeInSettings(type, 'stepOneHidden', 'Variables') ? (
          <VariablesInput
            isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
            widgetType={type}
            assetTypes={assetTypes}
            variables={variables}
            tags={tags}
            eventTemplateId={eventTemplateId}
            onSelectedVariables={onSelectedVariables}
          />
        ) : null}
        <AssetPropertiesInput
          assetProperties={assetProperties}
          widgetType={type}
          widgetData={widgetData}
          isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
          onSelectedAssetProperties={onSelectedAssetProperties}
        />
        {type == 'alarms' ? (
          <AlarmsInfoInput
            alarmInfos={alarmInfos}
            widgetType={type}
            widgetData={widgetData}
            onSelectedAlarmsInfo={onSelectedAlarmsInfo}
          />
        ) : assetTypes.length > 1 ? (
          <AssetTagTypesInput
            isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
            widgetType={type}
            assetTypes={assetTypes}
            tagTypes={tagTypes}
            onSelectedTagTypes={onSelectedTagTypes}
          />
        ) : (
          <AssetTagsInput
            isMaxDataTags={totalDataTags >= MAX_DATA_TAGS}
            widgetType={type}
            assetTypes={assetTypes}
            tags={tags}
            variables={variables}
            onSelectedTags={onSelectedTags}
            eventTemplateId={eventTemplateId}
            allAssetTypes={widgetData.allAssetTypes}
          />
        )}
      </div>
    </div>
  );
}

export default StepOne;
