import React from 'react';
import ConditionalTrigger from './ConditionalTrigger/ConditionalTrigger';
import SchedulerTrigger from './SchedulerTrigger/SchedulerTrigger';

function Trigger(props) {
  const { templateData, setTemplateData } = props;
  const { trigger } = templateData;

  return (
    <>
      {trigger === 'SCHEDULER' && (
        <SchedulerTrigger templateData={templateData} setTemplateData={setTemplateData} />
      )}
      {(trigger === 'ALARM' || trigger === 'TELEMETRY') && (
        <ConditionalTrigger templateData={templateData} setTemplateData={setTemplateData} />
      )}
    </>
  );
}

export default Trigger;
