import { PieCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { CanvasWidget } from '@src/redux/redux.interface';

export interface PieWidgetProps {
  data: any;
  customization: PieCustomization;
  widget?: any;
  navigateDashboard?: any;
  widgetData?: any;
  apexLangChange?: boolean;
  defaultDecimalDigits?: number;
  timezone?: string;
}

export enum PieDisplayType {
  pie = 'pie',
  donut = 'donut',
}
