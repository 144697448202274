import * as RI from '../../redux.interface.d';

export const setAssetsTypeData = (assetsTypeData: RI.AssetTypeState): RI.StoreAction => ({
  type: 'assetsType/SET_ASSETS_TYPE_DATA',
  payload: assetsTypeData,
});

export const addAssetsTypeData = (assetsTypeData: RI.AssetTypeState): RI.StoreAction => ({
  type: 'assetsType/ADD_ASSETS_TYPE_DATA',
  payload: assetsTypeData,
});

export const editAssetType = (id: number, status: string): RI.StoreAction => ({
  type: 'assetsType/EDIT_ASSETS_TYPE',
  payload: { id, status },
});

export const editAssetTypeGpsSupport = (supportGps: boolean): RI.StoreAction => ({
  type: 'assetsType/EDIT_ASSETS_TYPE_GPS_SUPPORT',
  payload: { supportGps },
});

export const updateAssetTypeTableData = (changedValues: any, rowIdx: number): RI.StoreAction => ({
  type: 'assetsType/UPDATE_ASSETS_TYPE_DATA',
  payload: { data: changedValues, rowIdx },
});

export const refreshAssetTypeTableData = (): RI.StoreAction => ({
  type: 'assetsType/REFRESH_ASSETS_TYPE_DATA',
  payload: null,
});
