import { isEqual } from 'lodash';

export const onSelectingNewPlace = (
  newPlaceId,
  coordinates,
  isMulti,
  setCountryDetails,
  map,
  countryDetails
) => {
  const service = new (window as any).google.maps.places.PlacesService(map);

  if (newPlaceId === 'Unknown') {
    const serverAddressFormat = {
      googleId: null,
      formatted: 'Unknown',
      streetNumber: null,
      route: null,
      locality: null,
      state: null,
      country: null,
    };

    isMulti
      ? setCountryDetails([...countryDetails, serverAddressFormat])
      : setCountryDetails([serverAddressFormat]);

    return;
  }

  service.getDetails(
    {
      placeId: newPlaceId,
    },
    (place, status) => {
      const { formatted_address, place_id, id, address_components, geometry } = place;
      const placeCoordinates = {};
      const address: any = {
        placeId: newPlaceId,
        formatted: formatted_address,
      };
      address_components.map((component) => {
        address[component.types[0]] = component.long_name;
      });

      if (coordinates) {
        placeCoordinates['lat'] = geometry.location.lat();
        placeCoordinates['lon'] = geometry.location.lng();
      }

      const serverAddressFormat = {
        googleId: address.placeId,
        formatted: address.formatted || null,
        streetNumber: address.street_number || null,
        route: address.route || null,
        locality: address.locality || null,
        state: address.state || address.administrative_area_level_1 || null,
        country: address.country || null,
        ...placeCoordinates,
      };

      isMulti
        ? setCountryDetails([...countryDetails, serverAddressFormat])
        : setCountryDetails([serverAddressFormat]);
    }
  );
};

/**
 *  googleId is the id that google returns which is unique for each location and stored in the server so we can later compare between
 *  the location object on the server and the one google returns.
 *  A newly added location object will hold the id in an id field,
 *  this requires a check whether the location object's id field is 'googleId' or 'id'
 */
const getIdField = (locationObj) => {
  return locationObj.googleId ? 'googleId' : 'place_id';
};

const onRemovingPlace = (newValues, countryDetails, setCountryDetails) => {
  const newValuesIds = newValues.map((val) => val[getIdField(val)]);
  const valueRemoved = countryDetails
    .map((cd) => cd.googleId)
    .filter((val) => !newValuesIds.includes(val))[0];
  const updatedCountryDetails = countryDetails.filter((cd) => cd.googleId !== valueRemoved);
  setCountryDetails(updatedCountryDetails);
};

export const addressChanged = (
  newValues,
  countryDetails,
  coordinates,
  isMulti,
  setCountryDetails,
  map
) => {
  const currentCdIds = countryDetails.map((cd) => cd.googleId);
  const newValue = Array.isArray(newValues)
    ? newValues.map((val) => val[getIdField(val)]).filter((val) => !currentCdIds.includes(val))[0]
    : newValues;

  if (newValue) {
    Array.isArray(newValues)
      ? onSelectingNewPlace(
          newValues.find((c) => c.place_id === newValue)?.place_id,
          coordinates,
          isMulti,
          setCountryDetails,
          map,
          countryDetails
        )
      : onSelectingNewPlace(
          newValue.place_id,
          coordinates,
          isMulti,
          setCountryDetails,
          map,
          countryDetails
        );
    return;
  }
  // Checks if a selected value was removed
  onRemovingPlace(newValues, countryDetails, setCountryDetails);
};

export const setOptionsGoogleMaps = (
  autocompleteService,
  inputValue,
  setOptions,
  fetch,
  unknownLocationText,
  allowSelectingUnknownAddress
) => {
  let active = true;
  if (!autocompleteService.current && (window as any).google) {
    autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
  }
  if (!autocompleteService.current) {
    return undefined;
  }

  if (inputValue === '' || inputValue.length < 3) {
    setOptions([]);
    return undefined;
  }

  fetch({ input: inputValue }, (results?: any[]) => {
    if (active) {
      const res = results ? results : [];
      if (allowSelectingUnknownAddress && unknownLocationText?.toLowerCase().startsWith(inputValue))
        res.splice(0, 0, {
          description: unknownLocationText,
          formatted: 'Unknown',
          place_id: 'Unknown',
          country: null,
          googleId: null,
        });
      setOptions(res);
    }
  });

  return () => {
    active = false;
  };
};

export const setCountryWithDefault = (defaultValue, isMulti, countryDetails, setCountryDetails) => {
  const defaultVal = defaultValue ? (isMulti ? defaultValue : [defaultValue]) : [];
  if (!isEqual(defaultVal, countryDetails)) {
    setCountryDetails(defaultVal);
  }
};

export const selectedCountryDetailsChange = (countryDetails, onSelectedCountryChange, isMulti) => {
  countryDetails && onSelectedCountryChange(isMulti ? countryDetails : countryDetails[0]);
};

export const onResetValues = (resetValues, setCountryDetails) => {
  resetValues && setCountryDetails([]);
};
