import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useSelector } from '@redux/useSelector';
import LoginInput from '@pages/LoginPage/LoginInput';
import styles from './ForgotPasswordPage.scss';
import I18n from '@components/I18n';
import OnboardingActionMessage from '@components/OnboardingActionMessage';
import { ForgotPasswordModel } from './ForgotPasswordPage.interface';
import { useHistory } from 'react-router-dom';
import Icon from '@components/Icon';
import { httpService } from '@core/http/HttpService';
import { emailRegex, CAPTCHA_SCRIPT_URL } from '@core/utils';
import NewLogo from '@components/NewLogo';
import { authService } from '@core/auth/AuthService';

function ForgotPasswordPage() {
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  const host = window.location.protocol + '//' + window.location.host;
  const apiKey = authService.getReCaptchaAPIKey(
    false,
    host.includes('localhost') ? process.env.BASE_URL : host
  );
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    const reCAPTCHAscript = document.createElement('script');
    reCAPTCHAscript.type = 'text/javascript';
    reCAPTCHAscript.src = CAPTCHA_SCRIPT_URL;
    (reCAPTCHAscript.defer = true), document.head.appendChild(reCAPTCHAscript);
  }, []);

  function handleRecaptcha(response) {
    setCaptchaToken(response);
  }

  function handleRecaptchaExpired() {
    setCaptchaToken('');
  }

  window['handleRecaptcha'] = handleRecaptcha;
  window['handleRecaptchaExpired'] = handleRecaptchaExpired;

  const onSubmit = (values: any) => {
    httpService
      .api({
        type: 'forgotPassword',
        data: { email: values.email, captchaToken: captchaToken },
      })
      .then((res) => {
        setDisplayConfirmation(true);
      })
      .catch((err) => {
        setErrorMessage('errors.NOT_EXIST_EMAIL'); // TODO: Get ENUM from server
      });
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .trim()
          .required('validations.incorrect-email')
          .matches(emailRegex, 'validations.incorrect-email'),
      }),
    []
  );

  const initialValues: ForgotPasswordModel = useMemo(
    () => ({
      email: '',
    }),
    []
  );

  const onboardingActionOnclick = useCallback(() => {
    history.push('login');
  }, []);

  return (
    <>
      {!displayConfirmation ? (
        <div className={classNames(!isMobileView && 'max-height', styles.fpPage)}>
          <div className={classNames(styles.logo, isMobileView && styles.logoMobile)}>
            <NewLogo />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ isValid, values }) => (
              <Form className={styles.fpForm}>
                <Typography align="center" className={styles.fpHeader}>
                  <I18n element="div">forgot-password-page.header</I18n>
                </Typography>
                <div
                  className={classNames(styles.fpContent, isMobileView && styles.fpContentMobile)}>
                  {errorMessage && (
                    <div className={styles.errorMessage}>
                      <Icon type="error" className={styles.errorIcon} />
                      <span className={styles.errorText}>
                        <I18n noEllipsis>{errorMessage}</I18n>
                      </span>
                    </div>
                  )}
                  <LoginInput name="email" label="forgot-password-page.label" trimOnBlur />
                  <div
                    className={classNames('g-recaptcha', styles.recaptcha)}
                    data-sitekey={apiKey}
                    data-action={'FORGOT_PASSWORD'}
                    data-callback={'handleRecaptcha'}
                    data-expired-callback={'handleRecaptchaExpired'}></div>
                  <Button
                    className={classNames(
                      styles.submit,
                      (!isValid ||
                        values.email === 'unidemo@unitronics.com' ||
                        !captchaToken ||
                        captchaToken === '') &&
                        styles.disabled
                    )}
                    type="submit"
                    variant="contained"
                    disabled={
                      !isValid ||
                      values.email === 'unidemo@unitronics.com' ||
                      !captchaToken ||
                      captchaToken === ''
                    }>
                    <I18n>general.submit</I18n>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <OnboardingActionMessage
          text="forgot-password-page.confirmation"
          headerText="forgot-password-page.header"
          isError={false}
          actionBtnText="login-page.submit"
          onClickAction={onboardingActionOnclick}
        />
      )}
    </>
  );
}

export default ForgotPasswordPage;
