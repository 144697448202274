import React from 'react';
import { makeStyles, LinearProgress } from '@material-ui/core';
import Icon from '@components/Icon';

const useStyles = makeStyles((theme: any) => ({
  download: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    height: 2,
    width: 24,
  },
}));

function DownloadInProgress(props) {
  const classes = useStyles(props);
  const { color = 'white' } = props;

  return (
    <div className={classes.download}>
      <Icon type={'downloadWidgetDataDisabled'} color={color} tooltipText='context.export.download-in-progress' />
      <LinearProgress classes={{ root: classes.root }} />
    </div>
  );
}

export default DownloadInProgress;
