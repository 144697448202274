import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Table from '@components/Table';
import { useSelector } from '@redux/useSelector';
import styles from './AssetsTable.scss';
import { getFlagStatus } from '@core/ffAndPermissions';
import { compact } from 'lodash';
import TextField from '@components/Table/TableFields/TextField';
import { getAssets } from './AssetsTable.utils';
import SubscriptionData from './SubscriptionData/SubscriptionData';
import AssignSubscriptionCheckbox from './AssignSubscriptionCheckbox/AssignSubscriptionCheckbox';

const getColunmsCfg = (handleChecked, isCheckboxDisabled, selectedAssetsIds) =>
  compact([
    {
      Component: TextField,
      label: 'assets-tab.asset-id',
      field: 'assetId',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.name',
      field: 'assetName',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'assets-tab.asset-type',
      field: 'assetType',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: SubscriptionData,
      label: 'assets-tab.status',
      field: 'subscriptionStatus',
      style: { width: '25%' },
      isSortable: true,
    },
    {
      Component: AssignSubscriptionCheckbox,
      type: 'staticEnd',
      label: 'assign-subscriptions-to-assets-modal.assign-column-header',
      field: 'assign',
      style: { width: 170 },
      isFilterable: true,
      filterType: 'checkbox',
      filterOptions: [
        { label: `my-account-page.licensing-plan.unassigned`, value: 'UNASSIGNED' },
        { label: `my-account-page.licensing-plan.assigned`, value: 'ASSIGNED' },
      ],
      filterAll: true,
      handleChecked,
      isCheckboxDisabled,
      selectedAssetsIds,
    },
  ]);

function AssetsTable(props) {
  const { available, handleChecked, selectedAssetsIds, setSearchResults } = props;
  const [data, setData] = useState([]);

  const isCheckboxDisabled = (isChecked) => {
    if (available === 0 && !isChecked) return true;

    return false;
  };

  const colunmsCfg = useMemo(() => {
    return getColunmsCfg(handleChecked, isCheckboxDisabled, selectedAssetsIds);
  }, [selectedAssetsIds]);

  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [_order, setOrder] = useState(null);
  const { searchText, licenseId } = props;

  const pageSize = useMemo(() => 50, []);

  useEffect(
    () =>
      getAssets(
        setNextPage,
        searchText,
        pageSize,
        nextPage,
        setDisableLoad,
        true,
        licenseId,
        data,
        setData,
        setSearchResults,
        _order
      ),
    [searchText]
  );

  useEffect(() => {
    getAssets(
      setNextPage,
      searchText,
      pageSize,
      nextPage,
      setDisableLoad,
      true,
      licenseId,
      data,
      setData,
      setSearchResults,
      _order
    );
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={data}
        getData={(firstInit, order, filters) => {
          order && setOrder(order);
          getAssets(
            setNextPage,
            searchText,
            pageSize,
            nextPage,
            setDisableLoad,
            firstInit,
            licenseId,
            data,
            setData,
            setSearchResults,
            order,
            filters
          );
        }}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default AssetsTable;
