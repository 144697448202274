import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import styles from './Users.scss';
import ToolBar from '@components/ToolBar';
import UsersTable from './UsersTable';
import { httpService } from '@core/http/HttpService';
import { apiMap } from '@core/http/apiMap';
import { template, compact } from 'lodash';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { useSelector } from '@src/redux/useSelector';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { getUsers, openNewUserModal, getOrganizationId } from './Users.utils';

function Users() {
  const [searchText, setSearchText] = useState('');
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);

  const [usersData, setUsersData] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const organizationId = useSelector((state) => state.organizations.selectedOrganizationsId);
  const selectedOrganizationStatus = useSelector(
    (state) => state.organizations && state.organizations.status
  );
  const PAGE_SIZE = useMemo(() => 35, []);

  useEffect(() => {
    if (organizationId)
      getUsers(
        setUsersData,
        setTotalCount,
        setResultsCount,
        setNextPage,
        usersData,
        PAGE_SIZE,
        setDisableLoad,
        nextPage,
        searchText,
        true
      );
  }, [searchText, organizationId]);

  const search = (searchText) => {
    setSearchText(searchText);
  };

  const getLastSearches = () => {
    const organizationId = getOrganizationId();
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getUsers'].url)({ organizationId }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches.reverse());
      });
  };

  const hasPermission = useMemo(() => getPermissionStatus('ORGANIZATION', 'EDIT'), []);

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'users',
    },
    buttons: compact([
      {
        type: 'create',
        onClick: async () => {
          const shouldReloadUsers = await openNewUserModal(
            searchText,
            setClearSearch,
            search,
            setUsersData
          );
          if (shouldReloadUsers)
            getUsers(
              setUsersData,
              setTotalCount,
              setResultsCount,
              setNextPage,
              usersData,
              PAGE_SIZE,
              setDisableLoad,
              nextPage,
              searchText,
              true
            );
        },
        disabled: selectedOrganizationStatus === 'PRE_ACTIVATED' || !hasPermission,
      },
    ]),
  };

  return (
    <div className={classNames('max-height', styles.usersTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <UsersTable
        usersData={usersData}
        setUsersData={setUsersData}
        getUsers={(isFirstInit) =>
          getUsers(
            setUsersData,
            setTotalCount,
            setResultsCount,
            setNextPage,
            usersData,
            PAGE_SIZE,
            setDisableLoad,
            nextPage,
            searchText,
            isFirstInit
          )
        }
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default Users;
