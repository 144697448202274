import React, { useMemo } from 'react';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { makeStyles } from '@material-ui/core';
import IconButtonWithText from '@components/IconButtonWithText';
import { openDeleteVariableModal, openEditVariableSettingsModal } from './ActionButtons.utils';
import { openResetValueModal, valueTypeOptions } from '../../Variables.utils';
import { i18nService } from '@core/i18n/I18nService';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonWithText: {
    display: 'flex',
  },
  text: {
    margin: '2px 10px',
    fontSize: '14px',
  },
}));

function ActionButtons(props) {
  const {
    origin,
    setVariablesList,
    name,
    managementLevel,
    setEditedVariableId,
    setEditedAssetId,
    assetId,
    value,
    defaultValue,
    order,
  } = props;
  const assetTypeOrAssetId = +props.assetTypeOrAssetId || +props.assetTypeId;
  const variableId = props.id;
  const valueType = i18nService.translate(
    valueTypeOptions.find((option) => option.value === props.valueType).label
  );
  const classes = useStyles(props);
  const hasPermission = useMemo(
    () => getPermissionStatus('ORGANIZATION', 'EDIT') || getPermissionStatus('DEVICE', 'EDIT'),
    []
  );
  const showAllButtons = ['assetType', 'organizationAssetTypes'].includes(origin);
  const showReset = ['organizationAssets', 'assets'].includes(origin) && value !== defaultValue;
  const showUpdate =
    ['assetType', 'assets', 'organizationAssetTypes'].includes(origin) ||
    managementLevel !== 'GLOBAL';

  const buttons = [
    {
      type: 'edit',
      name: 'general.update',
      tooltip: 'general.update',
      onClick: (e) => {
        if (showUpdate) {
          e.stopPropagation();
          setEditedVariableId(variableId);
          assetId && setEditedAssetId(assetId);
        }
      },
      isDisabled: !hasPermission,
      isVisible: showUpdate,
    },
    {
      type: 'settings',
      name: 'general.settings',
      tooltip: 'general.settings',
      onClick: (e) => {
        e.stopPropagation();
        setEditedVariableId(null);
        openEditVariableSettingsModal(
          origin,
          {
            assetTypeId: +assetTypeOrAssetId,
            id: variableId,
            name,
            managementLevel,
            valueType,
          },
          setVariablesList
        );
      },
      isDisabled: !hasPermission,
      isVisible: showAllButtons,
    },
    {
      type: 'delete',
      name: 'general.delete',
      tooltip: 'general.delete',
      onClick: (e) => {
        e.stopPropagation();
        setEditedVariableId(null);
        openDeleteVariableModal(origin, +assetTypeOrAssetId, variableId, name, setVariablesList);
      },
      isDisabled: !hasPermission,
      isVisible: showAllButtons,
    },
    {
      type: 'reset',
      name: 'general.reset',
      tooltip: 'general.reset',
      onClick: () => {
        setEditedVariableId(null);
        openResetValueModal(origin, assetId, variableId, setVariablesList, order);
      },
      isDisabled: !hasPermission,
      isVisible: showReset,
    },
  ];

  return (
    <div className={classes.wrapper}>
      {buttons.map((button, idx) => (
        <div style={{ width: '100px', minWidth: '100px' }} key={idx}>
          {button.isVisible ? (
            <IconButtonWithText
              iconType={button.type}
              name={button.name}
              showName
              tooltip={button.tooltip}
              onClick={!button.isDisabled ? button.onClick : null}
              isDisabled={button.isDisabled}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default ActionButtons;
