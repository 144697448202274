import React from 'react';
import classNames from 'classnames';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import ColorInput from '@components/ColorInput';
import { changeWidgetData } from '../Heatmap.utils';
import { TextField } from '@material-ui/core';

function DataLabels({ chartType, classes, dataLabels, setWidgetData }) {
  return (
    <div className={classes.section}>
      <div className={classes.titleWrapper}>
        <I18n>create-widget-page.create-widget.step-four.data-labels</I18n>
      </div>
      <div className={classes.item}>
        <MaterialCheckbox
          color="primary"
          checked={dataLabels?.visible}
          onClick={() =>
            changeWidgetData(
              { ...dataLabels, visible: !dataLabels.visible },
              'dataLabels',
              setWidgetData
            )
          }
        />
        <I18n className={classes.cbLabel}>create-widget-page.create-widget.step-four.visible</I18n>
      </div>
      {chartType === 'REGULAR' && (
        <div className={classNames(classes.item, classes.colorItem)}>
          <I18n className={classes.text}>
            create-widget-page.create-widget.step-four.text-color
          </I18n>
          <ColorInput
            disabled={!dataLabels.visible}
            value={dataLabels.textColor}
            customeMarginButton={0}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            colorChange={(value) =>
              changeWidgetData({ ...dataLabels, textColor: value }, 'dataLabels', setWidgetData)
            }
          />
        </div>
      )}
      <div className={classes.item}>
        <I18n className={classes.text}>
          create-widget-page.create-widget.step-four.decimal-digits
        </I18n>
        <TextField
          title={dataLabels.decimalDigits && dataLabels.decimalDigits.toString()}
          disabled={!dataLabels.visible}
          className={classes.numberField}
          margin="dense"
          variant="outlined"
          value={dataLabels.decimalDigits}
          onChange={(e) =>
            changeWidgetData(
              { ...dataLabels, decimalDigits: +e.target.value },
              'dataLabels',
              setWidgetData
            )
          }
          type="number"
          inputProps={{
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
    </div>
  );
}

export default DataLabels;
