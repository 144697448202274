import React, { useCallback, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router';
import DataSourcesPage from './DataSourcesPage/DataSourcesPage';
import CreateDataSource from './CreateDataSource/CreateDataSource';

function DataSources(props) {
  const [isAddingDataSource, setIsAddingDataSource] = useState(false);
  const [editedDataSource, setEditedDataSource] = useState();

  return (
    <div className="max-height">
      {isAddingDataSource ? (
        <CreateDataSource
          {...props}
          setIsAddingDataSource={setIsAddingDataSource}
          setEditedDataSource={setEditedDataSource}
          initialDataSourceData={editedDataSource}
        />
      ) : (
        <DataSourcesPage
          {...props}
          setIsAddingDataSource={setIsAddingDataSource}
          setEditedDataSource={setEditedDataSource}
        />
      )}
    </div>
  );
}

export default DataSources;
