import React from 'react';
import classNames from 'classnames';

import styles from '../WidgetSideBar.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

const StepFourText = ({ displayData }) => {
  const languageId = useSelector((state) => state.config.languageId);
  return (
    <>
      <div
        className={classNames('ellipsis-overflow', styles.activeText)}
        title={displayData.name}>{`${i18nService.translate(
        !displayData.eventTemplateId
          ? 'widget-side-bar.widget-name'
          : 'event-data-source-side-bar.event-data-source-name',
        languageId
      )} ${displayData.name}`}</div>

      <div
        className={classNames('ellipsis-overflow', styles.activeText)}
        title={
          displayData.type === 'vpn'
            ? 'VPN'
            : i18nService.translate(`edit-dashboard.widget-name.${displayData.type}`, languageId)
        }>{`${i18nService.translate(
        !displayData.eventTemplateId
          ? 'widget-side-bar.widget-type'
          : 'event-data-source-side-bar.event-data-source-type',
        languageId
      )} ${
        displayData.type === 'vpn'
          ? 'VPN'
          : i18nService.translate(`edit-dashboard.widget-name.${displayData.type}`, languageId)
      }`}</div>
    </>
  );
};

export default StepFourText;
