import UpdateAssetWidget from '@components/widgets/UpdateAssetWidget';
import {
  UpdateAssetCustomization,
  WidgetData,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { stepThreeDefaultValidation } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';
import { setMetricsAndCustomization } from './updateAsset.utils';

const stepFourUpdateAssetValidation = (widgetData: WidgetData) => {
  if (widgetData.customization) {
    const { switchCustomization, requestSentText, tagsConfiguration } =
      widgetData.customization as UpdateAssetCustomization;

    if (!requestSentText?.text || !switchCustomization.onText) return false;

    if (tagsConfiguration.some((t) => t.validate && t.min == null && t.max == null)) return false;

    if (
      tagsConfiguration.some((t) => t.validate && t.format === 'STRING' && (t.min < 0 || t.max < 0))
    )
      return false;

    if (
      tagsConfiguration.some(
        (t) => t.validate && t.format !== 'FLOAT32' && t.min && !/^[-]?\d*$/.test(t.min.toString())
      )
    )
      return false;

    if (
      tagsConfiguration.some(
        (t) => t.validate && t.format !== 'FLOAT32' && t.max && !/^[-]?\d*$/.test(t.max.toString())
      )
    )
      return false;
  }
  return true;
};

export const updateAsset: WidgetMapItem = {
  id: 'update_asset',
  name: 'update_asset',
  component: UpdateAssetWidget,
  minWidth: 166,
  minHeight: 100,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_update_asset.svg',
    thumbnail: 'update_asset',
  },
  editModalSize: {},
  settings: {
    enableSaveAsDraft: false,
    dragAndDropRules: {
      maxGrouping: () => null,
      maxMetrics: null,
    },
    accordionDisable: ['sort', 'groupingAndMeasures', 'filtering'],
    assetIsNotDisabled: ['booleanType', 'floatType', 'numberType', 'stringType', 'dateTimeType'],
    acceptOnlyWritableTags: true,
    dataChangesToHandle: ['metrics', 'scope'],
    showDisclaimer: true,
    stepOneDisabled: ['AssetProperties'],
    stepOneHidden: ['Variables'],
    useSteps: [1, 3, 4],
    setDefaultDataByStep: {
      3: (scope, setWidgetData, widgetData) => {
        setMetricsAndCustomization(setWidgetData, widgetData);
      },
    },
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) => stepFourUpdateAssetValidation(widgetData),
    },
    languageKeys: (isArWidget) => {
      return [
        'textualRemark.text',
        'requestSentText.text',
        'switchCustomization.onText',
        'switchCustomization.offText',
        'updateButtonCustomization.text',
        'columnsDict.*',
      ];
    },
    customizationServerChanges: (customization) => ({
      ...customization,
      columnsDict:
        customization.tagsConfiguration &&
        customization.tagsConfiguration.reduce(
          (result, tag) => ({
            ...result,
            [`${tag.tagType.toLowerCase()}.${tag.tagId}`]: tag.displayName,
          }),
          {}
        ),
    }),
    allowAssetTypeNavigation: () => false,
    hideFilterNavigation: () => true,
  },
};
