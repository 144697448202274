import React from 'react';
import classNames from 'classnames';
import styles from './SelectComponent.scss';
import Select from '@components/Select';
import I18n from '@components/I18n';
import { sharedStyles } from '@core/utils';

const customStyles = () => {
  return {
    option: (base, state) => ({
      ...base,
      height: '100%',
      padding: '4px 12px',
      color: 'var(--systemFont)',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
  };
};

function SelectComponent(props) {
  const { caption, options, value, placeholder, onChange, isDisabled = false, restProps } = props;

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.caption, 'ellipsis-overflow')}>
        <I18n>{caption}</I18n>:
      </div>
      <Select
        disabled={isDisabled}
        styles={{ ...customStyles(), placeholder: sharedStyles.placeholder }}
        options={options}
        value={value}
        onChange={(selectedOption) => {
          onChange(selectedOption, restProps);
        }}
        placeholder={placeholder}
        isOptionDisabled={(option) => option.isDisabled}
        maxMenuHeight={280}
      />
    </div>
  );
}

export default SelectComponent;
