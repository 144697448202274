import React, { useEffect, useState, useMemo } from 'react';

// Components
import Table from '@components/Table';
import TextField from '@components/Table/TableFields/TextField';
import DateField from '@components/Table/TableFields/DateField';
import PLCAssetName from './PLCAssetName';
import RouterPLCDisconnectBTN from './RouterPLCDisconnectBTN';

// Services | Interfaces
import { httpService } from '@core/http/HttpService';
import { PLCDataResponse } from '@pages/DeviceManagment/PLC/PLCManagementTab/PLCTable/plcTable.interface';
import { RouterTabsProps } from '@modals/EditRouterModal/EditRouterModal.interface';
import styles from './RouterPLCsTable.scss';
import { getPermissionStatus } from '@core/ffAndPermissions';

// 3rd party
import { compact } from 'lodash';

const getColunmsCfg = (props: RouterTabsProps, hasEditPermission) =>
  compact([
    hasEditPermission && {
      Component: RouterPLCDisconnectBTN,
      type: 'staticStart',
      label: '',
      style: { width: 35 },
      props: { routerId: props.id, onDataChanged: props.onDataChanged },
    },
    {
      Component: TextField,
      label: 'plc-tab.serial-number',
      field: 'serial',
      style: { width: '17%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'plc-tab.model',
      field: 'model',
      style: { width: '12%' },
      isSortable: true,
    },
    {
      Component: TextField,
      label: 'plc-tab.catalog-number',
      field: 'catalogNumber',
      style: { width: '20%' },
      isSortable: true,
    },
    {
      Component: DateField,
      label: 'plc-tab.date-created',
      field: 'createdAt',
      style: { width: '15%' },
      isSortable: true,
    },
    {
      Component: PLCAssetName,
      label: 'plc-tab.asset-connected-name',
      field: 'assetName',
      style: { width: '20%' },
      isSortable: true,
    },
  ]);

function RouterPLCsTable(props: RouterTabsProps) {
  const { id: routerId, plcCount, ownerId } = props;
  const [routerPLCs, setRouterPLCs] = useState([]);
  const defaultOrdering = { label: 'createdAt', orderSign: '-' };
  const [nextPage, setNextPage] = useState(1);
  const pageSize = useMemo(() => 50, []);
  const [disabledLoad, setDisableLoad] = useState(false);
  const hasEditPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);
  const colunmsCfg = useMemo(() => {
    return getColunmsCfg(props, hasEditPermission);
  }, []);

  useEffect(() => getRouterPLCs(true), [plcCount]);

  const getRouterPLCs = (firstInit = false, order = defaultOrdering) => {
    httpService
      .api({
        type: 'getRouterPLCs',
        urlParams: { routerId, organizationId: ownerId || undefined },
        query: {
          p: firstInit ? 1 : nextPage,
          ps: pageSize,
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      })
      .then((res: PLCDataResponse) => {
        setDisableLoad(!res.next);
        if (firstInit) {
          setRouterPLCs(res.results);
          setNextPage(2);
        } else {
          setRouterPLCs([...routerPLCs, ...res.results]);
          setNextPage(nextPage + 1);
        }
      });
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={true}
        data={routerPLCs}
        colunmsCfg={colunmsCfg}
        className={styles.tableBody}
        getData={getRouterPLCs}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default RouterPLCsTable;
