import React from 'react';

// Comonents
import I18n from '@components/I18n';
import Button from '@components/Button';

// Interfaces | Services
import { modalService } from '@core/modals/ModalService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { httpService } from '@core/http/HttpService';

const AssignButton = (props) => {
  const { styles = {}, licenseId, assetId, reloadData } = props;

  const onClick = async () => {
    const confirm = await modalService.openModal('assignSubscriptionToAsset', {
      showCloseBtn: true,
      subscriptionInfo: {
        name: props.licenseName,
        subscriptionKey: props.subscriptionKey,
        description: props.description,
        endDate: props.endDate,
        catalogNum: props.catalogNumber,
        status: props.status,
        licenseId,
        subscriptionId: props.id,
      },
    });

    if (confirm && reloadData) {
      reloadData();
    }
  };

  return (
    <Button
      onClick={onClick}
      styles={{ minWidth: 92, width: 92, padding: '0 12px', ...styles }}
      disabled={status === 'EXPIRED' || assetId}>
      <I18n>{`assets-tab.assign`}</I18n>
    </Button>
  );
};

export default AssignButton;
