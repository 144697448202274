import * as Yup from 'yup';
import { phoneRegex, buildErrorObj, emailRegex } from '@core/utils';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { omit } from 'lodash';
import { dispatch } from '@src/redux/store';
import { setUserName } from '@src/redux/login';

export const getFieldsCfg1 = (titleOptions) => {
  return [
    {
      label: 'my-details.title',
      name: 'title',
      type: 'select',
      options: titleOptions,
    },
    {
      label: 'my-details.first-name',
      name: 'firstName',
      type: 'input',
      isRequired: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(20, 'my-details.first-name-message')
        .min(1, 'my-details.first-name-message'),
    },
    {
      label: 'my-details.middle-name',
      name: 'middleName',
      type: 'input',
      validation: Yup.string()
        .nullable()
        .max(20, 'my-details.middle-name-message')
        .min(1, 'my-details.middle-name-message'),
    },
    {
      label: 'my-details.last-name',
      name: 'lastName',
      type: 'input',
      isRequired: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(100, 'my-details.last-name-message')
        .min(1, 'my-details.last-name-message'),
    },
  ];
};

export const getFieldsCfg2 = (
  emailValidation,
  data,
  setEmailValidation,
  setIsValidEmail,
  options
) => {
  return [
    {
      label: 'my-details.phone',
      name: 'phone',
      type: 'phoneInput',
    },
    {
      label: 'my-details.mobile',
      name: 'mobile',
      type: 'phoneInput',
    },
    {
      label: 'my-details.email',
      name: 'email',
      type: 'input',
      validate: (values) => {
        if (emailValidation.email === values.email && emailValidation.message) {
          return emailValidation.message;
        }
      },
      onLostFocus: (values, setFieldValue, setInputValue, setErrors) => {
        values.email = values.email?.trim();
        emailValidation.email !== values.email &&
          data['email'] !== values.email &&
          httpService
            .api({
              type: 'editUserValidateEmail',
              data: { email: values['email'] },
              disableBI: true,
            })
            .then((res: any) => {
              if (res.isValid) {
                setEmailValidation({ email: '', message: '', isValid: true });
                setIsValidEmail(true);
              } else {
                const message =
                  res.message &&
                  i18nService.translate(
                    `errors.${res.message.code}`,
                    undefined,
                    buildErrorObj(res.message.keys)
                  );
                setEmailValidation({ email: values.email, message, isValid: false });
                setIsValidEmail(true);
                setErrors({ email: message });
              }
            });
      },
      validation: Yup.string()
        .trim()
        .required('validations.mandatory-field')
        .matches(emailRegex, 'my-details.email-message'),
    },
  ];
};

export const get2FAFieldsCfg = (mfa, mobileVerified, verifiedMobileChanged, isUnassigned) => {
  const options = [
    {
      label: i18nService.translate('my-details.2fa-notification-method.email'),
      value: 'EMAIL',
    },
  ];

  if (mobileVerified && !isUnassigned)
    options.push({
      label: i18nService.translate('my-details.2fa-notification-method.sms'),
      value: 'SMS',
    });

  return {
    label: 'my-details.2fa-notification-method',
    name: 'mfaMethod',
    type: 'select',
    options: options,
    condition: () => mfa,
    editable: () => mobileVerified && !verifiedMobileChanged && !isUnassigned,
  };
};

export const save = async (values, data, setEditMode, setData, setSaving, setIsValidEmail) => {
  try {
    values['title'] = values['title']
      ? values['title'].value
        ? values['title'].value
        : values['title']
      : null;
    values['timezone'] =
      values['timezone'] && values['timezone'].value
        ? values['timezone'].value
        : values['timezone'];

    values['mfaMethod'] = values['mfaMethod']
      ? values['mfaMethod'].value
        ? values['mfaMethod'].value
        : values['mfaMethod']
      : null;

    const res: any = await httpService.api({
      type: 'updateMyProfile',
      data: omit(values, ['enable']),
    });
    dispatch(setUserName(res));
    if (data['email'] !== values.email) {
      modalService.openAlert({
        text: 'edit-user-modal.new-email-alert',
        iconType: 'v_image',
      });
    }
    setEditMode(false);
    setData(res);
  } catch {}

  setSaving(false);
  setIsValidEmail(false);
};

export const onSubmit = (
  values,
  data,
  isValidEmailRef,
  emailValidationRef,
  setEditMode,
  setData,
  setSaving,
  setIsValidEmail
) => {
  setSaving(true);
  const myInterval = setInterval(() => {
    if (
      values['email'] === data['email'] ||
      (isValidEmailRef.current && emailValidationRef.current.isValid)
    ) {
      save(
        { ...values, middleName: values.middleName === '' ? null : values.middleName },
        data,
        setEditMode,
        setData,
        setSaving,
        setIsValidEmail
      );
      clearInterval(myInterval);
    } else if (isValidEmailRef.current && !emailValidationRef.current.isValid) {
      clearInterval(myInterval);
      setIsValidEmail(false);
      setSaving(false);
    }
  }, 100);
};
