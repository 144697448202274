import React from 'react';
import I18n from '@components/I18n';
import { UserData } from '../usersTable.interface';
import { colorMap } from '@core/statusColorMap';

function UserStatus(props: UserData) {
  const { status } = props;
  return <I18n style={{ color: colorMap[status] }}>{`user-status.${status}`}</I18n>;
}

export default UserStatus;
