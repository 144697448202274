export const getCountryByGoogleId = (newPlaceId, setCountry: any) => {
  const map = new google.maps.Map(document.getElementById('map'));
  const service = new (window as any).google.maps.places.PlacesService(map);

  if (newPlaceId) {
    service.getDetails(
      {
        placeId: newPlaceId,
      },
      (place) => {
        place
          ? setCountry(
              place.address_components
                .find((ac) => ac.types.some((t) => t === 'country'))
                .short_name.toLowerCase() || 'us'
            )
          : setCountry('us');
      }
    );
  } else {
    setCountry('us');
  }
};
