import React from 'react';
import classNames from 'classnames';
import style from './NumberField.scss';

function NumberField(props: any) {
  const { field } = props;
  const value = props[field];
  return (
    <div className={classNames(style.text, 'ellipsis-overflow')} title={value.toString()}>
      {value}
    </div>
  );
}

export default NumberField;
