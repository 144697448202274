import React, { useState, useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import styles from './IBVCustomizationRow.scss';
import { IBVCustomizationRowProps } from './IBVCustomizationRow.interface';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { modalService } from '@core/modals/ModalService';

function IBVCustomizationRow(props: IBVCustomizationRowProps) {
  const {
    column,
    columnCfg,
    columnChanged,
    validateOnBlur,
    isBoolean,
    conditions,
    deleteCondition,
    index,
    error,
    disabled = false,
    isInstantServerUpdate = false,
  } = props;
  const [editedColumn, setEditedColumn] = useState(column);

  useEffect(() => {
    setEditedColumn(column);
  }, [column]);

  const handleChange = (key: string, value) => {
    setEditedColumn({ ...editedColumn, [key]: value });
  };

  const openImageGallery = async () => {
    try {
      const res = await modalService.openModal('imageGallery', {
        selectedImageData: { id: editedColumn.imageId, name: editedColumn.imageName },
        existingImages: conditions.map((con) => con.imageId),
      });
      if (res && res.id) {
        setEditedColumn({ ...editedColumn, imageName: res.name, imageId: res.id });
        columnChanged({ ...editedColumn, imageName: res.name, imageId: res.id }, index);
        validateOnBlur &&
          validateOnBlur({ ...editedColumn, imageName: res.name, imageId: res.id }, index);
      }
    } catch (e) {}
  };

  const openImageViewer = () => {
    modalService.openModal('imageViewer', {
      imageId: editedColumn.imageId,
      imageName: editedColumn.imageName || 'Image',
    });
  };

  return (
    <div className={styles.wrapper}>
      <div style={columnCfg[0].style}>
        {isBoolean ? (
          <div>{upperFirst(editedColumn.boolValue?.toString())}</div>
        ) : (
          <InputBase
            title={`${
              editedColumn.fromValue || editedColumn.fromValue === 0 ? editedColumn.fromValue : ''
            }`}
            type={'number'}
            id={styles.inputStyle}
            disabled={disabled}
            className={classNames(styles.containerInput, error && styles.error)}
            value={
              editedColumn.fromValue || editedColumn.fromValue === 0 ? editedColumn.fromValue : ''
            }
            onBlur={
              isInstantServerUpdate && validateOnBlur
                ? () => validateOnBlur(editedColumn, index)
                : () => columnChanged(editedColumn, index, 'fromValue')
            }
            onChange={
              isInstantServerUpdate
                ? (e) => {
                    const updatedColumn = {
                      ...editedColumn,
                      fromValue: e.target.value === '' ? null : +e.target.value,
                    };
                    columnChanged(updatedColumn, index, 'fromValue');
                    handleChange('fromValue', e.target.value === '' ? null : +e.target.value);
                  }
                : (e) => handleChange('fromValue', e.target.value === '' ? null : +e.target.value)
            }
          />
        )}
      </div>
      {!isBoolean && (
        <div style={columnCfg[1].style}>
          <InputBase
            type={'number'}
            title={`${
              editedColumn.toValue || editedColumn.toValue === 0 ? editedColumn.toValue : ''
            }`}
            className={classNames(styles.containerInput, error && styles.error)}
            id={styles.inputStyle}
            disabled={disabled}
            value={editedColumn.toValue || editedColumn.toValue === 0 ? editedColumn.toValue : ''}
            onBlur={
              isInstantServerUpdate && validateOnBlur
                ? () => validateOnBlur(editedColumn, index)
                : () => columnChanged(editedColumn, index, 'toValue')
            }
            onChange={
              isInstantServerUpdate
                ? (e) => {
                    const updatedColumn = {
                      ...editedColumn,
                      toValue: e.target.value === '' ? null : +e.target.value,
                    };
                    columnChanged(updatedColumn, index, 'toValue');
                    handleChange('toValue', e.target.value === '' ? null : +e.target.value);
                  }
                : (e) => handleChange('toValue', e.target.value === '' ? null : +e.target.value)
            }
          />
        </div>
      )}
      <div style={columnCfg[isBoolean ? 1 : 2].style}>
        {editedColumn.imageId && (
          <Icon
            className={'pointer'}
            type={'previewImage'}
            onClick={openImageViewer}
            disabled={disabled}
            tooltipText={'general.preview'}
          />
        )}
      </div>
      <div style={columnCfg[isBoolean ? 2 : 3].style}>
        {editedColumn.imageId ? (
          <div className={classNames('ellipsis-overflow')} title={editedColumn.imageName}>
            {editedColumn.imageName}
          </div>
        ) : (
          <Button onClick={openImageGallery} disabled={disabled}>
            <I18n>create-widget-page.create-widget.step-four.image-by-value.select</I18n>
          </Button>
        )}
      </div>
      <div className={styles.lastIcons}>
        {editedColumn.imageId && (
          <Icon
            type={'edit'}
            className={'pointer'}
            onClick={openImageGallery}
            color={'var(--systemFont)'}
            disabled={disabled}
            tooltipText={'general.edit'}
          />
        )}
        {!isBoolean && conditions.length > 1 && (
          <Icon
            type={'removeImageRow'}
            style={{ cursor: 'pointer', marginLeft: 8 }}
            onClick={deleteCondition}
            disabled={disabled}
            tooltipText={'general.delete'}
          />
        )}
      </div>
    </div>
  );
}

export default IBVCustomizationRow;
