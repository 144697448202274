import * as RI from '../../redux.interface.d';

const initialState = (): RI.PLCState => ({
  totalCount: 0,
  resultsCount: 0,
  plcData: [],
  refresh: false
});

export default function plctReducer(state: RI.PLCState = initialState(), action: RI.StoreAction) {
  const { type, payload } = action;

  switch (type) {
    case 'plc/SET_PLC_DATA':
      return {
        ...state,
        plcData: payload.plcData,
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount
      };
    case 'plc/ADD_PLC_DATA':
      return {
        ...state,
        plcData: state.plcData.concat(payload.plcData),
        totalCount: payload.totalCount,
        resultsCount: payload.resultsCount
      };
    case 'plc/REFRESH_PLC_DATA':
      return { ...state, refresh: !state.refresh };
    case 'plc/REMOVE_PLC_DATA':
      return initialState();
    default:
      return state;
  }
}
