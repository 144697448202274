import React, { useMemo } from 'react';
import { canvasService } from '@core/canvas/CanvasService';
import { useSubject } from '@core/effects';
import styles from './CanvasEndIndicator.scss';

/**
 * Renders a red line indicating the rightmost place where widgets
 * can be placed in order to resize the canvas to the specified
 * width.
 */
function CanvasEndIndicator() {
  const canvasEndIndicatorX = useSubject(canvasService.canvasEndIndicatorX$);
  const divS = useMemo(() => ({ left: canvasEndIndicatorX }), [canvasEndIndicatorX]);

  return canvasEndIndicatorX ? <div className={styles.wrapper} style={divS} /> : null;
}

export default CanvasEndIndicator;
