import React, { useRef, useState } from 'react';
import Icon from '@components/Icon';
import { getWidgetGroupMap } from '@core/canvas/widgetGroupMap';
import ThumbnailWidgetWrapper from './ThumbnailWidgetWrapper';
import styles from './WidgetGallery.scss';
import { useSelector } from '@src/redux/useSelector';
import ThumbnailWidgetGroupWrapper from './ThumbnailWidgetGroupWrapper';
import { dispatch } from '@src/redux/store';
import { setSelectionBox } from '@src/redux/dashboardEditor';

function WidgetGallery() {
  const carouselRef = useRef(null);
  const clipboardWidget = useSelector((store) => store.dashboardGallery.clipboardWidget);
  const [showHorizontalScroll, setShowHorizontalScroll] = useState(window.innerWidth < 1700);
  const selectionBox = useSelector((state) => state.dashboardEditor.selectionBox);

  const scrollBy = (number) => {
    const isEdge = !!window['StyleMedia'];
    if (carouselRef.current) {
      if (isEdge) {
        carouselRef.current.scrollLeft = carouselRef.current.scrollLeft + number;
      } else {
        carouselRef.current.scrollBy({
          top: 0,
          left: number,
          behavior: 'smooth',
        });
      }
    }
  };

  const goLeft = () => {
    scrollBy(-120);
  };

  const goRight = () => {
    scrollBy(120);
  };

  const handleResize = () => {
    window.innerWidth < 1700 ? setShowHorizontalScroll(true) : setShowHorizontalScroll(false);
  };

  window.addEventListener('resize', handleResize);

  function onMouseUp() {
    dispatch(setSelectionBox(false));
  }

  return (
    <div className={styles.widgetGallery} onMouseUp={onMouseUp}>
      <div
        className={styles.copyWidget}
        style={selectionBox ? { pointerEvents: 'none' } : undefined}>
        {
          <ThumbnailWidgetWrapper
            widgetType={clipboardWidget ? clipboardWidget.type : 'clipboard'}
            copy
            theme="Blue"
            isRegularWidget={false}
          />
        }
      </div>
      <div
        className={styles.filtersWidget}
        style={selectionBox ? { pointerEvents: 'none' } : undefined}>
        <ThumbnailWidgetWrapper widgetType={'userFilters'} isRegularWidget={false} />
      </div>
      {showHorizontalScroll && (
        <div className={styles.shadowBoxLeft}>
          <Icon
            type="leftArrow"
            color={'var(--systemFont)'}
            onClick={goLeft}
            className={styles.arrow}
          />
        </div>
      )}
      <div className={styles.wrapper} ref={carouselRef}>
        {getWidgetGroupMap().map((item, idx) => {
          const widgets = item.widgets;
          !widgets.some((w) => w.id === 'empty') && widgets.splice(0, 0, { id: 'empty' });
          return (
            <ThumbnailWidgetGroupWrapper
              key={idx}
              widgetGroup={{ ...item, widgets: widgets }}
              isRegularWidget
            />
          );
        })}
      </div>
      {showHorizontalScroll && (
        <div className={styles.shadowBoxRight}>
          <Icon
            type="rightArrow"
            color={'var(--systemFont)'}
            onClick={goRight}
            className={styles.arrow}
          />
        </div>
      )}
    </div>
  );
}

export default WidgetGallery;
