import React, { useState, useEffect } from 'react';
import { compact, cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { i18nService } from '@core/i18n/I18nService';
import I18n from '@components/I18n';
import TagConfigurationRow from './TagConfigurationRow/TagConfigurationRow';
import { isNameValid, onTagConfigChanged } from '../UpdateAsset.utils';

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1px',
    // padding: 10,
    fontSize: 12,
    fontWeight: 500,
    borderTop: 'solid 1px #c2cfe0',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 0px 10px 0px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: 15,
  },
  wraning: {
    fontSize: 12,
    color: `var(--formikErrorText)`,
    fontWeight: 'bold',
    marginBottom: 8,
  },
}));

const TagsConfigurationTable = (props) => {
  const { widgetData, tagsConfiguration, setCustomizationData, section } = props;
  const classes = useStyles(props);
  const [displayNameHasError, setDisplayNameHasError] = useState(false);

  const columnCfg = compact([
    {
      style: { minWidth: '5%', width: '5%', marginRight: 10 },
    },
    {
      style: { minWidth: '16%', width: '17%' },
      headerLabel: 'create-widget-page.create-widget.step-four.tag-name',
    },
    {
      style: { minWidth: '16%', width: '18%' },
      headerLabel: 'create-widget-page.create-widget.step-four.display-name',
    },
    {
      style: { minWidth: '12%', width: '14%' },
      headerLabel: 'create-widget-page.create-widget.step-four.validate',
    },
    {
      style: { minWidth: '13%', width: '15%' },
      headerLabel: 'create-widget-page.create-widget.step-four.min',
    },
    {
      style: { minWidth: '13%', width: '15%' },
      headerLabel: 'create-widget-page.create-widget.step-four.max',
    },
    {
      style: { minWidth: '11%', width: '13%' },
      headerLabel: 'create-widget-page.create-widget.step-four.date-time',
    },
  ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) return; // No need to reorder

    const items = reorder(tagsConfiguration, result.source.index, result.destination.index);
    setCustomizationData(items, 'tagsConfiguration');
  };

  useEffect(() => {
    setDisplayNameHasError(tagsConfiguration?.some((t) => !isNameValid(t.displayName)));
  });

  return (
    <>
      {displayNameHasError && (
        <I18n
          element="div"
          className={
            classes.wraning
          }>{`create-widget-page.create-widget.step-four.update-asset.name-length-error`}</I18n>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tableTagsConfig">
          {(provided) => (
            <div
              className={classes.tableContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              <div className={classes.tableHeader}>
                {columnCfg.map((header, idx) => (
                  <div
                    title={header.headerLabel && i18nService.translate(header.headerLabel)}
                    key={idx}
                    className={classes.header}
                    style={header.style}>
                    {header.headerLabel && (
                      <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                    )}
                  </div>
                ))}
              </div>
              {tagsConfiguration &&
                tagsConfiguration.map((tagConfig, idx) => (
                  <Draggable
                    index={idx}
                    key={tagConfig.tagId}
                    draggableId={`tags_configuration_${tagConfig.tagId}`}>
                    {(provided) => (
                      <div
                        key={idx}
                        className={classes.rowContainer}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <TagConfigurationRow
                          widgetData={widgetData}
                          columnCfg={columnCfg}
                          tagConfig={tagConfig}
                          index={idx}
                          isLast={idx + 1 == tagConfig.length}
                          provided={provided}
                          tagConfigChanged={(editedTagConfig, index) =>
                            onTagConfigChanged(
                              editedTagConfig,
                              index,
                              tagsConfiguration,
                              setCustomizationData
                            )
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default TagsConfigurationTable;
