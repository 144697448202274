import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from '../DashboardThumbnail.scss';
import ThumbnailToolbar from '../ThumbnailToolbar';
import { GenericThumbnailProps } from './GenericThumbnail.interface';
import { ButtonConfig } from '../ThumbnailToolbar/ThumbnailToolbar.interface';
import I18n from '@components/I18n';
import { dashboardService } from '@core/canvas/DashboardService';

function GenericThumbnail(props: GenericThumbnailProps) {
  const { dashboard, buttonsCfg, toolbarSize } = props;

  const defaultCfg: ButtonConfig = useMemo(
    () => ({
      edit: {
        disabled: false,
        onClick: () =>
          dashboardService.dashboardCopyEdit(dashboard.id, 'edit', dashboard.status === 'DRAFT'),
      },
      copy: {
        disabled: false,
        onClick: () => dashboardService.dashboardCopyEdit(dashboard.id, 'copy'),
      },
      remove: {
        disabled: false,
        onClick: () =>
          dashboard.status === 'DRAFT'
            ? dashboardService.removeDraftDashboard(dashboard, true)
            : dashboardService.removePublishedDashboard(dashboard, true),
      },
      preview: {
        disabled: false,
        onClick: () => dashboardService.openDashboardPreview(dashboard.id, 'gallery'),
      },
    }),
    [dashboard]
  );

  return (
    <div className={styles.wrapper}>
      {dashboard.status === 'DRAFT' && (
        <div className={styles.ribbonBox}>
          <div className={classNames(styles.draftRibbon, 'ellipsis-overflow')}>
            <I18n>general.draft</I18n>
          </div>
        </div>
      )}
      <div className={styles.preview}>
        <div className={styles.dashboardPreview} />
      </div>
      <ThumbnailToolbar
        dashboard={dashboard}
        btnConfig={buttonsCfg ? buttonsCfg : defaultCfg}
        published={dashboard.status === 'PUBLISHED'}
        size={toolbarSize}
      />
    </div>
  );
}

export default GenericThumbnail;
