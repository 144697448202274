import { Operators } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import { i18nService } from '@core/i18n/I18nService';

const InitialConfig = MaterialConfig;

export const getOperators = (): Operators => {
  return {
    equal: {
      ...InitialConfig.operators.equal,
      label: '==',
      labelForFormat: '===',
    },
    not_equal: {
      ...InitialConfig.operators.not_equal,
      label: '!=',
    },
    less: {
      ...InitialConfig.operators.less,
      label: '<',
    },
    less_or_equal: {
      ...InitialConfig.operators.less_or_equal,
      label: '<=',
    },
    greater: {
      ...InitialConfig.operators.greater,
      label: '>',
    },
    greater_or_equal: {
      ...InitialConfig.operators.greater_or_equal,
      label: '>=',
    },
    starts_with: {
      ...InitialConfig.operators.starts_with,
      label: `${i18nService.translate(`events-template.edit-template.starts-with`)}`,
      spelFormatOp: (field, _op, value) => `startsWith(${field}, ${value})`,
    },
    ends_with: {
      ...InitialConfig.operators.ends_with,
      label: `${i18nService.translate(`events-template.edit-template.ends-with`)}`,
      spelFormatOp: (field, _op, value) => `endsWith(${field}, ${value})`,
    },
    between: {
      ...InitialConfig.operators.between,
      label: `${i18nService.translate(`events-template.edit-template.between`)}`,
    },
    not_between: {
      ...InitialConfig.operators.not_between,
      label: `${i18nService.translate(`events-template.edit-template.not-between`)}`,
    },
    is_null: {
      ...InitialConfig.operators.is_null,
      label: `${i18nService.translate(`events-template.edit-template.is-null`)}`,
      spelFormatOp: (field) => `isUndefined(${field})`,
    },
    is_not_null: {
      ...InitialConfig.operators.is_not_null,
      label: `${i18nService.translate(`events-template.edit-template.is-not-null`)}`,
      spelFormatOp: (field) => `!isUndefined(${field})`,
    },
  };
};
