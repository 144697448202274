import Button from '@components/Button';
import I18n from '@components/I18n';
import React from 'react';
import classNames from 'classnames';
import styles from './SubFilterButton.scss';

function SubFilterButton(props) {
  const { label, isActive, count, onClick, filter, disabled } = props;
  return (
    <Button
      disabled={disabled}
      onClick={() => onClick(filter)}
      styles={{
        marginRight: 10,
        marginBottom: 10,
        border: isActive ? '1px solid transparent' : '1px solid var(--systemButtonBackground)',
        backgroundColor: isActive ? 'var(--systemButtonBackground)' : 'transparent',
      }}>
      <div className={styles.container}>
        <I18n className={classNames(styles.label, isActive && styles.isActive)}>{label}</I18n>
        <div className={classNames(styles.count, isActive && styles.isActive)}>{count}</div>
      </div>
    </Button>
  );
}

export default SubFilterButton;
