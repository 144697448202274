import React, { useState, useEffect, useMemo } from 'react';
import Table from '@components/Table';
import { httpService } from '@http/HttpService';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';
import { useSelector } from '@redux/useSelector';
import { RoutersDataResponse, RoutersTableProps } from './routersTable.interface';
import styles from './RoutersTable.scss';
import { compact } from 'lodash';
import TextField from '@components/Table/TableFields/TextField';
import DateField from '@components/Table/TableFields/DateField';
import NumberField from '@components/Table/TableFields/NumberField';
import { modalService } from '@core/modals/ModalService';
import RemoveRouterBTN from './RemoveRouterBTN';
import RemoteAccessButton from '@components/RemoteAccessButton';
import { getFlagStatus } from '@core/ffAndPermissions';

const getColunmsCfg = (flags) =>
  compact([
    {
      Component: RemoveRouterBTN,
      type: 'staticStart',
      label: '',
      style: { width: 35 },
      isClickable: false,
    },
    {
      Component: TextField,
      label: 'routers-tab.serial-number',
      field: 'serialNumber',
      style: { cursor: 'pointer', width: '17.5%' },
      isSortable: true,
      isClickable: true,
    },
    {
      Component: TextField,
      label: 'routers-tab.model',
      field: 'model',
      style: { cursor: 'pointer', width: '15%' },
      isSortable: true,
      isClickable: true,
    },
    {
      Component: TextField,
      label: 'routers-tab.catalog-number',
      field: 'catalogNumber',
      style: { cursor: 'pointer', width: '17.5%' },
      isSortable: true,
      isClickable: true,
    },
    {
      Component: DateField,
      label: 'routers-tab.date-created',
      field: 'createdAt',
      style: { cursor: 'pointer', width: '15%' },
      isSortable: true,
      isClickable: true,
    },
    {
      Component: TextField,
      label: 'routers-tab.owner',
      field: 'owner',
      style: { cursor: 'pointer', width: '15%' },
      isSortable: true,
      isClickable: true,
    },
    {
      Component: NumberField,
      label: 'routers-tab.connected-assets',
      field: 'assetCount',
      style: { cursor: 'pointer', width: '10%' },
      isSortable: true,
      isClickable: true,
    },
    {
      Component: NumberField,
      label: 'routers-tab.connected-plcs',
      field: 'plcCount',
      style: { cursor: 'pointer', width: '10%' },
      isSortable: true,
      isClickable: true,
    },
    flags.remoteAccess && {
      Component: RemoteAccessButton,
      type: 'staticEnd',
      label: '',
      field: 'web',
      style: { width: 78 },
      props: {
        remoteAccessType: 'routerWeb',
        parentComponent: 'RoutersTable',
      },
    },
  ]);

function RoutersTable(props: RoutersTableProps) {
  const routersData = useSelector((state) => state.routers.routersData);
  const refresh = useSelector((state) => state.routers.refresh);
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );
  const userOrganizationId = useSelector((state: any) => state.login.userOrganizationId);
  const colunmsCfg = useMemo(() => {
    // Fun with flags
    return getColunmsCfg({
      remoteAccess:
        getFlagStatus('assets.remote-access-buttons') &&
        (!selectedOrganizationsId || selectedOrganizationsId === +userOrganizationId),
    });
  }, []);

  const pageSize = useMemo(() => 50, []);

  const [nextPage, setNextPage] = useState(1);
  const [disabledLoad, setDisableLoad] = useState(false);
  const { searchText } = props;
  const defaultOrdering = { label: 'createdAt', orderSign: '-' };

  useEffect(() => getRouters(true), [searchText, selectedOrganizationsId]);

  useEffect(() => {
    if (refresh) {
      getRouters(true);
    }
  }, [refresh]);

  const getRouters = (firstInit = false, order = defaultOrdering) => {
    setDisableLoad(true);
    httpService
      .api({
        type: 'getRouters',
        query: {
          p: firstInit ? 1 : nextPage,
          ps: pageSize,
          search: searchText ? searchText : null,
          ordering: order ? `${order.orderSign}${order.label}` : null,
          subs: !selectedOrganizationsId,
        },
      })
      .then((res: RoutersDataResponse) => {
        res.next && setDisableLoad(false);
        if (firstInit) {
          routersService.setRoutersTableData({
            totalCount: res.allCount,
            resultsCount: res.count,
            routersData: res.results,
          });
          setNextPage(2);
        } else {
          routersService.addRoutersTableData({
            totalCount: res.allCount,
            resultsCount: res.count,
            routersData: res.results,
          });
          setNextPage(nextPage + 1);
        }
      });
  };

  const openEditRouterModal = ({ column, rowIndex }) => {
    modalService
      .openModal('editRouter', { rowData: routersData[rowIndex], rowIndex })
      .then((res) => {
        if (res) {
        }
      });
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={routersData}
        getData={getRouters}
        colunmsCfg={colunmsCfg}
        onCellClicked={openEditRouterModal}
        disabledLoad={disabledLoad}
        defaultOrdering={defaultOrdering}
      />
    </div>
  );
}

export default RoutersTable;
