import React from 'react';
import MaterialCheckbox from '@components/Checkbox';

import styles from '../../StepFour.scss';
import I18n from '@components/I18n';

const ExportItem = props => {
  const { onSelectedExportFormat, isExportFormatChecked, label, name } = props;
  return (
    <div className={styles.exportItem}>
      <MaterialCheckbox
        color="primary"
        onChange={() => onSelectedExportFormat(name)}
        checked={isExportFormatChecked(name)}
      />
      <I18n className={styles.text}>{`create-widget-page.create-widget.step-four.${label}`}</I18n>
    </div>
  );
};

export default ExportItem;
