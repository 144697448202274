import React, { useState } from 'react';
import classNames from 'classnames';
import { compact } from 'lodash';

// Style
import styles from './EditLicensePlan.scss';

// 3rd party
import Icon from '@components/Icon';
import Select from '@components/Select';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
const enterpriseLicenseId = 5;

function EditLicensePlan(props) {
  const {
    onCancelEditClicked,
    assetId,
    licences,
    selectedLicensePlan,
    assetTypeName,
    setRemoteAccessMode,
  } = props;
  const [selectedLicense, setSelectedLicensePlan] = useState(selectedLicensePlan);
  const onChange = (value) => {
    setSelectedLicensePlan(value);
  };

  const save = async (data?) => {
    try {
      const res: any = await httpService.api({
        type: 'saveSubscriptionDefaultLicenseAssetType',
        urlParams: { assetTypeId: assetId },
        data: { licenceId: selectedLicense.id, excludeManual: data?.excludeManual },
      });
      if (res) {
        selectedLicense.name === 'Remote Access'
          ? setRemoteAccessMode(true)
          : setRemoteAccessMode(false);
        onCancelEditClicked(true, { defaultLicenceId: res.id });
      }
    } catch (e) {}
  };

  const onSave = async () => {
    try {
      const data: any = await httpService.api({
        type: 'getSubscriptionDependencyOnAssetType',
        urlParams: { assetTypeId: assetId },
        data: { licenceId: selectedLicense.id },
      });
      if (
        selectedLicense?.id == enterpriseLicenseId ||
        (!data?.incompatibleSubscriptions &&
          !data?.manuallyUpdatedAssets &&
          selectedLicense.name !== 'Remote Access')
      ) {
        save();
      } else {
        const { incompatibleSubscriptions, manuallyUpdatedAssets } = data;
        const text =
          selectedLicense.name === 'Remote Access'
            ? 'asset-type-subscription.license-plan.switch-to-remote-access-message-line1'
            : `asset-type-subscription.license-plan.${
                incompatibleSubscriptions ? `incompatible` : 'manually-updated-assets'
              }${
                incompatibleSubscriptions === 1 ||
                (!incompatibleSubscriptions && manuallyUpdatedAssets === 1)
                  ? '-single'
                  : ''
              }`;
        const secondText =
          selectedLicense.name === 'Remote Access'
            ? manuallyUpdatedAssets === 0
              ? 'asset-type-subscription.license-plan.switch-to-remote-access-message-line2-all'
              : 'asset-type-subscription.license-plan.switch-to-remote-access-message-line2-manually'
            : incompatibleSubscriptions &&
              manuallyUpdatedAssets &&
              `asset-type-subscription.license-plan.manually-updated-assets-extra${
                manuallyUpdatedAssets === 1 ? '-single' : ''
              }`;
        modalService.openConfirmWithMultiButtons(
          {
            text,
            headerText:
              selectedLicense.name === 'Remote Access'
                ? 'asset-type-subscription.license-plan.switch-to-remote-access-header'
                : 'asset-type-subscription.license-plan.header',
            secondText,
            extraText:
              selectedLicense.name === 'Remote Access'
                ? 'asset-type-subscription.license-plan.switch-to-remote-access-message-line2-all'
                : null,
            iconType: 'attention_image',
            showCloseBtn: true,
            multiButtons: compact([
              {
                text: 'general.confirm',
                dismissModal: true,
                onClick: save,
              },
              manuallyUpdatedAssets && {
                text: 'general.exclude-manual-changes',
                dismissModal: true,
                onClick: () => save({ excludeManual: true }),
              },
            ]),
          },
          { incompatibleSubscriptions, manuallyUpdatedAssets, assetTypeName }
        );
      }
    } catch (e) {}
  };

  return (
    <div className={styles.editMode}>
      <Select
        className={styles.ownerSelect}
        label="edit-router-modal.router-owner"
        name="owner"
        defaultValue={selectedLicense}
        options={licences}
        hideLabel={true}
        getOptionLabel={(opt) => opt.name}
        getOptionValue={(opt) => opt.id}
        onChange={onChange}
      />
      <Icon
        type="save"
        disabled={selectedLicensePlan?.id === selectedLicense?.id}
        className={classNames('pointer', styles.icon)}
        onClick={() => onSave()}
      />
      <Icon
        type="remove"
        className={classNames('pointer', styles.icon)}
        onClick={() => {
          onCancelEditClicked();
        }}
      />
    </div>
  );
}

export default EditLicensePlan;
