import { ApiMap } from './http.interface';
import * as mockData from './mockData';

const prefix = '/api/p/org/<%= organizationId %>';

export const apiMap: ApiMap = {
  login: {
    url: '/api/p/login/',
    method: 'post',
  },
  activateUserIfAcceptedTerms: {
    url: '/api/p/user/activate/',
    method: 'post',
  },
  userEmailValidate: {
    url: `${prefix}/user/email/validate/`,
    method: 'post',
  },
  resetPasswordRequest: {
    url: `${prefix}/password/reset/send/`,
    method: 'post',
  },
  setPasswordReset: {
    url: `/api/p/password/reset/confirm/`,
    method: 'post',
  },
  forgotPassword: {
    url: `/api/p/password/reset/forgot/`,
    method: 'post',
  },
  firstLoginSetPassword: {
    url: `/api/p/auth/passwithtoken/`,
    method: 'post',
  },
  EmailVerification: {
    url: `/api/p/auth/emailtoken/verify/`,
    method: 'post',
  },
  activateOrg: {
    url: `/api/p/account/activate/`,
    method: 'post',
  },
  getAccounts: {
    url: '/api/p/accounts/',
    method: 'get',
  },
  refreshToken: {
    url: '/auth/p/refresh-token/',
    method: 'post',
    noAuthHeader: true,
  },
  // TODO: add the 'api/p/org/<%= organizationId %>' prefix to the api method
  validatePlc: {
    url: `${prefix}/plc/validate/`,
    method: 'post',
  },
  generatePlcSerial: {
    url: `${prefix}/plc/serial/`,
    method: 'post',
  },
  createNonUnitronicsPlc: {
    url: `${prefix}/plc/create/`,
    method: 'post',
  },
  validateNewAssetPlc: {
    url: `${prefix}/asset/plc/assign/`,
    method: 'post',
  },
  getAssetTypeOptions: {
    url: `${prefix}/asset/types/`,
    method: 'get',
  },
  createAsset: {
    url: `${prefix}/asset/`,
    method: 'put',
    hasPermission: { key: 'DEVICE', type: 'EDIT' },
  },
  publishDashboard: {
    url: `${prefix}/dashboard/<%= id %>/publish/`,
    method: 'post',
    hasPermission: { key: 'DASHBOARD', type: 'EDIT' },
  },
  editDashboard: {
    url: `${prefix}/dashboard/<%= id %>/edit/`,
    method: 'post',
    hasPermission: { key: 'DASHBOARD', type: 'EDIT' },
  },
  copyDashboard: {
    url: `${prefix}/dashboard/<%= id %>/copy/`,
    method: 'post',
    hasPermission: { key: 'DASHBOARD', type: 'EDIT' },
  },
  getAllDashboards: {
    url: `${prefix}/dashboards?p=<%= p %>&ps=<%= ps %>`,
    method: 'get',
    hasPermission: { key: 'DASHBOARD', type: 'VIEW' },
  },
  getAllPublishDashboard: {
    url: `${prefix}/dashboard/list/?p=<%= p %>&ps=<%= ps %>`,
    method: 'get',
    hasPermission: { key: 'DASHBOARD', type: 'VIEW' },
  },
  getDashboard: {
    url: `${prefix}/dashboard/<%= id %>/data/`,
    method: 'post',
    hasPermission: { key: 'DASHBOARD', type: 'VIEW' },
  },
  updatesDashboardStates: {
    url: `${prefix}/dashboard/<%= id %>/states/`,
    method: 'post',
    hasPermission: { key: 'DASHBOARD', type: 'EDIT' },
  },
  getLiveDashboardData: {
    url: `${prefix}/dashboard/ref/<%= id %>/data/`,
    method: 'post',
    hasPermission: { key: 'DASHBOARD', type: 'VIEW' },
  },
  setDashboard: {
    url: `${prefix}/dashboard/<%= id %>/`,
    method: 'post',
  },
  createDashboard: {
    url: `${prefix}/dashboard/`,
    method: 'put',
    hasPermission: { key: 'DASHBOARD', type: 'EDIT' },
  },
  updateDashboard: {
    url: `${prefix}/dashboard/<%= id %>/state/`,
    method: 'post',
  },
  updateDashboardFilters: {
    url: `${prefix}/dashboard/<%= id %>/filters/`,
    method: 'post',
  },
  setDefaultDashboard: {
    url: `/api/p/dashboard/<%= dashboardId %>/setAsDefault/`,
    method: 'post',
  },
  verifyRemoveDashboard: {
    url: `${prefix}/dashboard/<%= dashboardId %>/delete/verify/`,
    method: 'post',
  },
  removeDashboard: {
    url: `${prefix}/dashboard/<%= dashboardId %>/delete/`,
    method: 'post',
  },
  updateDashboardGalleryView: {
    url: `/api/p/user/dashboardGalleryView/`,
    method: 'post',
  },
  getAssets: {
    url: `${prefix}/asset/list/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getLastSearches: {
    url: `${prefix}/searches/`,
    method: 'get',
  },
  getAssetTypes: {
    url: `${prefix}/asset/type/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getAvailableTagTypes: {
    url: `/api/p/tag/<%=tagId%>/available/types?search=<%=search%>&p=<%=p%>&ps=<%=ps%>/`,
    method: 'get',
  },
  removeTagType: {
    url: `${prefix}/tag/<%=tagId%>/delete/type/`,
    method: 'post',
  },
  assignTagType: {
    url: `${prefix}/tag/<%=tagId%>/assign/type/`,
    method: 'post',
  },
  getTagTypeAssignmentStatus: {
    url: '/api/p/tag/types/status/',
    method: 'post',
  },
  getAssetInfo: {
    url: `${prefix}/asset/<%= assetId %>/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getAssetTypesLabels: {
    url: `${prefix}/asset/type/label/`,
    method: 'get',
  },
  saveAssetTypeLabel: {
    url: `${prefix}/asset/type/<%= assetTypeId %>/label/`,
    method: 'post',
  },
  updateAssetType: {
    url: `${prefix}/asset/type/<%= assetTypeId %>/`,
    method: 'post',
  },
  getAssetTypeVariablesByOrg: {
    url: `${prefix}/variables/list/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getAssetVariablesByOrg: {
    url: `${prefix}/asset-variables/list/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getVariableValuesPerAsset: {
    url: `/api/p/asset-variables/<%= id %>/list/`,
    method: 'get',
  },
  getAssetTypeVariablesList: {
    url: `/api/p/asset-type/<%= id %>/variables/list/`,
    method: 'get',
  },
  getAssetVariablesList: {
    url: `/api/p/asset/<%= id %>/variables/list/`,
    method: 'get',
  },
  editAssetTypeVariableSettings: {
    url: `/api/p/asset-type/<%= assetTypeId %>/variables/<%= variableId %>/`,
    method: 'put',
  },
  addAssetTypeVariable: {
    url: `/api/p/asset-type/<%= assetTypeId %>/variables/`,
    method: 'post',
  },
  validateVariableName: {
    url: `/api/p/asset-type/<%= assetTypeId %>/variables/name/validate/`,
    method: 'post',
  },
  validateVariableAllSameValue: {
    url: `/api/p/asset-type/<%= assetTypeId %>/variables/<%= variableId %>/value-changed/`,
    method: 'get',
  },
  updateAssetTypeVariable: {
    url: `/api/p/asset-type/<%= assetTypeId %>/variables/<%= variableId %>/value/`,
    method: 'put',
  },
  updateAssetVariable: {
    url: `/api/p/asset/<%= assetId %>/variables/<%= variableId %>/`,
    method: 'put',
  },
  resetAssetVariableValue: {
    url: `/api/p/asset/<%= assetId %>/variables/<%= variableId %>/reset/`,
    method: 'post',
  },
  deleteVariable: {
    url: `/api/p/variables/<%= variableId %>/`,
    method: 'delete',
  },
  getVariableInUse: {
    url: `/api/p/variables/<%= variableId %>/in-use/`,
    method: 'get',
  },
  getPLCs: {
    url: `${prefix}/plc/list/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  createPLC: {
    url: `${prefix}/plc/`,
    method: 'post',
  },
  getRouters: {
    url: `${prefix}/router/list/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
    // mock: mockData.getRouters
  },
  getRouter: {
    url: `${prefix}/router/<%= routerId %>/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getRouterAssets: {
    url: `${prefix}/router/<%= routerId %>/assets/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getRouterPLCs: {
    url: `${prefix}/router/<%= routerId %>/plcs/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  createRouter: {
    url: `${prefix}/router/<%= routerId %>/`,
    method: 'post',
    // mock: mockData.createRouter
  },
  removeRouter: {
    url: `${prefix}/router/<%= routerId %>/disconnect/`,
    method: 'post',
  },
  updateRouter: {
    url: `${prefix}/router/<%= routerId %>/`,
    method: 'put',
  },
  validateRouter: {
    url: `${prefix}/router/validate/`,
    method: 'post',
    // mock: mockData.validateRouter
  },
  validatePLCforRouter: {
    url: `${prefix}/router/<%= routerId %>/plc/`,
    method: 'post',
  },
  getAssetCertificate: {
    url: `${prefix}/asset/<%= assetId %>/certificate/`,
    method: 'get',
  },
  getRouterCertificate: {
    url: `${prefix}/router/<%= routerId %>/certificate/`,
    method: 'get',
  },
  getPlcCertificate: {
    url: `${prefix}/plc/<%= plcId %>/certificate/`,
    method: 'get',
  },
  getOwners: {
    url: `${prefix}/subs/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  getAssetTypeInfo: {
    url: `${prefix}/asset/type/<%=assetTypeId%>/`,
    method: 'get',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  saveGeneralAsset: {
    url: `${prefix}/asset/<%= assetId %>/`,
    method: 'post',
  },
  saveAssetTypeChanges: {
    url: `${prefix}/asset/type/<%= aseetTypeid %>/`,
    method: 'post',
  },
  connect: {
    url: `${prefix}/asset/<%= assetId %>/connect/`,
    method: 'post',
  },
  disconnect: {
    url: `${prefix}/asset/<%= assetId %>/disconnect/`,
    method: 'post',
  },
  activate: {
    url: `${prefix}/asset/<%= assetId %>/activate/`,
    method: 'post',
  },
  archive: {
    url: `${prefix}/asset/<%= assetId %>/archive/`,
    method: 'post',
  },
  getAssetAvaliableSubscriptions: {
    url: `${prefix}/asset/<%= assetId %>/available/subscriptions/`,
    method: 'get',
  },
  assignSubscriptionToAsset: {
    url: `${prefix}/asset/<%= assetId %>/subscription/assign/`,
    method: 'post',
  },
  removeSubscriptionFromAsset: {
    url: `${prefix}/asset/<%= assetId %>/subscription/<%= subscriptionId %>/`,
    method: 'delete',
  },
  getAssetTypeOptionsInfo: {
    url: `${prefix}/asset/type/info/`,
    method: 'get',
    hasPermission: [
      { key: 'DEVICE', type: 'VIEW' },
      { key: 'AR', type: 'EDIT' },
    ],
  },
  getAssetProperties: {
    url: `/api/p/asset/properties/meta/`,
    method: 'get',
  },
  getAlarmsInfos: {
    url: `/api/p/alarm/infos/meta/`,
    method: 'get',
  },
  getAssetListBaseDetails: {
    url: `/api/p/asset/baseDetails/`,
    method: 'get',
  },
  getAssetBaseDetails: {
    url: `/api/p/asset/<%= assetId %>/baseDetails/`,
    method: 'get',
  },
  getAssetTags: {
    url: `${prefix}/asset/type/<%= assetId %>/tags/meta/`,
    method: 'get',
  },
  getAssetTagTypes: {
    url: `${prefix}/asset/types/tag/types/meta/`,
    method: 'get',
  },
  getAssetTypeVariables: {
    url: `/api/p/asset-type/<%= assetTypeId %>/variables/meta/`,
    method: 'get',
  },
  getWidget: {
    url: `${prefix}/widget/<%= widgetId %>/`,
    method: 'get',
  },
  createWidget: {
    url: `/api/p/widget/`,
    method: 'post',
  },
  updateWidget: {
    url: `/api/p/widget/<%= widgetId %>/`,
    method: 'put',
  },
  copyWidgetValidation: {
    url: `/api/p/widget/canPaste/`,
    method: 'post',
  },
  updateWidgetState: {
    url: `${prefix}/widget/<%= widgetId %>/state/`,
    method: 'put',
  },
  getWidgetDefaultName: {
    url: `/api/p/dashboard/<%= dashboardId %>/widget/defaultName/`,
    method: 'get',
  },
  getFilterAutocomplete: {
    url: `${prefix}/widget/filter/autocomplete/`,
    method: 'get',
  },
  getTagsData: {
    url: `${prefix}/asset/<%= assetId %>/tags/data/`,
    method: 'get',
  },
  getAlarms: {
    url: `${prefix}/asset/<%= assetId %>/alarms/data/`,
    method: 'get',
  },
  getLastUpdated: {
    url: `${prefix}/asset/<%= assetId %>/lastupdate/`,
    method: 'get',
  },
  getAssetData: {
    url: `${prefix}/asset/<%= assetId %>/data/`,
    method: 'get',
  },
  getWidgetData: {
    url: `${prefix}/widget/<%= widgetId %>/data/`,
    method: 'post',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getWidgetPreviewData: {
    url: `${prefix}/widget/preview/`,
    method: 'post',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getMapData: {
    // 64 grid size
    url: `${prefix}/widget/<%= widgetId %>/map/data/<%= zoom %>/64/`,
    method: 'post',
    hasPermission: { key: 'DEVICE', type: 'VIEW' },
  },
  getMapPreviewData: {
    // 64 grid size
    url: `${prefix}/widget/map/preview/<%= zoom %>/64/`,
    method: 'post',
  },
  getMarkerData: {
    // 64 grid size
    url: `${prefix}/widget/<%= widgetId %>/map/data/getAreaContent/<%= zoom %>/64/`,
    method: 'post',
  },
  getMarkerPreviewData: {
    // 64 grid size
    url: `${prefix}/widget/map/preview/getAreaContent/<%= zoom %>/64/`,
    method: 'post',
  },
  saveSubscriptionAsset: {
    url: `/api/p/asset/<%= assetId %>/timers/`,
    method: 'post',
  },
  saveSubscriptionDefaultLicenseAsset: {
    url: `/api/p/asset/<%= assetId %>/subscription/default/`,
    method: 'post',
  },
  getTimersStatus: {
    url: `/api/p/asset/type/<%= assetTypeId %>/timers/status`,
    method: 'get',
  },
  getAssetSubscriptions: {
    url: `${prefix}/asset/<%= assetId %>/subscriptions/`,
    method: 'get',
  },
  getAssetSubscription: {
    url: `${prefix}/asset/<%= assetId %>/subscription/`,
    method: 'get',
  },
  saveSubscriptionAssetType: {
    url: `/api/p/asset/type/<%= assetTypeId %>/timers/`,
    method: 'post',
  },
  getSubscriptionDependencyOnAssetType: {
    url: `/api/p/asset/type/<%= assetTypeId %>/subscription/dependency/`,
    method: 'post',
  },
  saveSubscriptionDefaultLicenseAssetType: {
    url: `/api/p/asset/type/<%= assetTypeId %>/subscription/default/`,
    method: 'post',
  },
  deleteAssetTypeLabel: {
    url: `${prefix}/asset/type/<%= assetTypeId %>/label/`,
    method: 'delete',
  },
  getAssetTypeSubscription: {
    url: `${prefix}/asset/type/<%= assetTypeId %>/subscription/`,
    method: 'get',
  },
  exportWidgetData: {
    url: `${prefix}/widget/<%= widgetId %>/export/`,
    method: 'post',
  },
  getJobs: {
    url: `${prefix}/jobs/status/`,
    method: 'get',
  },
  downloadFile: {
    url: `${prefix}/download/`,
    method: 'get',
  },
  deleteWidget: {
    url: `/api/p/widget/<%= widgetId %>/`,
    method: 'delete',
  },
  signUpEmailValidation: {
    url: `/api/p/org/registration/email/validate/`,
    method: 'post',
  },
  sendMaintenanceNotificationEmails: {
    url: `/api/p/send-maintenance-notification-email/`,
    method: 'post',
  },
  emailCompanyValidation: {
    url: `/api/p/validate/email/`,
    method: 'post',
  },
  newOrganization: {
    url: `${prefix}/`,
    method: 'put',
  },
  getUsers: {
    url: `${prefix}/users/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  getOrganizations: {
    url: `${prefix}/subs/hierarchy`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  getParentOrgHierarchyByName: {
    url: `${prefix}/search-organization-name/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  getOrganizationDetails: {
    url: `${prefix}/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  getOrganizationUsers: {
    url: `${prefix}/users/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  editUserValidateEmail: {
    url: `${prefix}/user/email/edit/validate/`,
    method: 'post',
  },
  getUserDetails: {
    url: `${prefix}/user/<%= userId %>/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  updateUserDetails: {
    url: `${prefix}/user/<%= userId %>/`,
    method: 'put',
  },
  archiveUser: {
    url: `${prefix}/user/<%= userId %>/archive/`,
    method: 'post',
  },
  activateUser: {
    url: `${prefix}/user/<%= userId %>/activate/`,
    method: 'post',
  },
  suspendUser: {
    url: `${prefix}/user/<%= userId %>/suspend/`,
    method: 'post',
  },
  resendUserInvitaion: {
    url: `${prefix}/user/<%= userId %>/email/resend/`,
    method: 'post',
  },
  deleteUser: {
    url: `${prefix}/user/<%= userId %>/`,
    method: 'delete',
  },
  getParentsAvailable: {
    url: `/api/p/tenant/<%= tenantId %>/org/<%= organizationId %>/available/parents/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  createUser: {
    url: `${prefix}/user/`,
    method: 'post',
  },
  activeOrg: {
    url: `${prefix}/activate/`,
    method: 'post',
  },
  suspendedOrg: {
    url: `${prefix}/suspend/`,
    method: 'post',
  },
  archivedOrg: {
    url: `${prefix}/archive/`,
    method: 'post',
  },
  canDeleteOrg: {
    url: `/api/p/org/<%= organizationId %>/canDelete/`,
    method: 'post',
  },
  deleteOrg: {
    url: `/api/p/org/<%= organizationId %>/`,
    method: 'delete',
  },
  assignUserToOrg: {
    url: `${prefix}/user/<%= userId %>/assign/`,
    method: 'post',
  },
  editOrganization: {
    url: `${prefix}/`,
    method: 'post',
  },
  getUserConfig: {
    url: `/api/p/user/config/`,
    method: 'get',
  },
  updateDashboardRefreshRate: {
    url: `/api/p/mb/config/`,
    method: 'post',
  },
  getUserConfigForUnitronicsAdmin: {
    url: `/api/p/org/<%= organizationId %>/user/config/`,
    method: 'get',
  },
  updateUserConfig: {
    url: `/api/p/user/language/`,
    method: 'post',
  },
  getRoles: {
    url: `${prefix}/roles/`,
    method: 'get',
  },
  registrationOrg: {
    url: `/api/p/org/registration/`,
    method: 'post',
  },
  getAdminUsers: {
    url: `${prefix}/admins/users/`,
    method: 'get',
  },
  getMyProfile: {
    url: `/api/p/account/`,
    method: 'get',
  },
  unsubscribeNotification: {
    url: `/api/p/account/notification/`,
    method: 'post',
  },
  updateMyProfile: {
    url: `/api/p/account/`,
    method: 'put',
  },
  getCompanyDetails: {
    url: `/api/p/account/company/details/`,
    method: 'get',
    hasPermission: { key: 'ORGANIZATION', type: 'VIEW' },
  },
  defaultLanguage: {
    url: `/api/p/language/<%= languageId %>/default/`,
    method: 'post',
  },
  publishLanguage: {
    url: `/api/p/language/<%= languageId %>/publish/`,
    method: 'post',
  },
  unPublishLanguage: {
    url: `/api/p/language/<%= languageId %>/unpublish/`,
    method: 'post',
  },
  previewLanguage: {
    url: `/api/p/language/<%= languageId %>/preview/`,
    method: 'post',
  },
  unPreviewLanguage: {
    url: `/api/p/language/<%= languageId %>/unpreview/`,
    method: 'post',
  },
  uploadFile: {
    url: `/api/p/language/upload/`,
    method: 'post',
  },
  uploadFileResult: {
    url: `/api/p/language/upload/result/<%= taskId %>/`,
    method: 'get',
  },
  exportLanguage: {
    url: `/api/p/language/<%= languageId %>/export/`,
    method: 'post',
  },
  getExportLanguageResults: {
    url: `/api/p/language/job/<%= jobId %>/status/`,
    method: 'get',
  },
  autoPublish: {
    url: `/api/p/language/autoPublish/`,
    method: 'post',
  },
  getLanguageById: {
    url: '/api/p/language/<%= languageId %>/terms/',
    method: 'get',
    isTenantIdRequired: true,
  },
  getLanguageByIdForUnitronicsAdmin: {
    url: '/api/p/org/<%= organizationId %>/language/<%= languageId %>/terms/',
    method: 'get',
    hasPermission: { key: 'LANGUAGE', type: 'VIEW' },
  },

  getFlags: {
    url: '/api/p/language/flags/',
    method: 'get',
    hasPermission: { key: 'LANGUAGE', type: 'VIEW' },
  },
  createLanguage: {
    url: '/api/p/language/',
    method: 'post',
  },
  editLanguage: {
    url: '/api/p/language/<%= languageId %>/',
    method: 'post',
  },
  updateLanguage: {
    url: '/api/p/language/<%= languageId %>/',
    method: 'post',
  },
  validateLanguageName: {
    url: '/api/p/language/name/validate/',
    method: 'post',
  },
  publishAll: {
    url: `/api/p/language/publishAll/`,
    method: 'post',
  },
  getListLanguages: {
    url: `/api/p/language/list/`,
    method: 'get',
    hasPermission: { key: 'LANGUAGE', type: 'VIEW' },
  },
  getOrganizationDefaultLanguage: {
    url: `${prefix}/language/published/`,
    method: 'get',
  },
  getAssetTypeIdByAssetId: {
    url: `${prefix}/asset/<%= asset_id %>/assetTypeId/`,
    method: 'get',
  },
  disconnectPlc: {
    url: `${prefix}/plc/<%=plcId %>/disconnect/`,
    method: 'post',
  },
  disconnectPlcFromAsset: {
    url: `${prefix}/asset/<%=assetId %>/disconnect/plc/`,
    method: 'post',
  },
  disconnectPlcFromRouter: {
    url: `${prefix}/router/<%= routerId %>/plc/<%= plcId %>/disconnect/`,
    method: 'post',
  },
  disconnectRouterFromAsset: {
    url: `${prefix}/asset/<%= assetId %>/disconnect/router/`,
    method: 'post',
  },
  getDisplaySettings: {
    url: `${prefix}/white/labeling/`,
    method: 'get',
  },
  updateDisplaySettings: {
    url: `${prefix}/white/labeling/`,
    method: 'post',
  },
  setDisplaySettingAction: {
    url: `${prefix}/white/labeling/action/`,
    method: 'post',
  },
  validateSubDomain: {
    url: `${prefix}/subdomain/validate/`,
    method: 'post',
  },
  addSubdomain: {
    url: `${prefix}/subdomain/`,
    method: 'post',
  },
  deleteSubdomain: {
    url: `${prefix}/subdomain/`,
    method: 'delete',
  },
  uploadLogo: {
    url: `${prefix}/white/labeling/logo/`,
    method: 'post',
  },
  uploadLogoResult: {
    url: `${prefix}/white/labeling/logo/result/<%= taskId %>/`,
    method: 'get',
  },
  createDefaulDashboard: {
    url: `${prefix}/dashboard/default/copy/`,
    method: 'post',
  },
  getTagsInfo: {
    url: `${prefix}/tag/<%=tagId%>/usage/`,
    method: 'get',
  },
  uploadImage: {
    url: `${prefix}/widget/<%= widgetId %>/upload/`,
    method: 'post',
  },
  getForgottenUsers: {
    url: '/api/p/users/forgotten/',
    method: 'get',
  },
  validateUserCanBeForgotten: {
    url: '/api/p/user/forget/validate/',
    method: 'post',
  },
  forgetUser: {
    url: '/api/p/user/forget/',
    method: 'post',
  },
  getUnauthenticatedConfig: {
    url: `/api/p/unauthenticated/config/`,
    method: 'get',
  },
  downloadUsersCsv: {
    url: `/api/p/downloadUsersCsv/`,
    method: 'post',
  },
  getTimeseriesAssetsAndMetricsListPreview: {
    url: `${prefix}/widget/timeseries/assets/preview/`,
    method: 'post',
  },
  getTimeseriesAssetsAndMetricsList: {
    url: `${prefix}/widget/<%= widgetId %>/timeseries/assets/data/`,
    method: 'post',
  },
  getTimeseriesDataPreview: {
    url: `${prefix}/widget/timeseries/preview/`,
    method: 'post',
  },
  getTimeseriesData: {
    url: `${prefix}/widget/<%= widgetId %>/timeseries/data/`,
    method: 'post',
  },
  getHeatmapDataPreview: {
    url: `${prefix}/widget/heatmap/preview/`,
    method: 'post',
  },
  getHeatmapData: {
    url: `${prefix}/widget/<%= widgetId %>/heatmap/data/`,
    method: 'post',
  },
  getHeatmapSeries: {
    url: `${prefix}/widget/<%= widgetId %>/heatmap/series/`,
    method: 'post',
  },
  getRemoteVNC: {
    url: `${prefix}/plc/<%= plcId %>/remote/vnc/`,
    method: 'get',
  },
  getRemoteWeb: {
    url: `${prefix}/plc/<%= plcId %>/remote/web/`,
    method: 'get',
  },
  getRemoteVPN: {
    url: `${prefix}/plc/<%= plcId %>/remote/vpn/`,
    method: 'get',
  },
  getClientRemoteVPN: {
    url: `/api/p/remote/vpn/client/`,
    method: 'get',
  },
  getClientRemoteHMI: {
    url: `/api/p/remote/hmi/client/`,
    method: 'get',
  },
  getRouterRemoteWeb: {
    url: `${prefix}/router/<%= routerId %>/remote/web/`,
    method: 'get',
  },
  getSubscriptionsPlansSummary: {
    url: `${prefix}/subscription/sum`,
    method: 'get',
  },
  getSubscriptionsPlansSummarByType: {
    url: `${prefix}/subscription/sum_by_type`,
    method: 'get',
  },
  getSubscriptionList: {
    url: `${prefix}/subscription/list`,
    method: 'get',
  },
  getOrganizationSubscriptionList: {
    url: `${prefix}/organization/subscriptions/list/`,
    method: 'get',
  },
  activateOrganizationSubscription: {
    url: `${prefix}/subscription/<%= subscription_id %>/activate`,
    method: 'post',
  },
  getLicensingPlan: {
    url: `${prefix}/licences/`,
    method: 'get',
  },
  getRemoteAccessWidgetData: {
    url: `${prefix}/asset/<%= assetId %>/remote/<%= type %>/`,
    method: 'get',
  },
  getImageGallery: {
    url: `${prefix}/gallery/`,
    method: 'get',
  },
  getImageData: {
    url: `${prefix}/gallery/<%= identifier %>/`,
    method: 'get',
  },
  uploadImageGallery: {
    url: `${prefix}/gallery/image/`,
    method: 'post',
  },
  uploadImageGalleryResult: {
    url: `${prefix}/gallery/image/result/<%= taskId %>`,
    method: 'get',
  },
  deleteGalleryImageValidation: {
    url: `${prefix}/gallery/<%= identifier %>/delete/verify/`,
    method: 'post',
  },
  deleteGalleryImage: {
    url: `${prefix}/gallery/<%= identifier %>/delete/`,
    method: 'delete',
  },
  getConditionalValueIcons: {
    url: `/api/p/system/icons/`,
    method: 'get',
  },
  getConditioanlValueIcon: {
    url: `/api/p/system/icon/<%= iconId %>/`,
    method: 'get',
  },
  isGalleryImagesExistValidation: {
    url: `${prefix}/gallery/images/exists/`,
    method: 'post',
  },
  acceptDisclaimer: {
    url: `/api/p/user/disclaimer/accept/`,
    method: 'post',
  },
  updateAssetTags: {
    url: `${prefix}/widget/<%= widgetId %>/asset/<%= assetId %>/update/tags/`,
    method: 'post',
  },
  getAssetsMatchingSubscriptionId: {
    url: `${prefix}/subscription/<%= licenseId %>/asset/list/`,
    method: 'get',
  },
  assignSubscriptionByItToAsset: {
    url: `${prefix}/subscription/<%= subscriptionId %>/asset/<%= assetId %>/assign/`,
    method: 'post',
  },
  getAvailableSubscriptions: {
    url: `${prefix}/subscription/type/list`,
    method: 'get',
  },
  assignSubscriptionsToAssets: {
    url: `${prefix}/subscription/assign/`,
    method: 'post',
  },
  getAssetsMatchingSubscriptionTypeId: {
    url: `${prefix}/subscription/type/<%= licenseId %>/asset/list/`,
    method: 'get',
  },
  getRegionalSettingsWL: {
    url: `/api/p/account/white/labeling`,
    method: 'get',
  },
  setRegionalSettingsWL: {
    url: `/api/p/account/white/labeling`,
    method: 'post',
  },
  clearRegionalSettingsWL: {
    url: `/api/p/account/white/labeling`,
    method: 'delete',
  },
  getEventTemplateDefaultName: {
    url: `${prefix}/event-template/default-name/`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  validateEventTemplateName: {
    url: `${prefix}/event-template/name/validate/`,
    method: 'post',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  createEventTemplate: {
    url: `/api/p/event-templates/`,
    method: 'post',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  updateEventTemplate: {
    url: `/api/p/event-templates/<%= templateId %>/`,
    method: 'put',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  getEventTemplateDataSourceDefaultName: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/default-name/?type=<%= dataSourceType %>`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  validateEventTemplateDataSourceName: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/name/validate/`,
    method: 'post',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  getEventTemplateDataSources: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  getEventTemplateDataSource: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/<%= dataSourceId %>/`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'VIEW' },
  },
  createEventTemplateDataSource: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/`,
    method: 'post',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  updateEventTemplateDataSource: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/<%= dataSourceId %>/`,
    method: 'put',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  deleteEventTemplateDataSource: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/<%= dataSourceId %>/`,
    method: 'delete',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  copyEventTemplateDataSource: {
    url: `/api/p/event-templates/<%= templateId %>/data-sources/<%= dataSourceId %>/copy/`,
    method: 'post',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  getEventTemplates: {
    url: `/api/p/event-templates/`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'VIEW' },
  },
  getEventTemplateData: {
    url: `/api/p/event-templates/<%= templateId %>/`,
    method: 'get',
  },
  editEventTemplate: {
    url: `/api/p/event-templates/<%= templateId %>`,
    method: 'put',
  },
  deleteEventTemplate: {
    url: `/api/p/event-templates/<%= templateId %>/`,
    method: 'delete',
  },
  copyEventTemplateData: {
    url: `/api/p/event-templates/<%= templateId %>/copy/`,
    method: 'post',
  },
  endEventTemplateData: {
    url: `/api/p/event-templates/<%= templateId %>/end/`,
    method: 'post',
  },
  activateEventTemplateData: {
    url: `/api/p/event-templates/<%= templateId %>/activate/`,
    method: 'post',
  },
  archiveEventTemplateData: {
    url: `/api/p/event-templates/<%= templateId %>/archive/`,
    method: 'post',
  },
  publishEventTemplate: {
    url: `/api/p/event-templates/<%= templateId %>/publish/`,
    method: 'post',
  },
  testEventService: {
    url: `/api/p/event-templates/<%= templateId %>/test/`,
    method: 'post',
  },
  getPublishEventTemplateStatus: {
    url: `/api/p/event-templates/job/<%= jobId %>/status/`,
    method: 'get',
  },
  saspendEventTemplate: {
    url: `/api/p/event-templates/<%= templateId %>/suspend/`,
    method: 'post',
  },
  getSystemSettings: {
    url: `/api/p/systemSettings/`,
    method: 'get',
  },
  setSystemSettings: {
    url: `/api/p/systemSettings/`,
    method: 'put',
  },
  getNotificationGroups: {
    url: `${prefix}/notificationGroup/`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  addNotificationGroup: {
    url: `${prefix}/notificationGroup/`,
    method: 'post',
    hasPermission: { key: 'EVENTS', type: 'EDIT' },
  },
  getEventsData: {
    url: `${prefix}/event/`,
    method: 'get',
    hasPermission: { key: 'EVENTS', type: 'VIEW' },
  },
  eventsExcludeAdminsGroup: {
    url: `/api/p/event/<%= eventId %>/sendToAdmin/`,
    method: 'post',
  },
  updateEventsScheduler: {
    url: `/api/p/event/<%= templateId %>/`,
    method: 'put',
  },
  getGroupMembers: {
    url: `/api/p/notificationGroup/<%= notificationGroup %>/accounts`,
    method: 'get',
  },
  addGroupMember: {
    url: `/api/p/notificationGroup/<%= notificationGroup %>/accounts/<%= accountId %>/`,
    method: 'post',
  },
  removeGroupMember: {
    url: `/api/p/notificationGroup/<%= notificationGroup %>/accounts/<%= accountId %>`,
    method: 'delete',
  },
  getFileForDownload: {
    url: `/api/p/event/file/<%= fileId %>/`,
    method: 'get',
  },
  sendSMSvertification: {
    url: `/api/p/smsVerification/send/`,
    method: 'post',
  },
  validateSMSvertification: {
    url: `/api/p/smsVerification/validate/`,
    method: 'post',
  },
  showSMSvertification: {
    url: `/api/p/smsVerification/show/`,
    method: 'post',
  },
  showSplitDashboardConfirmation: {
    url: `/api/p/splitDashboardConfirmation/show/`,
    method: 'post',
  },
  setDownShowAgain: {
    url: `/api/p/user/dontshowagain/`,
    method: 'post',
  },
  getActivitiesLogs: {
    url: `/api/p/event-logs/`,
    method: 'get',
  },
  getActivitiesLogsFilters: {
    url: `/api/p/event-logs/filters/`,
    method: 'get',
  },
  getSubscriptionsUsage: {
    url: `${prefix}/subscriptions/sms/usage/`,
    method: 'get',
  },
  queryEventTemplateConditionLength: {
    url: `/api/p/event-template/<%= templateId %>/condition/length/`,
    method: 'post',
  },
  createDailyReport: {
    url: `/api/p/dailyReport/`,
    method: 'post',
  },
  createAssetsAndPlcsReport: {
    url: `/api/p/assetsReport/`,
    method: 'post',
  },
  get2FaStatus: {
    url: `/api/p/user/2fa/status/`,
    method: 'get',
    isTenantIdRequired: true,
  },
  verify2FaCode: {
    url: `/api/p/user/2fa/code/verify/`,
    method: 'post',
    isTenantIdRequired: true,
  },
  resend2FaCode: {
    url: `/api/p/user/2fa/code/resend/`,
    method: 'post',
    isTenantIdRequired: true,
  },
  getLanguageAndWhiteLabel: {
    url: `/api/p/user/languageAndWhiteLabel/`,
    method: 'get',
    isTenantIdRequired: true,
  },
  getAssetDataForComboWidget: {
    url: `/api/p/assetWidget/`,
    method: 'get',
  },
  getNotifications: {
    url: `/api/p/notifications/`,
    method: 'get',
  },
  getNotificationDetails: {
    url: `/api/p/notification/<%= notificationId %>/`,
    method: 'get',
  },
  markNotificationRead: {
    url: `/api/p/notification/<%= notificationId %>/read/`,
    method: 'post',
  },
  exploreDataBackup: {
    url: `${prefix}/asset/<%= assetId %>/backup/explore/`,
    method: 'get',
  },
  downloadDataBackupFile: {
    url: `${prefix}/asset/<%= assetId %>/backup/download/`,
    method: 'get',
  },
  restoreFromDataBackupFile: {
    url: `${prefix}/asset/<%= assetId %>/backup/restore/`,
    method: 'post',
  },
  registerFcm: {
    url: `/api/p/account/fcm/register/`,
    method: 'post',
  },
  unregisterFcm: {
    url: `/api/p/account/fcm/unregister/`,
    method: 'post',
  },
  getAllARProjects: {
    url: `/api/p/ar/projects/`,
    method: 'get',
  },
  getARProjectByID: {
    url: `/api/p/ar/projects/<%= projectId %>/`,
    method: 'get',
  },
  getARProjectByAssetType: {
    url: `/api/p/ar/projects/asset-type/<%= assetTypeId %>/`,
    method: 'get',
  },
  assignARProjectToAsset: {
    url: `${prefix}/asset/`,
    method: 'put',
  },
  updateARProjectInAsset: {
    url: `${prefix}/asset/<%= assetId %>/`,
    method: 'post',
  },
  getNewArProjectDefaultName: {
    url: `/api/p/ar/projects/default-name/`,
    method: 'get',
    hasPermission: { key: 'AR', type: 'EDIT' },
  },
  arProjectNameValidate: {
    url: `/api/p/ar/projects/name/validate/`,
    method: 'post',
  },
  arProjectValidateUpdatedName: {
    url: `/api/p/ar/projects/<%= projectId %>/name/validate/`,
    method: 'post',
  },
  arProjectNameUpdate: {
    url: `/api/p/ar/projects/<%= projectId %>/`,
    method: 'put',
  },
  arProjectStateUpdate: {
    url: `/api/p/ar/projects/<%= projectId %>/state/`,
    method: 'put',
  },
  uploadARModelFile: {
    url: `/api/p/ar/models/upload/`,
    method: 'post',
  },
  uploadARModelFileStatus: {
    url: `/api/p/ar/models/upload/<%= taskId %>/status/`,
    method: 'get',
  },
  uploadARPreviewScreenshot: {
    url: `/api/p/ar/models/<%= ArModelID %>/preview-image/upload/`,
    method: 'post',
  },
  saveUnitsForARProject: {
    url: `/api/p/ar/models/tasks/<%= taskId %>/`,
    method: 'put',
  },
  createNewARProject: {
    url: `/api/p/ar/projects/`,
    method: 'post',
  },
  editPublishedArProject: {
    url: `/api/p/ar/projects/<%= projectId %>/edit/`,
    method: 'post',
  },
  removeArProject: {
    url: `/api/p/ar/projects/<%= projectId %>/`,
    method: 'delete',
  },
  createNewArWidget: {
    url: `/api/p/ar/projects/<%= projectId %>/widgets/`,
    method: 'post',
  },
  getArWidget: {
    url: `/api/p/ar/projects/<%= projectId %>/widgets/<%= widgetId %>/`,
    method: 'get',
  },
  getArWidgets: {
    url: `/api/p/ar/projects/<%= projectId %>/widgets/`,
    method: 'get',
  },
  updateArWidget: {
    url: `/api/p/ar/projects/<%= projectId %>/widgets/<%= widgetId %>/`,
    method: 'put',
  },
  updateArWidgetState: {
    url: `/api/p/ar/projects/<%= projectId %>/widgets/<%= widgetId %>/state/`,
    method: 'patch',
  },
  deleteArWidget: {
    url: `/api/p/ar/projects/<%= projectId %>/widgets/<%= widgetId %>/`,
    method: 'delete',
  },
  trainingArProject: {
    url: `/api/p/ar/models/<%= arModelId %>/train/`,
    method: 'post',
  },
  publishArProject: {
    url: `/api/p/ar/projects/<%= projectId %>/publish/`,
    method: 'post',
  },
};
