import moment from 'moment';

const format = 'DD/MM/YYYY';
const BEformat = 'YYYY-MM-DD';

const timeUpdate = (type, dateDifference, setDate, dateFilter) => {
  setDate({
    dateFilter,
    dateFilterFrom: moment().subtract(dateDifference, type),
    dateFilterTo: moment(),
  });
};

const setDateRange = (
  dateFilter,
  setDate,
  dateFilterFrom,
  dateFilterTo,
  prevDateFilter,
  isSameDashboard
) => {
  const newDateFilter =
    prevDateFilter && dateFilter != prevDateFilter ? prevDateFilter : dateFilter;
  switch (newDateFilter) {
    case 'DAY':
      timeUpdate(1, 'days', setDate, newDateFilter);
      break;
    case 'WEEK':
      timeUpdate(6, 'days', setDate, newDateFilter);
      break;
    case 'MONTH':
      timeUpdate(1, 'months', setDate, newDateFilter);
      break;
    case 'YEAR':
      timeUpdate(1, 'year', setDate, newDateFilter);
      break;
    case 'CUSTOM':
      setDate({
        dateFilter: newDateFilter,
        dateFilterFrom: moment(dateFilterFrom, BEformat),
        dateFilterTo: moment(dateFilterTo, BEformat),
      });
  }
};

export const updatePossibleDateRange = (
  dateFilter,
  setDate,
  dateFilterFrom,
  dateFilterTo,
  dashboardId,
  prevDashboardId,
  date,
  prevDateFilter
) => {
  //   console.log('updatePossibleDateRange');
  //   if (!!dateFilter && !date) {
  if (
    !!dateFilter &&
    (!date || (prevDashboardId && prevDashboardId !== dashboardId) || prevDateFilter != dateFilter)
  ) {
    setDateRange(
      dateFilter,
      setDate,
      dateFilterFrom,
      dateFilterTo,
      prevDateFilter,
      prevDashboardId !== dashboardId
    );
  }
};

export const getDdateFilterText = (dateFilter, dateLabelMap, dateFilterFrom, dateFilterTo) => {
  return dateFilter
    ? dateFilter !== 'CUSTOM'
      ? `${dateLabelMap[dateFilter]}`
      : `${moment(dateFilterFrom, BEformat).format(format)} - ${moment(
          dateFilterTo,
          BEformat
        ).format(format)}`
    : '';
};

export const getDdateFilterRange = (selectedDateRange) => {
  return (
    moment(selectedDateRange.dateFilterFrom, BEformat).valueOf() >
    moment(selectedDateRange.dateFilterTo, BEformat).valueOf()
  );
};
