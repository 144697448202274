import React, { useCallback } from 'react';
import Select from '@components/Select';
import { i18nService } from '@core/i18n/I18nService';

function ShowGridSelect({ showGrid, setShowGrid }) {
  const showGridOptions = [
    { label: i18nService.translate('general.yes'), value: true },
    { label: i18nService.translate('general.no'), value: false },
  ];

  const onChange = useCallback((selected) => {
    setShowGrid(selected);
  }, []);

  return (
    <Select
      styles={{ control: { height: 37 }, menuPortal: { zIndex: 9999999999999 } }}
      menuPortalTarget={document.body}
      options={showGridOptions}
      value={showGridOptions.find((opt) => opt.value === showGrid)}
      getOptionLabel={(i) => i.label}
      getOptionValue={(i) => i.value}
      onChange={(value) => onChange(value.value)}
    />
  );
}

export default ShowGridSelect;
