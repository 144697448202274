import React, { useEffect, useMemo, useState } from 'react';
import styles from './ActivitiesToolBar.scss';
import I18n from '@components/I18n';
import { ActivitiesToolBarProps, FiltersResponse } from '../ActivitiesLogTab.interface';
import ActivityFilter from './ActivityFilter';
import { i18nService } from '@core/i18n/I18nService';
import moment from 'moment';
import DatePicker from '@components/DatePicker';
import { httpService } from '@core/http/HttpService';
import classNames from 'classnames';

function ActivitiesToolBar(props: ActivitiesToolBarProps) {
  const {
    numberOfSMS = '',
    numberOfEmails = '',
    numberOfPushes = '',
    selectedDates,
    setSelectedDates,
    selectedEvents,
    setSelectedEvents,
    selectedOrganizationTypes,
    setSelectedOrganizationTypes,
    selectedOrganizatioionNames,
    setSelectedOrganizatioionNames,
    selectedactionTypes,
    setSelectedactionTypes,
  } = props;

  const [eventsOptions, setEventsOptions] = useState([]);
  const [organizationsOptions, setOrganizationsOptions] = useState([]);

  useEffect(() => {
    getFilters();
  }, []);

  const organizationTypeOptions = useMemo(
    () => [
      {
        value: 'MACHINE_BUILDER',
        label: 'events-template.edit-template.organization-type.machine-builder',
      },
      {
        value: 'MACHINE_BUILDER_CHANNEL',
        label: 'events-template.edit-template.organization-type.machine-builder-channel',
      },
      {
        value: 'END_CUSTOMER',
        label: 'events-template.edit-template.organization-type.customer',
      },
    ],
    []
  );

  const actionTypeOptions = useMemo(
    () => [
      {
        value: 'EMAIL_NOTIFICATION',
        label: 'activity-log-tab.table.email-notification',
      },
      {
        value: 'SMS_NOTIFICATION',
        label: 'activity-log-tab.table.sms-notification',
      },
      {
        value: 'PUSH_NOTIFICATION',
        label: 'activity-log-tab.table.push-notification',
      },
    ],
    []
  );

  const getFilters = async () => {
    const res: FiltersResponse = await httpService.api({
      type: 'getActivitiesLogsFilters',
      disableBI: true,
    });

    if (res) {
      setEventsOptions(res?.events.map((val) => ({ value: val.eventId, label: val.eventName })));
      setOrganizationsOptions(
        res?.organizations.map((val) => ({
          value: val.organizationId,
          label: val.organizationName,
        }))
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.filtesSection}>
        <div className={styles.col}>
          <div className={styles.fiterRow} style={{ width: 270 }}>
            <I18n className={styles.text}>filters.date-range</I18n>
            <div className={styles.filter}>
              <DatePicker
                mode="allOption"
                selectedChanged={setSelectedDates}
                minDate={moment().subtract(7, 'year').format('DD/MM/YYYY')}
                data={selectedDates}
                height={36}
              />
            </div>
          </div>
          <ActivityFilter
            title={'activity-log-tab.table.event'}
            options={eventsOptions}
            selectedOptions={selectedEvents}
            onSelect={(val) => setSelectedEvents(val)}
          />
        </div>

        <div className={styles.col}>
          <ActivityFilter
            title={'activity-log-tab.table.organization-type'}
            options={organizationTypeOptions}
            selectedOptions={selectedOrganizationTypes}
            onSelect={setSelectedOrganizationTypes}
            width={350}
            filterWidth={230}
          />
          <ActivityFilter
            title={'activity-log-tab.table.organization-name'}
            options={organizationsOptions}
            selectedOptions={selectedOrganizatioionNames}
            onSelect={setSelectedOrganizatioionNames}
            width={350}
            filterWidth={230}
          />
        </div>

        <div className={styles.col}>
          <ActivityFilter
            title={'activity-log-tab.table.action-type'}
            options={actionTypeOptions}
            selectedOptions={selectedactionTypes}
            onSelect={setSelectedactionTypes}
          />
        </div>
      </div>

      <div className={styles.counterContainer}>
        <div className={styles.notificationNumber}>
          <div className={styles.counterRow}>
            <div
              className={classNames(
                styles.counterCaption,
                'ellipsis-overflow'
              )}>{`${i18nService.translate('activity-log-tab.number-of-sms')}:`}</div>
            <div className={styles.counterValue}>{`${numberOfSMS}`}</div>
          </div>
        </div>
        <div className={styles.notificationNumber}>
          <div className={styles.counterRow}>
            <div
              className={classNames(
                styles.counterCaption,
                'ellipsis-overflow'
              )}>{`${i18nService.translate('activity-log-tab.number-of-pushes')}:`}</div>
            <div className={styles.counterValue}>{`${numberOfPushes}`}</div>
          </div>
        </div>
        <div className={styles.notificationNumber}>
          <div className={styles.counterRow}>
            <div
              className={classNames(
                styles.counterCaption,
                'ellipsis-overflow'
              )}>{`${i18nService.translate('activity-log-tab.number-of-emails')}:`}</div>
            <div className={styles.counterValue}>{`${numberOfEmails}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitiesToolBar;
