import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';

import { findDateTimeTag } from '@pages/CreateWidgetPage/widgets.utils';
import { ComboWidgetCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { AssetProps } from './Combo.interface';
import { getColumnName } from '@core/canvas/widget.utils';
import {
  setColumnsDefaultData,
  changeWidgetData,
  remoteAccessButtonsCfg,
  remoteAccessButtonsLabels,
  onRemoteAccessButtonRowChanged,
  getTagSettingsCfg,
  updateColumns,
  getCustomizationColumns,
  formatRemoteAccessButtons,
  onMetricRowChanged,
} from './Combo.utils';

import { cloneDeep } from 'lodash';
import { getArrayFromObjectWithOrder } from '@core/utils';
import DraggableTable from '@components/DraggableTable';
import { tagFormatMap } from '@core/mapsAndDefinitions';
import { i18nService } from '@core/i18n/I18nService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  visibleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  visibleLabel: {
    marginLeft: 6,
    fontSize: 14,
  },
  section: {
    margin: '10px 0',
  },
  draggableTable: {
    display: 'flex',
    margin: '13px 0 13px 0',
  },
}));

function Combo(props: AssetProps) {
  const classes = useStyles(props);
  const { widgetData, setWidgetData, previewData, defaultDecimalDigits } = props;
  const customization = cloneDeep(widgetData.customization);
  const dateTimeTag = findDateTimeTag(widgetData.metrics);

  const {
    columns,
    remoteAccessButtons = {
      vpn: {
        order: 0,
        show: true,
        displayName: i18nService.translate(remoteAccessButtonsLabels.vpn),
      },
      web: {
        order: 0,
        show: true,
        displayName: i18nService.translate(remoteAccessButtonsLabels.web),
      },
      vnc: {
        order: 0,
        show: true,
        displayName: i18nService.translate(remoteAccessButtonsLabels.hmi),
      },
    },
    remoteAccessButtonsVisible = true,
    imageVisible = true,
  } = (customization as ComboWidgetCustomization) || {};

  const [widgetColumns, setWidgetColumns] = useState(columns);

  const [remoteAccessButtonsColumns, setRemoteAccessButtonsColumns] = useState(
    getArrayFromObjectWithOrder(remoteAccessButtons, 'button').map((element, idx) => {
      return {
        ...element,
        label: i18nService.translate(remoteAccessButtonsLabels[element['button']]),
        order: idx,
        draggableId: 'remoteAccessButtonsSettings',
      };
    })
  );

  useEffect(() => {
    let _columns;

    if (!customization) {
      _columns = getCustomizationColumns(widgetData, previewData, defaultDecimalDigits);
      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          columns: _columns,
          remoteAccessButtons,
          remoteAccessButtonsVisible: true,
          imageVisible: true,
        },
      }));
    } else if (columns?.length) {
      const unOrderedColumns = getCustomizationColumns(
        widgetData,
        previewData,
        defaultDecimalDigits
      );
      const tempColumns = updateColumns(unOrderedColumns, columns);
      _columns = tempColumns;

      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          columns: _columns,
        },
      }));

      setColumnsDefaultData(
        customization,
        widgetData,
        previewData,
        setWidgetData,
        defaultDecimalDigits
      );
    }
    setWidgetColumns(
      _columns.map((col, idx) => ({
        ...col,
        name: getColumnName(widgetData, col),
        type: tagFormatMap[col.tagType],
        draggableId: 'tagSettings',
      }))
    );
  }, [previewData]);

  useEffect(() => {
    const reorderedRemoteAccessButtons = remoteAccessButtonsColumns.map((col, idx) => {
      return { ...col, order: idx };
    });
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        remoteAccessButtons: formatRemoteAccessButtons(reorderedRemoteAccessButtons),
      },
    }));
  }, [remoteAccessButtonsColumns]);

  useEffect(() => {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        columns: widgetColumns as any,
      },
    }));
  }, [widgetColumns]);

  useEffect(() => {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        columns: widgetColumns as any,
      },
    }));
  }, [widgetColumns]);

  function mergeWidgetColumns(tagSettingsData) {
    const defaultColumns = widgetColumns?.filter(
      (d) => d.valueType === 'ASSET_PROPERTY' && [1, 2, 5].includes(d.id)
    );
    setWidgetColumns([...defaultColumns, ...tagSettingsData]);
  }

  return (
    <div className={classes.wrapper}>
      {widgetColumns?.length > 3 && (
        <div className={classes.section}>
          <div className={classes.titleWrapper}>
            <I18n>create-widget-page.create-widget.step-four.tags-settings</I18n>
          </div>
          <div className={classes.draggableTable}>
            <div style={{ width: '100%' }}>
              <DraggableTable
                droppableId={'tagSettings'}
                columnCfg={getTagSettingsCfg(widgetData, dateTimeTag)}
                data={widgetColumns?.filter(
                  (d) => !(d.valueType === 'ASSET_PROPERTY' && [1, 2, 5].includes(d.id))
                )}
                onRowChanged={onMetricRowChanged}
                setData={setWidgetColumns}
                mergeData={mergeWidgetColumns}
              />
            </div>
          </div>
        </div>
      )}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.remote-access-buttons</I18n>
        </div>
        <div className={classes.visibleWrapper}>
          <MaterialCheckbox
            color="primary"
            checked={remoteAccessButtonsVisible}
            onClick={() =>
              changeWidgetData(
                !remoteAccessButtonsVisible,
                'remoteAccessButtonsVisible',
                setWidgetData
              )
            }
          />
          <I18n className={classes.visibleLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classes.draggableTable}>
          <div style={{ width: '100%' }}>
            <DraggableTable
              droppableId={'remoteAccessButtonsSettings'}
              columnCfg={remoteAccessButtonsCfg}
              data={remoteAccessButtonsColumns}
              onRowChanged={onRemoteAccessButtonRowChanged}
              setData={setRemoteAccessButtonsColumns}
            />
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>general.image</I18n>
        </div>
        <div className={classes.visibleWrapper}>
          <MaterialCheckbox
            color="primary"
            checked={imageVisible}
            onClick={() => changeWidgetData(!imageVisible, 'imageVisible', setWidgetData)}
          />
          <I18n className={classes.visibleLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
      </div>
    </div>
  );
}

export default Combo;
