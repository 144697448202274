import React from 'react';
import classNames from 'classnames';
import style from './TextDataField.scss';
import { getByValue, tagFormatsMap } from '@core/mapsAndDefinitions';
import Icon from '@components/Icon';

const TextDataField = (props) => {
  const format = props.format ? props?.format : props?.type;

  return (
    <div style={{ display: 'flex' }}>
      <Icon type={`${getByValue(tagFormatsMap, format)}`} />
      <div className={classNames(style.text, 'ellipsis-overflow')} title={props.name}>
        {props.name}
      </div>
    </div>
  );
};

export default TextDataField;
