import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory, useLocation, Prompt } from 'react-router-dom';
import { usePrevious } from '@core/hooks/usePrevious';
import {
  initialWidgetData,
  creationStageMap,
  creationStepIndexesByStageName,
} from './widgets.utils';
import WidgetSideBar from './WidgetSideBar';
import { WidgetData } from './CreateWidgetPage.interface';
import { widgetMap } from '@core/canvas/widgetMap';
import WidgetFooter from './WidgetFooter';
import DataStepComponent from './DataStepComponent';
import { widgetStepValidationMap } from './widgetStepValidation/widgetStepValidationMap';
import PreviewSection from './PreviewSection';
import { makeStyles } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import { isEqual } from 'lodash';
import {
  onChangeWidgetData,
  getWidget,
  cancel,
  onNavigateFromPage,
  createWidget,
  getData,
  silentCancel,
  promptOpenReplaceTagsModal,
} from './CreateWidgetPage.utils';
import { dispatch, getState } from '@src/redux/store';
import { resetEditor } from '@src/redux/dashboardEditor';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { setShowDisclaimerAgain } from '@src/redux/config';

const useStyles = makeStyles((theme) => ({
  createWidgetPage: {
    display: 'flex',
    height: '100%',
  },
  widgetBody: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: 'var(--systemScreenBackground)',
    height: '100%',
    flexDirection: 'column',
  },
  widgetDataPreviewContainer: {
    padding: '30px 30px 0 30px',
    flexGrow: 1,
    height: 'calc(100% - 68px)',
    overflowY: 'auto',
  },
  widgetDataPreviewBody: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
  },

  widgetPreviewContainer: {
    paddingLeft: 26,
    width: '50%',
  },
}));

function CreateWidgetPage(props) {
  const location = useLocation();
  const history = useHistory();
  const { widgetId, dashboardId, canvasWidget } = useParams<any>();

  const [currentStep, setCurrentStep] = useState(0);
  const [widgetData, setWidgetData] = useState(initialWidgetData);
  const [initialData, setInitialData] = useState(initialWidgetData);
  const [steps, setSteps] = useState([]);
  const [enableSaveAsDraft, setEnableSaveAsDraft] = useState(false);
  const [previewData, setPreviewData] = useState();
  const dashboardEditorId = useSelector((state) => state.dashboardEditor.id);
  const dashboardEditor = useSelector((state) => state.dashboardEditor);
  const showDisclaimer = useSelector((state) => state.config.showDisclaimerAgain);
  const acceptDate = useSelector((state) => state.config.acceptDisclaimerDate);
  const [prevDashboardEditor, setPrevDashboardEditor] = useState(dashboardEditor);
  const disclaimerText = `Please note that when you use the Update Asset widgets in your dashboard, you enable your dashboard users to directly update the PLC.`;
  const dontShowOpenReplaceTagsPopup = useSelector((state) =>
    state.config.dontShowAgain.includes('SWITCH_TAGS_UTILITY')
  );
  const restOfText = `Unitronics will not be liable for any damage or consequence that is caused by the user's actions.`;

  const defaultDecimalDigits = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
  );

  let widget, useSteps;

  const classes = useStyles(props);

  const prevWidgetData: WidgetData = usePrevious(widgetData);

  useEffect(() => {
    onChangeWidgetData(widgetData, prevWidgetData, setPreviewData);
    if (widgetData) {
      widget = widgetMap[widgetData.type];
      useSteps = widget?.settings?.useSteps;
      setEnableSaveAsDraft(getEnableSaveAsDraft(widget));
      setSteps(useSteps || [1, 2, 3, 4, 5]);
      if (!currentStep && widgetData.type) {
        setCurrentStep(
          widgetData.creationStage
            ? creationStepIndexesByStageName.get(widgetData.creationStage)
            : useSteps
            ? useSteps[0]
            : 1
        );

        if (['line', 'columns'].includes(widgetData.type) && !widgetData.id) {
          setWidgetData((prevState) => ({
            ...prevState,
            exportFormats: ['CSV'],
          }));
        }

        if (widget?.settings?.showDisclaimer) {
          if ((showDisclaimer || !acceptDate) && !widgetData.id) {
            modalService
              .openModal('disclaimer', {
                style: { width: 550 },
                //iconType: 'attention_image',
                text: disclaimerText,
                listText: [restOfText],
                confirmText: 'general.confirm',
                cancelText: 'general.cancel',
                headerText: 'general.warning',
                showCloseBtn: true,
              })
              .then((confirm) => {
                if (confirm.res) {
                  dispatch(setShowDisclaimerAgain(!confirm.dontShowAgain));
                  httpService.api({
                    type: 'acceptDisclaimer',
                    data: { showDisclaimerAgain: !confirm.dontShowAgain },
                  });
                  if (
                    widgetData['copyId'] &&
                    !dontShowOpenReplaceTagsPopup &&
                    !widgetData['stepOnePassed']
                  )
                    promptOpenReplaceTagsModal(widgetData, dashboardId, setWidgetData);
                } else {
                  // cancel the widget creation
                  silentCancel(history, widgetData.dashboardId);
                }
              });
          }
        }
      }
    }
  }, [widgetData]);

  useEffect(() => {
    if (
      widgetData &&
      !isEqual(
        { ...dashboardEditor, creationStage: undefined },
        { ...prevDashboardEditor, creationStage: undefined }
      )
    ) {
      setPrevDashboardEditor(dashboardEditor);
      widgetData.status !== 'DRAFT' && getData(widgetData, setPreviewData);
    }
  }, [dashboardEditor]);

  useEffect(() => {
    setWidgetData((prevState) => ({
      ...prevState,
      creationStage: creationStageMap[currentStep],
    }));
  }, [currentStep]);

  useEffect(() => {
    getWidget(
      widgetId,
      dashboardEditorId,
      dashboardId,
      location,
      setWidgetData,
      setCurrentStep,
      setInitialData,
      canvasWidget
    );

    if (currentStep == 100) {
    }
  }, []);

  const getEnableSaveAsDraft = (widget) => {
    if (
      widget?.settings?.enableSaveAsDraftOnStepValidation &&
      widget.settings.enableSaveAsDraftOnStepValidation[currentStep]
    ) {
      return widgetStepValidationMap[currentStep](widgetData) && widgetData.assetTypes.length > 0;
    }
    return (
      widget?.settings?.enableSaveAsDraft ||
      widgetData.assetTypes.length > 0 ||
      widgetData.allAssetTypes
    );
  };

  const onSaveWidget = useCallback(
    (publish: boolean = false) => {
      const currentLayout = getState().dashboardEditor.currentLayout || 'DESKTOP';
      createWidget(widgetData, setWidgetData, publish).then(() => {
        dispatch(resetEditor(currentLayout));
        history.push(`/main/edit-dashboard/${dashboardId}`, {
          overrideGuard: true,
        });
      });
    },
    [widgetData, dashboardId, history]
  );

  const getPage = (currentStep, context) => {
    const action = context == 'NEXT' ? 1 : -1;

    const stepIdx = steps.findIndex((step) => step == currentStep);
    const stepToReturn = steps[stepIdx + action];

    return stepToReturn;
  };

  const next = () => {
    setCurrentStep(getPage(currentStep, 'NEXT'));
  };
  const prev = () => {
    setCurrentStep(getPage(currentStep, 'PREV'));
  };

  const onChangeStep = (from, to) => {
    setCurrentStep(to);
  };

  /**
   * This guard is activated whenever the user tries to navigate away from the page.
   */
  const leavePageGuard = useCallback(
    (location) => {
      return onNavigateFromPage(
        location,
        history,
        widgetData,
        setWidgetData,
        widgetId,
        initialData,
        getEnableSaveAsDraft(widgetData)
      );
    },
    [history, widgetData, getEnableSaveAsDraft]
  );

  return (
    <div className={classes.createWidgetPage}>
      <Prompt message={leavePageGuard} />
      {currentStep ? (
        <>
          <WidgetSideBar
            currentStep={currentStep}
            onUserChangeStep={onChangeStep}
            widgetData={widgetData}
          />
          <div className={classes.widgetBody}>
            <div className={classes.widgetDataPreviewContainer}>
              <div className={classes.widgetDataPreviewBody}>
                <DataStepComponent
                  widgetData={widgetData}
                  setWidgetData={setWidgetData}
                  currentStep={currentStep}
                  previewData={previewData}
                  onChangeStep={onChangeStep}
                  isNameValid
                  defaultDecimalDigits={defaultDecimalDigits}
                />
                {currentStep > 2 && currentStep !== 5 && (
                  <div className={classes.widgetPreviewContainer}>
                    <PreviewSection
                      widgetData={widgetData}
                      defaultDecimalDigits={defaultDecimalDigits}
                      data={previewData}
                    />
                  </div>
                )}
              </div>
            </div>
            <WidgetFooter
              cancel={() =>
                cancel(
                  history,
                  widgetData,
                  dashboardId,
                  onSaveWidget,
                  getEnableSaveAsDraft(widgetMap[widgetData?.type]),
                  initialData
                )
              }
              next={next}
              prev={prev}
              currentStep={currentStep}
              steps={steps}
              enableSaveAsDraft={enableSaveAsDraft}
              saveWidget={onSaveWidget}
              widgetData={widgetData}
              validation={widgetStepValidationMap[currentStep]}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CreateWidgetPage;
