import React, { useEffect, useMemo, useState } from 'react';

// Components
import MaterialCheckbox from '@components/Checkbox';

// services & Interfaces
import { ConditionalValueCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import styles from './ConditionalValue.scss';
import I18n from '@components/I18n';
import { cssVarsService } from '@core/CssVarsService';

// 3rd party
import { cloneDeep, compact } from 'lodash';
import { TextField, RadioGroup, FormControlLabel, Radio, SvgIcon } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import { getIsFloat } from '@pages/CreateWidgetPage/widgets.utils';
import CfgConditions from './CfgConditions';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';

const ConditionalValue = (props) => {
  const languageId = useSelector((state) => state.config.languageId);
  const { widgetData, setWidgetData, defaultDecimalDigits } = props;
  const { customization, metrics, scope, calculations } = widgetData;
  const {
    alignment,
    valueFontSize,
    decimalDigits,
    remarkFontSize,
    iconSize,
    symbol,
    symbolFontSize,
    symbolToLeft,
    valueColor,
    symbolColor,
    remarkColor,
    iconColor,
    conditions,
  } = (customization as ConditionalValueCustomization) || {};
  const isBoolean = useMemo(
    () => metrics[0].type === 'booleanType' && scope === 'LAST_VALUE',
    [metrics, scope]
  );
  const showDecimalDigits = useMemo(
    () =>
      getIsFloat(
        widgetData.metrics[0].operation,
        widgetData.metrics[0].valueType,
        widgetData.metrics[0].type,
        calculations?.find(
          (calc) =>
            calc.valueType === widgetData.metrics[0].valueType &&
            calc.valueId === widgetData.metrics[0].valueId
        ),
        widgetData
      ),
    [widgetData]
  );

  useEffect(() => {
    if (!customization) {
      let conditions = isBoolean
        ? [
            {
              boolValue: true,
              backgroundColor: cssVarsService.vars.widgetsBackground,
              id: `condition_1`,
            },
            {
              boolValue: false,
              backgroundColor: cssVarsService.vars.widgetsBackground,
              id: `condition_2`,
            },
          ]
        : [
            {
              fromValue: null,
              toValue: null,
              backgroundColor: cssVarsService.vars.widgetsBackground,
              id: `condition_1`,
            },
          ];

      updateWidgetCustomization(
        {
          alignment: 'STANDART',
          valueFontSize: 35,
          decimalDigits: showDecimalDigits ? defaultDecimalDigits : 0,
          remarkFontSize: 12,
          iconSize: 30,
          symbol: '',
          symbolFontSize: 20,
          symbolToLeft: false,
          valueColor: cssVarsService.vars.widgetsFont,
          symbolColor: cssVarsService.vars.widgetsFont,
          remarkColor: cssVarsService.vars.widgetsFont,
          iconColor: cssVarsService.vars.widgetsFont,
          conditions,
        },
        setWidgetData
      );
    }
  }, []);

  const setCustomizationData = (data, key) => {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetCustomization(values, setWidgetData);
  };

  return (
    <div className={styles.widgetValue}>
      <div className={styles.rowWithHeader}>
        <RadioGroup
          value={alignment || ''}
          classes={{ root: styles.radioGroupRoot }}
          onChange={(e) => setCustomizationData(e.target.value, 'alignment')}>
          <FormControlLabel
            classes={{ label: styles.radioLabel }}
            value="STANDART"
            control={<Radio />}
            label={i18nService.translate(
              'create-widget-page.create-widget.step-four.conditional-value.standard',
              languageId
            )}
          />
          <FormControlLabel
            value="CENTERED"
            control={<Radio />}
            classes={{ label: styles.radioLabel }}
            label={i18nService.translate(
              'create-widget-page.create-widget.step-four.conditional-value.centered',
              languageId
            )}
          />
        </RadioGroup>
      </div>
      <div className={styles.costumizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.value-size
        </I18n>
        <TextField
          value={valueFontSize || ''}
          margin="dense"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(+e.target.value, 'valueFontSize');
          }}
          onBlur={(e) => {
            setCustomizationData(
              200 < +valueFontSize ? 200 : 1 > +valueFontSize ? 1 : +valueFontSize,
              'valueFontSize'
            );
          }}
          inputProps={{
            min: 1,
            max: 200,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      {showDecimalDigits && (
        <div className={styles.costumizeRow}>
          <I18n
            noEllipsis={true}
            element="div"
            className={classNames(styles.textText, styles.subHeaderspace)}>
            create-widget-page.create-widget.step-four.conditional-value.decimal-digits
          </I18n>
          <div className={styles.smallWidth}>
            <TextField
              value={decimalDigits ?? ''}
              margin="dense"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCustomizationData(
                  !e.target.value ? e.target.value : +e.target.value < 0 ? 0 : +e.target.value,
                  'decimalDigits'
                );
              }}
              onBlur={(e) => {
                setCustomizationData(
                  10 < +decimalDigits ? 10 : 0 > +decimalDigits ? 0 : +decimalDigits,
                  'decimalDigits'
                );
              }}
              inputProps={{
                min: 0,
                max: 10,
                style: { color: 'var(--systemFont)' },
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.costumizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.remark-size
        </I18n>
        <TextField
          value={remarkFontSize || ''}
          margin="dense"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(+e.target.value, 'remarkFontSize');
          }}
          onBlur={(e) => {
            setCustomizationData(
              200 < +remarkFontSize ? 200 : 1 > +remarkFontSize ? 1 : +remarkFontSize,
              'remarkFontSize'
            );
          }}
          inputProps={{
            min: 1,
            max: 200,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.icon-size
        </I18n>
        <TextField
          value={iconSize || ''}
          margin="dense"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(+e.target.value, 'iconSize');
          }}
          onBlur={(e) => {
            setCustomizationData(200 < +iconSize ? 200 : 1 > +iconSize ? 1 : +iconSize, 'iconSize');
          }}
          inputProps={{
            min: 1,
            max: 200,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.symbol
        </I18n>
        <TextField
          value={symbol || ''}
          margin="dense"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(e.target.value, 'symbol');
          }}
          inputProps={{
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n
          noEllipsis={true}
          element="div"
          className={classNames(styles.textText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.symbol-size
        </I18n>
        <TextField
          value={symbolFontSize || ''}
          margin="dense"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setCustomizationData(+e.target.value, 'symbolFontSize');
          }}
          onBlur={(e) => {
            setCustomizationData(
              200 < +symbolFontSize ? 200 : 1 > +symbolFontSize ? 1 : +symbolFontSize,
              'symbolFontSize'
            );
          }}
          inputProps={{
            min: 1,
            max: 200,
            style: { color: 'var(--systemFont)' },
          }}
        />
      </div>
      <div className={styles.showSymbolLeft}>
        <MaterialCheckbox
          color="primary"
          onChange={(e) => setCustomizationData(!symbolToLeft, 'symbolToLeft')}
          checked={symbolToLeft || false}
        />
        <I18n className={styles.showSymbolLeftText}>
          create-widget-page.create-widget.step-four.show-symbol-to-left
        </I18n>
      </div>
      <div className={styles.costumizeRow}>
        <I18n className={styles.textText}>
          create-widget-page.create-widget.step-four.conditional-value.conditions
        </I18n>
        {customization?.errors && Object.values(customization?.errors).find((err) => err) && (
          <I18n className={styles.error}>
            create-widget-page.create-widget.step-four.conditional-value.conditions-error
          </I18n>
        )}
      </div>
      <CfgConditions
        isBoolean={isBoolean}
        widgetData={widgetData}
        setWidgetData={setWidgetData}></CfgConditions>
      <div className={styles.costumizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.smallText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.value-color
        </I18n>
        <ColorInput
          value={valueColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'valueColor')}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.smallText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.symbol-color
        </I18n>
        <ColorInput
          value={symbolColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'symbolColor')}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.smallText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.remark-color
        </I18n>
        <ColorInput
          value={remarkColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'remarkColor')}
        />
      </div>
      <div className={styles.costumizeRow}>
        <I18n noEllipsis={true} className={classNames(styles.smallText, styles.subHeaderspace)}>
          create-widget-page.create-widget.step-four.conditional-value.icon-color
        </I18n>
        <ColorInput
          value={iconColor}
          popOverPosition={{ left: 1 }}
          isParentRelative={false}
          customeMarginButton={0}
          colorChange={(value) => setCustomizationData(value, 'iconColor')}
        />
      </div>
    </div>
  );
};

export default ConditionalValue;
