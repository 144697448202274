import React from 'react';
import styles from './CanvasWidgetResizeIndicators.scss';
import { CanvasWidgetResizeIndicatorsProps } from './CanvasWidgetResizeIndicators.interface.d';
import { useResizeCallback } from './CanvasWidgetResizeIndicators.utils';

/**
 * Renders the resize indicators in the border of the CanvasWidgetWrapper.
 * When clicked, will initiate widget resize.
 */
function CanvasWidgetResizeIndicators(props: CanvasWidgetResizeIndicatorsProps) {
  const { widget, widgetRef, setIsWidgetResized } = props;

  const resizeTop = useResizeCallback(widget, 'top', widgetRef);
  const resizeLeft = useResizeCallback(widget, 'left', widgetRef);
  const resizeRight = useResizeCallback(widget, 'right', widgetRef);
  const resizeBottom = useResizeCallback(widget, 'bottom', widgetRef);
  const resizeTopLeft = useResizeCallback(widget, 'top-left', widgetRef);
  const resizeTopRight = useResizeCallback(widget, 'top-right', widgetRef);
  const resizeBottomLeft = useResizeCallback(widget, 'bottom-left', widgetRef);
  const resizeBottomRight = useResizeCallback(widget, 'bottom-right', widgetRef);

  function onMouseDown(e, func: any) {
    setIsWidgetResized(true);
    func(e);
  }

  return (
    <>
      <div className={styles.topBorder} onMouseDown={(e) => onMouseDown(e, resizeTop)} />
      <div className={styles.leftBorder} onMouseDown={(e) => onMouseDown(e, resizeLeft)} />
      <div className={styles.rightBorder} onMouseDown={(e) => onMouseDown(e, resizeRight)} />
      <div className={styles.bottomBorder} onMouseDown={(e) => onMouseDown(e, resizeBottom)} />
      <div className={styles.topLeftBorder} onMouseDown={(e) => onMouseDown(e, resizeTopLeft)} />
      <div className={styles.topRightBorder} onMouseDown={(e) => onMouseDown(e, resizeTopRight)} />
      <div
        className={styles.bottomLeftBorder}
        onMouseDown={(e) => onMouseDown(e, resizeBottomLeft)}
      />
      <div
        className={styles.bottomRightBorder}
        onMouseDown={(e) => onMouseDown(e, resizeBottomRight)}
      />
    </>
  );
}

export default React.memo(CanvasWidgetResizeIndicators);
