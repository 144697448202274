import * as RI from '../redux.interface.d';

export const setAll = (editAssetTypeData: Partial<RI.EditAssetTypeState>): RI.StoreAction => ({
  type: 'editAssetType/SET_ALL',
  payload: editAssetTypeData
});

export const clearEditAssetType = (): RI.StoreAction => ({
  type: 'editAssetType/CLEAR_EDIT_ASSET_TYPE',
  payload: ''
});

export const updateEditAssetType = (changes: any): RI.StoreAction => ({
  type: 'editAssetType/UPDATE_EDIT_ASSET_TYPE',
  payload: changes
});

export const setSelectedTab = (tab: string): RI.StoreAction => ({
  type: 'editAssetType/SET_SELECTED_TAB',
  payload: tab
});

const initialState = (): RI.EditAssetTypeState => ({
  selectedTab: 'tagsMeta',
  staticData: null,
  alarmsMeta: [],
  dataTablesMeta: [],
  tagsMeta: []
});

export default function editAssetReducer(
  state: RI.EditAssetTypeState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'editAssetType/SET_SELECTED_TAB':
      return { ...state, selectedTab: payload };
    case 'editAssetType/SET_ALL':
      return { ...state, ...payload };
    case 'editAssetType/CLEAR_EDIT_ASSET_TYPE':
      return initialState();
    case 'editAssetType/UPDATE_EDIT_ASSET_TYPE':
      return { ...state, staticData: { ...state.staticData, ...payload } };
    default:
      return state;
  }
}
