import React, { useCallback } from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import styles from './PreviewActionBar.scss';
import { PreviewActionBarProps } from './PreviewActionBar.interface';
import { dashboardService } from '@core/canvas/DashboardService';
import { cssVarsService } from '@core/CssVarsService';
import { getState } from '@src/redux/store';

function PreviewActionBar(props: PreviewActionBarProps) {
  const { route, dashboard } = props;
  const currentLayout = getState().dashboardEditor.currentLayout;

  const dashboardAction = useCallback(
    (action) => {
      switch (action) {
        case 'edit':
        case 'copy':
          dashboardService.dashboardCopyEdit(dashboard.id, action);
          break;
        case 'remove':
          dashboard.status === 'DRAFT'
            ? dashboardService.removeDraftDashboard(dashboard)
            : dashboardService.removePublishedDashboard(dashboard);
          break;
      }
    },
    [dashboard.id]
  );

  return (
    <div
      className={styles.previewButtons}
      style={currentLayout ? { justifyContent: 'right', marginTop: '20px' } : {}}>
      <div
        className={classNames(styles.actionBtn, 'ellipsis-overflow')}
        onClick={() => dashboardService.closeDashboardPreview()}>
        <Icon type="preview" color={cssVarsService.vars.systemEnableEditorIcons} />
        <I18n element="span">dashboard-editor.preview.close</I18n>
      </div>
      {route === 'gallery' && (
        <div
          className={classNames(styles.actionBtn, 'ellipsis-overflow')}
          onClick={() => dashboardAction('edit')}>
          <Icon type="edit_dashbord_small" color={cssVarsService.vars.systemEnableEditorIcons} />
          <I18n element="span">dashboard-editor.preview.edit</I18n>
        </div>
      )}
      <div
        className={classNames(styles.actionBtn, 'ellipsis-overflow')}
        onClick={() => dashboardAction('remove')}>
        <Icon type="trash" color={cssVarsService.vars.systemEnableEditorIcons} />
        <I18n element="span">dashboard-editor.preview.remove</I18n>
      </div>
      <div
        className={classNames(styles.actionBtn, 'ellipsis-overflow')}
        onClick={() => dashboardAction('copy')}>
        <Icon type="duplicate" color={cssVarsService.vars.systemEnableEditorIcons} />
        <I18n element="span">dashboard-editor.preview.copy</I18n>
      </div>
    </div>
  );
}

export default PreviewActionBar;
