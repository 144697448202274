import React, { useCallback } from 'react';

// Components
import I18n from '@components/I18n';
import FormikPlcInput from '@components/Formik/FormikPlcInput';
import Icon from '@components/Icon';

// Services | Interfaces
import { ModalComponentProps } from '@core/modals/modals.interface';
import { PLCConnectModel } from './ConnectPLCToRouterModal.interface';
import styles from './ConnectPLCToRouterModal.scss';

// 3rd party
import { Formik, Form } from 'formik';

const initialValues: PLCConnectModel = {
  plcSerial: { id: null, text: null, message: null, type: null },
};

function ConnectPLCToRouterModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const onSubmit = useCallback(
    (values: PLCConnectModel) => {
      const data = {
        plcId: values.plcSerial,
      };
      dismiss(data);
    },
    [dismiss]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>connect-plc-to-router-modal.title</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form className={styles.modalForm}>
            <div className={styles.modalContent}>
              <FormikPlcInput
                onSucceedValidation={onSubmit}
                routerId={args.routerId}
                name="plcSerial"
                label="connect-plc-to-router-modal.message"
                type="associateToRouter"
                className="associateToRouter"
                allowRowsEllipsis
                removeLabelEllipsis
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ConnectPLCToRouterModal;
