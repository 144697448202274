class StorageService {
  set(key: string, value: any): Promise<void> {
    localStorage.setItem(key, JSON.stringify(value));
    return Promise.resolve();
  }

  remove(key: string): Promise<void> {
    localStorage.removeItem(key);
    return Promise.resolve();
  }

  get(key: string): Promise<any> {
    const item = localStorage.getItem(key);
    const parsed = item ? JSON.parse(item) : null;
    return Promise.resolve(parsed);
  }
}

export const storageService = new StorageService();
