import React, { useCallback, useMemo } from 'react';
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import DataViewer from '@components/DataViewer';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import styles from '../AssetInfoGeneral.scss';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';
import { staticDictionary } from '@core/i18n/dictionary';
import { i18nService } from '@core/i18n/I18nService';
import Image from '@components/Image';
import { useSelector } from '@src/redux/useSelector';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dataValueStyle: {
    display: 'flex',
    flexDirection: 'row',
    height: '25px',
    minHeight: '25px',
    maxHeight: '25px',
    width: 'fit-content',
    maxWidth: '320px',
  },
});

function AssetInfoGeneralView(props) {
  const classes = useStyles(props);

  const { onEditClicked, data, imageUrl, setImageUrl } = props;
  const cancel = useCallback(() => editAssetService.dismiss(false), []);
  const editFlag = useMemo(() => getFlagStatus('assets.edit-buttons'), []);
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const canEditImage = userOrgType === 'MACHINE_BUILDER';

  const getLocation = (data) => {
    if (data.location) {
      return data.location.description
        ? data.location.description
        : data.location.formatted === 'Unknown'
        ? i18nService.translate('edit-asset-modal.location.unknown')
        : data.location.formatted;
    }
    return '';
  };

  const getSignalStrengthValue = (signalStrength: string) => {
    const signalStrengthKey = signalStrength
      ? signalStrength.toLocaleLowerCase().replace(' ', '-')
      : 'no-signal';
    return i18nService.translate(`edit-asset-modal.signal-strength.${signalStrengthKey}`);
  };

  const getNetworkTypeValue = (networkType: string) => {
    return networkType === 'None'
      ? i18nService.translate(`edit-asset-modal.network-type.none`)
      : networkType;
  };

  const dataViewerData = [
    { label: 'edit-asset-modal.owner', value: data.owner },
    {
      label: 'edit-asset-modal.location',
      value: getLocation(data),
    },
    ...(data?.gpsSupport && data?.location?.lat && data?.location?.lon
      ? [
          {
            label: '',
            value: `${i18nService.translate(
              'edit-asset-modal.latitude'
            )}: ${data?.location?.lat?.toFixed(4)}º N          ${i18nService.translate(
              'edit-asset-modal.longitude'
            )}: ${data?.location?.lon?.toFixed(4)}º E`,
            valueStyle: { whiteSpace: 'pre-wrap' },
            dataValueStyle: classes.dataValueStyle,
          },
        ]
      : []),
    { label: 'edit-asset-modal.type', value: data.deviceType },
    {
      label: 'edit-asset-modal.model',
      value: data.model,
    },
    {
      label: 'edit-asset-modal.plc-model',
      value:
        data.plcModel == 'Legacy'
          ? staticDictionary['enum.LEGACY']
          : data.plcModel === 'MODBUS'
          ? staticDictionary['enum.MODBUS']
          : data.plcModel,
    },
    {
      label: 'edit-asset-modal.plc-catalog-number',
      value:
        data.plcCatalogNumber === 'MODBUS'
          ? staticDictionary['enum.MODBUS']
          : data.plcCatalogNumber,
    },
    ...(data?.networkType
      ? [
          {
            label: 'edit-asset-modal.network-type',
            value: getNetworkTypeValue(data?.networkType),
          },
        ]
      : []),
    ...(data?.signalStrength || data?.networkType
      ? [
          {
            label: 'edit-asset-modal.signal-strength',
            value:
              data?.networkType === 'None'
                ? getSignalStrengthValue('no-service')
                : getSignalStrengthValue(data?.signalStrength),
          },
        ]
      : []),
    { label: 'edit-asset-modal.comments', value: data.comments },
  ];

  return (
    <>
      <div className={styles.content}>
        <div className={styles.actionRow}>
          {editFlag && (
            <Icon
              type="edit"
              className="pointer"
              onClick={onEditClicked}
              disabled={!hasPermission}
              color="var(--systemFont)"
            />
          )}
        </div>
        <div className={styles.dataWrapper}>
          <DataViewer
            data={dataViewerData}
            className={styles.dataUnit}
            boldLabel
            smallLabel
            marginBottom
            labelStyle={{ width: 'fit-content', maxWidth: '190px' }}
          />
          <div className={styles.imageContainerView}>
            <div className={styles.imageWrapper}>
              <Image
                src={imageUrl ? imageUrl : '/assets/images/empty_Image.svg'}
                text={
                  imageUrl || !canEditImage ? null : 'edit-asset-modal.image-placeholder-text-view'
                }
                maxTextWidth={'50%'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetInfoGeneralView;
