import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { useSelector } from '@redux/useSelector';
import { colorMap } from '@core/statusColorMap';
import styles from './EventDetailsModal.scss';
import moment from 'moment-timezone';
import DataSection from '@components/DataSection';
import { i18nService } from '@core/i18n/I18nService';
import MaterialCheckbox from '@components/Checkbox';
import { modalService } from '@core/modals/ModalService';
import { ADMIN_GROUP, dateTimezoneFormat } from '@core/utils';
import { getPermissionStatus } from '@core/ffAndPermissions';

function EventDetailsModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { rowData, organizationPage, refresh },
  } = props;
  const [eventData, setEventData] = useState(rowData);
  const [excludeAdmins, setExcludeAdmins] = useState(false);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const orgTimezone = useSelector((state) => state.config && state.config.timezone);
  const selectOrgTimezone = useSelector(
    (state) => state.organizations && state.organizations.timezone
  );
  const languageId = useSelector((state) => state.config.languageId);
  const hasPermission = useMemo(() => getPermissionStatus('EVENTS', 'EDIT'), []);

  useEffect(() => {
    setExcludeAdmins(!rowData.sendToAdminGroup);
  }, []);

  const generalSectionLeftContainer = useMemo(
    () => [
      { label: 'templates-delails-modal.name', value: rowData?.name },
      {
        label: 'templates-delails-modal.create-date',
        value: dateTimezoneFormat(rowData?.createdDate, selectOrgTimezone, orgTimezone),
      },
    ],
    [rowData]
  );
  const generalSectionRightContainer = useMemo(
    () => [
      {
        label: 'templates-delails-modal.action',
        value: i18nService.translate(`enum.${rowData?.action || ''}`, languageId),
      },
      {
        label: 'templates-delails-modal.expiry-date',
        value: dateTimezoneFormat(rowData?.expiryDate, selectOrgTimezone, orgTimezone),
      },
    ],
    [rowData]
  );

  const triggerSectionLeftContainer = useMemo(() => {
    const tempSection = [];

    tempSection.push({
      label: 'templates-delails-modal.execution-start-date',
      value: dateTimezoneFormat(eventData?.startDate, selectOrgTimezone, orgTimezone),
    });

    if (eventData?.trigger === 'SCHEDULER') {
      tempSection.push({
        label: 'templates-delails-modal.recurrence',
        value: eventData?.timeFrame
          ? i18nService.translate(`enum.${eventData?.timeFrame || ''}`, languageId)
          : '',
      });

      if (eventData?.timeFrame === 'WEEKLY') {
        tempSection.push({
          label: 'templates-delails-modal.weeks',
          value: eventData?.daysOfWeek
            .map((item) => {
              return i18nService.translate(`enum.${item || ''}`, languageId);
            })
            .join(', '),
        });
      } else if (eventData?.timeFrame === 'MONTHLY') {
        tempSection.push({
          label: 'templates-delails-modal.months',
          value: eventData?.dayOfMonth,
        });
      } else if (eventData?.timeFrame === 'YEARLY') {
        tempSection.push({
          label: 'templates-delails-modal.years',
          value: `${eventData?.dayOfYear} ${moment(eventData?.monthOfYear, 'M').format('MMMM')}`,
        });
      }
    }

    return tempSection;
  }, [eventData]);

  const triggerSectionRightContainer = useMemo(() => {
    const tempSection = [];

    if (eventData?.trigger === 'SCHEDULER')
      tempSection.push({
        label: 'templates-delails-modal.execution-time',
        value: moment(eventData?.time, ['HH.mm']).format('HH:mm'),
      });

    tempSection.push({
      label: 'templates-delails-modal.execution-end-date',
      value: dateTimezoneFormat(eventData?.endDate, selectOrgTimezone, orgTimezone),
    });

    return tempSection;
  }, [eventData]);

  const onExcludeAdmins = async () => {
    try {
      await httpService.api({
        type: 'eventsExcludeAdminsGroup',
        urlParams: { eventId: rowData.eventId },
        data: { sendToAdmin: excludeAdmins },
      });
      setExcludeAdmins(!excludeAdmins);
      setEventData((prev) => ({ ...prev, sendToAdminGroup: excludeAdmins }));
    } catch (e) {}
  };

  const onEditSchedule = async () => {
    const updatedSchedule = await modalService.openModal('schedulePickerModal', {
      templateInfo: { ...rowData, ...rowData.templateInfo },
    });

    if (updatedSchedule) {
      setEventData((prev) => ({ ...prev, ...updatedSchedule }));
      refresh();
    }
  };

  const onEditGroupMembers = async () => {
    await modalService.openModal('editGroupMembersModal', {
      notificationGroup: rowData?.templateInfo?.notificationGroups,
      includeAdmins: rowData?.sendToAdminGroup,
    });
    refresh();
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex' }}>
        <I18n className={styles.modalHeader} element="div" templateName={rowData?.name}>
          templates-delails-modal.title
        </I18n>
        <div style={{ display: 'flex' }} onClick={cancel}>
          <Icon className={styles.exitButton} type="close" />
        </div>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalToolbar}>
          <div className={styles.statusWarpper}>
            <div className={styles.status}>
              <I18n>edit-asset-modal.status</I18n>:
            </div>
            <div
              className="ellipsis-overflow"
              title={
                rowData.status === 'ACTIVE'
                  ? i18nService.translate('event-templates-tab.table.status.active', languageId)
                  : i18nService.translate(`enum.${rowData.status || 'NOT_AVALIABLE'}`, languageId)
              }
              style={{
                color: colorMap[rowData.status],
              }}>
              {rowData.status === 'ACTIVE'
                ? i18nService.translate('event-templates-tab.table.status.active', languageId)
                : i18nService.translate(`enum.${rowData.status || 'NOT_AVALIABLE'}`, languageId)}
            </div>
          </div>

          <div className={styles.toolbarsIcon}>
            {rowData.trigger === 'SCHEDULER' && hasPermission && (
              <div
                id={'7994f032-8162-4adc-b37a-bd9397f8d4f1'}
                onClick={onEditSchedule}
                className={
                  organizationPage
                    ? styles.disabledbutton
                    : rowData?.isSchedulerEditable
                    ? styles.btnGroup
                    : styles.disabledbutton
                }>
                <Icon
                  className={styles.iconBtn}
                  type="events_edit_schedule"
                  color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>
                  event-templates-tab.detail-popup.edit-scheduler
                </I18n>
              </div>
            )}

            {hasPermission && (
              <div
                id={'06c6cd10-cec0-496e-a4c9-b45771b0060c'}
                onClick={onEditGroupMembers}
                className={styles.btnGroup}>
                <Icon
                  className={styles.iconBtn}
                  type="events_edit_group"
                  color="var(--systemFont)"></Icon>
                <I18n className={styles.textBtn}>
                  event-templates-tab.table.create-date.table.edit-group-members
                </I18n>
              </div>
            )}
          </div>
        </div>

        <div className={styles.templateDetailsContainer}>
          <div className={styles.dataContainer}>
            <I18n className={styles.dataSectionTitle}>templates-delails-modal.general</I18n>
            <DataSection
              leftContainer={generalSectionLeftContainer}
              rightContainer={generalSectionRightContainer}></DataSection>
          </div>

          <div className={styles.dataContainer}>
            <div style={{ display: 'flex' }}>
              <I18n className={styles.dataSectionTitle}>templates-delails-modal.trigger</I18n>
              {`:  ${i18nService.translate(`enum.${rowData.trigger || ''}`, languageId)}`}
            </div>
            <DataSection
              leftContainer={triggerSectionLeftContainer}
              rightContainer={triggerSectionRightContainer}></DataSection>
          </div>

          <div className={styles.dataContainer}>
            <I18n className={styles.dataSectionTitle}>templates-delails-modal.audience</I18n>
            <div className={styles.audienceSection}>
              <div style={{ display: 'flex' }}>
                <div>
                  <div style={{ width: 315 }}>
                    {eventData?.sendToAdminGroup && <I18n>{ADMIN_GROUP}</I18n>}
                  </div>
                  {eventData?.notificationGroups.map((group, index) => (
                    <div key={index}>{group.name}</div>
                  ))}
                </div>
                {hasPermission && eventData.allowUnselectAdminGroup && (
                  <div id={'68aa6290-af81-43d1-8e5d-467b80a23aa1'}>
                    <MaterialCheckbox
                      color="primary"
                      checked={excludeAdmins}
                      onChange={onExcludeAdmins}
                    />
                    <I18n className={styles.allowUnselectAdminGroup} style={{ fontSize: 12 }}>
                      event-templates-tab.detail-popup.exclude-admins
                    </I18n>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.modalButtons}>
          <Button mode="bigFont" disabled={false} onClick={cancel}>
            <I18n>general.close</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EventDetailsModal;
