import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './EditARProject.scss';
import Icon from '@components/Icon';
import Select from '@components/Select';
import { httpService } from '@core/http/HttpService';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';

function EditARProject(props) {
  const { onCancelEdit, assetId, currentProject, selectedProject, setSelectedProject, options } =
    props;

  function onChange(value) {
    setSelectedProject(value);
  }

  useEffect(() => {
    setSelectedProject(
      currentProject?.id ? { id: currentProject?.id, name: currentProject?.name } : null
    );
  }, []);

  async function onSave() {
    const res: any = await httpService.api({
      type: 'updateARProjectInAsset',
      urlParams: { assetId: assetId },
      data: { arProjectId: selectedProject.id },
    });
    if (res) {
      setSelectedProject(res.ar ? { id: res.ar?.id, name: res.ar?.name } : null);
      editAssetService.updateEditAsset({ ar: res.ar });
      onCancelEdit();
    }
  }

  return (
    <div className={styles.editMode}>
      <Select
        name="arProject"
        value={selectedProject}
        placeholder={'general.none'}
        options={options}
        hideLabel={true}
        getOptionLabel={(opt) => opt.name}
        getOptionValue={(opt) => opt.id}
        onChange={onChange}
      />
      <Icon
        type="save"
        disabled={selectedProject?.id === currentProject?.id}
        className={classNames('pointer', styles.icon)}
        onClick={onSave}
      />
      <Icon type="remove" className={classNames('pointer', styles.icon)} onClick={onCancelEdit} />
    </div>
  );
}

export default EditARProject;
