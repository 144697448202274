import * as Yup from 'yup';
import { compact, uniq, pick } from 'lodash';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import {
  EmailValidationResponse,
  UserDetailsResponse,
  ArchiveUserResponse,
} from './EditUserModal.interface';
import { buildErrorObj, emailRegex } from '@core/utils';
import { modalService } from '@core/modals/ModalService';
import usersRolesMap, { addMoreRolesMap } from '@pages/OrganizationsPage/Users/UsersRolesMap';

export function getFieldsCfg(
  emailValidation,
  setEmailValidation,
  dashboardOptions,
  user,
  rolesOptions,
  isRoleOptionDisabled,
  allowDefaultDashboard,
  isUserMB
) {
  return [
    {
      label: 'my-details.email',
      name: 'email',
      type: 'input',
      editable: false,
      isRequired: true,
      visible: true,
      validate: (values) => {
        if (emailValidation.email === values.email && emailValidation.message) {
          return emailValidation.message;
        }
      },
      validation: Yup.string()
        .trim()
        .required('validations.mandatory-field')
        .matches(emailRegex, 'validations.incorrect-email'),
      onLostFocus: (values, setFieldValue, setInputValue, setErrors) => {
        values.email = values.email?.trim();
        emailValidation.email !== values.email &&
          user.email !== values.email &&
          httpService
            .api({
              type: 'editUserValidateEmail',
              data: { email: values['email'] },
              disableBI: true,
            })
            .then((res: EmailValidationResponse) => {
              if (res.isValid) {
                setEmailValidation({ email: '', message: '', isValid: true });
              } else {
                const message =
                  res.message &&
                  i18nService.translate(
                    `errors.${res.message.code}`,
                    undefined,
                    buildErrorObj(res.message.keys)
                  );
                setEmailValidation({ email: values.email, message, isValid: false });
                setErrors({ email: message });
              }
            });
      },
    },
    {
      label: 'my-details.title',
      name: 'title',
      type: 'select',
      editable: false,
      visible: true,
      options: ['NONE', 'MR', 'MRS', 'MISS', 'MS', 'DR', 'PROF'].map((opt) => ({
        value: opt,
        label: i18nService.translate(`enum.${opt}`),
      })),
    },
    {
      label: 'my-details.first-name',
      name: 'firstName',
      type: 'input',
      editable: false,
      isRequired: true,
      visible: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(20, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
    },
    {
      label: 'my-details.middle-name',
      name: 'middleName',
      type: 'input',
      editable: false,
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
      validation: Yup.string()
        .nullable()
        .max(20, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
    },
    {
      label: 'my-details.last-name',
      name: 'lastName',
      type: 'input',
      editable: false,
      isRequired: true,
      visible: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .max(100, 'my-details.name-message')
        .min(1, 'my-details.name-message'),
    },
    {
      label: 'my-details.phone',
      name: 'phone',
      type: 'phoneInput',
      editable: false,
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
    },
    {
      label: 'my-details.mobile',
      name: 'mobile',
      type: 'phoneInput',
      editable: false,
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
    },
    {
      label: 'my-details.roles',
      name: 'roles',
      type: 'multiSelect',
      options: rolesOptions,
      isOptionDisabled: isRoleOptionDisabled,
      isRequired: true,
      visible: true,
      validation: Yup.array().required('validations.mandatory-field'),
    },
    {
      label: 'my-details.default-language',
      name: 'languageName',
      editable: () => false,
      type: 'input',
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
    },
    {
      label: 'edit-user-modal.sms-notifications',
      name: user?.subscribeSMSNotification
        ? 'edit-user-modal.subscribed'
        : 'edit-user-modal.unsubscribed',
      editable: () => false,
      type: 'booleanVal',
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
    },
    {
      label: 'edit-user-modal.email-notifications',
      name: user?.subscribeEmailNotification
        ? 'edit-user-modal.subscribed'
        : 'edit-user-modal.unsubscribed',
      editable: () => false,
      type: 'booleanVal',
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
    },
    {
      label: 'my-details.default-dashboard',
      name: 'dashboardRefId',
      type: 'select',
      options: dashboardOptions,
      editable: () => (allowDefaultDashboard ? true : false),
      condition: () => isUserMB,
      visible: user?.status && user?.status !== 'PRE_ACTIVATED',
    },
  ];
}

export const getUser = async (
  setRolesOptions,
  setAdminRoleIds,
  userInfo,
  setUser,
  languageId,
  setEmailValidation
) => {
  const user =
    userInfo?.status === 'PRE_ACTIVATED'
      ? userInfo
      : await httpService.api({
          type: 'getUserDetails',
          urlParams: { userId: userInfo.id },
        });

  setUser({
    ...user,
    middleName: !user.middleName ? '' : user.middleName,
    roles:
      userInfo?.status === 'PRE_ACTIVATED'
        ? userInfo.roles.map((t) => ({
            value: Number.isInteger(t) ? t : usersRolesMap[t],
            label: i18nService.translate(
              `users-roles.${Number.isInteger(t) ? t : usersRolesMap[t]}`,
              languageId
            ),
          }))
        : user.roles.map((t) => ({
            value: t.id,
            label: i18nService.translate(`users-roles.${t.id}`, languageId),
          })),
  });

  setEmailValidation({ email: user.email, message: '', isValid: true });

  if (userInfo?.status && userInfo?.status !== 'PRE_ACTIVATED') {
    const rolesRes: any = await httpService.api({
      type: 'getRoles',
    });

    const { rolesOptions, adminRoleIds } = rolesRes.reduce(
      (data, current) => ({
        rolesOptions: [
          ...data.rolesOptions,
          {
            value: current.id,
            label: `users-roles.${current.id}`,
          },
        ],
        adminRoleIds: [...data.adminRoleIds, current.adminRole ? current.id : undefined],
      }),
      { rolesOptions: [], adminRoleIds: [] }
    );

    setAdminRoleIds(compact(adminRoleIds));
    setRolesOptions(rolesOptions);
  }
};

export const resetPassword = (user) => {
  modalService
    .openModal('confirm', {
      text: 'edit-user-modal.reset-password-message',
      iconType: 'attention_image',
      userName: `${user.firstName} ${user.lastName}`,
      showCloseBtn: true,
      headerText: 'edit-user-modal.reset-password-header',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
    })
    .then((confirm) => {
      if (confirm) {
        httpService
          .api({
            type: 'resetPasswordRequest',
            data: { userId: user.id },
          })
          .then((res) => {
            modalService.openAlert({
              text: i18nService.translate('edit-user-modal.reset-password-alert'),
              iconType: 'v_image',
            });
          });
      }
    });
};

export const deleteUser = (user, dissmis) => {
  modalService
    .openModal('confirm', {
      text: 'edit-user-modal.delete-user-message',
      iconType: 'attention_image',
      userName: `${user.firstName} ${user.lastName}`,
      showCloseBtn: true,
      headerText: 'edit-user-modal.delete-user-header',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
    })
    .then((confirm) => {
      if (confirm) {
        httpService
          .api({
            type: 'deleteUser',
            urlParams: { userId: user.id },
          })
          .then((res) => {
            dissmis('delete');
          });
      }
    });
};

const updateUserLocalToServer = (values) => {
  const roles =
    values.roles &&
    uniq(
      compact(
        values.roles.reduce((all, role) => [...all, role.value, addMoreRolesMap[role.value]], [])
      )
    );
  return {
    ...values,
    //if set default dashboard/title with initial value it will set: values.dashboard = id, when select value will be set as object
    dashboard: values.dashboard
      ? values.dashboard.value
        ? values.dashboard.value
        : values.dashboard
      : null,
    title: values.title ? (values.title.value ? values.title.value : values.title) : null,
    roles,
  };
};

const updateUserDetails = (values, user, setUser, languageId, setEditMode) => {
  httpService
    .api({
      type: 'updateUserDetails',
      urlParams: { userId: user.id },
      data: pick(updateUserLocalToServer(values), ['dashboardRefId', 'roles']),
    })
    .then((res: UserDetailsResponse) => {
      if (user.email !== values.email) {
        modalService.openModal('confirm', {
          text: 'edit-user-modal.new-email-alert',
          iconType: 'v_image',
          confirmText: 'general.confirm',
          headerText: 'edit-user-modal.new-email-alert-header',
          showCloseBtn: true,
        });
      }
      setUser({
        ...values,
        ...res,
        roles: res.roles.map((t) => ({
          value: t.id,
          label: i18nService.translate(`users-roles.${t.id}`, languageId),
        })),
      }); //TODO remove "values" when server return default dashboard & language
      setEditMode(false);
    });
};

export const onSubmit = (
  emailValidation,
  values,
  user,
  setEmailValidation,
  setUser,
  languageId,
  setEditMode
) => {
  if (emailValidation.isValid && values.email === user.email)
    updateUserDetails(
      { ...values, middleName: values.middleName === '' ? null : values.middleName },
      user,
      setUser,
      languageId,
      setEditMode
    );
  else {
    httpService
      .api({
        type: 'editUserValidateEmail',
        data: { email: values['email'] },
      })
      .then((res: EmailValidationResponse) => {
        if (res.isValid) {
          setEmailValidation({ email: '', message: '', isValid: true });
          updateUserDetails(values, user, setUser, languageId, setEditMode);
        } else {
          setEmailValidation({ email: values.email, message: res.message, isValid: false });
        }
      });
  }
};

export const onChangeStatus = (btnType, user, setUser) => {
  let apiType = null;
  switch (btnType) {
    case 'archiveBtn':
      apiType = user.status === 'ARCHIVED' ? 'activateUser' : 'archiveUser';
      break;
    case 'suspendBtn':
      apiType = user.status === 'SUSPENDED' ? 'activateUser' : 'suspendUser';
      break;
    case 'activateBtn':
      apiType = 'activateUser';
      break;
  }

  httpService
    .api({
      type: apiType,
      urlParams: { userId: user.id },
    })
    .then((res: ArchiveUserResponse) => {
      if (res.organizations && res.organizations.length) {
        modalService.openModal('alert', {
          text: 'edit-user-modal.main-contact-alert',
          iconType: 'attention_image',
          btnText: 'general.confirm',
          showCloseBtn: true,
          headerText: 'edit-user-modal.main-contact-alert-header',
          userName: `${user.firstName} ${user.lastName}`,
          companyName: res.organizations.join(', '),
        });
      }
      setUser((prevState) => ({
        ...prevState,
        status: res.status,
      }));
    });
};

export const resendUserInvitaion = async (user) => {
  try {
    await httpService.api({
      type: 'resendUserInvitaion',
      urlParams: { userId: user.id },
    });
  } catch {}
};
