import { getState, dispatch } from '@redux/store';
import { httpService } from '@core/http/HttpService';

class TrainingARProjectService {
  private _dismiss: Function;

  setModalDismiss(dismiss: (val) => void) {
    this._dismiss = dismiss;
  }

  dismiss(val) {
    this._dismiss && this._dismiss(val);
  }
}

export const trainingARProjectService = new TrainingARProjectService();
