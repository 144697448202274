import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';

export const onCancel = (dismiss) => {
  dismiss(false);
};

export const trainingARProject = async (
  arModelId,
  optimizeTrackingFor,
  successCallback,
  errorCallback
) => {
  try {
    const dataModel = {
      automaticColoring: 'AUTO',
      optimizeTrackingFor: optimizeTrackingFor,
    };
    httpService
      .api({
        type: 'trainingArProject',
        urlParams: { arModelId: arModelId },
        data: dataModel,
      })
      .then((newARProject: any) => {
        const isError = checkResponse(newARProject);
        if (isError) {
          errorCallback(isError);
        } else {
          successCallback(newARProject);
        }
      });
  } catch (error) {
    console.error('Error training project', error);
    errorCallback(error);
  }
};

const checkResponse = (trainingArResponse) => {
  if (trainingArResponse.default != null) {
    //{"default":["Only a NOT_TRAINED AR Model can be trained"]}
    if (trainingArResponse.default.length > 0) {
      return trainingArResponse.default;
    }
  }
  return false;
};
