import React, { useState } from 'react';
import Panel from '@pages/MyAccountPage/RegionalSettings/Panel';
import { UsageDataInterface, UsageDataResponse } from './UsageTab.interface';
import UsageData from './UsageData/UsageData';
import moment from 'moment';
import I18n from '@components/I18n';
import styles from './UsageTab.scss';
import TimePicker from './TimePicker/TimePicker';
import { httpService } from '@core/http/HttpService';
import { makeStyles } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    minWidth: 600,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    width: '100%',
    height: 'calc(100% - 294px)',
    maxHeight: '600px',
    overflowY: 'scroll',
    backgroundColor: 'var(--systemContentBackground)',
    boxShadow: '0 0 6px 0 rgba(194, 207, 224, 0.4)',
    borderRadius: 8,
    padding: '25px 25px 12px 25px',
  },
}));

function UsageTab(props) {
  const classes = useStyles(props);
  const [data, setData] = useState(null);
  const organizationId = useSelector((state) => state.organizations.selectedOrganizationsId);

  const getData = async (filter) => {
    try {
      const res: UsageDataResponse = await httpService.api({
        type: 'getSubscriptionsUsage',
        query: {
          ...filter,
          p: 1,
          ps: 1000,
        },
        urlParams: { organizationId: organizationId },
      });

      if (res) {
        setData(res.results);
      }
    } catch (e) {}
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={styles.summary}>
          <div className={styles.countContainer}>
            <I18n className={styles.lable}>
              {'my-account-page.licensing-plan.usage.monthly-sms'}
            </I18n>
            <div className={styles.number}>{data?.currentMonthly}</div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.countContainer}>
            <I18n className={styles.lable}>{'my-account-page.licensing-plan.usage.sms-pool'}</I18n>
            <div className={styles.number}>{data?.currentMonthly + data?.currentPool}</div>
          </div>
        </div>
        <TimePicker setfilter={(date) => getData(date)} />
      </div>
      <div className={classes.content}>
        <div className={classes.box}>
          {data?.months.map((item: UsageDataInterface) => {
            const defaultExpanded =
              moment(item.date).format('MMMM YYYY') === moment().format('MMMM YYYY')
                ? { defaultExpanded: true }
                : {};
            return (
              <Panel
                label={moment(item.date).format('MMMM YYYY')}
                Component={UsageData}
                {...defaultExpanded}
                data={item}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default UsageTab;
