import React, { useEffect } from 'react';
import { RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import I18n from '@components/I18n';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: 263,
    overflowY: (props) => (props['fileList']?.length > 24 ? 'auto' : 'visible'),
    justifyContent: 'flex-start',
    paddingLeft: 2,
    gap: '6px',
  },
  itemContainer: {
    border: 'solid 1px hsl(from var(--systemFont) h s l / 0.2)',
    height: 38,
    width: 228,
    borderRadius: 5,
    background: 'var(--systemContentBackground)',
    padding: '0 0 6px 10px',
  },
  label: {
    '&.MuiTypography-body1': {
      fontSize: 14,
    },
  },
  radioButton: {
    '&.MuiButtonBase-root': {
      height: 38,
    },
  },
  empty: {
    width: 946,
    textAlign: 'center',
    marginTop: 10,
  },
});

function DataSection(props) {
  const { selectedFile, setSelectedFile, fileList, noDataText } = props;
  const classes = useStyles(props);

  useEffect(() => {
    if (fileList?.length === 1) {
      setSelectedFile(fileList[0]);
    }
  }, [fileList]);

  return (
    <div className={classes.wrapper}>
      <RadioGroup
        value={selectedFile || ''}
        onChange={(e) => {
          setSelectedFile(e.target.value);
        }}>
        <div className={classes.wrapper}>
          {!fileList?.length ? (
            <div className={classNames(classes.empty, 'ellipsis-overflow')}>
              <I18n element={'div'}>{noDataText}</I18n>
            </div>
          ) : (
            fileList.map((item, idx) => (
              <div key={idx} className={classes.itemContainer}>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value={item}
                  control={<Radio classes={{ root: classes.radioButton }} />}
                  label={item}
                />
              </div>
            ))
          )}
        </div>
      </RadioGroup>
    </div>
  );
}

export default DataSection;
