import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { ConfirmWithMultiButtonsProps } from './ConfirmWithMultiButtons.interface';
import styles from './ConfirmWithMultiButtons.scss';

function ConfirmWithMultiButtons(props: ConfirmWithMultiButtonsProps) {
  const { args, dismiss } = props;
  const {
    text,
    iconType,
    showCloseBtn,
    multiButtons,
    cancelText,
    headerText,
    secondText,
    ...rest
  } = args;

  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const cancel = useCallback(() => dismiss(false), [dismiss]);

  return (
    <div className={styles.wrapper}>
      {showCloseBtn && (
        // <div className={styles.closeBtn}>
        //   <IconButton onClick={close}>
        //     <Close />
        //   </IconButton>
        // </div>
        <div className={styles.modalHeader}>
          <I18n {...rest}>{headerText}</I18n>
          <Icon type="close" onClick={close} className={'pointer'}></Icon>
        </div>
      )}
      <div className={styles.content}>
        {iconType && <Icon className={styles.icon} type={iconType}></Icon>}
        <I18n element="div" noEllipsis className={styles.text} {...rest}>
          {text}
        </I18n>
        {secondText ? (
          <I18n element="div" noEllipsis className={styles.text} {...rest}>
            {secondText}
          </I18n>
        ) : null}
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={cancel} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
          <I18n>{cancelText}</I18n>
        </Button>
        {multiButtons.map((btn, idx) => (
          <Button
            key={idx}
            onClick={(e) => {
              btn.onClick(e);
              btn.dismissModal && dismiss();
            }}
            styles={{ marginLeft: 13 }}
            mode="bigFont">
            <I18n>{btn.text}</I18n>
          </Button>
        ))}
      </div>
    </div>
  );
}

export default ConfirmWithMultiButtons;
