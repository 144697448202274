import React, { useMemo } from 'react';
import moment from 'moment';
import { i18nService } from '@core/i18n/I18nService';
import { colorMap } from '@core/statusColorMap';
import { useSelector } from '@src/redux/useSelector';

function SubscriptionData(props) {
  const { field: tempField, status: tempStatus, endDate, isInUse } = props;
  status = tempStatus;

  if (status == 'AVAILABLE') status = 'UNASSIGNED';

  if (!isInUse && status !== 'EXPIRED') {
    status = 'PENDING';
  }

  const field =
    tempField === 'subscriptionStatus'
      ? 'status'
      : tempField === 'subscriptionEndDate'
      ? 'endDate'
      : tempField === 'licenseType'
      ? 'licenceType'
      : tempField;
  const languageId = useSelector((state) => state.config.languageId);
  const value = useMemo(
    () =>
      field === 'status'
        ? i18nService.translate(`enum.subscription.plc.${status}`, languageId)
        : field === 'endDate' && endDate
        ? moment(endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : props[field],
    [field, languageId, status, endDate]
  );

  const color = useMemo(
    () =>
      field === 'status' && status !== 'ACTIVE' && colorMap[status]
        ? colorMap[status]
        : 'var(--systemFont)',
    [field, status]
  );

  return (
    <span
      style={{
        color: color,
      }}
      title={value}>
      {value}
    </span>
  );
}

export default SubscriptionData;
