import React from 'react';
import styles from './ActivitiesToolBar.scss';
import MultiSelect from '@components/MultiSelect';
import I18n from '@components/I18n';
import { ActivityFilterProps } from '../ActivitiesLogTab.interface';
import { i18nService } from '@core/i18n/I18nService';

function ActivityFilter({
  title,
  options,
  selectedOptions,
  onSelect,
  width,
  filterWidth,
}: ActivityFilterProps) {
  return (
    <div className={styles.fiterRow} style={{ width: width || 270 }}>
      <I18n className={styles.text}>{title}</I18n>
      <div className={styles.filter} style={{ width: filterWidth || 190 }}>
        <MultiSelect
          id={title}
          placeholder={i18nService.translate('general.all')}
          options={options}
          values={selectedOptions}
          onChange={onSelect}
          optionType="checkbox"
        />
      </div>
    </div>
  );
}

export default ActivityFilter;
