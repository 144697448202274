import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { ConfirmProps } from './Confirm.interface';
import styles from './Confirm.scss';
import classNames from 'classnames';
import { useSelector } from '@redux/useSelector';

function Confirm(props: ConfirmProps) {
  const { args, dismiss } = props;
  const {
    text,
    iconType,
    confirmText,
    cancelText,
    showCloseBtn,
    headerText,
    listText,
    secondText,
    extraText,
    needTextField = true,
    style = {},
    allowDontShowAgain = false,
    updateDontShowAgainOnCancel = false,
    textClassName,
    modalClassName,
    ...rest
  } = args;
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const confirmBtn = useRef(null);

  function close() {
    dismiss(undefined);
  }
  function cancel() {
    updateDontShowAgainOnCancel ? dismiss({ res: false, dontShowAgain }) : dismiss(false);
  }
  function confirm() {
    dismiss({ res: true, dontShowAgain });
  }

  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const buttonStyle = { marginLeft: 13 };

  useEffect(() => {
    confirmText && confirmBtn.current.focus();
  }, []);

  return (
    <div
      id={'2873fccd-c684-4190-b4ed-8b5faf3b88eb'}
      className={classNames(styles.wrapper, isMobileView && styles.wrapperMobile, modalClassName)}
      style={
        style
          ? { ...style, width: allowDontShowAgain && !isMobileView && '500px' }
          : { width: allowDontShowAgain && !isMobileView && '500px' }
      }>
      {showCloseBtn && (
        <div className={classNames(styles.modalHeader, isMobileView && styles.modalHeaderMobile)}>
          <I18n {...rest}>{headerText}</I18n>
          <Icon type="close" onClick={close} className={'pointer'}></Icon>
        </div>
      )}
      {needTextField && (
        <div className={styles.content}>
          {iconType && <Icon className={styles.icon} type={iconType}></Icon>}
          <I18n
            element="div"
            noEllipsis
            className={classNames(styles.text, textClassName)}
            {...rest}>
            {text}
          </I18n>
          {secondText && (
            <I18n
              element="div"
              noEllipsis
              className={classNames(styles.text, textClassName)}
              {...rest}>
              {secondText}
            </I18n>
          )}
          {listText && listText.length > 0 && (
            <ul className={styles.listText}>
              {listText.map((item, idx) => (
                <li key={idx}>
                  <I18n className={classNames(styles.text, textClassName)}>{item}</I18n>
                </li>
              ))}
            </ul>
          )}
          {extraText && (
            <I18n
              element="div"
              noEllipsis
              className={classNames(styles.text, textClassName)}
              {...rest}>
              {extraText}
            </I18n>
          )}
        </div>
      )}
      <div
        className={styles.actionsRow}
        style={
          isMobileView && allowDontShowAgain
            ? { flexDirection: 'column', height: '110px' }
            : allowDontShowAgain
            ? {
                flexDirection: 'row',
              }
            : {
                flexDirection: 'row-reverse',
              }
        }>
        {allowDontShowAgain && (
          <div id={'4b7c2aa9-f1d9-4dcb-ae8b-530347c8c8b8'}>
            <Checkbox
              style={{ marginRight: '10px' }}
              onChange={(e) => {
                setDontShowAgain(e.target.checked);
              }}
              color="primary"
              checked={dontShowAgain}
            />
            <I18n className={classNames(styles.text, textClassName, 'ellipsis-overflow')}>
              general.dont-show-again
            </I18n>
          </div>
        )}
        <div
          className={
            isMobileView
              ? allowDontShowAgain
                ? styles.actionButtonsMobileAllowDontShowAgain
                : styles.actionButtonsMobile
              : undefined
          }>
          {cancelText && (
            <Button
              onClick={cancel}
              styles={buttonStyle}
              mode={['cancel', rest['mode'] ? rest['mode'] : 'bigFont']}>
              <I18n>{cancelText}</I18n>
            </Button>
          )}
          {confirmText && (
            <Button
              onClick={confirm}
              styles={buttonStyle}
              mode={rest['mode'] ? rest['mode'] : 'bigFont'}
              ref={confirmBtn}>
              <I18n>{confirmText}</I18n>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Confirm;
