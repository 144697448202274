import React from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import ColorInput from '@components/ColorInput';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    padding: '22px 44px',
    width: '100%',
    height: 700,
    borderRadius: 5,
    backgroundColor: '#f9fafe',
    flexDirection: 'column',
  },
  fields: {
    paddingLeft: 30,
    marginTop: 10,
  },
  field: {
    display: 'flex',
    position: 'relative',
    marginBottom: 20,
  },
  fieldLabel: {
    width: 310,
    color: 'var(--systemFont)',
  },
  item: {
    marginBottom: 25,
  },
  colorWrapper: {
    marginRight: 10,
  },
}));

function DashboardsColors(props) {
  const classes = useStyles(props);
  const { displaySettingsData, onChangeData, refreshColorPickers } = props;
  const fieldsCfgDashboards = [
    {
      label: 'white-labeling.dashboards-colors.border',
      name: 'border',
      type: 'color',
      keyName: 'dashboardColors',
    },
    {
      label: 'white-labeling.dashboards-colors.background',
      name: 'background',
      type: 'color',
      keyName: 'dashboardColors',
    },
  ];

  const fieldsCfgGraphWidgets = {
    label: 'white-labeling.dashboards-colors.color-palette',
    name: 'graphsColorsPalette',
    type: 'color',
    keyName: 'widgetsColors',
  };

  const fieldsCfgWidgets = [
    {
      label: 'white-labeling.dashboards-colors.border-and-header',
      name: 'borderHeader',
      type: 'color',
      keyName: 'widgetsColors',
    },
    {
      label: 'white-labeling.dashboards-colors.header-font',
      name: 'headerFont',
      type: 'color',
      keyName: 'widgetsColors',
    },
    {
      label: 'white-labeling.dashboards-colors.widgets-background',
      name: 'background',
      type: 'color',
      keyName: 'widgetsColors',
    },
    {
      label: 'white-labeling.dashboards-colors.font',
      name: 'font',
      type: 'color',
      keyName: 'widgetsColors',
    },
  ];

  const fieldsCfgMenus = [
    {
      label: 'white-labeling.dashboards-colors.menus-background',
      name: 'liveBackground',
      secondName: 'background',
      type: 'color',
      keyName: 'menusColors',
    },
    {
      label: 'white-labeling.dashboards-colors.foreground',
      name: 'liveForeground',
      secondName: 'foreground',
      type: 'color',
      keyName: 'menusColors',
    },
    {
      label: 'white-labeling.dashboards-colors.selected',
      name: 'liveSelected',
      secondName: 'selected',
      type: 'color',
      keyName: 'menusColors',
    },
  ];

  const getGraphWidgetValue = () => {
    return displaySettingsData[fieldsCfgGraphWidgets.keyName]?.content[fieldsCfgGraphWidgets.name];
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.item}>
        <I18n>white-labeling.dashboards-colors.dashboards</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgDashboards.map((field, idx) => (
              <ColorInput
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.dashboards-colors.widgets</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgWidgets.map((field, idx) => (
              <ColorInput
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) => onChangeData(field.keyName, field.name, value)}
                isDisplaySettings
              />
            ))}
        </div>
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.dashboards-colors.graph-widgets</I18n>
        <div className={classes.fields}>
          <div className={classes.field}>
            <I18n className={classes.fieldLabel}>{fieldsCfgGraphWidgets.label}</I18n>
            {!refreshColorPickers &&
              getGraphWidgetValue()?.map((color, idx) => (
                <div className={classes.colorWrapper} key={`${idx}_${color}`}>
                  <ColorInput
                    disableScrollIntoView={true}
                    value={color}
                    customeMarginButton={0}
                    colorChange={(value) =>
                      onChangeData(
                        fieldsCfgGraphWidgets.keyName,
                        fieldsCfgGraphWidgets.name,
                        getGraphWidgetValue()?.map((originalVal, index) =>
                          index === idx ? value : originalVal
                        )
                      )
                    }
                    isDisplaySettings
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={classes.item}>
        <I18n>white-labeling.dashboards-colors.menus</I18n>
        <div className={classes.fields}>
          {!refreshColorPickers &&
            fieldsCfgMenus.map((field, idx) => (
              <ColorInput
                key={`${idx}_${field.keyName}`}
                {...field}
                disableScrollIntoView={true}
                value={displaySettingsData[field.keyName]?.content[field.name]}
                colorChange={(value) =>
                  onChangeData(field.keyName, field.name, value, field.secondName)
                }
                isDisplaySettings
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardsColors;
