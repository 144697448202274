import { DropResult } from 'react-beautiful-dnd';

type DragEndCallback = (result: DropResult) => any;

/**
 * Handles DragDropContext events for Drag and Drop via react-beautiful-dnd components.
 */
class DndService {
  private dragEndSubs: Array<{ cb: DragEndCallback }> = [];

  /**
   * Handles dragEnd events fired from DragDropContext. Use this function only there.
   */
  onDragEnd = (result: DropResult) => {
    this.dragEndSubs.forEach(({ cb }) => cb(result));
  };

  /**
   * Subscribe to dragEnd events. Returns an object with an unsubscribe function.
   */
  dragEndSubscribe = (cb: DragEndCallback) => {
    const ref = { cb };
    this.dragEndSubs.push(ref);
    const unsubscribe = () => {
      this.dragEndSubs = this.dragEndSubs.filter(s => s !== ref);
    };
    return { unsubscribe };
  };
}

export const dndService = new DndService();
