import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { TextField, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import styles from './AddUserGroupModal.scss';

const useStyle = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
}));

function AddUserGroupModal(props) {
  const { dismiss, args } = props;
  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState(true);
  const { customUserGroups } = args;
  const classes = useStyle(props);

  const validateName = async (newName) => {
    if (newName)
      setNameValid(!customUserGroups?.some((g) => g.name.toLowerCase() === newName.toLowerCase()));
    else setNameValid(true); // So error won't show, Save button should be disabled in that case
  };

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const confirm = useCallback(async () => {
    await validateName(name);

    if (nameValid) {
      dismiss({ name });
    }
  }, [dismiss, name]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n>events-template.audience.user-groups.add-user-group-modal.header</I18n>
        <Icon type="close" onClick={props.dismiss} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={styles.rowWithError}>
            <div className={styles.header}>
              <I18n>events-template.audience.user-groups.add-user-group-modal.new-group-name</I18n>
            </div>
            <TextField
              value={name}
              margin="dense"
              variant="outlined"
              className={styles.textField}
              inputProps={{
                style: { fontSize: 12 },
                maxLength: 20,
                autoFocus: true,
              }}
              onChange={(e) => {
                validateName(e.target.value);
                setName(e.target.value);
              }}
            />
          </div>
          <div className={styles.error}>
            {!nameValid && (
              <I18n>
                events-template.audience.user-groups.add-user-group-modal.group-name-exists
              </I18n>
            )}
          </div>
        </div>
        <div className={styles.modalButtons}>
          <Button mode={['cancel', 'bigFont']} disabled={false} onClick={cancel}>
            <I18n>general.cancel</I18n>
          </Button>
          <Button
            styles={{ marginLeft: 13 }}
            mode="bigFont"
            onClick={confirm}
            disabled={!nameValid || !name}>
            <I18n>general.confirm</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddUserGroupModal;
