import React from 'react';
import BodyColumn from './BodyColumn';

import styles from './TableBody.scss';

const BodyRow = ({ columnLayout, row, rowIndex, handleCellClick }) => {
  return (
    <>
      {columnLayout.staticStart.map((col, colIndex) => (
        <BodyColumn
          key={colIndex * Math.random() * 9999}
          row={row}
          rowIndex={rowIndex}
          col={col}
          colIndex={colIndex}
          ellipsis={false}
          handleCellClick={handleCellClick}
        />
      ))}
      <div
        className={styles.responsiveContainer}
        style={{ width: `calc(100% - ${columnLayout.fixedWidth}px)` }}>
        {columnLayout.responsive.map((col, colIndex) => (
          <BodyColumn
            key={colIndex * Math.random() * 9999}
            row={row}
            rowIndex={rowIndex}
            col={col}
            colIndex={colIndex}
            ellipsis={true}
            handleCellClick={handleCellClick}
          />
        ))}
      </div>
      {columnLayout.staticEnd.map((col, colIndex) => (
        <BodyColumn
          key={colIndex * Math.random() * 9999}
          row={row}
          rowIndex={rowIndex}
          col={col}
          colIndex={colIndex}
          ellipsis={false}
          handleCellClick={handleCellClick}
        />
      ))}
    </>
  );
};

export default BodyRow;
