import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { withRouter, Redirect, Link, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import { authService } from '@auth/AuthService';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import NewLogo from '@components/NewLogo';
import { useSelector } from '@redux/useSelector';
import styles from './LoginPage.scss';
import LoginInput from './LoginInput';
import { LoginModel } from './LoginPage.interface';
import { httpService } from '@core/http/HttpService';
import OnboardingActionMessage from '@components/OnboardingActionMessage';
import Spinner from '@components/Spinner';
import { onSubmit, loadFormByStatus } from './LoginPage.utils';
import { emailRegex, isSubDomain, CAPTCHA_SCRIPT_URL } from '@core/utils';
import { dispatch } from '@src/redux/store';
import { setfileForDownload } from '@src/redux/config';
import { setAccounts } from '@src/redux/accounts';

const initialValues: LoginModel = {
  username: '',
  password: '',
  timeZone: '',
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required('validations.incorrect-email')
    .matches(emailRegex, 'validations.incorrect-email'),
  password: Yup.string().required('validations.incorrect-pass'),
});

function LoginPage() {
  const { isLoggedIn, userOrganizationId, user_status, twoFaStatus } = useSelector(
    (state) => state.login
  );
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const [errorsMessage, setErrorsMessage] = useState(null);
  const [loadForm, setLoadForm] = useState(false);
  const accounts = useSelector((state) => state.accountState.accounts);
  const [actionMessage, setActionMessage] = useState(null);
  const history = useHistory();
  const hideSignUp = useMemo(() => isSubDomain(), []);
  const urlSearchParams = new URLSearchParams(history.location.search);
  const key = urlSearchParams.get('key');
  const action = urlSearchParams.get('action');

  const search = useLocation().search;
  const attachmentFileKey = new URLSearchParams(search).get('attachmentFileKey');

  const host = window.location.protocol + '//' + window.location.host;
  const apiKey = authService.getReCaptchaAPIKey(
    true,
    host.includes('localhost') ? process.env.BASE_URL : host
  );

  const [grecaptcha, setGrecaptcha] = useState('');

  useEffect(() => {
    attachmentFileKey && dispatch(setfileForDownload(attachmentFileKey));

    const script = document.getElementById('whatfixId');

    if (script) {
      document.body.removeChild(script);
      window.location.reload();
    }

    if (isLoggedIn) {
      dispatch(setAccounts([]));
    }

    loadFormByStatus(key, action, changeEmail, welcomeEmail, setLoadForm);
  }, []);

  useEffect(() => {
    const reCAPTCHAscript = document.createElement('script');
    reCAPTCHAscript.type = 'text/javascript';
    reCAPTCHAscript.src = CAPTCHA_SCRIPT_URL;
    (reCAPTCHAscript.defer = true), document.head.appendChild(reCAPTCHAscript);

    setGrecaptcha(window['grecaptcha']);
  }, [loadForm]);

  const onBoardingCallback = useCallback(() => {
    setTimeout(() => setActionMessage(null), 5000);
  }, []);

  const onFormChange = () => {
    setErrorsMessage(null);
  };

  const onClickAction = useCallback(() => {
    setActionMessage(null);
  }, []);

  const status = useMemo(
    () => ({
      EMAIL_CHANGED: {
        text: 'login-page.change-email-message',
        headerText: 'login-page.action-verified',
        isError: false,
        callback: onBoardingCallback,
      },
      EXPIRED: {
        text: 'login-page.token-expired',
        headerText: 'login-page.request-expired',
        isError: true,
        actionBtnText: 'login-page.submit',
        onClickAction,
      },
    }),
    []
  );

  const changeEmail = useCallback(() => {
    httpService
      .api({
        type: 'EmailVerification',
        data: { key },
        disableBI: true,
      })
      .then((res) => {
        setActionMessage('EMAIL_CHANGED');
        setLoadForm(true);
      })
      .catch((err) => {
        setActionMessage('EXPIRED');
        setLoadForm(true);
      });
  }, [key, authService]);

  const welcomeEmail = useCallback(() => {
    setTimeout(() => {
      httpService
        .api({
          type: 'activateOrg',
          data: { key },
          disableBI: true,
        })
        .then((res) => {
          setLoadForm(true);
        })
        .catch((err) => {
          setActionMessage('EXPIRED');
          setLoadForm(true);
        });
    });
  }, [key, isLoggedIn, history]);

  const signUp = useCallback(() => {
    history.push('/login/signUp');
  }, []);

  return loadForm ? (
    <>
      {isLoggedIn && user_status === 'PRE_ACTIVATED' ? (
        <Redirect to="/terms-and-conditions" />
      ) : null}
      {isLoggedIn &&
      user_status === 'ACTIVE' &&
      accounts &&
      twoFaStatus?.isCompleted &&
      userOrganizationId ? (
        <Redirect to="/main" />
      ) : null}
      {isLoggedIn && user_status === 'ACTIVE' && accounts && !userOrganizationId ? (
        <Redirect to="/select-organization" />
      ) : null}
      {actionMessage ? (
        <OnboardingActionMessage {...status[actionMessage]} />
      ) : (
        <div
          className={classNames(
            !isMobileView && 'max-height',
            styles.loginPage,
            isMobileView && styles.loginPageMobile
          )}>
          <div
            className={'g-recaptcha'}
            data-sitekey={apiKey}
            data-size={'invisible'}
            data-action={'LOGIN'}></div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values, setErrorsMessage, grecaptcha)}>
            {({ isValid }) => (
              <Form
                onChange={onFormChange}
                className={classNames(styles.loginForm, isMobileView && styles.mobileMode)}>
                <div className={classNames(styles.logoWrapper, isMobileView && styles.mobileMode)}>
                  <NewLogo />
                </div>
                <div className={styles.loginContent}>
                  {errorsMessage && (
                    <div className={styles.errorMessage}>
                      <Icon type="error" className={styles.errorIcon} />
                      <span className={styles.errorText}>
                        <I18n noEllipsis>{errorsMessage}</I18n>
                      </span>
                    </div>
                  )}
                  <LoginInput
                    name="username"
                    label="login-page.email"
                    className={styles.label}
                    trimOnBlur
                  />
                  <LoginInput
                    type="password"
                    name="password"
                    label="login-page.password"
                    className={styles.label}
                  />
                  <Link to="/forgot-password" className={styles.link}>
                    <Button className={classNames(styles.link, isMobileView && styles.mobileMode)}>
                      <I18n element="div">login-page.forgot-password</I18n>
                    </Button>
                  </Link>
                  <Button
                    className={classNames(styles.submit, isMobileView && styles.submitMobile)}
                    type="submit"
                    variant="contained"
                    disabled={!isValid}>
                    <I18n>login-page.submit</I18n>
                  </Button>
                  {!hideSignUp && (
                    <div className={styles.signUp}>
                      <I18n>login-page.creat-user-ques</I18n>
                      <Button
                        className={classNames(styles.link, styles.signInLink)}
                        onClick={signUp}>
                        <I18n>login-page.sign-up</I18n>
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  ) : (
    <Spinner />
  );
}

export default withRouter(LoginPage);
