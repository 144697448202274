import React, { useCallback } from 'react';
import Button from '@components/Button';
import { editAssetService } from '../../EditAssetService';
import styles from './AssetInfoTagsMeta.scss';
import I18n from '@components/I18n';
import TagsTable from './TagsTable/TagsTable';

function AssetInfoTagsMeta() {
  const cancel = useCallback(() => editAssetService.dismiss(false), []);

  return (
    <>
      <div className={styles.content}>
        <TagsTable></TagsTable>
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetInfoTagsMeta;
