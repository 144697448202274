import React from 'react';
import styles from './GalleryThumbnailContainer.scss';
import GalleryThumbnailInstance from './GalleryThumbnailInstance';
import { GalleryThumbnailContainerProps } from './GalleryThumbnailContainer.interface';
import { omit } from 'lodash';

function GalleryThumbnailContainer(props: GalleryThumbnailContainerProps) {
  const { galleryItem, badge, infoBar } = props;
  const restProps = omit(props, ['galleryItem', 'badge', 'infoBar']);
  const { status, published, draft } = galleryItem;

  return (
    <div className={styles.container}>
      {draft && published ? (
        <div className={styles.doubleThumbnailWrapper}>
          <div className={styles.publishedThumbnail}>
            <GalleryThumbnailInstance
              galleryItemInstance={published}
              status={status}
              {...restProps}
            />
          </div>
          <div className={styles.draftThumbnail}>
            <GalleryThumbnailInstance
              galleryItemInstance={draft}
              status={status}
              badge={badge}
              infoBar={infoBar}
              {...restProps}
            />
          </div>
        </div>
      ) : (
        <div className={styles.singleThumbnailWrapper}>
          <GalleryThumbnailInstance
            galleryItemInstance={published || draft}
            status={status}
            badge={badge}
            infoBar={infoBar}
            {...restProps}
          />
        </div>
      )}
    </div>
  );
}

export default GalleryThumbnailContainer;
