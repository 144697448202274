import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { getDefaultWidgetColorPalette, getVar } from '@core/canvas/widget.utils';
import { compact, sum } from 'lodash';
import { numberFormatter } from '@core/utils';
import { getState } from '@src/redux/store';

const onChartClick = (config, data, navigateDashboard, widget) => {
  const { metricsColumns = [], results = [] } = data;
  const dataPointIndex = config.dataPointIndex;
  const metricsIndex = config.seriesIndex;
  const selectedValue =
    metricsIndex >= 0 && dataPointIndex >= 0
      ? results[dataPointIndex][metricsColumns[metricsIndex].name]
      : null;
  const selectedColumn = metricsIndex >= 0 ? metricsColumns[metricsIndex] : null;

  navigateDashboard &&
    widget.navigationDashboard &&
    navigationDataBuilder({
      selectedValue,
      selectedColumn,
      widget,
      columns: data.columns,
      rawValues: results[dataPointIndex],
      navigateDashboard,
    });
};

const onTooltipFormatter = (val, customization) => {
  const { tooltip_decimalDigits = 0 } = customization;
  return isNaN(Number(val)) ? val : numberFormatter(val, +tooltip_decimalDigits);
};

export const getSeries = (metricsColumns, columns, results, groupByColumns) => {
  const keys = [];
  results.forEach((row) => {
    let key = '';
    if (groupByColumns && groupByColumns.length != 0) {
      groupByColumns.forEach(
        (g) => (key = key.concat(g.name).concat(':').concat(row[g.name]).concat('-'))
      );
    } else {
      results.forEach(
        (r) => (key = key.concat(r.name).concat(':').concat(row[r.name]).concat('-'))
      );
    }

    if (keys.find((k) => k === key)) row['specific_asset'] = true;
    keys.push(key);
  });

  return compact(
    columns.map((column) => {
      const metric = metricsColumns.find(
        (metric) => metric.valueId === column.id && metric.operation === column.operation
      );

      if (
        !metric ||
        metric.hidden ||
        (column.specific_asset && !results.find((data) => data.specific_asset))
      )
        return;

      let type = column.type || 'BAR';
      let legendLabel = column.displayName;

      const filteredRows = results.filter((r) => !!r.specific_asset === !!column.specific_asset);

      return {
        specific_asset: column.specific_asset,
        name: legendLabel,
        type: type === 'BAR' ? 'column' : type?.toLowerCase(),
        data: filteredRows.map((data) =>
          data[metric.name] === undefined ? null : data[metric.name]
        ),
      };
    })
  );
};

export const getGeneralMinAndMax = (series, resultData) => {
  return series.length
    ? { minVal: Math.min(...resultData) * 1.25, maxVal: Math.max(...resultData) * 1.25 }
    : { minVal: 0, maxVal: 0 };
};

export const getStackedMinAndMax = (series) => {
  if (series.length) {
    const minVal = Math.min(
      ...series.reduce(
        (prevSeries, nextSeries) =>
          nextSeries.data.map((value, idx) => {
            if (value < 0) {
              return (prevSeries[idx] || 0) + value;
            }
            return prevSeries[idx] || 0;
          }),
        []
      )
    );
    const maxVal = Math.max(
      ...series.reduce(
        (prevSeries, nextSeries) =>
          nextSeries.data.map((value, idx) => {
            if (value >= 0) {
              return (prevSeries[idx] || 0) + value;
            }
            return prevSeries[idx] || 0;
          }),
        []
      )
    );

    return {
      minVal: Number.isFinite(minVal) && minVal <= 0 ? minVal * 1.05 : 0,
      maxVal: Number.isFinite(maxVal) ? maxVal * 1.05 : 0,
    };
  }
  return { minVal: 0, maxVal: 0 };
};

export const getXaxisPosition = (yAxes, metricsColumns, maxVal) => {
  return !yAxes.length && maxVal <= 0 && metricsColumns.length ? 'top' : 'bottom';
};

export const getColumnsColors = (columns) => {
  return columns?.length
    ? columns.map((col, idx) => {
        if (!!col.color)
          return {
            color: col.color,
            name: col.name,
            specific_asset: col.specific_asset,
          };
        else {
          const varIndex = idx < 6 ? idx : idx % 6;
          return {
            color: getVar(`widgetsGraphsColorsPalette${varIndex + 1}`),
            name: col.name,
            specific_asset: col.specific_asset,
          };
        }
      })
    : getDefaultWidgetColorPalette().map((color, idx) => {
        return {
          color: color,
          name: idx,
          specific_asset: false,
        };
      });
};

export const getYaxisCustomization = (
  yAxes,
  columns,
  series,
  maxVal,
  minVal,
  defaultDecimalDigits
) => {
  return yAxes && yAxes.length && series.length
    ? compact(
        yAxes.map((x, idx) => {
          const {
            show = idx === 0 ? 'LEFT' : 'NO',
            name,
            displayName,
            decimalDigits = defaultDecimalDigits,
            scale = 'AUTO',
            minValue,
            maxValue,
          } = x;
          if (yAxes.length === 1 && show === 'NO') {
            return;
          }
          const yAxisItem: any = {
            show: show !== 'NO',
            seriesName: columns[idx].displayName,
            yAxisIndex: idx,
            axisBorder: {
              show: true,
              color: 'var(--widgetsFont)',
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: 'var(--widgetsFont)',
              width: 6,
              offsetX: 0,
              offsetY: 0,
            },
            // tickAmount: 5,
            labels: {
              show: true,
              style: {
                colors: ['var(--widgetsFont)'],
                fontSize: '12px',
                fontWeight: 500,
              },
              formatter: (value) => numberFormatter(+value, decimalDigits),
            },
            opposite: show === 'RIGHT',
            title: {
              text: displayName || name,
              style: {
                color: 'var(--widgetsFont)',
                fontSize: '12px',
                fontWeight: 500,
              },
            },
          };
          if (scale === 'MANUAL' && isFinite(minValue) && isFinite(maxValue)) {
            yAxisItem.forceNiceScale = false;
            yAxisItem.min = +minValue;
            yAxisItem.max = +maxValue;
          } else {
            yAxisItem.forceNiceScale = true;
            yAxisItem.min = minVal > 0 ? 0 : minVal;
            yAxisItem.max = maxVal < 0 ? 0 : maxVal;
          }

          return yAxisItem;
        })
      )
    : {
        forceNiceScale: true,
        min: minVal > 0 ? 0 : minVal,
        max: maxVal < 0 ? 0 : maxVal,
      };
};

export const getOptions = (
  xaxisCategories,
  xaxisPosition,
  yaxisCustomization,
  columnsColors,
  cellSize,
  widget,
  customization,
  data,
  navigateDashboard,
  series,
  defaultDecimalDigits
) => {
  const filteredColumnsColors = !series.find((s) => s.specific_asset)
    ? columnsColors.filter((c) => !c.specific_asset).map((c) => c.color)
    : columnsColors.map((c) => c.color);

  const {
    columnsWidthPercent = 50,
    subtitle_text,
    subtitle_textColor = 'var(--widgetsFont)',
    subtitle_alignment = 'CENTER',
    legend_visible = true,
    legend_position = 'BOTTOM',
    legend_alignment = 'CENTER',
    tooltip_visible = true,
    tooltip_shared,
    tooltip_showAxisLegend,
    tooltip_decimalDigits = defaultDecimalDigits,
    dataLabels_visible = true,
    dataLabels_textColor = 'var(--widgetsFont)',
    stacked = false,
    stackType = 'NORMAL',
  } = customization;
  const delimiter = getState().config?.whiteLabelDetails?.regionalSettings?.content?.delimiter;
  return {
    plotOptions: {
      bar: {
        columnWidth: `${columnsWidthPercent}%`,
      },
    },
    subtitle: {
      text: subtitle_text || '',
      align: subtitle_alignment.toLowerCase(),
      margin: 5,
      style: {
        color: subtitle_textColor,
      },
    },
    legend: {
      show: legend_visible,
      position: legend_position.toLowerCase(),
      horizontalAlign: legend_alignment.toLowerCase(),
      showForSingleSeries: true,
      offsetY: legend_position === 'TOP' ? 17 : 0,
      labels: {
        colors: 'var(--widgetsFont)',
        useSeriesColors: false,
      },
      markers: {
        shape: 'circle', // circle, square, line, plus, cross
        size: 5,
        strokeWidth: 0,
        radius: 2,
        offsetX: -2,
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: tooltip_visible,
      theme: 'apexcharts-tooltip',
      shared: tooltip_shared,
      intersect: false,
      y: {
        formatter: (val) => onTooltipFormatter(val, customization),
      },
      x: {
        show: tooltip_showAxisLegend,
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetX: 0,
        offsetY: 0,
      },
      items: {
        display: 'flex',
      },
    },
    dataLabels: {
      enabled: data?.results?.length < 1500 && dataLabels_visible,
      formatter: (value, { seriesIndex }) => {
        if (value === null) return '';

        return numberFormatter(
          +value,
          customization.columns && customization.columns[seriesIndex]?.decimalDigits
        );
      },
      style: {
        colors: [dataLabels_textColor],
        fontSize: '12px',
        fontWeight: 500,
      },
      dropShadow: {
        enabled: false,
      },
    },
    xaxis: {
      categories: xaxisCategories,
      labels: {
        show: true,
        offsetY: xaxisPosition === 'top' && -18,
        style: {
          colors: 'var(--widgetsFont)',
        },
      },
      position: xaxisPosition,
    },
    yaxis: stacked ? yaxisCustomization[0] : yaxisCustomization,
    colors: filteredColumnsColors,
    stroke: {
      show: true,
      colors:
        series?.map((s, i) => (s.type === 'line' ? filteredColumnsColors[i] : 'transparent')) ||
        filteredColumnsColors,
      width: 2,
    },
    fill: {
      opacity: 1,
    },
    chart: {
      toolbar: {
        export: {
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
      stacked: stacked,
      stackType: stackType === 'PERCENTAGE' ? '100%' : stackType,
      width: widget ? widget.w * cellSize : '100%',
      height: widget ? widget.h * cellSize : '100%',
      events: {
        dataPointSelection: (chartContext, seriesIndex, config) => {
          onChartClick(config, data, navigateDashboard, widget);
        },
        markerClick: (chartContext, seriesIndex, config) => {
          onChartClick(config, data, navigateDashboard, widget);
        },

        dataPointMouseEnter: (e) => {
          e.target.style.cursor =
            navigateDashboard && widget?.navigationDashboard?.length && 'pointer';
        },
        dataPointMouseLeave: (e) => {
          e.target.style.cursor = 'auto';
        },
      },
    },
  };
};
