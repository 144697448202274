import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import styles from './NewUserModal.scss';
import classNames from 'classnames';
import Icon from '@components/Icon';
import * as Yup from 'yup';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { useSelector } from '@src/redux/useSelector';
import { createUserLocalToServer, assingUserLocalToServer } from './localToServer';
import { getFieldsCfg } from './NewUserModal.utils';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { CAPTCHA_SCRIPT_URL } from '@core/utils';
import { authService } from '@core/auth/AuthService';
import { cssVarsService } from '@core/CssVarsService';

function NewUserModal(props: ModalComponentProps) {
  const { dismiss } = props;
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [userId, setUserId] = useState(null);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [emailValidation, setEmailValidation] = useState({
    email: '',
    message: '',
    isValid: false,
  });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [saving, setSaving] = useState(false);
  const isValidEmailRef = useRef(null);
  isValidEmailRef.current = isValidEmail;
  const emailValidationRef = useRef(null);
  emailValidationRef.current = emailValidation;
  const userIdRef = useRef(null);
  userIdRef.current = userId;

  const host = window.location.protocol + '//' + window.location.host;
  const apiKey = authService.getReCaptchaAPIKey(
    false,
    host.includes('localhost') ? process.env.BASE_URL : host
  );
  const [captchaToken, setCaptchaToken] = useState(null);
  const orgDetails = useSelector((store) => store.organizations);

  useEffect(() => {
    httpService
      .api({
        type: 'getRoles',
      })
      .then((res: any) => {
        setRolesOptions(
          res.map((t) => ({
            value: t.id,
            label: `users-roles.${t.id}`,
          }))
        );
      });

    const reCAPTCHAscript = document.createElement('script');
    reCAPTCHAscript.type = 'text/javascript';
    reCAPTCHAscript.src = CAPTCHA_SCRIPT_URL;
    (reCAPTCHAscript.defer = true), document.head.appendChild(reCAPTCHAscript);
  }, []);

  function handleRecaptcha(response) {
    setSaving(false);
    setCaptchaToken(response);
  }

  function handleRecaptchaExpired() {
    setSaving(false);
    setCaptchaToken('');
  }

  window['handleRecaptcha'] = handleRecaptcha;
  window['handleRecaptchaExpired'] = handleRecaptchaExpired;

  const initialValues = useMemo(
    () => ({
      email: '',
      title: 'NONE',
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      mobile: '',
      roles: '',
      address: orgDetails && orgDetails.address,
      timezone: orgDetails && orgDetails.timezone,
      language: orgDetails && orgDetails.defaultLanguage,
      dashboard: orgDetails && orgDetails.dashboard,
    }),
    [orgDetails]
  );

  const fieldsCfg = getFieldsCfg(
    emailValidation,
    setEmailValidation,
    userId,
    setUserId,
    dashboardOptions,
    organizationTypeMap[orgDetails.type],
    rolesOptions,
    setIsValidEmail
  );

  const onSubmit = (values) => {
    setSaving(true);
    const myInterval = setInterval(() => {
      if (isValidEmailRef.current && emailValidationRef.current.isValid) {
        save(values);
        clearInterval(myInterval);
      } else if (isValidEmailRef.current && !emailValidationRef.current.isValid) {
        clearInterval(myInterval);
        setIsValidEmail(false);
        setSaving(false);
      }
    }, 100);
  };

  const save = async (values: any) => {
    updateUserDetails(values);
  };

  const updateUserDetails = async (values) => {
    const newUser = userIdRef.current
      ? await assignUserToOrg({ ...values, captchaToken: captchaToken })
      : await createUser({ ...values, captchaToken: captchaToken });
    setSaving(false);
    setIsValidEmail(false);
    dismiss(newUser);
  };

  const createUserAlert = (user) => {
    modalService.openAlert({
      text: i18nService.translate('users.user-creation-message'),
      iconType: 'v_image',
    });
    return user;
  };

  const createUser = useCallback((values) => {
    return httpService
      .api({
        type: 'createUser',
        data: createUserLocalToServer(values),
      })
      .then((user) => {
        return createUserAlert(user);
      });
  }, []);

  const assignUserToOrg = useCallback((values) => {
    return httpService
      .api({
        type: 'assignUserToOrg',
        data: assingUserLocalToServer(values),
        urlParams: { userId: userIdRef.current },
      })
      .then((user) => {
        return createUserAlert(user);
      });
  }, []);

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  const validate = (values) =>
    [...fieldsCfg].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>users.new-user</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        <Formik
          enableReinitialize
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema([...fieldsCfg])}
          validate={validate}
          onSubmit={onSubmit}>
          {({ isValid, isSubmitting, values, errors, setFieldValue }) => {
            return (
              <FormikForm className={styles.form}>
                <div className={styles.content}>
                  {fieldsCfg.map((field, idx) =>
                    userId ? (
                      <FormikField
                        key={idx}
                        editMode={true}
                        {...field}
                        values={values}
                        setFieldValue={setFieldValue}></FormikField>
                    ) : (
                      <div className={styles.formikFieldWrapper}>
                        <FormikField
                          key={idx}
                          editMode={true}
                          {...field}
                          values={values}
                          setFieldValue={setFieldValue}></FormikField>
                      </div>
                    )
                  )}
                </div>
                <div
                  className={classNames('g-recaptcha', styles.recaptcha)}
                  style={{ transform: 'scale(0.78)' }}
                  data-sitekey={apiKey}
                  data-size={'normal'}
                  data-action={'INVITE_USER'}
                  data-callback={'handleRecaptcha'}
                  data-expired-callback={'handleRecaptchaExpired'}></div>
                <div className={styles.footerButtons}>
                  <Button styles={{ width: 92, marginRight: 13 }} onClick={cancel} mode="cancel">
                    {i18nService.translate('general.cancel')}
                  </Button>
                  <Button
                    styles={
                      !isValid || saving || !captchaToken
                        ? {
                            backgroundColor: `${cssVarsService.vars.colorDisabled}`,
                          }
                        : undefined
                    }
                    type="submit"
                    disabled={!isValid || saving || !captchaToken}>
                    {i18nService.translate('general.save')}
                  </Button>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default NewUserModal;
