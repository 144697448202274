import Button from '@components/Button';
import I18n from '@components/I18n';
import React from 'react';
import classNames from 'classnames';
import styles from './MainStatisticsButton.scss';

function MainStatisticsButton(props) {
  const { label, isActive, count, onClick, filter, disabled } = props;
  return (
    <Button
      disabled={disabled}
      onClick={() => onClick(filter)}
      styles={{
        height: 56,
        borderRadius: 4,
        width: 150,
        marginRight: 15,
        boxShadow: 'var(--boxShadow_1)',
        backgroundColor: isActive
          ? 'var(--systemButtonBackground)'
          : disabled
          ? 'var(--textDisabled)'
          : 'transparent',
      }}>
      <div className={styles.container}>
        <I18n className={classNames(styles.label, isActive && !disabled && styles.isActive)}>
          {label}
        </I18n>
        <div className={classNames(styles.count, isActive && !disabled && styles.isActive)}>
          {count}
        </div>
      </div>
    </Button>
  );
}

export default MainStatisticsButton;
