import React, { useCallback } from 'react';
import Select from '@components/Select';
import { editorConfig } from '@core/canvas/editorConfig';

/**
 * A dropdown that sets the canvas width.
 */
function CanvasWidthSelect({ canvasWidth, setCanvasWidth, currentLayout }) {
  const onChange = (selected) => {
    setCanvasWidth(selected);
  };

  return (
    <Select
      styles={{ control: { height: 37 }, menuPortal: { zIndex: 9999999999999 } }}
      menuPortalTarget={document.body}
      value={canvasWidth}
      options={editorConfig.canvasWidthOptions[`${currentLayout.toLowerCase()}`]}
      onChange={onChange}
    />
  );
}

export default CanvasWidthSelect;
