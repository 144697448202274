import I18n from '@components/I18n';
import Select from '@components/Select';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

const useStyles = makeStyles(() => ({
  wrapper: { display: 'block' },
  label: {
    fontSize: 14,
    fontWeight: 900,
    marginBottom: '8px',
  },
  row: {
    padding: '22px 0 20px 44px',
  },
  rowWithError: {
    padding: '22px 0 8px 44px',
  },
  select: {
    width: '200px',
    marginRight: '32px',
  },
  textFieldContainer: {
    width: '200px',
  },
  textField: {
    padding: '0 10px',
    width: '187px',
    height: '34px',
    margin: '0px',
    borderRadius: '5px',
    border: 'solid 1px var(--lightGrey)',
    backgroundColor: 'var(--white1)',
    color: 'var(--systemFont)',
  },
  error: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    color: 'var(--formikErrorText)',
    maxWidth: 790,
  },
}));

const selectCustomStyle = { control: { height: 36 }, container: { width: 187, marginRight: 8 } };

function NumberFormatting(props) {
  const { regionalSettingsData, displaySettingsData, onChangeData, setIsError } = props;
  const initialValues =
    regionalSettingsData?.numberFormatting || displaySettingsData?.numberFormatting;
  const [updatedValues, setUpdatedValues] = useState(initialValues || {});

  const classes = useStyles(props);
  const [showThousandsSeparatorError, setShowThousandsSeparatorError] = useState(false);
  const [showDecimalSeparatorError, setShowDecimalSeparatorError] = useState(false);

  useEffect(() => {
    setUpdatedValues(initialValues);
  }, [initialValues]);

  const thousandsSeparatorOptions = [
    {
      value: ',',
      label: ',',
    },
    {
      value: '.',
      label: '.',
    },
    {
      value: ' ',
      label: 'white-labeling.number-formatting.space',
    },
    {
      value: '',
      label: 'white-labeling.number-formatting.none',
    },
  ];

  const decimalSeparatorOptions = [
    {
      value: ',',
      label: ',',
    },
    {
      value: '.',
      label: '.',
    },
    {
      value: "'",
      label: "'",
    },
  ];

  const defaultDecimalDigitsOptions = Array.from({ length: 10 }, (_, i) => ({
    label: (0 + i).toString().padStart(1, ''),
    value: 0 + i,
  }));

  const cfg = [
    {
      label: 'white-labeling.number-formatting.thousands-separator',
      name: 'thousandsSeparator',
      type: 'select',
      options: thousandsSeparatorOptions,
    },
    {
      label: 'white-labeling.number-formatting.decimal-separator',
      name: 'decimalSeparator',
      type: 'select',
      options: decimalSeparatorOptions,
    },
    {
      label: 'white-labeling.number-formatting.default-decimal',
      name: 'defaultDecimalDigits',
      type: 'select',
      options: defaultDecimalDigitsOptions,
    },
  ];

  const onValueChanged = (option, name) => {
    let separatorsEqual = false;
    if (updatedValues.content.decimalSeparator !== updatedValues.content.thousandsSeparator) {
      setIsError && setIsError(false);
    }

    if (name === 'thousandsSeparator') {
      setShowDecimalSeparatorError(false);
      if (option.value === updatedValues.content.decimalSeparator) {
        setShowThousandsSeparatorError(true);
        setIsError && setIsError(true);
        separatorsEqual = true;
      } else {
        setShowThousandsSeparatorError(false);
        setIsError && setIsError(false);
      }
    } else if (name === 'decimalSeparator') {
      setShowThousandsSeparatorError(false);
      if (option.value === updatedValues.content.thousandsSeparator) {
        setShowDecimalSeparatorError(true);
        setIsError && setIsError(true);
        separatorsEqual = true;
      } else {
        setShowDecimalSeparatorError(false);
        setIsError && setIsError(false);
      }
    }

    setUpdatedValues({
      ...updatedValues,
      content: { ...updatedValues.content, [`${name}`]: option.value },
    });

    if (!separatorsEqual) {
      onChangeData('numberFormatting', `${name}`, option.value);
    }
  };

  const getValue = (options: any[], data) => {
    return options.find((opt) => opt.value === data);
  };

  return (
    <div className={classes.wrapper}>
      {cfg.map((item, idx) => (
        <div
          key={idx}
          className={
            showThousandsSeparatorError || showDecimalSeparatorError
              ? classes.rowWithError
              : classes.row
          }>
          <I18n className={classes.label}>{item.label}</I18n>
          <Select
            styles={selectCustomStyle}
            value={getValue(item.options, updatedValues.content[item.name])}
            options={item.options}
            onChange={(option) => onValueChanged(option, item.name)}
            maxMenuHeight={120}
          />
          {item.name === 'thousandsSeparator' ? (
            showThousandsSeparatorError ? (
              <I18n className={classes.error}>
                white-labeling.number-formatting.error-same-value-thousands
              </I18n>
            ) : null
          ) : item.name === 'decimalSeparator' ? (
            showDecimalSeparatorError ? (
              <I18n className={classes.error}>
                white-labeling.number-formatting.error-same-value-decimal
              </I18n>
            ) : null
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default NumberFormatting;
