import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from '@redux/useSelector';

// Components
import AssetInfoSubscriptionView from './AssetInfoSubscriptionView';
import AssetInfoSubscriptionEdit from './AssetInfoSubscriptionEdit';

// Services | Interfaces
import { httpService } from '@core/http/HttpService';
import styles from './AssetInfoSubscriptionMeta.scss';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { AssetSubscription } from '@src/redux/redux.interface';

function AssetInfoSubscriptionMeta(props) {
  const { remoteAccessMode, setRemoteAccessMode } = props;
  const cancel = useCallback(() => editAssetService.dismiss(false), []);
  const editAssetGeneral = useSelector((state) => state.editAsset.general);
  const { assetId, assetTypePlcModel, assetName } = editAssetGeneral;
  const [editMode, setEditMode] = useState(false);
  const [assetSubscription, setAssetSubscription] = useState<AssetSubscription>();
  //moment-duration-format is handles with more than 1h (in seconds)
  //in order to display the interval in the correct format.
  const moment = require('moment');
  const [duration, setDuration] = useState();

  useEffect(() => {
    getAssetSubscription();
  }, []);

  const getAssetSubscription = () => {
    httpService
      .api({ type: 'getAssetSubscription', urlParams: { assetId } })
      .then((res: AssetSubscription) => {
        setAssetSubscription(res);
        calculateDuration(res);
      });
  };

  const calculateDuration = (subscription) => {
    const durationTagsTimer =
      subscription && subscription.tagsTimer > 3600
        ? moment.duration(subscription.tagsTimer, 'seconds').format('HH:mm:ss')
        : moment.utc(subscription.tagsTimer * 1000).format('HH:mm:ss');
    setDuration(durationTagsTimer);
  };

  const onCancelEdit = (changed: boolean) => {
    if (changed) {
      getAssetSubscription();
    }
    setEditMode(false);
  };

  return (
    <div className="max-height">
      <div className={styles.formWrapper}>
        <AssetInfoSubscriptionView
          cancel={cancel}
          assetTypePlcModel={assetTypePlcModel}
          assetSubscription={assetSubscription}
          duration={duration}
          onEditClicked={() => setEditMode(!editMode)}
          assetId={assetId}
          assetName={assetName}
          getAssetSubscription={getAssetSubscription}
          onCancelEdit={onCancelEdit}
          remoteAccessMode={remoteAccessMode}
          setRemoteAccessMode={setRemoteAccessMode}
        />
      </div>
    </div>
  );
}

export default AssetInfoSubscriptionMeta;
