import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import styles from './WidgetFooter.scss';
import { WidgetFooterProps } from './WidgetFooter.interface';
import I18n from '@components/I18n';
import Button from '@components/Button';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';

const style = {
  root: {
    height: '28px',
    borderRadius: '25px',
    marginLeft: '10px',
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold' as 'bold',
    lineHeight: 'normal',
    color: 'var(--systemFont)',
  },
};

function WidgetFooter(props: WidgetFooterProps) {
  const {
    cancel,
    currentStep,
    prev,
    next,
    saveWidget,
    validation,
    widgetData,
    steps,
    enableSaveAsDraft,
  } = props;
  const { scope, metrics } = widgetData;
  const useSteps = useMemo(
    () =>
      !widgetData.eventTemplateId
        ? getConfigValueFromWidgetSettings(widgetData.type, 'useSteps')
        : [1, 2, 3, 4],
    []
  );
  const lastStep = useMemo(() => (useSteps ? useSteps[useSteps.length - 1] : 5), [useSteps]);

  return (
    <div className={styles.actionButtons}>
      <Button onClick={cancel} styles={{ marginLeft: 10 }} mode={['bigFont', 'cancel']}>
        <I18n>create-widget-page.cancel</I18n>
      </Button>
      <Button
        onClick={(e) => saveWidget()}
        disabled={
          !enableSaveAsDraft ||
          (['AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'].includes(scope) &&
            metrics.some((m) => m.valueType !== 'CALCULATION' && !m.operation))
        }
        styles={{ marginLeft: 10 }}
        mode="bigFont">
        <I18n>create-widget-page.save-as-draft</I18n>
      </Button>
      <Button
        onClick={prev}
        disabled={!steps || currentStep === steps[0]}
        styles={{ marginLeft: 10 }}
        mode="bigFont">
        <I18n>create-widget-page.back</I18n>
      </Button>
      <Button
        onClick={currentStep !== lastStep ? next : (e) => saveWidget(true)}
        disabled={!validation(widgetData)}
        styles={{ marginLeft: 10 }}
        mode="bigFont">
        <I18n>{currentStep !== lastStep ? 'create-widget-page.next' : 'general.finish'}</I18n>
      </Button>
    </div>
  );
}

export default withStyles(style)(WidgetFooter);
