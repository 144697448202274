import { updateDontShowAgain } from '@core/utils';
import { dispatch } from '@src/redux/store';
import { setModal } from '@src/redux/widgetModals';

export function openAndroidAppIsAvailableModal(isMobileAppInstalled: boolean) {
  dispatch(
    setModal({
      type: 'confirm',
      args: {
        headerText: 'mobile-app.app-available-pop-up.header',
        text: isMobileAppInstalled
          ? 'mobile-app.app-available-pop-up.text.app-installed.vnc-widget'
          : 'mobile-app.app-available-pop-up.text.app-not-installed.vnc-widget',
        confirmText: isMobileAppInstalled
          ? 'mobile-app.app-available-pop-up.open-in-unicloud-app'
          : 'mobile-app.app-available-pop-up.go-to-google-play',
        cancelText: 'general.cancel',
        showCloseBtn: true,
        updateDontShowAgainOnCancel: true,
        mode: 'smallFont',
      },
      onClose: (confirm) => {
        if (confirm?.res) {
          if (isMobileAppInstalled) {
            return window.open('app://com.unitronics.unicloud');
          } else {
            return window.open(
              'https://play.google.com/store/apps/details?id=com.unitronics.unicloud'
            );
          }
        }
      },
    })
  );
}

export function openIOSAppIsAvailableModal() {
  dispatch(
    setModal({
      type: 'confirm',
      args: {
        headerText: 'mobile-app.app-available-pop-up.header',
        text: 'mobile-app.app-available-pop-up.text.app-installed.vnc-widget',
        confirmText: 'general.confirm',
        showCloseBtn: true,
        updateDontShowAgainOnCancel: true,
        mode: 'smallFont',
      },
    })
  );
}
