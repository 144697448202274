import React, { useCallback, useState, useEffect, useMemo } from 'react';
import GooglePlacesInput from '@components/GooglePlacesInput';
import { i18nService } from '@core/i18n/I18nService';
import { useSubject } from '@core/effects';
import { useSelector } from '@redux/useSelector';
import { DateFilterValues } from '@redux/redux.interface.d';
import { userFiltersService } from '../UserFiltersService';
import MultiSelect from '@components/MultiSelect';
import DatePicker from '@components/DatePicker';
import moment from 'moment';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { dispatch } from '@src/redux/store';
import { resetLiveDashboardWidgetsData } from '@src/redux/liveDashboardWidgetsData';
import { isEqual } from 'lodash';
import AssetFilterComponent from '@components/AssetFilterComponent/AssetFilterComponent';
import { ShowClearButton } from '@components/Select/Select';
import ConfigurationPopover from '@src/components/ConfigurationPopover';

const dateFilterInitial: DateFilterValues = {
  dateFilter: 'MONTH',
  dateFilterFrom: null,
  dateFilterTo: null,
};

function FiltersPopover(props) {
  const { openFiltersPopover, anchorEl, closeFilters, setApplyButtonClicked } = props;
  const [resetFilters, setResetFilters] = useState(false);
  // Saved in redux values
  const organizations = useSelector((state) => state.dashboardEditor.organizationsFilter);
  const assetTypes = useSelector((state) => state.dashboardEditor.assetTypesFilter);
  const assets = useSelector((state) => state.dashboardEditor.assetFilter);
  const dateRange = useSelector((state) => state.dashboardEditor.dateFilter);
  const dateRangeFrom = useSelector((state) => state.dashboardEditor.dateFilterFrom);
  const dateRangeTo = useSelector((state) => state.dashboardEditor.dateFilterTo);
  const geography = useSelector((state) => state.dashboardEditor.geoFilter);

  // Dropdowns options
  const organizationOptions = useSubject(userFiltersService.organizationOptions$);
  const assetTypeOptions = useSubject(userFiltersService.assetTypeOptions$);
  // New selection values
  const [selectedOrganization, setSelectedOrganization] = useState(organizations);
  const [selectedAssetType, setSelectedAssetType] = useState(assetTypes);
  const [selectedGeography, setSelectedGeography] = useState(geography);
  const [selectedDateRange, setSelectedDateRange] = useState<DateFilterValues>({
    dateFilter: dateRange,
    dateFilterFrom: dateRangeFrom,
    dateFilterTo: dateRangeTo,
  });
  const [selectedAsset, setSelectedAsset] = useState(assets);

  const cancel = useCallback(() => closeFilters('cancel'), []);
  const submit = useCallback(() => {
    userFiltersService.setOrganizationsFilter(selectedOrganization);
    userFiltersService.setAssetTypesFilter(selectedAssetType);
    userFiltersService.setAssetFilter(selectedAsset);
    userFiltersService.setGeoFilter(selectedGeography);
    userFiltersService.setDateFilter(selectedDateRange);
    dispatch(resetLiveDashboardWidgetsData());
  }, [
    selectedOrganization,
    selectedAssetType,
    selectedGeography,
    selectedDateRange,
    selectedAsset,
  ]);

  const resetFiltersClicked = useCallback(() => {
    setSelectedOrganization([]);
    setSelectedAssetType([]);
    setSelectedAsset([]);
    setSelectedGeography([]);
    setSelectedDateRange(dateFilterInitial);
    setResetFilters(true);
    setTimeout(() => setResetFilters(false), 1000);
  }, []);

  useEffect(() => {
    userFiltersService.getOrganizationOptions();
    userFiltersService.getAssetTypeOptions();
  }, []);

  const disableApply = useMemo(() => {
    return (
      isEqual(organizations, selectedOrganization) &&
      isEqual(assetTypes, selectedAssetType) &&
      isEqual(assets, selectedAsset) &&
      isEqual(dateRange, selectedDateRange.dateFilter) &&
      isEqual(dateRangeFrom, selectedDateRange.dateFilterFrom) &&
      isEqual(dateRangeTo, selectedDateRange.dateFilterTo) &&
      isEqual(geography, selectedGeography)
    );
  }, [
    organizations,
    assetTypes,
    assets,
    dateRange,
    dateRangeFrom,
    dateRangeTo,
    geography,
    selectedAssetType,
    selectedAsset,
    selectedDateRange,
    selectedGeography,
    selectedOrganization,
  ]);

  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'VIEW'), []);

  const filterConfig = [
    {
      name: 'organizationFilter',
      label: 'filters.organization',
      Component: MultiSelect,
      props: {
        id: 'organizationOptionsPopover',
        options: organizationOptions,
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option.id,
        placeholder: i18nService.translate('general.all'),
        onChange: (filters) => setSelectedOrganization(filters),
        values: selectedOrganization,
        closeMenuOnSelectAll: true,
        optionType: 'checkbox',
      },
      isHidden: false,
    },
    {
      name: 'assetTypeFilter',
      label: 'filters.asset-type',
      Component: MultiSelect,
      props: {
        id: 'assetTypeOptionsPopover',
        options: assetTypeOptions,
        getOptionLabel: (options) => options.name,
        getOptionValue: (option) => option.id,
        placeholder: i18nService.translate('general.all'),
        onChange: (filters) => setSelectedAssetType(filters),
        values: selectedAssetType,
        closeMenuOnSelectAll: true,
        optionType: 'checkbox',
      },
      isHidden: !hasPermission,
    },
    {
      name: 'dateRangeFilter',
      label: 'filters.date-range',
      Component: DatePicker,
      props: {
        selectedChanged: setSelectedDateRange,
        minDate: moment().subtract(7, 'year').format('DD/MM/YYYY'),
        data: selectedDateRange,
      },
      isHidden: false,
    },
    {
      name: 'geoFilter',
      label: 'filters.geography',
      Component: GooglePlacesInput,
      props: {
        id: 'geographyOptionsPopover',
        defaultValue: selectedGeography,
        resetValues: resetFilters,
        placeholder: i18nService.translate('general.all'),
        allowSelectingUnknownAddress: true,
        onSelectedCountryChange: (filters) => setSelectedGeography(filters),
      },
      isHidden: false,
    },
    {
      name: 'assetFilter',
      label: 'create-dashboard-modal.asset',
      Component: AssetFilterComponent,
      props: {
        assetTypeSelected: selectedAssetType,
        assetSelected: selectedAsset,
        setAssetSelected: setSelectedAsset,
        onChange: (filters) => setSelectedAsset(filters),
        showClearButton: selectedAsset ? ShowClearButton.true : ShowClearButton.false,
      },
      isHidden: !hasPermission,
    },
  ];

  return (
    <ConfigurationPopover
      config={filterConfig}
      open={openFiltersPopover}
      close={closeFilters}
      onSubmit={submit}
      disableApply={disableApply}
      showClearAll
      onClearAll={resetFiltersClicked}
      anchorEl={anchorEl}
      setApplyButtonClicked={setApplyButtonClicked}
    />
  );
}

export default FiltersPopover;
