import moment from 'moment';
import { i18nService } from '@core/i18n/I18nService';

// Returns the range value of the selected label according to the minDate/maxDate props
export const getRange = (min, max, granularity, format, minDate, maxDate) => {
  if (
    moment(min, format).isSameOrAfter(moment(minDate, format), granularity) &&
    moment(max, format).isSameOrBefore(moment(maxDate, format), granularity)
  ) {
    return [min, max];
  }
  return null;
};

export const handleEvent = (
  event,
  picker,
  format,
  minDate,
  maxDate,
  setStart,
  setEnd,
  setChosenLabel,
  singleDatePicker,
  setLabel
) => {
  // TODO: split all the if's to mini functions
  if (event.type === 'apply') {
    const startDate = picker.startDate.format(format);
    const endDate = picker.endDate.format(format);
    if (minDate && maxDate) {
      const isInRange =
        moment(startDate, format).isSameOrAfter(moment(minDate, format), 'day') &&
        moment(endDate, format).isSameOrBefore(moment(maxDate, format), 'day');
      if (!isInRange) return;
    }

    setStart(startDate);
    setEnd(endDate);
    setChosenLabel(picker.chosenLabel);
    setDatePickerLabel(startDate, endDate, picker.chosenLabel, singleDatePicker, setLabel);
  }
};

export const setDatePickerLabel = (
  startDate,
  endDate,
  pickerChosenLabel,
  singleDatePicker,
  setLabel
) => {
  // TODO: split all the if's to mini functions

  if (singleDatePicker) {
    setLabel(
      pickerChosenLabel && pickerChosenLabel !== i18nService.translate('date-picker.custom')
        ? pickerChosenLabel
        : startDate
    );
  } else {
    setLabel(
      pickerChosenLabel !== i18nService.translate('date-picker.custom')
        ? pickerChosenLabel
        : startDate === endDate
        ? startDate
        : `${startDate} - ${endDate}`
    );
  }
};

const timeUpdate = (
  type,
  dateDifference,
  rangeLabel,
  format,
  setStart,
  setEnd,
  setChosenLabel,
  rangesLabels,
  singleDatePicker,
  setLabel
) => {
  setStart(moment().subtract(dateDifference, type).format(format));
  setEnd(moment().format(format));
  setDatePickerLabel(
    moment().subtract(dateDifference, type).format(format),
    moment().format(format),
    rangesLabels[rangeLabel],
    singleDatePicker,
    setLabel
  );
  setChosenLabel(rangesLabels[rangeLabel]);
};

export const setTimeUpdate = (
  data,
  BEformat,
  format,
  setStart,
  setEnd,
  setChosenLabel,
  rangesLabels,
  singleDatePicker,
  setLabel
) => {
  if (data) {
    switch (data.dateFilter) {
      case 'DAY':
        timeUpdate(
          1,
          'days',
          'lastDay',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'WEEK':
        timeUpdate(
          6,
          'days',
          'lastWeek',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;

      case 'MONTH':
        timeUpdate(
          1,
          'months',
          'lastMonth',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;

      case 'YEAR':
        timeUpdate(
          1,
          'year',
          'lastYear',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;

      case 'LAST_HOUR':
        timeUpdate(
          1,
          'hour',
          'lastHour',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'LAST_TWO_HOURS':
        timeUpdate(
          2,
          'hour',
          'last2Hours',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'LAST_FOUR_HOURS':
        timeUpdate(
          4,
          'hour',
          'last4Hours',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'LAST_SIX_HOURS':
        timeUpdate(
          6,
          'hour',
          'last6Hours',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'LAST_EIGHT_HOURS':
        timeUpdate(
          8,
          'hour',
          'last8Hours',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'LAST_TWELVE_HOURS':
        timeUpdate(
          12,
          'hour',
          'last12Hours',
          format,
          setStart,
          setEnd,
          setChosenLabel,
          rangesLabels,
          singleDatePicker,
          setLabel
        );
        break;
      case 'CUSTOM':
        const from = moment(data.dateFilterFrom, BEformat).format(format);
        const to = moment(data.dateFilterTo, BEformat).format(format);
        setStart(from);
        setEnd(to);
        setDatePickerLabel(
          from,
          to,
          i18nService.translate('date-picker.custom'),
          singleDatePicker,
          setLabel
        );
        setChosenLabel(i18nService.translate('date-picker.custom'));
        break;
      case 'CUSTOM_OPTIONAL':
        const fromOptional = moment(data.dateFilterFrom, BEformat).format(format);
        const toOptional = moment(data.dateFilterTo, BEformat).format(format);
        data.dateFilterFrom && setStart(fromOptional);
        data.dateFilterTo && setEnd(toOptional);

        if (singleDatePicker) {
          if (data.dateFilterFrom)
            setDatePickerLabel(
              fromOptional,
              toOptional,
              i18nService.translate('date-picker.custom'),
              singleDatePicker,
              setLabel
            );
          else {
            setStart(undefined);
            setEnd(undefined);
            setLabel(undefined);
          }
        } else {
          if (data.dateFilterFrom && singleDatePicker)
            setDatePickerLabel(
              fromOptional,
              toOptional,
              i18nService.translate('date-picker.custom'),
              singleDatePicker,
              setLabel
            );
          else {
            setStart(undefined);
            setEnd(undefined);
            setLabel(undefined);
          }
        }

        setChosenLabel('OPTIONAL');
        break;
    }
  }
};

export const setDateFilterLabel = (
  chosenLabel,
  rangesLabels,
  start,
  end,
  BEformat,
  format,
  selectedChanged
) => {
  if (!chosenLabel && !start && !end) return;

  const dateFilter = {
    dateFilterFrom: null,
    dateFilterTo: null,
  };
  switch (chosenLabel) {
    case rangesLabels.lastDay:
      dateFilter['dateFilter'] = 'DAY';
      break;
    case rangesLabels.lastWeek:
      dateFilter['dateFilter'] = 'WEEK';
      break;
    case rangesLabels.lastMonth:
      dateFilter['dateFilter'] = 'MONTH';
      break;
    case rangesLabels.lastYear:
      dateFilter['dateFilter'] = 'YEAR';
      break;
    case rangesLabels.lastHour:
      dateFilter['dateFilter'] = 'LAST_HOUR';
      break;
    case rangesLabels.last2Hours:
      dateFilter['dateFilter'] = 'LAST_TWO_HOURS';
      break;
    case rangesLabels.last4Hours:
      dateFilter['dateFilter'] = 'LAST_FOUR_HOURS';
      break;
    case rangesLabels.last6Hours:
      dateFilter['dateFilter'] = 'LAST_SIX_HOURS';
      break;
    case rangesLabels.last8Hours:
      dateFilter['dateFilter'] = 'LAST_EIGHT_HOURS';
      break;
    case rangesLabels.last12Hours:
      dateFilter['dateFilter'] = 'LAST_TWELVE_HOURS';
      break;
    case i18nService.translate('date-picker.custom'):
      dateFilter['dateFilter'] = 'CUSTOM';
      dateFilter['dateFilterFrom'] = moment(start, format).format(BEformat);
      dateFilter['dateFilterTo'] = moment(end, format).format(BEformat);
      break;
    case 'OPTIONAL':
      dateFilter['dateFilter'] = 'CUSTOM_OPTIONAL';
      dateFilter['dateFilterFrom'] = start ? moment(start, format).format(BEformat) : undefined;
      dateFilter['dateFilterTo'] = end ? moment(end, format).format(BEformat) : undefined;
      break;
    case null: // in case the picker is displayed without ranges label
      dateFilter['dateFilterFrom'] = moment(start, format).format(BEformat);
      dateFilter['dateFilterTo'] = moment(end, format).format(BEformat);
  }
  selectedChanged(dateFilter);
};
