import React from 'react';
import { editorConfig } from '@core/canvas/editorConfig';
import { useSelector } from '@redux/useSelector';

const cellSize = editorConfig.gridCellSizePx;

function GridIndicator() {
  const showGrid = useSelector((state) => state.dashboardEditor.showGrid);

  if (!showGrid) {
    return null;
  }

  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="canvasGrid" width={cellSize} height={cellSize} patternUnits="userSpaceOnUse">
          <path
            d={`M ${cellSize} 0 L 0 0 0 ${cellSize}`}
            fill="none"
            stroke="var(--canvasGridColor)"
            strokeDasharray="1 1"
            strokeWidth="1"
          />
        </pattern>
      </defs>

      <rect width="100%" height="100%" fill="url(#canvasGrid)" />
    </svg>
  );
}

export default React.memo(GridIndicator);
