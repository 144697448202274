import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import MaterialCheckbox from '@components/Checkbox';

import Icon from '@components/Icon';
import styles from './Checkbox.scss';

const dataTypeIconMap = {
  INT32: 'numberType',
  UINT32: 'numberType',
  FLOAT32: 'floatType',
  BOOLEAN: 'booleanType',
  STRING: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
  INT8: 'numberType',
  UINT8: 'numberType',
  INT16: 'numberType',
  UINT16: 'numberType',
  GEO: 'geoType',
  DURATION: 'numberType',
};

const AssetProps = (props) => {
  const {
    selectProps: { tableConfig, showCheckbox = true },
    data,
  } = props;
  const keys = Object.keys(tableConfig(data));

  return (
    <components.Option {...props}>
      <div className={styles.row} title={props.children}>
        {data.value === '*' ? (
          <>
            {showCheckbox && <MaterialCheckbox color="primary" checked={data.meta.isAllSelected} />}
            <span className={classNames(styles.oneColumn, 'ellipsis-overflow')}>{data.label}</span>
          </>
        ) : (
          <>
            {showCheckbox && <MaterialCheckbox color="primary" checked={props.isSelected} />}
            <div className={styles.dataIcon}>
              <Icon
                className={styles.dataIcon}
                type={dataTypeIconMap[tableConfig(data)[keys[0]]]}
              />
            </div>
            <span className={classNames(styles.oneColumn, 'ellipsis-overflow')}>
              {props.children}
            </span>
          </>
        )}
      </div>
    </components.Option>
  );
};

export default AssetProps;
