import React, { useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';

import Icon from '@components/Icon';
import I18n from '@components/I18n';
import NewLogo from '@components/NewLogo';
import styles from './OnboardingActionMessage.scss';
import { OnboardingActionMessageProps } from './OnboardingActionMessage.interface';
import { useSelector } from 'react-redux';
import { buildImageUrl } from '@core/utils';

const OnboardingErrorAction = (props: OnboardingActionMessageProps) => {
  const { text, actionBtnText, headerText, onClickAction, isError, callback } = props;
  const whiteLabelDetails = useSelector((state) => state['config'].whiteLabelDetails);
  const isMobileView = useSelector((state) => state['viewport'].isMobileView);

  useEffect(() => {
    callback && callback();
  }, []);

  return (
    <div className={classNames('max-height', styles.wrapper)}>
      <div className={styles.logo}>
        <NewLogo />
      </div>
      <div className={classNames(styles.container, isMobileView && styles.containerMobile)}>
        <Typography align="center" className={styles.header}>
          <I18n>{headerText}</I18n>
        </Typography>
        <div className={styles.messageContainer}>
          <div className={styles.messageText}>
            {isError && <Icon type="largeError" className={styles.errorIcon} />}
            <div className={isError ? styles.errorText : styles.successText}>
              <I18n noEllipsis>{text}</I18n>
            </div>
          </div>
        </div>
        {actionBtnText && (
          <Button
            className={styles.actionBtn}
            type="submit"
            variant="contained"
            onClick={onClickAction}>
            <I18n>{actionBtnText}</I18n>
          </Button>
        )}
      </div>
    </div>
  );
};

export default OnboardingErrorAction;
