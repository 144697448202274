import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import ColorInput from '@components/ColorInput';
import Select from '@components/Select';
import { TextField } from '@material-ui/core';
import { alignmentOptions, positionOptions } from '@core/canvas/widget.utils';
import MaterialCheckbox from '@components/Checkbox';
import { changeWidgetData } from '../../Heatmap.utils';

function Legend({ classes, rangesChart, setWidgetData }) {
  return (
    <div className={classes.section}>
      <div className={classes.titleWrapper}>
        <I18n>create-widget-page.create-widget.step-four.legend</I18n>
      </div>
      <div className={classes.item}>
        <MaterialCheckbox
          color="primary"
          checked={rangesChart.legend.visible}
          onClick={() =>
            changeWidgetData(
              {
                ...rangesChart,
                legend: { ...rangesChart.legend, visible: !rangesChart.legend.visible },
              },
              'rangesChart',
              setWidgetData
            )
          }
        />
        <I18n className={classes.cbLabel}>create-widget-page.create-widget.step-four.visible</I18n>
      </div>
      <div className={classes.item}>
        <I18n className={classes.text}>create-widget-page.create-widget.step-four.position</I18n>
        <Select
          styles={classes.selectFullWidthCustomStyle}
          disabled={!rangesChart.legend.visible}
          options={positionOptions}
          value={positionOptions.find((option) => option.value === rangesChart.legend.position)}
          onChange={(option) =>
            changeWidgetData(
              { ...rangesChart, legend: { ...rangesChart.legend, position: option.value } },
              'rangesChart',
              setWidgetData
            )
          }
          unSelect={true}
        />
      </div>
      <div className={classes.item}>
        <I18n className={classes.text}>create-widget-page.create-widget.step-four.aligment</I18n>
        <Select
          styles={classes.selectFullWidthCustomStyle}
          disabled={!rangesChart.legend.visible}
          options={alignmentOptions}
          value={alignmentOptions.find((option) => option.value === rangesChart.legend.alignment)}
          onChange={(option) =>
            changeWidgetData(
              { ...rangesChart, legend: { ...rangesChart.legend, alignment: option.value } },
              'rangesChart',
              setWidgetData
            )
          }
          unSelect={true}
        />
      </div>
    </div>
  );
}

export default Legend;
