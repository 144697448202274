import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { InputBase } from '@material-ui/core';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { FormikRouterInputProps, RouterSerial } from './FormikRouterInput.interface';
import styles from './FormikRouterInput.scss';
import { i18nService } from '@core/i18n/I18nService';
import { handleBlurRouterInput, validateRouterSerial } from './FormikRouterInput.utils';
import DataViewer from '@components/DataViewer';

const validate = (value: RouterSerial, isRequired: boolean) => {
  if ((!value.text || value.text.length <= 0) && !isRequired) {
    return undefined;
  } else if ((!value.text || value.text.length <= 0) && isRequired) {
    return i18nService.translate('validations.router-missing-serial-number');
  } else if (!value.id) {
    return i18nService.translate('validations.router-validation');
  } else if (value.message) {
    return value.message;
  } else {
    return undefined;
  }
};

function FormikRouterInput(props: FormikRouterInputProps) {
  const {
    formik,
    name,
    label,
    disabled,
    type,
    className,
    value,
    isRequired = true,
    allowRowsEllipsis,
  } = props;

  const [catalogNumber, setCatalogNumber] = useState(null);
  const [routerSerial, setRouterSerial] = useState({});
  const btnClicked = useRef(false);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const initialValuesRouter = getIn(formik.initialValues, name);

  useEffect(() => {
    if (value && routerSerial['id'] !== value.id) {
      setRouterSerial(value);
      setCatalogNumber(value['catalog']);
    }
  }, [value]);

  useEffect(() => {
    formik.registerField(name, { props: { validate: (value) => validate(value, isRequired) } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      formik.unregisterField(name);
    };
  }, [name]);

  const dataViewerData = useMemo(
    () => [{ label: 'create-asset-modal.cat-num', value: catalogNumber }],
    [catalogNumber]
  );

  const handleChange = useCallback(
    (e) => {
      const fieldVal = e.target.value.trim();
      const routerVal = { id: null, text: fieldVal, message: null };
      setRouterSerial(routerVal);
      setCatalogNumber(null);
      formik.setFieldValue(name, routerVal);
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    handleBlurRouterInput(btnClicked, formik, name);
  }, [formik, btnClicked]);

  const validateRouter = useCallback(() => {
    validateRouterSerial(
      btnClicked,
      type,
      routerSerial,
      formik,
      initialValuesRouter,
      setCatalogNumber,
      name
    );
  }, [formik, routerSerial]);

  return (
    <>
      <div className={classNames(styles.fieldWrapper, className)}>
        <label
          className={classNames(
            styles.fieldLabel,
            isRequired && 'asterisk',
            'ellipsis-overflow',
            type === 'associateToAsset' ? styles.fieldLabel_associateToAsset : ''
          )}>
          <I18n>{label}</I18n>:
        </label>
        <div className={styles.fieldInput}>
          <div className={styles.formikRouterInputWrapper}>
            <InputBase
              id={
                error && touched
                  ? styles.formikRouterErrorInput
                  : type == 'associateToAsset'
                  ? styles.formikRouterInput_associateToAsset
                  : styles.formikRouterInput
              }
              value={routerSerial ? routerSerial['text'] : null}
              disabled={disabled}
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {error && (
              <Button
                mode="outlined"
                disabled={!routerSerial || !routerSerial['text']}
                styles={{ marginLeft: 8, maxWidth: 200 }}
                onClick={validateRouter}>
                <I18n>router-validation.validate</I18n>
              </Button>
            )}
          </div>

          <ErrorMessage name={name}>
            {(err) =>
              typeof err === 'string' ? (
                <I18n
                  className={classNames(styles.error, allowRowsEllipsis && styles.rowsEllipsis)}
                  element="div"
                  noEllipsis={allowRowsEllipsis}>
                  {err}
                </I18n>
              ) : null
            }
          </ErrorMessage>
        </div>
      </div>
      {type === 'associateToAsset' && catalogNumber && (
        <DataViewer
          className={styles.formikPlcCatalogNumber}
          data={dataViewerData}
          opacity
          boldLabel
        />
      )}
    </>
  );
}

export default connect(FormikRouterInput);
