import React, { useCallback, useState, useRef, useEffect } from 'react';
import EventListener from 'react-event-listener';
import { CircularProgress, makeStyles, TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import I18n from '@components/I18n';
import { modalService } from '@core/modals/ModalService';
import { ModalComponentProps } from '@core/modals/modals.interface.d';
import Button from '@components/Button';
import { httpService } from '@http/HttpService';
import { GetAvailableTagTypesRes, AvailableTagType } from '@http/server.interface.d';
import { editAssetTypeService } from '@modals/EditAssetTypeModal/EditAssetTypeService';
import { i18nService } from '@i18n/I18nService';
import { useSelector } from '@redux/useSelector';
import {
  getOptions,
  deleteByBackspace,
  focusOnInput,
  getFetchOptions,
  addEditRemoneTagType,
  onChange,
} from './assignTagTypeModal.utils';
import styles from './AssignTagTypeModal.scss';
import { buildErrorObj } from '@core/utils';
import Icon from '@components/Icon';

interface AssignTagTypeModalProps extends ModalComponentProps {
  args: {
    tagId: string;
    type: string;
    name: string;
  };
}

const stylesMaterial = makeStyles(() => ({
  root: {
    '& .MuiChip-root': {
      color: 'var(--systemFont)',
    },
    '& .MuiAutocomplete-input': {
      color: 'var(--systemFont)',
    },
  },
}));

function AssignTagTypeModal(props: AssignTagTypeModalProps) {
  const { args, dismiss } = props;
  const { tagId, type, name } = args;
  const [options, setOptions] = useState<AvailableTagType[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selected, setSelected] = useState(type ? [{ id: undefined, name: type }] : []);
  const timer = useRef(undefined);
  const inputValue = useRef('');
  const autocompleteEl = useRef<HTMLDivElement>(null);
  const saveEnabled = !isFetching && type ? (selected[0] || {}).name !== type : selected.length > 0;
  const classes = stylesMaterial(props);

  useEffect(() => {
    focusOnInput(autocompleteEl);
  }, []);

  /**
   * The user can delete the selected chip by using backspace.
   */
  const onKeyup = useCallback(
    (event) => {
      deleteByBackspace(event, autocompleteEl, selected, setSelected);
    },
    [selected]
  );

  const fetchOptions = useCallback(
    (withBusy: boolean = false) => {
      getFetchOptions(setOptions, inputValue, withBusy, setIsFetching, tagId);
    },
    [tagId]
  );

  const save = useCallback(() => {
    addEditRemoneTagType(type, selected, tagId, type, dismiss);
  }, [type, tagId, selected]);

  const close = () => {
    dismiss();
  };

  const onInput = useCallback(
    (event) => {
      inputValue.current = event.target.value;
      setOptions(getOptions(options, inputValue.current));

      // Debounced search.
      clearTimeout(timer.current);
      timer.current = setTimeout(() => fetchOptions(true), 300);
    },
    [options]
  );

  const getOptionLabel = useCallback((option) => option.name, []);

  const renderInput = useCallback(
    (params) => {
      const placeholder =
        selected.length > 0
          ? ''
          : i18nService.translate('assign-tag-type-modal.search-placeholder');

      return (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            maxLength: 15,
          }}
          InputProps={{
            ...params.InputProps,
            placeholder,
            endAdornment: (
              <React.Fragment>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    },
    [isFetching, selected]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n tagName={name}>update-tag-type-modal.header</I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <EventListener target="window" onKeyup={onKeyup} />
        <Autocomplete
          className={styles.autocomplete}
          classes={{
            root: classes.root,
          }}
          freeSolo
          multiple
          autoHighlight
          disabled={selected.length > 0}
          onInput={onInput}
          value={selected}
          onChange={(event, value) => onChange(event, value, setSelected, inputValue, fetchOptions)}
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
          ref={autocompleteEl}
        />
      </div>
      <div className={styles.buttonRow}>
        <Button mode="bigFont" disabled={!saveEnabled} onClick={save}>
          <I18n>general.save</I18n>
        </Button>
      </div>
    </div>
  );
}

export default React.memo(AssignTagTypeModal);
