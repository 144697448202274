import Default from './ChildrenTemplates/Default';
import Checkbox from './ChildrenTemplates/Checkbox';
import TableWithCheckbox from './ChildrenTemplates/TableWithCheckbox';
import TagDataOptions from './ChildrenTemplates/TagDataOptions';
import HeaderTableCheckbox from './MenuHeader/HeaderTableCheckbox';
import HeaderTagData from './MenuHeader/HeaderTagData';
import DefaultMenuList from './MenuHeader/DefaultMenuList';
import AssetProps from './ChildrenTemplates/AssetProps';
import VariableDataOptions from './ChildrenTemplates/VariableDataOptions';

export const optionsMap = {
  default: {
    option: Default,
    header: DefaultMenuList,
  },
  checkbox: {
    option: Checkbox,
    header: DefaultMenuList,
  },
  tableWithCheckbox: {
    option: TableWithCheckbox,
    header: HeaderTableCheckbox,
  },
  tagDataOptions: {
    option: TagDataOptions,
    header: HeaderTagData,
  },
  variableDataOptions: {
    option: VariableDataOptions,
    header: HeaderTagData,
  },
  assetProps: {
    option: AssetProps,
    header: DefaultMenuList,
  },
};
