import * as RI from '../redux.interface.d';

export const setSelectedWidgetDimenssions = (
  selectedWidgetDimenssions: Partial<RI.DashboardEditorState>
): RI.StoreAction => ({
  type: 'editor/SET_WIDGET_DIMENSSIONS',
  payload: selectedWidgetDimenssions,
});

export const initEditor = (state: Partial<RI.DashboardEditorState>): RI.StoreAction => ({
  type: 'editor/INIT',
  payload: state,
});

export const resetEditor = (currentLayout?: string): RI.StoreAction => ({
  type: 'editor/RESET',
  payload: currentLayout,
});

export const setDashboardName = (name: string): RI.StoreAction => ({
  type: 'editor/SET_NAME',
  payload: name,
});

export const setCanvasHeight = (height: number): RI.StoreAction => ({
  type: 'editor/SET_CANVAS_HEIGHT',
  payload: height,
});

export const addCanvasWidget = (widget: Partial<RI.CanvasWidget>): RI.StoreAction => ({
  type: 'editor/ADD_WIDGET',
  payload: widget,
});

export const setDashboardWidgets = (widgets: Array<Partial<RI.CanvasWidget>>): RI.StoreAction => ({
  type: 'editor/SET_DASHBOARD_WIDGETS',
  payload: widgets,
});

export const setCanvasWidgets = (
  widgets: Array<Partial<RI.CanvasWidget>>,
  currentLayout: string
): RI.StoreAction => ({
  type: 'editor/SET_CANVAS_WIDGETS',
  payload: { widgets, currentLayout },
});

export const clearWidgetSelection = (): RI.StoreAction => ({
  type: 'editor/CLEAR_WIDGET_SELECTION',
  payload: null,
});

export const selectWidgetById = (id: number, options: { isMultiSelect?: boolean } = {}) => ({
  type: 'editor/SELECT_WIDGET',
  payload: { id, isMultiSelect: options.isMultiSelect },
});

export const unselectWidgetById = (id: number) => ({
  type: 'editor/UNSELECT_WIDGET',
  payload: { id },
});

export const editCanvasWidgetById = (
  id: number,
  widget: Partial<RI.CanvasWidget>,
  currentLayout: string
): RI.StoreAction => ({
  type: 'editor/EDIT_WIDGET',
  payload: { id, widget, currentLayout },
});

export const setCanvasWidgetStatesById = (
  id: number,
  states: Array<RI.WidgetState>
): RI.StoreAction => ({
  type: 'editor/SET_WIDGET_STATES',
  payload: { id, states },
});

export const removeCanvasWidget = (widgetId: number | number[]): RI.StoreAction => ({
  type: 'editor/RM_WIDGET',
  payload: widgetId,
});

export const setCanvasWidth = (canvasWidth: RI.CanvasWidthOption): RI.StoreAction => ({
  type: 'editor/SET_CANVAS_WIDTH',
  payload: canvasWidth,
});

export const setRefreshRate = (refreshRate: number): RI.StoreAction => ({
  type: 'editor/SET_REFRESH_RATE',
  payload: refreshRate,
});

export const setShowGrid = (showGrid: boolean): RI.StoreAction => ({
  type: 'editor/SET_SHOW_GRID',
  payload: showGrid,
});

export const setIsSplitLayout = (isSplitLayout: boolean): RI.StoreAction => ({
  type: 'editor/SET_LAYOUT_MODE',
  payload: isSplitLayout,
});

export const setCurrentLayout = (currentLayout: string): RI.StoreAction => ({
  type: 'editor/SET_CURRENT_LAYOUT',
  payload: currentLayout,
});

export const setDashboardStates = (dashboardStates: Array<any>): RI.StoreAction => ({
  type: 'editor/SET_DASHBOARD_STATES',
  payload: dashboardStates,
});

export const setOrganizationsFilter = (
  organizationsFilter: Array<RI.FilterItem>
): RI.StoreAction => ({
  type: 'editor/SET_ORGANIZATIONS_FILTER',
  payload: organizationsFilter,
});

export const setAssetTypesFilter = (assetTypesFilter: Array<RI.FilterItem>): RI.StoreAction => ({
  type: 'editor/SET_ASSET_TYPES_FILTER',
  payload: assetTypesFilter,
});

export const setAssetFilter = (assetFilter: Array<RI.FilterItem>): RI.StoreAction => ({
  type: 'editor/SET_ASSET_FILTER',
  payload: assetFilter,
});

export const setGeoFilter = (geoFilter: Array<RI.GeoFilter>): RI.StoreAction => ({
  type: 'editor/SET_GEO_FILTER',
  payload: geoFilter,
});

export const setDateFilter = (dateFilterValues: RI.DateFilterValues): RI.StoreAction => ({
  type: 'editor/SET_DATE_FILTER',
  payload: dateFilterValues,
});

export const setFilterWidgetVisibility = (visible: boolean): RI.StoreAction => ({
  type: 'editor/SET_FILTER_WIDGET_VISIBILITY',
  payload: visible,
});

export const setUserFilters = (filters: Array<RI.UserFilterItem>): RI.StoreAction => ({
  type: 'editor/SET_FILTERS',
  payload: filters,
});

export const setSelectionBox = (selectionBox: boolean): RI.StoreAction => ({
  type: 'editor/SET_SELECTION_BOX',
  payload: selectionBox,
});
