import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './MuiExpandMoreIcon.scss';

function MuiExpandMoreIcon(props) {
  const { id } = props;
  return <ExpandMoreIcon id={id ? id : 'expandMoreIcon'} className={styles.expandMoreIcon} />;
}

export default MuiExpandMoreIcon;
