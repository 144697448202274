import React, { useState, useEffect } from 'react';
import styles from './CompanyDetails.scss';
import I18n from '@components/I18n';
import Form from '@components/Form';

import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';

const fieldsCfg = [
  { label: 'company-details.company-name', name: 'name', type: 'input' },
  { label: 'company-details.address', name: 'address', type: 'input' },
  { label: 'company-details.default-email', name: 'email', type: 'input' },
];

function CompanyDetails(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    const getCompanyDetails = async () => {
      const res = await httpService.api({ type: 'getCompanyDetails' });
      if (res) {
        if (!res['address'] || res['address'] === 'Unknown') {
          res['address'] = i18nService.translate('company-details.address.unknown');
        } else {
          res['address'] = res['address'].formatted || res['address'].country;
        }
        setData(res);
      }
    };

    getCompanyDetails();
  }, []);

  return (
    <div className={styles.box}>
      <div className={styles.boxBody}>
        <Form enableReinitialize data={data} fieldsCfg={fieldsCfg} editMode={false}></Form>
      </div>
    </div>
  );
}

export default CompanyDetails;
