import React from 'react';
import styles from './TagDataSection.scss';
import { getMetricName } from '@pages/CreateWidgetPage/widgets.utils';
import I18n from '@components/I18n';
import classNames from 'classnames';
import { numberFormatter } from '@core/utils';
import { useSelector } from '@src/redux/useSelector';
import { buildDateTime } from '@components/widgets/charts.utils';
import { tagFormatMap } from '@core/mapsAndDefinitions';

function TagDataSection(props) {
  const { widgetData, dataToDisplay } = props;
  const defaultDecimalDigits = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
  );

  function getValue(value, format, dateTimeFormat, decimalDigits = defaultDecimalDigits) {
    switch (tagFormatMap[format]) {
      case 'stringType':
      case 'numberType':
      case 'booleanType':
        return value;
      case 'floatType':
        return numberFormatter(+value, decimalDigits);
      case 'dateTimeType':
        return buildDateTime(value, dateTimeFormat, 'momentFormat', undefined);
    }

    return isNaN(Number(value)) || format === 'STRING'
      ? value
      : typeof value === 'boolean'
      ? (value + '').toUpperCase()[0] + (value + '').slice(1)
      : numberFormatter(+value, decimalDigits);
  }

  return (
    <div className={styles.wrapper}>
      {dataToDisplay.map((d, idx) => (
        <div key={idx} className={classNames(styles.dataRow, 'ellipsis-overflow')}>
          <I18n element={'span'} style={{ fontWeight: 'bold' }}>
            {d.displayName ? d.displayName : getMetricName(widgetData, d)}
          </I18n>
          :
          {d.symbol ? (
            d.showSymbolLeft ? (
              <I18n element={'span'} style={{ marginLeft: '5px' }}>
                {d.symbol}
                {getValue(d.value, d.format, d.dateTimeFormat, d.decimalDigits)}
              </I18n>
            ) : (
              <I18n element={'span'} style={{ marginLeft: '5px' }}>
                {getValue(d.value, d.format, d.dateTimeFormat, d.decimalDigits)}
                {d.symbol}
              </I18n>
            )
          ) : (
            <I18n element={'span'} style={{ marginLeft: '5px' }}>
              {getValue(d.value, d.format, d.dateTimeFormat, d.decimalDigits)}
            </I18n>
          )}
        </div>
      ))}
    </div>
  );
}

export default TagDataSection;
