import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import MaterialCheckbox from '@components/Checkbox';
import moment from 'moment';
import DatePicker from '@components/DatePicker';
import { ChoseDateModalProps } from './ChoseDateModal.interface';
import styles from './ChoseDateModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';

function ChoseDateModal(props: ChoseDateModalProps) {
  const { args, dismiss } = props;
  const { confirmText, cancelText, showCloseBtn, headerText, style = {}, ...rest } = args;
  const confirmBtn = useRef(null);
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const [isToday, setIsToday] = useState(true);
  const [chosenDate, setCosenDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    confirmText && confirmBtn.current.focus();
  }, []);

  const onConfirm = () => {
    const tempDate = isToday ? moment(new Date()).format('YYYY-MM-DD') : chosenDate;
    tempDate ? dismiss(tempDate) : null;
  };

  return (
    <div
      className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}
      style={style ? style : {}}>
      {showCloseBtn && (
        <div className={styles.modalHeader}>
          <I18n {...rest}>{headerText}</I18n>
          <Icon type="close" onClick={close} className={'pointer'}></Icon>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.row}>
          <MaterialCheckbox
            color="primary"
            onChange={(e) => setIsToday(!isToday)}
            checked={isToday}
          />
          <div className={styles.header}>
            <I18n>templates-delails-modal.today</I18n>
          </div>
        </div>

        <div className={styles.row}>
          <MaterialCheckbox
            color="primary"
            onChange={(e) => setIsToday(!isToday)}
            checked={!isToday}
          />
          <div className={styles.header}>
            <I18n>templates-delails-modal.end-tamplate-at</I18n>
          </div>
          <div className={styles.datePicker}>
            <DatePicker
              data={{
                dateFilter: 'CUSTOM_OPTIONAL',
                dateFilterFrom: chosenDate,
                dateFilterTo: null,
              }}
              singleDatePicker={true}
              displayRanges={false}
              disableCustomLabel={true}
              selectedChanged={({ dateFilterFrom }) => setCosenDate(dateFilterFrom)}
              minDate={moment().format('DD/MM/YYYY')}
              height={28}></DatePicker>
          </div>
        </div>
      </div>
      <div className={styles.modalButtons}>
        {cancelText && (
          <Button onClick={close} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
            <I18n>{cancelText}</I18n>
          </Button>
        )}
        {confirmText && (
          <Button onClick={onConfirm} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
            <I18n>{confirmText}</I18n>
          </Button>
        )}
      </div>
    </div>
  );
}

export default ChoseDateModal;
