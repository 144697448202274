import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import I18n from '@components/I18n';
import MultiSelect from '@components/MultiSelect';
import Select from '@components/Select';
import { httpService } from '@core/http/HttpService';
import styles from '../StepOne.scss';
import { AssetTypeResponse } from '../StepOne.interface';
import { filterOption, tableConfig, getOptionLabel, getOptionValue } from './AssetTypesInput.utils';
import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';
import { cloneDeep } from 'lodash';

function AssetTypesInput({
  assetTypes,
  widgetData,
  setWidgetData,
  onSelectedAssetTypes,
  updateWidgetDataOnSelectAllChanged,
  eventTemplateId,
  isSelectAllAllowed,
  ...restProps
}) {
  const [assetTypesOptions, setAssetTypesOptions] = useState([]);
  const { dashboardId }: any = restProps?.dashboardId ? restProps : useParams();
  const { hideCaption, isOptionDisabled } = restProps;
  const widgetType = widgetData?.type;

  const singleAssetTypeWidget = false; // Meanwhile there are no single asset type widgets, the flag is always false

  const optionsWithAll = cloneDeep(assetTypesOptions);
  isSelectAllAllowed &&
    !assetTypesOptions.some((opt) => opt.value === 'SELECT_ALL') &&
    optionsWithAll.unshift({
      name: i18nService.translate('general.all'),
      value: 'SELECT_ALL',
      id: 'SELECT_ALL',
    });

  useEffect(() => {
    const request = httpService.api({
      type: 'getAssetTypeOptionsInfo',
      query: { p: 1, ps: 1000, dashboardId, eventTemplateId },
    });
    request.then((types: AssetTypeResponse) => {
      setAssetTypesOptions(
        types.results.map((t) => ({
          id: t.id,
          name: t.name,
          labels: t.labels,
        }))
      );
    });

    return () => {
      // If the component is unmounted before the request finishes we should cancel the request.
      request.cancel && request.cancel();
    };
  }, []);

  const onSelectAll = async () => {
    if (widgetData.tags?.length || widgetData.tagTypes?.length || widgetData.variables?.length) {
      await modalService
        .openModal('confirm', {
          headerText: 'create-widget-page.create-widget.step-one.pop-up-all-selected.header',
          text: 'create-widget-page.create-widget.step-one.pop-up-all-selected.text',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          showCloseBtn: true,
          tagsOrTagTypes: widgetData.tags?.length
            ? i18nService.translate('create-widget-page.create-widget.step-one.tag-data')
            : widgetData.tagTypes?.length
            ? i18nService.translate('create-widget-page.create-widget.step-one.tag-types')
            : '',
        })
        .then((confirm) => {
          if (confirm) {
            updateWidgetDataOnSelectAllChanged(widgetData, setWidgetData, true);
          }
        });
    } else if (
      (!['map', 'asset'].includes(widgetType) && widgetData.assetProperties?.length) ||
      (['map', 'asset'].includes(widgetType) && widgetData.assetProperties?.length > 3)
    ) {
      await modalService
        .openModal('confirm', {
          iconType: 'attention_image',
          text: 'create-widget-page.create-widget.step-one.initialize-settings',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText: !widgetData.eventTemplateId
            ? 'create-widget-page.create-widget.confirm-widget-changes.header'
            : 'create-event-data-source-page.create-event-data-source.confirm-data-source-changes.header',
          showCloseBtn: true,
        })
        .then((confirm) => {
          if (confirm) {
            updateWidgetDataOnSelectAllChanged(widgetData, setWidgetData, true);
          }
        });
    } else {
      updateWidgetDataOnSelectAllChanged(widgetData, setWidgetData, true);
    }
  };

  return (
    <div className={styles.dataField}>
      {!hideCaption ? (
        singleAssetTypeWidget && !widgetData.allAssetTypes ? (
          <div className={styles.fieldLabel}>
            <I18n capitalizeFirstLeters>general.asset-type</I18n>
          </div>
        ) : (
          <div className={styles.fieldLabel}>
            <I18n>create-widget-page.create-widget.step-one.asset-types-header</I18n>
            {assetTypes.length > 0 && ` (${assetTypes.length})`}
          </div>
        )
      ) : null}
      <div className={styles.inputField}>
        {singleAssetTypeWidget ? (
          <Select
            id="assetTypes"
            styles={{
              control: { height: 36 },
              placeholder: { color: 'var(--systemFont)' },
              menuPortal: { zIndex: 9999999999999, minWidth: '200px' },
            }}
            maxMenuHeight={180}
            menuPortalTarget={document.getElementById('mainContent') || document.body}
            value={
              widgetData.allAssetTypes
                ? [
                    {
                      name: i18nService.translate('general.all'),
                      value: 'SELECT_ALL',
                      id: 'SELECT_ALL',
                    },
                  ]
                : assetTypes
            }
            options={isSelectAllAllowed ? optionsWithAll : assetTypesOptions}
            getOptionLabel={(option) => (option.id ? option.name : '')}
            getOptionValue={(option) => option.id}
            onChange={(newValue) => {
              newValue.id === 'SELECT_ALL' ? onSelectAll() : onSelectedAssetTypes([newValue]);
            }}
          />
        ) : (
          <MultiSelect
            id="assetTypes"
            values={
              widgetData.allAssetTypes
                ? [
                    {
                      name: i18nService.translate('general.all'),
                      value: 'SELECT_ALL',
                    },
                  ]
                : assetTypes
            }
            options={assetTypesOptions}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={onSelectedAssetTypes}
            filterOption={filterOption}
            tableConfig={tableConfig}
            optionType="tableWithCheckbox"
            isSelectAllAllowed={isSelectAllAllowed}
            onSelectAll={isSelectAllAllowed && onSelectAll}
            isOptionDisabled={isOptionDisabled}
          />
        )}
      </div>
    </div>
  );
}

export default AssetTypesInput;
