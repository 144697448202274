import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Select from '@components/Select';
import I18n from '@components/I18n';
import moment from 'moment';
import { classNames } from 'react-select/src/utils';

const useStyles = makeStyles(() => ({
  wrapper: { display: 'flex', flexDirection: 'column', paddingBottom: 34 },
  row: { display: 'flex', marginTop: 8 },
  separator: { width: 101, marginRight: 8 },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--systemFont)',
  },
  format: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 12,
    textAlign: 'center',
    color: 'var(--systemFont)',
    maxWidth: 790,
  },
}));

const selectCustomStyle = { control: { height: 36 }, container: { width: 187, marginRight: 8 } };
const selectSmallCustomStyle = {
  control: { height: 36 },
  container: { width: 101, marginRight: 8 },
};

const getSaperator = (sap) => {
  switch (sap) {
    case 'space':
      return ' ';
    case 'none':
      return '';
    default:
      return sap;
  }
};

const TimeRow = (props) => {
  const classes = useStyles(props);
  const { onChangeData, keyName } = props;
  const { format, momentFormat, hours, firstSeparator, minutes, secondSeparator, seconds } =
    props.data;

  const options = useMemo(
    () => ({
      hours: [
        { value: 'hh', label: 'hh' },
        { value: 'h', label: 'h' },
        { value: 'HH', label: 'HH' },
        { value: 'H', label: 'H' },
      ],
      minutes: [
        { value: 'mm', label: 'mm' },
        { value: 'm', label: 'm' },
      ],
      seconds: [
        { value: 'ss', label: 'ss' },
        { value: 's', label: 's' },
      ],
    }),
    []
  );

  const separatorsOptions = useMemo(
    () => [
      { value: ':', label: ':' },
      { value: '.', label: '.' },
      { value: 'space', label: 'white-labeling.date-time.space' },
      { value: 'none', label: 'white-labeling.date-time.none' },
    ],
    []
  );

  const onChange = (value, key) => {
    const tempData = { ...props.data, [key]: value };
    const format = `${tempData.hours}${getSaperator(tempData.firstSeparator)}${
      tempData.minutes
    }${getSaperator(tempData.secondSeparator)}${tempData.seconds}${
      tempData.hours.includes('h') ? ' tt' : ''
    }`;
    const momentFormat = `${tempData.hours}${getSaperator(tempData.firstSeparator)}${
      tempData.minutes
    }${getSaperator(tempData.secondSeparator)}${tempData.seconds}${
      tempData.hours.includes('h') ? ' a' : ''
    }`;
    onChangeData('dateTime', keyName, { ...tempData, format, momentFormat });
  };

  return (
    <div className={classes.wrapper}>
      <I18n className={classes.title}>{`white-labeling.date-time.${keyName}`}</I18n>
      <div className={classes.row}>
        <Select
          styles={selectCustomStyle}
          options={options.hours}
          value={options.hours.find((opt) => opt.value === hours)}
          onChange={(option) => onChange(option.value, 'hours')}
        />
        <Select
          styles={selectSmallCustomStyle}
          options={separatorsOptions}
          value={separatorsOptions.find((opt) => opt.value === firstSeparator)}
          onChange={(option) => onChange(option.value, 'firstSeparator')}
        />
        <Select
          styles={selectCustomStyle}
          options={options.minutes}
          value={options.minutes.find((opt) => opt.value === minutes)}
          onChange={(option) => onChange(option.value, 'minutes')}
        />
        <Select
          styles={selectSmallCustomStyle}
          options={separatorsOptions}
          value={separatorsOptions.find((opt) => opt.value === secondSeparator)}
          onChange={(option) => onChange(option.value, 'secondSeparator')}
        />
        <Select
          styles={selectCustomStyle}
          options={options.seconds}
          value={options.seconds.find((opt) => opt.value === seconds)}
          onChange={(option) => onChange(option.value, 'seconds')}
        />
      </div>
      <div className={classes.format}>{moment().format(momentFormat)}</div>
    </div>
  );
};

export default TimeRow;
