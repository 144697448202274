import React, { useCallback, useMemo } from 'react';
import Table from '@components/Table';
import { TableColunm } from '@components/Table/table.interface';
import { modalService } from '@core/modals/ModalService';
import styles from './UsersTable.scss';
import UserName from './UserName';
import { pick } from 'lodash';
import UserStatus from './UserStatus';
import UserRoles from './UserRoles';
import SubscribeStatus from './SubscribeStatus';

const colunmsCfg: Array<TableColunm> = [
  {
    Component: UserName,
    label: 'users.name',
    field: 'name',
    style: { width: 'calc(100% / 3)' },
  },
  {
    Component: UserStatus,
    label: 'users.status',
    field: 'status',
    style: { width: 'calc(100% / 6)' },
  },
  {
    Component: UserRoles,
    label: 'users.roles',
    field: 'roles',
    style: { width: 'calc(100% / 2)' },
  },
  {
    Component: SubscribeStatus,
    label: 'users.sms',
    field: 'subscribeSMSNotification',
    style: { width: '60px' },
  },
  {
    Component: SubscribeStatus,
    label: 'users.email',
    field: 'subscribeEmailNotification',
    style: { width: '60px' },
  },
];

function UsersTable(props) {
  const { usersData, setUsersData, getUsers, disabledLoad } = props;
  const statusSortOrder = useMemo(
    () => ({ PRE_ACTIVATED: 0, ACTIVE: 1, LOCKED: 2, SUSPENDED: 3, ARCHIVED: 4 }),
    []
  );

  const sortUsersByStatus = useCallback((users) => {
    users.sort((a, b) => statusSortOrder[a.status] - statusSortOrder[b.status]);
  }, []);

  const openEditUserModal = (rowData, rowIdx) => {
    modalService
      .openModal('editUserModal', { rowData, rowIdx, setUsersData }, { disableBackdropClick: true })
      .then((res) => {
        if (res?.roles) {
          res['roles'] = res['roles'].map((role) => (role.value ? role.value : role));
          setUsersData((prevState) => {
            prevState[rowIdx] = {
              ...prevState[rowIdx],
              ...pick(res, ['id', 'title', 'firstName', 'lastName', 'status', 'roles']),
            };
            sortUsersByStatus(prevState);
            return [...prevState];
          });
        } else if (res === 'delete') {
          getUsers(true);
        }
      });
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={usersData}
        getData={getUsers}
        colunmsCfg={colunmsCfg}
        onRowClicked={openEditUserModal}
        disabledLoad={disabledLoad}
      />
    </div>
  );
}

export default UsersTable;
