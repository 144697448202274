import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styles from './AugmentedRealityEditorPage.scss';
import { cssVarsService } from '@core/CssVarsService';
import SidePanelToolbar from './SidePanelToolbar/SidePanelToolbar';
import {
  createUpdateArWidget,
  getArWidget,
  fetchProject,
  getArWidgetList,
  updateProjectName,
  deleteArWidget,
  updateArWidgetState,
  publishARProject,
  validateUpdatedARProjectName,
} from './AugmentedRealityEditorPage.utils';
import { compact } from 'lodash';
import ToolBar from '@components/ToolBar';
import { InputBase } from '@material-ui/core';
import { modalService } from '@core/modals/ModalService';
import { i18nService } from '@core/i18n/I18nService';
import CanvasWrapper from './CanvasWrapper';
import history from '@core/history';
import { decrementBusyCounter, incrementBusyCounter } from '@src/redux/config';
import { dispatch } from '@src/redux/store';
import {
  combinedArWidgetInitialValueData,
  combinedArWidgetInitialImageByValueData,
} from './InitialProperties';
import { getUniqueNameWithIncrement } from '@core/utils';

function AugmentedRealityEditorPage() {
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const [widgetData, setWidgetData] = useState(combinedArWidgetInitialValueData);
  const [widgetList, setWidgetList] = useState([]);
  const { projectId } = useParams<any>();
  const [projectData, setProjectData] = useState(null);
  const [tmpProjectName, setTmpProjectName] = useState('');
  const [modelLoaded, setModelLoaded] = useState(false);
  const [isAtLeastOneWidgetExistAndFinished, setIsAtLeastOneWidgetExistAndFinished] =
    useState<boolean>(false);
  const projectNameTitleMaxLength = 100;

  const training = useCallback(() => {
    modalService
      .openModal('trainingARProject', { projectData: projectData }, { disableBackdropClick: true })
      .then((res) => {
        if (res) updateTrainingStatus('IN_PROGRESS');
      });
  }, [projectData]);

  const updateTrainingStatus = (status) => {
    setProjectData((prevData) => ({
      ...prevData,
      arModel: {
        ...prevData.arModel,
        training: {
          ...prevData.arModel.training,
          status: status,
        },
      },
    }));
  };

  const publish = useCallback(() => {
    publishARProject(projectId, (result) => {
      history.push(`/main/augmented-reality`);
    });
  }, [projectData]);

  const toolbarCfg = {
    style: {
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'center',
      margin: '0 15px 0 5px',
      padding: 0,
      border: 'none',
    },
    buttons: compact([
      {
        type: 'training',
        text: 'general.training',
        onClick: training,
        disabled:
          !isAtLeastOneWidgetExistAndFinished ||
          projectData?.arModel?.training?.status != 'NOT_TRAINED',
      },
      {
        type: 'publish',
        text: 'general.publish',
        onClick: publish,
        disabled:
          !isAtLeastOneWidgetExistAndFinished ||
          projectData?.arModel?.training?.status != 'TRAINED',
      },
      {
        type: 'closeCustomColor',
        text: 'general.close',
        color: cssVarsService.vars.systemEnableEditorIcons,
        onClick: () => {
          history.push(`/main/augmented-reality`);
        },
      },
    ]),
  };

  const checkIsAtLeastOneWidgetExistAndFinished = useCallback(() => {
    const isExistAndFinished = widgetList.some((widget) => widget.status === 'PUBLISHED');
    setIsAtLeastOneWidgetExistAndFinished(isExistAndFinished);
  }, [widgetList]);

  const handleWidgetClick = async (selectedWidgetData) => {
    const preSelectedWidgetData = {
      ...combinedArWidgetInitialValueData,
      ...selectedWidgetData,
    };
    setWidgetData(preSelectedWidgetData);
    await getArWidget(projectData.id, selectedWidgetData.id, (widgetResult) => {
      setWidgetData((prevWidgetData) => ({
        ...prevWidgetData,
        ...widgetResult,
        statesForm: widgetResult.states[0],
      }));
    });
    setSelectedWidgetId(selectedWidgetData.id);
  };

  const handleOnShowWidgetList = () => {
    setSelectedWidgetId(null);
    getArWidgetList(projectId, (widgetsResult) => {
      const updatedWidgets = widgetsResult.map((widget) => ({
        ...widget,
        statesForm: widget.states[0],
      }));
      setWidgetList(updatedWidgets);
    });
  };

  const handleOnDeleteSelectedWidget = async () => {
    const isConfirmed = await modalService.openModal('confirm', {
      text: 'augmented-reailty.widget.side-panel.delete-confirmation-text',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText:
        i18nService.translate('augmented-reailty.widget.side-panel.delete-confirmation-header') +
        widgetData.name,
      showCloseBtn: true,
    });
    if (isConfirmed) {
      deleteArWidget(projectId, selectedWidgetId);
      deleteWidgetFromListById(selectedWidgetId);
      setSelectedWidgetId(null);
    }
  };

  const handleOnFinishWidgetEditing = (updatedWidgetData, shouldUnselectWidget = true) => {
    setSelectedWidgetDataInWidgetList(updatedWidgetData);
    setWidgetData(updatedWidgetData);
    if (shouldUnselectWidget) {
      setSelectedWidgetId(null);
    }
  };

  const createWidgetOnDropPosition = async (position, maxModelDimension, widgetType) => {
    var widgetDefaultName;
    var initialWidgetData;
    switch (widgetType) {
      case 'value':
        console.log('Creating a Value widget at position:', position);
        initialWidgetData = combinedArWidgetInitialValueData;
        widgetDefaultName = getUniqueNameWithIncrement(
          widgetList,
          'name',
          `${i18nService.translate('augmented-reailty.widget.side-panel.default-name')}`,
          1
        );
        break;

      case 'image_by_value':
        console.log('Creating an Image by Value widget at position:', position);
        initialWidgetData = combinedArWidgetInitialImageByValueData;
        widgetDefaultName = getUniqueNameWithIncrement(
          widgetList,
          'name',
          `${i18nService.translate(
            'augmented-reailty.widget.side-panel.default-name.image-by-value'
          )}`,
          1
        );
        break;

      default:
        console.log('Unknown widget type:', widgetType);
        break;
    }
    const precision = 5;
    const newStatesForm = {
      ...widgetData.statesForm,
      w: Number((maxModelDimension / 4).toFixed(precision)),
      h: Number(((maxModelDimension / 4) * 0.8).toFixed(precision)),
      x: position.x.toFixed(precision),
      y: position.y.toFixed(precision),
      z: position.z.toFixed(precision),
    };

    const newWidgetData = {
      ...initialWidgetData,
      statesForm: newStatesForm,
      type: widgetType,
      name: widgetDefaultName,
      onDrop: true,
    };
    createUpdateArWidget(projectData, newWidgetData, setWidgetList, (widgetDataResult) => {
      const updatedWidgetData = {
        ...newWidgetData,
        id: widgetDataResult.id,
      };
      setWidgetData(updatedWidgetData);
      setWidgetList((prevWidgetList) => [...prevWidgetList, updatedWidgetData]);
      setSelectedWidgetId(widgetDataResult.id);
    });
  };

  const setSelectedWidgetDataInWidgetList = (updatedWidgetData) => {
    const updatedWidgets = widgetList.map((widget) => {
      if (widget.id == selectedWidgetId) {
        return {
          ...widget,
          ...updatedWidgetData,
        };
      } else {
        return widget;
      }
    });
    setWidgetList(updatedWidgets);
  };

  const deleteWidgetFromListById = (widgetIdToDelete) => {
    const updatedWidgets = widgetList.filter((widget) => widget.id != widgetIdToDelete);
    setWidgetList(updatedWidgets);
  };

  const handleWidgetPositionChange = (position) => {
    const newStatesForm = {
      ...widgetData.statesForm,
      x: position.x,
      y: position.y,
      z: position.z,
    };
    const newWidgetData = {
      ...widgetData,
      statesForm: newStatesForm,
    };
    updateArWidgetState(projectData, newWidgetData);
    handleOnFinishWidgetEditing(newWidgetData, false);
  };

  const onBlurProjectName = useCallback(
    async (event) => {
      const dashboardName = event.target.value;
      if (dashboardName == projectData.name) return;
      if (dashboardName.length > projectNameTitleMaxLength) return;
      const isValidProjectName = await validateUpdatedARProjectName(dashboardName, projectId);
      if (isValidProjectName) {
        await updateProjectName(projectId, dashboardName);
        setProjectData((prevProjectData) => ({
          ...prevProjectData,
          name: dashboardName,
        }));
      } else {
        modalService.openModal('alert', {
          text:
            'Error: ' +
            i18nService.translate('augmented-reality.project-name-not-valid') +
            ': ' +
            dashboardName,
          iconType: 'attention_image',
          showCloseBtn: true,
          headerText: `errors.error-header`,
        });
      }
    },
    [projectData]
  );

  const onChangeProjectName = useCallback(async (event) => {
    if (event.target.value.length > projectNameTitleMaxLength) return;
    setTmpProjectName(event.target.value);
  }, []);

  useEffect(() => {
    fetchProject(projectId, (projectInfoResult) => {
      console.log(projectInfoResult);
      setProjectData(projectInfoResult);
    });
    handleOnShowWidgetList();
  }, [fetchProject, getArWidgetList]);

  const handleLoad = (gl, scene, camera, canvas) => {
    setModelLoaded(true);
  };

  useEffect(() => {
    if (projectData) {
      setTmpProjectName(projectData.name);
    }
  }, [projectData]);

  useEffect(() => {
    if (!modelLoaded) {
      dispatch(incrementBusyCounter());
    } else {
      dispatch(decrementBusyCounter());
    }
  }, [modelLoaded]);

  useEffect(() => {
    checkIsAtLeastOneWidgetExistAndFinished();
  }, [widgetList]);

  if (!projectData) {
    return <div></div>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <InputBase
            id={styles.title}
            placeholder="Project Name"
            value={tmpProjectName}
            onChange={onChangeProjectName}
            onBlur={onBlurProjectName}
          />
        </div>
        <div style={{ display: 'flex', padding: 0, border: 'none' }}>
          <ToolBar toolbarCfg={toolbarCfg} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <div className={styles.viewport}>
            <CanvasWrapper
              onLoad={handleLoad}
              projectData={projectData}
              widgetList={widgetList}
              widgetData={widgetData}
              selectedWidgetId={selectedWidgetId}
              handleWidgetClick={handleWidgetClick}
              handleWidgetPositionChange={handleWidgetPositionChange}
              OnShowWidgetList={handleOnShowWidgetList}
              OnDeleteSelectedWidget={handleOnDeleteSelectedWidget}
              createWidgetOnDropPosition={createWidgetOnDropPosition}
            />
          </div>
          <SidePanelToolbar
            projectData={projectData}
            widgetData={widgetData}
            setWidgetData={setWidgetData}
            widgetList={widgetList}
            setWidgetList={setWidgetList}
            selectedWidgetId={selectedWidgetId}
            handleWidgetRowClick={handleWidgetClick}
            onFinishWidgetEditing={handleOnFinishWidgetEditing}
          />
        </div>
      </div>
    </div>
  );
}

export default AugmentedRealityEditorPage;
