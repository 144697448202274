import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './NewOrganizationModal.scss';
import Icon from '@components/Icon';
import * as Yup from 'yup';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { useSelector } from '@src/redux/useSelector';
import {
  getFieldsCfg,
  getFieldsCfgMainContact,
  getFieldCfgPreferences,
  getDashboard,
  onSubmit,
  validationSchema,
  validate,
} from './NewOrganizationModal.utils';

function NewOrganizationModal(props: ModalComponentProps) {
  const userOrganizationType = useSelector((state: any) => state.config.organizationDetails.type);
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const organizationDetails = useSelector((state) => state.organizations);
  const organizationsTypesLists = useSelector(
    (state) => state.config && state.config.organizationsTypesLists
  );
  const userRoles = useSelector((state) => state.config.roles);
  const { dismiss, args } = props;
  const isCancelled = useRef(false);
  const { organizationType, rootRelatedOrg } = args;
  const data = useMemo(
    () => ({
      name: '',
      type: '',
      website: '',
      address: null,
      rootRelatedOrg: false,
      erpInstanceId: '',
      erpCustomerId: '',
      dashboard: organizationDetails && organizationDetails.dashboard,
      id: null,
      email: '',
      title: 'NONE',
      firstName: null,
      middleName: null,
      lastName: null,
      phone: '',
      mobile: '',
    }),
    [organizationDetails]
  );

  const orgType = organizationTypeMap[organizationType];
  const cancel = useCallback(() => {
    isCancelled.current = true;
    dismiss(false);
  }, [dismiss]);

  const [dashboardOptions, setDashboardOptions] = useState(null);
  const [emailErrors, setEmailErrors] = useState({
    email: '',
    name: '',
    message: '',
    isEmailMessage: null,
    isValid: false,
  });
  const [userId, setUserId] = useState(null);
  const [prevCompanyAndEmail, setPrevCompanyAndEmail] = useState({ email: '', company: '' });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [saving, setSaving] = useState(false);
  const isUserAdmin = useMemo(() => userRoles.find((role) => role.includes('Admin')), [userRoles]);
  const isValidEmailRef = useRef(null);
  isValidEmailRef.current = isValidEmail;
  const emailValidationRef = useRef(null);
  emailValidationRef.current = emailErrors;
  const userIdRef = useRef(null);
  userIdRef.current = userId;

  useEffect(() => {
    isCancelled.current = false;
  }, []);

  useEffect(() => {
    getDashboard(userOrganizationType, selectedMBId, setDashboardOptions, organizationDetails);
  }, [organizationDetails]);

  const fieldsCfg = getFieldsCfg(
    rootRelatedOrg,
    orgType,
    organizationType,
    organizationsTypesLists,
    emailErrors,
    setUserId,
    setEmailErrors,
    setIsValidEmail,
    prevCompanyAndEmail,
    setPrevCompanyAndEmail,
    isUserAdmin,
    organizationTypeMap[userOrganizationType],
    isCancelled
  );

  const fieldsCfgMainContact = getFieldsCfgMainContact(
    setIsValidEmail,
    emailErrors,
    setUserId,
    setEmailErrors,
    userId,
    prevCompanyAndEmail,
    setPrevCompanyAndEmail,
    isCancelled
  );

  const fieldCfgPreferences = getFieldCfgPreferences(dashboardOptions, userOrganizationType);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>organizations-page.new-organizations</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        <Formik
          enableReinitialize
          initialValues={{ ...data }}
          validate={(values) =>
            validate(values, fieldsCfg, fieldsCfgMainContact, fieldCfgPreferences)
          }
          validationSchema={validationSchema([
            ...fieldsCfg,
            ...fieldsCfgMainContact,
            ...fieldCfgPreferences,
          ])}
          onSubmit={(values) =>
            onSubmit(
              values,
              userIdRef,
              dismiss,
              setSaving,
              isValidEmailRef,
              emailValidationRef,
              setIsValidEmail
            )
          }>
          {({ isValid, values, setFieldValue }) => {
            return (
              <FormikForm className={styles.form}>
                <div className={styles.details}>
                  <div className={classNames(styles.item, styles.firstItem)}>
                    <div className={styles.fields}>
                      <I18n element="div" className={styles.subTitle}>
                        organizations-page.company-details
                      </I18n>
                      <div className={styles.fieldsContent}>
                        {fieldsCfg.map((field, idx) => (
                          <FormikField
                            key={idx}
                            editMode={true}
                            {...field}
                            values={values}
                            setFieldValue={setFieldValue}></FormikField>
                        ))}
                      </div>
                        <I18n element="div" className={styles.subTitle} style={{marginTop: '19px'}}>
                          details.preferences
                        </I18n>
                      <div className={styles.fieldsContent}>
                        {fieldCfgPreferences.map((field, idx) => (
                          <FormikField
                            key={idx}
                            editMode={true}
                            {...field}
                            values={values}></FormikField>
                        ))}
                      </div>
                    </div>
                    <div className={styles.fields}>
                      <I18n element="div" className={styles.subTitle}>
                        details.main-contact
                      </I18n>
                      <div className={styles.fieldsContent}>
                        {fieldsCfgMainContact.map((field, idx) => (
                          <FormikField
                            key={idx}
                            editMode={true}
                            {...field}
                            values={values}
                            setFieldValue={setFieldValue}></FormikField>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.footerButtons}>
                  <Button styles={{ width: 92, marginRight: 13 }} mode="cancel" onClick={cancel}>
                    {i18nService.translate('general.cancel')}
                  </Button>
                  <Button styles={{ width: 92 }} type="submit" disabled={!isValid || saving}>
                    {i18nService.translate('general.save')}
                  </Button>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default NewOrganizationModal;
