import React, { useCallback, useState, useEffect } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';

// Services | Interfaces
import { i18nService } from '@core/i18n/I18nService';
import { ChangeRouterOwnerModalProps } from './ChangeRouterOwnerModal.interface';
import styles from './ChangeRouterOwnerModal.scss';

function ChangeRouterOwnerModal(props: ChangeRouterOwnerModalProps) {
  const {
    args: { newOwner, router },
    dismiss,
  } = props;
  const { plcCount, assetCount } = router;

  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [text, setText] = useState('');
  const [actionButtons, setActionButtons] = useState([]);

  useEffect(() => {
    if (!assetCount && plcCount > 0) {
      setText(
        i18nService.translate('edit-router-details.change-owner-with-connected-plcs', undefined, {
          plcCount,
          newOwner: newOwner.label,
        })
      );
      applyActionButtons('plcs');
    } else if (assetCount > 0 && plcCount > 0) {
      setText(
        i18nService.translate(
          'edit-router-details.change-owner-with-connected-plcs-and-assets',
          undefined,
          {
            plcCount,
            assetCount,
            newOwner: newOwner.label,
          }
        )
      );
      applyActionButtons('devices');
    }
  }, []);

  const applyActionButtons = (context) => {
    setActionButtons([
      { text: 'general.cancel', onClick: cancel },
      {
        text: `edit-router-details.change-owner-modal.move-${context}`,
        onClick: () => dismiss(true),
      },
      {
        text: 'edit-router-details.change-owner-modal.move-router-only',
        onClick: () => {
          setText(`edit-router-details.change-owner-modal.move-router-only.disconnect-${context}`);
          setActionButtons([{ text: 'general.confirm', onClick: () => dismiss(false) }]);
        },
      },
    ]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>edit-router-details.change-owner-modal.header</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n element="div" className={styles.text} noEllipsis={true}>
          {text}
        </I18n>
      </div>
      <div className={styles.modalButtons}>
        {actionButtons.map((btn, idx) => (
          <Button
            key={idx}
            onClick={() => btn.onClick()}
            styles={{ marginLeft: 13 }}
            mode={btn.text === 'general.cancel' ? ['cancel'] : 'bigFont'}>
            <I18n>{btn.text}</I18n>
          </Button>
        ))}
      </div>
    </div>
  );
}

export default ChangeRouterOwnerModal;
