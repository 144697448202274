import React from 'react';
import classNames from 'classnames';
import { TagMeta } from '@http/server.interface.d';
import styles from './TagsDescription.scss';

function TagsDescription(props: TagMeta) {
  const {  description } = props;



  return (
    <div className={styles.wrapper}>
      <span className={classNames(styles.text, 'ellipsis-overflow')} title={description}>
        {description}
      </span>
    </div>
  );
}

export default React.memo(TagsDescription);
