import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from '@src/redux/useSelector';

export function useGetWidgetData({
  getWidgetData,
  dataChangesToHandle,
  widgetData,
  prevWidgetData,
  userFilters,
}) {
  const {
    organizationsFilter,
    assetTypesFilter,
    assetFilter,
    geoFilter,
    dateFilter,
    dateFilterFrom,
    dateFilterTo,
  } = useSelector(({ dashboardEditor }) => dashboardEditor);

  //listening preview (edit widget) changes
  useEffect(() => {
    if (widgetData) {
      dataChangesToHandle.forEach((changeKey) => {
        if (prevWidgetData && !isEqual(prevWidgetData[changeKey], widgetData[changeKey])) {
          getWidgetData();
        }
      });
    }
  }, [widgetData]);

  //listening for liveDashboard filters changes
  useEffect(() => {
    getWidgetData();
  }, [
    organizationsFilter,
    assetTypesFilter,
    assetFilter,
    geoFilter,
    dateFilter,
    dateFilterFrom,
    dateFilterTo,
    userFilters,
  ]);

  return null;
}
