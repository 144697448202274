import { httpService } from '@http/HttpService';
import * as server from '@http/server.interface.d';
import store, { dispatch, getState } from '@redux/store';
import {
  setSelectedTab,
  setAll,
  clearEditAssetType,
  updateEditAssetType,
} from '@redux/deviceManagment/editAssetType';

const POLLING_INTERVAL_MS = 10000;

class EditAssetTypeService {
  assetTypeId: number;

  private _dismiss: Function;
  private pollingInterval;
  private tagsMeta: server.TagMeta[];
  private inProgressTagIds: string[];

  constructor() {
    // We react to changes in tagsMeta in the redux store and update
    // inProgressTagIds accordingly.
    store.subscribe(() => {
      const newTagsMeta = getState().editAssetType.tagsMeta;
      if (this.tagsMeta !== newTagsMeta) {
        this.tagsMeta = newTagsMeta;
        this.setInProgressTagIds();
      }
    });

    // This is for the initialization.
    this.tagsMeta = getState().editAssetType.tagsMeta;
    this.setInProgressTagIds();
  }

  setModalDismiss(dismiss: (val) => void) {
    this._dismiss = dismiss;
  }

  dismiss(val) {
    this._dismiss && this._dismiss(val);
  }

  startPollingForAssignmentCompletion() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
    this.pollingInterval = setInterval(async () => {
      if (this.inProgressTagIds.length === 0) {
        return; // No need to poll if no tag ids are in progress.
      }
      const res = await httpService.api<server.GetTagTypeAssignmentStatusRes>({
        type: 'getTagTypeAssignmentStatus',
        disableBI: true,
        disableErrorHandling: true,
        data: { tags: this.inProgressTagIds },
      });
      const newInProgressTagIds = res.filter((t) => t.status === 'IN_PROGRESS').map((t) => t.id);

      if (newInProgressTagIds.length !== this.inProgressTagIds.length) {
        // If some tags were completed, we need to update the data with fresh
        // data from the server.
        this.getAssetTypeInfo({ disableBI: true });
      }
    }, POLLING_INTERVAL_MS);
  }

  stopPollingForAssignmentCompletion() {
    if (!this.pollingInterval) {
      return; // No polling to stop.
    }
    clearInterval(this.pollingInterval);
    this.pollingInterval = undefined;
  }

  async getAssetTypeInfo(options: { disableBI?: boolean } = {}) {
    const res = await httpService.api<server.GetAssetTypeInfoRes>({
      type: 'getAssetTypeInfo',
      urlParams: { assetTypeId: this.assetTypeId },
      disableBI: options.disableBI,
    });
    const assetInfoData = {
      staticData: {
        id: res.id,
        labels: res.labels,
        model: res.model,
        name: res.name,
        status: res.status,
        gpsSupport: res.gpsSupport,
        isDemo: res.isDemo,
        remoteAccessMode: res.remoteAccessMode,
      },
      tagsMeta: res.tagMetas,
      alarmsMeta: res.alarmMetas,
      dataTablesMeta: res.dataTables,
    };
    dispatch(setAll(assetInfoData));
  }

  updateEditAssetType(changes: any) {
    dispatch(updateEditAssetType(changes));
  }

  setSelectedTab(tab: string) {
    dispatch(setSelectedTab(tab));
  }

  clearEditAssetType() {
    dispatch(clearEditAssetType());
  }

  private setInProgressTagIds() {
    this.inProgressTagIds = this.tagsMeta
      .filter((tag) => tag.assignmentStatus === 'IN_PROGRESS')
      .map((tag) => tag.tagId);
  }
}

export const editAssetTypeService = new EditAssetTypeService();
