import React, { useEffect, useMemo, useState } from 'react';
import Table from '@components/Table';
import styles from './ActivitiesLogTable.scss';
import TextField from '@components/Table/TableFields/TextField';
import { httpService } from '@core/http/HttpService';
import DateField from '@components/Table/TableFields/DateField';
import TextLowercase from '@components/Table/TableFields/TextLowercase';
import moment from 'moment';
import { EventLogsResponse } from '../ActivitiesLogTab.interface';
import EventStatusText from '@pages/EventsManagementPage/EditTemplate/EventStatusText/EventStatusText';
import { isEmpty } from 'lodash';

function ActivitiesLogTable(props) {
  const { setNumberOfEmails, setNumberOfSMS, setNumberOfPushes, filterData, filterAllSelected } =
    props;

  const [events, setEvents] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [disabledLoad, setDisableLoad] = useState(false);
  const [clearOrder, setClearOrder] = useState(false);

  useEffect(() => {
    const canRequest = !(isEmpty(filterData) && !filterAllSelected);
    if (canRequest) getActivitiesLogs(true, null);
  }, [filterData]);

  const colunmsCfg = useMemo(
    () => [
      {
        Component: TextField,
        field: 'eventName',
        label: 'activity-log-tab.table.event',
        isSortable: true,
        style: { width: '16%' },
      },
      {
        Component: EventStatusText,
        field: 'organizationType',
        label: 'activity-log-tab.table.organization-type',
        isSortable: true,
        style: { width: '16%' },
      },
      {
        Component: TextField,
        field: 'organizationName',
        label: 'activity-log-tab.table.organization-name',
        isSortable: true,
        style: { width: '16%' },
      },
      {
        Component: DateField,
        field: 'date',
        label: 'activity-log-tab.table.date',
        isSortable: true,
        style: { width: '10%' },
      },
      {
        Component: TextField,
        field: 'time',
        label: 'activity-log-tab.table.time',
        isSortable: true,
        style: { width: '10%' },
      },
      {
        Component: TextLowercase,
        field: 'actionType',
        label: 'activity-log-tab.table.action-type',
        isSortable: true,
        style: { width: '16%' },
      },
      {
        Component: TextField,
        field: 'messages',
        label: 'activity-log-tab.table.messages',
        isSortable: true,
        style: { width: '16%' },
      },
    ],
    []
  );

  const getActivitiesLogs = async (firstInit, order) => {
    try {
      setDisableLoad(true);
      !order && setClearOrder(true);
      const res: EventLogsResponse = await httpService.api({
        type: 'getActivitiesLogs',
        query: {
          ps: 50,
          p: firstInit ? 1 : nextPage,
          ordering: order ? `${order.orderSign}${order.label}` : null,
          ...filterData,
        },
      });

      if (res) {
        res.next && setDisableLoad(false);
        setNumberOfSMS(res.results.smsTotalAmount);
        setNumberOfEmails(res.results.emailTotalAmount);
        setNumberOfPushes(res.results.pushTotalAmount);

        const eventLogs = res.results.eventLogs.map((data) => ({
          ...data,
          time: moment(data.timestamp).format('HH:mm'),
          date: data.timestamp,
        }));

        if (firstInit) {
          setEvents(eventLogs);
          setNextPage(2);
        } else {
          setEvents((prev) => [...prev, ...eventLogs]);
          setNextPage(nextPage + 1);
        }
        !order && setClearOrder(false);
      }
    } catch (e) {}
  };

  const getTableData = (firstInit, order) => {
    getActivitiesLogs(firstInit, order);
  };

  return (
    <div className={styles.eventsTableWrapper}>
      <Table
        data={events}
        getData={getTableData}
        colunmsCfg={colunmsCfg}
        disabledLoad={disabledLoad}
        clearOrder={clearOrder}
      />
    </div>
  );
}

export default ActivitiesLogTable;
