import { compact, uniq } from 'lodash';
import { addMoreRolesMap } from '@pages/OrganizationsPage/Users/UsersRolesMap';

const getRoles = (roles) =>
  roles
    ? uniq(
        compact(
          roles.reduce(
            (all, role) => [...all, role.value || role, addMoreRolesMap[role.value || role]],
            []
          )
        )
      )
    : null;

export const createUserLocalToServer = (values) => {
  const roles = getRoles(values.roles);

  return {
    ...values,
    middleName: values.middleName === '' ? null : values.middleName,
    //if set default dashboard/roles/title with initial value it will set: values.dashboard = id, when select value will be set as object
    roles,
    title: values.title ? (values.title.value ? values.title.value : values.title) : null,
    dashboard: values.dashboard
      ? values.dashboard.value
        ? values.dashboard.value
        : values.dashboard
      : null,
  };
};

export const assingUserLocalToServer = (values) => ({
  captchaToken: values.captchaToken,
  roles: getRoles(values.roles),
  userEmail: values.email,
});
