import { StoreAction } from './redux.interface';

export const setAccounts = (payload: any): StoreAction => ({
  type: 'accounts/SET_ACCOUNTS',
  payload,
});

export const resetAccounts = (): StoreAction => ({
  type: 'accounts/RESET_ACCOUNTS',
  payload: [],
});

export default function accountsReducer(state = {}, action: StoreAction) {
  if (action.type == 'accounts/SET_ACCOUNTS') {
    return { accounts: action.payload };
  } else if (action.type == 'accounts/RESET_ACCOUNTS') {
    return { accounts: [] };
  } else {
    return state;
  }
}
