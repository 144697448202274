import Table from '@components/Table';
import { httpService } from '@core/http/HttpService';
import { compact } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@components/Table/TableFields/TextField';
import styles from './DataSourcesTable.scss';
import EditCopyDeleteButton from './EditCopyDeleteButton/EditCopyDeleteButton';
import DataSourceType from './DataSourceType/DataSourceType';
import { serverToLocal } from '@pages/CreateWidgetPage/widgets.utils';
import DataSourceStatus from './DataSourceStatus/DataSourceStatus';
import { getUsedDataSources } from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/RichTextEditor.utils';
import { modalService } from '@core/modals/ModalService';
import { AlarmsDataSources } from '@pages/EventsManagementPage/EditTemplate/EditTemplate.utils';
import { omit } from 'lodash';

const getColumnsCfg = (
  templateId,
  editDataSource,
  copyDataSource,
  deleteDataSource,
  templateData
) => {
  return compact([
    {
      Component: TextField,
      label: 'events-template.create-data.source.name',
      field: 'name',
      style: { width: '20%' },
      isSortable: true,
    },
    {
      Component: DataSourceType,
      label: 'events-template.create-data.source.type',
      field: 'type',
      style: { width: '20%' },
      isSortable: false,
    },
    {
      Component: DataSourceStatus,
      label: '',
      field: 'status',
      style: { width: '20%' },
      isSortable: false,
    },
    {
      Component: EditCopyDeleteButton,
      type: 'staticEnd',
      field: 'edit',
      style: { width: 108 },
      buttonType: 'edit',
      onClick: editDataSource,
      templateId,
      templateData,
    },
    {
      Component: EditCopyDeleteButton,
      type: 'staticEnd',
      field: 'copy',
      style: { width: 108 },
      buttonType: 'copy',
      onClick: copyDataSource,
      templateId,
      templateData,
    },
    {
      Component: EditCopyDeleteButton,
      type: 'staticEnd',
      field: 'delete',
      style: { width: 108 },
      buttonType: 'delete',
      onClick: deleteDataSource,
      templateId,
      templateData,
    },
  ]);
};

function DataSourcesTable(props) {
  const [data, setData] = useState([]);
  const { templateData, editDataSource, onSaveTemplate, refresh, setRefresh } = props;
  const { trigger, conditionAssetTypes } = templateData;
  const [_order, setOrder] = useState(null);
  const defaultOrdering = { label: 'name', orderSign: '' };

  const getDataSources = async (order) => {
    try {
      const res: any = await httpService.api({
        type: 'getEventTemplateDataSources',
        urlParams: { templateId: templateData.id },
        query: {
          ordering: order ? `${order.orderSign}${order.label}` : null,
        },
      });

      const pdfReportDataSource = templateData.dashboardReport?.refId
        ? [
            {
              name: templateData.dashboardReport?.name,
              type: 'pdf',
            },
          ]
        : [];

      if (templateData.trigger === 'ALARM') {
        setData([...AlarmsDataSources, ...pdfReportDataSource, ...res]);
      } else {
        setData([...pdfReportDataSource, ...res]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      getDataSources(_order);
    }
  }, [refresh]);

  const onEditDataSource = useCallback(async (templateId, dataSourceId, type, templateData) => {
    if (type === 'pdf') {
      const pdfDataSource = {
        type,
      };

      editDataSource(pdfDataSource, templateData);
    } else {
      let usedDataSources = [];
      if (templateData.action && templateData.action === 'EMAIL_NOTIFICATION') {
        usedDataSources = getUsedDataSources(templateData.subject, usedDataSources);
        usedDataSources = getUsedDataSources(templateData.body, usedDataSources);
      } else if (
        templateData.action &&
        ['SMS_NOTIFICATION', 'PUSH_NOTIFICATION'].includes(templateData.action)
      ) {
        usedDataSources = getUsedDataSources(templateData.body);
      }

      if (usedDataSources && usedDataSources.some((id) => +id === dataSourceId)) {
        // data source is in use. show message
        await modalService.openModal('alert', {
          multiLineText: 'events-template.edit-datasource.data-source-in-use.text',
          headerText: 'events-template.edit-datasource.data-source-in-use.header',
          btnText: 'general.confirm',
          showCloseBtn: true,
        });
      } else {
        onSaveTemplate(templateData, false); // Syncs BE with used data sources and releases unused data sources before edit
        try {
          const res: any = await httpService.api({
            type: 'getEventTemplateDataSource',
            urlParams: { templateId, dataSourceId },
          });

          if (res) {
            editDataSource(serverToLocal(res), templateData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, []);

  const copyDataSource = useCallback(async (templateId, dataSourceId, type) => {
    try {
      if (type === 'VALUE') {
        const confirm = await modalService.openModal('confirm', {
          text: 'events-template.copy-datasource.confirm-text',
          headerText: 'events-template.copy-datasource.confirm-header',
          showCloseBtn: true,
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
        });
        if (confirm) {
          await httpService.api({
            type: 'copyEventTemplateDataSource',
            urlParams: { templateId, dataSourceId },
          });

          setRefresh(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteDataSource = useCallback(async (templateId, dataSourceId, type, templateData) => {
    if (type === 'pdf') {
      await onSaveTemplate(
        {
          ...templateData,
          dashboardReport: {},
        },
        false,
        false
      );

      setRefresh(true);
    } else {
      let usedDataSources = [];
      if (templateData.action && templateData.action === 'EMAIL_NOTIFICATION') {
        usedDataSources = getUsedDataSources(templateData.subject, usedDataSources);
        usedDataSources = getUsedDataSources(templateData.body, usedDataSources);
      } else if (
        templateData.action &&
        ['SMS_NOTIFICATION', 'PUSH_NOTIFICATION'].includes(templateData.action)
      ) {
        usedDataSources = getUsedDataSources(templateData.body);
      }

      if (usedDataSources && usedDataSources.some((id) => +id === dataSourceId)) {
        // data source is in use. show message
        await modalService.openModal('alert', {
          text: 'events-template.delete-datasource.data-source-in-use.text',
          headerText: 'events-template.delete-datasource.data-source-in-use.header',
          btnText: 'general.confirm',
          showCloseBtn: true,
        });
      } else {
        const confirm = await modalService.openModal('confirm', {
          text: 'events-template.delete-datasource.confirm-text',
          headerText: 'events-template.delete-datasource.confirm-header',
          showCloseBtn: true,
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
        });

        if (confirm) {
          onSaveTemplate(templateData, false); // Syncs BE with used data sources and releases unused data sources before delete
          try {
            const res: any = await httpService.api({
              type: 'deleteEventTemplateDataSource',
              urlParams: { templateId, dataSourceId },
            });

            setRefresh(true);
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }, []);

  const columnsCfg = useMemo(() => {
    return getColumnsCfg(
      templateData.id,
      onEditDataSource,
      copyDataSource,
      deleteDataSource,
      templateData
    );
  }, [templateData.id, editDataSource, copyDataSource, deleteDataSource, templateData]);

  return (
    <div className={styles.tableWrapper}>
      <Table
        data={data}
        defaultOrdering={defaultOrdering}
        disabledLoad
        getData={(firstInit, order, filters?) => {
          order && setOrder(order);
          getDataSources(order);
        }}
        colunmsCfg={columnsCfg}
      />
    </div>
  );
}

export default DataSourcesTable;
