export function getByValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (Array.isArray(value)) {
      if (value.some((v) => v.includes(searchValue))) return key;
    }
    if (searchValue === value) return key;
  }
}

export const tagFormatsMap = new Map([
  ['numberType', ['FLOAT32', 'INT8', 'INT16', 'INT32', 'UINT8', 'UINT16', 'UINT32', 'DURATION']],
  ['stringType', ['STRING']],
  ['dateTimeType', ['DATETIME', 'DATE']],
  ['booleanType', ['BOOLEAN']],
  ['geoType', ['GEO']],
]);

export const tagFormatsMapWithFloatType = new Map([
  ['numberType', ['INT8', 'INT16', 'INT32', 'UINT8', 'UINT16', 'UINT32', 'DURATION']],
  ['floatType', ['FLOAT32']],
  ['stringType', ['STRING']],
  ['dateTimeType', ['DATETIME', 'DATE']],
  ['booleanType', ['BOOLEAN']],
  ['geoType', ['GEO']],
]);

export const tagFormatMap = {
  INT32: 'numberType',
  UINT32: 'numberType',
  FLOAT32: 'floatType',
  BOOLEAN: 'booleanType',
  STRING: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
  INT8: 'numberType',
  UINT8: 'numberType',
  INT16: 'numberType',
  UINT16: 'numberType',
  GEO: 'stringType',
  IPV4: 'ipv4Type',
  DURATION: 'numberType',
};

export const assetProperiesFormatsMap = {
  1: 'stringType',
  2: 'stringType',
  3: 'geoType',
  4: 'stringType',
  5: 'stringType',
  6: 'dateTimeType',
  7: 'dateTimeType',
  8: 'stringType',
  10: 'stringType',
  11: 'stringType',
  12: 'stringType',
  13: 'stringType',
  14: 'stringType',
  15: 'stringType',
  16: 'stringType',
  17: 'stringType',
  18: 'stringType',
  19: 'stringType',
};

export const variableFormatMap = {
  NUMERIC: 'numberType',
  BOOLEAN: 'booleanType',
  TEXT: 'stringType',
  DATE: 'dateTimeType',
  DATETIME: 'dateTimeType',
};

export const propertyNameByValueTypeMap = new Map([
  ['TAG', 'tags'],
  ['TAG_TYPE', 'tagTypes'],
  ['ASSET_PROPERTY', 'assetProperties'],
  ['ALARM_INFO', 'alarmInfos'],
  ['VARIABLE', 'variables'],
  ['CALCULATION', 'localTags'],
]);

export const expressionBuilderGroupNames = [
  'assetTags',
  'tagTypes',
  'systemProperties',
  'assetProperties',
];

export const expressionBuilderGroupNamesVsValueType = new Map([
  ['TAG', 'assetTags'],
  ['TAG_TYPE', 'tagTypes'],
  ['ASSET_PROPERTY', 'assetProperties'],
  ['VARIABLE', 'systemProperties'],
]);

export const allowedOperationsMap = new Map([
  ['AGGREGATED_DATA', ['AVERAGE', 'COUNT', 'DELTA', 'FIRST', 'LAST', 'MAX', 'MIN', 'SUM']],
  ['AGGREGATED_LAST_VALUE', ['AVERAGE', 'COUNT', 'MAX', 'MIN', 'SUM']],
  ['LAST_VALUE', null],
  ['RAW_DATA', null],
  ['VARIABLE', ['AVERAGE', 'MAX', 'MIN']],
  ['VARIABLES', ['AVERAGE', 'MAX', 'MIN']],
  ['numberType', ['AVERAGE', 'COUNT', 'DELTA', 'FIRST', 'LAST', 'MAX', 'MIN', 'SUM']],
  ['floatType', ['AVERAGE', 'COUNT', 'DELTA', 'FIRST', 'LAST', 'MAX', 'MIN', 'SUM']],
  ['assetProperty', ['COUNT']],
  ['stringType', ['COUNT']],
  ['booleanType', ['COUNT']],
  ['dateTimeType', ['COUNT']],
  ['alarms', ['AVERAGE', 'COUNT', 'MAX', 'MIN', 'SUM']],
]);

export const singleValueWidgets = [
  'conditional_value',
  'image_by_value',
  'progress_bar',
  'value',
  'map',
  'radial_gauge',
  'linear_gauge',
  'pie',
  'switch',
];

export const scopeTypesMap = [
  {
    name: 'AGGREGATED_DATA',
    labels: ['aggregated-data', 'ag-top-text', 'ag-bottom-text'],
  },
  {
    name: 'LAST_VALUE',
    labels: ['last-value', 'lv-top-text', 'lv-bottom-text'],
  },
  {
    name: 'AGGREGATED_LAST_VALUE',
    labels: ['aggregated-by-last_-value', 'ag-lv-top-text', 'ag-lv-bottom-text'],
  },
  {
    name: 'RAW_DATA',
    labels: ['raw-data', 'rd-top-text', 'rd-bottom-text'],
  },
];

export const dateFilterValuesMap = new Map([
  ['DAY', { type: 'DAY', amount: 1, date: null }],
  ['WEEK', { type: 'WEEK', amount: 1, date: null }],
  ['MONTH', { type: 'MONTH', amount: 1, date: null }],
  ['YEAR', { type: 'YEAR', amount: 1, date: null }],
  ['LAST_HOUR', { type: 'HOUR', amount: 1, date: null }],
  ['LAST_TWO_HOURS', { type: 'HOUR', amount: 2, date: null }],
  ['LAST_FOUR_HOURS', { type: 'HOUR', amount: 4, date: null }],
  ['LAST_SIX_HOURS', { type: 'HOUR', amount: 6, date: null }],
  ['LAST_EIGHT_HOURS', { type: 'HOUR', amount: 8, date: null }],
  ['LAST_TWELVE_HOURS', { type: 'HOUR', amount: 12, date: null }],
]);
