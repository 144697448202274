import { getIsIncludeInSettings } from '@core/canvas/widget.utils';
import { widgetMap } from '@core/canvas/widgetMap';
import { scopeTypesMap } from '@core/mapsAndDefinitions';

export function getDefaultScope(widgetType) {
  const defaultScope = widgetMap[widgetType].settings.defaultScope;
  const enabledScopes = scopeTypesMap.filter(
    (scopeType) => !getIsIncludeInSettings(widgetType, 'disabledScope', scopeType.name)
  );

  return defaultScope ? defaultScope : enabledScopes?.length === 1 ? enabledScopes[0].name : null;
}
