import { httpService } from '@core/http/HttpService';
import { RouterValidModel } from './FormikRouterInput.interface';
import { i18nService } from '@core/i18n/I18nService';
import { buildErrorObj } from '@core/utils';
import { HttpRequestCfg } from '@core/http/http.interface';

export const handleBlurRouterInput = (btnClicked, formik, name) => {
  setTimeout(() => {
    if (btnClicked.current) {
      btnClicked.current = false;
    } else {
      // We only set the field as touched if blur is not
      // from pressing the validate button.
      formik.setFieldTouched(name, true);
    }
  }, 300);
};

export const validateRouterSerial = (
  btnClicked,
  type,
  routerSerial,
  formik,
  initialValuesRouter,
  setCatalogNumber,
  name
) => {
  btnClicked.current = true; // Needed for onBlur logic.

  const apiCfg: HttpRequestCfg = {
    type: 'validateRouter',
    data: { routerSerial: routerSerial.text }
  };
  if (type === 'associateToAsset') {
    apiCfg.query = { for_asset: true };
  }

  httpService.api(apiCfg).then((res: RouterValidModel) => {
    if (routerSerial.text === initialValuesRouter.text) {
      if (type === 'associateToAsset') {
        setCatalogNumber(res.catalogNumber);
      }
      formik.setFieldValue(name, {
        id: res.routerId || Math.random(),
        text: routerSerial.text,
        message: null
      });
    } else {
      if (res.isValid) {
        if (type === 'associateToAsset') {
          setCatalogNumber(res.catalogNumber);
        }
        formik.setFieldValue(name, { id: res.routerId, text: routerSerial.text, message: null });
      } else {
        formik.setFieldValue(name, {
          id: Math.random(),
          text: routerSerial.text,
          message: res.message && i18nService.translate(`errors.${res.message}`)
        });
        formik.setFieldTouched(name, true);
      }
    }
  });
};
