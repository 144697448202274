import React, { useState } from 'react';

function ImageIcon(props) {
  const { image, onChange, height, width } = props;
  const [dimensions, setDimensions] = useState<any>({});

  const onImageLoad = async ({ target: img }) => {
    const newWidth = img.offsetHeight === img.offsetWidth ? height : width;
    setDimensions({ height, width: newWidth });
    onChange && onChange({ height, width: newWidth });
  };

  return (
    <img
      src={image}
      onLoad={onImageLoad}
      width={'100%'}
      style={{
        visibility: dimensions.width ? 'visible' : 'hidden',
      }}
    />
  );
}

export default ImageIcon;
