import React, { useState, useEffect, useMemo } from 'react';
import { i18nService } from '@core/i18n/I18nService';
import Icon from '@components/Icon';
import styles from './BasicDatePicker.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import classNames from 'classnames';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useSelector } from '@src/redux/useSelector';

function BasicDatePicker(props) {
  const {
    selectedChanged,
    singleDatePicker,
    maxDate,
    minDate,
    monthNames,
    disableCustomLabel = false,
    daysOfWeek,
    firstDay,
    value,
    height = 37,
    displayRanges = true,
    openDirection = 'down',
    timePicker = false,
    format = 'DD/MM/YYYY',
    disabled,
    buildFormat,
    inputClassname = '',
    allowNullValue = false,
    allowEmptyDefaultValue = false,
    dateWrapperStyle = undefined,
  } = props;
  const [date, setDate] = useState(
    allowNullValue && !value ? value : value ? moment(value) : moment()
  );
  const [dateFormat, setDateFormat] = useState(format);
  const languageId = useSelector((state) => state.config.languageId);

  useEffect(() => {
    let rangesWidth = '140px';
    !displayRanges && singleDatePicker && disableCustomLabel && (rangesWidth = '0px');
    document.documentElement.style.setProperty(`--datePickerRangesWidth`, rangesWidth);
  }, [displayRanges, singleDatePicker, disableCustomLabel]);

  useEffect(() => {}, [value, languageId]);

  useEffect(() => {
    setDateFormat(format);
  }, [format]);

  const locale = useMemo(
    () => ({
      format,
      firstDay, //1=Mo,2=Tu
      applyLabel: i18nService.translate('date-picker.apply'),
      cancelLabel: i18nService.translate('date-picker.cancel'),
      customRangeLabel: i18nService.translate('date-picker.custom'),
      monthNames, //['1', '2', '3', '4', '5', '6', '7', '8', '9', '10/', '11/', '12'],
      daysOfWeek, //['רא', 'שנ', 'של', 'רב', 'חמ', 'שי', 'שב']
    }),
    [languageId, format, firstDay, monthNames, daysOfWeek]
  );

  return (
    <div
      style={dateWrapperStyle ? dateWrapperStyle : { height, width: '100%' }}
      className="dateWrapper">
      {disabled ? (
        <div className={styles.dateRangeInput}>
          <span
            title={date ? (buildFormat ? buildFormat(date.format()) : date.format(dateFormat)) : ''}
            className="ellipsis-overflow">
            {date ? (buildFormat ? buildFormat(date.format()) : date.format(dateFormat)) : ''}
          </span>
          <Icon className={styles.icon} type="dropDown" />
        </div>
      ) : (
        <DateRangePicker
          parentEl="#liveDashboard"
          timePicker={timePicker}
          singleDatePicker={true}
          showDropdowns={true}
          disabled={disabled}
          startDate={date}
          minYear={minDate || parseInt(moment().subtract(100, 'years').format('YYYY'))}
          maxYear={maxDate || parseInt(moment().add(100, 'years').format('YYYY'))}
          onEvent={(e, picker) => {
            if (e.type === 'apply') {
              setDate(picker.startDate);
              selectedChanged(picker.startDate.format());
            }
          }}
          locale={locale}
          drops={openDirection}
          // opens="center"
          applyClass={classNames(styles.applyButton, styles.btn)}
          cancelClass={classNames(styles.cancelButton, styles.btn)}>
          <div className={styles.dateRangeInput}>
            <span
              title={
                date ? (buildFormat ? buildFormat(date.format()) : date.format(dateFormat)) : ''
              }
              className={classNames('ellipsis-overflow', inputClassname)}>
              {allowEmptyDefaultValue && !value
                ? ''
                : date
                ? buildFormat
                  ? buildFormat(date.format())
                  : date.format(dateFormat)
                : ''}
            </span>
            <Icon className={styles.icon} type="dropDown" />
          </div>
        </DateRangePicker>
      )}
    </div>
  );
}

export default BasicDatePicker;
