import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import {
  convertCalculationToObject,
  isFloatCalculation,
} from '@pages/CreateWidgetPage/widgets.utils';
import { getCalculationItems } from './Calculations.utils';
import { usePrevious } from '@core/hooks/usePrevious';
import { useSelector } from '@src/redux/useSelector';
import { getCalculationItemsInMultiply } from '@src/pages/CreateWidgetPage/DataStepComponent/StepThree/Multiply/Multiply.utils';

export function useCalculations(props) {
  const { widgetData, setWidgetData } = props;
  const { metrics, calculations, assetProperties, tags, tagTypes, localTags, alarms, type } =
    widgetData;
  const [calculationItems, setCalculationItems] = useState(calculations);
  const prevCalculationsItems: any = usePrevious(calculationItems) || calculationItems;
  const defaultDecimalDigits = useSelector(
    (state) => state.config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
  );

  useEffect(() => {
    if (type === 'update_asset') {
      const calculationsObject = convertCalculationToObject(calculations);
      getCalculationItemsInMultiply(widgetData, setCalculationItems, calculationsObject);
    } else if (type !== 'alarms') {
      const calculationsObject = convertCalculationToObject(calculations);
      getCalculationItems(widgetData, setCalculationItems, calculationsObject);
    }
  }, [metrics, assetProperties, tags, tagTypes, localTags, alarms, calculations]);

  useEffect(() => {
    if (prevCalculationsItems && !isEqual(prevCalculationsItems, calculationItems)) {
      if (
        widgetData?.customization &&
        (type === 'table' || type === 'line' || type === 'columns')
      ) {
        const getUpdatedColumnsOrYAxes = (customizationArray) => {
          return customizationArray.map((item) => {
            const isFloat =
              calculationItems.some(
                (calc, calcIndex) =>
                  calc.expression &&
                  calc.expression !== '' &&
                  isFloatCalculation(calculationItems[calcIndex], widgetData) &&
                  calc.valueId === item.id
              ) ||
              (item.tagType === 'FLOAT32' && item.operation !== 'COUNT') ||
              (item.operation && item.operation !== 'COUNT') ||
              widgetData?.metrics.find(
                (metric) => metric.valueId === item.id && metric.valueType === item.valueType
              )?.type === 'floatType';
            if (isFloat) {
              return { ...item, decimalDigits: item.decimalDigits || defaultDecimalDigits };
            }
            return { ...item, decimalDigits: null };
          });
        };

        switch (type) {
          case 'table':
            setWidgetData((prevState) => {
              return {
                ...prevState,
                calculations: calculationItems,
                customization: {
                  ...widgetData.customization,
                  columns: getUpdatedColumnsOrYAxes(widgetData.customization.columns),
                },
              };
            });
            break;
          case 'line':
            setWidgetData((prevState) => {
              return {
                ...prevState,
                calculations: calculationItems,
                customization: {
                  ...widgetData.customization,
                  yAxes: getUpdatedColumnsOrYAxes(widgetData.customization.yAxes),
                },
              };
            });
            break;
          case 'columns':
            setWidgetData((prevState) => {
              return {
                ...prevState,
                calculations: calculationItems,
                customization: {
                  ...widgetData.customization,
                  columns: getUpdatedColumnsOrYAxes(widgetData.customization.columns),
                  yAxes: getUpdatedColumnsOrYAxes(widgetData.customization.yAxes),
                },
              };
            });
            break;
          default:
            break;
        }
      } else {
        setWidgetData((prevState) => {
          return {
            ...prevState,
            calculations: calculationItems,
          };
        });
      }
    }
  }, [calculationItems, prevCalculationsItems]);

  function onCalculationChanged(updatedCalculation) {
    setCalculationItems((prevState) => {
      const newCalculations = [...prevState];

      const index = newCalculations?.findIndex(
        (d) =>
          d.valueId === updatedCalculation.valueId &&
          d.valueType === updatedCalculation.valueType &&
          d.operation === updatedCalculation.operation
      );

      if (index > -1) {
        newCalculations[index] = updatedCalculation;
      } else {
        newCalculations[newCalculations.length] = updatedCalculation;
      }

      return newCalculations;
    });
  }

  return { calculationItems, setCalculationItems, onCalculationChanged };
}
