import React, { useEffect, useState } from 'react';
import { useSelector } from '@redux/useSelector';
import styles from './AssetInfoGeneral.scss';
import AssetInfoGeneralEdit from './AssetInfoGeneralEdit';
import AssetInfoGeneralView from './AssetInfoGeneralView';
import { getImageData } from '@modals/EditAssetModal/EditAssetModal.utils';

function AssetInfoGeneral() {
  const [editMode, setEditMode] = useState(false);
  const data = useSelector((state) => state.editAsset.general);
  const [imageUrl, setImageUrl] = useState<string>(null);

  useEffect(() => {
    data?.galleryImageId && getImageData(data?.galleryImageId, setImageUrl);
  }, [data?.galleryImageId]);

  return (
    <div className="max-height">
      <div className={styles.formWrapper}>
        {editMode ? (
          <AssetInfoGeneralEdit
            data={data}
            setEditMode={setEditMode}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />
        ) : (
          <AssetInfoGeneralView
            data={data}
            onEditClicked={() => setEditMode(true)}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />
        )}
      </div>
    </div>
  );
}

export default AssetInfoGeneral;
