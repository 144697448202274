import ToolBar from '@components/ToolBar';
import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { template, compact } from 'lodash';
import styles from './TemplatesTab.scss';
import TemplatesTable from './TemplatesTable/TemplatesTable';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { useSelector } from '@redux/useSelector';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';
import { getState } from '@src/redux/store';
import { httpService } from '@core/http/HttpService';
import { apiMap } from '@core/http/apiMap';
import { useHistory } from 'react-router-dom';
import { getDefaultEventTemplateData } from '../EditTemplate/EditTemplate.utils';

function TemplatesTab(props) {
  const [searchText, setSearchText] = useState('');
  const [clearSearch, setClearSearch] = useState(false);
  const [lastSearches, setLastSearches] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const history = useHistory();
  const state = getState();
  const [showAll, setShowAll] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);
  const userOrganizationId = state.login && state.login.userOrganizationId;

  const userOrgType = useSelector(
    (state) => state.config.organizationDetails && state.config.organizationDetails.type
  );

  const search = (searchText) => {
    setSearchText(searchText);
  };

  const getLastSearches = () => {
    const organizationId = getState().login.userOrganizationId;
    httpService
      .api({
        type: 'getLastSearches',
        query: {
          url: template(apiMap['getEventTemplates'].url)({ organizationId }),
        },
        disableBI: true,
      })
      .then(({ searches }: any) => {
        setLastSearches(searches);
      });
  };

  const openCreateTemplate = useCallback(() => {
    httpService
      .api({
        type: 'getEventTemplateDefaultName',
      })
      .then((res: any) => {
        const initialData = {
          ...getDefaultEventTemplateData(userOrganizationId),
          name: res.name,
        };
        history.push(`/main/events-management/templates/create`, { templateData: initialData });
      });
  }, [searchText, showArchived]);

  const hasPermission = useMemo(() => getPermissionStatus('EVENTS', 'EDIT'), []);

  const toolbarCfg: ToolbarCfg = {
    search: {
      clearSearch,
      searchFunc: search,
      getLastSearches,
      lastSearches,
      pageName: 'templates',
    },
    buttons: compact([
      {
        type: 'view_all',
        onClick: () => {
          setShowAll(!showAll);
          setShowArchived(false);
        },
        disabled: !hasPermission,
        text: showAll ? 'assets-tab.show-active' : 'event-templates-tab.view-all',
        color: 'rgb(135, 155, 183)',
      },
      {
        type: 'events_archived',
        onClick: () => {
          setShowArchived(!showArchived);
          setShowAll(false);
        },
        disabled: !hasPermission,
        text: showArchived ? 'assets-tab.show-active' : 'assets-tab.show-archived',
        color: 'rgb(135, 155, 183)',
      },
      {
        type: 'events_add',
        text: 'assets-tab.add-asset',
        onClick: openCreateTemplate,
        disabled: !hasPermission,
      },
    ]),
  };

  const setResoults = (total, results) => {
    setTotalCount(total);
    setResultsCount(results);
  };

  return (
    <div className={classNames('max-height', styles.templatesTab)}>
      <ToolBar toolbarCfg={toolbarCfg} totalCount={totalCount} resultsCount={resultsCount} />
      <TemplatesTable
        searchText={searchText}
        showArchived={showArchived}
        showAll={showAll}
        setResolts={setResoults}
      />
    </div>
  );
}

export default TemplatesTab;
