import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { VpnWidgetProps } from './VpnWidget.interface';
import Button from '@components/Button';
import { useSelector } from '@src/redux/useSelector';
import { dispatch } from '@src/redux/store';
import { setModal } from '@src/redux/widgetModals';
import { httpService } from '@core/http/HttpService';
import { isMobile, isTablet } from 'react-device-detect';
import { getUrlForVpn } from '../RemoteAccessWidget.utils';

const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    display: `flex`,
    height: ({ customization }: any) => {
      let textHeight = 0;
      if (customization.textualRemark && customization.textualRemark !== '')
        textHeight += customization.textualRemarkFontSize;

      if (customization.requestSentText && customization.requestSentText !== '')
        textHeight += customization.requestSentFontSize;

      return `calc(100% - ${textHeight}px)`;
    },
    width: ({ customization }: any) => (customization?.buttonType === 'IMAGE_BUTTON' ? '100%' : ''),
    minHeight: 38,
  },
  text: {
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function VpnWidget(props: VpnWidgetProps) {
  const classes = useStyles(props);
  const { customization, isPreview, assetId, macchinaConnected } = props;
  const currentLayout = useSelector((state) => state.dashboardEditor.currentLayout);
  const isUniCloudApp = useSelector((state) => state.clientState.clientType === 'uniCloudApp');

  const isDisabled =
    isUniCloudApp || currentLayout !== 'DESKTOP' || isMobile || isTablet || !macchinaConnected;

  function handleClick() {
    !isPreview &&
      httpService.api({ type: 'getClientRemoteVPN' }).then((res: { clientUrl: string }) =>
        dispatch(
          setModal({
            type: 'remoteVPN',
            onClose: (confirm) => {
              if (confirm) {
                return onStart();
              }
            },
            args: {
              link: res.clientUrl,
            },
          })
        )
      );
  }

  async function onStart() {
    const remoteAccessUrl = await getUrlForVpn(assetId);
    return window.open(`${remoteAccessUrl}`, '_blank');
  }

  return (
    <Typography component="div" className={classes.buttonWrapper}>
      <Button
        disabled={isDisabled && !isPreview}
        mode={'big'}
        onClick={handleClick}
        styles={{
          backgroundColor: customization.enabledButtonColor,
          fontSize: '16px',
          color: customization.buttonTextColor,
        }}>
        {customization.buttonText}
      </Button>
    </Typography>
  );
}

export default VpnWidget;
