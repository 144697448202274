import React, { useEffect, useCallback, useState } from 'react';
import { connect, getIn, ErrorMessage, isNaN } from 'formik';
import classNames from 'classnames';
import InputBase from '@material-ui/core/InputBase';
import I18n from '@components/I18n';
import styles from './FormikInput.scss';
import { FormikInputProps } from './formikInput.interface';
import { handleChangeInput, getStyles, handleBlurInput } from './FormikInput.utils';

function FormikInput(props: FormikInputProps | any) {
  const {
    formik,
    name,
    label,
    validate,
    disabled,
    className,
    isRequired,
    maxLength,
    value,
    labelInline = true,
    mode = '',
    onLostFocus,
    minValue = null,
    maxValue = null,
    removeLeadingSpaces = false,
    trimOnLostFocus = false,
    maxDecimalDigitRegex = null,
    ...restProps
  } = props;
  const [inputValue, setInputValue] = useState(value);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const handleChange = useCallback(
    (e) => {
      const isFloat = Number.parseFloat(e.target.value) && e.target.value % 1 !== 0;

      if (isNaN(Number(e.target.value)) || e.target.value === '') {
        handleChangeInput(e, maxLength, setInputValue, formik, name, removeLeadingSpaces);
        return;
      }

      if (
        (!minValue || Number.parseFloat(e.target.value) >= minValue) &&
        (!maxValue || Number.parseFloat(e.target.value) <= maxValue)
      ) {
        if (!isFloat || !maxDecimalDigitRegex || e.target.value.match(maxDecimalDigitRegex)) {
          handleChangeInput(e, maxLength, setInputValue, formik, name, removeLeadingSpaces);
        }
      }
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    handleBlurInput(formik, onLostFocus, error, setInputValue, name, trimOnLostFocus);
  }, [formik]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div
      className={classNames(
        labelInline ? styles.fieldInlineWrapper : styles.fieldWrapper,
        mode === 'NewMobile' && styles.wrapperMobileMode,
        className
      )}>
      {labelInline ? (
        <label
          className={classNames(
            styles.fieldLabelInline,
            isRequired && 'asterisk',
            isRequired && styles.isRequired,
            mode === 'NewMobile' && styles.mobileMode,
            'ellipsis-overflow'
          )}
          style={restProps?.editLabelStyle}>
          <I18n>{label}</I18n>:
        </label>
      ) : (
        <I18n element="div" className={styles.fieldLabel}>
          {label}
        </I18n>
      )}
      <div className={labelInline ? styles.fieldInput : null}>
        <InputBase
          id={getStyles(error, touched, labelInline, mode, styles)}
          value={inputValue || ''}
          disabled={disabled}
          fullWidth
          autoComplete="off"
          onChange={handleChange}
          onBlur={handleBlur}
          type={restProps.inputType}
        />
        <ErrorMessage name={name}>
          {(err: any) => {
            return (
              <I18n className={styles.error} {...(err.args || {})} element="div" noEllipsis>
                {err?.text || (restProps?.errors && restProps?.errors[name]) || err}
              </I18n>
            );
          }}
        </ErrorMessage>
      </div>
    </div>
  );
}

export default connect(FormikInput);
