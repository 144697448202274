import React, { useCallback, useState } from 'react';
import Button from '@components/Button';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import styles from './ModalWithSelect.scss';
import MultiSelect from '@components/MultiSelect';
import Select from '@components/Select';
import Icon from '@components/Icon';

function ModalWithSelect(props: ModalComponentProps) {
  const {
    dismiss,
    args: {
      title,
      options,
      condition,
      changedKey,
      text,
      showCloseBtn = true,
      selectText,
      selectCfg: { isMulti, getOptionLabel, getOptionValue, defaultSelect = true },
      ...rest
    },
  } = props;
  const [selected, setSelected] = useState(defaultSelect && options);
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const confirm = useCallback(() => dismiss(selected), [dismiss, selected]);

  const onSelect = (newSelected) => {
    isMulti && setSelected(newSelected);
    !isMulti && newSelected && setSelected(newSelected);
  };

  return (
    <div className={styles.wrapper}>
      {showCloseBtn && (
        // <div className={styles.closeBtn}>
        //   <IconButton onClick={close}>
        //     <Close />
        //   </IconButton>
        // </div>
        <div className={styles.modalHeader}>
          <I18n {...rest}>{title}</I18n>
          <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
        </div>
      )}
      <div className={styles.modalContent}>
        <I18n noEllipsis {...rest} element="div">
          {text}
        </I18n>
        <div className={styles.selectWrapper}>
          {selectText && (
            <div className={styles.selectText}>
              <I18n {...rest}>{selectText}</I18n>:
            </div>
          )}
          {isMulti ? (
            <MultiSelect
              id="groupByOptions"
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChange={onSelect}
              values={selected}
              optionType="checkbox"
            />
          ) : (
            <Select
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChange={onSelect}
              values={selected}
            />
          )}
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']} onClick={cancel}>
          <I18n>general.cancel</I18n>
        </Button>
        <Button
          styles={{ marginLeft: 13 }}
          mode="bigFont"
          onClick={confirm}
          disabled={condition({ [changedKey]: selected })}>
          <I18n>general.confirm</I18n>
        </Button>
      </div>
    </div>
  );
}

export default ModalWithSelect;
