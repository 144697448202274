import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EditTemplate from './EditTemplate/EditTemplate';
import EventsManagementTabs from './EventsManagementsTabs/EventsManagementTabs';

function EventsManagementPage(props) {
  return (
    <div className="max-height">
      <Switch>
        <Route exact path={`${props.match.url}/templates/create`} component={EditTemplate} />
        <Route
          exact
          path={`${props.match.url}/templates/:templateId/create-datasource/`}
          component={EditTemplate}
        />
        <Route path={`${props.match.url}`} component={EventsManagementTabs} />
      </Switch>
    </div>
  );
}

export default EventsManagementPage;
