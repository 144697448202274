import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { usePrevious } from '@core/hooks/usePrevious';

// Components
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';
import MaterialCheckbox from '@components/Checkbox';
import LineCustomizationRow from './LineCustomizationRow';
import I18n from '@components/I18n';

// Services | Interfaces
import { i18nService } from '@core/i18n/I18nService';
import { getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import { LineCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { LineProps } from './Line.interface';
import {
  alignmentOptions,
  getDefaultWidgetColorPalette,
  positionOptions,
} from '@core/canvas/widget.utils';
import {
  changeWidgetTable,
  setColumnsDefaultData,
  changeWidgetData,
  tagSettingsCfg,
  yAxesCfg,
  legendLabels,
  onLegendRowChanged,
  legendCfg,
  thresholdCfg,
} from './Line.utils';

// 3rd party
import classNames from 'classnames';
import { cloneDeep, omit } from 'lodash';
import { getArrayFromObjectWithOrder } from '@core/utils';
import DraggableTable from '@components/DraggableTable';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 55,
    fontWeight: 800,
  },
  columnTitle: {
    marginBottom: 13,
  },
  columnWidthTitle: {
    fontWeight: 800,
    width: '152px',
  },
  columnsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    paddingRight: 10,
  },
  section: {
    margin: '10px 0',
  },
  rowSection: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '13px 0 13px 32px',
  },
  legend: {
    display: 'flex',
    margin: '13px 0 13px 32px',
  },
  brushDisplayItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 15px 0px',
  },
  tagSelectionItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 15px 0px',
  },
  columnWidthItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    width: 'calc(100% - 115px)',
    margin: '0 !important',
  },
  numberField: {
    width: '100px',
    margin: '0 !important',
  },
  cbLabel: {
    marginLeft: 6,
    fontSize: 14,
  },
  radioLabel: {
    fontSize: 14,
    marginLeft: 0,
  },
  colorItem: {
    margin: '13px 0 13px 32px',
  },
  paletteColorItem: {
    margin: '8px 0 13px 20px',
  },
  text: {
    fontSize: '14px',
    width: '105px',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
    padding: 10,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 0px 10px 0px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
  colotPalleteWrapper: {
    display: 'flex',
    marginLeft: '30px',
  },
  colotPalleteText: {
    marginRight: '5px',
    fontSize: '14px',
  },
}));

const selectCustomStyle = { control: { height: 40 }, container: { width: 'calc(100% - 115px)' } };
const selectFullWidthCustomStyle = { control: { height: 40 }, container: { width: '100%' } };

function Line(props: LineProps) {
  const classes = useStyles(props);
  const { widgetData, setWidgetData, previewData, defaultDecimalDigits } = props;
  const customization = cloneDeep(widgetData.customization);
  const dateOptions = useMemo(() => getDateTimeOptions('DATE'), []);
  const timeOptions = useMemo(() => getDateTimeOptions('TIME'), []);

  const prevCustomization: LineCustomization = usePrevious(widgetData?.customization);

  const displayMoreThanOneYAxes = useMemo(
    () => customization?.yAxes?.filter((y) => y.show !== 'NO').length > 1,
    [customization]
  );

  const {
    columns,
    yAxes,
    subtitle_text = '',
    subtitle_textColor = 'var(--widgetsFont)',
    subtitle_alignment = 'CENTER',
    legend = {
      visible: true,
      position: 'BOTTOM',
      alignment: 'CENTER',
      structure: {
        assetId: { order: 0, show: true },
        assetName: { order: 1, show: true },
        tagName: { order: 2, show: true },
      },
    },
    threshold = [
      {
        id: 1,
        value: 0,
        show: false,
        style: 'SOLID',
        color: '#000000',
        displayName: i18nService.translate(
          'create-widget-page.create-widget.step-four.columns.max-value'
        ),
      },
      {
        id: 2,
        value: 0,
        show: false,
        style: 'SOLID',
        color: '#000000',
        displayName: i18nService.translate(
          'create-widget-page.create-widget.step-four.columns.min-value'
        ),
      },
    ],
    tooltip_visible = true,
    tooltip_shared = true,
    tooltip_showAxisLegend = true,
    tooltip_decimalDigits = defaultDecimalDigits,
    dataLabels_visible = false,
    dataLabels_textColor = 'var(--widgetsFont)',
    brush_display = widgetData.scope === 'RAW_DATA',
    dataLabels_decimalDigits = defaultDecimalDigits,
    dateFormat = 'DATE',
    timeFormat = 'TIME_FORMAT_ONE',
    allowTagSelection = false,
    colorPaletteMode = 'AUTO',
    colorPalette = getDefaultWidgetColorPalette().slice(0, 4),
  } = (customization as LineCustomization) || {};

  const [legendStructureColumns, setLegendStructureColumns] = useState(
    getArrayFromObjectWithOrder(legend.structure, 'item').map((element, idx) => {
      return { ...element, label: legendLabels[element['item']], order: idx };
    })
  );

  useEffect(() => {
    if (
      prevCustomization?.colorPaletteMode === 'MANUAL' &&
      customization?.colorPaletteMode === 'AUTO'
    ) {
      changeWidgetData(getDefaultWidgetColorPalette().slice(0, 4), 'colorPalette', setWidgetData);
    }
  }, [customization?.colorPaletteMode]);

  useEffect(() => {
    const reorderedLegend = legendStructureColumns.map((col, idx) => {
      return { ...col, order: idx };
    });
    setWidgetData((prevState) => ({
      ...prevState,
      customization: {
        ...prevState.customization,
        legend: {
          ...customization?.legend,
          structure: {
            assetId: omit(
              {
                ...reorderedLegend.find((col) => col['item'] === 'assetId'),
              },
              ['item', 'label']
            ),
            assetName: omit(
              {
                ...reorderedLegend.find((col) => col['item'] === 'assetName'),
              },
              ['item', 'label']
            ),
            tagName: omit(
              {
                ...reorderedLegend.find((col) => col['item'] === 'tagName'),
              },
              ['item', 'label']
            ),
          },
        },
      },
    }));
  }, [legendStructureColumns]);

  useEffect(() => {
    if (!customization) {
      updateWidgetData({
        dataLabels_textColor,
        dataLabels_visible,
        dataLabels_decimalDigits,
        legend,
        subtitle_alignment,
        subtitle_text,
        subtitle_textColor,
        tooltip_decimalDigits,
        tooltip_shared,
        tooltip_showAxisLegend,
        tooltip_visible,
        brush_display,
        dateFormat,
        timeFormat,
        colorPaletteMode,
        threshold,
      });
    } else {
      const tempCustomization = cloneDeep(widgetData.customization);
      let customizationChanged = false;
      if (!customization.dateFormat || !customization.timeFormat) {
        tempCustomization.dateFormat = 'DATE';
        tempCustomization.timeFormat = 'TIME_FORMAT_ONE';
        customizationChanged = true;
      }
      if (!customization.colorPalette) {
        let palette = customization.columns?.map((c) => c.color);
        if (palette.length < 4)
          palette = palette.concat(getDefaultWidgetColorPalette().slice(palette.length, 4));

        tempCustomization.colorPalette = palette;
        customizationChanged = true;
      }

      if (
        widgetData.metrics.length === 1 &&
        customization.columns &&
        customization.columns[0] &&
        customization.columns[0]?.name?.startsWith(
          'create-widget-page.create-widget.step-four.line.default-name'
        )
      ) {
        tempCustomization.columns = [
          {
            ...customization.columns[0],
            name: widgetData.metrics[0].name,
            displayName:
              widgetData.metrics[0].name > 25
                ? widgetData.metrics[0].name.slice(0, 25)
                : widgetData.metrics[0].name,
          },
        ];
        customizationChanged = true;
      }

      if (
        widgetData.metrics.length === 1 &&
        customization.yAxes &&
        customization.yAxes[0] &&
        customization.yAxes[0]?.name?.startsWith(
          'create-widget-page.create-widget.step-four.line.default-name'
        )
      ) {
        tempCustomization.yAxes = [
          {
            ...customization.yAxes[0],
            name: widgetData.metrics[0].name,
            displayName:
              widgetData.metrics[0].name > 25
                ? widgetData.metrics[0].name.slice(0, 25)
                : widgetData.metrics[0].name,
          },
        ];
        customizationChanged = true;
      }

      if (customizationChanged) updateWidgetData({ ...tempCustomization });
    }
  }, []);

  const updateWidgetData = (values, field = null) => {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: field
        ? {
            ...prevState.customization,
            [field]: values,
          }
        : {
            ...prevState.customization,
            ...values,
          },
    }));
  };

  const subtitleAlignment = useMemo(
    () => subtitle_alignment && alignmentOptions.find((o) => o.value === subtitle_alignment),
    [subtitle_alignment]
  );
  const lengedPosition = useMemo(
    () => legend.position && positionOptions.find((o) => o.value === legend.position),
    [legend.position]
  );
  const legendAlignment = useMemo(
    () => legend.alignment && alignmentOptions.find((o) => o.value === legend.alignment),
    [legend.alignment]
  );

  useEffect(() => {
    setColumnsDefaultData(
      customization,
      widgetData,
      previewData,
      setWidgetData,
      defaultDecimalDigits
    );
  }, [previewData]);

  function setCustomizationData(data, key) {
    const values = cloneDeep(customization);
    values[key] = data;
    updateWidgetData(values);
  }

  return (
    <div className={classes.wrapper}>
      {/* columns-settings */}
      <div className={classes.section}>
        <div className={classNames(classes.titleWrapper, classes.columnTitle)}>
          <I18n>white-labeling.dashboards-colors.lines-color-palette</I18n>
        </div>
        <div className={classes.colotPalleteWrapper}>
          <RadioGroup
            value={customization?.colorPaletteMode || ''}
            onChange={(e) => setCustomizationData(e.target.value, 'colorPaletteMode')}>
            <FormControlLabel
              value="AUTO"
              control={<Radio />}
              label={i18nService.translate('enum.AUTO')}
            />
            <FormControlLabel
              value="MANUAL"
              control={<Radio />}
              label={i18nService.translate('enum.MANUAL')}
            />
          </RadioGroup>
          {colorPalette?.map((color, index) => (
            <div key={`${color}-${index}`} className={classNames(classes.paletteColorItem)}>
              <ColorInput
                value={color}
                customeMarginButton={0}
                popOverPosition={{ left: 1 }}
                isParentRelative={false}
                disabled={customization?.colorPaletteMode === 'MANUAL' || false}
                colorChange={(value) =>
                  changeWidgetData(
                    colorPalette.map((originalVal, idx) => (idx === index ? value : originalVal)),
                    'colorPalette',
                    setWidgetData
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classNames(classes.titleWrapper, classes.columnTitle)}>
          <I18n>create-widget-page.create-widget.step-four.tags-settings</I18n>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            {tagSettingsCfg.map((header, idx) => (
              <div
                title={header.headerLabel && i18nService.translate(header.headerLabel)}
                key={`columns_${idx}`}
                className={classes.header}
                style={header.style}>
                {header.headerLabel && (
                  <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                )}
              </div>
            ))}
          </div>
          {columns?.map((col, idx) => (
            <LineCustomizationRow
              colorPaletteMode={colorPaletteMode}
              columnCfg={tagSettingsCfg}
              column={col}
              colIndex={idx}
              index={idx}
              key={`columns_${idx}`}
              type="columns"
              isFirst={idx === 0}
              isLast={idx + 1 === columns.length}
              columnChanged={(column) => changeWidgetTable(column, idx, 'columns', setWidgetData)}
            />
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classNames(classes.titleWrapper, classes.columnTitle)}>
          <I18n>create-widget-page.create-widget.step-four.threshold</I18n>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            {thresholdCfg.map((header, idx) => (
              <div
                title={header.headerLabel && i18nService.translate(header.headerLabel)}
                key={`columns_${idx}`}
                className={classes.header}
                style={header.style}>
                {header.headerLabel && (
                  <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                )}
              </div>
            ))}
          </div>
          {threshold?.map((col, idx) => (
            <LineCustomizationRow
              colorPaletteMode={colorPaletteMode}
              columnCfg={thresholdCfg}
              column={col}
              colIndex={idx}
              index={idx}
              key={`threshold_${idx}`}
              type={'threshold'}
              isFirst={idx === 0}
              isLast={idx + 1 === threshold.length}
              columnChanged={(column) => changeWidgetTable(column, idx, 'threshold', setWidgetData)}
            />
          ))}
        </div>
      </div>
      {/* y-axis */}
      <div className={classes.section}>
        <div className={classNames(classes.titleWrapper, classes.columnTitle)}>
          <I18n>create-widget-page.create-widget.step-four.y-axis</I18n>
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            {yAxesCfg().map((header, idx) => (
              <div
                title={header.headerLabel && i18nService.translate(header.headerLabel)}
                key={`yAxes_${idx}`}
                className={classes.header}
                style={header.style}>
                {header.headerLabel && (
                  <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                )}
              </div>
            ))}
          </div>
          {yAxes?.map((col, idx) => (
            <LineCustomizationRow
              columnCfg={yAxesCfg()}
              displayMoreThanOneYAxes={displayMoreThanOneYAxes}
              key={`yAxes_${idx}`}
              column={col}
              colIndex={idx}
              index={idx}
              isFirst={idx === 0}
              isLast={idx + 1 === yAxes.length}
              type="yAxes"
              columnChanged={(column) => changeWidgetTable(column, idx, 'yAxes', setWidgetData)}
            />
          ))}
        </div>
        {widgetData.scope === 'AGGREGATED_DATA' && (
          <div className={classes.brushDisplayItem}>
            <MaterialCheckbox
              color="primary"
              checked={brush_display}
              onClick={() => changeWidgetData(!brush_display, 'brush_display', setWidgetData)}
            />
            <I18n className={classes.cbLabel}>
              create-widget-page.create-widget.step-four.brush_display
            </I18n>
          </div>
        )}
        {widgetData.metrics.length > 1 && widgetData.metrics.length < 5 && (
          <div className={classes.tagSelectionItem}>
            <MaterialCheckbox
              color="primary"
              checked={allowTagSelection}
              onClick={() =>
                changeWidgetData(!allowTagSelection, 'allowTagSelection', setWidgetData)
              }
            />
            <I18n className={classes.cbLabel}>
              create-widget-page.create-widget.step-four.allow-tag-selection
            </I18n>
          </div>
        )}
      </div>
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.date-time</I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text} noEllipsis>
            create-widget-page.create-widget.step-four.date-format
          </I18n>
          <Select
            styles={selectFullWidthCustomStyle}
            options={dateOptions}
            value={dateFormat && dateOptions.find((opt) => opt.value === dateFormat)}
            onChange={(option) => changeWidgetData(option.value, 'dateFormat', setWidgetData)}
          />
        </div>
        <div className={classes.item}>
          <I18n className={classes.text} noEllipsis>
            create-widget-page.create-widget.step-four.time-format
          </I18n>
          <Select
            styles={selectFullWidthCustomStyle}
            options={timeOptions}
            value={timeFormat && timeOptions.find((opt) => opt.value === timeFormat)}
            onChange={(option) => changeWidgetData(option.value, 'timeFormat', setWidgetData)}
          />
        </div>
      </div>
      {/* subtitle */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.subtitle</I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.text</I18n>
          <TextField
            value={subtitle_text}
            margin="dense"
            variant="outlined"
            className={classes.textField}
            inputProps={{
              maxLength: 50,
              style: { color: 'var(--systemFont)' },
            }}
            onChange={(e) => changeWidgetData(e.target.value, 'subtitle_text', setWidgetData)}
          />
        </div>
        <div className={classNames(classes.item, classes.colorItem)}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.color</I18n>
          <ColorInput
            disabled={!subtitle_text}
            value={subtitle_textColor}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            customeMarginButton={0}
            colorChange={(value) => changeWidgetData(value, 'subtitle_textColor', setWidgetData)}
          />
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.aligment</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!subtitle_text}
            options={alignmentOptions}
            value={subtitleAlignment}
            onChange={(option) =>
              changeWidgetData(option.value, 'subtitle_alignment', setWidgetData)
            }
            unSelect={true}
          />
        </div>
      </div>
      {/* legend */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.legend</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            checked={legend.visible}
            onClick={() =>
              changeWidgetData({ ...legend, visible: !legend.visible }, 'legend', setWidgetData)
            }
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.position</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!legend.visible}
            options={positionOptions}
            value={lengedPosition}
            onChange={(option) =>
              changeWidgetData({ ...legend, position: option.value }, 'legend', setWidgetData)
            }
            unSelect={true}
          />
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>create-widget-page.create-widget.step-four.aligment</I18n>
          <Select
            styles={selectCustomStyle}
            disabled={!legend.visible}
            options={alignmentOptions}
            value={legendAlignment}
            onChange={(option) =>
              changeWidgetData({ ...legend, alignment: option.value }, 'legend', setWidgetData)
            }
            unSelect={true}
          />
        </div>
        <div className={classes.legend}>
          <I18n className={classes.text} style={{ paddingTop: '11px' }}>
            create-widget-page.create-widget.step-four.legend-text
          </I18n>
          <div style={{ width: 'calc(100% - 115px)' }}>
            <DraggableTable
              droppableId={'legendSettings'}
              columnCfg={legendCfg}
              data={legendStructureColumns}
              onRowChanged={onLegendRowChanged}
              setData={setLegendStructureColumns}
            />
          </div>
        </div>
      </div>
      {/* tooltip */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.tooltip</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            checked={tooltip_visible}
            onClick={() => changeWidgetData(!tooltip_visible, 'tooltip_visible', setWidgetData)}
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            disabled={!tooltip_visible}
            checked={tooltip_shared}
            onClick={() => changeWidgetData(!tooltip_shared, 'tooltip_shared', setWidgetData)}
          />
          <I18n className={classes.cbLabel}>create-widget-page.create-widget.step-four.shared</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            disabled={!tooltip_visible}
            checked={tooltip_showAxisLegend}
            onClick={() =>
              changeWidgetData(!tooltip_showAxisLegend, 'tooltip_showAxisLegend', setWidgetData)
            }
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.show-axis-legend
          </I18n>
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>
            create-widget-page.create-widget.step-four.decimal-digits
          </I18n>
          <TextField
            disabled={!tooltip_visible}
            className={classes.numberField}
            value={tooltip_decimalDigits}
            margin="dense"
            variant="outlined"
            onChange={(e) =>
              changeWidgetData(+e.target.value, 'tooltip_decimalDigits', setWidgetData)
            }
            type="number"
            inputProps={{
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
      {/* data-labels */}
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <I18n>create-widget-page.create-widget.step-four.data-labels</I18n>
        </div>
        <div className={classes.item}>
          <MaterialCheckbox
            color="primary"
            checked={dataLabels_visible}
            onClick={() =>
              changeWidgetData(!dataLabels_visible, 'dataLabels_visible', setWidgetData)
            }
          />
          <I18n className={classes.cbLabel}>
            create-widget-page.create-widget.step-four.visible
          </I18n>
        </div>
        <div className={classNames(classes.item, classes.colorItem)}>
          <I18n className={classes.text}>
            create-widget-page.create-widget.step-four.text-color
          </I18n>
          <ColorInput
            disabled={!dataLabels_visible}
            value={dataLabels_textColor}
            popOverPosition={{ left: 1 }}
            isParentRelative={false}
            customeMarginButton={0}
            colorChange={(value) => changeWidgetData(value, 'dataLabels_textColor', setWidgetData)}
          />
        </div>
        <div className={classes.item}>
          <I18n className={classes.text}>
            create-widget-page.create-widget.step-four.data-label-decimal-digits
          </I18n>
          <TextField
            title={dataLabels_decimalDigits && dataLabels_decimalDigits.toString()}
            disabled={!dataLabels_visible}
            className={classes.numberField}
            margin="dense"
            variant="outlined"
            value={dataLabels_decimalDigits}
            onChange={(e) =>
              changeWidgetData(+e.target.value, 'dataLabels_decimalDigits', setWidgetData)
            }
            type="number"
            inputProps={{
              style: { color: 'var(--systemFont)' },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Line;
