import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import * as Yup from 'yup';
import { buildErrorObj, emailRegex } from '@core/utils';
import { omit } from 'lodash';

const emailValidation = (validationCfg) => {
  const {
    values: { email, name },
    prevCompanyAndEmail,
    setPrevCompanyAndEmail,
  } = validationCfg;
  const { email: prevEmail, company: prevCompany } = prevCompanyAndEmail;

  if (!email?.length || !name?.length || (email === prevEmail && name === prevCompany)) return;

  httpService
    .api({
      type: 'signUpEmailValidation',
      data: { email, companyName: name },
      disableBI: true,
    })
    .then((res: any) => {
      if (res.isValid) {
        if (res.id) {
          onEmailExistsForAnotherCompanyName(res, validationCfg);
        } else {
          onEmailAndCompanyNameNotExist(validationCfg);
        }
      } else {
        onEmailExistsWithCompanyName(res, validationCfg);
      }
    });

  setPrevCompanyAndEmail({ email: email, company: name });
};

const onEmailExistsForAnotherCompanyName = (res, validationCfg) => {
  const {
    values: { email, name },
    setFieldValue,
    setEmailErrors,
    setUserId,
    setIsValidEmail,
    isEmailMessage,
  } = validationCfg;

  modalService
    .openConfirm({
      text: `errors.${res.message.code}`,
      ...buildErrorObj(res.message.keys),
    })
    .then((confirm) => {
      if (confirm) {
        setFieldValue('id', res['id']);
        setUserId(res['id']);
        setEmailErrors({ email, name, isEmailMessage, message: '', isValid: true });
        setIsValidEmail(true);
      } else {
        setFieldValue('email', '');
        setEmailErrors({ name: '', email: '', message: '', isEmailMessage: null, isValid: false });
        setIsValidEmail(true);
      }
    });
};

const onEmailAndCompanyNameNotExist = (validationCfg) => {
  const {
    values: { email, name },
    setFieldValue,
    setEmailErrors,
    setUserId,
    setIsValidEmail,
    isEmailMessage,
  } = validationCfg;

  setUserId(null);
  setEmailErrors({ name, email, message: '', isEmailMessage, isValid: true });
  setIsValidEmail(true);
  setFieldValue('id', null);
};

const onEmailExistsWithCompanyName = (res, validationCfg) => {
  const {
    values: { email, name },
    setFieldValue,
    setEmailErrors,
    setUserId,
    setIsValidEmail,
    isEmailMessage,
  } = validationCfg;

  setEmailErrors({
    name,
    email,
    isEmailMessage,
    message:
      res.message &&
      i18nService.translate(
        `errors.${res.message.code}`,
        undefined,
        buildErrorObj(res.message.keys)
      ),
    isValid: false,
  });
  setFieldValue('id', null);
  setUserId(null);
  setIsValidEmail(true);
};

export const getCompanyFieldsCfg = (
  setUserId,
  emailErrors,
  setEmailErrors,
  prevCompanyAndEmail,
  setPrevCompanyAndEmail,
  setIsValidEmail
) => {
  return [
    {
      label: 'details.company-name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .min(2, 'details.company-name-message')
        .max(100, 'details.company-name-message'),
      validate: (values) => {
        if (
          emailErrors &&
          !emailErrors.isEmailMessage &&
          values.name === emailErrors.name &&
          values.email === emailErrors.email
        ) {
          return emailErrors.message;
        }
        if (values.name === '') {
          if (prevCompanyAndEmail.company !== values.name) {
            setPrevCompanyAndEmail((prevState) => ({ ...prevState, company: values.name }));
          }
          setUserId(null);
          setEmailErrors(null);
          return 'validations.mandatory-field';
        }
      },
      onLostFocus: (values, setFieldValue) => {
        emailValidation({
          values,
          setFieldValue,
          setEmailErrors,
          setUserId,
          prevCompanyAndEmail,
          setPrevCompanyAndEmail,
          setIsValidEmail,
          isEmailMessage: false,
        });
      },
    },
    {
      label: 'details.company-website',
      name: 'website',
      type: 'input',
      validation: Yup.string().nullable().max(100, 'details.company-website-message'),
    },
    {
      label: 'details.company-address',
      name: 'address',
      type: 'googlePlacesInput',
      placeholder: 'sign-up-page.address-placeholder',
      isRequired: true,
      validation: Yup.string().required('validations.mandatory-field'),
    },
  ];
};

export const getFieldsCfgMainContact = (
  userId,
  setUserId,
  emailErrors,
  setEmailErrors,
  prevCompanyAndEmail,
  setPrevCompanyAndEmail,
  setIsValidEmail
) => {
  return [
    {
      label: 'details.title',
      name: 'title',
      type: 'select',
      options: ['NONE', 'MR', 'MRS', 'MISS', 'MS', 'DR', 'PROF'].map((opt) => ({
        value: opt,
        label: i18nService.translate(`enum.${opt}`),
      })),
      condition: () => !userId,
    },
    {
      label: 'details.first-name',
      name: 'firstName',
      type: 'input',
      isRequired: !userId,
      validation:
        !userId &&
        Yup.string()
          .nullable()
          .required('validations.mandatory-field')
          .max(20, 'details.first-name-message')
          .min(1, 'details.first-name-message'),
      condition: () => !userId,
    },
    {
      label: 'details.middle-name',
      name: 'middleName',
      type: 'input',
      validation:
        !userId &&
        Yup.string()
          .nullable()
          .max(20, 'details.middle-name-message')
          .min(1, 'details.middle-name-message'),
      condition: () => !userId,
    },
    {
      label: 'details.last-name',
      name: 'lastName',
      type: 'input',
      isRequired: !userId,
      validation:
        !userId &&
        Yup.string()
          .nullable()
          .required('validations.mandatory-field')
          .max(100, 'details.last-name-message')
          .min(1, 'details.last-name-message'),
      condition: () => !userId,
    },
    {
      label: 'details.email',
      name: 'email',
      type: 'input',
      isRequired: true,
      validate: (values) => {
        if (
          emailErrors &&
          emailErrors.isEmailMessage &&
          values.email === emailErrors.email &&
          values.name === emailErrors.name
        )
          return emailErrors.message;
        if (values.email === '') {
          if (prevCompanyAndEmail.email !== values.email) {
            setPrevCompanyAndEmail((prevState) => ({ ...prevState, email: values.email }));
          }
          setUserId(null);
          setEmailErrors(null);
          return 'validations.mandatory-field';
        }
      },
      validation: Yup.string()
        .trim()
        .required('validations.mandatory-field')
        .matches(emailRegex, 'my-details.email-message'),
      onLostFocus: (values, setFieldValue) => {
        values.email = values.email?.trim();
        emailValidation({
          values,
          setFieldValue,
          setEmailErrors,
          setUserId,
          prevCompanyAndEmail,
          setPrevCompanyAndEmail,
          setIsValidEmail,
          isEmailMessage: true,
        });
      },
    },
    {
      label: 'details.phone',
      name: 'phone',
      type: 'phoneInput',
      condition: () => !userId,
    },
    {
      label: 'details.mobile',
      name: 'mobile',
      type: 'phoneInput',
      condition: () => !userId,
    },
  ];
};

export function registerOrganization(
  values: any,
  userProperty: string[],
  userId: string | number,
  history,
  captchaToken: string
) {
  httpService
    .api({
      type: 'registrationOrg',
      data: omit(
        {
          ...values,
          user: {
            ...values.user,
            middleName: values.user.middleName === '' ? null : values.user.middleName,
          },
          captchaToken,
        },
        userProperty
      ),
    })
    .then((res: any) => {
      if ('ga' in window && !userId) {
        const { ga } = window as any;
        const tracker = ga.getAll()[0];
        if (tracker) {
          tracker.send('event', 'registration-completed');
        }
      }

      modalService
        .openModal('alert', { text: 'sign-up-page.save-message', iconType: 'v_image' })
        .then((res) => {
          history.push('/login');
        });
    })
    .catch((err) => {
      if (err.data?.message) {
        modalService.openModal('alert', {
          text: `errors.${err.data.code}`,
          ...buildErrorObj(err.data.keys),
          iconType: 'attention_image',
        });
      } else if (err.data?.default) {
        const errorMessage = Array.isArray(err.data?.default)
          ? err.data?.default[0]
          : err.data?.default;
        modalService.openModal('alert', {
          text: `errors.${errorMessage?.toLowerCase().replace(' ', '-')}`,
          iconType: 'attention_image',
        });
      }
    });
}

export function save(
  values: any,
  userIdRef,
  userId,
  captchaToken,
  setSaving,
  setIsValidEmail,
  history
) {
  const userProperty = [
    'id',
    'title',
    'firstName',
    'middleName',
    'lastName',
    'email',
    'phone',
    'mobile',
  ];
  if (userIdRef.current) {
    userProperty.forEach((key) => (values[key] = ''));
    values['id'] = userIdRef.current;
  }
  values['user'] = userProperty.reduce(
    (o, key) => ({
      ...o,
      [key]:
        typeof values[key] === 'object' && values[key] != null ? values[key].value : values[key],
    }),
    {}
  );

  registerOrganization(values, userProperty, userId, history, captchaToken);
  setSaving(false);
  setIsValidEmail(false);
}
