import React from 'react';
import I18n from '@components/I18n';
import MaterialCheckbox from '@components/Checkbox';
import Ranges from '@components/Ranges';
import ReverseNegativeShade from '../ReverseNegativeShade';
import { changeWidgetData } from '../Heatmap.utils';
import Legend from './Legend';
import { columnCfg } from './RangesCustomization.utils';
import { cssVarsService } from '@core/CssVarsService';
import { HEATMAP_RANGE_TITLE_LENGTH } from '@core/constants';

function RangesCustomization(props) {
  const { chartType, widgetData, setWidgetData, rangesChart, classes } = props;
  const { customization } = widgetData;

  function insertToCustomization(prevstateCustomization, ranges, errors = {}) {
    return {
      ...prevstateCustomization,
      rangesChart: { ...rangesChart, ranges: ranges },
      errors: errors,
    };
  }

  return (
    <>
      {/* RANGES */}
      <Ranges
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        columnCfg={columnCfg}
        ranges={rangesChart.ranges}
        errors={customization.errors}
        insertToCustomization={insertToCustomization}
        promptOnRangeDelete={false}
        optionalFields={{
          remark: null,
          backgroundColor: '#008FFB',
          textColor: cssVarsService.vars.widgetsFont,
        }}
        maxRemarkLength={HEATMAP_RANGE_TITLE_LENGTH}
        addRangeButtonTitle={'create-widget-page.create-widget.step-four.add-range'}
      />
      {/* LEGEND */}
      <Legend classes={classes} rangesChart={rangesChart} setWidgetData={setWidgetData} />
      {/* SHADED */}
      <div className={classes.section}>
        <MaterialCheckbox
          color="primary"
          checked={rangesChart.shaded}
          onClick={() => {
            changeWidgetData(
              { ...rangesChart, shaded: !rangesChart.shaded },
              'rangesChart',
              setWidgetData
            );
          }}
        />
        <I18n className={classes.checkboxText}>
          create-widget-page.create-widget.step-four.heatmap.shaded
        </I18n>
      </div>
      {/* REVERSE NEGATIVE SHADES */}
      <ReverseNegativeShade
        classes={classes}
        chartType={chartType}
        reverseNegativeShade={customization.reverseNegativeShade}
        shaded={rangesChart.shaded}
        setWidgetData={setWidgetData}
      />
    </>
  );
}

export default RangesCustomization;
