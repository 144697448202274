import React, { useState } from 'react';
import { InputBase } from '@material-ui/core';
import { isEqual } from 'lodash';
import styles from './HeatmapCustomizationRow.scss';
import { HeatmapCustomizationColumn } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { HeatmapCustomizationRowProps } from './HeatmapCustomizationRow.interface';
import classNames from 'classnames';
import { useEffect } from 'react';
import {
  handleNameBlur,
  handleNameChange,
  validateNameError,
} from './HeatmapCustomizationRow.utils';
import { getMetricName } from '@pages/CreateWidgetPage/widgets.utils';

function HeatmapCustomizationRow(props: HeatmapCustomizationRowProps) {
  const { widgetData, column, columnCfg } = props;
  const [editedColumn, setEditedColumn] = useState<any>(column);
  const [displayNameHasError, setDisplayNameHasError] = useState(false);

  const metricName =
    column.displayName && column.displayName != ''
      ? column.displayName
      : getMetricName(widgetData, column);

  useEffect(() => {
    validateNameError(editedColumn.displayName, setDisplayNameHasError);
  }, []);

  useEffect(() => {
    if (!isEqual(column, editedColumn))
      setEditedColumn({
        ...column,
        displayName: column?.displayName ? column?.displayName : getDisplayName(),
      });
  }, [column]);

  function getDisplayName() {
    return column.displayName
      ? column.displayName
      : metricName && column?.operation
      ? `${metricName} ${column.operation.toLowerCase()}`
      : metricName && column?.valueType === 'CALCULATION'
      ? metricName
      : null;
  }

  return (
    <div className={styles.wrapper}>
      <div
        title={column.name}
        style={columnCfg[0].style}
        className={classNames('ellipsis-overflow')}>
        {column.name}
      </div>
      <div id={'94b07581-4aa3-4196-aad8-bfb4f3624e6a'} style={columnCfg[1].style}>
        <InputBase
          title={editedColumn.displayName}
          id={styles.inputStyle}
          className={classNames(
            styles.containerInput,
            displayNameHasError && styles.containerInputError
          )}
          value={editedColumn.displayName}
          inputProps={{ maxLength: 25 }}
          onChange={(e) =>
            handleNameChange(e.target.value, editedColumn, setEditedColumn, setDisplayNameHasError)
          }
          onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
        />
      </div>
    </div>
  );
}

export default HeatmapCustomizationRow;
