import React from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import Button from '@components/Button';
import { useSelector } from '@redux/useSelector';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { setWhiteLabelDetails } from '@src/redux/config';
import { dispatch } from '@src/redux/store';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 19,
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'baseline',
  },
  title: {
    marginRight: 16,
    fontSize: 20,
    fontWeight: 900,
  },
  type: {
    fontWeight: 600,
    fontSize: 12,
    marginLeft: 4,
  },
}));

const buttonsMap = [
  {
    label: 'white-labeling.publish',
    title: 'white-labeling.publish-message',
    header: 'white-labeling.publish-header',
    action: 'PUBLISH',
  },
  {
    label: 'white-labeling.preview-customization',
    title: 'white-labeling.preview-message',
    header: 'white-labeling.preview-header',
    action: 'PREVIEW',
  },
  {
    label: 'white-labeling.undo',
    title: 'white-labeling.undo-message',
    header: 'white-labeling.undo-header',
    action: 'UNDO',
  },
  {
    label: 'white-labeling.reset',
    title: 'white-labeling.reset-message',
    header: 'white-labeling.reset-header',
    action: 'RESET',
  },
];

function Header(props) {
  const { setDisplaySettingsData, displaySettingsData, setRefreshColorPickers } = props;
  const organizationDetails = useSelector((store) => store.config.organizationDetails);
  const licensingType = useSelector((state) => state.config.licensingType);
  const classes = useStyles(props);

  // To clear the Color pickers cache, if not, it will save the
  // last color selected even after we change the colors via 'Reset'
  const refreshColorPickers = () => {
    setRefreshColorPickers(true);
    setTimeout(() => {
      setRefreshColorPickers(false);
    });
  };

  const updateDataPerAction = (action, res) => {
    switch (action) {
      case 'PUBLISH':
      case 'PREVIEW':
        dispatch(setWhiteLabelDetails(displaySettingsData));
        return;
      case 'UNDO':
      case 'RESET':
        setDisplaySettingsData({ ...displaySettingsData, ...res.whiteLabelDetails });
        dispatch(setWhiteLabelDetails({ ...displaySettingsData, ...res.whiteLabelDetails }));
        refreshColorPickers();
        return;
    }
  };

  const onClickButton = async (buttonData) => {
    const { title, action, header } = buttonData;
    const confirm = await modalService.openModal('confirm', {
      text: title,
      headerText: header,
      showCloseBtn: true,
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
    });
    if (confirm) {
      try {
        const res = await httpService.api({
          type: 'setDisplaySettingAction',
          data: { action },
        });
        updateDataPerAction(action, res);
      } catch (e) {}
    }
  };
  return (
    <div className={classes.header}>
      <div className={classes.titleWrapper}>
        <span className={classes.title}>{organizationDetails.name}</span>
        <I18n>white-labeling.cutomizing-level</I18n>
        <I18n
          element="span"
          className={
            classes.type
          }>{`white-labeling.licensing-level-${licensingType.toLowerCase()}`}</I18n>
      </div>
      {buttonsMap.map((button) => (
        <Button
          styles={{ marginLeft: 18 }}
          onClick={() => onClickButton(button)}
          mode="big"
          key={button.label}>
          <I18n>{button.label}</I18n>
        </Button>
      ))}
    </div>
  );
}

export default Header;
