import { stepOneResetValuesMap } from './resetValuesMap';
import { getIsIncludeInSettings } from '@core/canvas/widget.utils';
import * as differenceBy from 'lodash/differenceBy';
import { httpService } from '@core/http/HttpService';
import { AssetTagsResponse } from './StepOne.interface';
import { buildMetricsArray, calculationsToServer } from '@pages/CreateWidgetPage/widgets.utils';
import { getDefaultScope } from '../StepTwo/StepTwo.utils';

export const checkByTypeUsedInStep3 = (widgetData, type) => {
  return (
    widgetData.groupBy.some((e) => e.valueType === type && !e.hide) ||
    widgetData.metrics.some((e) => e.valueType === type && !e.hide) ||
    widgetData.filters.some((e) => e.valueType === type) ||
    widgetData.sorts.some((e) => e.valueType === type)
  );
};

export const checkSameTagTypes = (widgetData, newValue) => {
  const assetIds = newValue.map((asset) => asset.id);
  return httpService
    .api({
      type: 'getAssetTagTypes',
      query: { p: 1, ps: 1000, assetTypeIds: assetIds.join(','), widgetType: widgetData.type },
      disableBI: true,
    })
    .then((tagTypes: AssetTagsResponse) => {
      return differenceBy(widgetData.tagTypes, tagTypes.results, 'id');
    });
};

export const removeAssetTypeNotChangeFunctionality = (widgetData, newValue) => {
  const { assetProperties, tags, tagTypes, variables, assetTypes } = widgetData;
  return (
    (!!assetProperties.length || !!tags.length || !!tagTypes.length || !!variables.length) &&
    assetTypes.length < 3 &&
    newValue.length < assetTypes.length
  );
};

export const getDeleted = (sourceList, newValue) => differenceBy(sourceList, newValue, 'id');

export const removeAssetTypeChangeFunctionality = (widgetData, newValue) => {
  const deletedAssetType = getDeleted(widgetData.assetTypes, newValue);
  return (
    widgetData.assetTypes.length > 2 &&
    newValue.length < widgetData.assetTypes.length &&
    widgetData.navigationDashboard.length &&
    widgetData.navigationDashboard.some((e) => e.assetTypeId === deletedAssetType[0].id)
  );
};

export const removeAllAssetTypesChangeFunctionality = (widgetData) => {
  return (
    widgetData.allAssetTypes &&
    (widgetData.customization ||
      widgetData.sorts?.length ||
      widgetData.filters?.length ||
      widgetData.calculations?.some((calc) => calc.expression && calc.expression !== '') ||
      widgetData.navigationDashboard.length)
  );
};

export const addAssetTypeOnOneExists = (widgetData, newValue) =>
  widgetData.assetTypes.length == 1 && newValue.length > widgetData.assetTypes.length;

export const addAssetTypeMoreTwo = (widgetData, newValue) =>
  widgetData.assetTypes.length > 1 &&
  newValue.length > widgetData.assetTypes.length &&
  widgetData.tagTypes.length &&
  checkSameTagTypes(widgetData, newValue);

export const cleanAndUpdateData = (newValue, setWidgetData) => {
  updateWidgetData(newValue, setWidgetData);
  stepOneResetValuesMap.stepOneAssetType(setWidgetData);
};

export const cleanNavigationDashboard = (newValue, widgetData, setWidgetData) => {
  const deletedAssetType = getDeleted(widgetData.assetTypes, newValue);
  setWidgetData((prevData) => ({
    ...prevData,
    assetTypes: newValue,
    navigationDashboard: prevData.navigationDashboard.filter(
      (e) => e.assetTypeId !== deletedAssetType[0].id
    ),
  }));
};

export const getUpdateFilterList = (widgetData, removedItem, type) =>
  widgetData.filters.filter((x) => !(x.valueId == removedItem.id && x.valueType == type));

export const getUpdateSortList = (widgetData, removedItem, type) =>
  widgetData.sorts.filter((x) => !(x.valueId == removedItem.id && x.valueType == type));

export const updateWidgetDataAndRemoveItem = (
  newValue,
  setWidgetData,
  dataTags,
  filtersData,
  sortsData
) => {
  setWidgetData((prevData) => ({
    ...prevData,
    [dataTags]: newValue,
    filters: filtersData,
    sorts: sortsData,
    customization: null,
    localTags: [],
  }));
};

export const cleanAndUpdate = (newValue, setWidgetData, dataTags, widgetType = null) => {
  setWidgetData((prevData) => ({
    ...prevData,
    [dataTags]: newValue,
    calculations: [],
    navigateFilterBy: widgetType === 'heatmap' ? [] : prevData.navigateFilterBy,
    navigationDashboard: widgetType === 'heatmap' ? [] : prevData.navigationDashboard,
  }));
  stepOneResetValuesMap.stepOneAssetProps(setWidgetData);
};

export const updateWidgetData = (newValue, setWidgetData) => {
  setWidgetData((prevData) => ({
    ...prevData,
    assetTypes: newValue,
    scope: getDefaultScope(prevData.type),
    navigationDashboard: [],
    navigateFilterBy: [],
  }));
};

export const updateWidgetDataOnSelectAllChanged = (
  widgetData: any,
  setWidgetData: any,
  allAssetTypes: boolean,
  assetTypes = []
) => {
  setWidgetData({
    ...widgetData,
    allAssetTypes: allAssetTypes,
    scope: null,
    assetTypes: assetTypes,
    assetProperties: ['map', 'asset'].includes(widgetData.type)
      ? widgetData.assetProperties.filter((option) => {
          return getIsIncludeInSettings(widgetData.type, 'defaultAssetProperties', option.id);
        })
      : [],
    alarmInfos: [],
    variables: [],
    localTags: [],
    tags: [],
    tagTypes: [],
    metrics: [],
    calculations: [],
    customizationMetrics: [],
    sorts: [],
    groupBy: [],
    filters: [],
    customization: null,
    exportFormats: [],
    navigateFilterBy: [],
    navigationDashboard: [],
    terms: {},
  });
};

export const checkAssetPropertieOrTagOrTagTypeUsedInGroupingOrMetrics = (
  removedItem,
  type,
  widgetData
) => {
  // Also checks in Calculations. Function name is too long already
  return (
    widgetData.groupBy.some((x) => x.valueType == type && x.valueId == removedItem.id && !x.hide) ||
    widgetData.metrics.some((x) => x.valueType == type && x.valueId == removedItem.id && !x.hide) ||
    calculationsToServer(widgetData.calculations, widgetData).some((c) =>
      c.metrics.some((m) => m.valueType == type && m.valueId == removedItem.id)
    )
  );
};

export const checkAssetPropertieOrTagOrTagTypeUsedInFilterNotInSort = (
  removedItem,
  type,
  widgetData
) => {
  return (
    widgetData.filters.some((x) => x.valueType == type && x.valueId == removedItem.id) &&
    !widgetData.sorts.some((x) => x.valueType == type && x.valueId == removedItem.id)
  );
};

export const checkAssetPropertieOrTagOrTagTypeUsedInSortAndFilter = (
  removedItem,
  type,
  widgetData
) => {
  return (
    widgetData.filters.some((x) => x.valueType == type && x.valueId == removedItem.id) &&
    widgetData.sorts.some((x) => x.valueType == type && x.valueId == removedItem.id)
  );
};

export const checkAssetPropertieOrTagOrTagTypeUsedInSortNotInFilter = (
  removedItem,
  type,
  widgetData
) => {
  return (
    !widgetData.filters.some((x) => x.valueType == type && x.valueId == removedItem.id) &&
    widgetData.sorts.some((x) => x.valueType == type && x.valueId == removedItem.id)
  );
};

export const checkIsMapReset = (widgetData) =>
  widgetData.type === 'map' &&
  widgetData.customization &&
  (widgetData.assetProperties.length > 3 || widgetData.tags.length || widgetData.tagTypes.length);

export const checkAssetPropertieOrTagOrTagTypeOrVariable = (
  widgetData,
  setWidgetData,
  newValue,
  type,
  widgetDataProp,
  openConfirmModal
) => {
  if (newValue.length < widgetData[widgetDataProp].length) {
    var removedItem = getDeleted(widgetData[widgetDataProp], newValue)[0];
    if (
      checkIsMapReset(widgetData) ||
      checkAssetPropertieOrTagOrTagTypeUsedInGroupingOrMetrics(removedItem, type, widgetData)
    ) {
      openConfirmModal('create-widget-page.create-widget.step-one.initialize-settings').then(
        (confirm) => {
          confirm && cleanAndUpdate(newValue, setWidgetData, widgetDataProp, widgetData.type);
        }
      );
    } else if (
      type !== 'VARIABLE' &&
      checkAssetPropertieOrTagOrTagTypeUsedInFilterNotInSort(removedItem, type, widgetData)
    ) {
      openConfirmModal('create-widget-page.create-widget.step-one.initialize-one-filters').then(
        (confirm) => {
          confirm &&
            updateWidgetDataAndRemoveItem(
              newValue,
              setWidgetData,
              widgetDataProp,
              getUpdateFilterList(widgetData, removedItem, type),
              widgetData.sorts
            );
        }
      );
    } else if (
      type !== 'VARIABLE' &&
      checkAssetPropertieOrTagOrTagTypeUsedInSortNotInFilter(removedItem, type, widgetData)
    ) {
      openConfirmModal('create-widget-page.create-widget.step-one.initialize-one-sorts').then(
        (confirm) => {
          confirm &&
            updateWidgetDataAndRemoveItem(
              newValue,
              setWidgetData,
              widgetDataProp,
              widgetData.filters,
              getUpdateSortList(widgetData, removedItem, type)
            );
        }
      );
    } else if (
      type !== 'VARIABLE' &&
      checkAssetPropertieOrTagOrTagTypeUsedInSortAndFilter(removedItem, type, widgetData)
    ) {
      openConfirmModal(
        'create-widget-page.create-widget.step-one.initialize-one-sorts-filters'
      ).then((confirm) => {
        confirm &&
          updateWidgetDataAndRemoveItem(
            newValue,
            setWidgetData,
            widgetDataProp,
            getUpdateFilterList(widgetData, removedItem, type),
            getUpdateSortList(widgetData, removedItem, type)
          );
      });
    } else {
      onSetWidgetData(widgetData, setWidgetData, widgetDataProp, newValue);
    }
  } else if (widgetData.type === 'map' && widgetData.customization && !widgetData.allAssetTypes) {
    openConfirmModal('create-widget-page.create-widget.step-one.initialize-settings').then(
      (confirm) => {
        confirm && cleanAndUpdate(newValue, setWidgetData, widgetDataProp);
      }
    );
  } else {
    onSetWidgetData(widgetData, setWidgetData, widgetDataProp, newValue);
  }
};

export function onSetWidgetData(widgetData, setWidgetData, widgetDataProp, newValue) {
  if (widgetData.scope === 'RAW_DATA') {
    setWidgetData((prevData) => ({
      ...prevData,
      customization: null,
      [widgetDataProp]: newValue,
    }));
  } else if (
    widgetData.scope === 'LAST_VALUE' &&
    widgetData.type === 'columns' &&
    widgetDataProp === 'assetProperties'
  ) {
    setWidgetData((prevData) => {
      const xAxisInfo = newValue.find((a) => a.id === 1) ? 1 : 2;
      const tempMetrics = prevData.metrics.filter((m) => !m.hide);
      return {
        ...prevData,
        customization: prevData.customization ? { ...prevData.customization, xAxisInfo } : null,
        metrics:
          xAxisInfo === 1
            ? [
                ...tempMetrics,
                ...buildMetricsArray([1], 'ASSET_PROPERTY', true, tempMetrics.length),
              ]
            : [...tempMetrics],
        [widgetDataProp]: newValue,
      };
    });
  } else {
    setWidgetData((prevData) => ({
      ...prevData,
      [widgetDataProp]: newValue,
    }));
  }
}
