import React from 'react';
import styles from './ImageSection.scss';
import Image from '@components/Image';

function ImageSection(props) {
  const { imageUrl } = props;

  return (
    <div className={styles.wrapper}>
      <Image src={imageUrl ? imageUrl : '/assets/images/empty_Image.svg'} />
    </div>
  );
}

export default ImageSection;
