import ColorInput from '@components/ColorInput';
import { makeStyles } from '@material-ui/core';
import { onChange } from '@modals/AssignTagTypeModal/assignTagTypeModal.utils';
import { useSelector } from '@src/redux/useSelector';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  fields: {
    paddingLeft: 30,
    marginTop: 20,
  },
}));

function EventStatusColors(props) {
  const classes = useStyles(props);
  const { displaySettingsData, setDisplaySettingsData, onChangeData, refreshColorPickers } = props;
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const outerSectionName = 'statusColors';

  const fieldsCfgEventStatus = [
    {
      label: 'white-labeling.event-status-colors.published',
      name: 'published',
      type: 'color',
      keyName: 'eventStatusColors',
    },
    {
      label: 'white-labeling.event-status-colors.suspended',
      name: 'suspended',
      type: 'color',
      keyName: 'eventStatusColors',
    },
    {
      label: 'white-labeling.event-status-colors.draft',
      name: 'draft',
      type: 'color',
      keyName: 'eventStatusColors',
    },
    {
      label: 'white-labeling.event-status-colors.expired',
      name: 'expired',
      type: 'color',
      keyName: 'eventStatusColors',
    },
    {
      label: 'white-labeling.event-status-colors.archived',
      name: 'archived',
      type: 'color',
      keyName: 'eventStatusColors',
    },
    {
      label: 'white-labeling.event-status-colors.active',
      name: 'active',
      type: 'color',
      keyName: 'eventStatusColors',
    },
    {
      label: 'white-labeling.event-status-colors.recipients-missing',
      name: 'recipientsMissing',
      type: 'color',
      keyName: 'eventStatusColors',
    },
  ];

  const onColorChanged = (keyName, name, value) => {
    const eventStatusColors = {
      ...displaySettingsData[keyName],
      [name]: value,
    };

    onChangeData(keyName, eventStatusColors);
  };

  return (
    <div className={classes.fields}>
      {!refreshColorPickers &&
        fieldsCfgEventStatus.map((field, idx) => (
          <ColorInput
            key={`${idx}_${field.keyName}`}
            {...field}
            disableScrollIntoView={true}
            disabled={!isPremium}
            value={displaySettingsData[field.keyName][field.name]}
            colorChange={(value) => onColorChanged(field.keyName, field.name, value)}
            isDisplaySettings
          />
        ))}
    </div>
  );
}

export default EventStatusColors;
