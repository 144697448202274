import React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { TextWidgetProps } from './TextWidget.interface';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: `flex`,
    overflow: 'hidden',
    fontSize: ({ customization }: any) =>
      customization.size && customization.size >= 10 ? customization.size : 20,
    textDecoration: ({ customization }: any) => (customization.underline ? 'underline' : 'unset'),
    textDecorationSkipInk: ({ customization }: any) => (customization.underline ? 'none' : 'unset'),
    fontStyle: ({ customization }: any) => (customization.italic ? 'italic' : 'unset'),
    fontWeight: ({ customization }: any) => (customization.bold ? 600 : 400),
    color: ({ customization }: any) => customization.color || 'var(--widgetsFont)',
    flexDirection: 'column',
    padding: '5px 23px 15px 23px',
    '-ms-user-select': 'none',
    'user-select': 'none',
    alignItems: 'left',
    justifyContent: 'center',
    '& :hover': {
      cursor: ({ navigateDashboard, widget }: any) =>
        navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
    },
  },
  text: {
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function TextWidget(props: TextWidgetProps) {
  const classes = useStyles(props);
  const { widget, navigateDashboard, widgetFilters } = props;
  const { text } = props.customization;

  const value =
    props.data &&
    props.data.results &&
    props.data.results[0] &&
    props.data.results[0][props.data.columns[0].name];

  const onNavigate = () => {
    if (navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: value,
        selectedColumn: props.data && props.data.columns && props.data.columns[0],
        widget,
        columns: props.data.columns,
        rawValues:
          (widget.navigationFilters?.length &&
            widgetFilters?.find((f) => f.valueType === 'ASSET_PROPERTY' && f.valueId === 1)
              ?.values) ||
          [],
        navigateDashboard,
      });
    }
  };

  return (
    <div className={classes.wrapper} onClick={onNavigate}>
      <div className={classNames(classes.text, 'ellipsis-overflow')}>{text}</div>
    </div>
  );
}

export default TextWidget;
