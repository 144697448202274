import { httpService } from '@core/http/HttpService';
import { dispatch } from '@src/redux/store';
import { setModal } from '@src/redux/widgetModals';

export const getUrlForWebAndVnc = async (
  widgetFilters,
  widgetType,
  autoScale,
  setRemoteAccessUrl
) => {
  try {
    const data =
      Array.isArray(widgetFilters) &&
      widgetFilters?.find(
        (filter) =>
          filter.valueId === 1 &&
          filter.valueType === 'ASSET_PROPERTY' &&
          (!Array.isArray(filter.values) || filter.values.length === 1)
      );
    if (data) {
      const res: any = await httpService.api({
        type: 'getRemoteAccessWidgetData',
        urlParams: {
          assetId: Array.isArray(data.values) ? data.values[0] : data.values,
          type: widgetType,
        },
      });
      if (res && res.link) {
        const isAutoScale = autoScale && widgetType === 'vnc';
        const urlLink = isAutoScale ? res.link.concat('%26resize%3Dscale') : res.link;
        setRemoteAccessUrl(urlLink);
      }
    }
  } catch (e) {}
};

export const getUrlForVpn = async (assetId) => {
  try {
    const res: any = await httpService.api({
      type: 'getRemoteAccessWidgetData',
      urlParams: {
        assetId: assetId,
        type: 'vpn',
      },
    });
    if (res && res.link) {
      return res.link;
    }
  } catch (e) {
    return '';
  }
};

export function openRemoteHMIorVPNpopup(url, origin: 'hmi' | 'vpn') {
  const getApiType = (origin) => (origin === 'hmi' ? 'getClientRemoteHMI' : 'getClientRemoteVPN');
  const getModalType = (origin) => (origin === 'hmi' ? 'remoteHMI' : 'remoteVPN');

  httpService.api({ type: getApiType(origin) }).then((res: { clientUrl: string }) =>
    dispatch(
      setModal({
        type: getModalType(origin),
        onClose: (confirm) => {
          if (confirm) {
            return window.open(`${url}`, '_blank');
          }
        },
        args: {
          link: res?.clientUrl || '',
        },
      })
    )
  );
}
