import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { setCustomizationTermsForBE } from '@pages/CreateWidgetPage/CreateWidgetPage.utils';
import { localToServer } from '@pages/CreateWidgetPage/widgets.utils';
import { isEqual, omit } from 'lodash';

export const createDataSource = async (widgetData, setWidgetData, publish: boolean = false) => {
  const newWidget = localToServer(widgetData);

  if (publish) {
    newWidget.status = 'PUBLISHED';
    setWidgetData((prevState) => ({ ...prevState, status: 'PUBLISHED' }));
  } else {
    newWidget.status = 'DRAFT';
    setWidgetData((prevState) => ({ ...prevState, status: 'DRAFT' }));
  }

  if (widgetData.id) {
    await httpService.api<number>({
      type: 'updateEventTemplateDataSource',
      urlParams: { templateId: widgetData.eventTemplateId, dataSourceId: widgetData.id },
      data: {
        ...newWidget,
        customization: newWidget.customization
          ? newWidget.customization['columns']
            ? {
                ...omit(newWidget.customization, ['columnsDict', 'symbolDict']),
                columns: newWidget.customization['columns'].map((col) =>
                  omit(col, ['name', 'displayName'])
                ),
              }
            : newWidget.customization
          : null,
        terms: setCustomizationTermsForBE(
          newWidget.type === 'VALUE'
            ? {
                ...newWidget,
                customization: omit(newWidget.customization, 'description'),
              }
            : newWidget,
          false
        ),
      },
    });
  } else {
    const res = await httpService.api<{ id: number }>({
      type: 'createEventTemplateDataSource',
      urlParams: { templateId: widgetData.eventTemplateId },
      data: {
        ...newWidget,
        customization: newWidget.customization
          ? newWidget.customization['columns']
            ? {
                ...omit(newWidget.customization, ['columnsDict', 'symbolDict']),
                columns: newWidget.customization['columns'].map((col) =>
                  omit(col, ['name', 'displayName'])
                ),
              }
            : newWidget.customization
          : null,
        terms: setCustomizationTermsForBE(
          newWidget.type === 'VALUE'
            ? {
                ...newWidget,
                customization: omit(newWidget.customization, 'description'),
              }
            : newWidget,
          false
        ),
      },
    });
    setWidgetData((prevState) => ({ ...prevState, id: res.id }));
  }
};

export const cancel = (
  widgetData,
  onSaveWidget,
  enableSaveAsDraft,
  initialData,
  setIsAddingDataSource,
  setShowFooter
) => {
  setIsAddingDataSource(false);
  setShowFooter(true);
};

export const onNavigateFromPage = (
  location,
  history,
  widgetData,
  setWidgetData,
  initialData,
  enableSaveAsDraft,
  setIsAddingDataSource,
  setShowFooter
) => {
  if (
    (location.state && location.state.overrideGuard) ||
    isEqual(
      { ...initialData, creationStage: undefined },
      { ...widgetData, creationStage: undefined }
    )
  ) {
    setIsAddingDataSource(false);
    setShowFooter(true);
    return true; // Allow navigation.
  }

  const navigate = () => history.push(location.pathname, { overrideGuard: true });

  const exitEdit = () => {
    setIsAddingDataSource(false);
    setShowFooter(true);
  };

  modalService
    .openConfirm({
      headerText: 'general.discard-changes',
      text: 'create-event-data-source-page.leave-page-warning',
      iconType: 'attention_image',
      cancelText: 'general.cancel',
      confirmText: 'general.confirm',
      showCloseBtn: true,
    })
    .then((confirm) => {
      if (confirm) {
        exitEdit();
        navigate();
      }
    });
  return false; // Block navigation.
};
