import React, { useCallback, useState } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';

// Services | Interfaces
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';
import { DisconnectPLCFromRouterModalProps } from './DisconnectPLCFromRouterModal.interface';
import styles from './DisconnectPLCFromRouterModal.scss';

function DisconnectPLCFromRouterModal(props: DisconnectPLCFromRouterModalProps) {
  const { args, dismiss } = props;
  const { asset, id: plcId, routerId } = args;

  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [text, setText] = useState('router-plcs-tab.disconnect-plc');

  const apply = (context) => {
    if (asset && asset.id) {
      setText(
        i18nService.translate(`router-plcs-tab.${context}-plc-with-asset`, undefined, {
          assetName: asset.name,
        })
      );
      setActionButtons([
        { text: 'general.confirm', onClick: () => diconnectPlcFromRouter(context) },
      ]);
    } else {
      diconnectPlcFromRouter(context);
    }
  };

  const [actionButtons, setActionButtons] = useState([
    {
      text: 'router-disconnect-plc.disconnect',
      onClick: () => apply('disconnect'),
      style: { minWidth: '110px' },
    },
    { text: 'router-disconnect-plc.remove', onClick: () => apply('remove') },
  ]);

  const diconnectPlcFromRouter = (context) => {
    httpService
      .api({
        type: 'disconnectPlcFromRouter',
        urlParams: {
          routerId,
          plcId,
        },
        data: {
          disconnectOrg: context == 'disconnect' ? false : true,
        },
      })
      .then(() => {
        dismiss(true);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>diconnect-plc-from-router-modal.header</I18n>
      </div>
      <div className={styles.content}>
        <I18n element="div" className={styles.text} noEllipsis={true}>
          {text}
        </I18n>
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={cancel} mode={['cancel', 'bigFont']}>
          <I18n>general.cancel</I18n>
        </Button>
        {actionButtons.map((btn, idx) => (
          <Button
            key={idx}
            onClick={() => btn.onClick()}
            styles={btn.style ? { ...btn.style, marginLeft: 13 } : { marginLeft: 13 }}
            mode="bigFont">
            <I18n>{btn.text}</I18n>
          </Button>
        ))}
      </div>
    </div>
  );
}

export default DisconnectPLCFromRouterModal;
