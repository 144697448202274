import React, { useMemo } from 'react';

// Components
import Icon from '@components/Icon';
import I18n from '@components/I18n';

// Services | Interfaces
import { modalService } from '@core/modals/ModalService';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import { RemoveRouterRouterBTNProps } from './RemoveRouterBTN.interface';
import { getPermissionStatus } from '@core/ffAndPermissions';
import styles from './RemoveRouterBTN.scss';

// 3rd party
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

function RemoveRouterBTN(router: RemoveRouterRouterBTNProps) {
  const { serialNumber, assetCount, plcCount, id, btnText, onRemoved } = router;
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const onRemove = () => {
    let text, cancelText;
    let confirmText = 'general.confirm';
    let enableRemove = false;

    if (!assetCount && !plcCount) {
      text = 'remove-router-modal.no-devices-connected';
      confirmText = 'general.remove';
      cancelText = 'general.cancel';
      enableRemove = true;
    } else if (!assetCount && plcCount > 0) {
      text = 'remove-router-modal.only-plc-connected';
    } else if (assetCount > 0 && plcCount > 0) {
      text = 'remove-router-modal.plc-and-assets-connected';
    }

    modalService
      .openModal('confirm', {
        headerText: 'remove-router-modal.header',
        text: i18nService.translate(text, undefined, {
          serialNumber,
          plcCount,
          assetCount,
        }),
        confirmText,
        cancelText,
        showCloseBtn: true,
        style: {
          width: '460px',
        },
      })
      .then((confirm) => {
        if (!confirm || !enableRemove) return;
        httpService
          .api({
            type: 'removeRouter',
            urlParams: { routerId: id },
          })
          .then(() => {
            routersService.refreshRoutersTableData();
            onRemoved && onRemoved();
          });
      });
  };

  return (
    <IconButton onClick={onRemove} disabled={!hasPermission} id={styles.button}>
      <Icon
        color="var(--systemFont)"
        className={styles.icon}
        type="disconnectPlc"
        disabled={!hasPermission}
        tooltipText={!btnText ? 'plc-tab.disconnect-plc-tooltip' : undefined}
      />
      {btnText && (
        <I18n className={classNames(styles.textBtn, !hasPermission && styles.disabled)}>
          {btnText}
        </I18n>
      )}
    </IconButton>
  );
}
export default RemoveRouterBTN;
