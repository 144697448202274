import { StoreAction, LoginState, LoginCredentials, User, TwoFaStatus } from './redux.interface.d';
import { storageService } from '@core/StorageService';

export const setLoggedIn = (credentials: LoginCredentials): StoreAction => ({
  type: 'login/SET_LOGGED_IN',
  payload: credentials,
});

export const setSelectedOrganization = (userOrganizationId): StoreAction => ({
  type: 'login/SET_SELECTED_ORGANIZATION',
  payload: userOrganizationId,
});

export const setLoggedOut = (): StoreAction => ({ type: 'login/SET_LOGGED_OUT', payload: null });
export const setUser = (user: User): StoreAction => ({
  type: 'login/SET_USER',
  payload: user,
});

export const setUserStatus = (user_status: string): StoreAction => ({
  type: 'login/SET_USER_STATUS',
  payload: user_status,
});

export const setUserName = ({ firstName, lastName, middleName }): StoreAction => ({
  type: 'login/SET_USER_NAME',
  payload: {
    firstName,
    lastName,
    middleName,
  },
});

export const set2FaStatus = (twoFaStatus: TwoFaStatus): StoreAction => ({
  type: 'login/SET_2FA_STATUS',
  payload: twoFaStatus,
});

export const setErrorHandling = (errorType): StoreAction => ({
  type: 'login/SET_ERROR_HANDLING',
  payload: errorType,
});
export const removeErrorHandling = (): StoreAction => ({
  type: 'login/REMOVE_ERROR_HANDLING',
  payload: null,
});

const initialState: LoginState = {
  isLoggedIn: false,
  userOrganizationId: null,
  token: null,
  refreshToken: null,
  user: null,
  user_status: null,
  twoFaStatus: {
    isCompleted: false,
    mfaPreferredMethod: 'EMAIL',
  },
};

export default function loginReducer(state: LoginState = initialState, action: StoreAction) {
  switch (action.type) {
    case 'login/SET_LOGGED_IN':
      const { token, refreshToken, user, userOrganizationId, user_status, twoFaStatus } =
        action.payload;
      return {
        ...state,
        isLoggedIn: true,
        userOrganizationId,
        token,
        refreshToken,
        user,
        user_status,
        twoFaStatus,
      };
    case 'login/SET_LOGGED_OUT':
      return {
        ...state,
        isLoggedIn: false,
        userOrganizationId: null,
        token: null,
        refreshToken: null,
        user: null,
        user_status: null,
      };
    case 'login/SET_SELECTED_ORGANIZATION':
      storageService.set('AUTH_CREDENTIALS', {
        ...state,
        userOrganizationId: action.payload,
      });
      return {
        ...state,
        userOrganizationId: action.payload,
      };
    case 'login/SET_USER':
      return { ...state, user: action.payload };
    case 'login/SET_USER_STATUS':
      return { ...state, user_status: action.payload };
    case 'login/SET_USER_NAME':
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          middleName: action.payload.middleName,
          lastName: action.payload.lastName,
        },
      };
    case 'login/SET_ERROR_HANDLING':
      return { ...state, errorHandling: action.payload };
    case 'login/REMOVE_ERROR_HANDLING':
      return { ...state, errorHandling: undefined };
    case 'login/SET_2FA_STATUS':
      return {
        ...state,
        twoFaStatus: {
          mfaPreferredMethod: action.payload.mfaPreferredMethod,
          isCompleted: action.payload.isCompleted,
        },
      };
    default:
      return state;
  }
}
