import React, { useState, useEffect, useMemo } from 'react';
import { i18nService } from '@core/i18n/I18nService';
import Icon from '@components/Icon';
import styles from './DatePicker.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import classNames from 'classnames';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useSelector } from '@src/redux/useSelector';
import { getRange, handleEvent, setTimeUpdate, setDateFilterLabel } from './DatePicker.utils';

function DatePicker(props) {
  const {
    selectedChanged,
    singleDatePicker,
    showDropdowns,
    maxDate,
    minDate,
    monthNames,
    disableCustomLabel = false,
    daysOfWeek,
    firstDay,
    data, // {dateFilter: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'CUSTOM', dateFilterFrom: 'YYYY-MM-DD' | null ,dateFilterTo: 'YYYY-MM-DD' | null }
    height = 37,
    displayRanges = true,
    mode = 'default',
    openDirection = 'down',
    timePicker = false,
    format = 'DD/MM/YYYY',
    scope,
  } = props;
  const BEformat = 'YYYY-MM-DD';
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [label, setLabel] = useState();
  const [chosenLabel, setChosenLabel] = useState();
  const languageId = useSelector((state) => state.config.languageId);

  const CONFIG = {
    default: {
      rangesLabels: {
        lastDay: i18nService.translate('date-picker.last-day'),
        lastWeek: i18nService.translate('date-picker.last-week'),
        lastMonth: i18nService.translate('date-picker.last-month'),
        lastYear: i18nService.translate('date-picker.last-year'),
      },
      rangeGranularity: ['day', 'week', 'month', 'year'],
      ranges: [
        [moment().subtract(1, 'days'), moment()],
        [moment().subtract(6, 'days'), moment()],
        [moment().subtract(1, 'months'), moment()],
        [moment().subtract(1, 'year'), moment()],
      ],
    },
    allOption: {
      rangesLabels: {
        allLable: i18nService.translate('general.all'),
        lastDay: i18nService.translate('date-picker.last-day'),
        lastWeek: i18nService.translate('date-picker.last-week'),
        lastMonth: i18nService.translate('date-picker.last-month'),
        lastYear: i18nService.translate('date-picker.last-year'),
      },
      rangeGranularity: ['all', 'day', 'week', 'month', 'year'],
      ranges: [
        [moment('0-1-1', 'YYYY-MM-DD'), moment('9999-12-31', 'YYYY-MM-DD')],
        [moment().subtract(1, 'days'), moment()],
        [moment().subtract(6, 'days'), moment()],
        [moment().subtract(1, 'months'), moment()],
        [moment().subtract(1, 'year'), moment()],
      ],
    },
    line: {
      rangesLabels: {
        lastDay: i18nService.translate('date-picker.last-day'),
        lastWeek: i18nService.translate('date-picker.last-week'),
        lastMonth: i18nService.translate('date-picker.last-month'),
      },
      rangeGranularity: ['day', 'week', 'month'],
      ranges: [
        [moment().subtract(1, 'days'), moment()],
        [moment().subtract(6, 'days'), moment()],
        [moment().subtract(1, 'months'), moment()],
      ],
    },
    alarms: {
      rangesLabels:
        scope === 'RAW_DATA'
          ? {
              lastHour: i18nService.translate('date-picker.last-hour'),
              last2Hours: i18nService.translate('date-picker.last-2-hours'),
              last4Hours: i18nService.translate('date-picker.last-4-hours'),
              last6Hours: i18nService.translate('date-picker.last-6-hours'),
              last8Hours: i18nService.translate('date-picker.last-8-hours'),
              last12Hours: i18nService.translate('date-picker.last-12-hours'),
              lastDay: i18nService.translate('date-picker.last-day'),
              lastWeek: i18nService.translate('date-picker.last-week'),
              lastMonth: i18nService.translate('date-picker.last-month'),
              lastYear: i18nService.translate('date-picker.last-year'),
            }
          : {
              lastDay: i18nService.translate('date-picker.last-day'),
              lastWeek: i18nService.translate('date-picker.last-week'),
              lastMonth: i18nService.translate('date-picker.last-month'),
              lastYear: i18nService.translate('date-picker.last-year'),
            },
      rangeGranularity:
        scope === 'RAW_DATA'
          ? [
              'oneHour',
              'twoHours',
              'fourHours',
              'sixHours',
              'eightHours',
              'twelveHours',
              'day',
              'week',
              'month',
              'year',
            ]
          : ['day', 'week', 'month', 'year'],
      ranges: [
        [moment().subtract(1, 'hour'), moment()],
        [moment().subtract(2, 'hour'), moment()],
        [moment().subtract(4, 'hour'), moment()],
        [moment().subtract(6, 'hour'), moment()],
        [moment().subtract(8, 'hour'), moment()],
        [moment().subtract(12, 'hour'), moment()],
        [moment().subtract(1, 'days'), moment()],
        [moment().subtract(6, 'days'), moment()],
        [moment().subtract(1, 'months'), moment()],
        [moment().subtract(1, 'year'), moment()],
      ],
    },
  };

  const rangesLabels = useMemo(() => CONFIG[mode].rangesLabels, [languageId]);

  /**
   * Output:
   * {...
   *    Last Month: "month"
        Last Week: "week"
        Last Year: "year"
        Last day: "day"
      }
   */
  const rangeGranularity = Object.keys(CONFIG[mode].rangesLabels).reduce(
    (acc, item, idx) => ({
      ...acc,
      [CONFIG[mode].rangesLabels[item]]: CONFIG[mode].rangeGranularity[idx],
    }),
    {}
  );

  const scopedRanges = () => {
    const scopedRanges = {};

    Object.entries(ranges).forEach((range) => {
      // range[0] is the range label
      // range[1] is the minDate maxDate array - range[1][0] == min | range[1][1] == max
      const dateRange = getRange(
        range[1][0],
        range[1][1],
        rangeGranularity[range[0]],
        format,
        minDate,
        maxDate
      );
      if (dateRange) scopedRanges[range[0]] = dateRange;
    });

    return scopedRanges;
  };

  /**
   * Output:
   * {...
   *    Last Week: (2) [Moment, Moment]
        Last Year: (2) [Moment, Moment]
        Last day: (2) [Moment, Moment]
      }
   */
  const ranges = Object.keys(CONFIG[mode].rangesLabels).reduce(
    (acc, item, idx) => ({ ...acc, [CONFIG[mode].rangesLabels[item]]: CONFIG[mode].ranges[idx] }),
    {}
  );

  useEffect(() => {
    setDateFilterLabel(chosenLabel, rangesLabels, start, end, BEformat, format, selectedChanged);
  }, [label]);
  useEffect(() => {
    let rangesWidth = '140px';
    !displayRanges && singleDatePicker && disableCustomLabel && (rangesWidth = '0px');
    document.documentElement.style.setProperty(`--datePickerRangesWidth`, rangesWidth);
  }, [displayRanges, singleDatePicker, disableCustomLabel]);

  useEffect(() => {
    setTimeUpdate(
      data,
      BEformat,
      format,
      setStart,
      setEnd,
      setChosenLabel,
      rangesLabels,
      singleDatePicker,
      setLabel
    );
  }, [data, languageId]);

  const locale = useMemo(
    () => ({
      format,
      firstDay, //1=Mo,2=Tu
      applyLabel: i18nService.translate('date-picker.apply'),
      cancelLabel: i18nService.translate('date-picker.cancel'),
      customRangeLabel: i18nService.translate('date-picker.custom'),
      monthNames, //['1', '2', '3', '4', '5', '6', '7', '8', '9', '10/', '11/', '12'],
      daysOfWeek, //['רא', 'שנ', 'של', 'רב', 'חמ', 'שי', 'שב']
    }),
    [languageId, format, firstDay, monthNames, daysOfWeek]
  );

  return (
    <div style={{ height, width: '100%', color: 'var(--systemFont)' }} className="dateWrapper">
      <DateRangePicker
        timePicker={timePicker}
        startDate={start}
        singleDatePicker={singleDatePicker}
        showDropdowns={showDropdowns}
        endDate={end}
        minDate={minDate}
        maxDate={maxDate}
        ranges={minDate && maxDate ? scopedRanges() : displayRanges ? ranges : {}}
        onEvent={(e, picker) =>
          handleEvent(
            e,
            picker,
            format,
            minDate,
            maxDate,
            setStart,
            setEnd,
            setChosenLabel,
            singleDatePicker,
            setLabel
          )
        }
        locale={locale}
        isCustom={true}
        drops={openDirection}
        // opens="center"
        showCustomRangeLabel={!disableCustomLabel}
        style={{ color: 'var(--systemFont)' }}
        applyClass={classNames(styles.applyButton, styles.btn)}
        cancelClass={classNames(styles.cancelButton, styles.btn)}>
        <div className={styles.dateRangeInput}>
          <span title={label} className="ellipsis-overflow">
            {label}
          </span>
          <Icon className={styles.icon} type="dropDown" />
        </div>
      </DateRangePicker>
    </div>
  );
}

export default DatePicker;
