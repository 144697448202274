import React, { useEffect } from 'react';
import { LinearGaugeCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface.d';
import { GaugeCustomizationProps } from '../GaugeCustomization.interface';
import {
  getDefaultLinearGaugeCustomization,
  getLinearNeedleConfig,
  getBackgroundColorConfig,
  getBarConfig,
} from './LinearGaugeCustomizationUtils';
import {
  getDataAndValueConfig,
  getDataTicksConfig,
  getZonesConfig,
  getNewZones,
} from '../GaugeCustomizationUtils';
import GaugeCustomizationSection from '../GaugeCustomizationSection';
import { cloneDeep, isEmpty } from 'lodash';

function LinearGaugeCustomization(props: GaugeCustomizationProps) {
  const { widgetData, setWidgetData, defaultDecimalDigits } = props;

  const { customization } = widgetData;
  const _customization = cloneDeep(customization) as LinearGaugeCustomization;

  useEffect(() => {
    if (isEmpty(customization)) {
      setWidgetData((prevState) => ({
        ...prevState,
        customization: getDefaultLinearGaugeCustomization(defaultDecimalDigits),
      }));
      return;
    }

    if (_customization && _customization.zones.length < 4) {
      setWidgetData((prevState) => ({
        ...prevState,
        customization: {
          ...prevState.customization,
          zones: [...(prevState.customization as any).zones, ...getNewZones()],
        },
      }));
    }
  }, [customization]);

  if (isEmpty(_customization)) return <></>;

  const dataAndValueConfig = getDataAndValueConfig(_customization, setWidgetData);
  const backColorConfig = getBackgroundColorConfig(_customization, setWidgetData);
  const dataTicksConfig = getDataTicksConfig(_customization, setWidgetData, true, widgetData);
  const zonesConfig = getZonesConfig(_customization, setWidgetData, widgetData);
  const needleConfig = getLinearNeedleConfig(_customization, setWidgetData);
  const barConfig = getBarConfig(_customization, setWidgetData);

  const sectionsConfig = [
    ...dataAndValueConfig,
    ...backColorConfig,
    ...dataTicksConfig,
    ...zonesConfig,
    ...needleConfig,
    ...barConfig,
  ];

  return sectionsConfig.map((section, idx) => (
    <GaugeCustomizationSection
      key={`${section.sectionLabel}_${idx}`}
      {...section}
      widgetData={widgetData}
    />
  ));
}

export default LinearGaugeCustomization;
