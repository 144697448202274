import React, { useCallback, useEffect, useMemo, useState } from 'react';
import I18n from '@components/I18n';
import { dashboardService } from '@core/canvas/DashboardService';
import { modalService } from '@core/modals/ModalService';
import { useSelector } from '@redux/useSelector';
import styles from './DashboardGalleryPage.scss';
import DashboardThumbnail from './DashboardThumbnail/DashboardTumbnail';
import ViewControl from '@components/ViewControl/ViewControl';
import { getPermissionStatus } from '@core/ffAndPermissions';
import DashboardTable from './DashboardTable/DashboardTable';
import { makeStyles } from '@material-ui/core';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { compact } from 'lodash';
import { dispatch, getState } from '@src/redux/store';
import { setDashboardGalleryViewType } from '@src/redux/config';
import { httpService } from '@core/http/HttpService';
import { setCurrentLayout } from '@src/redux/dashboardEditor';

const useStyles = makeStyles((theme: any) => ({
  box: {
    width: '100%',
    height: 'calc(100% - 60px)',
    boxSizing: 'border-box',
    backgroundColor: 'var(--systemContentBackground)',
    boxShadow: '0 0 6px 0 rgba(194, 207, 224, 0.4)',
    borderRadius: 8,
    padding: 25,
    marginTop: 22,
  },
  publishAll: {
    padding: '0px 10px',
    fontSize: '14px',
  },
  toolbar: {
    '&.container': {
      padding: 0,
      border: 'none',
    },
  },
}));

function DashboardGalleryPage(props) {
  const dashboards = useSelector((state) => state.dashboardGallery.dashboards);
  const dashboardGalleryViewType =
    useSelector((state) => state.config.dashboardGalleryViewType) || 'TILE';
  const isDimensionsStatic = useMemo(() => dashboards && dashboards.length <= 3, [dashboards]);
  const [isTileView, setIsTileView] = useState(!!(dashboardGalleryViewType === 'TILE'));
  const classes = useStyles(props);
  const [sorted, setSorted] = useState(false);
  const [sortedDashboards, setSortedDashboards] = useState([]);

  useEffect(() => {
    dashboardService.getAllDashboards();
    dispatch(setCurrentLayout('DESKTOP'));
  }, []);

  useEffect(() => {
    const dashboardSortArray = getState().dashboardGallery.dashboardSortArray;
    if (dashboards?.length && dashboardSortArray?.length) {
      setSortedDashboards(
        dashboards.sort((a, b) =>
          dashboardSortArray.indexOf(a.ref) < dashboardSortArray.indexOf(b.ref)
            ? -1
            : dashboardSortArray.indexOf(a.ref) > dashboardSortArray.indexOf(b.ref) ||
              dashboardSortArray.indexOf(b.ref) === -1
            ? 1
            : -1
        )
      );
    } else {
      setSortedDashboards(dashboards);
    }
  }, [sorted, dashboards]);

  const createNewDashboard = useCallback(() => {
    if (dashboards) {
      const takenNumbers = dashboards
        .map((dashboard) => {
          const dashboardMatch = dashboard.dash_instances.find((d) =>
            d.name.match(/^Dashboard (\d+)$/)
          );
          const match = dashboardMatch?.name.split(' ')[1];
          return match ? +match : undefined;
        })
        .filter((num) => num !== undefined);

      let nextNumber = 1;
      while (takenNumbers.includes(nextNumber)) {
        nextNumber++;
      }

      modalService
        .openModal('newDashboard', { nextNumber }, { disableBackdropClick: true })
        .then((res) => {});
    }
  }, [dashboards]);

  const hasPermission = useMemo(() => {
    return getPermissionStatus('DASHBOARD', 'EDIT');
  }, []);

  const toolbarCfg: ToolbarCfg = {
    style: {
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'center',
      margin: '0 15px 0 5px',
      padding: 0,
      border: 'none',
    },
    buttons: compact([
      {
        type: 'create',
        text: 'general.add',
        onClick: createNewDashboard,
        disabled: !hasPermission,
      },
    ]),
  };

  useEffect(() => {
    httpService
      .api({
        type: 'updateDashboardGalleryView',
        data: { viewType: isTileView ? 'TILE' : 'LIST' },
      })
      .then(() => {
        dispatch(setDashboardGalleryViewType(isTileView ? 'TILE' : 'LIST'));
      });
  }, [isTileView]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} style={{ display: 'flex' }}>
        <I18n className={styles.title}>dashboard-editor-gallery.dashboard-gallery</I18n>
        <div style={{ display: 'flex', padding: 0, border: 'none' }}>
          <ToolBar toolbarCfg={toolbarCfg} />
          <ViewControl value={isTileView} setValue={setIsTileView} />
        </div>
      </div>
      {getPermissionStatus('DASHBOARD', 'VIEW') &&
        sortedDashboards &&
        (isTileView ? (
          <div className={styles[`dashboardListGrid${isDimensionsStatic ? 'Static' : ''}`]}>
            {sortedDashboards.map((dashboard) => (
              <DashboardThumbnail key={dashboard.ref} dashboard={dashboard} />
            ))}
          </div>
        ) : (
          <div className={classes.box}>
            <DashboardTable
              dashboards={sortedDashboards}
              sorted={sorted}
              setSorted={setSorted}
              setSortedDashboards={setSortedDashboards}
            />
          </div>
        ))}
    </div>
  );
}

export default DashboardGalleryPage;
