import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editAssetTypeService } from '../../EditAssetTypeService';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import styles from './AssetTypeInfoVariablesMeta.scss';
import I18n from '@components/I18n';
import { getPermissionStatus } from '@core/ffAndPermissions';
import Button from '@components/Button';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { VariableMeta } from '@core/http/server.interface';
import VariablesTable from '@pages/OrganizationsPage/Variables/VariablesTable/VariablesTable';

function AssetTypeInfoVariablesMeta({ assetTypeId = null, assetId = null }) {
  const assetTypeOrAssetId = assetTypeId !== null ? assetTypeId : assetId;
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);
  const [variablesList, setVariablesList] = useState([]);
  const [editedVariableId, setEditedVariableId] = useState<number>(null);

  function cancel() {
    assetTypeId !== null ? editAssetTypeService.dismiss(false) : editAssetService.dismiss(false);
  }

  useEffect(() => {
    getVariablesList();
  }, [assetTypeOrAssetId]);

  const getVariablesList = () => {
    httpService
      .api({
        type: assetTypeId !== null ? 'getAssetTypeVariablesList' : 'getAssetVariablesList',
        urlParams: { id: assetTypeOrAssetId },
      })
      .then((res: VariableMeta[]) => {
        setVariablesList(res);
      });
  };

  const toolbarCfg: ToolbarCfg = {
    buttons: [
      {
        type: 'create',
        text: 'assets-tab.add-asset',
        disabled: !hasPermission,
        onClick: () => {
          setEditedVariableId(null);
          openAddVariableModal();
        },
      },
    ],
  };

  function openAddVariableModal() {
    modalService.openModal('addVariableModal', {
      setVariablesList,
    });
  }

  return (
    <>
      <div className={styles.content}>
        {assetTypeId ? <ToolBar toolbarCfg={toolbarCfg} /> : null}
        <VariablesTable
          variablesList={variablesList}
          origin={assetTypeId ? 'assetType' : 'asset'}
          setVariablesList={setVariablesList}
          assetTypeOrAssetId={assetTypeOrAssetId}
          editedVariableId={editedVariableId}
          setEditedVariableId={setEditedVariableId}
        />
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetTypeInfoVariablesMeta;
