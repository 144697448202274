import React, { useState, useMemo } from 'react';
import { MenuItem, Menu, makeStyles } from '@material-ui/core';
import { useSelector } from '@src/redux/useSelector';
import { dispatch } from '@src/redux/store';
import { setLanguage, setApexLangChange } from '@src/redux/config';
import { i18nService } from '@core/i18n/I18nService';
import Icon from '@components/Icon';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme: any) => ({
  menuItem: {
    margin: '0 10px',
    padding: '5px 16px 5px 27px',
    borderBottom: `1px solid ${theme.app.multiSelectChip}`,
    display: 'flex',
    cursor: 'pointer',
  },
  mobileMenuItem: {
    paddingRight: '0px!important',
    paddingLeft: '0px!important',
  },
  name: {
    margin: '0 11px',
    color: 'var(--systemFont)',
  },
}));

function SelectLanguage(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const languages = useSelector((store) => store.config.languages);
  const languageId = useSelector((store) => store.config.languageId);
  const selectedLang = useMemo(
    () => languageId && languages.find((lang) => lang.id === languageId),
    [languageId]
  );
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  const classes = useStyles(props);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onselect = async (lang) => {
    handleClose();
    if (lang.id !== languageId) {
      const res: any = await httpService.api({
        type: 'updateUserConfig',
        data: { languageId: lang.id },
      });
      dispatch(setApexLangChange(true));
      res && i18nService.changeApexLang(res);
      dispatch(setLanguage({ languageId: lang.id, dictionary: res, apexLangChange: false }));
    }
  };

  return (
    <div className={props.className}>
      <MenuItem classes={isMobileView ? { root: classes.mobileMenuItem } : {}} onClick={handleMenu}>
        {selectedLang && <img src={selectedLang.flag} style={{ width: 30, height: 20 }} />}
        {!isMobileView && (
          <div className={classes.name}>
            {selectedLang
              ? `${selectedLang.name.charAt(0).toUpperCase()}${selectedLang.name.slice(1)}`
              : i18nService.translate('languages.select-lang')}
          </div>
        )}
        {!isMobileView && <Icon type="dropDown" />}
      </MenuItem>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}>
        <div style={{ maxHeight: 350, overflow: 'auto' }}>
          {languages.map((lang) => (
            <MenuItem
              key={lang.id}
              classes={{ root: classes.menuItem }}
              onClick={() => onselect(lang)}>
              <img src={lang.flag} style={{ width: 30, height: 20 }} />
              {!isMobileView && (
                <div className={classes.name}>
                  {`${lang.name.charAt(0).toUpperCase()}${lang.name.slice(1)}`}
                </div>
              )}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
}

export default SelectLanguage;
