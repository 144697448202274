import React from 'react';
import classNames from 'classnames';

import styles from './ThumbnailToolbar.scss';
import { ThumbnailToolbarProps } from './ThumbnailToolbar.interface';
import Icon from '@components/Icon';
import { cssVarsService } from '@core/CssVarsService';

function ThumbnailToolbar(props: ThumbnailToolbarProps) {
  const {
    galleryItemInstance,
    status,
    getButtonsCfg,
    setGalleryData = null,
    size = 'large',
  } = props;
  const btnColor = cssVarsService.vars.systemEnableEditorIcons;
  const disabledBtnColor = cssVarsService.vars.systemDisableEditorIcons;

  const iconSize = size === 'small' ? '14px' : null;

  return (
    <div
      className={classNames(
        styles.toolbar,
        galleryItemInstance.status === 'PUBLISHED' ? styles.published : styles.draft
      )}>
      <div className={classNames(styles.title, 'ellipsis-overflow')}>
        <span
          title={galleryItemInstance.name}
          className={classNames(styles.galleryItemName, size === 'small' && styles.smallName)}>
          {galleryItemInstance.name}
        </span>
      </div>
      <div className={styles.dashboardButtons}>
        {getButtonsCfg(galleryItemInstance, status, setGalleryData).map((button, idx) => {
          return button.isVisible ? (
            <Icon
              key={idx}
              className={classNames('pointer', styles.btn)}
              type={button.type}
              tooltipText={button.tooltip}
              onClick={button.onClick}
              disabled={button.isDisabled}
              color={button.isDisabled ? disabledBtnColor : btnColor}
              width={iconSize}
              height={iconSize}
            />
          ) : null;
        })}
      </div>
    </div>
  );
}

export default ThumbnailToolbar;
