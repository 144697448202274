import React, { useMemo, useState } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import AssetSubscriptionLicensingPlanesTable from './AssetSubscriptionLicensingPlanesTable';

// Services | Interfaces
import styles from './AssetInfoSubscriptionView.scss';

// 3rd party
import 'moment-duration-format';
import classNames from 'classnames';
import EditTimer from './EditTimer';
import EditLicensePlan from './EditLicensePlan';
import moment from 'moment';

function AssetInfoSubscriptionView(props) {
  const [editMode, setEditMode] = useState() as any;
  const {
    assetSubscription,
    duration,
    setDuration,
    assetTypePlcModel,
    cancel,
    onCancelEdit,
    remoteAccessMode,
    getAssetTypeSubscription,
  } = props;
  const { licences, defaultLicenceId, requiredPlan, licensingPlans } = assetSubscription || {};
  const selectedLicensePlan = useMemo(
    () => licences?.find((l) => defaultLicenceId === l.id),
    [defaultLicenceId, licences]
  );

  const licencesOptions = useMemo(() => {
    if (licences && requiredPlan && licensingPlans) {
      let options = licences;
      const minLicense = licensingPlans?.find((l) => l.planName === requiredPlan);
      const minLicenseIndex = options?.findIndex((l) => l.id === minLicense?.licenceId);
      minLicenseIndex > 0 && options.splice(0, minLicenseIndex);
      const remoteAccessPlan = licensingPlans.find((l) => l.planName === 'Remote Access');
      !options?.some((opt) => opt.name === 'Remote Access') && remoteAccessPlan
        ? (options = [
            remoteAccessPlan && {
              id: remoteAccessPlan.licenceId,
              name: remoteAccessPlan.planName,
            },
            ...options,
          ])
        : (options = [...options]);
      return options;
    }
  }, [licences, requiredPlan, licensingPlans]);

  const onTimerEditClicked = ({ value }) => {
    setEditMode(value ?? 'timer');
  };

  const onLicensePlanEditClicked = ({ value }) => {
    setEditMode(value ?? 'licensePlan');
  };

  const onEditDone = (isUpdate, data) => {
    setEditMode('');
    onCancelEdit(isUpdate, data);
    getAssetTypeSubscription();
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.dataSection}>
          <div>
            <div className={styles.requiredPlanEdit}>
              <div className={styles.header}>
                <I18n>asset-subscription.license-plan</I18n>:
              </div>
              {editMode !== 'licensePlan' ? (
                <>
                  <span className={styles.dataValue}>{selectedLicensePlan?.name}</span>
                  <Icon
                    type="edit"
                    color="var(--systemFont)"
                    className={styles.editIcon}
                    onClick={onLicensePlanEditClicked}
                  />
                </>
              ) : (
                <EditLicensePlan
                  setTimerEditMode={setEditMode}
                  onCancelEditClicked={onEditDone}
                  {...props}
                  selectedLicensePlan={selectedLicensePlan}
                  licences={licencesOptions}
                />
              )}
            </div>
            {!remoteAccessMode && (
              <div className={styles.timer}>
                <div className={styles.header}>
                  <I18n>asset-subscription.tags-timer-interval</I18n>:
                </div>
                {editMode !== 'timer' ? (
                  <>
                    <span className={styles.dataValue}>{duration}</span>
                    <Icon
                      type="edit"
                      className={styles.editIcon}
                      onClick={onTimerEditClicked}
                      color="var(--systemFont)"
                    />
                  </>
                ) : (
                  <EditTimer
                    setTimerEditMode={setEditMode}
                    onCancelEditClicked={onEditDone}
                    {...props}
                  />
                )}
              </div>
            )}
            {assetSubscription && (
              <div>
                {!remoteAccessMode && (
                  <div style={{ display: 'flex' }}>
                    <div className={styles.requiredPlan}>
                      <I18n className={styles.header}>asset-subscription.tags-licensing-plan</I18n>
                      <span className={styles.dataValue}>{assetSubscription.requiredPlan}</span>
                    </div>
                    <div className={classNames(styles.expectedTags, 'asterisk')}>
                      <I18n element="div" className={styles.expectedTagsHeader}>
                        asset-subscription.licensing-plan.expected-tags
                      </I18n>
                      <span className={styles.dataValue}>
                        {assetSubscription.expectedTagUpdates.toLocaleString()}
                      </span>
                    </div>
                  </div>
                )}
                <AssetSubscriptionLicensingPlanesTable planes={assetSubscription.licensingPlans} />
              </div>
            )}
          </div>
          {!remoteAccessMode &&
            assetTypePlcModel &&
            assetTypePlcModel.toUpperCase() === 'UNISTREAM' && (
              <div className={classNames(styles.unistreamAlertWrapper, 'asterisk')}>
                <I18n className={styles.unistreamAlert}>asset-subscription.alert</I18n>
              </div>
            )}
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button mode="bigFont" onClick={cancel}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </>
  );
}

export default AssetInfoSubscriptionView;
