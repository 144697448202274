import React from 'react';
import I18n from '@components/I18n';
import { LicensingPlan } from '@src/redux/redux.interface';

function LicensingPlaneFrequencyField(props: LicensingPlan) {
  const { updateFrequencySeconds, planName } = props;
  let time: number, timePart: string;

  if (updateFrequencySeconds < 60) {
    time = updateFrequencySeconds;
    timePart = 'seconds';
  } else if (updateFrequencySeconds < 3600) {
    timePart = 'minutes';
    time = Math.floor(updateFrequencySeconds / 60);
  } else {
    timePart = 'hours';
    time = Math.floor(updateFrequencySeconds / 3600);
  }

  return (
    <>
      {planName === 'Remote Access' ? null : (
        <div>
          {time} <I18n element="span">{`licensing-plan-table.update-frequency.${timePart}`}</I18n>
        </div>
      )}
    </>
  );
}

export default LicensingPlaneFrequencyField;
