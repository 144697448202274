import { httpService } from '@core/http/HttpService';
import { getState } from '@redux/store';
import { pick } from 'lodash';

import { localToServer } from '@pages/CreateWidgetPage/widgets.utils';
import { GetWidgetDataProps } from './GetWidgetDataService.interface';
import { i18nService } from '@core/i18n/I18nService';
import { staticDictionary } from '@core/i18n/dictionary';
import {
  getHeatmapData,
  getHeatmapDataPreview,
} from '@components/widgets/HeatmapWidget/HeatmapWidgetGetData.utils';

export const getWidgetData = async ({
  widgetId,
  widgetData,
  dashboardFilters,
  widgetFilters,
  paging,
  options,
  widgetType,
  customization,
}: GetWidgetDataProps) => {
  let apiData: any = {};
  if (widgetId) {
    apiData.type = 'getWidgetData';
    apiData.urlParams = { widgetId };
    apiData.query = paging || { p: 1, ps: 50 };
    apiData.data = { dashboardFilters, widgetFilters };
  } else {
    //preview
    const {
      organizationsFilter,
      assetTypesFilter,
      assetFilter,
      geoFilter,
      dateFilter,
      dateFilterFrom,
      dateFilterTo,
    } = getState().dashboardEditor;
    const widgetServerData = localToServer(widgetData);
    apiData.type =
      widgetServerData.type === 'HEATMAP' ? 'getHeatmapDataPreview' : 'getWidgetPreviewData';
    apiData.data = {
      dashboardFilters: {
        organizationsFilter: organizationsFilter.map((f) => f.id),
        assetTypesFilter: assetTypesFilter.map((f) => f.id),
        assetFilter: assetFilter.map((f) => f.id),
        geoFilter,
        dateFilter,
        dateFilterFrom,
        dateFilterTo,
      },
      widgetDetails: pick(widgetServerData, [
        'alarmInfos',
        'assetProperties',
        'assetTypes',
        'allAssetTypes',
        'calculations',
        'variables',
        'localTags',
        'tags',
        'tagTypes',
        'type',
        'scope',
        'groupBy',
        'metrics',
        'filters',
        'sorts',
        'customizationMetrics',
      ]),
    };
  }
  if (options) {
    apiData = { ...apiData, ...options };
  }

  // Fix: for image and Text, there are no asset types. The get widget preview causes error 400 (because assetTypeList is empty);
  // If you debug the code, you see that there are a lot of cases where the code halts after localToServer
  // This is because  type is null, so toUpperCase throws an error that causes setWidgetData({})
  if (
    apiData.type === 'getWidgetPreviewData' &&
    (apiData?.data?.widgetDetails?.type === 'MAP' ||
      (!apiData?.data?.widgetDetails.allAssetTypes &&
        (!apiData?.data?.widgetDetails?.assetTypes ||
          !apiData?.data?.widgetDetails?.assetTypes?.length)))
  )
    return {};

  if (
    apiData.type === 'getHeatmapDataPreview' &&
    !(apiData.data.widgetDetails.metrics?.length || apiData?.data?.widgetDetails?.localTags?.length)
  ) {
    return {};
  }

  if (
    !apiData?.data?.dashboardFilters?.organizationsFilter ||
    !apiData?.data?.dashboardFilters?.assetTypesFilter ||
    !apiData?.data?.dashboardFilters?.assetFilter ||
    !apiData?.data?.dashboardFilters?.geoFilter
  ) {
    return {};
  }

  if (
    (apiData?.data?.widgetDetails?.scope === 'AGGREGATED_DATA' ||
      apiData?.data?.widgetDetails?.scope === 'AGGREGATED_LAST_VALUE') &&
    apiData?.data?.widgetDetails?.metrics?.some(
      (m) => m.valueType !== 'CALCULATION' && !m.operation
    )
  )
    return {};

  let res: any =
    apiData.type === 'getHeatmapDataPreview'
      ? await getHeatmapDataPreview({ ...apiData, paging: paging, widgetId: widgetId })
      : await httpService.api(apiData);
  const geoColumn = res?.columns?.find((c) => c.valueId === 3 && c.valueType === 'ASSET_PROPERTY');
  if (geoColumn && res?.results.some((r) => r[geoColumn.name] === 'Unknown')) {
    res = {
      ...res,
      results: [
        ...res.results.map((r) => ({
          ...r,
          [geoColumn.name]:
            r[geoColumn.name] === 'Unknown'
              ? i18nService.translate('widget.data.location.unknown')
              : r[geoColumn.name],
        })),
      ],
    };
  }

  const plcTypeColumn = res?.columns?.find(
    (c) => c.valueId === 14 && c.valueType === 'ASSET_PROPERTY'
  );
  if (plcTypeColumn) {
    res = {
      ...res,
      results: [
        ...res.results.map((r) => ({
          ...r,
          [plcTypeColumn.name]: staticDictionary[`enum.${r[plcTypeColumn.name]}`],
        })),
      ],
    };
  }

  return res;
};
