import React from 'react';
import Icon from '@components/Icon';
import styles from './MultiIconSwitch.scss';
import { MultiIconSwitchProps } from './MultiIconSwitch.interface';

function MultiIconSwitch(props: MultiIconSwitchProps) {
  const {
    config,
    txtColor = 'var(--systemEnableEditorIcons)',
    bgColor = 'var(--systemScreenBackground)',
    outlineColor = 'var(--systemFont)',
  } = props;

  return (
    <div className={styles.controlSwitch} style={{ outline: `1px solid ${outlineColor}` }}>
      {config.map((button, idx) =>
        button.isActive ? (
          <Icon
            key={idx}
            color={bgColor}
            type={button.type}
            width={'30px'}
            height={'25px'}
            tooltipText={button.tooltipText}
            onClick={() => null}
            className={styles.icon}
            style={{
              backgroundColor: txtColor,
              cursor: 'default',
            }}
          />
        ) : (
          <Icon
            key={idx}
            color={button.isDisabled ? 'var(--systemDisabledFont)' : txtColor}
            type={button.type}
            width={'30px'}
            height={'25px'}
            tooltipText={button.tooltipText}
            onClick={button.onClick}
            className={styles.icon}
            style={{
              backgroundColor: button.isDisabled ? 'var(--systemDisabledBackground)' : bgColor,
              cursor: button.isDisabled ? 'default' : 'pointer',
            }}
          />
        )
      )}
    </div>
  );
}

export default MultiIconSwitch;
