import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Button from '@components/Button';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { EditVariableSettingsModal } from './EditVariableSettingsModal.interface';
import styles from './EditVariableSettingsModal.scss';
import {
  getFieldsCfg,
  openResetValuesModal,
  saveVariableSettings,
} from './EditVariableSettingsModal.utils';
import FormikField from '@components/FormikField';
import * as Yup from 'yup';
import { useSelector } from '@src/redux/useSelector';
import Icon from '@components/Icon';
import { validateName } from '@pages/OrganizationsPage/Variables/Variables.utils';

function EditVariableSettingsModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const { origin, variable, setVariablesList } = args;
  const assetTypeId =
    variable.assetTypeId || useSelector((state) => state.editAssetType?.staticData?.id);
  const [nameValidation, setNameValidation] = useState({
    name: variable.name,
    message: '',
    isValid: true,
  });

  const initialValues = {
    id: variable.id,
    name: variable.name,
    valueType: variable.valueType,
    managementLevel: variable.managementLevel,
  };

  const fieldsCfg = getFieldsCfg(nameValidation);

  function cancel() {
    dismiss(false);
  }

  async function onSubmit(values, formik) {
    const { name, id, managementLevel } = values;
    const args = { origin, assetTypeId, id, name, managementLevel, setVariablesList, dismiss };
    await validateName(assetTypeId, initialValues, setNameValidation, formik, name);
    if (initialValues.managementLevel !== 'GLOBAL' && managementLevel === 'GLOBAL') {
      openResetValuesModal(args);
      return;
    }
    saveVariableSettings(args);
  }

  function validate(values) {
    return [...fieldsCfg].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );
  }

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>variables.edit-variable-settings-pop-up.header</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema([...fieldsCfg])}
        validate={validate}
        onSubmit={onSubmit}>
        {({ isValid, isSubmitting, values, errors, setFieldValue }) => {
          return (
            <FormikForm className={styles.modalForm}>
              <div className={styles.modalContent}>
                {fieldsCfg.map((field, idx) => (
                  <FormikField
                    key={idx}
                    editMode={true}
                    {...field}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
              <div className={styles.modalButtons}>
                <Button styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']} onClick={cancel}>
                  <I18n>general.cancel</I18n>
                </Button>
                <Button
                  styles={{ marginLeft: 13 }}
                  mode="bigFont"
                  type="submit"
                  disabled={!isValid}>
                  <I18n>general.save</I18n>
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default EditVariableSettingsModal;
