import I18n from '@components/I18n';
import { TextField, makeStyles } from '@material-ui/core';
import Select from '@components/Select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CreateEventTemplateDataSourceModal.scss';
import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import Icon from '@components/Icon';
import Button from '@components/Button';
import { initialWidgetData } from '@pages/CreateWidgetPage/widgets.utils';

const getDataSourcesTypeOptions = (canAddTableOrAlarms, canAddPdf) => {
  const res = [
    {
      label: 'events-template.create-data.source.type.value',
      value: 'VALUE',
    },
  ];

  if (canAddTableOrAlarms)
    res.push(
      {
        label: 'events-template.create-data.source.type.table',
        value: 'TABLE',
      },
      {
        label: 'events-template.create-data.source.type.alarms',
        value: 'ALARMS',
      }
    );

  if (canAddPdf)
    res.push({
      label: 'events-template.create-data.source.type.pdf',
      value: 'PDF',
    });

  return res;
};

const useStyle = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
}));

function CreateEventTemplateDataSourceModal(props) {
  const { args, dismiss } = props;
  const [name, setName] = useState('');
  const [type, setType] = useState('VALUE');
  const [nameValid, setNameValid] = useState(true);
  const [canAddTableOrAlarms, setCanAddTableOrAlarms] = useState(false);
  const [dashboardOptions, setDashboardOptions] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState();
  const { templateData } = args;
  const classes = useStyle(props);
  const [isLoading, setIsLoading] = useState(false);
  const canAddPdf = !templateData.dashboardReport?.refId;

  const dataSourceTypeOptions = useMemo(
    () => getDataSourcesTypeOptions(canAddTableOrAlarms, canAddPdf),
    [canAddTableOrAlarms, canAddPdf]
  );

  useEffect(() => {
    if (type !== 'PDF') {
      setSelectedDashboard(undefined);
      const getDefaultName = async () => {
        try {
          const res: any = await httpService.api({
            type: 'getEventTemplateDataSourceDefaultName',
            urlParams: { templateId: templateData?.id, dataSourceType: type },
          });
          if (res) {
            setName(res.name);
          }
          setIsLoading(false);
        } catch {
          setIsLoading(false);
        }
      };
      setIsLoading(true);
      getDefaultName();
    } else {
      setName('PDF Report');

      if (!dashboardOptions) {
        const getPublishedDashboards = async () => {
          const publishDashboards: any = await httpService.api({
            type: 'getAllPublishDashboard',
            urlParams: { p: 1, ps: 99999 },
          });

          const dashbaords = publishDashboards.results.map((t) => ({
            value: t.refId,
            label: t.name,
          }));

          dashbaords.sort((a, b) => a.label.localeCompare(b.label));

          setDashboardOptions(dashbaords);
        };

        getPublishedDashboards();
      }
    }
  }, [type, dashboardOptions]);

  useEffect(() => {
    const getDataSources = async () => {
      try {
        const res: any = await httpService.api({
          type: 'getEventTemplateDataSources',
          urlParams: { templateId: templateData?.id },
        });
        if (res) {
          const canAddNonSimpleValue = !res.some((d) => d.type === 'TABLE' || d.type === 'ALARMS');
          setCanAddTableOrAlarms(canAddNonSimpleValue);
          if (!canAddNonSimpleValue) setType('VALUE');
        }
      } catch {
        setCanAddTableOrAlarms(false);
        setType('VALUE');
      }
    };

    getDataSources();
  }, []);

  useEffect(() => {
    setNameValid(true);
  }, [name]);

  const validateName = async (newName) => {
    if (newName)
      try {
        const res: any = await httpService.api({
          type: 'validateEventTemplateDataSourceName',
          data: { name: newName },
          urlParams: { templateId: templateData?.id },
        });
        if (res) {
          setNameValid(res.valid);
          return res.valid;
        }
      } catch {
        setNameValid(false);
        return false;
      }
  };

  const cancel = useCallback(() => dismiss(false), [dismiss]);

  const confirm = useCallback(async () => {
    const isValidName = type === 'PDF' ? true : await validateName(name);
    if (isValidName) {
      const dataSource =
        type === 'PDF'
          ? {
              name,
              type: type.toLowerCase(),
              selectedDashboard,
            }
          : {
              ...initialWidgetData,
              name,
              type: type.toLowerCase(),
              eventTemplateId: templateData?.id,
            };
      dismiss({ dataSource });
    }
  }, [dismiss, name, templateData?.id, selectedDashboard]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.modalHeader}>
        <I18n>events-template.create-datasource.modal.header</I18n>
        <Icon type="close" onClick={props.dismiss} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={styles.rowWithError}>
            <div className={styles.header}>
              <I18n>events-template.create-data.source.name</I18n>
            </div>
            <TextField
              value={name}
              margin="dense"
              variant="outlined"
              className={styles.textField}
              id={styles.textFieldColor}
              inputProps={{
                style: { fontSize: 12 },
                maxLength: 100,
                autoFocus: true,
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className={styles.error}>
            {!nameValid && !isLoading && (
              <I18n>events-template.edit-datasource.name-validation-error</I18n>
            )}
          </div>

          <div className={styles.row}>
            <div className={styles.header}>
              <I18n>events-template.create-data.source.type</I18n>
            </div>
            {canAddTableOrAlarms || canAddPdf ? (
              <Select
                styles={{ container: { width: '400px' } }}
                className={styles.field}
                options={dataSourceTypeOptions}
                value={dataSourceTypeOptions.find((opt) => opt.value === type)}
                getOptionLabel={(i) => i18nService.translate(i.label)}
                getOptionValue={(i) => i.value}
                onChange={(option) => setType(option.value)}
              />
            ) : (
              <I18n className={styles.field}>events-template.create-data.source.type.value</I18n>
            )}
          </div>

          {type === 'PDF' && (
            <div className={styles.row}>
              <div className={styles.header}>
                <I18n>events-template.create-data.source.dashboard</I18n>
              </div>
              <Select
                styles={{ container: { width: '400px' }, menuPortal: { zIndex: 9999999999999 } }}
                maxMenuHeight={180}
                menuPortalTarget={document.body}
                className={styles.field}
                options={dashboardOptions}
                value={dashboardOptions?.find((opt) => opt.value === selectedDashboard)}
                getOptionLabel={(i) => i.label}
                getOptionValue={(i) => i.value}
                onChange={(option) => setSelectedDashboard(option.value)}
              />
            </div>
          )}
        </div>
        <div className={styles.modalButtons}>
          <Button mode={['cancel', 'bigFont']} disabled={false} onClick={cancel}>
            <I18n>general.cancel</I18n>
          </Button>
          <Button
            styles={{ marginLeft: 13 }}
            mode="bigFont"
            onClick={confirm}
            disabled={!nameValid || !name || (type === 'PDF' && !selectedDashboard)}>
            <I18n>general.confirm</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateEventTemplateDataSourceModal;
