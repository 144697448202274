import { editorConfig } from '@core/canvas/editorConfig';
import { unifiedLayout } from '@pages/DashboardEditorPage/DashboardEditorLayout.utils';
import * as RI from '../redux.interface.d';
import { deHandlers } from './dashboardEditor.handlers';

export const initialState = (currentLayout = 'DESKTOP'): RI.DashboardEditorState => ({
  name: '',
  canvasHeight: editorConfig.defaultCanvasHeight,
  canvasWidth: editorConfig.defaultCanvasWidth,
  canvasWidgets: [],
  numSelected: 0,
  showGrid: false,
  refreshRate: null,
  organizationsFilter: [],
  assetTypesFilter: [],
  assetFilter: [],
  geoFilter: [],
  dateFilter: 'MONTH',
  dateFilterFrom: null,
  dateFilterTo: null,
  filterWidgetVisible: true,
  filters: [
    { filterType: 'ORGANIZATIONS', index: 0, editable: false, visible: true },
    { filterType: 'ASSET_TYPES', index: 1, editable: false, visible: true },
    { filterType: 'DATE', index: 2, editable: false, visible: true },
    { filterType: 'GEO', index: 3, editable: false, visible: true },
    { filterType: 'ASSET', index: 4, editable: false, visible: true },
  ],
  selectedWidgetDimenssions: {},
  isSplitLayout: false,
  states: unifiedLayout(),
  currentLayout: currentLayout,
  selectionBox: false,
});

export default function dashboardEditorReducer(
  state: RI.DashboardEditorState = initialState(),
  action: RI.StoreAction
) {
  const { type, payload } = action;

  switch (type) {
    case 'editor/INIT':
      return { ...state, ...payload };
    case 'editor/RESET':
      return initialState(payload);
    case 'editor/SET_CANVAS_HEIGHT':
      return { ...state, canvasHeight: payload };
    case 'editor/SET_CANVAS_WIDTH':
      return { ...state, canvasWidth: payload };
    case 'editor/SET_REFRESH_RATE':
      return { ...state, refreshRate: payload };
    case 'editor/SET_NAME':
      return { ...state, name: payload };
    case 'editor/SET_SHOW_GRID':
      return { ...state, showGrid: payload };
    case 'editor/SET_LAYOUT_MODE':
      return { ...state, isSplitLayout: payload };
    case 'editor/SET_CURRENT_LAYOUT':
      return { ...state, currentLayout: payload };
    case 'editor/SET_DASHBOARD_WIDGETS':
      return { ...state, canvasWidgets: payload };
    case 'editor/ADD_WIDGET':
      return deHandlers.addCanvasWidget(state, payload);
    case 'editor/SET_CANVAS_WIDGETS':
      return deHandlers.setCanvasWidgets(state, payload.widgets, payload.currentLayout);
    case 'editor/EDIT_WIDGET':
      return deHandlers.editCanvasWidgetById(
        state,
        payload.id,
        payload.widget,
        payload.currentLayout
      );
    case 'editor/SET_WIDGET_STATES':
      return deHandlers.setCanvasWidgetStatesById(state, payload.id, payload.states);
    case 'editor/RM_WIDGET':
      return deHandlers.removeCanvasWidget(state, payload);
    case 'editor/SELECT_WIDGET':
      return deHandlers.selectWidget(state, payload.id, payload.isMultiSelect);
    case 'editor/UNSELECT_WIDGET':
      return deHandlers.unselectWidget(state, payload.id);
    case 'editor/CLEAR_WIDGET_SELECTION':
      return deHandlers.clearWidgetSelection(state);
    case 'editor/SET_ORGANIZATIONS_FILTER':
      return { ...state, organizationsFilter: payload };
    case 'editor/SET_ASSET_TYPES_FILTER':
      return { ...state, assetTypesFilter: payload };
    case 'editor/SET_ASSET_FILTER':
      return { ...state, assetFilter: payload };
    case 'editor/SET_GEO_FILTER':
      return { ...state, geoFilter: payload };
    case 'editor/SET_DATE_FILTER':
      return deHandlers.setDateFilterValues(state, payload);
    case 'editor/SET_FILTER_WIDGET_VISIBILITY':
      return { ...state, filterWidgetVisible: payload };
    case 'editor/SET_FILTERS':
      return { ...state, filters: payload };
    case 'editor/SET_WIDGET_DIMENSSIONS':
      return { ...state, selectedWidgetDimenssions: payload.selectedWidgetDimenssions };
    case 'editor/SET_DASHBOARD_STATES':
      return { ...state, states: payload };
    case 'editor/SET_SELECTION_BOX':
      return { ...state, selectionBox: payload };
    default:
      return state;
  }
}
