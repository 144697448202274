import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconButtonWithText from '@components/IconButtonWithText';
import { dashboardService } from '@core/canvas/DashboardService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonWithText: {
    display: 'flex',
  },
  text: {
    margin: '2px 10px',
    fontSize: '14px',
  },
}));

function ActionButtons(props) {
  const { draft, published, status, showName } = props;
  const dashboard = props;
  const classes = useStyles(props);

  const buttons = [
    {
      type: 'preview',
      name: 'general.preview',
      tooltip: 'general.preview',
      onClick: () =>
        dashboardService.openDashboardPreview(
          status === 'published' || status === 'combined' ? published.id : draft.id,
          'gallery'
        ),
    },
    {
      type: 'edit',
      name: 'general.edit',
      tooltip: 'general.edit',
      onClick: () =>
        dashboardService.dashboardCopyEdit(
          status === 'draft' ? draft.id : published.id,
          'edit',
          !!(status === 'draft')
        ),
    },
    {
      type: 'delete',
      name: 'general.delete',
      tooltip: 'general.delete',
      onClick: () => {
        switch (status) {
          case 'combined':
            dashboardService.removeCombinedDashboards(dashboard, true);
            break;
          case 'published':
            dashboardService.removePublishedDashboard(published, true);
            break;
          case 'draft':
            dashboardService.removeDraftDashboard(draft, true);
            break;
          default:
            break;
        }
      },
    },
    {
      type: 'copy',
      name: 'general.copy',
      tooltip: 'general.copy',
      onClick: () =>
        status === 'combined'
          ? dashboardService.copyCombinedDashboards(dashboard, true)
          : dashboardService.copyDashboard(dashboard[status], () =>
              dashboardService.dashboardCopyEdit(dashboard[status].id, 'copy')
            ),
    },
  ];

  return (
    <div className={classes.wrapper}>
      {buttons.map((button, idx) => (
        <IconButtonWithText
          key={idx}
          iconType={button.type}
          name={button.name}
          showName={showName}
          tooltip={button.tooltip}
          onClick={button.onClick}
        />
      ))}
    </div>
  );
}

export default ActionButtons;
