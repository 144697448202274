import React, { useEffect, useMemo, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import ColorInput from '@components/ColorInput';
import MaterialCheckbox from '@components/Checkbox';
import I18n from '@components/I18n';
import Select from '@components/Select';
import styles from './GaugeCustomizationField.scss';
import { GaugeCustomizationFieldProps } from './GaugeCustomizationField.interface.d';
import { hasValue } from '@core/canvas/widget.utils';
import classNames from 'classnames';
import CalculatedNumericInput from '@components/CalculatedNumericInput';
import { getExpressionFromCalculations } from '@pages/CreateWidgetPage/widgets.utils';

const selectCustomStyle = {
  control: { height: 40 },
  container: { width: 'calc(100% - 130px)' },
};
const useStyles = makeStyles(() => ({
  errTextfield: {
    border: 'solid 1px var(--formikErrorBorder)',
    backgroundColor: 'var(--formikErrorBackground)',
  },
}));

function GaugeCustomizationField(props: GaugeCustomizationFieldProps) {
  const classes = useStyles(props);
  const {
    label,
    value,
    optionsForSelect,
    onChange,
    onBlur,
    type,
    required,
    disabled,
    extraClass,
    widgetData,
    calculationStyles,
  } = props;
  const { calculations, customizationMetrics } = widgetData;
  const hasError = useMemo(() => required && !hasValue(value), [required, value]);
  const [expression, setExpression] = useState('');

  useEffect(() => {
    let calculation;

    if (customizationMetrics.length && value?.valueId) {
      calculation = getExpressionFromCalculations(calculations, value?.valueId);
    }

    setExpression(calculation ? calculation : value?.toString());
  }, []);

  const textField = (
    <>
      <I18n className={classNames(styles.label, extraClass && styles[extraClass])} element="div">
        {`gauge-customization.${label}`}
      </I18n>
      <div>
        <TextField
          className={type === 'text' ? styles.textField : styles.numberField}
          InputProps={{
            classes: hasError ? { root: classes.errTextfield } : {},
            style: { color: 'var(--systemFont)' },
          }}
          value={value}
          margin="dense"
          variant="outlined"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={(e) => {
            onBlur && onBlur(e.target.value);
          }}
          type={type}
          disabled={disabled}
        />
        {hasError && extraClass && (
          <I18n className={styles[`${extraClass}Required`]} element="div">
            validations.mandatory
          </I18n>
        )}
      </div>
    </>
  );

  const calcNumberField = (
    <>
      <I18n
        className={classNames(
          styles.label,
          required && 'asterisk',
          extraClass && styles[extraClass]
        )}
        style={
          !required && label === 'end-value' && calculationStyles?.noAsteriskPadding
            ? { marginLeft: calculationStyles?.noAsteriskPadding }
            : undefined
        }
        element="div">
        {`gauge-customization.${label}`}
      </I18n>
      <div>
        <CalculatedNumericInput
          expression={expression}
          setExpression={setExpression}
          widgetData={widgetData}
          onChange={(value) => {
            setExpression(value);
            onChange(value);
          }}
          inputWidth={
            calculationStyles?.expressionTextlWidth
              ? calculationStyles?.expressionTextlWidth
              : '500px'
          }
        />
        {hasError && extraClass && (
          <I18n className={styles[`${extraClass}Required`]} element="div">
            validations.mandatory
          </I18n>
        )}
      </div>
    </>
  );

  const colorField = (
    <>
      <I18n className={classNames(styles.label, extraClass && styles[extraClass])} element="div">
        {`gauge-customization.${label}`}
      </I18n>
      <ColorInput
        value={value}
        customeMarginButton={0}
        popOverPosition={{ left: 1 }}
        isParentRelative={false}
        colorChange={onChange}
        disabled={disabled}
      />
    </>
  );

  const checkboxField = (
    <>
      <MaterialCheckbox
        color="primary"
        checked={value}
        onClick={() => onChange(!value)}
        disabled={disabled}
      />
      <I18n className={styles.cbLabel} element="div">
        {`gauge-customization.${label}`}
      </I18n>
    </>
  );

  const labelField = (
    <I18n className={styles.label} element="div">
      {`gauge-customization.${label}`}
    </I18n>
  );

  const selectField = (
    <>
      <I18n className={classNames(styles.label, extraClass && styles[extraClass])} element="div">
        {`gauge-customization.${label}`}
      </I18n>
      <Select
        styles={selectCustomStyle}
        options={optionsForSelect}
        value={value}
        onChange={(option) => onChange(option.value)}
        disabled={disabled}
      />
    </>
  );

  const fieldsMap = {
    text: textField,
    number: textField,
    calcNumber: calcNumberField,
    color: colorField,
    checkbox: checkboxField,
    label: labelField,
    select: selectField,
  };

  return (
    <div
      className={type === 'label' ? styles.fieldWrapperOnlyLabel : styles.fieldWrapper}
      style={
        type === 'calcNumber' && calculationStyles?.zoneInputWidth
          ? { width: calculationStyles?.zoneInputWidth }
          : type === 'color' && calculationStyles?.zoneColorWidth
          ? { width: calculationStyles?.zoneColorWidth }
          : undefined
      }>
      <div className={styles.fieldRow}>{fieldsMap[type]}</div>
      {hasError && !extraClass && (
        <I18n className={styles.required} element="div">
          validations.mandatory
        </I18n>
      )}
    </div>
  );
}

export default GaugeCustomizationField;
