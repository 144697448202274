import React, { useMemo } from 'react';

// Components
import I18n from '@components/I18n';
import Button from '@components/Button';

// Interfaces | Services
import {
  AssetTableRemoteAccessButtonProps,
  PLCTableRemoteAccessButtonProps,
  EditAssetRemoteAccessButtonProps,
  RouterTableRemoteAccessButtonProps,
} from './RemoteAccessButton.interface';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { useSelector } from '@src/redux/useSelector';
import { getFlagStatus } from '@core/ffAndPermissions';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';

const RemoteAccessButton = (
  props:
    | AssetTableRemoteAccessButtonProps
    | PLCTableRemoteAccessButtonProps
    | EditAssetRemoteAccessButtonProps
    | RouterTableRemoteAccessButtonProps
) => {
  // determines value of plcId & plcType By parent component (each parent sends the props differently)
  let plcId, plcType, routerId, isDemo, isExpired;
  switch (props.parentComponent) {
    case 'AssetsTable':
      const assetTableProps = props as AssetTableRemoteAccessButtonProps;
      plcId = assetTableProps.plc_id;
      plcType = assetTableProps.assetTypePlcModel;
      isDemo = assetTableProps.isDemo;
      isExpired = assetTableProps.subscription?.status === 'EXPIRED';
      break;
    case 'PLCTable':
      const plcTableProps = props as PLCTableRemoteAccessButtonProps;
      plcId = plcTableProps.id;
      plcType = plcTableProps.type;
      isDemo = plcTableProps.subscription.licenceType === 'Demo';
      isExpired = plcTableProps.asset?.subscriptionStatus
        ? plcTableProps.asset?.subscriptionStatus === 'EXPIRED'
        : plcTableProps.subscription?.status === 'EXPIRED';
      break;
    case 'EditAssetModal':
      const editAssetProps = props as EditAssetRemoteAccessButtonProps;
      plcId = editAssetProps.plc_id;
      plcType = editAssetProps.assetTypePlcModel;
      isExpired = editAssetProps.subscription?.status === 'EXPIRED';
      break;
    case 'RoutersTable':
    case 'EditRouterModal':
      const routerTableProps = props as RouterTableRemoteAccessButtonProps;
      routerId = routerTableProps.id;
      break;
  }
  const {
    remoteAccessType,
    styles = { minWidth: 62, width: 62 },
    status,
    hasCertificate,
    macchinaConnected,
  } = props;
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const flag = useMemo(() => getFlagStatus('assets.remote-access-buttons'), []);
  const hasPermission = useMemo(() => organizationTypeMap[userOrgType] === 3, [userOrgType]);
  const assetStatusToHide = useMemo(() => 'ARCHIVED', []);
  const showButton =
    hasPermission &&
    flag &&
    assetStatusToHide !== status &&
    ((plcType !== 'LEGACY' && plcType !== 'MODBUS') ||
      ((plcType === 'LEGACY' || plcType === 'MODBUS') && remoteAccessType === 'vpn'));

  const isDisabled =
    (!macchinaConnected && remoteAccessType !== 'routerWeb') ||
    (!hasCertificate && remoteAccessType !== 'routerWeb') ||
    props.isDemo ||
    isDemo ||
    isExpired;

  const onClick = () => {
    switch (remoteAccessType) {
      case 'vpn':
        onVPN();
        break;
      case 'vnc':
        onRemote('getRemoteVNC');
        break;
      case 'web':
        onRemote('getRemoteWeb');
        break;
      case 'routerWeb':
        onRemote('getRouterRemoteWeb');
        break;
    }
  };

  const onRemote = (apiType) => {
    httpService
      .api({
        type: apiType,
        urlParams: { plcId, routerId },
      })
      .then((res: { link: string }) => window.open(`${res.link}`, '_blank'));
  };

  const onVPN = () => {
    httpService.api({ type: 'getClientRemoteVPN' }).then((res: { clientUrl: string }) =>
      modalService
        .openModal('remoteVPN', {
          link: res.clientUrl,
        })
        .then((confirm) => {
          confirm && onRemote('getRemoteVPN');
        })
    );
  };

  return showButton ? (
    <Button
      disabled={isDisabled}
      onClick={!isDisabled ? onClick : null}
      mode="outlined"
      styles={{ ...styles }}>
      <I18n style={{ pointerEvents: 'none' }}>{`assets-tab.${remoteAccessType}`}</I18n>
    </Button>
  ) : null;
};

export default RemoteAccessButton;
