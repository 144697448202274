import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import { dispatch } from '@redux/store';
import Switch from '@material-ui/core/Switch';
import Button from '@components/Button';
import { Typography, Grid } from '@material-ui/core';
import { SwitchWidgetProps } from './SwitchWidget.interface';
import { SwitchCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { httpService } from '@core/http/HttpService';
import classNames from 'classnames';
import { setModal } from '@redux/widgetModals';
import Image from '@components/Image';
import { i18nService } from '@core/i18n/I18nService';
import { getFlagStatus } from '@core/ffAndPermissions';
import { imageCacheService } from '@core/canvas/ImageCacheService';
import { releaseWidgetForEdit, updateWidgetFilters } from '../widgets.utils';
import { updateUpdateAssetWidgetsData } from '@src/redux/updateAssetWidgetsData';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: `flex`,
    flexDirection: 'column',
    padding: '5px 25px',
    overflow: 'hidden',
  },
  switchWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    display: `flex`,
    height: ({ customization }: any) => {
      let textHeight = 0;
      if (customization.textualRemark && customization.textualRemark !== '')
        textHeight += customization.textualRemarkFontSize;

      if (customization.requestSentText && customization.requestSentText !== '')
        textHeight += customization.requestSentFontSize;

      return `calc(100% - ${textHeight}px)`;
    },
    width: ({ customization }: any) => (customization?.buttonType === 'IMAGE_BUTTON' ? '100%' : ''),
    minHeight: 38,
  },
  remarkText: {
    fontSize: ({ customization }: any) => customization.textualRemarkFontSize,
    color: ({ customization }: any) => customization.textualRemarkColor,
    textAlign: ({ customization }: any) =>
      customization.textualRemarkHorizontalAlignment &&
      customization.textualRemarkHorizontalAlignment.toLowerCase(),
  },
  requestSentText: {
    fontSize: ({ customization }: any) => customization.requestSentFontSize,
    color: ({ customization }: any) => customization.requestSentColor,
    textAlign: ({ customization }: any) =>
      customization.requestSentHorizontalAlignment &&
      customization.requestSentHorizontalAlignment.toLowerCase(),
  },
  switchText: {
    fontSize: ({ customization }: any) => customization.switchTextFontSize,
    color: ({ customization }: any) => customization.switchTextColor,
  },
  emptyBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
}));

function SwitchWidget(props: SwitchWidgetProps) {
  const {
    widget,
    isPreview,
    emptyImage,
    dashboardFilters,
    applyButtonClicked,
    setApplyButtonClicked,
  } = props;
  const switchUpdateAssetFlag = useMemo(() => getFlagStatus('dashboard-updates.switch-widget'), []);
  const [isDisabled, setIsDisabled] = useState(!isPreview && !switchUpdateAssetFlag);
  const classes = useStyles({ isDisabled, ...props });
  const [onImageUrl, setOnImageUrl] = useState();
  const [offImageUrl, setOffImageUrl] = useState();
  const customization = props.customization as SwitchCustomization;
  const [value, setValue] = useState(false);
  const [hasAssetValue, setHasAssetValue] = useState(false);
  const [showRequestSentText, setShowRequestSentText] = useState(false);
  const [assetName, setAssetName] = useState();
  const [assetStatus, setAssetStatus] = useState();
  const [assetSubscriptionStatus, setAssetSubscriptionStatus] = useState();
  const [selectedAssetId, setSelectedAssetId] = useState();
  const [widgetFilters, setWidgetFilters] = useState(props.widgetFilters);
  const [lastAssetUpdate, setLastAssetUpdate] = useState('');
  const [displayName, setDisplayName] = useState<string>(null);

  useEffect(() => {
    if (applyButtonClicked || dashboardFilters?.assetFilter?.length) {
      dashboardFilters?.assetFilter?.length > 0
        ? setWidgetFilters(() => updateWidgetFilters(dashboardFilters?.assetFilter))
        : setWidgetFilters([]);

      setApplyButtonClicked(false);
    }
  }, [applyButtonClicked]);

  useEffect(() => {
    let val =
      props?.data?.results &&
      props?.data?.results[0] &&
      props?.data?.results[0][props?.data?.columns[0]?.name];

    if (props?.data?.columns && props?.data?.columns[0]?.format === 'BOOLEAN') {
      setHasAssetValue(true);
      setValue(val);
    }
  }, [props.data?.results]);

  useEffect(() => {
    getDisplayName();
  }, [props?.data?.columns]);

  function getDisplayName() {
    if (!displayName && selectedAssetId && props?.data?.columns?.length) {
      const tagId = props?.data?.columns?.find((col) => col.valueType != 'ASSET_PROPERTY')?.valueId;
      const valueType = props?.data?.columns?.find(
        (col) => col.valueType != 'ASSET_PROPERTY' && col.valueId === tagId
      )?.valueType;

      httpService
        .api({
          type: 'getTagsData',
          urlParams: { assetId: selectedAssetId },
          disableBI: true,
        })
        .then((res: { tags: any[] }) => {
          const resultTag = res?.tags?.find((tag) => {
            if (valueType === 'TAG' && tag.id === tagId) return tag;
            if (valueType === 'TAG_TYPE' && tag.tagTypeId === tagId) return tag;
          });
          setDisplayName(resultTag.name);
        });
    }
  }

  const {
    textualRemark,
    textualRemarkFontSize,
    textualRemarkColor,
    textualRemarkHorizontalAlignment,
    textualRemarkVerticalAlignment,
    requestSentText,
    requestSentFontSize,
    requestSentColor,
    requestSentHorizontalAlignment,
    buttonType,
    switchSize,
    onText,
    offText,
    switchTextColor,
    switchTextDisabledColor,
    switchTextFontSize,
    onColor,
    offColor,
    disabledColor,
    switchColor,
    onImage,
    offImage,
  } = customization;

  const CustomSwitch = withStyles({
    switchBase: {
      color: isDisabled ? customization.disabledColor : customization.switchColor, // button unchecked
      '&$checked': {
        color: isDisabled ? customization.disabledColor : customization.switchColor, // button checked
      },
      '&$checked + $track': {
        backgroundColor: `${customization.onColor}!important`, // track checked,
      },
    },
    checked: {},
    track: {
      backgroundColor: `${customization.offColor}!important`, // track unchecked
    },
  })(Switch);

  const getImageData = async (imageId) => {
    try {
      const res = await imageCacheService.getImageURL(imageId);
      if (res) {
        return res.url;
      }
    } catch {}
  };

  const getAssetInfo = async () => {
    try {
      const data =
        Array.isArray(widgetFilters) &&
        widgetFilters?.find(
          (filter) =>
            filter.valueId === 1 &&
            filter.valueType === 'ASSET_PROPERTY' &&
            (!Array.isArray(filter.values) || filter.values.length === 1)
        );
      if (data) {
        setSelectedAssetId(Array.isArray(data.values) ? data.values[0] : data.values);
        const res: any = await httpService.api({
          type: 'getAssetInfo',
          urlParams: {
            assetId: Array.isArray(data.values) ? data.values[0] : data.values,
          },
        });

        if (res) {
          if (res.assetName) setAssetName(res.assetName);

          if (res.state) setAssetStatus(res.state);

          if (res.subscription && res.subscription.status)
            setAssetSubscriptionStatus(res.subscription.status);

          setLastAssetUpdate(res.updatedAt);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (onImage?.imageId) {
      const url = getImageData(onImage?.imageId);
      url.then((res: any) => {
        setOnImageUrl(res);
      });
    }
    if (offImage?.imageId) {
      const url = getImageData(offImage?.imageId);
      url.then((res: any) => {
        setOffImageUrl(res);
      });
    }
  }, [onImage?.imageId, offImage?.imageId]);

  useEffect(() => {
    getAssetInfo();
  }, [widgetFilters]);

  const onUpdateAssetTag = async (value) => {
    if (isPreview) {
      setValue(value);
      return;
    }

    dispatch(
      setModal({
        type: 'confirm',
        onClose: onUpdateAssetConfirmationClosed,
        args: {
          text: 'update-asset-modal.text',
          iconType: 'attention_image',
          tagName: displayName,
          tagValue: value
            ? onText
            : offText ||
              i18nService.translate(
                'create-widget-page.create-widget.step-four.switch.off-text.default'
              ),
          assetName: assetName,
          showCloseBtn: true,
          headerText: 'update-asset-modal.header',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
        },
      })
    );
  };

  const onUpdateAssetConfirmationClosed = (res): {} => {
    if (res) {
      const valueToSet = !value;
      setValue(valueToSet);
      setIsDisabled(true);
      dispatch(updateUpdateAssetWidgetsData({ [widget.id]: { isWidgetUpdating: true } }));

      if (assetStatus && assetStatus === 'CONNECTED')
        // is it the real status for an asset which is not connected?
        setShowRequestSentText(true);

      // is tag Id stored here?
      const tagId = props?.data?.columns[0]?.valueId;

      httpService.api({
        type: 'updateAssetTags',
        urlParams: { assetId: selectedAssetId, widgetId: widget.id },
        data: [
          {
            valueType: props?.data?.columns[0]?.valueType,
            valueId: tagId,
            data: valueToSet ? true : false,
          },
        ],
      });

      setTimeout(() => handleAfterUpdate(selectedAssetId, lastAssetUpdate), 2000);
    }

    return true;
  };

  useEffect(() => {
    if (!isDisabled) {
      setShowRequestSentText(false);
    }
  }, [isDisabled]);

  const handleAfterUpdate = useCallback((assetId: number, lastUpdated: string) => {
    releaseWidgetForEdit(
      assetId,
      widget.id,
      lastUpdated,
      setIsDisabled,
      setLastAssetUpdate,
      Date.now()
    );
  }, []);

  const handleClick = async () => {
    if (isDisabled) return;

    await onUpdateAssetTag(!value);
  };

  const handleChange = async (event) => {
    await onUpdateAssetTag(event.target.checked);
  };

  let component;

  if (
    (!assetSubscriptionStatus ||
      assetSubscriptionStatus === 'EXPIRED' ||
      !hasAssetValue ||
      !widgetFilters?.length ||
      !widgetFilters?.some(
        (f) =>
          f['valueType'] === 'ASSET_PROPERTY' &&
          f['values'].some((v) =>
            props?.data?.results?.some((r) => r['alias_ASSET_PROPERTY_1_None'] === v)
          )
      )) &&
    !isPreview
  )
    component = (
      <div className={classes.emptyBox}>
        <Image
          mode="smaller"
          src={emptyImage}
          text={`widget.widget-empty-${
            assetSubscriptionStatus === 'EXPIRED' ? 'label' : 'no-asset'
          }`}
        />
      </div>
    );
  else
    component = (
      <div className={classes.wrapper}>
        {textualRemarkVerticalAlignment && textualRemarkVerticalAlignment == 'TOP' && (
          <div
            className={classNames(classes.remarkText, 'ellipsis-overflow')}
            title={textualRemark}>
            {textualRemark}
          </div>
        )}
        <Typography component="div" className={classes.switchWrapper}>
          {buttonType === 'TOGGLE_BUTTON' && (
            <Grid component="label" container alignItems="center" spacing={1}>
              {offText && (
                <Grid
                  item
                  className={classNames(classes.switchText, 'ellipsis-overflow')}
                  title={offText}>
                  {offText}
                </Grid>
              )}
              <Grid item>
                <CustomSwitch
                  checked={value}
                  onChange={handleChange}
                  disabled={isDisabled}
                  size={switchSize === 'SMALL' ? 'small' : 'medium'}
                />
              </Grid>
              <Grid
                item
                className={classNames(classes.switchText, 'ellipsis-overflow')}
                title={onText}>
                {onText}
              </Grid>
            </Grid>
          )}
          {buttonType === 'BUTTON' && (
            <Button
              disabled={isDisabled}
              mode={switchSize === 'SMALL' ? 'default' : 'big'}
              styles={{
                backgroundColor: isDisabled ? disabledColor : value ? onColor : offColor,
                fontSize: switchSize === 'SMALL' ? '14px' : '16px',
                color: switchTextColor,
              }}
              onClick={handleClick}>
              {value ? onText : offText}
            </Button>
          )}

          {buttonType === 'IMAGE_BUTTON' && (
            <div
              style={{
                height: '100%',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                backgroundImage: `url(${value ? onImageUrl : offImageUrl})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                cursor: 'pointer',
                pointerEvents: isDisabled ? 'none' : 'all',
              }}
              onClick={handleClick}
            />
          )}
        </Typography>
        {textualRemarkVerticalAlignment && textualRemarkVerticalAlignment == 'BOTTOM' && (
          <div
            className={classNames(classes.remarkText, 'ellipsis-overflow')}
            title={textualRemark}>
            {textualRemark}
          </div>
        )}
        {(isPreview || showRequestSentText) && (
          <div
            className={classNames(classes.requestSentText, 'ellipsis-overflow')}
            title={requestSentText}>
            {requestSentText}
          </div>
        )}
      </div>
    );

  return component;
}

export default SwitchWidget;
