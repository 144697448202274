import I18n from '@components/I18n';
import React, { useEffect, useState } from 'react';
import styles from './TimePicker.scss';
import Select from '@components/Select';
import { i18nService } from '@core/i18n/I18nService';
import moment from 'moment';

const monthsOptions = [
  {
    label: 'events-template.edit-template.trigger.month.january',
    value: '01',
  },
  {
    label: 'events-template.edit-template.trigger.month.february',
    value: '02',
  },
  {
    label: 'events-template.edit-template.trigger.month.march',
    value: '03',
  },
  {
    label: 'events-template.edit-template.trigger.month.april',
    value: '04',
  },
  {
    label: 'events-template.edit-template.trigger.month.may',
    value: '05',
  },
  {
    label: 'events-template.edit-template.trigger.month.june',
    value: '06',
  },
  {
    label: 'events-template.edit-template.trigger.month.july',
    value: '07',
  },
  {
    label: 'events-template.edit-template.trigger.month.august',
    value: '08',
  },
  {
    label: 'events-template.edit-template.trigger.month.september',
    value: '09',
  },
  {
    label: 'events-template.edit-template.trigger.month.october',
    value: '10',
  },
  {
    label: 'events-template.edit-template.trigger.month.november',
    value: '11',
  },
  {
    label: 'events-template.edit-template.trigger.month.december',
    value: '12',
  },
];

const defoultTime = {
  fromYear: {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  fromMonth: {
    label: 'events-template.edit-template.trigger.month.january',
    value: 1,
  },
  toYear: {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  toMonth: {
    label: monthsOptions[moment().month()].label,
    value: monthsOptions[moment().month()].value,
  },
};

function TimePicker(props) {
  const { setfilter } = props;

  const [yearsOptions, setYearsOptions] = useState([]);
  const [fromYear, setFromYear] = useState(defoultTime.fromYear);
  const [fromMonth, setFromMonth] = useState(defoultTime.fromMonth);
  const [toYear, setToYear] = useState(defoultTime.toYear);
  const [toMonth, setToMonth] = useState(defoultTime.toMonth);

  useEffect(() => {
    const yearsArray = years(20);
    setYearsOptions(yearsArray.reverse());
  }, []);

  useEffect(() => {
    setfilter({
      date_after: `${fromYear.value}-${fromMonth.value}-01`,
      date_before: `${toYear.value}-${toMonth.value}-01`,
    });
  }, [fromYear, toYear, fromMonth, toMonth]);

  const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => ({
      label: year - back + i + 1,
      value: year - back + i + 1,
    }));
  };

  return (
    <div className={styles.row}>
      <div className={styles.text}>
        <I18n>my-account-page.licensing-plan.usage.from</I18n>
      </div>
      <Select
        styles={{ container: { width: '110px', marginRight: '3px' } }}
        className={styles.field}
        options={monthsOptions}
        value={fromMonth}
        defaultValue={fromMonth}
        getOptionLabel={(i) => i18nService.translate(i.label)}
        getOptionValue={(i) => i.value}
        onChange={(option) => setFromMonth(option)}
      />
      <Select
        styles={{ container: { width: '90px', marginRight: '3px' } }}
        className={styles.field}
        options={yearsOptions}
        value={fromYear}
        getOptionLabel={(i) => i.label}
        getOptionValue={(i) => i.value}
        onChange={(option) => setFromYear(option)}
      />

      <div className={styles.text}>
        <I18n>my-account-page.licensing-plan.usage.to</I18n>
      </div>
      <Select
        styles={{ container: { width: '110px', marginRight: '3px' } }}
        className={styles.field}
        options={monthsOptions}
        value={toMonth}
        getOptionLabel={(i) => i18nService.translate(i.label)}
        getOptionValue={(i) => i.value}
        onChange={(option) => setToMonth(option)}
      />
      <Select
        styles={{ container: { width: '90px', marginRight: '3px' } }}
        className={styles.field}
        options={yearsOptions}
        value={toYear}
        getOptionLabel={(i) => i.label}
        getOptionValue={(i) => i.value}
        onChange={(option) => setToYear(option)}
      />
    </div>
  );
}

export default TimePicker;
