import { httpService } from '@core/http/HttpService';
import { AssetDataResponse } from './AssetsTable.interface';

export const getAssets = (
  setNextPage,
  searchText,
  pageSize,
  nextPage,
  setDisableLoad,
  firstInit = false,
  licenseId,
  data,
  setData,
  setSearchResults,
  order?,
  filters?
) => {
  setDisableLoad(true);

  const filtersData = filters
    ? Object.keys(filters).reduce(
        (res, current) => ({
          ...res,
          [current]: `${filters[current].map((id) => id)}`,
        }),
        {}
      )
    : {};

  const reveredOrderSign = order ? (order.orderSign ? '' : '-') : '';

  httpService
    .api({
      type: 'getAssetsMatchingSubscriptionId',
      urlParams: { licenseId },
      query: {
        p: firstInit ? 1 : nextPage,
        ps: pageSize,
        search: searchText ? searchText : null,
        ordering: order
          ? `${order.orderSign}${order.label}${
              order.label === 'subscriptionStatus'
                ? `,${reveredOrderSign}subscriptionStatusAdd`
                : ''
            }`
          : null,
        ...filtersData,
      },
    })
    .then((res: AssetDataResponse) => {
      setSearchResults && setSearchResults(res.allCount, res.count);
      res.next && setDisableLoad(false);
      if (firstInit) {
        setData(res.results);
        setNextPage(2);
      } else {
        const newData = data.concat(res.results);
        setData(newData);
        setNextPage(nextPage + 1);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
