import { excludesWidgets } from '@core/canvas/widget.utils';

export const getDisplayWidget = (data, widget) => {
  return (
    (data && data.results && data.results.length) ||
    (widget && excludesWidgets.includes(widget.type))
  );
};

export const getCustomization = (widget) => {
  return widget && widget.customization ? widget.customization : {};
};
