import { httpService } from '@core/http/HttpService';
import { useEffect, useState } from 'react';

interface AssetBaseDetails {
  id: number;
  name: string;
  plcType: string;
  macchinaConnected: boolean;
}

export const useAssetBaseDetails = (assetId: number) => {
  const [assetBaseDetails, setAssetBaseDetails] = useState<AssetBaseDetails>(null);

  async function getAssetBaseDetails() {
    const assetBaseDetailsResponse: AssetBaseDetails = await httpService.api({
      type: 'getAssetBaseDetails',
      urlParams: { assetId },
    });
    setAssetBaseDetails(assetBaseDetailsResponse);
  }

  useEffect(() => {
    if (assetId) {
      getAssetBaseDetails();
      return;
    }
    setAssetBaseDetails(null);
  }, [assetId]);

  return { assetBaseDetails };
};
